import { Alert, Card, createStyles, Group, Space, Text } from '@mantine/core';
import { isValidValue } from '@root/Components/Resources/Tags/TagValidation';
import { AnchorButton } from '@root/Design/Primitives';
import { colorPalette } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { Fragment, useEffect, useState } from 'react';
import { ResourceTagService } from '../Services/ResourceTagService';
import { BulkTagProps } from './BulkTagProps';
import { TagKeyNames, TagEditor } from './TagEditor';
import { TagField } from './TagField';

export const RenameTag = (props: BulkTagProps) => {
    const [tagKeyList, setTagKeyList] = useState<string[]>([]);
    const [allTagKeyList, setAllTagKeyList] = useState<string[]>([]);
    const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
    const { classes, theme } = useStyles();
    const resourceSvc = useDi(ResourceService);
    const [showOverwriteWarning, setShowOverwriteWarning] = useState<boolean>(false);
    const [selectedKeyError, setSelectedKeyError] = useState<string>('');
    const [renameKeys, setRenameKeys] = useState<TagKeyNames[]>([{ OldTagKey: '', NewTagKey: '' }]);
    const [canAdd, setCanAdd] = useState<boolean>(true);
    const [canRemove, setCanRemove] = useState<boolean>(false);
    const tagSvc = useDi(ResourceTagService);
    const [overwriteConflicts, setOverwriteConflicts] = useState<boolean>(true);
    const [deleteRenamedTags, setDeleteRenamedTags] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            setTagKeyList(await tagSvc.getValidKeys(props.resourceIds));
            setAllTagKeyList((await resourceSvc.getTags()).sort());
        })();
    }, [props.resourceIds.count()]);

    useEffect(() => {
        if (props.tagstoUpdate && props.updateToValue) {
            setRenameKeys(props.tagstoUpdate.map((t) => ({ OldTagKey: t, NewTagKey: props.updateToValue! })));
        }
    }, []);

    useEffect(() => {
        checkCanAdd();
    }, [tagKeyList]);

    useEffect(() => {
        const validForm = formIsValid();
        // disabling validation until select-by-query is added
        setDisableSubmit(!validForm);
        checkCanAdd();
        checkCanRemove();
    }, [JSON.stringify(renameKeys), props.resourceIds.count()]);

    const checkCanAdd = () => {
        setCanAdd(renameKeys.length < tagKeyList.length);
    };

    const checkCanRemove = () => {
        setCanRemove(renameKeys.length > 1);
    };

    const formIsValid = () => {
        return (
            renameKeys.filter((k) => k.NewTagKey === '' || k.OldTagKey === '').length === 0 &&
            renameKeys.filter((k) => k.NewTagKey === k.OldTagKey || isValidValue(k.NewTagKey ?? '') != 0).length === 0
        );
    };

    const setOldTagKey = (index: number, key: string) => {
        setRenameKeys((prevState) => {
            let newState = [...prevState];
            let oldKey = newState[index];
            oldKey.OldTagKey = key;
            newState.splice(index, 1, oldKey);
            return newState;
        });
    };

    const setNewTagKey = (index: number, key: string) => {
        setRenameKeys((prevState) => {
            let newState = [...prevState];
            let newKey = newState[index];
            newKey.NewTagKey = key;
            newState.splice(index, 1, newKey);
            return newState;
        });
    };

    const addRowHandler = () => {
        setRenameKeys((prevState) => {
            let newState = [...prevState];
            newState.push({ OldTagKey: '', NewTagKey: '' });
            return newState;
        });
    };

    const removeRowHandler = (index: number) => {
        setRenameKeys((prevState) => {
            let newState = [...prevState];
            newState.splice(index, 1);
            return newState;
        });
    };

    const tagJob = tagSvc.createTagJob(props.resourceIds, {
        Renames: renameKeys,
        OverwriteConflicts: overwriteConflicts,
        DeleteRenamedTags: deleteRenamedTags,
    });

    return (
        <TagEditor
            onButtonClick={props.onButtonClick}
            companyId={props.companyId}
            resourceIds={props.resourceIds}
            pageTitle={props.pageTitle}
            instructions={props.instructions}
            canSubmit={!disableSubmit}
            setDisableSubmit={setDisableSubmit}
            overwriteConflicts={!overwriteConflicts}
            setOverwriteConflicts={setOverwriteConflicts}
            operationType="renameKey"
            tagJob={tagJob}
            onTagJobStarting={props.onTagJobStarting}
            onTagJobStarted={props.onTagJobStarted}
            setDeleteRenamedTags={setDeleteRenamedTags}
        >
            <>
                {renameKeys.map((tag, index) => (
                    <Fragment key={`keyFragment${index}`}>
                        <Card withBorder radius="md" p="sm">
                            <Group noWrap grow spacing={0}>
                                <TagField
                                    atid={'TagNewKeyInput:' + index}
                                    key={`newKey${index}`}
                                    children={
                                        <TagField
                                            atid={'TagKeyInput:' + index}
                                            key={`oldKey${index}`}
                                            fieldValue={renameKeys[index].OldTagKey}
                                            valuePickList={tagKeyList.filter((k) => !renameKeys.map((m) => m.OldTagKey).includes(k))}
                                            label="Select Tag"
                                            setSelectedValue={setOldTagKey}
                                            canAdd={false}
                                            canRemove={false}
                                            index={index}
                                            count={renameKeys.length}
                                            receiveInitalFocus={true}
                                        />
                                    }
                                    fieldValue={renameKeys[index].NewTagKey}
                                    valuePickList={allTagKeyList}
                                    label={deleteRenamedTags ? 'Rename To' : 'Copy To'}
                                    setSelectedValue={setNewTagKey}
                                    canAdd={false}
                                    canRemove={canRemove}
                                    index={index}
                                    count={renameKeys.length}
                                    handleAddRow={addRowHandler}
                                    handleRemoveRow={removeRowHandler}
                                />
                            </Group>
                        </Card>
                        <Space h="sm" />
                    </Fragment>
                ))}
                <Card withBorder radius="md" p="sm">
                    <AnchorButton text="Add" onClick={addRowHandler} icon={<i className="ti ti-circle-plus"></i>} atid={'RenameTagKeysAddAnchor'} />
                </Card>
                {showOverwriteWarning && !disableSubmit ? (
                    <Alert
                        icon={<i className="ti ti-alert-triangle" color="warning" />}
                        sx={{ marginTop: theme.spacing.lg, borderRadius: theme.radius.sm }}
                        title="Are you sure?"
                        color="warning"
                    >
                        One or more of your selected resources already has the target tag. Renaming will over-write these tag values.
                    </Alert>
                ) : null}
                <Text color="error" hidden={selectedKeyError == undefined || selectedKeyError.length === 0}>
                    *{selectedKeyError}
                </Text>
            </>
        </TagEditor>
    );
};

const useStyles = createStyles((theme) => ({
    textColor: {
        color: colorPalette.subHeaderTextColor,
        alignContent: 'center',
        i: {
            alignItems: 'center',
        },
    },
    footer: {
        position: 'absolute',
        bottom: 0,
    },

    keyValues: {
        marginTop: `${theme.spacing.xs}px`,
    },
}));

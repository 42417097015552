import { postResourcesQuery, postResourceTaggingEstimateTagJob, postSavedSearchSaveSearch, QueryExpr, QueryResult } from '@apis/Resources';
import { IQueryExpr, Query, ResourceIdentifier, SavedSearchToken, Tag, TagRename, TagResourcesJob, TagValueReplace } from '@apis/Resources/model';
import { BaseResource, BaseResourceId, IResourceGridSelection } from '@root/Components/Resources/ResourcesGrid';
import { Logger } from '@root/Services/Logger';
import { queryBuilder, traverseExpr } from '@root/Services/QueryExpr';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { inject, injectable } from 'tsyringe';

@injectable()
export class ResourceTagService {
    public constructor(@inject(ResourceService) private readonly resourceSvc: ResourceService, @inject(Logger) private readonly logger: Logger) {}

    public async estimateJobCredits(tagJob: TagResourcesJob) {
        return await postResourceTaggingEstimateTagJob(tagJob);
    }

    private convertResourceId({ CloudPlatform, Id, ResourceType }: BaseResourceId) {
        return {
            CloudPlatform,
            ResourceId: Id,
            ResourceType,
        } as ResourceIdentifier;
    }

    public createTagJob(
        selection: IResourceGridSelection,
        options:
            | { AddTags: Tag[]; OverwriteConflicts: boolean }
            | { DeleteTags: Tag[] }
            | { Renames: TagRename[]; OverwriteConflicts: boolean; DeleteRenamedTags: boolean }
            | { ReplaceValues: TagValueReplace }
    ) {
        return {
            AllResources: selection.allSelected(),
            Filter: selection.allSelected() ? selection.getResourceQuery() : undefined,
            ExcludedResourceIds: selection.allSelected() ? selection.getExcluded().map(this.convertResourceId) : undefined,
            ResourceIds: selection.allSelected() ? undefined : selection.getIncluded().map(this.convertResourceId),
            ...options,
        } as TagResourcesJob;
    }

    public async getValidKeys(selection: IResourceGridSelection) {
        return this.getUniqueField(selection, 'Tags.Key');
    }

    public async getKeysByQuery(expr: QueryExpr | undefined) {
        return this.getUniqueFieldByQuery(expr, 'Tags.Key');
    }

    public async getValidValues(selection: IResourceGridSelection, key: string) {
        return this.getUniqueField(selection, `CsTags.${key}`);
    }

    public async tryPinResults(tagJob: TagResourcesJob, query: Query) {
        const affectedTags = !query.Where ? null : this.getAffectedTags(tagJob);
        const queryContainsTags = !query.Where
            ? null
            : traverseExpr(
                  query.Where as QueryExpr,
                  (e) =>
                      ('Field' in e && affectedTags?.has(e.Field)) ||
                      ('Operation' in e &&
                          e.Operation === 'eq' &&
                          'Field' in e.Operands[0] &&
                          e.Operands[0].Field === 'Tags.Key' &&
                          'Value' in e.Operands[1] &&
                          e.Operands[1].Value instanceof Array &&
                          e.Operands[1].Value.some((a: string) => affectedTags?.has(a)))
              );

        if (queryContainsTags) {
            try {
                return await postSavedSearchSaveSearch(query!);
            } catch (err) {
                this.logger.warn(`Saved search failed for tag job ${JSON.stringify(query)}`, err);
            }
        }
    }

    private queryContainsTag(tags: Set<string>, expr: QueryExpr) {
        return traverseExpr(expr, (e) => {
            if ('Field' in e && tags.has(e.Field)) {
                return true;
            }
            if ('Value' in e) {
                const value = e.Value;
                if (value instanceof Array) {
                    return value.some((v) => tags.has(v));
                } else if (typeof value === 'string') {
                    return tags.has(value);
                }
            }
            return false;
        });
    }

    private getAffectedTags(tagJob: TagResourcesJob) {
        if (tagJob.ReplaceValues?.Key) {
            var result = new Set<string>();
            result.add(`CsTags.${tagJob.ReplaceValues?.Key}`);
            result.add(tagJob.ReplaceValues?.Key);
            result.add(`CsTags.${tagJob.ReplaceValues?.Replacement}`);
            result.add(tagJob.ReplaceValues?.Replacement!);
            result.add(`CsTags.${tagJob.ReplaceValues?.Values?.[0]}`);
            result.add(tagJob.ReplaceValues?.Values?.[0]!);
            return result;
        } else {
            return [
                tagJob.AddTags?.map((t) => t.Key),
                tagJob.DeleteTags?.map((t) => t.Key),
                tagJob.Renames?.map((r) => r.OldTagKey),
                tagJob.Renames?.map((r) => r.NewTagKey),
            ].reduce((result, set) => {
                if (set) {
                    for (const item of set) {
                        if (item) {
                            result.add(`CsTags.${item}`);
                            result.add(item);
                        }
                    }
                }
                return result;
            }, new Set<string>());
        }
    }

    private getUniqueField(selection: IResourceGridSelection, field: string) {
        const criteria = selection.getResourceQuery() as QueryExpr;
        return this.getUniqueFieldByQuery(criteria, field);
    }

    private async getUniqueFieldByQuery(expr: QueryExpr | undefined, field: string) {
        const query = await queryBuilder<BaseResource>()
            .select((b) => ({
                tag: b.model[field],
                count: b.count(),
            }))
            .build();
        query.Where = expr;
        const results = (await postResourcesQuery(query)) as QueryResult<{ tag: string }>;
        const resultValues = results?.Results?.map((r) => r.tag) ?? [];
        resultValues.sort();
        return resultValues;
    }
}

import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AllocationRuleEditor } from '../Model';
import { AllocationSources } from '../AllocationSources';
import { DisbursementTargetPicker } from '../DisbursementTargetPicker';
import { BaseAllocationRuleModel } from './BaseAllocationRuleModel';
import { IMiniDataGridProps, MiniDataGridItem } from '../MiniDataGrid';
import { useDi, useDiMemo } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { Button, Drawer, Group, NumberInput, Select, Space, Stack, TextInput } from '@mantine/core';
import { DataGrid } from '@root/Components/DataGrid';
import { DataColumnConfig } from '@root/Components/DataGrid/Models';
import { exprBuilder, queryBuilder } from '@root/Services/QueryExpr';
import { IInvoiceRollup, IMonthlyRollup } from '@root/Services/Invoices/InvoiceSchemaService';
import { FundSource, FundSourceDefinition, FundSourceAmortizationAmortizationPeriodUnit } from '@apis/Invoices/model';
import { ExternalFundSource, LineItemFundSource } from '../../Models';
import { DatePicker } from '@mantine/dates';
import { CalendarTime } from 'tabler-icons-react';
import { SidePanel, SidePanelOpener, useSidePanelOpener } from '@root/Design/SidePanel';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';

export const SharedCostRuleCard = observer(function SharedCostRuleCard({ ruleEditor }: { ruleEditor: AllocationRuleEditor }) {
    const model = useMemo(() => new SharedCostRuleModel(ruleEditor), [ruleEditor.rule]);
    const sourcePicker = useSidePanelOpener();
    const customPicker = useSidePanelOpener();
    const [disableDimension, setDisableDimension] = useState(false);

    const addSourceButtons = useMemo(
        () => [
            {
                label: 'Add Allocation Source',
                onClick: () => {
                    sourcePicker.open();
                },
            },
            {
                label: 'Add Custom Fee',
                onClick: () => {
                    customPicker.open();
                },
            },
        ],
        []
    );

    const addDisbursementButtons = useMemo(
        () => [
            {
                label: 'Add Filters',
                onClick: () => {},
            },
        ],
        []
    );

    // #region Mini Grid Related
    const getSources = useCallback(() => model.getSharedCostAllocationSources(), [model]);

    const sourceListProps = {
        nameHeader: 'Description',
        amountHeader: 'Amount',
        displayTotal: true,
    } as IMiniDataGridProps;

    // #endregion

    const handleCustomSave = useCallback(
        (
            name: string,
            description: string,
            amount: number,
            date: Date,
            amoritizationPeriodValue: number,
            amortizationPeriodUnit: FundSourceAmortizationAmortizationPeriodUnit
        ) => {
            model.addCustomFee(name, description, amount, date, amoritizationPeriodValue, amortizationPeriodUnit);
            ruleEditor.setFundSource({ Sources: model.getSources() } as FundSourceDefinition);
            customPicker.close();
        },
        []
    );

    useEffect(() => {
        setDisableDimension(model.getCustomSources().length > 0);
    }, [model.getCustomSources()]);

    return (
        <>
            <AllocationSources
                title="Shared Costs"
                gridData={getSources}
                gridProps={sourceListProps}
                buttons={addSourceButtons}
                sourceChanged={model.sourcesChanged}
                helpText={`Add Costs that need to be distributed to resources or ${ruleEditor.getDimensionName()} or create your own internal cost to distribute.`}
            />
            <DisbursementTargetPicker ruleEditor={ruleEditor} buttons={addDisbursementButtons} disableDimension={disableDimension} />
            <SharedCostOptionsDrawer model={model} sourcePicker={sourcePicker} />
            <CustomFeeDrawer setIsCustomOpen={customPicker} handleCustomSave={handleCustomSave} />
        </>
    );
});

type SharedCostProduct = { productName: string; cost?: number };
class SharedCostRuleModel extends BaseAllocationRuleModel {
    private productCosts?: Map<string, number>;
    private sharedCostProducts?: SharedCostProduct[];
    public loading = new EventEmitter<boolean>(true);

    public constructor(ruleEditor: AllocationRuleEditor) {
        super(ruleEditor);
        this.loadSharedCostOptions();
    }

    public addCustomFee(
        name: string,
        description: string,
        amount: number,
        date: Date,
        amoritizationPeriodValue: number,
        amortizationPeriodUnit: FundSourceAmortizationAmortizationPeriodUnit
    ) {
        const customSource = this.createFundSource('External') as ExternalFundSource;
        customSource.Name = name;
        customSource.ExternalDetail = {
            Name: name,
            Description: description,
            Amount: amount,
            Date: date.toISOString(),
        };
        customSource.Amortization = {
            AmortizationPeriodUnit: amortizationPeriodUnit,
            AmortizationPeriodValue: amoritizationPeriodValue,
            Amount: amount,
            Date: date.toISOString(),
        };

        this.addSource(customSource);
        this.sourcesChanged.emit();
    }

    public getCustomSources(): FundSource[] {
        let sources = this.getSources();
        if (sources) {
            sources = sources.filter((s) => s.SourceType === 'External');
        }
        return sources;
    }

    public getSource(): FundSource {
        let source = this.getSources().find((s) => s.SourceType === 'LineItems');
        if (!source) {
            const lineItemSource = this.createFundSource('LineItems') as LineItemFundSource;

            this.addSource((source = lineItemSource as FundSource));
        }
        return source!;
    }

    public async loadSharedCostOptions() {
        this.loading.emit(true);
        try {
            if (!this.sharedCostProducts) {
                const currentOptions = await this.getSharedCostOptions();
                const previousSelections = this.getPersistedProducts();
                this.sharedCostProducts = [
                    ...previousSelections.filter((p) => !currentOptions.some((o) => o.productName === p.productName)),
                    ...currentOptions,
                ];
                this.productCosts = currentOptions.reduce((result, item) => result.set(item.productName, item.cost), new Map<string, number>());
            }
            return this.sharedCostProducts.sort((a, b) => a.productName.localeCompare(b.productName))!;
        } finally {
            this.loading.emit(false);
            this.sourcesChanged.emit();
        }
    }

    public getProductOptions() {
        return this.loading.value ? [] : this.sharedCostProducts ?? [];
    }

    public getProductCost(product: SharedCostProduct) {
        return this.productCosts?.get(product.productName) ?? 0;
    }

    private async getSharedCostOptions() {
        const { allocDim, month, showbackSvc } = this.ruleEditor;

        const { chargeFees, chargeSupport, chargeNotMarketplace } = showbackSvc.getMetricCriteria(allocDim);
        return await queryBuilder<IMonthlyRollup>()
            .where((b) => b.and(b.fromExpr<boolean>(chargeNotMarketplace), b.or(b.fromExpr<boolean>(chargeFees), b.fromExpr<boolean>(chargeSupport))))
            .select((b) => ({
                productName: b.model['product/ProductName']!,
                cost: b.sum(b.model['lineItem/UnblendedCost']!),
            }))
            .execute((q) => showbackSvc.invoiceApi.queryMonthlyRollup(q, [month]))
            .then((r) => r.Results ?? []);
    }

    public getSharedCostAllocationSources() {
        const products = this.getSelectedProducts().map(
            (p) =>
                ({
                    getAmount: () => this.getProductCost(p) ?? 0,
                    getName: () => p.productName,
                    onChanged: this.sourcesChanged,
                    remove: () => this.removeProduct(p),
                } as MiniDataGridItem)
        );

        const sources = this.getCustomSources();
        const customNames = sources?.map(
            (m) =>
                ({
                    getAmount: () => m.ExternalDetail?.Amount,
                    getName: () => m.Name,
                    onChanged: this.sourcesChanged,
                    remove: () => this.removeSource(m),
                } as MiniDataGridItem)
        );

        return [...products, ...customNames];
    }

    private getPersistedProducts() {
        const source = this.getSource();
        return (source.PresentationOptions?.['products'] ?? []) as SharedCostProduct[];
    }

    public getSelectedProducts() {
        return this.getPersistedProducts()
            .map((p) => this.sharedCostProducts?.find((sp) => sp.productName === p.productName))
            .filter((p) => !!p) as SharedCostProduct[];
    }

    public setSelectedProducts(products: SharedCostProduct[]) {
        const source = this.getSource();
        source.PresentationOptions = { products };
        this.updateFilters();
        this.sourcesChanged.emit();
    }

    public getCustomNames() {
        const sources = this.getCustomSources();
        return sources.map((s) => s.Name) as string[];
    }

    private getProductNameFilters() {
        return { Operation: 'eq', Operands: [{ Field: 'product/ProductName' }, { Value: this.getSelectedProducts().map((p) => p.productName) }] };
    }

    private removeProduct(product: SharedCostProduct) {
        const products = this.getSelectedProducts();
        this.setSelectedProducts(products.filter((p) => p !== product));
    }

    private updateFilters() {
        const { allocDim, showbackSvc } = this.ruleEditor;
        const { chargeFees, chargeSupport } = showbackSvc.getMetricCriteria(allocDim);
        const productSource = this.getSource();
        const selections = this.getSelectedProducts();

        if (!selections.length) {
            productSource.Filters = {};
        } else {
            productSource.Filters = {
                InclusionRules: [
                    {
                        Name: 'Shared Cost Charges',
                        Filter: {
                            Operation: 'and',
                            Operands: [
                                this.getProductNameFilters(),
                                {
                                    Operation: 'or',
                                    Operands: [chargeFees, chargeSupport],
                                },
                            ],
                        },
                    },
                ],
            };
        }
    }
}

function SharedCostOptionsDrawer({ sourcePicker, model }: { sourcePicker: SidePanelOpener; model: SharedCostRuleModel }) {
    const fmtSvc = useDi(FormatService);
    const [selections, setSelections] = useState<SharedCostProduct[]>();
    const clearSelections = useCallback(() => setSelections(undefined), [selections]);
    useEvent(sourcePicker.evt, clearSelections);

    const gridColumns = useMemo(
        () =>
            [
                {
                    accessor: 'productName',
                    defaultWidth: 250,
                    id: 'productName',
                    header: 'Product Name',
                    type: 'string',
                },
                {
                    accessor: 'cost',
                    defaultWidth: 150,
                    id: 'cost',
                    header: 'Amount',
                    type: 'number',
                    formatter: (v) => fmtSvc.formatMoneyNonZeroTwoDecimals(model.getProductCost(v)),
                    align: 'right',
                },
            ] as DataColumnConfig<SharedCostProduct>[],
        []
    );

    useEffect(() => {
        model.loadSharedCostOptions();
    }, []);

    const handleSave = useCallback(() => {
        if (selections) {
            model.setSelectedProducts(selections ?? []);
        }
        sourcePicker.close();
    }, [selections]);

    const handleSelectionChanged = useCallback(async ({ getItems }: { getItems: () => Promise<SharedCostProduct[]> }) => {
        const selections = await getItems();
        setSelections(selections);
    }, []);

    return (
        <SidePanel
            size={500}
            title="Allocation Source"
            opener={sourcePicker}
            toolbar={
                <>
                    <Button onClick={sourcePicker.close} variant="outline">
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleSave}>
                        Save
                    </Button>
                </>
            }
        >
            {() => (
                <DataGrid
                    dataSource={model.getProductOptions()}
                    columns={gridColumns}
                    onRowClick="select"
                    selectionMode="multiple"
                    onSelectedChanged={handleSelectionChanged}
                    initialSelection={model.getSelectedProducts()}
                    minimumLoadingMs={0}
                    hideFilter
                    hideColumnSelector
                    hideGlobalSearch
                    hideHeader
                    hideMenu
                />
            )}
        </SidePanel>
    );
}

function CustomFeeDrawer({
    setIsCustomOpen,
    handleCustomSave,
}: {
    setIsCustomOpen: SidePanelOpener;
    handleCustomSave: (
        name: string,
        description: string,
        amount: number,
        date: Date,
        amoritizationPeriodValue: number,
        amortizationPeriodUnit: 'Years' | 'Months' | 'Days' | 'None'
    ) => void;
}) {
    const [customAmount, setCustomAmount] = useState(0);
    const [customName, setCustomName] = useState('');
    const [customDescription, setCustomDescription] = useState('');
    const [customDate, setCustomDate] = useState<Date>(new Date());
    const [amortUnit, setAmortUnit] = useState<'Years' | 'Months' | 'Days' | 'None'>('Years');
    const [amortValue, setAmortValue] = useState(0);
    const [canSave, setCanSave] = useState(false);
    useEvent(
        setIsCustomOpen.evt,
        useCallback(() => {
            setCustomAmount(0);
            setCustomName('');
            setCustomDescription('');
            setCustomDate(new Date());
            setAmortUnit('Years');
            setAmortValue(0);
        }, [])
    );

    useEffect(() => {
        setCanSave(customAmount > 0 && customName.length > 0);
    }, [customAmount, customName]);
    return (
        <SidePanel
            opener={setIsCustomOpen}
            size={500}
            title="Custom Fee"
            toolbar={
                <>
                    <Button onClick={setIsCustomOpen.close} variant="outline">
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => handleCustomSave(customName, customDescription, customAmount, customDate, amortValue, amortUnit)}
                        disabled={!canSave}
                    >
                        Save
                    </Button>
                </>
            }
        >
            {() => (
                <Stack align="flex-start">
                    <TextInput
                        type="text"
                        name="name"
                        sx={{ width: 320 }}
                        placeholder="Enter name"
                        label="Name"
                        onChange={(e) => setCustomName(e.target.value)}
                    />
                    <TextInput
                        type="text"
                        name="description"
                        placeholder="Enter description"
                        label="Description"
                        sx={{ width: 320 }}
                        onChange={(e) => setCustomDescription(e.target.value)}
                    />
                    <NumberInput
                        name="amount"
                        sx={{ width: 200 }}
                        placeholder="Enter amount"
                        label="Amount"
                        value={customAmount}
                        onChange={(value) => setCustomAmount(value ?? 0)}
                    />
                    <DatePicker
                        sx={{ width: 200 }}
                        label="Date"
                        value={customDate}
                        onChange={(value) => setCustomDate(value ?? new Date())}
                        icon={<CalendarTime />}
                    />

                    <NumberInput
                        name="amount"
                        sx={{ width: 250 }}
                        placeholder="Enter amount"
                        label="Amortize Over"
                        value={amortValue}
                        onChange={(value) => setAmortValue(value ?? 0)}
                        rightSectionWidth={125}
                        rightSection={
                            <Select
                                data={[
                                    { label: 'Years', value: 'Years' },
                                    { label: 'Months', value: 'Months' },
                                    { label: 'Days', value: 'Days' },
                                ]}
                                sx={{ ['input']: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }}
                                value={amortUnit}
                                onChange={(value) => setAmortUnit((value as 'Years' | 'Months' | 'Days' | 'None') ?? 'None')}
                            />
                        }
                    />
                </Stack>
            )}
        </SidePanel>
    );
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import { container } from 'tsyringe';
import { Logger } from './Logger';

export class EventEmitter<T> {
    private listeners = new Set<(value: T, prevValue: T) => void>();
    private logger = container.resolve(Logger);
    public get value() {
        return this._value;
    }
    public constructor(private _value: T) {}

    public static empty() {
        return new EventEmitter<void>(void 0);
    }

    public get hasListeners() {
        return this.listeners.size > 0;
    }

    public emitCurrent = () => {
        this.emit(this.value);
    };
    public emit = (value: T) => {
        const prevValue = this.value;
        this._value = value;
        for (const listener of this.listeners) {
            try {
                listener(value, prevValue);
            } catch (err) {
                this.logger.error('Error raising event listener. ', err, listener);
            }
        }
    };

    public listen = (listener: (value: T, prevValue: T) => void) => {
        const dispose = () => {
            this.listeners.delete(listener);
        };
        this.listeners.add(listener);
        return { dispose };
    };
}

export function useToggle(initial: boolean) {
    const [value, setValue] = useState(initial);
    const toggle = useCallback(() => setValue((v) => !v), [setValue]);
    const close = useCallback(() => setValue(false), [setValue]);
    const open = useCallback(() => setValue(true), [setValue]);
    return [value, { toggle, close, open }] as [boolean, { toggle(): void; close(): void; open(): void }];
}

const eventEmitterTraceEnabled = () => (window as any).enableEventEmitterTrace === false;
function getEventEmitterTrace() {
    if (eventEmitterTraceEnabled()) {
        const getStackTrace = () => new Error().stack;
        const source = getStackTrace();
        return () => console.log('Emitted by', getStackTrace(), 'Listening from', source);
    } else {
        return () => {};
    }
}

export function useEvent<T>(event?: EventEmitter<T>, handler?: (value: T, prevValue: T) => void) {
    const [_, setState] = useState(0);
    const listenHandle = useMemo(() => {
        const trace = getEventEmitterTrace();
        return event?.listen((value, prev) => {
            trace();
            setState((state) => state + 1);
            handler?.(value, prev);
        });
    }, [event, handler, setState]);
    useEffect(() => {
        return () => listenHandle?.dispose();
    }, [listenHandle]);
}

export function useEventValue<T>(event?: EventEmitter<T>) {
    useEvent(event);
    return event?.value;
}

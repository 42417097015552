import { Card, Space, Title } from '@mantine/core';
import { observer } from 'mobx-react';
import { RuleEditor } from './Model';
import { InheritanceActionCard, InheritanceEditor } from './InheritanceActionCard';
import { ReactNode, useMemo } from 'react';
import { PreviewRuleCard } from './PreviewRuleCard';
import RuleStatusCard from './RuleStatusCard';
import { useDiContainer } from '@root/Services/DI';

export function InheritanceRuleCard({ ruleEditor }: { ruleEditor: RuleEditor }) {
    const di = useDiContainer();
    const typeEditor = useMemo(() => di.resolve(InheritanceEditor).init(ruleEditor), []);
    return (
        <>
            <InheritanceActionCard inheritanceEditor={typeEditor} />
            <Space h="md" />
            <PreviewRuleCard ruleEditor={ruleEditor} />
            <Space h="md" />
            <RuleStatusCard ruleEditor={ruleEditor} />
        </>
    );
}

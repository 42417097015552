import { inject, injectable } from 'tsyringe';
import { useDiContainer } from './DI';
import type { IRouteMeta } from './Router/BasicRouteLoader';
import { IRouteMetaToken } from './Router/BasicRouteLoader';
import { Router } from './Router/Router';
import { Route, RouteSerializer } from './Router/RouteSerializer';

@injectable()
export class NavigationService {
    public constructor(
        @inject(IRouteMetaToken) private routeMeta: IRouteMeta,
        @inject(RouteSerializer) private routeSerializer: RouteSerializer,
        @inject(Router) private router: Router
    ) {}

    /**
     * Navigate deeper, append new endpoint at the end of the breadcrumb
     * @param name route name for component to navigate to
     * @param params data to pass to the endpoint
     */
    public descend = (name: string, params?: Record<string, string>) => {
        this.setLocation(this.getDescendUrl(name, params));
    };
    /**
     * Get URL to navigate deeper and append new endpoint at the end of the breadcrumb
     * @param name route name for component to navigate to
     * @param params data to pass to the endpoint
     */
    public getDescendUrl = (name: string, params?: Record<string, string>) => {
        return this.getUrl([...this.routeMeta.route, { name, data: params ?? {} }]);
    };
    public getDescendUnencodedUrl = (name: string, params?: Record<string, string>) => {
        return this.getUnencodedUrl([...this.routeMeta.route, { name, data: params ?? {} }]);
    };

    /**
     * Navigate up the breadcrumb, remove the deepest page
     */
    public ascend = () => {
        this.setLocation(this.getAscendUrl());
    };
    /**
     * Get URL to navigate up the breadcrumb and remove the deepest page
     */
    public getAscendUrl = () => {
        return this.getUrl(this.routeMeta.parentRoute);
    };

    /**
     * Navigate away, replace the current endpoint with the passed endpoint
     * @param name route name for component to navigate to
     * @param params data to pass to the endpoint
     */
    public move = (name: string, params?: Record<string, string>) => {
        this.setLocation(this.getMoveUrl(name, params));
    };

    /**
     * Get URL to navigate away and replace the current endpoint with the passed endpoint
     * @param name route name for component to navigate to
     * @param params data to pass to the endpoint
     */
    public getMoveUrl = (name: string, params?: Record<string, string>) => {
        return this.getUrl([...this.routeMeta.parentRoute, { name, data: params ?? {} }]);
    };

    /**
     * Navigate away, replace entire breadcrumb
     * @param route route to navigate to
     */
    public goto = (route: Route | string) => {
        if (typeof route === 'string') {
            this.setLocation(route);
        } else {
            this.setLocation(this.getUrl(route));
        }
    };

    public getRootUrl = (route: Route | string) => {
        if (typeof route === 'string') {
            return this.getUrl([]) + route;
        } else {
            return this.getUrl(route);
        }
    };

    public getRoute = () => {
        return this.routeMeta.route;
    };

    /**
     * Update parameters for the current route, merge with existing parameters
     * @param params extra parameters
     */
    public mergeParams = (params: Record<string, string>) => {
        this.setParams({ ...this.getParams(), ...params });
    };

    /**
     * Update parameters for the current route, replace all parameters
     * @param params new params
     */
    public replaceParams = (params: Record<string, string>) => {
        this.setParams(params);
    };
    /**
     * Get url for current route, with specific data
     * @param params new params
     */
    public getDataUrl = (params: Record<string, string>) => {
        return this.getMoveUrl(this.routeMeta.endpointName, params);
    };

    private setParams = (params: Record<string, string>) => {
        const url = this.getMoveUrl(this.routeMeta.endpointName, params);
        this.setLocation(url);
    };

    private getUrl = (route: Route) => {
        return '/' + this.routeSerializer.serialize(this.routeMeta.consumed) + '/' + this.routeSerializer.serialize(route);
    };
    private getUnencodedUrl = (route: Route) => {
        return '/' + this.routeSerializer.serialize(this.routeMeta.consumed) + '/' + this.routeSerializer.serializeNoEncode(route);
    };

    private setLocation = (url: string) => {
        this.router.navigate(url);
    };

    /**
     * Get specific route params, case-insensitive
     * @param params route param names
     * @returns
     */
    public getData = <P extends string[]>(...params: P) => {
        const result: { [K in P[number]]?: string } = {};
        const paramLookup = new Map(params.map((p) => [p.toLocaleLowerCase(), p]));
        for (const key of Object.keys(this.routeMeta.params)) {
            const param = paramLookup.get(key.toLocaleLowerCase());
            if (param) {
                result[param as P[number]] = this.routeMeta.params[key];
            }
        }
        return result;
    };

    /**
     * Get all route params, not recommended, use getData instead
     * @returns route params
     */
    public getParams = () => {
        return this.routeMeta.params;
    };
}

export function useNav(routeMeta?: IRouteMeta) {
    const di = useDiContainer();
    const navSvc = !routeMeta ? di.resolve(NavigationService) : new NavigationService(routeMeta, di.resolve(RouteSerializer), di.resolve(Router));
    return navSvc;
}

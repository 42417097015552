import { Anchor, Box, Collapse, Space, Stack, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { AnchorButton } from '@root/Design/Primitives';
import { CustomColors } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { useNav } from '@root/Services/NavigationService';
import { BasicRouteLoader } from '@root/Services/Router/BasicRouteLoader';
import { useLink } from '@root/Services/Router/Router';
import { Fragment, ReactNode, useState, useEffect } from 'react';
import { ChevronDown, ChevronLeft, ChevronRight, X } from 'tabler-icons-react';
import { FaqA, FaqQ, WizardStep, WizardStepContainer, WizardStepLine, WizardStepRow } from './Design';
import { MspService } from '@root/Services/MspService';

export function MapWizardSteps({
    canProceed,
    goto,
    step,
    steps,
}: {
    canProceed: boolean;
    goto: (step: number) => void;
    step: number;
    steps: number;
}) {
    const theme = useMantineTheme();
    const routeLoader = useDi(BasicRouteLoader);
    const { getAscendUrl, goto: gotoUrl } = useNav(routeLoader.getTopRouteMeta());
    const handleOnCancel = () => {
        let url = getAscendUrl();
        gotoUrl(url);
    };
    return (
        <Box sx={{ display: 'flex', whiteSpace: 'nowrap' }}>
            <WizardStepContainer>
                <Box sx={{ justifySelf: 'flex-start' }}>
                    {step === 1 ? null : <WizardStepArrow canProceed dir="left" onClick={() => goto(step - 1)} />}
                </Box>
                <WizardStepRow>
                    {Array.from({ length: steps }).map((_, i) => (
                        <Fragment key={i}>
                            {i > 0 ? <WizardStepLine /> : null}
                            <WizardStep
                                onClick={i < step ? () => goto(i + 1) : undefined}
                                mode={step === i + 1 ? 'active' : i + 1 > step ? 'upcoming' : 'finished'}
                            >
                                {i + 1}
                            </WizardStep>
                        </Fragment>
                    ))}
                </WizardStepRow>
                <Box sx={{ justifySelf: 'flex-end' }}>
                    {step === steps ? null : <WizardStepArrow canProceed={canProceed} dir="right" onClick={() => goto(step + 1)} />}
                </Box>
            </WizardStepContainer>
            <Stack
                spacing={0}
                sx={{
                    marginLeft: 'auto',
                    marginRight: '30px',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    color: theme.colors.primary[6],
                    flexGrow: 'unset',
                    cursor: 'pointer',
                }}
                onClick={handleOnCancel}
            >
                <X size="40" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                <Text color={theme.colors.primary[6] as CustomColors}>Cancel</Text>
            </Stack>
        </Box>
    );
}

function WizardStepArrow({ canProceed, dir, onClick }: { canProceed: boolean; dir: 'left' | 'right'; onClick: () => void }) {
    const theme = useMantineTheme();
    return (
        <UnstyledButton
            sx={{
                borderRadius: '50%',
                background: dir === 'left' || canProceed ? theme.colors.primary[3] : theme.colors.gray[3],
                height: '52px',
                width: '52px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: dir === 'left' ? 0 : '5px',
                paddingRight: dir === 'left' ? '5px' : 0,
                cursor: dir === 'left' || canProceed ? 'pointer' : 'not-allowed',
            }}
            data-atid="WizardStepArrow"
            className={dir !== 'left' && canProceed ? 'next-hint' : ''}
            disabled={!canProceed}
            onClick={onClick}
        >
            {dir === 'left' ? (
                <ChevronLeft color={theme.colors.primary[6]} size={40} />
            ) : (
                <ChevronRight color={canProceed ? theme.colors.primary[6] : theme.colors.gray[6]} size={40} />
            )}
        </UnstyledButton>
    );
}

export function WizardHeavyLifting() {
    const { getDescendUrl } = useNav();
    const link = useLink();
    const mspSvc = useDi(MspService);
    const [mngdSvcsPageUrl, setMngdSvcsPageUrl] = useState<string>('https://www.cloudsaver.com/services/cloud-migration-services/');
    const [mngdSvcsIconPath, setMngdSvcsIconPath] = useState<string>('/assets/map-manager/cloudsaver-lifting.png');
    const [isExternalMgdSvcPage, setIsExternalMgdSvcPage] = useState(true);
    useEffect(() => {
        if (mspSvc?.config?.isWhiteLabelEnabled) {
            if (mspSvc?.config?.MapMgrProSvcsLink !== '') {
                setMngdSvcsPageUrl(mspSvc!.config!.MapMgrProSvcsLink!);
                setIsExternalMgdSvcPage(true);
            }
            if (mspSvc?.config?.MapMgrProSvcsIcon !== '') {
                setMngdSvcsIconPath(mspSvc!.config!.MapMgrProSvcsIcon!);
            }
        }
    }, []);

    function getManagedSvcsUrl() {
        if (isExternalMgdSvcPage) {
            return mngdSvcsPageUrl;
        } else {
            return getDescendUrl(mngdSvcsPageUrl);
        }
    }

    return (
        <Box sx={{ display: 'flex', width: '300px', alignItems: 'flex-end' }}>
            <Box sx={{ minWidth: '200px' }}>
                <Text size="sm" weight="bolder">
                    Want help?
                    <br />
                    Let us do the heavy lifting.
                </Text>
                <Space h="xs" />
                <Text size="sm">We offer professional services that will get your organization's tags and cloud organization done quickly.</Text>
                <Space h="xs" />
                <AnchorButton
                    {...link(getManagedSvcsUrl())}
                    external={isExternalMgdSvcPage}
                    text="Learn more"
                    iconPosition="right"
                    icon={<ChevronRight />}
                    color="primary.5"
                />
                <Space h="xl" />
            </Box>
            <img style={{ minWidth: '100px' }} src={mngdSvcsIconPath} />
        </Box>
    );
}

export function FaqItem({ question, children }: { question: ReactNode; children: ReactNode }) {
    const [open, { toggle }] = useDisclosure(false);
    return (
        <Box my="md">
            <FaqQ onClick={toggle}>
                {open ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
                <div>{question}</div>
            </FaqQ>
            <Collapse in={open}>
                <FaqA>{children}</FaqA>
            </Collapse>
        </Box>
    );
}

import { Box, Card, Group, List, Select, Space, Text, ThemeIcon, useMantineTheme } from '@mantine/core';
import { observer } from 'mobx-react';
import { TagAutomationRuleStatus } from '@apis/TagManager/model';
import { RuleEditCard, ValidationIssues } from './Design';
import { RuleEditor } from './Model';
import { AlertTriangle, Triangle } from 'tabler-icons-react';

const statusDescription: Record<TagAutomationRuleStatus, string> = {
    Active: 'This rule will be Activated when saved',
    Deleted: 'This rule will be deleted and will not be shown with the other rules',
    Test: 'This rule will run in test mode. No resources will be changed, but changes will be stored for later review',
    Draft: 'This rule will be saved as a Draft. It will not make any tag changes',
};

const selectOptions = [
    { value: 'Draft', label: 'Draft' },
    { value: 'Test', label: 'Test' },
    { value: 'Active', label: 'Active' },
] as { value: TagAutomationRuleStatus; label: string }[];
function RuleStatusCard({ ruleEditor }: { ruleEditor: RuleEditor }) {
    const supplementalText = ruleEditor.ruleStatus ? statusDescription[ruleEditor.ruleStatus] : '';
    const ruleIssues = ruleEditor.getRuleTypeIssues();
    const hasValidationErrors = ruleEditor.filterValidation.length || ruleIssues?.length;
    const theme = useMantineTheme();

    return (
        <RuleEditCard title="Status" color="gray">
            <Group noWrap align="flex-start">
                <Box mt="md" sx={{ width: '300px' }}>
                    <Box sx={{ width: '200px' }}>
                        <Select width={200} withinPortal value={ruleEditor.ruleStatus} onChange={ruleEditor.setRuleStatus} data={selectOptions} />
                    </Box>
                    <Space h="sm" />
                    <Text size="sm">{supplementalText}</Text>
                </Box>
                <Box>
                    {!hasValidationErrors ? null : (
                        <Card radius="lg" sx={{ background: theme.white }}>
                            <Group noWrap>
                                <ThemeIcon radius="lg" variant="light" color="warning">
                                    <AlertTriangle size={20} />
                                </ThemeIcon>
                                <Text size="sm">
                                    The rule may only be saved in <strong>Draft</strong> mode, because of validation errors.
                                </Text>
                            </Group>
                        </Card>
                    )}
                    <Box px="lg">
                        {!ruleIssues?.length ? null : <ValidationIssues title="There are issues with the rule type setup" issues={ruleIssues} />}
                        {!ruleEditor.filterValidation.length ? null : (
                            <ValidationIssues title="Some filters are not valid" issues={ruleEditor.filterValidation} />
                        )}
                    </Box>
                </Box>
            </Group>
        </RuleEditCard>
    );
}

export default observer(RuleStatusCard);

import { Anchor, Box, Center, Group, Space, Text } from '@mantine/core';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { useNav } from '@root/Services/NavigationService';
import { useLink } from '@root/Services/Router/Router';
import { CustomColors, mspColorPalette } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { MspService } from '@root/Services/MspService';
import { useEventValue } from '@root/Services/EventEmitter';

interface NavBarLink {
    text: string;
    atid?: string | undefined;
    endpoint: string;
    icon: ReactNode;
    type: 'link';
    data?: Record<string, string>;
    visible?: boolean;
    rightIcon?: ReactNode;
}
interface NavBarTitle {
    text: string;
    type: 'title';
    visible?: boolean;
}
interface NavBarDivider {
    type: 'divider';
    visible?: boolean;
}

export type NavBarItem = NavBarLink | NavBarTitle | NavBarDivider;

export function PageNavBar({ depth = 1, items }: { depth?: number; items: NavBarItem[] }) {
    const mspService = useDi(MspService);
    const themeReady = useEventValue(mspService.themeReady);
    return themeReady ? (
        <PageNavBarEl>{items.map((item, i) => (item.visible ?? true ? <Item key={i} item={item} depth={depth} /> : null))}</PageNavBarEl>
    ) : (
        <></>
    );
}

function Item({ depth, item }: { depth: number; item: NavBarItem }) {
    if (item.type === 'divider') {
        return <Space h="xl" />;
    } else if (item.type === 'link') {
        return <NavBarLink item={item} depth={depth} />;
    } else if (item.type === 'title') {
        return (
            <Text color="dimmed" size="sm">
                {item.text}
            </Text>
        );
    }
    return <></>;
}

function NavBarLink({ item, depth }: { depth: number; item: NavBarLink }) {
    const { getRoute, getMoveUrl, getRootUrl } = useNav();
    const link = useLink();
    const href = getMoveUrl(item.endpoint, item.data ?? {});
    const selected = getRootUrl(getRoute()).toLocaleLowerCase().indexOf(href.toLocaleLowerCase()) >= 0;
    return (
        <Anchor data-atid={item.atid} component={PageNavBarLink} mode={selected ? 'selected' : undefined} {...link(href)}>
            <Group noWrap position="left" spacing={5} sx={{ flex: 1 }}>
                {item.icon}
                {item.text}
            </Group>
            <Center sx={{ flex: 0 }}>{item.rightIcon}</Center>
        </Anchor>
    );
}

//settings nav
export const PageNavBarLink = styled.a<{ mode?: 'selected' }>`
    display: flex;
    width: 100%;
    font-size: ${(p) => p.theme.fontSizes.sm}px;
    line-height: 35px;
    margin: ${(p) => p.theme.spacing.xs / 2}px 0;
    padding: 0 ${(p) => p.theme.spacing.xs}px;
    align-items: center;
    white-space: nowrap;
    border-radius: ${(p) => p.theme.radius.md}px;
    color: ${(p) => (p.mode === 'selected' ? mspColorPalette.SettingsNavHoverAndSelectedTextColor : (p.theme.colors.gray[8] as CustomColors))};
    background: ${(p) => (p.mode === 'selected' ? (mspColorPalette.SettingsNavHoverAndSelectedBgColor as CustomColors) : undefined)};
    &:hover {
        text-decoration: none;
        background: ${(p) => mspColorPalette.SettingsNavHoverAndSelectedBgColor as CustomColors};
        color: ${(p) => mspColorPalette.SettingsNavHoverAndSelectedTextColor};
    }
`;

export const PageNavBarEl = styled.div`
    padding: ${(p) => p.theme.spacing.lg}px;
    min-width: 200px;
`;

import { getResourcesGetParentResourceTypes, postResourcesGetChildResources, postResourcesQuery, QueryExpr, QueryResult } from '@apis/Resources';
import { BaseResource } from '@apis/Resources/model';
import styled from '@emotion/styled';
import { Anchor, Box, Button, Card, Divider, Grid, Group, Space, Stack, Title } from '@mantine/core';
import { BarChart, BarChartSettings } from '@root/Components/Charts/BarChart';
import { PieChart, PieChartSettings } from '@root/Components/Charts/PieChart';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { ChildAccessor, ColumnConfig, DataSourceConfig } from '@root/Components/DataGrid/Models';
import { DataFilterPercent } from '@root/Components/Filter/Filters';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';
import { InitialSyncCheck } from '@root/Components/Resources/IntialSyncCheck';
import { ResourceDetailsOpener } from '@root/Components/Resources/ResourceDetails';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { VisibleSpaces } from '@root/Components/Text/VisibleSpaces';
import { PageContent, PagePanel, PanelBody, PaneledPage, PanelHeader } from '@root/Design/Layout';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { exprBuilder, IFluentQueryAdapter, queryBuilder, SchemaService } from '@root/Services/QueryExpr';
import { ResourceSchemaProvider, ResourceService } from '@root/Services/Resources/ResourceService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useGridResizer } from '@root/Site/Invoices/Components/GridResizer';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { OpenSidebar } from '../Components/OpenSidebar';
import { UpdateTags } from '../Components/UpdateTags';
import { CopyTags } from './CopyTags';
import { HierarchyGrid, HierarchyTagCell } from './HierarchyGrid';
import { TagIntelligencePage } from './TagIntelligencePage';

interface ResourcePieChartItem {
    ResourceType: string;
    Count: number;
}

export function TagHierarchyContent() {
    const formatSvc = useDi(FormatService);
    const company = useCompany();
    const onResourceClick = useMemo(() => new EventEmitter<BaseResource | undefined>(undefined), []);
    const userPermissions = useAuthZValues({ canTag: { TagManager: 'Tag' } });
    const [grid, setGrid] = useState<DataGridModel>();
    const [selectedResources, setSelectedResources] = useState<BaseResource[]>([]);
    const [disableButton, setDisableButton] = useState(false);
    const [completenessXAxis, setCompletenessXAxis] = useState<string>();
    const [completenessBarData, setCompletenessBarData] = useState<{ x: string; state: string; count: number }[]>();
    const [groupCountByResourceType, setGroupCountByResourceType] = useState<Record<string, string | number>[]>();
    const [kpiResults, setKpiResults] = useState<{ roots: number; total: number; averageCompleteness: number }>();
    const [tagKeys, setTagKeys] = useState<string[]>();
    const [defaultColumns, setDefaultColumns] = useState<ColumnConfig<BaseResource>[]>();
    const childAccessor = useMemo(
        () =>
            ({
                hasChildren: (item) => {
                    return item.ChildResourceIds && item.ChildResourceIds.length > 0 ? true : false;
                },
            } as ChildAccessor<BaseResource>),
        []
    );
    const [openModal, setOpenModal] = useState<OpenSidebar>(OpenSidebar.undefined);
    const resourceSvc = useDi(ResourceService);
    const resourceSchemaProvider = useDi(ResourceSchemaProvider);
    const [schema, setSchema] = useState<SchemaService>();
    const [loading, setLoading] = useState(true);
    const resourceDetails = useEventValue(onResourceClick);

    const completenessBarSettings = {
        margin: { top: 20, bottom: 80, left: 40, right: 20 },
        orientation: 'Vertical',
        valueFormat: '>-0.1~%',
        noWrapper: true,
    } as BarChartSettings;

    const groupPieSettings = {
        angle: 'medium',
        margin: { top: 50, bottom: 50, left: 50, right: 50 },
        threshold: 2,
        noWrapper: true,
    } as PieChartSettings;

    const gridDataSource = useMemo(() => {
        return {
            async getPage(start, end, state, parent) {
                const query = await queryBuilder<BaseResource>()
                    .skip(start)
                    .take(end - start + 1)
                    .build();
                query.Sort = state.sort;
                const relationshipCriteria = !parent
                    ? exprBuilder<BaseResource>().createExpr(getRootResourcesExpr)
                    : exprBuilder<BaseResource>().createExpr((b) =>
                          b.and(b.model['ParentResourceId.Id']!.eq(parent.Id), b.model['ParentResourceId.ResourceType']!.eq(parent.ResourceType))
                      );
                query.Where = !parent
                    ? {
                          Operation: 'And',
                          Operands: [...state.filters, relationshipCriteria],
                      }
                    : relationshipCriteria;
                const result = (await postResourcesQuery(query)) as QueryResult<BaseResource>;

                return { items: result.Results, total: result.Count ?? 0 };
            },
        } as DataSourceConfig<BaseResource>;
    }, []);

    useEffect(() => {
        if (!tagKeys) {
            return;
        }
        (async () => {
            try {
                const completenessXAxis = tagKeys.includes('Application')
                    ? { field: 'CsTags.Application', label: 'Application' }
                    : { field: 'ResourceType', label: 'Type' };
                setCompletenessXAxis(completenessXAxis.label);
                const completenessResult = await queryBuilder<BaseResource>()
                    .where((x) => x.model.CompletenessScore!.isNotNull())
                    .select((b) => ({
                        x: {
                            operation: 'values',
                            operands: [{ field: completenessXAxis.field }, { value: '' }, { value: 'Unknown' }],
                        } as unknown as string,
                        complete: b.count(b.model.CompletenessScore?.eq(1)) as unknown as number,
                        count: b.count(),
                    }))
                    .execute(postResourcesQuery);

                const completeness = (completenessResult?.Results ?? []).reduce((result, item) => {
                    result?.push(
                        { x: item.x, state: 'Complete', count: item.complete },
                        { x: item.x, state: 'Incomplete', count: item.count - item.complete }
                    );
                    return result;
                }, [] as typeof completenessBarData);
                setCompletenessBarData(completeness);

                const kpiResults = await queryBuilder<BaseResource>()
                    .where((x) => x.model.CompletenessScore!.isNotNull())
                    .select((b) => ({
                        roots: b.count(getRootResourcesExpr(b)) as unknown as number,
                        total: b.count(),
                        averageCompleteness: b.avg(b.model.CompletenessScore),
                    }))
                    .execute(postResourcesQuery);

                setKpiResults(kpiResults?.Results?.[0] ?? { averageCompleteness: 0, roots: 0, total: 0 });

                const resourceTypeResults = await queryBuilder<BaseResource>()
                    .where(getRootResourcesExpr)
                    .select((b) => ({
                        resourceType: b.model.ResourceType as string,
                        count: b.count(),
                    }))
                    .execute(postResourcesQuery);

                const resourceTypes = resourceTypeResults?.Results ?? [];
                setGroupCountByResourceType(resourceTypes);
            } finally {
                setLoading(false);
            }
        })();
    }, [tagKeys]);

    const changeOpenModel = (sidebar: OpenSidebar) => {
        setOpenModal(sidebar);
    };

    useEvent(grid?.selectionChanged, async () => {
        const selectedResources = (await grid?.selections.getSelected()) as BaseResource[];
        setSelectedResources(selectedResources);
    });

    useEffect(() => {
        (async () => {
            const types = await resourceSchemaProvider.getSchema();
            setSchema(new SchemaService(types));
            setTagKeys(await resourceSvc.getTags());
        })();
    }, []);

    useEffect(() => {
        if (!tagKeys || !schema) {
            return;
        }
        const valueProviderFactory = resourceSchemaProvider.createValueProviderFactory()(schema);
        (async () => {
            const columnResult: ColumnConfig<BaseResource>[] = [
                {
                    accessor: (item) => item.Id,
                    id: 'Base.Name',
                    sortField: 'Name',
                    header: 'ID',
                    defaultWidth: 230,
                    cellRenderer: (resource) => (
                        <Anchor
                            onClick={() => {
                                onResourceClick.emit(resource);
                            }}
                        >
                            {resource.Name}
                        </Anchor>
                    ),
                    defaultFixed: true,
                    type: 'string',
                    filter: {
                        filterType: 'string',
                        name: 'ID',
                        filterField: 'Name',
                    },
                },
                {
                    header: 'Resource Type',
                    accessor: 'ResourceType',
                    defaultWidth: 150,
                    id: 'ResourceType',
                    type: 'string',
                    align: 'left',
                    filter: {
                        filterField: 'ResourceType',
                        filterType: 'string',
                        name: 'Resource Type',
                        options: valueProviderFactory,
                    },
                },
                {
                    header: 'Completeness Score',
                    accessor: (item) => ((item['CompletenessScore'] || 0) as number) * 100,
                    defaultWidth: 165,
                    id: 'CompletenessScore',
                    sortField: 'CompletenessScore',
                    type: 'number',
                    align: 'center',
                    filter: {
                        filterField: 'CompletenessScore',
                        filterType: 'number',
                        name: 'Completeness Score',
                        valueRenderer: DataFilterPercent,
                        tokenProvider: (filter, tokens) =>
                            tokens.map((token) => {
                                if (token.type === 'constant' && typeof filter.value === 'number') {
                                    return {
                                        expr: token.expr,
                                        name: formatSvc.formatPercent(filter.value),
                                        text: formatSvc.formatPercent(filter.value),
                                        type: 'constant',
                                    };
                                }
                                return token;
                            }),
                    },
                    cellRenderer: (item) => formatSvc.formatPercent((item['CompletenessScore'] || 0) as number),
                },
            ];
            const tagColumns = tagKeys.map(
                (c, i) =>
                    ({
                        id: `Tags.CsTags.${c}`,
                        accessor: `CsTags.${c}`,
                        header: c,
                        defaultWidth: 120,
                        defaultHidden: i > 9,
                        align: 'left',
                        cellRenderer: (item: BaseResource) => <HierarchyTagCell item={item} tag={c} />,
                        headerRenderer: () => <VisibleSpaces value={c} />,
                        groupName: 'Tags',
                        filter: {
                            filterField: `CsTags.${c}`,
                            filterType: 'string',
                            name: c,
                            options: valueProviderFactory,
                        },
                    } as ColumnConfig<BaseResource>)
            );
            tagColumns.sort((a, b) => a.header?.localeCompare(b.header ?? '', undefined, { sensitivity: 'base' }) ?? 0);
            tagColumns.forEach((c) => columnResult.push(c));
            setDefaultColumns(columnResult);
        })();
    }, [tagKeys, schema]);

    useEffect(() => {
        if (selectedResources!.length == 1) {
            setDisableButton(false);
        } else if (selectedResources!.length == 0 || selectedResources!.length > 1) {
            setDisableButton(true);
        }
    }, [selectedResources]);

    function clearSelection() {
        setSelectedResources([]);
    }

    function setPanelButtonClick(openSidebar: OpenSidebar, canClear: boolean) {
        if (canClear) {
            clearSelection();
        }
        changeOpenModel(openSidebar);
    }

    const button = userPermissions.canTag ? (
        <div style={{ display: openModal == OpenSidebar.undefined ? 'block' : 'none' }}>
            <Button
                data-atid={'CopyTags'}
                style={{ marginRight: '24px' }}
                disabled={disableButton}
                onClick={() => changeOpenModel(OpenSidebar.copyTags)}
            >
                Copy Tags &nbsp;&nbsp;<i className="ti ti-chevron-right"></i>
            </Button>
        </div>
    ) : (
        <></>
    );

    const { Resizer, containerStyle, setScrollContainer } = useGridResizer();
    return (
        <>
            <TagIntelligencePage>
                <PaneledPage style={{ overflow: 'hidden' }}>
                    <Stack
                        p="lg"
                        ref={setScrollContainer}
                        sx={{ gap: 24, height: '100%', width: '100%', display: 'flex', flexDirection: 'column', overflow: 'auto' }}
                    >
                        <PanelHeader>
                            <Title data-atid={'IntelligenceHeader'} order={2}>
                                Tag Hierarchy
                            </Title>
                        </PanelHeader>
                        <Group noWrap>
                            <Stack sx={{ flexGrow: 1, height: 400, width: 600 }}>
                                <Card withBorder radius="lg" p="md" sx={{ height: '120px', display: 'flex' }}>
                                    <HierarchyStat value={kpiResults?.roots} title="Groups" />
                                    <HierarchyStat value={kpiResults?.total} title="Resources" />
                                    <HierarchyStat value={formatSvc.formatPercent(kpiResults?.averageCompleteness ?? 0)} title="Avg. Score" />
                                </Card>
                                <Card withBorder radius="lg" p="lg" sx={{ height: '100%' }}>
                                    Completeness Score {completenessXAxis ? `by ${completenessXAxis}` : ''}
                                    {completenessBarData ? (
                                        <BarChart
                                            settings={completenessBarSettings}
                                            groups={['x', 'state']}
                                            values={['count']}
                                            data={completenessBarData}
                                        />
                                    ) : null}
                                </Card>
                            </Stack>

                            <Card withBorder radius="lg" p="lg" sx={{ height: 400, width: 670 }}>
                                Parent Resources
                                <PieChart
                                    settings={groupPieSettings}
                                    groups={['resourceType']}
                                    values={['count']}
                                    data={groupCountByResourceType ?? []}
                                />
                            </Card>
                        </Group>
                        <Box sx={{ minHeight: 400, height: '100%', overflow: 'hidden', ...containerStyle }}>
                            <Card withBorder radius="lg" sx={{ height: '100%' }}>
                                {defaultColumns ? (
                                    <HierarchyGrid<BaseResource>
                                        data={gridDataSource}
                                        schemaSvc={schema!}
                                        childAccessor={childAccessor!}
                                        metricColumns={defaultColumns}
                                        rightTopPlaceHolder={button}
                                        rightToolPlaceHolder={
                                            <>
                                                <Resizer />
                                                <Space w="md" />
                                            </>
                                        }
                                        onModelLoaded={setGrid}
                                    />
                                ) : null}
                            </Card>
                        </Box>
                    </Stack>
                    {openModal != OpenSidebar.undefined ? (
                        <>
                            <Divider orientation="vertical" />
                            <PagePanel padded={false} size="sm" style={{ minWidth: 350, height: '100%' }}>
                                {openModal == OpenSidebar.copyTags ? (
                                    <CopyTags
                                        onButtonClick={setPanelButtonClick}
                                        companyId={company?.Id ?? 0}
                                        resource={selectedResources[0]}
                                        pageTitle="Copy Tags"
                                        instructions="Select tags to copy to parent resources or child resources in the hiearchy tree for this resource."
                                    ></CopyTags>
                                ) : null}
                            </PagePanel>
                        </>
                    ) : null}
                </PaneledPage>
            </TagIntelligencePage>

            <ResourceDetailsOpener
                onClose={() => onResourceClick.emit(undefined)}
                resourceId={resourceDetails?.Id ?? ''}
                platform={resourceDetails?.CloudPlatform ?? 'Aws'}
                resourceType={resourceDetails?.ResourceType ?? ''}
                resource={resourceDetails}
            />
        </>
    );
}

function getRootResourcesExpr(b: IFluentQueryAdapter<BaseResource>) {
    return b.and(b.model['ChildResourceIds.Id']!.isNotNull(), b.or(b.model['ParentResourceId.Id']!.eq(''), b.model['ParentResourceId.Id']!.isNull()));
}

function TagHierarchy() {
    return <ConnectionCheck>{() => <InitialSyncCheck>{() => <TagHierarchyContent />}</InitialSyncCheck>}</ConnectionCheck>;
}
endpoint('tag-hierarchy', TagHierarchy, 'Tag Intelligence');

function HierarchyStat({ value, title, format }: { value?: number | string; title: string; format?: 'percent' }) {
    return (
        <Box sx={{ flex: 1 }}>
            <BigNumber>{(value ?? 0).toLocaleString()}</BigNumber>
            <Box sx={{ textAlign: 'center' }}>
                <Title order={6}>{title}</Title>
            </Box>
        </Box>
    );
}

const BigNumber = styled.div`
    font-size: 28px;
    font-weight: bold;
    text-align: center;
`;

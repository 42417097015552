import { getCreditsGetCreditSummary } from '@apis/Customers';
import { QueryExpr, QueryResult } from '@apis/Resources';
import { IQueryExpr, Query, TagAutomationRule } from '@apis/Resources/model';
import { postTagAutomationQueryTraceByRule } from '@apis/TagManager';
import { TagAutomationTraceByRule } from '@apis/TagManager/model';
import styled from '@emotion/styled';
import { Box, Divider, Space, Text, useMantineTheme } from '@mantine/core';
import { DataGrid } from '@root/Components/DataGrid';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { GridFullCell } from '@root/Components/DataGrid/Design';
import { ColumnConfig, DataSourceConfig } from '@root/Components/DataGrid/Models';
import { TreeQuery } from '@root/Components/VirtualTree/TreeQuery';
import { PanelBody, PanelContent } from '@root/Design/Layout';
import { Score } from '@root/Design/Primitives';
import { CustomColors } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { copyExpr, IFluentOperators, queryBuilder } from '@root/Services/QueryExpr';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { TagAutomationRuleActivityModel } from './Model';

export function ActivityGrid({ rule, dateRange }: { rule: TagAutomationRule; dateRange: { from?: Date; to?: Date } }) {
    const theme = useMantineTheme();
    const model = useDi(TagAutomationRuleActivityModel);
    const status = useEventValue(model.status);
    const [gridFilters, setGridFilters] = useState<IQueryExpr[]>();

    return (
        <PanelContent>
            <Box>
                <ActivityStats rule={rule} dateRange={dateRange} filters={gridFilters} status={status} />
            </Box>
            <Space h="md" />
            <Divider color={theme.colors.gray[3] as CustomColors} />
            <Space h="md" />
            <PanelBody noPadding>
                <ActivityDataGrid
                    rule={rule}
                    dateRange={dateRange}
                    status={status}
                    onFilter={setGridFilters}
                    onSelect={model.traceSelectionChange.emit}
                    selected={model.traceSelectionChange.value}
                />
            </PanelBody>
        </PanelContent>
    );
}

function ActivityDataGrid({
    rule,
    dateRange,
    status,
    onSelect,
    selected,
    onFilter,
}: {
    rule: TagAutomationRule;
    dateRange: { from?: Date; to?: Date };
    status: string | undefined;
    onSelect: (trace?: TagAutomationTraceByRule) => void;
    selected?: TagAutomationTraceByRule;
    onFilter: (filters: IQueryExpr[]) => void;
}) {
    const [grid, setGrid] = useState<DataGridModel>();
    const fmtSvc = useDi(FormatService);
    const [canUseCredits, setCanUseCredits] = useState<boolean>();
    useEffect(() => {
        getCreditsGetCreditSummary().then((o) => setCanUseCredits(o.CanUseCredits ?? false));
    }, []);

    const datasource = useMemo<DataSourceConfig<TagAutomationTraceByRule>>(
        () => async (query: Query) => {
            const updatedQuery = queryBuilder<Omit<TagAutomationTraceByRule, 'Timestamp'> & { Timestamp: Date }>()
                .skip(query.Skip!)
                .take(query.Take!)
                .build();

            updatedQuery.Where = createFilter(query.Where, rule.Id!, dateRange, status);

            updatedQuery.Sort = [];
            if (query.Sort) {
                updatedQuery.Sort = query.Sort;
            }
            const changesSort = updatedQuery.Sort?.find((s) => s.Expr && 'Field' in s.Expr && s.Expr.Field === 'Changes');
            if (changesSort) {
                updatedQuery.Sort = [
                    { Expr: { Field: 'RequestedChanges' }, Direction: changesSort.Direction },
                    { Expr: { Field: 'CompletedChanges' }, Direction: changesSort.Direction },
                ];
            }
            if (!updatedQuery.Sort.some((s) => s.Expr && 'Field' in s.Expr && s.Expr.Field === 'Timestamp')) {
                updatedQuery.Sort?.push({ Expr: { Field: 'Timestamp' }, Direction: 'Desc' });
            }

            const response = await postTagAutomationQueryTraceByRule(updatedQuery);
            return response;
        },
        [dateRange.from, dateRange.to, status]
    );
    const columns = useMemo(() => {
        const results = [
            {
                accessor: 'Timestamp',
                header: 'Timestamp',
                id: 'Timestamp',
                defaultWidth: 160,
                cellRenderer: (item) => <>{fmtSvc.formatDatetimeNoSecs(fmtSvc.toLocalDate(item.Timestamp))}</>,
                filter: false,
                noRemove: true,
                sortField: 'Timestamp',
            },
            {
                accessor: 'ResourceId',
                header: 'Resource',
                id: 'ResourceId',
                noSort: true,
                defaultWidth: 200,
                noRemove: true,
                cellRenderer: (item) => (
                    <GridFullCell>
                        <Text sx={{ lineHeight: '25px' }} weight="bolder">
                            {item.ResourceType}
                        </Text>
                        <Text sx={{ lineHeight: '25px' }}>{fmtSvc.awsIdToName(item.ResourceId ?? '')}</Text>
                    </GridFullCell>
                ),
                filter: {
                    filterType: 'string',
                    name: 'Resource Type',
                    filterField: 'ResourceType',
                    options: {
                        getValueProvider: () => {
                            const results = queryBuilder<TagAutomationTraceByRule>()
                                .where((b) => b.model.RuleId!.eq(rule.Id!))
                                .select((b) => ({ type: b.model.ResourceType!, count: b.count() }))
                                .execute(postTagAutomationQueryTraceByRule)
                                .then((o) => (o.Results ?? []).sort((a, b) => a?.type?.localeCompare(b?.type ?? '') ?? 0).map((b) => b.type));
                            return async (filter: string) => {
                                const lcFilter = filter?.toLocaleLowerCase() ?? '';
                                const items = await results;
                                return lcFilter ? items.filter((o) => o?.toLocaleLowerCase().includes(lcFilter)) : items;
                            };
                        },
                    },
                },
            },
            {
                accessor: 'Mode',
                header: 'Rule Status',
                id: 'Mode',
                defaultWidth: 120,
                filter: {
                    filterType: 'string',
                    name: 'Rule Status',
                    filterField: 'Mode',
                    options: {
                        getValueProvider: () => [
                            { label: 'Test', value: 'Test' },
                            { label: 'Live', value: 'Run' },
                        ],
                    },
                },
                noRemove: true,
                defaultHidden: true,
            },
            {
                accessor: 'RequestedChanges',
                header: 'Requested Changes',
                id: 'RequestedChanges',
                defaultWidth: 120,
                align: 'right',
                filter: {
                    filterType: 'number',
                    name: 'Requested Changes',
                    filterField: 'RequestedChanges',
                },
                noRemove: true,
                defaultHidden: true,
            },
            {
                accessor: 'CompletedChanges',
                header: 'Completed Changes',
                id: 'CompletedChanges',
                defaultWidth: 120,
                align: 'right',
                filter: {
                    filterType: 'number',
                    name: 'Completed Changes',
                    filterField: 'CompletedChanges',
                },
                noRemove: true,
                defaultHidden: true,
            },
            {
                accessor: 'Changes',
                header: 'Tag Changes',
                helpText: 'Number of tag changes made. Completed / Requested',
                id: 'Changes',
                defaultWidth: 120,
                align: 'center',
                cellRenderer: (item) => (
                    <>
                        {fmtSvc.formatInt(item.CompletedChanges ?? 0)} / {fmtSvc.formatInt(item.RequestedChanges ?? 0)}
                    </>
                ),
                noRemove: true,
                sortField: 'Changes',
            },
            {
                accessor: 'Issues',
                header: 'Issues',
                id: 'Issues',
                defaultWidth: 130,
                noSort: true,
                align: 'center',
                cellRenderer: (item) =>
                    item.Status === 'CreditsExhausted' ? (
                        'Credits Exhausted'
                    ) : item.Status === 'CycleDetected' ? (
                        'Automation Loop'
                    ) : item.Status === 'Fail' ? (
                        'Unknown Failure'
                    ) : item.JobStatus === 'Failed' ? (
                        'Tag Failure'
                    ) : (
                        <Text sx={{ lineHeight: '50px' }} italic color="dimmed">
                            None
                        </Text>
                    ),
                noRemove: true,
                filter: {
                    filterType: 'string',
                    name: 'Issues',
                    filterField: 'Issues',
                    options: {
                        getValueProvider: () => [
                            { label: 'Automation Loop', value: 'CycleDetected' },
                            { label: 'Credits Exhausted', value: 'CreditsExhausted' },
                            { label: 'Tag Failure', value: 'Failed' },
                            { label: 'Unknown Failure', value: 'Fail' },
                        ],
                    },
                },
            },
            {
                accessor: 'RuleInteractions',
                header: 'Interactions',
                id: 'RuleInteractions',
                helpText: 'Number of other rules that contributed to the changes made by this rule.',
                defaultWidth: 110,
                align: 'center',
                cellRenderer: (item) => fmtSvc.formatInt(item.RuleInteractions ?? 0),
                noRemove: true,
                sortField: 'RuleInteractions',
                filter: {
                    filterType: 'number',
                    name: 'Interactions',
                    filterField: 'RuleInteractions',
                },
            },
        ] as ColumnConfig<TagAutomationTraceByRule>[];
        canUseCredits
            ? results.push({
                  accessor: 'CompletedChanges',
                  header: 'Credits Used',
                  id: 'Credits Used',
                  defaultWidth: 120,
                  align: 'center',
                  cellRenderer: (item) => fmtSvc.formatInt(item.CompletedChanges ?? 0),
                  noRemove: true,
                  sortField: 'CompletedChanges',
                  filter: {
                      filterType: 'number',
                      name: 'Credits Used',
                      filterField: 'CompletedChanges',
                  },
              })
            : null;
        return results;
    }, [canUseCredits]);

    return canUseCredits !== undefined ? (
        <DataGrid
            columns={columns}
            onModelLoaded={setGrid}
            dataSource={datasource}
            itemHeight={50}
            onRowClick={onSelect}
            onFilterAdded={onFilter}
            hideGlobalSearch
            hideMenu
            hideHeader
            selectionMode="single"
            selection={selected}
            onSelectionChanged={onSelect}
        />
    ) : (
        <></>
    );
}

type Stats = { requested?: number; completed?: number; resources?: number; count?: number };
function ActivityStats({
    rule,
    dateRange,
    filters,
    status,
}: {
    rule: TagAutomationRule;
    dateRange: { from?: Date; to?: Date };
    filters?: IQueryExpr[];
    status?: string;
}) {
    const theme = useMantineTheme();
    const [loading, setLoading] = useState(false);
    const [stats, setStats] = useState<Stats>({});

    const loadStats = async (
        ruleId: number | undefined,
        dateRange: { from?: Date; to?: Date },
        filters: IQueryExpr[] | undefined,
        status?: string
    ) => {
        if (ruleId) {
            setLoading(true);
            try {
                const filter = createFilter(filters?.length ? { Operation: 'and', Operands: filters } : undefined, ruleId, dateRange, status);
                const query = queryBuilder<TagAutomationTraceByRule>()
                    .select((b) => ({
                        requested: b.sum(b.model.RequestedChanges!),
                        completed: b.sum(b.model.CompletedChanges!),
                        resources: b.countUniqueValues(b.model.UniqueResource!),
                        count: b.count(),
                    }))
                    .build();
                query.Where = filter;

                const result = (await postTagAutomationQueryTraceByRule(query)) as QueryResult<Stats>;
                setStats(result.Results?.[0] ?? {});
            } finally {
                setLoading(false);
            }
        }
    };
    useEffect(() => {
        loadStats(rule.Id, dateRange, filters, status);
    }, [dateRange?.from, dateRange.to, filters, status, rule.Id]);

    return (
        <>
            <StatsPanel>
                <StatsPanelItem label="Activity" value={stats.count} loading={loading} helpText="Number of times the rule ran" />
                <Divider orientation="vertical" color={theme.colors.gray[3] as CustomColors} />
                <StatsPanelItem
                    label="Tag Change Attempts"
                    value={stats.requested}
                    loading={loading}
                    helpText={
                        <>
                            Tag changes attempted by the rule.
                            <br /> When rule is active, each change may use a credit
                        </>
                    }
                />
                <Divider orientation="vertical" color={theme.colors.gray[3] as CustomColors} />
                <StatsPanelItem
                    label="Completed Changes"
                    value={stats.completed}
                    loading={loading}
                    helpText={
                        <>
                            Tags changes applied by the rule.
                            <br /> Each completed change charges a credit
                        </>
                    }
                />
                <Divider orientation="vertical" color={theme.colors.gray[3] as CustomColors} />
                <StatsPanelItem label="Resources" value={stats.resources} loading={loading} helpText="Number of resources targeted by the rule" />
            </StatsPanel>
        </>
    );
}

function StatsPanelItem({ loading, label, value, helpText }: { loading: boolean; label: string; value?: number; helpText?: ReactNode }) {
    const fmtSvc = useDi(FormatService);
    const text = typeof value === 'number' ? fmtSvc.formatInt(value) : '-';
    return <Score value={text} title={label} loading={loading} helpText={helpText} />;
}

const StatsPanel = styled.div`
    display: grid;
    grid-template-columns: 25% 1px 25% 1px 25% 1px 25%;
`;

const createFilter = (gridFilter: undefined | IQueryExpr, ruleId: number, dateRange: { from?: Date; to?: Date }, status: string | undefined) => {
    let result = queryBuilder<Omit<TagAutomationTraceByRule, 'Timestamp'> & { Timestamp: Date }>()
        .where((b) => {
            const filters: IFluentOperators<boolean>[] = [];
            if (dateRange.from) {
                filters.push(b.model.Timestamp!.onOrAfter(dateRange.from));
            }
            if (dateRange.to) {
                filters.push(b.model.Timestamp!.before(dateRange.to));
            }
            const failCrit = b.or(b.model.Status!.eq(['Fail', 'CreditsExhausted', 'CycleDetected']), b.model.JobStatus!.eq(['Failed', 'Canceled']));
            if (status === 'success') {
                filters.push(b.model.Mode!.ne('Test'), b.not(failCrit));
            } else if (status === 'fail') {
                filters.push(b.model.Mode!.ne('Test'), failCrit);
            } else if (status === 'test') {
                filters.push(b.model.Mode!.eq('Test'), b.model.Status!.eq('Sent'));
            } else if (status === 'testIssues') {
                filters.push(b.model.Mode!.eq('Test'), b.model.Status!.ne('Sent'));
            }
            return b.and(b.model.RuleId!.eq(ruleId!), ...filters);
        })
        .build().Where;

    if (gridFilter) {
        const clone = copyExpr(gridFilter as QueryExpr);
        for (const node of TreeQuery.init([clone], (o) => ('Operands' in o ? o.Operands : undefined))) {
            if ('Field' in node.item && node.item.Field === 'Issues') {
                const valueExpr = node.parent!.children.find((o) => 'Value' in o.item)?.item;
                if (valueExpr && node.parent?.item.Operands) {
                    const operation = node.parent.item.Operation;
                    node.parent.item.Operation = 'or';
                    node.parent.item.Operands = [
                        {
                            Operation: operation,
                            Operands: [{ Field: 'Status' }, valueExpr],
                        },
                        {
                            Operation: operation,
                            Operands: [{ Field: 'JobStatus' }, valueExpr],
                        },
                    ];
                }
            }
        }
        result = { Operation: 'and', Operands: [result, clone] };
    }

    return result;
};

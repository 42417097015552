import { Button, Loader } from '@mantine/core';
import { colorPalette } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { PollingService } from '@root/Services/Jobs/PollingService';
import { useState } from 'react';

type HubSpotConversationsEvents = 'widgetClosed' | 'conversationClosed';
declare global {
    interface Window {
        /**
         * HubSpot Conversations API
         * https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
         */
        HubSpotConversations: {
            widget: {
                load: (options: { widgetOpen: boolean }) => void;
                open: () => void;
                close: () => void;
                remove: () => void;
            };
            on: (event: HubSpotConversationsEvents, callback: (payload: any) => void) => void;
            off: (event: HubSpotConversationsEvents, callback: (payload: any) => void) => void;
        };
    }
}

export function useSupportChat() {
    const [loadingChat, setLoadingChat] = useState(false);
    const pollingSvc = useDi(PollingService);
    const loadChat = async () => {
        setLoadingChat(true);
        try {
            const onClose = () => {
                window.HubSpotConversations.widget.remove();
                window.HubSpotConversations.off('widgetClosed', onClose);
            };
            window.HubSpotConversations.widget.load({ widgetOpen: true });
            window.HubSpotConversations.on('widgetClosed', onClose);

            await pollingSvc.pollUntil(
                async () => {},
                () => !!document.querySelector('[aria-label="Chat Widget"]')
            );

            window.HubSpotConversations.widget.open();
        } finally {
            setLoadingChat(false);
        }
    };

    return { loadingChat, loadChat };
}

export function SupportChatButton({ text = 'Contact Support' }: { text?: string }) {
    const { loadingChat, loadChat } = useSupportChat();
    return (
        <Button leftIcon={loadingChat ? <Loader color={colorPalette.fffColor} size="sm" /> : undefined} onClick={loadChat}>
            {text}
        </Button>
    );
}

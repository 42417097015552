import { MapContract } from '@apis/TagManager/model';
import styled from '@emotion/styled';
import { Anchor, Box, Button, Center, Divider, Loader, LoadingOverlay, Popover, Space, Text, Title, useMantineTheme } from '@mantine/core';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { PageContent } from '@root/Design/Layout';
import { useNav } from '@root/Services/NavigationService';
import { useCallback, useEffect, useState } from 'react';
import { useDi } from '@root/Services/DI';
import { useLink } from '@root/Services/Router/Router';
import { useDisclosure } from '@mantine/hooks';
import { getMapContractsGetMapContractById, getMapContractsGetMapContracts } from '@apis/TagManager';
import { ChevronDown, ChevronRight } from 'tabler-icons-react';
import { Picker } from '@root/Components/Picker/Picker';
import { MapMonitoringHomeModel } from './MapMonitoringHome';
import { useEventValue } from '@root/Services/EventEmitter';
import { ProgressSection } from './ProgressSection';
import { TaggingSection } from './TaggingSection';
import { WorkloadSection } from './WorkloadSection';
import { MapSplashBg } from '../../MapContracts/Design';
import { endpoint } from '@root/Services/Router/EndpointRegistry';

export function MapMonitoring() {
    const company = useCompany();
    const theme = useMantineTheme();
    const homeModel = useDi(MapMonitoringHomeModel);
    const loading = useEventValue(homeModel.loading);
    const { getData } = useNav();
    const { getMoveUrl } = useNav();
    const link = useLink();
    const { contractId, section, noContracts } = getData('contractId', 'section', 'noContracts');
    useEffect(() => {
        if (contractId !== undefined && contractId !== null) {
            homeModel.init(contractId);
        }
    }, [contractId]);

    return (
        <PageContent>
            {noContracts && (
                <PageContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <Box sx={{ maxWidth: 1400 }} px={70} py={50}>
                            <Space h="md" />
                            <Center>
                                <Box>
                                    <Center>
                                        <Title order={2}>Monitor Your AWS MAP Contracts</Title>
                                    </Center>

                                    <Text>Monitoring will be available once a MAP Contract has been added.</Text>
                                </Box>
                            </Center>
                            <Center>
                                <Box>
                                    <Space h="md" />

                                    <Button component="a" {...link(getMoveUrl('map-contracts'))} rightIcon={<ChevronRight />}>
                                        MAP Contracts
                                    </Button>
                                </Box>
                            </Center>
                        </Box>
                        <MapSplashBg />
                    </Box>
                </PageContent>
            )}
            {!noContracts && loading && (
                <Box sx={{ minWidth: '100%', position: 'relative', height: '400px' }}>
                    <LoadingOverlay overlayOpacity={0} visible />
                </Box>
            )}
            {!noContracts && !loading && (
                <>
                    <MonitoringHeader>
                        <CompanyTitle>{company?.CompanyName}</CompanyTitle>
                        <HeaderPanelGroup>
                            <MonitoringMenu />
                        </HeaderPanelGroup>
                    </MonitoringHeader>
                    <Divider />
                    <MonitoringBody>
                        {!section || section === 'progress' ? <ProgressSection homeModel={homeModel}></ProgressSection> : null}
                        {section === 'tagging' && <TaggingSection homeModel={homeModel}></TaggingSection>}
                        {section === 'workloads' && <WorkloadSection homeModel={homeModel}></WorkloadSection>}
                        {section === 'behavior' && <div>Add behavior Component</div>}
                    </MonitoringBody>
                </>
            )}
        </PageContent>
    );
}

function MonitoringMenu() {
    const theme = useMantineTheme();
    const { getData, getDataUrl } = useNav();
    const link = useLink();
    const { contractId, section } = getData('contractId', 'section');
    const sections = [
        {
            section: 'progress',
            label: 'Progress',
        },
        {
            section: 'workloads',
            label: 'Workloads',
        },
        {
            section: 'tagging',
            label: 'Tagging',
        },
        // {
        //     section: 'behavior',
        //     label: 'Behavior',
        // },
    ];
    return (
        <>
            {sections.map(({ ...s }) => {
                return (
                    <SimpleMenuItem
                        key={s.section}
                        mode={s.section === section ? 'active' : 'inactive'}
                        {...link(getDataUrl({ contractId: contractId!, section: s.section }))}
                    >
                        <span data-atid={'MonitoringTab:' + s.label}>{s.label}</span>
                    </SimpleMenuItem>
                );
            })}
        </>
    );
}

function ManageContractsBreadCrumb() {
    const { getData } = useNav();
    const { contractId: rawId } = getData('contractId');
    const id = rawId ? parseInt(rawId) : null;
    const [contract, setContract] = useState<MapContract | null | undefined>();
    const [pickerOpen, { close, open, toggle }] = useDisclosure(false);
    const [contracts, setContracts] = useState<MapContract[] | null>();
    const { goto, getMoveUrl } = useNav();

    useEffect(() => {
        getMapContractsGetMapContracts().then((contracts) => {
            if (contracts.length === 0) {
                setContracts(null);
            } else setContracts(contracts);
        });
    }, []);

    useEffect(() => {
        if (id) {
            getMapContractsGetMapContractById({ id: id }).then(setContract);
        } else {
            if (contracts !== undefined) {
                //this is where we set the default contract, currently its just the first one we load
                const url =
                    contracts && contracts.length > 0
                        ? getUrl(contracts![0].Id?.toString() ?? '', getMoveUrl)
                        : contracts === null
                        ? getNoContractUrl(getMoveUrl)
                        : null;
                if (url) {
                    goto(url);
                }
            }
        }
    }, [id, contracts]);
    return (
        <>
            <Popover opened={pickerOpen} position="bottom-start" closeOnClickOutside onClose={close} shadow="lg" withArrow withinPortal>
                <Popover.Target>
                    <Anchor sx={{ display: 'flex' }} onClick={toggle}>
                        <Text>{contract === undefined ? 'Loading' : contract === null ? 'Invalid' : contract.Name}</Text>
                        <ChevronDown />
                    </Anchor>
                </Popover.Target>
                <Popover.Dropdown p={0}>{pickerOpen ? <ContractPicker contracts={contracts ?? []} onClose={close} /> : null}</Popover.Dropdown>
            </Popover>
        </>
    );
}

function ContractPicker({ contracts, onClose }: { contracts: MapContract[]; onClose: () => void }) {
    const { goto, getMoveUrl } = useNav();
    const onSelect = useCallback(async (contracts: MapContract[]) => {
        const url = contracts[0] ? getUrl(contracts[0].Id?.toString() ?? '', getMoveUrl) : null;
        if (url) {
            goto(url);
        }
        onClose();
    }, []);

    return (
        <PickerContainer>
            <Picker
                items={contracts ?? []}
                mode="single"
                selections={[]}
                minimizeHeight
                height={400}
                width={400}
                renderItem={(item) => {
                    return (
                        <ContractPickerItem>
                            <Text color="primary" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {item.Name}
                            </Text>
                        </ContractPickerItem>
                    );
                }}
                nameAccessor={(c) => c.Name ?? ''}
                onChange={onSelect}
            />
            {contracts === undefined ? (
                <Center my="lg">
                    <Loader />
                </Center>
            ) : null}
        </PickerContainer>
    );
}

const getUrl = (contractId: string, getMoveUrl: (name: string, params: Record<string, string> | undefined) => string) => {
    return getMoveUrl('map-monitoring', { contractId: contractId, section: 'progress' });
};

const getNoContractUrl = (getMoveUrl: (name: string, params: Record<string, string> | undefined) => string) => {
    return getMoveUrl('map-monitoring', { noContracts: 'true' });
};

const PickerContainer = styled.div`
    .picker-item {
        border-bottom: solid 1px ${(p) => p.theme.colors.gray[3]};
        padding: 0;
        cursor: pointer;
        > div {
            border-radius: 0;
            padding: 0;
            height: 100%;
        }
        :hover > div {
            background: ${(p) => p.theme.colors.primary[1]};
        }
    }
    .picker-item-text {
        flex: 1;
    }
`;
const ContractPickerItem = styled.div`
    display: grid;
    grid-template-columns: auto 100px;
    width: 100%;
    .state {
        justify-self: center;
        display: flex;
        align-items: center;
    }
`;

const MonitoringHeader = styled.div`
    height: 60px;
    background: #fff;
`;
const MonitoringBody = styled.div`
    height: 100px;
`;
const CompanyTitle = styled.div`
    float: left;
    color: ${(props) => props.theme.colors.primary[7]};
    margin: 10px 20px;
    font-size: 24px;
    font-weight: 900;
    position: relative;
    display: flex;
`;
const HeaderPanelGroup = styled.div`
    float: right;
`;
// background: ${({ theme, mode }) => (mode === 'active' ? theme.colors.primary[1] : 'transparent')};
const SimpleMenuItem = styled.a<{ mode: 'active' | 'inactive' }>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
    font-size: 14px;
    float: left;
    margin: 16px;
    padding: 10px;
    border-bottom: 2px solid ${({ theme, mode }) => (mode === 'active' ? theme.colors.primary[6] : 'transparent')};
    color: ${({ theme }) => theme.colors.primary[6]};
    font-weight: 900;
    text-decoration: none;
`;

endpoint('map-monitoring', MapMonitoring, ManageContractsBreadCrumb);

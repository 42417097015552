/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.TagManager.Host.Api
 * OpenAPI spec version: 1.0
 */

export type MapSupportedServiceTargetType = typeof MapSupportedServiceTargetType[keyof typeof MapSupportedServiceTargetType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MapSupportedServiceTargetType = {
  Compute: 'Compute',
  DBA: 'DBA',
  OracleSap: 'OracleSap',
} as const;

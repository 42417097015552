import {
    ActionIcon,
    Alert,
    Box,
    Button,
    Checkbox,
    Divider,
    Drawer,
    Grid,
    Group,
    Loader,
    LoadingOverlay,
    Menu,
    Skeleton,
    Space,
    Switch,
    Text,
    Tooltip,
} from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { DiContext, useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { useId } from '@root/Services/IdGen';
import { CSSProperties, forwardRef, Fragment, MouseEventHandler, ReactNode, Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ITreeItemState, ITypedTreeConfig, VirtualTree } from '../VirtualTree';
import { Node } from '../VirtualTree/Node';
import { DataGridColumn, DataGridModel, GridColumnSet } from './DataGridModel';
import {
    FixedColumnSet,
    GridBodyContainer,
    GridCell,
    GridContainer,
    GridExpander,
    GridFooterContainer,
    GridHeaderCell,
    GridHeaderCellResizer,
    GridHeaderCellText,
    GridHeaderContainerFixed,
    GridHeaderContainerScroll,
    GridHeaderGroupCell,
    GridHeaderSetContainer,
    GridMenuItem,
    GridReorderableContainer,
    GridRow,
    GridTitleContainer,
    GridToolbar,
    HeaderContainer,
    ListBodyContainer,
    ListHeaderCell,
    ListHeaderCellContainerEl,
    ListHeaderCellText,
    ListHeaderContainer,
    ListHeaderSetContainer,
    ScrollingColumnSet,
} from './Design';
import { AnonymousValue, ColumnConfig, DataColumnConfig, DataGridProps, DataSourceConfig, IDataSource } from './Models';
import { ColumnSelector, IColumnSelectorOption } from './ColumnSelector';
import { AlertTriangle, ChevronDown, EyeOff, MathFunction } from 'tabler-icons-react';
import { DataGridFilters } from './Filters';
import { SchemaService } from '@root/Services/QueryExpr';
import { FormatService } from '@root/Services/FormatService';
import { LayoutService, useScrollBarDimensions } from '@root/Design/Layout';
import { colorPalette, theme } from '@root/Design/Themes';
import { closeModal, useModals } from '@mantine/modals';
import { BaseResource, IQueryExpr } from '@apis/Resources/model';
import { GridSavedViews } from './GridSavedViews';

export const TreeItemStateToken = 'DataGridTreeItemState';

export function DataGrid<T extends object>(props: DataGridProps<T>) {
    const [el, setEl] = useState<HTMLElement | null>(null);
    const container = useDiContainer();

    const model = useMemo(() => {
        const result = container.resolve(DataGridModel);
        if (props.schemaSvc) {
            result.loadSchema(props.schemaSvc);
        }
        result.customColumns = props.customColumns;
        props.onModelLoaded?.(result);
        return result;
    }, []);
    useEvent(model.viewInvalidated);
    useEvent(model.selectionChanged, () => {
        if (props.selectionMode === 'multiple' && props.onSelectedChanged) {
            props.onSelectedChanged({ getItems: model.getSelectedItems });
        } else if (props.selectionMode === 'single' && props.onSelectionChanged) {
            model.getSelectedItems().then((items) => {
                if (items[0] !== props.selection) {
                    props.onSelectionChanged?.(items[0]);
                }
            });
        }
    });
    useEvent(model.gridStateChanged, (change) => (change ? props.onStateChanged?.(change.state) : null));
    useEffect(() => {
        if (props.selectionMode === 'single') {
            if (props.selection) {
                model.select(props.selection);
            } else {
                model.setSelectAll(false);
            }
        }
    }, [props.selectionMode === 'single' ? props.selection : undefined]);
    const onScroll = useCallback(
        (left: number, top: number[]) => {
            model.scrollPos = { top, left };
            model.scrollPosChanged.emit({ top, left });
            if (el) {
                el.style.setProperty('--grid-horz-scroll', left + 'px');
                el.style.setProperty('--grid-horz-scroll-neg', -left + 'px');
                el.style.setProperty('--grid-fixed-shadow', left === 0 ? '0' : '6px');
            }
        },
        [el, model]
    );
    useEffect(() => {
        if (el) {
            onScroll(model.scrollPos.left, model.scrollPos.top);
            el.style.setProperty('--grid-item-height', model.itemHeight + 'px');
            el.style.setProperty('--grid-header-row-group-height', 30 + 'px');
            el.style.setProperty('--grid-header-row-height', (props.headerHeight ? props.headerHeight : 30) + 'px');
            el.style.setProperty('--grid-header-height', (props.headerHeight ? props.headerHeight : 30) + (model.hasGroups ? 30 : 0) + 'px');
        }
    }, [el, model.itemHeight, model.hasGroups]);
    useEffect(() => {
        model.load(props);
    }, [props.columns, props.dataSource, props.state]);
    const BodyContainer = model.displayMode === 'list' ? ListBodyContainer : GridBodyContainer;
    const scrollController = useScrollController(200);

    return (
        <GridContainer ref={setEl}>
            {props.hideFilter ? (
                props.showToolbar ? (
                    <>
                        <GridToolbar>
                            <Group spacing={0}>
                                {props.leftTopPlaceHolder}
                                {props.filterRightPlaceHolder}
                            </Group>
                            {props.rightTopPlaceHolder}
                        </GridToolbar>
                        <Space h={10}></Space>
                    </>
                ) : null
            ) : (
                <>
                    <GridToolbar>
                        <Group spacing={0} sx={{ flex: 1 }}>
                            {props.leftTopPlaceHolder}
                            <DataGridFilters hideFilters={props.hideFilterOnly} leftFilterPlaceHolder={props.leftFilterPlaceHolder} grid={model} />
                            {props.filterRightPlaceHolder}
                        </Group>
                        <Group spacing={0}>
                            {props.rightToolPlaceHolder}
                            {props.allowSavedViews ? <GridSavedViews grid={model} /> : null}
                        </Group>
                        {props.rightTopPlaceHolder}
                        {props.hideMenu === false && props.hideHeader ? (
                            <>
                                <DataGridHeaderMenu model={model} />
                                <Space w="xs" />
                            </>
                        ) : null}
                    </GridToolbar>
                    <Space h={10}></Space>
                </>
            )}

            <HeaderContainer mode={model.displayMode}>
                {props.hideHeader ? null : (
                    <Group position="apart" px={20} py={5}>
                        <Group position="left" spacing={6}>
                            {props.renderStats?.(model)}
                            {props.showCount !== false ? <ItemCount itemCount={model.itemCount} /> : null}
                            {(props.renderStats || props.showCount) && props.showNumSelected ? <Text color="dimmed">|</Text> : null}
                            <Box>
                                {props.showNumSelected ? (
                                    <Text color="dimmed" data-atid={'DataGridSelected:' + model.selections.count()}>
                                        {model.selections.count()} selected
                                    </Text>
                                ) : null}
                            </Box>
                            <Box>
                                {props.showShowOnlySelected && props.showNumSelected && model.selections.count() > 0 ? (
                                    <Switch onChange={(event) => model.showOnlySelected(event.currentTarget.checked)} label="Show Only Selected" />
                                ) : null}
                            </Box>
                            {props.leftResultsPlaceHolder}
                        </Group>

                        <Group spacing="xs">
                            {props.showRefresh ? <RefreshIcon onRefreshing={props.onRefreshing} model={model} /> : null}
                            {props.hideMenu ? null : <DataGridHeaderMenu model={model} />}
                        </Group>
                    </Group>
                )}
                {props.gridTitle ? props.gridTitle : null}
                <DataGridHeader
                    hideHeader={!!props.hideHeader}
                    model={model}
                    showHeaderGroups={props.showHeaderGroups ?? false}
                    headerHeight={props.headerHeight ?? 0}
                />
            </HeaderContainer>

            {props.renderFooter && (props.footerPosition === 'top' || props.footerPosition === 'both') ? (
                <GridFooterContainer position="top">
                    <DataGridFooter model={model} />
                </GridFooterContainer>
            ) : null}

            {!props.splitBodyProps ? (
                <BodyContainer mode={!props.renderFooter ? 'none' : props.footerPosition !== 'top' ? 'footer' : 'none'}>
                    <DataGridTreeBody<T>
                        model={model}
                        bodyIndex={0}
                        noScroll={!!props.splitBodyProps}
                        onScroll={onScroll}
                        scrollController={scrollController}
                    />
                </BodyContainer>
            ) : null}
            {props.splitBodyProps
                ? props.splitBodyProps.map((splitBody, i) =>
                      splitBody.renderer ? (
                          splitBody.renderer(model)
                      ) : (
                          <BodyContainer
                              mode={!props.renderFooter ? 'none' : props.footerPosition !== 'top' ? 'footer' : 'none'}
                              height={splitBody.height}
                              radius={i === props.splitBodyProps!.length - 1 ? undefined : 0}
                              style={{ ...splitBody.style }}
                          >
                              <DataGridTreeBody<T>
                                  bodyIndex={i + 1}
                                  model={model}
                                  onScroll={onScroll}
                                  dataSource={splitBody.datasource}
                                  noScroll={!splitBody.scrollable ?? true}
                                  scrollController={scrollController}
                              />
                          </BodyContainer>
                      )
                  )
                : null}
            {props.renderFooter && (!props.footerPosition || props.footerPosition === 'bottom' || props.footerPosition === 'both') ? (
                <GridFooterContainer position="bottom">
                    <DataGridFooter model={model} />
                </GridFooterContainer>
            ) : null}
        </GridContainer>
    );
}

function ItemCount({ itemCount }: { itemCount: EventEmitter<number> }) {
    const count = useEventValue(itemCount);
    const fmtSvc = useDi(FormatService);
    return (
        <Text data-atid={'DataGridResults:' + count?.toString()} color="dimmed">
            {fmtSvc.formatInt(count ?? 0)} result{count == 1 ? '' : 's'}
        </Text>
    );
}

function RefreshIcon({ model, onRefreshing }: { model: DataGridModel; onRefreshing?: () => void }) {
    const [refreshing, setRefreshing] = useState(false);
    const refresh = useCallback(async () => {
        setRefreshing(true);
        try {
            if (onRefreshing) {
                await onRefreshing();
            }
            await model.refresh();
        } finally {
            setRefreshing(false);
        }
    }, [model]);
    return (
        <Tooltip label="Refresh">
            <ActionIcon
                onClick={() => {
                    !refreshing ? refresh() : null;
                }}
                data-atid="RefreshGridButton"
            >
                <i className={`ti ti-refresh ${refreshing ? 'ti-spin' : ''}`}></i>
            </ActionIcon>
        </Tooltip>
    );
}

function RevertToDefault({ model, id }: { model: DataGridModel; id: string }) {
    return (
        <Box>
            <Text>Are you sure you want to reset to the default filter, group, and column configuration?</Text>
            <Space h="md" />
            {model.currentOptions?.allowSavedViews ? (
                <Alert color="warning" variant="light" icon={<AlertTriangle />}>
                    The saved view <strong>Default</strong> will be overwritten with defaults settings, or a new Default saved view will be created if
                    none exist.
                </Alert>
            ) : null}
            <Space h={15} />
            <Group position="right">
                <Button
                    onClick={() => {
                        model.revert();
                        closeModal(id);
                    }}
                    data-atid="RevertToDefaultConfirmButton"
                >
                    Reset
                </Button>
                <Button
                    variant="outline"
                    onClick={() => {
                        closeModal(id);
                    }}
                    data-atid="KeepCurrentSettingsButton"
                >
                    Keep Current Settings
                </Button>
            </Group>
        </Box>
    );
}

export function DataGridHeaderMenu({ model }: { model: DataGridModel }) {
    const [selectorOpen, setSelectorOpen] = useState(false);
    const canSelectColumn = model.displayMode !== 'list' && !model.currentOptions?.hideHeader && !model.currentOptions?.hideColumnSelector;
    const canClearFilters = !model.currentOptions?.hideFilter && !model.currentOptions?.hideFilterOnly;
    const [opened, { open, close }] = useDisclosure(false);
    const canExport = model.canExport;
    const exportUnavailable = model.currentOptions?.export === 'unavailable';

    const modals = useModals();
    const openRevertToDefaultModal = () => {
        const id = modals.openModal({
            zIndex: 500,
            title: (
                <Text size={18} weight={600} color={colorPalette.darkTitleColor}>
                    Revert to Default
                </Text>
            ),
            children: <RevertToDefault model={model} id="revertToDefault" />,
            sx: { borderRadius: theme.radius?.lg, padding: '32px' },
        });
    };

    return (
        <>
            <Menu withArrow withinPortal position="bottom-end" onOpen={open} onClose={close} width={200} shadow="md">
                <Menu.Target>
                    <ActionIcon variant="transparent" my={5} data-atid="ModifyColumnsHamburger">
                        <i className="ti ti-menu-2"></i>
                    </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                    {canSelectColumn ? (
                        <Menu.Item icon={<i className="ti ti-list"></i>} onClick={() => setSelectorOpen(true)} data-atid="SelectColumnsButton">
                            Select Columns
                        </Menu.Item>
                    ) : null}
                    {!canClearFilters ? null : (
                        <Menu.Item icon={<i className="ti ti-filter-off"></i>} onClick={() => model.clearFilters()} data-atid="ClearAllFiltersButton">
                            Clear All Filters
                        </Menu.Item>
                    )}
                    {!model.currentOptions?.allowGroupBy || !!model.currentOptions?.groupByRequired ? (
                        <></>
                    ) : (
                        <Menu.Item
                            disabled={!model.hasGroupBy()}
                            icon={<i className={'ti ti-list'}></i>}
                            onClick={() => model.clearGroupBy()}
                            data-atid="ClearAllGroupsButton"
                        >
                            Clear All Groups
                        </Menu.Item>
                    )}

                    {canSelectColumn || canClearFilters || !(!model.currentOptions?.allowGroupBy || !!model.currentOptions?.groupByRequired) ? (
                        <Menu.Item
                            disabled={!opened || !model.canRevert().length}
                            icon={<i className="ti ti-rotate-2"></i>}
                            onClick={() => openRevertToDefaultModal()}
                            data-atid="RevertToDefaultButton"
                        >
                            Revert to Default
                        </Menu.Item>
                    ) : null}

                    {canExport ? (
                        <Tooltip disabled={!exportUnavailable} label={exportUnavailable ? 'Export is unavailable. Please contact support.' : ''}>
                            <Menu.Item icon={<i className="ti ti-download"></i>} onClick={model.export} data-atid="ExportGridToExcelButton">
                                Export Grid to Excel
                            </Menu.Item>
                        </Tooltip>
                    ) : null}
                </Menu.Dropdown>
            </Menu>
            <ColumnSelectorDrawer
                model={model}
                schema={model.schemaSvc}
                open={selectorOpen}
                onClose={() => setSelectorOpen(false)}
                customColumns={model.customColumns}
            />
        </>
    );
}

function ColumnSelectorDrawer({
    model,
    open,
    onClose,
    schema,
    customColumns,
}: {
    model: DataGridModel;
    open: boolean;
    onClose: () => void;
    schema?: SchemaService;
    customColumns?: ColumnConfig<BaseResource>[];
}) {
    const selectorOptions = open ? model.getColumnSelectorOptions() : undefined;
    const [blockClose, setBlockClose] = useState(false);
    const applySelections = useCallback(
        (selections: IColumnSelectorOption[]) => {
            const [items, fixed] = selections.reduce(
                (result, item) => {
                    const [items, fixed] = result;
                    if (item.locked) {
                        fixed.add(item.column.id);
                    }
                    items.push(item.column);
                    return result;
                },
                [[] as DataColumnConfig<any>[], new Set<string>()]
            );
            model.applyColumnSelection(items, fixed);
            onClose();
        },
        [model]
    );
    return (
        <Drawer
            onClose={onClose}
            opened={open}
            title={
                <Text size="xl" ml="md" py={10}>
                    Select Columns
                </Text>
            }
            position="right"
            closeOnClickOutside={!blockClose}
            closeOnEscape={!blockClose}
            withCloseButton
            withinPortal
            data-atid="ColumnSelectorDrawer"
        >
            {open ? (
                <ColumnSelector
                    onRender={model.currentOptions?.onRenderColumnSelector}
                    allowPinning={model.currentOptions?.allowPinning}
                    columns={selectorOptions?.options ?? []}
                    selections={selectorOptions?.selections ?? []}
                    defaultGroup={model.getDefaultGroup()}
                    onBlockClose={setBlockClose}
                    onApply={applySelections}
                    onCancel={onClose}
                    schemaFilter={model.currentOptions?.schemaFilter}
                    schema={schema}
                />
            ) : null}
        </Drawer>
    );
}

export function AnonymousValueGridCell(props: { value: AnonymousValue | null; formatter?: (item: any, value: any) => any }) {
    return <>{props.formatter ? props.formatter(undefined, props.value?.first) : props.value?.first}</>;
}

export function GridHeaderSet({
    model,
    set,
    showHeaderGroups,
    headerHeight,
}: {
    model: DataGridModel;
    set: GridColumnSet;
    showHeaderGroups: boolean;
    headerHeight: number;
}) {
    const container = useRef<HTMLDivElement>(null);
    const layoutSvc = useDi(LayoutService);
    const [width, setWidth] = useState<number>(0);
    const Container = model.displayMode === 'list' ? ListHeaderContainer : set.fixed ? GridHeaderContainerFixed : GridHeaderContainerScroll;
    const showCheckbox = set === model.columnSets[0] && model.showCheckbox;
    const styles = {
        [`--grid-set-offset`]: set.offset + 'px',
        [`--grid-set-width`]: set.width + 'px',
        [`--grid-set-visible-width`]: width + 'px',
    } as CSSProperties;
    const updateWidth = useCallback(() => {
        if (container.current) {
            const nextWidth = container.current.clientWidth;
            if (nextWidth > 0) {
                setWidth(nextWidth);
            }
        }
    }, [container.current]);

    useEvent(layoutSvc.windowSizeInvalidated, updateWidth);
    useEffect(updateWidth, [container.current]);
    useEvent(set.reordered);
    useEvent(model.columnSetSizeChanged);
    return (
        <Container ref={container} style={styles} mode={model.columnSets.length > 1 ? 'some-fixed' : undefined}>
            {model.hasGroups ? (
                <>
                    {model.currentOptions?.hideHeader ? null : <Divider />}
                    <div className="groups">
                        {set.groups.map((g, i) => (
                            <GridHeaderGroupCell
                                noOverlay={g.group?.noOverlay ?? false}
                                key={i}
                                className={set.fixed ? 'groups-fixed' : 'groups-scrollable'}
                                style={
                                    {
                                        ['--grid-group-color']: !g.group ? '#0000' : g.group?.color ?? '#8884',
                                        ['--grid-group-width']: g.width + 'px',
                                        ['--grid-group-offset']: g.offset + 'px',
                                        display: showHeaderGroups ? 'block' : 'none',
                                    } as CSSProperties
                                }
                            >
                                {model.currentOptions?.renderColumnGroup?.(g.name!) ?? (
                                    <div>
                                        <span>{g.name}</span>
                                    </div>
                                )}
                            </GridHeaderGroupCell>
                        ))}
                    </div>
                </>
            ) : null}
            <div className="headers" data-atid="GridHeaders">
                {showCheckbox ? <DataGridHeaderCheckbox model={model} /> : null}
                {set.columnInfo.map((c, i) => (
                    <DataGridHeaderCell key={c.column.config.id ?? i} column={c.column} headerHeight={headerHeight} />
                ))}
            </div>
        </Container>
    );
}

export function DataGridHeader({
    model,
    showHeaderGroups,
    hideHeader,
    headerHeight,
}: {
    model: DataGridModel;
    showHeaderGroups: boolean;
    hideHeader: boolean;
    headerHeight: number;
}) {
    useEvent(model.viewInvalidated);
    const Container = model.displayMode === 'list' ? ListHeaderSetContainer : GridHeaderSetContainer;
    return (
        <Container hideHeader={hideHeader && !showHeaderGroups}>
            {model.columnSets.map((s, i) => (
                <GridHeaderSet key={i} model={model} set={s} showHeaderGroups={showHeaderGroups} headerHeight={headerHeight} />
            ))}
        </Container>
    );
}

export function DataGridHeaderCell({ column, headerHeight }: { column: DataGridColumn; headerHeight: number }) {
    const [showMenu, setShowMenu] = useState(false);
    const hasMenu = column.canFilter() || column.canSort();
    const toggleMenu = useCallback(() => {
        if (!column.reordering) {
            setShowMenu(!showMenu);
        }
    }, [column, showMenu, setShowMenu]);
    const close = useCallback(() => setShowMenu(false), [setShowMenu]);
    const [cellRef, setCellRef] = useState<HTMLDivElement | null>(null);
    const [menuRef, setMenuRef] = useState<HTMLDivElement | null>(null);
    useClickOutside(close, undefined, [cellRef!, menuRef!]);
    const Container = column.grid.displayMode === 'grid' ? DataGridReorderableContainer : ListHeaderCellContainer;

    const sortDir = column.getSortDir();
    const filteredBy = column.isFilteredBy();
    const imgMargintop = column.config.headerRenderer ? -2 + 'px' : headerHeight - (headerHeight > 30 ? 30 : 2) + 'px';
    const imgSrc = hasMenu
        ? sortDir === 'Asc' && !filteredBy
            ? '/assets/sort-ascending.svg'
            : sortDir === 'Desc' && !filteredBy
            ? '/assets/sort-descending.svg'
            : sortDir !== 'Asc' && sortDir !== 'Desc' && filteredBy
            ? '/assets/filter-regular.svg'
            : filteredBy && sortDir === 'Asc'
            ? '/assets/filter_ascending.svg'
            : filteredBy && sortDir === 'Desc'
            ? '/assets/filter_descending.svg'
            : null
        : null;

    return (
        <Container column={column}>
            <Menu onClose={() => setShowMenu(false)} opened={showMenu} shadow="lg" withinPortal offset={2} position="bottom-end">
                {column.grid.displayMode === 'grid' ? (
                    <Menu.Target>
                        <GridHeaderCell
                            ref={setCellRef}
                            onMouseDown={column.startReordering}
                            style={{ width: column.width, display: 'block', lineHeight: `var(--grid-header-row-group-height)` }}
                        >
                            <GridHeaderCellText
                                className="header-text"
                                style={{ textAlign: column.align }}
                                onClick={toggleMenu}
                                data-atid={'GridHeaderCell:' + column.header}
                            >
                                {column.renderHeaderCell(headerHeight ? headerHeight : 30, imgSrc != null, showMenu)}

                                {imgSrc ? (
                                    <>
                                        <img
                                            src={imgSrc}
                                            style={{
                                                height: '20px',
                                                width: '20px',
                                                marginTop: imgMargintop,
                                                paddingLeft: 5,
                                            }}
                                            onClick={toggleMenu}
                                            className="opener"
                                        />
                                    </>
                                ) : null}
                            </GridHeaderCellText>
                            {column.config.noResize ? null : <GridHeaderCellResizer onMouseDown={column.startResize} />}
                        </GridHeaderCell>
                    </Menu.Target>
                ) : (
                    <Menu.Target>
                        <ListHeaderCell ref={setCellRef}>
                            <ListHeaderCellText style={{ textAlign: column.align, cursor: hasMenu ? 'pointer' : 'default' }} onClick={toggleMenu}>
                                <Group noWrap>
                                    {column.renderHeaderCell(headerHeight ? headerHeight : 30, imgSrc != null, showMenu)}
                                    {hasMenu ? <ChevronDown style={{ paddingLeft: 5 }} className="opener" /> : null}
                                </Group>
                            </ListHeaderCellText>
                        </ListHeaderCell>
                    </Menu.Target>
                )}
                <Menu.Dropdown>
                    <GridHeaderMenu ref={setMenuRef} column={column} />
                </Menu.Dropdown>
            </Menu>
        </Container>
    );
}

export function DataGridReorderableContainer({ column, children }: { column: DataGridColumn; children: ReactNode }) {
    useEvent(column.getOffest().set.reordering);
    const resizing = useEventValue(column.getOffest().set.resizing);
    return (
        <GridReorderableContainer
            mode={column.reordering ? 'reordering' : resizing ? 'resizing' : 'transition'}
            style={{ left: column.getOffest().offset }}
        >
            {children}
        </GridReorderableContainer>
    );
}

export function ListHeaderCellContainer({ column, children }: { column: DataGridColumn; children: ReactNode }) {
    return <ListHeaderCellContainerEl style={{ width: (column.getWidthPercent() * 100).toFixed(0) + '%' }}>{children}</ListHeaderCellContainerEl>;
}

export const GridHeaderMenu = forwardRef(({ column }: { column: DataGridColumn }, ref: Ref<HTMLDivElement>) => {
    const sortDir = column.getSortDir();
    const sortAsc = useCallback(() => column.applySort(true), [column]);
    const sortDesc = useCallback(() => column.applySort(false), [column]);
    const removeSort = useCallback(() => column.removeSort(), [column]);
    const canSelectColumn = column.grid.displayMode !== 'list' && !column.grid.currentOptions?.hideHeader;
    const aggOptions = column.getAggregateOptions();

    return (
        <div ref={ref}>
            {!column.canFilter() ? null : (
                <>
                    <Menu.Item component={GridMenuItem} onClick={column.editFilter} data-atid="SetFilterLink" icon={<i className="ti ti-filter"></i>}>
                        Set Filter
                    </Menu.Item>
                </>
            )}
            {!column.canSort() ? null : (
                <>
                    <Menu.Item
                        icon={<i className="ti ti-sort-ascending"></i>}
                        onClick={sortAsc}
                        component={GridMenuItem}
                        mode={sortDir === 'Asc' ? 'selected' : undefined}
                        data-atid="SortAscendingLink"
                    >
                        Sort Ascending
                    </Menu.Item>
                    <Menu.Item
                        icon={<i className="ti ti-sort-descending"></i>}
                        component={GridMenuItem}
                        onClick={sortDesc}
                        mode={sortDir === 'Desc' ? 'selected' : undefined}
                        data-atid="SortDescendingLink"
                    >
                        Sort Descending
                    </Menu.Item>
                    {!column.grid?.currentOptions?.allowMultiSort ? null : (
                        <Menu.Item
                            icon={<i className="ti ti-trash-filled"></i>}
                            component={GridMenuItem}
                            onClick={removeSort}
                            mode={!sortDir ? 'disabled' : undefined}
                            data-atid="RemoveSortLink"
                        >
                            Remove Sort
                        </Menu.Item>
                    )}
                </>
            )}
            {!column.canGroup() ? null : (
                <Menu.Item
                    icon={<i className={`ti ${column.isGroupedBy() ? 'ti-list' : 'ti-list-tree'}`}></i>}
                    onClick={column.toggleGroupedBy}
                    component={GridMenuItem}
                    data-atid="GroupByLink"
                >
                    {column.isGroupedBy() ? 'Ungroup' : 'Group by'}
                </Menu.Item>
            )}
            {!aggOptions ? null : (
                <>
                    <Menu.Divider />
                    <Menu.Label>Aggregation</Menu.Label>
                    {aggOptions.map((o) => (
                        <Menu.Item
                            key={o.config.id}
                            icon={<MathFunction size={16} />}
                            mode={o.selected ? 'selected' : o.enabled ? undefined : 'disabled'}
                            onClick={() => o.enabled && column.setAggregate(o.config)}
                            component={GridMenuItem}
                            data-atid="AggregateLink"
                        >
                            {o.label}
                        </Menu.Item>
                    ))}
                    <Menu.Divider />
                </>
            )}
            {!canSelectColumn ? null : (
                <Menu.Item
                    component={GridMenuItem}
                    icon={<EyeOff size={16} />}
                    mode={column.canRemove() ? undefined : 'disabled'}
                    onClick={() => (column.canRemove() ? column.grid.removeColumnById(column.config.id) : null)}
                    data-atid="RemoveColumnLink"
                >
                    Remove Column
                </Menu.Item>
            )}
        </div>
    );
});

export function DataGridHeaderCheckbox({ model }: { model: DataGridModel }) {
    useEvent(model.selectionChanged);
    useEvent(model.attachedBodyChanged);
    const itemCount = useEventValue(model.itemCount);
    const count = model.selections.count();
    const selectAllError = model.selections.getSelectAllValidity();
    const canSelectAll = !selectAllError || count > 0;
    const [selecting, setSelecting] = useState(false);
    const toggle = useCallback(
        async (checked: boolean) => {
            try {
                setSelecting(true);
                await model.setSelectAll(checked);
            } finally {
                setSelecting(false);
            }
        },
        [setSelecting]
    );
    const state = { some: count > 0, all: itemCount === count, toggle };

    return (
        <GridHeaderCell
            style={{
                width: model.checkboxColWidth,
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                bottom: 0,
                height: 'var(--grid-header-row-height)',
            }}
        >
            {selecting ? (
                <Loader size="xs" />
            ) : (
                <Tooltip withinPortal label={selectAllError} disabled={canSelectAll}>
                    <div>
                        {model.currentOptions?.renderRowSelector?.(null, state, true) ?? (
                            <Checkbox
                                size="xs"
                                onChange={(e) => (!canSelectAll ? null : toggle(e.currentTarget.checked))}
                                checked={state?.some && state?.all}
                                indeterminate={state?.some && !state?.all}
                                sx={{ opacity: !canSelectAll ? 0.5 : undefined }}
                                data-atid="SelectAllCheckbox"
                            />
                        )}
                    </div>
                </Tooltip>
            )}
        </GridHeaderCell>
    );
}

function DataGridRow<T>({
    model,
    columns,
    item,
    showCheckbox,
    atid,
}: {
    model: DataGridModel;
    columns: GridColumnSet;
    item: Node<T>;
    showCheckbox: boolean;
    atid?: string;
}) {
    const id = useId(item);
    const rowId = model.currentOptions?.rowIdProvider?.(item.item) ?? id;
    const isGroupByRow = !model.currentOptions?.groupByAsRows && model.getGroupByDepth() > item.depth;
    return isGroupByRow ? (
        <DataGridGroupByRow
            model={model}
            item={item}
            columns={columns}
            atid={'GroupByRow:' + atid + ':Depth:' + item.depth + ':Count:' + (item as any).item?.count}
        />
    ) : (
        <GridRowEl key={rowId} model={model} item={item} atid={atid}>
            <DataGridRowCells model={model} columns={columns} item={item} showCheckbox={showCheckbox} atid={atid} />
        </GridRowEl>
    );
}

function GridRowEl<T>({ model, item, children, atid }: { model: DataGridModel; item: Node<T>; children: ReactNode; atid?: string }) {
    const treeState = useDi(TreeItemStateToken) as ITreeItemState<T>;
    const isHighlighted = model.currentOptions?.disableHighlight ? false : treeState.isHighlighted(item.item);
    const onClick: MouseEventHandler = useCallback(
        (evt) => {
            if (!evt.defaultPrevented) {
                if (model.rowClickHandler === 'select') {
                    model.setSelected(item.item, !model.isSelected(item.item));
                    treeState.model.highlight(item.item);
                } else if (typeof model.rowClickHandler === 'function') {
                    model.rowClickHandler(item.item);
                }
            }
        },
        [item.item, treeState.model, model.rowClickHandler]
    );
    const onHover = useCallback(() => {
        if (model.hovered.value !== item) {
            model.hovered.emit(item);
        }
    }, [item]);
    const onLeave = useCallback(() => {
        if (model.hovered.value !== undefined) {
            model.hovered.emit(undefined);
        }
    }, [item]);
    const hovered = useEventValue(model.hovered);
    return (
        <GridRow
            style={model.currentOptions?.rowStyle?.(item.item, item.index, hovered === item)}
            mode={model.rowClickHandler ? 'selectable' : undefined}
            hovered={hovered === item && model.rowClickHandler && model.rowClickHandler !== 'select' ? 'hovered' : undefined}
            highlighted={isHighlighted ? 'highlight' : undefined}
            highlightColor={model.currentOptions?.highlightColor}
            onMouseEnter={onHover}
            onMouseLeave={onLeave}
            onClick={onClick}
            data-atid={atid}
        >
            {children}
        </GridRow>
    );
}

function DataGridRowCells<T>({
    model,
    columns,
    item,
    showCheckbox,
    atid,
}: {
    model: DataGridModel;
    columns: GridColumnSet;
    item: Node<T>;
    showCheckbox: boolean;
    atid?: string;
}) {
    const scrollPos = useEventValue(model.scrollPosChanged);
    const left = scrollPos?.left ?? 0;
    const right = model.treeModel?.viewableArea?.right ?? 1000;

    const renderSets = columns.getColumnRenderSets(left, right);
    return (
        <>
            {showCheckbox ? <GridRowSelector item={item.item} model={model} atid={'DataGridCellSelector:' + item.index} /> : null}
            {renderSets.map((c, index) =>
                'blank' in c ? (
                    <td key={index}>
                        <div style={{ width: c.blank }}></div>
                    </td>
                ) : (
                    <DataGridCell key={c.column.config.id} column={c.column} item={item.item} isFirst={columns.isFirst && c.isFirst} inView />
                )
            )}
        </>
    );
}

function DataGridGroupByRow<T>({ model, item, columns, atid }: { model: DataGridModel; item: Node<T>; columns: GridColumnSet; atid?: string }) {
    const treeState = useDi(TreeItemStateToken) as ITreeItemState<T>;
    const onClick = useCallback(() => {
        treeState.model.toggle(item.item);
    }, [item.item, treeState.model, model.rowClickHandler]);
    const isLoading = model.isLoading(item);
    const isHighlighted = treeState.isHighlighted(item.item);
    const hovered = useEventValue(model.hovered);
    const onHover = useCallback(() => {
        model.hovered.emit(item);
    }, [item]);
    const onLeave = useCallback(() => {
        model.hovered.emit(undefined);
    }, [item]);
    return (
        <GridRow
            mode="clickable"
            hovered={hovered === item ? 'hovered' : undefined}
            highlighted={isHighlighted ? 'highlight' : undefined}
            highlightColor={model.currentOptions?.highlightColor}
            style={model.currentOptions?.rowStyle?.(item.item, item.index, hovered === item)}
            onClick={onClick}
            onMouseEnter={onHover}
            onMouseLeave={onLeave}
            data-atid={atid}
        >
            <td colSpan={columns.columnInfo.length + (model.showCheckbox ? 1 : 0)} data-atid={columns.columnInfo.length}>
                <GridCell
                    style={{ width: columns.width, display: 'flex' }}
                    data-atid={'GroupByCategory:' + (item as any).item?.value + ':' + (item as any).item?.count}
                >
                    {columns.isFirst ? (
                        <>
                            {!isLoading ? <Expander item={item.item} model={model} ignoreClick /> : null}
                            {isLoading ? <Skeleton style={{ top: '10rem' }} height="calc(100% - .5rem)" /> : model.renderGroupBy(item)}
                        </>
                    ) : null}
                </GridCell>
            </td>
        </GridRow>
    );
}

function DataGridFooter({ model }: { model: DataGridModel }) {
    const container = useDiContainer();
    const scrollPosistionDisposer = useMemo(
        () => ({
            dispose: () => {},
        }),
        []
    );
    const containerLoaded = useCallback((containerEl: null | HTMLDivElement) => {
        if (containerEl) {
            scrollPosistionDisposer.dispose();
            scrollPosistionDisposer.dispose = model.scrollPosChanged.listen((pos) => {
                if (pos) {
                    containerEl.scrollLeft = pos.left;
                }
            }).dispose;
        }
    }, []);
    return (
        <div ref={containerLoaded} style={{ position: 'relative', overflow: 'hidden' }}>
            {model.columnSets.map((s, i) => (
                <FooterColumnSet key={i} model={model} set={s} />
            ))}
        </div>
    );
}

function FooterColumnSet({ model, set }: { model: DataGridModel; set: GridColumnSet }) {
    useEvent(model.columnSetSizeChanged);
    useEvent(set.reordered);

    const showCheckbox = set === model.columnSets[0] && model.showCheckbox;
    const Container = set.fixed ? FixedColumnSet : ScrollingColumnSet;
    return (
        <Container
            style={{
                marginLeft: set.offset,
                width: model.displayMode === 'list' ? undefined : set.width,
            }}
        >
            <table
                data-atid="DataGridFooter"
                style={{ width: model.displayMode === 'list' ? '100%' : undefined, tableLayout: model.displayMode === 'list' ? 'fixed' : undefined }}
            >
                <tbody>
                    <GridRow>
                        {showCheckbox ? <GridCell style={{ width: model.checkboxColWidth }}></GridCell> : null}
                        {set.columnInfo.map((c, i) => (
                            <td key={i}>
                                <GridCell noBorder style={{ width: c.column.width, textAlign: c.column.align }}>
                                    {c.column.config.footerRenderer?.() ?? null}
                                </GridCell>
                            </td>
                        ))}
                    </GridRow>
                </tbody>
            </table>
        </Container>
    );
}

function DataGridBody<T>({ model, items, treeState }: { model: DataGridModel; treeState: ITreeItemState<T>; items: Node<T>[] }) {
    const container = useDiContainer();
    const childContainer = useMemo(() => {
        const result = container.createChildContainer();
        result.register(TreeItemStateToken, { useValue: treeState });
        model.attachBody(treeState);
        return result;
    }, [treeState]);
    const onPageInvalidated = useCallback(() => {
        const parents = new Set<Node<T>>();
        for (const item of items) {
            if (item.parent) {
                parents.add(item.parent);
            }
        }
        for (const parent of parents) {
            parent.invalidateChildren(true);
        }
        treeState.model.invalidateData();
    }, [items, treeState]);
    useEvent(model.pageInvalidated, onPageInvalidated);
    model.checkItems(items);
    return (
        <DiContext.Provider value={childContainer}>
            {model.columnSets.map((s, i) => (
                <GridColumnSetData key={i} model={model} items={items} set={s} atid={i.toString()} />
            ))}
        </DiContext.Provider>
    );
}

function useScrollController(pxPerSec: number) {
    return useMemo(
        () => ({
            viewable: undefined as { left: number; right: number } | undefined,
            ensureViewable(viewable: { left: number; right: number } | undefined) {
                if (!this.viewable && viewable) {
                    this.startScrolling();
                }
                this.viewable = viewable;
            },
            scrollTo(pos: { left?: number }, index: number) {
                if (pos.left !== undefined) {
                    let i = 0;
                    for (const container of this.containers) {
                        if (container && i !== index) {
                            container.scrollLeft = pos.left;
                        }
                        i++;
                    }
                }
            },
            onScroll: (() => {}) as (left: number, top: number[]) => void,
            startScrolling() {
                const containers = this.containers.slice();
                const validContainers = containers.filter((c) => !!c) as HTMLDivElement[];
                const lastContainer = validContainers[validContainers.length - 1];
                const box = lastContainer?.getBoundingClientRect();
                if (box && lastContainer) {
                    let lastTs: null | number = null;
                    const scroll = (ts: DOMHighResTimeStamp) => {
                        if (this.viewable) {
                            const { left, right } = this.viewable;
                            const deltaMod = lastTs !== null ? ts - lastTs : 0;
                            const delta = (pxPerSec * deltaMod) / 1000;
                            let changed = false;
                            for (const container of validContainers) {
                                if (right > box.width + container.scrollLeft) {
                                    container.scrollLeft += delta;
                                    changed = true;
                                } else if (left < container.scrollLeft) {
                                    container.scrollLeft -= delta;
                                    changed = true;
                                }
                            }
                            if (changed) {
                                this.onScroll(
                                    lastContainer.scrollLeft,
                                    validContainers.map((c) => c.scrollTop)
                                );
                            }
                            requestAnimationFrame(scroll);
                            lastTs = ts;
                        }
                    };
                    requestAnimationFrame(scroll);
                }
            },
            containers: [] as (HTMLDivElement | undefined)[],
            registerContainer(container: HTMLDivElement | undefined, index: number) {
                this.containers[index] = container;
            },
        }),
        []
    );
}

function DataGridTreeBody<T>({
    model,
    onScroll,
    dataSource,
    bodyIndex,
    noScroll,
    scrollController,
}: {
    model: DataGridModel;
    onScroll: null | ((left: number, top: number[]) => void);
    bodyIndex: number;
    dataSource?: DataSourceConfig<T>;
    noScroll?: boolean;
    scrollController: ReturnType<typeof useScrollController>;
}) {
    const [data, setData] = useState<T[]>([]);
    const scrollBarDimensions = useScrollBarDimensions();

    const [overlayVisible, setOverlayVisible] = useState(true);
    const updateData = useCallback(
        (clear: void | boolean) => {
            (async () => {
                if (clear !== false) {
                    setOverlayVisible(true);
                }
                const data = dataSource ?? (await model.getData());
                setData(data as T[]);
                if (model.currentOptions?.minimumLoadingMs === 0) {
                    setOverlayVisible(false);
                } else {
                    setTimeout(() => {
                        setOverlayVisible(false);
                    }, model.currentOptions?.minimumLoadingMs ?? 500);
                }
            })();
        },
        [setData, dataSource]
    );

    const handleVirtualTreeScroll = useCallback(
        (left: number, top: number) => {
            const topValues = [];
            topValues[bodyIndex] = top;
            onScroll?.(left, topValues);
            scrollController?.scrollTo({ left }, bodyIndex);
        },
        [onScroll, scrollController]
    );

    const config = useMemo(
        () =>
            ({
                renderBody: (items, state) => <DataGridBody items={items} treeState={state} model={model} />,
                renderNode: () => <></>,
                itemHeight: model.itemHeight,
                canExpand: (parent) => model.hasChildren(parent),
                childAccessor: (parent) => model.getChildren(parent),
                lazyLoad: true,
                onScroll: handleVirtualTreeScroll,
            } as ITypedTreeConfig<T>),
        [model.itemHeight, handleVirtualTreeScroll]
    );
    const handleVirtualTreeRef = useCallback(
        (tree: VirtualTree | null) => {
            const container = tree?.getContainer().current;
            if (container) {
                if (noScroll && container.parentElement) {
                    container.parentElement.style.height = `calc(100% + ${scrollBarDimensions.x}px)`;
                    container.style.overflowX = 'scroll';
                } else {
                    container.style.marginBottom = '';
                    container.style.overflowX = '';
                }
            }
            scrollController.registerContainer(tree?.getContainer().current || undefined, bodyIndex);
            model.updateRenderSize = () => tree?.invalidateSize();
            model.treeView = tree;
        },
        [model]
    );
    const handleEnsureViewable = useCallback(
        (box: { left: number; right: number } | undefined) => {
            if (!noScroll && onScroll) {
                scrollController.onScroll = onScroll;
                scrollController.ensureViewable(box);
            }
        },
        [noScroll, onScroll, scrollController.ensureViewable]
    );
    useEvent(model.ensureViewable, handleEnsureViewable);
    const handleScrollTo = useCallback((pos: { left?: number } | undefined) => {
        if (pos) {
            scrollController?.scrollTo(pos, bodyIndex);
        }
    }, []);
    useEvent(model.scrollTo, handleScrollTo);

    useEvent(model.viewInvalidated, updateData);
    return (
        <div style={{ position: 'relative', height: '100%' }}>
            <LoadingOverlay visible={overlayVisible} zIndex={1} overlayBlur={2} />
            <VirtualTree ref={handleVirtualTreeRef} data={data} config={config} />
        </div>
    );
}

function GridColumnSetData<T>({ model, set, items, atid }: { model: DataGridModel; set: GridColumnSet; items: Node<T>[]; atid?: string }) {
    useEvent(model.columnSetSizeChanged);
    useEvent(set.reordered);
    const showCheckbox = set === model.columnSets[0] && model.showCheckbox;
    const Container = set.fixed ? FixedColumnSet : ScrollingColumnSet;
    return (
        <Container style={{ marginLeft: set.offset, width: model.displayMode === 'list' ? undefined : set.width }}>
            <table
                data-atid={'DataGridTable:' + atid}
                style={{ width: model.displayMode === 'list' ? '100%' : undefined, tableLayout: model.displayMode === 'list' ? 'fixed' : undefined }}
            >
                <tbody>
                    {items.map((r, i) => (
                        <DataGridRow
                            key={i}
                            columns={set}
                            item={r}
                            model={model}
                            showCheckbox={showCheckbox}
                            atid={(r as any).item?.value ?? ((r as any).item?.EMail != null ? 'UserEMail:' + (r as any).item?.EMail : '')}
                        />
                    ))}
                </tbody>
            </table>
        </Container>
    );
}

function GridRowSelector<T>({ item, model, atid }: { item: T; model: DataGridModel; atid: string }) {
    useEvent(model.selectionChanged);
    return (
        <th>
            <GridCell style={{ width: model.checkboxColWidth, textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                {model.currentOptions?.renderRowSelector?.(
                    item,
                    { selected: model.isSelected(item), toggle: (selected) => model.setSelected(item, selected) },
                    false
                ) ?? (
                    <Checkbox
                        className="no-animation"
                        size="xs"
                        onChange={(e) => model.setSelected(item, e.currentTarget.checked)}
                        checked={model.isSelected(item)}
                        aria-label="Select Row"
                        data-atid={atid}
                    />
                )}
            </GridCell>
        </th>
    );
}

function Expander<T>({ item, model, ignoreClick }: { item: T; model: DataGridModel; ignoreClick?: boolean }) {
    const id = useId(item);
    const treeState = useDi(TreeItemStateToken) as ITreeItemState<T>;
    const node = treeState.model.getTreeNode(item);
    const depth = node?.depth ?? 0;
    const toggle = useCallback(
        (evt: React.MouseEvent<HTMLDivElement>) => {
            if (!ignoreClick) {
                treeState.model.toggle(item);
                evt.stopPropagation();
            }
        },
        [treeState, item]
    );
    return (
        <GridExpander
            key={id}
            depth={depth}
            mode={treeState.isExpanded(item) ? 'expanded' : 'collapsed'}
            onClick={toggle}
            style={{ lineHeight: model.itemHeight + 'px' }}
        >
            <i className="ti ti-chevron-right"></i>
        </GridExpander>
    );
}

function DataGridCell<T>({ column, item, inView, isFirst }: { column: DataGridColumn; item: T; inView: boolean; isFirst: boolean }) {
    useEvent(column.widthChanged);
    const treeState = useDi(TreeItemStateToken) as ITreeItemState<T>;
    const node = isFirst && column.grid.currentOptions?.indentLeafNodes ? treeState.model.getTreeNode(item) : null;
    const depth = node ? node.depth : null;
    const isLoading = column.grid.isLoading(item);
    const showExpander = column.showExpander(item);
    const shouldRender = inView || column.grid.displayMode === 'list';

    return (
        <td
            data-atid={'DataGridCell:' + column.config.id}
            style={{
                backgroundColor: column.backgroundColor ? column.backgroundColor : 'transparent',
                width: column.grid.displayMode === 'list' ? (column.getWidthPercent() * 100).toFixed() + '%' : undefined,
            }}
        >
            <GridCell
                style={{
                    width: column.grid.displayMode === 'list' ? undefined : column.width,
                    textAlign: column.align,
                    paddingLeft: showExpander || depth === null ? undefined : depth * 25 + 8,
                }}
                data-atid={node?.item}
            >
                {!shouldRender ? null : !isLoading && showExpander ? <Expander item={item} model={column.grid} /> : null}
                {!shouldRender ? (
                    <>&mdash;</>
                ) : isLoading ? (
                    <Skeleton style={{ top: '10rem' }} height="calc(100% - .5rem)" />
                ) : (
                    column.renderCell(item)
                )}
            </GridCell>
        </td>
    );
}

import { Box, useMantineTheme, Text } from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AllocationRuleEditor } from '../Model';
import { ColumnConfig } from '@root/Components/DataGrid/Models';
import { DataGrid } from '@root/Components/DataGrid';
import { IInvoiceRollup } from '@root/Services/Invoices/InvoiceSchemaService';
import { QueryResult } from '@apis/Resources';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { InvoiceTaggingRule } from '@apis/Invoices/model';
import { GridFullCell } from '@root/Components/DataGrid/Design';

type TaggingRulePreviewGridRow = {
    dimensionKey: string;
    resourceId?: string;
    resourceType?: string;
    usageAccountId?: string;
    unblendedCost?: number;
    previewKey?: string;
};

function TagValuePreview({ valueChangedEvt }: { valueChangedEvt: EventEmitter<string> }) {
    const value = useEventValue(valueChangedEvt);
    return (
        <Text span color="success.5">
            {value}
        </Text>
    );
}
export function TaggingRulePreviewGrid({ ruleEditor }: { ruleEditor: AllocationRuleEditor }) {
    const fmtSvc = useDi(FormatService);
    const [targetDatasource, setTargetDatasource] = useState<TaggingRulePreviewGridRow[]>([]);
    const tagRule = ruleEditor.rule as InvoiceTaggingRule;
    const [totalHits, setTotalHits] = useState(0);
    const previewTagValueEvt = useMemo(() => new EventEmitter<string>(tagRule.TagValue ?? ''), []);
    useEvent(ruleEditor.rulePropsChanged);

    const tagRuleQuery = ruleEditor.getUntaggedTargetsPreviewQuery();
    useEffect(() => {
        previewTagValueEvt.emit(tagRule.TagValue ?? '');
    }, [tagRule.TagValue]);

    useEffect(() => {
        targetDatasourceCallback();
    }, [JSON.stringify(tagRuleQuery)]);

    const throttleHandle = useMemo(() => ({ timeout: 0 }), []);
    const targetDatasourceCallback = useCallback(async () => {
        clearTimeout(throttleHandle.timeout);
        throttleHandle.timeout = setTimeout(async () => {
            const { results, count } = await ruleEditor.getUntaggedTargetsPreview();
            const dimensionKey = ruleEditor.getDimensionKey();
            const data = results.map((r) => {
                return {
                    dimensionKey: r[dimensionKey] ?? '',
                    usageAccountId: r['lineItem/UsageAccountId'],
                    resourceId: r['lineItem/ResourceId'],
                    resourceType: r['product/servicecode'],
                    unblendedCost: r['lineItem/UnblendedCost'],
                } as TaggingRulePreviewGridRow;
            });
            setTotalHits(count);
            setTargetDatasource(data);
        }, 500) as unknown as number;
    }, []);
    useEvent(ruleEditor.rulePropsChanged, targetDatasourceCallback);
    const columns = useMemo(() => {
        let columns: ColumnConfig<TaggingRulePreviewGridRow>[] = [];
        const dimensionName = ruleEditor.showbackSvc.getDimensionName(ruleEditor.allocDim);

        columns.push(
            {
                accessor: 'dimensionKey',
                header: (dimensionName ?? 'Dimension') + ' Before',
                defaultWidth: 150,
                id: 'dimensionKey',
                type: 'string',
                noSort: true,
                noRemove: true,
                cellRenderer: (item) => {
                    return (
                        <GridFullCell>
                            {item.dimensionKey ? (
                                <Text color="error.5" strikethrough>
                                    {item.dimensionKey}
                                </Text>
                            ) : (
                                <Text color="gray.4" italic>
                                    Empty
                                </Text>
                            )}
                        </GridFullCell>
                    );
                },
            },
            {
                accessor: 'previewKey',
                header: (dimensionName ?? 'Dimension') + ' After',
                defaultWidth: 150,
                id: 'previewKey',
                type: 'string',
                noSort: true,
                noRemove: true,
                cellRenderer: () => (
                    <GridFullCell>
                        <TagValuePreview valueChangedEvt={previewTagValueEvt} />
                    </GridFullCell>
                ),
            },
            {
                accessor: 'resourceId',
                header: `Resource Id`,
                defaultWidth: 150,
                id: 'resourceId',
                type: 'string',
                sortField: 'resourceId',
                noSort: true,
                noRemove: true,
            },
            {
                accessor: 'resourceType',
                header: `Service Code`,
                defaultWidth: 125,
                id: 'resourceType',
                type: 'string',
                sortField: 'resourceType',
                noSort: true,
                noRemove: true,
            },
            {
                accessor: 'usageAccountId',
                header: `Account ID`,
                defaultWidth: 125,
                id: 'usageAccountId',
                type: 'string',
                sortField: 'usageAccountId',
                noSort: true,
                noRemove: true,
            },
            {
                accessor: 'unblendedCost',
                header: `Cost`,
                defaultWidth: 100,
                id: 'unblendedCost',
                align: 'right',
                type: 'number',
                noSort: true,
                noRemove: true,
                cellRenderer: (item) => {
                    return fmtSvc.formatMoneyNonZeroTwoDecimals(item.unblendedCost ?? 0);
                },
            }
        );
        return columns;
    }, []);
    const totalMessage =
        totalHits === 0
            ? 'No results found'
            : totalHits > 100
            ? `Showing 100 results out of ${fmtSvc.formatInt(totalHits)}`
            : `Total ${totalHits} results`;

    return (
        <>
            <DataGrid
                columns={columns}
                hideFilter
                hideMenu={true}
                renderStats={() => totalMessage}
                showCount={false}
                dataSource={targetDatasource}
                minimumLoadingMs={0}
            />
        </>
    );
}

import styled from '@emotion/styled';
import { Input, ActionIcon, useMantineTheme } from '@mantine/core';
import { EventEmitter } from '@root/Services/EventEmitter';
import { makeAutoObservable } from 'mobx';
import { Observer } from 'mobx-react';
import { ChangeEvent } from 'react';
import { Contrast, Search } from 'tabler-icons-react';
import { DataFilterModel } from './Filters';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { useDi } from '@root/Services/DI';

export function GlobalSearch({ model: { globalFilter: model } }: { model: DataFilterModel }) {
    const theme = useMantineTheme();
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        model.updateText(e.currentTarget.value);
    };

    return (
        <Observer
            render={() => {
                const isEmpty = !model.focused && !model.inputText;
                return (
                    <GlobalSearchAnimated style={{ cursor: isEmpty ? 'pointer' : undefined }}>
                        <Input
                            data-atid="GlobalSearchInput"
                            icon={<Search color={theme.colors.primary[6]} size={16} />}
                            rightSection={
                                <ActionIcon
                                    style={{
                                        opacity: model.inputText ? 1 : 0,
                                    }}
                                    radius="xl"
                                    size="md"
                                    onClick={model.clear}
                                    data-atid="GlobalSearchClear"
                                >
                                    <i className="ti ti-x"></i>
                                </ActionIcon>
                            }
                            placeholder="Search"
                            size="xs"
                            my={5}
                            radius="xl"
                            onFocus={model.setFocus}
                            onBlur={model.unsetFocus}
                            value={model.inputText}
                            onChange={onChange}
                        />
                    </GlobalSearchAnimated>
                );
            }}
        />
    );
}
export class GlobalFilterModel {
    public focused = false;
    public inputText = '';
    public appliedText = '';
    public onTextApplied = EventEmitter.empty();
    private applyTimeout: any;

    public constructor() {
        makeAutoObservable(this);
    }

    public load(text: string) {
        this.inputText = this.appliedText = text;
    }

    public updateText(text: string) {
        this.inputText = text;
        clearTimeout(this.applyTimeout);
        this.applyTimeout = setTimeout(() => {
            this.appliedText = text;
            this.onTextApplied.emit();
        }, 600);
    }

    public clear = () => {
        this.appliedText = '';
        this.inputText = '';
        this.onTextApplied.emit();
    };
    public setFocus = () => (this.focused = true);
    public unsetFocus = () => (this.focused = false);
}
const GlobalSearchAnimated = styled.div`
    input::placeholder {
        color: ${(p) => p.theme.colors.primary[6]};
    }
    input,
    div {
        transition: all 0.25s;
    }
`;

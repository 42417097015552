import { ActionIcon, Space, Stack, Sx } from '@mantine/core';
import { ReactNode } from 'react';
import { Card, Group, Title, useMantineTheme } from '@mantine/core';
import { InfoIconTooltip, TooltipWhite } from '@root/Design/Primitives';
import { InfoCircle } from 'tabler-icons-react';

import { Box, Divider, Text } from '@mantine/core';
import { useTimeout } from '@mantine/hooks';
import styled from '@emotion/styled';

export function EditorCardWhiteWrap({ children }: { children: ReactNode }) {
    return (
        <Card p="md" radius="md" withBorder>
            {children}
        </Card>
    );
}

export const RuleStatusIcon = styled.div<{ active?: boolean }>`
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: ${(p) => (p.active ? p.theme.colors.success[5] : undefined)};
    box-sizing: border-box;
    border: solid ${(p) => (p.active ? 0 : 5)}px ${(p) => p.theme.colors.primary[6]};
`;

export function EditorCard({
    children,
    title,
    titleDescriptionControl,
    description,
    color,
    wrapped,
    accent,
    onBlur,
    height,
    info,
}: {
    children: ReactNode;
    title?: string;
    titleDescriptionControl?: ReactNode;
    description?: string;
    color?: 'blue' | 'gray' | 'white';
    wrapped?: boolean;
    accent?: boolean;
    onBlur?: () => void;
    height?: number;
    info?: ReactNode;
}) {
    const theme = useMantineTheme();
    const background = color === 'blue' ? '#E3EDFD' : color === 'gray' ? theme.colors.gray[2] : theme.white;
    const { start, clear } = useTimeout(onBlur ?? (() => {}), 200);
    const cardStyles = {
        background,
        borderWidth: 1,
        borderColor: wrapped ? 0 : theme.colors.gray[3],
        borderTopWidth: !accent ? 0 : '1px',
        height: height ?? 'unset',
    } as Sx;

    return (
        <Card p={0} sx={cardStyles} withBorder radius={wrapped ? 'sm' : 'md'} onBlur={start} onClick={clear} onFocus={clear}>
            {accent ? <Divider size={3} color="primary" /> : null}
            <Box p="xl" pt="md">
                <Group position="apart">
                    {title ? <Title order={5}>{title}</Title> : null}
                    {titleDescriptionControl}
                    {info && <InfoIconTooltip label={info} />}
                </Group>
                {description ? (
                    <Text size="sm" color="dimmed" mt={4}>
                        {description}
                    </Text>
                ) : null}
                {title || description ? <Space h="sm" /> : null}
                {children}
            </Box>
        </Card>
    );
}

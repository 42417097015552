import { Box, Center, Group, Text, useMantineTheme } from '@mantine/core';
import { CustomColors } from '@root/Design/Themes';
import { AlertTriangle, Check, Forbid } from 'tabler-icons-react';

export function DiagnosticMessage({ message, type }: { message: string; type: 'risk' | 'incompatibility' | 'clear' }) {
    const theme = useMantineTheme();
    const color: CustomColors = type === 'risk' ? 'warning' : type === 'clear' ? 'success' : 'error';
    const Icon = type === 'risk' ? AlertTriangle : type === 'clear' ? Check : Forbid;
    return (
        <>
            <Group position="left" noWrap spacing={5} my={4}>
                <Center>
                    <Icon size={18} color={theme.colors[color][6]} />
                </Center>
                <Text size="sm" color={color}>
                    {message}
                </Text>
            </Group>
        </>
    );
}

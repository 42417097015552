import styled from '@emotion/styled';
import { Text, Anchor, Box, Modal, useMantineTheme, Divider } from '@mantine/core';
import { AnchorButton } from '@root/Design/Primitives';
import { ConfigService } from '@root/Services/ConfigService';
import { useDi } from '@root/Services/DI';
import { MspService } from '@root/Services/MspService';
import { observer } from 'mobx-react';
import { ChevronRight } from 'tabler-icons-react';

export const AwsPrivateOfferSuccessPage = observer(function CustomerHeader() {
    const theme = useMantineTheme();
    const mspSvc = useDi(MspService);
    return (
        <Modal
            padding={theme.spacing.xl}
            opened={true}
            onClose={() => {}}
            withCloseButton={false}
            closeOnEscape={false}
            closeOnClickOutside={false}
            withinPortal
            overlayBlur={4}
            overlayOpacity={1}
            overlayColor={theme.colors.gray[1]}
            size={450}
            shadow="xl"
        >
            <Box style={{ textAlign: 'center', marginTop: '50px' }}>
                <img src={mspSvc.mspSupportAndLogos.Logo} style={{ height: '100px' }} />
                <br />
                <br />
                <br />
                <Text>Successfully registered. </Text>
                <br />
                <Text>You may now close this window or continue to the site.</Text>
                <br />
                If you have any questions contact {mspSvc.mspSupportAndLogos.CompanyName} customer support at{' '}
                <Anchor>{mspSvc.mspSupportAndLogos.SupportEmail.replace('mailto:', '')}</Anchor>.
                <ContinueToPlatform />
            </Box>
        </Modal>
    );
});

export function ContinueToPlatform() {
    const configSvc = useDi(ConfigService);

    return (
        <Box sx={{ textAlign: 'center' }}>
            <Divider my="lg" />
            <AnchorButton
                onClick={() => {
                    location.href = `${configSvc.config.loginUrl}?host=${window.location.hostname}`;
                }}
                text="Continue"
                icon={<ChevronRight />}
            />
        </Box>
    );
}

const CenterContainer = styled.div`
    text-align: center;
`;

import { Button, Divider, Group, Text, CloseButton, Space, UnstyledButton, createStyles, ThemeIcon, Box } from '@mantine/core';
import { PagePanel, PanelBody, PanelToolbar } from '@root/Design/Layout';
import { TagActionBody, TagActionContainer } from './UpdateTags';
import { BulkTagProps } from './BulkTagProps';
import { OpenSidebar } from './OpenSidebar';
import { colorPalette, CustomColors } from '@root/Design/Themes';
import { CreditBalanceDetail } from './CreditBalanceDetails';
import { postResourcesTagResources } from '@apis/Resources';
import { TagResourcesJob, TagResourcesJobJob, TagValueReplace } from '@apis/Resources/model';
import { useDi } from '@root/Services/DI';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { JobService } from '@root/Services/Jobs/JobService';
import { BaseResourceId } from '@root/Components/Resources/ResourcesGrid';
import { ListCheck } from 'tabler-icons-react';
import { useState } from 'react';
import { ResourceTagService } from '../Services/ResourceTagService';

export interface BulkTagEditorProps extends BulkTagProps {
    children?: React.ReactNode;
    filteredResources?: () => BaseResourceId[];
    canSubmit: boolean;
    setDisableSubmit: (value: boolean) => void;
    overwriteConflicts: boolean;
    setOverwriteConflicts: (value: boolean) => void;
    setDeleteRenamedTags?: (value: boolean) => void;
    tagJob: TagResourcesJob;
    // operationType is used to set the appropriate API parameters
    // and set the status messages
    operationType: 'add' | 'remove' | 'renameKey' | 'renameValue';
}

export interface TagKeyNames {
    OldTagKey: string;
    NewTagKey: string;
}

export function TagEditor(props: BulkTagEditorProps) {
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const { classes, theme } = useStyles();
    const notificationSvc = useDi(NotificationService);
    const jobService = useDi(JobService);
    // console.log(JSON.stringify(props));
    const handleSubmit = async () => {
        setSubmitClicked(true);
        const resourceCount = props.resourceIds.count();
        // props.tagJob.PreventAddOverwrite = true;
        try {
            await props.onTagJobStarting?.(props.tagJob);
            const job = await postResourcesTagResources(props.tagJob);
            props.tagJob.JobId = job.Id;

            props.onTagJobStarted(props.tagJob);

            notificationSvc.notify(
                'Tag Update Task',
                `Tag ${props.operationType} job for ${resourceCount} ${
                    resourceCount === 1 ? 'resource' : 'resources'
                } was submitted. Please check the activity log for status details.`,
                'primary',
                <ThemeIcon style={{ backgroundColor: theme.colors?.gray?.[2] as CustomColors }} variant="light" size="xl" radius="xl">
                    <ListCheck style={{ color: theme.colors!.primary![8] }} />
                </ThemeIcon>
            );
            jobService.notifyNewJob(job);
            props.onButtonClick(OpenSidebar.undefined, true);
        } catch {
            notificationSvc.notify(
                'Tag Update Task',
                `Error: Tag ${props.operationType} job for ${resourceCount} ${
                    resourceCount === 1 ? 'resource' : 'resources'
                } failed. Please try the tag operation again.`,
                'error',
                null
            );
        }
        setSubmitClicked(false);
    };

    return (
        <PagePanel padded={false} size="fill">
            <TagActionContainer>
                <Group position="apart" p="lg">
                    <Group>
                        <UnstyledButton>
                            <i className="ti ti-chevron-left" onClick={() => props.onButtonClick(OpenSidebar.updateTags, false)} />
                        </UnstyledButton>
                        <Text data-atid="SidePanelHeader" size={20}>
                            {props.pageTitle}
                        </Text>
                    </Group>
                    <CloseButton data-atid="SidePanelCloseButton" mr="xl" onClick={() => props.onButtonClick(OpenSidebar.undefined, false)} />
                </Group>
                <Divider />
                <PanelBody style={{ marginLeft: 0, flex: '1', height: '100%', minHeight: 0 }}>
                    <Text className={classes.textColor}>{props.instructions}</Text>
                    <TagActionBody>{props.children}</TagActionBody>
                </PanelBody>
                <Divider />
                <Box>
                    <Box p="lg" pb={0}>
                        <CreditBalanceDetail
                            resourcesSelected={props.resourceIds}
                            canSubmit={props.canSubmit}
                            setDisableSubmit={props.setDisableSubmit}
                            tagJob={props.tagJob}
                            overwrite={props.overwriteConflicts}
                            setOverwrite={props.setOverwriteConflicts}
                            setDeleteRenamedTags={props.setDeleteRenamedTags || (() => {})}
                        ></CreditBalanceDetail>
                    </Box>
                    <PanelToolbar>
                        <Button data-atid="SidePanelSubmitButton" type="submit" disabled={!props.canSubmit || submitClicked} onClick={handleSubmit}>
                            {props.pageTitle}
                        </Button>
                        <Button
                            data-atid="SidePanelCancelButton"
                            variant="outline"
                            disabled={submitClicked}
                            onClick={() => props.onButtonClick(OpenSidebar.undefined, false)}
                        >
                            Cancel
                        </Button>
                    </PanelToolbar>
                </Box>
            </TagActionContainer>
        </PagePanel>
    );
}

const useStyles = createStyles((theme) => ({
    textColor: {
        color: colorPalette.subHeaderTextColor,
        alignContent: 'center',
        i: {
            alignItems: 'center',
        },
    },
    footer: {
        position: 'absolute',
        bottom: 0,
    },

    keyValues: {
        marginTop: `${theme.spacing.xs}px`,
    },
    hover: {
        '&:hover': {
            color: theme.colors?.primary?.[7] as CustomColors,
            backgroundColor: colorPalette.white,
        },
    },
}));

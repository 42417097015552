import { MapMonitoringHomeModel, ProgressArrDataPoint } from './MapMonitoringHome';
import { Button, Card, Grid, NumberInput, Progress, Space, TextInput, useMantineTheme } from '@mantine/core';
import styled from '@emotion/styled';
import { Check, ChevronDown } from 'tabler-icons-react';
import { Clearfix } from '@root/Design/Primitives';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { MapContract } from '@apis/TagManager/model';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DatePicker } from '@mantine/dates';
import { PieChart } from '@root/Components/Charts/PieChart';
import { LineChart } from '@root/Components/Charts/LineChart';
import { DateRangeFilter } from '@root/Components/Filter/DateRangeFilter';
import { observer } from 'mobx-react';
import { getMonth, getYear, parseISO, startOfWeek } from 'date-fns';

interface GroupedData {
    [key: string]: {
        date: Date;
        arrValue: number;
        count: number;
    };
}

export const ProgressSection = observer(function ProgressSection({ homeModel }: { homeModel: MapMonitoringHomeModel }) {
    const contract = homeModel.contract ?? {};
    const latestSnapshot = homeModel.latestContractSnapshot ?? {};
    const [editMode, setEditMode] = useState(false);
    const [migrationDaysTotal, setMigrationDaysTotal] = useState(0);
    const [migrationDaysCompleted, setMigrationDaysCompleted] = useState(0);
    const [contractPlanCompletedPct, setContractPlanCompletedPct] = useState(0);
    const [firstSpendDate, setFirstSpendDate] = useState('');
    const [first1000spendDate, setFirst1000SpendDate] = useState('');
    const [first50000spendDate, setFirst50000SpendDate] = useState('');
    const [contractHistoricalArrData, setContractHistoricalArrData] = useState<{ date: Date; arrValue: number }[]>([]);
    const [dateRange, setDateRange] = useState<{ from?: Date; to?: Date }>({});
    const fmtSvc = useDi(FormatService);
    const showMaxValue = false;

    useEffect(() => {
        setEditMode(
            !contract?.MigrationStartDate ||
                !contract?.MigrationEndDate ||
                !contract?.District ||
                !contract?.CompanyType ||
                !contract?.MigrationPartner
        );
        setDateRange({ from: new Date(contract?.MigrationStartDate! ?? contract.ContractStartDate!), to: new Date() });
        const pct =
            latestSnapshot.Arr?.EligibleTaggedCorrectAnnualizedSpend! / (contract.AnticipatedAnnualSpend ? contract.AnticipatedAnnualSpend! : 0);
        setContractPlanCompletedPct(!Number.isNaN(pct) ? pct : 0);
        setMigrationDaysTotal(getDuration(new Date(contract?.MigrationStartDate!), new Date(contract?.MigrationEndDate!)));
        setMigrationDaysCompleted(getDuration(new Date(contract?.MigrationStartDate!), new Date()));
    }, [contract]);

    useMemo(async () => {
        Promise.all([
            setFirstSpendDate((await homeModel.getSpendSnapshotDate(1)) ?? ''), // First Spend
            setFirst1000SpendDate((await homeModel.getSpendSnapshotDate(1000)) ?? ''), // First $1,000
            setFirst50000SpendDate((await homeModel.getSpendSnapshotDate(50000)) ?? ''), // First $50,000
        ]);
    }, [contract]);

    useMemo(async () => {
        Promise.resolve(
            setContractHistoricalArrData(
                await homeModel.getDateRangeSnapshotArrData(
                    dateRange.from ?? new Date(contract.ContractStartDate!),
                    dateRange.to ?? new Date(contract.ContractEndDate ?? new Date().toString())
                )
            )
        );
    }, [contract, dateRange]);

    const handleDateChanged = useCallback((range: { from?: Date; to?: Date }) => {
        setDateRange(range);
    }, []);

    const groupData = (data: ProgressArrDataPoint[], interval: string) => {
        const groupedData = data.reduce((acc: GroupedData, item) => {
            let index: string;
            switch (interval) {
                case 'week':
                    index = fmtSvc.toShortDate(startOfWeek(parseISO(item.date as any)));
                    break;
                case 'month':
                    index = `${getYear(parseISO(item.date as any))}-${getMonth(parseISO(item.date as any)) + 1}`;
                    break;
                default:
                    index = item.date.toString();
                    break;
            }
            if (!acc[index]) {
                acc[index] = { ...item, count: 1 };
            } else {
                acc[index].arrValue += item.arrValue;
                acc[index].count += 1;
            }
            return acc;
        }, {});

        Object.keys(groupedData).forEach((key) => {
            groupedData[key].arrValue /= groupedData[key].count;
        });

        return Object.values(groupedData);
    };

    const lineChartInterval = contractHistoricalArrData.length > 60 ? 'month' : contractHistoricalArrData.length > 30 ? 'week' : 'day';
    const lineChartData = groupData(contractHistoricalArrData, lineChartInterval);
    const lineChartHeader = lineChartInterval === 'week' || lineChartInterval === 'month' ? 'Incremental ARR (Average)' : 'Incremental ARR';

    const theme = useMantineTheme();
    return (
        <Grid style={{ width: '98.7%', margin: '5px 10px' }}>
            <Grid.Col span={8}>
                <Card component={ProgressLongCard}>
                    <CardHeader>Incremental ARR</CardHeader>
                    {latestSnapshot ? (
                        <>
                            {' '}
                            <LongCardHeaderStats>
                                <span>
                                    <strong style={{ fontWeight: '900' }}>
                                        {homeModel.fmtSvc.formatMoneyNoDecimals(latestSnapshot.Arr?.EligibleTaggedCorrectAnnualizedSpend ?? 0)}
                                    </strong>{' '}
                                    /{' '}
                                    {contract.AnticipatedAnnualSpend
                                        ? homeModel.fmtSvc.formatMoneyNoDecimals(contract.AnticipatedAnnualSpend)
                                        : homeModel.fmtSvc.formatMoneyNoDecimals(0)}
                                </span>
                                <span
                                    style={{ padding: '5px', backgroundColor: theme.colors.primary[2], borderRadius: '10px' }}
                                    data-atid={'PlanCompletedPercent:' + homeModel.fmtSvc.formatPercent(contractPlanCompletedPct)}
                                >
                                    {homeModel.fmtSvc.formatPercent(contractPlanCompletedPct)}
                                </span>
                                <span style={{ fontWeight: '300', margin: '0px 5px', color: theme.colors.gray[5] }}>Plan Completed</span>
                            </LongCardHeaderStats>
                            <Clearfix />
                            <Progress
                                sx={{
                                    margin: '15px',
                                    height: '30px',
                                    backgroundColor: theme.colors.primary[2],
                                    border: '1px solid',
                                    borderColor: theme.colors.gray[3],
                                }}
                                radius={0}
                                sections={[
                                    {
                                        value: contractPlanCompletedPct * 100 ?? 0,
                                        color: 'primary.5',
                                    },
                                ]}
                            />
                            <LongCardFooterStats>
                                <span>{homeModel.fmtSvc.formatMoneyNoDecimals(latestSnapshot.Arr?.EligibleTaggedCorrectAnnualizedSpend! ?? 0)}</span>
                                <span>
                                    {contract.AnticipatedAnnualSpend
                                        ? homeModel.fmtSvc.formatMoneyNoDecimals(
                                              contract.AnticipatedAnnualSpend - (latestSnapshot.Arr?.EligibleTaggedCorrectAnnualizedSpend! ?? 0)
                                          )
                                        : null}
                                </span>
                                <Clearfix />
                            </LongCardFooterStats>
                        </>
                    ) : (
                        <>
                            {' '}
                            <LongCardInstructionBanner>
                                Map Contract does not have any historical data available yet. Please wait a day.
                            </LongCardInstructionBanner>
                        </>
                    )}
                </Card>
                <Space h="md" />
                <Card component={ProgressLongCard}>
                    <CardHeader style={{ float: 'left' }}>Migration Timeframe</CardHeader>
                    {contract.MigrationStartDate && contract.MigrationEndDate ? (
                        <>
                            {' '}
                            <LongCardHeaderStats>
                                <span>
                                    <strong style={{ fontWeight: '900' }}>{migrationDaysCompleted}</strong> / {migrationDaysTotal}
                                </span>
                                <span
                                    style={{ padding: '5px', backgroundColor: theme.colors.primary[2], borderRadius: '10px' }}
                                    data-atid={
                                        'MigrationCompletedPercent:' + homeModel.fmtSvc.formatPercent(migrationDaysCompleted / migrationDaysTotal)
                                    }
                                >
                                    {homeModel.fmtSvc.formatPercent(migrationDaysCompleted / migrationDaysTotal)}
                                </span>
                                <span style={{ fontWeight: '300', margin: '0px 5px', color: theme.colors.gray[5] }}>Days Completed</span>
                            </LongCardHeaderStats>
                            <Clearfix />
                            <Progress
                                sx={{
                                    margin: '15px',
                                    height: '30px',
                                    backgroundColor: theme.colors.primary[2],
                                    border: '1px solid',
                                    borderColor: theme.colors.gray[3],
                                }}
                                radius={0}
                                sections={[{ value: (migrationDaysCompleted / migrationDaysTotal) * 100 ?? 0, color: 'primary.5' }]}
                            />
                            <LongCardFooterStats>
                                <span>{homeModel.fmtSvc.toShortDate(new Date(contract.MigrationStartDate!))}</span>
                                <span>{homeModel.fmtSvc.toShortDate(new Date(contract.MigrationEndDate!))}</span>
                                <Clearfix />
                            </LongCardFooterStats>
                        </>
                    ) : (
                        <>
                            {' '}
                            <LongCardInstructionBanner>
                                Please set your Migration Timeline using the details panel on the right.
                            </LongCardInstructionBanner>
                        </>
                    )}
                </Card>
                <Space h="md" />
                <div>
                    <Grid columns={10}>
                        <Grid.Col span={2}>
                            <Card component={TinyCard} $topColor="#b42318">
                                <TinyCardHeader>MAP Signed</TinyCardHeader>
                                <TinyCardText>
                                    <ChevronDown />
                                    {contract.SignDate
                                        ? homeModel.fmtSvc.toShortDate(new Date(contract.SignDate!))
                                        : contract.MigrationStartDate
                                        ? homeModel.fmtSvc.toShortDate(new Date(contract.MigrationStartDate!))
                                        : '--'}
                                </TinyCardText>
                            </Card>
                        </Grid.Col>
                        <Grid.Col span={2}>
                            <Card component={TinyCard} $topColor="#039855">
                                <TinyCardHeader>MM Connected</TinyCardHeader>
                                <TinyCardText>{homeModel.fmtSvc.toShortDate(new Date(contract.CreatedAt!))}</TinyCardText>
                            </Card>
                        </Grid.Col>
                        <Grid.Col span={2}>
                            <Card component={TinyCard} $topColor="#db81dd">
                                <TinyCardHeader>First Spend</TinyCardHeader>
                                <TinyCardText>{firstSpendDate ? homeModel.fmtSvc.toShortDate(new Date(firstSpendDate!)) : '--'}</TinyCardText>
                            </Card>
                        </Grid.Col>
                        <Grid.Col span={2}>
                            <Card component={TinyCard} $topColor="#9f85eb">
                                <TinyCardHeader>First $1,000</TinyCardHeader>
                                <TinyCardText>{first1000spendDate ? homeModel.fmtSvc.toShortDate(new Date(first1000spendDate!)) : '--'}</TinyCardText>
                            </Card>
                        </Grid.Col>
                        <Grid.Col span={2}>
                            <Card component={TinyCard} $topColor="#8176f0">
                                <TinyCardHeader>First $50,000</TinyCardHeader>
                                <TinyCardText>
                                    {first50000spendDate ? homeModel.fmtSvc.toShortDate(new Date(first50000spendDate!)) : '--'}
                                </TinyCardText>
                            </Card>
                        </Grid.Col>
                    </Grid>
                </div>
                <Space h="md" />
                <Card component={ProgressGridLongCard}>
                    <CardHeader>{lineChartHeader}</CardHeader>
                    <ProgressGridLongCardHeaderTools>
                        <DateRangeFilter
                            corners={4}
                            height={36}
                            constraint={{
                                min: new Date(contract.ContractStartDate!),
                                max: new Date(getEndDate(contract, homeModel.fmtSvc) ?? new Date().toString()),
                            }}
                            value={dateRange}
                            onChange={handleDateChanged}
                        />
                    </ProgressGridLongCardHeaderTools>

                    <ProgressGridContainer>
                        <LineChart
                            groups={['date']}
                            values={['arrValue']}
                            data={lineChartData}
                            settings={{
                                margin: { top: 30, right: 0, bottom: 80, left: 50 },
                                interval: lineChartInterval,
                                direction: 'up',
                                chartColors: ['#009fe1'],
                                format: 'money',
                                mode: 'trend-line',
                                noWrapper: true,
                            }}
                        ></LineChart>
                    </ProgressGridContainer>
                </Card>
            </Grid.Col>
            <Grid.Col span={4}>
                <Card component={ProgressShortCard}>
                    <CardSectionLeft>
                        <CardHeader>Annualized ARR</CardHeader>
                        <PieLabel>{homeModel.fmtSvc.formatMoneyNoDecimals(latestSnapshot.Arr?.EligibleTaggedCorrectAnnualizedSpend! ?? 0)}</PieLabel>
                    </CardSectionLeft>
                    <CardSectionRight>
                        <PieChart
                            data={[
                                { label: 'Incomplete', value: 1 - contractPlanCompletedPct, color: '#d8d8d8' },
                                { label: 'Complete', value: contractPlanCompletedPct, color: '#0075c1' },
                            ]}
                            groups={['label']}
                            values={['value']}
                            settings={{
                                valueFormat: ' ^-.0~%',
                                chartColors: ['#d8d8d8', '#0075c1'],
                                enableArcLinkLabels: false,
                                enableArcLabels: false,
                                showTotal: false,
                                innerRadius: 0,
                                cornerRadius: 0,
                                noWrapper: true,
                            }}
                        />
                    </CardSectionRight>
                </Card>
                <Space h="md" />
                <Card component={ProgressShortCard}>
                    <CardHeader style={{ float: 'left' }}>Contract Details</CardHeader>
                    <Clearfix></Clearfix>
                    <ContractDetailSection>
                        <ContractDetailLabel>
                            <span>Contract Name</span>
                            <span>{contract?.Name}</span>
                        </ContractDetailLabel>
                        <ContractDetailLabel>
                            <span>MPE ID</span>
                            <span>{contract?.ProgramId}</span>
                        </ContractDetailLabel>
                        <ContractDetailLabel>
                            <span>Designated Account(s)</span>
                            <span>
                                {contract?.AccountIds?.map((a) => {
                                    return <p>{a}</p>;
                                })}
                            </span>
                        </ContractDetailLabel>
                        <ContractDetailLabel>
                            <span>Contract Start</span>
                            <span>{homeModel.fmtSvc.formatDate(new Date(contract?.ContractStartDate!))}</span>
                        </ContractDetailLabel>
                        <ContractDetailLabel>
                            <span>Contract End</span>
                            <span>{getEndDate(contract!, homeModel.fmtSvc)}</span>
                        </ContractDetailLabel>
                    </ContractDetailSection>
                    <ContractDetailSection>
                        {' '}
                        <ContractDetailLabel>
                            <span>Migration Timeline</span>
                            <span>{migrationDaysTotal} days</span>
                        </ContractDetailLabel>
                        <ContractDetailLabel>
                            <span>Start {!contract?.MigrationStartDate ? <RedAsterisk>*</RedAsterisk> : null}</span>
                            {editMode ? (
                                <span>
                                    <DatePicker
                                        placeholder={
                                            contract.MigrationStartDate
                                                ? homeModel.fmtSvc.formatDate(new Date(contract.MigrationStartDate))
                                                : homeModel.fmtSvc.formatDate(new Date(new Date()))
                                        }
                                        value={contract.MigrationStartDate ? new Date(contract.MigrationStartDate) : null}
                                        onChange={(d) => {
                                            contract.MigrationStartDate = d?.toJSON();
                                        }}
                                        inputFormat="MM/DD/YYYY"
                                        data-atid="MigrationTimelineStartPicker"
                                    />
                                </span>
                            ) : (
                                <span data-atid="MigrationTimelineStart">{homeModel.fmtSvc.formatDate(new Date(contract?.MigrationStartDate!))}</span>
                            )}
                        </ContractDetailLabel>
                        <ContractDetailLabel>
                            <span>End {!contract?.MigrationEndDate ? <RedAsterisk>*</RedAsterisk> : null}</span>
                            {editMode ? (
                                <span>
                                    <DatePicker
                                        placeholder={
                                            contract.MigrationEndDate
                                                ? homeModel.fmtSvc.formatDate(new Date(contract.MigrationEndDate))
                                                : homeModel.fmtSvc.formatDate(new Date(new Date()))
                                        }
                                        value={contract.MigrationEndDate ? new Date(contract.MigrationEndDate) : null}
                                        onChange={(d) => (contract.MigrationEndDate = d?.toJSON())}
                                        inputFormat="MM/DD/YYYY"
                                        data-atid="MigrationTimelineEndPicker"
                                    />
                                </span>
                            ) : (
                                <span data-atid="MigrationTimelineEnd">{homeModel.fmtSvc.formatDate(new Date(contract?.MigrationEndDate!))}</span>
                            )}
                        </ContractDetailLabel>
                    </ContractDetailSection>
                    <ContractDetailSection>
                        <ContractDetailLabel>
                            <span>Incremental ARR</span>
                            {editMode ? (
                                <span>
                                    <NumberInput
                                        hideControls
                                        placeholder={`e.g. $100,000`}
                                        value={contract.AnticipatedAnnualSpend! > 0 ? contract.AnticipatedAnnualSpend! : undefined}
                                        parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                                        formatter={(value) => {
                                            contract.AnticipatedAnnualSpend = Number(value);
                                            if (value !== undefined && value !== null && value !== '') {
                                                value = value?.toString().replace(/[^0-9]/g, '');
                                                contract.AnticipatedAnnualSpend = Number(value);
                                                return !Number.isNaN(parseFloat(value!))
                                                    ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                                                    : '$ ';
                                            } else {
                                                return '';
                                            }
                                        }}
                                        data-atid="AnticipatedAnnualSpendInput"
                                    />
                                </span>
                            ) : (
                                <span>{homeModel.fmtSvc.formatMoneyNoDecimals(contract?.AnticipatedAnnualSpend!)}</span>
                            )}
                        </ContractDetailLabel>
                        {showMaxValue ? (
                            <ContractDetailLabel>
                                <span data-atid="MaxMAPCreditsDisplay">
                                    Max. MAP Credits{' '}
                                    {contract?.MaximumContractValue === 0 || contract?.MaximumContractValue === null ? (
                                        <RedAsterisk>*</RedAsterisk>
                                    ) : null}
                                </span>

                                {editMode ? (
                                    <span>
                                        <NumberInput
                                            hideControls
                                            placeholder={`e.g. $100,000`}
                                            value={contract.MaximumContractValue! > 0 ? contract.MaximumContractValue : undefined}
                                            parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                                            formatter={(value) => {
                                                contract.MaximumContractValue = Number(value);
                                                if (value !== undefined && value !== null && value !== '') {
                                                    value = value?.toString().replace(/[^0-9]/g, '');
                                                    contract.MaximumContractValue = Number(value);
                                                    return !Number.isNaN(parseFloat(value!))
                                                        ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                                                        : '$ ';
                                                } else {
                                                    return '';
                                                }
                                            }}
                                            data-atid="MaxMAPCreditsInput"
                                        />
                                    </span>
                                ) : (
                                    <span>{homeModel.fmtSvc.formatMoneyNoDecimals(contract?.MaximumContractValue!)}</span>
                                )}
                            </ContractDetailLabel>
                        ) : null}
                        <ContractDetailLabel>
                            <span>Contract Source</span>
                            {editMode ? (
                                <span>
                                    <TextInput
                                        defaultValue={contract.MigrationPartner!}
                                        onChange={(evt) => (contract.MigrationPartner = evt.currentTarget.value)}
                                        placeholder={`e.g. "Aws"`}
                                        data-atid="ContractSourceInput"
                                    />
                                </span>
                            ) : (
                                <span>{contract?.MigrationPartner}</span>
                            )}
                        </ContractDetailLabel>
                        <ContractDetailLabel>
                            <span>Company Type</span>
                            {editMode ? (
                                <span>
                                    <TextInput
                                        defaultValue={contract.CompanyType!}
                                        onChange={(evt) => (contract.CompanyType = evt.currentTarget.value)}
                                        placeholder={`e.g. "SMB"`}
                                        data-atid="CompanyTypeInput"
                                    />
                                </span>
                            ) : (
                                <span>{contract?.CompanyType}</span>
                            )}
                        </ContractDetailLabel>
                        <ContractDetailLabel>
                            <span>District</span>
                            {editMode ? (
                                <span>
                                    <TextInput
                                        defaultValue={contract.District!}
                                        onChange={(evt) => (contract.District = evt.currentTarget.value)}
                                        placeholder={`e.g. "Central"`}
                                        data-atid="DistrictInput"
                                    />

                                    <Clearfix></Clearfix>
                                </span>
                            ) : (
                                <span>{contract?.District}</span>
                            )}
                        </ContractDetailLabel>
                    </ContractDetailSection>
                    <div style={{ float: 'right' }}>
                        {editMode ? (
                            <>
                                {' '}
                                <Button
                                    style={{ margin: '5px 5px' }}
                                    onClick={() => {
                                        homeModel.reloadContract();
                                        setEditMode(false);
                                    }}
                                    data-atid="CancelEditContractDetailsButton"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    style={{ margin: '5px 5px' }}
                                    onClick={() => {
                                        homeModel.saveContract();
                                        setEditMode(false);
                                    }}
                                    data-atid="SaveEditContractDetailsButton"
                                >
                                    Save
                                </Button>
                            </>
                        ) : (
                            <Button
                                style={{ margin: '5px 5px' }}
                                onClick={() => {
                                    setEditMode(true);
                                }}
                            >
                                Toggle Edit Mode
                            </Button>
                        )}
                    </div>
                </Card>
            </Grid.Col>
        </Grid>
    );
});
export function GreenCheck() {
    return <Check style={{ filter: 'invert(68%) sepia(100%) saturate(325%) hue-rotate(80deg) brightness(90%) contrast(80%)' }} />;
}

export function getEndDate(contract: MapContract, fmtSvc: FormatService) {
    var startDate = new Date(contract?.ContractStartDate!);
    if (contract?.ContractTermYears == null) return null;
    var date =
        contract?.ContractEndDate ?? new Date(new Date(startDate).setFullYear(startDate.getFullYear() + contract.ContractTermYears!)).toString();

    return fmtSvc.formatDate(new Date(date!));
}

export function getDuration(startDate: Date, endDate: Date) {
    var duration = endDate.valueOf() - startDate.valueOf();
    var diffDays = Math.ceil(duration / (1000 * 3600 * 24));
    return diffDays;
}

export const TinyCard = styled.div<{ $topColor?: string }>`
    border: 1px solid ${(props) => props.theme.colors.gray[2]};
    filter: drop-shadow(0.5px 0.5px 0.5px rgba(0, 0, 0, 0.25));
    border-top: 5px solid ${(props) => props.$topColor};
    padding: 8px 20px;

    :hover {
        border: 1px solid ${(props) => props.$topColor};
        border-top: 5px solid ${(props) => props.$topColor};
        background-color: #fcfcfc;

        transition: all 0.2s ease-in-out;
    }
`;

export const TinyCardHeader = styled.span`
    color: ${(props) => props.theme.colors.gray[7]};
    font-size: 15px;
    vertical-align: top;
`;
export const TinyCardText = styled.span`
    color: ${(props) => props.theme.colors.primary[7]};
    font-size: 20px;
    display: block;

    svg {
        vertical-align: middle;
        margin-right: 7px;
    }
`;

export const ProgressLongCard = styled.div`
    height: 175px;
    margin: 5px;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.colors.gray[2]};
    filter: drop-shadow(0.5px 0.5px 0.5px rgba(0, 0, 0, 0.25));
`;

export const ProgressGridLongCard = styled.div`
    height: 550px;
    margin: 5px;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.colors.gray[2]};
    filter: drop-shadow(0.5px 0.5px 0.5px rgba(0, 0, 0, 0.25));
`;

export const ProgressGridContainer = styled.div`
    height: 500px;
    padding: 25px;
    h4 {
        float: left;
    }
`;

export const ProgressGridLongCardHeaderTools = styled.div`
    float: right;
    :after {
        clear: both;
    }
`;

export const LongCardHeaderStats = styled.div`
    float: right;
    color: ${(props) => props.theme.colors.primary[6]};
    margin: 5px;
    width: 50%;
    font-size: 20px;
    text-align: right;
    span:first-of-type {
        margin: 0px 10px;
    }
`;

export const LongCardInstructionBanner = styled.div`
    color: ${(props) => props.theme.colors.primary[6]};
    font-size: 20px;
    position: relative;
    margin: 60px auto;
    text-align: center;
`;
export const RedAsterisk = styled.p`
    color: ${(props) => props.theme.colors.error[6]};
    display: inline;
    vertical-align: top;
`;

export const LongCardFooterStats = styled.div`
    color: ${(props) => props.theme.colors.primary[6]};
    margin: 5px;
    font-weight: 400;
    font-size: 14px;
    span:first-of-type {
        width: 50%;
        float: left;
        text-align: left;
    }
    span:nth-of-type(2) {
        width: 50%;
        float: right;
        text-align: right;
    }
`;

export const ProgressShortCard = styled.div`
    margin: 5px;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.colors.gray[2]};
    filter: drop-shadow(0.5px 0.5px 0.5px rgba(0, 0, 0, 0.25));
`;

export const PieLabel = styled.h1`
    margin: 5px;
    font-size: 60px;
    text-align: center;
    color: ${(props) => props.theme.colors.primary[6]};
`;

export const CardSectionLeft = styled.div`
    width: 60%;
    float: left;
    border-right: 1px solid ${(props) => props.theme.colors.gray[2]};
`;
export const CardSectionRight = styled.div`
    width: 39%;
    float: right;
    height: 140px;
`;

export const CardHeader = styled.h4`
    margin: 0px 5px;
    font-weight: 1000;
`;

export const ContractDetailSection = styled.div`
    margin: 10px;
    padding: 5px;
    background-color: ${(props) => props.theme.colors.gray[1]};
    border-radius: 5px;
    text-align: center;
`;
export const ContractDetailLabel = styled.span`
    display: block;
    padding: 10px;
    font-size: 14px;

    span:nth-of-type(odd) {
        float: left;
        width: 45%;
        text-align: right;
        font-weight: 900;
    }
    span:nth-of-type(even) {
        float: right;
        width: 45%;
        text-align: left;

        p {
            display: flex;
            margin: 0px;
            padding: 0px;
            line-height: 1.25;
        }
    }
    :after {
        content: '';
        display: table;
        clear: both;
    }
`;

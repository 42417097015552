import { CompanyType } from '@apis/Customers/model/companyType';
import { getConnectionGetCliConnectionInfo, getConnectionTryAssumeRole, postConnectionGetPendingConnection } from '@apis/Resources';
import { CliConnectionInfo, PendingCliConnection } from '@apis/Resources/model';
import { Button, Divider, Group, Space } from '@mantine/core';
import { useCompany } from '@root/Components/Router/CompanyContent';
import {
    WizardForm,
    WizardFormStep,
    WizardFormTitle,
    WizardFormFooter,
    LoadingState,
    WizardFormStepContainer,
} from '@root/Components/Wizard/WizardForm';
import { PageContent } from '@root/Design/Layout';
import { ConfigService } from '@root/Services/ConfigService';
import { useDi } from '@root/Services/DI';
import { useNav } from '@root/Services/NavigationService';
import { BasicRouteLoader } from '@root/Services/Router/BasicRouteLoader';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { Link } from '@root/Services/Router/Links';
import { ConnectionWizardFooterText, ConnectionWizardStepper, WizardHeader } from './Common';
import { useCallback, useEffect, useMemo, useState } from 'react';

export function ConnectRM() {
    const { platform } = useNav().getData('platform');
    const { getMoveUrl } = useNav();
    const configSvc = useDi(ConfigService);
    const company = useCompany();

    const [connState, setConnState] = useState<LoadingState>(LoadingState.loading);
    const [connMsg, setConnMsg] = useState<string>('Awaiting connection');
    const [cliScriptWin, setCliScriptWin] = useState<string>('');
    const [cliScriptMac, setCliScriptMac] = useState<string>('');
    const [cliScriptLinux, setCliScriptLinux] = useState<string>('');
    const connChecker = useMemo(
        () => ({
            connInfo: null as CliConnectionInfo | null,
            pendingConn: null as PendingCliConnection | null,
            shouldPoll: true,
            poll() {
                setTimeout(async () => {
                    if (!this.shouldPoll) {
                        return;
                    }
                    try {
                        if (this.connInfo) {
                            if (this.pendingConn && this.pendingConn.MasterAccount && this.pendingConn.ExternalId) {
                                const assumeResult = await getConnectionTryAssumeRole({
                                    awsAccountId: this.pendingConn.MasterAccount,
                                    externalId: this.pendingConn.ExternalId,
                                });
                                if (assumeResult?.Success) {
                                    setConnState(LoadingState.check);
                                    setConnMsg('Connection successful');
                                    this.shouldPoll = false;
                                }
                            } else {
                                this.pendingConn = await postConnectionGetPendingConnection({ token: this.connInfo.Token! });
                            }
                        }
                    } finally {
                        this.poll();
                    }
                }, 3000);
            },
            stopPolling() {
                this.shouldPoll = false;
            },
        }),
        []
    );

    useEffect(() => {
        (async () => {
            const baseUrl = configSvc.config.apis['Resources'].baseUrl;
            const connInfo = await getConnectionGetCliConnectionInfo();
            connChecker.connInfo = connInfo;
            const [, creds] = (connInfo.Creds ?? '').split(' ');
            const [name, value] = creds.replace(/"/g, '').split('=');
            const domain = new URL(baseUrl).hostname;
            setCliScriptWin(
                `$session=New-Object Microsoft.PowerShell.Commands.WebRequestSession; $cookie=New-Object System.Net.Cookie; $cookie.Name='${name}'; $cookie.Domain='${domain}'; $cookie.Value='${value}'; $session.Cookies.Add($cookie); iwr ${baseUrl}/connection/scripts/${connInfo.Token}/cs-connector.ps1 -WebSession $session -OutFile .\cs-connector.ps1; powershell .\cs-connector.ps1`
            );
            const linux = `curl ${connInfo.Creds} -L ${baseUrl}/connection/scripts/${connInfo.Token}/cs-connector.sh | sudo bash`;
            setCliScriptLinux(linux);
            setCliScriptMac(linux);
        })();
    }, []);

    useEffect(() => {
        connChecker.poll();
        return () => connChecker.stopPolling();
    }, []);

    const routeLoader = useDi(BasicRouteLoader);
    const { goto } = useNav(routeLoader.getTopRouteMeta());
    const myCompaniesUrl = '/' + company?.DatabaseName!.toLowerCase() + '/msp/my-companies';
    const ReturnToMspPage = useCallback((e: { preventDefault: () => void }) => {
        goto(myCompaniesUrl);
        e.preventDefault();
    }, []);

    return (
        <PageContent>
            <WizardHeader />
            <WizardForm>
                <ConnectionWizardStepper step={3} platform={platform!} />
                <WizardFormTitle title={'Connect to ' + platform + ' Using Resource Manager'}></WizardFormTitle>
                <WizardFormStepContainer>
                    <WizardFormStep title="1. Use Resource Manager to connect" infoText="Resource Manager" infoSize="small">
                        <Space h="md" />
                    </WizardFormStep>
                    <Divider />
                    <WizardFormFooter>
                        <Group position="apart">
                            <ConnectionWizardFooterText company={company!} />
                            {company?.Type !== CompanyType.Msp ? (
                                <Button
                                    component={Link}
                                    href={getMoveUrl('connections')}
                                    disabled={connState !== LoadingState.check}
                                    style={{ pointerEvents: connState !== LoadingState.check ? 'none' : undefined }}
                                >
                                    View connections
                                </Button>
                            ) : (
                                <Button
                                    disabled={connState !== LoadingState.check}
                                    type="submit"
                                    onClick={ReturnToMspPage}
                                    style={{ pointerEvents: connState !== LoadingState.check ? 'none' : undefined }}
                                >
                                    View connections
                                </Button>
                            )}{' '}
                        </Group>
                    </WizardFormFooter>
                </WizardFormStepContainer>
            </WizardForm>
        </PageContent>
    );
}
endpoint('connect-rm', ConnectRM, 'Connection Wizard');

import { postResourcesGetChildResources, postResourcesGetParentResource, postResourcesTagResources } from '@apis/Resources';
import { BaseResource, ResourceIdentifier, Tag, TagRename, TagValueReplace } from '@apis/Resources/model';
import styled from '@emotion/styled';
import { Box, Button, CloseButton, createStyles, Divider, Group, LoadingOverlay, Space, Text, ThemeIcon } from '@mantine/core';
import { CompanyAddress } from '@root/Components/CompanyInfo/CompanyAddress';
import { DataGrid } from '@root/Components/DataGrid';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { ColumnConfig } from '@root/Components/DataGrid/Models';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { PagePanel, PanelBody } from '@root/Design/Layout';
import { colorPalette } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { useEvent } from '@root/Services/EventEmitter';
import { JobService } from '@root/Services/Jobs/JobService';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { useEffect, useState } from 'react';
import { ListCheck } from 'tabler-icons-react';
import { CopyTagsProps } from '../Components/BulkTagProps';
import { OpenSidebar } from '../Components/OpenSidebar';
import { ResourceTagService } from '../Services/ResourceTagService';

interface TagKeyValueItem {
    TagKey: string;
    TagValue: string;
}

interface CopyAddTagsJobs {
    resourceId: ResourceIdentifier;
    tagsToAdd: Tag[];
}

interface CopyReplaceTagsJobs {
    resourceId: ResourceIdentifier;
    tagsToReplaceValues: TagValueReplace[];
}

export function CopyTags(props: CopyTagsProps) {
    const { classes, theme } = useStyles();
    const company = useCompany();
    const [gridData, setGridData] = useState<TagKeyValueItem[]>();
    const [defaultColumns, setDefaultColumns] = useState<ColumnConfig<TagKeyValueItem>[]>([]);
    const [grid, setGrid] = useState<DataGridModel>();
    const [selectedTagItems, setSelectedTagItems] = useState<TagKeyValueItem[]>([]);
    const [disableParentButton, setDisableParentButton] = useState(false);
    const [disableChildrenButton, setDisableChildrenButton] = useState(false);
    const [parentResource, setParentResource] = useState<BaseResource>();
    const [childResources, setChildResources] = useState<BaseResource[]>();
    const notificationSvc = useDi(NotificationService);
    const jobService = useDi(JobService);
    const tagSvc = useDi(ResourceTagService);

    useEffect(() => {
        (async () => {
            const data = getResourceTags(props.resource);
            setGridData(data);
            setParentResource(await getParentResource(props.resource, company?.Id!));
            setChildResources(await getChildResources(props.resource, company?.Id!));
        })();
    }, []);

    useEvent(grid?.selectionChanged, async () => {
        const selectedTagItems = (await grid?.selections.getSelected()) as TagKeyValueItem[];
        setSelectedTagItems(selectedTagItems);
    });

    useEffect(() => {
        if (selectedTagItems!.length > 0 && props.resource.ParentResourceId != null && props.resource.ParentResourceId != undefined) {
            setDisableParentButton(false);
        } else if (selectedTagItems!.length === 0 || props.resource.ParentResourceId == null || props.resource.ParentResourceId == undefined) {
            setDisableParentButton(true);
        }

        if (selectedTagItems!.length > 0 && props.resource.ChildResourceIds != undefined && props.resource.ChildResourceIds.length > 0) {
            setDisableChildrenButton(false);
        } else if (selectedTagItems!.length === 0 || props.resource.ChildResourceIds == undefined || props.resource.ChildResourceIds == null) {
            setDisableChildrenButton(true);
        }
    }, [selectedTagItems, parentResource, childResources]);

    function clearSelection() {
        setSelectedTagItems([]);
    }

    useEffect(() => {
        (async () => {
            const columnResult: ColumnConfig<TagKeyValueItem>[] = [
                {
                    header: 'Tag Key',
                    accessor: 'TagKey',
                    defaultWidth: 200,
                    id: 'TagKey',
                    type: 'string',
                    align: 'left',
                    filter: {
                        filterField: 'TagKey',
                        filterType: 'string',
                        name: 'TagKey',
                    },
                },
                {
                    header: 'Tag Value',
                    accessor: 'TagValue',
                    defaultWidth: 150,
                    id: 'TagValue',
                    type: 'string',
                    align: 'left',
                    filter: {
                        filterField: 'TagValue',
                        filterType: 'string',
                        name: 'TagValue',
                    },
                },
            ];
            setDefaultColumns(columnResult);
        })();
    }, []);

    const copyTagsToParent = () => {
        const tagsToAddToResource: Tag[] = [];
        const tagsToReplaceValuesToResource: TagValueReplace[] = [];
        const parentTags = getResourceTags(parentResource!);

        selectedTagItems.forEach((selectedTagItem) => {
            if (parentTags.filter((p) => p.TagKey === selectedTagItem.TagKey).length > 0) {
                var parentTag = parentTags.find((p) => p.TagKey === selectedTagItem.TagKey);
                if (parentTag?.TagValue != selectedTagItem.TagValue) {
                    tagsToReplaceValuesToResource.push({
                        Key: selectedTagItem.TagKey,
                        Values: [parentTag!.TagValue],
                        Replacement: selectedTagItem.TagValue,
                    });
                }
            } else {
                tagsToAddToResource.push({ Key: selectedTagItem.TagKey, Value: selectedTagItem.TagValue });
            }
        });

        const resourceId = {
            CloudPlatform: parentResource?.CloudPlatform,
            ResourceID: parentResource!.Id!,
            ResourceType: parentResource!.ResourceType,
        } as ResourceIdentifier;

        if (tagsToAddToResource.length > 0 || tagsToReplaceValuesToResource.length > 0) {
            postResourcesTagResources({ ResourceIds: [resourceId], AddTags: tagsToAddToResource, OverwriteConflicts: true });

            tagsToReplaceValuesToResource.forEach((replaceValue) => {
                postResourcesTagResources({ ResourceIds: [resourceId], ReplaceValues: replaceValue, OverwriteConflicts: true });
            });

            notificationSvc.notify(
                'Tag Update Task',
                `Tag Copy to Parent job for 1 resource was submitted. Please check the activity log for status details.`,
                'primary',
                <ThemeIcon style={{ backgroundColor: theme.colors.primary[2] }} variant="light" size="xl" radius="xl">
                    <ListCheck style={{ color: theme.colors!.primary![8] }} />
                </ThemeIcon>
            );
            jobService.notifyNewJob();
        } else {
            notificationSvc.notify(
                'Tag Update Task',
                `No Tag Copy job was submitted. All selected tags already exist on parent.`,
                'error',
                <ThemeIcon style={{ backgroundColor: theme.colors.primary[2] }} variant="light" size="xl" radius="xl">
                    <ListCheck style={{ color: theme.colors!.primary![8] }} />
                </ThemeIcon>
            );
        }
        props.onButtonClick(OpenSidebar.undefined, false);
    };

    const copyTagsToChildren = () => {
        const resourceIds =
            childResources?.map((c) => ({ CloudPlatform: c.CloudPlatform, ResourceId: c.Id, ResourceType: c.ResourceType } as ResourceIdentifier)) ??
            [];
        const tags = selectedTagItems.map((t) => ({ Key: t.TagKey, Value: t.TagValue } as Tag)) ?? [];

        if (resourceIds.length && tags.length) {
            postResourcesTagResources({
                ResourceIds: resourceIds,
                AddTags: tags,
                OverwriteConflicts: true,
            });

            notificationSvc.notify(
                'Tag Update Task',
                `Tag Copy to Children job for ${childResources?.length} ${
                    childResources!.length === 1 ? 'resource' : 'resources'
                } was submitted. Please check the activity log for status details.`,
                'primary',
                <ThemeIcon style={{ backgroundColor: theme.colors.primary[2] }} variant="light" size="xl" radius="xl">
                    <ListCheck style={{ color: theme.colors!.primary![8] }} />
                </ThemeIcon>
            );
            jobService.notifyNewJob();
        }
        props.onButtonClick(OpenSidebar.undefined, false);
    };

    return (
        <PagePanel size="fill">
            <TagActionContainer>
                <Group position="apart" p="lg">
                    <Text size={20} weight={600}>
                        {props.pageTitle}
                    </Text>
                    <CloseButton mr="xl" onClick={() => props.onButtonClick(OpenSidebar.undefined, false)} />
                </Group>
                <Divider />
                <PanelBody style={{ marginLeft: 0 }}>
                    {!gridData ? (
                        <LoadingOverlay visible></LoadingOverlay>
                    ) : (
                        <DataGrid
                            displayMode="grid"
                            dataSource={gridData! ?? []}
                            selectionMode={'multiple'}
                            showHeaderGroups={false}
                            columns={defaultColumns ?? []}
                            hideMenu={true}
                            onModelLoaded={setGrid}
                        ></DataGrid>
                    )}
                </PanelBody>
                <Divider />
                <Box p="lg">
                    <Button fullWidth disabled={disableParentButton} onClick={() => copyTagsToParent()}>
                        Copy Tags to Parent
                    </Button>
                    <Space w="xs" h="xs" />
                    <Button fullWidth disabled={disableChildrenButton} onClick={() => copyTagsToChildren()}>
                        Copy Tags to Children
                    </Button>
                </Box>
            </TagActionContainer>
        </PagePanel>
    );
}

async function getChildResources(baseResource: BaseResource, companyId: number) {
    const results = await postResourcesGetChildResources(baseResource, { companyId: companyId });
    return results;
}

async function getParentResource(baseResource: BaseResource, companyId: number) {
    const result = await postResourcesGetParentResource(baseResource, { companyId: companyId });
    return result;
}

function getResourceTags(baseResource: BaseResource) {
    return Object.entries(baseResource.CsTags!).map(([k, v]) => ({
        TagKey: k,
        TagValue: v,
    })) as TagKeyValueItem[];
}

const useStyles = createStyles((theme) => ({
    textColor: {
        color: colorPalette.subHeaderTextColor,
    },

    footer: {
        position: 'absolute',
        bottom: 0,
    },

    keyValues: {
        marginTop: `${theme.spacing.xs}px`,
    },
}));

export const TagActionContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const TagActionHeader = styled.div`
    height: 40px;
    justify-content: start;
    align-items: center;
    font-size: 20px;
    padding: 10px 0;
`;
export const TagActionSubHeader = styled.div`
    justify-content: start;
    align-items: center;
    font-size: 16px;
    color: ${colorPalette.subHeaderTextColor};
`;

export const TagActionBody = styled.div`
    justify-content: start;
    align-items: center;
    margin-top: ${(p) => p.theme.spacing.xs}px;
    margin-bottom: ${(p) => p.theme.spacing.xs}px;

    Text {
        color: ${colorPalette.subHeaderTextColor};
    }
`;

export const TagActionFooter = styled.div`
    justify-content: start;
    align-items: center;
    position: 'absolute';
    bottom: 0;
`;

import { MapContractDailySnapShot } from '@apis/TagManager/model';
import { makeAutoObservable, toJS } from 'mobx';
import { Lifecycle, scoped } from 'tsyringe';

export enum TaggingDisplayTypes {
    AnnualizedSpend = 'Annualized Spend',
    Resources = 'Resources',
    Percent = 'Percent',
}

export enum TaggingDisplayPercent {
    TotalResources = 'Total Resources',
    AnnualizedSpend = 'Annualized Spend',
}

export interface TagMetric {
    selected: boolean;
    name: string;
    annualizedSpend: number;
    resources: number;
    resourcesPercent: number;
    annualizedSpendPercent: number;
    actionable: boolean;
    color: string;
}

@scoped(Lifecycle.ContainerScoped)
export class TaggingSectionModel {
    public displayType: TaggingDisplayTypes = TaggingDisplayTypes.AnnualizedSpend;
    public displayPercent: TaggingDisplayPercent = TaggingDisplayPercent.TotalResources;
    public dateRange: { from?: Date; to?: Date } = {};
    public metrics: TagMetric[] = [];

    private totalAnnualizedSpend: number = 0.0;
    private totalResources: number = 0.0;

    public constructor() {
        makeAutoObservable(this);
    }

    public setDisplayType(value: any) {
        this.displayType = value as unknown as TaggingDisplayTypes;
    }

    public setDisplayPercent(value: any) {
        this.displayPercent = value as unknown as TaggingDisplayPercent;
    }

    public setDateRange(value: { from?: Date; to?: Date }) {
        this.dateRange = value;
    }

    public setMetrics(snapshot: MapContractDailySnapShot | null) {
        this.metrics = [];

        if (!snapshot || !snapshot.Arr) return;

        const eligibleAnnualizedSpend = snapshot.Arr.TotalEligibleAnnualizedSpend ?? 0.0;
        const eligibleResources = snapshot.EligibleTotalCount ?? 0.0;
        const ineligibleAnnualizedSpend = snapshot.Arr.TotalIneligibleAnnualizedSpend ?? 0.0;
        const ineligibleResources = snapshot.IneligibleTotalCount ?? 0.0;
        this.totalAnnualizedSpend = eligibleAnnualizedSpend + ineligibleAnnualizedSpend;
        this.totalResources = eligibleResources + ineligibleResources;

        this.addMetric('Eligible', eligibleAnnualizedSpend, eligibleResources, '#0411c4', true);
        this.addMetric(
            'Tagged Correctly',
            snapshot.Arr.EligibleTaggedCorrectAnnualizedSpend ?? 0.0,
            snapshot.EligibleTaggedCorrectCount ?? 0.0,
            '#00609F',
            true
        );
        this.addMetric(
            'Tagged Incorrectly',
            snapshot.Arr.EligibleTaggedIncorrectAnnualizedSpend ?? 0.0,
            snapshot.EligibleTaggedIncorrectCount ?? 0.0,
            '#009FE1',
            true,
            true
        );
        this.addMetric(
            'Not Tagged',
            snapshot.Arr.EligibleNotTaggedAnnualizedSpend ?? 0.0,
            snapshot.EligibleNotTaggedCount ?? 0.0,
            '#B0E8FF',
            true,
            true
        );
        this.addMetric('Ineligible', ineligibleAnnualizedSpend, ineligibleResources, '#7A2E0E', true);
        this.addMetric(
            'Created Prior to Contract',
            snapshot.Arr.IneligibleOutsideContractPeriodAnnualizedSpend ?? 0.0,
            snapshot.IneligibleOutsideContractPeriodCount ?? 0.0,
            '#B54708',
            false
        );
        this.addMetric(
            'Not Covered Service',
            snapshot.Arr.IneligibleNotCoveredAnnualizedSpend ?? 0.0,
            snapshot.IneligibleNotCoveredCount ?? 0.0,
            '#DC6803',
            false
        );
        this.addMetric(
            'Not Eligible Workload',
            snapshot.Arr.IneligibleNotEligibleWorkloadAnnualizedSpend ?? 0.0,
            snapshot.IneligibleNotEligibleWorkloadCount ?? 0.0,
            '#FDB022',
            false
        );
        this.addMetric(
            'Over-Tagged Spend',
            snapshot.Arr.IneligibleOvertaggedAnnualizedSpend ?? 0.0,
            snapshot.IneligibleOvertaggedCount ?? 0.0,
            '#FEDF89',
            true,
            true
        );
        this.addMetric('Total', this.totalAnnualizedSpend, this.totalResources, '#000000', true);
    }

    private addMetric(name: string, annualizedSpend: number, resources: number, color: string, selected: boolean, actionable: boolean = false) {
        let resourcesPercent = this.totalResources > 0 ? resources / this.totalResources : 0;
        let annualizedSpendPercent = this.totalAnnualizedSpend > 0 ? annualizedSpend / this.totalAnnualizedSpend : 0;

        this.metrics.push({
            selected,
            name,
            annualizedSpend,
            resources,
            resourcesPercent,
            annualizedSpendPercent,
            color,
            actionable,
        });
    }
}

import { BaseResource } from '@apis/Resources/model';
import { Table } from '@mantine/core';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { NoDataMessage, ResourceDetailSection } from '../Design';

interface AwsEmrEc2Instance {
    Ec2InstanceId?: string;
    Market?: { Value: string };
    InstanceType: string;
    Status?: {
        State?: { Value: string };
    };
}
export function AwsEmrResources({ resource }: { resource: BaseResource }) {
    const emr = resource as { Instances: AwsEmrEc2Instance[] };

    return (
        <ResourceDetailSection>
            {!emr.Instances?.length ? (
                <NoDataMessage>No Instances Found</NoDataMessage>
            ) : (
                <Table verticalSpacing="sm" horizontalSpacing="lg">
                    <thead>
                        <tr>
                            <th>Resource ID</th>
                            <th>Markte</th>
                            <th>Instance Type</th>
                            <th>State</th>
                        </tr>
                    </thead>
                    <tbody>
                        {emr.Instances.map((val, key) => {
                            return (
                                <tr key={key}>
                                    <td>{val.Ec2InstanceId}</td>
                                    <td>{val.Market?.Value}</td>
                                    <td>{val.InstanceType}</td>
                                    <td>{val.Status?.State?.Value}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            )}
        </ResourceDetailSection>
    );
}

export function ResourceResources() {
    return <>Resources</>;
}
endpoint('resource', ResourceResources, 'Resource Resources');

import { QueryExpr } from '@apis/Resources';
import { Accordion, ActionIcon, Anchor, Box, Group, TextInput, ThemeIcon, Tooltip } from '@mantine/core';
import { KpiChartSettings } from '@root/Components/Charts/KpiChart';
import { DataFilters, AddFilterButton } from '@root/Components/Filter/Filters';
import { FieldPicker } from '@root/Components/Picker/FieldPicker';
import { useEvent } from '@root/Services/EventEmitter';
import { observer } from 'mobx-react';
import { useCallback, useMemo } from 'react';
import { Plus } from 'tabler-icons-react';
import { AggregateOperation, DropdownSettings, EditorField } from './Components';
import { SettingsLabel, SettingsDivider } from './Design';
import { ChartEditor } from './Models';

export const KpiSettings = observer(function KpiSettings({ editor }: { editor: ChartEditor }) {
    const settings = useMemo(() => editor.getChartSettings<KpiChartSettings>(), []);
    const addItem = useCallback(() => {
        const nextIdx = editor.settings.values.length;
        const chartSettings = editor.settings.settings as KpiChartSettings;
        editor.setValue(nextIdx, { Expr: { Operation: 'Count' }, ...editor.settings.values[nextIdx - 1], Alias: 'Value' + nextIdx });
        settings.labels.push('');
        settings.valueFilters.push(chartSettings.valueFilters[nextIdx - 1] ?? []);
        settings.format.push(chartSettings.format[nextIdx - 1] ?? undefined);
    }, [settings, editor]);
    useEvent(editor.onSettingsChanged);
    return (
        <>
            <Accordion.Item value="Data">
                <Accordion.Control value="Data">Data</Accordion.Control>
                <Accordion.Panel>
                    <SettingsDivider />
                    {editor.settings.values.map((v, i) => (
                        <Box key={i}>
                            <KpiSettingsItem editor={editor} index={i} settings={settings} />
                            <SettingsDivider />
                        </Box>
                    ))}
                    <Anchor onClick={addItem}>
                        <Plus size={15} /> Add a KPI
                    </Anchor>
                </Accordion.Panel>
            </Accordion.Item>
        </>
    );
});
const KpiSettingsItem = observer(function KpiSettings(props: { editor: ChartEditor; index: number; settings: KpiChartSettings }) {
    const { editor, index, settings } = props;
    const expr = editor.getValue(index);
    useEvent(editor.onSettingsChanged);
    function setItemAtIndex<T>(item: T, filler: T, index: number, items: T[]) {
        if (items.length <= index) {
            items.push(...Array(index - items.length + 1).fill(filler));
        }
        items[index] = item;
    }
    const applyFilters = useCallback(
        (filters: QueryExpr[]) => {
            setItemAtIndex(filters, [], index, settings.valueFilters);
        },
        [index, settings.valueFilters]
    );
    const applyLabel = useCallback(
        (label: string) => {
            setItemAtIndex(label, '', index, settings.labels);
        },
        [index, settings.labels]
    );
    const remove = useCallback(() => {
        settings.labels.splice(index, 1);
        settings.valueFilters.splice(index, 1);
        settings.format.splice(index, 1);
        editor.removeValue(index);
    }, [index, settings]);
    const setValue = (expr: QueryExpr) => {
        if ('Operation' in expr && expr.Operation === 'percent') {
            settings.format[index] = 'percent';
        } else {
            settings.format[index] = undefined;
        }
        editor.setValue(index, { Alias: 'Value', Expr: expr });
    };

    return (
        <Box>
            <Group position="apart">
                <SettingsLabel size="xs">KPI {index + 1}</SettingsLabel>
                <Tooltip label="Remove KPI">
                    <ActionIcon onClick={remove}>
                        <i className="ti ti-x" />
                    </ActionIcon>
                </Tooltip>
            </Group>
            <Group position="apart">
                <SettingsLabel>Label</SettingsLabel>
                <TextInput
                    size="xs"
                    value={settings.labels.length > index ? settings.labels[index] : ''}
                    onChange={(e) => applyLabel(e.currentTarget.value)}
                />
            </Group>
            <Group position="apart" noWrap align="start">
                <SettingsLabel>Value</SettingsLabel>
                <Box>
                    <DataFilters
                        align="end"
                        valueProvider={editor.valueProvider}
                        fieldInfoProvider={editor.queryDescriptorSvc.fieldInfoProvider}
                        filters={settings.valueFilters.length > index ? settings.valueFilters[index] : []}
                        onChange={applyFilters}
                        renderFieldPicker={(select) => (
                            <Box>
                                <FieldPicker mode="single" selections={[]} schema={editor.shemaSvc} onChange={([f]) => select(f.path)} />
                            </Box>
                        )}
                        renderAddFilter={(model) => (
                            <>
                                <AggregateOperation editor={editor} expr={expr.Expr as QueryExpr} types={['number']} onChange={setValue} />
                                <AddFilterButton onClick={model.addEmptyFilter} />
                            </>
                        )}
                    />
                </Box>
            </Group>
            <DropdownSettings
                options={[
                    { label: 'Currency', value: 'money' },
                    { label: 'Currency (whole)', value: 'money-whole' },
                    { label: 'Percent', value: 'percent' },
                ]}
                label="Format"
                onChange={(value) => (settings.format[index] = value as any)}
                value={typeof settings.format[index] === 'string' ? (settings.format[index] as string) : undefined}
            />
        </Box>
    );
});

import { getMetadataIntegrationGetSchema } from '@apis/Resources';
import { SchemaType } from '@apis/Resources/model';
import { inject, injectable } from 'tsyringe';
import { FormatService } from '../FormatService';

@injectable()
export class IntegrationSchemaService {
    public constructor(@inject(FormatService) private readonly fmtSvc: FormatService) {}

    public async getIntegrationSchema() {
        const integrationSchemas = await getMetadataIntegrationGetSchema();
        this.adjustFields(integrationSchemas);
        return integrationSchemas;
    }

    private adjustFields(types: SchemaType[]) {
        for (const type of types) {
            if (type.Fields) {
                for (const field of type.Fields) {
                    field.Field = field.Name;
                    field.Name = this.fmtSvc.userFriendlyCamelCase(field.Name ?? field.Field ?? '');
                }
            }
        }
    }
}

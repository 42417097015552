import { BaseResource } from '@apis/Resources/model';
import { getVariantGroupGetVariantGroup, postVariantGroupAddVariantsToVariantGroup, putVariantGroupUpdateVariantGroup } from '@apis/TagManager';
import { Variant, VariantGroup } from '@apis/TagManager/model';
import { Button, Input, Modal, TextInput } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { DataGrid } from '@root/Components/DataGrid';
import { ColumnConfig, DataGridProps } from '@root/Components/DataGrid/Models';
import { useDiComponent } from '@root/Services/DI';
import { makeAutoObservable } from 'mobx';
import { Observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import { VariantViewModel, VGViewModel } from '../ViewModels/ViewModels';
import { VariantForm } from './VariantForm';

export function VariantGroupGrid(props: Omit<DataGridProps<VGViewModel | VariantViewModel | BaseResource>, 'columns'>) {
    const changes = useMemo(() => {
        return makeAutoObservable({
            changedGroups: new Set<VGViewModel>(),
            add(group: VGViewModel) {
                this.changedGroups.add(group);
            },
            clear() {
                this.changedGroups.clear();
            },
        });
    }, []);

    const modals = useModals();
    const DiContainer = useDiComponent();
    const openAddVariantModal = (item: VGViewModel) => {
        const id = modals.openModal({
            title: 'Add Variant',
            children: (
                <DiContainer>
                    <VariantForm onClose={() => modals.closeModal(id)} model={item}></VariantForm>
                </DiContainer>
            ),
        });
    };

    const columns = useMemo(
        () =>
            [
                {
                    header: 'Primary String',
                    cellRenderer: (item) => {
                        if ('PrimaryString' in item) {
                            return (
                                <Observer
                                    render={() => {
                                        return <div>{'PrimaryString' in item ? item.PrimaryString + '' : null}</div>;
                                    }}
                                />
                            );
                        } else {
                            return null;
                        }
                    },

                    defaultWidth: 100,
                    sortField: 'PrimaryString',
                    rowGroup: true,
                },
                {
                    header: 'Variant Count',
                    accessor: 'variantCount',
                    defaultWidth: 100,
                    sortField: 'variantCount',
                },
                {
                    header: '',
                    cellRenderer: (item) => {
                        if ('PrimaryString' in item) {
                            return (
                                <Observer
                                    render={() => {
                                        return (
                                            <>
                                                <Button type="submit" onClick={() => openAddVariantModal(item as VGViewModel)}>
                                                    Add Variant
                                                </Button>
                                            </>
                                        );
                                    }}
                                />
                            );
                        } else {
                            return null;
                        }
                    },
                    defaultWidth: 200,
                },
                {
                    header: 'Variant Name',
                    accessor: 'VariantString',
                    defaultWidth: 100,
                    sortField: 'VariantString',
                },
                {
                    header: 'Primary',
                    cellRenderer: (item: VariantViewModel) => {
                        if ('IsPrimary' in item) {
                            return (
                                <Observer
                                    render={() => {
                                        return (
                                            <input
                                                type={'radio'}
                                                name={item.VariantGroupId?.toString()}
                                                checked={item.IsPrimary}
                                                value={item.IsPrimary ? 'true' : 'false'}
                                                onChange={() => {
                                                    item.group().setPrimary(item);
                                                    changes.add(item.group());
                                                }}
                                            />
                                        );
                                    }}
                                />
                            );
                        } else {
                            return null;
                        }
                    },
                    defaultWidth: 100,
                },
                {
                    header: 'Disassociate',
                    cellRenderer: (item: VariantViewModel) => {
                        if ('IsPrimary' in item) {
                            return (
                                <Observer
                                    render={() => (
                                        <input
                                            type={'checkbox'}
                                            name={item.VariantGroupId?.toString()}
                                            checked={item.IsDeleted}
                                            value={item.IsDeleted ? 'true' : 'false'}
                                            onChange={(e) => {
                                                if ('group' in item) {
                                                    item.IsDeleted = e.currentTarget.checked;
                                                    changes.add(item.group());
                                                }
                                            }}
                                        />
                                    )}
                                />
                            );
                        } else {
                            return null;
                        }
                    },
                    defaultWidth: 100,
                },
                {
                    header: 'Resources Count',
                    accessor: 'Count',
                    defaultWidth: 100,
                },
                {
                    groupName: 'Resources',
                    header: 'Resource Id',
                    cellRenderer: (item) => {
                        if ('Id' in item) {
                            return item.Id;
                        }
                    },
                    defaultWidth: 100,
                    sortField: 'Id',
                },
                {
                    groupName: 'Resources',
                    header: 'Cloud Platform',
                    accessor: 'CloudPlatform',
                    defaultWidth: 100,
                    sortField: 'CloudPlatform',
                },
                {
                    groupName: 'Resources',
                    header: 'Resource Type',
                    accessor: (item) => ('ResourceType' in item ? item.ResourceType : ''),
                    defaultWidth: 100,
                    sortField: 'ResourceType',
                    filter: { filterField: 'ResourceType', filterType: 'string' },
                },
            ] as ColumnConfig<VGViewModel | VariantViewModel | BaseResource>[],
        []
    );
    return (
        <>
            <DataGrid {...{ columns, ...props }} />
            <Observer
                render={() => (
                    <Button
                        type="submit"
                        disabled={changes.changedGroups.size === 0}
                        onClick={() => {
                            updateVariantGroups(changes.changedGroups);
                            changes.clear();
                        }}
                    >
                        Save
                    </Button>
                )}
            />
        </>
    );
}

async function updateVariantGroups(changedGroups: Set<VGViewModel>) {
    const VGs: VariantGroup[] = getVGs(changedGroups);
    for (const VG of VGs) {
        await putVariantGroupUpdateVariantGroup(VG);
    }
}

function getVGs(changedGroups: Set<VGViewModel>): VariantGroup[] {
    const VGs: VariantGroup[] = new Array();
    for (const gVM of changedGroups) {
        const VG: VariantGroup = {
            Id: gVM.Id,
            CompanyId: gVM.CompanyId,
            ParentKey: gVM.PrimaryString,
            Variants: MapVarients(gVM.Variants),
        };
        VGs.push(VG);
    }
    return VGs;
}

function MapVarients(Variants: VariantViewModel[]): Variant[] {
    const Vars: Variant[] = new Array();
    for (const VVM of Variants) {
        const VariantModel: Variant = {
            Id: VVM.Id as number,
            VariantGroupId: VVM.VariantGroupId as number,
            VariantString: VVM.VariantString as string,
            IsPrimary: VVM.IsPrimary as boolean,
            Count: VVM.Count as number,
            IsDeleted: VVM.IsDeleted as boolean,
        };
        Vars.push(VariantModel);
    }
    return Vars;
}

export async function PutVariant(values: any, item: VGViewModel): Promise<void> {
    const VariantFromForm: Variant = {
        VariantGroupId: item.Id,
        VariantString: values.variantName as string,
        IsPrimary: false,
        Count: 0,
        IsDeleted: false,
    };

    const Vars: Variant[] = new Array();
    Vars.push(VariantFromForm);

    await postVariantGroupAddVariantsToVariantGroup(Vars);
}

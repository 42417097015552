import { QuerySelectExpr } from '@apis/Jobs/model';
import { QueryExpr } from '@apis/Resources';
import { Accordion, Group, SegmentedControl, Select, SelectItem } from '@mantine/core';
import { LineChartSettings } from '@root/Components/Charts/LineChart';
import { useEvent } from '@root/Services/EventEmitter';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { ChartMargins, DropdownSettings, EditorSelectExpr, FilterableAggregate, FilterSettings, NumberSettings } from './Components';
import { SettingsDivider, SettingsLabel } from './Design';
import { ChartEditor } from './Models';

export const LineSettings = observer(function LineSettings({ editor }: { editor: ChartEditor }) {
    const settings = useMemo(() => editor.getChartSettings<LineChartSettings>(), []);
    const intervalOptions = useMemo(
        () =>
            [
                { value: 'day', label: 'Day' },
                { value: 'week', label: 'Week' },
                { value: 'month', label: 'Month' },
            ] as SelectItem[],
        []
    );
    const xAxis = editor.getGroup(0)?.Expr as QueryExpr;
    const isDate = xAxis && editor.queryDescriptorSvc.getExprType(xAxis) === 'date';
    const handleValueSet = (expr: QuerySelectExpr) => {
        const operation = expr.Expr && 'Operation' in expr.Expr && expr.Expr.Operation;
        editor.setValue(0, expr);
        settings.format =
            operation === 'percent'
                ? 'percent'
                : operation === 'count'
                ? 'int'
                : operation === 'countvalues'
                ? 'int'
                : operation === 'countuniquevalues'
                ? 'int'
                : undefined;
    };
    useEvent(editor.onSettingsChanged);
    return (
        <>
            <Accordion.Item value="Data">
                <Accordion.Control value="Data">Data</Accordion.Control>
                <Accordion.Panel>
                    <Group position="apart">
                        <SettingsLabel>X-Axis</SettingsLabel>
                        <EditorSelectExpr editor={editor} expr={editor.getGroup(0)} types={['date']} onChange={(x) => editor.setGroup(0, x)} />
                    </Group>
                    {!isDate ? null : (
                        <Group position="apart">
                            <SettingsLabel>Interval</SettingsLabel>
                            <Select
                                sx={{ width: 100 }}
                                size="xs"
                                value={settings.interval ?? 'month'}
                                onChange={(n) => (settings.interval = n ?? undefined)}
                                data={intervalOptions}
                            ></Select>
                        </Group>
                    )}
                    <Group position="apart" noWrap align="start">
                        <SettingsLabel>Y-Axis</SettingsLabel>
                        <FilterableAggregate editor={editor} expr={editor.getValue(0)} onChange={handleValueSet} />
                    </Group>

                    <DropdownSettings
                        options={[
                            { label: 'Currency', value: 'money' },
                            { label: 'Currency (whole)', value: 'money-whole' },
                            { label: 'Percent', value: 'percent' },
                        ]}
                        label="Format"
                        onChange={(value) => (settings.format = value as any)}
                        value={settings.format}
                    />

                    <SettingsDivider />
                    <Group position="apart">
                        <SettingsLabel>Grouping</SettingsLabel>
                        <EditorSelectExpr
                            onRemove={() => editor.removeGroup(1)}
                            editor={editor}
                            expr={editor.getGroup(1)}
                            onChange={(x) => editor.setGroup(1, x)}
                        />
                    </Group>
                    <NumberSettings label="Top N" value={settings.topN} onChange={(value) => (settings.topN = value ?? undefined)} min={0} />
                    <Group position="apart">
                        <SettingsLabel>Stacking</SettingsLabel>
                        <SegmentedControl
                            value={settings.stacked ? 'stacked' : 'normal'}
                            data={[
                                { value: 'stacked', label: 'Stack' },
                                { value: 'normal', label: 'Overlap' },
                            ]}
                            onChange={(value) => (settings.stacked = value === 'stacked')}
                        />
                    </Group>
                    <SettingsDivider />
                    <FilterSettings editor={editor} />
                </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="Options">
                <Accordion.Control value="Options">Options</Accordion.Control>
                <Accordion.Panel>
                    <ChartMargins settings={settings} />
                </Accordion.Panel>
            </Accordion.Item>
        </>
    );
});

import { Component, createRef, CSSProperties, Fragment, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Node } from './Node';
import { VirtualRenderArea } from './VirtualRenderArea';
import { VirtualTreeModel } from './VirtualTreeModel';
import { IdGen } from '@root/Services/IdGen';

export class VirtualTree extends Component<TreeProps<TreeItemType>> {
    private model = new VirtualTreeModel<TreeItemType>(defaultCanExpand, defaultChildAccessor, false);
    private renderArea = new VirtualRenderArea();
    private visibleItems: Node<TreeItemType>[] = [];
    private loadingItems = new Set<TreeItemType>();
    private disposers: (() => void)[] = [];
    private stateAccessor: ITreeItemState<TreeItemType>;
    private idGen = new IdGen();
    private containerEl = createRef<HTMLDivElement>();
    private childrenLoaded = new WeakMap<TreeItemType, TreeItemType[] | undefined>();

    public constructor(props: TreeProps<any>) {
        super(props);
        this.model.onDataInvalidated = this.handleDataChanged;
        this.disposers.push(
            this.model.stateChanged.listen(this.invalidateView).dispose,
            this.renderArea.stateChanged.listen(this.invalidateView).dispose
        );
        this.stateAccessor = {
            isExpanded: (o) => this.model.isExpanded(o),
            isHighlighted: (o) => this.model.isHighlighted(o),
            isLoading: (o) => this.isItemLoading(o),
            isSelected: (o) => this.model.isSelected(o),
            model: this.model,
        };
        this.model.clearChildrenLoaded = () => (this.childrenLoaded = new WeakMap<TreeItemType, TreeItemType[] | undefined>());
    }

    public render() {
        const { topBuffer, totalHeight } = this.renderArea;
        const maxHeight = this.props.config.minimizeHeight ? Math.min(100000, this.model.items.length * this.renderArea.itemHeight) : undefined;
        return (
            <TreeContainerEl
                ref={this.containerEl}
                tabIndex={0}
                onKeyDown={this.handleKeyDown}
                onScroll={this.handleScroll}
                style={{ [`--tree-top-buffer`]: topBuffer + 'px', maxHeight, height: this.props.config.height } as CSSProperties}
            >
                <TreeBottomSpaceEl style={{ transform: `translateY(${(totalHeight || 0) - 1}px` }}></TreeBottomSpaceEl>
                {this.renderBody()}
            </TreeContainerEl>
        );
    }

    public componentDidMount() {
        this.initResizeListener();
        this.updateContainerSize();
        this.updateRenderAreaSettings();
        this.initModel();
        this.props.config.onModelLoaded?.(this.model);
    }

    public componentWillUnmount() {
        this.disposers.forEach((d) => d());
    }

    public componentDidUpdate(prevProps: TreeProps<any>) {
        this.updateModelSettings();
        if (this.props.data !== prevProps.data) {
            this.model.load(this.props.data);
            this.updateVisibleItems();
        }
        if (this.props.config.itemHeight !== prevProps.config.itemHeight) {
            this.updateRenderAreaSettings();
            this.updateVisibleItems();
        }
    }

    public invalidate() {
        this.updateModelSettings();
        this.model.invalidateData();
        this.updateVisibleItems();
    }

    public getContainer() {
        return this.containerEl;
    }

    public scrollToItem(item: TreeItemType) {
        const idx = this.model.getItemIndex(item);
        if (typeof idx === 'number' && idx > -1) {
            this.scrollToIndex(idx);
        }
    }

    public scrollToIndex(index: number) {
        const containerEl = this.containerEl.current;
        if (containerEl) {
            const { viewerHeight, itemHeight } = this.renderArea,
                itemTop = index * itemHeight,
                itemBottom = itemTop + itemHeight;

            if (itemTop < containerEl.scrollTop) {
                containerEl.scrollTop = itemTop;
            } else if (itemBottom > containerEl.scrollTop + viewerHeight) {
                containerEl.scrollTop = itemBottom - viewerHeight;
            }
        }
    }

    public scrollToSelected() {
        this.scrollToItem(this.model.getSelectedItem());
    }

    public invalidateSize() {
        this.updateContainerSize();
    }

    private initModel() {
        this.updateModelSettings();
        this.model.load(this.props.data);
    }

    private updateRenderAreaSettings() {
        this.renderArea.itemHeight = this.props.config.itemHeight;
    }

    private initResizeListener() {
        const onResize = () => {
            this.updateContainerSize();
        };
        window.addEventListener('resize', onResize);
        this.disposers.push(() => window.removeEventListener('resize', onResize));
    }

    private renderBody() {
        return this.props.config.renderBody ? (
            this.props.config.renderBody(this.visibleItems, this.stateAccessor)
        ) : (
            <TreeContentEl style={{ transform: `translateY(var(--tree-top-buffer))` }}>{this.visibleItems.map(this.renderChild)}</TreeContentEl>
        );
    }

    private renderChild = (node: Node<TreeItemType>, index: number) => {
        return <Fragment key={index}>{this.props.config.renderNode(node, this.stateAccessor)}</Fragment>;
    };

    private handleScroll = (evt: React.UIEvent<HTMLDivElement>) => {
        this.renderArea.scrollPos = evt.currentTarget.scrollTop;

        this.props.config.onScroll?.(evt.currentTarget.scrollLeft, evt.currentTarget.scrollTop);
        this.updateVisibleItems();
    };

    private handleKeyDown = (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === 'Enter') {
            this.model.selectHighlightedItem();
        } else if (evt.key.startsWith('Arrow')) {
            const direction = evt.key.replace('Arrow', ''),
                nextHighlightIdx = this.model.navigate(direction);

            if (nextHighlightIdx !== undefined) {
                this.scrollToIndex(nextHighlightIdx);
            }
            evt.preventDefault();
        }
    };

    private handleDataChanged = () => {
        this.renderArea.itemCount = this.model.items.length;
        this.updateVisibleItems();
    };

    private updateModelSettings() {
        const childAccessor = this.props.config.childAccessor || defaultChildAccessor;
        this.model.childAccessor = (item) => {
            if (this.childrenLoaded.has(item)) {
                return this.childrenLoaded.get(item);
            } else {
                this.loadingItems.add(item);
                const accessorResult = childAccessor(item);
                if (Array.isArray(accessorResult) || accessorResult === undefined) {
                    this.loadingItems.delete(item);
                    return accessorResult;
                } else {
                    accessorResult.then((children: undefined | TreeItemType[]) => {
                        this.loadingItems.delete(item);
                        this.childrenLoaded.set(item, children);
                        this.model.invalidateItem(item, true);
                    });
                    return [];
                }
            }
        };
        this.model.canExpand = this.props.config.canExpand || defaultCanExpand;
        this.model.lazyLoad = !!this.props.config.lazyLoad;
        if ('filter' in this.props) {
            this.model.setFilter(this.props.filter);
        }
    }

    private isItemLoading(item: TreeItemType) {
        return this.loadingItems.has(item);
    }

    private updateContainerSize() {
        if (this.containerEl.current) {
            const bounds = this.containerEl.current.getBoundingClientRect();
            if (!bounds.height && !bounds.bottom && this.renderArea.viewerHeight) {
                return;
            }
            if (this.props.config.height) {
                this.renderArea.viewerHeight = this.props.config.height;
            } else {
                this.renderArea.viewerHeight = bounds.height;
            }
            if (this.renderArea.itemCount !== this.visibleItems.length) {
                this.updateVisibleItems();
            }
            this.model.viewableArea.bottom = bounds.bottom;
            this.model.viewableArea.top = bounds.top;
            this.model.viewableArea.left = bounds.left;
            this.model.viewableArea.right = bounds.right;
        }
    }

    private updateVisibleItems() {
        const { visibleStart, visibleCount } = this.renderArea;
        this.visibleItems = this.model.items.slice(visibleStart, visibleStart + visibleCount);
    }

    private invalidateView = () => this.forceUpdate();
}
const defaultChildAccessor = (item: TreeItemType) => {
        if ('children' in item) {
            return item.children;
        }
        return undefined;
    },
    defaultCanExpand = (item: TreeItemType) => 'children' in item;
const TreeContainerEl = styled.div`
    height: 100%;
    overflow: auto;
    position: relative;
    outline: none;
`;
TreeContainerEl.defaultProps = {
    className: 'vtree-container',
};
const TreeBottomSpaceEl = styled.div`
    position: absolute;
    width: 1px;
    height: 1px;
`;
const TreeContentEl = styled.div`
    position: absolute;
    width: 100%;
`;
/**
 * State accessor providing behavior state given a data item
 */

export interface ITreeItemState<T> {
    /**
     * True if the passed item is expanded
     */
    isExpanded: (item: T) => boolean;
    /**
     * True if the passed item is selected
     */
    isSelected: (item: T) => boolean;
    /**
     * True if the passed item is highlighted
     */
    isHighlighted: (item: T) => boolean;
    /**
     * True if the passed item is loading its children
     */
    isLoading: (item: T) => boolean;
    model: VirtualTreeModel<T>;
}
type ChildAccessor<ItemType> = (item: ItemType) => Promise<undefined> | Promise<ItemType[]> | ItemType[] | undefined;
export interface ITypedTreeConfig<ItemType> {
    renderNode: (node: Node<ItemType>, state: ITreeItemState<ItemType>) => React.ReactNode;
    renderBody?: (items: Node<ItemType>[], state: ITreeItemState<ItemType>) => ReactNode;
    canExpand?: (item: ItemType) => boolean;
    childAccessor?: ChildAccessor<ItemType>;
    onScroll?: (left: number, top: number) => void;
    onModelLoaded?: (mode: VirtualTreeModel<ItemType>) => void;
    lazyLoad?: boolean;
    itemHeight: number;
    minimizeHeight?: boolean;
    height?: number;
}
type TreeProps<T> = {
    config: ITypedTreeConfig<T>;
    data: T[];
    filter?: (item: T) => boolean;
};
type TreeItemType = any;

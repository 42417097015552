import { getAccountGetAccounts, getAccountGetAccountConnectionStatus, getCompanyGetAzureSubscriptions } from '@apis/Customers';
import { Account, AccountConnectedStatus, AzureSubscriptionInfo, CloudProviderConnectionStatus, CompanyInfo } from '@apis/Customers/model';
import styled from '@emotion/styled';
import { Group, Title, Button, Space, Text, Anchor, Card, Box } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { RemoveAccountBody } from '@root/Components/Connections/Functions';
import { DataGrid } from '@root/Components/DataGrid';
import { DataColumnConfig } from '@root/Components/DataGrid/Models';
import { useDi, useDiComponent } from '@root/Services/DI';
import { useEffect, useMemo, useState } from 'react';
import { MspService } from '@root/Services/MspService';
import { InfoCircle } from 'tabler-icons-react';
import { AdminPageTitle, AdminPanel } from './Design';
import { AdminConnectionModal } from './AdminConnectionModal';
import { CompanyAdministrationPanelModel } from './CompanyAdministrationPanelModel';
import { useEvent } from '@root/Services/EventEmitter';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { AzureSubscriptionsGrid } from '@root/Components/CompanyInfo/Administration/AzureSubscriptionsGrid';

export interface AdminConnectionsFormProps {
    companyInfo: CompanyInfo;
    model: CompanyAdministrationPanelModel;
    isSupport: boolean;
}

export const AdminConnections = (props: AdminConnectionsFormProps) => {
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [cloudProviders, setCloudProviders] = useState<AzureSubscriptionInfo[]>();
    const mspSvc = useDi(MspService);
    const DiContainer = useDiComponent();
    const [accountStatus, setAccountStatus] = useState<AccountConnectedStatus[]>([]);
    const com = useCompany();

    useEffect(() => {
        loadAccounts();
    }, []);

    useEvent(mspSvc.newAccountAdded, () => {
        loadAccounts();
    });

    const loadAccounts = async () => {
        const cloudProviders = await getCompanyGetAzureSubscriptions();
        setCloudProviders(cloudProviders);

        const accs = await getAccountGetAccounts();
        const accountsConnectedStatus = await getAccountGetAccountConnectionStatus({ companyId: com?.Id });
        setAccountStatus(accountsConnectedStatus);
        for (const acc of accs) {
            acc.Connected =
                (accountsConnectedStatus.find((a) => a.AccountId == acc.Id)?.ConnectionStatus ? 'Connected' : 'Not Connected') ?? acc.IsConnected;
        }
        setAccounts(accs);
    };

    const modals = useModals();
    const openUserInvitewModal = () => {
        modals.openModal({
            title: 'Download IAM Permision Policy',
            size: 'lg',
            children: (
                <DiContainer>
                    <AdminConnectionModal></AdminConnectionModal>
                </DiContainer>
            ),
        });
    };

    function RemoveAccount() {
        modals.openModal({
            title: 'How to Remove a Connection',
            children: <RemoveAccountBody />,
        });
    }

    const awsColumns = useMemo(
        () =>
            [
                {
                    id: 'AccountNumber',
                    header: 'Account Number',
                    accessor: (u) => u.AwsAccountId,
                    defaultWidth: 200,
                    sortField: 'AwsAccountId',
                    filter: {
                        filterType: 'string',
                        name: 'AccountNumber',
                        filterField: 'AwsAccountId',
                    },
                },
                {
                    id: 'Name',
                    header: 'Name',
                    accessor: (u) => u.Name,
                    defaultWidth: 300,
                    sortField: 'Name',
                    filter: {
                        filterType: 'string',
                        name: 'Name',
                        filterField: 'Name',
                    },
                },
                {
                    id: 'Type',
                    header: 'Type',
                    accessor: (u) => u.MasterAccount,
                    defaultWidth: 145,
                    sortField: 'MasterAccount',
                    filter: {
                        filterType: 'string',
                        name: 'Type',
                        filterField: 'MasterAccount',
                        options: {
                            getValueProvider() {
                                return [
                                    { label: 'Management', value: 'Management' },
                                    { label: 'Linked', value: 'Linked' },
                                ];
                            },
                        },
                    },
                },
                {
                    id: 'Connected',
                    header: 'Connected',
                    accessor: (u) => u.Connected,
                    defaultWidth: 145,
                    sortField: 'Connected',
                    filter: {
                        filterType: 'string',
                        name: 'Connected',
                        filterField: 'IsConnected',
                        options: {
                            getValueProvider() {
                                return [
                                    { label: 'Connected', value: 'Connected' },
                                    { label: 'Not Connected', value: 'Not Connected' },
                                ];
                            },
                        },
                    },
                },
            ] as DataColumnConfig<Account>[],
        [accounts]
    );

    return (
        <AdminPanel>
            <Group position="apart" align="center" mt={4}>
                <AdminPageTitle data-atid="ConnectionsMainHeader">Connections</AdminPageTitle>
                <Group hidden={props.isSupport}>
                    <Anchor data-atid="RemoveConnectionButton" onClick={RemoveAccount}>
                        <Group spacing={4}>
                            <InfoCircle size={20} />
                            <Text>Remove a Connection</Text>
                        </Group>
                    </Anchor>
                    <Button
                        data-atid="CreateNewConnectionButton"
                        onClick={openUserInvitewModal}
                        disabled={false}
                        type="submit"
                        size="sm"
                        color="primary"
                    >
                        Add Connection
                    </Button>
                </Group>
            </Group>

            <Space h="lg" />
            <Card withBorder radius="md" p={0}>
                {!accounts.length && !cloudProviders?.length ? <Text p="md">No Cloud Connections</Text> : null}

                {accounts?.length ? (
                    <AccountContainer>
                        <Box p="md">
                            <Title data-atid="AwsAccountsTitle" order={5} sx={{ fontWeight: 600 }}>
                                <img src="/assets/Amazon_Web_Services_Logo.svg" style={{ width: '25px', marginRight: '8px' }} /> AWS Accounts
                            </Title>
                        </Box>
                        <DataGridContainer>
                            <DataGrid dataSource={accounts!} columns={awsColumns} />
                        </DataGridContainer>
                    </AccountContainer>
                ) : null}

                {cloudProviders?.length ? (
                    <AccountContainer>
                        <Box p="md">
                            <Title data-atid="AzureAccountsTitle" order={4}>
                                <img src="/assets/azure_logo.svg" style={{ width: '25px', marginRight: '8px' }} /> Azure Subscriptions
                            </Title>
                        </Box>

                        <DataGridContainer>
                            <AzureSubscriptionsGrid azureSubscriptions={cloudProviders} />
                        </DataGridContainer>
                    </AccountContainer>
                ) : null}
            </Card>
        </AdminPanel>
    );
};

const AccountContainer = styled.div`
    height: 375px;
    padding: 0 16px 16px 16px;
`;

const DataGridContainer = styled.div`
    height: 300px;
    overflow: auto;
`;

import { Box } from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { useNav } from '@root/Services/NavigationService';
import { useCallback, useMemo } from 'react';
import { ActionList, ActionListItem } from './Components';
import { ShowbackSummaryModel } from './ShowbackSummaryModel';

export function SummaryStatList({ summaryModel }: { summaryModel: ShowbackSummaryModel }) {
    const summaryData = useEventValue(summaryModel.dataChanged);
    const loading = useEventValue(summaryModel.loading);
    const fmtSvc = useDi(FormatService);
    const sbModel = summaryModel.showbackModel;

    const nav = useNav();
    const openReconciliation = useCallback(() => sbModel.openTab(nav, 'reconciliation'), [sbModel, nav]);
    const openUntaggedEditor = useCallback(() => sbModel.openRuleEditor(nav, undefined, 'UntaggedCosts'), [sbModel, nav]);
    const openDiscountEditor = useCallback(() => sbModel.openRuleEditor(nav, undefined, 'Discounts'), [sbModel, nav]);
    const openSharedCostEditor = useCallback(() => sbModel.openRuleEditor(nav, undefined, 'SharedCosts'), [sbModel, nav]);
    const openMktplaceEditor = useCallback(() => sbModel.openRuleEditor(nav, undefined, 'Marketplace'), [sbModel, nav]);
    const openAdvancedEditor = useCallback(() => sbModel.openRuleEditor(nav, undefined, 'Advanced'), [sbModel, nav]);

    const invoiceListItems = useMemo(() => {
        const metrics = { ...summaryData?.stats };
        const val = (...values: (undefined | number)[]) => {
            const amount = values.reduce((r, value) => (r ?? 0) + (value ?? 0), 0);
            return amount === undefined ? '—' : fmtSvc.formatMoneyNonZeroTwoDecimals(amount);
        };
        const { allocated: alloc, unallocated: unalloc } = metrics;
        return [
            { title: 'Invoice Charges', metric: val(metrics.total) },
            { section: 'Allocated', action: 'Reconcile', onAction: openReconciliation, metric: val(alloc), children: [] },
            {
                section: 'Unallocated',
                metric: val(unalloc),
                initExpanded: true,
                children: [
                    { name: 'Untagged Costs', metric: val(metrics.unallocatedUntagged), action: 'Allocate', onAction: openUntaggedEditor },
                    { name: 'Discounts', metric: val(metrics.discountSp, metrics.discountRi), action: 'Allocate', onAction: openDiscountEditor },
                    {
                        name: 'Credits',
                        metric: val(metrics.discountCredits, metrics.discountEdp, metrics.discountPvtRate),
                        action: 'Allocate',
                        onAction: openAdvancedEditor,
                    },
                    { name: 'Support', metric: val(metrics.chargeSupport), action: 'Allocate', onAction: openSharedCostEditor },
                    { name: 'Fees', metric: val(metrics.chargeFees), action: 'Allocate', onAction: openSharedCostEditor },
                    { name: 'Marketplace Charges', metric: val(metrics.chargeMarketplace), action: 'Allocate', onAction: openMktplaceEditor },
                    { name: 'Other', metric: val(metrics.unallocatedNoCategory), action: 'Allocate', onAction: openAdvancedEditor },
                ],
            },
            { spacer: true },
            { title: 'Amortization of Up-Front Payments', metric: val(metrics.upfrontSpFees, metrics.upfrontRiFees) },
            { name: 'Savings Plans', metric: val(metrics.upfrontSpFees) },
            { name: 'Reserved Instances', metric: val(metrics.upfrontRiFees) },
            { spacer: true },
            { total: 'Total Showback Amounts', metric: val(alloc, unalloc, metrics.upfrontSpFees, metrics.upfrontRiFees) },
        ] as ActionListItem[];
    }, [summaryData]);

    return (
        <Box sx={{ transition: 'all .5s', filter: loading ? 'blur(5px)' : 'blur(0)' }}>
            <ActionList items={invoiceListItems} />
        </Box>
    );
}

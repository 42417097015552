import styled from '@emotion/styled';

export const CoverageText = styled.div`
    padding: 5px;
    width: 33.3%;
    font-size: 14px;
    text-align: center;
`;

export const CoverageNumber = styled.div`
    width: 33.3%;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
`;

export const PolicySection = styled.div`
    border: 1px solid ${(p) => p.theme.colors!.gray![3]};
    border-radius: 10px;
    padding: 16px;
    background: ${(p) => p.theme.colors!.gray![0]};
`;

import styled from '@emotion/styled';
import { Box, Button, Divider, Group, Space, Stack, Tabs, Text, useMantineTheme } from '@mantine/core';
import { PageContent, PagePanel, PanelBody, PaneledPage, PanelHeader } from '@root/Design/Layout';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { ReactNode, useState } from 'react';
import { AssesmentSummaries } from './Summary';
import { AssessmentsGrid } from './AssessmentsGrid';
import { SiteMenuCompanyService } from '@root/Components/Shell/SiteMenuCompanyService';
import { useDi } from '@root/Services/DI';
import { useEventValue } from '@root/Services/EventEmitter';
import { MspSummary } from './MspSummary';
import { Portfolio } from './Portfolio';
import { useResizeNeeded } from '@root/Design/Primitives';
import { HistoryPage } from './History';
import { MspHistoryPage } from './MspHistory';
import { useNav } from '@root/Services/NavigationService';
import { useCompany } from '@root/Components/Router/CompanyContent';

export function Assessments() {
    const company = useCompany();
    const nav = useNav();
    const { tab } = nav.getData('tab');
    const activeTab = tab ?? (company?.Type === 'Msp' ? 'mspsummary' : 'summary');
    const siteMenuContextSvc = useDi(SiteMenuCompanyService);
    const rootCompany = useEventValue(siteMenuContextSvc.rootCompany);
    const resizeNeeded = useResizeNeeded();

    const handleTabChange = (tabName: string) => {
        nav.replaceParams({ tab: tabName });
        resizeNeeded();
    };

    return (
        <Tabs
            defaultValue="summary"
            value={activeTab}
            onTabChange={handleTabChange}
            sx={{
                flex: 1,
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                minHeight: 0,
                ['[role=tabpanel]']: { height: '100%', minHeight: 0 },
            }}
        >
            <Tabs.List px="lg">
                {company?.Type == 'Msp' ? (
                    <Tabs.Tab value="mspsummary" data-atid="AssessmentsMspSummary">
                        MSP Summary
                    </Tabs.Tab>
                ) : (
                    <Tabs.Tab value="summary" data-atid="AssessmentsSummary">
                        Summary
                    </Tabs.Tab>
                )}
                {company?.Type == 'Msp' ? (
                    <Tabs.Tab value="portfolio" data-atid="AssessmentsPortfolio">
                        Portfolio
                    </Tabs.Tab>
                ) : (
                    <Tabs.Tab value="assessments" data-atid="AssessmentsAssessments">
                        Assessments
                    </Tabs.Tab>
                )}
                {company?.Type == 'Msp' ? (
                    <Tabs.Tab value="msphistory" data-atid="AssessmentsMspHistory">
                        MSP History
                    </Tabs.Tab>
                ) : (
                    <Tabs.Tab value="history" data-atid="AssessmentsHistory">
                        History
                    </Tabs.Tab>
                )}
            </Tabs.List>
            <Tabs.Panel value="mspsummary">{activeTab !== 'mspsummary' ? null : <MspSummary />}</Tabs.Panel>
            <Tabs.Panel value="portfolio">
                {activeTab !== 'portfolio' ? null : (
                    <AssessmentsPage title={'MSP Portfolio'}>
                        <Portfolio />
                    </AssessmentsPage>
                )}
            </Tabs.Panel>
            <Tabs.Panel value="summary">{activeTab !== 'summary' ? null : <AssesmentSummaries />}</Tabs.Panel>
            <Tabs.Panel value="assessments">{activeTab !== 'assessments' ? null : <AssessmentsGrid />}</Tabs.Panel>
            <Tabs.Panel value="history">{activeTab !== 'history' ? null : <HistoryPage />}</Tabs.Panel>
            <Tabs.Panel value="msphistory">{activeTab !== 'msphistory' ? null : <MspHistoryPage />}</Tabs.Panel>
        </Tabs>
    );
}
endpoint('assessment-overview', Assessments, 'Assessments');
endpoint('msp-assessment-overview', Assessments, 'Assessments');

export function AssessmentsPage({
    children,
    title,
    titleRightPlaceholder,
}: {
    children: ReactNode;
    title: string;
    titleRightPlaceholder?: ReactNode;
}) {
    const theme = useMantineTheme();
    return (
        <PaneledPage style={{ height: '100%' }}>
            <PagePanel size="fill">
                <PanelBody bg={theme.colors.gray[1]} noPadding style={{ position: 'relative', marginTop: 0, padding: '0 24px 24px 24px' }}>
                    <Stack sx={{ height: '100%' }}>
                        <Group mt="md" position="apart" style={{ width: '100%' }}>
                            <Text data-atid={'SiteSecondaryHeader:' + title} size={20}>
                                {title}
                            </Text>
                            {titleRightPlaceholder}
                        </Group>
                        <ResourceContainer>{children}</ResourceContainer>
                    </Stack>
                </PanelBody>
            </PagePanel>
        </PaneledPage>
    );
}

const ResourceContainer = styled.div`
    height: 100%;
    flex: 1;
    min-height: 0;
`;

import { useCompany } from '@root/Components/Router/CompanyContent';
import { FillerSwitch } from '@root/Design/Filler';
import { useDi } from '@root/Services/DI';
import { InvoiceApiCache } from '@root/Services/Invoices/InvoiceApiCache';
import { PlatformService } from '@root/Services/PlatformService';
import { ComponentType, useEffect, useId, useMemo, useState } from 'react';

export function withSchemaPreloader<T>(Component: ComponentType<T>) {
    return function SchemaPreloader(props: any) {
        const platformSvc = useDi(PlatformService);
        const invoiceApi = useDi(InvoiceApiCache);
        const company = useCompany();
        const [ready, setReady] = useState(false);
        const invoiceSchemaSvc = useMemo(() => invoiceApi.getInvoiceSchemaSvc(platformSvc, company?.Id), [company]);

        useEffect(() => {
            let unloaded = false;
            (async function load() {
                await invoiceSchemaSvc.getSchema();
                if (!unloaded) {
                    setReady(true);
                }
            })();
            return () => {
                unloaded = true;
            };
        }, []);

        return <FillerSwitch loading={!ready}>{() => <Component {...props} />}</FillerSwitch>;
    };
}

import { EventEmitter } from '@root/Services/EventEmitter';
import { singleton } from 'tsyringe';

@singleton()
export class MapContractsEvents {
    public readonly onContractsChanged = EventEmitter.empty();
    public readonly onTagChanged = EventEmitter.empty();
    public readonly onTaggedIncorrectly = EventEmitter.empty();
    public readonly onNotTagged = EventEmitter.empty();
    public readonly onIneligibleMAPTags = EventEmitter.empty();
    public readonly onActionRequired = EventEmitter.empty();
    public readonly onNoSelection = EventEmitter.empty();
}

/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Jobs.Host.Api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  PostJobAddMessageParams,
  JobIncrementalProgress,
  PostJobLogProgressParams,
  AnonymousQueueJobJob,
  AnonymousQueueJobJobQueryResult,
  Query,
  ObjectQueryResult,
  PostJobJobIdStartParams,
  PostJobJobIdCompleteParams,
  AnonymousResultDetail,
  PostJobJobIdCompleteWithDetailsParams,
  FailDetails,
  PostJobJobIdFailParams,
  RawEvent
} from './Jobs/model'
import { request } from '../Services/BasicApi'


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const postJobAddMessage = (
    postJobAddMessageBody: string,
    params?: PostJobAddMessageParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Job/AddMessage`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: postJobAddMessageBody,
        params,
    },
      { type: 'Jobs',  ...options});
    }
  


    export type PostJobAddMessageMutationResult = NonNullable<Awaited<ReturnType<typeof postJobAddMessage>>>
    export type PostJobAddMessageMutationBody = string
    export type PostJobAddMessageMutationError = unknown

    export const usePostJobAddMessage = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postJobAddMessage>>, TError,{data: string;params?: PostJobAddMessageParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postJobAddMessage>>, {data: string;params?: PostJobAddMessageParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postJobAddMessage(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postJobAddMessage>>, TError, {data: string;params?: PostJobAddMessageParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postJobLogProgress = (
    jobIncrementalProgress: JobIncrementalProgress,
    params?: PostJobLogProgressParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Job/LogProgress`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: jobIncrementalProgress,
        params,
    },
      { type: 'Jobs',  ...options});
    }
  


    export type PostJobLogProgressMutationResult = NonNullable<Awaited<ReturnType<typeof postJobLogProgress>>>
    export type PostJobLogProgressMutationBody = JobIncrementalProgress
    export type PostJobLogProgressMutationError = unknown

    export const usePostJobLogProgress = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postJobLogProgress>>, TError,{data: JobIncrementalProgress;params?: PostJobLogProgressParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postJobLogProgress>>, {data: JobIncrementalProgress;params?: PostJobLogProgressParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postJobLogProgress(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postJobLogProgress>>, TError, {data: JobIncrementalProgress;params?: PostJobLogProgressParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const putJob = (
    anonymousQueueJobJob: AnonymousQueueJobJob,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Job`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: anonymousQueueJobJob
    },
      { type: 'Jobs',  ...options});
    }
  


    export type PutJobMutationResult = NonNullable<Awaited<ReturnType<typeof putJob>>>
    export type PutJobMutationBody = AnonymousQueueJobJob
    export type PutJobMutationError = unknown

    export const usePutJob = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putJob>>, TError,{data: AnonymousQueueJobJob}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putJob>>, {data: AnonymousQueueJobJob}> = (props) => {
          const {data} = props ?? {};

          return  putJob(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putJob>>, TError, {data: AnonymousQueueJobJob}, TContext>(mutationFn, mutationOptions)
    }
    
export const putJobBulk = (
    anonymousQueueJobJob: AnonymousQueueJobJob[],
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Job/Bulk`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: anonymousQueueJobJob
    },
      { type: 'Jobs',  ...options});
    }
  


    export type PutJobBulkMutationResult = NonNullable<Awaited<ReturnType<typeof putJobBulk>>>
    export type PutJobBulkMutationBody = AnonymousQueueJobJob[]
    export type PutJobBulkMutationError = unknown

    export const usePutJobBulk = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putJobBulk>>, TError,{data: AnonymousQueueJobJob[]}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putJobBulk>>, {data: AnonymousQueueJobJob[]}> = (props) => {
          const {data} = props ?? {};

          return  putJobBulk(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putJobBulk>>, TError, {data: AnonymousQueueJobJob[]}, TContext>(mutationFn, mutationOptions)
    }
    
export const getJobJobId = (
    jobId: string,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<AnonymousQueueJobJob | void>(
      {url: `/Job/${jobId}`, method: 'get', signal
    },
      { type: 'Jobs',  ...options});
    }
  

export const getGetJobJobIdQueryKey = (jobId: string,) => [`/Job/${jobId}`];

    
export type GetJobJobIdQueryResult = NonNullable<Awaited<ReturnType<typeof getJobJobId>>>
export type GetJobJobIdQueryError = unknown

export const useGetJobJobId = <TData = Awaited<ReturnType<typeof getJobJobId>>, TError = unknown>(
 jobId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getJobJobId>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetJobJobIdQueryKey(jobId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getJobJobId>>> = ({ signal }) => getJobJobId(jobId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getJobJobId>>, TError, TData>(queryKey, queryFn, {enabled: !!(jobId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const postJobSearch = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<AnonymousQueueJobJobQueryResult>(
      {url: `/Job/Search`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'Jobs',  ...options});
    }
  


    export type PostJobSearchMutationResult = NonNullable<Awaited<ReturnType<typeof postJobSearch>>>
    export type PostJobSearchMutationBody = Query
    export type PostJobSearchMutationError = unknown

    export const usePostJobSearch = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postJobSearch>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postJobSearch>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postJobSearch(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postJobSearch>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const postJobQuery = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult>(
      {url: `/Job/Query`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'Jobs',  ...options});
    }
  


    export type PostJobQueryMutationResult = NonNullable<Awaited<ReturnType<typeof postJobQuery>>>
    export type PostJobQueryMutationBody = Query
    export type PostJobQueryMutationError = unknown

    export const usePostJobQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postJobQuery>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postJobQuery>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postJobQuery(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postJobQuery>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const postJobCompanyQuery = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult>(
      {url: `/Job/CompanyQuery`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'Jobs',  ...options});
    }
  


    export type PostJobCompanyQueryMutationResult = NonNullable<Awaited<ReturnType<typeof postJobCompanyQuery>>>
    export type PostJobCompanyQueryMutationBody = Query
    export type PostJobCompanyQueryMutationError = unknown

    export const usePostJobCompanyQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postJobCompanyQuery>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postJobCompanyQuery>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postJobCompanyQuery(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postJobCompanyQuery>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteJobJobIdCancel = (
    jobId: string,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Job/${jobId}/Cancel`, method: 'delete'
    },
      { type: 'Jobs',  ...options});
    }
  


    export type DeleteJobJobIdCancelMutationResult = NonNullable<Awaited<ReturnType<typeof deleteJobJobIdCancel>>>
    
    export type DeleteJobJobIdCancelMutationError = unknown

    export const useDeleteJobJobIdCancel = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteJobJobIdCancel>>, TError,{jobId: string}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteJobJobIdCancel>>, {jobId: string}> = (props) => {
          const {jobId} = props ?? {};

          return  deleteJobJobIdCancel(jobId,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteJobJobIdCancel>>, TError, {jobId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const postJobJobIdStart = (
    jobId: string,
    params?: PostJobJobIdStartParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Job/${jobId}/Start`, method: 'post',
        params,
    },
      { type: 'Jobs',  ...options});
    }
  


    export type PostJobJobIdStartMutationResult = NonNullable<Awaited<ReturnType<typeof postJobJobIdStart>>>
    
    export type PostJobJobIdStartMutationError = unknown

    export const usePostJobJobIdStart = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postJobJobIdStart>>, TError,{jobId: string;params?: PostJobJobIdStartParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postJobJobIdStart>>, {jobId: string;params?: PostJobJobIdStartParams}> = (props) => {
          const {jobId,params} = props ?? {};

          return  postJobJobIdStart(jobId,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postJobJobIdStart>>, TError, {jobId: string;params?: PostJobJobIdStartParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postJobJobIdComplete = (
    jobId: string,
    params?: PostJobJobIdCompleteParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Job/${jobId}/Complete`, method: 'post',
        params,
    },
      { type: 'Jobs',  ...options});
    }
  


    export type PostJobJobIdCompleteMutationResult = NonNullable<Awaited<ReturnType<typeof postJobJobIdComplete>>>
    
    export type PostJobJobIdCompleteMutationError = unknown

    export const usePostJobJobIdComplete = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postJobJobIdComplete>>, TError,{jobId: string;params?: PostJobJobIdCompleteParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postJobJobIdComplete>>, {jobId: string;params?: PostJobJobIdCompleteParams}> = (props) => {
          const {jobId,params} = props ?? {};

          return  postJobJobIdComplete(jobId,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postJobJobIdComplete>>, TError, {jobId: string;params?: PostJobJobIdCompleteParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postJobJobIdCompleteWithDetails = (
    jobId: string,
    anonymousResultDetail: AnonymousResultDetail,
    params?: PostJobJobIdCompleteWithDetailsParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Job/${jobId}/CompleteWithDetails`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: anonymousResultDetail,
        params,
    },
      { type: 'Jobs',  ...options});
    }
  


    export type PostJobJobIdCompleteWithDetailsMutationResult = NonNullable<Awaited<ReturnType<typeof postJobJobIdCompleteWithDetails>>>
    export type PostJobJobIdCompleteWithDetailsMutationBody = AnonymousResultDetail
    export type PostJobJobIdCompleteWithDetailsMutationError = unknown

    export const usePostJobJobIdCompleteWithDetails = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postJobJobIdCompleteWithDetails>>, TError,{jobId: string;data: AnonymousResultDetail;params?: PostJobJobIdCompleteWithDetailsParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postJobJobIdCompleteWithDetails>>, {jobId: string;data: AnonymousResultDetail;params?: PostJobJobIdCompleteWithDetailsParams}> = (props) => {
          const {jobId,data,params} = props ?? {};

          return  postJobJobIdCompleteWithDetails(jobId,data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postJobJobIdCompleteWithDetails>>, TError, {jobId: string;data: AnonymousResultDetail;params?: PostJobJobIdCompleteWithDetailsParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postJobJobIdFail = (
    jobId: string,
    failDetails: FailDetails,
    params?: PostJobJobIdFailParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Job/${jobId}/Fail`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: failDetails,
        params,
    },
      { type: 'Jobs',  ...options});
    }
  


    export type PostJobJobIdFailMutationResult = NonNullable<Awaited<ReturnType<typeof postJobJobIdFail>>>
    export type PostJobJobIdFailMutationBody = FailDetails
    export type PostJobJobIdFailMutationError = unknown

    export const usePostJobJobIdFail = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postJobJobIdFail>>, TError,{jobId: string;data: FailDetails;params?: PostJobJobIdFailParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postJobJobIdFail>>, {jobId: string;data: FailDetails;params?: PostJobJobIdFailParams}> = (props) => {
          const {jobId,data,params} = props ?? {};

          return  postJobJobIdFail(jobId,data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postJobJobIdFail>>, TError, {jobId: string;data: FailDetails;params?: PostJobJobIdFailParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postJobPublishRawEvent = (
    rawEvent: RawEvent,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Job/PublishRawEvent`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: rawEvent
    },
      { type: 'Jobs',  ...options});
    }
  


    export type PostJobPublishRawEventMutationResult = NonNullable<Awaited<ReturnType<typeof postJobPublishRawEvent>>>
    export type PostJobPublishRawEventMutationBody = RawEvent
    export type PostJobPublishRawEventMutationError = unknown

    export const usePostJobPublishRawEvent = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postJobPublishRawEvent>>, TError,{data: RawEvent}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postJobPublishRawEvent>>, {data: RawEvent}> = (props) => {
          const {data} = props ?? {};

          return  postJobPublishRawEvent(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postJobPublishRawEvent>>, TError, {data: RawEvent}, TContext>(mutationFn, mutationOptions)
    }
    
export const postJobPublishRawEvents = (
    rawEvent: RawEvent[],
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Job/PublishRawEvents`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: rawEvent
    },
      { type: 'Jobs',  ...options});
    }
  


    export type PostJobPublishRawEventsMutationResult = NonNullable<Awaited<ReturnType<typeof postJobPublishRawEvents>>>
    export type PostJobPublishRawEventsMutationBody = RawEvent[]
    export type PostJobPublishRawEventsMutationError = unknown

    export const usePostJobPublishRawEvents = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postJobPublishRawEvents>>, TError,{data: RawEvent[]}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postJobPublishRawEvents>>, {data: RawEvent[]}> = (props) => {
          const {data} = props ?? {};

          return  postJobPublishRawEvents(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postJobPublishRawEvents>>, TError, {data: RawEvent[]}, TContext>(mutationFn, mutationOptions)
    }
    

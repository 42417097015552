import { Box, Grid, Group, Space, Text, Title } from '@mantine/core';
import { ReactNode } from 'react';

export function IntroText({ action, displayText }: { action: ReactNode; displayText: boolean }) {
    return (
        <Box sx={{ maxWidth: 1400 }} px={70} py={displayText ? 50 : 25}>
            <Space h="md" />
            <Group position="apart" noWrap>
                <Box>
                    <Title order={2}>Manage Your AWS MAP Contract</Title>
                    {displayText ? (
                        <Text>
                            MAP Manager helps you comply with the complex tagging requirements for the AWS Migration Acceleration Program (MAP). We'll
                            help you collect the information you need and meet the resource tagging requirements. Add your MAP Contract to begin.
                        </Text>
                    ) : (
                        <Text></Text>
                    )}
                </Box>

                <Space w={50} />
                <Box>{action}</Box>
            </Group>
        </Box>
    );
}

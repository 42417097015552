import { Company, CustomerSubsidyRequest, CustomerSubsidyViewModel } from '@apis/Customers/model';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { CompanyContext } from '@root/Components/Router/CompanyContext';
import { CompanyContextService } from '@root/Services/Customers/CompanyContext';
import { useDi } from '@root/Services/DI';
import { useEvent } from '@root/Services/EventEmitter';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useMemo } from 'react';
import { CompanyDetailBody } from './CompanyDetail';

export function CompanyDetailPage() {
    const nav = useNav();
    const { companyId: rawCompanyId } = nav.getData('companyId');
    const companyId = useMemo(() => (rawCompanyId ? parseInt(rawCompanyId) : 0), [rawCompanyId]);

    return (
        <CompanyContext<CustomerSubsidyViewModel> type="customer" companyId={companyId}>
            {(customer) => customer && <CompanyDetailPageBody customer={customer} />}
        </CompanyContext>
    );
}

function CompanyDetailBreadCrumb() {
    const contextSvc = useDi(CompanyContextService) as CompanyContextService<CustomerSubsidyViewModel>;
    useEvent(contextSvc.loading);

    return <>{contextSvc.customer ? contextSvc.customer.Company : 'Loading Company'}</>;
}
endpoint('company-detail', CompanyDetailPage, CompanyDetailBreadCrumb);

export function CompanyDetailPageBody({ customer }: { customer: CustomerSubsidyViewModel }) {
    const company = useCompany();
    return <>{company ? <CompanyDetailBody company={company} customer={customer} /> : null}</>;
}

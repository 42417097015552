import { QueryExpr } from '@apis/Resources';
import { Accordion, Group, NumberInput } from '@mantine/core';
import { PieChartSettings } from '@root/Components/Charts/PieChart';
import { useEvent } from '@root/Services/EventEmitter';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { AggregateOperation, ChartMargins, DropdownSettings, EditorSelectExpr, FilterSettings, NumberSettings } from './Components';
import { SettingsDivider, SettingsLabel } from './Design';
import { ChartEditor } from './Models';

export const PieSettings = observer(function PieSettings({ editor }: { editor: ChartEditor }) {
    const settings = useMemo(() => editor.getChartSettings<PieChartSettings>(), []);
    useEvent(editor.onSettingsChanged);
    return (
        <>
            <Accordion.Item value="Data">
                <Accordion.Control value="Data">Data</Accordion.Control>
                <Accordion.Panel>
                    <Group position="apart">
                        <SettingsLabel>Value</SettingsLabel>
                        <AggregateOperation
                            options={['count', 'countvalues', 'countuniquevalues', 'sum', 'avg', 'min', 'max']}
                            editor={editor}
                            expr={editor.getValue(0).Expr as QueryExpr}
                            onChange={(x) => editor.setValue(0, { Alias: 'Value', Expr: x })}
                            types={['number']}
                        />
                    </Group>
                    <Group position="apart">
                        <SettingsLabel>Slice by</SettingsLabel>
                        <EditorSelectExpr editor={editor} expr={editor.getGroup(0)} onChange={(x) => editor.setGroup(0, x)} types={['string']} />
                    </Group>
                    <DropdownSettings
                        options={[
                            { label: 'Currency', value: 'money' },
                            { label: 'Currency (whole)', value: 'money-whole' },
                            { label: 'Percent', value: 'percent' },
                        ]}
                        label="Format"
                        onChange={(value) => (settings.valueFormat = value as any)}
                        value={typeof settings.valueFormat === 'string' ? settings.valueFormat : undefined}
                    />
                    <Group position="apart">
                        <SettingsLabel>Threshold</SettingsLabel>
                        <NumberInput
                            size="sm"
                            sx={{ width: '6rem' }}
                            value={settings.threshold ?? 0}
                            onChange={(value) => (settings.threshold = value)}
                        />
                    </Group>
                    <NumberSettings label="Top N" onChange={(value) => (settings.topN = value ?? undefined)} value={settings.topN} min={0} />
                    <SettingsDivider />
                    <FilterSettings editor={editor} />
                </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="Options">
                <Accordion.Control value="Options">Options</Accordion.Control>
                <Accordion.Panel>
                    <ChartMargins settings={settings} />
                </Accordion.Panel>
            </Accordion.Item>
        </>
    );
});

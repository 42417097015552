/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Resources.Host.Api
 * OpenAPI spec version: 1.0
 */

export type GetConnectionScriptsCsCloudformationYmlPlatform = typeof GetConnectionScriptsCsCloudformationYmlPlatform[keyof typeof GetConnectionScriptsCsCloudformationYmlPlatform];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetConnectionScriptsCsCloudformationYmlPlatform = {
  Aws: 'Aws',
  Azure: 'Azure',
} as const;

import { Button, CloseButton, Group, Space, Text, TextInput, useMantineTheme } from '@mantine/core';
import { DataGridState } from '@root/Components/DataGrid/Models';
import { isValidValue, validationReturnCode } from '@root/Components/Resources/Tags/TagValidation';
import { useNav } from '@root/Services/NavigationService';
import { useLink } from '@root/Services/Router/Router';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { OpenSidebar } from './OpenSidebar';
import { TagActionBody, TagActionContainer } from './UpdateTags';

export interface SidePanelProps {
    onClose(): void;
    sidePanelState: 'key' | 'value';
    state: DataGridState;
    operation: OpenSidebar;
    tagsToUpdate?: string[];
    tagValueToUpdate?: string[];
    tagKeyGettingValue?: string;
    onReviewClick?: () => void;
    enableReview: boolean;
}

export interface OperationState {
    operation: OpenSidebar;
    renameValue?: string;
    tagsToUpdate?: string[];
    tagKeyGettingValue?: string;
}

export const TagConsolidationSidePanel = (props: SidePanelProps) => {
    const [renameValue, setRenameValue] = useState<string>('');
    const [enableButton, setEnableButton] = useState(false);
    const link = useLink();
    const { getDescendUrl } = useNav();
    const [operationValues, setOperationValues] = useState<OperationState>();
    const [title, setTitle] = useState<string>();

    const [tagValid, setTagValid] = useState<boolean>(true);
    const [validValue, setValidValue] = useState<number>(0);
    const [validationCode, setValidationCode] = useState<string>('');
    const theme = useMantineTheme();

    useEffect(() => {
        setValidValue(isValidValue(renameValue));
    }, [renameValue]);

    useEffect(() => {
        setTagValid(validValue == 0);
    }, [validValue]);

    useEffect(() => {
        setValidationCode(validationReturnCode(isValidValue(renameValue)));
    }, [tagValid]);

    useEffect(() => {
        if (props.sidePanelState == 'key') {
            setTitle('What is the correct key?');
        }

        if (props.sidePanelState == 'value') {
            setTitle('What is the correct value?');
        }
    }, []);

    useEffect(() => {
        if (renameValue.length > 0 && tagValid) {
            setEnableButton(props.enableReview && true);
            setOperationValues({
                operation: props.operation,
                renameValue: renameValue,
                tagsToUpdate: props.tagsToUpdate!,
                tagKeyGettingValue: props.sidePanelState === 'value' ? props.tagKeyGettingValue : undefined,
            });
        } else {
            setEnableButton(false);
        }
    }, [renameValue, props.tagsToUpdate, props.operation, props.sidePanelState, props.tagKeyGettingValue, props.enableReview, tagValid]);

    const { href, onClick } = link(
        getDescendUrl('tag-explorer', {
            state: JSON.stringify(props.state),
            operation: JSON.stringify(operationValues),
            returnTo: 'Tag Consolidation',
        })
    );
    const handleReviewClick = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            props.onReviewClick?.();
            onClick(e);
        },
        [props.onReviewClick, onClick]
    );
    return (
        <TagActionContainer style={{ padding: 20 }}>
            <Group position="apart" noWrap>
                <Group>
                    <Text data-atid="SidePanelHeader" size={20}>
                        {title}
                    </Text>
                </Group>
                <CloseButton data-atid="CloseButton" mr="xl" onClick={() => props.onClose()} />
            </Group>

            <TagActionBody>
                <Space h="sm" />
                <TextInput
                    data-atid="RenameInput"
                    label="Rename To"
                    onChange={(e: { currentTarget: { value: SetStateAction<string> } }) => setRenameValue(e.currentTarget.value)}
                />
                <Space h="sm" />
                {props.sidePanelState == 'key' ? (
                    <Text size="sm">
                        Review resources affected by renaming the <strong>({props.tagsToUpdate?.length})</strong> selected key
                        {props.tagsToUpdate?.length! > 1 ? 's' : ''} to <strong>"{renameValue}"</strong>
                    </Text>
                ) : (
                    <Text size="sm">
                        Review resources affected by replacing {props.tagKeyGettingValue}:<strong>{props.tagsToUpdate?.[0]}</strong>
                        {' with '}
                        {props.tagKeyGettingValue}:<strong>{renameValue}</strong>
                    </Text>
                )}
                {validationCode !== '' ? (
                    <Text
                        size="xs"
                        style={{ color: validValue == 0 ? theme.colors.success[7] : validValue == 1 ? theme.white : theme.colors.error[7] }}
                    >
                        {validationCode}
                    </Text>
                ) : (
                    <Space h={19}></Space>
                )}
            </TagActionBody>
            <Group position="right">
                <Button data-atid="CancelButton" variant="outline" onClick={props.onClose}>
                    Cancel
                </Button>
                <Button
                    data-atid="ReviewButton"
                    component="a"
                    type="submit"
                    disabled={!enableButton}
                    {...{
                        href: enableButton ? href : undefined,
                        onClick: enableButton ? handleReviewClick : undefined,
                    }}
                    rightIcon={<i className="ti ti-chevron-right" />}
                >
                    Review
                </Button>
            </Group>
        </TagActionContainer>
    );
};

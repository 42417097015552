import styled from '@emotion/styled';
import { Box, Button, Card, Divider, Group, Popover, Text, TextInput, ThemeIcon, Tooltip, useMantineTheme } from '@mantine/core';
import { AlertCircle, Check, Icon, InfoCircle, Tag, X } from 'tabler-icons-react';
import { TagOption, getContractTagOptions, getTypeTagOptions } from '../Services/MapCostAllocationTagService';
import { MapResourceQueryService } from '../Services/MapResourceQueryService';
import { theme } from '@root/Design/Themes';
import { useState } from 'react';

interface IMapTagPickerProps {
    contractId: string;
    size: 'sm' | 'lg';
    selected: string;
    onSelectPanel?: (selected: TagOption) => void;
    onSelectInline?: (selected: string) => void;
}
export function MapTagPicker({ contractId, size, selected, onSelectPanel }: IMapTagPickerProps) {
    const theme = useMantineTheme();
    const tagOptions = getContractTagOptions(contractId);
    return (
        <>
            {tagOptions.map((tag) => {
                return (
                    <MapTagPickerOption
                        description={tag.title}
                        onClick={() => onSelectPanel!(tag)}
                        selected={selected === tag.value}
                        size={size}
                        value={tag.value}
                    />
                );
            })}
        </>
    );
}

export function ResourceTypeMapTagPicker({
    size,
    selected,
    onSelectPanel,
    onSelectInline,
    querySvc,
    type,
}: Omit<IMapTagPickerProps, 'contractId'> & { querySvc: MapResourceQueryService; type?: string[] }) {
    const tagOptions = getTypeTagOptions(querySvc, type);
    return (
        <>
            <Box sx={{ background: theme.colors?.gray?.[2] }} px={size === 'lg' ? 'sm' : 'xs'} py={size === 'lg' ? 5 : '5px'}>
                <Text>Eligible Tags</Text>
            </Box>
            <Divider />
            {tagOptions
                .filter((tag) => tag.disabled === false && !tag.other)
                .map((tag) => (
                    <MapTagPickerOption
                        key={tag.value}
                        description={tag.title}
                        onClick={() => (size === 'lg' ? onSelectPanel!(tag) : onSelectInline!(tag.value))}
                        selected={selected === tag.value}
                        size={size}
                        value={tag.value}
                        Icon={Tag}
                    />
                ))}
            <Divider />
            <Box sx={{ background: theme.colors?.gray?.[2] }} px={size === 'lg' ? 'sm' : 'xs'} py={size === 'lg' ? 5 : '5px'}>
                <Text>Ineligible Tags</Text>
            </Box>
            <Divider />
            {tagOptions
                .filter((tag) => tag.disabled === true && !tag.other)
                .map((tag) => (
                    <MapTagPickerOption
                        key={tag.value}
                        description={tag.title}
                        onClick={() => (size === 'lg' ? onSelectPanel!(tag) : onSelectInline!(tag.value))}
                        selected={selected === tag.value}
                        size={size}
                        value={tag.value}
                        Icon={Tag}
                    />
                ))}
            <Box sx={{ background: theme.colors?.gray?.[2] }} px={size === 'lg' ? 'sm' : 'xs'} py={size === 'lg' ? 5 : '5px'}>
                <Text>Other Options</Text>
            </Box>
            <Divider />
            {tagOptions
                .filter((tag) => tag.other)
                .map((tag) => (
                    <MapTagPickerOption
                        key={tag.value}
                        description={tag.title}
                        onClick={() => (size === 'lg' ? onSelectPanel!(tag) : onSelectInline!(tag.value))}
                        selected={selected === tag.value}
                        size={size}
                        value={tag.value}
                        Icon={Tag}
                    />
                ))}
        </>
    );
}

export function MapTagPickerOption({
    size,
    value,
    selected,
    onClick,
    description,
    Icon = Tag,
}: {
    size: 'sm' | 'lg';
    value: string;
    description: string;
    selected: boolean;
    onClick: () => void;
    Icon?: Icon;
}) {
    return (
        <Card
            component={MapTagPickerItem}
            px={size === 'lg' ? 'sm' : 'xs'}
            py={size === 'lg' ? 5 : '5px'}
            my={2}
            selected={selected}
            onClick={onClick}
            data-atid={'MapTagOption:' + value}
        >
            <Group noWrap position="apart" align="center">
                <Group noWrap sx={{ flex: 1 }}>
                    <Icon size={size === 'lg' ? 20 : 16} style={{ flexShrink: 0 }} />
                    <Box sx={{ overflow: 'hidden' }} data-atid={'MapTagOption:' + value}>
                        <Text size={size === 'lg' ? 'md' : 'sm'}>{value}</Text>
                        <Text
                            hidden={size === 'sm'}
                            color="dimmed"
                            size="xs"
                            sx={{ overflowWrap: 'break-word', overflow: 'hidden', textOverflow: 'wrap' }}
                        >
                            {description}
                        </Text>
                    </Box>
                </Group>
                {size !== 'sm' ? null : (
                    <Tooltip withinPortal label={description}>
                        <ThemeIcon className="help-icon" radius="lg" variant="light" color="gray.6">
                            <InfoCircle size={16} />
                        </ThemeIcon>
                    </Tooltip>
                )}
            </Group>
        </Card>
    );
}
const MapTagPickerItem = styled.a<{ selected: boolean }>`
    background: ${(props) => (props.selected === true ? props.theme.colors.primary[2] : props.theme.white)};
    color: ${(props) => (props.selected === true ? props.theme.colors.primary[6] : props.theme.black)};
    border-radius: 0;
    margin: 0;
    cursor: pointer;
    border: 1px solid ${(props) => (props.selected === true ? props.theme.colors.primary[3] : props.theme.colors.gray[2])};
    .help-icon {
        visibility: hidden;
    }
    &:hover {
        background: ${(props) => (props.selected === true ? props.theme.colors.primary[3] : props.theme.colors.primary[1])};
        .help-icon {
            visibility: visible;
        }
    }
`;

export function TaggingGuide() {
    return (
        <Button
            size="xs"
            variant="outline"
            component="a"
            target="_blank"
            href="https://s3.us-west-2.amazonaws.com/map-2.0-customer-documentation/html/AWSMapDocs/setting-up.html"
        >
            Tagging Guide
        </Button>
    );
}

export function MpeIdInput({ onChange, value, hideLabel }: { onChange: (value: string) => void; value: string; hideLabel?: boolean }) {
    const [popoverOpened, setPopoverOpened] = useState(false);
    const [validIndex, setValidIndex] = useState<number>();
    const theme = useMantineTheme();

    const requirements = [
        { re: /^([0-9]){5}$/, label: 'Numeric length of 5' },
        { re: /^([A-Za-z0-9]){10}$/, label: 'Alphanumeric length of 10' },
    ];
    const matchFound = requirements.some((requirement) => requirement.re.test(value));

    const checks = requirements.map((requirement, index) => (
        <PasswordRequirement
            key={index}
            label={requirement.label}
            meets={requirement.re.test(value)}
            index={index}
            validIndex={validIndex}
            setValidIndex={setValidIndex}
        />
    ));

    const handleOnChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        onChange(evt.currentTarget.value);
    };
    return (
        <Popover opened={popoverOpened} width="300px" position="bottom" withArrow shadow="md" withinPortal={true}>
            <Popover.Target>
                <div onFocusCapture={() => setPopoverOpened(true)} onBlurCapture={() => setPopoverOpened(false)}>
                    <TextInput
                        autoComplete="off"
                        onChange={handleOnChange}
                        value={value ?? ''}
                        required
                        maxLength={10}
                        placeholder='e.g. "12345"'
                        error={!matchFound && value.length > 0 && !popoverOpened}
                        label={hideLabel ? undefined : 'MPE ID'}
                        data-atid="EditMPEInput"
                    />
                </div>
            </Popover.Target>
            <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
                <Text size="sm">{matchFound ? 'ID is valid' : 'ID must match one of the following:'}</Text>
                {checks}
            </Popover.Dropdown>
        </Popover>
    );
}
function PasswordRequirement({
    meets,
    label,
    index,
    validIndex,
    setValidIndex,
}: {
    meets: boolean;
    label: string;
    index: number;
    validIndex: number | undefined;
    setValidIndex: (i: number | undefined) => void;
}) {
    if (meets) {
        setValidIndex(index);
    } else if (validIndex === index && !meets) {
        setValidIndex(undefined);
    }
    return (
        <>
            {(validIndex ?? index) === index && (
                <Text color={meets ? 'success' : 'error'} sx={{ display: 'flex', alignItems: 'center' }} mt={7} size="sm">
                    {meets ? <Check size="0.9rem" /> : <X size="0.9rem" />} <Box ml={10}>{label}</Box>
                </Text>
            )}
        </>
    );
}

import { EventEmitter } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { JobService } from '@root/Services/Jobs/JobService';
import { inject, singleton } from 'tsyringe';
import { ActivityPoller } from './ActivityPoller';
import { JobStatus, ActivityItemAdapter, ActivityItemModel } from './ActivityTypes';

@singleton()
export class ActivityPanelModel {
    public jobsUpdated = new EventEmitter<JobStatus[]>([]);
    public newJobs = 0;
    public toggleRequested = EventEmitter.empty();

    private lastViewed = new Date();
    private disposer = () => {};

    public constructor(
        @inject(JobService) private readonly jobService: JobService,
        @inject(FormatService) private readonly formatSvc: FormatService,
        @inject(ActivityPoller) private readonly activityPoller: ActivityPoller,
        @inject(ActivityItemAdapter) public readonly activityItemAdapter: ActivityItemAdapter
    ) {
        this.jobService.newJobStarted.listen(this.loadJobs);
        this.seeJobs();
    }

    public seeJobs() {
        this.lastViewed = this.formatSvc.toUtc(new Date());
    }

    public poll() {
        this.loadJobs();
    }
    public pause() {
        this.disposer();
    }

    private loadJobs = async () => {
        this.disposer?.();
        this.disposer = this.activityPoller.listen((statuses) => {
            this.newJobs = statuses.reduce(
                (result, item) => result + (item.job.QueuedAt && new Date(item.job.QueuedAt) > this.lastViewed ? 1 : 0),
                0
            );
            if (statuses.length > 0) {
                this.jobsUpdated.emit(statuses);
            }
        }).dispose;
    };
}

export interface ActivityDetailsProps {
    item: JobStatus;
    displayInfo: ActivityItemModel;
}

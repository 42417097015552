import { Anchor, Grid, Group, LoadingOverlay, Progress } from '@mantine/core';
import { CoverageText, PercentCovered, Header, NoEffectivePolicies, Container } from '../../TagPolicies';
import { PieChart } from '@root/Components/Charts/PieChart';
import { DataGrid } from '@root/Components/DataGrid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomColors, theme } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { ColumnConfig } from '@root/Components/DataGrid/Models';
import { postResourcesQuery, postTagPolicyGetAzureTagPolicies, QueryExpr } from '@apis/Resources';
import { azureTagPolicyComplianceCounts } from '@root/Components/TagManager/TagPolicyFunctions';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { PanelBody } from '@root/Design/Layout';
import { BasicRouteLoader } from '@root/Services/Router/BasicRouteLoader';
import { useNav } from '@root/Services/NavigationService';
import { getCompanyGetCompanyCloudProviders } from '@apis/Customers';
import { CompanyCloudProvider } from '@apis/Customers/model';
import { useLink } from '@root/Services/Router/Router';
import { AzureTagPolicy, BaseResource } from '@apis/Resources/model';
import { queryBuilder, traverseExprDepthFirst } from '@root/Services/QueryExpr';

type PolicyAggregateData = {
    subscriptionId: string;
    subscription: string;
    rules: number;
    compliant: number;
    nonCompliant: number;
    notCovered: number;
    total: number;
};

export const AzureTagPolicySection = () => {
    const [policySummary, setPolicySummary] = useState<PolicyAggregateData[]>();
    const [numInCompliance, setNumInCompliance] = useState<number>(0);
    const [numNotInCompliance, setNumNotInCompliance] = useState<number>(0);
    const [numNotCovered, setNumNotCovered] = useState<number>(0);
    const [totalResources, setTotalResources] = useState<number>(0);
    const [tagKeys, setTagKeys] = useState(0);
    const [coveredKeys, setCoveredKeys] = useState(0);

    const fmtSvc = useDi(FormatService);

    useEffect(() => {
        (async () => {
            const [azureTagPolicies, cloudProviders] = await Promise.all([postTagPolicyGetAzureTagPolicies(), getCompanyGetCompanyCloudProviders()]);
            const policyLookup = azureTagPolicies.reduce((result, p) => {
                let subPolicies = result.get(p.SubscriptionId ?? '');
                if (!subPolicies) {
                    result.set(p.SubscriptionId ?? '', (subPolicies = []));
                }
                subPolicies.push(p);
                return result;
            }, new Map<string, AzureTagPolicy[]>());

            const tagKeys = await queryBuilder<{ ['Tags.Key']: string }>()
                .select((b) => ({ tagKeys: b.countUniqueValues(b.model['Tags.Key']) }))
                .execute((q) => postResourcesQuery(q));
            setTagKeys(tagKeys.Results?.[0]?.tagKeys ?? 0);
            setCoveredKeys(
                azureTagPolicies.reduce((result, p) => {
                    if (p.PolicyRule) {
                        traverseExprDepthFirst(p.PolicyRule as QueryExpr, (expr) => {
                            if ('Field' in expr && expr.Field.startsWith('CsTags.')) {
                                result.add(expr.Field);
                            }
                        });
                    }
                    return result;
                }, new Set<string>()).size
            );

            const policies: PolicyAggregateData[] = cloudProviders.map((p) => ({
                compliant: 0,
                nonCompliant: 0,
                notCovered: 0,
                rules: policyLookup.get(p.ExternalId ?? '')?.length ?? 0,
                subscription: p.Name ?? 'Unknown',
                subscriptionId: p.ExternalId ?? 'Unknown',
                total: 0,
            }));

            const compliantBySub = await queryBuilder<BaseResource & { SubscriptionId: string }>()
                .select((b) => ({
                    subscriptionId: b.model.SubscriptionId,
                    compliant: b.countIf(b.model.ComplianceScore!.eq(1)),
                    notCovered: b.countIf(b.model.ComplianceScore!.isNull()),
                    total: b.count(),
                }))
                .execute((q) => postResourcesQuery(q));
            const totals = { totalCompliant: 0, totalNonCompliant: 0, totalNotCovered: 0, total: 0 };
            const countLookup = (compliantBySub.Results ?? []).reduce((result, item) => {
                totals.totalCompliant += item.compliant;
                totals.totalNotCovered += item.notCovered;
                totals.total += item.total;
                totals.totalNonCompliant += item.total - item.notCovered - item.compliant;
                return result.set(item.subscriptionId ?? '', item);
            }, new Map<string, Partial<PolicyAggregateData>>());
            policies.forEach((p) => {
                const counts = Object.assign({ total: 0, compliant: 0, notCovered: 0 }, countLookup.get(p.subscriptionId));
                Object.assign(p, counts, { nonCompliant: counts.total - counts.compliant - counts.notCovered });
            });

            setPolicySummary(policies);
            setNumInCompliance(totals.totalCompliant);
            setNumNotInCompliance(totals.totalNonCompliant);
            setNumNotCovered(totals.totalNotCovered);
            setTotalResources(totals.total);
        })();
    }, []);

    const link = useLink();
    const { descend, getDescendUrl } = useNav();
    const selectPolicy = useCallback((policy: PolicyAggregateData) => {
        descend('tag-policy-details', {
            subscriptionId: policy.subscriptionId,
            cloudProvider: 'azure',
        });
    }, []);

    const cols = useMemo(() => {
        return [
            {
                header: 'Subscription ID',
                defaultWidth: 140,
                id: 'subscriptionId',
                accessor: 'subscriptionId',
                type: 'string',
                align: 'left',
                sortField: 'subscriptionId',
                filter: {
                    filterField: 'subscriptionId',
                    filterType: 'string',
                    name: 'subscripitonId',
                    options: {
                        getValueProvider: () => policySummary?.map((p) => ({ label: p.subscriptionId, value: p.subscriptionId })) ?? [],
                    },
                },
            },
            {
                header: 'Subscription',
                defaultWidth: 300,
                id: 'subscription',
                accessor: 'subscription',
                cellRenderer: (item) => {
                    const linkProps = link(getDescendUrl('tag-policy-details', { subscriptionId: item.subscriptionId, cloudProvider: 'azure' }));
                    return <Anchor {...linkProps}>{item.subscription}</Anchor>;
                },
                type: 'string',
                align: 'left',
                sortField: 'subscription',
                filter: {
                    filterField: 'subscription',
                    filterType: 'string',
                    name: 'subscripiton',
                    options: {
                        getValueProvider: () => policySummary?.map((p) => ({ label: p.subscription, value: p.subscription })) ?? [],
                    },
                },
            },
            {
                header: 'Policies',
                accessor: 'rules',
                defaultWidth: 100,
                id: 'rules',
                type: 'number',
                align: 'right',
                sortField: 'rules',
                formatter: (item) => fmtSvc.formatInt(item.rules),
                filter: true,
            },
            {
                header: 'Compliant',
                accessor: 'compliant',
                defaultWidth: 150,
                id: 'compliant',
                type: 'number',
                align: 'right',
                sortField: 'compliant',
                formatter: (item) => fmtSvc.formatInt(item.compliant),
                filter: true,
            },
            {
                header: 'Non-Compliant',
                accessor: 'nonCompliant',
                defaultWidth: 150,
                id: 'nonCompliant',
                type: 'number',
                align: 'right',
                sortField: 'nonCompliant',
                formatter: (item) => fmtSvc.formatInt(item.nonCompliant),
                filter: true,
            },
            {
                header: 'Total',
                accessor: 'total',
                defaultWidth: 150,
                id: 'total',
                align: 'right',
                type: 'number',
                sortField: 'total',
                formatter: (item) => fmtSvc.formatInt(item.total),
                filter: true,
            },
            {
                header: 'Compliance',
                accessor: (item) => (item.compliant + item.nonCompliant > 0 ? (item.compliant / (item.compliant + item.nonCompliant)) * 100 : 0),
                cellRenderer: (item) => {
                    return (
                        <Group position="apart">
                            <Group>
                                <div style={{ marginTop: '2px', width: '100px' }}>
                                    <Progress
                                        radius="md"
                                        size="md"
                                        value={
                                            item.compliant + item.nonCompliant > 0 ? (item.compliant / (item.compliant + item.nonCompliant)) * 100 : 0
                                        }
                                    />
                                </div>
                                <div style={{ textAlign: 'right', width: '40px' }}>
                                    {Math.round(
                                        item.compliant + item.nonCompliant > 0 ? (item.compliant / (item.compliant + item.nonCompliant)) * 100 : 0
                                    )}
                                    %
                                </div>
                            </Group>
                            <div>
                                <i className="ti ti-chevron-right"></i>
                            </div>
                        </Group>
                    );
                },
                defaultWidth: 250,
                id: 'Compliance',
                align: 'left',
                type: 'number',
            },
        ] as ColumnConfig<PolicyAggregateData>[];
    }, [policySummary]);

    return (
        <PanelBody>
            <Grid>
                <Grid.Col span={6}>
                    <Container>
                        <Header>Resource Policy Coverage</Header>
                        <div style={{ textAlign: 'center' }}>
                            <PercentCovered>
                                {totalResources <= 0 ? 0 : Math.floor((totalResources - numNotCovered) / totalResources) * 100}%
                            </PercentCovered>
                            <Group position="apart" align="center" spacing={0}>
                                <CoverageText style={{ fontWeight: 'bold', background: theme.colors?.gray?.[2] as CustomColors }}>
                                    <br />
                                    Covered by Policy
                                </CoverageText>
                                <CoverageText style={{ fontWeight: 'bold', background: theme.colors?.gray?.[2] as CustomColors }}>
                                    Not Covered
                                    <br />
                                    by Policy
                                </CoverageText>
                                <CoverageText style={{ fontWeight: 'bold', background: theme.colors?.gray?.[2] as CustomColors }}>
                                    <br />
                                    Total
                                </CoverageText>
                            </Group>
                            <Group position="apart" align="center" spacing={0}>
                                <CoverageText>{fmtSvc.formatInt(totalResources - numNotCovered)}</CoverageText>
                                <CoverageText>{fmtSvc.formatInt(numNotCovered)}</CoverageText>
                                <CoverageText>{fmtSvc.formatInt(totalResources)}</CoverageText>
                            </Group>
                        </div>
                    </Container>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Container>
                        <Header>Tag Keys Covered by Policies</Header>
                        {coveredKeys > 0 ? (
                            <div style={{ height: '150px' }}>
                                <PieChart
                                    settings={{ noWrapper: true }}
                                    groups={['x']}
                                    values={['y']}
                                    data={[
                                        { x: 'Covered', y: coveredKeys },
                                        { x: 'Not Covered', y: tagKeys - coveredKeys },
                                    ]}
                                />
                            </div>
                        ) : (
                            <NoEffectivePolicies>No tag policies</NoEffectivePolicies>
                        )}
                    </Container>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Container style={{ height: '416px' }}>
                        <Header style={{ paddingBottom: '16px' }}>Tag Policies by Subscription</Header>
                        <div style={{ height: '350px' }}>
                            <LoadingOverlay visible={!policySummary} />
                            {!policySummary ? null : (
                                <DataGrid dataSource={policySummary} minimumLoadingMs={0} columns={cols} hideHeader onRowClick={selectPolicy} />
                            )}
                        </div>
                    </Container>
                </Grid.Col>
            </Grid>
        </PanelBody>
    );
};

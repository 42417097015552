import { singleton } from 'tsyringe';
import { EventEmitter } from '../EventEmitter';

@singleton()
export class TenantCacheInvalidator {
    private invalidateRequested = new EventEmitter<number>(0);

    public invalidate(...companyIds: number[]) {
        for (const companyId of companyIds) {
            this.invalidateRequested.emit(companyId);
        }
    }

    public listen(callback: (companyId: number) => void) {
        return this.invalidateRequested.listen(callback);
    }
}

import { Box, Divider, Group, Title, useMantineTheme } from '@mantine/core';
import { PageContent } from '@root/Design/Layout';
import { ReactNode } from 'react';
import { TagIntelligenceNav } from './TagIntelligenceNav';

export function TagIntelligencePageHeader({ text, children }: { text: string; children?: ReactNode }) {
    return (
        <div
            style={{
                paddingBottom: '1rem',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid lightgray',
            }}
        >
            <Group position="apart">
                <Title order={3} px="xl" pt="md">
                    {text}
                </Title>
                <Group style={{ paddingRight: '26px', paddingTop: '11px' }}>{children}</Group>
            </Group>
        </div>
    );
}

export function TagIntelligencePage({
    children,
    pageContentRef,
}: {
    children: ReactNode;
    pageContentRef?: (el: HTMLElement | null) => HTMLElement | null;
}) {
    const theme = useMantineTheme();

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ flex: 0, height: '100%' }}>
                <TagIntelligenceNav />
            </Box>
            <Divider orientation="vertical" />
            <PageContent ref={pageContentRef}>{children}</PageContent>
        </Box>
    );
}

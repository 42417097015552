import {
    MapCreditsOptions,
    MapCreditsOptionsMapCreditType,
    MapCreditsOptionsMapDestination,
    MapCreditsOptionsMapMigrationType,
    MapSupportedServiceTargetType,
    TagAutomationRule,
    TagAutomationRuleStatus,
} from '@apis/TagManager/model';
import styled from '@emotion/styled';
import {
    Anchor,
    Box,
    Button,
    Card,
    CloseButton,
    Divider,
    Drawer,
    Group,
    List,
    Loader,
    Modal,
    Select,
    Space,
    Stack,
    Text,
    TextInput,
    Title,
    UnstyledButton,
    useMantineTheme,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { colorPalette, CustomColors } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { RuleEditor, TagLookupService } from '@root/Site/TagManager/TagAutomation/Components/Model';
import { useFilterValidation } from '@root/Site/TagManager/TagAutomation/Components/RuleDescriber';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import { Trash } from 'tabler-icons-react';
import { TaggingGuide } from '../Components';
import { CardContentLayout, EditorCard, EditorCardTitle } from './Components';
import { getDestinationTypes, getMigrationTypes, getSourceTypes, getSuggestedName, getTagValue, SelectOption } from './MapDefinitions';
import { MapFilterResourcesCard } from './MapFilterResourcesCard';
import MapRuleStatusCard from './MapRuleStatusCard';
import { FormatService } from '@root/Services/FormatService';
import { MapResourceQueryService } from '../../Services/MapResourceQueryService';
import { useDisclosure } from '@mantine/hooks';
import { makeAutoObservable } from 'mobx';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { inject, scoped, Lifecycle } from 'tsyringe';
import { MapContractHomeModel } from '../Models';
import { MapAutoTagModel } from './MapAutoTagModel';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { MapContractEditContext } from '@root/Site/TagManager/TagAutomation/AutomationRuleActivity';

@scoped(Lifecycle.ContainerScoped)
class MapRuleEditorContext {
    private initialized = false;
    public loading = new EventEmitter(true);
    public ruleEditor: RuleEditor;

    public constructor(
        @inject(TagLookupService) private readonly tagLookupSvc: TagLookupService,
        @inject(FormatService) private readonly formatSvc: FormatService
    ) {
        this.ruleEditor = new RuleEditor(formatSvc);
        makeAutoObservable(this);
    }

    public async load(contractId: number, ruleId?: number) {
        this.loading.emit(true);
        try {
            this.ruleEditor = new RuleEditor(this.formatSvc);
            await this.ruleEditor.init(ruleId).then(() => {
                this.ruleEditor.rule!.Parameters ??= {};
                this.ruleEditor.rule!.Parameters.Type = 'MapCredits';
                this.ruleEditor.rule!.Status ??= 'Draft';
                this.ruleEditor.rule!.Parameters.Syntax ??= {};
                this.ruleEditor.rule!.Parameters.Syntax.MapCreditsOptions ??= {
                    ContractDBId: contractId,
                };
            });
            if (!this.initialized) {
                await this.tagLookupSvc.init();
                this.initialized = true;
            }
        } finally {
            this.loading.emit(false);
        }
    }
}

export const MapAutoTagRule = observer(function MapAutoTagRule({
    automationRuleId,
    contractId,
    mapQueryService,
    onClose,
    onSave,
    onDelete,
}: {
    automationRuleId: number | null;
    contractId: number;
    mapQueryService: MapResourceQueryService;
    onClose: () => void;
    onSave: (rule: TagAutomationRule) => Promise<void>;
    onDelete: (rule: TagAutomationRule) => Promise<void>;
}) {
    const editorCtx = useDi(MapRuleEditorContext);
    const autoTagModel = useDi(MapAutoTagModel);
    const loading = useEventValue(autoTagModel.loading);
    const [rule, setRule] = useState<MapCreditsOptions>();
    const [canSave, setCanSave] = useState(false);

    useEffect(() => {
        editorCtx.load(contractId, automationRuleId!).then(() => {
            setRule(editorCtx.ruleEditor.rule!.Parameters?.Syntax?.MapCreditsOptions);
        });
    }, [automationRuleId]);

    const mapContractEditCtx = useDi(MapContractEditContext);
    function closeModal() {
        mapContractEditCtx.editorOpen.emit(false);
        onClose();
    }

    const deleteRule = useCallback(async () => {
        if (!rule) return;

        openConfirmModal({
            title: (
                <span style={{ color: 'red' }}>
                    <b>Delete Invitation?</b>
                </span>
            ),
            zIndex: 10000,
            children: <Text>Are you sure you want to delete this rule?</Text>,
            centered: true,
            labels: { confirm: 'Delete Rule', cancel: 'Cancel' },
            onConfirm: async () => {
                await onDelete(editorCtx.ruleEditor.rule!);
                closeModal();
            },
            confirmProps: { color: 'error' },
        });
    }, [editorCtx.ruleEditor, rule]);
    const saveRule = useCallback(async () => {
        if (!rule) return;
        await onSave(editorCtx.ruleEditor.rule!);
    }, [rule]);

    return (
        <Drawer opened onClose={closeModal} padding={0} position="right" withinPortal={false} size={850} withCloseButton={false}>
            <DrawerLayout>
                <Group p="lg" position="apart">
                    <Title order={4}>Auto-Tagging Rule Editor</Title>
                    <CloseButton onClick={closeModal} size="lg" />
                </Group>
                <Divider />
                <Box sx={{ height: '100%', overflow: 'auto' }} p="lg">
                    {!editorCtx.ruleEditor || !rule ? null : (
                        <MapAutoTagRuleContent
                            onValidityChanged={setCanSave}
                            ruleEditor={editorCtx.ruleEditor}
                            rule={rule}
                            mapQueryService={mapQueryService}
                        />
                    )}
                </Box>
                <Divider />
                <Box>
                    <Group position="apart" p="lg">
                        <Box>
                            <Button variant="light" onClick={deleteRule} leftIcon={<Trash />} color="error" hidden={!automationRuleId}>
                                Delete Rule
                            </Button>
                        </Box>
                        <Group>
                            <Button onClick={closeModal} variant="outline">
                                Cancel
                            </Button>
                            <Button
                                disabled={!canSave || loading || (rule?.MapWorkloadValue ?? '') == ''}
                                onClick={saveRule}
                                leftIcon={loading ? <Loader size={'xs'} /> : <></>}
                                sx={{ minWidth: '72px' }}
                            >
                                Save
                            </Button>
                        </Group>
                    </Group>
                </Box>
            </DrawerLayout>
        </Drawer>
    );
});

const MapAutoTagRuleContent = observer(function MapAutoTagRuleContent({
    rule,
    ruleEditor,
    mapQueryService,
    onValidityChanged,
}: {
    rule: MapCreditsOptions;
    ruleEditor: RuleEditor;
    mapQueryService: MapResourceQueryService;
    onValidityChanged: (valid: boolean) => void;
}) {
    const theme = useMantineTheme();
    const homeModel = useDi(MapContractHomeModel);
    const [migrationTypeOptions, setMigrationTypeOptions] = useState<SelectOption[]>([]);
    const [sourceTypeOptions, setSourceTypeOptions] = useState<SelectOption[]>([]);
    const [destinationTypeOptions, setDestinationTypeOptions] = useState<SelectOption[]>([]);
    const filterValidityChanged = useFilterValidation(ruleEditor);
    const [nameSuggestion, setNameSuggestion] = useState<string>(getSuggestedName(rule));
    const [services, setServices] = useState<string[]>([]);
    const [opened, { open, close }] = useDisclosure(false);
    const [columnCount, setColumnCount] = useState<number>(1);
    const [confirmed, setConfirmed] = useState<boolean>(false);

    const handleConfirmed = (value: boolean) => {
        setConfirmed(value);
    };

    useEffect(() => {
        setMigrationTypeOptions(getMigrationTypes());
    }, []);

    useEffect(() => {
        setSourceTypeOptions(getSourceTypes(rule.MapMigrationType));
    }, [rule.MapMigrationType]);

    useEffect(() => {
        setColumnCount(Math.ceil(services.length / 25));
    }, [services]);

    useEffect(() => {
        if (sourceTypeOptions.length === 1) {
            rule.MapCreditType = sourceTypeOptions[0].value as MapCreditsOptionsMapCreditType;
        } else if (sourceTypeOptions.length > 1 && !sourceTypeOptions.find((x) => x.value === rule.MapCreditType)) {
            rule.MapCreditType = undefined;
        }
    }, [sourceTypeOptions]);

    useEffect(() => {
        setDestinationTypeOptions(getDestinationTypes(rule.MapMigrationType, rule.MapCreditType));
    }, [rule.MapMigrationType, rule.MapCreditType]);

    useEffect(() => {
        if (!rule.MapCreditType) {
            rule.MapDestination = undefined;
        } else if (destinationTypeOptions.length === 1) {
            rule.MapDestination = destinationTypeOptions[0].value as MapCreditsOptionsMapDestination;
        } else if (destinationTypeOptions.length > 1 && !destinationTypeOptions.find((x) => x.value === rule.MapDestination)) {
            rule.MapDestination = undefined;
        }
    }, [destinationTypeOptions]);

    useEffect(() => {
        if (rule.MapCreditType && rule.MapDestination && rule.MapMigrationType) {
            const tagValue = getTagValue(rule.MapMigrationType, rule.MapCreditType, rule.MapDestination);
            rule.ValueToAdd = tagValue;
            const targetType =
                rule.MapMigrationType === 'Oracle'
                    ? MapSupportedServiceTargetType.OracleSap
                    : rule.MapMigrationType === 'SAP'
                    ? MapSupportedServiceTargetType.OracleSap
                    : rule.MapMigrationType === 'DatabaseAndAnalytics'
                    ? MapSupportedServiceTargetType.DBA
                    : MapSupportedServiceTargetType.Compute;
            setServices(
                mapQueryService.mapResourceTypeMeta.reduce((result, item) => {
                    if (item.ServiceName && item.Targets?.find((t) => t.Type === targetType)) {
                        result.push(item.ServiceName);
                    }
                    return result;
                }, [] as string[])
            );
            const newSuggestedName = getSuggestedName(rule);
            if (ruleEditor.rule && (ruleEditor.rule.RuleName === nameSuggestion || !ruleEditor.rule.RuleName)) {
                ruleEditor.rule.RuleName = newSuggestedName;
            }
            setNameSuggestion(newSuggestedName);
        } else {
            rule.ValueToAdd = undefined;
            setServices([]);
        }
    }, [rule.MapCreditType, rule.MapDestination, rule.MapMigrationType]);

    const updateRuleStatus = (status: TagAutomationRuleStatus) => {
        ruleEditor.setRuleStatus(status);
    };

    const displayTagValue = () => {
        if (!rule.ValueToAdd || !homeModel.contract?.ProgramId) return '--';
        return rule.ValueToAdd + homeModel.contract?.ProgramId;
    };

    const confirmStateIsValid = () => {
        return ruleEditor.rule?.Status !== TagAutomationRuleStatus.Active || (ruleEditor.rule.Status === TagAutomationRuleStatus.Active && confirmed);
    };

    useEffect(() => {
        onValidityChanged(
            ruleEditor.filterValidation.length === 0 &&
                !!rule.ValueToAdd &&
                !!rule.MapDestination &&
                !!rule.MapCreditType &&
                !!rule.MapMigrationType &&
                confirmStateIsValid()
        );
    }, [
        ruleEditor.filterValidation.length,
        rule.ValueToAdd,
        rule.MapDestination,
        rule.MapCreditType,
        rule.MapMigrationType,
        ruleEditor.rule?.Status,
        confirmed,
    ]);

    interface workloads {
        value: string;
        label: string;
    }
    const [mapWorkloadValue, setMapWorkloadValue] = useState(rule.MapWorkloadValue ?? '');
    const [mapWorkloadData, setMapWorkloadData] = useState<workloads[]>([]);
    const resourceSvc = useDi(ResourceService);

    const onValueChange = (value: any) => {
        setMapWorkloadValue(value);
        rule.MapWorkloadValue = value;
    };

    useEffect(() => {
        (async () => {
            var mapWorkloadKeys = await resourceSvc.getTagValues('map-workload');
            mapWorkloadKeys = removeItemsWithName(mapWorkloadKeys, '');
            if (
                rule.MapWorkloadValue !== null && //Existing rule with no MapWorkloadValue
                rule.MapWorkloadValue !== undefined && //New rule
                rule.MapWorkloadValue !== '' &&
                !mapWorkloadKeys.includes(rule.MapWorkloadValue!)
            ) {
                // This will happen if the user edits a tag rule before any resources have been indexed.
                mapWorkloadKeys.push(rule.MapWorkloadValue!);
            }
            mapWorkloadKeys.sort((a, b) => a.localeCompare(b));
            setMapWorkloadData(mapWorkloadKeys.map((s) => ({ value: s, label: s })));
        })();
    }, []);
    function removeItemsWithName(items: string[], name: string): string[] {
        return items.filter((i) => i !== name);
    }
    useEffect(() => {
        setMapWorkloadValue(rule.MapWorkloadValue ?? '');
    }, [mapWorkloadData]);

    return (
        <>
            <Stack>
                {ruleEditor.rule && <MapRuleName rule={ruleEditor.rule} />}
                <EditorCard>
                    <CardContentLayout>
                        <EditorCardTitle title="Tag Selection" description="Identify which MAP tag applies to this rule" />
                        <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                            <TaggingGuide />
                        </Box>
                    </CardContentLayout>
                    <Space h="md" />
                    <CardContentLayout>
                        <Stack>
                            <InputLayout>
                                <Text size="sm" align="right">
                                    Migration Type
                                </Text>
                                <Select
                                    withinPortal
                                    sx={{ width: '300px' }}
                                    value={rule.MapMigrationType}
                                    data={migrationTypeOptions}
                                    onChange={(v) => (rule.MapMigrationType = v as MapCreditsOptionsMapMigrationType)}
                                />
                            </InputLayout>
                            <InputLayout>
                                <Text size="sm" align="right">
                                    Source
                                </Text>
                                <Select
                                    withinPortal
                                    sx={{ width: '300px' }}
                                    value={rule.MapCreditType ?? ''}
                                    data={sourceTypeOptions}
                                    onChange={(v) => (rule.MapCreditType = v as MapCreditsOptionsMapCreditType)}
                                />
                            </InputLayout>
                            <InputLayout>
                                <Text size="sm" align="right">
                                    Destination
                                </Text>
                                <Select
                                    withinPortal
                                    sx={{ width: '300px' }}
                                    value={rule.MapDestination ?? ''}
                                    data={destinationTypeOptions}
                                    onChange={(v) => (rule.MapDestination = v as MapCreditsOptionsMapDestination)}
                                />
                            </InputLayout>
                        </Stack>
                        <Card radius="md" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Text size="sm" weight="bold" align="center">
                                Applies to
                            </Text>
                            <Anchor onClick={open}>
                                <Text color="primary" weight="bold" size="xl" sx={{ fontSize: '2rem' }} align="center">
                                    {services.length}
                                </Text>
                            </Anchor>

                            <Text size="sm" align="center">
                                AWS Services
                            </Text>
                        </Card>
                    </CardContentLayout>
                    <>
                        <Divider size="sm" color={colorPalette.white as CustomColors} my="lg" mx={'-24px'} />
                        <CardContentLayout>
                            <InputLayout>
                                <Text align="right" size="sm" weight="bold">
                                    MAP Migrated Tag
                                </Text>
                                <div
                                    style={{
                                        border: 'px solid lightgray',
                                        borderRadius: '5px',
                                        backgroundColor: 'white',
                                        width: '300px',
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: '5px',
                                            width: '75px',
                                            float: 'left',
                                            borderBottom: '1px solid lightgray',
                                            borderRight: '1px solid lightgray',
                                            textAlign: 'right',
                                        }}
                                    >
                                        <Text span size="sm" color="gray" pr="xs">
                                            Key
                                        </Text>
                                    </div>
                                    <div
                                        style={{
                                            padding: '5px',
                                            width: '225px',
                                            float: 'right',
                                            borderBottom: '1px solid lightgray',
                                        }}
                                    >
                                        <Text span size="sm" color="primary" weight="bold">
                                            map-migrated
                                        </Text>
                                    </div>
                                    <div
                                        style={{
                                            padding: '5px',
                                            width: '75px',
                                            float: 'left',
                                            borderRight: '1px solid lightgray',
                                            textAlign: 'right',
                                        }}
                                    >
                                        <Text span size="sm" color="gray" mr="xs">
                                            Value
                                        </Text>
                                    </div>
                                    <div
                                        style={{
                                            padding: '5px',
                                            width: '220px',
                                            float: 'right',
                                            textAlign: 'left',
                                        }}
                                    >
                                        <Text span size="sm" color="primary" weight="bold">
                                            {displayTagValue()}
                                        </Text>
                                    </div>
                                </div>
                            </InputLayout>
                        </CardContentLayout>
                        <Space h={8} />
                        <CardContentLayout>
                            <InputLayout>
                                <Text align="right" size="sm" weight="bold">
                                    MAP Workload Tag
                                </Text>
                                <div
                                    style={{
                                        border: 'px solid lightgray',
                                        borderRadius: '5px',
                                        backgroundColor: 'white',
                                        width: '300px',
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: '5px',
                                            width: '75px',
                                            float: 'left',
                                            borderBottom: '1px solid lightgray',
                                            borderRight: '1px solid lightgray',
                                            textAlign: 'right',
                                        }}
                                    >
                                        <Text span size="sm" color="gray" pr="xs">
                                            Key
                                        </Text>
                                    </div>
                                    <div
                                        style={{
                                            padding: '5px',
                                            width: '225px',
                                            float: 'right',
                                            borderBottom: '1px solid lightgray',
                                        }}
                                    >
                                        <Text span size="sm" color="primary" weight="bold">
                                            map-workload
                                        </Text>
                                    </div>
                                    <div
                                        style={{
                                            padding: '5px',
                                            width: '75px',
                                            float: 'left',
                                            borderRight: '1px solid lightgray',
                                            textAlign: 'right',
                                        }}
                                    >
                                        <Text span size="sm" color="gray" mr="xs">
                                            Value
                                        </Text>
                                    </div>
                                    <div
                                        style={{
                                            padding: '5px 5px 0 0',
                                            width: '220px',
                                            float: 'right',
                                            textAlign: 'left',
                                        }}
                                    >
                                        <Select
                                            size="xs"
                                            placeholder="Select or type new"
                                            data={mapWorkloadData}
                                            disabled={false}
                                            onChange={onValueChange}
                                            nothingFound="Nothing found"
                                            value={mapWorkloadValue}
                                            searchable
                                            creatable
                                            getCreateLabel={(query) => `+ Create '${query}'`}
                                            onCreate={(query) => {
                                                const item = { value: query, label: query };
                                                setMapWorkloadData((current) => [...current, item]);
                                                return item;
                                            }}
                                        />
                                    </div>
                                </div>
                            </InputLayout>
                        </CardContentLayout>
                    </>
                    <Group position="apart"></Group>
                    <Space />
                    <Group>
                        <Box></Box>
                    </Group>
                    <Group>
                        <Box></Box>
                    </Group>
                </EditorCard>
                <MapFilterResourcesCard ruleEditor={ruleEditor} onValidationIssuesChanged={filterValidityChanged} />
                <MapRuleStatusCard ruleStatus={ruleEditor.rule?.Status} onChange={updateRuleStatus} onConfirmed={handleConfirmed} />
            </Stack>
            <Modal
                opened={opened}
                onClose={close}
                title="This rule will apply to the following list of MAP eligible services."
                size={600 + (columnCount > 1 ? 600 : 0)}
                overflow="inside"
            >
                <Divider size="sm" my="sm" color={theme?.colors.primary?.[6] as CustomColors} />
                <List sx={{ columnCount: columnCount }}>
                    {services
                        .sort((a, b) => a.localeCompare(b))
                        .map((s) => (
                            <List.Item>{s}</List.Item>
                        ))}
                </List>
            </Modal>
        </>
    );
});

const MapRuleName = observer(function MapRuleName({ rule }: { rule: TagAutomationRule }) {
    return (
        <EditorCard>
            <EditorCardTitle title="Rule Name" description="Give this rule a name so you can identify it" />
            <TextInput width="100%" value={rule.RuleName ?? ''} onChange={(e) => (rule.RuleName = e.currentTarget.value)} maxLength={75} />
        </EditorCard>
    );
});

const DrawerLayout = styled.div`
    display: grid;
    height: 100%;
    grid-template-rows: min-content 1px auto 1px min-content;
`;

const InputLayout = styled.div`
    display: grid;
    grid-template-columns: 160px auto;
    column-gap: 1rem;
    align-items: center;
`;

import { QuerySortExpr } from '@apis/Resources/model';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { ActionIcon, Badge, Box, Button, createStyles, Group, Menu, Overlay, Popover, Portal, Text, Tooltip, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { AnchorButton, SectionedPopover, SectionedPopoverToolbar } from '@root/Design/Primitives';
import { colorPalette } from '@root/Design/Themes';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { ReactNode, useMemo } from 'react';
import { FilterItem, FilterToken } from '../Filter/Design';
import { DataGridModel } from './DataGridModel';
import { GripVertical, Plus, Sort09, SortAscending2, SortAscendingLetters, SortAscendingNumbers, SortDescending, Trash } from 'tabler-icons-react';
import { traverseExpr } from '@root/Services/QueryExpr';

export function MultiSortButton({ grid, fieldPicker }: { grid: DataGridModel; fieldPicker: (select: (column: QuerySortExpr) => void) => ReactNode }) {
    const theme = useMantineTheme();
    const [opened, { open, close, toggle }] = useDisclosure(false);
    useEvent(grid.gridStateChanged);
    const sortBy = grid.gridState.sort;
    return (
        <>
            <Portal>{opened ? <Overlay onClick={close} opacity={0} zIndex={200} /> : null}</Portal>
            <Popover opened={opened} radius="sm" onClose={close} withinPortal closeOnClickOutside={false} withArrow offset={0} shadow="md">
                <SectionedPopover>{opened ? <MultiSortEditor fieldPicker={fieldPicker} grid={grid} onClose={close} /> : null}</SectionedPopover>
                <Popover.Target>
                    <FilterItem onClick={toggle} state="valid" style={{ color: colorPalette.linkColor }}>
                        <FilterToken style={{ fontSize: theme.fontSizes.xs, marginTop: 2 }}>
                            <SortDescending size={16} strokeWidth={1} style={{ transform: 'translate(0.5px, 0.5px)' }} />
                        </FilterToken>
                        <FilterToken>Sort By</FilterToken>
                        {sortBy.length ? <Badge>{sortBy.length}</Badge> : null}
                    </FilterItem>
                </Popover.Target>
            </Popover>
        </>
    );
}

class MultiSortEditorModel {
    public readonly sorts: MultiSortEditorItemModel[] = [];
    public readonly sortsChanged = EventEmitter.empty();
    public readonly addingSort = new EventEmitter<boolean>(false);

    constructor(private readonly grid: DataGridModel, private readonly onClose: () => void) {
        this.sorts = this.createSorts();
    }

    get canApply() {
        return true;
    }

    public applyChanges = () => {
        this.grid.sort(this.sorts.map((s) => ({ Expr: s.Expr, Direction: s.Direction })));
        this.onClose();
    };

    private createSorts() {
        const sorts = this.grid.gridState.sort;
        return sorts.map((s) => this.createSortModel(s));
    }

    private createSortModel(item: QuerySortExpr) {
        let field = '';
        traverseExpr(item.Expr!, (x) => {
            if ('Field' in x) {
                field = x.Field;
                return true;
            }
        });
        const name = field ? this.grid.columns.find((c) => c.sortField === field)?.header ?? field : 'Unknown';

        const sort: MultiSortEditorItemModel = {
            field,
            name,
            remove: () => {
                this.sorts.splice(this.sorts.indexOf(sort), 1);
                this.sortsChanged.emit();
            },
            sort: (dir) => {
                sort.Direction = dir;
                this.sortsChanged.emit();
            },
            ...item,
        };
        return sort;
    }

    public move = (e: DragEndEvent) => {
        const active = this.sorts.find((g) => g.field === e.active.id);
        const over = this.sorts.find((g) => g.field === e.over?.id);
        if (over && active) {
            const currIdx = this.sorts.indexOf(active);
            const nextIdx = this.sorts.indexOf(over);
            this.sorts.splice(0, Infinity, ...arrayMove(this.sorts, currIdx, nextIdx));
            this.sortsChanged.emit();
        }
    };

    public addSort = (sort: QuerySortExpr) => {
        if (!this.sorts.find((s) => s.field === sort.Expr?.Field)) {
            this.sorts.push(this.createSortModel(sort));
        }
        this.addingSort.emit(false);
    };
    public stopAddingSort = () => {
        this.addingSort.emit(false);
    };
    public toggleAddingSort = () => {
        this.addingSort.emit(!this.addingSort.value);
    };
}

function MultiSortEditor({
    onClose,
    grid,
    fieldPicker,
}: {
    onClose: () => void;
    grid: DataGridModel;
    fieldPicker: (select: (column: QuerySortExpr) => void) => ReactNode;
}) {
    const model = useMemo(() => new MultiSortEditorModel(grid, onClose), [grid]);
    const pickerOpened = useEventValue(model.addingSort);
    useEvent(model.sortsChanged);
    return (
        <>
            <SectionedPopoverToolbar>
                <Group position="right">
                    <Button color="primary" onClick={model.applyChanges} disabled={!model.canApply}>
                        Apply
                    </Button>
                    <Button variant="outline" onClick={onClose}>
                        Cancel
                    </Button>
                </Group>
            </SectionedPopoverToolbar>

            <Box py="md">
                <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={model.move}>
                    <SortableContext strategy={verticalListSortingStrategy} items={model.sorts.map((g) => g.field)}>
                        {model.sorts.map((g) => (
                            <MultiSortEditorItem key={g.field} item={g} sortChanged={model.sortsChanged} />
                        ))}
                    </SortableContext>
                </DndContext>
            </Box>
            <Portal>{pickerOpened ? <Overlay onClick={model.stopAddingSort} opacity={0} zIndex={300} /> : null}</Portal>
            <Popover opened={pickerOpened} withinPortal>
                <Popover.Dropdown p={0}>{fieldPicker(model.addSort)}</Popover.Dropdown>
                <Popover.Target>
                    <Box>
                        <AnchorButton onClick={model.toggleAddingSort} size="md" icon={<Plus size={16} />} text="Add Sort" />
                    </Box>
                </Popover.Target>
            </Popover>
        </>
    );
}

function MultiSortEditorItem({ item, sortChanged }: { item: MultiSortEditorItemModel; sortChanged: EventEmitter<void> }) {
    const sortOptions = useMemo(
        () =>
            [
                { ufDir: 'Ascending', dir: 'Asc', icon: 'ti-sort-ascending' },
                { ufDir: 'Descending', dir: 'Desc', icon: 'ti-sort-descending' },
            ].map((o) => ({
                sort: () => item.sort(o.dir as 'Asc' | 'Desc'),
                text: `Sort ${o.ufDir}`,
                selected: o.dir === item.Direction,
                icon: `ti ${o.icon}`,
            })),
        [item.field, item.Direction]
    );
    useEvent(sortChanged);
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ data: item, id: item.field });
    const selectedSort = sortOptions.find((o) => o.selected);
    const selectedSortIcon = selectedSort?.icon || 'ti ti-sort-ascending';
    const selectedSortLabel = selectedSort?.text || 'Sort by Count Descending';
    const { classes } = useStyles();

    return (
        <Group ref={setNodeRef} sx={{ transform: CSS.Transform.toString(transform), transition }}>
            <Group {...listeners} noWrap spacing={4} {...attributes} className={(isDragging ? 'is' : 'not') + '-dragging ' + classes.editorItemText}>
                <GripVertical style={{ flexShrink: 0 }} size={16} />
                <Text>{item.name}</Text>
            </Group>
            <Menu withinPortal withArrow position="bottom" shadow="md" offset={0}>
                <Menu.Target>
                    <Box>
                        <Tooltip label={selectedSortLabel} position="bottom" withArrow>
                            <ActionIcon key={selectedSortIcon}>
                                <i className={selectedSortIcon}></i>
                            </ActionIcon>
                        </Tooltip>
                    </Box>
                </Menu.Target>
                <Menu.Dropdown>
                    {sortOptions.map((o, i) => (
                        <Menu.Item
                            key={i}
                            onClick={o.sort}
                            icon={<i className={o.icon}></i>}
                            rightSection={o.selected ? <i style={{ marginLeft: 10 }} className="ti ti-check"></i> : null}
                        >
                            {o.text}
                        </Menu.Item>
                    ))}
                </Menu.Dropdown>
            </Menu>
            <Tooltip label="Remove Layer" position="right" withArrow>
                <ActionIcon onClick={item.remove}>
                    <Trash size={18} />
                </ActionIcon>
            </Tooltip>
        </Group>
    );
}

type MultiSortEditorItemModel = QuerySortExpr & {
    field: string;
    name: string;
    remove: () => void;
    sort: (dir: 'Asc' | 'Desc') => void;
};

const useStyles = createStyles((theme) => ({
    editorItemText: {
        flex: 1,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        cursor: 'grab',
        borderRadius: theme.radius.md,
        padding: '4px 8px',
        [`&.is-dragging`]: {
            cursor: 'grabbing',
        },
    },
}));

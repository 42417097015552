import { QueryResult, postAssessmentsSearchAll, postResourcesQuery } from '@apis/Resources';
import { AssessmentDefinition, AssessmentResult, BaseResource } from '@apis/Resources/model';
import styled from '@emotion/styled';
import { Box, Button, CloseButton, Divider, Group, Space, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { LineChart, LineChartSettings } from '@root/Components/Charts/LineChart';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { FillerSwitch } from '@root/Design/Filler';
import { CustomColors } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { queryBuilder } from '@root/Services/QueryExpr';
import { useEffect, useMemo, useState } from 'react';
import { AssessmentAction } from './Components/AssessmentActions';
import { getAssessmentColor } from './Design';

interface AssessmentDetailsProps {
    toggleSidePanel: (showSidePanel: boolean) => void;
    assessmentResult: AssessmentResult;
    assessmentDef: AssessmentDefinition;
}

const getComparisonValues = (assessmentDef: AssessmentDefinition, assessmentResult: AssessmentResult) => {
    const amountLabel = assessmentDef.PresentationOptions?.Comparison?.AmountLabel ?? 'Spend';
    const inverseAmountLabel = amountLabel === 'Risk' ? 'Risk' : 'Savings';
    const countLabel = assessmentDef.PresentationOptions?.Comparison?.CountLabel ?? 'Opportunities';
    const currentLabel = assessmentDef.PresentationOptions?.Comparison?.CurrentLabel ?? 'Current';
    const targetLabel = assessmentDef.PresentationOptions?.Comparison?.TargetLabel ?? 'Target';
    const labels = { amountLabel, inverseAmountLabel, countLabel, currentLabel, targetLabel };

    const fullAmt = assessmentResult.AssessmentScore?.TotalSpend ?? 0;
    const fullCount = assessmentResult.AssessmentScore?.TotalCount ?? 0;
    const opportunities = assessmentResult.AssessmentScore?.AssessmentOpportunityCount ?? 0;
    const amount = assessmentResult.AssessmentScore?.AssessmentSavings ?? 0;

    const calcType = assessmentDef.PresentationOptions?.Comparison?.Calculation ?? 'TargetTotalCostToZero';
    const values =
        calcType === 'TargetTotalCostMinusSavings'
            ? {
                  currentAmt: fullAmt,
                  currentCt: fullCount - opportunities,
                  idealAmt: fullAmt - amount,
                  idealCt: fullCount,
              }
            : calcType === 'TargetTotalCostToZero'
            ? {
                  currentAmt: amount,
                  currentCt: fullCount - opportunities,
                  idealAmt: 0,
                  idealCt: fullCount,
              }
            : {
                  currentAmt: fullAmt,
                  currentCt: fullCount,
                  idealAmt: fullAmt - amount,
                  idealCt: fullCount - opportunities,
              };

    return { ...labels, ...values, amount, opportunities, fullAmt, fullCount };
};

export const AssessmentDetails = (props: AssessmentDetailsProps) => {
    const fmtSvc = useDi(FormatService);
    const theme = useMantineTheme();
    const {
        amountLabel,
        inverseAmountLabel,
        countLabel,
        currentLabel,
        targetLabel,
        currentAmt,
        currentCt,
        idealAmt,
        idealCt,
        amount,
        opportunities,
    } = useMemo(() => getComparisonValues(props.assessmentDef, props.assessmentResult), [props.assessmentDef, props.assessmentResult]);

    return (
        <Stack sx={{ height: '100%' }} spacing={0}>
            <Box>
                <Group position="apart">
                    <Box px="lg" py="md">
                        <Title order={3}>Assessment Detail</Title>
                    </Box>
                    <CloseButton
                        variant="transparent"
                        size="xl"
                        onClick={() => {
                            props.toggleSidePanel(false);
                        }}
                    ></CloseButton>
                </Group>
                <Divider />
            </Box>
            <Box sx={{ flex: 1, height: '100%', overflow: 'auto' }} px="md">
                <Space h="md" />
                <Group position="center" spacing={4}>
                    <Row1Box>
                        <Text>Service</Text>
                        <Space h="md" />
                        <Text style={{ textAlign: 'center' }}>
                            {props.assessmentResult.Platform === 'Aws' ? (
                                <img src="/assets/Amazon_Web_Services_Logo.svg" style={{ height: 40 }} />
                            ) : (
                                <img src="/assets/azure_logo.svg" style={{ width: 46 }} />
                            )}
                        </Text>
                    </Row1Box>
                    <Row1Box>
                        <Text>Assessment</Text>
                        <Space h="md" />
                        <Row1BigText>{props.assessmentResult.AssessmentCode}</Row1BigText>
                    </Row1Box>
                    <Row1Box>
                        <Text>Type</Text>
                        <Space h="md" />
                        <Row1BigText>{props.assessmentResult.AssessmentType}</Row1BigText>
                    </Row1Box>
                </Group>
                <Space h="md" />
                <Divider />
                <Space h="md" />
                <Group position="center">
                    <Row2Box1>
                        <Text>Potential {inverseAmountLabel}</Text>
                        <Row2BigText>{fmtSvc.formatMoneyNoDecimals(amount)}</Row2BigText>
                    </Row2Box1>
                    <Row2Box2>
                        <Text>Potential Opportunities</Text>
                        <Row2BigText>{fmtSvc.formatInt(opportunities)}</Row2BigText>
                    </Row2Box2>
                </Group>
                <Space h="md" />
                <Divider />
                <Space h="md" />

                <Group spacing={0} position="center">
                    <TableRowHeader></TableRowHeader>
                    <TableRowHeader>Score</TableRowHeader>
                    <TableRowHeader>{amountLabel}</TableRowHeader>
                    <TableRowHeader>{countLabel}</TableRowHeader>
                </Group>
                <Group spacing={0} position="center" sx={{ '>div': { background: theme.colors.gray[2] } }}>
                    <TableRow style={{ marginRight: '5px', borderTopLeftRadius: '5px' }}>{currentLabel}</TableRow>
                    <TableRow>
                        <ScoreCell
                            backgroundColor={
                                getAssessmentColor(props.assessmentResult.AssessmentScore?.AssessmentScore ?? 0) as unknown as CustomColors
                            }
                        >
                            {fmtSvc.formatInt(Math.round((props.assessmentResult.AssessmentScore?.AssessmentScore ?? 0) * 100))}
                        </ScoreCell>
                    </TableRow>
                    <TableRow>{fmtSvc.formatMoneyNoDecimals(currentAmt)}</TableRow>
                    <TableRow style={{ borderTopRightRadius: '5px' }}>{fmtSvc.formatInt(currentCt)}</TableRow>
                </Group>
                <Space h={4} />
                <Group spacing={0} position="center" sx={{ '>div': { background: theme.colors.warning[2] } }}>
                    <TableRow style={{ marginRight: '5px', borderBottomLeftRadius: '5px' }}>{targetLabel}</TableRow>
                    <TableRow>
                        <ScoreCell backgroundColor={getAssessmentColor(1) as unknown as CustomColors}>100</ScoreCell>
                    </TableRow>
                    <TableRow>{fmtSvc.formatMoneyNoDecimals(idealAmt)}</TableRow>
                    <TableRow style={{ borderBottomRightRadius: '5px' }}>{fmtSvc.formatInt(idealCt)}</TableRow>
                </Group>
                <Space h={50} />
                <Box sx={{ flexGrow: 1, height: 300 }}>
                    <OptimizationScoreHistory {...props} />
                </Box>
                <Space h="md" />
            </Box>
            <Box>
                <Divider />
                <Group p="md" position="right">
                    <Button variant="outline" onClick={() => props.toggleSidePanel(false)}>
                        Close
                    </Button>
                    <AssessmentAction definition={props.assessmentDef} assessment={props.assessmentResult} />
                </Group>
            </Box>
        </Stack>
    );
};

function OptimizationScoreHistory({ assessmentResult: { AssessmentDefId: defId, Platform: platform } }: AssessmentDetailsProps) {
    const fmtSvc = useDi(FormatService);
    const [loading, setLoading] = useState(true);

    var chartProps = useMemo(
        () => ({
            groups: ['Date'],
            values: ['Total'],
            settings: {
                format: 'int',
                margin: { top: 20, right: 20, bottom: 60, left: 40 },
                yMax: 100,
                noWrapper: true,
            } as LineChartSettings,
        }),
        []
    );

    const company = useCompany();
    const [data, setData] = useState<Record<string, number | string>[]>([]);
    useEffect(() => {
        setLoading(true);
        (async () => {
            const query = queryBuilder<AssessmentResult>()
                .where((b) => b.and(b.model.CompanyId!.eq(company!.Id!), b.model.AssessmentDefId!.eq(defId), b.model.Platform!.eq(platform!)))
                .build();

            const results = (await postAssessmentsSearchAll(query)) as QueryResult<AssessmentResult>;
            const items = results?.Results ?? [];

            const data = items
                .filter((o) => o.AssessmentDate)
                .sort((a, b) => a.AssessmentDate!.localeCompare(b.AssessmentDate!))
                .map((o) => ({
                    Ts: o.AssessmentResultId!,
                    Date: fmtSvc.toJsonShortDate(new Date(o.AssessmentDate!)),
                    Total: Math.round((o.AssessmentScore?.AssessmentScore ?? 0) * 100),
                }));
            setData(data);
        })().finally(() => setLoading(false));
    }, [company, defId, platform]);

    return (
        <FillerSwitch loading={loading} noData={!data?.length}>
            {() => (
                <>
                    <Stack sx={{ height: '100%' }} spacing={4}>
                        <Text size="lg">Score History</Text>
                        <Box sx={{ flex: '1 1 100%', overflow: 'hidden' }}>
                            <LineChart data={data} {...chartProps} />
                        </Box>
                    </Stack>
                </>
            )}
        </FillerSwitch>
    );
}

export const TableRowHeader = styled.div`
    width: 123px;
    text-align: center;
    padding-bottom: 0.25rem;
`;

export const TableRow = styled.div`
    width: 123px;
    text-align: right;
    padding: 15px 1.25rem;
    display: flex;
    line-height: 30px;
    align-items: center;
    justify-content: flex-end;
`;

export const ScoreCell = styled.div<{ backgroundColor: string }>`
    text-align: center;
    padding: 5px;
    color: #fff;
    border-radius: 10px;
    height: 30px;
    margin: 0px auto;
    width: 60px;
    font-size: 20px;
    line-height: 20px;
    position: relative;
    background-color: ${(p) => p.backgroundColor};
`;

export const Row1Box = styled.div`
    background-color: ${(p) => p.theme.colors?.gray?.[2] as CustomColors};
    &:first-child {
        border-top-left-radius: ${(p) => p.theme.radius.md}px;
        border-bottom-left-radius: ${(p) => p.theme.radius.md}px;
    }
    &:last-child {
        border-top-right-radius: ${(p) => p.theme.radius.md}px;
        border-bottom-right-radius: ${(p) => p.theme.radius.md}px;
    }
    flex: 1;
    width: 146px;
    padding: 10px 15px;
    min-height: 125px;
`;
export const Row1BigText = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
`;

export const Row2Box1 = styled.div`
    background-color: ${(p) => p.theme.colors?.primary?.[1] as CustomColors};
    border-radius: 5px;
    margin: 5px;
    width: 240px;
    padding: 15px;
`;

export const Row2Box2 = styled.div`
    background-color: ${(p) => p.theme.colors?.gray?.[2] as CustomColors};
    border-radius: 5px;
    margin: 5px;
    width: 224px;
    padding: 15px;
`;
export const Row2BigText = styled.div`
    color: ${(p) => p.theme.colors?.primary?.[6] as CustomColors};
    font-size: 28px;
    font-weight: bold;
    text-align: center;
`;

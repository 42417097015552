import { getValueFormatter, GetColor } from '@nivo/core';
import { ResponsiveSankey } from '@nivo/sankey';
import { useCallback } from 'react';
import { chartColors, ChartMargin } from './Common';

export function SankeyChart(props: SankeyChartProps) {
    const formatter = getValueFormatter(
        props.settings?.format === 'money'
            ? '>-$,.2f'
            : props.settings?.format === 'money-whole'
            ? '>-$,.0f'
            : props.settings?.format === 'percent'
            ? '>-.0%'
            : '>-,'
    );

    const colorFn = useCallback(
        (item: { id: string; index: number }) =>
            (typeof props.settings?.colors === 'function' && props.settings.colors(item)) || chartColors[item.index % chartColors.length],
        [props.settings?.colors]
    );
    const colors = !props.settings?.colors ? chartColors : typeof props.settings?.colors === 'function' ? colorFn : props.settings.colors;
    return (
        <ResponsiveSankey
            valueFormat={formatter}
            margin={{ top: 30, left: 30, bottom: 30, right: 30, ...props.settings?.margin }}
            data={props.data}
            colors={colors}
            linkBlendMode="normal"
            labelOrientation="vertical"
            labelPosition="outside"
        />
    );
}
export interface SankeyChartSettings {
    margin?: ChartMargin;
    format?: 'money' | 'money-whole' | 'percent';
    colors?: string[] | ((item: { index: number; id: string }) => string);
}
export interface SankeyChartProps {
    settings?: SankeyChartSettings;
    data: { nodes: { id: string }[]; links: { source: string; target: string; value: number }[] };
}

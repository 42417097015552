import styled from '@emotion/styled';
import { Box, Button, Card, Grid, Group, LoadingOverlay, Text, Title } from '@mantine/core';
import { WizardForm, WizardCard, BadgeHolder, CardBadge, WizardFormTitle } from '@root/Components/Wizard/WizardForm';
import { PageContent } from '@root/Design/Layout';
import { colorPalette, CustomColors, theme } from '@root/Design/Themes';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { Link } from '@root/Services/Router/Links';
import { ConnectionWizardStepper, HelpLink, WizardHeader } from './Common';

const ChoiceIcon = styled.img`
    height: 32px;
`;

export function ConnectTwo() {
    const { getMoveUrl, getData } = useNav();
    const { platform } = getData('platform');
    const isAzure = platform?.toLowerCase() === 'azure';
    return (
        <PageContent>
            <WizardHeader platform={platform!} />
            <WizardForm>
                <ConnectionWizardStepper step={2} platform={platform!} />
                <Box>
                    <WizardFormTitle title={'Choose Your ' + platform + ' Connection Method'}></WizardFormTitle>
                    {platform == 'AWS' ? (
                        <Text mb="xl" mt={-16}>
                            Not sure which to use? See our{' '}
                            <HelpLink text="onboarding guide" color={colorPalette.linkColor} fontWeight={'normal'}></HelpLink>.
                        </Text>
                    ) : (
                        <></>
                    )}
                </Box>
                <Grid>
                    {isAzure ? (
                        <>
                            <Grid.Col span={6}>
                                <WizardCard
                                    button={
                                        <Button data-atid="ResourceManagerButton" component={Link} href={'connect-rm@platform:' + platform}>
                                            Select Resource Manager
                                        </Button>
                                    }
                                >
                                    <LoadingOverlay
                                        visible
                                        overlayColor="#f4f4f4"
                                        loader={
                                            <Card withBorder shadow="lg" p="xl" radius="lg">
                                                Coming soon
                                            </Card>
                                        }
                                    />
                                    <ChoiceIcon src="/assets/azure_arm.png" alt="Resource Manager" />
                                    <Title my="xs" order={2}>
                                        Resource Manager
                                    </Title>
                                    <BadgeHolder>
                                        <CardBadge>
                                            <Text color={theme.colors?.primary?.[8] as CustomColors}>Estimated Setup Time: 10 min</Text>
                                        </CardBadge>
                                    </BadgeHolder>
                                    <Text>Template to deploy via Resource Manager</Text>
                                </WizardCard>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <WizardCard
                                    button={
                                        <Button data-atid="CliButton" component={Link} href={getMoveUrl('connect-cli', { platform: platform! })}>
                                            Select CLI
                                        </Button>
                                    }
                                >
                                    <ChoiceIcon style={{ opacity: 0.5 }} src="/assets/cli.svg" alt="CLI" />
                                    <Group align="center">
                                        <Title my="xs" order={2}>
                                            CLI
                                        </Title>
                                    </Group>
                                    <BadgeHolder>
                                        <CardBadge>
                                            <Text color={theme.colors?.primary?.[8] as CustomColors}>Estimated Setup Time: 5 min</Text>
                                        </CardBadge>
                                    </BadgeHolder>
                                    <Text>One-line command for a simple scripted connection through Azure CLI</Text>
                                </WizardCard>
                            </Grid.Col>
                        </>
                    ) : (
                        <></>
                    )}
                    {!isAzure ? (
                        <AwsConnectionOptions
                            onCloudFormationClick={{ href: getMoveUrl('connect-cf', { platform: 'aws' }) }}
                            onTerraformClick={{ href: getMoveUrl('connect-tf', { platform: platform! }) }}
                            onCliClick={{ href: getMoveUrl('connect-cli', { platform: platform! }) }}
                        />
                    ) : null}
                </Grid>
            </WizardForm>
        </PageContent>
    );
}
endpoint('connect-two', ConnectTwo, 'Connection Wizard');

export function AwsConnectionOptions({
    onCloudFormationClick,
    onTerraformClick,
    onCliClick,
}: {
    onCloudFormationClick: { href?: string; onClick?: () => void };
    onTerraformClick: { href?: string; onClick?: () => void };
    onCliClick: { href?: string; onClick?: () => void };
}) {
    return (
        <>
            <Grid.Col span={4}>
                <WizardCard
                    button={
                        <Button data-atid="CloudFormationButton" component={Link} {...onCloudFormationClick}>
                            Select CloudFormation
                        </Button>
                    }
                >
                    <ChoiceIcon src="/assets/aws-cloudformation.svg" alt="CloudFormation" />
                    <Title my="xs" order={2}>
                        CloudFormation
                    </Title>
                    <BadgeHolder>
                        <CardBadge>
                            <Text color={theme.colors?.primary?.[8] as CustomColors}>Estimated Setup Time: 10 min</Text>
                        </CardBadge>
                    </BadgeHolder>
                    <Text>Infrastructure as Code template to deploy via CloudFormation</Text>
                </WizardCard>
            </Grid.Col>

            <Grid.Col span={4}>
                <WizardCard
                    button={
                        <Button data-atid="TerraformButton" component={Link} {...onTerraformClick}>
                            Select Terraform
                        </Button>
                    }
                >
                    <ChoiceIcon src="/assets/terraformio-icon.svg" alt="Terraform" />
                    <Title my="xs" order={2}>
                        Terraform
                    </Title>
                    <BadgeHolder>
                        <CardBadge>
                            <Text color={theme.colors?.primary?.[8] as CustomColors}>Estimated Setup Time: 10 min</Text>
                        </CardBadge>
                    </BadgeHolder>
                    <Text>Infrastructure as Code template to deploy via Terraform</Text>
                </WizardCard>
            </Grid.Col>
            <Grid.Col span={4}>
                <WizardCard
                    button={
                        <Button data-atid="CliButton" component={Link} {...onCliClick}>
                            Select CLI
                        </Button>
                    }
                >
                    <ChoiceIcon style={{ opacity: 0.5 }} src="/assets/cli.svg" alt="CLI" />
                    <Group align="center">
                        <Title my="xs" order={2}>
                            CLI
                        </Title>
                    </Group>
                    <BadgeHolder>
                        <CardBadge>
                            <Text color={theme.colors?.primary?.[8] as CustomColors}>Estimated Setup Time: 5 min</Text>
                        </CardBadge>
                    </BadgeHolder>
                    <Text>One-line command for a simple scripted connection through CloudShell or your AWS CLI</Text>
                </WizardCard>
            </Grid.Col>
        </>
    );
}

import styled from '@emotion/styled';
import { Card } from '@mantine/core';
import { ForwardedRef, forwardRef, ReactNode } from 'react';

export const CustomizableDashboardContainer = styled.div<{ height?: string; padding?: number }>`
    height: ${(p) => p.height ?? '100%'};
    position: relative;
    padding: ${(p) => p.padding ?? 32}px;
`;

export const DashboardTitle = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`;

export const DashboardItemHeaderText = styled.h3`
    margin: 0;
    padding-top: ${(p) => p.theme.spacing.xs}px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    font-weight: 100;
    text-overflow: ellipsis;
`;
export const DashboardItemHeader = styled.div`
    margin: var(--header-padding) 0 0 0;
    display: flex;
    align-items: center;
`;
export const DashboardItemMenu = styled.div`
    flex: 0;
    min-width: ${(p) => p.theme.spacing.xl}px;
`;
export const DashboardContainer = styled.div`
    .react-grid-placeholder {
        background: ${(p) => p.theme.fn.rgba(p.theme.colors[p.theme.primaryColor][9], 0.75)};
        border-radius: ${(p) => p.theme.radius.lg}px;
    }
`;

export function DashboardCard({ children, p }: { children: ReactNode; p?: string | number }) {
    return (
        <Card
            component={DashboardCardContainer}
            withBorder
            p={p}
            radius="lg"
            sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'auto' }}
        >
            {children}
        </Card>
    );
}

export const DashboardCardContainer = styled.div`
    > div {
        min-height: 0;
    }
`;

interface DashboardItemContainerProps {
    drag: 'move' | 'nomove';
    mode: 'standard' | 'freeform';
    children: ReactNode;
}
export const DashboardItem = styled.div<DashboardItemContainerProps>`
    ${(p) =>
        p.mode === 'standard'
            ? `
    border: solid 1px ${p.theme.colors.gray[1]};
    background: ${p.theme.colors.gray[0]};
    padding: 1rem;
    --header-padding: -1rem;
    display: flex;
    flex-direction: column;   
    > div {
        min-height: 0;
    }
    `
            : null}
    .dashboard-header-name {
        flex: 1;
        overflow: hidden;
        cursor: ${(p) => (p.drag === 'move' ? 'grab' : 'default')};
    }
    &.react-draggable-dragging {
        .dashboard-header-name {
            cursor: grabbing;
        }
    }
    .react-resizable-handle {
        bottom: 0.5rem !important;
        right: 0.5rem !important;
    }
`;

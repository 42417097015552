/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Customers.Host.Api
 * OpenAPI spec version: 1.0
 */

export type CompanyType = typeof CompanyType[keyof typeof CompanyType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyType = {
  Customer: 'Customer',
  StrategicPartner: 'StrategicPartner',
  Msp: 'Msp',
  Support: 'Support',
  PlatformSupport: 'PlatformSupport',
} as const;

import { useDi, useDiMemo } from '@root/Services/DI';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useCallback, useEffect, useState } from 'react';
import { FillerSwitch } from '@root/Design/Filler';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';
import { useNav } from '@root/Services/NavigationService';
import { useEventValue } from '@root/Services/EventEmitter';
import { SpendForecastModel, TenantForecastStatus } from './Forecasting/Models';
import { LoadedCostForecast } from './Forecasting/LoadedCostForecast';
import {
    ForecastFailed,
    ForecastingUnavailable,
    ForecastInProgress,
    ForecastPrompt,
    TenantForecastInProgress,
    TenantForecastNotConfigured,
} from './Forecasting/PageStates';
import { ForecastLoader } from './Forecasting/ForecastLoader';
import { JobOfCostForecast } from '@apis/Resources';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { TenantForecastConfigDrawer } from './Forecasting/TenantForecastConfig';
import { withSchemaPreloader } from '@root/Components/Invoices/SchemaPreloader';

export function CostForecastPage() {
    const model = useDiMemo(SpendForecastModel);
    const initialized = useEventValue(model.initialized);
    const nav = useNav();
    const { forecastId } = nav.getData('forecastId');
    const [mode, setMode] = useState<'loading' | 'unavailable' | 'prompt' | 'loaded' | 'in-progress' | 'failed'>('loading');
    const [selectedJob, setJob] = useState<JobOfCostForecast>();
    const notificationSvc = useDi(NotificationService);

    const onLoad = useCallback(
        (newForecastId: string) => {
            if (newForecastId == forecastId) {
                notificationSvc.notify('Forecast already loaded', '', 'success', null);
            } else {
                nav.replaceParams({ forecastId: newForecastId });
            }
        },
        [model, forecastId]
    );

    const resolveState = useCallback(async () => {
        if (initialized) {
            if (model.historicalDaysAvailable < model.historicalDaysNeeded) {
                setMode('unavailable');
            } else if (!forecastId) {
                const defaultForecast = model.getDefaultForecast();
                if (defaultForecast) {
                    nav.replaceParams({ forecastId: defaultForecast });
                } else {
                    setMode('prompt');
                }
            } else {
                const { job, savedForecast } = await model.getForecastInfoByJobId(forecastId);
                if (job && savedForecast) {
                    setJob(job);
                    setMode('loaded');
                } else if (job && !savedForecast) {
                    const status = await model.getJobStatus(job.HierarchyId ?? '');
                    if (!status) {
                        nav.replaceParams({ forecastId: '' });
                    } else if (status.inProgress) {
                        setJob(job);
                        setMode('in-progress');
                    } else if (status.hasErrors) {
                        setJob(job);
                        setMode('failed');
                    } else {
                        const savedForecast = await model.createSavedForecast(job);
                        if (savedForecast) {
                            setJob(job);
                            setMode('loaded');
                        } else {
                            setMode('prompt');
                        }
                    }
                } else {
                    setMode('prompt');
                }
            }
        } else {
            setMode('loading');
        }
    }, [initialized, forecastId]);

    useEffect(() => {
        resolveState();
    }, [initialized, forecastId, resolveState]);

    return (
        <ConnectionCheck>
            {() => (
                <FillerSwitch loading={mode === 'loading'}>
                    {() => (
                        <>
                            {mode === 'unavailable' ? (
                                <ForecastingUnavailable model={model} />
                            ) : mode === 'prompt' ? (
                                <ForecastPrompt model={model} />
                            ) : mode === 'in-progress' ? (
                                <ForecastInProgress model={model} job={selectedJob!} onFinished={resolveState} />
                            ) : mode === 'loaded' ? (
                                <LoadedCostForecast key={selectedJob?.Id} model={model} job={selectedJob!} tenantForecast={false} />
                            ) : mode === 'failed' ? (
                                <ForecastFailed model={model} job={selectedJob!} />
                            ) : null}
                            <ForecastLoader model={model} onLoad={onLoad} />
                        </>
                    )}
                </FillerSwitch>
            )}
        </ConnectionCheck>
    );
}

export function TenantForecastPage() {
    const model = useDiMemo(SpendForecastModel);
    const initialized = useEventValue(model.initialized);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState<TenantForecastStatus | 'Unavailable'>();

    const resolveState = useCallback(async () => {
        if (initialized) {
            setLoading(false);
            const { status } = await model.getTenantForecastStatus();
            setStatus(status);
            if (model.historicalDaysAvailable < model.historicalDaysNeeded && status !== 'Ready') {
                setStatus('Unavailable');
            }
        } else {
            setLoading(true);
        }
    }, [initialized]);

    useEffect(() => {
        resolveState();
    }, [initialized, resolveState]);

    return (
        <ConnectionCheck>
            {() => (
                <FillerSwitch loading={loading}>
                    {() => (
                        <>
                            {status === 'Unavailable' ? (
                                <ForecastingUnavailable model={model} />
                            ) : status === 'NotConfigured' ? (
                                <TenantForecastNotConfigured model={model} />
                            ) : status === 'NotReady' ? (
                                <TenantForecastInProgress model={model} onFinished={resolveState} />
                            ) : status === 'Ready' ? (
                                <LoadedCostForecast job={undefined} model={model} tenantForecast />
                            ) : null}
                            <TenantForecastConfigDrawer model={model} onFinished={resolveState} />
                        </>
                    )}
                </FillerSwitch>
            )}
        </ConnectionCheck>
    );
}

endpoint('cost-forecasting', withSchemaPreloader(TenantForecastPage), 'Cost Forecasting');

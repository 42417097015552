import { useDisclosure } from '@mantine/hooks';
import { ActionIcon, createStyles, Space, Popover, TextInput, Text, Group, Stack, Box, Badge } from '@mantine/core';
import { colorPalette } from '@root/Design/Themes';
import { Picker } from '@root/Components/Picker/Picker';
import { ChevronDown } from 'tabler-icons-react';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { VirtualTreeModel } from '@root/Components/VirtualTree/VirtualTreeModel';
import { VirtualTree } from '@root/Components/VirtualTree';
import { TagValidation } from '@root/Components/Resources/Tags/TagValidation';
import { useDiContainer } from '@root/Services/DI';
import { TagModel } from '@root/Components/Resources/Tags/TagModel';
import { useEvent } from '@root/Services/EventEmitter';

interface TagFieldProps {
    children?: React.ReactNode;
    index?: number;
    count?: number;
    valuePickList: string[];
    setSelectedValue: (index: number, value: string) => void;
    fieldValue: string;
    label: string;
    handleAddRow?: () => void;
    handleRemoveRow?: (index: number) => void;
    canAdd: boolean;
    canRemove: boolean;
    disabled?: boolean;
    onFocus?: (index: number) => void;
    onBlur?: (index: number) => void;
    receiveInitalFocus?: boolean;
    atid?: string;
    placeholder?: string;
    icon?: ReactNode;
    error?: boolean;
    iconWidth?: number;
    dropdownTopSection?: ReactNode;
}

export const TagField = (props: TagFieldProps) => {
    const { classes, theme } = useStyles();
    const [popupOpened, popupHandlers] = useDisclosure(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const arrowRef = useRef<HTMLButtonElement>(null);
    const [model, setModel] = useState<VirtualTreeModel<string>>();
    const [treeRef, setTreeRef] = useState<VirtualTree>();
    const [firstFocus, setFirstFocus] = useState(false);
    const [inputBlur, setInputBlur] = useState(false);
    const [value, setValue] = useState('');
    const [tagValid, setTagValid] = useState<boolean>(true);
    const container = useDiContainer();
    const tagModel = container.resolve(TagModel);
    useEvent(tagModel.TagValid, (value) => {
        setTagValid(value);
    });

    useEffect(() => {
        //Set focus to the input control upon render. Timer gives time to finish cycling through controls.
        if (props.receiveInitalFocus) {
            const timer = setTimeout(async () => {
                inputRef.current?.focus();
            }, 300);

            return () => clearTimeout(timer);
        }
    }, []);

    useEffect(() => {
        // Since the action icon (down arrow) isn't a part of the text input, onfocus and onblur were not
        // behaving correctly. The desired behavior is that when the down arrow is clicked, the dropdown is toggled
        // and also the input box gains focus. Once the input box loses focus, the dropdown should close. Since the
        // icon is not part of the input box, when clicked, the input would lose focus and close the dropdown. This
        // time provides the necessary delay and treats the icon focus as part of input focus.
        const timer = setTimeout(async () => {
            if (inputBlur && document.activeElement !== arrowRef.current && document.activeElement !== treeRef?.getContainer().current) {
                popupHandlers.close();
            }
        }, 100);

        return () => clearTimeout(timer);
    }, [inputBlur]);

    const handlePicklistSelect = (e: string[]) => {
        props.setSelectedValue(props?.index ?? 0, e[0]);
        setValue(e[0]);
        popupHandlers.close();
    };

    const handleInputChange = (e: any) => {
        props.setSelectedValue(props?.index ?? 0, e.target.value);
        setValue(e.target.value);
    };

    // we only open the dropdown the first time the input is focused, so that if the user wants to type in a value
    // that isn't in the dropdown, they can type with it closed, if they choose.
    const handleFocus = () => {
        if (props.onFocus) {
            props.onFocus(props?.index ?? 0);
        }
        setInputBlur(false);
        if (firstFocus) {
            popupHandlers.open();
            setFirstFocus(false);
        }
    };

    const handleToggle = () => {
        popupHandlers.toggle();
        inputRef.current?.focus();
    };

    const handleOnBlur = () => {
        if (props.onBlur) {
            props.onBlur(props?.index ?? 0);
        }
        setInputBlur(true);
    };

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        let selections: string[] = [];

        switch (e.key) {
            case ' ':
                if (e.ctrlKey) {
                    popupHandlers.open();
                }
                break;
            case 'Escape':
                popupHandlers.close();
                break;
            case 'ArrowDown':
                model?.navigate('Down');
                treeRef?.scrollToItem(model?.getHighlightedItem());
                break;
            case 'ArrowUp':
                model?.navigate('Up');
                treeRef?.scrollToItem(model?.getHighlightedItem());
                break;
            case 'Enter':
                const highlightedItem = model?.getHighlightedItem();
                if (highlightedItem) {
                    model?.select(highlightedItem);
                    selections.push(highlightedItem);
                    handlePicklistSelect(selections);
                }
                popupHandlers.close();
                break;
            default:
                if (model?.items) {
                    model?.highlightByIndex(0);
                }
                break;
        }
    };

    const handleModelLoaded = (model?: VirtualTreeModel<string>, tree?: VirtualTree) => {
        setTreeRef(tree);
        setModel(model);
    };

    const field = (
        <Box sx={{ minWidth: 160, flex: '1' }}>
            <Popover width="target" opened={popupOpened} position="bottom" withArrow withinPortal offset={0} shadow="md">
                <Popover.Dropdown sx={{ width: 250, minWidth: 350 }} p={0}>
                    {props.dropdownTopSection}
                    <Picker
                        onModelLoaded={handleModelLoaded}
                        mode="single"
                        width="100%"
                        height={300}
                        minimizeHeight
                        // default type is an object with children. This is just a noop override
                        childAccessor={() => undefined}
                        items={props.valuePickList.filter((v) => v?.toLowerCase().includes(props.fieldValue?.toLowerCase()))}
                        onChange={handlePicklistSelect}
                        selections={[]}
                        nameAccessor={(n) => n}
                        renderItem={(item) => <Text size="sm">{item}</Text>}
                        noFilter={true}
                    />
                </Popover.Dropdown>
                <Popover.Target>
                    <TextInput
                        placeholder={props.placeholder}
                        icon={props.icon}
                        iconWidth={props.iconWidth}
                        data-atid={props.atid}
                        onKeyUp={handleKeyUp}
                        ref={inputRef}
                        autoComplete="off"
                        disabled={props.disabled}
                        mx={5}
                        label={props.label}
                        error={props.error}
                        onChange={handleInputChange}
                        onFocus={handleFocus}
                        onBlur={handleOnBlur}
                        value={props.fieldValue}
                        rightSection={
                            <ActionIcon ref={arrowRef} tabIndex={-1} onClick={handleToggle}>
                                <ChevronDown />
                            </ActionIcon>
                        }
                    />
                </Popover.Target>
            </Popover>
            <Space h={5} />
            <TagValidation value={value} model={tagModel}></TagValidation>
        </Box>
    );

    const buttons = props.canRemove ? (
        <>
            <ActionIcon
                data-atid={'RemoveTagButton' + props?.index ?? 0}
                onClick={() => props.handleRemoveRow && props.handleRemoveRow(props?.index ?? 0)}
                color="error"
                sx={{ marginRight: 0, marginTop: '25px', fontSize: theme.fontSizes.xl }}
            >
                <i className="ti ti-circle-minus"></i>
            </ActionIcon>
        </>
    ) : null;

    return (
        <>
            {props.children ? (
                <>
                    <Group spacing={0}>
                        {props.children}
                        {field}
                    </Group>
                    {buttons}
                </>
            ) : (
                <>
                    {field}
                    {buttons}
                </>
            )}
        </>
    );
};

const useStyles = createStyles((theme) => ({
    textColor: {
        color: colorPalette.subHeaderTextColor,
        alignContent: 'center',
        i: {
            alignItems: 'center',
        },
    },
    footer: {
        position: 'absolute',
        bottom: 0,
    },

    keyValues: {
        marginTop: `${theme.spacing.xs}px`,
    },
}));

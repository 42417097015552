import { postRecommendationQuery } from '@apis/Recommendations';
import { Recommendation } from '@apis/Recommendations/model';
import { QueryResult } from '@apis/Resources';
import { Query } from '@apis/Resources/model';
import { injectable } from 'tsyringe';

@injectable()
export class RecommendationApiService {
    public constructor() {}
    public async query(query: Query) {
        const result = await postRecommendationQuery(query);
        return result;
    }

    public async queryByResourceType(resourceType: string, query: Query) {
        const resourceTypeExpr = { Operation: 'eq', Operands: [{ Field: 'ResourceIdentifier.ResourceType' }, { Value: resourceType }] };
        query.Where = !query.Where
            ? resourceTypeExpr
            : {
                  Operation: 'and',
                  Operands: [query.Where, resourceTypeExpr],
              };
        const result = (await postRecommendationQuery(query)) as QueryResult<Recommendation>;
        return result;
    }
}

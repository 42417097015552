import { BaseAwsResource, BaseResource, Ebs, Ec2, Tag } from '@apis/Resources/model';
import { queryBuilder } from '@root/Services/QueryExpr';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useEffect, useState } from 'react';
import { useCompany } from '../../Router/CompanyContent';
import { Table, Text } from '@mantine/core';
import { NoDataMessage, ResourceDetailSection } from '../Design';
import { useDi } from '@root/Services/DI';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { FillerSwitch } from '@root/Design/Filler';

interface AwsEbsVolume extends Ebs {
    VolumeType?: { Value: string };
    Size: string;
    Iops: string;
}
export function AwsEc2Storage({ resource }: { resource: BaseResource }) {
    const resourceSvc = useDi(ResourceService);
    const [volumes, setVolumes] = useState<AwsEbsVolume[]>();

    useEffect(() => {
        (async () => {
            try {
                const ec2 = resource as Ec2;
                var instanceId = ec2.InstanceId ?? '';
                const resp = await queryBuilder<AwsEbsVolume>()
                    .take(1000)
                    .where((r) => r.and(r.model.ResourceType!.eq('EBS'), r.model['Attachments.InstanceId']!.eq(instanceId)))
                    .execute(resourceSvc.query);

                setVolumes(resp.Results ?? []);
            } catch {
                setVolumes([]);
            }
        })();
    }, []);

    return (
        <FillerSwitch loading={volumes === undefined}>
            {() => (
                <>
                    {!volumes?.length ? (
                        <NoDataMessage>No Volumes Found</NoDataMessage>
                    ) : (
                        <ResourceDetailSection>
                            <Table verticalSpacing="sm" horizontalSpacing="lg">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Volume ID</th>
                                        <th>Type</th>
                                        <th>Size</th>
                                        <th className="left">IOPS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {volumes.map((v, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>
                                                    {v.Name ?? (
                                                        <Text color="dimmed" italic>
                                                            No Name
                                                        </Text>
                                                    )}
                                                </td>
                                                <td>{v.VolumeId}</td>
                                                <td>{v.VolumeType?.Value}</td>
                                                <td>{v.Size} GB</td>
                                                <td>{v.Iops}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </ResourceDetailSection>
                    )}
                </>
            )}
        </FillerSwitch>
    );
}

import { postResourcesQuery } from '@apis/Resources';
import { BaseAwsResource } from '@apis/Resources/model';
import { Box, Card, Center, Divider, Group, Loader, SegmentedControl, Text, useMantineTheme } from '@mantine/core';
import { MapChart } from '@root/Components/Charts/MapChart';
import { DashboardItemModel } from '@root/Components/DashboardLayout/Models';
import { CustomColors } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useNav } from '@root/Services/NavigationService';
import { IStringFluentOperators, queryBuilder } from '@root/Services/QueryExpr';
import { geoCircle } from 'd3-geo';
import { useEffect, useMemo, useState } from 'react';
import { MapContractHomeModel } from '../Models';
import { MapDashboardModel, useTileLoader } from './MapDashboard';

interface RegionCoverage {
    id: string;
    value: number;
    correct: number;
    incorrect: number;
    undecided: number;
    notCovered: number;
    overRide: number;
    outsideContractTerm: number;
    [key: string]: string | undefined | number;
}

function EligibleResourcesByRegionHeader({ title, onDisplayTypeChange }: { title: string; onDisplayTypeChange: (type: string) => void }) {
    const [displayType, setDisplayType] = useState('notTagged');
    useEffect(() => {
        onDisplayTypeChange(displayType);
    }, [displayType]);
    return (
        <Group position="apart">
            <Text>{title}</Text>
            <SegmentedControl
                size="sm"
                value={displayType}
                onChange={setDisplayType}
                data={[
                    { label: 'Tagged Correctly', value: 'taggedCorrectly' },
                    { label: 'Tagged Incorrectly', value: 'taggedIncorrectly' },
                    { label: 'Not Tagged', value: 'notTagged' },
                ]}
            />
        </Group>
    );
}

export function EligibleResourcesByRegion({
    model,
    dashboardItemModel,
}: {
    model: MapDashboardModel;
    dashboardItemModel: DashboardItemModel<{
        title: string;
    }>;
}) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const { mapResourceQuerySvc } = useDi(MapContractHomeModel);
    const [displayType, setDisplayType] = useState('notTagged');
    const [colors, setColors] = useState<string[]>(['#31C2FF', '#009FE1', '#0075C1', '#00609F', '#004F83', '#003A61']);
    const [domainUpperBounds, setDomainUpperBounds] = useState<number>(100);
    const [key, setKey] = useState<string>('undecided');
    const { descend } = useNav();
    const contractId = model.contract?.Id?.toString() ?? '';

    useEffect(() => {
        dashboardItemModel.getHeader = () => (
            <EligibleResourcesByRegionHeader onDisplayTypeChange={setDisplayType} title={dashboardItemModel.settings.title} />
        );
    }, []);

    const correctColors = ['#31C2FF', '#009FE1', '#0075C1', '#00609F', '#004F83', '#003A61'];
    const incorrectColors = ['#FF0000', '#E10000', '#C10000', '#9F0000', '#830000', '#610000'];
    const untaggedColors = ['#FF0000', '#E10000', '#C10000', '#9F0000', '#830000', '#610000'];

    /* Lat / long values are calculated by finding the city closest to the AZ from
    https://aws.amazon.com/about-aws/global-infrastructure/regions_az/?p=ngi&loc=2
    and then looking up the coordinates manually. */
    const awsRegions = [
        { name: 'af-south-1', lat: -33.9, long: 18.4 },
        { name: 'ap-east-1', lat: 22.3, long: 114.17 },
        { name: 'ap-northeast-1', lat: 35.65, long: 139.83 },
        { name: 'ap-northeast-2', lat: 37.56, long: 126.98 },
        { name: 'ap-northeast-3', lat: 34.69, long: 135.5 },
        { name: 'ap-south-1', lat: 19.07, long: 72.87 },
        { name: 'ap-south-2', lat: 17.385, long: 78.486 },
        { name: 'ap-southeast-1', lat: 1.35, long: 103.8 },
        { name: 'ap-southeast-2', lat: -33.86, long: 151.2 },
        { name: 'ap-southeast-3', lat: -6.2, long: 106.8 },
        { name: 'ca-central-1', lat: 45.5, long: -73.5 },
        { name: 'ca-west-1', lat: 51.049, long: -114.066 },
        { name: 'cn-north-1', lat: 39.904, long: 116.407 },
        { name: 'cn-northwest-1', lat: 38.486, long: 106.232 },
        { name: 'eu-central-1', lat: 50.11, long: 8.68 },
        { name: 'eu-central-2', lat: 47.377, long: 8.541 },
        { name: 'eu-north-1', lat: 59.3, long: 18.1 },
        { name: 'eu-south-1', lat: 45.46, long: 9.19 },
        { name: 'eu-south-2', lat: 42.136, long: -0.408 },
        { name: 'eu-west-1', lat: 53.4, long: -8.2 },
        { name: 'eu-west-2', lat: 51.5, long: -0.11 },
        { name: 'eu-west-3', lat: 48.85, long: 2.3 },
        { name: 'il-central-1', lat: 32.085, long: 34.782 },
        { name: 'me-central-1', lat: 25.204, long: 55.271 },
        { name: 'me-south-1', lat: 25.93, long: 50.63 },
        { name: 'sa-east-1', lat: -23.53, long: -46.62 },
        { name: 'us-gov-east-1', lat: 38.882, long: -77.091 },
        { name: 'us-gov-west-1', lat: 43.804, long: -120.554 },
        { name: 'us-east-1', lat: 39, long: -78 },
        { name: 'us-east-2', lat: 40, long: -83 },
        { name: 'us-west-1', lat: 40, long: -122 },
        { name: 'us-west-2', lat: 45, long: -123 },
    ];

    if (!mapResourceQuerySvc) return null;

    const { loading, data } = useTileLoader(model.dateRange, async (from, to) => {
        const results = await queryBuilder<Omit<BaseAwsResource, 'CreateDate'> & { CreateDate: Date }>()
            .where((b) =>
                b.and(
                    model.querySvc.getMgmtAccountQuery(),
                    b.model.CreateDate.onOrAfter(from ?? model.querySvc.contractFrom),
                    b.model.CreateDate.onOrBefore(to ?? new Date())
                )
            )
            .select((b) => ({
                id: b.model['Region'] as IStringFluentOperators,
                value: b.count(),
                correct: b.countIf(mapResourceQuerySvc.getCorrectlyTaggedQuery()),
                incorrect: b.countIf(mapResourceQuerySvc.getIncorrectlyTaggedQuery()),
                undecided: b.countIf(mapResourceQuerySvc.getUndecidedQuery()),
                notCovered: b.countIf(mapResourceQuerySvc.getNotCoveredQuery()),
                overRide: b.countIf(mapResourceQuerySvc.getOverrideQuery()),
                outsideContractTerm: b.countIf(mapResourceQuerySvc.getOutsidePeriodQuery()),
            }))
            .execute(postResourcesQuery);

        return results.Results as RegionCoverage[];
    });

    useEffect(() => {
        setKey(displayType === 'taggedCorrectly' ? 'correct' : displayType === 'taggedIncorrectly' ? 'incorrect' : 'undecided');
    }, [displayType]);

    const generatorCircles = useMemo(() => {
        if (!data?.length) return [];

        switch (key) {
            case 'correct':
                setColors(correctColors);
                break;
            case 'incorrect':
                setColors(incorrectColors);
                break;
            case 'undecided':
                setColors(untaggedColors);
                break;
            default:
                setColors(correctColors);
        }

        let maxValue = Math.max.apply(
            Math,
            data.map((o) => Number(o[key]))
        );
        setDomainUpperBounds(maxValue);
        return awsRegions.map((region) => {
            const stats = data.find((d) => d.id === region.name);
            if (!stats) return {};

            let radius = stats[key] === 0 ? 0 : (Number(stats[key]) / maxValue) * 8 >= 2 ? (Number(stats[key]) / maxValue) * 8 : 2;
            let circleGenerator = geoCircle().center([region.long, region.lat]).radius(radius);

            return {
                id: region.name,
                type: 'Feature',
                properties: {
                    name: region.name,
                },
                featureType: 'region',
                geometry: circleGenerator(),
            };
        });
    }, [data, key]);

    const primaryStat = (label: string, value: any) => {
        return (
            <Group position={'apart'}>
                <Text size="xs" weight={'bolder'}>
                    {label}
                </Text>
                <Text size="xs" weight={'bolder'}>
                    {fmtSvc.formatInt(value)} Resources
                </Text>
            </Group>
        );
    };

    const secondaryStat = (label: string, value: any) => {
        return (
            <Group position={'apart'}>
                <Text size="xs" color={theme.colors.gray[6] as CustomColors}>
                    {label}
                </Text>
                <Text size="xs" color={theme.colors.gray[6] as CustomColors}>
                    {fmtSvc.formatInt(value)} Resources
                </Text>
            </Group>
        );
    };

    return (
        <>
            <Divider color="gray.3" mt="sm" mx={-16} />
            {loading ? (
                <Group position="center">
                    <Loader />
                </Group>
            ) : data?.length ? (
                <Center sx={{ height: '400px' }}>
                    <Box sx={{ width: '700px', height: '100%' }}>
                        <MapChart
                            data={[]}
                            groups={[]}
                            values={[]}
                            settings={{
                                data: data,
                                features: generatorCircles,
                                projectionTranslation: [0.5, 0.5],
                                projectionRotation: [0, 0, 0],
                                fillColor: '#9dc5ca',
                                borderWidth: 0.5,
                                borderColor: '#9dc5ca',
                                enableGraticule: false,
                                colors: colors,
                                domain: [0, domainUpperBounds],
                                unknownColor: '#9dc5ca',
                                onClick: ({ featureType, id }: { featureType?: string; id: string }) => {
                                    if (featureType === 'region') {
                                        const regionCriteria = { Operation: 'eq', Operands: [{ Field: 'Region' }, { Value: id }] };
                                        const tagCriteria =
                                            key === 'correct'
                                                ? model.querySvc?.getCorrectlyTaggedQuery()
                                                : key === 'incorrect'
                                                ? model.querySvc?.getIncorrectlyTaggedQuery()
                                                : model.querySvc?.getUndecidedQuery();
                                        const description =
                                            key === 'correct' ? 'Tagged Correctly' : key === 'incorrect' ? 'Tagged Incorrectly' : 'Not Tagged';
                                        const tagCritWrapper = { Operation: 'and', Operands: [tagCriteria.resolve()], description };
                                        descend('map-resource-browser', {
                                            type: description,
                                            filter: JSON.stringify([tagCritWrapper, regionCriteria]),
                                            id: contractId,
                                        });
                                    }
                                },
                                value: (d) => {
                                    if (!d) return 0;
                                    return Number(d[key]);
                                },
                                tooltip: (e) => {
                                    if (!e.feature.data) return null;

                                    return (
                                        <Card p={'sm'} sx={{ backgroundColor: theme.colors.gray[0] as CustomColors }}>
                                            <Text align="center" size="sm" weight={500} color={theme.colors.primary[6] as CustomColors}>
                                                {e.feature.label}
                                            </Text>
                                            <Divider pb={'xs'} color={theme.colors.primary[6] as CustomColors} />
                                            {key === 'correct'
                                                ? primaryStat('Tagged Correctly', e.feature.data.correct)
                                                : secondaryStat('Tagged Correctly', e.feature.data.correct)}
                                            {key === 'incorrect'
                                                ? primaryStat('Tagged Incorrectly', e.feature.data.incorrect)
                                                : secondaryStat('Tagged Incorrectly', e.feature.data.incorrect)}
                                            {key === 'undecided'
                                                ? primaryStat('Not Tagged', e.feature.data.undecided)
                                                : secondaryStat('Not Tagged', e.feature.data.undecided)}
                                        </Card>
                                    );
                                },
                            }}
                        />
                    </Box>
                </Center>
            ) : (
                <Group>
                    <Text color="dimmed">No data</Text>
                </Group>
            )}
        </>
    );
}

import { QueryExpr } from '@apis/Resources';
import { Box, Sx } from '@mantine/core';
import { FilterToken } from '@root/Components/Filter/Design';
import { SchemaFieldNameProvider, QueryDescriptorService, ExprTypeProvider } from '@root/Components/Filter/Services';
import { PropertyGridConfig, PropertyGridOptionItem, usePropertyGridOptionTranformer } from '@root/Components/PropertyGrid/PropertyGrid';
import { PropertyGridItem } from '@root/Components/PropertyGrid/Models';
import { useEventValue } from '@root/Services/EventEmitter';
import { useCallback } from 'react';
import { ColumnInsertLeft, FilterOff, Filter } from 'tabler-icons-react';
import { ResourceDetailsOpener } from '../ResourceDetails';
import { ResourceGridModel, BaseResource } from '../ResourcesGrid';

export function ResourcesGridDetailDrawer({ model, gridTitle }: { model: ResourceGridModel; gridTitle?: string }) {
    const resource = useEventValue(model.onResourceDetailsSelected);
    const refreshDataForDetailsTab = () => {
        model.refreshDataOnly();
    };
    const DetailMenuTransformer = useDetailMenuTransformer(model, gridTitle ?? 'Resource Details');

    return (
        <DetailMenuTransformer>
            <ResourceDetailsOpener
                onClose={() => model.onResourceDetailsSelected.emit(undefined)}
                resourceId={resource?.Id ?? ''}
                platform={resource?.CloudPlatform ?? 'Aws'}
                resourceType={resource?.ResourceType ?? ''}
                resource={resource}
                refreshDataOnly={() => refreshDataForDetailsTab()}
            />
        </DetailMenuTransformer>
    );
}

function useDetailMenuTransformer(model: ResourceGridModel, gridTitle: string) {
    const menuTransformer = useCallback(
        (item: PropertyGridItem, options: PropertyGridOptionItem[], config: PropertyGridConfig) => {
            const resource = item.root as BaseResource;
            const resourceType = (resource?.ResourceType ?? '').replace(/ /g, '_').toLowerCase();
            const platform = (resource?.CloudPlatform ?? '').toLowerCase();
            const { columnConfig, columnModel } = model.getColumnById(platform, resourceType, item.objectPath) ?? {};
            if (columnConfig && columnModel && item.isPrimitive) {
                options.push({ type: 'header', label: gridTitle });

                const hasColumn = model.isColumnIncluded(columnConfig);
                options.push({
                    label: (
                        <>
                            {hasColumn ? 'Remove' : 'Add'} {columnModel.header} Column
                        </>
                    ),
                    icon: <ColumnInsertLeft size={18} />,
                    onClick: () => {
                        model.toggleColumn(columnConfig, !hasColumn);
                        return { confirmation: `Column ${hasColumn ? 'Removed' : 'Added'}` };
                    },
                    disabled: !columnModel.canRemove(),
                });

                const fieldInfoProvider = new SchemaFieldNameProvider(model.getSchemaSvc());
                const queryDescriptor = new QueryDescriptorService(fieldInfoProvider, new ExprTypeProvider(fieldInfoProvider));
                const field = columnModel.filterField ?? '';
                const queryFilter = (expr: QueryExpr) => queryDescriptor.findOperationByField(expr, field, (_, parents) => parents.length === 0);
                const filters = model.getFilters().flatMap((x) => queryFilter(x as QueryExpr));
                if (filters.length && field) {
                    options.push({
                        label: `Remove Filters on ${fieldInfoProvider.getName({ Field: field })}`,
                        icon: <FilterOff size={18} />,
                        onClick: () => {
                            model.dataGrid?.applyFilters(model.getFilters().filter((x) => !queryFilter(x as QueryExpr)));
                            return { confirmation: 'Filters Removed' };
                        },
                    });
                } else {
                    const operators =
                        item.valueType === 'string'
                            ? ['eq', 'contains']
                            : item.valueType === 'number'
                            ? ['eq', 'gte', 'lte']
                            : item.valueType === 'date'
                            ? ['eq', 'gte', 'lte']
                            : 'eq';
                    const rightOp = item.valueType === 'null' ? [] : [{ Value: item.value }];
                    const formattedValue = <>{config.format?.(item) ?? item.value}</>;
                    for (const op of operators) {
                        const filterExpr = { Operation: op, Operands: [{ Field: field }, ...rightOp] };
                        const sx: Sx = { display: 'inline-grid', gridTemplateColumns: 'repeat(3, minmax(0, auto))', gap: '4px', maxWidth: 300 };
                        options.push({
                            label: (
                                <>
                                    Add Filter:{' '}
                                    <Box sx={sx}>
                                        {queryDescriptor.getTokens(filterExpr).map((t, i) => (
                                            <FilterToken key={i}>{t.type === 'constant' ? formattedValue : t.name}</FilterToken>
                                        ))}
                                    </Box>
                                </>
                            ),
                            icon: <Filter size={18} />,
                            onClick: () => {
                                model.addFilter(filterExpr);
                                return { confirmation: 'Filter Added' };
                            },
                        });
                    }
                }
            }
            return options;
        },
        [model]
    );
    return usePropertyGridOptionTranformer(menuTransformer);
}

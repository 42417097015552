import styled from '@emotion/styled';

export const ChartWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > div > div {
        position: static !important;
    }
`;

import { postResourcesTagResource } from '@apis/Resources';
import { BaseResource, TagResourcesJob } from '@apis/Resources/model';
import { EventEmitter } from '@root/Services/EventEmitter';
import { Logger } from '@root/Services/Logger';
import { injectable, inject } from 'tsyringe';

@injectable()
export class InlineTagEditService {
    private pendingChanges = new WeakMap<BaseResource, Map<string, InlineTagJob>>();
    public onTagChanged = EventEmitter.empty();

    public constructor(@inject(Logger) private readonly logger: Logger) {}

    public async update(resource: BaseResource, change: TagResourcesJob, tag: string) {
        this.setStatus(resource, tag, { status: 'started' });
        try {
            const { Job: job, Resource: updatedResource } = await postResourcesTagResource(change);
            if (job) {
                if (job.Status === 'Succeeded') {
                    this.setStatus(resource, tag, { status: 'done', jobId: job.Id });
                } else {
                    this.setStatus(resource, tag, { status: 'failed', jobId: job.Id });
                }
                if (updatedResource) {
                    Object.assign(resource, updatedResource);
                    this.onTagChanged.emit();
                }
            } else {
                this.setStatus(resource, tag, { status: 'bad-request' });
            }
        } catch (err) {
            this.logger.log('Failed while tagging a resource ', err);
        }
    }

    public getStatus(item: BaseResource, tag: string) {
        const lookup = this.pendingChanges.get(item);
        return lookup?.get(tag);
    }

    public consumeStatus(item: BaseResource, tag: string) {
        const lookup = this.pendingChanges.get(item);
        lookup?.delete(tag);
        if (lookup && lookup.size < 1) {
            this.pendingChanges.delete(item);
        }
    }

    private setStatus(resource: BaseResource, tag: string, details: InlineTagJob) {
        let lookup = this.pendingChanges.get(resource);
        if (!lookup) {
            this.pendingChanges.set(resource, (lookup = new Map<string, InlineTagJob>()));
        }
        lookup.set(tag, details);
    }
}
interface InlineTagJob {
    jobId?: string;
    status: 'started' | 'done' | 'failed' | 'bad-request';
}

import { ChartConfig } from '@root/Components/DashboardLayout/Charts/ChartRenderer';
import { Layout } from '@root/Components/DashboardLayout/Models';

export const defaultAzure = [
    {
        x: 0,
        y: 0,
        h: 9,
        w: 5,
        data: {
            type: 'chart',
            chartType: 'grid',
            groups: [],
            values: [],
            settings: {
                columns: [
                    {
                        type: 'string',
                        id: 'TagKey',
                        select: {
                            Alias: 'Resource Type',
                            Expr: {
                                Field: 'ResourceType',
                                Type: 'Base',
                            },
                        },
                    },
                    {
                        type: 'number',
                        id: 'Resources',
                        select: {
                            Alias: 'Count',
                            Expr: {
                                Operation: 'count',
                            },
                        },
                    },
                ],
                state: {
                    columns: [
                        {
                            id: 'TagKey',
                            width: 278,
                        },
                        {
                            id: 'Resources',
                            width: 94,
                        },
                    ],
                    filters: [],
                    sort: [],
                },
            },
            datasourceName: 'resources',
            title: 'Total Resources',
        },
    },
    {
        x: 5,
        y: 4,
        h: 5,
        w: 7,
        data: {
            type: 'chart',
            chartType: 'grid',
            groups: [
                {
                    Expr: {
                        Field: 'Region',
                    },
                    Alias: 'Region',
                },
            ],
            values: [
                {
                    Expr: {
                        Operation: 'count',
                    },
                    Alias: 'Count',
                },
            ],
            settings: {
                columns: [
                    {
                        type: 'string',
                        select: {
                            Alias: 'Primary Location',
                            Expr: {
                                Field: 'Microsoft_Storage/storageAccounts.primaryLocation',
                                Type: 'azure/Microsoft_Storage',
                            },
                        },
                        id: 'Region',
                    },
                    {
                        type: 'string',
                        select: {
                            Alias: 'Account',
                            Expr: {
                                Field: 'Microsoft_Storage/storageAccounts.primaryEndpoints.web',
                                Type: 'azure/Microsoft_Storage',
                            },
                        },
                        id: 'Web-1669256174230',
                    },
                    {
                        type: 'number',
                        select: {
                            Expr: {
                                Operation: 'countvalues',
                                Operands: [
                                    {
                                        Field: 'Tags.Key',
                                        Type: 'Base',
                                    },
                                ],
                            },
                            Alias: 'Tags',
                        },
                        id: 'Count of Tag Keys-1669256224310',
                    },
                    {
                        type: 'string',
                        select: {
                            Alias: 'Status',
                            Expr: {
                                Field: 'Microsoft_Storage/storageAccounts.statusOfPrimary',
                                Type: 'azure/Microsoft_Storage',
                            },
                        },
                        id: 'Status Of Primary-1669256927908',
                    },
                ],
                state: {
                    columns: [
                        {
                            id: 'Web-1669256174230',
                            width: 345,
                        },
                        {
                            id: 'Region',
                            width: 139,
                        },
                        {
                            id: 'Count of Tag Keys-1669256224310',
                            width: 93,
                        },
                        {
                            id: 'Status Of Primary-1669256927908',
                            width: 160,
                        },
                    ],
                    filters: [],
                    sort: [],
                },
            },
            datasourceName: 'resources',
            title: 'Storage Accounts',
        },
    },
    {
        x: 5,
        y: 0,
        h: 4,
        w: 5,
        data: {
            type: 'chart',
            chartType: 'pie',
            groups: [
                {
                    Alias: 'Tag Keys',
                    Expr: {
                        Field: 'Tags.Key',
                        Type: 'Base',
                    },
                },
            ],
            values: [
                {
                    Alias: 'Count',
                    Expr: {
                        Operation: 'count',
                    },
                },
            ],
            settings: {
                margin: {
                    top: 30,
                    bottom: 30,
                    left: 65,
                    right: 65,
                },
                threshold: 2,
                angle: 'large',
            },
            datasourceName: 'resources',
            title: 'Most Used Tags',
        },
    },
    {
        x: 10,
        y: 0,
        h: 2,
        w: 2,
        data: {
            type: 'chart',
            chartType: 'kpi',
            groups: [
                {
                    Expr: {
                        Field: 'Region',
                    },
                    Alias: 'Region',
                },
            ],
            values: [
                {
                    Alias: 'Value',
                    Expr: {
                        Operation: 'countvalues',
                        Operands: [
                            {
                                Field: 'Tags.Key',
                                Type: 'Base',
                            },
                        ],
                    },
                },
            ],
            settings: {
                labels: [''],
                valueFilters: [[]],
                format: [null],
            },
            datasourceName: 'resources',
            title: 'Total Tags',
        },
    },
    {
        x: 10,
        y: 2,
        h: 2,
        w: 2,
        data: {
            type: 'chart',
            chartType: 'kpi',
            groups: [],
            values: [
                {
                    Alias: 'Resources',
                    Expr: {
                        Operation: 'count',
                        Operands: [],
                    },
                },
            ],
            settings: {
                valueFilters: [[]],
                labels: [''],
                format: [null],
            },
            datasourceName: 'resources',
            title: 'Resources',
        },
    },
] as Layout<ChartConfig>[];

export const defaultAws = [
    {
        x: 0,
        y: 2,
        h: 7,
        w: 6,
        data: {
            type: 'chart',
            chartType: 'grid',
            groups: [],
            values: [],
            settings: {
                columns: [
                    { type: 'string', id: 'TagKey', select: { Alias: 'Owner', Expr: { Field: 'CsTags.Owner', Type: 'Tags' } } },
                    { type: 'number', id: 'Resources', select: { Alias: 'Resources', Expr: { Operation: 'count' } } },
                    {
                        type: 'number',
                        select: { Expr: { Operation: 'countvalues', Operands: [{ Field: 'Tags.Key', Type: 'Base' }] }, Alias: 'Tags' },
                        id: 'Count of Tag Keys-1669575628901',
                    },
                ],
                state: {
                    columns: [
                        { id: 'TagKey', width: 179 },
                        { id: 'Resources', width: 142 },
                        { id: 'Count of Tag Keys-1669575628901', width: 160 },
                    ],
                    filters: [],
                    sort: [],
                },
            },
            datasourceName: 'resources',
            title: 'Tags by Owner',
        },
    },
    {
        x: 0,
        y: 0,
        h: 2,
        w: 12,
        data: {
            type: 'chart',
            chartType: 'kpi',
            groups: [],
            values: [
                { Alias: 'Resources', Expr: { Operation: 'count', Operands: [] } },
                { Alias: 'Value', Expr: { Operation: 'countvalues', Operands: [{ Field: 'Tags.Key', Type: 'Base' }] } },
                { Alias: 'Value', Expr: { Operation: 'count', Operands: [] } },
            ],
            settings: {
                labels: ['Taggable Resources', 'Tags', 'Untagged Resources'],
                valueFilters: [[], [], [{ Operation: 'isNull', Operands: [{ Field: 'Tags.Key' }] }]],
                format: [null, null, null],
            },
            datasourceName: 'resources',
            title: 'AWS Environment Summary',
        },
    },
    {
        x: 6,
        y: 2,
        h: 7,
        w: 6,
        data: {
            type: 'chart',
            chartType: 'bar',
            groups: [{ Alias: 'Owner', Expr: { Field: 'CsTags.Owner', Type: 'Tags' } }],
            values: [{ Alias: 'Value', Expr: { Operation: 'countvalues', Operands: [{ Field: 'Tags.Key', Type: 'Base' }] } }],
            settings: { labelAngle: -50, margin: { top: 30, bottom: 70, left: 70, right: 20 }, orientation: 'Vertical' },
            datasourceName: 'resources',
            title: 'Tags by Owner',
        },
    },
    {
        x: 0,
        y: 9,
        h: 6,
        w: 6,
        data: {
            type: 'chart',
            chartType: 'grid',
            groups: [],
            values: [],
            settings: {
                columns: [
                    { type: 'string', id: 'TagKey', select: { Alias: 'Application', Expr: { Field: 'CsTags.Application', Type: 'Tags' } } },
                    { type: 'number', id: 'Resources', select: { Alias: 'Resources', Expr: { Operation: 'count' } } },
                    {
                        type: 'number',
                        select: { Expr: { Operation: 'countvalues', Operands: [{ Field: 'Tags.Key', Type: 'Base' }] }, Alias: 'Tags' },
                        id: 'Count of Tag Keys-1669576107094',
                    },
                ],
                state: {
                    columns: [
                        { id: 'TagKey', width: 200 },
                        { id: 'Resources', width: 100 },
                        { id: 'Count of Tag Keys-1669576107094', width: 160 },
                    ],
                    filters: [],
                    sort: [{ Direction: 'Desc', Expr: { Field: 'Resources', Type: 'string' } }],
                },
            },
            datasourceName: 'resources',
            title: 'Tags by Application',
        },
    },
    {
        x: 6,
        y: 9,
        h: 6,
        w: 6,
        data: {
            type: 'chart',
            chartType: 'bar',
            groups: [{ Alias: 'Application', Expr: { Field: 'CsTags.Application', Type: 'Tags' } }],
            values: [{ Alias: 'Value', Expr: { Operation: 'countvalues', Operands: [{ Field: 'Tags.Key', Type: 'Base' }] } }],
            settings: { labelAngle: -50, margin: { top: 30, bottom: 70, left: 70, right: 20 }, orientation: 'Vertical' },
            datasourceName: 'resources',
            title: 'Tags by Application',
        },
    },
    {
        x: 0,
        y: 15,
        h: 6,
        w: 6,
        data: {
            type: 'chart',
            chartType: 'grid',
            groups: [],
            values: [],
            settings: {
                columns: [
                    { type: 'string', id: 'TagKey', select: { Alias: 'Resource Type', Expr: { Field: 'ResourceType', Type: 'Base' } } },
                    { type: 'number', id: 'Resources', select: { Alias: 'Resources', Expr: { Operation: 'count', Operands: [] } } },
                    {
                        type: 'number',
                        select: { Expr: { Operation: 'countvalues', Operands: [{ Field: 'Tags.Key', Type: 'Base' }] }, Alias: 'Tags' },
                        id: 'Count of Tag Keys-1669576771872',
                    },
                ],
                state: {
                    columns: [
                        { id: 'TagKey', width: 200 },
                        { id: 'Resources', width: 100 },
                        { id: 'Count of Tag Keys-1669576771872', width: 160 },
                    ],
                    filters: [],
                    sort: [],
                },
            },
            datasourceName: 'resources',
            title: 'Tags by Resource Type',
        },
    },
    {
        x: 6,
        y: 15,
        h: 6,
        w: 6,
        data: {
            type: 'chart',
            chartType: 'bar',
            groups: [{ Alias: 'Resource Type', Expr: { Field: 'ResourceType', Type: 'Base' } }],
            values: [{ Alias: 'Count', Expr: { Operation: 'count' } }],
            settings: { margin: { top: 30, bottom: 70, left: 70, right: 20 } },
            datasourceName: 'resources',
            title: 'Tags by Resource type',
        },
    },
] as Layout<ChartConfig>[];

import { Autoscale, BaseResource, Ec2InstanceProp } from '@apis/Resources/model';
import { ResourceInfoTable } from '@root/Design/ResourceContainer';
import { Space, Table } from '@mantine/core';
import { NoDataMessage, ResourceDetailSection } from '../Design';

export interface AutoscaleInstance extends Ec2InstanceProp {
    AvailabilityZone?: string | null;
    HealthStatus?: string | null;
    InstanceType?: string | null;
    LifecycleState?: string | null;
    ProtectedFromScaleIn?: boolean | null;
}
export function AwsAutoscaleInstances({ resource }: { resource: BaseResource }) {
    const autoscale = resource as Autoscale;
    const instances = (autoscale.Instances ?? []) as AutoscaleInstance[];
    /*
    availabilityZone: resource.Instances[i].AvailabilityZone as string,
    healthStatus: resource.Instances[i].HealthStatus as string,
    instanceId: resource.Instances[i].InstanceId as string,
    instanceType: resource.Instances[i].InstanceType as string,
    lifecycleState: resource.Instances[i].LifecycleState as string,
    protectedFromScaleIn: resource.Instances[i].ProtectedFromScaleIn ? 'Yes' : 'No',

    */
    return (
        <ResourceDetailSection>
            {instances.length === 0 ? (
                <NoDataMessage>No instances found</NoDataMessage>
            ) : (
                <Table horizontalSpacing="md" verticalSpacing="sm">
                    <thead>
                        <tr>
                            <th>Availability Zone</th>
                            <th>Health Status</th>
                            <th>Instance Id</th>
                            <th>Instance Type</th>
                            <th>Lifecycle State</th>
                            <th>Protected From Scale In</th>
                        </tr>
                    </thead>
                    <tbody>
                        {instances.map((val, key) => {
                            return (
                                <tr key={key}>
                                    <td>{val.AvailabilityZone}</td>
                                    <td>{val.HealthStatus}</td>
                                    <td>{val.InstanceId}</td>
                                    <td>{val.InstanceType}</td>
                                    <td>{val.LifecycleState}</td>
                                    <td>{val.ProtectedFromScaleIn ? 'Yes' : 'No'}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            )}
        </ResourceDetailSection>
    );
}

import { Title, Text, Box, Group } from '@mantine/core';
import { MapContract } from '@apis/TagManager/model';
import { IDateFluentOperators, queryBuilder } from '@root/Services/QueryExpr';
import { ResourceChangeGrid, ResourceChangeModel } from '@root/Components/Actions/ResourceChangeGrid';
import { ReportHeader, ReportPanel } from '../Design';
import { useDi } from '@root/Services/DI';
import { MapContractHomeModel } from '../Models';
import { DatePeriodPicker } from '@root/Site/Msp/Components/DatePeriodPicker';
import { useEffect, useState } from 'react';

export function MapContractActivity({ contract }: { contract: MapContract }) {
    const homeModel = useDi(MapContractHomeModel);
    const [resourceChangeModel, setResourceChangeModel] = useState<ResourceChangeModel>();
    const [selectedPeriod, setSelectedPeriod] = useState<string>('This Month');
    const [datePeriod, setDatePeriod] = useState<{ startDate: Date; endDate: Date }>({
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
    });

    useEffect(() => {
        if (resourceChangeModel) {
            resourceChangeModel.additionalFilter = createBaseQuery();
            resourceChangeModel.init();
        }
    }, [JSON.stringify(datePeriod)]);

    const handleModelCreated = (model: ResourceChangeModel) => {
        setResourceChangeModel(model);
        model.additionalFilter = createBaseQuery();
    };

    const createBaseQuery = () => {
        const accounts = homeModel.accounts.map((acc) => acc.AwsAccountId ?? '');
        const query = queryBuilder<{ ['TimeStamp']?: Date; ['Keywords']?: string; ['AccountId']?: string }>()
            .where((b) => {
                const where = [];

                where.push(b.model.AccountId!.eq(accounts));
                where.push(b.model.Keywords!.eq(['map-migrated']));
                where.push((b.model.TimeStamp as unknown as IDateFluentOperators).onOrAfter(datePeriod.startDate));
                where.push((b.model.TimeStamp as unknown as IDateFluentOperators).onOrBefore(datePeriod.endDate));

                return b.and(...where);
            })
            .build();

        return query;
    };

    const handleDateChange = (range: { startDate: Date; endDate: Date }) => {
        setDatePeriod(range);
    };

    return (
        <ReportPanel>
            <ReportHeader>
                <Group>
                    <Title order={4}>Activity</Title> - <Text style={{ fontSize: '.8rem', color: 'gray' }}>{selectedPeriod}</Text>
                </Group>
                <DatePeriodPicker range={datePeriod!} selectedPeriodChanged={setSelectedPeriod} onRangeChanged={handleDateChange} />
            </ReportHeader>
            <Box sx={{ overflow: 'hidden', height: 'calc(100% - 16px)' }}>
                <ResourceChangeGrid noPadding onModelCreated={handleModelCreated} />
            </Box>
        </ReportPanel>
    );
}

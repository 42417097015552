import { CustomerSubsidyViewModel } from '@apis/Customers/model';
import { postDailyRollupQuery } from '@apis/Invoices';
import { QueryExpr, QueryField, QueryResult } from '@apis/Resources';
import { Query } from '@apis/Resources/model';
import { Box, Button, Card, Group, Space, Text, Title } from '@mantine/core';
import { GridChartSettings } from '@root/Components/Charts/GridChart';
import { KpiChart, KpiChartSettings } from '@root/Components/Charts/KpiChart';
import { ChartDashboardItem } from '@root/Components/DashboardLayout/ChartDashboardItem';
import { ChartConfig } from '@root/Components/DashboardLayout/Charts/ChartRenderer';
import { CustomizableDashboard } from '@root/Components/DashboardLayout/CustomizableDashboard';
import { IDashboardConfig, IDashboardItemType, QueryDatasource } from '@root/Components/DashboardLayout/Models';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { useNav } from '@root/Services/NavigationService';
import { useLink } from '@root/Services/Router/Router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ArrowRight } from 'tabler-icons-react';
import { inject, Lifecycle, scoped } from 'tsyringe';
import { add, differenceInHours, format, max, min } from 'date-fns';
import { queryBuilder, SchemaService, SchemaValueProvider } from '@root/Services/QueryExpr';
import { DateRangeFilter } from '@root/Components/Filter/DateRangeFilter';
import { BasicRouteLoader } from '@root/Services/Router/BasicRouteLoader';
import { LineChartSettings } from '@root/Components/Charts/LineChart';
import { InvoiceSchemaService } from '@root/Services/Invoices/InvoiceSchemaService';
import { AddFilterButton, DataFilters } from '@root/Components/Filter/Filters';
import { SchemaFieldNameProvider } from '@root/Components/Filter/Services';
import { FieldPicker } from '@root/Components/Picker/FieldPicker';

@scoped(Lifecycle.ContainerScoped)
class CeDashboardState {
    public readonly customer = new EventEmitter<CustomerSubsidyViewModel | undefined>(undefined);
    public readonly dateRange = new EventEmitter<{ from: Date; to: Date }>({ from: add(new Date(), { days: -30 }), to: new Date() });
    public readonly constraint = new EventEmitter<{ min: Date; max: Date } | undefined>(undefined);
    public readonly filters = new EventEmitter<QueryExpr[]>([]);
    public readonly schemaService = new EventEmitter<SchemaService | undefined>(undefined);
    public valueProvider?: SchemaValueProvider;

    public constructor(@inject(InvoiceSchemaService) public readonly invoiceSchemaSvc: InvoiceSchemaService) {}

    public async init() {
        const types = await this.invoiceSchemaSvc.getSchema();
        const schema = new SchemaService(types);
        this.valueProvider = new SchemaValueProvider(schema, (query: Query) => postDailyRollupQuery(query, { ...this.getRangeJson() }));
        this.schemaService.emit(schema);
    }

    public getRangeJson() {
        const { value } = this.dateRange;
        return {
            from: format(value.from, 'yyyy-MM-dd'),
            to: format(value.to, 'yyyy-MM-dd'),
        };
    }

    public getFilter() {
        const range = this.getRangeJson();
        return [
            { Operation: 'gt', Operands: [{ Field: 'UsageStartDate' }, { Value: range.from }] },
            { Operation: 'lte', Operands: [{ Field: 'UsageStartDate' }, { Value: range.to }] },
            ...this.filters.value,
        ];
    }
}

export function CloudExplorerDashboard({ customer }: { customer: CustomerSubsidyViewModel }) {
    const di = useDiContainer();
    const dashboardState = useMemo(() => {
        const result = di.resolve(CeDashboardState);
        result.init();
        return result;
    }, []);
    const invoiceSchema = dashboardState.invoiceSchemaSvc;
    const formatSvc = useDi(FormatService);
    useEffect(() => dashboardState.customer.emit(customer), [customer]);
    const { dateRange, constraint, handleChange, options } = useDateRangePicker();
    const [dateFilter, setDateFilter] = useState<QueryExpr[]>();
    const schemaSvc = useEventValue(dashboardState.schemaService);
    const fieldInfoProvider = useMemo(() => schemaSvc && new SchemaFieldNameProvider(schemaSvc), [schemaSvc]);
    useEvent(dashboardState.dateRange);
    useEffect(() => dashboardState.constraint.emit(constraint), [constraint]);
    useEffect(() => {
        if (dateRange?.from && dateRange?.to) {
            dashboardState.dateRange.emit(dateRange as { from: Date; to: Date });
            setDateFilter(dashboardState.getFilter());
        } else {
            setDateFilter(undefined);
        }
    }, [dateRange?.from, dateRange?.to, setDateFilter]);
    const filters = useEventValue(dashboardState.filters);

    const datasource = useMemo(
        () =>
            [
                {
                    name: 'invoice',
                    source: (query: Query) => postDailyRollupQuery(query, { ...dashboardState.getRangeJson() }),
                    schema: invoiceSchema,
                    getValueProviderFactory: () => {
                        const schemaValueProvider = dashboardState.valueProvider!;
                        return {
                            getValueProvider(field: QueryField) {
                                return schemaValueProvider.getValueProvider(field);
                            },
                        };
                    },
                    getDefaultGroup: () => ({ Expr: { Field: 'ResourceType' }, Alias: 'Resource Type' }),
                    getDefaultValue: () => ({ Expr: { Operation: 'count' }, Alias: 'Count' }),
                    getDefaultHistogram: () => ({
                        Expr: { Field: 'None' },
                        Alias: 'None',
                    }),
                },
            ] as QueryDatasource[],
        []
    );
    const itemTypes = useMemo(() => {
        const result = [
            ChartDashboardItem.itemType,
            { component: CompanyDetailCard, type: 'details-card', custom: true },
            { component: CpuMarketshareKpi, type: 'cpu-marketshare-kpi' },
        ] as IDashboardItemType[];
        return result;
    }, []);
    const defaultConfig = useMemo(() => {
        const result = {
            name: 'Default Dashboard',
            layout: [
                {
                    x: 8,
                    y: 0,
                    w: 4,
                    h: 5,
                    data: {
                        type: 'details-card',
                    },
                },
                {
                    x: 0,
                    y: 0,
                    h: 3,
                    w: 8,
                    data: {
                        type: 'cpu-marketshare-kpi',
                    },
                },
                {
                    x: 0,
                    y: 3,
                    h: 4,
                    w: 8,
                    data: {
                        settings: {
                            margin: { left: 60, top: 25, bottom: 80, right: 20 },
                            interval: 'day',
                        } as LineChartSettings,
                        chartType: 'line',
                        groups: [
                            { Alias: 'Date', Expr: { Field: 'UsageStartDate' } },
                            { Alias: 'CPU', Expr: { Field: 'product/processorFamily' } },
                        ],
                        values: [{ Alias: 'vCPU hrs', Expr: { Operation: 'sum', Operands: [{ Field: 'product/vcpuHours' }] } }],
                        type: 'chart',
                        title: 'vCPU Hours over Time',
                        datasourceName: 'invoice',
                        filters: [{ Operation: 'isNotNull', Operands: [{ Field: 'product/processorFamily' }] }],
                    } as ChartConfig,
                },
                {
                    x: 0,
                    y: 7,
                    h: 5,
                    w: 8,
                    data: {
                        type: 'chart',
                        chartType: 'grid',
                        datasourceName: 'invoice',
                        groups: [],
                        values: [],
                        title: 'Physical Processors',
                        filters: [{ Operation: 'isNotNull', Operands: [{ Field: 'product/processorFamily' }] }],
                        settings: {
                            columns: [
                                {
                                    id: 'family',
                                    type: 'string',
                                    select: {
                                        Alias: 'Type',
                                        Expr: {
                                            Field: 'product/processorFamily',
                                        },
                                    },
                                },
                                {
                                    id: 'model',
                                    type: 'string',
                                    select: {
                                        Alias: 'Model Name',
                                        Expr: {
                                            Field: 'product/physicalProcessor',
                                        },
                                    },
                                },
                                {
                                    id: 'vcpus',
                                    type: 'number',
                                    select: {
                                        Alias: 'Total vCPU Hours',
                                        Expr: {
                                            Operation: 'Sum',
                                            Operands: [{ Field: 'product/vcpuHours' }],
                                        },
                                    },
                                    formatter: (item: { vcpus: number }) => {
                                        return formatSvc.formatDecimal2(item.vcpus);
                                    },
                                },
                                {
                                    id: 'minvcpus',
                                    type: 'number',
                                    select: {
                                        Alias: 'Min VM Size',
                                        Expr: {
                                            Operation: 'Min',
                                            Operands: [{ Field: 'product/vcpu' }],
                                        },
                                    },
                                },
                                {
                                    id: 'maxvcpus',
                                    type: 'number',
                                    select: {
                                        Alias: 'Max VM Size',
                                        Expr: {
                                            Operation: 'Max',
                                            Operands: [{ Field: 'product/vcpu' }],
                                        },
                                    },
                                },
                            ],
                            state: {
                                columns: [
                                    { id: 'family', width: 80 },
                                    { id: 'model', width: 300 },
                                    { id: 'vcpus', width: 150 },
                                    { id: 'minvcpus', width: 120 },
                                    { id: 'maxvcpus', width: 120 },
                                ],
                                filters: [],
                                sort: [],
                            },
                        } as GridChartSettings,
                    } as ChartConfig,
                },
            ],
        } as IDashboardConfig;
        return result;
    }, []);
    return constraint && dateFilter && dateRange ? (
        <>
            <Group position="apart" mb="lg">
                <Box>
                    {fieldInfoProvider && (
                        <DataFilters
                            align="start"
                            renderFieldPicker={(onChange) => (
                                <FieldPicker mode="single" onChange={(f) => onChange(f[0]?.path)} schema={schemaSvc!} selections={[]} />
                            )}
                            valueProvider={dashboardState.valueProvider}
                            renderAddFilter={(model) => <AddFilterButton onClick={() => model.addEmptyFilter()} />}
                            filters={filters ?? []}
                            fieldInfoProvider={fieldInfoProvider}
                            onChange={dashboardState.filters.emit}
                        />
                    )}
                </Box>
                <Box sx={{ textAlign: 'right' }}>
                    <DateRangeFilter constraint={constraint} onChange={handleChange} value={dateRange} options={options} />
                </Box>
            </Group>
            <CustomizableDashboard
                implicitFilter={dashboardState.getFilter()}
                dashboardKey="cloud-ex"
                allowFilter
                hideHeader
                addOptions={[]}
                datasources={datasource}
                itemTypes={itemTypes}
                defaultConfig={defaultConfig}
                static
                height="0"
                padding={0}
            />
        </>
    ) : null;
}

interface CpuMarketshareKpiData {
    total: number;
    intel: number;
    amd: number;
    other: number;
    intelPct: number;
    amdPct: number;
    otherPct: number;
}
function CpuMarketshareKpi() {
    const [data, setData] = useState<CpuMarketshareKpiData>();
    const formatSvc = useDi(FormatService);
    const dashboardState = useDi(CeDashboardState);
    const dateRange = useEventValue(dashboardState.dateRange);
    const filters = useEventValue(dashboardState.filters);

    useEffect(() => {
        (async () => {
            const query = queryBuilder<{ ['product/vcpuHours']: number; ['product/processorFamily']: string }>()
                .select((b) => ({
                    total: b.sum(b.model['product/vcpuHours']),
                    intel: b.sum(b.iif(b.model['product/processorFamily'].eq('Intel'), b.model['product/vcpuHours'], b.param(0))),
                    amd: b.sum(b.iif(b.model['product/processorFamily'].eq('AMD'), b.model['product/vcpuHours'], b.param(0))),
                }))
                .build();
            query.Where = {
                Operation: 'and',
                Operands: [...dashboardState.getFilter(), { Operation: 'isNotNull', Operands: [{ Field: 'product/processorFamily' }] }],
            };
            const results = (await postDailyRollupQuery(query, { ...dashboardState.getRangeJson() })) as QueryResult<CpuMarketshareKpiData>;

            const item = results.Results?.[0];
            const total = item?.total ?? 0;
            const amd = item?.amd ?? 0;
            const intel = item?.intel ?? 0;
            const divisor = total ? total : 1;

            setData({
                total,
                amd: Math.round(amd),
                intel: Math.round(intel),
                other: Math.round(total - amd - intel),
                amdPct: amd / divisor,
                intelPct: intel / divisor,
                otherPct: (total - amd - intel) / divisor,
            });
        })();
    }, [dateRange, filters]);

    const kpiSettings = useMemo(() => {
        if (data) {
            return {
                data: [[{ Value: data.total }], [{ Value: data.amdPct }], [{ Value: data.intelPct }], [{ Value: data.otherPct }]],
                labels: [
                    'Total vCPU hrs',
                    `AMD ${formatSvc.formatInt(data.amd)} hrs`,
                    `Intel ${formatSvc.formatInt(data.intel)} hrs`,
                    `Other ${formatSvc.formatInt(data.other)} hrs`,
                ],
                format: [undefined, 'percent', 'percent', 'percent'] as KpiChartSettings['format'],
            };
        }
        return undefined;
    }, [data]);

    const periodHours = !dateRange ? 0 : differenceInHours(dateRange.to, dateRange.from);
    const periodHoursLbl = formatSvc.formatInt(periodHours);
    const fromName = !dateRange ? '' : formatSvc.toShortDate(dateRange.from);
    const toName = !dateRange ? '' : formatSvc.toShortDate(dateRange.to);
    const vcpusPerHour = data?.total && periodHours > 0 ? formatSvc.formatDecimal2(data.total / periodHours) : 0;

    return (
        <>
            <Group position="apart" px="xl">
                <Box>
                    <Title order={4}>Total Processor Usage</Title>
                    <Text>
                        Period: {fromName} &mdash; {toName}
                    </Text>
                </Box>
                {dateRange && (
                    <Box>
                        <Text align="right">
                            Hours: <strong>{periodHoursLbl}</strong>
                        </Text>
                        <Text align="right">
                            vCPU Demand: <strong>~{vcpusPerHour}</strong>
                        </Text>
                    </Box>
                )}
            </Group>
            {kpiSettings ? (
                <KpiChart
                    groups={[]}
                    values={[]}
                    data={kpiSettings.data as any}
                    settings={{ valueFilters: [], labels: kpiSettings.labels, format: kpiSettings.format }}
                ></KpiChart>
            ) : null}
        </>
    );
}

function CompanyDetailCard() {
    const formatSvc = useDi(FormatService);
    const dashboardState = useDi(CeDashboardState);
    const customer = useEventValue(dashboardState.customer);
    const constraint = useEventValue(dashboardState.constraint);
    const link = useLink();
    const { getDescendUrl } = useNav();

    return (
        <Card withBorder radius="lg" sx={{ height: '100%', alignItems: 'center', display: 'flex' }}>
            <Box sx={{ flexGrow: 1, flexBasis: '100%' }}>
                <Box px="md">
                    <Title>{customer?.Company}</Title>
                    <Space h="lg" />
                </Box>
                <Box px="lg">
                    <Space h="lg" />
                    <Text color="dimmed">Available data</Text>
                    <Text size="lg" weight="bold">
                        {!constraint ? (
                            <>&mdash;</>
                        ) : (
                            <>
                                {formatSvc.toShortDate(constraint.min)}
                                <> &mdash; </>
                                {formatSvc.toShortDate(min([new Date(), constraint.max]))}
                            </>
                        )}
                    </Text>
                    <Space h="lg" />
                    <Text color="dimmed">Signed Up At</Text>
                    <Text size="lg" weight="bold">
                        {formatSvc.toShortDate(formatSvc.toLocalDate(customer?.InvitationAcceptDate))}
                    </Text>
                    <Space h="lg" />
                    <Text color="dimmed">Account Rep</Text>
                    <Text size="lg" weight="bold">
                        {customer?.AccountRepName}
                    </Text>
                </Box>
            </Box>
        </Card>
    );
}

function useDateRangePicker() {
    const { getData, mergeParams } = useNav();
    const routeLoader = useDi(BasicRouteLoader);
    const company = useCompany();
    const { range: rawRange } = getData('range');
    const [dateRange, setDateRange] = useState<{ from?: Date; to?: Date; id?: string }>();
    const [constraint, setConstraint] = useState<{ min: Date; max: Date }>();
    const formatSvc = useDi(FormatService);
    useEvent(routeLoader.routeMeta);

    useEffect(() => {
        setConstraint(undefined);
        (async () => {
            const result = await queryBuilder<{ UsageStartDate: string }>()
                .select((b) => ({
                    max: b.max(b.model.UsageStartDate),
                    min: b.min(b.model.UsageStartDate),
                }))
                .execute((q) => postDailyRollupQuery(q, {}));
            const constraint = result?.Results?.map((r) => ({
                min: formatSvc.toLocalDate(r.min),
                max: formatSvc.toLocalDate(r.max),
            })).find(() => true);
            setConstraint(constraint);
        })();
    }, [company?.Id]);

    const getKnownRange = useCallback((id: string) => {
        switch (id) {
            case 'last-30':
                return { from: add(new Date(), { days: -30 }), to: new Date(), id };
            case 'last-60':
                return { from: add(new Date(), { days: -60 }), to: new Date(), id };
        }
    }, []);

    const getDateRange = useCallback((range: string) => {
        const [from, to] = range.split('-').map((d) => (d.match(/^\d{8}$/) ? formatSvc.from8DigitDate(d) : null)) as [Date | null, Date | null];
        return { from, to };
    }, []);

    useEffect(() => {
        if (constraint) {
            const defFrom = constraint?.max ? add(constraint.max, { days: -30 }) : new Date();
            const knownRange = rawRange ? getKnownRange(rawRange) : null;
            const dateRange = knownRange ? knownRange : rawRange ? getDateRange(rawRange) : null;
            const nextRange = dateRange ?? { from: defFrom, to: constraint.max };
            const safeRange = {
                from: max([nextRange.from ?? new Date(), constraint.min]),
                to: min([nextRange.to ?? new Date(), constraint.max]),
                id: rawRange,
            };

            setDateRange(safeRange);
        } else {
            setDateRange(undefined);
        }
    }, [rawRange, constraint, setDateRange]);

    const handleChange = useCallback((range: { from?: Date; to?: Date; id?: string }) => {
        const from = range.from ? formatSvc.to8DigitDate(range.from) : '';
        const to = range.to ? formatSvc.to8DigitDate(range.to) : '';
        mergeParams({
            range: range.id ? range.id : `${from}-${to}`,
        });
    }, []);

    const options = useMemo(
        () => [
            { id: 'last-30', label: 'Last 30 days' },
            { id: 'last-60', label: 'Last 60 days' },
        ],
        []
    );

    return { constraint, dateRange, handleChange, options };
}

import { postResourcesQuery } from '@apis/Resources';
import { BaseResource } from '@apis/Resources/model';
import { getVariantGroupGetVariantGroups } from '@apis/TagManager';
import { Variant, VariantGroup } from '@apis/TagManager/model';
import { ChildAccessor } from '@root/Components/DataGrid/Models';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { PageContent, PageSection } from '@root/Design/Layout';
import { EventEmitter } from '@root/Services/EventEmitter';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { makeAutoObservable } from 'mobx';
import { useEffect, useMemo, useState } from 'react';
import { TagsShell } from './Common';
import { VariantGroupGrid } from './Components/VariantGroupGrid';
import { VariantViewModel, VGViewModel } from './ViewModels/ViewModels';

export function TagManagerVariants() {
    const [vGroups, setVGroups] = useState<VGViewModel[]>([]);
    const com = useCompany();
    useEffect(() => {
        (async () => {
            const VGs: VariantGroup[] = await getVariantGroupGetVariantGroups({ companyId: com?.Id });

            const vGroups: VGViewModel[] = [];
            for (const group of VGs) {
                const vGroup: VGViewModel = {
                    ...group,
                    PrimaryString: '',
                    Type: !group.ParentKey ? 'Key' : 'Value',
                    active: false,
                    variantCount: group.Variants?.length ?? 0,
                    Variants: [],
                    invalidated: EventEmitter.empty(),
                    setPrimary: (variant) => {
                        if (vGroup.currentPrimary) {
                            vGroup.currentPrimary.IsPrimary = false;
                        }
                        vGroup.currentPrimary = variant;
                        vGroup.PrimaryString = variant.VariantString as string;
                        variant.IsPrimary = true;
                    },
                };

                if (group.Variants && group.Variants.length > 0) {
                    let anyInactive = false;
                    for (const varient of group.Variants) {
                        const variantVm: VariantViewModel = makeAutoObservable({
                            ...varient,
                            invalidated: EventEmitter.empty(),
                            group: () => vGroup,
                        });
                        if (varient.IsPrimary) {
                            vGroup.PrimaryString = varient.VariantString as string;
                            vGroup.currentPrimary = variantVm;
                        }
                        vGroup.Variants.push(variantVm);
                        anyInactive = anyInactive || (!varient.IsPrimary && (varient.Count as number) > 0);
                    }
                    vGroup.active = anyInactive;
                }
                vGroups.push(makeAutoObservable(vGroup));
            }
            setVGroups(vGroups);
        })();
    }, []);

    const childRows = useMemo(
        () =>
            ({
                hasChildren: (item) => {
                    return 'Variants' in item
                        ? ((item.Variants as Variant[]).length ?? 0) > 0
                        : 'VariantString' in item
                        ? (item.Count as number) > 0
                        : false;
                },
                getChildren: (item) => {
                    if ('Variants' in item) {
                        return item.Variants ?? [];
                    }
                    if ('VariantString' in item) {
                        return getChildResources(item.VariantString as string);
                    }
                    return [] as unknown;
                },
            } as ChildAccessor<VGViewModel | VariantViewModel | BaseResource>),
        []
    );

    const ResolvedVariantGroups = vGroups.filter((s) => !s.active);
    const UnResolvedVariantGroups = vGroups.filter((s) => s.active);

    return (
        <TagsShell>
            <PageContent>
                Tags Landing Page
                <PageSection>
                    UnResolved Variant Groups
                    <PageSection height={500}>
                        <VariantGroupGrid dataSource={UnResolvedVariantGroups} childAccessor={childRows} />
                    </PageSection>
                </PageSection>
                <PageSection>
                    Resolved Variant Groups
                    <PageSection height={500}>
                        <VariantGroupGrid dataSource={ResolvedVariantGroups} childAccessor={childRows} />
                    </PageSection>
                </PageSection>
            </PageContent>
        </TagsShell>
    );
}
endpoint('tmvariants', TagManagerVariants, 'TMVariants');

async function getChildResources(variantString: string) {
    const RS = await postResourcesQuery(
        {
            Where: {
                operation: 'eq',
                operands: [{ field: 'Tags.Key' }, { value: variantString }],
            },
        },
        { companyId: 71 }
    );
    return RS.Results as BaseResource[];
}

import { Card, Group, Space } from '@mantine/core';
import { AnchorButton } from '@root/Design/Primitives';
import { useDi } from '@root/Services/DI';
import { useEffect, useState } from 'react';
import { ResourceTagService } from '../Services/ResourceTagService';
import { BulkTagProps } from './BulkTagProps';
import { TagEditor } from './TagEditor';
import { TagField } from './TagField';

export const RemoveTags = (props: BulkTagProps) => {
    const [tagKeyList, setTagKeyList] = useState<string[]>([]);
    const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
    const [canAdd, setCanAdd] = useState<boolean>(false);
    const [canRemove, setCanRemove] = useState<boolean>(false);
    const [tagsToRemove, setTagsToRemove] = useState<string[]>(['']);
    const [selectedValueErrors, setSelectedValueErrors] = useState<Map<number, string>>(new Map());
    const tagSvc = useDi(ResourceTagService);

    useEffect(() => {
        (async () => {
            const keys = await tagSvc.getValidKeys(props.resourceIds);
            setTagKeyList(keys);
        })();
    }, [props.resourceIds.count()]);

    useEffect(() => {
        const validForm = formIsValid();
        setDisableSubmit(!validForm);
        setCanRemove(tagsToRemove.length > 1);
        setCanAdd(validForm && tagKeyList.filter((t) => !tagsToRemove.includes(t)).length > 0);
    }, [JSON.stringify(tagsToRemove)]);

    const formIsValid = () => {
        return tagsToRemove.filter((t) => t === '').length === 0;
    };

    const addRowHandler = () => {
        setTagsToRemove((prevState) => {
            let newState = [...prevState];
            newState.push('');
            return newState;
        });
    };

    const removeRowHandler = (index: number) => {
        if (tagsToRemove.length > 1) {
            setTagsToRemove((prevState) => {
                let newState = [...prevState];
                newState.splice(index, 1);
                return newState;
            });
        }
    };

    const setTagKey = (index: number, key: string) => {
        setTagsToRemove((prevState) => {
            let newState = [...prevState];
            let tag = newState[index];
            tag = key;
            newState.splice(index, 1, tag);
            return newState;
        });

        if (tagKeyList.includes(key)) {
            setSelectedValueErrors((prevState) => {
                let newState = new Map(prevState);
                if (newState.has(index)) {
                    newState.delete(index);
                }
                return newState;
            });
        } else {
            setSelectedValueErrors((prevState) => {
                let newState = new Map(prevState);
                newState.set(index, `Value "${key}" does not exist for any of the selected resources.`);
                return newState;
            });
        }
    };

    const tagJob = tagSvc.createTagJob(props.resourceIds, { DeleteTags: tagsToRemove.map((t) => ({ Key: t })) });

    return (
        <TagEditor
            onButtonClick={props.onButtonClick}
            companyId={props.companyId}
            resourceIds={props.resourceIds}
            pageTitle={props.pageTitle}
            instructions={props.instructions}
            canSubmit={!disableSubmit}
            setDisableSubmit={setDisableSubmit}
            operationType="remove"
            tagJob={tagJob}
            onTagJobStarting={props.onTagJobStarting}
            onTagJobStarted={props.onTagJobStarted}
            overwriteConflicts={false}
            setOverwriteConflicts={() => null}
        >
            {tagsToRemove.map((tag, index) => (
                <>
                    <Card withBorder radius="md" p="sm">
                        <Group noWrap grow spacing={0}>
                            <TagField
                                atid={'TagKeyInput:' + index}
                                key={`RemoveTagField${index}`}
                                fieldValue={tagsToRemove[index] ?? ''}
                                index={index}
                                count={tagsToRemove.length}
                                valuePickList={tagKeyList.filter((t) => !tagsToRemove.includes(t))}
                                label="Tag"
                                setSelectedValue={setTagKey}
                                handleAddRow={addRowHandler}
                                handleRemoveRow={removeRowHandler}
                                canAdd={canAdd}
                                canRemove={canRemove}
                                receiveInitalFocus={true}
                            />
                        </Group>
                    </Card>
                    <Space h="sm" />
                </>
            ))}
            <Card withBorder radius="md" p="sm">
                <AnchorButton text="Add" onClick={addRowHandler} icon={<i className="ti ti-circle-plus"></i>} atid="RemoveTagsAddAnchor" />
            </Card>
        </TagEditor>
    );
};

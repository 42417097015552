import { IFieldConfig } from '@root/Components/Recommendations/RecommendationGrid';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useMemo } from 'react';
import { IComparsonConfig, IMetricInfo } from '@root/Components/Recommendations/Options/Models';
import { BaseRightsizingPage, SelectionSpecCompare } from './Components';
import { Box, Group } from '@mantine/core';
import { ChevronRight } from 'tabler-icons-react';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';

const specMetrics = [
    {
        label: 'vCPUs',
        path: 'vCpu',
        value: (o) => o['vCpu'] ?? -1,
        format: (v) => (v < 0 ? 'Not Available' : v),
    },
    {
        label: 'Memory',
        path: 'Memory',
        value: (o) => o['Memory'] ?? -1,
        format: (v) => (v < 0 ? 'Not Available' : v + 'GB'),
    },
    {
        label: 'Network Performance',
        path: 'NetworkPerformance',
        value: (o) => o['NetworkPerformance'] ?? -1,
        format: (v) => (v < 0 ? 'Not Available' : v.toFixed(v < 1 ? 1 : 0) + 'Gbps'),
    },
] as IMetricInfo[];

export function Ec2RightsizingPage() {
    const fmtSvc = useDi(FormatService);
    const fieldConfig = useMemo(
        () =>
            ({
                selectorRenderer: (r) => {
                    if (!r.OptionSelected?.ChangeDetails?.SizeChange?.RecommendedSize) {
                        return <>No Change</>;
                    } else {
                        const {
                            InstanceTypeAttributes: { InstanceType: currentType },
                        } = r.Factors as { InstanceTypeAttributes: { InstanceType: string } };
                        const selectedType = r.OptionSelected?.ChangeDetails?.SizeChange?.RecommendedSize;
                        return (
                            <>
                                {!selectedType ? (
                                    'No Change'
                                ) : (
                                    <Box ml="xs">
                                        <Group spacing="sm" sx={{ height: 25 }} noWrap>
                                            {currentType} <ChevronRight size={16} /> {selectedType}
                                        </Group>
                                        <SelectionSpecCompare specMetrics={specMetrics} recommendation={r} />
                                    </Box>
                                )}
                            </>
                        );
                    }
                },
                currentStateField: 'Factors.InstanceTypeAttributes.InstanceType',
                currentStateFieldLabel: 'Instance Type',
                currentStateAccessor: (r) => (r.Factors as any)?.InstanceTypeAttributes?.InstanceType ?? '',
                factorFields: [
                    'Factors.UsedByEMR',
                    'Factors.UsedByEKS',
                    'Factors.UsedByElasticBeanstalk',
                    'Factors.UsedInAutoScaleGroup',
                    'Factors.ClusterName',
                    'Factors.HasParentResource',
                    'Factors.InstanceLifecycle',
                    'Factors.HasSavingsPlanCoveredUsage',
                    'Factors.FromCloudFormation',
                    'Factors.HasDiscountedUsage',
                    'Factors.Architecture',
                    'Factors.InstanceTypeAttributes.Sku',
                    'Factors.InstanceTypeAttributes.Location',
                    'Factors.InstanceTypeAttributes.RegionCode',
                    'Factors.InstanceTypeAttributes.InstanceType',
                    'Factors.InstanceTypeAttributes.Tenancy',
                    'Factors.InstanceTypeAttributes.vCpu',
                    'Factors.InstanceTypeAttributes.Memory',
                    'Factors.InstanceTypeAttributes.NetworkPerformance',
                    'Factors.InstanceTypeAttributes.ProcessorArchitecture',
                    'Factors.InstanceTypeAttributes.Storage',
                    'Factors.InstanceTypeAttributes.VirtualizationType',
                    'Factors.InstanceTypeAttributes.OperatingSystem',
                    'Factors.InstanceTypeAttributes.LicenseModel',
                    'Factors.InstanceTypeAttributes.PreInstalledSoftware',
                    'Factors.InstanceTypeAttributes.GpuMemory',
                    'Factors.InstanceTypeAttributes.CurrentGeneration',
                    'Factors.InstanceTypeAttributes.InstanceFamily',
                    'Factors.RunningState',
                    'Factors.Pricing.PricePerUnit',
                    'Factors.Placement.AvailabilityZone',
                    'Factors.Metrics.CpuUtilization',
                    'Factors.Metrics.NetworkIn',
                    'Factors.Metrics.NetworkOut',
                    'Factors.Metrics.MemoryUtilization',
                ],
                columnConfig: {
                    'Factors.Metrics.CpuUtilization': {
                        format: 'whole-percent',
                        header: 'CPU Utilization',
                        helpText: 'Top Average CloudWatch CPU usage for last 30 days per 1 hour interval.',
                    },
                    'Factors.Metrics.NetworkIn': {
                        format: 'bytes',
                        header: 'Network In',
                        helpText: 'Top Average CloudWatch Network In for last 30 days per 1 hour interval.',
                    },
                    'Factors.Metrics.NetworkOut': {
                        format: 'bytes',
                        header: 'Network Out',
                        helpText: 'Top Average CloudWatch Network Out for last 30 days per 1 hour interval.',
                    },
                    'Factors.Metrics.MemoryUtilization': {
                        format: 'whole-percent',
                        header: 'Memory Utilization',
                        helpText: 'Top Average CloudWatch Memory usage for last 30 days per 1 hour interval.',
                    },
                    'Factors.ClusterName': {
                        header: 'Cluster Name',
                        helpText: 'The type and name of the cluster that the instance is in.',
                        format: 'string',
                    },
                    'Factors.InstanceTypeAttributes.vCpu': {
                        header: 'vCPUs',
                        helpText: 'The number of virtual CPUs that the instance has.',
                        format: 'number',
                    },
                },
                defaultColumns: [
                    'LowestRiskHighestSavings.ExpectedAnnualSavings',
                    'LowestRiskHighestSavings.ChangeDetails.SizeChange.RecommendedSize',
                    'Factors.InstanceTypeAttributes.InstanceType',
                    'ResourceMetadata.Account',
                    'ResourceMetadata.AccountName',
                    'ResourceMetadata.Name',
                    'ResourceMetadata.ResourceId',
                    'ResourceMetadata.ExtendedAnnualizedCost',
                    'ResourceMetadata.Region',
                    'Factors.ClusterName',
                ],
                specInfo: specMetrics,
            } as IFieldConfig),
        []
    );

    const comparisonConfig = useMemo(
        () =>
            ({
                currentStateAccessor: (o) => (o.Factors as any)?.InstanceTypeAttributes?.InstanceType ?? 'Unknown',
                observabilityMetrics: [
                    {
                        metric: 'CPUUtilization',
                        spec: 'vCpu',
                        title: 'CPU',
                        transform: (v) => v / 100,
                    },
                    {
                        metric: 'NetworkIn',
                        spec: 'NetworkPerformance',
                        title: 'Network In',
                        transform: (v, r) => v / ((r.HistoricalUtilizationMetrics?.['NetworkPerformance'] ?? 1) * 1024 ** 3),
                    },
                    {
                        metric: 'NetworkOut',
                        spec: 'NetworkPerformance',
                        title: 'Network Out',
                        transform: (v, r) => v / ((r.HistoricalUtilizationMetrics?.['NetworkPerformance'] ?? 1) * 1024 ** 3),
                    },
                    {
                        metric: 'Memory Used',
                        spec: 'Memory',
                        title: 'Memory',
                        transform: (v) => v / 100,
                    },
                ],
                utilizationMetrics: [
                    {
                        label: 'CPU Utilization',
                        value: (o) => (o['CpuUtilization'] ?? 0) / 100,
                        format: (v) => (v < 0 ? 'Not Available' : fmtSvc.formatPercent(v)),
                    },
                    {
                        label: 'Memory Utilization',
                        value: (o) => (o['MemoryUtilization'] ?? 0) / 100,
                        format: (v) => (v < 0 ? 'Not Available' : fmtSvc.formatPercent(v)),
                    },
                    {
                        label: 'Network In',
                        value: (o) => o['NetworkIn'] ?? -1,
                        format: (v) => (v < 0 ? 'Not Available' : fmtSvc.formatPercent(v)),
                    },
                    {
                        label: 'Network Out',
                        value: (o) => o['NetworkOut'] ?? -1,
                        format: (v) => (v < 0 ? 'Not Available' : fmtSvc.formatPercent(v)),
                    },
                ],
                specMetrics: specMetrics,
            } as IComparsonConfig),
        []
    );

    return (
        <ConnectionCheck>
            {() => <BaseRightsizingPage title="EC2" comparisonConfig={comparisonConfig} fieldConfig={fieldConfig} resourceType="EC2 On Demand" />}
        </ConnectionCheck>
    );
}

endpoint('rightsizing-ec2', Ec2RightsizingPage, 'Rightsizing');

/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Customers.Host.Api
 * OpenAPI spec version: 1.0
 */

export type CustomerSubsidyRequestCustomerSubsidyStatus = typeof CustomerSubsidyRequestCustomerSubsidyStatus[keyof typeof CustomerSubsidyRequestCustomerSubsidyStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerSubsidyRequestCustomerSubsidyStatus = {
  PendingApproval: 'PendingApproval',
  Approved: 'Approved',
  Rejected: 'Rejected',
  Invited: 'Invited',
  Connected: 'Connected',
} as const;

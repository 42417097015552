import { Anchor, Group, List, Popover, Text } from '@mantine/core';
import { useTheme } from '@emotion/react';

export interface IdleResourcesTooltipProps {
    resourceType: string;
}

export const IdleResourcesTooltip = (props: IdleResourcesTooltipProps) => {
    const theme = useTheme();

    return (
        <Popover width={330} trapFocus position="bottom" withArrow shadow="md">
            <Popover.Target>
                <i className="ti ti-info-circle" style={{ fontSize: theme.fontSizes.md, cursor: 'help' }}></i>
            </Popover.Target>
            <Popover.Dropdown>
                <Group p="sm" style={{ fontSize: '15px' }}>
                    <Text>
                        The <strong>{props.resourceType}</strong> resource type does not have an option for stopping/pausing or restarting/unpausing.
                    </Text>
                    <Text>
                        CloudSaver does not terminate resources in your environment because we don't have visibility into the potential associated
                        data loss.
                    </Text>
                    <Text>If you identify resources no longer needed, please refer to:</Text>
                    <List style={{ fontSize: '15px' }}>
                        {props.resourceType === 'EMR' ? (
                            <List.Item>
                                <Anchor href="https://docs.aws.amazon.com/emr/latest/ManagementGuide/UsingEMR_TerminateJobFlow.html" target="_blank">
                                    AWS Terminate Job Flow
                                </Anchor>
                            </List.Item>
                        ) : (
                            <List.Item>
                                <Anchor
                                    href="https://docs.aws.amazon.com/elasticloadbalancing/latest/application/load-balancer-delete.html"
                                    target="_blank"
                                >
                                    AWS Load Balancer Deletion
                                </Anchor>
                            </List.Item>
                        )}
                    </List>
                </Group>
            </Popover.Dropdown>
        </Popover>
    );
};

import { Box, Divider } from '@mantine/core';
import { AnchorButton } from '@root/Design/Primitives';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { useDi } from '@root/Services/DI';
import { DoorExit } from 'tabler-icons-react';

export function ErrorLogout() {
    const authNSvc = useDi(AuthenticationService);

    return (
        <Box sx={{ textAlign: 'center' }}>
            <Divider my="lg" />
            <AnchorButton onClick={() => authNSvc.signout()} text="Log out" icon={<DoorExit />} />
        </Box>
    );
}

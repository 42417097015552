import {
    Drawer,
    Group,
    Title,
    CloseButton,
    Divider,
    Stack,
    Box,
    Button,
    Input,
    useMantineTheme,
    Popover,
    Text,
    Card,
    Loader,
    ThemeIcon,
} from '@mantine/core';
import { EventEmitter, useEvent, useEventValue, useToggle } from '@root/Services/EventEmitter';
import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AllocationDimension } from '@apis/Invoices/model';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useDisclosure } from '@mantine/hooks';
import { AlertTriangle, Calendar, ChevronDown, ChevronUp } from 'tabler-icons-react';
import { Picker } from '@root/Components/Picker/Picker';
import { AllocDimOption, ShowbackPersistence } from '@root/Services/Invoices/ShowbackService';
import { VisibleSpaces } from '@root/Components/Text/VisibleSpaces';
import { SidePanel, useSidePanelOpener } from '@root/Design/SidePanel';

interface AllocationDimensionDetailsDrawerProps {
    settingsRequestedEvt: EventEmitter<AllocationDimension | undefined>;
    onSave: (allocDim: AllocationDimension) => Promise<void>;
}
export function AllocationDimensionDetailsDrawer({ settingsRequestedEvt, onSave }: AllocationDimensionDetailsDrawerProps) {
    const requestedAllocDim = useEventValue(settingsRequestedEvt);
    const [dimension, setDimension] = useState<AllocDimOption>();
    const saveAllowed = useMemo(() => !!dimension?.field && !!dimension?.type && requestedAllocDim, [dimension, requestedAllocDim]);
    const [saving, setSaving] = useState(false);
    const sidePanelOpener = useSidePanelOpener(false);

    useEffect(() => {
        setDimension(
            !requestedAllocDim
                ? undefined
                : {
                      field: requestedAllocDim?.DimensionName!,
                      type: requestedAllocDim?.DimensionType!,
                      prefix: requestedAllocDim?.IntegrationPrefix ?? null,
                  }
        );
        if (requestedAllocDim) {
            sidePanelOpener.open();
        } else {
            sidePanelOpener.close();
        }
    }, [requestedAllocDim]);

    const onOpenEvt = useCallback((opened: boolean) => {
        if (!opened) {
            settingsRequestedEvt.emit(undefined);
        }
    }, []);
    useEvent(sidePanelOpener.evt, onOpenEvt);

    const save = async () => {
        if (dimension?.field && dimension?.type && !!requestedAllocDim) {
            setSaving(true);
            requestedAllocDim.DimensionName = dimension.field;
            requestedAllocDim.DimensionType = dimension.type;
            requestedAllocDim.IntegrationPrefix = dimension.prefix;

            try {
                await onSave(requestedAllocDim);
                sidePanelOpener.close();
            } finally {
                setSaving(false);
            }
        }
    };

    const onSettingsChanged = useCallback((dim: AllocDimOption) => setDimension(dim), [setDimension]);

    const hasRules = !!requestedAllocDim?.TaggingRules?.length || !!requestedAllocDim?.AllocationRules?.length;

    return (
        <SidePanel
            size={500}
            opener={sidePanelOpener}
            title="Showback Settings"
            toolbar={
                <>
                    <Button variant="outline" onClick={sidePanelOpener.close}>
                        Cancel
                    </Button>
                    <Button disabled={!saveAllowed} onClick={save} rightIcon={saving ? <Loader size="sm" color="gray.0" /> : null}>
                        Save
                    </Button>
                </>
            }
        >
            <Stack sx={{ height: '100%' }}>
                <AllocationDimensionSettings onChange={onSettingsChanged} selectedDim={dimension} />
                {!hasRules ? null : <AllocChangeWarning />}
            </Stack>
        </SidePanel>
    );
}

function AllocChangeWarning() {
    const theme = useMantineTheme();
    return (
        <Card radius="md" sx={{ background: theme.colors.warning[2] }}>
            <Group noWrap>
                <AlertTriangle size={50} stroke={theme.colors.warning[5]} />
                <Text>Changing the Allocation Dimension will affect your current resources allocations</Text>
            </Group>
        </Card>
    );
}

export function AllocationDimensionSettings({ onChange, selectedDim }: { selectedDim?: AllocDimOption; onChange: (dim: AllocDimOption) => void }) {
    const showbackSvc = useDi(ShowbackPersistence);
    const theme = useMantineTheme();
    const [dimension, setDimension] = useState(selectedDim);
    const [dimensionOptions, setDimensionOptions] = useState<(AllocDimOption | { group: string; options: AllocDimOption[] })[]>([]);
    const [loading, setLoading] = useState(true);
    const [pickerOpen, { close: closePicker, toggle: togglePicker }] = useToggle(false);

    const getAllocDimOptions = useCallback(async () => {
        const dims = await showbackSvc.getAllocDimOptions();
        const { integration, tag, standard } = dims;

        const standardOptions = standard;

        const tagGroup = { group: 'Tag', options: tag };

        const integrationGroups = integration.reduce((res, item) => {
            if (item.prefix) {
                if (res.has(item.prefix)) {
                    res.get(item.prefix)!.options.push(item);
                } else {
                    res.set(item.prefix, { group: item.prefix, options: [item] });
                }
            }
            return res;
        }, new Map<string, { group: string; options: AllocDimOption[] }>());
        const integrationOptions = Array.from(integrationGroups.values());

        return [...standardOptions, tagGroup, ...integrationOptions];
    }, []);

    useEffect(() => {
        setLoading(true);
        getAllocDimOptions()
            .then(setDimensionOptions)
            .finally(() => setLoading(false));
    }, []);

    const handleDimSelected = useCallback(
        (dimOptions: ({ group: string; options: AllocDimOption[] } | AllocDimOption)[]) => {
            const [selected] = dimOptions;
            if (selected && 'type' in selected) {
                onChange(selected);
                setDimension(selected);
                closePicker();
            }
        },
        [onChange, setDimension]
    );

    return (
        <Box sx={{ minHeight: 0, flex: 1 }}>
            <Card px="lg" py="md" radius="md" sx={{ background: theme.colors.gray[2] }}>
                <Stack spacing="xs">
                    <Title order={5}>Allocation Dimension</Title>
                    <Text size="sm" color="dimmed">
                        The Showback tool uses a data point from your invoice to identify your organization's business units. This is known as the
                        "allocation dimension."
                    </Text>
                    <Text size="sm" color="dimmed">
                        Select an allocation dimension from the list below.
                    </Text>

                    <Box sx={{ display: 'flex' }}>
                        <Popover opened={pickerOpen} onClose={closePicker} withinPortal shadow="md" offset={0}>
                            <Popover.Target>
                                <Input.Wrapper label="Name" required onClick={togglePicker}>
                                    <Input
                                        component="button"
                                        placeholder="Select..."
                                        rightSection={loading ? <Loader size="sm" /> : pickerOpen ? <ChevronUp /> : <ChevronDown />}
                                        sx={{ minWidth: 250, whiteSpace: 'nowrap' }}
                                    >
                                        {!dimension ? '' : showbackSvc.getDimensionTypeName(dimension.field, dimension.type)}
                                    </Input>
                                </Input.Wrapper>
                            </Popover.Target>
                            <Popover.Dropdown p={0}>
                                <Picker
                                    items={dimensionOptions}
                                    onChange={handleDimSelected}
                                    isSelectable={(o) => !('group' in o)}
                                    selections={[]}
                                    nameAccessor={(d) => ('group' in d ? d.group : showbackSvc.getDimensionTypeName(d.field, d.type))}
                                    childAccessor={(d) => ('group' in d ? d.options : undefined)}
                                    renderItem={(d) =>
                                        'group' in d ? d.group : <VisibleSpaces value={showbackSvc.getDimensionTypeName(d.field, d.type)} />
                                    }
                                    minimizeHeight
                                    height={300}
                                    width={400}
                                    mode="single"
                                />
                            </Popover.Dropdown>
                        </Popover>
                    </Box>
                </Stack>
            </Card>
        </Box>
    );
}

interface IMonthPickerProps {
    month: Date;
    onChange: (month: Date) => void;
    months: Date[];
}
export function ShowbackMonthPicker({ month, onChange, months }: IMonthPickerProps) {
    const fmtSvc = useDi(FormatService);
    const [opened, { toggle, close }] = useDisclosure(false);
    const handleChange = useCallback(
        (selected: Date[]) => {
            onChange(selected[0]);
            close();
        },
        [close, onChange]
    );
    const selected = useMemo(() => [month], [month]);

    return (
        <Popover opened={opened} onClose={close} withinPortal offset={0}>
            <Popover.Target>
                <Button
                    variant="outline"
                    size="sm"
                    onClick={toggle}
                    leftIcon={<Calendar size={20} />}
                    rightIcon={opened ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                >
                    <Text sx={{ minWidth: 120 }}>{fmtSvc.formatLongMonthYear(month)}</Text>
                </Button>
            </Popover.Target>
            <Popover.Dropdown p={0}>
                <Picker
                    items={months}
                    onChange={handleChange}
                    selections={selected}
                    nameAccessor={(m) => fmtSvc.formatLongMonthYear(m)}
                    minimizeHeight
                    height={300}
                    width={200}
                    mode="single"
                    noFilter
                />
            </Popover.Dropdown>
        </Popover>
    );
}

export const InputSection = styled.div`
    background-color: ${(p) => p.theme.colors.gray[2]};
    border-radius: 5px;
    padding: ${(p) => p.theme.spacing.md}px;
    margin: ${(p) => p.theme.spacing.md}px;
`;

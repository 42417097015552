import { Company } from '@apis/Customers/model';
import { DashboardPersistenceService, IDashboardConfigBase } from '@root/Components/DashboardPersistence/DashboardPersistenceService';
import { CompanyTenantPrereqService } from '@root/Components/Router/CompanyContent';
import { BaseCacheByTenant } from '@root/Services/Customers/BaseCacheByTenant';
import { ICompanyContextToken } from '@root/Services/Customers/CompanyContext';
import { useDiContainer } from '@root/Services/DI';
import { EventEmitter } from '@root/Services/EventEmitter';
import { useMemo } from 'react';
import { singleton, inject, injectable } from 'tsyringe';

export function usePinningModel(configKey: string, groupPriority: string[], defaultPinned: PropertyPinLookup): IPropertyPinningModel {
    const container = useDiContainer();
    return useMemo(
        () => container.resolve(PropertyPinningModel).init(configKey, defaultPinned, groupPriority),
        [JSON.stringify([configKey, groupPriority])]
    );
}

type PinSetting = string | { omit: string };
type PropertyPinLookup = { [group: string]: PinSetting[] };
@singleton()
class PropertyPinningService extends BaseCacheByTenant<PropertyPinLookup> {
    public constructor(
        @inject(DashboardPersistenceService) private readonly dashboardSvc: DashboardPersistenceService,
        @inject(CompanyTenantPrereqService) tenantPrereqSvc: CompanyTenantPrereqService
    ) {
        super(tenantPrereqSvc);
    }

    public async getPinLookup(tenantId: number, configKey: string, defaultPinned: PropertyPinLookup) {
        const result = await this.get(tenantId, async () => {
            const { pinLookup: result } = await this.getLatest(configKey, defaultPinned);
            return result;
        });
        this.applyChanges(result, defaultPinned, false);
        return result;
    }

    public async savePinLookup(tenantId: number, configKey: string, defaultPinned: PropertyPinLookup, pinLookup: PropertyPinLookup) {
        this.invalidate(tenantId);
        const { pinLookup: latest, id } = await this.getLatest(configKey, defaultPinned);
        this.applyChanges(latest, pinLookup, true);
        await this.dashboardSvc.save(configKey, id, { pinLookup: latest, name: 'Default' } as IDashboardConfigBase);
    }

    private async getLatest(configKey: string, defaultPinned: PropertyPinLookup) {
        const cfgs = await this.dashboardSvc.getLayouts<{ pinLookup: PropertyPinLookup } & IDashboardConfigBase>(configKey);
        const cfg = cfgs?.[0];
        return { pinLookup: cfg?.layout?.pinLookup ?? defaultPinned, id: cfg?.id };
    }

    private applyChanges(pinLookup: PropertyPinLookup, pins: PropertyPinLookup, overwrite: boolean) {
        for (const key of Object.keys(pins)) {
            if (overwrite || !(key in pinLookup)) {
                pinLookup[key] = pins[key];
            }
        }
    }
}

export interface IPropertyPinningModel {
    isPinned(field: string): boolean;
    getGroupsForPin(field: string): Set<string>;
    getPinnedFields(): string[];
    pin(field: string, toGroup: string): void;
    unpin(field: string, fromGroup: string): void;
    getGroupPriority(): ReadonlyArray<string>;
    readonly changed: EventEmitter<void>;
    readonly loading: EventEmitter<boolean>;
}

@injectable()
class PropertyPinningModel implements IPropertyPinningModel {
    public readonly loading = new EventEmitter<boolean>(true);
    public readonly changed = EventEmitter.empty();
    private pinLookup?: PinGroup;
    private groupPriority: string[] = [];
    private configKey = '';

    public constructor(
        @inject(PropertyPinningService) private readonly pinningSvc: PropertyPinningService,
        @inject(ICompanyContextToken) private readonly company: Company
    ) {}

    public init(configKey: string, defaultPinned: PropertyPinLookup, groupPriority: string[]) {
        this.groupPriority = groupPriority;
        this.configKey = configKey;
        this.load(configKey, defaultPinned);
        return this;
    }

    private async load(configKey: string, defaultPinned: PropertyPinLookup) {
        this.loading.emit(true);
        try {
            const pinLookup = await this.pinningSvc.getPinLookup(this.company.Id ?? 0, configKey, defaultPinned);
            this.pinLookup = PinGroup.create(this.groupPriority, { ...pinLookup });
        } finally {
            this.loading.emit(false);
        }
    }

    public getGroupPriority() {
        return this.groupPriority;
    }
    public isPinned(field: string) {
        return this.pinLookup?.isIncluded(field) ?? false;
    }
    public getGroupsForPin(field: string) {
        return this.pinLookup?.inclusionGroups(field) ?? new Set<string>();
    }

    public getPinnedFields() {
        return this.pinLookup?.getFields() ?? [];
    }

    public pin(field: string, toGroup: string) {
        this.pinLookup?.include(field, toGroup);
        this.saveChanges();
        this.changed.emit();
    }

    public unpin(field: string, fromGroup: string) {
        this.pinLookup?.exclude(field, fromGroup);
        this.saveChanges();
        this.changed.emit();
    }

    private saveChanges() {
        if (this.pinLookup) {
            this.pinningSvc.savePinLookup(this.company.Id ?? 0, this.configKey, {}, this.pinLookup.rawLookup);
        }
    }
}

class PinGroup {
    private readonly inclusions = new Set<string>();
    private readonly exclusions = new Map<string, { omit: string }>();
    private readonly pinSettings: PinSetting[];
    private prev?: PinGroup;
    private next?: PinGroup;
    private get head(): PinGroup {
        return this?.prev?.head ?? this;
    }

    public constructor(public readonly group: string, public readonly rawLookup: PropertyPinLookup, prev?: PinGroup) {
        this.pinSettings = rawLookup[group] ??= [];
        for (const setting of this.pinSettings) {
            if (typeof setting === 'string') {
                this.inclusions.add(setting);
            } else {
                this.exclusions.set(setting.omit, setting);
            }
        }
        this.prev = prev;
        if (prev) {
            prev.next = this;
        }
    }

    public static create(groupPriority: string[], pinLookup: PropertyPinLookup) {
        let prev: PinGroup | undefined;
        for (const group of groupPriority) {
            prev = new PinGroup(group, pinLookup, prev);
        }
        return prev?.head;
    }

    public isIncluded(field: string) {
        return this.inclusions.has(field);
    }
    public inclusionGroups(field: string) {
        return this.collectInclusionGroups(field);
    }
    public isExcluded(field: string) {
        return this.exclusions.has(field);
    }
    public include(field: string, atGroup?: string) {
        atGroup ??= this.group;
        if (atGroup !== this.group) {
            this.next?.include(field, atGroup);
        } else {
            const exclusion = this.exclusions.get(field);
            if (exclusion) {
                this.removeSetting(exclusion);
            }
            if (!this.inclusions.has(field)) {
                this.addSetting(field);
            }
            this.removeNextExclusion(field);
        }
    }
    public exclude(field: string, atGroup?: string) {
        atGroup ??= this.group;
        if (atGroup !== this.group) {
            this.next?.exclude(field, atGroup);
        } else {
            if (this.inclusions.has(field)) {
                this.removeSetting(field);
            }
            if (this.isInherited(field)) {
                this.addSetting({ omit: field });
            }
            this.removeNextInclusion(field);
        }
    }
    public getFields() {
        return this.collectIncludedFields();
    }

    private isInherited(field: string): boolean {
        return !!this.prev && (this.prev.isExcluded(field) || this.prev.isInherited(field));
    }
    private removeExclusion(field: string) {
        if (this.exclusions.has(field)) {
            this.removeSetting(this.exclusions.get(field)!);
        }
    }
    private removeInclusion(field: string) {
        if (this.inclusions.has(field)) {
            this.removeSetting(field);
        }
    }
    private removeNextInclusion(field: string) {
        if (this.next) {
            this.next.removeInclusion(field);
            this.next.removeNextExclusion(field);
        }
    }
    private removeNextExclusion(field: string) {
        if (this.next) {
            this.next.removeExclusion(field);
            this.next.removeNextExclusion(field);
        }
    }
    private addSetting(setting: PinSetting) {
        if (typeof setting === 'string') {
            this.inclusions.add(setting);
        } else {
            this.exclusions.set(setting.omit, setting);
        }
        this.pinSettings.push(setting);
    }
    private removeSetting(setting: PinSetting) {
        if (typeof setting === 'string') {
            this.inclusions.delete(setting);
        } else {
            this.exclusions.delete(setting.omit);
        }
        this.pinSettings.splice(this.pinSettings.indexOf(setting), 1);
    }
    private collectInclusionGroups(field: string) {
        const result = new Set<string>();
        let current: PinGroup | undefined = this.head;
        while (current) {
            if (current.isIncluded(field)) {
                result.add(current.group);
            }
            current = current.next;
        }
        return result;
    }
    private collectIncludedFields() {
        const result: string[] = [];
        const foundFields = new Set<string>();
        let current: PinGroup | undefined = this.head;
        while (current) {
            for (const setting of current.pinSettings) {
                const isOmission = typeof setting !== 'string';
                const field = isOmission ? setting.omit : setting;
                const included = foundFields.has(field);
                if (isOmission && included) {
                    foundFields.delete(field);
                    result.splice(result.indexOf(field), 1);
                } else if (!isOmission && !included) {
                    foundFields.add(field);
                    result.push(field);
                }
            }
            current = current.next;
        }
        return result;
    }
}

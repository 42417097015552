import { observer } from 'mobx-react';
import { AllocationRuleEditor } from '../Model';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AllocationSources } from '../AllocationSources';
import { DisbursementTargetPicker } from '../DisbursementTargetPicker';
import { LineItemFundSource } from '../../Models';
import { IMiniDataGridProps, MiniDataGridItem } from '../MiniDataGrid';
import { BaseAllocationRuleModel } from './BaseAllocationRuleModel';
import { exprBuilder, queryBuilder } from '@root/Services/QueryExpr';
import { IInvoiceRollup } from '@root/Services/Invoices/InvoiceSchemaService';
import { Button, Drawer, Group, Space } from '@mantine/core';
import { useDi, useDiMemo } from '@root/Services/DI';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { DataColumnConfig } from '@root/Components/DataGrid/Models';
import { FundSource, FundSourceDefinition } from '@apis/Invoices/model';
import { DataGrid } from '@root/Components/DataGrid';
import { FormatService } from '@root/Services/FormatService';
import { SidePanel, useSidePanelOpener } from '@root/Design/SidePanel';

export const MarketplaceRuleCard = observer(function MarketplaceRuleCard({ ruleEditor }: { ruleEditor: AllocationRuleEditor }) {
    const fmtSvc = useDi(FormatService);
    const invoiceApi = useDiMemo(InvoiceApiService);
    const model = useMemo(() => new MarketplaceEditorModel(ruleEditor), [ruleEditor.rule]);
    const sourcePicker = useSidePanelOpener();

    //Get Selected Month
    const selectedMonth = ruleEditor.month ?? new Date();
    const startOfMonth = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1);
    const endOfMonth = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0);
    const dateRange = useMemo(() => ({ from: startOfMonth, to: endOfMonth }), []);

    //Selection Code
    const [selectionFilters, setSelectionFilters] = useState<string[]>(model.getMarketplaceGridItems().map((v) => v.getName()));

    const handleSelectionChanged = useCallback(async ({ getItems }: { getItems: () => Promise<{ productName: string; cost: number }[]> }) => {
        const selections = await getItems();
        setSelectionFilters(selections.map((v) => v.productName));
    }, []);

    const addSourceButtons = useMemo(
        () => [
            {
                label: 'Add Allocation Source',
                onClick: () => {
                    sourcePicker.open();
                },
            },
        ],
        []
    );

    const addDisbursementButtons = useMemo(
        () => [
            {
                label: 'Add Filters',
                onClick: () => {
                    // disbursement targets is probably a common component
                },
            },
        ],
        []
    );

    const handleSave = useCallback(() => {
        model.addMarketplaceCharge(selectionFilters ?? []);
        ruleEditor.setFundSource({ Sources: model.getSources() } as FundSourceDefinition);
        sourcePicker.close();
    }, [selectionFilters]);

    const sourceListProps = {
        nameHeader: 'Description',
        amountHeader: 'Amount',
    } as IMiniDataGridProps;

    const [marketplaceOptions, setMarketplaceOptions] = useState<{ productName: string; cost: number }[]>([]);
    const sources = useCallback(
        () => model.getMarketplaceAllocationSources(marketplaceOptions),
        [marketplaceOptions, JSON.stringify(model.getProductNames())]
    );
    useEffect(() => {
        model.getMarketplaceOptions(invoiceApi).then(setMarketplaceOptions);
    }, [selectedMonth]);
    const gridColumns = useMemo(
        () =>
            [
                {
                    accessor: 'productName',
                    defaultWidth: 200,
                    id: 'productName',
                    header: 'Product Name',
                    type: 'string',
                },
                {
                    accessor: 'cost',
                    defaultWidth: 150,
                    id: 'cost',
                    header: 'Amount',
                    type: 'number',
                    formatter: (v) => fmtSvc.formatMoneyNonZeroTwoDecimals(v.cost),
                    align: 'right',
                },
            ] as DataColumnConfig<{ productName: string; cost: number }>[],
        []
    );

    return (
        <>
            <AllocationSources
                gridProps={sourceListProps}
                gridData={sources}
                buttons={addSourceButtons}
                sourceChanged={model.sourcesChanged}
                title="Marketplace"
                helpText="Add Marketplace costs that need to be disbursed to. "
            />
            <DisbursementTargetPicker ruleEditor={ruleEditor} buttons={addDisbursementButtons} />
            <SidePanel
                opener={sourcePicker}
                title="Allocation Source"
                size={500}
                toolbar={
                    <>
                        <Button onClick={sourcePicker.close} variant="outline">
                            Cancel
                        </Button>
                        <Button color="primary" onClick={handleSave}>
                            Save
                        </Button>
                    </>
                }
            >
                {() => (
                    <DataGrid
                        dataSource={marketplaceOptions}
                        columns={gridColumns}
                        onRowClick="select"
                        selectionMode="multiple"
                        onSelectedChanged={handleSelectionChanged}
                        initialSelection={marketplaceOptions.filter((v) => selectionFilters.includes(v.productName))}
                        hideFilter
                        hideColumnSelector
                        hideGlobalSearch
                        hideHeader
                        hideMenu
                    />
                )}
            </SidePanel>
        </>
    );
});

class MarketplaceEditorModel extends BaseAllocationRuleModel {
    public constructor(ruleEditor: AllocationRuleEditor) {
        super(ruleEditor);
    }

    public getSource(): FundSource {
        let source = this.getSources()[0];
        if (!source) {
            const lineItemSource = this.createFundSource('LineItems') as LineItemFundSource;

            this.addSource(lineItemSource as FundSource);
        }
        return source;
    }

    public getProductNames() {
        const source = this.getSource();
        return (source.PresentationOptions?.['productNames'] ?? []) as string[];
    }

    public setProductNames(productNames: string[]) {
        const source = this.getSource();
        source.PresentationOptions = { productNames };
        this.updateFilters();
        this.sourcesChanged.emit();
    }

    public addMarketplaceCharge(productNames: string[]) {
        this.setProductNames(productNames);
    }

    private updateFilters() {
        const { allocDim, showbackSvc } = this.ruleEditor;
        const { chargeMarketplace } = showbackSvc.getMetricCriteria(allocDim);
        this.getSource().Filters = {
            InclusionRules: [
                {
                    Name: 'Marketplace Charges',
                    Filter: {
                        Operation: 'and',
                        Operands: [this.getProductNameFilters(), chargeMarketplace],
                    },
                },
            ],
        };
    }

    private getProductNameFilters() {
        return { Operation: 'eq', Operands: [{ Field: 'product/ProductName' }, { Value: this.getProductNames() }] };
    }

    public getMarketplaceOptions(invoiceApi: InvoiceApiService) {
        const { allocDim, month, showbackSvc } = this.ruleEditor;

        const { chargeMarketplace } = showbackSvc.getMetricCriteria(allocDim);
        return queryBuilder<IInvoiceRollup>()
            .where((b) => b.fromExpr<boolean>(chargeMarketplace))
            .select((b) => ({ productName: b.model['product/ProductName']!, cost: b.sum(b.model['lineItem/UnblendedCost']!) }))
            .execute((q) => showbackSvc.invoiceApi.queryMonthlyRollup(q, [month]))
            .then((r) => (r.Results ?? []).sort((a, b) => a.productName.localeCompare(b.productName)));
    }

    public getMarketplaceGridItems() {
        return this.getSourceGridItems((s) => s.Name ?? '');
    }

    public getMarketplaceAllocationSources(options: { productName: string; cost: number }[]) {
        const productNameLookup = new Set(this.getProductNames());
        return options
            .filter((o) => productNameLookup.has(o.productName))
            .map(
                (m) =>
                    ({
                        getAmount: () => m.cost,
                        getName: () => m.productName,
                        onChanged: this.sourcesChanged,
                        remove: () => this.removeProductName(m.productName),
                    } as MiniDataGridItem)
            );
    }

    private removeProductName(productName: string) {
        this.setProductNames(this.getProductNames().filter((p) => p !== productName));
    }
}

import { CostForecastRequested } from '@apis/Invoices/model';
import { getJobJobId, postJobCompanyQuery, postJobQuery } from '@apis/Jobs';
import { AnonymousQueueJobJob, AnonymousQueueJobJobStatus } from '@apis/Jobs/model';
import { AnonymousJob } from '@apis/Resources';
import { CheckAwsResources, TagResourcesJob } from '@apis/Resources/model';
import { inject, Lifecycle, scoped, singleton } from 'tsyringe';
import { EventEmitter } from '../EventEmitter';
import { IDateFluentOperators, IFluentOperators, IFluentQueryAdapter, ISearchBuilder, queryBuilder } from '../QueryExpr';
import { PollingPromise, PollingService } from './PollingService';

export type JobHierarchyProgress = { [key in AnonymousQueueJobJobStatus]: number } & { lastDate?: number };
export type JobHierarchyIncProgress = JobHierarchyProgress & { IncProgress: number; IncTotal: number; Count: number; HierarchyId: string };

('Cloudsaver.Resources.Domain.Models.TagResourcesJob');
declare module '@apis/Resources' {
    export type TJob<TParams, TType> = Exclude<AnonymousQueueJobJob, 'Parameters'> & {
        Parameters: TParams;
        Type: TType;
    };
    export const TagResourcesJobType = 'Cloudsaver.Resources.Domain.Models.TagResourcesJob';
    export type JobOfTagResources = TJob<TagResourcesJob, typeof TagResourcesJobType>;
    export const CheckAwsResourcesType = 'Cloudsaver.Resources.Domain.Aws.Models.CheckAwsResources';
    export type JobOfCheckAwsResources = TJob<CheckAwsResources, typeof CheckAwsResourcesType>;

    export const CostForecastRequestedType = 'Cloudsaver.InvoiceIngestion.Application.Models.CostForecastRequested';
    export type JobOfCostForecast = TJob<CostForecastRequested, 'Cloudsaver.InvoiceIngestion.Application.Models.CostForecastRequested'> & {
        ResultDetail?: { ForecastRequestJobId: string; Records: number; Cardinality: number };
    };

    export type JobOfCostForecastRequestFulfilled = TJob<
        { Cardinality: number },
        'Cloudsaver.InvoiceIngestion.Application.Models.CostForecastRequestFulfilled'
    >;

    type JobTypes = typeof TagResourcesJobType | typeof CheckAwsResourcesType;

    export type Job = JobOfTagResources | JobOfCheckAwsResources;
    export type AnonymousJob = TJob<unknown, string>;
}

export type StatusInfo = { inProgress: boolean; hasErrors: boolean; total: number; progress: number };
@singleton()
export class JobService {
    public newJobStarted = new EventEmitter<Omit<AnonymousQueueJobJob, 'Parameters'> | void>(void 0);

    public constructor(@inject(PollingService) private readonly pollingSvc: PollingService) {}

    public notifyNewJob(job: Omit<AnonymousQueueJobJob, 'Parameters'> | void) {
        this.newJobStarted.emit(job);
    }

    public async getJob(jobId: string) {
        const job = await this.getJobById(jobId);
        return job;
    }

    public getStatusInfo(job: JobHierarchyProgress): StatusInfo {
        const finished = (job.Failed || 0) + (job.Succeeded || 0);
        const unfinished = (job.Created || 0) + (job.Started || 0);
        const total = unfinished + finished;
        return {
            inProgress: !!job.Created || !!job.Started,
            hasErrors: !!job.Failed,
            total,
            progress: finished / (total || 1),
        };
    }

    public async waitForJobHierarchyByJobId(
        jobId: string,
        frequency: number = 500
    ): Promise<{ poller: PollingPromise<JobHierarchyProgress | undefined> }> {
        const job = await this.getJobById(jobId);
        if (!job) {
            throw new Error(`Job with job ID ${jobId} does not exist`);
        }

        return this.waitForJobHierarchy(job.HierarchyId!, frequency);
    }
    public async waitForJobHierarchy(
        hierarchyId: string,
        frequency: number = 500
    ): Promise<{ poller: PollingPromise<JobHierarchyProgress | undefined> }> {
        return {
            poller: this.pollingSvc.pollUntil(
                () => this.getHierarchyProgress(hierarchyId),
                (r) => (!r?.Created && !r?.Started) || !r,
                frequency
            ),
        };
    }

    public async getRootJobs(max: number, maxAge: Date, minAge?: Date, userId?: number, includeSyncJobs?: boolean, type?: string) {
        const result = await queryBuilder<AnonymousQueueJobJob>()
            .where((b) => {
                const criteria = [b.model.ParentId!.isNull(), (b.model.QueuedAt as unknown as IDateFluentOperators).after(maxAge)];
                if (minAge) {
                    criteria.push((b.model.QueuedAt as unknown as IDateFluentOperators).onOrBefore(minAge));
                }
                if (userId) {
                    criteria.push(b.model.RequestedById!.eq(userId));
                }
                if (!includeSyncJobs) {
                    criteria.push(b.model.Synchronous!.ne(true));
                }
                if (type) {
                    criteria.push(b.model.Type!.eq(type));
                }
                return b.and(...criteria);
            })
            .take(max)
            .sortDesc((b) => b.model.QueuedAt)
            .execute(postJobCompanyQuery);

        return result.Results ?? [];
    }

    public async getHierarchyProgress(
        hierarchyId: string,
        criteria?: (builder: IFluentQueryAdapter<AnonymousQueueJobJob>) => IFluentOperators<boolean>
    ) {
        const lookup = await this.getHierarchyProgressLookup([hierarchyId], criteria);
        return lookup.get(hierarchyId);
    }

    /**
     * Get hierarchy status rollup, including incremental progress
     * @param hierarchyIds
     * @param criteria
     * @returns
     */
    public async getHierarchyProgressInc(
        hierarchyIds: string[],
        criteria?: (builder: IFluentQueryAdapter<AnonymousQueueJobJob>) => IFluentOperators<boolean>
    ): Promise<JobHierarchyIncProgress[] | null | undefined> {
        const response = await queryBuilder<
            AnonymousQueueJobJob & { ['Progress.Progress']: number; ['Progress.Total']: number; ['Progress.UpdatedAt']: string }
        >()
            .where((b) => {
                const crit = [b.model.HierarchyId!.eq(hierarchyIds)];
                if (criteria) {
                    crit.push(criteria(b));
                }
                return b.and(...crit);
            })
            .select((b) => ({
                HierarchyId: b.model.HierarchyId,
                Created: b.countIf(b.model.Status?.eq('Created')),
                Failed: b.countIf(b.model.Status?.eq('Failed')),
                Started: b.countIf(b.model.Status?.eq('Started')),
                Succeeded: b.countIf(b.model.Status?.eq('Succeeded')),
                Canceled: b.countIf(b.model.Status?.eq('Canceled')),
                MaxDate: b.max(b.model.FinishedAt) as unknown as number | undefined | null,
                Count: b.count(),
                IncProgress: b.sum(b.model['Progress.Progress']),
                IncTotal: b.sum(b.model['Progress.Total']),
            }))
            .execute(postJobCompanyQuery);

        return response?.Results;
    }

    public async getHierarchyProgressLookup(
        hierarchyIds: string[],
        criteria?: (builder: IFluentQueryAdapter<AnonymousQueueJobJob>) => IFluentOperators<boolean>
    ) {
        const progress = await this.getRawHierarchyProgress(hierarchyIds, criteria);
        return progress.reduce((result, current) => {
            let item = result.get(current.HierarchyId);
            if (!item) {
                result.set(current.HierarchyId, (item = { Created: 0, Failed: 0, Started: 0, Succeeded: 0, Canceled: 0 }));
            }
            item[current.Status as AnonymousQueueJobJobStatus] = current.Count;
            if (current.MaxDate && (!item.lastDate || current.MaxDate > item.lastDate)) {
                item.lastDate = current.MaxDate;
            }
            return result;
        }, new Map<string, JobHierarchyProgress>());
    }

    private async getRawHierarchyProgress(
        hierarchyIds: string[],
        criteria?: (builder: IFluentQueryAdapter<AnonymousQueueJobJob>) => IFluentOperators<boolean>
    ) {
        const response = await queryBuilder<AnonymousQueueJobJob>()
            .where((b) => {
                const crit = [b.model.HierarchyId!.eq(hierarchyIds)];
                if (criteria) {
                    crit.push(criteria(b));
                }
                return b.and(...crit);
            })
            .select((b) => ({
                HierarchyId: b.model.HierarchyId,
                Status: b.model.Status,
                MaxDate: b.max(b.model.FinishedAt) as unknown as number | undefined | null,
                Count: b.count(),
            }))
            .execute(postJobCompanyQuery);
        return response?.Results ?? [];
    }

    public async getJobById(jobId: string) {
        const response = await queryBuilder<AnonymousQueueJobJob>()
            .where((b) => b.and(b.model.Id!.eq(jobId)))
            .take(1)
            .execute(postJobCompanyQuery);

        return response?.Results?.[0];
    }

    public async queryJobs(queryHandler: (qb: ISearchBuilder<AnonymousQueueJobJob>) => ISearchBuilder<AnonymousQueueJobJob>) {
        let builder = queryBuilder<AnonymousQueueJobJob>();
        if (queryHandler) {
            builder = queryHandler(builder);
        }
        const response = await builder.execute(postJobCompanyQuery);

        return response?.Results ?? [];
    }
}

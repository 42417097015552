import { getUserPingActivity } from '@apis/Customers';
import { singleton } from 'tsyringe';

@singleton()
export class UsageReportingService {
    private readonly pingInterval = 1000 * 20;
    private initialized = false;
    public init() {
        if (!this.initialized) {
            this.initialized = true;
            this.startPing();
        }
    }

    private async startPing() {
        const ping = async () => {
            try {
                if (!document.visibilityState || document.visibilityState === 'visible') {
                    getUserPingActivity();
                }
            } finally {
                setTimeout(ping, this.pingInterval);
            }
        };
        setTimeout(ping, this.pingInterval);
    }

    public ping() {
        getUserPingActivity();
    }
}

import { getConnectionTryAssumeRole } from '@apis/Resources';
import { GetConnectionScriptsCsCloudformationYmlPlatform, GetConnectionScriptsCsCloudformationYmlProductsItem } from '@apis/Resources/model';
import { ConfigService } from '@root/Services/ConfigService';
import { CspConnectionService } from '@root/Services/Connections/CspConnectionService';
import { EventEmitter } from '@root/Services/EventEmitter';
import { inject, injectable } from 'tsyringe';
import { v4 as uuidv4 } from 'uuid';

export enum LoadingState {
    loading,
    success,
    failed,
    check,
}

@injectable()
export class IacConnector {
    private externalId = uuidv4();
    private accountId = '';
    private pollerTimeout: any = 0;
    private polling = true;
    public status = new EventEmitter<'none' | 'connecting' | 'connected'>('none');

    public constructor(
        @inject(ConfigService) private readonly configSvc: ConfigService,
        @inject(CspConnectionService) private readonly cspConnSvc: CspConnectionService
    ) {}

    public updateAccount(accountId: string, externalId: string) {
        this.accountId = accountId;
        this.externalId = externalId;
        if (this.isAccountValid()) {
            this.polling = true;
            this.pollConnection();
        } else {
            this.stopPolling();
        }
    }
    public dispose() {
        this.stopPolling();
    }
    public isAccountValid() {
        return this.accountId.length === 12;
    }
    public async pollConnection() {
        let connected = false;
        this.status.emit('connecting');
        try {
            const { Success } = await getConnectionTryAssumeRole({ awsAccountId: this.accountId, externalId: this.externalId });
            if (Success) {
                connected = true;
                this.status.emit('connected');
                this.cspConnSvc.connectionStateChanged.emit();
            }
        } finally {
            if (!connected && this.polling) {
                this.pollerTimeout = setTimeout(() => this.pollConnection(), 3000);
            }
        }
    }
    public getDownloadUrl(file: 'cs-cloudformation.yml' | 'cs-terraform.tf' | 'update-cs-cloudformation.yml' | 'update-cs-terraform.tf') {
        return `${this.configSvc.config.apis.Resources.baseUrl}/connection/scripts/${file}?accountid=${this.accountId}&externalId=${this.externalId}&platform=${GetConnectionScriptsCsCloudformationYmlPlatform.Aws}&products=${GetConnectionScriptsCsCloudformationYmlProductsItem.TagManager}&products=${GetConnectionScriptsCsCloudformationYmlProductsItem.RightSizing}`;
    }
    public stopPolling() {
        clearTimeout(this.pollerTimeout);
        this.polling = false;
        this.status.emit('none');
    }
    public getStatusMessage() {
        return this.status.value === 'connecting' ? 'Awaiting connection' : this.status.value === 'connected' ? 'Connection successful' : '';
    }
    public getLoadingState() {
        return this.status.value === 'connecting' ? LoadingState.loading : this.status.value === 'connected' ? LoadingState.check : null;
    }
    public isConnected() {
        return this.status.value === 'connected';
    }
}

import { postResourceSnapshotQuerySnapshots, postResourcesQuery } from '@apis/Resources';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useEffect, useMemo, useState } from 'react';
import { CustomizableDashboard, DashboardAddOption } from '@root/Components/DashboardLayout/CustomizableDashboard';
import { IQueryExpr, Query } from '@apis/Resources/model';
import { ChartDashboardItem } from '@root/Components/DashboardLayout/ChartDashboardItem';
import { IDashboardConfig, IDashboardItemType, Layout, QueryDatasource } from '@root/Components/DashboardLayout/Models';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { useDi } from '@root/Services/DI';
import { ResourceSchemaProvider } from '@root/Services/Resources/ResourceService';
import { useNav } from '@root/Services/NavigationService';
import { GaugeChartSettings } from '@root/Components/Charts/GaugeChart';
import { PieChartSettings } from '@root/Components/Charts/PieChart';
import { BarChartSettings } from '@root/Components/Charts/BarChart';
import { IRouteMeta, IRouteMetaToken } from '@root/Services/Router/BasicRouteLoader';
import { LineChartSettings } from '@root/Components/Charts/LineChart';
import { InitialSyncCheck } from '@root/Components/Resources/IntialSyncCheck';
import { ChartConfig } from '@root/Components/DashboardLayout/Charts/ChartRenderer';
import { IdleResourcesService } from '../../../Services/Resources/IdleResourcesService';
import { IdleResourcesPage } from './IdleResourcesPage';
import { withAppFeatureCheck } from '@root/Components/Shell/AppFeatureAccess';
import { AppFeatureNames } from '@root/Services/Customers/CompanyFeatureService';

export function IdleDashboardContent({ dashboard }: { dashboard?: string }) {
    const route = useDi(IRouteMetaToken) as IRouteMeta;
    const nav = useNav();
    const company = useCompany()!;

    const resourceSchema = useDi(ResourceSchemaProvider);
    const idleResourceService = useDi(IdleResourcesService);
    const [loading, setLoading] = useState(true);
    const [idleEc2Query, setIdleEc2Query] = useState<IQueryExpr>(idleResourceService.getDefaultQuery('EC2 On Demand'));
    const [idleRdsQuery, setIdleRdsQuery] = useState<IQueryExpr>(idleResourceService.getDefaultQuery('RDS'));
    const [idleElbQuery, setIdleElbQuery] = useState<IQueryExpr>(idleResourceService.getDefaultQuery('ELB'));
    const [idleEMRQuery, setIdleEMRQuery] = useState<IQueryExpr>(idleResourceService.getDefaultQuery('EMR'));
    const [idleRedshiftQuery, setIdleRedshiftQuery] = useState<IQueryExpr>(idleResourceService.getDefaultQuery('Redshift'));
    const [stoppedResourceQuery, setStoppedResourceQuery] = useState<IQueryExpr>(idleResourceService.getStoppedResourcesQuery());
    var today = new Date();
    //Set default start date to 30 days ago
    var defaultStartDate = new Date().setMonth(today.getMonth() - 3);
    useEffect(() => {
        (async () => {
            await idleResourceService.getResourceTypeQuery('EMR', true).then((results) => setIdleEMRQuery(results!));
            await idleResourceService.getResourceTypeQuery('ELB', true).then((results) => setIdleElbQuery(results!));
            await idleResourceService.getStoppedResourcesQuery().then((results) => setStoppedResourceQuery(results!));

            const baseIdleEc2Query = await idleResourceService.getResourceTypeQuery('EC2 On Demand', true);
            const baseIdleRdsQuery = await idleResourceService.getResourceTypeQuery('RDS', true);
            const baseIdleRedshiftQuery = await idleResourceService.getResourceTypeQuery('Redshift', true);

            //Exclude Stopped EC2, RDS, and Redshift from "base idle resources queries"
            setIdleEc2Query({
                Operation: 'and',
                Operands: [
                    baseIdleEc2Query!,
                    {
                        Operation: 'ne',
                        Operands: [{ Field: 'RunningState.Value' }, { Value: 'stopped' }],
                    },
                ],
            } as IQueryExpr);

            setIdleRdsQuery({
                Operation: 'and',
                Operands: [
                    baseIdleRdsQuery!,
                    {
                        Operation: 'ne',
                        Operands: [{ Field: 'ActivityStreamStatus.Value' }, { Value: 'stopped' }],
                    },
                ],
            } as IQueryExpr);

            setIdleRedshiftQuery({
                Operation: 'and',
                Operands: [
                    baseIdleRedshiftQuery!,
                    {
                        Operation: 'ne',
                        Operands: [{ Field: 'ClusterStatus' }, { Value: 'paused' }],
                    },
                ],
            } as IQueryExpr);

            setLoading(false);
        })();
    }, []);

    const datasource = useMemo(
        () =>
            [
                {
                    name: 'resources',
                    source: (query: Query) => postResourcesQuery(query, { companyId: company.Id }),
                    schema: resourceSchema,
                    getValueProviderFactory: resourceSchema.createValueProviderFactory(),
                    getDefaultGroup: () => ({ Expr: { Field: 'Region' }, Alias: 'Region' }),
                    getDefaultValue: () => ({ Expr: { Operation: 'count' }, Alias: 'Count' }),
                    getDefaultHistogram: () => ({ Expr: { Field: 'CreateTime' }, Alias: 'Create Time' }),
                },
                {
                    name: 'resourcesOverTime',
                    source: (query: Query) =>
                        postResourceSnapshotQuerySnapshots(query, { from: new Date(defaultStartDate).toDateString(), to: today.toDateString() }),
                    schema: resourceSchema,
                    getValueProviderFactory: resourceSchema.createValueProviderFactory(),
                    getDefaultGroup: () => ({ Expr: { Field: 'Region' }, Alias: 'Region' }),
                    getDefaultValue: () => ({ Expr: { Operation: 'count' }, Alias: 'Count' }),
                    getDefaultHistogram: () => ({ Expr: { Field: 'Date' }, Alias: 'Snapshot Date' }),
                },
            ] as QueryDatasource[],
        []
    );

    const itemTypes = useMemo(() => {
        const result = [ChartDashboardItem.itemType] as IDashboardItemType[];
        return result;
    }, []);

    const defaultAws = useMemo(() => {
        const result = [
            {
                x: 0,
                y: 0,
                h: 2,
                w: 6,
                data: {
                    type: 'chart',
                    chartType: 'kpi',
                    groups: [],
                    values: [
                        { Alias: 'ResourcesCount', Expr: { Operation: 'count', Operands: [] } },
                        { Alias: 'Cost', Expr: { Operation: 'sum', Operands: [{ Field: 'AnnualizedCost', Type: 'Money' }] } },
                    ],
                    filters: [idleEc2Query],
                    settings: {
                        labels: ['Resources Idle', 'Annualized Cost'],
                        format: [null, 'money'],
                    },
                    datasourceName: 'resources',
                    title: 'Idle EC2',
                },
            },
            {
                x: 0,
                y: 0,
                h: 2,
                w: 6,
                data: {
                    type: 'chart',
                    chartType: 'kpi',
                    groups: [],
                    values: [
                        { Alias: 'ResourcesCount', Expr: { Operation: 'count', Operands: [] } },
                        { Alias: 'Cost', Expr: { Operation: 'sum', Operands: [{ Field: 'AnnualizedCost', Type: 'Money' }] } },
                    ],
                    filters: [idleRdsQuery],
                    settings: {
                        labels: ['Resources Idle', 'Annualized Cost'],
                        format: [null, 'money'],
                    },
                    datasourceName: 'resources',
                    title: 'Idle RDS',
                },
            },
            {
                x: 0,
                y: 0,
                h: 2,
                w: 6,
                data: {
                    type: 'chart',
                    chartType: 'kpi',
                    groups: [],
                    values: [
                        { Alias: 'ResourcesCount', Expr: { Operation: 'count', Operands: [] } },
                        { Alias: 'Cost', Expr: { Operation: 'sum', Operands: [{ Field: 'AnnualizedCost', Type: 'Money' }] } },
                    ],
                    filters: [idleEMRQuery],
                    settings: {
                        labels: ['Resources Idle', 'Annualized Cost'],
                        format: [null, 'money'],
                    },
                    datasourceName: 'resources',
                    title: 'Idle EMR',
                },
            },
            {
                x: 0,
                y: 0,
                h: 2,
                w: 6,
                data: {
                    type: 'chart',
                    chartType: 'kpi',
                    groups: [],
                    values: [
                        { Alias: 'ResourcesCount', Expr: { Operation: 'count', Operands: [] } },
                        { Alias: 'Cost', Expr: { Operation: 'sum', Operands: [{ Field: 'AnnualizedCost', Type: 'Money' }] } },
                    ],
                    filters: [idleElbQuery],
                    settings: {
                        labels: ['Resources Idle', 'Annualized Cost'],
                        format: [null, 'money'],
                    },
                    datasourceName: 'resources',
                    title: 'Idle ELB',
                },
            },
            {
                x: 0,
                y: 0,
                h: 2,
                w: 6,
                data: {
                    type: 'chart',
                    chartType: 'kpi',
                    groups: [],
                    values: [
                        { Alias: 'ResourcesCount', Expr: { Operation: 'count', Operands: [] } },
                        { Alias: 'Cost', Expr: { Operation: 'sum', Operands: [{ Field: 'AnnualizedCost', Type: 'Base' }] } },
                    ],
                    filters: [idleRedshiftQuery],
                    settings: {
                        labels: ['Resources Idle', 'Annualized Cost'],
                        format: [null, 'money'],
                    },
                    datasourceName: 'resources',
                    title: 'Idle Redshift',
                },
            },
            {
                x: 6,
                y: 12,
                h: 3,
                w: 6,
                data: {
                    type: 'chart',
                    chartType: 'kpi',
                    groups: [],
                    values: [
                        { Alias: 'ResourcesCount', Expr: { Operation: 'count' } },
                        { Alias: 'Cost', Expr: { Operation: 'sum', Operands: [{ Field: 'RealizedSaving', Type: 'Base' }] } },
                    ],
                    filters: [stoppedResourceQuery],
                    settings: {
                        labels: ['Stopped Resources', 'Savings on Resources'],
                        format: [null, 'money'],
                    },
                    datasourceName: 'resources',
                    title: 'Realized Savings',
                },
            },
            {
                x: 6,
                y: 16,
                h: 6,
                w: 6,
                data: {
                    type: 'chart',
                    chartType: 'bar',
                    groups: [{ Alias: 'Resource Type', Expr: { Field: 'ResourceType', Type: 'Base' } }],
                    values: [{ Alias: 'Annualized Cost', Expr: { Operation: 'sum', Operands: [{ Field: 'IRSavingsAfterStop', Type: 'Base' }] } }],
                    filters: [
                        {
                            Operation: 'or',
                            Operands: [
                                {
                                    Operation: 'and',
                                    Operands: [
                                        { Operation: 'eq', Operands: [{ Field: 'ResourceType' }, { Value: 'EC2 On Demand' }] },
                                        { Operation: 'eq', Operands: [{ Field: 'RunningState.Value' }, { Value: 'stopped' }] },
                                    ],
                                },
                                {
                                    Operation: 'and',
                                    Operands: [
                                        { Operation: 'eq', Operands: [{ Field: 'ResourceType' }, { Value: 'RDS' }] },
                                        { Operation: 'eq', Operands: [{ Field: 'ActivityStreamStatus.Value' }, { Value: 'stopped' }] },
                                    ],
                                },
                                {
                                    Operation: 'and',
                                    Operands: [
                                        { Operation: 'eq', Operands: [{ Field: 'ResourceType' }, { Value: 'Redshift' }] },
                                        { Operation: 'eq', Operands: [{ Field: 'ClusterStatus' }, { Value: 'paused' }] },
                                    ],
                                },
                            ],
                        },
                    ],
                    settings: {
                        margin: { top: 30, bottom: 70, left: 70, right: 30 },
                        format: 'money',
                    },
                    datasourceName: 'resources',
                    title: 'Savings by Stopped Resource type',
                },
            },
            {
                x: 0,
                y: 10,
                h: 4,
                w: 6,
                data: {
                    type: 'chart',
                    chartType: 'grid',
                    groups: [],
                    values: [],
                    settings: {
                        columns: [
                            {
                                type: 'string',
                                select: { Alias: 'Account Name', Expr: { Field: 'AccountName', Type: 'Base' } },
                                id: 'Account Name',
                            },
                            {
                                type: 'number',
                                select: {
                                    Expr: {
                                        Operation: 'count',
                                        Operands: [
                                            {
                                                Operation: 'or',
                                                Operands: [idleEc2Query, idleRdsQuery, idleElbQuery, idleEMRQuery, idleRedshiftQuery],
                                            },
                                        ],
                                    },
                                    Alias: 'Resources',
                                },
                                id: 'Resource Count',
                            },
                            {
                                type: 'number',
                                select: {
                                    Alias: 'Sum Annualized Cost',
                                    Expr: {
                                        Operation: 'aggif',
                                        Operands: [
                                            {
                                                Operation: 'or',
                                                Operands: [idleEc2Query, idleRdsQuery, idleElbQuery, idleEMRQuery, idleRedshiftQuery],
                                            },
                                            { Operation: 'sum', Operands: [{ Field: 'AnnualizedCost', Type: 'Base' }] },
                                        ],
                                    },
                                },
                                id: 'Sum Annualized Cost',
                            },
                        ],
                        state: {
                            columns: [
                                { id: 'Account Name', fixed: false, width: 287 },
                                { id: 'Resource Count', fixed: false, width: 95 },
                                { id: 'Sum Annualized Cost', fixed: false, width: 188 },
                            ],
                            filters: [],
                            sort: [],
                        },
                    },
                    datasourceName: 'resources',
                    title: 'Idle Resources By Account',
                },
            },
            {
                x: 6,
                y: 24,
                h: 5,
                w: 6,
                data: {
                    type: 'chart',
                    chartType: 'pie',
                    groups: [{ Alias: 'Account Name', Expr: { Field: 'AccountName', Type: 'Base' } }],
                    values: [{ Alias: 'Count', Expr: { Operation: 'count' } }],
                    settings: { margin: { top: 50, bottom: 50, left: 50, right: 50 }, threshold: 0, angle: 'large' },
                    datasourceName: 'resources',
                    title: 'Idle Resources By Account',

                    filters: [
                        {
                            Operation: 'or',
                            Operands: [idleEc2Query, idleRdsQuery, idleElbQuery, idleEMRQuery, idleRedshiftQuery],
                        },
                    ],
                },
            },
            {
                x: 0,
                y: 28,
                h: 5,
                w: 12,
                data: {
                    type: 'chart',
                    chartType: 'line',
                    groups: [{ Alias: 'Date', Expr: { Field: 'Date' } }],
                    values: [{ Alias: 'Count', Expr: { Operation: 'count' } }],
                    filters: [
                        {
                            Operation: 'eq',
                            Operands: [{ Field: 'IsIdle' }, { Value: true }],
                        },
                    ],
                    settings: {
                        interval: 'week',
                        margin: { top: 30, bottom: 70, left: 70, right: 20 },
                    },
                    datasourceName: 'resourcesOverTime',
                    title: 'Idle Resource count over time.',
                },
            },
            {
                x: 0,
                y: 32,
                h: 5,
                w: 12,
                data: {
                    type: 'chart',
                    chartType: 'line',
                    groups: [{ Alias: 'Date', Expr: { Field: 'Date' } }],
                    values: [{ Alias: 'Annualized Cost', Expr: { Operation: 'sum', Operands: [{ Field: 'AnnualizedCost', Type: 'Base' }] } }],
                    filters: [
                        {
                            Operation: 'eq',
                            Operands: [{ Field: 'IsIdle' }, { Value: true }],
                        },
                    ],
                    settings: {
                        interval: 'week',
                        margin: { top: 30, bottom: 70, left: 70, right: 20 },
                        format: 'money',
                    },
                    datasourceName: 'resourcesOverTime',
                    title: 'Idle Resource cost over time.',
                },
            },
        ] as Layout<ChartConfig>[];
        return result;
    }, [idleEc2Query, idleRdsQuery, idleElbQuery, idleEMRQuery, idleRedshiftQuery, stoppedResourceQuery]);

    const defaultConfig = useMemo(() => {
        const result = {
            name: 'Default Idle Dashboard',
            layout: defaultAws,
        } as IDashboardConfig;
        return result;
    }, [defaultAws]);

    const addOptions = useMemo(
        () =>
            [
                {
                    label: 'New KPI',
                    category: 'blank',
                    settings: {
                        type: 'chart',
                        chartType: 'kpi',
                        groups: [],
                        values: [{ Alias: 'Resources', Expr: { Operation: 'count', Operands: [] } }],
                        settings: { valueFilters: [[]], labels: [''], format: [undefined] },
                        datasourceName: 'resources',
                        title: 'Resources',
                    },
                },
                {
                    label: 'New Table',
                    category: 'blank',
                    settings: {
                        type: 'chart',
                        chartType: 'grid',
                        groups: [],
                        values: [],
                        settings: {
                            state: {
                                columns: [
                                    { id: 'TagKey', width: 200 },
                                    { id: 'Resources', width: 100 },
                                ],
                                filters: [],
                                sort: [],
                            },
                            columns: [
                                { type: 'string', id: 'TagKey', select: { Alias: 'Tag Key', Expr: { Field: 'Tags.Key' } } },
                                { type: 'number', id: 'Resources', select: { Alias: 'Resources', Expr: { Operation: 'count' } } },
                            ],
                        },
                        datasourceName: 'resources',
                        title: 'Resources Count by Tag',
                    },
                },
                {
                    label: 'New Gauge',
                    category: 'blank',
                    settings: {
                        type: 'chart',
                        chartType: 'gauge',
                        groups: [{ Alias: 'Region', Expr: { Field: 'Region' } }],
                        values: [{ Alias: 'Count', Expr: { Operation: 'count' } }],
                        settings: { angle: 'large', margin: { top: 30, bottom: 30, left: 20, right: 20 } } as GaugeChartSettings,
                        datasourceName: 'resources',
                        title: 'Resources per Region',
                    },
                },
                {
                    label: 'New Pie',
                    category: 'blank',
                    settings: {
                        type: 'chart',
                        chartType: 'pie',
                        groups: [{ Alias: 'Region', Expr: { Field: 'Region' } }],
                        values: [{ Alias: 'Count', Expr: { Operation: 'count' } }],
                        settings: { angle: 'large', margin: { top: 30, bottom: 30, left: 20, right: 20 }, threshold: 3 } as PieChartSettings,
                        datasourceName: 'resources',
                        title: 'Resources per Region',
                    },
                },
                {
                    label: 'New Bar',
                    category: 'blank',
                    settings: {
                        type: 'chart',
                        chartType: 'bar',
                        groups: [{ Alias: 'Region', Expr: { Field: 'Region' } }],
                        values: [{ Alias: 'Count', Expr: { Operation: 'count' } }],
                        settings: { margin: { top: 30, bottom: 70, left: 70, right: 20 } } as BarChartSettings,
                        datasourceName: 'resources',
                        title: 'Resources per Region',
                    },
                },
                {
                    label: 'New Line',
                    category: 'blank',
                    settings: {
                        type: 'chart',
                        chartType: 'line',
                        groups: [{ Alias: 'Date', Expr: { Field: 'Date' } }],
                        values: [{ Alias: 'Count', Expr: { Operation: 'count' } }],
                        settings: { interval: 'month', margin: { top: 30, bottom: 70, left: 70, right: 20 } } as LineChartSettings,
                        datasourceName: 'resourcesOverTime',
                        title: 'Resources Added Over Time',
                    },
                },
            ] as DashboardAddOption[],
        []
    );

    const loadDashboard = (id?: number) => {
        nav.mergeParams({ dashboard: id?.toString() ?? '' });
    };

    return (
        <IdleResourcesPage title="" types={[]}>
            {!loading && (
                <CustomizableDashboard
                    dashboardKey="idleDashboard225"
                    addOptions={addOptions}
                    allowAdd={true}
                    allowFilter={true}
                    allowLoader={true}
                    datasources={datasource}
                    itemTypes={itemTypes}
                    id={(dashboard && parseInt(dashboard)) || undefined}
                    onIdChanged={loadDashboard}
                    defaultConfig={defaultConfig}
                />
            )}
        </IdleResourcesPage>
    );
}
export function IdleDashboard() {
    const nav = useNav();
    const { dashboard } = nav.getData('dashboard');
    return <InitialSyncCheck>{() => <IdleDashboardContent dashboard={dashboard} />}</InitialSyncCheck>;
}
endpoint('idle-customizable-dashboard', withAppFeatureCheck(IdleDashboard, 'Optimization', AppFeatureNames.IdleResources), 'Idle Resources');

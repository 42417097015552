import { inject, singleton } from 'tsyringe';

interface ILogReceiver {
    log(message: string, ...args: any[]): void;
    warn(message: string, ...args: any[]): void;
    error(message: string, ...args: any[]): void;
}

@singleton()
export class LoggerConfig {
    public logReceivers: ILogReceiver[] = [];

    public configure(logReceivers: ILogReceiver[]) {
        this.logReceivers.push(...logReceivers);
    }
}

@singleton()
export class Logger {
    public constructor(@inject(LoggerConfig) private config: LoggerConfig) {}

    public log(message: string, ...args: any[]) {
        this.config.logReceivers.forEach((l) => l.log(message, ...args));
    }
    public warn(message: string, ...args: any[]) {
        this.config.logReceivers.forEach((l) => l.warn(message, ...args));
    }
    public error(message: string, ...args: any[]) {
        this.config.logReceivers.forEach((l) => l.error(message, ...args));
    }
}

@singleton()
export class ConsoleLogReceiver implements ILogReceiver {
    public log(message: string, ...args: any[]) {
        console.log(message, ...args);
    }
    public warn(message: string, ...args: any[]) {
        console.warn(message, ...args);
    }
    public error(message: string, ...args: any[]) {
        console.error(message, ...args);
    }
}

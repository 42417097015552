import { Box, Button, Group, Text, ThemeIcon } from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useNav } from '@root/Services/NavigationService';
import { useLink } from '@root/Services/Router/Router';
import { RuleEditor } from './Model';

export function RuleTestStatus({ rule }: { rule: RuleEditor }) {
    const { getDescendUrl } = useNav();
    const link = useLink();
    const fmtSvc = useDi(FormatService);
    const testEnd = rule.getTestEndDate();
    const currentlyTesting = rule.isTesting();
    const changed = rule.parametersChanged();
    const willTest = rule.ruleStatus === 'Test' && (!currentlyTesting || changed);
    const nolongerTesting = rule.ruleStatus !== 'Test';
    const message = willTest
        ? `Rule ${changed ? 'has changed, it ' : ''} will run in test mode until ${fmtSvc.formatDate(rule.calculateTestEndDate())} `
        : currentlyTesting
        ? `Rule is currently running in test mode until ${fmtSvc.formatDate(rule.calculateTestEndDate())}`
        : !currentlyTesting && testEnd
        ? `Rule ran in test mode until ${fmtSvc.formatDate(testEnd)}`
        : '';
    return !nolongerTesting && testEnd ? (
        <Group>
            <Button
                color="warning"
                leftIcon={<i className="ti ti-clock-hour-10"></i>}
                component="a"
                {...link(getDescendUrl('tag-automation-rule-activity', { ruleId: rule.rule?.Id?.toString() ?? '' }))}
            >
                Review Test Activity
            </Button>
            <Box>
                <Text color="warning">Test Mode</Text>
                <Text size="sm">{message}</Text>
            </Box>
        </Group>
    ) : null;
}

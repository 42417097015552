import styled from '@emotion/styled';
import { Divider, Title } from '@mantine/core';
import { ReactNode } from 'react';

export const WizardStep = styled.a<{ mode: 'active' | 'finished' | 'upcoming' }>`
    width: 56px;
    height: 56px;
    border-radius: 8px;
    background: ${(p) =>
        p.mode === 'active' ? p.theme.colors.primary[6] : p.mode === 'upcoming' ? p.theme.colors.gray[3] : p.theme.colors.primary[3]};
    color: ${(p) => (p.mode === 'active' ? p.theme.colors.primary[0] : p.mode === 'upcoming' ? p.theme.colors.gray[6] : p.theme.colors.primary[6])};
    cursor: ${(p) => (p.mode === 'upcoming' ? 'not-allowed' : p.mode === 'active' ? 'normal' : 'pointer')};
    font-size: 32px;
    line-height: 56px;
    text-align: center;
`;
export const WizardStepContainer = styled.div`
    flex-grow: 1;
    background: ${(p) => p.theme.white};
    display: grid;
    max-width: 1000px;
    grid-template-columns: 1fr auto 1fr;
    padding: 45px;
    align-items: center;
    justify-content: space-between;
`;
export const WizardStepRow = styled.div`
    display: flex;
    align-items: center;
`;
export function WizardStepLine() {
    return <Divider size={2} sx={{ width: '94px' }} color="gray.3" />;
}
export const MapWizardPageOuter = styled.div`
    height: 100%;
    overflow: auto;
    flex: 1;
    background: ${(p) => p.theme.colors.gray[1]};
`;
export const MapWizardPageContent = styled.div`
    display: flex;
    min-height: 100%;
    background: ${(p) => p.theme.colors.gray[2]};
`;
export const MapWizardPageContentBody = styled.div`
    padding: 45px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    width: 100%;
    flex: 1 1 100%;
`;
export const MapWizardPageHelp = styled.div`
    padding: 45px 30px;
    background: ${(p) => p.theme.colors.gray[3]};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    min-width: 360px;
    > div {
        max-width: 300px;
    }
`;
export function StepTitle({ title }: { title: ReactNode }) {
    return (
        <Title order={3} align="center">
            {title}
        </Title>
    );
}

export const FaqQ = styled.a`
    display: grid;
    align-items: center;
    cursor: pointer;
    grid-template-columns: 30px auto;
    font-size: 16px;
    font-weight: bold;
    color: ${(p) => p.theme.colors.gray[7]};
`;

export const FaqA = styled.div`
    padding-left: 30px;
    font-size: 14px;
    color: ${(p) => p.theme.colors.gray[7]};
    margin-bottom: 20px;
`;

import { AnchorHTMLAttributes, forwardRef, useCallback } from 'react';
import { linkHandler } from './Router';

export const Link = forwardRef<HTMLAnchorElement, AnchorHTMLAttributes<HTMLAnchorElement>>((props, ref) => {
    const onClick = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            if (props.onClick) {
                props.onClick(e);
            }
            linkHandler(e);
        },
        [props.onClick]
    );
    return <a {...props} ref={ref} onClick={onClick} />;
});

import { BaseAwsResource } from '@apis/Resources/model';
import { Stack } from '@mantine/core';
import {
    PropertyGrid,
    PropertyGridConfig,
    PropertyGridOptionItem,
    usePropertyGridConfig,
    usePropertyGridConfigBuilder,
} from '@root/Components/PropertyGrid/PropertyGrid';
import { useMemo } from 'react';
import { CollapsibleDetailsSection } from '../Design';
import { getCopyOption, useCommonFieldConfig, useFieldPinning, useResourceChangeCount } from '../Common/OverviewCommon';
import { BaseResource } from '../ResourcesGrid';
import { useAwsResourceFieldConfig } from './AwsFieldConfig';
import { FillerSwitch } from '@root/Design/Filler';
import { PropertyGridItem } from '@root/Components/PropertyGrid/Models';
import { FormatService } from '@root/Services/FormatService';
import { useDi } from '@root/Services/DI';

export function AwsResourceOverview({ resource }: { resource: BaseResource }) {
    const fmtSvc = useDi(FormatService);
    const changeCt = useResourceChangeCount();
    const { awsRes, adjustedEnumLookup } = useMemo(() => {
        const result = structuredClone(resource) as BaseAwsResource;
        const adjustmentLookup = flattenAwsEnums(result);
        return { awsRes: result, adjustedEnumLookup: adjustmentLookup };
    }, [resource, changeCt]);

    const { resourceTypeFields, awsCommonFields } = useAwsResourceFieldConfig();
    const type = awsRes.ResourceType ?? 'Type';
    const typeFieldConfig = resourceTypeFields[type];
    const typeFields = !typeFieldConfig ? [] : Object.keys(typeFieldConfig);
    const typeFieldPins = !typeFieldConfig ? [] : typeFields.filter((f) => !typeFieldConfig[f].hidden);

    const {
        loading: pinFieldsLoading,
        getMenuOptions: getPinMenuOptions,
        pinnedFieldConfig,
    } = useFieldPinning('ResourceOverview', 'AWS', Object.keys(awsCommonFields), type, typeFieldPins, ['Region', 'Account', 'CreateDate']);

    const commonFieldConfig = useCommonFieldConfig();

    const commonMenuOptions = useMemo(
        () =>
            ({
                getOptions: (item, _, config) => {
                    const menuSections = [
                        { header: null, options: getCopyOption(item, config) },
                        { header: 'Pinning', options: getPinMenuOptions(item) },
                    ];

                    const result = menuSections.reduce((items, section) => {
                        if (items.length) {
                            items.push('divider');
                        }
                        if (section.header && section.options.length) {
                            items.push({ type: 'header', label: section.header });
                        }
                        items.push(...section.options);

                        return items;
                    }, [] as PropertyGridOptionItem[]);

                    return result;
                },
            } as PropertyGridConfig),
        [resource, getPinMenuOptions]
    );

    const ruleBuilder = usePropertyGridConfigBuilder();
    const adjustedEmunFieldConfig = useMemo(() => {
        return ruleBuilder(adjustedEnumLookup, {
            label: (item, defaultRenderer) => {
                const propStr = item.property.toString();
                const prop = propStr.substring(0, propStr.length - 6);
                return defaultRenderer(fmtSvc.userFriendlyCamelCase(prop));
            },
        });
    }, [adjustedEnumLookup]);

    const sharedOptions = useMemo(() => [...commonFieldConfig, typeFieldConfig, adjustedEmunFieldConfig], [adjustedEnumLookup]);

    const pinnedConfig = usePropertyGridConfig(() => [...sharedOptions, ...pinnedFieldConfig], commonMenuOptions, [pinnedFieldConfig]);
    const baseConfig = usePropertyGridConfig(() => sharedOptions, commonMenuOptions);

    return (
        <Stack>
            <FillerSwitch loading={pinFieldsLoading}>
                {() => <PropertyGrid target={awsRes} {...pinnedConfig} flatten layout="labels" columns={3} />}
            </FillerSwitch>
            <CollapsibleDetailsSection label="Resource Details" shadow>
                <PropertyGrid target={awsRes} {...baseConfig} />
            </CollapsibleDetailsSection>
        </Stack>
    );

    function flattenAwsEnums(item: any) {
        const result = new Map<string, Set<any>>();

        const checkAndFlattenEnum = (parent: Record<string, unknown>, property: string) => {
            const value = parent[property];
            const valueObj = typeof value === 'object' ? value : null;
            const valueKeys = !valueObj ? null : Object.keys(valueObj);
            const nextValue = valueKeys?.length !== 1 ? null : (valueObj as any).Value;

            if (typeof nextValue === 'string') {
                delete parent[property];
                const adjustedKey = `${property}.Value`;
                parent[`${property}.Value`] = nextValue;
                let adjustedItems = result.get(adjustedKey);
                if (!adjustedItems) {
                    result.set(adjustedKey, (adjustedItems = new Set()));
                }
                adjustedItems.add(parent);
            }
        };
        const visitItems = (item: any) => {
            if (Array.isArray(item)) {
                item.forEach(visitItems);
            } else if (typeof item === 'object' && !!item) {
                for (const key of Object.keys(item)) {
                    checkAndFlattenEnum(item, key);
                    const value = item[key];
                    if (Array.isArray(value)) {
                        visitItems(value);
                    } else if (typeof value === 'object' && !!value) {
                        visitItems(value);
                    }
                }
            }
        };
        visitItems(item);

        return (item: PropertyGridItem) => typeof item.property === 'string' && result.get(item.property)?.has(item.target) === true;
    }
}

import { BaseResource } from '@apis/Resources/model';
import { Table } from '@mantine/core';
import { ResourceDetailSection } from '../Design';

export function AwsResourceRedshiftNodes({ resource }: { resource: BaseResource }) {
    const redshift = resource as { ClusterNodes?: { NodeRole: string; PrivateIPAddress: string; PublicIPAddress: string }[] };

    return (
        <ResourceDetailSection>
            {!redshift.ClusterNodes?.length ? null : (
                <Table>
                    <thead>
                        <tr>
                            <th>Node Role</th>
                            <th>Private IP Address</th>
                            <th className="left">Public IP Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        {redshift.ClusterNodes.map((val, key) => {
                            return (
                                <tr key={key}>
                                    <td>{val.NodeRole}</td>
                                    <td>{val.PrivateIPAddress}</td>
                                    <td>{val.PublicIPAddress}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            )}
        </ResourceDetailSection>
    );
}

import { singleton } from 'tsyringe';
import { useDi } from './DI';

@singleton()
export class IdGen {
    private items = new WeakMap<any, number>();
    private nextId = 1;
    public clear() {
        this.items = new WeakMap<any, number>();
    }
    public getId(item: any) {
        if (!this.items.has(item)) {
            this.items.set(item, this.nextId++);
        }
        return this.items.get(item);
    }
}

export function useId(item: any) {
    const idGen = useDi(IdGen);
    return idGen.getId(item);
}
export function useIdGen() {
    const idGen = useDi(IdGen);
    return { getId: (item: any) => idGen.getId(item) };
}

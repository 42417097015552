import styled from '@emotion/styled';

export const AdminPageTitle = styled.h3`
    line-height: 28px;
    font-size: 18px;
    padding: 0;
    margin: 0;
`;

export const AdminPanel = styled.div<{ fullHeight?: boolean }>`
    padding: ${(p) => p.theme.spacing.md}px ${(p) => p.theme.spacing.xl}px;
    ${(p) => (p.fullHeight ? 'height: 100%;' : '')}
`;

import { getRbacGetCompanyRoles } from '@apis/Customers';
import { CompanyRoleListItem, UserFacingPermissionInfo } from '@apis/Customers/model';
import { CompanyTenantPrereqService } from '@root/Components/Router/CompanyContent';
import { inject, injectable, singleton } from 'tsyringe';
import { AuthorizationService } from '../AuthorizationService';
import { BaseCacheByTenant } from './BaseCacheByTenant';

@singleton()
class TenantRoleCache extends BaseCacheByTenant<CompanyRoleLookup> {
    public constructor(@inject(CompanyTenantPrereqService) tenantPrereqSvc: CompanyTenantPrereqService) {
        super(tenantPrereqSvc);
    }
}

export interface ICompanyRoleLookup {
    /**
     * Get the roles for the company
     */
    getRoles(): ReadonlyArray<CompanyRoleListItem>;
    /**
     * Get the user facing permissions for the role IDs
     */
    getUfPermissions(...roleIds: number[]): UserFacingPermissionInfo[];
    /**
     * Get the user facing permissions for the current user
     */
    getUserUfps(): UserFacingPermissionInfo[];
    /**
     * True if the role has the feature ID
     * @param roleId
     * @param featureId
     */
    roleHasFeature(roleId: number, featureId: number): boolean;
    /**
     * True if the current user has the feature ID in any of their roles
     * @param featureId
     */
    userHasFeature(featureId: number): boolean;
}
class CompanyRoleLookup implements ICompanyRoleLookup {
    private readonly roleById = new Map<number, CompanyRoleListItem>();
    private readonly featureIdsByRoleId = new Map<number, Set<number>>();

    public constructor(private readonly companyRoles: CompanyRoleListItem[], public readonly userRoleIds: number[]) {
        for (const role of companyRoles) {
            if (role.Role?.Id) {
                this.roleById.set(role.Role?.Id, role);
            }
        }
    }

    public getRoles(): ReadonlyArray<CompanyRoleListItem> {
        return this.companyRoles;
    }

    public roleHasFeature(roleId: number, featureId: number) {
        let featureIds = this.featureIdsByRoleId.get(roleId);
        if (!featureIds) {
            featureIds = new Set<number>();
            this.featureIdsByRoleId.set(roleId, featureIds);
            const features = this.getUfPermissions(roleId).map((ufp) => ufp.FeatureId);
            if (features.length) {
                this.featureIdsByRoleId.set(roleId, new Set(features.map((f) => f ?? 0)));
            }
        }
        return featureIds.has(featureId);
    }

    public userHasFeature(featureId: number) {
        return this.userRoleIds.some((r) => this.roleHasFeature(r, featureId));
    }

    public getUfPermissions(...roleIds: number[]) {
        const ufpsFound = new Map<number, UserFacingPermissionInfo>();
        for (const roleId of roleIds) {
            const ufps = this.roleById.get(roleId)?.UserFacingPermissions ?? [];
            for (const ufp of ufps) {
                if (ufp.Id && !ufpsFound.has(ufp.Id)) {
                    ufpsFound.set(ufp.Id, ufp);
                }
            }
        }
        return [...ufpsFound.values()];
    }

    public getUserUfps() {
        return this.getUfPermissions(...this.userRoleIds);
    }
}

@injectable()
export class CompanyRoleService {
    public constructor(
        @inject(TenantRoleCache) private readonly roleCache: TenantRoleCache,
        @inject(AuthorizationService) private readonly authSvc: AuthorizationService
    ) {}

    public invalidate(companyId: number) {
        this.roleCache.invalidate(companyId);
    }

    public async getRoles(companyId: number, parentCompanyId?: number): Promise<ICompanyRoleLookup> {
        const parentRoleLookup = !parentCompanyId
            ? null
            : await this.roleCache.get(parentCompanyId, async () => {
                  return new CompanyRoleLookup(await getRbacGetCompanyRoles(), this.authSvc.companyRoleLookup.get(parentCompanyId) ?? []);
              });
        return await this.roleCache.get(companyId, async () => {
            const parentCompanyRoles = parentRoleLookup?.getRoles() ?? [];
            const parentRoleIds = parentRoleLookup?.userRoleIds ?? [];
            const companyRoles = [...(await getRbacGetCompanyRoles()), ...parentCompanyRoles];
            const rolesIds = [...(this.authSvc.companyRoleLookup.get(companyId) ?? []), ...parentRoleIds];
            return new CompanyRoleLookup(companyRoles, rolesIds);
        });
    }
}

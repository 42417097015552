import { Button, Card, Space, Title, useMantineTheme } from '@mantine/core';
import { RuleEditor } from './Model';
import { useEffect, useMemo, useState } from 'react';
import { PreviewRuleCard } from './PreviewRuleCard';
import RuleStatusCard from './RuleStatusCard';
import { useDi, useDiContainer } from '@root/Services/DI';
import { ResourceGroupActionCard, ResourceGroupEditor } from './ResourceGroupActionCard';
import { Platform, PlatformService } from '@root/Services/PlatformService';
import { AwsOrgEntityEditor, AwsOrgEntityGroupActionCard } from './AWSOrgEntityGroupActionCard';
import { RuleEditCard } from './Design';
import { Clearfix } from '@root/Design/Primitives';

export function ResourceGroupRuleCard({ ruleEditor }: { ruleEditor: RuleEditor }) {
    const di = useDiContainer();
    const platformSvc = useDi(PlatformService);
    const [hasAws, setHasAws] = useState(false);
    const [hasAzure, setHasAzure] = useState(false);
    const [selectedPlatform, setSelectedPlatform] = useState<'aws' | 'azure'>('aws');

    useEffect(() => {
        platformSvc.init().then(() => {
            setHasAws(platformSvc.hasPlatform('Aws'));
            setHasAzure(platformSvc.hasPlatform('Azure'));
            if (platformSvc.hasPlatform('Aws')) {
                setSelectedPlatform('aws');
            } else if (platformSvc.hasPlatform('Azure')) {
                setSelectedPlatform('azure');
            }
        });
    }, [platformSvc]);

    const awsOrgEntityEditor = useMemo(() => di.resolve(AwsOrgEntityEditor).init(ruleEditor), []);
    const resourceGroupEditor = useMemo(() => di.resolve(ResourceGroupEditor).init(ruleEditor), []);

    if (!hasAws && !hasAzure) {
        return <></>; //should never end here, should always have either aws or azure
    }

    return (
        <>
            {hasAws && hasAzure && (
                <>
                    <RuleEditCard height={200} accent title="Platform" description="Select platform to use">
                        <div style={{ width: '30%', margin: '0 auto' }}>
                            <Button
                                onClick={() => setSelectedPlatform('aws')}
                                sx={{
                                    width: '45%',
                                    float: 'left',
                                }}
                                variant={selectedPlatform === 'aws' ? 'filled' : 'outline'}
                                fullWidth
                            >
                                AWS
                            </Button>
                            <Button
                                onClick={() => setSelectedPlatform('azure')}
                                sx={{
                                    width: '45%',
                                    float: 'right',
                                }}
                                variant={selectedPlatform === 'azure' ? 'filled' : 'outline'}
                                fullWidth
                            >
                                Azure
                            </Button>
                            <Clearfix />
                        </div>
                    </RuleEditCard>
                    <Space h="md" />
                </>
            )}
            {selectedPlatform === 'aws' ? (
                <>
                    {' '}
                    <AwsOrgEntityGroupActionCard awsOrgEntityEditor={awsOrgEntityEditor} />
                    <Space h="md" />
                    <PreviewRuleCard ruleEditor={ruleEditor} />
                    <Space h="md" />
                    <RuleStatusCard ruleEditor={ruleEditor} />
                </>
            ) : (
                <>
                    {' '}
                    <ResourceGroupActionCard resourceGroupEditor={resourceGroupEditor} />
                    <Space h="md" />
                    <PreviewRuleCard ruleEditor={ruleEditor} />
                    <Space h="md" />
                    <RuleStatusCard ruleEditor={ruleEditor} />
                </>
            )}
        </>
    );
}

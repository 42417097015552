import { getCWMetricsConfigGetCWMetricsConfigData, getCWMetricsConfigGetCWMetricsConfigFilters } from '@apis/Customers';
import { Company, CWMetricsConfigData, GetCWMetricsConfigGetCWMetricsConfigDataAppType } from '@apis/Customers/model';
import { GetCWMetricsConfigGetCWMetricsConfigFiltersAppType } from '@apis/Customers/model';
import { postResourcesQuery, QueryExpr, QueryOperation } from '@apis/Resources';
import { IQueryExpr } from '@apis/Resources/model';
7;
import { ICompanyContextToken } from '@root/Services/Customers/CompanyContext';
import { EventEmitter } from '@root/Services/EventEmitter';
import { Logger } from '@root/Services/Logger';
import { inject, Lifecycle, scoped, singleton } from 'tsyringe';

@singleton()
class IdleResourceStatsCache {
    private companyId: number = 0;
    private stats: Promise<Record<string, number>> | null = null;
    private customFilters: Promise<Map<string, QueryExpr>> | null = null;

    public async getCachedStats(companyId: number, valueLoader: () => Promise<Record<string, number>>) {
        if (companyId !== this.companyId || !this.stats) {
            this.companyId = companyId;
            this.stats = valueLoader();
        }
        return await this.stats;
    }

    public async getCachedFilters(companyId: number, valueLoader: () => Promise<Map<string, QueryExpr>>) {
        if (companyId !== this.companyId || !this.customFilters) {
            this.companyId = companyId;
            this.customFilters = valueLoader();
        }
        return await this.customFilters;
    }

    public invalidate() {
        this.stats = null;
        this.customFilters = null;
    }
}

@scoped(Lifecycle.ContainerScoped)
export class IdleResourcesService {
    public readonly refreshNeeded = EventEmitter.empty();
    public readonly statsLoading = new EventEmitter<boolean>(false);

    private readonly idleQueryDefault = new Map<string, QueryExpr>([
        [
            'EC2',
            {
                Operation: 'and',
                Operands: [
                    { Operation: 'eq', Operands: [{ Field: 'ResourceType' }, { Value: 'EC2 On Demand' }] },
                    { Operation: 'lte', Operands: [{ Field: 'Metrics.IdleResources.NetworkOut' }, { Value: 5000000 }] },
                    { Operation: 'lte', Operands: [{ Field: 'Metrics.IdleResources.NetworkIn' }, { Value: 5000000 }] },
                    { Operation: 'lt', Operands: [{ Field: 'Metrics.IdleResources.CpuUtilization' }, { Value: 2 }] },
                ],
            },
        ],
        [
            'EC2Memory',
            {
                Operation: 'and',
                Operands: [
                    { Operation: 'eq', Operands: [{ Field: 'ResourceType' }, { Value: 'EC2 On Demand' }] },
                    { Operation: 'lte', Operands: [{ Field: 'Metrics.IdleResources.NetworkOut' }, { Value: 5000000 }] },
                    { Operation: 'lte', Operands: [{ Field: 'Metrics.IdleResources.NetworkIn' }, { Value: 5000000 }] },
                    { Operation: 'lt', Operands: [{ Field: 'Metrics.IdleResources.CpuUtilization' }, { Value: 2 }] },
                    { Operation: 'lt', Operands: [{ Field: 'Metrics.IdleResources.MemoryUtilization' }, { Value: 2 }] },
                ],
            },
        ],
        [
            'RDS',
            {
                Operation: 'and',
                Operands: [
                    { Operation: 'eq', Operands: [{ Field: 'ResourceType' }, { Value: 'RDS' }] },
                    { Operation: 'lte', Operands: [{ Field: 'Metrics.IdleResources.DatabaseConnections' }, { Value: 1 }] },
                    { Operation: 'lte', Operands: [{ Field: 'Metrics.IdleResources.ReadIOPS' }, { Value: 20 }] },
                    { Operation: 'lte', Operands: [{ Field: 'Metrics.IdleResources.WriteIOPS' }, { Value: 20 }] },
                ],
            },
        ],
        [
            'EMR',
            {
                Operation: 'and',
                Operands: [
                    { Operation: 'eq', Operands: [{ Field: 'ResourceType' }, { Value: 'EMR' }] },
                    { Operation: 'eq', Operands: [{ Field: 'Metrics.IdleResources.IsIdle' }, { Value: true }] },
                ],
            },
        ],
        [
            'ELB',
            {
                Operation: 'and',
                Operands: [
                    {
                        Operation: 'or',
                        Operands: [
                            { Operation: 'eq', Operands: [{ Field: 'ResourceType' }, { Value: 'ELBV1' }] },
                            {
                                Operation: 'and',
                                Operands: [
                                    { Operation: 'eq', Operands: [{ Field: 'ResourceType' }, { Value: 'ELBV2' }] },
                                    { Operation: 'eq', Operands: [{ Field: 'Type.Value' }, { Value: 'application' }] },
                                ],
                            },
                        ],
                    },
                    { Operation: 'lte', Operands: [{ Field: 'Metrics.IdleResources.SumOfRequests' }, { Value: 100 }] },
                ],
            },
        ],
        [
            'Redshift',
            {
                Operation: 'and',
                Operands: [
                    { Operation: 'eq', Operands: [{ Field: 'ResourceType' }, { Value: 'Redshift' }] },
                    { Operation: 'lt', Operands: [{ Field: 'Metrics.IdleResources.DatabaseConnections' }, { Value: 1 }] },
                    { Operation: 'lt', Operands: [{ Field: 'Metrics.IdleResources.ReadIOPS' }, { Value: 20 }] },
                    { Operation: 'lt', Operands: [{ Field: 'Metrics.IdleResources.WriteIOPS' }, { Value: 20 }] },
                ],
            },
        ],
    ]);

    private readonly idleConfigData = new Map<string, CWMetricsConfigData>([
        [
            'EC2',
            {
                cWMetricLabelConfig: [
                    {
                        label: 'CpuUtilization',
                        pollingInterval: 3600,
                        lookBackDate: 30,
                        stat: 'Average',
                        namespace: 'AWS/EC2',
                        metricName: 'CPUUtilization',
                    },
                    {
                        label: 'MemoryUtilization',
                        pollingInterval: 3600,
                        lookBackDate: 30,
                        stat: 'Average',
                        namespace: 'CWAgent',
                        metricName: 'MemoryUtilization',
                    },
                    { label: 'NetworkIn', pollingInterval: 3600, lookBackDate: 30, stat: 'Average', namespace: 'AWS/EC2', metricName: 'NetworkIn' },
                    { label: 'NetworkOut', pollingInterval: 3600, lookBackDate: 30, stat: 'Average', namespace: 'AWS/EC2', metricName: 'NetworkOut' },
                ],
            },
        ],
        [
            'RDS',
            {
                cWMetricLabelConfig: [
                    {
                        label: 'DatabaseConnections',
                        pollingInterval: 3600,
                        lookBackDate: 30,
                        stat: 'Maximum',
                        namespace: 'AWS/RDS',
                        metricName: 'DatabaseConnections',
                    },
                    { label: 'ReadIOPS', pollingInterval: 3600, lookBackDate: 30, stat: 'Average', namespace: 'AWS/RDS', metricName: 'ReadIOPS' },
                    { label: 'WriteIOPS', pollingInterval: 3600, lookBackDate: 30, stat: 'Average', namespace: 'AWS/RDS', metricName: 'WriteIOPS' },
                ],
            },
        ],
        [
            'EMR',
            {
                cWMetricLabelConfig: [
                    {
                        label: 'IsIdle',
                        pollingInterval: 300,
                        lookBackDate: 1,
                        stat: 'Minimum',
                        namespace: 'AWS/ElasticMapReduce',
                        metricName: 'IsIdle',
                    },
                ],
            },
        ],
        [
            'ELB',
            {
                cWMetricLabelConfig: [
                    {
                        label: 'SumOfRequests',
                        pollingInterval: 60,
                        lookBackDate: 7,
                        stat: 'Sum',
                        namespace: 'AWS/ELB',
                        metricName: 'RequestCount',
                    },
                    {
                        label: 'SumOfRequests',
                        pollingInterval: 60,
                        lookBackDate: 7,
                        stat: 'Sum',
                        namespace: 'AWS/ApplicationELB',
                        metricName: 'RequestCount',
                    },
                ],
            },
        ],
        [
            'Redshift',
            {
                cWMetricLabelConfig: [
                    {
                        label: 'DatabaseConnections',
                        pollingInterval: 3600,
                        lookBackDate: 7,
                        stat: 'Average',
                        namespace: 'AWS/Redshift',
                        metricName: 'DatabaseConnections',
                    },
                    { label: 'ReadIOPS', pollingInterval: 3600, lookBackDate: 7, stat: 'Average', namespace: 'AWS/Redshift', metricName: 'ReadIOPS' },
                    {
                        label: 'WriteIOPS',
                        pollingInterval: 3600,
                        lookBackDate: 7,
                        stat: 'Average',
                        namespace: 'AWS/Redshift',
                        metricName: 'WriteIOPS',
                    },
                ],
            },
        ],
    ]);

    public readonly pollingIntervals = [
        { value: 60, label: '1 min' },
        { value: 300, label: '5 mins' },
        { value: 900, label: '15 mins' },
        { value: 1800, label: '30 mins' },
        { value: 3600, label: '1 hour' },
    ];

    public readonly lookBackDays = [
        { value: 1, label: '1 day' },
        { value: 7, label: '7 days' },
        { value: 15, label: '15 days' },
        { value: 30, label: '30 days' },
        { value: 63, label: '63 days' },
        { value: 455, label: '455 days' },
    ];

    public readonly stats = [
        { value: 'Sum', label: 'Sum' },
        { value: 'Average', label: 'Average' },
        { value: 'Maximum', label: 'Maximum' },
        { value: 'Minimum', label: 'Minimum' },
    ];

    constructor(
        @inject(ICompanyContextToken) private readonly company: Company,
        @inject(IdleResourceStatsCache) private readonly companyStatsCache: IdleResourceStatsCache,
        @inject(Logger) private readonly logger: Logger
    ) {}

    public async getResourceTypeConfigData(type: string) {
        if (type === 'EC2 On Demand') {
            type = 'EC2';
        }
        const configData = (await this.getResourceTypeConfigDataForCompany(type))?.[0];
        return configData?.cWMetricLabelConfig ? configData.cWMetricLabelConfig : this.idleConfigData.get(type)?.cWMetricLabelConfig;
    }

    public async getResourceTypeQuery(type: string, includeMemory: boolean) {
        const queryLookup = await this.getIdleQuery();
        if (type === 'EC2 On Demand') {
            if (includeMemory) {
                type = 'EC2Memory';
            } else {
                type = 'EC2';
            }
        }

        return queryLookup.get(type) ?? this.idleQueryDefault.get(type);
    }

    public async getIdleQuery() {
        const parseJsonOrDefault = (json: string | null | undefined, defaultQuery: QueryExpr | undefined) => {
            try {
                if (json) {
                    return JSON.parse(json);
                }
            } catch (err) {
                this.logger.error(`Error parsing idle query ${json}`, err);
            }
            return defaultQuery;
        };

        return await this.companyStatsCache.getCachedFilters(this.company.Id ?? 0, async () => {
            const query = new Map<string, QueryExpr>(this.idleQueryDefault);
            const results = await this.getResourceTypeQueryForCompany();
            for (const result of results) {
                const resourceType = result.ResourceType === 'EC2 On Demand' ? 'EC2' : result.ResourceType;
                if (resourceType) {
                    query.set(resourceType, parseJsonOrDefault(result.MetricJson, this.idleQueryDefault.get(resourceType)));
                }
            }
            return query;
        });
    }

    public getDefaultConfigData(resourceType: string) {
        if (resourceType === 'EC2 On Demand') {
            resourceType = 'EC2';
        }

        return this.idleConfigData.get(resourceType);
    }

    public getDefaultQuery(resourceType: string) {
        if (resourceType === 'EC2 On Demand') {
            resourceType = 'EC2';
        }

        return this.idleQueryDefault.get(resourceType) as unknown as IQueryExpr;
    }

    public formatLabel(label: string) {
        if (label === 'CPUUtilization') return 'CPU Utilization';
        return label.replace(/(?<=[a-z])([A-Z])/g, ' $1');
    }

    private async getResourceTypeQueryForCompany() {
        return await getCWMetricsConfigGetCWMetricsConfigFilters({
            appType: GetCWMetricsConfigGetCWMetricsConfigFiltersAppType.IdleResources,
        });
    }

    private async getResourceTypeConfigDataForCompany(type: string) {
        if (type === 'EC2') {
            type = 'EC2 On Demand';
        }
        return await getCWMetricsConfigGetCWMetricsConfigData({
            resourceType: type,
            appType: GetCWMetricsConfigGetCWMetricsConfigDataAppType.IdleResources,
        });
    }

    public invalidateCache() {
        this.companyStatsCache.invalidate();
    }

    public async getStats() {
        try {
            const idleQuery = await this.getIdleQuery();
            this.statsLoading.emit(true);
            return await this.companyStatsCache.getCachedStats(this.company.Id ?? 0, async () => {
                const typeCounts = [...idleQuery].map(([type, query]) => {
                    var newQuery = this.addStoppedExlusionFilter(type, query);
                    return { Alias: type, Expr: { Operation: 'countif', Operands: [newQuery] } };
                });

                const results = await postResourcesQuery({ Select: typeCounts }, { companyId: this.company.Id ?? 0 });
                return (results.Results?.[0] ?? {}) as Record<string, number>;
            });
        } finally {
            this.statsLoading.emit(false);
        }
    }

    public addStoppedExlusionFilter(type: string, query: QueryExpr) {
        let typeStoppedQuery;
        var queryOp = query as QueryOperation;
        switch (type) {
            case 'EC2':
                typeStoppedQuery = { Operation: 'ne', Operands: [{ Field: 'RunningState.Value' }, { Value: 'stopped' }] };
                break;
            case 'RDS':
                typeStoppedQuery = { Operation: 'ne', Operands: [{ Field: 'ActivityStreamStatus.Value' }, { Value: 'stopped' }] };
                break;
            case 'Redshift':
                typeStoppedQuery = { Operation: 'ne', Operands: [{ Field: 'ClusterStatus' }, { Value: 'paused' }] };
                break;
            default:
                return query;
        }
        if (typeStoppedQuery) {
            var newQuery = { Operation: 'and', Operands: [...queryOp.Operands, typeStoppedQuery] };
            return newQuery;
        }
    }

    public async getLastSyncDate(resourceType: string) {
        const query = await this.getIdleQuery();
        const where = query.get(resourceType);

        const response = await postResourcesQuery(
            { Where: where, Select: [{ Alias: 'LastSyncDate', Expr: { Operation: 'min', Operands: [{ Field: 'LastSyncDate' }] } }], Take: 0 },
            { companyId: this.company.Id ?? 0 }
        );

        return response.Results && response.Results.length > 0
            ? new Date((response.Results?.[0] as any)?.LastSyncDate as number) ?? new Date('1/1/2000')
            : new Date('1/1/2000');
    }

    public async getStoppedResourcesQuery() {
        const query = {
            Operation: 'or',
            Operands: [
                {
                    Operation: 'and',
                    Operands: [
                        { Operation: 'eq', Operands: [{ Field: 'ResourceType' }, { Value: 'EC2 On Demand' }] },
                        { Operation: 'eq', Operands: [{ Field: 'RunningState.Value' }, { Value: 'stopped' }] },
                    ],
                },
                {
                    Operation: 'and',
                    Operands: [
                        { Operation: 'eq', Operands: [{ Field: 'ResourceType' }, { Value: 'RDS' }] },
                        { Operation: 'eq', Operands: [{ Field: 'ActivityStreamStatus.Value' }, { Value: 'stopped' }] },
                    ],
                },
                {
                    Operation: 'and',
                    Operands: [
                        { Operation: 'eq', Operands: [{ Field: 'ResourceType' }, { Value: 'Redshift' }] },
                        { Operation: 'eq', Operands: [{ Field: 'ClusterStatus' }, { Value: 'paused' }] },
                    ],
                },
            ],
        };
        return query;
    }
}

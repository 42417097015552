/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.TagManager.Host.Api
 * OpenAPI spec version: 1.0
 */

export type MapCreditsOptionsMapMigrationType = typeof MapCreditsOptionsMapMigrationType[keyof typeof MapCreditsOptionsMapMigrationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MapCreditsOptionsMapMigrationType = {
  ComputeStorageAndOther: 'ComputeStorageAndOther',
  DatabaseAndAnalytics: 'DatabaseAndAnalytics',
  SAP: 'SAP',
  Oracle: 'Oracle',
} as const;

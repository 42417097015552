import styled from '@emotion/styled';
import { Box, useMantineTheme, Divider, Group, Button, Loader, Stack, Center, Space, Grid } from '@mantine/core';
import { CustomColors } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { useObserver } from 'mobx-react';
import { useCallback, useEffect } from 'react';
import { AlertTriangle } from 'tabler-icons-react';
import { AllocationRuleTypeList } from './AllocationRuleTypeList';
import { AllocationRuleEditor, AllocationRuleEditorContext } from './Model';
import { AdvancedRuleCard } from './RuleCards/AdvancedRuleCard';
import { DiscountsRuleCard } from './RuleCards/DiscountsRuleCard';
import { MarketplaceRuleCard } from './RuleCards/MarketplaceRuleCard';
import { SharedCostRuleCard } from './RuleCards/SharedCostRuleCard';
import { UntaggedCostRuleCard } from './RuleCards/UntaggedCostRuleCard';
import { RuleNameAndDescription } from './RuleNameAndDescription';
import { RuleStatus } from './RuleStatus';
import { RulePreviewContainer } from './RulePreview/RulePreviewContainer';

export function AllocationRuleEditorContainer({
    editorCtx,
    ruleEditor,
    onClose,
}: {
    editorCtx: AllocationRuleEditorContext;
    ruleEditor: AllocationRuleEditor;
    onClose: () => void;
}) {
    const theme = useMantineTheme();
    const notificationSvc = useDi(NotificationService);
    const ruleSave = useCallback(async () => {
        if (editorCtx.ruleEditor.canSave()) {
            const success = await editorCtx.ruleEditor.save();
            if (success) {
                onClose();
                notificationSvc.notify('Rule saved. Redirecting to rule dashboard.', '', 'success', <i className="ti ti-circle-check"></i>);
            } else {
                notificationSvc.notify('Failed to save rule!', 'Please try again or contact support', 'error', <AlertTriangle />);
            }
        }
    }, []);

    const canSave = useObserver(() => editorCtx.ruleEditor.canSave());

    return (
        <ShowbackRulePage>
            <RuleContainer>
                <RuleInnerContainer>
                    <RuleEditorContainer ruleEditor={ruleEditor} />
                </RuleInnerContainer>
                <Divider />
                <RuleInnerContainerBottom>
                    <Box>
                        <Box p="md">
                            <Group position="right">
                                <Button data-atid="SettingsCancelButton" onClick={onClose} variant="outline">
                                    Cancel
                                </Button>
                                <Button
                                    data-atid="SaveRuleButton"
                                    onClick={ruleSave}
                                    size="sm"
                                    disabled={!canSave}
                                    color={theme.colors?.primary?.[6] as CustomColors}
                                    leftIcon={editorCtx.ruleEditor.saving && <Loader color={'#fff' as CustomColors} size="xs" />}
                                >
                                    Save
                                </Button>
                            </Group>
                        </Box>
                    </Box>
                </RuleInnerContainerBottom>
            </RuleContainer>
            <Divider orientation="vertical" />
            {ruleEditor.rule ? <RulePreviewContainer ruleEditor={ruleEditor} /> : null}
        </ShowbackRulePage>
    );
}

function RuleEditorContainer({ ruleEditor }: { ruleEditor: AllocationRuleEditor }) {
    const rule = useObserver(() => ruleEditor.rule);
    const ruleType = useObserver(() => ruleEditor.rule?.RuleType);
    return (
        <>
            <Center mb={-16}>{rule ? <AllocationRuleTypeList rule={rule} /> : null}</Center>
            <Stack mx={24} spacing={18}>
                <RuleNameAndDescription ruleEditor={ruleEditor}></RuleNameAndDescription>
                {ruleType === 'UntaggedCosts' && <UntaggedCostRuleCard ruleEditor={ruleEditor} />}
                {ruleType === 'SharedCosts' && <SharedCostRuleCard ruleEditor={ruleEditor} />}
                {ruleType === 'Discounts' && <DiscountsRuleCard ruleEditor={ruleEditor} />}
                {ruleType === 'Marketplace' && <MarketplaceRuleCard ruleEditor={ruleEditor} />}
                {ruleType === 'Advanced' && <AdvancedRuleCard ruleEditor={ruleEditor} />}
                <RuleStatus ruleEditor={ruleEditor}></RuleStatus>
                <Space h="md" />
            </Stack>
        </>
    );
}

const RuleInnerContainer = styled.div`
    flex: 1;
    overflow: auto;
`;

const ShowbackRulePage = styled.div`
    height: 100%;
    display: flex;
`;

const RuleContainer = styled.div`
    display: flex;
    flex: 0;
    flex-direction: column;
    height: 100%;
    min-width: 800px;
    background-color: ${(p) => p.theme.colors.gray[1]};
`;

const RuleInnerContainerBottom = styled.div`
    background-color: ${({ theme }) => theme.colors.gray[3]};
    opacity: 1;
`;

import { getCompanyGetCompanyConfig } from '@apis/Customers';
import { injectable } from 'tsyringe';

interface CompanyConfig {
    MapWorkload?: 'Require';
    Export?: 'Block';
    ForecastMaxPredictionDays?: string;
}

@injectable()
export class CompanyConfigService {
    public async getCompanyConfig() {
        return await getCompanyGetCompanyConfig();
    }
    public async getTypedCompanyConfig() {
        const config = await this.getCompanyConfig();
        return config.reduce((acc, c) => ({ ...acc, [c.Key ?? '']: c.Value }), {} as CompanyConfig);
    }
}

import { usePostObservabilityQuery } from '@apis/Resources';
import { useEffect } from 'react';
import { useCompany } from '../../Router/CompanyContent';
import { KpiLineChart, KpiLineChartSettings } from '../../Charts/LineChart';
import { ResourceChart, ResourceChartTitle } from '@root/Design/ResourceContainer';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { NoDataMessage } from '../Design';

export function AwsResourceUsageChart(props: {
    resourceType: string;
    resourceId: string;
    metric: string;
    unit: string;
    operation: string;
    daysBack: number;
    frequency: number;
}) {
    const { data, isLoading, mutate: runQuery } = usePostObservabilityQuery();
    const fmtSvc = useDi(FormatService);
    const company = useCompany();
    let min: string | number = 0;
    let avg: string | number = 0;
    let max: string | number = 0;
    let unit = '';

    useEffect(() => {
        var endTime = new Date();
        endTime.setDate(endTime.getDate() + 1); // Make sure that we get all times during the current day
        var startTime = new Date();
        startTime.setDate(startTime.getDate() - props.daysBack);
        const frequency = props.frequency * 60;

        var StartTime = startTime.getFullYear() + '-' + ('00' + (startTime.getMonth() + 1)).slice(-2) + '-' + ('00' + startTime.getDate()).slice(-2);
        var EndTime = endTime.getFullYear() + '-' + ('00' + (endTime.getMonth() + 1)).slice(-2) + '-' + ('00' + endTime.getDate()).slice(-2);
        runQuery({
            data: {
                Requests: [
                    {
                        ResourceId: props.resourceId,
                        ResourceType: props.resourceType,
                        Metric: props.metric.replace(/_/g, ''),
                        Operation: props.operation,
                    },
                ],
                StartTime: StartTime,
                EndTime: EndTime,
                Period: frequency,
            },
            params: { companyId: company?.Id },
        });
    }, [props.frequency, props.daysBack]);

    var lineChart;
    if (isLoading) {
        lineChart = (
            <>
                <NoDataMessage>Loading...</NoDataMessage>
            </>
        );
    } else {
        if (data == null || data.Results == null || data.Results[0] == null) {
            lineChart = (
                <>
                    <NoDataMessage>No data available</NoDataMessage>
                </>
            );
        } else {
            let format: KpiLineChartSettings['format'] = 'int';
            min = 1000000;
            var records: any = [];
            var total = 0;
            for (var j = 0; j < data.Results.length; j++) {
                var yVal = data.Results[j].Values![0];
                if (yVal < min) {
                    min = yVal;
                }
                total += yVal;
                if (yVal > max) {
                    max = yVal;
                }

                var r = { id: props.metric, x: data.Results[j].Time, y: yVal };
                records.push(r);
            }
            if (data.Results.length > 0) {
                avg = total / (data.Results.length - 1);
            }
            min = Math.round(min * 10) / 10;
            avg = Math.round(avg * 10) / 10;
            max = Math.round(max * 10) / 10;

            var tickValues = [];
            if (data.Results.length > 10) {
                var l = data.Results.length - 1;
                tickValues.push(data.Results[0].Time);
                tickValues.push(data.Results[Math.round(l / 4)].Time);
                tickValues.push(data.Results[Math.round(l / 2)].Time);
                tickValues.push(data.Results[Math.round((l / 4) * 3)].Time);
                tickValues.push(data.Results[l].Time);
            }

            if (props.unit == 'Percent') {
                format = 'percent';
                unit = '%';
                for (var j = 0; j < records.length; j++) {
                    records[j].y = Math.round(records[j].y * 10) / 10;
                }
            } else if (props.unit == 'Bytes') {
                format = 'bytes';
                min = fmtSvc.formatBytes(Math.round(min * 10) / 10);
                avg = fmtSvc.formatBytes(Math.round(avg * 10) / 10);
                max = fmtSvc.formatBytes(Math.round(max * 10) / 10);
            } else {
                for (var j = 0; j < records.length; j++) {
                    records[j].y = Math.round(records[j].y * 10) / 10;
                }
            }

            lineChart = (
                <>
                    <KpiLineChart
                        data={records}
                        groups={['id', 'x']}
                        values={['y']}
                        settings={{ tickValues, yMax: props.unit == 'Percent' ? 100 : 0, margin: { left: 50 }, format }}
                    />
                </>
            );
        }
    }

    const title = `${props.metric.replace(/_/g, ' ')} ${unit != '' ? '(' + unit + ')' : ''}`;

    return (
        <ResourceChart key={props.metric + '_' + props.frequency + '_' + props.daysBack}>
            <ResourceChartTitle
                title={title}
                metrics={[
                    { label: 'Min', value: min, unit },
                    { label: 'Avg', value: avg, unit },
                    { label: 'Max', value: max, unit },
                ]}
            />
            {lineChart}
        </ResourceChart>
    );
}

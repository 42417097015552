import styled from '@emotion/styled';
import { ComponentType, ReactNode } from 'react';
import { ArrowsRight, Bolt, FileInvoice, IconProps, Tags } from 'tabler-icons-react';

export const appIcons = new Map<string, ComponentType<IconProps>>([
    ['tag manager', Tags],
    ['map manager', ArrowsRight],
    ['idle resources', Bolt],
    ['rightsizing', Bolt],
    ['invoice manager', FileInvoice],
]);

export const getProductIcon = (productName: string) => {
    return appIcons.get(productName.toLowerCase());
};

export const appIconCircle = (appName: string, features: string) => {
    return (
        <>
            <Circle>
                {appName === 'Tag Manager' ? (
                    <i className="ti ti-tags"></i>
                ) : appName === 'MAP Manager' ? (
                    <i className="ti ti-arrows-right"></i>
                ) : appName === 'Idle Resources' || appName === 'RightSizing' ? (
                    <i className="ti ti-bolt"></i>
                ) : appName === 'Invoice Manager' ? (
                    <i className="ti ti-file-invoice"></i>
                ) : (
                    <i className="ti ti-receipt"></i>
                )}
            </Circle>
            <div>
                <div>{appName}</div>
                <div style={{ fontSize: '12px' }}>{appName != 'Platform' ? features : 'All CloudSaver apps'}</div>
            </div>
        </>
    );
};

const Circle = styled.div`
    padding-top: 7px;
    margin-left: 12px;
    background-color: ${(p) => p.theme.colors.primary[2]};
    color: ${(p) => p.theme.colors.primary[6]};
    border-radius: 50%;
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 24px;
`;

import { AllocationDimension } from '@apis/Invoices/model';
import { Query } from '@apis/Invoices/model/query';
import { QueryExpr, QueryResult } from '@apis/Resources';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Center, Divider, Loader, Text } from '@mantine/core';
import { DataGrid } from '@root/Components/DataGrid';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { ColumnConfig, ColumnGroupConfig, DataGridState, ISelectionStrategy } from '@root/Components/DataGrid/Models';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { ShowbackPersistence } from '@root/Services/Invoices/ShowbackService';
import { ArrayDataSource } from '@root/Services/Query/ArrayDataSource';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronRight } from 'tabler-icons-react';
import { inject, injectable } from 'tsyringe';
import { ShowbackModel } from '../Models';

export type ReconciliationGridRow = {
    assetId?: string;
    invoiceAmount?: number;
    showbackAmount?: number;
    savingsPlans?: number;
    reservedInstances?: number;
    edp?: number;
    credits?: number;
    support?: number;
    data?: number;
    fees?: number;
    marketplace?: number;
    internal?: number;
    amortizationSavingsPlans?: number;
    amortizationReservedInstances?: number;
    otherSavingsPlans?: number;
    otherReservedInstances?: number;
};

@injectable()
export class ReconciliationGridModel {
    public onFilterChanged?: (filters: QueryExpr[]) => void;
    public baseDataSourceAllocated!: ReconciliationGridRow[];
    public baseDataSourceUnallocated!: ReconciliationGridRow[];
    public allocatedDataSource!: ArrayDataSource;
    public unallocatedDataSource!: ArrayDataSource;
    public datasource!: ReconciliationGridRow[];
    public datasourceTotal!: ReconciliationGridRow[];
    public datasourceUnallocated!: ReconciliationGridRow[];
    public datasourceUnallocatedTotal!: ReconciliationGridRow[];
    public totalData!: ReconciliationGridRow[];
    public gridModel?: DataGridModel;
    public sortDatasource?: (state: DataGridState) => void;
    public columns!: ColumnConfig<ReconciliationGridRow>[];
    public parentGroup!: { label: string; value: string }[];
    public queryApi!: (query: Query) => Promise<QueryResult<any>>;
    public initializing = new EventEmitter(true);
    public dataRefreshNeeded = EventEmitter.empty();
    public groupConfig: { [groupName: string]: ColumnGroupConfig } = {
        Reconciliation: { color: '#ECFDF3', noOverlay: true },
        ['Discount Plans']: { color: '#E6E2F1', noOverlay: true },
        Charges: { color: '#FCE4D3', noOverlay: true },
        Amortization: { color: '#D2F2DD', noOverlay: true },
        Other: { color: '#BCC7D8', noOverlay: true },
    };
    public month?: Date;
    public invalidateDatasource?: () => void;
    public allocDimName?: string;

    public constructor(
        @inject(FormatService) private readonly formatSvc: FormatService,
        @inject(ShowbackPersistence) private readonly showbackPersistence: ShowbackPersistence,
        @inject(InvoiceApiService) private readonly invoiceApi: InvoiceApiService
    ) {}

    public init(month: Date, allocDim: AllocationDimension, state?: DataGridState) {
        this.month = month;
        this.queryApi = (query: Query) => this.invoiceApi.queryByUsageMonth(query, month);
        this.initialize(allocDim, state);
        return this;
    }

    public async initialize(allocDim: AllocationDimension, state?: DataGridState) {
        try {
            this.initializing.emit(true);
            this.allocDimName = this.showbackPersistence.getDimensionName(allocDim);
            this.baseDataSourceAllocated = await this.createDataSource(this.month!, allocDim);
            this.baseDataSourceUnallocated = await this.createDataSourceUnallocated(this.month!, allocDim);
            this.columns = this.createColumns();
        } finally {
            this.initializing.emit(false);
        }
    }

    public updateData(allocDataSource?: ReconciliationGridRow[], unallocDataSource?: ReconciliationGridRow[]) {
        this.datasource = allocDataSource ?? this.baseDataSourceAllocated;
        this.datasourceUnallocated = unallocDataSource ?? this.baseDataSourceUnallocated;
        this.datasourceTotal = this.getDataSourceTotal(true);
        this.datasourceUnallocatedTotal = this.getDataSourceTotal(false);
        this.totalData = this.getAllTotal();
        this.dataRefreshNeeded.emit();
    }

    public setOnFilterChanged(handler: ((filters: QueryExpr[]) => void) | undefined) {
        if (handler !== this.onFilterChanged) {
            this.onFilterChanged = handler;
        }
        return this;
    }

    private async createDataSource(month: Date, allocDim: AllocationDimension) {
        const data = await this.showbackPersistence.getAllocatedData(allocDim, month);
        return data?.Results ?? [];
    }

    private async createDataSourceUnallocated(month: Date, allocDim: AllocationDimension) {
        const data = await this.showbackPersistence.getUnallocatedData(allocDim, month);
        return data ?? [];
    }

    private getAllTotal() {
        const allocatedTotal = this.datasourceTotal[0] ?? {};
        const unallocatedTotal = this.datasourceUnallocatedTotal[0] ?? {};

        const total: ReconciliationGridRow = {
            assetId: 'Total',
            invoiceAmount: (allocatedTotal.invoiceAmount ?? 0) + (unallocatedTotal.invoiceAmount ?? 0),
            showbackAmount: (allocatedTotal.showbackAmount ?? 0) + (unallocatedTotal.showbackAmount ?? 0),
            savingsPlans: (allocatedTotal?.savingsPlans ?? 0) + (unallocatedTotal?.savingsPlans ?? 0),
            reservedInstances: (allocatedTotal?.reservedInstances ?? 0) + (unallocatedTotal?.reservedInstances ?? 0),
            edp: (allocatedTotal?.edp ?? 0) + (unallocatedTotal?.edp ?? 0),
            credits: (allocatedTotal?.credits ?? 0) + (unallocatedTotal?.credits ?? 0),
            support: (allocatedTotal?.support ?? 0) + (unallocatedTotal?.support ?? 0),
            fees: (allocatedTotal?.fees ?? 0) + (unallocatedTotal?.fees ?? 0),
            marketplace: (allocatedTotal?.marketplace ?? 0) + (unallocatedTotal?.marketplace ?? 0),
            internal: (allocatedTotal?.internal ?? 0) + (unallocatedTotal?.internal ?? 0),
            amortizationSavingsPlans: (allocatedTotal?.amortizationSavingsPlans ?? 0) + (unallocatedTotal?.amortizationSavingsPlans ?? 0),
            amortizationReservedInstances:
                (allocatedTotal?.amortizationReservedInstances ?? 0) + (unallocatedTotal?.amortizationReservedInstances ?? 0),
            otherSavingsPlans: (allocatedTotal?.otherSavingsPlans ?? 0) + (unallocatedTotal?.otherSavingsPlans ?? 0),
            otherReservedInstances: (allocatedTotal?.otherReservedInstances ?? 0) + (unallocatedTotal?.otherReservedInstances ?? 0),
        };

        return [total];
    }

    private getDataSourceTotal(isAllocated: boolean) {
        const data = isAllocated ? this.datasource : this.datasourceUnallocated;
        if (data.length === 0) {
            return [];
        }
        const valuePropertyKeys = Object.keys(data[0]) as (keyof ReconciliationGridRow)[];

        const initialTotals = valuePropertyKeys.reduce((acc, key) => ({ ...acc, [key]: 0 }), {}) as Record<string, number>;
        const total = data.reduce((sum, current) => {
            valuePropertyKeys.forEach((key) => {
                if (typeof current[key] === 'number') {
                    if (!sum[key]) {
                        sum[key] = 0;
                    }
                    sum[key] += (current[key] as number) ?? 0;
                }
            });
            return sum;
        }, initialTotals);
        const totalRow = total as ReconciliationGridRow;
        totalRow.assetId = 'Total';
        return [totalRow];
    }

    private createColumns() {
        const result: ColumnConfig<ReconciliationGridRow>[] = [
            {
                accessor: 'assetId',
                header: `${this.allocDimName}`,
                defaultWidth: 200,
                id: 'assetId',
                type: 'string',
                sortField: 'assetId',
                filter: {
                    filterType: 'string',
                    name: this.allocDimName ?? '',
                    filterField: 'assetId',
                    options: {
                        getValueProvider: () => this.baseDataSourceAllocated.map((r) => r.assetId ?? '').map((v) => ({ value: v, label: v })),
                    },
                },
            },
            {
                header: 'Invoice Amount',
                accessor: 'invoiceAmount',
                defaultWidth: 125,
                id: 'invoiceAmount',
                type: 'number',
                align: 'right',
                sortField: 'invoiceAmount',
                headerRenderer: () => (
                    <div style={{ lineHeight: '25px' }}>
                        Invoice <br /> Amount
                    </div>
                ),
                formatter: (v) => this.formatSvc.formatMoneyNonZeroTwoDecimals(v.invoiceAmount ?? 0),
                groupName: 'Reconciliation',
                filter: {
                    filterField: 'invoiceAmount',
                    name: 'Invoice Amount',
                    filterType: 'number',
                    fieldPickerRenderer: () => 'Invoice Amount',
                },
            },
            {
                header: 'Showback Amount',
                accessor: 'showbackAmount',
                defaultWidth: 125,
                id: 'showbackAmount',
                type: 'number',
                align: 'right',
                sortField: 'showbackAmount',
                headerRenderer: () => (
                    <div style={{ lineHeight: '25px' }}>
                        Showback <br /> Amount
                    </div>
                ),
                formatter: (v) => this.formatSvc.formatMoneyNonZeroTwoDecimals(v.showbackAmount ?? 0),
                groupName: 'Reconciliation',
                filter: {
                    filterField: 'showbackAmount',
                    name: 'Showback Amount',
                    filterType: 'number',
                    fieldPickerRenderer: () => 'Showback Amount',
                },
            },
            {
                header: 'Savings Plans',
                accessor: 'savingsPlans',
                defaultWidth: 125,
                id: 'savingsPlans',
                type: 'number',
                align: 'right',
                sortField: 'savingsPlans',
                formatter: (v) => this.formatSvc.formatMoneyNonZeroTwoDecimals(v.savingsPlans ?? 0),
                groupName: 'Discount Plans',
                filter: true,
            },
            {
                header: 'Reserved Instances',
                accessor: 'reservedInstances',
                defaultWidth: 125,
                id: 'reservedInstances',
                type: 'number',
                align: 'right',
                sortField: 'reservedInstances',
                headerRenderer: () => (
                    <div style={{ lineHeight: '25px' }}>
                        Reserved <br /> Instances
                    </div>
                ),
                formatter: (v) => this.formatSvc.formatMoneyNonZeroTwoDecimals(v.reservedInstances ?? 0),
                groupName: 'Discount Plans',
                filter: {
                    filterField: 'reservedInstances',
                    name: 'Reserved Instances',
                    filterType: 'number',
                    fieldPickerRenderer: () => 'Reserved Instances',
                },
            },
            {
                header: 'EDP',
                accessor: 'edp',
                defaultWidth: 75,
                id: 'edp',
                type: 'number',
                align: 'right',
                sortField: 'edp',
                formatter: (v) => this.formatSvc.formatMoneyNonZeroTwoDecimals(v.edp ?? 0),
                groupName: 'Discount Plans',
                filter: true,
            },
            {
                header: 'Credits',
                accessor: 'credits',
                defaultWidth: 125,
                id: 'credits',
                type: 'number',
                align: 'right',
                sortField: 'credits',
                formatter: (v) => this.formatSvc.formatMoneyNonZeroTwoDecimals(v.credits ?? 0),
                groupName: 'Discount Plans',
                filter: true,
            },
            {
                header: 'Support',
                accessor: 'support',
                defaultWidth: 100,
                id: 'support',
                type: 'number',
                align: 'right',
                sortField: 'support',
                formatter: (v) => this.formatSvc.formatMoneyNonZeroTwoDecimals(v.support ?? 0),
                groupName: 'Charges',
                filter: true,
            },
            {
                header: 'Fees',
                accessor: 'fees',
                defaultWidth: 75,
                id: 'fees',
                type: 'number',
                align: 'right',
                sortField: 'fees',
                formatter: (v) => this.formatSvc.formatMoneyNonZeroTwoDecimals(v.fees ?? 0),
                groupName: 'Charges',
                filter: true,
            },
            {
                header: 'Marketplace',
                accessor: 'marketplace',
                defaultWidth: 125,
                id: 'marketplace',
                type: 'number',
                align: 'right',
                sortField: 'marketplace',
                formatter: (v) => this.formatSvc.formatMoneyNonZeroTwoDecimals(v.marketplace ?? 0),
                groupName: 'Charges',
                filter: true,
            },
            {
                header: 'Internal',
                accessor: 'internal',
                defaultWidth: 125,
                id: 'internal',
                type: 'number',
                align: 'right',
                sortField: 'internal',
                formatter: (v) => this.formatSvc.formatMoneyNonZeroTwoDecimals(v.internal ?? 0),
                groupName: 'Charges',
                filter: true,
            },
            {
                header: 'Savings Plans',
                accessor: 'amortizationSavingsPlans',
                defaultWidth: 125,
                id: 'amortizationSavingsPlans',
                type: 'number',
                align: 'right',
                sortField: 'amortizationSavingsPlans',
                formatter: (v) => this.formatSvc.formatMoneyNonZeroTwoDecimals(v.amortizationSavingsPlans ?? 0),
                groupName: 'Amortization',
                filter: true,
            },
            {
                header: 'Reserved Instances',
                accessor: 'amortizationReservedInstances',
                defaultWidth: 125,
                id: 'amortizationReservedInstances',
                type: 'number',
                align: 'right',
                sortField: 'amortizationReservedInstances',
                headerRenderer: () => (
                    <div style={{ lineHeight: '25px' }}>
                        Reserved <br /> Instances
                    </div>
                ),
                formatter: (v) => this.formatSvc.formatMoneyNonZeroTwoDecimals(v.amortizationReservedInstances ?? 0),
                groupName: 'Amortization',
                filter: {
                    filterField: 'amortizationReservedInstances',
                    name: 'Reserved Instances',
                    filterType: 'number',
                    fieldPickerRenderer: () => 'Reserved Instances',
                },
            },
        ];
        return result;
    }

    public attach = (gridModel: DataGridModel) => {
        this.gridModel = gridModel;
        this.updateData();

        gridModel.gridStateChanged.listen((change) => {
            this.allocatedDataSource ??= gridModel.createArrayDataSource(this.baseDataSourceAllocated);
            this.unallocatedDataSource ??= gridModel.createArrayDataSource(this.baseDataSourceUnallocated);
            const updatedAllocDataSource = this.allocatedDataSource.applyState(change?.state ?? gridModel.gridState);
            const updatedUnallocDataSource = this.unallocatedDataSource.applyState(change?.state ?? gridModel.gridState);
            this.updateData(updatedAllocDataSource, updatedUnallocDataSource);
        });
    };

    public handleStateLoaded = () => {
        this.invalidateDatasource?.();
        this.onFilterChanged?.((this.gridModel?.gridState.filters ?? []).slice() as QueryExpr[]);
    };
}

export function ReconciliationGrid({
    onFilterChanged,
    persistenceKey,
    selectionStrategy,
    model,
}: {
    onFilterChanged?: (filters: QueryExpr[]) => void;
    persistenceKey: string;
    selectionStrategy?: ISelectionStrategy<ReconciliationGridRow>;
    model: ShowbackModel;
}) {
    const theme = useTheme();
    const container = useDiContainer();
    const selectedMonth = useEventValue(model.selectedMonthChanged)!;
    const [dataCount, setDataCount] = useState<ReactNode>('');
    const fmtSvc = useDi(FormatService);

    const [gridState, setGridState] = useState<DataGridState>();
    const gridModel = useMemo(() => {
        const reconciliationGridModel = container
            .resolve(ReconciliationGridModel)
            .init(selectedMonth, model.selectedAllocationDimension!, gridState)
            .setOnFilterChanged(onFilterChanged);
        return reconciliationGridModel;
    }, [selectedMonth]);

    useEffect(() => {
        gridModel.setOnFilterChanged(onFilterChanged);
    }, [onFilterChanged]);
    const initializing = useEventValue(gridModel.initializing);
    const [allocatedGridCollapsed, setAllocatedGridCollapsed] = useState(false);
    const [unallocatedGridCollapsed, setUnallocatedGridCollapsed] = useState(false);
    const getCollapseStyle = (collapsed: boolean, maxHeight?: number) => ({
        background: '#fff',
        transition: 'max-height 400ms',
        maxHeight: collapsed ? 0 : maxHeight ?? '100%',
    });

    const toggleAllocated = useCallback(() => setAllocatedGridCollapsed((v) => !v), [setAllocatedGridCollapsed]);
    const toggleUnallocated = useCallback(() => setUnallocatedGridCollapsed((v) => !v), [setUnallocatedGridCollapsed]);
    useEffect(() => {
        const count = gridModel.datasource?.length;
        setDataCount(
            <Text data-atid={'DataGridResults:' + count?.toString()} color="dimmed">
                {fmtSvc.formatInt(count ?? 0)} result{count == 1 ? '' : 's'}
            </Text>
        );
    }, [gridModel.datasource]);
    useEvent(gridModel.dataRefreshNeeded);

    return initializing ? (
        <Center>
            <Loader />
        </Center>
    ) : (
        <DataGrid
            key={persistenceKey}
            columns={gridModel.columns}
            dataSource={[]}
            headerHeight={50}
            groupConfig={gridModel.groupConfig}
            onModelLoaded={gridModel.attach}
            minimumLoadingMs={0}
            showCount={false}
            leftResultsPlaceHolder={dataCount}
            showHeaderGroups
            showRefresh
            gridTitle={
                <>
                    <Divider />
                    <GridSectionCollapser noBorder onClick={toggleAllocated} label="Allocated Costs" collapsed={allocatedGridCollapsed} />
                </>
            }
            splitBodyProps={[
                {
                    datasource: gridModel.datasource,
                    columns: gridModel.columns,
                    style: getCollapseStyle(allocatedGridCollapsed, gridModel.datasource?.length * 30 + 2),
                },
                {
                    datasource: gridModel.datasourceTotal,
                    columns: gridModel.columns,
                    height: 31,
                    style: { background: theme.colors.gray[0], borderTopWidth: 0, fontWeight: 'bold' },
                },
                {
                    renderer: () => {
                        return <GridSectionCollapser label="Unallocated Costs" onClick={toggleUnallocated} collapsed={unallocatedGridCollapsed} />;
                    },
                },
                {
                    datasource: gridModel.datasourceUnallocated,
                    columns: gridModel.columns,
                    style: getCollapseStyle(unallocatedGridCollapsed),
                },
                {
                    datasource: gridModel.datasourceUnallocatedTotal,
                    columns: gridModel.columns,
                    height: 31,
                    style: { background: theme.colors.gray[0], borderTopWidth: 0, fontWeight: 'bold' },
                },
                {
                    datasource: gridModel.totalData,
                    columns: gridModel.columns,
                    height: 50,
                    style: { background: theme.colors.warning[2], borderTopWidth: 0, fontWeight: 'bold' },
                    scrollable: true,
                },
            ]}
        />
    );
}

function GridSectionCollapser({
    label,
    collapsed,
    onClick,
    noBorder,
}: {
    label: string;
    collapsed: boolean;
    onClick: () => void;
    noBorder?: boolean;
}) {
    return (
        <GridSectionCollapserEl noBorder={!!noBorder} onClick={onClick}>
            <ChevronRight size={20} style={{ transform: collapsed ? 'rotate(0deg)' : 'rotate(90deg)', transition: 'all 400ms' }} />
            <Text span weight="600">
                {label}
            </Text>
        </GridSectionCollapserEl>
    );
}

const GridSectionCollapserEl = styled.a<{ noBorder: boolean }>`
    cursor: pointer;
    display: flex;
    height: 30px;
    align-items: center;
    padding: 0 16px;
    gap: 8px;
    background: ${(p) => p.theme.colors.primary[2]};
    border-color: ${(p) => p.theme.colors.gray[4]};
    border-style: solid;
    border-width: 0 ${(p) => (p.noBorder ? 0 : '1px')};
    &:hover {
        background: ${(p) => p.theme.colors.primary[1]};
    }
`;

import { AppFeaturesSystemName } from '@apis/Customers/model';
import { Text, Title } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { CompanyContextService } from '@root/Services/Customers/CompanyContext';
import { CompanyFeatureService } from '@root/Services/Customers/CompanyFeatureService';
import { useDi } from '@root/Services/DI';
import { useNav } from '@root/Services/NavigationService';
import { ReactNode, useEffect, useState } from 'react';
import { useCompany } from '../Router/CompanyContent';

export function SubscriptionCheck({ children, app, featureName }: { children: () => ReactNode; app: AppFeaturesSystemName; featureName?: string }) {
    const [allow, setAllow] = useState<boolean>();
    const companyFeatureSvc = useDi(CompanyFeatureService);
    const { goto } = useNav();
    const companyContext = useDi(CompanyContextService);
    const company = useCompany();
    useEffect(() => {
        (async () => {
            const allowed = await companyFeatureSvc.checkAccess(company ?? {}, app, featureName);
            setAllow(allowed);
        })();
    }, []);

    if (allow === false) {
        if (companyContext.parentCompany !== null && companyContext.parentCompany?.Type === 'Msp') {
            const myCompaniesUrl = '/' + companyContext.parentCompany?.DatabaseName!.toLowerCase() + '/msp/my-companies';
            goto(myCompaniesUrl);
        } else if (companyContext.parentCompany !== null && companyContext.parentCompany?.Type === 'Support') {
            const myCompaniesUrl = '/' + companyContext.parentCompany?.DatabaseName!.toLowerCase() + '/read-only/my-support-companies';
            goto(myCompaniesUrl);
        } else {
            openConfirmModal({
                title: <Title order={3}>Check Subscription</Title>,
                children: <Text>Your subscription has expired or does not allow access to {featureName}.</Text>,
                style: { outline: 'none' },
                labels: {
                    confirm: 'Check Subscription',
                    cancel: 'Go Back',
                },
                withCloseButton: false,
                closeOnClickOutside: false,
                overlayBlur: 5,
                cancelProps: {
                    hidden: false,
                },
                onConfirm: () => {
                    goto('settings/subscription');
                },
                onCancel: () => {
                    window.history.back();
                },
            });
        }
    }

    return <>{allow ? children() : null}</>;
}

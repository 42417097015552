import { Text } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';

export interface confirmActionProps {
    headerText: string;
    promptText: string;
    confirmButtonText: string;
    cancelButtonText: string;
    confirmAction: () => void;
}

export const confirmAction = (props: confirmActionProps) => {
    openConfirmModal({
        title: (
            <span>
                <b>{props.headerText}</b>
            </span>
        ),
        children: <Text>{props.promptText}</Text>,
        centered: true,
        labels: { confirm: props.confirmButtonText, cancel: props.cancelButtonText },
        onConfirm: () => props.confirmAction(),
        confirmProps: { color: 'primary' },
    });
};

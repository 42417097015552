import {
    MapCreditsOptions,
    MapCreditsOptionsMapCreditType,
    MapCreditsOptionsMapDestination,
    MapCreditsOptionsMapMigrationType,
} from '@apis/TagManager/model';

export const getLabelFromValue = (value: string) => {
    switch (value) {
        case MapCreditsOptionsMapCreditType.OnPrem:
            return 'On-Premises';
        case MapCreditsOptionsMapCreditType.OnPremCommercial:
            return 'On-Premises - Commercial DB&A';
        case MapCreditsOptionsMapCreditType.OnPremNonCommercial:
            return 'On-Premises - Non-commercial DB&A';
        case MapCreditsOptionsMapCreditType.Ec2Commercial:
            return 'EC2 - Commercial DB&A';
        case MapCreditsOptionsMapCreditType.Ec2NonCommercial:
            return 'EC2 - Non-commercial DB&A';
        case MapCreditsOptionsMapMigrationType.ComputeStorageAndOther:
            return 'Compute, Storage, and Other';
        case MapCreditsOptionsMapMigrationType.DatabaseAndAnalytics:
            return 'Database and Analytics';
        case MapCreditsOptionsMapMigrationType.SAP:
            return 'SAP';
        case MapCreditsOptionsMapMigrationType.Oracle:
            return 'Oracle';
        case MapCreditsOptionsMapDestination.AWS:
            return 'AWS';
        case MapCreditsOptionsMapDestination.AnyAWSDBAService:
            return 'Any AWS DB&A Service';
        case MapCreditsOptionsMapDestination.EC2:
            return 'EC2';
        default:
            return value;
    }
};

export interface MigrationMapping {
    migrationType: string;
    sourceType: string;
    destinationType: string;
    tagValue: string;
}

export interface SelectOption {
    value: string;
    label: string;
}

export const getMigrationTypes = () => {
    const options = MapCreditsMapMigrationOptions.map(
        (x) =>
            ({
                value: x.migrationType.toString(),
                label: getLabelFromValue(x.migrationType),
            } as SelectOption)
    );

    return uniqueEntriesOnly(options);
};

const uniqueEntriesOnly = (options: SelectOption[]) => {
    const uniqueOptions: SelectOption[] = [];
    options.filter(function (item) {
        var i = uniqueOptions.findIndex((x) => x.value == item.value && x.label == item.label);
        if (i <= -1) {
            uniqueOptions.push(item);
        }
        return null;
    });
    return uniqueOptions.sort((a, b) => a.label.localeCompare(b.label));
};

export const getSourceTypes = (migrationType?: string) => {
    const options = MapCreditsMapMigrationOptions.filter((x) => x.migrationType === migrationType).map(
        (x) =>
            ({
                value: x.sourceType.toString(),
                label: getLabelFromValue(x.sourceType),
            } as SelectOption)
    );

    return uniqueEntriesOnly(options);
};

export const getDestinationTypes = (migrationType?: string, sourceType?: string) => {
    const options = MapCreditsMapMigrationOptions.filter((x) => x.migrationType === migrationType && x.sourceType === sourceType).map(
        (x) =>
            ({
                value: x.destinationType.toString(),
                label: getLabelFromValue(x.destinationType),
            } as SelectOption)
    );

    return uniqueEntriesOnly(options);
};

export const getTagValue = (migrationType: string, sourceType: string, destinationType: string) => {
    return (
        MapCreditsMapMigrationOptions.find(
            (x) => x.migrationType === migrationType && x.sourceType === sourceType && x.destinationType === destinationType
        )?.tagValue ?? null
    );
};

export const getSuggestedName = (rule: MapCreditsOptions) => {
    return `${getLabelFromValue(rule.MapMigrationType!)}: ${getLabelFromValue(rule.MapCreditType!)} for ${getLabelFromValue(rule.MapDestination!)}`;
};

export const MapCreditsMapMigrationOptions = [
    {
        migrationType: MapCreditsOptionsMapMigrationType.ComputeStorageAndOther,
        sourceType: MapCreditsOptionsMapCreditType.OnPrem,
        destinationType: MapCreditsOptionsMapDestination.AWS,
        tagValue: 'mig',
    },
    {
        migrationType: MapCreditsOptionsMapMigrationType.DatabaseAndAnalytics,
        sourceType: MapCreditsOptionsMapCreditType.OnPremCommercial,
        destinationType: MapCreditsOptionsMapDestination.AnyAWSDBAService,
        tagValue: 'comm',
    },
    {
        migrationType: MapCreditsOptionsMapMigrationType.DatabaseAndAnalytics,
        sourceType: MapCreditsOptionsMapCreditType.OnPremNonCommercial,
        destinationType: MapCreditsOptionsMapDestination.AnyAWSDBAService,
        tagValue: 'mig',
    },
    {
        migrationType: MapCreditsOptionsMapMigrationType.DatabaseAndAnalytics,
        sourceType: MapCreditsOptionsMapCreditType.OnPremCommercial,
        destinationType: MapCreditsOptionsMapDestination.EC2,
        tagValue: 'mig',
    },
    {
        migrationType: MapCreditsOptionsMapMigrationType.DatabaseAndAnalytics,
        sourceType: MapCreditsOptionsMapCreditType.OnPremNonCommercial,
        destinationType: MapCreditsOptionsMapDestination.EC2,
        tagValue: 'mig',
    },
    {
        migrationType: MapCreditsOptionsMapMigrationType.DatabaseAndAnalytics,
        sourceType: MapCreditsOptionsMapCreditType.Ec2Commercial,
        destinationType: MapCreditsOptionsMapDestination.AnyAWSDBAService,
        tagValue: 'comm_ec2_',
    },
    {
        migrationType: MapCreditsOptionsMapMigrationType.DatabaseAndAnalytics,
        sourceType: MapCreditsOptionsMapCreditType.Ec2NonCommercial,
        destinationType: MapCreditsOptionsMapDestination.AnyAWSDBAService,
        tagValue: 'mig_ec2_',
    },
    {
        migrationType: MapCreditsOptionsMapMigrationType.SAP,
        sourceType: MapCreditsOptionsMapCreditType.OnPrem,
        destinationType: MapCreditsOptionsMapDestination.AWS,
        tagValue: 'sap',
    },
    {
        migrationType: MapCreditsOptionsMapMigrationType.Oracle,
        sourceType: MapCreditsOptionsMapCreditType.OnPrem,
        destinationType: MapCreditsOptionsMapDestination.AWS,
        tagValue: 'oracle',
    },
];

import { getUserGetMyProfile, getUserLoginCallback, getUserLogout } from '@apis/Customers';
import { User } from '@apis/Customers/model';
import { inject, singleton } from 'tsyringe';
import { BasicApi } from './BasicApi';
import { ConfigService } from './ConfigService';
import { EventEmitter } from './EventEmitter';
import { Logger } from './Logger';
import { MspService } from './MspService';

@singleton()
export class AuthenticationService {
    public user?: User;
    private pendingAuth?: {
        resolver: () => void;
        promise: Promise<void>;
    };

    private pollFrequencyMs = 1000;
    private refreshPollerTimeout = 0;
    private usingPartnerAuth = false;
    private refreshFrequencyMs = 1000 * 60 * 10;

    public authenticationNeeded = new EventEmitter<boolean>(false);
    public initialLoginNeeded = new EventEmitter(false);
    public tokenLoginNeeded = new EventEmitter(false);
    public unknownError = new EventEmitter(false);

    public constructor(
        @inject(BasicApi) private readonly basicApi: BasicApi,
        @inject(ConfigService) private readonly configSvc: ConfigService,
        @inject(MspService) private readonly mspSvc: MspService,
        @inject(Logger) private readonly logger: Logger
    ) {}
    public async init() {
        this.basicApi.registerResponseHandler(this.checkAuthN);
        await this.loadMyProfile();
    }

    public async signout() {
        if (this.usingPartnerAuth) {
            this.partnerLogout();
        }
        window.location.href = this.mspSvc.config?.LogoutUrl ?? this.configSvc.config.logoutUrl;
    }

    private checkAuthN = async (apiResponse: Response) => {
        if (this.pendingAuth) {
            await this.pendingAuth.promise;
            return { retry: true };
        } else if (apiResponse.status === 401) {
            await this.waitForAuth();
            return { retry: true };
        } else {
            this.pendingAuth = undefined;
            return { retry: false };
        }
    };

    private async waitForAuth() {
        this.authenticationNeeded.emit(true);
        let resolver: () => void = () => {};
        const promise = new Promise<void>((r) => (resolver = r));
        this.pendingAuth = { resolver, promise };
        while (!(await this.pollForAuth())) {
            await new Promise((r) => setTimeout(r, this.pollFrequencyMs));
        }
        resolver();
        this.pendingAuth = undefined;
        this.authenticationNeeded.emit(false);
    }

    private async loadMyProfile() {
        if (!(await this.pollForAuth())) {
            this.initialLoginNeeded.emit(true);
            await this.waitForAuth();
        }
        while (!(await this.loadProfile())) {
            await this.waitForVerification('emailverified');
        }
    }

    private async loadProfile() {
        try {
            const { url, request } = this.basicApi.createRequest({ method: 'GET', url: '/User/GetMyProfile' }, 'Customers');
            const response = await fetch(url, request);
            if (response.status === 401) {
                this.tokenLoginNeeded.emit(true);
                return false;
            } else if (response.status === 403) {
                return false;
            } else if (response.status >= 400) {
                this.unknownError.emit(true);
                return false;
            }
            this.tokenLoginNeeded.emit(false);
            this.unknownError.emit(false);
            this.user = await response.json();
            return true;
        } catch (err) {
            console.error(err);
            this.tokenLoginNeeded.emit(true);
            return false;
        }
    }

    private async pollForAuth() {
        try {
            return (await this.pollForPartnerAuth()) || (await this.pollForNativeAuth());
        } catch {
            return false;
        }
    }

    private async pollForPartnerAuth() {
        try {
            clearTimeout(this.refreshPollerTimeout);
            const { url, request } = this.basicApi.createRequest({ method: 'GET', url: '/user/BearerLogin?checkAccess=true' }, 'Customers');
            const response = await fetch(url, { ...request, redirect: 'manual' });
            if (response.status < 400) {
                this.periodicallyRefresh();
            }
            this.usingPartnerAuth = true;
            return response.status < 400;
        } catch {
            return false;
        }
    }

    private async partnerLogout() {
        try {
            const { url, request } = this.basicApi.createRequest({ method: 'GET', url: '/user/BearerLogin?logout=true' }, 'Customers');
            await fetch(url, { ...request, redirect: 'manual' });
        } catch (err) {
            this.logger.error('Failed while trying to log out partner', err);
        }
    }

    private async pollForNativeAuth() {
        try {
            const { url, request } = this.basicApi.createRequest({ method: 'GET', url: '/user/logincallback?checkAccess=true' }, 'Customers');
            const response = await fetch(url, { ...request, redirect: 'manual' });
            return response.status !== 401 && response.type !== 'opaqueredirect';
        } catch {
            return false;
        }
    }

    private periodicallyRefresh(retry = 0) {
        this.refreshPollerTimeout = setTimeout(async () => {
            if (retry >= 3) {
                this.signout();
            }
            try {
                const { url, request } = this.basicApi.createRequest({ method: 'GET', url: '/user/BearerLogin?refresh=true' }, 'Customers');
                const response = await fetch(url, { ...request, redirect: 'manual' });
                this.periodicallyRefresh(response.status < 400 ? 0 : retry + 1);
            } catch (err) {
                this.logger.error('Failed while trying to refresh token', err);
                this.periodicallyRefresh(retry + 1);
            }
        }, this.refreshFrequencyMs) as any;
    }

    public async waitForVerification(verificationType: string) {
        let signedIn = false;
        do {
            while (!(await this.checkVerification(verificationType))) {
                await new Promise((r) => setTimeout(r, this.pollFrequencyMs));
            }
            if ((await this.tryGetToken()) && (await this.loadProfile())) {
                signedIn = true;
            }
        } while (!signedIn);
    }

    private async checkVerification(verificationType: string) {
        try {
            const { url, request } = this.basicApi.createRequest({ method: 'GET', url: `/user/${verificationType}` }, 'Customers');
            const response = await fetch(url, { ...request, redirect: 'manual' });
            if (response.status === 401) {
                this.unknownError.emit(true);
            }
            const result = (await response.json()) as { Verified: boolean };
            return result.Verified;
        } catch {
            return false;
        }
    }

    private async tryGetToken() {
        try {
            const { url, request } = this.basicApi.createRequest({ method: 'GET', url: '/user/logincallback?getToken=true' }, 'Customers');
            const response = await fetch(url, { ...request, redirect: 'manual' });
            return response.status !== 401 && response.type !== 'opaqueredirect';
        } catch {
            return false;
        }
    }
}

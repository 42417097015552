import { CWMetricsConfigData } from '@apis/Customers/model';
import { EventEmitter } from '@root/Services/EventEmitter';
import { injectable, Lifecycle, scoped } from 'tsyringe';

@scoped(Lifecycle.ContainerScoped)
export class ConfigAdministrationModel {
    public isModifiedChanged = new EventEmitter<boolean>(false);
    public isFilterDataModified = new EventEmitter<boolean>(false);
    public isConfigDataModified = new EventEmitter<boolean>(false);
    public changeFiltersToDefault = new EventEmitter<boolean>(false);
    public changeConfigDataToDefault = new EventEmitter<boolean>(false);
    public isRefreshNeeded = new EventEmitter<boolean>(false);
    public isResyncNeeded = new EventEmitter<boolean>(false);
}

import { Router } from '@root/Services/Router/Router';
import { inject, singleton } from 'tsyringe';
import { AuthenticationService } from './AuthenticationService';
import { AuthorizationService } from './AuthorizationService';
import { AwsService } from './AwsService';
import { AppFeatureService } from './Customers/AppFeatureService';
import { EventEmitter } from './EventEmitter';
import { ConsoleLogReceiver, LoggerConfig } from './Logger';
import { MonitoringService } from './MonitoringService';
import { MspService } from './MspService';
import { SplashService } from './SplashService';

@singleton()
export class System {
    public siteReady = new EventEmitter(false);

    public constructor(
        @inject(MonitoringService) private monitoringSvc: MonitoringService,
        @inject(Router) private router: Router,
        @inject(LoggerConfig) private loggerConfig: LoggerConfig,
        @inject(ConsoleLogReceiver) private consoleLogger: ConsoleLogReceiver,
        @inject(AuthenticationService) private authNSvc: AuthenticationService,
        @inject(AuthorizationService) private authZSvc: AuthorizationService,
        @inject(MspService) private mspSvc: MspService,
        @inject(SplashService) private splashService: SplashService,
        @inject(AwsService) private awsSvc: AwsService,
        @inject(AppFeatureService) private appFeatureSvc: AppFeatureService
    ) {}

    public async init() {
        try {
            this.monitoringSvc.init();

            this.loggerConfig.configure([this.consoleLogger]);

            this.router.init();

            await this.mspSvc.init();

            await this.awsSvc.init();

            await this.authNSvc.init();

            await this.appFeatureSvc.init();

            this.monitoringSvc.setUser();

            await this.authZSvc.init();

            this.siteReady.emit(true);
        } finally {
            this.splashService.removeSplash();
        }
    }
}

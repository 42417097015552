import { PageContent, PageSection } from '@root/Design/Layout';
import { endpoint } from '@root/Services/Router/EndpointRegistry';

export function Notifications() {
    return (
        <PageContent>
            <PageSection>Welcome to Resources Notifications Page</PageSection>
        </PageContent>
    );
}
endpoint('notifications', Notifications, 'Notifications');

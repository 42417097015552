import { useMantineTheme } from '@mantine/core';
import { SankeyChart, SankeyChartSettings } from '@root/Components/Charts/SankeyChart';
import { FillerSwitch } from '@root/Design/Filler';
import { useEventValue } from '@root/Services/EventEmitter';
import { useMemo } from 'react';
import { ShowbackSummaryModel } from './ShowbackSummaryModel';

export function SummarySankeyChart({ summaryModel }: { summaryModel: ShowbackSummaryModel }) {
    const { colors } = useMantineTheme();
    const summaryData = useEventValue(summaryModel.dataChanged);
    const loading = useEventValue(summaryModel.loading);
    const settings = useMemo(
        () =>
            ({
                format: 'money-whole',
                colors: ({ id }: { id: string }) => (id === 'Allocated' ? colors.success[6] : id === 'Unallocated' ? colors.error[6] : undefined),
            } as SankeyChartSettings),
        []
    );
    const sankeyData = useMemo(() => {
        if (!summaryData?.stats) {
            return undefined;
        }
        const { stats } = summaryData;
        const links = [
            {
                source: 'Invoice',
                target: 'Showback Amount',
                value: stats.total,
            },
            {
                source: 'Upfront Fees',
                target: 'Showback Amount',
                value: stats.upfrontRiFees + stats.upfrontSpFees,
                optional: true,
            },
            {
                source: 'Showback Amount',
                target: 'Allocated',
                value: stats.allocated,
            },
            {
                source: 'Showback Amount',
                target: 'Unallocated',
                value: stats.unallocated,
            },
            {
                source: 'Unallocated',
                target: 'Support',
                value: stats.chargeSupport,
                optional: true,
            },
            {
                source: 'Unallocated',
                target: 'Unallocated Usage',
                value: stats.unallocatedUntagged,
                optional: true,
            },
            {
                source: 'Unallocated',
                target: 'Fees',
                value: stats.chargeFees,
                optional: true,
            },
            {
                source: 'Unallocated',
                target: 'Marketplace',
                value: stats.chargeMarketplace,
                optional: true,
            },
            {
                source: 'Unallocated',
                target: 'Discounts',
                value: stats.allDiscounts,
                optional: true,
            },
        ].filter((f) => f.value !== 0 || !f.optional);

        const nodes = new Set(links.flatMap((l) => [l.source, l.target]));

        return {
            nodes: [...nodes].map((id) => ({ id })),
            links: links.map((l) => ({ ...l, value: Math.abs(l.value) })),
        };
    }, [summaryData]);

    return (
        <FillerSwitch loading={loading} noData={!sankeyData}>
            {() => <SankeyChart data={sankeyData!} settings={settings} />}
        </FillerSwitch>
    );
}

import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';

@singleton()
export class AwsOnboardingService {
    public awsToken: string | undefined;
    private awsCookieKey: string = 'awsMarketToken';

    public constructor() {
        makeAutoObservable(this);
        this.checkForExistingCookie();
    }

    public setCookie(cspToken: string) {
        localStorage.setItem(this.awsCookieKey, cspToken);
        this.awsToken = cspToken;
    }

    public checkForExistingCookie() {
        var token = localStorage.getItem(this.awsCookieKey);
        if (token != null) this.awsToken = token;
    }

    public removeCookie() {
        localStorage.removeItem(this.awsCookieKey);
    }
}

import { getCreditsGetCreditSummary } from '@apis/Customers';
import { CreditSummary } from '@apis/Customers/model';
import { Alert, Text } from '@mantine/core';
import { colorPalette } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useEffect, useState } from 'react';

export function InlineTaggingCreditBalance(props: { onOutOfCredits: (hasCredits: boolean) => void; onUpdate?: () => void }) {
    const [creditSummary, setCreditSummary] = useState<CreditSummary>();
    const [showCreditError, setShowCreditError] = useState(false);
    const [creditBalanceMessage, setCreditBalanceMessage] = useState('');
    const fmtSvc = useDi(FormatService);
    const totalCredits = creditSummary?.TotalCredits ?? 0;
    const creditThreshold = creditSummary?.CreditThreshold ?? 0;
    const canUseCredits = creditSummary?.CanUseCredits ?? true;

    const prepaidBalanceText = 'This will use 1 credit. You have ' + fmtSvc.formatInt(totalCredits) + ' available.';

    const postPaidBalanceText =
        'This will use 1 credit.' +
        (creditSummary?.CreditThreshold === 0 ? '' : ' You have ' + fmtSvc.formatInt(totalCredits + creditThreshold) + ' left.');

    useEffect(() => {
        getCreditsGetCreditSummary().then(setCreditSummary);
    }, []);

    useEffect(() => {
        if (!creditSummary?.CanUseCredits) {
            setShowCreditError(false);
            setCreditBalanceMessage('');
        } else if (creditSummary?.PaymentType === 'Prepaid') {
            setCreditBalanceMessage(prepaidBalanceText);
            if (1 > creditSummary?.TotalCredits!) {
                setShowCreditError(true);
            } else {
                setShowCreditError(false);
            }
        } else if (creditSummary?.CreditThreshold !== 0) {
            setCreditBalanceMessage(postPaidBalanceText);
            if (1 > creditSummary?.TotalCredits! + creditSummary?.CreditThreshold!) {
                setShowCreditError(true);
            } else {
                setShowCreditError(false);
            }
        } else {
            //no threshold = no credit restriction
            setCreditBalanceMessage('');
            setShowCreditError(false);
        }
        props.onUpdate?.();
    }, [creditSummary]);

    useEffect(() => {
        props.onOutOfCredits(showCreditError);
    }, [showCreditError]);

    return (
        <>
            {canUseCredits ? (
                <>
                    <Text size="xs" style={{ color: colorPalette.subHeaderTextColor }}>
                        {creditBalanceMessage}
                    </Text>
                    <Alert color="warning" hidden={!showCreditError} icon={<i className="ti ti-alert-triangle" color="warning" />}>
                        <Text size="xs">
                            <>
                                <div>You have reached your credit usage limit for the month.</div>
                                <div>Please add more credits.</div>
                            </>
                        </Text>
                    </Alert>
                </>
            ) : (
                <Text></Text>
            )}
        </>
    );
}

import { JobOfCostForecast } from '@apis/Resources';
import { Box, Space, Title, Grid, Text, useMantineTheme, Card } from '@mantine/core';
import { PanelHeader } from '@root/Design/Layout';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { CostForecastPersistence } from '@root/Services/Invoices/CostForecastService';
import { addDays } from 'date-fns';
import { ReactNode, useEffect, useState } from 'react';
import { ForecastRangeInfo } from './Models';

export function useForecastDescription(job: JobOfCostForecast) {
    const fmtSvc = useDi(FormatService);
    const historicalStart = fmtSvc.toLocalDate(fmtSvc.parseDateNoTime(job.Parameters.SpendHistoryStartDate ?? ''));
    const historicalEnd = fmtSvc.toLocalDate(fmtSvc.parseDateNoTime(job.Parameters.SpendHistoryEndDate ?? ''));
    const forecastStartDate = historicalEnd;
    const forecastDays = job.Parameters.DaysToForecast ?? 0;
    const forecastEndDate = addDays(forecastStartDate, forecastDays);
    const accounts: string[] = job.Parameters.Accounts ?? [];
    const accountIdList = accounts.map((acct) => fmtSvc.awsAccountId(acct)).join(', ');

    return {
        historicalStart,
        historicalEnd,
        forecastStartDate,
        forecastEndDate,
        forecastDays,
        accounts,
        accountIdList,
    };
}

export function useForecastRange(job?: Parameters<CostForecastPersistence['getForecastRange']>[0]): ForecastRangeInfo {
    const forecastSvc = useDi(CostForecastPersistence);
    const [range, setRange] = useState<ForecastRangeInfo>(forecastSvc.getForecastRange(job));

    useEffect(() => {
        if (job) {
            setRange(forecastSvc.getForecastRange(job));
        }
    }, [job]);

    return range;
}

export function ForecastHeader({ children }: { children: ReactNode }) {
    return (
        <PanelHeader style={{ flex: 0, padding: 0 }}>
            <Box>
                <Title order={3} data-atid="AISpendForecastHeader">
                    Cost Forecasting
                </Title>
                <Space h="xs" />
                <Text>Use machine learning predictive modeling to forecast your future spend.</Text>
            </Box>
            {children}
        </PanelHeader>
    );
}

export function DescriptionLine({ label, value }: { label: string; value: string }) {
    return (
        <Grid columns={3}>
            <Grid.Col span={1} py={6}>
                <Text size="sm" weight="bold" align="right">
                    {label}
                </Text>
            </Grid.Col>
            <Grid.Col span={2} py={6}>
                <Text size="sm" weight="bold" color="primary">
                    {value}
                </Text>
            </Grid.Col>
        </Grid>
    );
}

export function ForecastCard({ children }: { children: React.ReactNode }) {
    const theme = useMantineTheme();
    return (
        <Card sx={{ background: theme.colors.gray[2], position: 'static' }} p="lg" radius="md">
            {children}
        </Card>
    );
}

export function ForecastCardTitle({
    title,
    description,
    readonly,
    readonlyDescription,
}: {
    title: string;
    description?: string;
    readonly?: boolean;
    readonlyDescription?: string;
}) {
    return (
        <Box>
            <Title order={4}>{title}</Title>
            <Text pb={'xs'} color="dimmed" size="sm">
                {readonly ? readonlyDescription : description}
            </Text>
        </Box>
    );
}

import { QueryExpr } from '@apis/Resources';
import { ActionIcon, Menu } from '@mantine/core';
import { useEvent } from '@root/Services/EventEmitter';
import { useCallback, useEffect, useState } from 'react';
import { ChartDashboardItemEditor } from './Charts/ChartDashboardItemEditor';
import { ChartPreview } from './Charts/ChartPreview';
import { ChartConfig, ChartRenderer } from './Charts/ChartRenderer';
import { DashboardEditorOverlay } from './Charts/DashboardEditorOverlay';
import { ChartEditor } from './Charts/Models';
import { DashboardItemProps, IDashboardItemType } from './Models';

export function ChartDashboardItem(props: DashboardItemProps<ChartConfig>) {
    const [editorModel, setEditorModel] = useState<ChartEditor>();
    const [filters, setFilters] = useState<QueryExpr[]>(props.model.dashboard.getFilters());
    useEvent(props.model.dashboard.filtersChanged, () => setFilters(props.model.dashboard.getFilters() ?? []));
    const openEditor = useCallback(() => setEditorModel(new ChartEditor(props.model, true)), [setEditorModel, props.model]);
    useEvent(editorModel?.onClose, () => setEditorModel(undefined));
    useEffect(() => {
        props.model.getHeader = () => <>{props.model.settings.title}</>;
        props.model.getMenu = () => (
            <>
                <Menu position="bottom-end" withinPortal withArrow>
                    <Menu.Dropdown>
                        <Menu.Item icon={<i className="ti ti-settings"></i>} onClick={openEditor}>
                            Customize
                        </Menu.Item>
                        <Menu.Item icon={<i className="ti ti-copy"></i>} onClick={props.model.duplicate}>
                            Duplicate
                        </Menu.Item>
                        <Menu.Item icon={<i className="ti ti-trash"></i>} onClick={props.model.remove}>
                            Remove
                        </Menu.Item>
                    </Menu.Dropdown>
                    <Menu.Target>
                        <ActionIcon>
                            <i className="ti ti-dots-vertical"></i>
                        </ActionIcon>
                    </Menu.Target>
                </Menu>
            </>
        );
        props.model.invalidate();
    }, [props.model, openEditor, editorModel]);
    useEffect(() => {
        if (props.model.editOnLoad) {
            openEditor();
        }
    }, []);

    return (
        <>
            <DashboardEditorOverlay
                settingsWidth={400}
                preview={() => <ChartPreview ratio={1.5} editor={editorModel!} />}
                settings={() => <ChartDashboardItemEditor editor={editorModel!} width={400} />}
                open={!!editorModel}
            ></DashboardEditorOverlay>
            <ChartRenderer
                config={props.model.settings}
                datasource={props.model.getDatasource(props.model.settings.datasourceName)!}
                filters={filters}
                resized={props.model.resized}
                saveLayout={() => props.model.dashboard.saveLayout()}
            />
        </>
    );
}
ChartDashboardItem.itemType = {
    type: 'chart',
    component: ChartDashboardItem,
} as IDashboardItemType;

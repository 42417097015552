import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { SettingsPage, SettingsPageHeader } from './SettingsPage';
import { getInvoiceGetInvoices, getInvoiceDownloadInvoice } from '@apis/Customers';
import { useEffect, useMemo, useState } from 'react';
import { PagePanel, PanelBody, PaneledPage } from '@root/Design/Layout';
import { DataGrid } from '@root/Components/DataGrid';
import { DataColumnConfig } from '@root/Components/DataGrid/Models';
import { ActionIcon, Badge, Center, Text } from '@mantine/core';
import { InvoiceDetail } from '@apis/Customers/model';
import { colorPalette, CustomColors, theme } from '@root/Design/Themes';
import { FormatService } from '@root/Services/FormatService';
import { useDi } from '@root/Services/DI';
export function Transactions() {
    const formatSvc = useDi(FormatService);
    const [invoices, setInvoices] = useState([] as InvoiceDetail[]);
    useEffect(() => {
        getInvoiceGetInvoices().then((invoices) =>
            setInvoices(
                invoices.map((invoice) => ({
                    Name: invoice.Name,
                    Date: invoice.Date,
                    FilterableDate: new Date(formatSvc.toShortDate(new Date(invoice.Date as string))),
                    Description: invoice.Description,
                    Amount: invoice.Amount,
                    Status: invoice.Status,
                    Id: invoice.Id,
                }))
            )
        );
    }, [] as InvoiceDetail[]);

    async function downloadInvoice(invoiceId: string) {
        var pdfUrl = await getInvoiceDownloadInvoice({ invoiceId });
        window.open(pdfUrl);
    }

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    function highlightStatus(status: string) {
        switch (status) {
            case 'Paid':
                return 'success';
            case 'Posted':
            case 'Voided':
            case 'Pending':
            case 'Refunded':
                return 'gray';
            case 'PaymentDue':
            case 'NotPaid':
                return 'warning';
        }
    }

    const columns = useMemo(
        () =>
            [
                {
                    id: 'Name',
                    accessor: (item) => item.Name,
                    header: 'Name',
                    align: 'center',
                    type: 'string',
                    defaultWidth: 300,
                    sortField: 'Name',
                    filter: {
                        filterField: 'Name',
                        filterType: 'string',
                        name: 'Name',
                    },
                },
                {
                    id: 'Date',
                    accessor: 'Date',
                    cellRenderer: (item) => formatSvc.formatDate(new Date(item.Date as string)),
                    header: 'Date',
                    align: 'center',
                    defaultWidth: 300,
                    sortField: 'Date',
                    filter: {
                        filterType: 'date',
                        name: 'Date',
                        filterField: 'FilterableDate',
                    },
                },
                {
                    id: 'Description',
                    accessor: (item) => item.Description,
                    header: 'Description',
                    align: 'center',
                    type: 'string',
                    defaultWidth: 300,
                    sortField: 'Description',
                    filter: {
                        filterField: 'Description',
                        filterType: 'string',
                        name: 'Description',
                    },
                },
                {
                    id: 'Amount',
                    accessor: (item) => item.Amount,
                    cellRenderer: (item) => (
                        <Text
                            color={item.Status == 'Paid' ? colorPalette.subTextColor : colorPalette.negativeNumberColor}
                            sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            {formatter.format(item.Amount!)}
                        </Text>
                    ),
                    header: 'Amount',
                    align: 'center',
                    type: 'number',
                    defaultWidth: 300,
                    sortField: 'Amount',
                    filter: {
                        filterField: 'Amount',
                        filterType: 'number',
                        name: 'Amount',
                    },
                },
                {
                    id: 'Status',
                    accessor: (item) => item.Status,
                    header: 'Status',
                    align: 'center',
                    type: 'string',
                    defaultWidth: 300,
                    cellRenderer: (i) => <Badge color={highlightStatus(i.Status!)}>{i.Status}</Badge>,
                    sortField: 'Status',
                    filter: {
                        filterField: 'Status',
                        filterType: 'string',
                        name: 'Status',
                    },
                },
                {
                    id: 'Id',
                    header: '',
                    align: 'center',
                    defaultWidth: 300,
                    cellRenderer: (i) => (
                        <Center sx={{ height: 60 }}>
                            <ActionIcon color={theme.colors?.primary?.[6] as CustomColors} onClick={() => downloadInvoice(i.Id!)}>
                                <i className="ti ti-download" style={{ cursor: 'pointer', color: theme.colors?.primary?.[6] as CustomColors }} />
                            </ActionIcon>
                        </Center>
                    ),
                    exportOptions: {
                        hidden: true,
                    },
                },
            ] as DataColumnConfig<InvoiceDetail>[],
        []
    );
    return (
        <SettingsPage>
            <PaneledPage>
                <PagePanel size="fill">
                    <SettingsPageHeader text="Invoices" />
                    <PanelBody>
                        <DataGrid exportName="Invoices" displayMode="list" itemHeight={60} dataSource={invoices} columns={columns} />
                    </PanelBody>
                </PagePanel>
            </PaneledPage>
        </SettingsPage>
    );
}
endpoint('transactions', Transactions, 'Invoices');

import {
    getAccountGetAccountConnectionStatus,
    getAccountGetAccounts,
    getCompanyGetAzureSubscriptions,
    getOrganizationGetOrganizations,
} from '@apis/Customers';

import {
    Account,
    AccountConnectedStatus,
    Organization,
    CloudProviderConnection,
    CspCloudPlatform,
    AzureSubscriptionInfo,
} from '@apis/Customers/model';
import {
    ActionIcon,
    Anchor,
    Badge,
    Box,
    Button,
    Card,
    Divider,
    Group,
    LoadingOverlay,
    Space,
    Stack,
    Tabs,
    Text,
    TextInput,
    Title,
    Tooltip,
    useMantineTheme,
} from '@mantine/core';
import { InfoCircle, PlugConnected, Trash } from 'tabler-icons-react';
import { useModals } from '@mantine/modals';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { ITypedTreeConfig, VirtualTree } from '@root/Components/VirtualTree';
import { VirtualTreeModel } from '@root/Components/VirtualTree/VirtualTreeModel';
import { ActionPageContent, ColumnHeader, InfoBoxContainer } from '@root/Design/ActionPage';
import { PageContent, PagePanel, PanelBody, PaneledPage, PanelHeader } from '@root/Design/Layout';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useLink } from '@root/Services/Router/Router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SettingsPage } from './SettingsPage';
import { postConnectionDisconnectAccount, postConnectionDisconnectCloudsaverIntegration, postResourcesCheckAwsResources } from '@apis/Resources';
import { useDi, useDiComponent } from '@root/Services/DI';
import { AccountDisconnectionSummary, CheckAwsResourcesJob, DisconnectEventBridgeSummary } from '@apis/Resources/model';
import { JobService } from '@root/Services/Jobs/JobService';
import { useIntervalEffect } from '@react-hookz/web';
import { RemoveAccountBody } from '@root/Components/Connections/Functions';
import { CustomColors } from '@root/Design/Themes';
import { DataColumnConfig } from '@root/Components/DataGrid/Models';
import { DataGrid } from '@root/Components/DataGrid';
import styled from '@emotion/styled';
import { GridFullCell } from '@root/Components/DataGrid/Design';
import { Clearfix, useResizeNeeded } from '@root/Design/Primitives';
import { CloudProviderConnectionStatus, AzureSubscriptionsGrid } from '../../Components/CompanyInfo/Administration/AzureSubscriptionsGrid';
import { FillerSwitch } from '@root/Design/Filler';
import { Platform } from '@root/Services/PlatformService';
import { useEvent } from '@root/Services/EventEmitter';
import { CspConnectionService } from '@root/Services/Connections/CspConnectionService';

export type OrgViewModel = Organization & {
    children: OrgViewModel[];
    accounts: Account[];
    masterAccount?: Account;
};
export type MasterOrgViewModel = OrgViewModel;

export type azureTenant = { tenantId: string; subscriptions: CloudProviderConnectionStatus[] };
export type AzureViewModel = azureTenant | CloudProviderConnectionStatus | CloudProviderConnection;

export function Connections() {
    const { getData, getDescendUrl } = useNav();
    const com = useCompany();
    const { resync } = getData('resync');
    const link = useLink();
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [accountStatus, setAccountStatus] = useState<AccountConnectedStatus[]>([]);
    const [azureSubscriptions, setAzureSubscriptions] = useState<AzureSubscriptionInfo[]>();
    const [syncing, setSyncing] = useState<'azure' | 'aws' | 'both' | 'none'>('none');
    const [syncJob, setSyncJob] = useState<CheckAwsResourcesJob>();
    const [percentComplete, setPercentComplete] = useState(0);
    const jobSvc = useDi(JobService);
    const [timerInterval, setTimerInterval] = useState<number | undefined>();
    const [awsLoading, setAwsLoading] = useState(true);
    const [cloudProvidersLoading, setCloudProvidersLoading] = useState(true);
    const [removeModalId, setRemoveModalId] = useState<string | undefined>(undefined);
    const [tab, setTab] = useState<string>('AWS');

    const awsColumns = useMemo(
        () =>
            [
                {
                    id: 'AccountNumber',
                    header: 'Account Number',
                    accessor: (u) => u.AwsAccountId,
                    defaultWidth: 200,
                    sortField: 'AwsAccountId',
                    filter: {
                        filterType: 'string',
                        name: 'Account Number',
                        filterField: 'AccountNumber',
                    },
                },
                {
                    id: 'Name',
                    header: 'Name',
                    accessor: (u) => u.Name,
                    defaultWidth: 300,
                    sortField: 'Name',
                    filter: {
                        filterType: 'string',
                        name: 'Name',
                        filterField: 'Name',
                    },
                },
                {
                    id: 'MasterAccount',
                    header: 'Type',
                    accessor: (u) => u.MasterAccount,
                    defaultWidth: 145,
                    sortField: 'MasterAccount',
                    filter: {
                        filterType: 'string',
                        name: 'Type',
                        filterField: 'MasterAccount',
                        options: {
                            getValueProvider: () => ['Management', 'Linked'].map((s) => ({ label: s, value: s })),
                        },
                    },
                },
                {
                    id: 'Connected',
                    header: 'Connected',
                    accessor: (u) => u.Connected,
                    defaultWidth: 150,
                    sortField: 'Connected',
                    filter: {
                        filterType: 'string',
                        name: 'Connected',
                        filterField: 'Connected',
                        options: {
                            getValueProvider: () => ['Connected', 'Not Connected'].map((s) => ({ label: s, value: s })),
                        },
                    },
                    cellRenderer: (u) => (
                        <>
                            {u.Connected == 'Connected' ? (
                                <Badge color="success" data-atid="Badge:Connected">
                                    Connected
                                </Badge>
                            ) : (
                                <Badge color="error" data-atid="Badge:NotConnected">
                                    Not Connected
                                </Badge>
                            )}
                        </>
                    ),
                },
                {
                    id: 'Status',
                    header: 'AWS Account Status',
                    accessor: 'Status',
                    defaultWidth: 165,
                    sortField: 'Status',
                    filter: {
                        filterType: 'string',
                        name: 'Status',
                        filterField: 'Status',
                        options: {
                            getValueProvider: () => ['ACTIVE', 'PENDING_CLOSURE', 'SUSPENDED'].map((s) => ({ label: s, value: s })),
                        },
                    },
                },
                {
                    accessor: () => '',
                    id: 'delete',
                    defaultWidth: 40,
                    type: 'string',
                    cellRenderer: (item) => {
                        return (
                            <GridFullCell className="delete-icon" style={{ justifyContent: 'center', display: 'flex' }}>
                                <Tooltip withinPortal withArrow position="bottom" label="Remove Connected Account">
                                    <ActionIcon onClick={() => remove(item.Id!, item.IsMasterAccount!, item.Connected == 'Connected')}>
                                        <Trash size={16} />
                                    </ActionIcon>
                                </Tooltip>
                            </GridFullCell>
                        );
                    },
                    exportOptions: {
                        hidden: true,
                    },
                },
            ] as DataColumnConfig<Account>[],
        [accounts]
    );

    useEffect(() => {
        if (resync) {
            (async () => {
                const job = await postResourcesCheckAwsResources({ CompanyId: com?.Id });
                setSyncJob(job);
                setSyncing('aws');
                setTimerInterval(10000);
            })();
        }
    }, [resync]);

    const loadAccounts = useCallback(async () => {
        try {
            setAwsLoading(true);
            const accs = await getAccountGetAccounts();
            setAccounts(accs);
            const accountsConnectedStatus = await getAccountGetAccountConnectionStatus({ companyId: com?.Id });
            for (const acc of accs) {
                acc.Connected =
                    (accountsConnectedStatus.find((a) => a.AccountId == acc.Id)?.ConnectionStatus ? 'Connected' : 'Not Connected') ?? acc.IsConnected;
            }
            setAccountStatus(accountsConnectedStatus);
        } finally {
            setAwsLoading(false);
        }
    }, []);

    const loadCloudProviders = useCallback(async () => {
        try {
            setCloudProvidersLoading(true);

            const cloudProviders = await getCompanyGetAzureSubscriptions();
            setAzureSubscriptions(cloudProviders);
        } finally {
            setCloudProvidersLoading(false);
        }
    }, []);

    const loadCspConns = useCallback(() => {
        loadAccounts();
        loadCloudProviders();
    }, []);

    const cspConnSvc = useDi(CspConnectionService);
    useEvent(cspConnSvc.connectionStateChanged, loadCspConns);

    useEffect(loadCspConns, []);

    useIntervalEffect(async () => await checkSyncProgress(), timerInterval);

    const checkSyncProgress = async () => {
        if (syncJob && resync) {
            let status = await jobSvc.getHierarchyProgress(syncJob.HierarchyId!);
            let JobComplete = status && status.Created === 0 && status.Started === 0;
            let percent = Math.round(
                status
                    ? 100 -
                          ((status.Created + status.Started) /
                              (status.Canceled + status.Failed + status.Succeeded + status.Created + status.Started)) *
                              100
                    : 0
            );
            setPercentComplete(percent);

            setTimerInterval(5000);
            if (JobComplete) {
                setSyncing('none');
                setSyncJob(undefined);
                setPercentComplete(0);
            }
        } else {
            setSyncing('none');
            // undefined disables useIntervalEffect
            setTimerInterval(undefined);
        }
    };

    const modals = useModals();
    const DiContainer = useDiComponent();
    function removeAccount() {
        const id = modals.openModal({
            title: 'How to Remove a Connection',
            children: (
                <DiContainer>
                    <RemoveAccountBody />
                </DiContainer>
            ),
        });
    }

    function remove(accountId: number, isManagementAccount: boolean, isConnected: boolean) {
        const close = () => {
            modals.closeModal(id);
            loadAccounts();
        };
        const id = modals.openModal({
            title: 'Delete Connection',
            children: (
                <DiContainer>
                    <RemoveAwsAccount onClose={close} accountId={accountId} isManagementAccount={isManagementAccount} isConnected={isConnected} />
                </DiContainer>
            ),
        });
        setRemoveModalId(id);
    }

    const resizeNeeded = useResizeNeeded();

    const handleTabChange = useCallback(
        (tab: string) => {
            setTab(tab);
            resizeNeeded();
        },
        [resizeNeeded, setTab]
    );

    const hasAzure = !!azureSubscriptions?.length;
    const hasAws = !!accounts?.length;
    const azureContent = hasAzure ? <AzureSubscriptionsGrid azureSubscriptions={azureSubscriptions} /> : null;
    const awsContent = hasAws ? <DataGrid dataSource={accounts!} columns={awsColumns} /> : null;

    return (
        <SettingsPage>
            <PaneledPage>
                <PagePanel size="fill">
                    <PanelHeader>
                        <Title data-atid="SettingsMainHeader" order={3}>
                            Connections
                        </Title>
                        <Group>
                            {azureSubscriptions?.length && (
                                <Anchor data-atid="RemoveConnectionButton" onClick={removeAccount}>
                                    <Group spacing={4}>
                                        <InfoCircle size={20} />
                                        <Text>Remove a Connection</Text>
                                    </Group>
                                </Anchor>
                            )}
                            <Button data-atid="AddConnectionButton" component="a" {...link(getDescendUrl('connections-wizard'))}>
                                Add Connection
                            </Button>
                        </Group>
                    </PanelHeader>
                    {!awsLoading && !hasAws && !hasAzure ? <Divider /> : null}
                    <PanelBody noPadding>
                        <FillerSwitch
                            loading={awsLoading && cloudProvidersLoading}
                            noData={!hasAzure && !hasAws}
                            noDataMessage="No Cloud Connections"
                        >
                            {() =>
                                hasAws && hasAzure ? (
                                    <Tabs
                                        sx={{
                                            flex: 1,
                                            display: 'flex',
                                            height: '100%',
                                            flexDirection: 'column',
                                            minHeight: 0,
                                            ['[role=tabpanel]']: { height: '100%', minHeight: 0, padding: 20 },
                                        }}
                                        onTabChange={handleTabChange}
                                        value={tab}
                                    >
                                        <Tabs.List px="lg">
                                            <Tabs.Tab value="AWS">
                                                <CloudProviderTitle platform="Aws" />
                                            </Tabs.Tab>
                                            <Tabs.Tab value="Azure">
                                                <CloudProviderTitle platform="Azure" />
                                            </Tabs.Tab>
                                        </Tabs.List>
                                        <Tabs.Panel value="AWS">
                                            <Box component={ConnectionsGridContainer}>{awsContent}</Box>
                                        </Tabs.Panel>
                                        <Tabs.Panel value="Azure">
                                            <Box component={ConnectionsGridContainer}>{azureContent}</Box>
                                        </Tabs.Panel>
                                    </Tabs>
                                ) : (
                                    <>
                                        <Stack sx={{ height: '100%' }} spacing={0}>
                                            <Divider />
                                            <Box p="lg">
                                                {hasAzure ? (
                                                    <CloudProviderTitle platform="Azure" />
                                                ) : hasAws ? (
                                                    <CloudProviderTitle platform="Aws" />
                                                ) : null}
                                            </Box>
                                            <Box p="lg" component={ConnectionsGridContainer}>
                                                {azureContent || awsContent}
                                            </Box>
                                        </Stack>
                                    </>
                                )
                            }
                        </FillerSwitch>
                    </PanelBody>
                </PagePanel>
            </PaneledPage>
        </SettingsPage>
    );
}
endpoint('connections', Connections, 'Connections');

function CloudProviderTitle({ platform }: { platform: Platform }) {
    return (
        <>
            {platform === 'Aws' ? (
                <Title data-atid="AwsAccountsTitle" order={5} sx={{ fontWeight: 600 }}>
                    <img src="/assets/Amazon_Web_Services_Logo.svg" style={{ width: '25px', marginRight: '8px' }} /> AWS Accounts
                </Title>
            ) : platform === 'Azure' ? (
                <Title data-atid="AwsSubscriptionsTitle" order={5} sx={{ fontWeight: 600 }}>
                    <img src="/assets/azure_logo.svg" style={{ width: 20 }} /> Azure Subscriptions
                </Title>
            ) : null}
        </>
    );
}

function getUserFriendlyText(failReason: string) {
    switch (failReason) {
        case 'NOT_FOUND':
            return (
                <Text>
                    One or more of the resources for part of this deletion step was not found.
                    <br /> Please verify if the resources exist or not on AWS.
                </Text>
            );
        case 'RULE_DELETE_FAILED':
            return (
                <Text>
                    One or more of the EventBridge rules failed to delete.
                    <br /> Please verify if the rules exist or not on AWS.
                </Text>
            );
        case 'ACCESS_DENIED':
            return (
                <Text>
                    Cloudsaver does not have permissions to delete one or more of the resources.
                    <br /> Please manually delete the resources or contact support.
                </Text>
            );
        case 'NO_STACKSET_TO_DELETE':
            return (
                <Text>
                    Stack Instance for the account was deleted successfully,
                    <br /> but the StackSet it belonged to was not since it may have other accounts.
                    <br /> Please verify and manually delete the StackSet if it should be.
                </Text>
            );
        case 'LOST_PERMISSIONS':
            return (
                <Text>
                    Stack Instance containing the permissions required to delete
                    <br /> the StackSet was deleted successfully, but the StackSet was not deleted.
                    <br /> Please verify and manually delete the StackSet if it should be.
                </Text>
            );
        default:
            return <Text>Unknown error, please contact support.</Text>;
    }
}

function DeletionStatusIcon(props: StatusIconProps) {
    const theme = useMantineTheme();

    return props.success ? (
        <i className="ti ti-circle-check-filled" style={{ fontSize: '3em', color: 'green' }} />
    ) : props.toolTipText ? (
        <Tooltip label={getUserFriendlyText(props.toolTipText)}>
            <i className="ti ti-exclamation-circle" style={{ fontSize: '3em', color: theme.colors.warning[4] }} />
        </Tooltip>
    ) : (
        <i className="ti ti-exclamation-circle" style={{ fontSize: '3em', color: theme.colors.warning[4] }} />
    );
}

function DeletionSubStatusIcon(props: StatusIconProps) {
    const theme = useMantineTheme();
    return props.success ? (
        <i className="ti ti-circle-check-filled" style={{ fontSize: '20px', color: 'green' }} />
    ) : props.toolTipText ? (
        <Tooltip label={getUserFriendlyText(props.toolTipText)}>
            <i className="ti ti-alert-triangle-filled" style={{ fontSize: '20px', color: theme.colors.warning[4] }} />
        </Tooltip>
    ) : (
        <i className="ti ti-alert-triangle-filled" style={{ fontSize: '20px', color: theme.colors.warning[4] }} />
    );
}

function RemoveAwsAccount({ onClose, ...props }: RemoveAwsAccountProps) {
    const com = useCompany();
    const [deleteConfirmed, setDeleteConfirmed] = useState(false);
    const [deleteDisabled, setDeleteDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [summary, setSummary] = useState<AccountDisconnectionSummary>();

    const DeleteAccount = useCallback(async () => {
        setLoading(true);
        setDeleteConfirmed(true);
        if (props.isConnected) {
            var response = await postConnectionDisconnectAccount({ platform: CspCloudPlatform.Aws, accountId: props.accountId });
            setSummary(response);
            setLoading(false);
        } else {
            //if its not connected simply delete the resources on our side and delete the connection
            var response = await postConnectionDisconnectCloudsaverIntegration({ platform: CspCloudPlatform.Aws, accountId: props.accountId });
            setSummary(response);
            setLoading(false);
        }
    }, [props.accountId]);

    const setDelete = useCallback(async (text: string) => {
        if (text === 'DELETE') {
            setDeleteDisabled(false);
        } else {
            setDeleteDisabled(true);
        }
    }, []);

    ('');
    return !deleteConfirmed ? (
        <Box>
            <i className="ti ti-alert-triangle-filled" style={{ fontSize: '86px', color: 'red' }} />
            <div style={{ textAlign: 'left', float: 'right', fontSize: '15px', width: '315px', marginRight: '-15px' }}>
                <Text>Are you sure you want to delete this connection?</Text>
                <Space h="md" />
                <Text size="sm">
                    If you delete this connection, all of its associated data {props.isManagementAccount ? 'and member accounts' : ''} will be
                    removed. This cannot be undone.
                </Text>
                <Space h="md" />
                <Text>Please enter "DELETE" to continue</Text>
                <TextInput style={{ width: '250px' }} onInput={(e) => setDelete(e.currentTarget.value)} />
            </div>
            <Clearfix />
            <Space h="lg" />
            <Group position="right">
                <Button variant="outline" onClick={onClose}>
                    Cancel
                </Button>
                <Button disabled={deleteDisabled} component={DisabledButton} variant="filled" onClick={() => DeleteAccount()}>
                    Delete
                </Button>
            </Group>
            <Clearfix />
        </Box>
    ) : !loading ? (
        <Box>
            <div style={{ textAlign: 'left', float: 'right', fontSize: '15px', width: '100%' }}>
                <p>
                    This is a summary of the actions taken to remove the connection. If you have any questions, please contact support or check out
                    the knowledge base.
                </p>
            </div>
            <Clearfix />
            <Box component={SummaryContainer}>
                {summary!.CurSummary !== null && (
                    <Box component={SummaryStatusContainer}>
                        <Box component={SummaryStatusIcon}>
                            <DeletionStatusIcon
                                success={summary!.CurSummary!.CurDefinitionDeleted! && summary!.CurSummary!.CurBucketDeleted!}
                                toolTipText={summary!.CurSummary!.FailReason ?? undefined}
                            />
                        </Box>
                        <Box component={SummaryStatusText}>Cost and Usage Reports</Box>
                        <Clearfix />
                        <Box component={SummarySubStatusContainer}>
                            <Box component={SummarySubStatusIcon}>
                                <DeletionSubStatusIcon success={summary!.CurSummary!.CurDefinitionDeleted!} />
                            </Box>
                            <Box component={SummarySubStatusText}>
                                {summary?.CurSummary?.CurDefinitionDeleted ? (
                                    <>Report definition deleted successfully</>
                                ) : (
                                    <>Failed to delete Report definition</>
                                )}
                            </Box>
                            <Clearfix />
                            <Box component={SummarySubStatusIcon}>
                                <DeletionSubStatusIcon success={summary!.CurSummary!.CurBucketDeleted!} />
                            </Box>
                            <Box component={SummarySubStatusText}>
                                {summary?.CurSummary?.CurBucketDeleted ? <>S3 Bucket deleted successfully</> : <>Failed to delete S3 Bucket</>}
                            </Box>
                            <Clearfix />
                        </Box>
                        <Clearfix />
                    </Box>
                )}
                {summary!.EventBridgeSummaries !== null ? (
                    <>
                        <Box component={SummaryStatusContainer}>
                            <Box component={SummaryStatusIcon}>
                                <DeletionStatusIcon
                                    success={
                                        !summary!.EventBridgeSummaries?.some(
                                            (s: DisconnectEventBridgeSummary) =>
                                                s.EventBridgeRulesDeleted! === false || s.EventBridgeConnectionDeleted!
                                        )
                                    }
                                />
                            </Box>
                            <Box component={SummaryStatusText}>Event Bridge Connections & Rules</Box>
                            <Clearfix />
                            <Box component={SummarySubStatusContainer}>
                                {summary!.EventBridgeSummaries!.map((s) => (
                                    <>
                                        <Box component={SummarySubStatusIcon}>
                                            <DeletionSubStatusIcon success={s.EventBridgeRulesDeleted!} toolTipText={s.FailReason ?? undefined} />
                                        </Box>
                                        <Box component={SummarySubStatusText}>
                                            <>{s.Region} CloudSaver rules</>
                                        </Box>
                                        <Clearfix />
                                        <Box component={SummarySubStatusIcon}>
                                            <DeletionSubStatusIcon
                                                success={s.EventBridgeConnectionDeleted!}
                                                toolTipText={s.FailReason ?? undefined}
                                            />
                                        </Box>
                                        <Box component={SummarySubStatusText}>
                                            <>{s.Region} EventBridge Connection</>
                                        </Box>
                                        <Clearfix />
                                    </>
                                ))}
                            </Box>
                            <Clearfix />
                        </Box>
                    </>
                ) : null}

                {summary!.CloudFormationSummary !== null ? (
                    <>
                        <Box component={SummaryStatusContainer}>
                            <Box component={SummaryStatusIcon}>
                                <DeletionStatusIcon
                                    success={
                                        summary!.CloudFormationSummary!.StackInstancesDeleted! && summary!.CloudFormationSummary!.StackSetDeleted!
                                    }
                                    toolTipText={summary!.CloudFormationSummary!.FailReason ?? undefined}
                                />
                            </Box>
                            <Box component={SummaryStatusText}>CloudFormation StackSets and Stacks</Box>
                            <Clearfix />
                            <Box component={SummarySubStatusContainer}>
                                <Box component={SummarySubStatusIcon}>
                                    <DeletionSubStatusIcon success={summary!.CloudFormationSummary!.StackInstancesDeleted!} />
                                </Box>
                                <Box component={SummarySubStatusText}>
                                    {summary?.CloudFormationSummary!.StackInstancesDeleted! ? (
                                        <>Cloudsaver stacks deleted successfully</>
                                    ) : (
                                        <>Failed to delete CloudSaver stacks</>
                                    )}
                                </Box>
                                <Clearfix />
                                <Box component={SummarySubStatusIcon}>
                                    <DeletionSubStatusIcon success={summary!.CloudFormationSummary!.StackSetDeleted!} />
                                </Box>
                                <Box component={SummarySubStatusText}>
                                    {summary?.CloudFormationSummary!.StackSetDeleted! ? (
                                        <> Cloudsaver StackSet deleted successfully</>
                                    ) : (
                                        <>Failed to delete Cloudsaver StackSet</>
                                    )}
                                </Box>
                                <Clearfix />
                            </Box>
                            <Clearfix />
                        </Box>
                    </>
                ) : null}

                {summary!.DeletedCloudSaverResourcesSummary !== null && (
                    <Box component={SummaryStatusContainer}>
                        <Box component={SummaryStatusIcon}>
                            <DeletionStatusIcon
                                success={summary!.DeletedCloudSaverResourcesSummary!.ResourcesDeleted!}
                                toolTipText={summary!.DeletedCloudSaverResourcesSummary!.FailReason ?? undefined}
                            />
                        </Box>
                        <Box component={SummaryStatusText}>
                            {summary?.DeletedCloudSaverResourcesSummary!.ResourcesDeleted! ? (
                                <>Resources for account deleted successfully</>
                            ) : (
                                <>Failed to delete resources for account</>
                            )}
                        </Box>
                        <Clearfix />
                    </Box>
                )}
            </Box>
            <Button variant="light" mt="md" onClick={openDeleteHelp} style={{ width: '250px', height: '30px', margin: '5px', float: 'left' }}>
                View Knowledge Base&nbsp;&nbsp;
                <i className="ti ti-external-link"></i>
            </Button>
            <Button
                variant="white"
                component={GoBackButton}
                style={{ width: '125px', height: '30px', margin: '5px', float: 'right' }}
                mt="md"
                onClick={onClose}
            >
                OK
            </Button>
            <Clearfix />
        </Box>
    ) : (
        <LoadingOverlay visible={true} />
    );
}

export function InfoBox(props: InfoBoxProps) {
    const theme = useMantineTheme();
    const items = props.data.map((datum) => (
        <>
            <Card shadow="sm" radius="md" withBorder>
                <InfoBoxHeader title="AWS Connections"></InfoBoxHeader>
                <Group>
                    <InfoBoxSubHeaderText
                        connectedAccounts={props.accountStatus.filter((s) => s.ConnectionStatus).length}
                        totalAccounts={props.accountStatus.length}
                        typeName="accounts"
                    ></InfoBoxSubHeaderText>
                    <SyncIcon visible={props.syncing === 'aws' || props.syncing === 'both'} percentageComplete={props.percentageComplete}></SyncIcon>
                </Group>
                <Card.Section>
                    <Space h="sm" />
                    <ColumnHeader
                        style={{
                            background: theme.colors?.gray?.[2] as CustomColors,
                            fontSize: '14px',
                            borderRightWidth: '0px',
                            borderLeftWidth: '0px',
                        }}
                    >
                        <Group position="apart">
                            <Text>
                                <b>Organizational Structure</b>
                            </Text>
                            <Text>
                                <b>Connection Status</b>
                            </Text>
                        </Group>
                    </ColumnHeader>
                </Card.Section>
                <Card.Section>
                    <InfoBoxContainer key={datum.AwsOrganizationalUnitId}>
                        <Box sx={{ height: '100%' }}>
                            <BoxGrid data={datum} accountStatus={props.accountStatus}></BoxGrid>
                        </Box>
                    </InfoBoxContainer>
                </Card.Section>
            </Card>
        </>
    ));

    return <div>{items}</div>;
}

export function InfoBoxHeader(props: InfoBoxHeaderProps) {
    return (
        <Group position="apart">
            <h2 style={{ margin: '0px' }}>{props.title}</h2>
        </Group>
    );
}

export function InfoBoxSubHeaderText(props: InfoBoxSubHeaderProps) {
    return (
        <>
            <div>{`${props.connectedAccounts} of ${props.totalAccounts} ${props.typeName} connected`}</div>
        </>
    );
}

export function SyncIcon(props: SyncIconProps) {
    return (
        <div>
            {props.visible ? (
                <Group sx={{ color: 'gray' }}>
                    <i className="ti-spin" />
                    <Text sx={{ fontSize: 14 }}>syncing...</Text>
                    {props.percentageComplete > 0 && <Text sx={{ fontSize: 14 }}>{`${props.percentageComplete}%`}</Text>}
                </Group>
            ) : (
                ''
            )}
        </div>
    );
}

export function ConnectionStatusBadge(props: BadgeProps) {
    return (
        <Badge color={props.isConnected ? 'success' : 'gray'} style={{ paddingRight: !props.isConnected ? '0' : '28px' }}>
            {props.isConnected ? 'connected' : 'not connected'}
        </Badge>
    );
}

export function BoxGrid(props: BoxGridProps) {
    let datGridchildren: (OrgViewModel[] | Account[])[] = [];
    datGridchildren.push(props.data.children);
    datGridchildren.push(props.data.accounts);

    const [treeModel, setTreeModel] = useState<VirtualTreeModel<OrgViewModel>>();
    useEffect(() => {
        if (treeModel) {
            for (const node of treeModel.query) {
                treeModel.setExpanded(node.item, true);
            }
            treeModel.invalidateData();
        }
    }, [treeModel]);

    return (
        <>
            <VirtualTree
                data={[props.data]}
                config={{
                    itemHeight: 45,
                    onModelLoaded: setTreeModel,
                    renderNode: (node, state) => (
                        <>
                            <Box sx={{ paddingLeft: node.depth * 33, height: 75 }}>
                                <Group position="apart" sx={{ padding: 15, paddingRight: 100 }}>
                                    <Group>
                                        <ActionIcon hidden={!node.hasChildren} onClick={() => state.model.toggle(node.item)}>
                                            {state.isExpanded(node.item) ? (
                                                <i className="ti ti-chevron-down"></i>
                                            ) : (
                                                <i className="ti ti-chevron-right"></i>
                                            )}
                                        </ActionIcon>

                                        {node.item.OrganizationArn ? (
                                            <div>
                                                <Text sx={{ paddingLeft: !node.hasChildren ? 42 : 0 }}>
                                                    {node.item.ParentId ? <i className="ti ti-folder" /> : <i className="ti ti-folders" />}{' '}
                                                    <b>{node.item.Name} &nbsp;</b> {!node.hasChildren ? <Badge color={'gray'}>Empty</Badge> : ''}
                                                </Text>
                                                <Text sx={{ paddingLeft: !node.hasChildren ? 62 : 20, fontSize: 14, color: 'gray' }}>
                                                    {node.item.AwsOrganizationalUnitId}
                                                </Text>
                                            </div>
                                        ) : (
                                            <div>
                                                <Text sx={{ paddingLeft: 42 }}>
                                                    <i className="ti ti-box" /> <b>{node.item.Name} &nbsp;</b>
                                                    {node.item.IsMasterAccount ? <Badge color="gray">management account</Badge> : ''}
                                                </Text>
                                                <Text sx={{ paddingLeft: 62, fontSize: 14, color: 'gray' }}>
                                                    {node.item.AwsAccountId} | {node.item.Email}
                                                </Text>
                                            </div>
                                        )}
                                    </Group>
                                    {node.item.AwsAccountId ? (
                                        <ConnectionStatusBadge
                                            isConnected={props.accountStatus.filter((s) => s.AccountId == node.item.Id)[0].ConnectionStatus}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </Group>
                                <Divider></Divider>
                            </Box>
                        </>
                    ),
                    childAccessor: (item: OrgViewModel) => {
                        return [...(item.children ?? []), ...(item.accounts ?? [])];
                    },
                }}
            ></VirtualTree>
        </>
    );
}

async function GetOrgChildren(rootOrg: OrgViewModel, parentOrg: Organization, accounts: Account[], allOrgs: Organization[]): Promise<OrgViewModel[]> {
    const orgChildren: OrgViewModel[] = [];
    const orgChilds = allOrgs.filter((s) => s.ParentId == parentOrg.AwsOrganizationalUnitId);
    let masterAccount = accounts.find((c) => c.IsMasterAccount);
    for (const child of orgChilds) {
        const childAccounts = accounts.filter((s) => s.OrganizationUnitId === (child.Id as number));
        const orgChild: OrgViewModel = {
            ...child,
            children: await GetOrgChildren(rootOrg, child, accounts, allOrgs),
            accounts: childAccounts,
        };
        if (!masterAccount) {
            masterAccount = childAccounts.find((c) => c.IsMasterAccount);
        }

        orgChildren.push(orgChild);
    }
    if (masterAccount) {
        rootOrg.masterAccount = masterAccount;
    }

    return orgChildren;
}

function openDeleteHelp() {
    window.open('https://knowledge.cloudsaver.com/s/article/Disconnecting-Your-AWS-Accounts-from-CloudSaver');
}

export interface InfoBoxHeaderProps {
    title: string;
}

export interface InfoBoxProps {
    title: string;
    data: OrgViewModel[];
    syncing: 'azure' | 'aws' | 'both' | 'none';
    accountStatus: AccountConnectedStatus[];
    percentageComplete: number;
}

export interface InfoBoxSubHeaderProps {
    connectedAccounts?: number;
    totalAccounts?: number;
    typeName?: string;
}

export interface RemoveAwsAccountProps {
    accountId: number;
    isManagementAccount: boolean;
    isConnected: boolean;
    onClose: () => void;
}

export interface StatusIconProps {
    success: boolean;
    toolTipText?: string;
}

export interface BoxGridProps {
    data: OrgViewModel;
    accountStatus: AccountConnectedStatus[];
}

export interface SyncIconProps {
    visible: boolean;
    percentageComplete: number;
}

export interface BadgeProps {
    isConnected?: boolean;
    style?: any;
}

const ConnectionsGridContainer = styled.div`
    height: 100%;
    svg {
        font-size: 16px;
    }
    tr:hover .delete-icon {
        visibility: visible;
    }
    .delete-icon {
        visibility: hidden;
    }
`;

const GoBackButton = styled.div`
    border: 2px solid ${(props) => props.theme.colors.primary[5]};
`;

const DisabledButton = styled.button`
    width: 125px;
    margin: 5px;
    background-color: ${(props) => props.theme.colors.error[5]};
    float: right;

    &:hover {
        background-color: ${(props) => props.theme.colors.error[9]};
    }

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
`;

const SummaryContainer = styled.div`
    height: 400px;
    overflow-y: scroll;
`;

const SummaryStatusContainer = styled.div`
    width: 95%;
    margin: 0px auto;
    padding: 5px;
`;

const SummarySubStatusContainer = styled.div`
    float: right;
    width: 85%;
    margin-top: -15px;
`;
const SummaryStatusIcon = styled.div`
    float: left;
    width: 10%;
`;
const SummaryStatusText = styled.div`
    float: right;
    text-align: left;
    width: 85%;
    font-size: 16px;
`;
const SummarySubStatusIcon = styled.div`
    float: left;
    width: 5%;
`;
const SummarySubStatusText = styled.div`
    float: right;
    text-align: left;
    font-size: 12px;
    width: 90%;
`;

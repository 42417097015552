import { SettingsPage, SettingsPageHeader } from './SettingsPage';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { PagePanel, PanelBody, PaneledPage } from '@root/Design/Layout';
import { Button, Card, Grid, Space, Text, useMantineTheme } from '@mantine/core';
import { colorPalette } from '@root/Design/Themes';
import { useLink } from '@root/Services/Router/Router';
import { useNav } from '@root/Services/NavigationService';
import { useEffect, useState } from 'react';
import { getCompanyGetSingleSignOn } from '@apis/Customers';
import { SingleSignOnConfig } from '@apis/Customers/model';

export function SSO() {
    const theme = useMantineTheme();
    const link = useLink();
    const { getRootUrl } = useNav();
    const [ssoConfig, setSSOConfig] = useState<SingleSignOnConfig>();

    useEffect(() => {
        (async () => {
            let config = await getCompanyGetSingleSignOn();
            if (config && config.SignInUrl) {
                setSSOConfig(config);
            }
        })();
    }, []);

    return (
        <SettingsPage>
            <PaneledPage>
                <PagePanel size="fill">
                    <SettingsPageHeader text="Single Sign-On" />
                    <PanelBody>
                        <Grid>
                            <Grid.Col md={9} lg={6} xl={4}>
                                <Card withBorder shadow="xs" color={colorPalette.white} radius="lg">
                                    <Text weight={700} style={{ color: theme.black }} data-atid={'SSOStatus:' + (ssoConfig ? 'SSO' : 'LocalCreds')}>
                                        <i className="ti ti-cloud-lock" />
                                        &nbsp; SSO Status
                                    </Text>
                                    <Space h="sm" />
                                    <Text size="sm" color="dimmed">
                                        {ssoConfig ? 'SSO is configured' : 'You are currently using local credentials.'}
                                    </Text>
                                </Card>
                            </Grid.Col>
                        </Grid>
                        <Space h="lg" />
                        {ssoConfig ? (
                            <>
                                <Button
                                    component="a"
                                    {...link(getRootUrl('settings/sso/configure-sso'))}
                                    variant="outline"
                                    color={colorPalette.darkTextCustomColor}
                                    data-atid="ChangeSSO"
                                >
                                    Change SSO Settings
                                </Button>
                            </>
                        ) : (
                            <Button component="a" {...link(getRootUrl('settings/sso/configure-sso'))} data-atid="SetUpSSO">
                                Set Up SSO
                            </Button>
                        )}
                    </PanelBody>
                </PagePanel>
            </PaneledPage>
        </SettingsPage>
    );
}
endpoint('sso', SSO, 'Single Sign-On');

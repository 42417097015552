import styled from '@emotion/styled';
import { Badge, Box, Divider, Group, MantineColor, Text } from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { JobHierarchyProgress } from '@root/Services/Jobs/JobService';
import { ReactNode } from 'react';

export function DetailRow({ title, alignTop, children }: { title: string; children: ReactNode; alignTop?: boolean }) {
    return (
        <Group px="md" align={alignTop ? 'flex-start' : undefined}>
            <DetailRowTitle text={title} />
            <DetailRowValue>{children}</DetailRowValue>
        </Group>
    );
}

function DetailRowTitle({ text }: { text: string }) {
    return (
        <Text weight="bolder" size="sm" sx={{ width: '30%', maxWidth: 200, textAlign: 'right' }}>
            {text}
        </Text>
    );
}

function DetailRowValue({ children }: { children: ReactNode }) {
    return <Box sx={{ flex: 1, fontSize: 14 }}>{children}</Box>;
}

export function RowDivider() {
    return <Divider my="xs" />;
}

export const ActivityDetailsContainer = styled.div`
    height: 100%;
    padding: ${(p) => p.theme.spacing.xl}px;
    display: flex;
    min-height: 0;
    flex-direction: column;
`;

export function ProgressBadge({ value, text, color }: { value: number; text: string; color?: MantineColor }) {
    const formatSvc = useDi(FormatService);
    return value > 0 ? (
        <Badge color={color} sx={{ textTransform: 'none' }}>
            {formatSvc.formatInt(value)} {text}
        </Badge>
    ) : null;
}

export function ProgressBadges({ progress }: { progress?: JobHierarchyProgress }) {
    const remaining = (progress?.Created ?? 0) + (progress?.Started ?? 0);
    const succeeded = progress?.Succeeded ?? 0;
    const failed = progress?.Failed ?? 0;
    return (
        <>
            <ProgressBadge value={remaining} text="Remaining" />
            <ProgressBadge value={succeeded} color="success" text="Succeeded" />
            <ProgressBadge value={failed} color="error" text="Failed" />
        </>
    );
}

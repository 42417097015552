import { Stack, Tabs, useMantineTheme, Box, Center, Button, Divider, Loader, Card, Text, Anchor, Space, Title } from '@mantine/core';
import { useResizeNeeded } from '@root/Design/Primitives';
import { useDiMemo } from '@root/Services/DI';
import { useEventValue } from '@root/Services/EventEmitter';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { ReactNode, useEffect } from 'react';
import { ShowbackSummary } from './Summary/ShowbackSummary';
import styled from '@emotion/styled';
import { ChevronRight } from 'tabler-icons-react';
import { ShowbackModel } from './Models';
import { AllocationDimensionDetailsDrawer, ShowbackMonthPicker } from './Common';
import { AllocationRules } from './AllocationRules';
import { ReconciliationGrid } from './Reconciliation/ReconciliationGrid';
import { withSchemaPreloader } from '@root/Components/Invoices/SchemaPreloader';
import { useLink } from '@root/Services/Router/Router';

export function Showback() {
    //Show summary based on allocation dimension
    const nav = useNav();
    const model = useDiMemo(ShowbackModel);

    const showbackConfigured = useEventValue(model.showbackConfigured);
    const resizeNeeded = useResizeNeeded();

    const handleTabChange = (tabName: string) => {
        nav.replaceParams({ tab: tabName });
        resizeNeeded();
    };

    useEffect(() => () => model.dispose(), [model]);

    const selectedMonth = useEventValue(model.selectedMonthChanged);

    if (showbackConfigured === undefined) {
        return <ShowbackLoading />;
    } else if (model.availableMonths.length === 0) {
        return <ShowbackUnavailable />;
    } else if (!showbackConfigured) {
        return <InitialSetupShowback model={model} />;
    } else {
        const { tab } = nav.getData('tab');
        const activeTab = tab ?? 'summary';
        return (
            <>
                <Tabs
                    key={model.selectedAllocationDimension?.DimensionName ?? ''}
                    defaultValue="summary"
                    value={activeTab}
                    color="gray"
                    variant="pills"
                    onTabChange={handleTabChange}
                    sx={{
                        flex: 1,
                        display: 'flex',
                        height: '100%',
                        flexDirection: 'column',
                        minHeight: 0,
                        ['[role=tabpanel]']: { flex: 1, minHeight: 0 },
                    }}
                >
                    <ShowbackHeaderBar>
                        <Tabs.List>
                            <Tabs.Tab data-atid="ShowbackSummaryTab" value="summary">
                                Summary
                            </Tabs.Tab>
                            <Tabs.Tab data-atid="ShowbackReconciliationTab" value="reconciliation">
                                Reconciliation
                            </Tabs.Tab>
                            <Tabs.Tab data-atid="ShowbackAllocationRulesTab" value="allocationRules">
                                Allocation Rules
                            </Tabs.Tab>
                        </Tabs.List>
                        <Box>
                            {model.availableMonths.length ? (
                                <ShowbackMonthPicker month={selectedMonth!} onChange={model.setSelectedMonth} months={model.availableMonths} />
                            ) : null}
                            {/* Add Last Resync Date Component here */}
                        </Box>
                    </ShowbackHeaderBar>
                    <Divider color="gray.4" />
                    <Tabs.Panel value="summary">
                        {activeTab !== 'summary' ? null : (
                            <ShowbackPage>
                                <ShowbackSummary model={model} />
                            </ShowbackPage>
                        )}
                    </Tabs.Panel>
                    <Tabs.Panel value="reconciliation">
                        {' '}
                        {activeTab !== 'reconciliation' ? null : (
                            <ShowbackPage>
                                <ReconciliationGrid persistenceKey="ReconciliationGrid" model={model} />
                            </ShowbackPage>
                        )}
                    </Tabs.Panel>
                    <Tabs.Panel value="allocationRules">
                        {' '}
                        {activeTab !== 'allocationRules' ? null : <AllocationRules model={model}></AllocationRules>}
                    </Tabs.Panel>
                </Tabs>
                <AllocationDimensionDetailsDrawer settingsRequestedEvt={model.showbackSettingsRequested} onSave={model.saveDimension} />
            </>
        );
    }
}
endpoint('showback-overview', withSchemaPreloader(Showback), 'Showback');

export function ShowbackPage({ children }: { children: ReactNode }) {
    const theme = useMantineTheme();
    return (
        <Box p="xl" sx={{ height: '100%', overflow: 'auto', background: theme.colors.gray[2] }}>
            {children}
        </Box>
    );
}

export function InitialSetupShowback({ model }: { model: ShowbackModel }) {
    const theme = useMantineTheme();
    return (
        <SplashBg>
            <Box p="xl">
                <Center sx={{ height: 600 }}>
                    <Stack align="center">
                        <Button
                            radius="xl"
                            size="xl"
                            variant="outline"
                            color="primary"
                            rightIcon={<ChevronRight />}
                            onClick={model.createAllocationDimension}
                            sx={{ [':hover']: { background: theme.colors.gray[2] } }}
                        >
                            To begin, select your Allocation Dimension
                        </Button>
                    </Stack>
                </Center>
            </Box>
            <AllocationDimensionDetailsDrawer settingsRequestedEvt={model.showbackSettingsRequested} onSave={model.saveDimension} />
        </SplashBg>
    );
}

export function ShowbackLoading() {
    return (
        <SplashBg>
            <Center sx={{ height: '100%' }}>
                <Stack justify="center" align="center" sx={{ height: 100, width: 100, background: '#fff8', borderRadius: 100 }}>
                    <Loader size="xl" />
                </Stack>
            </Center>
        </SplashBg>
    );
}

export function ShowbackUnavailable() {
    const link = useLink();
    return (
        <SplashBg>
            <Center sx={{ height: '100%' }}>
                <Card shadow="md" radius="lg" withBorder p="lg" sx={{ width: 600 }}>
                    <Title order={3}>Service Unavailable</Title>
                    <Space h="sm" />
                    <Text>
                        Showback features are unavailable until invoice data has been collected. Please try again later, or{' '}
                        <Anchor {...link('connections')}>connect an account</Anchor>.
                    </Text>
                </Card>
            </Center>
        </SplashBg>
    );
}

export const SplashBg = styled.div`
    height: 100%;
    background-color: #f3f4f8;
    background-image: url('/assets/showback/showback-art-background.jpg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;

export const ShowbackHeaderBar = styled.div`
    height: 70px;
    padding: 0 ${(p) => 32}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${(p) => p.theme.colors.gray[2]};
`;

import styled from '@emotion/styled';
import { FrontEndErrorType } from '@apis/Customers/model';
import { MspService } from '@root/Services/MspService';
import { useDi } from '@root/Services/DI';
import { useEffect, useState } from 'react';

export function ErrorFallback({ error }: any) {
    const [isWhiteLabelEnabled, setIsWhiteLabelEnabled] = useState(false);
    const [logoUrl, setLogoUrl] = useState('');
    const mspSvc = useDi(MspService);

    useEffect(() => {
        if (mspSvc.config != null) {
            setIsWhiteLabelEnabled(mspSvc.config.isWhiteLabelEnabled);
            setLogoUrl(mspSvc.config.LogoPath!);
        }
    }, [mspSvc.config]);

    useEffect(() => {
        window.onpopstate = () => window.location.reload();
    }, []);

    return (
        <ErrorBackground>
            {logoUrl ? (
                <Logo style={{ background: "url('" + logoUrl + "') no-repeat" }} />
            ) : (
                <Logo
                    style={{
                        background: "url('https://cdn.cloudsaver.com/images/v3/cloudsaver-white-2024.png')",
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '50%',
                        backgroundPosition: 'center',
                    }}
                />
            )}

            <MessageBox>
                <h1>Refresh Your Page</h1>
                <p>{error.description != null && error.description != '' ? error.description : 'Something updated or changed unexpectedly.'}</p>
                <p>
                    Refresh your browser window to reload this page and everything will return to normal. <b>Thank you!</b>
                </p>
            </MessageBox>
            <SupportMessage>
                <p>
                    A message about this issue has been sent to the support team. If you continue to see this issue, or would like to speak to
                    someone, please <a href="https://knowledge.cloudsaver.com/s/">contact support</a>.
                </p>
            </SupportMessage>

            {!isWhiteLabelEnabled && (
                <BackgroundWrap>
                    <X6>
                        <Cloud></Cloud>
                    </X6>
                    <X1>
                        <Cloud></Cloud>
                    </X1>

                    <X2>
                        <Cloud></Cloud>
                    </X2>

                    <X3>
                        <Cloud></Cloud>
                    </X3>

                    <X4>
                        <Cloud></Cloud>
                    </X4>
                    <X5>
                        <Cloud></Cloud>
                    </X5>

                    <X6>
                        <Cloud></Cloud>
                    </X6>
                    <X7>
                        <Cloud></Cloud>
                    </X7>
                </BackgroundWrap>
            )}
        </ErrorBackground>
    );
}

//use this type to send exceptions on the front end if you wanna catch them and use the error screen
export class FrontEndException extends Error {
    public description?: string;
    public errorType?: FrontEndErrorType;
    public companyId?: number;
    public userId?: number;

    constructor(message: string, description?: string, errorType?: FrontEndErrorType, companyId?: number, userId?: number) {
        super(message);
        this.name = 'FrontEndError';
        this.description = description;
        this.errorType = errorType ?? FrontEndErrorType.Unknown;
        this.companyId = companyId;
        this.userId = userId;
    }
}

const ErrorBackground = styled.div`
    background: #00b4ff;
    color: #333;
    font: 100% Arial, Sans Serif;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    z-index: 0;
`;

const Logo = styled.div`
    position: sticky;
    top: 90px;
    margin: 0 auto;
    width: 640px;
    height: 240px;
    z-index: 2;
`;

const BackgroundWrap = styled.div`
    bottom: 0;
    left: 0;
    padding-top: 50px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
`;

const SupportMessage = styled.div`
    position: absolute;
    bottom: 0px;
    margin: 0 auto;
    width: 100%;
    background-color: rgba(3, 117, 193, 0.3);
    text-align: center;
    box-shadow: inset 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;
    p {
        margin: 14px 80px;
        font-size: 12px;
        font-weight: 100;
        color: rgba(255, 255, 255);
        opacity: 0.4;
    }

    a {
        font-weight: 700;
        color: rgba(255, 255, 255, 0.6);
    }

    :hover {
        p {
            cursor: default;
            opacity: 1;
        }
    }
`;

const MessageBox = styled.div`
    position: sticky;
    top: 280px;
    margin: 0 auto;
    width: 400px;
    border: 2px solid rgba(255, 255, 255, 0.4);
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.3);
    text-align: center;
    z-index: 2;
    background: -moz-linear-gradient(-45deg, rgba(247, 247, 247, 0.2) 0%, rgba(255, 255, 255, 0.4) 73%, rgba(237, 237, 237, 0.2) 100%);
    background: -webkit-gradient(
        left top,
        right bottom,
        color-stop(0%, rgba(247, 247, 247, 0.2)),
        color-stop(73%, rgba(255, 255, 255, 0.4)),
        color-stop(100%, rgba(237, 237, 237, 0.2))
    );
    background: linear-gradient(135deg, rgba(247, 247, 247, 0.2) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(237, 237, 237, 0.2) 100%);

    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);

    h1 {
        margin: 18px;
        color: rgba(255, 255, 255, 1);
        filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5));
    }

    p {
        margin: 18px;
        color: rgba(3, 117, 193, 1);
        line-height: 1.5;
    }
`;

const Cloud = styled.div`
    background: #fff;
    background: linear-gradient(top, #fff 5%, #f1f1f1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#f1f1f1',GradientType=0 );

    border-radius: 100px;

    box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);

    height: 120px;
    position: relative;
    width: 350px;

    :after,
    :before {
        background: #fff;
        content: '';
        position: absolute;
        z-index: -1;
    }

    :after {
        border-radius: 100px;

        height: 100px;
        left: 50px;
        top: -50px;
        width: 100px;
    }

    :before {
        border-radius: 200px;

        width: 180px;
        height: 180px;
        right: 50px;
        top: -90px;
    }
`;

const X1 = styled.div`
    animation: animateCloud 35s linear infinite;

    transform: scale(0.15);

    @keyframes animateCloud {
        0% {
            margin-left: -1000px;
        }
        100% {
            margin-left: 100%;
        }
    }
`;

const X2 = styled.div`
    animation: animateCloud 22s linear infinite;

    transform: scale(0.3);

    @keyframes animateCloud {
        0% {
            margin-left: -1000px;
        }
        100% {
            margin-left: 100%;
        }
    }
`;

const X3 = styled.div`
    animation: animateCloud 30s linear infinite;

    transform: scale(0.5);

    @keyframes animateCloud {
        0% {
            margin-left: -1000px;
        }
        100% {
            margin-left: 100%;
        }
    }
`;

const X4 = styled.div`
    animation: animateCloud 22s linear infinite;

    transform: scale(0.6);

    @keyframes animateCloud {
        0% {
            margin-left: -1000px;
        }
        100% {
            margin-left: 100%;
        }
    }
`;

const X5 = styled.div`
    animation: animateCloud 15s linear infinite;
    transform: scale(0.8);

    @keyframes animateCloud {
        0% {
            margin-left: -1000px;
        }
        100% {
            margin-left: 100%;
        }
    }
`;

const X6 = styled.div`
    opacity: 0.4;
    animation: animateCloud 22s linear infinite;

    transform: scale(1);

    @keyframes animateCloud {
        0% {
            margin-left: -1000px;
        }
        100% {
            margin-left: 100%;
        }
    }
`;

const X7 = styled.div`
    opacity: 0.1;
    animation: animateCloud 14s linear infinite;

    transform: scale(2);

    @keyframes animateCloud {
        0% {
            margin-left: -1000px;
        }
        100% {
            margin-left: 100%;
        }
    }
`;

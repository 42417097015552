/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Invoices.Host.Api
 * OpenAPI spec version: 1.0
 */

export type AllocationDimensionDimensionType = typeof AllocationDimensionDimensionType[keyof typeof AllocationDimensionDimensionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AllocationDimensionDimensionType = {
  CostAllocationTag: 'CostAllocationTag',
  IntegrationDatapoint: 'IntegrationDatapoint',
  UsageAccount: 'UsageAccount',
  PayerAccount: 'PayerAccount',
} as const;

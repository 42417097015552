import { BaseAzureResource, BaseResource } from '@apis/Resources/model';
import styled from '@emotion/styled';
import { Tabs, useMantineTheme } from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { EventEmitter } from '@root/Services/EventEmitter';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ResourceChangeCountCtx } from '../Common/OverviewCommon';
import { ResourceTags } from '../Common/ResourceTags';
import { AzureResourceHistory } from './AzureResourceHistory';
import { AzureResourceOverview } from './AzureResourceOverview';

export function AzureResourceDetails({ resourceId, onModelLoaded }: { resourceId: string; onModelLoaded: (resource: BaseResource) => void }) {
    const resourceSvc = useDi(ResourceService);
    const [resource, setResource] = useState<BaseAzureResource | null>();
    useEffect(() => {
        resourceSvc.getPlatformResource(resourceId, 'Azure').then(setResource);
    }, []);
    const loading = resource === undefined;
    const notFound = resource === null;

    return loading ? <></> : notFound ? <ResourceNotFound /> : <AzureResourceDetailsContent onChange={onModelLoaded} resource={resource} />;
}

export function ResourceNotFound() {
    return <></>;
}

function AzureResourceDetailsContent({ resource, onChange }: { resource: BaseAzureResource; onChange: (resource: BaseResource) => void }) {
    const theme = useMantineTheme();
    const changeCt = useMemo(() => ({ resourceChangeCt: new EventEmitter(0) }), []);

    const onTagsChanged = useCallback(() => {
        changeCt.resourceChangeCt.emit(changeCt.resourceChangeCt.value + 1);
        onChange(resource);
    }, [changeCt, onChange]);

    return (
        <ResourceChangeCountCtx.Provider value={changeCt}>
            <TabContainer>
                <Tabs
                    defaultValue="Details"
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        ['[role=tabpanel]']: { overflow: 'auto', flex: 1, minHeight: 0, background: theme.colors.gray[2] },
                    }}
                >
                    <Tabs.List px="lg">
                        <Tabs.Tab value="Details">Details</Tabs.Tab>
                        <Tabs.Tab value="Tags">Tags</Tabs.Tab>
                        <Tabs.Tab value="History">History</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <AzureResourceOverview resource={resource} />
                    </Tabs.Panel>
                    <Tabs.Panel value="Tags" sx={{ padding: '0 !important' }}>
                        <ResourceTags resource={resource} onChange={onTagsChanged} />
                    </Tabs.Panel>
                    <Tabs.Panel value="History">
                        <AzureResourceHistory resource={resource} />
                    </Tabs.Panel>
                </Tabs>
            </TabContainer>
        </ResourceChangeCountCtx.Provider>
    );
}

const TabContainer = styled.div`
    height: 100%;
    .mantine-Tabs-panel {
        padding: ${(p) => p.theme.spacing.lg}px;
    }
`;

import { getCompanyRelationshipGetCompanyRelationships } from '@apis/Customers';
import {
    AmbiguousDataDefinitionDigest,
    Company,
    CompanyRelationship,
    CompanyRelationshipBundle,
    DataAccessDataDefinition,
    DataAccessDataDefinitionType,
    RelatedCompanyItem,
    SchemaType,
} from '@apis/Customers/model';
import { postCspInvoiceDataDigestContollerMultiQuery, postCspInvoiceDataDigestContollerQuery } from '@apis/Invoices';
import { CspInvoiceDataDigestBaseRecord, CspInvoiceDataDigestDefinition, DigestContextBasedSelectFieldField } from '@apis/Invoices/model';
import { QueryResult } from '@apis/Resources';
import { Query } from '@apis/Resources/model';
import { inject, injectable, singleton } from 'tsyringe';
import { AsyncBundler } from '../AsyncBundler';
import { ICompanyContextToken } from '../Customers/CompanyContext';
import { exprBuilder, groupExprs, SchemaService } from '../QueryExpr';

interface DataDefinition {
    Id?: number;
    Type?: DataAccessDataDefinitionType;
    Digest?: AmbiguousDataDefinitionDigest;
}

export interface IDataDefinitionDigest {
    Name: string;
    Presentation: {
        Icon: string;
        PrimaryView: string;
        RelatedView: string;
    };
}

@singleton()
class DataMarketplaceApiCache {
    private cache = new Map<string, any>();

    public async getOrAdd<T>(key: string, fetch: () => Promise<T>) {
        if (!this.cache.has(key)) {
            this.cache.set(key, fetch());
        }
        return (await this.cache.get(key)) as T;
    }
}

export class RelationshipBundleService {
    protected readonly relationshipLookup: Map<number, CompanyRelationship>;
    protected readonly relByRelCompanyId: Map<number, CompanyRelationship>;
    protected readonly definitionRelationships = new Map<number, CompanyRelationship[]>();
    protected readonly definitions: DataAccessDataDefinition[] = [];
    protected readonly definitionLookup = new Map<number, DataAccessDataDefinition>();
    protected readonly companyLookup = new Map<number, RelatedCompanyItem>();

    public constructor(
        public readonly company: Company,
        public readonly relationships: CompanyRelationshipBundle,
        public readonly apiSvc: DataMarketplaceApiService
    ) {
        this.relationshipLookup = new Map(relationships.Relationships?.map((r) => [r.Id!, r]) ?? []);
        this.definitionLookup = new Map(relationships.Definitions?.map((d) => [d.Id!, d]) ?? []);
        this.definitions = relationships.Definitions ?? [];

        relationships.DataAccess?.forEach((d) => {
            if (!this.definitionRelationships.has(d.DataDefinitionId!)) {
                this.definitionRelationships.set(d.DataDefinitionId!, []);
            }
            this.definitionRelationships.get(d.DataDefinitionId!)!.push(this.relationshipLookup.get(d.CompanyRelationshipId!)!);
        });

        this.relByRelCompanyId = new Map(relationships.Relationships?.map((r) => [r.RelatedCompanyId!, r]) ?? []);

        this.companyLookup = new Map(relationships.Companies?.map((c) => [c?.CompanyId ?? 0, c]) ?? []);
    }

    public getDefById(id: number) {
        return this.definitionLookup.get(id);
    }

    public getDefaultDef() {
        return this.definitions.length ? this.definitions[0] : undefined;
    }

    public getRelByRelCompanyId(relatedCompanyId: number) {
        return this.relByRelCompanyId.get(relatedCompanyId);
    }

    public getDataDefinitionByName(name: string) {
        const defOption = this.definitions.map((d) => ({ digest: d.Digest, def: d })).find((d) => d.digest?.Name === name);
        return !defOption ? null : { options: defOption?.digest, definition: defOption?.def };
    }

    public static create(relationships: CompanyRelationshipBundle, company: Company, mktApiSvc: DataMarketplaceApiService) {
        return new RelationshipBundleService(company, relationships, mktApiSvc);
    }

    public scope(defId: number, relationshipId?: number) {
        return new ScopedRelationshipBundleService(defId, this.company, this.relationships, this.apiSvc, relationshipId);
    }
}

export class ScopedRelationshipBundleService extends RelationshipBundleService {
    public constructor(
        public readonly defId: number,
        company: Company,
        relationships: CompanyRelationshipBundle,
        apiSvc: DataMarketplaceApiService,
        public readonly relationshipId?: number
    ) {
        super(company, relationships, apiSvc);
    }

    public async query<TResult>(query: Query): Promise<QueryResult<TResult>> {
        const { primaryCompanyId, dataDefinition, relatedCompanyId } = this.getQueryParams();
        if (this.relationshipId) {
            const { builder: xb } = exprBuilder<{ DataAccessRelationshipId: number }>();
            const scopeCriteria = xb.resolve(xb.model.DataAccessRelationshipId.eq(this.relationshipId));
            query.Where = groupExprs('and', [query.Where, scopeCriteria]);
        }
        return (await this.apiSvc.query(primaryCompanyId!, relatedCompanyId, dataDefinition!, query)) as unknown as Promise<QueryResult<TResult>>;
    }

    public getQueryParams() {
        const dataDefinition = this.definitionLookup.get(this.defId);
        const relationships = this.definitionRelationships.get(this.defId);
        const primaryCompanyId = relationships?.map((r) => r.PrimaryCompanyId)?.[0];

        return { dataDefinition, relatedCompanyId: this.company.Id, primaryCompanyId };
    }

    public getParticipationType(): null | 'primary' | 'related' {
        const relationships = this.getRelationships();
        if (!relationships.length) {
            return null;
        }
        return relationships.find((r) => r.PrimaryCompanyId === this.company.Id) ? 'primary' : 'related';
    }

    public getParticipants(): { primary: RelatedCompanyItem; related?: RelatedCompanyItem } | undefined {
        const relationship = !this.relationshipId ? undefined : this.relationshipLookup.get(this.relationshipId);
        if (relationship) {
            return {
                primary: this.companyLookup.get(relationship.PrimaryCompanyId!)!,
                related: this.companyLookup.get(relationship.RelatedCompanyId!),
            };
        }
        const primaryIds = [...this.getRelationships().reduce((result, item) => result.add(item.PrimaryCompanyId ?? 0), new Set<number>())];
        const primary = primaryIds.length !== 1 ? undefined : this.companyLookup.get(primaryIds[0]);
        return primary ? { primary } : undefined;
    }

    public getRelationships() {
        const result: CompanyRelationship[] = [];
        if (this.relationshipId) {
            const relationship = this.relationshipLookup.get(this.relationshipId);
            if (relationship) {
                return [relationship];
            }
        }
        if (this.defId) {
            return this.definitionRelationships.get(this.defId) ?? [];
        }
        return result;
    }
}

@injectable()
export class DataMarketplaceApiService {
    private asyncBundler = new AsyncBundler();

    public constructor(
        @inject(DataMarketplaceApiCache) private readonly cache: DataMarketplaceApiCache,
        @inject(ICompanyContextToken) private readonly company: Company
    ) {}

    public async getCompanyRelationships(company?: Company) {
        return await this.cache.getOrAdd('companyRelationships-' + (company ?? this.company).Id, () =>
            getCompanyRelationshipGetCompanyRelationships()
        );
    }

    public async getBundleService(company?: Company) {
        const relationships = await this.getCompanyRelationships(company);
        return RelationshipBundleService.create(relationships, company ?? this.company, this);
    }

    public async query(primaryCompanyId: number, relatedCompanyId: number | undefined, dataDefinition: DataDefinition, query: Query) {
        const bundleKey = `${dataDefinition.Id}-${primaryCompanyId}-${relatedCompanyId}`;
        if (dataDefinition.Type === 'CspInvoiceDataDigest') {
            const params = { dataDefinitionId: dataDefinition.Id, primaryCompanyId, relatedCompanyId };
            return this.asyncBundler.bundle(bundleKey, query, async (r) => await postCspInvoiceDataDigestContollerMultiQuery(r, params));
        }
        return null;
    }
}

@injectable()
export class DataMarketplaceSchemaService {
    public async getSchemaSvc(primaryCompanyId: number, relatedCompanyId: number | undefined, dataDefinition: DataDefinition) {
        let types: SchemaType[] = [];

        if (dataDefinition.Type === 'CspInvoiceDataDigest') {
            types = await this.getCspInvoiceDataDigestSchema(primaryCompanyId, relatedCompanyId, dataDefinition);
        }

        return new SchemaService(types);
    }

    private async getCspInvoiceDataDigestSchema(
        primaryCompanyId: number,
        relatedCompanyId: number | undefined,
        dataDefinition: DataAccessDataDefinition
    ) {
        const response = await postCspInvoiceDataDigestContollerQuery(
            { IncludeSchema: true, Take: 0, IncludeCount: false },
            { dataDefinitionId: dataDefinition.Id, primaryCompanyId, relatedCompanyId }
        );

        const types = response.Types ?? [];

        const digest = dataDefinition.Digest as unknown as CspInvoiceDataDigestDefinition;

        const hiddenContextFields = new Set<DigestContextBasedSelectFieldField>(['RelationshipCompanyId', 'SourceCompanyId']);
        const hiddenContextAliases = new Set<string>(
            digest.ContextFields?.map((f) => (hiddenContextFields.has(f.Field!) ? f.Alias ?? '' : '')) ?? []
        );
        const systemFields = new Set<string>(['DataAccessRelationshipId', 'Status', 'DatasetKey', 'RequestKey', 'UniqueId']);

        types.forEach((t) => {
            t.Fields = t.Fields?.filter((f) => !hiddenContextAliases.has(f.Field ?? '') && !systemFields.has(f.Field ?? ''));
        });

        return types;
    }
}

import { Button, Group, Space, Textarea, TextInput } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { Alert } from '@mantine/core';
import { SendNotification } from './ConnectOne';
import { useDi } from '@root/Services/DI';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { NotificationService } from '@root/Services/Notification/NotificationService';

export function AdminInviteModal(props: { onClose: () => void; type: string }) {
    const authNSvc = useDi(AuthenticationService);
    const notificationSvc = useDi(NotificationService);
    const user = authNSvc.user;
    const company = useCompany();
    const [emailToInvite, setEmail] = useInputState('');
    const [messageFromInviter, setMessage] = useInputState('');
    const [error, setError] = useInputState(false);

    async function SendInvitation() {
        if (emailToInvite == '' || messageFromInviter == '') {
            setError(true);
        } else {
            setError(false);
            try {
                SendNotification(emailToInvite, messageFromInviter, company?.Id!, user?.ExternalId!);
                props.onClose();
                notificationSvc.notify('Admin invited', 'Your admin has been invited. ', 'success', <i className="ti ti-mail-forward" />);
            } catch {
                setError(true);
            }
        }
    }

    return (
        <>
            <TextInput size="md" type="email" label="Email" placeholder="cloud.engineer@mycompany.com" value={emailToInvite} onChange={setEmail} />
            <br />
            <Textarea size="md" label="Message" placeholder="Add Message" onChange={setMessage} value={messageFromInviter} minRows={4} />
            <Space h="md" />
            <Group position="right">
                <Button onClick={SendInvitation}>Send</Button>
                <Button variant="subtle" onClick={props.onClose}>
                    Cancel
                </Button>
            </Group>
            <Alert style={{ background: '#ffcccb' }} hidden={!error}>
                Form must be filled out.
            </Alert>
        </>
    );
}

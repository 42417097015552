import { DateRangeFilter, DateRangeFilterModel } from '@root/Components/Filter/DateRangeFilter';
import { addMonths, endOfMonth, format, startOfMonth } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';

export function InvoiceDateRange({
    constraint,
    onChange,
    value,
}: {
    constraint: { min?: Date; max?: Date };
    onChange: (value: { from?: Date; to?: Date }) => void;
    value: { from?: Date; to?: Date };
}) {
    const model = useMemo(() => new DateRangeFilterModel(), []);
    const [dateRange, setDateRange] = useState<{ from?: Date; to?: Date }>();
    const [mode, setMode] = useState<'combo' | 'calendar'>('combo');
    const options = useMemo(() => {
        const result = { items: [] as string[], lookup: new Map<string, { from: Date; to: Date }>() };
        const dataMax = constraint.max ? endOfMonth(constraint.max) : endOfMonth(new Date());
        const endOfCurrentMonth = endOfMonth(new Date());
        const max = dataMax.getTime() > endOfCurrentMonth.getTime() ? endOfCurrentMonth : dataMax;
        let date = constraint.min ? startOfMonth(constraint.min) : new Date();
        while (date) {
            const from = startOfMonth(date);
            const to = endOfMonth(date);
            const name = format(date, 'yyyy - LLLL');
            result.items.push(name);
            result.lookup.set(name, { from, to });
            date = addMonths(date, 1);
            if (date.getTime() > max.getTime()) {
                break;
            }
        }
        result.items.push('Custom');
        return result;
    }, [constraint.min, constraint.max]);
    const handleDateChanged = useCallback((range: { from?: Date; to?: Date }) => {
        setDateRange(range);
        if (range.from && range.to) {
            setMode('combo');
            model.toggleDialog.emit();
        }
    }, []);
    const handleItemChanged = useCallback(
        (item: string[]) => {
            const [name] = item;
            if (name === 'Custom') {
                setMode('calendar');
            } else {
                const range = options.lookup.get(name);
                setDateRange(range);
                model.toggleDialog.emit();
            }
        },
        [options]
    );
    useEffect(() => {
        if (dateRange && dateRange.from && dateRange.to) {
            onChange(dateRange);
        }
    }, [dateRange?.from, dateRange?.to, onChange]);
    return (
        <DateRangeFilter
            corners={4}
            height={36}
            constraint={constraint}
            value={value}
            onChange={handleDateChanged}
            handleItemChanged={handleItemChanged}
            listItems={options.items}
            mode={mode}
            model={model}
        />
    );
}

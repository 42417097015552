import { Company } from '@apis/Customers/model';
import { useMemo } from 'react';
import { inject, Lifecycle, scoped } from 'tsyringe';
import { ICompanyContextToken } from '../Customers/CompanyContext';
import { useDiContainer } from '../DI';
import { EventEmitter, useEventValue } from '../EventEmitter';
import { PlatformService } from '../PlatformService';
import { InvoiceApiCache } from './InvoiceApiCache';

export type InvoiceCostFields = 'lineItem/UnblendedCost' | 'lineItem/AmortizedCost' | 'AdjustedAmortizedCost' | 'AdjustedCashCost';

@scoped(Lifecycle.ContainerScoped)
export class InvoiceCostFieldContext {
    private availableFields = new Set<string>();
    public field = new EventEmitter<InvoiceCostFields>('lineItem/UnblendedCost');
    public readonly hasAdjustedCost: boolean;
    public constructor(
        @inject(ICompanyContextToken) company: Company,
        @inject(PlatformService) platformSvc: PlatformService,
        @inject(InvoiceApiCache) invoiceApi: InvoiceApiCache
    ) {
        const invoiceSchemaSvc = invoiceApi.getInvoiceSchemaSvc(platformSvc, company?.Id);
        const schemaTypes = invoiceSchemaSvc.getPrecachedSchema();
        if (!schemaTypes) {
            throw `The schema has not been preloaded. You should add the withSchemaPreloader HOC to the parent component.`;
        }

        const fields = schemaTypes.flatMap(
            (t) =>
                t.Fields?.filter(
                    (f) =>
                        f.Field === 'AdjustedAmortizedCost' ||
                        f.Field === 'AdjustedCashCost' ||
                        f.Field === 'lineItem/UnblendedCost' ||
                        f.Field === 'lineItem/AmortizedCost'
                ).map((f) => f.Field!) ?? []
        );
        this.availableFields = new Set(fields);
        this.hasAdjustedCost = this.availableFields.has('AdjustedCashCost');
        const defaultField = 'lineItem/UnblendedCost';
        this.field = new EventEmitter<InvoiceCostFields>(defaultField);
    }

    public update = (field: InvoiceCostFields) => {
        this.field.emit(field);
    };
}

export function useInvoiceCostFieldCtx() {
    const container = useDiContainer();
    const ctx = useMemo(() => container.resolve(InvoiceCostFieldContext), []);

    return ctx;
}

export function useInvoiceCostField() {
    const ctx = useInvoiceCostFieldCtx();
    return useEventValue(ctx.field)!;
}

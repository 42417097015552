import { CloseButton, Group, Text, useMantineTheme } from '@mantine/core';
import React, { forwardRef } from 'react';

export const AccountPickerItem = forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'> & { label: string; accountName: string }>(
    ({ label, accountName, ...others }, ref) => (
        <div ref={ref} {...others}>
            <Text>{label}</Text>
            <Text size="xs" color="dimmed">
                {accountName}
            </Text>
        </div>
    )
);

export const AccountValueItem = forwardRef<
    HTMLDivElement,
    React.ComponentPropsWithoutRef<'div'> & { onRemove: () => void; label: string; accountName: string }
>(({ label, accountName, onRemove, ...others }, ref) => {
    const theme = useMantineTheme();
    return (
        <div {...others} style={{ border: `solid 1px ${theme.colors.gray[3]}`, borderRadius: 3, background: theme.colors.gray[1] }}>
            <Group spacing={0}>
                <Text size="xs" px="xs">
                    {label} {accountName}
                </Text>
                <CloseButton onClick={onRemove} variant="transparent" size="sm" iconSize={14} tabIndex={-1} />
            </Group>
        </div>
    );
});

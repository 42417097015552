import styled from '@emotion/styled';
import { MantineNumberSize, Divider, Text, Space } from '@mantine/core';
import { ReactNode } from 'react';

export const chartTypeIcons = new Map<string, string>([
    ['kpi', 'ti ti-circle-number-1'],
    ['grid', 'ti ti-border-all'],
    ['gauge', 'ti ti-gauge'],
    ['pie', 'ti ti-chart-pie'],
    ['bar', 'ti ti-chart-bar'],
    ['line', 'ti ti-chart-line'],
]);

export function SettingsLabel({ children, icon, size }: { children: ReactNode; icon?: string; size?: MantineNumberSize }) {
    return (
        <Text size={size ?? 'sm'} sx={{ whiteSpace: 'nowrap', lineHeight: '40px' }}>
            {icon ? <LabelIcon className={icon}></LabelIcon> : null} {children}
        </Text>
    );
}

export function SettingsDivider() {
    return (
        <>
            <Space h="md" />
            <Divider mx={-16} color="gray.3" />
            <Space h="md" />
        </>
    );
}

export const LabelIcon = styled.i`
    font-size: 1.25rem;
    vertical-align: sub;
    color: ${(p) => p.theme.colors.gray[6]};
    margin-right: ${(p) => p.theme.fontSizes.xs / 4}px;
`;

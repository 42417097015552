import { FormatService } from '@root/Services/FormatService';
import { FieldInfo } from '@root/Services/QueryExpr';
import { differenceInCalendarDays } from 'date-fns';
import { inject, singleton } from 'tsyringe';
import { AnonymousValueGridCell } from './Components';
import { DataColumnConfig, AccessorPath, AnonymousValue } from './Models';

export interface FieldInfoColumnModifier {
    exclude?: boolean;
    group?: string;
    name?: string;
    format?: string;
    idAsPath?: boolean;
    helpText?: string;
}

@singleton()
export class FieldInfoColumnAdapter<T extends object> {
    public constructor(@inject(FormatService) private readonly formatterSvc: FormatService) {}

    public adapt(fieldInfo: FieldInfo, modifiers?: FieldInfoColumnModifier) {
        const filter = this.getFilterType(fieldInfo);
        return {
            id: modifiers?.idAsPath ? fieldInfo.path : fieldInfo.pathWithRoot,
            accessor: this.createAccessor(this.getAccessorPath(fieldInfo)),
            defaultWidth: this.getDefaultWidth(fieldInfo),
            header: fieldInfo.name,
            sortField: fieldInfo.path,
            formatter: this.getFormatter(modifiers?.format ?? fieldInfo.format ?? fieldInfo.typeName),
            helpText: modifiers?.helpText ?? fieldInfo.helpText,
            groupName: modifiers?.group ?? fieldInfo.groupName,
            cellRenderer(item: T) {
                return <AnonymousValueGridCell formatter={this.formatter} value={(this.accessor as (item: T) => AnonymousValue)(item)} />;
            },
            type: fieldInfo.typeName,
            filter: filter
                ? {
                      filterType: filter,
                      name: fieldInfo.name,
                      filterField: fieldInfo.path,
                  }
                : undefined,
            align: this.getDefaultAlignment(fieldInfo),
            minWidth: 30,
        } as DataColumnConfig<T>;
    }

    public createAccessor(accessorPath: AccessorPath) {
        const valueCache = new WeakMap<T, AnonymousValue>();
        const unwrapValue = (item: T) => {
            let value = valueCache.get(item);
            if (!value) {
                valueCache.set(item, (value = this.evaluatePath(item, accessorPath)));
            }
            return value;
        };

        return (item: T) => {
            return unwrapValue(item);
        };
    }

    public getAccessorPath(field: FieldInfo): AccessorPath {
        const accessorPath = field
            .getPath()
            .slice(0, -1)
            .map((f) => ({ accessor: f, isLeaf: false }));
        accessorPath.push({ accessor: field.fieldName ?? '', isLeaf: true });
        return accessorPath;
    }

    private evaluatePath(item: T, path: { accessor: string; isLeaf: boolean }[]) {
        const result: AnonymousValue = { first: undefined, values: [], hasMany: false };
        let children: Record<string, any>[] = [item];

        for (const accessor of path) {
            if (accessor.isLeaf) {
                for (const child of children) {
                    const value = child[accessor.accessor];
                    if (value instanceof Array) {
                        result.hasMany = true;
                        result.values.push(...value);
                    } else {
                        result.values.push(value);
                    }
                }
            } else {
                const nextChildren: Record<string, any>[] = [];
                for (const child of children) {
                    const value = child[accessor.accessor];
                    if (value instanceof Array) {
                        result.hasMany = true;
                        nextChildren.push(...value);
                    } else if (typeof value === 'object' && value !== null) {
                        nextChildren.push(value);
                    }
                }
                children = nextChildren;
            }
        }

        result.first = result.values[0];

        return result;
    }

    private getDefaultWidth(field: FieldInfo) {
        switch (field.typeName) {
            case 'string':
                return 180;
            case 'number':
                return 120;
            case 'date':
                return 120;
            case 'boolean':
                return field.name.length < 6 ? 50 : 100;
            default:
                return 140;
        }
    }
    private getDefaultAlignment(field: FieldInfo) {
        switch (field.typeName) {
            case 'number':
                return 'right';
            case 'boolean':
                return 'center';
            default:
                return 'left';
        }
    }

    private getFilterType(field: FieldInfo) {
        switch (field.typeName) {
            case 'string':
                return 'string';
            case 'number':
                return 'number';
            case 'date':
                return 'date';
            case 'boolean':
                return 'boolean';
            default:
                return null;
        }
    }

    private getFormatter(formatter?: string) {
        switch (formatter) {
            case 'whole-percent':
                return (_: T, value: number) =>
                    typeof value !== 'number' ? '' : value < 1 && value > 0 ? `< 1%` : `${this.formatterSvc.formatInt0Dec(value)}%`;
            case 'percent':
                return (_: T, value: number) =>
                    typeof value !== 'number' ? '' : value < 0.01 && value > 0 ? `< 1%` : this.formatterSvc.formatPercent(value);
            case 'string':
                return (_: T, value: string) => value;
            case 'number':
                return (_: T, value: number) => value;
            case 'number-with-two-decimals':
                return (_: T, value: number) => {
                    const numValue = typeof value === 'string' ? parseFloat(value) : value;
                    const formatted = value === undefined ? '' : numValue === 0 ? numValue : this.formatterSvc.formatDecimal(numValue, 2);
                    return formatted;
                };
            case 'number-with-four-decimals':
                return (_: T, value: number) => {
                    const numValue = typeof value === 'string' ? parseFloat(value) : value;
                    const formatted = numValue === 0 ? numValue : this.formatterSvc.formatDecimal(numValue, 4);
                    return formatted;
                };
            case 'bytes':
                return (_: T, value: number) => this.formatterSvc.formatBytes(value, null);
            case 'date':
                return (_: T, value: Date) => {
                    const date = typeof value === 'string' ? this.formatterSvc.toLocalDate(value) : value;
                    return !date
                        ? ''
                        : differenceInCalendarDays(new Date(), date) > 1
                        ? this.formatterSvc.formatDate(date)
                        : this.formatterSvc.formatDatetime(date);
                };
            case 'short-date':
                return (_: T, value: Date) => {
                    const date = typeof value === 'string' ? this.formatterSvc.parseDateNoTime(value) : value;
                    return date ? this.formatterSvc.toShortDate(date) : '';
                };
            case 'short-month':
                return (_: T, value: Date) => {
                    const date = typeof value === 'string' ? this.formatterSvc.parseDateNoTime(value) : value;
                    return date ? this.formatterSvc.formatShortMonthYear(date) : '';
                };
            case 'money':
                return (_: T, value: number) => this.formatterSvc.formatMoneyNoDecimals(value);
            case 'boolean':
                return (_: T, value: boolean) => (value === true ? 'Yes' : value === false ? 'No' : value);
            default:
                return (_: T, value: string) => value;
        }
    }
}

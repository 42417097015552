import { postResourcesQuery } from '@apis/Resources';
import { BaseResource } from '@apis/Resources/model';
import { Anchor, Card, LoadingOverlay, Popover, Title, UnstyledButton, Accordion } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { DataGrid } from '@root/Components/DataGrid';
import { ColumnConfig, DataGridState } from '@root/Components/DataGrid/Models';
import { FieldPicker } from '@root/Components/Picker/FieldPicker';
import { PagePanel, PanelBody, PaneledPage } from '@root/Design/Layout';
import { colorPalette } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { useNav } from '@root/Services/NavigationService';
import { FieldInfo, queryBuilder, SchemaService, ValuesGroupOtherText } from '@root/Services/QueryExpr';
import { ResourceSchemaProvider } from '@root/Services/Resources/ResourceService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useLink } from '@root/Services/Router/Router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { OpenSidebar } from '../Components/OpenSidebar';
import { OperationState } from '../Components/TagConsolidationSidePanel';
import { TagIntelligencePage } from './TagIntelligencePage';

function UntaggedResources() {
    return (
        <TagIntelligencePage>
            <PaneledPage>
                <PagePanel size="fill">
                    <PanelBody>
                        <Title data-atid="IntelligenceHeader" order={2}>
                            Untagged Resources
                        </Title>
                        <Accordion defaultValue="CleanTags" variant="separated" radius="lg" my="xl" chevron={<i className="ti ti-circle-minus" />}>
                            <Accordion.Item value="CleanTags" px="xl" py="md">
                                <Accordion.Control>How do I find and tag untagged resources?</Accordion.Control>
                                <Accordion.Panel>
                                    View sets of untagged resources by any non-tag characteristic that would allow association with proper tags. For
                                    example, this could be Resource Type, VPC, or Subnet. Then follow the link to add tags in Tag Explorer.
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>

                        <Card p="md" withBorder radius="lg" sx={{ height: 600 }}>
                            <ByServiceGrid />
                        </Card>
                    </PanelBody>
                </PagePanel>
            </PaneledPage>
        </TagIntelligencePage>
    );
}
endpoint('untagged-resources', UntaggedResources, 'Tag Intelligence');

type ByServiceDataItem = {
    type: string;
    resourceCount: number;
    taggedResources: number;
};

function ByServiceGrid() {
    const getData = useCallback(async (viewByField: string) => {
        const results = await queryBuilder<BaseResource>()
            .take(500)
            .select((b) => ({
                type: {
                    Operation: 'values',
                    Operands: [{ Field: viewByField }, { Value: '' }, { Value: ValuesGroupOtherText }],
                } as unknown as string,
                resourceCount: b.count(),
                taggedResources: b.count(b.model['Tags.Key'].isNotNull()),
            }))
            .execute(postResourcesQuery);
        return results.Results ?? [];
    }, []);
    const resourceSchemaProvider = useDi(ResourceSchemaProvider);
    const [schema, setSchema] = useState<SchemaService>();
    useEffect(() => {
        (async () => {
            const types = await resourceSchemaProvider.getSchema();
            setSchema(new SchemaService(types));
        })();
    }, []);

    const columns = useMemo(() => {
        const result: ColumnConfig<ByServiceDataItem>[] = [
            {
                header: 'Total Resources',
                accessor: 'resourceCount',
                defaultWidth: 200,
                id: 'resourceCount',
                type: 'number',
                align: 'center',
                filter: {
                    filterField: 'resourceCount',
                    filterType: 'number',
                    name: 'Resources',
                },
                cellRenderer: (item) => item.resourceCount.toLocaleString(),
            },
            {
                header: 'Tagged Resources',
                accessor: 'taggedResources',
                defaultWidth: 200,
                id: 'taggedResources',
                type: 'number',
                align: 'center',
                filter: {
                    filterField: 'taggedResources',
                    filterType: 'number',
                    name: 'Tagged Resources',
                },
                cellRenderer: (item) => item.taggedResources.toLocaleString(),
            },
        ];
        return result;
    }, []);

    if (schema) {
        return <ViewByGrid getData={getData} metricColumns={columns} schema={schema} />;
    } else {
        return null;
    }
}

export function ViewByGrid({
    schema,
    metricColumns,
    getData,
}: {
    schema: SchemaService;
    metricColumns: ColumnConfig<ByServiceDataItem>[];
    getData: (viewBy: string) => Promise<ByServiceDataItem[]>;
}) {
    const dataEvent = useMemo(() => new EventEmitter<ByServiceDataItem[] | undefined>(undefined), []);
    const data = useEventValue(dataEvent);
    const { getDescendUrl } = useNav();
    const link = useLink();
    const [viewBy, setViewBy] = useState(schema.getField('ResourceType'));

    useEffect(() => {
        (async () => {
            const viewByField = viewBy?.path ?? 'ResourceType';
            const results = await getData(viewByField);
            dataEvent.emit(results);
        })();
    }, [viewBy]);
    const columns = useMemo(() => {
        return [
            {
                header: viewBy?.name ?? 'Resource Type',
                accessor: (item) => (item.type === ValuesGroupOtherText ? 'Other' : item.type),
                defaultWidth: 300,
                id: 'type',
                sortField: 'type',
                filter: {
                    filterField: 'type',
                    filterType: 'string',
                    name: viewBy?.name ?? 'Resource Type',
                    options: {
                        getValueProvider: () =>
                            dataEvent.value?.map((d) => ({ value: d.type, label: d.type === ValuesGroupOtherText ? 'Other' : d.type })),
                    },
                },
                headerRenderer: () => viewBy?.name ?? 'Resource Type',
                cellRenderer: (item) => {
                    return item.type === ValuesGroupOtherText ? 'Other' : item.type;
                },
            },
            ...metricColumns,
            {
                header: 'Untagged Resources',
                accessor: (item) => item.resourceCount - item.taggedResources,
                defaultWidth: 200,
                id: 'untaggedResources',
                type: 'number',
                align: 'center',
                filter: {
                    filterField: 'untaggedResources',
                    filterType: 'number',
                    name: 'Untagged Resources',
                },
                cellRenderer: (item) => {
                    const filter1 =
                        item.type === ValuesGroupOtherText
                            ? { Operation: 'isNull', Operands: [{ Field: viewBy?.path }] }
                            : { Operation: 'eq', Operands: [{ Field: viewBy?.path }, { Value: item.type }] };
                    const filter2 = {
                        Operation: 'isNull',
                        Operands: [{ Field: ['Tags.Key'] }],
                    };
                    const state: DataGridState = {
                        columns: [],
                        filters: [filter1, filter2],
                        sort: [],
                    };
                    const operation: OperationState = {
                        operation: OpenSidebar.addTags,
                    };
                    const stateData = JSON.stringify(state);
                    const operationData = JSON.stringify(operation);
                    return (
                        <Anchor {...link(getDescendUrl('tag-explorer', { state: stateData, operation: operationData }))}>
                            {(item.resourceCount - item.taggedResources).toLocaleString()}
                        </Anchor>
                    );
                },
            },
        ] as ColumnConfig<ByServiceDataItem>[];
    }, [data, viewBy]);

    return !data ? (
        <LoadingOverlay visible />
    ) : (
        <DataGrid
            key={viewBy?.path}
            hideGlobalSearch
            leftTopPlaceHolder={<ViewByPicker field={viewBy} schema={schema} onChange={setViewBy} />}
            dataSource={data}
            columns={columns}
            hideMenu={false}
            hideColumnSelector
            hideHeader
        />
    );
}

function ViewByPicker(props: { schema: SchemaService; field?: FieldInfo; onChange: (field: FieldInfo) => void }) {
    const [opened, { open, close, toggle }] = useDisclosure(false);
    const onSelect = (fields: FieldInfo[]) => {
        props.onChange(fields[0]);
        close();
    };
    return (
        <Popover opened={opened} onClose={close}>
            <Popover.Target>
                <UnstyledButton
                    px={15}
                    onClick={open}
                    sx={{
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderRadius: 15,
                        fontSize: 14,
                        borderColor: '#0002',
                        color: colorPalette.linkColor,
                        background: colorPalette.fffColor,
                        margin: 'var(--item-margin)',
                        height: '30px',
                        marginRight: '10px',
                    }}
                >
                    <i className={'ti ti-eye'}></i> View By: {props.field?.name ?? 'Resource Type'}
                </UnstyledButton>
            </Popover.Target>
            <Popover.Dropdown p={0}>
                <FieldPicker mode="single" schema={props.schema} onChange={onSelect} selections={props.field ? [props.field] : []} />
            </Popover.Dropdown>
        </Popover>
    );
}

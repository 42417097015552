import { Box, Button } from '@mantine/core';
import { TooltipWhite } from '@root/Design/Primitives';
import { CSSProperties } from 'react';
import { Check } from 'tabler-icons-react';

interface AssessmentsPlatformSelectorProps {
    selectedType: string;
    setType: (type: string) => void;
    style?: CSSProperties;
}
export function AssessmentsPlatformSelector({ ...props }: AssessmentsPlatformSelectorProps) {
    function showAllDetails() {
        props.setType('All');
    }
    function showAws() {
        props.setType('Aws');
    }
    function showAzure() {
        props.setType('Azure');
    }

    return (
        <Box style={{ ...props.style }}>
            <TooltipWhite label="Select Service Provider">
                <Button.Group>
                    <Button
                        data-atid="platformSelectorShowAllButton"
                        size="xs"
                        variant={props.selectedType == 'All' ? 'filled' : 'outline'}
                        onClick={showAllDetails}
                    >
                        All
                    </Button>
                    <Button
                        data-atid="platformSelectorAwsButton"
                        size="xs"
                        variant={props.selectedType == 'Aws' ? 'filled' : 'outline'}
                        onClick={showAws}
                    >
                        AWS
                    </Button>
                    <Button
                        data-atid="platformSelectorAzureButton"
                        size="xs"
                        variant={props.selectedType == 'Azure' ? 'filled' : 'outline'}
                        onClick={showAzure}
                    >
                        Azure
                    </Button>
                </Button.Group>
            </TooltipWhite>
        </Box>
    );
}

interface AssessmentsHistoryScoreTypePickerProps {
    selectedType: string;
    setType: (type: string) => void;
    style?: CSSProperties;
}
export function AssessmentsHistoryScoreTypePicker({ ...props }: AssessmentsHistoryScoreTypePickerProps) {
    function setScore() {
        props.setType('score');
    }
    function setCost() {
        props.setType('cost');
    }

    return (
        <Box style={{ ...props.style }}>
            <TooltipWhite label="Score or Cost?">
                <Button.Group>
                    <Button
                        data-atid="historyScoreTypeSelectorScoreButton"
                        size="xs"
                        variant={props.selectedType == 'score' ? 'filled' : 'outline'}
                        onClick={setScore}
                    >
                        Score
                    </Button>
                    <Button
                        data-atid="historyScoreTypeSelectorCostButton"
                        size="xs"
                        variant={props.selectedType == 'cost' ? 'filled' : 'outline'}
                        onClick={setCost}
                    >
                        Cost
                    </Button>
                </Button.Group>
            </TooltipWhite>
        </Box>
    );
}

interface AssessmentsRangePickerProps {
    selectedInterval: string;
    setInterval: (type: string) => void;
}
export function AssessmentsHistoryRangePicker({ ...props }: AssessmentsRangePickerProps) {
    return (
        <Box sx={{ margin: '0px 10px' }}>
            <TooltipWhite label="Adjust Date range">
                <Button.Group my={5}>
                    <Button
                        key={'week'}
                        sx={{ height: 30, fontWeight: 'normal' }}
                        variant="outline"
                        radius="lg"
                        leftIcon={props.selectedInterval === 'week' ? <Check size={16} /> : undefined}
                        onClick={() => props.setInterval('week')}
                        data-atid={`Interval-week`}
                    >
                        Week
                    </Button>
                    <Button
                        key={'month'}
                        sx={{ height: 30, fontWeight: 'normal' }}
                        variant="outline"
                        radius="lg"
                        leftIcon={props.selectedInterval === 'month' ? <Check size={16} /> : undefined}
                        onClick={() => props.setInterval('month')}
                        data-atid={`Interval-month`}
                    >
                        Month
                    </Button>
                    <Button
                        key={'3month'}
                        sx={{ height: 30, fontWeight: 'normal' }}
                        variant="outline"
                        radius="lg"
                        leftIcon={props.selectedInterval === '3month' ? <Check size={16} /> : undefined}
                        onClick={() => props.setInterval('3month')}
                        data-atid={`Interval-3month`}
                    >
                        Last 3 Months
                    </Button>
                    <Button
                        key={'year'}
                        sx={{ height: 30, fontWeight: 'normal' }}
                        variant="outline"
                        radius="lg"
                        leftIcon={props.selectedInterval === 'year' ? <Check size={16} /> : undefined}
                        onClick={() => props.setInterval('year')}
                        data-atid={`Interval-year`}
                    >
                        Year
                    </Button>
                </Button.Group>
            </TooltipWhite>
        </Box>
    );
}

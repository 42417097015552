import { useEffect } from 'react';
import { DateRangeFilter, DateRangeFilterModel } from '../../../Components/Filter/DateRangeFilter';
import { useDiContainer } from '../../../Services/DI';
import { Box, Group, Title, Select } from '@mantine/core';

export interface CreditFeeCardsProps {
    range: { startDate: Date; endDate: Date };
    onRangeChanged: (range: { startDate: Date; endDate: Date }) => void;
    selectedPeriodChanged: (selectedPeriod: string) => void;
    selectionLabel?: string;
    datesLablel?: string;
    corners?: number;
}

export const DatePeriodPicker = (props: CreditFeeCardsProps) => {
    const diContainer = useDiContainer();
    const calendarModel = diContainer.resolve(DateRangeFilterModel);
    const dateMap = new Map<string, () => void>();

    useEffect(() => {
        setDatesForThisMonth();
    }, []);

    const handleSelectedPeriodChanged = (items: string[]) => {
        if (items[0]) {
            props.selectedPeriodChanged(items[0]);

            if (items[0] === 'Custom') {
                calendarModel.showCalendar.emit(true);
            } else {
                calendarModel.toggleDialog.emit();
            }

            let method = dateMap.get(items[0]);
            if (method) {
                method();
            }
        }
    };

    const handleCalendarDates = (value: { from?: Date | undefined; to?: Date | undefined; id?: string }) => {
        if (value.from && value.to) {
            props.onRangeChanged({ startDate: value.from, endDate: value.to });
            calendarModel.showCalendar.emit(false);
        }
    };

    const handleDatesChanged = (newStartDate: Date, newEndDate: Date) => {
        props.onRangeChanged({ startDate: newStartDate, endDate: newEndDate });
    };

    const setDatesForThisMonth = () => {
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        handleDatesChanged(firstDay, date);
    };

    const setDatesForLastMonth = () => {
        let date = new Date();
        let lastMonth = date.getMonth() - 1;
        let firstDay = new Date(date.getFullYear(), lastMonth, 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        handleDatesChanged(firstDay, lastDay);
    };

    const setDatesForYearToDate = () => {
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), 0, 1);
        handleDatesChanged(firstDay, date);
    };

    const setDatesForAllTime = () => {
        let date = new Date();
        let firstDay = new Date(2000, 0, 1);
        handleDatesChanged(firstDay, date);
    };

    const setDatesForCustom = () => {
        calendarModel?.showCalendar.emit(true);
    };

    dateMap.set('This Month', setDatesForThisMonth);
    dateMap.set('Last Month', setDatesForLastMonth);
    dateMap.set('Year To Date', setDatesForYearToDate);
    dateMap.set('All Time', setDatesForAllTime);
    dateMap.set('Custom', setDatesForCustom);

    return (
        <>
            <Group>
                <Title order={5}>{props.datesLablel}</Title>
                <DateRangeFilter
                    mode="combo"
                    model={calendarModel}
                    value={{ from: props.range.startDate, to: props.range.endDate, id: 'none' }}
                    onChange={handleCalendarDates}
                    constraint={{ min: new Date(2000, 1, 1), max: new Date() }}
                    options={[{ id: '', label: 'Close' }]}
                    handleItemChanged={handleSelectedPeriodChanged}
                    listItems={[...dateMap.keys()]}
                    corners={3}
                />
            </Group>
        </>
    );
};

import { postNotificationSaveMyNotificationOption, postNotificationSaveMyNotificationTypePreference } from '@apis/Notification';
import {
    NotificationTypeConfig,
    NotificationTypeConfigOptionId,
    NotificationTypePreference,
    NotificationTypePreferenceNotificationType,
} from '@apis/Notification/model';
import { useMemo } from 'react';
import { useDi } from '../DI';
import { EventEmitter } from '../EventEmitter';
import { NotificationPreferenceService } from './NotificationPreferenceService';

export class NotificationCrudService {
    public readonly loading = new EventEmitter<boolean>(false);
    public readonly notificationConfigs: NotificationTypeConfig[] = [];
    private notificationPrefs = new Map<NotificationTypePreferenceNotificationType, NotificationTypePreference>();
    public readonly prefsChanging = new EventEmitter(false);
    private configLookup = new Map<NotificationTypePreferenceNotificationType, NotificationTypeConfig>();

    public constructor(private readonly notificationPrefSvc: NotificationPreferenceService) {}
    public init() {
        this.initialLoad();
        return this;
    }

    private async initialLoad() {
        try {
            this.loading.emit(true);
            const [configs] = await Promise.all([this.notificationPrefSvc.getApplicableNotificationsConfigs(), this.reload()]);
            this.notificationConfigs.splice(0, Infinity, ...configs);
            this.configLookup = new Map<NotificationTypePreferenceNotificationType, NotificationTypeConfig>(configs.map((c) => [c.Type!, c]));
        } finally {
            this.loading.emit(false);
        }
    }

    public async reload() {
        this.notificationPrefs = await this.notificationPrefSvc.getNotificationPreferenceLookup();
    }

    public async getConfigs() {
        return await this.notificationPrefSvc.getApplicableNotificationsConfigs();
    }

    public getApps() {
        const uniqueApps = new Set<string>([...this.configLookup.values()].flatMap((c) => c.ApplicableApps ?? []));
        const sortedApps = [...uniqueApps].sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
        return sortedApps;
    }

    public getOptionValue(notificationType: NotificationTypePreferenceNotificationType, id: string) {
        const pref = this.notificationPrefs.get(notificationType);
        var retValue = '';
        if (pref) {
            var optionList = pref.Options;
            if (optionList) {
                optionList.forEach((f) => {
                    if (f.Id == id) {
                        retValue = f.Value ?? '';
                    }
                });
            }
        }
        return retValue != '' ? retValue : undefined;
    }

    public getPref(notificationType: NotificationTypePreferenceNotificationType, messageType: 'Email' | 'SMS' | 'InApp') {
        const pref = this.notificationPrefs.get(notificationType);
        const config = this.configLookup.get(notificationType);
        if (pref) {
            const accessor = this.getMessageTypeAccessor(messageType);
            return accessor(pref) ?? config?.DefaultOn ?? false;
        }
        return config?.DefaultOn ?? false;
    }

    public async setPref(notificationType: NotificationTypePreferenceNotificationType, messageType: 'Email' | 'SMS' | 'InApp', enable: boolean) {
        try {
            this.prefsChanging.emit(true);
            const pref = this.notificationPrefs.get(notificationType) ?? { NotificationType: notificationType };
            await postNotificationSaveMyNotificationTypePreference({
                ...pref,
                NotificationType: notificationType,
                [messageType]: enable,
            });
            await this.reload();
        } finally {
            this.prefsChanging.emit(false);
        }
    }

    public async setOption(notificationType: NotificationTypePreferenceNotificationType, id: NotificationTypeConfigOptionId, value: string) {
        try {
            this.prefsChanging.emit(true);
            await postNotificationSaveMyNotificationOption({
                notificationType: notificationType,
                id: id,
                value: value,
            });
            await this.reload();
        } finally {
            this.prefsChanging.emit(false);
        }
    }

    private getMessageTypeAccessor(messageType: 'Email' | 'SMS' | 'InApp') {
        return (pref: NotificationTypePreference, enable?: boolean) => {
            if (enable !== undefined) {
                pref[messageType] = enable;
            }
            return pref[messageType];
        };
    }
}

export function useNotificationCrudSvc() {
    const notificationPrefSvc = useDi(NotificationPreferenceService);
    const result = useMemo(() => new NotificationCrudService(notificationPrefSvc).init(), []);
    return result;
}

import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { ActionsLogContent } from '../Actions';
import { SettingsPage } from './SettingsPage';

export function ActivityLogPage() {
    return (
        <SettingsPage>
            <ActionsLogContent />
        </SettingsPage>
    );
}
endpoint('settings-activity-log', ActivityLogPage, 'Activity Log');

import { getMapContractsGetMapContracts } from '@apis/TagManager';
import { MapContract } from '@apis/TagManager/model';
import { Box, LoadingOverlay } from '@mantine/core';
import { SubscriptionCheck } from '@root/Components/CompanyInfo/SubscriptionCheck';
import { AppFeatureNames } from '@root/Services/Customers/CompanyFeatureService';
import { useDi } from '@root/Services/DI';
import { useEvent } from '@root/Services/EventEmitter';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useEffect, useState } from 'react';
import { MapContractsList } from './MapContracts/MapContractsList';
import { MapContractsEvents } from './Services/MapContractsEvents';

export function MapContractsContent() {
    const [contracts, setContracts] = useState<MapContract[]>([]);
    const [loading, setLoading] = useState(false);
    const events = useDi(MapContractsEvents);
    const loadContracts = async () => {
        setLoading(true);
        try {
            const contracts = await getMapContractsGetMapContracts();
            setContracts(contracts);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        loadContracts();
    }, []);
    useEvent(events.onContractsChanged, loadContracts);
    return (
        <>
            {loading ? (
                <Box sx={{ position: 'relative', height: '400px' }}>
                    <LoadingOverlay visible />
                </Box>
            ) : (
                <MapContractsList contracts={contracts} />
            )}
        </>
    );
}

function MapContracts() {
    return (
        <SubscriptionCheck app="Compliance" featureName={AppFeatureNames.MAPManager}>
            {() => <MapContractsContent />}
        </SubscriptionCheck>
    );
}

endpoint('map-contracts', MapContracts, 'MAP Contracts');

import styled from '@emotion/styled';
import { Box, Button, Divider, Group, Loader, LoadingOverlay, Space, Text, Title, Tooltip, useMantineTheme } from '@mantine/core';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';
import { InitialSyncCheck } from '@root/Components/Resources/IntialSyncCheck';
import { PageContent, PagePanel, PanelBody, PaneledPage, PanelToolbar } from '@root/Design/Layout';
import { CustomColors, theme } from '@root/Design/Themes';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { useNav } from '@root/Services/NavigationService';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { observer } from 'mobx-react';
import { useCallback, useEffect } from 'react';
import { inject, Lifecycle, scoped } from 'tsyringe';
import { AutoTagRuleCard } from './Components/AutoTagRuleCard';
import EditRuleForm from './Components/EditRuleForm';
import { RuleEditor, TagLookupService } from './Components/Model';
import { ReplacementRule } from './Components/ReplacementRule';
import { SyntaxRuleCard } from './Components/SyntaxRuleCard';
import { TagAutomationRuleEvents, TagAutomationRuleService } from './Components/TagAutomationRuleService';
import { PreviewResources } from './Components/PreviewRuleCard';
import { RuleTestStatus } from './Components/RuleTestStatus';
import { FormatService } from '@root/Services/FormatService';
import { InheritanceRuleCard } from './Components/InheritanceRuleCard';
import { ResourceGroupRuleCard } from './Components/ResourceGroupRuleCard';
import { AwsOrgEntityGroupRuleCard } from './Components/AWSOrgEntityGroupRuleCard';
import { withAppFeatureCheck } from '@root/Components/Shell/AppFeatureAccess';
import { AppFeatureNames } from '@root/Services/Customers/CompanyFeatureService';

@scoped(Lifecycle.ContainerScoped)
class RuleEditorContext {
    private initialized = false;
    public loading = new EventEmitter(true);
    public ruleEditor: RuleEditor;

    public constructor(
        @inject(TagLookupService) private readonly tagLookupSvc: TagLookupService,
        @inject(FormatService) private readonly formatSvc: FormatService
    ) {
        this.ruleEditor = new RuleEditor(formatSvc);
    }

    public async load(ruleId?: string) {
        this.loading.emit(true);
        try {
            const id = parseInt(ruleId ?? '');
            this.ruleEditor.init(isNaN(id) ? undefined : id);
            if (!this.initialized) {
                await this.tagLookupSvc.init();
                this.initialized = true;
            }
        } finally {
            this.loading.emit(false);
        }
    }
}

export const TagAutomationRuleContent = observer(function TagAutomationRuleContent() {
    const nav = useNav();
    const theme = useMantineTheme();
    const { ruleId } = nav.getData('ruleId');
    const editorCtx = useDi(RuleEditorContext);
    const ruleEvents = useDi(TagAutomationRuleEvents);
    const notificationSvc = useDi(NotificationService);
    useEffect(() => {
        editorCtx.load(ruleId);
    }, [ruleId]);
    const loading = useEventValue(editorCtx.loading);

    const tagPermissions = useAuthZValues({
        canCreate: { TagAutomation: 'Create' },
    });

    const save = useCallback(async () => {
        if (editorCtx.ruleEditor.canSave()) {
            const success = await editorCtx.ruleEditor.save();
            if (success) {
                ruleEvents.ruleSaved.emit();
                notificationSvc.notify(
                    'Rule saved. Redirecting to rule dashboard.',
                    '',
                    'success',
                    <>
                        <i className="ti ti-circle-check"></i>
                    </>
                );
                nav.ascend();
            } else {
                notificationSvc.notify('Failed to save rule.', '', 'error', <></>);
            }
        }
    }, []);

    const cancel = () => {
        nav.ascend();
    };

    const ruleType = editorCtx.ruleEditor.rule?.Parameters?.Type;

    return (
        <EditRulePage>
            <EditRuleContainer>
                <Columns style={{ height: '100%' }}>
                    <Box p="lg" sx={{ background: theme.colors.gray[1], borderRight: `solid 1px ${theme.colors.gray[3]}` }}>
                        <Title data-atid="SiteSecondaryHeader" order={3}>
                            Rule Editor
                        </Title>
                        <Space h="md" />
                        {loading || !editorCtx.ruleEditor?.rule ? (
                            <Box sx={{ position: 'relative' }}>
                                <LoadingOverlay visible />
                            </Box>
                        ) : (
                            <>
                                <EditRuleForm rule={editorCtx.ruleEditor.rule} />
                                {ruleType === 'Replacement' && <ReplacementRule ruleEditor={editorCtx.ruleEditor} />}
                                {ruleType === 'AutoTag' && <AutoTagRuleCard ruleEditor={editorCtx.ruleEditor} />}
                                {ruleType === 'SyntaxCorrection' && <SyntaxRuleCard ruleEditor={editorCtx.ruleEditor} />}
                                {ruleType === 'Inheritance' && <InheritanceRuleCard ruleEditor={editorCtx.ruleEditor} />}
                                {ruleType === 'ResourceGroup' && <ResourceGroupRuleCard ruleEditor={editorCtx.ruleEditor} />}
                                <PreviewResources ruleEditor={editorCtx.ruleEditor} />
                            </>
                        )}
                    </Box>
                </Columns>
            </EditRuleContainer>
            <Divider />
            <Columns>
                <Box>
                    <Box p="md">
                        <Group position="apart">
                            <Box>{!loading && editorCtx.ruleEditor.rule ? <RuleTestStatus rule={editorCtx.ruleEditor} /> : <></>}</Box>
                            <Group>
                                <Button data-atid="SettingsCancelButton" onClick={cancel} variant="outline">
                                    Cancel
                                </Button>
                                <Button
                                    data-atid="SaveRuleButton"
                                    onClick={save}
                                    size="sm"
                                    disabled={!editorCtx.ruleEditor.canSave()}
                                    color={theme.colors?.primary?.[6] as CustomColors}
                                    leftIcon={editorCtx.ruleEditor.saving && <Loader color={'#fff' as CustomColors} size="xs" />}
                                >
                                    Save
                                </Button>
                            </Group>
                        </Group>
                    </Box>
                </Box>
                <Box></Box>
            </Columns>
        </EditRulePage>
    );
});

const Columns = styled.div`
    display: grid;
    grid-template-columns: 950px auto;
    align-items: stretch;
`;

const EditRulePage = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const EditRuleContainer = styled.div`
    overflow: auto;
    height: 100%;
    flex: 1;
`;

function EditTagAutomationRule() {
    return <ConnectionCheck>{() => <InitialSyncCheck>{() => <TagAutomationRuleContent />}</InitialSyncCheck>}</ConnectionCheck>;
}

function EditTagRuleBreadCrumb() {
    const editorCtx = useDi(RuleEditorContext);
    const rule = editorCtx.ruleEditor.rule;
    const type =
        rule?.Parameters?.Type === 'AutoTag'
            ? 'Auto-Tag'
            : rule?.Parameters?.Type === 'Inheritance'
            ? 'Tag Inheritance'
            : rule?.Parameters?.Type === 'MapCredits'
            ? 'MAP Credits'
            : rule?.Parameters?.Type === 'Replacement'
            ? 'Tag Replacement'
            : rule?.Parameters?.Type === 'SyntaxCorrection'
            ? 'Syntax Correction'
            : rule?.Parameters?.Type === 'ResourceGroup'
            ? 'Resource Group'
            : rule?.Parameters?.Type === 'AwsOUAccountGroup'
            ? 'Organizational Unit/Account'
            : '';
    const verb = rule?.Id ? '' : 'Adding';
    const loading = editorCtx.ruleEditor.loading;
    const text = (
        <Tooltip
            withArrow
            withinPortal
            color="gray.0"
            sx={{
                color: 'GrayText',
                boxShadow: '0px 4px 15px 0px #00000040',
            }}
            label={`${verb} ${type} Rule: ${rule?.RuleName || 'Unnamed'}`}
        >
            <Box sx={{ maxWidth: '300px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {`${verb} ${type} Rule: ${rule?.RuleName || 'Unnamed'}`}
            </Box>
        </Tooltip>
    );
    return <>{!loading ? text : 'Loading...'}</>;
}

endpoint('automation-rule', withAppFeatureCheck(EditTagAutomationRule, 'Compliance', AppFeatureNames.TagManager), observer(EditTagRuleBreadCrumb));

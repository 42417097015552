import { Grid } from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { ShowbackPersistence } from '@root/Services/Invoices/ShowbackService';
import { useMemo } from 'react';
import { KpiCard } from './Components';
import { ShowbackSummaryModel } from './ShowbackSummaryModel';

export function SummaryScoreboard({ summaryModel }: { summaryModel: ShowbackSummaryModel }) {
    const showbackSvc = useDi(ShowbackPersistence);
    const dimName = showbackSvc.getDimensionName(summaryModel.showbackModel.selectedAllocationDimension);
    const fmtSvc = useDi(FormatService);

    const loading = useEventValue(summaryModel.loading);
    const summaryData = useEventValue(summaryModel.dataChanged);

    const { least, most } = useMemo(() => {
        if (!summaryData?.dimSizes?.length) {
            return {};
        }
        const { dimSizes } = summaryData;

        const sortedSizes = dimSizes.sort((a, b) => a.amount - b.amount);
        const least = sortedSizes.shift();
        const most = sortedSizes.pop();
        return { least, most };
    }, [summaryData]);

    const invoiceTotal = summaryData?.stats?.total;
    const safeFmt = (value: number | undefined) => {
        return value === undefined ? 'No data' : fmtSvc.formatMoneyNoDecimals(value);
    };

    return (
        <Grid columns={3}>
            <Grid.Col md={1}>
                <KpiCard title="Invoice Total" loading={loading} value={safeFmt(invoiceTotal)} />
            </Grid.Col>
            <Grid.Col md={1}>
                <KpiCard title={`Most Expensive ${dimName}`} loading={loading} value={safeFmt(most?.amount)} label={most?.dim} />
            </Grid.Col>
            <Grid.Col md={1}>
                <KpiCard title={`Least Expensive ${dimName}`} loading={loading} value={safeFmt(least?.amount)} label={least?.dim} />
            </Grid.Col>
        </Grid>
    );
}

import { singleton } from 'tsyringe';
import { EventEmitter } from '../EventEmitter';

@singleton()
export class SuperUserService {
    public superUserOn = new EventEmitter<boolean>(false);

    public toggleSuperUserMode() {
        this.superUserOn.emit(!this.superUserOn.value);
    }
}

import { useMemo } from 'react';
import { BaseAwsResource, Ec2, Emr, Rds, Redshift } from '@apis/Resources/model';
import { PropertyGridItem } from '@root/Components/PropertyGrid/Models';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { ReactNode } from 'react';
import { IdleResourcesFieldValue, RightSizingFieldValue } from '../Common/FieldValueRenderers';

type ResourceTypeFieldConfig = {
    [resourceType: string]: {
        [fieldPath: string]: {
            label?: string;
            valueRenderer?: (item: PropertyGridItem) => ReactNode;
            format?: (item: PropertyGridItem) => string | undefined;
            value?: (resource: BaseAwsResource) => unknown;
            hidden?: boolean;
        };
    };
};

export function useAwsResourceFieldConfig() {
    const fmtSvc = useDi(FormatService);
    const awsCommonFields = {
        Account: {},
        AccountName: {},
        ManagementAccount: {},
        Region: {},
        BillingKey: {},
    };
    const resourceTypeFields: ResourceTypeFieldConfig = useMemo(
        () => ({
            'API Gateway Rest API': {
                'ApiKeySource.Value': {
                    label: 'API Key Source',
                },
                Description: {},
                DisableExecuteApiEndpoint: {
                    label: 'Disable Execute API Endpoint',
                },
            },
            'API Gateway Stage': {
                DeploymentId: {},
                ApiGatewayManaged: {
                    label: 'API Gateway Managed',
                },
                LastUpdatedDate: {
                    format: (item) => fmtSvc.toLocal(item.valueAsStr('')!),
                },
            },
            'AppStream Fleet': {
                Arn: { label: 'ARN' },
                DisconnectTimeoutInSeconds: {},
                EnableDefaultInternetAccess: {},
                'FleetType.Value': {
                    label: 'Fleet Type',
                },
                IdleDisconnectTimeoutInSeconds: {},
                ImageArn: {},
                ImageName: {},
                InstanceType: {},
                MaxUserDurationInSeconds: {
                    label: 'MaxUser Duration In Seconds',
                },
                'State.Value': {
                    label: 'State',
                },
                'StreamView.Value': {
                    label: 'Stream View',
                },
            },
            'AppStream Stack': {
                Description: {},
                DisplayName: {},
            },
            AppSync: {
                'ApiType.Value': {
                    label: 'API Type',
                },
                Arn: {
                    label: 'ARN',
                },
                'AuthenticationType.Value': {
                    label: 'Authentication Type',
                },
                OwnerContact: {
                    label: 'Owner/Contact',
                },
                'Visibility.Value': {
                    label: 'Visibility',
                },
                XrayEnabled: {
                    label: 'X-Ray Enabled',
                },
            },
            Athena: {
                'Configuration.EngineVersion.EffectiveEngineVersion': {},
                'Configuration.EngineVersion.SelectedEngineVersion': {},
            },
            'Athena Capacity Reservation': {
                AllocatedDpus: {},
                LastSuccessfulAllocationTime: {},
                'Status.Value': {
                    label: 'Status',
                },
            },
            'Batch Compute Environment': {
                'Type.Value': {
                    label: 'Type',
                },
                'State.Value': {
                    label: 'State',
                },
                'Status.Value': {
                    label: 'Status',
                },
                StatusReason: {},
                UnmanagedvCpus: {
                    label: 'UnManaged vCPUs',
                },
            },
            'Certificate Manager': {
                DomainName: {},
                'KeyAlgorithm.Value': {
                    label: 'KeyAlgorithm',
                },
                'Status.Value': {
                    label: 'Status',
                },
                'Type.Value': {
                    label: 'Type',
                },
            },
            'Cloud HSM Backup': {
                HsmType: {
                    label: 'HSM Type',
                },
                VpcId: {
                    label: 'VPC Id',
                },
            },
            'Cloud HSM Cluster': {
                HsmType: {
                    label: 'HSM Type',
                },
                VpcId: {
                    label: 'VPC Id',
                },
            },
            CloudFront: {
                DomainName: {},
                'DistributionConfig.HttpVersion.Value': {
                    label: 'Http Version',
                },
                LastModifiedTime: {
                    label: 'Last Modified',
                    format: (item) => fmtSvc.toLocal(item.valueAsStr('')!),
                },
            },
            'CloudFront Streaming Distribution': {
                DomainName: {},
                LastModifiedTime: {
                    label: 'Last Modified',
                    format: (item) => fmtSvc.toLocal(item.valueAsStr('')!),
                },
                Status: {},
            },
            'CloudTrail Trail': {
                HasCustomEventSelectors: {},
                HasInsightSelectors: {},
                IncludeGlobalServiceEvents: {},
                IsOrganizationTrail: {},
                IsMultiRegionTrail: {
                    label: 'Is MultiRegion Trail',
                },
                LogFileValidationEnabled: {},
                S3BucketName: {
                    label: 'S3 Bucket Name',
                },
            },
            'CloudWatch Log Group': {
                MetricFilterCount: {},
                StoredBytes: {},
            },
            'CloudWatch Metric Alarm': {
                AlarmName: {
                    label: 'Alarm Name',
                },
            },
            'CloudWatch Metric Stream': {
                Arn: {},
                'ResponseMetadata.ChecksumAlgorithm': {
                    label: 'Check sum Algorithm',
                },
                'ResponseMetadata.ChecksumValidationStatus': {
                    label: 'Check sum Validation Status',
                },
                ContentLength: {},
                FirehoseArn: {},
                HttpStatusCode: {},
                LastUpdateDate: {
                    label: 'Last Updated',
                },
                'OutputFormat.Value': {
                    label: 'Output Format',
                },
                State: {},
            },
            'Code Star': {
                LastModified: {},
                ProjectId: {},
            },
            'CodeBuild Project': {
                LastModified: {},
                QueuedTimeoutInMinutes: {},
                TimeoutInMinutes: {},
            },
            'CodeBuild Report Group': {
                'ExportConfig.ExportConfigType.Value': {
                    label: 'Export Config',
                },
                LastModified: {},
                'Status.Value': {
                    label: 'Status',
                },
                'Type.Value': {
                    label: 'Type',
                },
            },
            CodePipeline: {
                Updated: {
                    label: 'Updated Date',
                    format: (item) => fmtSvc.toLocal(item.valueAsStr('')!),
                },
                Version: {},
            },
            'Cognito Identity': {
                'CognitoIdentityProviders.ClientId': {},
                'CognitoIdentityProviders.ProviderName': {},
            },
            'Cognito User Pool': {
                EstimatedNumberOfUsers: {
                    label: 'Estimated Number of Users',
                },
                'MfaConfiguration.Value': {
                    label: 'MFA Configuration',
                },
            },
            Comprehend: {
                DataLakeS3Uri: {
                    label: 'Data Lake S3 Uri',
                },
                'ModelType.Value': {
                    label: 'Model Type',
                },
                'Status.Value': {
                    label: 'Status',
                },
            },
            'DataSync Location': {
                LocationUri: {},
            },
            'DataSync Task': {
                'Status.Value': {
                    label: 'Status',
                },
            },
            'Database Migration Service Endpoint': {
                EngineName: {},
            },
            'Database Migration Service Event Subscription': {
                SnsTopicArn: {},
                SourceType: {},
                Status: {},
            },
            'Database Migration Service Replication Instance': {
                AllocatedStorage: {},
                EngineName: {},
                EngineVersion: {},
                ReplicationInstanceClass: {},
                'ReplicationSubnetGroup.VpcId': {},
            },
            'Database Migration Service Replication Task': {
                'MigrationType.Value': {
                    label: 'Migration Type',
                },
                Status: {},
                StopReason: {},
            },
            'Direct Connect': {
                Bandwidth: {},
                'ConnectionState.Value': {
                    label: 'Connection State',
                },
                ProviderName: {},
            },
            'Directory Service': {
                AccessUrl: {},
                Alias: {},
                'Type.Value': {
                    label: 'Type',
                },
            },
            'DocumentDB Cluster': {
                EngineVersion: {},
                Status: {},
            },
            'DocumentDB Instance': {
                DBInstanceClass: {},
                Engine: {},
                EngineVersion: {},
                StorageType: {},
            },
            'Dynamo DB': {
                'BillingModeSummary.BillingMode.Value': {
                    label: 'Billing Mode',
                },
                CreationDateTime: {
                    label: 'Create Date',
                },
                ItemCount: {},
                'ProvisionedThroughput.LastDecreaseDateTime': {
                    label: 'Provisioned Throughput Last Decrease',
                },
                'ProvisionedThroughput.LastIncreaseDateTime': {
                    label: 'Provisioned Throughput Last Increase',
                },
                'ProvisionedThroughput.NumberOfDecreasesToday': {
                    label: 'Provisioned Throughput Number Of Decreases Today',
                },
                'ProvisionedThroughput.ReadCapacityUnits': {
                    label: 'Provisioned Throughput Read Capacity Units',
                },
                'ProvisionedThroughput.WriteCapacityUnits': {
                    label: 'Provisioned Throughput Write Capacity Units',
                },
                'TableStatus.Value': {
                    label: 'Status',
                },
                TableSizeBytes: {
                    label: 'Table Size',
                    format: (item) => fmtSvc.formatBytes(item.valueAsNum(0), 1),
                },
            },
            EBS: {
                AvailabilityZone: {},
                'Attachments.DeleteOnTermination': { label: 'Delete on Termination' },
                'Attachments.Device': { label: 'Device' },
                Encrypted: {},
                'Attachments.InstanceId': {},
                Iops: { label: 'IOPS' },
                Size: { format: (item) => `${fmtSvc.formatDecimal(item.valueAsNum(0), 1)} GB` },
                'State.Value': { label: 'State' },
                VolumeId: {},
                'VolumeType.Value': { label: 'Volume Type' },
            },
            'EC2 Flow Log': {
                DeliverLogsPermissionArn: {},
                DeliverLogsStatus: {},
                FlowLogId: {},
                FlowLogStatus: {},
                'LogDestinationType.Value': {
                    label: 'Log Destination Type',
                },
                MaxAggregationInterval: {},
                'TrafficType.Value': {
                    label: 'Traffic Type',
                },
            },
            'EC2 Image': {
                'Architecture.Value': { label: 'Architecture' },
                'BlockDeviceMappings.DeviceName': {},
                'Hypervisor.Value': { label: 'Hypervisor' },
                'ImageType.Value': { label: 'Image Type' },
                'BlockDeviceMappings.Ebs.Iops': { label: 'IOPS' },
                PlatformDetails: { label: 'Platform' },
                'State.Value': { label: 'State' },
                UsageOperation: {},
                'BlockDeviceMappings.Ebs.VolumeSize': {
                    format: (item) => `${fmtSvc.formatDecimal(item.valueAsNum(0), 1)} GB`,
                },
                'BlockDeviceMappings.Ebs.VolumeType.Value': { label: 'Volume Type' },
            },
            'EC2 On Demand': {
                ARN: {
                    value: (resource) => `arn:aws:ec2:${resource.Region}:${resource.Account}:instance/${resource.InstanceId}`,
                },
                'InstanceType.Value': {
                    label: 'Instance Type',
                    valueRenderer: (item) => {
                        const ec2 = item.root as Ec2;
                        return <RightSizingFieldValue resource={ec2} endpoint="rightsizing-ec2" presentSize={item.valueAsStr('')} />;
                    },
                },
                'RunningState.Value': {
                    label: 'Running State',
                    valueRenderer: (item) => {
                        const ec2 = item.root as Ec2 & { RunningState?: { Value: string } };
                        return ec2.RunningState?.Value === 'stopped' ? (
                            item.valueAsStr('')
                        ) : (
                            <IdleResourcesFieldValue endpoint="idle-ec2" resource={ec2} presentState={item.valueAsStr('')} />
                        );
                    },
                },
                InstanceId: {},
                'Placement.AvailabilityZone': {},
                'Architecture.Value': {
                    label: 'Architecture',
                },
                'CreditSpecifications.CpuCredits': {
                    label: 'CPU Credits',
                },
                'Metrics.RightSizing.CpuSamples': {
                    label: 'CPU Samples',
                    format: (item) => `${fmtSvc.formatPercent(item.valueAsNum(0) / (30 * 24))} active last 30 days`,
                },
                EnaSupport: {
                    label: 'ENA Support',
                },
                'Placement.Tenancy.Value': {
                    label: 'Hosting Type',
                },
                'Hypervisor.Value': {
                    label: 'Hypervisor',
                },
                ImageId: {},
                SubnetId: {},
                DisableApiTermination: {},
                'VirtualizationType.Value': {
                    label: 'Virtualization Type',
                },
                VpcId: {
                    label: 'VPC ID',
                },
            },
            'EC2 Reserved': {
                AvailabilityZone: {},
                Duration: {
                    format: (item) => {
                        const seconds = item.valueAsNum(0);
                        const years = Math.floor(seconds / 60 / 60 / 24 / 365);
                        const plural = years > 1 ? 's' : '';
                        return `${years} Year${plural}`;
                    },
                },
                End: {},
                FixedPrice: {
                    format: (item) => fmtSvc.formatMoneyNonZeroTwoDecimals(item.valueAsNum(0)),
                },
                InstanceCount: {},
                'InstanceTenancy.Value': { label: 'Instance Tenancy' },
                'InstanceType.Value': { label: 'Instance Type' },
                'OfferingClass.Value': { label: 'Offering Class' },
                'OfferingType.Value': { label: 'Offering Type' },
                'ProductDescription.Value': { label: 'Product Description' },
                'Scope.Value': { label: 'Scope' },
                Start: {},
                'State.Value': { label: 'State' },
                UsagePrice: {
                    format: (item) => fmtSvc.formatMoneyNonZeroTwoDecimals(item.valueAsNum(0)),
                },
            },
            'EC2 Snapshot': {
                Encrypted: {},
                Progress: {},
                'State.Value': {},
                'StorageTier.Value': {},
                VolumeSize: {
                    format: (item) => `${fmtSvc.formatDecimal(item.valueAsNum(0), 1)} GB`,
                },
            },
            'EC2 Spot': {
                CreateTime: {
                    label: 'Create Date',
                },
                'InstanceInterruptionBehavior.Value': {
                    label: 'Instance Interruption Behavior',
                },
                SpotPrice: {
                    label: 'Price',
                },
                'ProductDescription.Value': {
                    label: 'Product Description',
                },
                'Status.Code': {
                    label: 'Status',
                },
                ValidUntil: {},
            },
            ECR: {
                RepositoryUri: {},
                RegistryId: {},
            },
            'ECS Service': {
                DesiredCount: {},
                HealthCheckGracePeriodSeconds: {},
                EnableECSManagedTags: {
                    label: 'Enable ECS Managed Tags',
                },
                EnableExecuteCommand: {
                    label: 'Enable Execute Command',
                },
                'LaunchType.Value': {
                    label: 'Launch Type',
                },
                PlatformFamily: {},
                PlatformVersion: {},
                'PropagateTags.Value': {
                    label: 'Propagate Tags',
                },
                RunningCount: {},
                'SchedulingStrategy.Value': {
                    label: 'Scheduling Strategy',
                },
                Status: {},
            },
            'ECS Task': {
                ClusterArn: {},
                EnableExecuteCommand: {},
                'LaunchType.Value': {
                    label: 'Launch Type',
                },
                PlatformFamily: {},
                PlatformVersion: {},
                LastStatus: {
                    label: 'Status',
                },
            },
            EKS: {
                Arn: {
                    label: 'ARN',
                },
            },
            ELBV1: {
                Scheme: {},
                CanonicalHostedZoneNameID: {},
                VPCId: { label: 'VPC ID' },
            },
            ELBV2: {
                Scheme: {},
                CanonicalHostedZoneNameID: {},
                VPCId: { label: 'VPC ID' },
            },
            EMR: {
                AutoTerminate: {
                    label: 'Auto-Terminate',
                },
                EbsRootVolumeSize: { label: 'EBS Root Volume Size' },
                EmrId: {
                    label: 'EMR Id',
                },
                NormalizedInstanceHours: {},
                'Status.Timeline.ReadyDateTime': {
                    label: 'Ready Date',
                },
                'Status.State.Value': {
                    label: 'Status',
                    valueRenderer: (item) => {
                        const emr = item.root as Emr;
                        return <IdleResourcesFieldValue resource={emr} presentState={item.valueAsStr('')} endpoint="idle-emr" />;
                    },
                },
            },
            'ElastiCache Cluster': {
                CacheClusterStatus: {},
                CacheNodeType: {},
                'CacheParameterGroup.CacheParameterGroupName': {},
                Engine: {},
                EngineVersion: {},
                'IpDiscovery.Value': {
                    label: 'Ip Discovery',
                },
                NumCacheNodes: {
                    label: 'Number Cache Nodes',
                },
                'CacheParameterGroup.ParameterApplyStatus': {},
                PreferredMaintenanceWindow: {},
            },
            'ElastiCache Replication Group': {
                AtRestEncryptionEnabled: {},
                AuthTokenEnabled: {},
                AutoMinorVersionUpgrade: {},
                'AutomaticFailover.Value': {
                    label: 'Automatic Failover',
                },
                CacheNodeType: {},
                ClusterEnabled: {},
                CoverageScore: {},
                'MultiAZ.Value': {
                    label: 'Multi-AZ',
                },
                'NetworkType.Value': {
                    label: 'Network Type',
                },
                ReplicationGroupId: {},
                SnapshotRetentionLimit: {},
                SnapshotWindow: {},
                Status: {},
                TransitEncryptionEnabled: {},
            },
            'Elastic Beanstalk Application': {
                DateUpdated: {
                    label: 'Updated',
                },
            },
            'Elastic Beanstalk Environment': {
                SolutionName: {},
                Status: {},
                DateUpdated: {
                    label: 'Updated',
                },
                VersionLabel: {
                    label: 'Version',
                },
            },
            'Elastic Disaster Recovery Instance': {
                'Ec2InstanceState.Value': {
                    label: 'Ec2 Instance State',
                },
                OriginAvailabilityZone: {},
                PointInTimeSnapshotDateTime: {
                    label: 'Point In Time Snapshot DateTime',
                },
                RecoveryInstanceID: {},
                SourceServerID: {},
            },
            'Elastic Disaster Recovery Source Network': {
                CfnStackName: {},
                ReplicationStatus: {},
                SourceAccountId: {},
                SourceNetworkId: {},
                SourceRegion: {},
                SourceVpcId: {},
            },
            'Elastic Disaster Recovery Source Server': {
                'SourceProperties.LastUpdatedDateTime': {
                    label: 'Last Updated DateTime',
                },
                'SourceProperties.Os.FullString': {
                    label: 'OS',
                },
                'SourceProperties.RamBytes': {},
                'SourceProperties.RecommendedInstanceType': {},
                RecoveryInstanceId: {},
            },
            'Elastic File System': {
                Encrypted: {},
                'LifeCycleState.Value': {
                    label: 'Life Cycle State',
                },
                NumberOfMountTargets: {},
                'PerformanceMode.Value': {
                    label: 'Performance Mode',
                },
                ProvisionedThroughputInMibps: {},
                'ThroughputMode.Value': {
                    label: 'Throughput Mode',
                },
            },
            'Elastic File System Access Point': {
                FileSystemId: {},
                'LifeCycleState.Value': {
                    label: 'Life Cycle State',
                },
                'RootDirectory.Path': {
                    label: 'Root Directory',
                },
            },
            'Elastic IP Address': {
                NetworkInterfaceId: {},
                PrivateIpAddress: {},
            },
            ElasticSearch: {
                'InstanceState.ElasticsearchClusterConfig.InstanceCount': {},
                'InstanceState.ElasticsearchClusterConfig.WarmEnabled': {},
                'InstanceState.ElasticsearchClusterConfig.WarmCount': {},
                'InstanceState.ElasticsearchClusterConfig.DedicatedMasterEnabled': {},
                'InstanceState.ElasticsearchClusterConfig.ZoneAwarenessConfig.AvailabilityZoneCount': {},
                'InstanceState.ElasticsearchClusterConfig.ColdStorageOptions.Enabled': {},
                'InstanceState.ElasticsearchClusterConfig.DedicatedMasterCount': {},
                'InstanceState.ElasticsearchClusterConfig.InstanceType.Value': { label: 'Instance Type' },
                'InstanceState.ElasticsearchVersion': {},
                'InstanceState.AutoTuneOptions.State.Value': { label: 'Auto Tune State' },
                'InstanceState.SnapshotOptions.AutomatedSnapshotStartHour': { label: 'Automated Snapshot Start Hour' },
                'InstanceState.DomainEndpointOptions.EnforceHTTPS': {},
                'InstanceState.DomainId': { label: 'Domain Id' },
                'InstanceState.EBSOptions.EBSEnabled': {},
                'InstanceState.EBSOptions.VolumeType.Value': { label: 'Volume Type' },
                'InstanceState.EBSOptions.Iops': { label: 'IOPS' },
                'InstanceState.EBSOptions.VolumeSize': { format: (item) => `${fmtSvc.formatDecimal(item.valueAsNum(0), 1)} GB` },
            },
            'Elemental MediaPackage Channel Group': {
                EgressDomain: {},
            },
            'Elemental MediaPackage Channel Origin Endpoint': {
                ChannelId: {},
                'StreamSelection.ManifestName': {},
            },
            'Elemental MediaPackage Channel Origin Endpoint V2': {
                ChannelName: {},
                ChannelGroupName: {},
            },
            'Elemental MediaPackage Channel V2': {
                ChannelGroupName: {},
            },
            'Elemental MediaPackage Packaging Configuration': {
                PackagingGroupId: {},
                'CmafPackage.SegmentDurationSeconds': {},
            },
            'Elemental MediaPackage Packaging Group': {
                DomainName: {},
            },
            'FSx Cache': {
                FileSystemId: {},
            },
            'FSx File System': {
                DNSName: {},
                'FileSystemType.Value': {
                    label: 'File System Type',
                },
                'Lifecycle.Value': {
                    label: 'Lifecycle',
                },
                OwnerId: {},
                StorageCapacity: {},
                'StorageType.Value': {
                    label: 'Storage Type Id',
                },
                VpcId: {},
            },
            'FSx Volume': {
                'VolumeType.Value': {
                    label: 'Volume Type',
                },
                FileSystemId: {},
            },
            'Glue Crawler': {
                'State.Value': {
                    label: 'State',
                },
                DatabaseName: {},
            },
            'Glue Job': {
                'Command.PythonVersion': {
                    label: 'Command',
                },
                'Command.Name': {
                    label: 'Command Name',
                },
                'ExecutionClass.Value': {
                    label: 'Execution Class',
                },
                GlueVersion: {},
                LastModifiedOn: {
                    label: 'Last Modified',
                },
                MaxCapacity: {},
                'ExecutionProperty.MaxConcurrentRuns': {},
                MaxRetries: {},
                'Command.ScriptLocation': {},
                Timeout: {},
            },
            'Health Imaging': {
                DatastoreName: {},
                'DatastoreStatus.Value': {
                    label: 'Status',
                },
            },
            KMS: {
                'KeySpec.Value': {
                    label: 'Key Spec',
                },
                'KeyUsage.Value': {
                    label: 'Key Usage',
                },
            },
            'Kendra Data Source': {
                'Type.Value': {
                    label: 'Type',
                },
                'Status.Value': {
                    label: 'Status',
                },
            },
            'Kendra Index': {
                'IndexStatistics.TextDocumentStatistics.IndexedTextDocumentsCount': {
                    label: 'Document Count',
                },
                'IndexStatistics.FaqStatistics.IndexedQuestionAnswersCount': {
                    label: 'Faq Count',
                },
                'CapacityUnits.QueryCapacityUnits': {},
                'CapacityUnits.StorageCapacityUnits': {},
                'UserContextPolicy.Value': {
                    label: 'User Context Policy',
                },
            },
            Keyspaces: {},
            'Keyspaces Table': {
                'Status.Value': {
                    label: 'Status',
                },
            },
            Kinesis: {
                RetentionPeriodHours: {},
                'StreamStatus.Value': { label: 'Status' },
                'StreamModeDetails.StreamMode.Value': { label: 'Stream Mode' },
            },
            'Kinesis Data Analytics': {
                'ApplicationStatus.Value': {
                    label: 'Status',
                },
            },
            'Kinesis Data Firehose': {},
            'Kinesis Video Stream': {
                DeviceName: {},
                MediaType: {},
                Version: {},
            },
            'Lake Formation': {},
            Lambda: {
                CodeSize: {},
                'EphemeralStorage.Size': {
                    label: 'Ephemeral Storage Size',
                    format: (item) => `${item.valueAsNum(0)} GB`,
                },
                LastModified: { label: 'Last Modified Date' },
                MemorySize: {},
                'PackageType.Value': { label: 'Package Type' },
                'Runtime.Value': { label: 'Runtime' },
                Timeout: {},
            },
            'MQ Broker': {
                'DeploymentMode.Value': {
                    label: 'Deployment Mode',
                },
                'EngineType.Value': {
                    label: 'Engine Type',
                },
                EngineVersion: {},
                HostInstanceType: {},
                'BrokerState.Value': {
                    label: 'State',
                },
            },
            'MQ Configuration': {
                'EngineType.Value': {
                    label: 'Engine Type',
                },
                EngineVersion: {},
            },
            MSK: {
                'ClusterType.Value': {
                    label: 'Cluster Type',
                },
                CurrentVersion: {},
            },
            'MSK VPC Connection': {
                TargetClusterArn: {},
                VpcId: {},
                Authentication: {},
            },
            'Mainframe Modernization': {
                ApplicationVersion: {},
                DeploymentStatus: {},
                Description: {},
                LastStartTime: {
                    format: (item) => fmtSvc.toLocal(item.valueAsStr('')!),
                },
                RoleArn: {},
            },
            'Mainframe Modernization Environment': {
                EngineVersion: {},
            },
            'Nat Gateway': {
                'NatGatewayAddresses.AllocationId': {},
                'NatGatewayAddresses.NetworkInterfaceId': {},
                'NatGatewayAddresses.PrivateIp': {},
                'NatGatewayAddresses.PublicIp': {},
                'State.Value': {
                    label: 'State',
                },
                SubnetId: {},
                VpcId: {},
            },
            'Neptune Cluster': {
                AllocatedStorage: {},
                Endpoint: {},
                EngineVersion: {},
                Status: {},
            },
            'Neptune Instance': {
                DBInstanceClass: {},
                Engine: {},
                EngineVersion: {},
                StorageType: {},
            },
            'Network Interface': {
                AvailabilityZone: {},
                Description: {},
                'InterfaceType.Value': {
                    label: 'Interface Type',
                },
                PrivateDnsName: {},
                PrivateIpAddress: {},
                'Status.Value': {
                    label: 'Status',
                },
                VpcId: {},
            },
            OpenSearch: {
                ARN: {},
                DomainId: {
                    label: 'Domain ID',
                },
                'ClusterConfig.DedicatedMasterCount': {},
                'ClusterConfig.DedicatedMasterEnabled': {
                    label: 'Dedicated Master Enabled',
                },
                'ClusterConfig.InstanceCount': {},
                'ClusterConfig.InstanceType.Value': {
                    label: 'Instance Type',
                },
                'ClusterConfig.WarmCount': {},
                'ClusterConfig.WarmEnabled': {
                    label: 'Warm Enabled',
                },
                'ClusterConfig.ZoneAwarenessConfig.AvailabilityZoneCount': {},
                'ClusterConfig.ZoneAwarenessEnabled': {},
                'EBSOptions.EBSEnabled': {
                    label: 'EBS Enabled',
                },
                'EBSOptions.Iops': {},
                'EBSOptions.VolumeSize': {},
                'EBSOptions.VolumeType.Value': {
                    label: 'Volume Type',
                },
            },
            'Organization Account': {
                'JoinedMethod.Value': {
                    label: 'Joined Method',
                },
                JoinedTimestamp: {
                    format: (item) => fmtSvc.toLocal(item.valueAsStr('')!),
                },
            },
            'Organization Policy': {
                'Type.Value': {
                    label: 'Policy Type',
                },
                AwsManaged: {
                    label: 'Aws Managed',
                },
            },
            'Quicksight Analysis': {
                AnalysisId: {},
                Arn: {},
            },
            'Quicksight Dashboard': {
                DashboardId: {},
                'Version.VersionNumber': {},
            },
            'Quicksight Data Source': {
                DataSourceId: {},
                'Type.Value': {
                    label: 'Type',
                },
            },
            'Quicksight Dataset': {
                DataSetId: {
                    label: 'Dataset Id',
                },
                'ImportMode.Value': {
                    label: 'Import Mode',
                },
            },
            'Quicksight Template': {
                TemplateId: {},
                'Version.VersionNumber': {},
            },
            'Quicksight Theme': {
                ThemeId: {},
                'Type.Value': {
                    label: 'Type',
                },
                'Version.VersionNumber': {},
            },
            'Quicksight User': {
                Email: {},
                'IdentityType.Value': {
                    label: 'Identity Type',
                },
            },
            'Quicksight VPC Connection': {
                VPCConnectionId: {
                    label: 'VPCConnection Id',
                },
                'AvailabilityStatus.Value': {
                    label: 'Availability Status',
                },
                VPCId: {},
            },
            RDS: {
                Engion: {},
                'InstanceTypeAttributes.InstanceType': {
                    valueRenderer: (item) => {
                        const rds = item.root as Rds;
                        return <RightSizingFieldValue resource={rds} presentSize={item.valueAsStr('')} endpoint="rightsizing-rds" />;
                    },
                },
                DBInstanceStatus: {
                    label: 'Status',
                    valueRenderer: (item) => {
                        const rds = item.root as Rds & { ActivityStreamStatus?: { Value: string } };
                        return rds.ActivityStreamStatus?.Value === 'stopped' ? (
                            item.valueAsStr('')
                        ) : (
                            <IdleResourcesFieldValue resource={rds} endpoint="idle-rds" presentState={item.valueAsStr('')} />
                        );
                    },
                },
                AllocatedStorage: {
                    format: (item) => `${fmtSvc.formatDecimal(item.valueAsNum(0), 1)} GB`,
                },
                AvailabilityZone: {},
                DeletionProtection: {},
                EngineVersion: {},
                Iops: { label: 'IOPS' },
                LicenseModel: {},
                MultiAZ: { label: 'Multi-AZ' },
                StorageEncrypted: {},
                StorageType: {},
                TagList: { hidden: true },
            },
            'RDS Cluster': {
                'ActivityStreamStatus.Value': {
                    label: 'Activity Stream Status',
                },
                IsGlobalCluster: {},
                BackupRetentionPeriod: {},
                CrossAccountClone: {},
                DatabaseName: {},
                DBSubnetGroup: {},
                DeletionProtection: {},
                EarliestRestorableTime: {},
                Engine: {},
                EngineMode: {},
                EngineVersion: {},
                GlobalWriteForwardingRequested: {},
                HostedZoneId: {},
                LatestRestorableTime: {},
                PerformanceInsightsEnabled: {},
                Port: {},
                PreferredBackupWindow: {},
                PreferredMaintenanceWindow: {},
                PubliclyAccessible: {},
                Status: {},
                StorageEncrypted: {},
                TagList: { hidden: true },
            },
            'RDS Cluster Snapshot': {
                DBClusterSnapshotIdentifier: {},
                Engine: {},
                EngineMode: {},
                EngineVersion: {},
                IAMDatabaseAuthenticationEnabled: {
                    label: 'IAM Database Authentication Enabled',
                },
                LicenseModel: {},
                PercentProgress: {},
                SnapshotCreateTime: {},
                StorageEncrypted: {},
                Status: {},
                VpcId: { label: 'VPC ID' },
            },
            'RDS Reserved': {
                DBInstanceClass: {},
                DBInstanceCount: {},
                Duration: {
                    format: (item) => {
                        const seconds = item.valueAsNum(0);
                        const years = Math.floor(seconds / 60 / 60 / 24 / 365);
                        const plural = years > 1 ? 's' : '';
                        return `${years} Year${plural}`;
                    },
                },
                FixedPrice: { format: (item) => fmtSvc.formatMoneyNonZeroTwoDecimals(item.valueAsNum(0)) },
                MultiAZ: { label: 'Multi-AZ' },
                OfferingType: {},
                ProductDescription: {},
                ReservedDBInstanceArn: {},
                ReservedDBInstanceId: {},
                StartTime: {},
                State: {},
                UsagePrice: {
                    format: (item) => fmtSvc.formatMoneyNonZeroTwoDecimals(item.valueAsNum(0)),
                },
            },
            'RDS Snapshot': {
                AllocatedStorage: {},
                AvailabilityZone: {},
                DBInstanceIdentifier: {},
                DBSnapshotIdentifier: {},
                Encrypted: {},
                Engine: {},
                EngineVersion: {},
                LicenseModel: {},
                OriginalSnapshotCreateTime: {},
                PercentProgress: {},
                SnapshotCreateTime: {},
                SnapshotTarget: {},
                SnapshotType: {},
                Status: {},
                StorageType: {},
                VpcId: {},
            },
            Redshift: {
                AllowVersionUpgrade: {},
                AutomatedSnapshotRetentionPeriod: {},
                AvailabilityZone: {},
                AvailabilityZoneRelocationStatus: {},
                ClusterAvailabilityStatus: {},
                ClusterIdentifier: {},
                ClusterRevisionNumber: {},
                ClusterStatus: {
                    valueRenderer: (item) => {
                        const redshift = item.root as Redshift;
                        return redshift.ClusterStatus === 'paused' ? (
                            'paused'
                        ) : (
                            <IdleResourcesFieldValue endpoint="idle-redshift" resource={redshift} presentState={item.valueAsStr('')} />
                        );
                    },
                },
                ClusterVersion: {},
                DBName: {},
                Encrypted: {},
                EnhancedVpcRouting: {
                    label: 'Enhanced VPC Routing',
                },
                ExpectedNextSnapshotScheduleTime: {},
                MaintenanceTrackName: {},
                ManualSnapshotRetentionPeriod: {},
                MasterUsername: {},
                NextMaintenanceWindowStartTime: {},
                NodeType: {},
                NumberOfNodes: {
                    label: 'Number of Nodes',
                },
                PreferredMaintenanceWindow: {},
                PubliclyAccessible: {},
                VpcId: {
                    label: 'VPC ID',
                },
            },
            'Route 53': {},
            'Route 53 Health Check': {
                HealthCheckVersion: {},
                ZoneId: {},
                CallerReference: {},
                'HealthCheckConfig.FullyQualifiedDomainName': {},
                'HealthCheckConfig.RequestInterval': {},
                'HealthCheckConfig.FailureThreshold': {},
                'HealthCheckConfig.HealthThreshold': {},
            },
            'S3 Glacier': {
                NumberOfArchives: {
                    label: 'Number of archives',
                },
            },
            Sagemaker: {
                LastModifiedTime: {
                    label: 'Modified Date',
                },
                'Status.Value': {
                    label: 'Status',
                },
            },
            'Sagemaker App': {
                'AppType.Value': {
                    label: 'App Type',
                },
                DomainId: {},
                'Status.Value': {
                    label: 'Status',
                },
                UserProfileName: {},
            },
            'Sagemaker Notebook Instance': {
                'ResponseMetadata.ChecksumAlgorithm': {
                    label: 'Check sum Algorithm',
                },
                'ResponseMetadata.ChecksumValidationStatus': {
                    label: 'Check sum Validation Status',
                },
                'DirectInternetAccess.Value': {
                    label: 'Direct Internet Access',
                },
                HttpStatusCode: {},
                LastModifiedTime: {
                    label: 'Last Modified',
                },
                'NotebookInstanceStatus.Value': {
                    label: 'Notebook Instance Status',
                },
                PlatformIdentifier: {},
                'RootAccess.Value': {
                    label: 'Root Access',
                },
                'InstanceMetadataServiceConfiguration.MinimumInstanceMetadataServiceVersion': {
                    label: 'Svc Configuration',
                },
                VolumeSizeInGB: {},
            },
            'Savings Plan': {
                SavingsPlanArn: {
                    label: 'ARN',
                },
                Description: {},
                End: {
                    label: 'End Date',
                },
                Start: {
                    label: 'Start Date',
                },
                'State.Value': {
                    label: 'State',
                },
                TermDurationInSeconds: {},
                'SavingsPlanType.Value': {
                    label: 'Type',
                },
                UpfrontPaymentAmount: {
                    format: (item) => fmtSvc.formatMoneyNonZeroTwoDecimals(item.valueAsNum(0)),
                },
            },
            'Secret Manager': {
                Description: {},
                LastAccessedDate: {
                    label: 'Last Access Date',
                },
                PrimaryRegion: {},
                'ResourceRecordSets.RotationEnabled': {},
            },
            'Step Functions Activity': {
                ContentLength: {},
            },
            'Step Functions State Machine': {
                ContentLength: {},
                'Status.Value': {
                    label: 'Status',
                },
                'Type.Value': {
                    label: 'Type',
                },
            },
            'Storage Gateway': {
                Ec2InstanceId: {
                    label: 'EC2 Instance Id',
                },
                GatewayARN: {},
                GatewayId: {},
                GatewayOperationalState: {},
                GatewayType: {},
            },
            'Storage Gateway File Share': {
                FileShareId: {},
                GatewayARN: {},
                'FileShareType.Value': {
                    label: 'File Share Type',
                },
            },
            'Storage Gateway Volume': {
                VolumeId: {},
                VolumeType: {},
                GatewayARN: {},
            },
            'Systems Manager': {
                CreatedDate: {
                    label: 'Create Date',
                    format: (item) => fmtSvc.toLocal(item.valueAsStr('')!),
                },
                'DocumentFormat.Value': {
                    label: 'Document Format',
                },
                'DocumentType.Value': {
                    label: 'Document Type',
                },
                TargetType: {},
            },
            'Systems Manager Parameter': {
                DataType: {},
                Version: {},
                'Tier.Value': {
                    label: 'Tier',
                },
                'Type.Value': {
                    label: 'Type',
                },
            },
            'Systems Manager Patch Baseline': {
                'OperatingSystem.Value': {
                    label: 'Operating System',
                },
            },
            'Transfer Family Workflow': {
                Description: {},
            },
            'Transit Gateways': {
                CreatedDate: {
                    label: 'Create Date',
                    format: (item) => fmtSvc.toLocal(item.valueAsStr('')!),
                },
            },
            'Transit Gateways Attachment': {
                CreatedDate: {
                    label: 'Create Date',
                    format: (item) => fmtSvc.toLocal(item.valueAsStr('')!),
                },
            },
            Workspace: {
                BundleId: {},
                'WorkspaceProperties.ComputeTypeName.Value': {
                    label: 'Compute Type Name',
                },
                DirectoryId: {},
                IpAddress: {
                    label: 'IP Address',
                },
                RootVolumeEncryptionEnabled: {},
                RootVolumeSizeGib: {},
                'WorkspaceProperties.RunningMode.Value': {
                    label: 'Running Mode',
                },
                'WorkspaceProperties.RunningModeAutoStopTimeoutInMinutes': {},
                'State.Value': {
                    label: 'State',
                },
                SubnetId: {},
                UserName: {},
                UserVolumeEncryptionEnabled: {},
                WorkspaceId: {},
            },
        }),
        []
    );
    return { awsCommonFields, resourceTypeFields };
}

import { Tabs } from '@mantine/core';
import { PageContent } from '@root/Design/Layout';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useState } from 'react';
import { SettingsPage, SettingsPageHeader } from '../SettingsPage';
import { BillingBody } from './Components/Credits';
import { CreditUsageOverview } from './Components/CreditUsageOverview';
import { CreditUsageSettings } from './Components/CreditUsageSettings';
import { BillingHeader } from './PaymentMethods';

export function CreditUsage() {
    const [activeTab, setActiveTab] = useState<string>('Overview');
    function tabPropertyChange(e: any) {
        setActiveTab(e);
    }

    return (
        <SettingsPage>
            <PageContent>
                <SettingsPageHeader text="Credit Usage" />
                <BillingBody>
                    <Tabs value={activeTab} onTabChange={tabPropertyChange}>
                        <Tabs.List>
                            <Tabs.Tab data-atid="OverviewTab" key="Overview" value="Overview">
                                Overview
                            </Tabs.Tab>
                            <Tabs.Tab data-atid="SettingsTab" key="Settings" value="Settings">
                                Settings
                            </Tabs.Tab>
                        </Tabs.List>
                        <Tabs.Panel value="Overview">
                            <CreditUsageOverview></CreditUsageOverview>
                        </Tabs.Panel>
                        <Tabs.Panel value="Settings">
                            <CreditUsageSettings></CreditUsageSettings>
                        </Tabs.Panel>
                    </Tabs>
                </BillingBody>
            </PageContent>
        </SettingsPage>
    );
}
endpoint('credit-usage', CreditUsage, 'Credit Usage');

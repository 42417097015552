import {
    deleteTagAutomationRemoveTagAutomationRule,
    getMapContractsEnsureRuleGroup,
    getTagAutomationRuleGroupGetRuleGroup,
    postTagAutomationCreateTagAutomationRule,
    postTagAutomationRuleGroupSaveRuleGroup,
    putTagAutomationSaveTagAutomationRule,
} from '@apis/TagManager';
import { MapContract, TagAutomationRule, TagAutomationRuleGroup, TagAutomationRuleParametersType } from '@apis/TagManager/model';
import { EventEmitter } from '@root/Services/EventEmitter';
import { TagLookupService } from '@root/Site/TagManager/TagAutomation/Components/Model';
import { inject, Lifecycle, scoped } from 'tsyringe';

@scoped(Lifecycle.ContainerScoped)
export class MapAutoTagModel {
    public contract?: MapContract;
    public ruleGroup?: TagAutomationRuleGroup;
    public loading = new EventEmitter(true);
    public saving = new EventEmitter(false);
    private originalPriority = '';

    public constructor(@inject(TagLookupService) private readonly tagLookupSvc: TagLookupService) {}

    public async init(contract: MapContract) {
        this.contract = contract;
        this.ruleGroup = undefined;
        this.reload();
    }

    public canSave() {
        return this.serializePriority() !== this.originalPriority;
    }

    public reload = async () => {
        try {
            this.loading.emit(true);
            await this.loadRuleGroup();
        } finally {
            this.loading.emit(false);
        }
    };

    private async loadRuleGroup() {
        let ruleGroupId = this.contract?.RuleGroupId;
        if (!ruleGroupId) {
            const group = await getMapContractsEnsureRuleGroup({ mapContractId: this.contract?.Id });
            ruleGroupId = group.Id;
        }

        this.ruleGroup = await getTagAutomationRuleGroupGetRuleGroup({ groupId: ruleGroupId });
        this.originalPriority = this.serializePriority();
    }

    public addRule = async (rule: TagAutomationRule) => {
        try {
            this.loading.emit(true);

            const status = rule.Status;
            if (rule.Status !== 'Draft') {
                rule.Status = 'Draft';
            }
            const newRule = await postTagAutomationCreateTagAutomationRule(rule);
            this.ruleGroup?.Items?.push({ Rule: newRule, TagAutomationRuleId: newRule.Id });
            await this.savePriority();
            if (status !== 'Draft') {
                newRule.Status = status;
                await putTagAutomationSaveTagAutomationRule(newRule);
            }
            await this.loadRuleGroup();
        } finally {
            this.loading.emit(false);
        }
    };

    public updateRule = async (rule: TagAutomationRule) => {
        try {
            this.loading.emit(true);
            await putTagAutomationSaveTagAutomationRule(rule);
            await this.loadRuleGroup();
        } finally {
            this.loading.emit(false);
        }
    };

    public savePriority = async () => {
        try {
            this.loading.emit(true);
            this.saving.emit(true);
            if (!this.ruleGroup) {
                return;
            }
            const ruleIds = this.ruleGroup.Items?.map((x) => x.TagAutomationRuleId ?? 0) ?? [];
            await postTagAutomationRuleGroupSaveRuleGroup(ruleIds, { groupId: this.ruleGroup.Id });
            await this.loadRuleGroup();
        } finally {
            this.loading.emit(false);
            this.saving.emit(false);
        }
    };

    public deleteRule = async (rule: TagAutomationRule) => {
        try {
            this.loading.emit(true);
            if (!this.ruleGroup) {
                return;
            }
            const ruleIds = this.ruleGroup?.Items?.filter((x) => x.Rule?.Id !== rule.Id).map((x) => x.TagAutomationRuleId ?? 0) ?? [];
            await deleteTagAutomationRemoveTagAutomationRule({ ruleId: rule.Id });
            await postTagAutomationRuleGroupSaveRuleGroup(ruleIds, { groupId: this.ruleGroup?.Id });
            await this.loadRuleGroup();
        } finally {
            this.loading.emit(false);
        }
    };

    private serializePriority() {
        return this.ruleGroup?.Items?.map((x) => x.Rule?.Id ?? 0).join(',') ?? '';
    }
}

import { useMantineTheme, Text, List } from '@mantine/core';

export function ValidationIssues({ title, issues }: { title: string; issues: string[] }) {
    const theme = useMantineTheme();
    return (
        <>
            <Text size="sm" mt="xs">
                {title}
            </Text>
            <List ml="md">
                {issues.map((v) => (
                    <List.Item sx={{ fontSize: '14px', color: theme.colors.gray[6] }} key={v}>
                        {v}
                    </List.Item>
                ))}
            </List>
        </>
    );
}

import { getDashboardGetUsersByDashboardId, getUserGetCompanyUsers } from '@apis/Customers';
import { DashboardConfigShareType, UserListItem } from '@apis/Customers/model';
import styled from '@emotion/styled';
import {
    ActionIcon,
    Anchor,
    Badge,
    Box,
    Button,
    Center,
    Divider,
    Group,
    Menu,
    MultiSelect,
    Select,
    SelectItem,
    Space,
    Tabs,
    Text,
    TextInput,
} from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { closeModal, openModal } from '@mantine/modals';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { useDi, useDiComponent } from '@root/Services/DI';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { forwardRef, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { DotsVertical, Pencil, Share, Trash } from 'tabler-icons-react';
import { DashboardPersistenceService, IDashboardConfigBase } from './DashboardPersistenceService';

type Config = { id: number; layout: IDashboardConfigBase; ownerUserId: number; shareType?: DashboardConfigShareType };

export function DashboardConfigLoadPanel({
    title,
    onSelect,
    configKey,
    updated,
    onConfigChange,
    tabText,
}: {
    title: string;
    prompt: string;
    onSelect: (config: Config) => void;
    onConfigChange?: (config: Config, action: 'rename' | 'delete') => void;
    configKey: string;
    tabText?: { my?: string; shared?: string; default?: 'My Saved Views' | 'Shared Saved Views' };
    updated: EventEmitter<void>;
}) {
    const userId = useDi(AuthenticationService).user?.Id;
    const dashboardSvc = useDi(DashboardPersistenceService);
    const fmtSvc = useDi(FormatService);
    const [configs, setConfigs] = useState<Config[]>([]);
    const loadConfigs = useCallback(() => {
        dashboardSvc.getLayouts(configKey).then((configs) => setConfigs(configs));
    }, []);
    useEffect(loadConfigs, []);
    useEvent(updated, loadConfigs);
    const onChange = useCallback(
        (config: Config, action: 'rename' | 'delete') => {
            loadConfigs();
            onConfigChange?.(config, action);
        },
        [onConfigChange]
    );

    const refresh = useCallback(() => {
        loadConfigs();
    }, [loadConfigs]);

    const onRenameChange = useCallback((config: Config) => onChange(config, 'rename'), [onChange]);
    const onDeleteChange = useCallback((config: Config) => onChange(config, 'delete'), [onChange]);
    const onRemoveChange = useCallback((dashboardId: number, userId: number) => refresh(), [refresh]);
    const onRename = useConfigRename(configKey, onRenameChange);
    const onDelete = useConfigDelete(onDeleteChange);
    const onRemove = useDashboardRemove(onRemoveChange);
    const myTemplates = configs.filter((c) => c.ownerUserId === userId);
    const sharedTemplates = configs.filter((c) => c.shareType !== 'None');
    return (
        <Tabs
            defaultValue={tabText?.default ?? 'My Saved Views'}
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                '> div': { minHeight: 0 },
                '.mantine-Tabs-panel': { height: 'calc(100% - 64px)' },
            }}
            mx="xl"
        >
            <Tabs.List>
                <Tabs.Tab value="My Saved Views" data-atid="MySavedViewsButton">
                    {tabText?.my ?? 'My Saved Views'}
                    <Badge>{fmtSvc.formatInt(myTemplates.length)}</Badge>
                </Tabs.Tab>
                <Tabs.Tab value="Shared Saved Views" data-atid="SharedSavedViewsButton">
                    {tabText?.shared ?? 'Shared Saved Views'}
                    <Badge>{fmtSvc.formatInt(sharedTemplates.length)}</Badge>
                </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="My Saved Views">
                <TemplateContainer>
                    <TemplateList>
                        {myTemplates.map((config, i) => (
                            <Fragment key={config.id}>
                                {i > 0 ? <Divider color="gray.2" /> : null}
                                <ConfigRow
                                    config={config}
                                    onSelect={onSelect}
                                    onDelete={onDelete}
                                    onRename={onRename}
                                    configKey={configKey}
                                    onRemove={onRemove}
                                />
                            </Fragment>
                        ))}
                    </TemplateList>
                </TemplateContainer>
            </Tabs.Panel>
            <Tabs.Panel value="Shared Saved Views">
                <TemplateContainer>
                    <TemplateList>
                        {sharedTemplates.map((config, i) => (
                            <Fragment key={config.id}>
                                {i > 0 ? <Divider color="gray.2" /> : null}
                                <ConfigRow
                                    config={config}
                                    onSelect={onSelect}
                                    onDelete={onDelete}
                                    onRename={onRename}
                                    configKey={configKey}
                                    onRemove={onRemove}
                                />
                            </Fragment>
                        ))}
                    </TemplateList>
                </TemplateContainer>
            </Tabs.Panel>
        </Tabs>
    );
}

const TemplateContainer = styled.div`
    height: 100%;
    padding: 16px;
    background: ${(p) => p.theme.colors.gray[2]};
    border-radius: 0 0 8px 8px;
`;

const TemplateList = styled.div`
    overflow: auto;
    height: 100%;
    border: solid 1px ${(p) => p.theme.colors.gray[4]};
    background: ${(p) => p.theme.white};
    border-radius: 4px;
`;

function useConfigRename(configKey: string, onChange: (config: Config) => void) {
    const DiContainer = useDiComponent();
    return useCallback(
        async (config: Config) => {
            openModal({
                modalId: 'rename-config',
                title: 'Rename',
                children: (
                    <DiContainer>
                        <RenameContent config={config} configKey={configKey} onChange={onChange} />
                    </DiContainer>
                ),
                overlayColor: 'transparent',
            });
        },
        [DiContainer]
    );
}

function RenameContent({ config, configKey, onChange }: { config: Config; configKey: string; onChange: (config: Config) => void }) {
    const [name, setName] = useInputState(config.layout.name);
    const [renaming, setRenaming] = useState(false);
    const close = useCallback(() => closeModal('rename-config'), []);
    const dashboardSvc = useDi(DashboardPersistenceService);
    const rename = useCallback(async () => {
        try {
            setRenaming(true);
            config.layout.name = name;
            await dashboardSvc.save(configKey, config.id, config.layout);
            onChange(config);
            close();
        } finally {
            setRenaming(false);
        }
    }, [config, dashboardSvc, close, name]);

    return (
        <>
            <TextInput label="Enter a new name" value={name} onChange={setName} data-atid="ViewRenameTextInput" />
            <Space h="md" />
            <Group position="right">
                <Button variant="outline" onClick={close} data-atid="ViewRenameCancelButton">
                    Cancel
                </Button>
                <Button variant="filled" disabled={renaming} onClick={rename} data-atid="ViewRenameConfirmButton">
                    Rename
                </Button>
            </Group>
        </>
    );
}

function useConfigDelete(onChange: (config: Config) => void) {
    const DiContainer = useDiComponent();
    return useCallback(
        async (config: Config) => {
            openModal({
                modalId: 'delete-config',
                title: 'Delete',
                children: (
                    <DiContainer>
                        <DeleteContent config={config} onChange={onChange} />
                    </DiContainer>
                ),
                overlayColor: 'transparent',
            });
        },
        [DiContainer]
    );
}

function useDashboardRemove(onChange: (dashboardId: number, userId: number) => void) {
    const DiContainer = useDiComponent();
    return useCallback(
        async (dashboardId: number, userId: number) => {
            openModal({
                modalId: 'remove-config',
                title: 'Delete',
                children: (
                    <DiContainer>
                        <RemoveContent dashboardId={dashboardId} userId={userId} onChange={onChange} />
                    </DiContainer>
                ),
                overlayColor: 'transparent',
            });
        },
        [DiContainer]
    );
}

function RemoveContent({
    dashboardId,
    userId,
    onChange,
}: {
    dashboardId: number;
    userId: number;
    onChange: (dashboardId: number, userId: number) => void;
}) {
    const [deleting, setDeleting] = useState(false);
    const close = useCallback(() => closeModal('delete-config'), []);
    const dashboardSvc = useDi(DashboardPersistenceService);
    const removeAccess = useCallback(async () => {
        try {
            setDeleting(true);
            await dashboardSvc.removeSharedUser(dashboardId, userId);
            onChange(dashboardId, userId);
            close();
        } finally {
            setDeleting(false);
        }
    }, [dashboardId, userId, dashboardSvc, close]);

    return (
        <>
            <Text>Are you sure you want to remove access to this dashboard?</Text>
            <Space h="md" />
            <Group position="right">
                <Button variant="outline" onClick={close}>
                    Cancel
                </Button>
                <Button variant="filled" color="error" disabled={deleting} onClick={removeAccess}>
                    Delete
                </Button>
            </Group>
        </>
    );
}

function DeleteContent({ config, onChange }: { config: Config; onChange: (config: Config) => void }) {
    const [deleting, setDeleting] = useState(false);
    const close = useCallback(() => closeModal('delete-config'), []);
    const dashboardSvc = useDi(DashboardPersistenceService);
    const deleteConfig = useCallback(async () => {
        try {
            setDeleting(true);
            await dashboardSvc.delete(config.id);
            onChange(config);
            close();
        } finally {
            setDeleting(false);
        }
    }, [config, dashboardSvc, close]);

    return (
        <>
            <Text>
                Are you sure you want to <strong>delete {config.layout.name}</strong>?
            </Text>
            <Space h="md" />
            <Group position="right">
                <Button variant="outline" onClick={close} data-atid="ViewDeleteCancel">
                    Cancel
                </Button>
                <Button variant="filled" color="error" disabled={deleting} onClick={deleteConfig} data-atid="ViewDeleteConfirm">
                    Delete
                </Button>
            </Group>
        </>
    );
}

const LoaderRow = styled.div`
    display: grid;
    grid-template-columns: auto 40px;
    align-items: center;
    .on-hover {
        visibility: hidden;
    }
    &:hover {
        background: ${(p) => p.theme.colors.gray[1]};
        .on-hover {
            visibility: visible;
        }
    }
`;

function ConfigRow({
    config,
    onSelect,
    onDelete,
    onRename,
    configKey,
    onRemove,
}: {
    config: Config;
    onSelect: (config: Config) => void;
    onDelete: (config: Config) => void;
    onRename: (config: Config) => void;
    configKey: string;
    onRemove: (dashboardId: number, userId: number) => void;
}) {
    const userId = useDi(AuthenticationService).user?.Id;
    const fmtSvc = useDi(FormatService);
    const onRenderNeeded = useMemo(() => EventEmitter.empty(), []);
    const onShare = useConfigShare(() => onRenderNeeded.emit());
    useEvent(onRenderNeeded);

    return (
        <LoaderRow>
            <Box px="sm" py={4}>
                <Anchor onClick={() => onSelect(config)} data-atid={'ViewNameAnchor:' + config.layout.name}>
                    {config.layout.name || 'Unnamed'}
                </Anchor>
                {config.layout.dateSaved ? (
                    <Text size="xs" color="dimmed">
                        {config.ownerUserId === userId && config.shareType !== 'None' ? 'Shared, ' : ''}Last Modified:{' '}
                        {fmtSvc.timeAgo(config.layout.dateSaved)}
                    </Text>
                ) : null}
            </Box>
            <Center hidden={config.ownerUserId !== userId}>
                <Menu position="bottom-end" shadow="md" width={200}>
                    <Menu.Target>
                        <ActionIcon size="sm" data-atid={'ViewEditButton:' + config.layout.name}>
                            <DotsVertical size={14} />
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item icon={<Pencil size={14} />} onClick={() => onRename(config)} data-atid="RenameViewButton">
                            Rename
                        </Menu.Item>
                        <Menu.Item icon={<Share size={14} />} onClick={() => onShare(config, configKey)} data-atid="ShareViewButton">
                            Share
                        </Menu.Item>
                        <Menu.Item icon={<Trash size={14} />} onClick={() => onDelete(config)} data-atid="DeleteViewButton">
                            Delete
                        </Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </Center>
            <Center hidden={config.ownerUserId === userId}>
                <Menu position="bottom-end" shadow="md" width={200}>
                    <Menu.Target>
                        <ActionIcon size="sm">
                            <DotsVertical size={14} />
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item icon={<Trash size={14} />} onClick={() => onRemove(config.id, userId!)}>
                            Delete
                        </Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </Center>
        </LoaderRow>
    );
}

function useConfigShare(onChange: (config: Config) => void) {
    const DiContainer = useDiComponent();
    return useCallback(
        async (config: Config, configKey: string) => {
            openModal({
                modalId: 'share-config',
                title: 'Share',
                children: (
                    <DiContainer>
                        <ShareContent config={config} onChange={onChange} configKey={configKey} />
                    </DiContainer>
                ),
                overlayColor: 'transparent',
            });
        },
        [DiContainer]
    );
}

function ShareContent({ config, onChange, configKey }: { config: Config; onChange: (config: Config) => void; configKey: string }) {
    const [sharing, setSharing] = useState(false);
    const close = useCallback(() => closeModal('share-config'), []);
    const [shareType, setShareType] = useState(config.shareType);
    const [usersList, setUsersList] = useState<UserListItem[]>([]);
    const dashboardSvc = useDi(DashboardPersistenceService);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const authSvc = useDi(AuthenticationService);
    const currentUser = authSvc.user;
    const loadUsers = () => {
        getUserGetCompanyUsers().then((users) => {
            setUsersList(() => users.filter((u) => u.Id !== currentUser?.Id));
        });
    };

    const getSelectedUsers = async () => {
        let users = await getDashboardGetUsersByDashboardId({ dashboardId: config.id });
        setSelectedUsers(users.map((u) => u));
    };

    useEffect(() => {
        getSelectedUsers();
        loadUsers();
    }, []);

    useEffect(() => {
        if (shareType === 'User' && selectedUsers.length === 0) {
            setSharing(true);
        } else {
            setSharing(false);
        }
    }, [shareType, selectedUsers]);

    async function handleOnSelectUsers(e: string[]) {
        let values = e.map((val) => +val);
        setSelectedUsers(values);
    }

    const shareConfig = useCallback(async () => {
        try {
            setSharing(true);
            config.shareType = shareType;

            await dashboardSvc.share(configKey, config.id, shareType ?? 'None');
            if (shareType === 'User' && selectedUsers.length > 0) {
                await dashboardSvc.shareWithUser(config.id, selectedUsers);
            }
            onChange(config);
            close();
        } finally {
            setSharing(false);
        }
    }, [config, dashboardSvc, close, shareType, selectedUsers]);

    return (
        <>
            <Text>
                How would you like to <strong>share {config.layout.name}</strong>?
            </Text>
            <Select
                itemComponent={ShareTypeItem}
                onChange={(v) => setShareType(v as DashboardConfigShareType)}
                data={[
                    {
                        value: 'None',
                        label: 'Not Shared',
                        description: 'This will not appear as a Shared Template for any other users',
                        // atid: 'NotShared',
                    },
                    {
                        value: 'Company',
                        label: 'Share with all company',
                        description: 'This will appear as a Shared Template for all users in your organization',
                        // atid: 'ShareWithAllCompany',
                    },
                    {
                        value: 'User',
                        label: 'Share with specific users',
                        description: 'This will appear as a Shared Template for users you selected in your organization',
                        // atid: 'ShareWithSpecificUsers',
                    },
                ]}
                data-atid="ViewShareTypeDropdown"
                value={shareType ?? 'None'}
            />
            {shareType == 'User' ? (
                <MultiSelect
                    label="Select Users"
                    searchable
                    data={usersList.map((i) => {
                        return {
                            label: !i.FirstName && !i.LastName ? i.EMail : i.FirstName + ' ' + i.LastName,
                            value: i.Id?.toString(),
                            atid: 'ShareViewUserSelection:' + (!i.FirstName && !i.LastName ? i.EMail : i.FirstName + ' ' + i.LastName),
                        } as SelectItem;
                    })}
                    value={selectedUsers.map((i) => i.toString())}
                    onChange={handleOnSelectUsers}
                    data-atid="ShareViewUserDropdown"
                />
            ) : null}
            <Space h="md" />
            <Group position="right">
                <Button variant="outline" onClick={close} data-atid="ShareViewCancelButton">
                    Cancel
                </Button>
                <Button variant="filled" color="primary" disabled={sharing} onClick={shareConfig} data-atid="ShareViewApplyButton">
                    Apply
                </Button>
            </Group>
        </>
    );
}

type ShareTypeItemProps = { label: string; description: string } & React.ComponentPropsWithRef<'div'>;
const ShareTypeItem = forwardRef<HTMLDivElement, ShareTypeItemProps>(({ label, description, ...others }: ShareTypeItemProps, ref) => (
    <div ref={ref} {...others} data-atid="ViewShareTypeDropdown">
        <Text size="sm" data-atid={'ShareType:' + label}>
            {label}
        </Text>
        <Text size="xs" sx={{ opacity: 0.65 }}>
            {description}
        </Text>
    </div>
));

import { Box, Button, Divider, Group, TextInput } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import {
    WizardForm,
    WizardFormStep,
    WizardFormTitle,
    LoadingValidation,
    WizardFormFooter,
    WizardDownload,
    LoadingState,
    WizardFormStepContainer,
} from '@root/Components/Wizard/WizardForm';
import { PageContent } from '@root/Design/Layout';
import { useDi, useDiContainer } from '@root/Services/DI';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useCallback, useEffect, useMemo } from 'react';
import { ConnectionWizardFooterText, ConnectionWizardStepper, AccountIdValidator, WizardHeader } from './Common';
import { Link } from '@root/Services/Router/Links';
import { useEvent } from '@root/Services/EventEmitter';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { BasicRouteLoader } from '@root/Services/Router/BasicRouteLoader';
import { CompanyType } from '@apis/Customers/model/companyType';
import { IacConnector } from '@root/Components/Connections/IacConnector';

export function ConnectTf() {
    const { platform } = useNav().getData('platform');

    return (
        <PageContent>
            <WizardHeader platform={platform!} />
            <WizardForm>
                <ConnectionWizardStepper step={3} platform={platform!} />
                <WizardFormTitle title={'Connect to AWS Using Terraform'}></WizardFormTitle>
                <WizardFormStepContainer>
                    <ConnectTfContent />
                </WizardFormStepContainer>
            </WizardForm>
        </PageContent>
    );
}
endpoint('connect-tf', ConnectTf, 'Connection Wizard');

export function ConnectTfContent({
    hideFooter,
    onReadyChanged,
}: {
    hideFooter?: boolean;
    onReadyChanged?: (ready: boolean, accountId: string) => void;
}) {
    const di = useDiContainer();
    const iacConn = useMemo(() => di.resolve(IacConnector), []);
    const { getMoveUrl } = useNav();
    const company = useCompany();

    useEvent(iacConn.status);
    const [accountId, setAccountId] = useInputState('');
    useEffect(() => {
        iacConn.updateAccount(accountId, company!.UniqueId!);
    }, [accountId]);

    useEffect(() => {
        return () => {
            iacConn.dispose();
        };
    }, []);

    useEffect(() => {
        onReadyChanged?.(iacConn.isConnected(), accountId);
    }, [iacConn.isConnected()]);

    return (
        <>
            <WizardFormStep
                title="1. Enter your 12-digit Management Account ID"
                infoText="Also known as master account or master payor, we'll need to connect to this account for resource information."
                infoSize="small"
                subTitle="We use this to find and connect CloudSaver to your AWS Management Account."
            >
                <Group spacing="xs" ml="xl">
                    <TextInput value={accountId} onChange={setAccountId} size="sm" type="number" placeholder="012345678910" sx={{ width: 200 }} />
                    <AccountIdValidator condition={accountId.length == 12} />
                </Group>
            </WizardFormStep>
            <Divider />
            <WizardFormStep
                title="2. Deploy the .tf file to your accounts."
                subTitle="Start with the Management Account, then deploy to all other accounts you want to connect to CloudSaver. A Management Account is required."
            >
                <Box ml="xl">
                    <WizardDownload
                        imgLocation="/assets/terraformio-icon.svg"
                        displayTitle="Terraform"
                        displayText="Download .tf"
                        fileLocation={iacConn.getDownloadUrl('cs-terraform.tf')}
                        disable={!iacConn.isAccountValid()}
                        disabledText="Account ID is Required"
                    ></WizardDownload>
                </Box>
            </WizardFormStep>
            <Divider />
            <WizardFormStep
                title="3. CloudSaver will check for connectivity to the Management Account."
                subTitle="Using the Management Account ID you entered, we will attempt to connect every 3 seconds until success."
            >
                <Box ml="xl">
                    <LoadingValidation loadingState={iacConn.getLoadingState() as LoadingState} text={iacConn.getStatusMessage()}></LoadingValidation>
                </Box>
            </WizardFormStep>
            {!hideFooter && (
                <>
                    <Divider />
                    <WizardFormFooter>
                        <Group position="apart">
                            <ConnectionWizardFooterText company={company!} />
                            <Button
                                disabled={!iacConn.isConnected()}
                                component={Link}
                                href={getMoveUrl('connections')}
                                style={{ pointerEvents: !iacConn.isConnected() ? 'none' : undefined }}
                            >
                                View connections
                            </Button>
                        </Group>
                    </WizardFormFooter>
                </>
            )}
        </>
    );
}

import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { container } from 'tsyringe';
import App from './App';
import './index.scss';
import { DiContext } from './Services/DI';
import { QueryClient, QueryClientProvider } from 'react-query';
import { System } from './Services/System';
import { configure } from 'mobx';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const system = container.resolve(System);
system.init();

configure({
    enforceActions: 'never',
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <DiContext.Provider value={container}>
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    </DiContext.Provider>
);

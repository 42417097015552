import { getPaymentGetPendingPaymentMethod, postPaymentUsePendingPaymentMethod, putPaymentCreatePendingPaymentMethod } from '@apis/Customers';
import { injectable } from 'tsyringe';

@injectable()
export class AzurePaymentMethodService {
    private readonly key = 'pending-payment-secret';

    public async createSecret() {
        const secret = await putPaymentCreatePendingPaymentMethod();
        if (!secret.Secret) {
            return null;
        }

        localStorage.setItem(this.key, secret.Secret);
        return secret.Secret;
    }
    public getSecret(secret: string) {
        return getPaymentGetPendingPaymentMethod({ secret });
    }
    public restoreSecret() {
        return localStorage.getItem(this.key) ?? '';
    }
    public useSecret(secret: string) {
        return postPaymentUsePendingPaymentMethod({ secret });
    }
    public clear() {
        localStorage.removeItem(this.key);
    }
}

import styled from '@emotion/styled';
import { Center, Loader, Text } from '@mantine/core';
import { Fragment, ReactNode } from 'react';

export function LoadingFiller({ height }: { height?: number | string }) {
    return (
        <Center sx={{ height: height ?? '100%' }}>
            <Loader />
        </Center>
    );
}

export function NoDataFiller({ height, message }: { height?: number | string; message: string }) {
    return (
        <Center sx={{ height: height ?? '100%' }}>
            <Text color="dimmed">{message}</Text>
        </Center>
    );
}

export function FillerSwitch({
    loading,
    noData,
    children,
    height,
    noDataMessage = 'No Data',
}: {
    loading?: boolean;
    noData?: boolean;
    children: ReactNode | (() => ReactNode);
    height?: number | string;
    noDataMessage?: string;
}) {
    const content = loading ? (
        <LoadingFiller height={height} />
    ) : noData ? (
        <NoDataFiller message={noDataMessage} height={height} />
    ) : typeof children === 'function' ? (
        <>{children()}</>
    ) : (
        <></>
    );
    const Wrap = typeof children === 'function' ? Fragment : FillerSwitchContent;
    return (
        <>
            <Wrap>{content}</Wrap>
            {typeof children !== 'function' ? <>{children}</> : <></>}
        </>
    );
}

const FillerSwitchContent = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`;

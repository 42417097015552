import { postVariantGroupAddVariantsToVariantGroup, postVariantGroupGetAllTagsForAutocomplete } from '@apis/TagManager';
import { Variant } from '@apis/TagManager/model';
import { Autocomplete, Button } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { useEffect, useState } from 'react';
import { VGViewModel } from '../ViewModels/ViewModels';

export function VariantForm(props: { onClose: () => void; model: VGViewModel }) {
    const form = useForm({
        initialValues: {
            variantName: '',
        },
        validate: {
            variantName: (value: string) => (!value ? 'Variant Name cannot be null' : null),
        },
    });

    const companyId = useCompany()?.Id;
    const [tags, setTags] = useState<string[]>([]);
    useEffect(() => {
        getAllTags(props.model.Type, companyId ?? 0).then((r) => {
            setTags(r);
        });
    }, []);

    return (
        <form
            onSubmit={form.onSubmit((values) => {
                PutVariant(values, props.model);
            })}
        >
            <Autocomplete
                label="Autocomplete Variant Name"
                placeholder="Variant string"
                data={tags}
                limit={5}
                {...form.getInputProps('variantName')}
            />
            <br></br>
            <Button type="submit">Add</Button>
            <span>&emsp;</span>
            <Button onClick={props.onClose}>Cancel</Button>
        </form>
    );
}

export async function PutVariant(values: any, item: VGViewModel): Promise<void> {
    const VariantFromForm: Variant = {
        VariantGroupId: item.Id,
        VariantString: values.variantName as string,
        IsPrimary: false,
        Count: 0,
        IsDeleted: false,
    };

    const Vars: Variant[] = new Array();
    Vars.push(VariantFromForm);

    await postVariantGroupAddVariantsToVariantGroup(Vars);
}

async function getAllTags(type: string, companyId: number): Promise<string[]> {
    const tags = await postVariantGroupGetAllTagsForAutocomplete({ type, companyId });
    return tags;
}

import { observer } from 'mobx-react';
import { AddTagsCard } from './AddTagsCard';
import { RuleEditor } from './Model';
import { StandardRuleLayout } from './StandardRuleLayout';

export const AutoTagRuleCard = observer(function AutoTagRuleCard({ ruleEditor }: { ruleEditor: RuleEditor }) {
    return (
        <StandardRuleLayout ruleEditor={ruleEditor}>
            <AddTagsCard ruleEditor={ruleEditor} />
        </StandardRuleLayout>
    );
});

import { Box, Button, Card, Center, Group, Space, Text, Title, useMantineTheme } from '@mantine/core';
import { WizardForm } from '@root/Components/Wizard/WizardForm';
import { ActionPageContent } from '@root/Design/ActionPage';
import { PageContent } from '@root/Design/Layout';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { Link } from '@root/Services/Router/Links';
import { SettingsShell } from './Common';

export function GetConnected() {
    const { getRootUrl } = useNav();
    return (
        <PageContent>
            <ActionPageContent>
                <WizardForm>
                    <Card shadow="sm" radius="md" withBorder p="xl">
                        <Box sx={{ display: 'flex', alignItems: 'center' }} p="sm">
                            <div>
                                <Title order={1}>Connect to CloudSaver</Title>
                                <Space h="md" />
                                <Text color="gray">You don't have any accounts connected yet. Connect CloudSaver to your cloud platform.</Text>
                            </div>
                            <Button component={Link} href={getRootUrl('connections-wizard')}>
                                Get started
                            </Button>
                        </Box>
                    </Card>
                </WizardForm>
            </ActionPageContent>
        </PageContent>
    );
}
endpoint('get-connected', GetConnected, 'Get Connected');

import { Anchor, Grid, Group, LoadingOverlay, Progress } from '@mantine/core';
import { CoverageText, PercentCovered, Header, NoEffectivePolicies, Container } from '../../TagPolicies';
import { PieChart, PieChartSettings } from '@root/Components/Charts/PieChart';
import { DataGrid } from '@root/Components/DataGrid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomColors, theme } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { ColumnConfig } from '@root/Components/DataGrid/Models';
import { useNav } from '@root/Services/NavigationService';
import { BasicRouteLoader } from '@root/Services/Router/BasicRouteLoader';
import { AwsEffectiveTagPolicy, BaseResource, Query } from '@apis/Resources/model';
import { getAccountGetAccountConnectionStatus, getAccountGetCompanyAccounts } from '@apis/Customers';
import { postResourcesMultiQuery, postResourcesQuery, postTagPolicyGetPolicies, QueryExpr, QueryResult } from '@apis/Resources';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { PanelBody } from '@root/Design/Layout';
import { queryBuilder } from '@root/Services/QueryExpr';
import { AsyncBundler } from '@root/Services/AsyncBundler';
import { Account, AccountConnectedStatus } from '@apis/Customers/model';
import { FillerSwitch } from '@root/Design/Filler';

export const AwsTagPolicySection = () => {
    const [numInCompliance, setNumInCompliance] = useState(0);
    const [totalResources, setTotalResources] = useState(0);
    const [totalTagKeys, setTotalTagKeys] = useState(0);
    const [policyTagKeys, setPolicyTagKeys] = useState(0);
    const [numNotInCompliance, setNumNotInCompliance] = useState(0);
    const [effPolicyDataItems, setEffPolicyDataItems] = useState<EffectiveTagPoliciesDataItem[]>();
    const [selectedEffectiveTagPolicy, setSelectedEffectiveTagPolicy] = useState<EffectiveTagPoliciesDataItem>();
    const [effPolicies, setEffPolicies] = useState<AwsEffectiveTagPolicy[]>();
    const fmtSvc = useDi(FormatService);
    const asyncBundler = useDi(AsyncBundler);
    const company = useCompany()!;
    async function bundledResourceRequest<T>(query: Query) {
        return asyncBundler.bundle('policy-compliance-counts', query, (r) => postResourcesMultiQuery(r, { companyId: company.Id })) as QueryResult<T>;
    }

    type EffectiveTagPoliciesDataItem = { id: number; accountId: string; name: string; statements: number; compliant: number; nonCompliant: number };

    useEffect(() => {
        (async () => {
            const accounts = await getAccountGetCompanyAccounts();
            const accountsConnectedStatus = await getAccountGetAccountConnectionStatus({ companyId: company.Id });
            const effectivePolicies = await postTagPolicyGetPolicies();
            setEffPolicies(effectivePolicies);
            const policyTagKeys = new Set<string>();

            const accountLookup = accounts.reduce((result, item) => result.set(item.Id ?? 0, item), new Map<number, Account>());
            const connectedLookup = accountsConnectedStatus.reduce(
                (result, item) => result.set(item.AccountId ?? 0, item),
                new Map<number, AccountConnectedStatus>()
            );
            if (effectivePolicies) {
                const getComplianceCounts = async (policy: AwsEffectiveTagPolicy) => {
                    const rules = policy.Statements?.map((stmt) => stmt.ComplianceRule).filter((r) => !!r) ?? [];
                    const result = { compliant: 0, nonCompliant: 0 };
                    if (rules.length) {
                        const query = queryBuilder<{ AccountID: number }>()
                            .where((b) => b.model.AccountID.eq(policy.AccountId!))
                            .select((b) => ({
                                compliant: rules.length ? b.countIf(b.and(...rules.map((r) => b.fromExpr(r as QueryExpr)))) : b.count(),
                            }))
                            .build();
                        const queryResult = await bundledResourceRequest<{ compliant: number }>(query);
                        result.compliant = queryResult.Results?.[0]?.compliant ?? 0;
                        result.nonCompliant = (queryResult.Count ?? 0) - result.compliant;
                    }
                    return result;
                };
                const totalResourceRequest = bundledResourceRequest<{}>({ Take: 0 }).then((r) => setTotalResources(r.Count ?? 0));
                const uniquePolicyTagKeys = bundledResourceRequest<{ tagKeys: number }>(
                    queryBuilder<{ ['Tags.Key']: string }>()
                        .select((b) => ({ tagKeys: b.countUniqueValues(b.model['Tags.Key']) }))
                        .build()
                ).then((r) => setTotalTagKeys(r.Results?.[0].tagKeys ?? 0));
                const policyPromises = effectivePolicies.map(async (policy) => {
                    const account = accountLookup.get(policy.AccountId ?? 0);
                    const connected = connectedLookup.get(policy.AccountId ?? 0);
                    policy.Statements?.forEach((stmt) => policyTagKeys.add(stmt.Tag ?? ''));
                    const { compliant, nonCompliant } = await getComplianceCounts(policy);

                    return {
                        id: policy.AccountId!,
                        accountId: account?.AwsAccountId ?? '',
                        name: account?.Name ?? '',
                        statements: policy.Statements?.length ?? 0,
                        compliant,
                        nonCompliant,
                        connected: !!connected?.ConnectionStatus,
                    };
                });

                const policies = await Promise.all(policyPromises);
                setEffPolicyDataItems(policies.filter((f) => f.connected));
                await Promise.all([totalResourceRequest, uniquePolicyTagKeys]);

                var numCompliant = policies.reduce((a, v) => (a = a + v.compliant), 0);
                var numNoncompliant = policies.reduce((a, v) => (a = a + v.nonCompliant), 0);
                setPolicyTagKeys(policyTagKeys.size);
                setNumInCompliance(numCompliant);
                setNumNotInCompliance(numNoncompliant);
            }
        })();
    }, []);

    function PolicyStringLink({ name, value, policy }: { name: string; value: string; policy: EffectiveTagPoliciesDataItem }) {
        return (
            <Anchor onClick={() => loadEffectiveTagPolicies(policy)}>
                {value} - {name}
            </Anchor>
        );
    }

    const routeLoader = useDi(BasicRouteLoader);
    const { getDescendUrl, goto } = useNav(routeLoader.getTopRouteMeta());
    const loadEffectiveTagPolicies = useCallback((policy?: EffectiveTagPoliciesDataItem) => {
        if (policy != null) {
            const allActivityUrl = getDescendUrl('tag-policy-details', {
                id: policy.id.toString(),
                accountId: policy.accountId,
                cloudProvider: 'aws',
            });
            goto(allActivityUrl);
        }
    }, []);

    const cols = useMemo(() => {
        return [
            {
                header: 'Account ID',
                defaultWidth: 300,
                id: 'accountId',
                type: 'string',
                align: 'left',
                accessor: (item) => {
                    return <PolicyStringLink name={item.name} value={item.accountId} policy={item}></PolicyStringLink>;
                },
                filter: {
                    filterField: 'accountId',
                    filterType: 'string',
                    name: 'accountId',
                },
            },
            {
                header: 'Effective Statements',
                accessor: (item) => {
                    return <div>{item.statements}</div>;
                },
                defaultWidth: 200,
                id: 'statements',
                type: 'number',
                align: 'right',
                filter: {
                    filterField: 'statements',
                    filterType: 'number',
                    name: 'statements',
                },
            },
            {
                header: 'Compliant',
                accessor: (item) => {
                    return <div>{item.compliant}</div>;
                },
                defaultWidth: 150,
                id: 'compliant',
                type: 'number',
                align: 'right',
                filter: {
                    filterField: 'compliant',
                    filterType: 'number',
                    name: 'compliant',
                },
            },
            {
                header: 'Non-Compliant',
                accessor: (item) => {
                    return <div>{item.nonCompliant}</div>;
                },
                defaultWidth: 150,
                id: 'nonCompliant',
                type: 'number',
                align: 'right',
                filter: {
                    filterField: 'nonCompliant',
                    filterType: 'number',
                    name: 'nonCompliant',
                },
            },
            {
                header: 'Total',
                accessor: (item) => {
                    return <div>{item.compliant + item.nonCompliant}</div>;
                },
                defaultWidth: 150,
                id: 'Total',
                align: 'right',
                type: 'number',
                sortField: 'Total',
            },
            {
                header: 'Compliance',
                accessor: (item) => {
                    return (
                        <Group>
                            <div style={{ marginTop: '2px', width: '100px' }}>
                                <Progress
                                    radius="md"
                                    size="md"
                                    value={item.compliant + item.nonCompliant > 0 ? (item.compliant / (item.compliant + item.nonCompliant)) * 100 : 0}
                                />
                            </div>
                            <div style={{ textAlign: 'right', width: '40px' }}>
                                {Math.round(
                                    item.compliant + item.nonCompliant > 0 ? (item.compliant / (item.compliant + item.nonCompliant)) * 100 : 0
                                )}
                                %
                            </div>
                            <div style={{ textAlign: 'right', width: '100px' }}>
                                <i className="ti ti-chevron-right"></i>
                            </div>
                        </Group>
                    );
                },
                defaultWidth: 300,
                id: 'Compliance',
                align: 'left',
                type: 'number',
            },
        ] as ColumnConfig<EffectiveTagPoliciesDataItem>[];
    }, [effPolicies, effPolicyDataItems]);

    var chartProps = useMemo(
        () => ({
            settings: {
                noWrapper: true,
            } as PieChartSettings,
        }),
        []
    );

    return (
        <PanelBody>
            <Grid>
                <Grid.Col span={6}>
                    <Container>
                        <Header>Resource Policy Coverage</Header>
                        <div style={{ textAlign: 'center' }}>
                            <PercentCovered>
                                {totalResources === 0 ? <>&mdash;</> : fmtSvc.formatPercent((numInCompliance + numNotInCompliance) / totalResources)}
                            </PercentCovered>
                            <Group position="apart" align="center" spacing={0}>
                                <CoverageText style={{ fontWeight: 'bold', background: theme.colors?.gray?.[2] as CustomColors }}>
                                    <br />
                                    Covered by Policy
                                </CoverageText>
                                <CoverageText style={{ fontWeight: 'bold', background: theme.colors?.gray?.[2] as CustomColors }}>
                                    Not Covered
                                    <br />
                                    by Policy
                                </CoverageText>
                                <CoverageText style={{ fontWeight: 'bold', background: theme.colors?.gray?.[2] as CustomColors }}>
                                    <br />
                                    Total
                                </CoverageText>
                            </Group>
                            <Group position="apart" align="center" spacing={0}>
                                <CoverageText>{fmtSvc.formatInt(numInCompliance + numNotInCompliance)}</CoverageText>
                                <CoverageText>{fmtSvc.formatInt(totalResources - (numInCompliance + numNotInCompliance))}</CoverageText>
                                <CoverageText>{fmtSvc.formatInt(totalResources)}</CoverageText>
                            </Group>
                        </div>
                    </Container>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Container>
                        <Header>Tag Keys Covered by Policies</Header>
                        {numInCompliance + numNotInCompliance > 0 ? (
                            <div style={{ height: '150px' }}>
                                <PieChart
                                    {...chartProps}
                                    groups={['x']}
                                    values={['y']}
                                    data={[
                                        { x: 'Covered', y: policyTagKeys },
                                        { x: 'Not Covered', y: totalTagKeys - policyTagKeys },
                                    ]}
                                />
                            </div>
                        ) : (
                            <NoEffectivePolicies>No effective policies</NoEffectivePolicies>
                        )}
                    </Container>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Container style={{ height: '416px' }}>
                        <Header style={{ paddingBottom: '16px' }}>Effective Tag Policies by Account</Header>
                        <div style={{ height: '350px' }}>
                            <FillerSwitch loading={!effPolicyDataItems}>
                                {() => (
                                    <>
                                        {!effPolicyDataItems ? null : (
                                            <DataGrid
                                                dataSource={effPolicyDataItems}
                                                columns={cols}
                                                hideHeader
                                                selection={selectedEffectiveTagPolicy}
                                                selectionMode="single"
                                                onRowClick={loadEffectiveTagPolicies}
                                            />
                                        )}
                                    </>
                                )}
                            </FillerSwitch>
                        </div>
                    </Container>
                </Grid.Col>
            </Grid>
        </PanelBody>
    );
};

import { Box, Card, Center, Group, ThemeIcon, Text, useMantineTheme } from '@mantine/core';
import { ReactNode } from 'react';

interface IContextButtonProps {
    onClick: () => void;
    sections: {
        /**
         * Small dim label for primary text
         */
        label: string;
        /**
         * Primary text for the button section
         */
        text: string | ReactNode;
    }[];
    icon?: ReactNode;
}
export function ContextButton({ sections, icon, onClick }: IContextButtonProps) {
    return (
        <Group onClick={onClick} sx={{ cursor: 'pointer', ':hover': { filter: 'brightness(.9) contrast(1.2)' } }} spacing={2}>
            {sections.map((s, i) => (
                <ContextButtonSection key={i} icon={icon} label={s.label} text={s.text} first={i === 0} last={i === sections.length - 1} />
            ))}
        </Group>
    );
}

function ContextButtonSection({
    label,
    text,
    first,
    last,
    icon,
}: {
    label: string;
    text: string | ReactNode;
    first: boolean;
    last: boolean;
    icon?: ReactNode;
}) {
    const theme = useMantineTheme();
    const radius = {
        borderTopLeftRadius: first ? theme.radius.sm : 0,
        borderBottomLeftRadius: first ? theme.radius.sm : 0,
        borderTopRightRadius: last ? theme.radius.sm : 0,
        borderBottomRightRadius: last ? theme.radius.sm : 0,
    };
    return (
        <Card p={0} sx={{ ...radius, background: theme.colors.primary[3], height: 60 }}>
            <Group p={12} sx={{ height: 60 }}>
                {first && icon ? <ThemeIcon size={36}>{icon}</ThemeIcon> : null}
                <Box>
                    <Text size="xs" color="dimmed" sx={{ lineHeight: 1 }}>
                        {label}
                    </Text>
                    <Center>
                        {typeof text === 'string' ? (
                            <Text color="primary" size="sm">
                                {text}
                            </Text>
                        ) : (
                            text
                        )}
                    </Center>
                </Box>
            </Group>
        </Card>
    );
}

import { AwsResourceUsageChart } from '@root/Components/Resources/Aws/AwsResourceUsageChart';
import { Grid, SegmentedControl, Table } from '@mantine/core';
import { useEffect, useState } from 'react';
import { awsResourceDefaultMetrics } from './AwsDefaultChartMetrics';
import { BaseResource } from '@apis/Resources/model';

export function AwsResourceUsage({ resource }: { resource: BaseResource }) {
    const [historyDaysBack, setHistoryDaysBack] = useState<string>('60');
    const [currDaysBack, setDaysBack] = useState<number>(60);
    const [dataFrequency, setDataFrequency] = useState<string>('60');
    const [currFrequency, setFrequency] = useState<number>(60);

    useEffect(() => {
        setDaysBack(Number(historyDaysBack));
    }, [historyDaysBack]);

    useEffect(() => {
        setFrequency(Number(dataFrequency));
    }, [dataFrequency]);

    const defaultCharts = awsResourceDefaultMetrics.get(resource.ResourceType ?? '');

    return (
        <div className="resourcesTableContainer">
            <Table>
                <thead>
                    <tr>
                        <td>History</td>
                        <td>Data Frequency</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="noPadding">
                            <SegmentedControl
                                key="DaysBack"
                                value={historyDaysBack}
                                onChange={setHistoryDaysBack}
                                defaultValue={historyDaysBack}
                                data={[
                                    { label: '30 Days', value: '30' },
                                    { label: '60 Days', value: '60' },
                                    { label: '180 Days', value: '180' },
                                    { label: '12 Months', value: '365' },
                                ]}
                            />
                        </td>
                        <td className="noPadding">
                            <SegmentedControl
                                key="Frequency"
                                value={dataFrequency}
                                onChange={setDataFrequency}
                                defaultValue={dataFrequency}
                                data={[
                                    { label: '5 Minutes', value: '5' },
                                    { label: '15 Minutes', value: '15' },
                                    { label: '1 Hour', value: '60' },
                                    { label: '1 Day', value: '1440' },
                                ]}
                            />
                        </td>
                    </tr>
                </tbody>
                <br />
            </Table>
            <Grid>
                {defaultCharts?.map(({ metric, unit, operation }, idx) => (
                    <Grid.Col md={6} key={idx}>
                        <AwsResourceUsageChart
                            resourceType={resource.ResourceType ?? ''}
                            resourceId={resource.Id ?? ''}
                            metric={metric}
                            unit={unit}
                            operation={operation}
                            daysBack={currDaysBack}
                            frequency={currFrequency}
                        />
                    </Grid.Col>
                ))}
            </Grid>
        </div>
    );
}

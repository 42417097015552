import {
    deleteDashboard,
    getDashboard,
    getDashboardGetByKey,
    postDashboardRemoveSharedAccess,
    postDashboardSave,
    postDashboardShare,
    postDashboardShareWithUser,
} from '@apis/Customers';
import { DashboardConfig, DashboardConfigShareType } from '@apis/Customers/model';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { Logger } from '@root/Services/Logger';
import { singleton, inject } from 'tsyringe';

export interface IDashboardConfigBase {
    name: string;
    dateAccessed?: Date;
    dateSaved?: Date;
}

@singleton()
export class DashboardPersistenceService {
    public constructor(
        @inject(Logger) private readonly logger: Logger,
        @inject(AuthenticationService) private readonly authSvc: AuthenticationService
    ) {}
    public async save(key: string, id: number | undefined, layout: IDashboardConfigBase) {
        layout.dateAccessed = new Date();
        layout.dateSaved = new Date();
        const result = await postDashboardSave({ Id: id, DashboardJson: JSON.stringify(layout), DashboardKey: key });
        return result?.Id;
    }
    public async share(key: string, id: number, shareType: DashboardConfigShareType) {
        return await postDashboardShare({ Id: id, ShareType: shareType, DashboardKey: key, DashboardJson: '' });
    }

    public async shareWithUser(dashboardId: number, userIds: number[]) {
        return await postDashboardShareWithUser(userIds, { dashboardId });
    }

    public async removeSharedUser(dashboardId: number, userId: number) {
        return await postDashboardRemoveSharedAccess({ dashboardId, userId });
    }

    public async load(key: string, id: number | undefined) {
        try {
            const dashboard = await getDashboard({ id });
            if (dashboard.DashboardJson) {
                const layout = this.deserializeLayout(dashboard.DashboardJson);
                this.updateDateAccessed(dashboard, layout);
                return layout;
            }
        } catch (err) {
            this.logger.error(`Error while trying to load dashboard layout for key ${key}`, err);
        }
        return null;
    }
    public async delete(id: number) {
        try {
            await deleteDashboard({ id });
        } catch (err) {
            this.logger.error(`Error while trying to delete dashboard layout for id ${id}`, err);
        }
    }
    public async getLayouts<T extends IDashboardConfigBase>(key: string) {
        const dashboards = await getDashboardGetByKey({ dashboardKey: key });
        const result: { id: number; layout: T; shareType?: DashboardConfigShareType; ownerUserId: number }[] = [];
        for (const dashboard of dashboards) {
            try {
                if (dashboard?.DashboardJson) {
                    result.push({
                        id: dashboard.Id!,
                        layout: this.deserializeLayout(dashboard.DashboardJson),
                        ownerUserId: dashboard.OwnerUserId ?? 0,
                        shareType: dashboard.ShareType,
                    });
                }
            } catch (err) {
                this.logger.error(`Error while trying to deserialize dashboard ${dashboard.Id}`);
            }
        }
        result.sort((a, b) => (a.layout.name ?? '').localeCompare(b.layout.name ?? '', undefined, { sensitivity: 'base' }));
        return result;
    }
    private deserializeLayout(dashboardJson: string) {
        const layout = JSON.parse(dashboardJson);
        const result = {
            ...layout,
            dateAccessed: layout.dateAccessed ? new Date(layout.dateAccessed) : undefined,
            dateSaved: layout.dateSaved ? new Date(layout.dateSaved) : undefined,
        };
        return result;
    }
    private async updateDateAccessed(dashboard: DashboardConfig, layout: IDashboardConfigBase) {
        try {
            await postDashboardSave({
                ...dashboard,
                DashboardJson: JSON.stringify({ ...layout, dateAccessed: new Date() }),
            });
        } catch (err) {
            this.logger.error('Error while trying to update dateAccessed on dashboard', err);
        }
    }
}

import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { AwsTagPolicyDetails } from './TagPolicy/Components/AwsTagPolicyDetails';
import { AzureTagPolicyDetails } from './TagPolicy/Components/AzureTagPolicyDetails';

export function TagPolicyDetails() {
    let { cloudProvider } = useNav().getData('cloudProvider');

    let mode = cloudProvider && cloudProvider === 'aws' ? <AwsTagPolicyDetails /> : <AzureTagPolicyDetails />;

    return mode;
}
endpoint('tag-policy-details', TagPolicyDetails, 'Tag Policy Details');

import { getVariantGroupGetVariantGroups, putVariantGroupUpdateVariantGroup, putVariantGroupUpdateVariantResources } from '@apis/TagManager';
import { Variant, VariantGroup } from '@apis/TagManager/model';
import { PageContent, PageSection } from '@root/Design/Layout';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { makeAutoObservable } from 'mobx';
import { useEffect, useMemo, useState } from 'react';
import { TagsShell } from './Common';
import { VGroupViewModel } from './ViewModels/ViewModels';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { InfoList, InfoListActionItemProps, InfoListRow } from './Components/InfoListTable';
import { Observer } from 'mobx-react';
import { ActionPageContainer, InfoListContainer } from '@root/Design/ActionPage';
import { Button, Divider } from '@mantine/core';

export function VariantGroups() {
    const [vGroups, setVGroups] = useState<VGroupViewModel[]>([]);
    const nav = useNav();

    const marked = useMemo(() => {
        return makeAutoObservable({
            markedVGs: new Set<VGroupViewModel>(),
            add(markedVG: VGroupViewModel) {
                this.markedVGs.add(markedVG);
            },
            clear() {
                this.markedVGs.clear();
            },
        });
    }, []);

    const com = useCompany();
    useEffect(() => {
        (async () => {
            const VGs: VariantGroup[] = await getVariantGroupGetVariantGroups({ companyId: com?.Id });
            const vGroupsVM: VGroupViewModel[] = [];
            for (const group of VGs) {
                const vGroup: VGroupViewModel = {
                    ...group,
                    Type: group.ParentKey ? 'Value' : 'Key',
                    VariantCount: group.Variants?.length ?? 0,
                    Primary: '',
                };

                if (group.Variants && group.Variants.length > 0) {
                    for (const variant of group.Variants) {
                        if (variant.IsPrimary) {
                            vGroup.Primary = variant.VariantString as string;
                        }
                    }
                }
                if (!vGroup.IsReviewed) {
                    vGroupsVM.push(vGroup);
                }
            }
            setVGroups(vGroupsVM);
        })();
    }, []);

    const vGroupsKey = vGroups.filter((group) => group.ParentKey == '');
    const vGroupsValue = vGroups.filter((group) => group.ParentKey != null && group.ParentKey != '');

    return (
        <TagsShell>
            <PageContent>
                <ActionPageContainer>
                    <InfoList columns={['', 'Most Commonly used Tag', 'Variant Count']}>
                        {vGroupsKey.map((group) => (
                            <InfoListRow>
                                {[
                                    <Observer
                                        render={() => (
                                            <input
                                                type={'checkbox'}
                                                name={group.Id?.toString()}
                                                value={group.Id as number}
                                                onChange={(e) => {
                                                    e.currentTarget.checked;
                                                    marked.add(group);
                                                }}
                                            />
                                        )}
                                    />,
                                    <Button component="a" href={nav.getDescendUrl('variants', { vgid: group.Id?.toString() ?? '' })}>
                                        {group.Primary}
                                    </Button>,
                                    group.VariantCount,
                                ]}
                            </InfoListRow>
                        ))}
                    </InfoList>
                    <Divider orientation="vertical" />
                    <InfoList columns={['', 'Most Commonly used Tag', 'Variant Count']}>
                        {vGroupsValue.map((group) => (
                            <InfoListRow>
                                {[
                                    <Observer
                                        render={() => (
                                            <input
                                                type={'checkbox'}
                                                name={group.Id?.toString()}
                                                value={group.Id as number}
                                                onChange={(e) => {
                                                    e.currentTarget.checked;
                                                    marked.add(group);
                                                }}
                                            />
                                        )}
                                    />,
                                    <Button component="a" href={nav.getDescendUrl('variants', { vgid: group.Id?.toString() ?? '' })}>
                                        {group.Primary}
                                    </Button>,
                                    group.VariantCount,
                                ]}
                            </InfoListRow>
                        ))}
                    </InfoList>
                </ActionPageContainer>
                <Observer
                    render={() => (
                        <Button
                            type="submit"
                            onClick={() => {
                                markAsReview(marked.markedVGs);
                                marked.clear();
                            }}
                        >
                            Mark as Reviewed
                        </Button>
                    )}
                />
            </PageContent>
        </TagsShell>
    );
}
endpoint('variant-groups', VariantGroups, 'Variant Groups');

async function markAsReview(markedVGs: Set<VGroupViewModel>): Promise<void> {
    for (const vG of markedVGs) {
        vG.IsReviewed = true;
        const VariantGroup = ConvertToVariantGroupModel(vG);
        const done = await putVariantGroupUpdateVariantGroup(VariantGroup);
        if (done.IsReviewed) {
            updateVariantResources(done.Variants as Variant[]);
        }
    }
}

function ConvertToVariantGroupModel(vG: VGroupViewModel) {
    const variantGroup: VariantGroup = {
        Id: vG.Id,
        CompanyId: vG.CompanyId,
        IsReviewed: vG.IsReviewed,
        ParentKey: vG.ParentKey,
        Variants: vG.Variants,
    };

    return variantGroup;
}
async function updateVariantResources(Variants: Variant[]) {
    await putVariantGroupUpdateVariantResources(Variants);
}

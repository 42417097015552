import { ThemeProvider } from '@emotion/react';
import { MantineProvider, useMantineTheme } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { NotificationsProvider } from '@mantine/notifications';
import './App.scss';
import { theme } from './Design/Themes';
import './Site';
import { Shell } from './Components/Shell/Shell';
import { AuthenticationProvider } from './Components/Shell/AuthenticationProvider';
import { useDi } from './Services/DI';
import { useEventValue } from './Services/EventEmitter';
import { MspService } from './Services/MspService';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './Components/Shell/ErrorFallback';
import { FrontEndErrorType } from '@apis/Customers/model';
import { postFrontEndErrorSaveError } from '@apis/Customers';

function AppThemed() {
    const theme = useMantineTheme();

    const logError = (error: any, info: { componentStack: string }) => {
        const errorToPost = {
            Type: FrontEndErrorType.Unknown,
            ErrorMessage: error.message + 'ComponentStack: ' + info.componentStack,
            Description: error.description,
            ErrorStack: error.stack,
        };

        postFrontEndErrorSaveError(errorToPost);
    };

    return (
        <ThemeProvider theme={theme}>
            <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
                <ModalsProvider>
                    <NotificationsProvider position="top-right" zIndex={1000} style={{ marginTop: '40px' }}>
                        <Shell></Shell>
                        <AuthenticationProvider />
                    </NotificationsProvider>
                </ModalsProvider>
            </ErrorBoundary>
        </ThemeProvider>
    );
}

function App() {
    const mspService = useDi(MspService);
    const themeReady = useEventValue(mspService.themeReady);
    return themeReady ? (
        <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
            <AppThemed />
        </MantineProvider>
    ) : (
        <></>
    );
}

export default App;

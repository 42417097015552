import { Box, Button, Card, Divider, Group, Loader, Text, ThemeIcon, useMantineTheme, Checkbox, Space } from '@mantine/core';
import { QueryBasedResourceSelection } from '@root/Components/Resources/ResourcesGrid';
import { PanelToolbar } from '@root/Design/Layout';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { JobService } from '@root/Services/Jobs/JobService';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { useCallback, useEffect, useState } from 'react';
import { AlertTriangle, ListCheck, Trash } from 'tabler-icons-react';
import { MapTagPickerOption, ResourceTypeMapTagPicker } from '../Components';
import { MapTagEditorModel } from './Models';
import { Clearfix } from '@root/Design/Primitives';
import styled from '@emotion/styled';
import { TagOption } from '../../Services/MapCostAllocationTagService';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { MapWorkloadTag } from '@root/Components/Resources/Tags/MapWorkloadTag';
import { CreditBalanceDetail } from '@root/Site/TagManager/Components/CreditBalanceDetails';
import { TagResourcesJob } from '@apis/Resources/model';

const mapMigratedKey = 'map-migrated';
const mapWorkloadKey = 'map-workload';

export function MapTagEditorPanel({
    selection,
    selectionChanged,
    model,
    onTagJobStarting,
}: {
    selection: QueryBasedResourceSelection;
    selectionChanged: EventEmitter<any>;
    model: MapTagEditorModel;
    onTagJobStarting?: () => Promise<void>;
}) {
    const theme = useMantineTheme();
    const [selectedTag, setSelectedTag] = useState<TagOption>();
    const [selectedTypes, setSelectedTypes] = useState<string[]>();
    const [applying, setApplying] = useState(false);
    const notificationSvc = useDi(NotificationService);
    const jobSvc = useDi(JobService);
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const [valueSelected, setValueSelected] = useState<boolean>(false);
    const requireWorkload = model.requireWorkload;
    const creditsEnforced = model.creditsEnforced;

    const onSelectionChange = useCallback(async () => {
        setSelectedTypes(await model.getSelectedResourceTypes());
    }, [model]);

    useEvent(selectionChanged, onSelectionChange);
    useEffect(() => {
        onSelectionChange();
    }, []);

    useEffect(() => {
        if (selectedTag?.value && !(selectedTag?.disabled && allResourceTypesValid)) {
            setValueSelected(true);
        }
    }, [selectedTag]);

    const [mapWorkloadValue, setMapWorkloadValue] = useState('');
    const handleNewMapWorkloadTagValue = (value: string) => {
        setMapWorkloadValue(value);
    };

    const apply = useCallback(async () => {
        const count = model.selection?.count() ?? 0;
        if (selectedTag && count > 0) {
            const action = selectedTag.value === 'None' ? 'Removal' : 'Assignment';
            try {
                setApplying(true);
                if (onTagJobStarting) {
                    await onTagJobStarting();
                }

                await model.applyTag(selectedTag.value, mapWorkloadValue);
                model.notificationSvc.notify(
                    'Tag Update Task',
                    `Tag ${action} job for ${count} ${
                        count !== 1 ? 'resources' : 'resource'
                    } was submitted. Please check the activity log for status details.`,
                    'primary',
                    <ThemeIcon style={{ backgroundColor: theme.colors?.gray?.[2] }} variant="light" size="xl" radius="xl">
                        <ListCheck style={{ color: theme.colors!.primary![8] }} />
                    </ThemeIcon>
                );
                jobSvc.notifyNewJob();
            } catch (err) {
                setApplying(false);
                notificationSvc.notify(
                    'Tag Update Task',
                    `Error: Tag ${action} job for ${count} ${
                        count !== 1 ? 'resources' : 'resource'
                    } failed. Please check the activity log for status details.`,
                    'error',
                    null
                );
            } finally {
                setApplying(false);
            }
        }
    }, [model, selectedTag, mapWorkloadValue, onTagJobStarting]);

    const allResourceTypesValid = selectedTypes?.every((t) => model.mapQuerySvc.validTagsByResourceType.get(t)?.size);
    const userPermissions = useAuthZValues({ canTag: { TagManager: 'Tag' } });

    const [creditsExhausted, setCreditsExhausted] = useState<boolean>(creditsEnforced);
    const requireConfirmation = !(selectedTag?.value === 'Delete' || selectedTag?.value === 'not-eligible-workload');
    const workloadApplicable = requireConfirmation && requireWorkload;
    const applyDisabled =
        !userPermissions.canTag ||
        applying ||
        !selectedTag ||
        selection.count() === 0 ||
        (requireConfirmation && !confirmed) ||
        (creditsEnforced && creditsExhausted) ||
        (workloadApplicable && mapWorkloadValue == '');

    const [tagJob, setTagJob] = useState<TagResourcesJob | null>(null);

    useEffect(() => {
        if (creditsEnforced && !!selectedTag?.value) {
            model.createTagRequest(selectedTag?.value ?? '', mapWorkloadValue).then(setTagJob);
        } else {
            setTagJob(null);
        }
    }, [!selectedTag, !mapWorkloadValue, creditsEnforced, selection.count()]);

    return (
        <Box sx={{ background: theme.colors.gray[0], height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Group p="md" py="sm" position="apart" noWrap sx={{ background: theme.colors.gray[1] }}>
                <Text color="dimmed">Select a "map-migrated" Value</Text>
                <Button
                    size="xs"
                    variant="outline"
                    component="a"
                    target="_blank"
                    href="https://s3.us-west-2.amazonaws.com/map-2.0-customer-documentation/html/AWSMapDocs/setting-up.html"
                    data-atid="TaggingGuideButton"
                >
                    Tagging Guide
                </Button>
            </Group>
            <Divider color="gray.3" />
            <Box p="md" sx={{ flex: 1, overflow: 'auto' }}>
                <Card p={0} withBorder radius="md" shadow="sm">
                    <ResourceTypeMapTagPicker
                        querySvc={model.mapQuerySvc}
                        type={selectedTypes}
                        onSelectPanel={setSelectedTag}
                        selected={selectedTag?.value ?? ''}
                        size="lg"
                    />
                    <MapTagPickerOption
                        Icon={Trash}
                        description={'Remove the "map-migrated" tag'}
                        value="Delete"
                        selected={selectedTag?.value === 'Delete'}
                        onClick={() => setSelectedTag({ value: 'Delete', title: 'Delete', disabled: false, other: true })}
                        size="lg"
                        data-atid="DeleteMapTag"
                    />
                </Card>
            </Box>
            <Divider color="gray.3" />
            {selectedTag?.disabled && allResourceTypesValid ? (
                <>
                    <Box component={WarningBox}>
                        <Box component={WarningBoxIcon}>
                            <AlertTriangle style={{ color: theme.colors.warning[5] }} size={50}></AlertTriangle>
                        </Box>
                        <Box component={WarningBoxText}>
                            <Text size="md" sx={{ padding: '0px 10px', fontWeight: 'bold' }}>
                                Warning: Ineligible Tag Value
                            </Text>
                            <Text size="xs" sx={{ padding: '0px 10px' }}>
                                You are attempting to apply a MAP tag value that is not approved for this service under the MAP program.
                            </Text>
                            <br></br>
                            <Text size="xs" sx={{ padding: '0px 10px' }}>
                                Applying MAP tags to the selected resources may violate the terms and conditions of your MAP agreement.
                            </Text>
                        </Box>
                        <Clearfix></Clearfix>
                    </Box>
                    <Divider color="gray.3" />
                </>
            ) : null}
            {selectedTag?.disabled && !allResourceTypesValid ? (
                <>
                    <Box component={WarningBox}>
                        <Box component={WarningBoxIcon}>
                            <AlertTriangle style={{ color: theme.colors.warning[5] }} size={50}></AlertTriangle>
                        </Box>
                        <Box component={WarningBoxText}>
                            <Text size="md" sx={{ padding: '0px 10px', fontWeight: 'bold' }}>
                                Warning: Ineligible Resources
                            </Text>
                            <Text size="xs" sx={{ padding: '0px 10px' }}>
                                You are attempting to apply a map-migrated tag to resources that are ineligible for the MAP program, either because
                                the service is not supported by MAP or because the resource was launched outside the contract period.{' '}
                            </Text>
                            <br></br>
                            <Text size="xs" sx={{ padding: '0px 10px' }}>
                                Applying MAP tags to the selected resources may violate the terms and conditions of your MAP agreement.
                            </Text>
                        </Box>
                        <Clearfix></Clearfix>
                    </Box>
                    <Divider color="gray.3" />
                </>
            ) : null}
            <Box p="lg" sx={{ background: theme.colors.gray[1] }}>
                {workloadApplicable ? (
                    <>
                        <MapWorkloadTag
                            topSpace={false}
                            bottomSpace={false}
                            mapWorkloadKey={mapWorkloadKey}
                            mapWorkloadValue={mapWorkloadValue}
                            newMapWorkloadTagValue={handleNewMapWorkloadTagValue}
                        />
                        <Space h="sm" />
                    </>
                ) : null}
                {requireConfirmation ? (
                    <Checkbox
                        disabled={!userPermissions.canTag || applying || !selectedTag || selection.count() === 0}
                        size="md"
                        px="sm"
                        checked={confirmed}
                        sx={{ input: { borderColor: theme.colors.gray[5], cursor: 'pointer' } }}
                        onChange={(event) => setConfirmed(event.currentTarget.checked)}
                        label={
                            <Text color="primary" size="xs" sx={{ cursor: 'pointer' }}>
                                I confirm the selected workloads are included in my MAP Migration Plan.
                            </Text>
                        }
                        data-atid="WorkloadCheckboxInDrawer"
                    />
                ) : null}

                {!creditsEnforced ? null : !tagJob ? null : (
                    <>
                        <Space h="sm" />
                        <CreditBalanceDetail
                            canSubmit={!applyDisabled}
                            overwrite
                            tagJob={tagJob}
                            resourcesSelected={selection}
                            setDisableSubmit={setCreditsExhausted}
                            setOverwrite={() => {}}
                            hideOverwrite
                        />
                    </>
                )}

                <Space h="sm" />
                <Button
                    fullWidth
                    leftIcon={applying ? <Loader color="gray.0" size="xs" /> : null}
                    onClick={apply}
                    disabled={applyDisabled}
                    color="primary"
                    data-atid="ApplyMapTagInDrawer"
                    mb={4}
                >
                    {selection.count() ? (
                        <>
                            Apply to {selection.count()} Resource{selection.count() == 1 ? '' : 's'}
                        </>
                    ) : (
                        <>Select Resources</>
                    )}
                </Button>
            </Box>
        </Box>
    );
}

const WarningBox = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.colors?.warning[1]};
`;

const WarningBoxText = styled.div`
    width: 82%;
    float: left;
    text-align: left;
    margin: 5px 0px;
`;

const WarningBoxIcon = styled.div`
    float: left;
    width: 15%;
    text-align: center;
    margin: 5px 0px 0px 10px;
`;

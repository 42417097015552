import { BaseResource } from '@apis/Resources/model';
import { Tabs, useMantineTheme, Sx, TabsListProps, TabsProps } from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { AwsResourceHistory } from './AwsResourceHistory';
import { AwsAutoscaleInstances } from './AwsAutoscaleInstances';
import { AwsEbsIopsUtilization } from './AwsEbsIopsUtilization';
import { AwsResourceRedshiftNodes } from './AwsResourceRedshiftNodes';
import { AwsEmrResources } from './AwsEmrResources';
import { AwsRoute53Records } from './AwsRoute53Records';
import { AwsEc2Snapshots } from './AwsEc2Snapshots';
import { AwsEc2Storage } from './AwsEc2Storage';
import { AwsResourceUsage } from './AwsResourceUsage';
import { AwsResourceOverview } from './AwsResourceOverview';
import { FillerSwitch } from '@root/Design/Filler';
import { ResourceTags } from '../Common/ResourceTags';
import { awsResourceDefaultMetrics } from './AwsDefaultChartMetrics';
import { AwsRdsSnapshots } from './AwsRdsSnapshots';
import { ResourceChangeCountCtx } from '../Common/OverviewCommon';
import { EventEmitter } from '@root/Services/EventEmitter';

export function AwsResourceDetailsTabs(props: { resourceType: string; resourceId: string; onModelLoaded?: (resource: BaseResource) => void }) {
    return <AwsResourceDetailsLoader {...props}>{(r, reload) => <ResourceDetailsTabs resource={r} reload={reload} />}</AwsResourceDetailsLoader>;
}

export function AwsResourceDetailsLoader({
    resourceId,
    resourceType,
    children,
    onModelLoaded,
}: {
    resourceType: string;
    resourceId: string;
    onModelLoaded?: (resource: BaseResource) => void;
    children: (resource: BaseResource, reload: () => void) => ReactNode;
}) {
    const resourceSvc = useDi(ResourceService);
    const [loading, setLoading] = useState(true);
    const [resource, setResource] = useState<BaseResource | null>();
    const loadResource = useCallback(() => {
        resourceSvc.getResource(resourceId, resourceType).then((r) => {
            setLoading(false);
            setResource(r);
            if (r) {
                onModelLoaded?.(r);
            }
        });
    }, [resourceId, resourceType]);
    useEffect(() => {
        loadResource();
    }, [loadResource]);

    return (
        <FillerSwitch loading={loading} noData={!resource}>
            {() => children(resource!, loadResource)}
        </FillerSwitch>
    );
}

function ResourceDetailsTabs({ resource, reload }: { resource: BaseResource; reload: () => void }) {
    const theme = useMantineTheme();
    const resourceType = resource.ResourceType ?? '';
    const resourceId = resource.Id ?? '';
    const [currentTab, setCurrentTab] = useState<string | null>('Details');
    const changeCt = useMemo(() => ({ resourceChangeCt: new EventEmitter(0) }), []);

    const onTagsChanged = useCallback(() => {
        changeCt.resourceChangeCt.emit(changeCt.resourceChangeCt.value + 1);
    }, [changeCt, reload]);

    const tabSx: Sx = {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        ['[role=tabpanel]']: { overflow: 'auto', flex: 1, minHeight: 0, background: theme.colors.gray[2] },
    };
    const defaultUsageCharts = awsResourceDefaultMetrics.get(resourceType);

    const tabProps: Partial<TabsProps> = { sx: tabSx, defaultValue: 'Details', value: currentTab, onTabChange: setCurrentTab };

    const tagsTab = (
        <Tabs.Panel sx={{ padding: '0 !important' }} value="Tags">
            <ResourceTags resource={resource} onChange={onTagsChanged} />
        </Tabs.Panel>
    );
    const usageTab = <>{!defaultUsageCharts?.length ? null : <Tabs.Tab value="Usage">Usage</Tabs.Tab>}</>;
    const usageTabPanel = (
        <>
            {!defaultUsageCharts?.length ? null : (
                <Tabs.Panel value="Usage">
                    <AwsResourceUsage resource={resource} />
                </Tabs.Panel>
            )}
        </>
    );

    var resourceContainer;
    switch (resourceType) {
        case 'Autoscale':
            resourceContainer = (
                <Tabs {...tabProps}>
                    <Tabs.List>
                        <Tabs.Tab key="Details" value="Details">
                            Details
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        <Tabs.Tab key="Instances" value="Instances">
                            Instances
                        </Tabs.Tab>
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <AwsResourceOverview resource={resource} />
                    </Tabs.Panel>
                    {tagsTab}
                    <Tabs.Panel value="Instances">
                        <AwsAutoscaleInstances resource={resource} />
                    </Tabs.Panel>
                    <Tabs.Panel value="History">
                        <AwsResourceHistory key="History" resourceId={resourceId} resourceType={resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
        case 'EBS':
            resourceContainer = (
                <Tabs {...tabProps}>
                    <Tabs.List>
                        <Tabs.Tab key="Details" value="Details">
                            Volume Details
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        <Tabs.Tab key="Ec2Details" value="Ec2Details">
                            EC2 Details
                        </Tabs.Tab>
                        <Tabs.Tab key="IopsUtilization" value="IopsUtilization">
                            IOPS Utilization
                        </Tabs.Tab>
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <AwsResourceOverview resource={resource} />
                    </Tabs.Panel>
                    {tagsTab}
                    <Tabs.Panel value="Ec2Details">
                        <AwsResourceDetailsLoader
                            resourceId={`${resource.AccountID}.${(resource as any).Attachments?.[0]?.InstanceId}`}
                            resourceType={'EC2 On Demand'}
                        >
                            {(r) => <AwsResourceOverview resource={r} />}
                        </AwsResourceDetailsLoader>
                    </Tabs.Panel>
                    <Tabs.Panel value="IopsUtilization">
                        <AwsEbsIopsUtilization resource={resource} />
                    </Tabs.Panel>
                    <Tabs.Panel value="History">
                        <AwsResourceHistory key="History" resourceId={resourceId} resourceType={resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
        case 'EC2 On Demand':
            resourceContainer = (
                <Tabs {...tabProps}>
                    <Tabs.List>
                        <Tabs.Tab key="Compute" value="Details">
                            Compute
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        {usageTab}
                        <Tabs.Tab key="Storage" value="Storage">
                            Storage
                        </Tabs.Tab>
                        <Tabs.Tab key="Snapshots" value="Snapshots">
                            Snapshots
                        </Tabs.Tab>
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <AwsResourceOverview resource={resource} />
                    </Tabs.Panel>
                    {tagsTab}
                    {usageTabPanel}
                    <Tabs.Panel value="Storage">
                        <AwsEc2Storage key="Storage" resource={resource} />
                    </Tabs.Panel>
                    <Tabs.Panel value="Snapshots">
                        <AwsEc2Snapshots resource={resource} />
                    </Tabs.Panel>
                    <Tabs.Panel value="History">
                        <AwsResourceHistory key="History" resourceId={resourceId} resourceType={resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
        case 'ElastiCache Cluster':
            resourceContainer = (
                <Tabs {...tabProps}>
                    <Tabs.List>
                        <Tabs.Tab key="Details" value="Details">
                            Details
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <AwsResourceOverview resource={resource} />
                    </Tabs.Panel>
                    {tagsTab}
                    <Tabs.Panel value="History">
                        <AwsResourceHistory key="History" resourceId={resourceId} resourceType={resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
        case 'ELBV1':
        case 'ELBV2':
            resourceContainer = (
                <Tabs {...tabProps}>
                    <Tabs.List>
                        <Tabs.Tab key="Details" value="Details">
                            Details
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        {usageTab}
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <AwsResourceOverview resource={resource} />
                    </Tabs.Panel>
                    {tagsTab}
                    {usageTabPanel}
                    <Tabs.Panel value="History">
                        <AwsResourceHistory key="History" resourceId={resourceId} resourceType={resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
        case 'EMR':
            resourceContainer = (
                <Tabs {...tabProps}>
                    <Tabs.List>
                        <Tabs.Tab key="Details" value="Details">
                            Details
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        {usageTab}
                        <Tabs.Tab key="Resources" value="Resources">
                            Resources
                        </Tabs.Tab>
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <AwsResourceOverview resource={resource} />
                    </Tabs.Panel>
                    {tagsTab}
                    <Tabs.Panel value="Resources">
                        <AwsEmrResources resource={resource} />
                    </Tabs.Panel>
                    {usageTabPanel}
                    <Tabs.Panel value="History">
                        <AwsResourceHistory key="History" resourceId={resourceId} resourceType={resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
        case 'RDS':
            resourceContainer = (
                <Tabs {...tabProps}>
                    <Tabs.List>
                        <Tabs.Tab key="Details" value="Details">
                            Details
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        {usageTab}
                        <Tabs.Tab key="Snapshots" value="Snapshots">
                            Snapshots
                        </Tabs.Tab>
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <AwsResourceOverview resource={resource} />
                    </Tabs.Panel>
                    {tagsTab}
                    {usageTabPanel}
                    <Tabs.Panel value="Snapshots">
                        <AwsRdsSnapshots resource={resource} />
                    </Tabs.Panel>
                    <Tabs.Panel value="History">
                        <AwsResourceHistory key="History" resourceId={resourceId} resourceType={resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
        case 'Redshift':
            resourceContainer = (
                <Tabs {...tabProps}>
                    <Tabs.List>
                        <Tabs.Tab key="Details" value="Details">
                            Details
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        <Tabs.Tab key="Nodes" value="Nodes">
                            Nodes
                        </Tabs.Tab>
                        {usageTab}
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <AwsResourceOverview resource={resource} />
                    </Tabs.Panel>
                    {tagsTab}
                    <Tabs.Panel value="Nodes">
                        <AwsResourceRedshiftNodes resource={resource} />
                    </Tabs.Panel>
                    {usageTabPanel}
                    <Tabs.Panel value="History">
                        <AwsResourceHistory key="History" resourceId={resourceId} resourceType={resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
        case 'Route 53':
            resourceContainer = (
                <Tabs {...tabProps}>
                    <Tabs.List>
                        <Tabs.Tab key="Details" value="Details">
                            Details
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        <Tabs.Tab key="Records" value="Records">
                            Records
                        </Tabs.Tab>
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <AwsResourceOverview resource={resource} />
                    </Tabs.Panel>
                    {tagsTab}
                    <Tabs.Panel value="Records">
                        <AwsRoute53Records resource={resource} />
                    </Tabs.Panel>
                    <Tabs.Panel value="History">
                        <AwsResourceHistory key="History" resourceId={resourceId} resourceType={resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
        case 'Workspace':
            resourceContainer = (
                <Tabs {...tabProps}>
                    <Tabs.List>
                        <Tabs.Tab key="Details" value="Details">
                            Details
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        {/* <Tabs.Tab key="Bundle" value="Bundle">
                            Bundle
                        </Tabs.Tab> */}
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <AwsResourceOverview resource={resource} />
                    </Tabs.Panel>
                    {tagsTab}
                    {/* Bundles are not available due to a missing permission, consider adding this back when it can be tested  */}
                    {/* <Tabs.Panel value="Bundle">
                        <GetResourceBundles key="Bundle" resourceId={resourceId} resourceType={resourceType} />
                    </Tabs.Panel> */}
                    <Tabs.Panel value="History">
                        <AwsResourceHistory key="History" resourceId={resourceId} resourceType={resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
        default:
            resourceContainer = (
                <Tabs {...tabProps}>
                    <Tabs.List>
                        <Tabs.Tab key="Details" value="Details">
                            Details
                        </Tabs.Tab>
                        <Tabs.Tab key="Tags" value="Tags">
                            Tags
                        </Tabs.Tab>
                        {usageTab}
                        <Tabs.Tab key="History" value="History">
                            History
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="Details">
                        <AwsResourceOverview resource={resource} />
                    </Tabs.Panel>
                    {tagsTab}
                    {usageTabPanel}
                    <Tabs.Panel value="History">
                        <AwsResourceHistory key="History" resourceId={resourceId} resourceType={resourceType} />
                    </Tabs.Panel>
                </Tabs>
            );
            break;
    }

    return <ResourceChangeCountCtx.Provider value={changeCt}>{resourceContainer}</ResourceChangeCountCtx.Provider>;
}

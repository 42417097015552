import { Box, Button, Group, Menu, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import { postResourcesQuery, QueryExpr, QueryOperation } from '@apis/Resources';
import { DataFilters, DataFilterModel } from '@root/Components/Filter/Filters';
import { FieldPicker } from '@root/Components/Picker/FieldPicker';
import { exprBuilder, SchemaService, SchemaValueProvider } from '@root/Services/QueryExpr';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDi } from '@root/Services/DI';
import { SchemaFieldNameProvider } from '@root/Components/Filter/Services';
import { Calendar, Plus } from 'tabler-icons-react';
import { IQueryExpr, TagAutomationRule } from '@apis/TagManager/model';
import { RuleEditor, TagLookupService } from '@root/Site/TagManager/TagAutomation/Components/Model';
import { useDisclosure } from '@mantine/hooks';
import { toJS } from 'mobx';

export type ValidationIssues = { total: number } & Partial<Record<'Missing Field' | 'Missing Value' | 'Missing Child Type', number>>;

export const MapFilterResources = observer(function FilterResources({
    filter,
    onChange,
    onValidationIssuesChanged,
    schema,
    showingErrors,
}: {
    filter: QueryExpr[];
    onChange: (filter: QueryExpr[]) => void;
    onValidationIssuesChanged: (issues: ValidationIssues) => void;
    schema: SchemaService;
    showingErrors?: boolean;
}) {
    const company = useCompany();
    const [validationIssues, setValidationIssues] = useState<ValidationIssues>({ total: 0 });
    const schemaValueProvider = useMemo(
        () => new SchemaValueProvider(schema, (query) => postResourcesQuery(query, { companyId: company?.Id })),
        [schema, company?.Id]
    );
    const fieldInfoProvider = useMemo(() => new SchemaFieldNameProvider(schema), [schema]);
    const [filterModel, setFilterModel] = useState<DataFilterModel>();
    const onFilterChange = useCallback(
        (filter: QueryExpr[]) => {
            onChange(filter);
            if (filterModel) {
                const validationIssues = filterModel.getValidationIssues();
                setValidationIssues(
                    validationIssues.reduce(
                        (result, { issue }) => {
                            result[issue] = result && result[issue] ? (result[issue] ?? 0) + 1 : 1;
                            result.total++;
                            return result;
                        },
                        { total: 0 } as ValidationIssues
                    )
                );
            }
        },
        [onChange, filterModel]
    );
    useEffect(() => {
        onValidationIssuesChanged(validationIssues);
    }, [onValidationIssuesChanged, validationIssues, validationIssues['Missing Field'], validationIssues['Missing Value']]);
    const existingResources = useCallback(
        () => filterModel?.addFilters(exprBuilder<any>().createExpr((b) => b.model.CreatedTime.lte(new Date())) as QueryOperation),
        [filterModel]
    );
    const newResources = useCallback(
        () => filterModel?.addFilters(exprBuilder<any>().createExpr((b) => b.model.CreatedTime.gte(new Date())) as QueryOperation),
        [filterModel]
    );

    return (
        <>
            {!!fieldInfoProvider && !!schema && !!schemaValueProvider && (
                <>
                    <DataFilters
                        dataFiltersAsLineItem
                        valueProvider={schemaValueProvider}
                        fieldInfoProvider={fieldInfoProvider}
                        filters={filter}
                        onModelLoaded={setFilterModel}
                        onChange={onFilterChange}
                        showErrors={showingErrors}
                        renderFieldPicker={(select) => (
                            <Box>
                                <FieldPicker mode="single" selections={[]} schema={schema!} onChange={([f]) => select(f.path)} />
                            </Box>
                        )}
                    />
                    <Group>
                        <Button radius="xl" size="xs" color="primary" onClick={() => filterModel?.addEmptyFilter(true, false)} leftIcon={<Plus />}>
                            Add Criteria
                        </Button>
                    </Group>
                </>
            )}
        </>
    );
});

export const MapRuleResourceFilter = observer(function MapRuleResourceFilter({
    ruleEditor,
    onValidationIssuesChanged,
    schema,
    showingErrors,
}: {
    ruleEditor: RuleEditor;
    onValidationIssuesChanged: (issues: ValidationIssues) => void;
    schema: SchemaService;
    showingErrors?: boolean;
}) {
    let rule: TagAutomationRule = ruleEditor.rule!;
    const normalizeFilter = (rule: TagAutomationRule) => {
        let result: QueryExpr[];
        rule.Parameters ??= {};
        rule.Parameters.Filter ??= { Operation: 'and', Operands: (result = []) };
        if (!('Operation' in rule.Parameters.Filter)) {
            rule.Parameters.Filter = { Operation: 'and', Operands: (result = []) };
        } else {
            result = rule.Parameters.Filter.Operands ??= [];
        }
        return result;
    };
    const filter = useMemo(() => normalizeFilter(rule), [rule, rule.Parameters?.Filter]);
    const onChange = useCallback((nextFilter: QueryExpr[]) => {
        filter.splice(0, Infinity, ...nextFilter);
    }, []);
    return (
        <MapFilterResources
            showingErrors={showingErrors}
            schema={schema}
            filter={filter}
            onChange={onChange}
            onValidationIssuesChanged={onValidationIssuesChanged}
        />
    );
});

import styled from '@emotion/styled';
import { Space, Textarea, TextInput, Text } from '@mantine/core';
import { AllocationRuleEditor } from './Model';
import { useState } from 'react';
import { EditorCard } from './Design';

export function RuleNameAndDescription({ ruleEditor }: { ruleEditor: AllocationRuleEditor }) {
    const [name, setName] = useState<string>(ruleEditor.rule?.Name ?? '');
    const [desc, setDesc] = useState<string>(ruleEditor.rule?.RuleDescription ?? '');
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
        ruleEditor.setRuleName(e.target.value);
    };
    const handleDescChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDesc(e.target.value);
        ruleEditor.setRuleDescription(e.target.value);
    };
    return (
        <EditorCard accent>
            <Text sx={{ fontWeight: 'bold' }}>Rule Name</Text>
            <TextInput data-atid="RuleName" value={name} onChange={handleNameChange} maxLength={75} />
            <Space h="md" />
            <Text sx={{ fontWeight: 'bold' }}>Rule Description</Text>
            <ResizeableTextarea>
                <Textarea data-atid="RuleDescription" minRows={1} autosize value={desc} onChange={handleDescChange} maxLength={300} />{' '}
            </ResizeableTextarea>
        </EditorCard>
    );
}

const ResizeableTextarea = styled.div`
    textarea {
        resize: vertical;
    }
`;

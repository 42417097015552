import { QueryExpr, QueryResult } from '@apis/Resources';
import { Query } from '@apis/Resources/model';
import { Card, Group, Switch, Sx, Text, useMantineTheme } from '@mantine/core';
import { InfoIconTooltip } from '@root/Design/Primitives';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { ShowbackPersistence } from '@root/Services/Invoices/ShowbackService';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { NamedFilterSetModel } from '../FilterSetEditor/NamedFilterSetModel';

export function useMemoizedDatasource(datasource: <T>(query: Query) => Promise<QueryResult<T>>, invalidateResults?: EventEmitter<void>) {
    const resultLookup = useMemo(() => {
        const results = new Map<string, Promise<QueryResult<any>>>();
        return {
            results,
            clear: () => results.clear(),
            datasource<T>(query: Query) {
                const key = JSON.stringify(query);
                if (!results.has(key)) {
                    results.set(key, datasource(query));
                }
                return results.get(key) as Promise<QueryResult<T>>;
            },
        };
    }, [datasource]);
    useEvent(invalidateResults, resultLookup.clear);

    return resultLookup;
}

export function useFilterSetExpr(filterSetModel: NamedFilterSetModel) {
    const showbackSvc = useDi(ShowbackPersistence);
    const [queryExpr, setQueryExpr] = useState<QueryExpr>();
    const handleFilterChanged = useCallback(() => {
        let queryExpr: QueryExpr | undefined;
        if (filterSetModel) {
            queryExpr = showbackSvc.getCriteriaForFilterSet(filterSetModel.filterSet) as QueryExpr;
            if (queryExpr) {
                queryExpr = { ...queryExpr };
            }
        }
        setQueryExpr(queryExpr);
    }, [setQueryExpr, filterSetModel]);
    useEffect(() => handleFilterChanged(), [filterSetModel]);
    useEvent(filterSetModel.onFilterChanged, handleFilterChanged);

    return queryExpr;
}

export function useInvoiceDatasource(month: Date, invalidateResults?: EventEmitter<void>) {
    const invoicesApi = useDi(InvoiceApiService);
    const baseDs = useMemo(
        () =>
            function query<T>(query: Query) {
                return invoicesApi.query(query, { from: month, to: month }, false) as Promise<QueryResult<T>>;
            },
        [month]
    );
    const { datasource } = useMemoizedDatasource(baseDs, invalidateResults);
    const datasourceApi = useMemo(() => ({ query: datasource }), [datasource]);
    return { datasource, datasourceApi };
}

import { AppFeatures, Company, CompanyType, Feature } from '@apis/Customers/model';
import { useEffect, useState } from 'react';
import { useEventValue } from '@root/Services/EventEmitter';
import { useDi, useDiMemo } from '@root/Services/DI';
import { SuperUserService } from '@root/Services/Customers/SuperUserService';
import { AppFeatureService } from '@root/Services/Customers/AppFeatureService';
import { UserFeatureAccessService } from '@root/Services/Customers/UserFeatureAccessService';
import { DataMarketplaceApiService, RelationshipBundleService } from '@root/Services/DataMarketplace/DataMarketplaceApiService';

export type NavDataItemChild = {
    label: string;
    link: string;
    disabled: boolean;
    visible: boolean;
    routeMatch?: RegExp;
    atid?: string;
};
export type NavDataItem =
    | {
          name: string;
          icon: string;
          href: string;
          children: NavDataItemChild[];
          footer: boolean;
          routeMatch?: string | RegExp;
          atid?: string;
      }
    | {
          name: string;
          icon: string;
          href: string;
          footer: boolean;
          children?: undefined;
          parentName?: string;
          routeMatch?: string | RegExp;
          atid?: string;
      };

export function useGetNavConfig(rootCompany: Company, selectedCompany: Company | undefined) {
    const appFeatureSvc = useDi(AppFeatureService);
    const userFeatureAccess = useDi(UserFeatureAccessService);
    const mktApiSvc = useDi(DataMarketplaceApiService);

    const superUserSvc = useDiMemo(SuperUserService);
    const isSuperUser = useEventValue(superUserSvc.superUserOn);

    const [rootMenuItems, setRootMenuItems] = useState<NavDataItem[]>([]);
    const [childMenuItems, setChildMenuItems] = useState<NavDataItem[]>();
    const [footerMenuItems, setFooterMenuItems] = useState<NavDataItem[]>([]);
    const [relBundleSvc, setRelBundleSvc] = useState<RelationshipBundleService>();

    useEffect(() => {
        setRootMenuItems([]);
        setChildMenuItems([]);
        setFooterMenuItems([]);
        let disposed = false;
        getCompanyPairMenu(rootCompany, selectedCompany).then((result) => {
            if (disposed) return;
            setRootMenuItems(result.rootCompanyMenu);
            setChildMenuItems(result.selectedCompanyMenu);
            setFooterMenuItems(result.footers);
        });
        return () => void (disposed = true);
    }, [rootCompany, selectedCompany, isSuperUser]);

    return { rootMenuItems, childMenuItems, footerMenuItems };

    function stripSpaces(str: string) {
        return str.replace(/\s/g, '');
    }

    function getChildCompanyUrl(tenantId: number, path: string) {
        const urlParts = [path];
        if (tenantId) {
            urlParts.unshift(`manage-company@id:${tenantId}`);
        }
        return `/${rootCompany.DatabaseName}/${urlParts.join('/')}`;
    }

    function getRootUrl(path: string) {
        return `/${rootCompany.DatabaseName}/${path}`;
    }

    type MenuItemFactory<T> = (name: string, icon: string, href: string, atid: string) => T;
    function createNavMenuItem(name: string, icon: string, href: string, atid: string) {
        return { name, icon, href, atid } as NavDataItem;
    }
    function createChildNavMenuItem(name: string, icon: string, href: string, atid: string) {
        return { label: name, link: href, visible: true, disabled: false, atid } as NavDataItemChild;
    }
    function createMenuItem<T>(itemFactory: MenuItemFactory<T>, name: string, icon: string, path: string, atidPrefix?: string, childCompId?: number) {
        const href = !childCompId ? getRootUrl(path) : getChildCompanyUrl(childCompId, path);
        const atid = atidPrefix + stripSpaces(name);
        return itemFactory(name, icon, href, atid);
    }

    function mapAppNavItem<T>(itemFactory: MenuItemFactory<T>, app: AppFeatures, atidPrefix: string, childTenantId?: number) {
        return createMenuItem(itemFactory, app.Name!, app.Icon!, app.SystemName + '/' + app.HrefPath!, atidPrefix, childTenantId);
    }
    function mapFeatureNavItem<T>(itemFactory: MenuItemFactory<T>, feature: Feature, atidPrefix: string, app: string, childTenantId?: number) {
        const routeMatch = feature.RouteMatch ? new RegExp(feature.RouteMatch) : undefined;
        return { ...createMenuItem(itemFactory, feature.Name!, '', app + '/' + feature.HrefPath!, atidPrefix, childTenantId), routeMatch };
    }
    function createCloudInsightsFeatureNavMapper(relationships: RelationshipBundleService) {
        return function mapCloudInsightsFeatureNavItem<T>(
            itemFactory: MenuItemFactory<T>,
            feature: Feature,
            atidPrefix: string,
            app: string,
            childTenantId?: number
        ) {
            const dataDef = !feature.Name ? null : relationships.getDataDefinitionByName(feature.Name);
            const routeMatch = feature.RouteMatch ? new RegExp(feature.RouteMatch) : undefined;
            const path = app + '/relationship-data@id:' + dataDef?.definition.Id;
            return !dataDef?.options
                ? null
                : {
                      ...createMenuItem(itemFactory, feature.Name!, '', path, atidPrefix, childTenantId),
                      routeMatch,
                  };
        };
    }

    function getFeatureMapper(app: AppFeatures, feature: Feature, relationships: RelationshipBundleService) {
        if (app.SystemName === 'CloudInsights') {
            return createCloudInsightsFeatureNavMapper(relationships);
        } else if (!!feature.HrefPath) {
            return mapFeatureNavItem;
        }
    }

    function getAtIdPrefix(companyType: CompanyType, isFooter: boolean = false) {
        const name =
            companyType === 'Customer'
                ? ''
                : companyType === 'Msp'
                ? 'Msp'
                : companyType === 'StrategicPartner'
                ? 'StrategicPartner'
                : companyType === 'PlatformSupport'
                ? 'Support'
                : companyType === 'Support'
                ? 'Support'
                : '';
        const suffix = isFooter ? '' : 'MainNav';
        const prefix = isFooter ? 'MainNav' : '';
        return prefix + name + suffix;
    }

    function getMyCompaniesMenu(companyType: CompanyType) {
        const endpoint =
            companyType === 'Msp'
                ? 'my-companies'
                : companyType === 'StrategicPartner'
                ? 'customer-requests'
                : companyType === 'PlatformSupport'
                ? 'support-companies'
                : companyType === 'Support'
                ? 'support-companies'
                : '';
        return createMenuItem(createNavMenuItem, 'Companies', 'companies', 'System/' + endpoint, getAtIdPrefix(companyType));
    }

    function getSystemMenuSettings<T>(itemFactory: MenuItemFactory<T>, type: CompanyType, childCompId?: number) {
        const companyTypeSystemSettings: Record<CompanyType, { settings: string; support?: string }> = {
            Customer: { settings: 'settings-overview', support: 'support-home' },
            Msp: { settings: 'msp-settings-overview' },
            StrategicPartner: { settings: 'settings-overview', support: 'support-home' },
            Support: { settings: 'settings-overview', support: 'support-home' },
            PlatformSupport: { settings: 'settings-overview' },
        };

        const typeSettings = companyTypeSystemSettings[type];
        const result = [createMenuItem(itemFactory, 'Settings', 'settings', 'settings/' + typeSettings.settings, getAtIdPrefix(type), childCompId)];
        if (typeSettings.support) {
            result.push(createMenuItem(itemFactory, 'Support', 'support', 'support/' + typeSettings.support, getAtIdPrefix(type), childCompId));
        }
        return result;
    }

    async function tryGetRelationshipSvc(company: Company) {
        try {
            return await mktApiSvc.getBundleService(company);
        } catch {
            return new RelationshipBundleService(company, { Relationships: [], Definitions: [], DataAccess: [] }, mktApiSvc);
        }
    }

    async function getCompanyMenu(company: Company, isChildCompany: boolean, hasChild: boolean, parentCompany?: Company) {
        const appFeatures = appFeatureSvc.getFeatures();
        const relationships = await tryGetRelationshipSvc(selectedCompany ?? rootCompany);
        const userAccess = await userFeatureAccess.getAccessLookup(company, parentCompany);
        const mainNav: NavDataItem[] = [];
        const footers: NavDataItem[] = [];
        const appsAsFeatures: NavDataItemChild[] = [];
        const companyType = company.Type ?? 'Customer';
        const atidPrefix = getAtIdPrefix(companyType);

        for (const app of appFeatures) {
            const features: NavDataItemChild[] = [];
            for (const feature of app.Features ?? []) {
                const mapper = getFeatureMapper(app, feature, relationships);
                if (app.CompanyType === companyType && userAccess.checkAccess(feature.Id!, 'view') && !feature.ParentId && mapper) {
                    const featureNav = mapper(createChildNavMenuItem, feature, atidPrefix, app.SystemName!, isChildCompany ? company.Id : undefined);
                    if (featureNav) {
                        features.push(featureNav);
                    }
                }
            }
            if (features.length > 0 || !app.Features?.length) {
                const mainNavItem = mapAppNavItem(createNavMenuItem, app, atidPrefix, isChildCompany ? company.Id : undefined);
                mainNav.push(mainNavItem);
                if (features.length > 1) {
                    mainNavItem.children = features;
                }
                if (features.map((m) => m.link).indexOf(mainNavItem.href) == -1) {
                    mainNavItem.href = features[0].link;
                }

                appsAsFeatures.push(mapAppNavItem(createChildNavMenuItem, app, atidPrefix, isChildCompany ? company.Id : undefined));
            }
        }

        if (hasChild) {
            const companiesMenu = getMyCompaniesMenu(companyType);
            companiesMenu.children = appsAsFeatures;
            companiesMenu.children.push(...getSystemMenuSettings(createChildNavMenuItem, companyType));
            mainNav.splice(0, Infinity, companiesMenu);
        } else {
            footers.push(...getSystemMenuSettings(createNavMenuItem, companyType, isChildCompany ? company.Id : undefined));
        }

        return [mainNav, footers];
    }

    async function getCompanyPairMenu(rootCompany: Company, selectedCompany?: Company) {
        const companyType = rootCompany?.Type ?? 'Customer';
        const [rootCompanyMenu, footers] = await getCompanyMenu(rootCompany, false, !!selectedCompany);
        let selectedCompanyMenu: NavDataItem[] = [];
        if (companyType !== 'Customer' && selectedCompany) {
            const [childItems, childFooters] = await getCompanyMenu(selectedCompany, true, false, rootCompany);
            selectedCompanyMenu = childItems;
            if (childFooters.length) {
                footers.push(...childFooters);
            }
        }
        return { rootCompanyMenu, selectedCompanyMenu, footers };
    }
}

import { AutoTagOptions } from '@apis/TagManager/model';
import styled from '@emotion/styled';
import { ActionIcon, Box, Group, Space, Text, Tooltip } from '@mantine/core';
import { useDisclosure, useTimeout } from '@mantine/hooks';
import { AnchorButton } from '@root/Design/Primitives';
import { useDi, useDiContainer } from '@root/Services/DI';
import { useIdGen } from '@root/Services/IdGen';
import { TagField } from '@root/Site/TagManager/Components/TagField';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Plus, Trash } from 'tabler-icons-react';
import { injectable } from 'tsyringe';
import { RuleEditCard } from './Design';
import { RuleEditor, TagLookupService } from './Model';

@injectable()
class AutoTagEditor {
    public options: AutoTagOptions[] = [];
    public constructor() {}
    public init(ruleEditor: RuleEditor) {
        const rule = ruleEditor.rule!;
        rule.Parameters ??= {};
        rule.Parameters.Syntax ??= {};
        rule.Parameters.Syntax.AutoTagOptions ??= [{}];
        this.options = rule.Parameters.Syntax.AutoTagOptions;
        return this;
    }

    public removeItem = (item: AutoTagOptions) => {
        const idx = this.options.indexOf(item);
        if (idx >= 0) {
            this.options.splice(idx, 1);
        }
    };
    public addItem() {
        this.options.push({});
    }
    public getErrors() {
        const result: string[] = [];
        const blanks = this.options.filter((o) => !o.KeyToAdd || !o.ValueToAdd).length;
        if (blanks) {
            result.push('Some tags have a blank key or value');
        }
        if (this.options.length === 0) {
            result.push('No tags have been selected');
        }
        return result;
    }
}

export const AddTagsCard = observer(function AddTagsCard({ ruleEditor }: { ruleEditor: RuleEditor }) {
    const di = useDiContainer();
    const typeEditor = useMemo(() => di.resolve(AutoTagEditor).init(ruleEditor), []);
    const { getId } = useIdGen();
    const [errorsShown, { open: showErrors }] = useDisclosure(false);
    const onChange = useCallback(() => {
        ruleEditor.setTypeErrors('AutoTag', typeEditor.getErrors());
    }, [ruleEditor, typeEditor]);
    const addItem = useCallback(() => {
        typeEditor.addItem();
        onChange();
    }, [typeEditor, onChange]);
    useEffect(onChange, []);

    return (
        <RuleEditCard title="Select Tags" description="Add at least one tag to be automatically added to resources" accent onBlur={showErrors}>
            {typeEditor.options.map((o) => (
                <AutoTagItem key={getId(o)} onChange={onChange} onRemove={typeEditor.removeItem} item={o} errorsShown={errorsShown} />
            ))}
            <Space h="md" />
            <AnchorButton size="sm" color="primary" icon={<Plus />} text="Add Another" onClick={addItem} />
        </RuleEditCard>
    );
});

const AutoTagItem = observer(
    ({
        onRemove,
        item,
        errorsShown,
        onChange,
    }: {
        onRemove: (item: AutoTagOptions) => void;
        item: AutoTagOptions;
        errorsShown: boolean;
        onChange: () => void;
    }) => {
        const change = useCallback((..._: any[]) => onChange(), [onChange]);
        const remove = useCallback(() => change(onRemove(item)), [onRemove, item, change]);
        return (
            <ItemLayout>
                <Box>
                    <TagKeyPicker errorsShown={errorsShown} onChange={(v) => change((item.KeyToAdd = v))} value={item.KeyToAdd} />
                </Box>
                <Box>
                    <TagValuePicker
                        errorsShown={errorsShown}
                        tagKey={item.KeyToAdd ?? ''}
                        onChange={(v) => change((item.ValueToAdd = v))}
                        value={item.ValueToAdd}
                    />
                </Box>
                <Box>
                    <Tooltip label="Remove">
                        <ActionIcon onClick={remove}>
                            <Trash />
                        </ActionIcon>
                    </Tooltip>
                </Box>
            </ItemLayout>
        );
    }
);

const ItemLayout = styled.div`
    display: grid;
    grid-template-columns: 250px 250px 40px;
    align-items: center;
    border-radius: 5px;
    background: ${(p) => p.theme.colors.primary[1]};
    margin: 4px 0;
    width: min-content;
    padding: 5px 10px;
`;

function TagKeyPicker({ value, onChange, errorsShown }: { value?: null | string; onChange: (value: string | null) => void; errorsShown: boolean }) {
    const tagSvc = useDi(TagLookupService);
    const setSelected = useCallback(
        (_: number, value: string) => {
            onChange(value);
        },
        [onChange]
    );
    return (
        <TagField
            label="Key"
            error={errorsShown && !value}
            canAdd={false}
            canRemove={false}
            fieldValue={value ?? ''}
            setSelectedValue={setSelected}
            valuePickList={tagSvc.keys}
            placeholder="Select"
        />
    );
}

function TagValuePicker({
    value,
    onChange,
    tagKey,
    errorsShown,
}: {
    value?: null | string;
    onChange: (value: string | null) => void;
    tagKey: string;
    errorsShown: boolean;
}) {
    const tagSvc = useDi(TagLookupService);
    const setSelected = useCallback(
        (_: number, value: string) => {
            onChange(value);
        },
        [onChange]
    );
    const [options, setOptions] = useState<string[]>([]);
    const { clear, start } = useTimeout(() => tagSvc.getValues(tagKey).then(setOptions), 600, { autoInvoke: true });
    useEffect(() => {
        clear();
        if (tagKey) {
            start();
        }
    }, [tagKey]);
    return (
        <TagField
            label="Value"
            error={errorsShown && !value}
            canAdd={false}
            canRemove={false}
            fieldValue={value ?? ''}
            setSelectedValue={setSelected}
            valuePickList={options}
            placeholder="Select"
        />
    );
}

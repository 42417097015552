import { PageContent, PageSection } from '@root/Design/Layout';
import { endpoint } from '@root/Services/Router/EndpointRegistry';

export function Analytics() {
    return (
        <PageContent>
            <PageSection>Welcome to Resources Analytics Page</PageSection>
        </PageContent>
    );
}
endpoint('analytics', Analytics, 'Analytics');

/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Resources.Host.Api
 * OpenAPI spec version: 1.0
 */

export type GetConnectionScriptsCsCloudformationYmlProductsItem = typeof GetConnectionScriptsCsCloudformationYmlProductsItem[keyof typeof GetConnectionScriptsCsCloudformationYmlProductsItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetConnectionScriptsCsCloudformationYmlProductsItem = {
  Base: 'Base',
  TagManager: 'TagManager',
  InvoiceExplorer: 'InvoiceExplorer',
  InvoiceManager: 'InvoiceManager',
  IdleResources: 'IdleResources',
  RightSizing: 'RightSizing',
  MapManager: 'MapManager',
} as const;

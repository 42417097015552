import React from 'react';
import { Card, Group, Stack, Title, Text } from '@mantine/core';
import { FormatService } from '../../../Services/FormatService';
import { useDi } from '../../../Services/DI';

export interface CreditFeeCardsProps {
    creditsUsed?: number;
    creditUsageFees?: number;
    selectedDate?: string;
}

export const CreditFeeCards = (props: CreditFeeCardsProps) => {
    var formatter = new Intl.NumberFormat('en-US', {});
    const formatSvc = useDi(FormatService);

    return (
        <>
            <Group position="center" style={{ display: 'flex', alignItems: 'stretch' }}>
                {props.creditsUsed != undefined && (
                    <Card withBorder radius="lg" p="lg" style={{ flex: 1 }}>
                        <Stack spacing={2}>
                            <Title order={5}>Total Credits Consumed</Title>
                            <Title order={2}>{formatter.format(props.creditsUsed ?? 0)}</Title>
                            <Text color="dimmed">{props.selectedDate}</Text>
                        </Stack>
                    </Card>
                )}
                {props.creditUsageFees != undefined && (
                    <Card withBorder radius="lg" p="lg" style={{ flex: 1 }}>
                        <Stack spacing={2}>
                            <Title order={5}>Total Usage Fees</Title>
                            <Title order={2}>{formatSvc.formatMoney(props.creditUsageFees ?? 0)}</Title>
                            <Text color="dimmed">{props.selectedDate}</Text>
                        </Stack>
                    </Card>
                )}
            </Group>
        </>
    );
};

import { getAppGetAppsAndFeatures, getUserFacingPermissionGetUserFacingPermissions } from '@apis/Customers';
import { AppFeatures, CompanyType, Feature, UserFacingPermissionInfo } from '@apis/Customers/model';
import { singleton } from 'tsyringe';

@singleton()
export class AppFeatureService {
    private appFeatures: AppFeatures[] = [];
    private appByFeatureId: Map<number, [AppFeatures, Feature]> = new Map();
    private userFacingPermissions: UserFacingPermissionInfo[] = [];
    private ufpByFeatureId: Map<number, { [verb: string]: UserFacingPermissionInfo }> = new Map();
    private featureTypeLookup: Map<string, Feature> = new Map();

    public async init() {
        this.appFeatures = await getAppGetAppsAndFeatures();
        this.appFeatures.sort((a, b) => (a.DisplayOrder ?? 0) - (b.DisplayOrder ?? 0));
        for (const app of this.appFeatures) {
            app.Features?.sort((a, b) => (a.DisplayOrder ?? 0) - (b.DisplayOrder ?? 0));
        }
        this.userFacingPermissions = await getUserFacingPermissionGetUserFacingPermissions();
        this.appByFeatureId = this.appFeatures
            .flatMap((app) => (app.Features ?? []).map((feat) => [app, feat]))
            .reduce((map, [app, feat]) => map.set(feat.Id ?? 0, [app, feat]), new Map());

        this.featureTypeLookup = this.appFeatures
            .flatMap((app) => (app.Features ?? []).map((feat) => [`${feat.CompanyType}/${feat.Name}`.toLocaleLowerCase(), feat]))
            .reduce((map, [key, feat]) => map.set(key, feat), new Map());

        this.ufpByFeatureId = this.userFacingPermissions.reduce((map, ufp) => {
            if (ufp.FeatureId && ufp.Verb) {
                const verb = ufp.Verb?.toLocaleLowerCase();
                if (!map.has(ufp.FeatureId)) {
                    map.set(ufp.FeatureId, {});
                }
                map.get(ufp.FeatureId)![verb] = ufp;
            }
            return map;
        }, new Map<number, { [verb: string]: UserFacingPermissionInfo }>());
    }

    public getFeature(featureId: number) {
        return this.appByFeatureId.get(featureId);
    }

    public getFeatureByTypeAndName(companyType: CompanyType, featureName: string) {
        return this.featureTypeLookup.get(`${companyType}/${featureName}`.toLocaleLowerCase());
    }

    public getFeatures() {
        return this.appFeatures;
    }

    public getFlatFeatures() {
        return [...this.appByFeatureId.values()].sort(
            ([appA, featA], [appB, featB]) =>
                (appA.DisplayOrder ?? 0) - (appB.DisplayOrder ?? 0) || (featA.DisplayOrder ?? 0) - (featB.DisplayOrder ?? 0)
        );
    }

    public getUfPermissions(): ReadonlyArray<UserFacingPermissionInfo> {
        return this.userFacingPermissions;
    }

    public getUfp(featureId: number, verb: string = 'view') {
        verb = verb.toLocaleLowerCase();
        return this.ufpByFeatureId.get(featureId)?.[verb];
    }
}

import { CompanyType } from '@apis/Customers/model/companyType';
import { getConnectionGetCliConnectionInfo, getConnectionTryAssumeRole, postConnectionGetPendingConnection } from '@apis/Resources';
import { CliConnectionInfo, PendingCliConnection } from '@apis/Resources/model';
import { Box, Button, Divider, Group, Space, Tabs, Text } from '@mantine/core';
import { useCompany } from '@root/Components/Router/CompanyContent';
import {
    WizardForm,
    WizardFormStep,
    WizardFormTitle,
    LoadingValidation,
    ScriptTextArea,
    WizardFormFooter,
    LoadingState,
    WizardFormStepContainer,
} from '@root/Components/Wizard/WizardForm';
import { PageContent } from '@root/Design/Layout';
import { ConfigService } from '@root/Services/ConfigService';
import { useDi } from '@root/Services/DI';
import { useNav } from '@root/Services/NavigationService';
import { BasicRouteLoader } from '@root/Services/Router/BasicRouteLoader';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { Link } from '@root/Services/Router/Links';
import { ConnectionWizardFooterText, ConnectionWizardStepper, WizardHeader } from './Common';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CspConnectionService } from '@root/Services/Connections/CspConnectionService';

export function ConnectCli() {
    const { platform } = useNav().getData('platform');
    return (
        <PageContent>
            <WizardHeader platform={platform!} />
            <WizardForm>
                <ConnectionWizardStepper step={3} platform={platform!} />
                <WizardFormTitle title={'Connect to AWS Using CLI'}></WizardFormTitle>
                <WizardFormStepContainer>
                    <ConnectCliContent />
                </WizardFormStepContainer>
            </WizardForm>
        </PageContent>
    );
}
endpoint('connect-cli', ConnectCli, 'Connection Wizard');

export function ConnectCliContent({
    hideFooter,
    onReadyChanged,
    ...props
}: {
    hideFooter?: boolean;
    platform?: string;
    onReadyChanged?: (ready: boolean, accountId: string) => void;
}) {
    const platform = props.platform ?? useNav().getData('platform')?.platform;
    const { getMoveUrl } = useNav();
    const configSvc = useDi(ConfigService);
    const company = useCompany();

    const [connState, setConnState] = useState<LoadingState>(LoadingState.loading);
    const [connMsg, setConnMsg] = useState<string>('Awaiting connection');
    const [cliScriptWin, setCliScriptWin] = useState<string>('');
    // const [cliScriptMac, setCliScriptMac] = useState<string>('');
    const [cliScriptLinux, setCliScriptLinux] = useState<string>('');
    const cspConnSvc = useDi(CspConnectionService);
    const connChecker = useMemo(
        () => ({
            connInfo: null as CliConnectionInfo | null,
            pendingConn: null as PendingCliConnection | null,
            shouldPoll: true,
            poll() {
                setTimeout(async () => {
                    if (!this.shouldPoll) {
                        return;
                    }
                    try {
                        if (this.connInfo) {
                            if (this.pendingConn && this.pendingConn.Complete) {
                                setConnState(LoadingState.check);
                                setConnMsg('Connection successful');
                                this.shouldPoll = false;
                                cspConnSvc.connectionStateChanged.emit();
                            } else if (this.pendingConn && this.pendingConn.MasterAccount && this.pendingConn.ExternalId) {
                                const assumeResult = await getConnectionTryAssumeRole({
                                    awsAccountId: this.pendingConn.MasterAccount,
                                    externalId: this.pendingConn.ExternalId,
                                });
                                if (assumeResult?.Success) {
                                    setConnState(LoadingState.check);
                                    setConnMsg('Connection successful');
                                    this.shouldPoll = false;
                                }
                            } else {
                                this.pendingConn = await postConnectionGetPendingConnection({ token: this.connInfo.Token! });
                            }
                        }
                    } finally {
                        this.poll();
                    }
                }, 3000);
            },
            stopPolling() {
                this.shouldPoll = false;
            },
        }),
        []
    );

    useEffect(() => {
        (async () => {
            const baseUrl = configSvc.config.apis['Resources'].baseUrl;
            const connInfo = await getConnectionGetCliConnectionInfo();
            connChecker.connInfo = connInfo;
            const [, creds] = (connInfo.Creds ?? '').split(' ');
            const [name, value] = creds.replace(/"/g, '').split('=');
            const domain = new URL(baseUrl).hostname;
            setCliScriptWin(
                `$session=New-Object Microsoft.PowerShell.Commands.WebRequestSession; $cookie=New-Object System.Net.Cookie; $cookie.Name='${name}'; $cookie.Domain='${domain}'; $cookie.Value='${value}'; $session.Cookies.Add($cookie); iwr ${baseUrl}/connection/scripts/${
                    connInfo.Token
                }/${platform?.toLowerCase()}-cs-connector.ps1 -WebSession $session -OutFile ${platform?.toLowerCase()}-cs-connector.ps1;  .\\${platform?.toLowerCase()}-cs-connector.ps1`
            );
            const linux = `bash <(curl ${connInfo.Creds} -sL ${baseUrl}/connection/scripts/${
                connInfo.Token
            }/${platform?.toLowerCase()}-cs-connector.sh)`;
            setCliScriptLinux(linux);
            // setCliScriptMac(linux);
        })();
    }, []);

    useEffect(() => {
        connChecker.poll();
        return () => connChecker.stopPolling();
    }, []);

    useEffect(() => {
        onReadyChanged?.(connState === LoadingState.check, connChecker.pendingConn?.MasterAccount ?? '');
    }, [connState === LoadingState.check]);

    return (
        <>
            <WizardFormStep title="1. Copy and paste the script into your preferred CLI" infoText="Bash or Powershell" infoSize="small">
                <Space h="md" />
                <Tabs defaultValue="windows">
                    <Tabs.List>
                        <Tabs.Tab value="windows">Windows</Tabs.Tab>
                        {/* <Tabs.Tab value="macos">MacOS</Tabs.Tab> */}
                        <Tabs.Tab value="linux">Linux</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="windows">
                        <ScriptTextArea value={cliScriptWin} label="script area" minRows={3} maxRows={4} />
                    </Tabs.Panel>
                    {/* <Tabs.Panel value="macos">
            <ScriptTextArea value={cliScriptMac} label="script area" minRows={3} maxRows={4} />
        </Tabs.Panel> */}
                    <Tabs.Panel value="linux">
                        <ScriptTextArea value={cliScriptLinux} label="script area" minRows={3} maxRows={4} />
                    </Tabs.Panel>
                </Tabs>
            </WizardFormStep>
            <Divider />
            <WizardFormStep
                title="2. CloudSaver will detect when the script is finished"
                subTitle="We'll check for the script to run and for accounts to be connected."
            >
                <Box ml="lg">
                    <LoadingValidation loadingState={connState} text={connMsg}></LoadingValidation>
                </Box>
            </WizardFormStep>
            {hideFooter ? null : (
                <>
                    <Divider />
                    <WizardFormFooter>
                        <Group position="apart">
                            <ConnectionWizardFooterText company={company!} />
                            <Button
                                component={Link}
                                href={getMoveUrl('connections')}
                                disabled={connState !== LoadingState.check}
                                style={{ pointerEvents: connState !== LoadingState.check ? 'none' : undefined }}
                            >
                                View Connections
                            </Button>
                        </Group>
                    </WizardFormFooter>
                </>
            )}
        </>
    );
}

import { getConnectionGetAwsPolicyVersion } from '@apis/Resources';
import { AwsPolicyVersionInfo } from '@apis/Resources/model';
import styled from '@emotion/styled';
import { Box, Card, Divider, Grid, Group, Loader, Space, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { useDi, useDiContainer } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useCompany } from '../Router/CompanyContent';
import { WizardDownload } from '../Wizard/WizardForm';
import { IacConnector, LoadingState } from './IacConnector';

export function AwsReconnector({ accountId, onConnectionStateChange }: { accountId: string; onConnectionStateChange: (connected: boolean) => void }) {
    const di = useDiContainer();
    const fmtSvc = useDi(FormatService);
    const iacConn = useMemo(() => di.resolve(IacConnector), []);
    const company = useCompany();
    const [{ Version, Date: policyDate }, setVersionInfo] = useState<AwsPolicyVersionInfo>({});

    useEffect(() => {
        getConnectionGetAwsPolicyVersion().then(setVersionInfo);
    }, []);

    useEffect(() => {
        iacConn.updateAccount(accountId, company!.UniqueId!);
    }, [accountId]);
    useEffect(() => {
        return () => {
            iacConn.dispose();
        };
    }, []);
    useEffect(() => {
        onConnectionStateChange?.(iacConn.isConnected());
    }, [iacConn.isConnected()]);

    return (
        <Card p={0} withBorder radius="md">
            <Box p="lg">
                <Text>Download the latest policy template</Text>
                <Text size="sm" color="dimmed">
                    Policy Version: {Version}, Last Updated: {policyDate}
                </Text>
                <Space h="lg" />
                <Group position="center">
                    <WizardDownload
                        imgLocation="/assets/aws-cloudformation.svg"
                        displayTitle="CloudFormation"
                        displayText="Download YAML"
                        fileLocation={iacConn.getDownloadUrl('cs-cloudformation.yml')}
                        disable={!iacConn.isAccountValid()}
                        disabledText="Account ID is Required"
                    ></WizardDownload>
                    <WizardDownload
                        imgLocation="/assets/terraformio-icon.svg"
                        displayTitle="Terraform"
                        displayText="Download .tf"
                        fileLocation={iacConn.getDownloadUrl('cs-terraform.tf')}
                        disable={!iacConn.isAccountValid()}
                        disabledText="Account ID is Required"
                    ></WizardDownload>
                </Group>
            </Box>
            <Divider />
            <Box p="lg">
                <Text>Checking for connectivity changes</Text>
                <Space h="lg" />
                <Group ml="sm" position="apart">
                    <LoadingValidation loadingState={iacConn.getLoadingState() as LoadingState} text={iacConn.getStatusMessage()}></LoadingValidation>
                </Group>
            </Box>
        </Card>
    );
}

function LoadingValidation(props: { loadingState: LoadingState; text: string }) {
    let icon: ReactNode;
    if (props.loadingState == LoadingState.loading) {
        icon = <Loader color="primary.6" variant="dots" size="sm" mr="sm" />;
    } else if (props.loadingState == LoadingState.success) {
        icon = <i className="ti ti-check"></i>;
    } else if (props.loadingState == LoadingState.failed) {
        icon = <i className="ti ti-x"></i>;
    } else if (props.loadingState == LoadingState.check) {
        icon = <i className="ti ti-circle-check-filled"></i>;
    }

    return (
        <LoadingValidationContainer>
            <Text color={props.loadingState === LoadingState.loading ? 'gray' : undefined}>
                {icon} {props.text}
            </Text>
        </LoadingValidationContainer>
    );
}

const LoadingValidationContainer = styled.div`
    span {
        font-size: 14px;
    }

    i {
        margin-right: ${(p) => p.theme.spacing.xs}px;
        font-size: 20px;
    }

    .ti-check,
    .ti-circle-check {
        color: green;
    }

    .ti-x {
        color: red;
    }
`;

import { IQueryExpr } from '@apis/Customers/model';
import { postResourcesSearch, QueryExpr } from '@apis/Resources';
import { AwsEffectiveTagPolicyStatement } from '@apis/Resources/model';
import { DataGridState } from '../DataGrid/Models';

type complianceObject = { compliance: Array<string>; noncompliance: Array<string> };

export async function awsTagPolicyComplianceCounts({ statement, companyID }: { statement: AwsEffectiveTagPolicyStatement; companyID: number }) {
    var inComplianceIdList: Array<string> = [];
    var notInComplianceIdList: Array<string> = [];

    inComplianceIdList = await getCompliance(statement.Filter, statement.ComplianceRule, companyID);
    notInComplianceIdList = await getCompliance(statement.Filter, statement.ComplianceRule, companyID, true);

    return { compliance: inComplianceIdList, noncompliance: notInComplianceIdList } as complianceObject;
}

export async function azureTagPolicyComplianceCounts(filter: IQueryExpr, rule: IQueryExpr, companyID: number) {
    var inComplianceIdList: Array<string> = [];
    var notInComplianceIdList: Array<string> = [];

    // Note: Azure uses if/then to perform action, so if the rule evaluates to true,
    // it is actually non-compliant and will take action, I.E. deny. So, inCompliance
    // evaluation is 'not'ed.
    inComplianceIdList = await getCompliance(filter, rule, companyID, true);
    notInComplianceIdList = await getCompliance(filter, rule, companyID);

    return { compliance: inComplianceIdList, noncompliance: notInComplianceIdList } as complianceObject;
}

const getCompliance = async (filter: IQueryExpr | undefined, rule: IQueryExpr | undefined, companyId: number | undefined, not: boolean = false) => {
    var idList: Array<string> = [];

    const inComplianceState: DataGridState = {
        columns: [],
        filters: [
            {
                Operation: 'And',
                Operands: not
                    ? [
                          { Operation: 'And', Operands: [filter] },
                          { Operation: 'Not', Operands: [rule] },
                      ]
                    : [filter, rule],
            },
        ],
        sort: [],
    };
    var { where } = await getQuery(inComplianceState);
    var response = await postResourcesSearch({ Where: where, Take: 100000 }, { companyId: companyId });
    response.Results?.map((m) => {
        idList.push(m.Id ?? '');
    });
    return idList;
};

async function getQuery(state: DataGridState) {
    const { filters } = state;
    const where: QueryExpr = { Operation: 'and', Operands: filters?.length ? filters : [] };

    const sort = [];
    sort.push({ Direction: 'Asc', Expr: { Field: 'Id' } });
    return { where: where.Operands.length ? where : undefined, sort };
}

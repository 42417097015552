import { Company } from '@apis/Customers/model';
import { postExportExport } from '@apis/Export';
import { ExportRequest, ExportRequestTarget, ExportRequestType } from '@apis/Export/model';
import { Query } from '@apis/Invoices/model';
import { QueryExpr, QueryResult } from '@apis/Resources';
import { ThemeIcon } from '@mantine/core';
import { FillerSwitch } from '@root/Design/Filler';
import { ICompanyContextToken } from '@root/Services/Customers/CompanyContext';
import { useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { IDailyRollup, InvoiceSchemaService } from '@root/Services/Invoices/InvoiceSchemaService';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { FieldInfo, SchemaService, SchemaValueProvider, ValuesGroupOtherText } from '@root/Services/QueryExpr';
import { format } from 'date-fns';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { FileSpreadsheet, Reload } from 'tabler-icons-react';
import { inject, injectable } from 'tsyringe';
import { ActivityPanelModel } from '../Actions/ActivityPanel/Models';
import { DataGrid } from '../DataGrid';
import { DataGridModel } from '../DataGrid/DataGridModel';
import { FieldInfoColumnAdapter } from '../DataGrid/FieldInfoColumnAdapter';
import { ColumnConfig, DataGridState, GridGroupByState, IDataSource, ISelectionStrategy } from '../DataGrid/Models';
import { QueryExprGroupHelper } from '../DataGrid/QueryExprGroupHelper';
import { IValueProviderFactory } from '../Filter/Filters';

interface InvoiceApi {
    query: <T>(query: Query, dateRange: { from?: Date; to?: Date }, appendRangeCheck?: boolean) => Promise<QueryResult<T>>;
}

export function DailyInvoiceGrid({
    persistenceKey,
    invoiceApi,
    onFilterChange,
    scope,
    dateRange,
    defaultGroupBy,
    rightPlaceholder,
    field,
    filtersDisabled,
    onSelectionChanged,
    selectionStrategy,
    renderRowSelector,
    disabledSavedViews,
    maxLineItems,
    costField,
    filters,
}: {
    persistenceKey: string;
    invoiceApi: InvoiceApi;
    onFilterChange?: (filter: QueryExpr[]) => void;
    scope?: QueryExpr;
    dateRange: { from?: Date; to?: Date };
    defaultGroupBy: GridGroupByState[];
    rightPlaceholder?: ReactNode;
    field: string;
    filtersDisabled?: boolean;
    onSelectionChanged?: (selectionState: { getItems: () => Promise<DailyInvoiceRow[]> }) => void;
    selectionStrategy?: ISelectionStrategy<DailyInvoiceRow>;
    renderRowSelector?: (
        item: DailyInvoiceRow | null,
        selectionState: { selected?: boolean; some?: boolean; all?: boolean; toggle: (selected: boolean) => void },
        isHeader: boolean
    ) => ReactNode;
    disabledSavedViews?: boolean;
    maxLineItems?: number;
    costField?: string;
    filters?: QueryExpr[];
}) {
    const container = useDiContainer();

    const model = useMemo(
        () => container.resolve(DailyInvoicesGridModel).setDefaultGroupBy(defaultGroupBy).setCostField(costField!).init(field, invoiceApi),
        []
    );

    useEffect(
        () =>
            model
                .setDateRange(dateRange.from, dateRange.to)
                .setCostField(costField!)
                .setOnFilterChanged(onFilterChange)
                .setScope(scope)
                .setMaxLineItems(maxLineItems)
                .setFilters(filters!)
                .reload(),
        [dateRange, scope, onFilterChange, maxLineItems]
    );

    useEffect(() => {
        model.selectionEnabled = !!selectionStrategy;
    }, [selectionStrategy]);
    const selectionProps = onSelectionChanged ? { selectionMode: 'multiple' as 'multiple', onSelectedChanged: onSelectionChanged } : {};

    useEffect(() => {
        model.setCostField(costField!);
    }, [costField]);

    const initializing = useEventValue(model.initializing);

    return (
        <FillerSwitch loading={initializing}>
            {() => (
                <DataGrid
                    statePersistence={persistenceKey ? { key: persistenceKey } : undefined}
                    columns={model.columns}
                    dataSource={model.datasource!}
                    filterValueProvider={model.filterValueProvider!}
                    onModelLoaded={model.attach}
                    onRowClick={model.onGridRowClick}
                    schemaSvc={model.schema}
                    state={model.state!}
                    childAccessor={model.childAccessor}
                    export={model.export}
                    allowNonColumnFilters
                    indentLeafNodes
                    hideGlobalSearch
                    allowGroupBy
                    allowMultiSort
                    groupByAsRows
                    groupByRequired={1}
                    groupByDisableSort
                    allowSavedViews={disabledSavedViews ? false : true}
                    rightToolPlaceHolder={rightPlaceholder}
                    hideFilterOnly={filtersDisabled}
                    selectionStrategy={selectionStrategy}
                    {...selectionProps}
                    renderRowSelector={renderRowSelector}
                    minimumLoadingMs={0}
                />
            )}
        </FillerSwitch>
    );
}

export type DailyInvoiceRow = {
    parent?: DailyInvoiceRow;
    value?: string;
    usage?: number;
    rate?: number;
    cost?: number;
    depth: number;
    field?: FieldInfo;
    nullValue: boolean;
} & IDailyRollup;

@injectable()
export class DailyInvoicesGridModel {
    private invoiceApi!: InvoiceApi;
    private gridModel?: DataGridModel;
    private dateRange?: { from?: Date; to?: Date } = {};
    private maxLineItems?: number;
    private filter?: QueryExpr;
    private groupQueryHelper?: QueryExprGroupHelper<DailyInvoiceRow>;
    private defaultGroupBy: GridGroupByState[] = [];
    private onFilterChanged?: (filter: QueryExpr[]) => void;

    public initializing = new EventEmitter(true);
    public selectionEnabled = false;
    public datasource?: IDataSource<DailyInvoiceRow>;
    public renderKey = new EventEmitter(0);
    public columns: ColumnConfig<DailyInvoiceRow>[] = [];
    public defaultState?: DataGridState;
    public state?: DataGridState;
    public schema?: SchemaService;
    public filterValueProvider?: IValueProviderFactory;
    private costField?: string;
    public filters?: QueryExpr[];

    public constructor(
        @inject(FormatService) private readonly formatSvc: FormatService,
        @inject(InvoiceSchemaService) private readonly invoiceSchemaSvc: InvoiceSchemaService,
        @inject(NotificationService) private readonly notificationSvc: NotificationService,
        @inject(FieldInfoColumnAdapter) private readonly fieldInfoColumnAdapter: FieldInfoColumnAdapter<DailyInvoiceRow>,
        @inject(ICompanyContextToken) private readonly company: Company,
        @inject(ActivityPanelModel) private readonly activityPanelModel: ActivityPanelModel
    ) {}

    public init(field: string, invoiceApi: InvoiceApi) {
        this.invoiceApi = invoiceApi;
        this.initialize(field ?? '');
        return this;
    }

    public setDateRange(from?: Date, to?: Date) {
        if (this.dateRange?.from !== from || this.dateRange?.to !== to) {
            this.dateRange = { from, to };
        }
        return this;
    }

    public setMaxLineItems(maxLineItems: number | undefined) {
        if (this.maxLineItems !== maxLineItems) {
            this.maxLineItems = maxLineItems;
        }
        return this;
    }

    public setFilters(filters: QueryExpr[] | undefined) {
        if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
            this.filters = filters;
        }
        return this;
    }

    public setOnFilterChanged(onFilterChanged?: (filter: QueryExpr[]) => void) {
        if (this.onFilterChanged !== onFilterChanged) {
            this.onFilterChanged = onFilterChanged;
        }
        return this;
    }

    public setCostField(costField: string) {
        this.costField = costField;
        (costField === 'AdjustedCashCost' || costField === 'AdjustedAmortizedCost') && this.gridModel?.addColumnById(`Sum(Common.${this.costField})`);
        return this;
    }

    public setScope(filter?: QueryExpr) {
        if (JSON.stringify(filter) !== JSON.stringify(this.filter)) {
            this.filter = filter;
        }
        return this;
    }

    public reload() {
        this.gridModel?.refresh();
    }

    public setDefaultGroupBy(defaultGroupBy: GridGroupByState[]) {
        this.defaultGroupBy = defaultGroupBy;
        return this;
    }

    public async initialize(field: string) {
        try {
            this.initializing.emit(true);
            const types = await this.invoiceSchemaSvc.getSchema();
            this.schema = new SchemaService(types);
            this.filterValueProvider = new SchemaValueProvider(this.schema, (q) => {
                if (this.filter) {
                    q.Where = q.Where ? { Operation: 'and', Operands: [q.Where, this.filter] } : this.filter;
                }
                return this.invoiceApi.query(q, this.dateRange ?? {}, false);
            });
            this.schema.resolveChildren();
            this.groupQueryHelper = this.createGroupQueryHelper(field ?? '');
            this.datasource = this.createDatasource();
            this.columns = this.createColumns();
            this.defaultState = this.createDefaultState(field ?? '');
            this.state = this.createState(field ?? '');
        } finally {
            this.initializing.emit(false);
        }
    }

    public attach = (gridModel: DataGridModel) => {
        this.gridModel = gridModel;
        this.groupQueryHelper?.attach(gridModel);
        this.gridModel.beforeApplyColumns = (columns) => {
            const valueColumn = gridModel.gridState?.columns.find((c) => c.id === 'value');
            if (valueColumn && !columns.some((c) => c.id === 'value')) {
                columns.unshift(valueColumn);
            }
        };
        this.gridModel.modifyColumnSelectorOptions = (options) => {
            options.selections.splice(0, Infinity, ...options.selections.filter((s) => s.column?.id !== 'value'));
        };
        this.gridModel.gridStateChanged.listen((change) => {
            if (change?.changes.has('filters')) {
                this.onFilterChanged?.(change.state.filters as QueryExpr[]);
            }
        });
        (this.costField === 'AdjustedCashCost' || this.costField === 'AdjustedAmortizedCost') &&
            this.gridModel?.addColumnById(`Sum(Common.${this.costField})`);
    };

    public export = async () => {
        const openActivity = this.activityPanelModel.toggleRequested.emit;
        try {
            const name = this.gridModel?.configName ?? 'Invoice Details';
            const config = this.gridModel?.getDashboardConfigForExport(name, 'Invoice Details', this.filter ? [this.filter] : undefined);
            if (config) {
                const requestBody: ExportRequest = {
                    CompanyId: this.company.Id,
                    Target: ExportRequestTarget.DailyInvoiceQuery,
                    Type: ExportRequestType.Excel,
                    DashboardConfig: config,
                    TargetParameters: {
                        From: this.dateRange?.from ? this.formatSvc.toJsonShortDate(this.dateRange.from) : undefined,
                        To: this.dateRange?.to ? this.formatSvc.toJsonShortDate(this.dateRange.to) : undefined,
                    },
                };
                await postExportExport(requestBody);
                this.notificationSvc.notify(
                    'Export Invoice Details',
                    `Export Requested. Click to check the activity log for download.`,
                    'primary',
                    <ThemeIcon variant="light" size="xl" radius="xl">
                        <FileSpreadsheet />
                    </ThemeIcon>,
                    openActivity
                );
            }
        } catch {
            this.notificationSvc.notify(
                'Export Failed',
                `Error: Export failed. Click to check the activity log for details.`,
                'error',
                null,
                openActivity
            );
        }
    };

    public onGridRowClick = (row: DailyInvoiceRow) => {
        if (this.selectionEnabled) {
            this.gridModel?.setSelected(row, !this.gridModel?.isSelected(row));
        } else {
            this.gridModel?.treeModel?.toggle(row);
        }
    };

    public childAccessor = {
        hasChildren: (row: DailyInvoiceRow) => row.depth < (this.gridModel!.gridState.groupBy?.length ?? 1),
    };

    private createDefaultState(field: string) {
        return {
            columns: [
                { id: 'value', width: 300, fixed: true },
                { id: field == 'BilledDate' ? 'Common.BilledDate' : 'Common.UsageStartDate', width: 130 },
                { id: 'product.product/region', width: 130 },
                { id: 'lineItem.lineItem/ResourceId', width: 300 },
                { id: 'lineItem.lineItem/UsageAmount', width: 130 },
                { id: 'lineItem.lineItem/UnblendedRate', width: 160 },
                { id: 'Sum(lineItem.lineItem/UnblendedCost)', width: 160 },
            ],
            filters: this.filters ? this.filters : [],
            sort: [{ Direction: 'Desc', Expr: { Field: 'Sum(lineItem/UnblendedCost)' } }],
            groupBy: this.defaultGroupBy,
        } as DataGridState;
    }

    public createState(field: string) {
        const defaultState = this.createDefaultState(field ?? '') as DataGridState;
        (this.costField === 'AdjustedCashCost' || this.costField === 'AdjustedAmortizedCost') &&
            defaultState.columns.push({ id: `Sum(Common.${this.costField})`, width: 160 });
        return defaultState;
    }

    private createColumns() {
        const result = this.schema!.rootTypeInfo.flatMap((t) => {
            return (
                t.fields?.flatMap((f) => {
                    const column = this.fieldInfoColumnAdapter.adapt(f);
                    if (column.type === 'string' || column.type === 'date') {
                        column.allowGrouping = true;
                    }
                    if (column.type === 'number') {
                        column.aggregations = ['Sum', 'Average', 'Min', 'Max'];
                    }
                    return column;
                }) ?? []
            );
        });
        const ValueCell = createDescriptionCell(this.formatSvc);
        result.push({
            accessor: 'value',
            defaultWidth: 300,
            id: 'value',
            defaultFixed: true,
            header: 'Description',
            noRemove: true,
            noReorder: true,
            type: 'string',
            cellRenderer: (item) => <ValueCell row={item} />,
        });
        return result;
    }

    private createDatasource() {
        return {
            getPage: async (start, end, state, parent) => {
                const rangeCriteria: QueryExpr[] = [];
                return this.groupQueryHelper?.getPage(
                    start,
                    end,
                    state,
                    parent,
                    rangeCriteria.length ? { Operation: 'and', Operands: rangeCriteria } : undefined
                );
            },
        } as IDataSource<DailyInvoiceRow>;
    }

    private createGroupQueryHelper(field: string) {
        return new QueryExprGroupHelper<DailyInvoiceRow>(
            (q) => this.runFlatQuery(q),
            (q, depth) => this.runGroupQuery(q, depth),
            undefined,
            undefined,
            [{ Direction: 'Asc', Expr: { Field: field == 'BilledDate' ? 'BilledDate' : 'UsageStartDate' } }],
            { count: null, value: 'value' }
        );
    }

    private async runGroupQuery(query: Query, depth: number) {
        if (query.Select && query.Select.length) {
            query = { ...query };
        }
        this.addCriteria(query);
        const result = await this.invoiceApi.query<DailyInvoiceRow>(query, this.dateRange ?? {}, false);
        const fieldId = this.gridModel?.gridState?.groupBy?.[depth]?.id;
        const [_, ...fieldPath] = fieldId?.split('.') ?? [];
        const field = fieldId ? this.schema?.getField(fieldPath.join('.')) : undefined;
        result.Results?.forEach((r) => {
            r.depth = depth;
            r.field = field;
            r.nullValue = r.value === ValuesGroupOtherText;
        });
        return result;
    }

    private runFlatQuery(query: Query) {
        query.Sort ??= [];
        query.Sort.push({ Direction: 'Asc', Expr: { Field: 'UniqueId' } });
        if (this.maxLineItems) {
            query.Take = this.maxLineItems;
        }
        this.addCriteria(query);
        return this.invoiceApi.query<DailyInvoiceRow>(query, this.dateRange ?? {}, false);
    }

    private addCriteria(query: Query) {
        const where = [] as (typeof query)['Where'][];
        if (query.Where) {
            where.push(query.Where);
        }
        if (this.filter) {
            where.push(this.filter);
        }
        if (this.dateRange?.from) {
            where.push({
                Operation: 'eq',
                Operands: [{ Field: 'UsageMonth' }, { Value: format(this.dateRange.from, 'yyyy-MM') }],
            });
        }
        query.Where = where.length ? { Operation: 'and', Operands: where } : undefined;
    }
}

function createDescriptionCell(fmtSvc: FormatService) {
    return function DescriptionCell({ row }: { row: DailyInvoiceRow }) {
        const value =
            row.value === ValuesGroupOtherText
                ? 'Other'
                : !row.field
                ? row.value
                : row.field.typeName === 'date'
                ? fmtSvc.toShortDate(fmtSvc.parseDateNoTime(row.value ?? ''))
                : row.value;
        return <>{value ?? ''}</>;
    };
}

import { Tag } from '@apis/Resources/model';
import {
    ActionIcon,
    Anchor,
    Badge,
    Box,
    Button,
    Card,
    Divider,
    Grid,
    Group,
    Input,
    Popover,
    Space,
    Text,
    TextInput,
    Tooltip,
    useMantineTheme,
} from '@mantine/core';
import { isValidValue } from '@root/Components/Resources/Tags/TagValidation';
import { useDi, useDiContainer } from '@root/Services/DI';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TagField } from '@root/Site/TagManager/Components/TagField';
import { TagAutomationRuleService } from '@root/Site/TagManager/TagAutomation/Components/TagAutomationRuleService';
import { observer } from 'mobx-react';
import { RuleEditCard } from './Design';
import { TagAutomationRule, TagAutomationRuleParameters } from '@apis/TagManager/model';
import { ReplacementOptions } from '@apis/TagManager/model/replacementOptions';
import { RuleEditor, TagLookupService } from './Model';
import { makeAutoObservable } from 'mobx';
import { useId, useIdGen } from '@root/Services/IdGen';
import { injectable } from 'tsyringe';
import { useDisclosure, useTimeout } from '@mantine/hooks';
import styled from '@emotion/styled';
import { Plus, Trash } from 'tabler-icons-react';
import { AnchorButton } from '@root/Design/Primitives';

@injectable()
class ReplacementEditor {
    public replacementOptions: ReplacementOptions[] = [];
    public constructor() {}
    public init(ruleEditor: RuleEditor) {
        const rule = ruleEditor.rule!;
        rule.Parameters ??= {};
        rule.Parameters.Syntax ??= {};
        rule.Parameters.Syntax.ReplacementOptions ??= [{}];
        this.replacementOptions = rule.Parameters.Syntax.ReplacementOptions;
        return this;
    }

    public removeItem = (item: ReplacementOptions) => {
        const idx = this.replacementOptions.indexOf(item);
        if (idx >= 0) {
            this.replacementOptions.splice(idx, 1);
        }
    };
    public addItem = () => {
        this.replacementOptions.push({});
    };
    public getErrors() {
        const result: string[] = [];
        const blanks = this.replacementOptions.filter((o) =>
            [o.KeyToCheck, o.KeyToReplace, o.ValueToCheck, o.ValueToReplace].some((r) => r === undefined || r === '')
        );
        if (blanks.length) {
            result.push('Some tag replacement rules are not filled in');
        }
        if (!this.replacementOptions.length) {
            result.push('No tag replacement rules have been set');
        }
        return result;
    }
}

export const ReplaceTagsCard = observer(({ ruleEditor }: { ruleEditor: RuleEditor }) => {
    const di = useDiContainer();
    const typeEditor = useMemo(() => di.resolve(ReplacementEditor).init(ruleEditor), []);
    const { getId } = useIdGen();
    const onChange = useCallback(() => {
        ruleEditor.setTypeErrors('Replacement', typeEditor.getErrors());
    }, [ruleEditor, typeEditor]);
    const addItem = useCallback(() => {
        typeEditor.addItem();
        onChange();
    }, [typeEditor.addItem]);
    useEffect(onChange, []);
    const [showingErrors, { open: showErrors }] = useDisclosure(false);
    return (
        <RuleEditCard title="Replace Tags" description="Add rules for finding and replacing tags" accent onBlur={showErrors}>
            {typeEditor.replacementOptions.map((o) => (
                <ReplacementRuleItem item={o} showingErrors={showingErrors} onRemove={typeEditor.removeItem} onChange={onChange} key={getId(o)} />
            ))}
            <AnchorButton size="sm" color="primary" icon={<Plus />} text="Add Another" onClick={addItem} data-atid="AddRuleButton" />
        </RuleEditCard>
    );
});

const ReplacementRuleItem = observer(
    ({
        onRemove,
        item,
        onChange,
        showingErrors,
    }: {
        onRemove: (item: ReplacementOptions) => void;
        item: ReplacementOptions;
        onChange: () => void;
        showingErrors: boolean;
    }) => {
        const theme = useMantineTheme();
        const change = useCallback(
            (..._: any[]) => {
                onChange();
            },
            [onChange]
        );
        const remove = useCallback(() => change(onRemove(item)), [onRemove, item, change]);
        const error = useCallback((value: string | null | undefined) => showingErrors && (value === undefined || value === ''), [showingErrors]);
        return (
            <Card mb="md" p="xs" withBorder sx={{ width: 'min-content', background: theme.colors.gray[1] }} radius="md">
                <OptionLayout>
                    <Text>
                        <></>
                    </Text>
                    <Text size="sm" align="center">
                        Key
                    </Text>
                    <Text size="sm" align="center">
                        Value
                    </Text>
                </OptionLayout>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box>
                        <OptionLayout>
                            <Text size="sm" component="span" align="right" mr="lg">
                                Find
                            </Text>
                            <FieldsBox bg={theme.colors.gray[3]}>
                                <TagKeyPicker
                                    error={error(item.KeyToCheck)}
                                    value={item.KeyToCheck}
                                    onChange={(v) => change((item.KeyToCheck = v))}
                                />
                            </FieldsBox>
                            <FieldsBox bg={theme.colors.gray[3]}>
                                <TagValuePicker
                                    error={error(item.ValueToCheck)}
                                    value={item.ValueToCheck}
                                    onChange={(v) => change((item.ValueToCheck = v))}
                                    tagKey={item.KeyToCheck ?? ''}
                                />
                            </FieldsBox>
                        </OptionLayout>
                        <Space h={2} />
                        <OptionLayout>
                            <Text size="sm" component="span" align="right" mr="lg">
                                Replace with
                            </Text>
                            <FieldsBox bg={theme.colors.primary[2]}>
                                <TagKeyPicker
                                    error={error(item.KeyToReplace)}
                                    value={item.KeyToReplace}
                                    onChange={(v) => change((item.KeyToReplace = v))}
                                />
                            </FieldsBox>
                            <FieldsBox bg={theme.colors.primary[2]}>
                                <TagValuePicker
                                    error={error(item.ValueToReplace)}
                                    value={item.ValueToReplace}
                                    onChange={(v) => change((item.ValueToReplace = v))}
                                    tagKey={item.KeyToReplace ?? ''}
                                />
                            </FieldsBox>
                        </OptionLayout>
                    </Box>
                    <RemoveBox>
                        <Tooltip label="Remove Replacement" withinPortal position="right">
                            <ActionIcon onClick={remove}>
                                <Trash />
                            </ActionIcon>
                        </Tooltip>
                    </RemoveBox>
                </Box>
            </Card>
        );
    }
);

const RemoveBox = styled.div`
    margin-left: 4px;
    align-self: stretch;
    display: flex;
    align-items: center;
    background: ${(p) => p.theme.colors.gray[2]};
    border-radius: 5px;
    padding: 5px;
`;

const FieldsBox = styled.div<{ bg: string }>`
    padding: 5px 4px 0 4px;
    &:first-of-type {
        border-radius: 5px 0 0 5px;
    }
    &:last-child {
        border-radius: 0 5px 5px 0;
    }
    &:first-of-type:last-child {
        border-radius: 5px;
    }

    background: ${(p) => p.bg};
`;

const OptionLayout = styled.div`
    display: grid;
    grid-template-columns: 120px 250px 250px;
    align-items: center;
`;

function AnyBadge({ onClick }: { onClick?: () => void }) {
    return (
        <Badge size="md" sx={{ cursor: 'pointer' }} variant="outline" color="gray" onClick={onClick}>
            Any
        </Badge>
    );
}

function TagKeyPicker({ value, onChange, error }: { value?: null | string; onChange: (value: string | null) => void; error?: boolean }) {
    const tagSvc = useDi(TagLookupService);
    const setSelected = useCallback(
        (_: number, value: string) => {
            onChange(value);
        },
        [onChange]
    );
    const anyClick = useCallback(() => onChange(null), [onChange]);
    return (
        <TagField
            label=""
            error={error}
            canAdd={false}
            canRemove={false}
            fieldValue={value ?? ''}
            setSelectedValue={setSelected}
            valuePickList={tagSvc.keys}
            placeholder={value !== null ? 'Select' : undefined}
        />
    );
}

function TagValuePicker({
    value,
    onChange,
    tagKey,
    error,
}: {
    value?: null | string;
    onChange: (value: string | null) => void;
    tagKey: string;
    error?: boolean;
}) {
    const tagSvc = useDi(TagLookupService);
    const setSelected = useCallback(
        (_: number, value: string) => {
            onChange(value);
        },
        [onChange]
    );
    const [options, setOptions] = useState<string[]>([]);
    const { clear, start } = useTimeout(() => tagSvc.getValues(tagKey).then(setOptions), 600, { autoInvoke: true });
    const anyClick = useCallback(() => onChange(null), [onChange]);
    useEffect(() => {
        clear();
        if (tagKey) {
            start();
        }
    }, [tagKey]);
    return (
        <TagField
            label=""
            error={error}
            canAdd={false}
            canRemove={false}
            fieldValue={value ?? ''}
            setSelectedValue={setSelected}
            valuePickList={options}
            placeholder={value !== null ? 'Select' : undefined}
            dropdownTopSection={
                <>
                    <Box px="md" py="xs">
                        <AnyBadge onClick={anyClick} />
                    </Box>
                    <Divider />
                </>
            }
            icon={value === null ? <AnyBadge /> : undefined}
            iconWidth={80}
        />
    );
}

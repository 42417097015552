import { NamedFilter, NamedFilterSet } from '@apis/Invoices/model';
import { QueryExpr, QueryOperation } from '@apis/Resources';
import { EventEmitter } from '@root/Services/EventEmitter';

type NamedFilterSetKeys<T> = { [K in keyof T]: T[K] extends NamedFilterSet | null | undefined ? K : never }[keyof T];
type NfsOwner<T> = T extends Record<NamedFilterSetKeys<T>, NamedFilterSet> ? T : never;

export interface INamedFilterModel {
    nameChanged: EventEmitter<string>;
    getName(): string;
    setName(value: string): void;
    getFilters(): QueryExpr[];
    setFilters(value: QueryExpr[]): void;
    raiseFilterChanged(): void;
    remove(): void;
    getIndex(): number;
}

export interface ISingleFilterNamedSet {
    getFilters(): QueryExpr[];
    setFilters(value: QueryExpr[]): void;
    onFilterChanged: EventEmitter<void>;
}

export interface IRulesModel {
    addItem(item?: NamedFilter): INamedFilterModel;
    getOrAdd(): INamedFilterModel;
    remove(idx: number): void;
    removeItem(item: INamedFilterModel): void;
    get items(): ReadonlyArray<INamedFilterModel>;
    get onSetChanged(): EventEmitter<void>;
}

class RulesModel implements IRulesModel {
    private readonly filterData: NamedFilter[] = [];
    private readonly _items: (INamedFilterModel & { original: NamedFilter })[] = [];
    public get items(): ReadonlyArray<INamedFilterModel> {
        return this._items;
    }

    public readonly onSetChanged = EventEmitter.empty();

    public constructor(private readonly onFilterChanged: () => void, filterData: NamedFilter[]) {
        this._items = filterData.map((f) => RulesModel.createModel(this, f));
        this.filterData = filterData;
    }

    private raiseSetChanged() {
        this.filterData.splice(0, Infinity, ...this._items.map((i) => i.original));
        this.onSetChanged.emit();
    }
    private raiseFilterChanged() {
        this.onFilterChanged();
    }

    private static createModel(owner: RulesModel, filter: NamedFilter): INamedFilterModel & { original: NamedFilter } {
        if (!filter.Filter) {
            filter.Filter = { Operation: 'and', Operands: [] };
        }
        const standardFilter = filter.Filter as QueryExpr;

        const andOperation: QueryOperation =
            'Operation' in standardFilter && standardFilter.Operation?.toLocaleLowerCase() === 'and'
                ? standardFilter
                : { Operation: 'and', Operands: [filter.Filter] };
        filter.Filter = andOperation;

        const result: INamedFilterModel & { original: NamedFilter } = {
            nameChanged: new EventEmitter<string>(filter.Name ?? ''),
            original: filter,
            getName() {
                return filter.Name ?? '';
            },
            setName(value: string) {
                if (value !== filter.Name) {
                    filter.Name = value;
                    result.nameChanged.emit(value);
                }
            },
            getFilters() {
                return andOperation.Operands as QueryExpr[];
            },
            setFilters(value: QueryExpr[]) {
                andOperation.Operands.splice(0, Infinity, ...(value ?? []));
                owner.raiseFilterChanged();
            },
            raiseFilterChanged() {
                owner.raiseFilterChanged();
            },
            remove() {
                owner.removeItem(result);
            },
            getIndex() {
                return owner.items.indexOf(result);
            },
        };

        return result;
    }
    public getOrAdd = () => {
        if (this._items.length === 0) {
            return this.addItem();
        }
        return this._items[0];
    };
    public addItem = (item?: NamedFilter) => {
        const result = RulesModel.createModel(this, item ?? { Name: '' });
        this._items.push(result);
        this.raiseSetChanged();
        return result as INamedFilterModel;
    };
    public remove = (idx: number) => {
        this._items.splice(idx, 1);
        this.raiseSetChanged();
        this.raiseFilterChanged();
    };
    public removeItem = (item: INamedFilterModel) => {
        const idx = this.items.indexOf(item);
        if (idx !== -1) {
            this.remove(idx);
        }
    };
}

export class NamedFilterSetModel {
    public onFilterChanged = EventEmitter.empty();
    public readonly inclusionRules: IRulesModel;
    public readonly exclusionRules: IRulesModel;

    public get filterSet(): NamedFilterSet {
        return this.namedFilterSet;
    }

    private constructor(private readonly namedFilterSet: NamedFilterSet) {
        this.inclusionRules = new RulesModel(this.onFilterChanged.emit, (this.namedFilterSet.InclusionRules ??= []));
        this.exclusionRules = new RulesModel(this.onFilterChanged.emit, (this.namedFilterSet.ExclusionRules ??= []));
    }

    public isEmpty() {
        return !this.inclusionRules.items.length && !this.exclusionRules.items.length;
    }

    public static create<TOwner>(owner: TOwner, filterSetProp: NamedFilterSetKeys<TOwner>, copy: boolean = false): NamedFilterSetModel {
        const validOwner = owner as NfsOwner<TOwner>;
        if (!owner[filterSetProp]) {
            const defaultFilterSet: NamedFilterSet = {};
            (owner as any)[filterSetProp] = defaultFilterSet;
        }
        if (!validOwner[filterSetProp].InclusionRules) {
            validOwner[filterSetProp].InclusionRules = [];
        }
        if (!validOwner[filterSetProp].ExclusionRules) {
            validOwner[filterSetProp].ExclusionRules = [];
        }
        if (copy) {
            owner[filterSetProp] = NamedFilterSetModel.copy(owner[filterSetProp]);
        }
        return new NamedFilterSetModel(owner[filterSetProp]);
    }

    private static copy(obj: any): any {
        return JSON.parse(JSON.stringify(obj));
    }

    /**
     * Create a simple getter/setter for a NamedFilterSet property, bindable to a DataFilter component
     * @param owner a model that has any NamedFilterSet properties
     * @param filterSetProp the name of the prop to get a model for
     */
    public static createSimpleSet<TOwner>(owner: TOwner, filterSetProp: NamedFilterSetKeys<TOwner>): ISingleFilterNamedSet {
        const baseModel = NamedFilterSetModel.create(owner, filterSetProp);
        const inclusionRules = baseModel.inclusionRules;
        const rule = inclusionRules.items[0] ?? inclusionRules.addItem();

        return {
            getFilters() {
                return rule.getFilters();
            },
            setFilters(value) {
                rule.setFilters(value);
            },
            onFilterChanged: baseModel.onFilterChanged,
        };
    }
}

import {
    Accordion,
    ActionIcon,
    Autocomplete,
    Box,
    Button,
    Center,
    CloseButton,
    Divider,
    Group,
    MultiSelect,
    Popover,
    Progress,
    Space,
    Table,
    Text,
    Textarea,
    TextInput,
    useMantineTheme,
} from '@mantine/core';
import { PagePanel, PanelBody, PanelContent, PaneledPage, PanelHeader, PanelToolbar } from '@root/Design/Layout';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import {
    deleteRbacDeleteRole,
    getAccountGetAccounts,
    getUserFacingPermissionGetUserFacingPermissions,
    postRbacSaveRole,
    postUserFacingPermissionGetRoleUserFacingPermissions,
    postUserFacingPermissionSaveRoleUserFacingPermissions,
} from '@apis/Customers';
import { DataGrid } from '@root/Components/DataGrid';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { DataColumnConfig } from '@root/Components/DataGrid/Models';
import {
    Account,
    Company,
    CompanyRoleListItem,
    CompanyType,
    Feature,
    Role,
    RoleTag,
    RoleUserFacingPermission,
    UserFacingPermissionInfo,
} from '@apis/Customers/model';
import { makeAutoObservable, reaction, toJS } from 'mobx';
import { observer, Observer } from 'mobx-react';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { inject, injectable } from 'tsyringe';
import { useDi, useDiContainer } from '@root/Services/DI';
import { openConfirmModal } from '@mantine/modals';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { SettingsPage, SettingsPageHeader } from './SettingsPage';
import { Picker } from '@root/Components/Picker/Picker';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { CompanyRoleService } from './Users/CompanyRoleService';
import styled from '@emotion/styled';
import { ICompanyFeatureLookup, useCompanyFeatureSvc } from '@root/Services/Customers/CompanyFeatureService';
import { User } from 'tabler-icons-react';
import { AppFeatureService } from '@root/Services/Customers/AppFeatureService';
import { TenantCacheInvalidator } from '@root/Services/Customers/TenantCacheInvalidator';

export function UserRoles() {
    const theme = useMantineTheme();
    const userPermissions = useAuthZValues({
        canManage: { Permissions: 'Manage' },
    });
    const company = useCompany();
    const companyRoleSvc = useDi(CompanyRoleService);
    const [roles, setRoles] = useState<CompanyRoleListItem[]>();
    const [loading, setLoading] = useState(true);
    const [selectedRole, setSelectedRole] = useState<CompanyRoleListItem>();
    const [mode, setMode] = useState<'edit' | 'add'>();
    const { getFeatures } = useCompanyFeatureSvc();
    const [appFeatures, setAppFeatures] = useState<ICompanyFeatureLookup>();

    const loadRoles = () => {
        companyRoleSvc.getRoleList().then((roles) => {
            setRoles(roles);
            if (selectedRole) {
                setSelectedRole(roles.find((r) => r.Role?.Id === selectedRole.Role?.Id));
            }
        });
    };

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                loadRoles();
                setAppFeatures(await getFeatures());
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const addRole = useCallback(() => {
        setMode('add');
        setSelectedRole({
            Role: { CompanyId: company?.Id, Accounts: [], Description: '', Name: '', Tags: [], Id: 0, CompanyType: company?.Type ?? 'Customer' },
            UserFacingPermissions: [],
        });
    }, [setSelectedRole]);

    const setRoleDetailEditMode = (item: CompanyRoleListItem | undefined) => {
        if (item) {
            setMode('edit');
            setSelectedRole(item);
        }
    };
    const columns = useMemo(
        () =>
            [
                {
                    id: 'Members',
                    accessor: 'Members',
                    header: 'Members',
                    headerRenderer: () => (
                        <Center sx={{ width: '100%' }}>
                            <User size={20} strokeWidth={0} fill={theme.colors.gray[7]} />
                        </Center>
                    ),
                    defaultWidth: 50,
                    align: 'center',
                    type: 'number',
                    filter: {
                        filterType: 'number',
                        name: 'Members',
                        filterField: 'Members',
                    },
                },
                {
                    id: 'Name',
                    accessor: (r) => r.Role?.Name + ' ' + r.Role?.Description,
                    header: 'Name',
                    defaultWidth: 300,
                    cellRenderer: (r) => (
                        <Box sx={{ alignItems: 'center', display: 'flex', height: '100%' }}>
                            <div>
                                <Text weight="bold">{r.Role?.Name}</Text>
                                <Text size="xs">{r.Role?.Description}</Text>
                            </div>
                        </Box>
                    ),
                    type: 'string',
                    sortField: 'Name',
                    filter: {
                        filterField: 'Name',
                        filterType: 'string',
                        name: 'Name',
                        options: {
                            getValueProvider: () => roles?.map((r) => ({ value: r.Role?.Name + ' ' + r.Role?.Description, label: r.Role?.Name })),
                        },
                    },
                },
                {
                    id: 'Tags',
                    accessor: (item) => item.Role?.Tags?.length ?? Infinity,
                    formatter: (_, value) => (value === Infinity ? 'Any' : value),
                    defaultWidth: 100,
                    header: 'Tags',
                    sortField: 'Tags',
                    type: 'number',
                    align: 'center',
                    exportOptions: {
                        renderer: (r) => r.Role?.Tags?.join(', ') || 'Any',
                    },
                },
                {
                    id: 'Accounts',
                    accessor: (item) => item.Role?.Accounts?.length ?? Infinity,
                    formatter: (_, value) => (value === Infinity ? 'All' : value),
                    defaultWidth: 100,
                    header: 'Accounts',
                    sortField: 'Accounts',
                    type: 'number',
                    align: 'center',
                    exportOptions: {
                        renderer: (r) => r.Role?.Accounts?.join(', ') || 'All',
                    },
                },
            ] as DataColumnConfig<CompanyRoleListItem>[],
        [roles]
    );

    return (
        <SettingsPage>
            <PaneledPage>
                <PagePanel size="fill">
                    <SettingsPageHeader text="Roles & Permissions">
                        {userPermissions.canManage && !selectedRole ? (
                            <Button data-atid="AddRoleButton" onClick={addRole}>
                                Add Role
                            </Button>
                        ) : (
                            <></>
                        )}
                    </SettingsPageHeader>

                    <PanelBody>
                        {loading || !roles ? (
                            <Progress />
                        ) : (
                            <DataGrid
                                selectionMode="single"
                                onRowClick="select"
                                onSelectionChanged={setRoleDetailEditMode}
                                selection={selectedRole}
                                displayMode="list"
                                itemHeight={60}
                                dataSource={roles!}
                                columns={columns}
                                exportName="Roles"
                            />
                        )}
                    </PanelBody>
                    <Divider />
                </PagePanel>
                {!selectedRole || !appFeatures ? null : (
                    <>
                        <Divider orientation="vertical" />
                        <PagePanel size="md">
                            <PanelHeader>
                                <Box>
                                    <Text data-atid="PanelHeader" size={20}>
                                        {mode === 'add' ? 'Add Role' : `Edit Role`}
                                    </Text>
                                    {selectedRole?.Role && !selectedRole?.Role?.CompanyId ? (
                                        <Text color="dimmed" size="sm">
                                            Readonly - System Role
                                        </Text>
                                    ) : null}
                                </Box>
                                <CloseButton mr="lg" onClick={() => setSelectedRole(undefined)} />
                            </PanelHeader>
                            <RoleDetails
                                onSave={loadRoles}
                                onDelete={loadRoles}
                                onCancel={() => setSelectedRole(undefined)}
                                role={selectedRole}
                                mode={mode}
                                appFeatures={appFeatures}
                            />
                        </PagePanel>
                    </>
                )}
            </PaneledPage>
        </SettingsPage>
    );
}
endpoint('user-roles', UserRoles, 'User Roles');

@injectable()
class RoleDetailsModel {
    public isLoading = true;
    public role: Role = {};
    public userFacingPermissions: UserFacingPermissionInfo[] = [];
    public roleUserFacingPermissions: RoleUserFacingPermission[] = [];
    public accounts: Account[] = [];
    public company: Company = {};
    public isReadonly = true;
    public isDirty = false;
    public features: Feature[] = [];
    public selectedAccounts = new Set<number>();
    public roleTags: RoleTag[] = [];
    public tagKeys: string[] = [];
    public appFeatures: ICompanyFeatureLookup | undefined;
    public featureChildren = new Map<number, Feature[]>();
    private ufpByFeature: Map<number, UserFacingPermissionInfo[]> = new Map();

    private disposeRole?: () => void;

    public constructor(
        @inject(ResourceService) private readonly resourceSvc: ResourceService,
        @inject(TenantCacheInvalidator) private readonly cacheInvalidator: TenantCacheInvalidator
    ) {
        makeAutoObservable(this);
    }

    public init(appFeatures: ICompanyFeatureLookup, company: Company) {
        this.company = company;
        this.appFeatures = appFeatures;
        this.featureChildren = appFeatures.allAppFeatures
            .flatMap((ap) => ap.Features)
            .reduce((acc, f) => {
                if (f?.ParentId) {
                    if (!acc.has(f.ParentId)) {
                        acc.set(f.ParentId, []);
                    }
                    acc.get(f.ParentId)!.push(f);
                }
                return acc;
            }, new Map<number, Feature[]>());
        for (const featureSet of this.featureChildren.values()) {
            featureSet.sort((a, b) => (a.DisplayOrder ?? 0) - (b.DisplayOrder ?? 0));
        }
        this.loadCompany(this.company);
        return this;
    }

    public async loadRole(nextRole: CompanyRoleListItem) {
        this.isLoading = true;
        try {
            this.isDirty = false;
            this.disposeRole?.();
            const role = makeAutoObservable({
                Name: '',
                Description: '',
                CompanyId: this.company?.Id,
                Accounts: [],
                Tags: [],
                CompanyType: this.company?.Type,
                ...nextRole.Role,
            });
            this.role = role;
            this.selectedAccounts.clear();
            if (role.Accounts) {
                for (const account of role.Accounts) {
                    this.selectedAccounts.add(account);
                }
            }
            const tags = role.Tags ? role.Tags : [];
            this.roleTags.splice(0, Infinity, ...tags);
            this.isReadonly = !role.CompanyId;

            if (role.Id) {
                const roleUserFacingPermissions = await postUserFacingPermissionGetRoleUserFacingPermissions([role.Id]);
                this.roleUserFacingPermissions.splice(0, Infinity, ...roleUserFacingPermissions.map((cf) => makeAutoObservable(cf)));
            } else {
                this.roleUserFacingPermissions.splice(0, Infinity);
            }
            this.disposeRole = reaction(
                () => toJS(this.role) && toJS(this.roleUserFacingPermissions),
                () => (this.isDirty = true)
            );
        } finally {
            this.isLoading = false;
        }
    }

    public save = async () => {
        this.role.Accounts = [...this.selectedAccounts];
        this.role.Tags = toJS(this.roleTags);
        const result = await postRbacSaveRole(toJS(this.role));

        var ids = this.roleUserFacingPermissions.map((m) => m.UserFacingPermissionId!) ?? [];
        await postUserFacingPermissionSaveRoleUserFacingPermissions(ids, { roleId: result.Id });

        this.cacheInvalidator.invalidate(this.company.Id ?? 0);

        return result;
    };

    public delete = async () => {
        await deleteRbacDeleteRole({ roleId: this.role.Id });
    };

    public async loadCompany(company?: Company) {
        this.userFacingPermissions = await getUserFacingPermissionGetUserFacingPermissions();
        const accounts = await getAccountGetAccounts();
        this.accounts.splice(0, Infinity, ...accounts);
        this.role.CompanyType = company?.Type;
        this.tagKeys = await this.resourceSvc.getTags();
        this.ufpByFeature.clear();
    }

    public getFeatureSections() {
        const companyType = this.company.Type ?? 'Customer';
        const results: CompanyType[] = [companyType];
        if (companyType === 'Msp') {
            results.push('Customer');
        }
        return results;
    }

    public getValidFeatures(companyType: CompanyType) {
        return this.appFeatures?.allAppFeatures.filter((f) => f.CompanyType === companyType) ?? [];
    }

    public permissionHasVerb(feature: Feature, verb: string) {
        return this.userFacingPermissions.filter((f) => f.FeatureId === feature.Id && f.Verb == verb).length > 0;
    }

    public hasVerbs(feature: Feature) {
        return !!this.getFeatureUfps(feature)?.length;
    }

    public getFeatureUfps(feature: Feature | number) {
        const featureId = typeof feature === 'number' ? feature : feature.Id ?? 0;
        if (!this.ufpByFeature.has(featureId)) {
            this.ufpByFeature.set(
                featureId,
                this.userFacingPermissions.filter((f) => f.FeatureId === featureId)
            );
        }
        return this.ufpByFeature.get(featureId) ?? [];
    }

    public isFeatureSelected(feature: Feature, verb: string) {
        const ufp = this.userFacingPermissions.find((f) => f.FeatureId === feature.Id && f.Verb == verb);
        if (ufp) {
            return this.roleUserFacingPermissions.some((f) => f.UserFacingPermissionId == ufp.Id);
        } else {
            return false;
        }
    }

    public toggleFeature(feature: Feature, verb: string) {
        if (this.isReadonly) {
            return;
        }

        const ufp = this.userFacingPermissions.find((f) => f.FeatureId === feature.Id && f.Verb == verb);
        if (this.isFeatureSelected(feature, verb)) {
            let rufpToRemove = this.roleUserFacingPermissions.find((f) => f.RoleId == this.role.Id && f.UserFacingPermissionId == ufp!.Id);
            this.roleUserFacingPermissions = this.removeVerb(this.roleUserFacingPermissions, rufpToRemove!);

            if (verb == 'View') {
                this.checkRemovePermission(feature, 'Manage');
                this.checkRemovePermission(feature, 'Execute');
            } else if (verb == 'Manage') {
                this.checkRemovePermission(feature, 'Execute');
            }
        } else {
            this.roleUserFacingPermissions.push(makeAutoObservable(this.createUserFacingPermission(ufp!.Id!)));

            if (verb == 'Execute') {
                this.checkAddPermission(feature, 'Manage');
                this.checkAddPermission(feature, 'View');
            } else if (verb == 'Manage') {
                this.checkAddPermission(feature, 'View');
            }
        }
    }

    private checkRemovePermission(feature: Feature, verb: string) {
        if (this.isFeatureSelected(feature, verb)) {
            const ufpExecute = this.userFacingPermissions.find((f) => f.FeatureId === feature.Id && f.Verb == verb);
            let rufpToRemove = this.roleUserFacingPermissions.find((f) => f.RoleId == this.role.Id && f.UserFacingPermissionId == ufpExecute!.Id);
            this.roleUserFacingPermissions = this.removeVerb(this.roleUserFacingPermissions, rufpToRemove!);
        }
    }
    private checkAddPermission(feature: Feature, verb: string) {
        if (!this.isFeatureSelected(feature, verb)) {
            const ufpView = this.userFacingPermissions.find((f) => f.FeatureId === feature.Id && f.Verb == verb);
            this.roleUserFacingPermissions.push(makeAutoObservable(this.createUserFacingPermission(ufpView!.Id!)));
        }
    }

    public removeVerb(arr: RoleUserFacingPermission[], value: RoleUserFacingPermission) {
        return arr.filter(function (ele) {
            return ele != value;
        });
    }

    public selectAccounts(accounts: Account[]) {
        this.selectedAccounts.clear();
        for (const account of accounts) {
            this.selectedAccounts.add(account.Id!);
        }
        this.isDirty = true;
    }

    public getSelectedAccounts() {
        const result: Account[] = [];
        for (const acct of this.accounts) {
            if (this.selectedAccounts.has(acct.Id!)) {
                result.push(acct);
            }
        }
        return result;
    }

    private createUserFacingPermission(ufpId: number) {
        return {
            RoleId: this.role.Id,
            UserFacingPermissionId: ufpId,
        } as RoleUserFacingPermission;
    }

    public getTagValues(key: string) {
        return this.resourceSvc.getTagValues(key);
    }

    public addTag = () => {
        this.roleTags.push(makeAutoObservable({ Key: '', Values: [] }));
        this.isDirty = true;
    };

    public removeTag(tag: RoleTag) {
        const idx = this.roleTags.indexOf(tag);
        if (idx >= 0) {
            this.roleTags.splice(idx, 1);
            this.isDirty = true;
        }
    }

    public updateTagValues(tag: RoleTag, values: string[]) {
        tag.Values = values;
        this.isDirty = true;
    }
    public updateTagKey(tag: RoleTag, key: string) {
        tag.Key = key;
        this.isDirty = true;
    }
}

const PermissionHeaderCell = ({
    text,
    width,
    textAlign,
    first,
    last,
}: {
    text: string;
    width: number;
    textAlign: string;
    first: boolean;
    last: boolean;
}) => {
    return (
        <HeaderCell textAlign={textAlign} width={width + '%'} first={first} last={last}>
            {text}
        </HeaderCell>
    );
};

const PermissionRow = observer(
    ({
        model,
        feature,
        doIndent,
        showPermissionCells,
    }: {
        model: RoleDetailsModel;
        feature: Feature;
        doIndent?: boolean;
        showPermissionCells?: boolean;
    }) => {
        const popoverText = createPopover(feature);
        return (
            <tr>
                <GridCell first={true} last={false} textAlign="left" style={{ width: 200, paddingLeft: doIndent ? 35 : 10 }}>
                    <>
                        {popoverText != null ? (
                            <Popover width={450}>
                                <Popover.Target>
                                    <div style={{ cursor: 'pointer' }}>{feature.Name}</div>
                                </Popover.Target>
                                <Popover.Dropdown style={{ borderWidth: '5px', borderRadius: '10px', borderColor: 'lightgray' }}>
                                    {popoverText}
                                </Popover.Dropdown>
                            </Popover>
                        ) : (
                            <>{feature.Name}</>
                        )}
                    </>
                </GridCell>
                <GridCell first={false} last={false} textAlign="center">
                    {showPermissionCells ? <PermissionCell model={model} feature={feature} verb={'View'} /> : null}
                </GridCell>
                <GridCell first={false} last={false} textAlign="center">
                    {showPermissionCells ? <PermissionCell model={model} feature={feature} verb={'Manage'} /> : null}
                </GridCell>
                <GridCell first={false} last={true} textAlign="center">
                    {showPermissionCells ? <PermissionCell model={model} feature={feature} verb={'Execute'} /> : null}
                </GridCell>
            </tr>
        );
    }
);

const PermissionCell = observer(({ model, feature, verb }: { model: RoleDetailsModel; feature: Feature; verb: string }) => {
    return (
        <>{model.permissionHasVerb(feature, verb) ? <PermissionActionCircle key={'View'} model={model} feature={feature} verb={verb} /> : <>---</>}</>
    );
});

const PermissionActionCircle = observer(({ model, feature, verb }: { model: RoleDetailsModel; feature: Feature; verb: string }) => {
    const isSelected = model.isFeatureSelected(feature, verb);
    return (
        <CircleIcon
            isSelected={isSelected}
            cursor={model.isReadonly ? 'default' : 'pointer'}
            fontSize="20px"
            className={isSelected ? 'ti ti-circle-filled' : 'ti ti-circle'}
            onClick={() => model.toggleFeature(feature, verb)}
        />
    );
});

const RoleTagEditor = observer(({ model, roleTag }: { model: RoleDetailsModel; roleTag: RoleTag }) => {
    const [values, setValues] = useState<string[]>([]);
    useEffect(() => {
        setValues([]);
        if (roleTag.Key) {
            model.getTagValues(roleTag.Key).then(setValues);
        }
    }, [roleTag.Key, setValues]);

    return (
        <Group noWrap>
            <Autocomplete
                withinPortal
                readOnly={model.isReadonly}
                data={model.tagKeys}
                value={roleTag.Key ?? ''}
                onChange={(key) => model.updateTagKey(roleTag, key)}
            />
            <MultiSelect
                data-atid="RoleTagDDL"
                sx={{ maxWidth: 300 }}
                dropdownPosition="flip"
                searchable
                creatable
                readOnly={model.isReadonly}
                data={values}
                disabled={!roleTag.Key}
                placeholder={`Values`}
                value={roleTag.Values ?? []}
                onChange={(tags) => model.updateTagValues(roleTag, tags)}
                getCreateLabel={(query) => query}
                onCreate={(query) => {
                    setValues([...values, query]);
                    return query;
                }}
            />
            {model.isReadonly ? null : (
                <ActionIcon onClick={() => model.removeTag(roleTag)} color="error">
                    <i className="ti ti-circle-minus"></i>
                </ActionIcon>
            )}
        </Group>
    );
});

const RoleTagPicker = observer(({ model }: { model: RoleDetailsModel }) => {
    return (
        <Box>
            {model.roleTags.map((t, i) => (
                <RoleTagEditor key={i} model={model} roleTag={t} />
            ))}
            <Space h="md" />
            <Button disabled={model.isReadonly} variant="light" leftIcon={<i className="ti ti-plus"></i>} onClick={model.addTag}>
                Add tag
            </Button>
        </Box>
    );
});

function RoleDetails({
    role,
    onSave,
    onDelete,
    onCancel,
    mode,
    appFeatures,
}: {
    role: CompanyRoleListItem;
    onSave?: (role: Role) => void;
    onDelete?: () => void;
    onCancel?: () => void;
    mode?: 'edit' | 'add';
    appFeatures: ICompanyFeatureLookup;
}) {
    const container = useDiContainer();
    const company = useCompany();
    const model = useMemo(() => container.resolve(RoleDetailsModel).init(appFeatures, company ?? {}), []);
    const [expandedSection, setExpandedSection] = useState<string[]>(['permissions']);
    const { canViewResources } = useAuthZValues({ canViewResources: { General: 'View' } });

    useEffect(() => {
        model.loadRole(role);
    }, [role]);
    const save = useCallback(() => {
        model.save().then(onSave);
    }, [model, onSave]);
    const deleteRole = useCallback(() => {
        model.delete().then(onDelete);
    }, [model, onDelete]);
    const confirmDelete = useCallback(() => {
        openConfirmModal({
            title: 'Delete Role?',
            children: (
                <>
                    <Text>
                        Are you sure you want to delete <strong>{model.role.Name} role</strong>?
                    </Text>
                    <Text>This will affect access of {role.Members} members. </Text>
                </>
            ),
            centered: true,
            labels: { confirm: 'Yes, delete it', cancel: 'Cancel' },
            onConfirm: deleteRole,
            confirmProps: { color: 'error' },
        });
    }, [model.role]);

    const featureSections = useMemo(() => model.getFeatureSections(), []);
    const sectionFeatures = useMemo(() => featureSections.map((s) => ({ section: s, features: model.getValidFeatures(s) })), [featureSections]);
    const showSectionName = sectionFeatures.length > 1;

    return (
        <Observer
            render={() => (
                <PanelContent>
                    <PanelBody>
                        <TextInput
                            label="Role name"
                            data-atid="RoleNameText"
                            readOnly={model.isReadonly}
                            value={model.role.Name ?? ''}
                            onChange={(e) => (model.role.Name = e.target.value)}
                        />
                        <Space h="md" />
                        <Textarea
                            label="Description"
                            data-atid="RoleDescriptionText"
                            readOnly={model.isReadonly}
                            value={model.role.Description ?? ''}
                            onChange={(e) => (model.role.Description = e.target.value)}
                        />
                        <Space h="md" />

                        <Accordion
                            multiple
                            variant="separated"
                            data-atid="PermissionsAccordian"
                            value={expandedSection}
                            onChange={setExpandedSection}
                        >
                            <Accordion.Item value="permissions">
                                <Accordion.Control>Permissions</Accordion.Control>
                                <Accordion.Panel>
                                    {sectionFeatures.map((sf, i) => (
                                        <Fragment key={sf.section}>
                                            <Space h={i > 0 ? 'xl' : 0} />
                                            <Table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <GridHeaderCellEmpty>{showSectionName ? sf.section : ''}</GridHeaderCellEmpty>
                                                        <GridHeaderCell last={false}>View</GridHeaderCell>
                                                        <GridHeaderCell last={false}>Manage</GridHeaderCell>
                                                        <GridHeaderCell last={true}>Execute</GridHeaderCell>
                                                    </tr>
                                                    {sf.features
                                                        .filter((f) => f.Features != null)
                                                        .map((ua) => (
                                                            <>
                                                                <tr key={ua.Id}>
                                                                    <PermissionHeaderCell
                                                                        text={ua.SystemName ?? ''}
                                                                        width={55}
                                                                        textAlign="left"
                                                                        first={true}
                                                                        last={false}
                                                                    />
                                                                    <PermissionHeaderCell
                                                                        text=""
                                                                        width={15}
                                                                        textAlign="center"
                                                                        first={false}
                                                                        last={false}
                                                                    />
                                                                    <PermissionHeaderCell
                                                                        text=""
                                                                        width={15}
                                                                        textAlign="center"
                                                                        first={false}
                                                                        last={false}
                                                                    />
                                                                    <PermissionHeaderCell
                                                                        text=""
                                                                        width={15}
                                                                        textAlign="center"
                                                                        first={false}
                                                                        last={true}
                                                                    />
                                                                </tr>
                                                                {ua
                                                                    .Features!.filter((f) => !f.ParentId)
                                                                    .map((f) => (
                                                                        <>
                                                                            {f.Name != 'Platform' && model.hasVerbs(f) ? (
                                                                                <PermissionRow
                                                                                    key={f.Id}
                                                                                    model={model}
                                                                                    feature={f}
                                                                                    showPermissionCells
                                                                                />
                                                                            ) : null}

                                                                            {(model.featureChildren.get(f.Id!) ?? []).map((f2) => (
                                                                                <>
                                                                                    <PermissionRow
                                                                                        key={f2.Id}
                                                                                        model={model}
                                                                                        feature={f2}
                                                                                        showPermissionCells={model.hasVerbs(f2)}
                                                                                    />

                                                                                    {(model.featureChildren.get(f2.Id!) ?? []).map((f3) => (
                                                                                        <PermissionRow
                                                                                            key={f3.Id}
                                                                                            model={model}
                                                                                            feature={f3}
                                                                                            doIndent
                                                                                            showPermissionCells
                                                                                        />
                                                                                    ))}
                                                                                </>
                                                                            ))}
                                                                        </>
                                                                    ))}
                                                            </>
                                                        ))}
                                                </tbody>
                                            </Table>
                                        </Fragment>
                                    ))}
                                </Accordion.Panel>
                            </Accordion.Item>
                            {company && (!company?.Type || company.Type === 'Customer') ? (
                                <>
                                    <Accordion.Item value="account">
                                        <Accordion.Control>
                                            <Text>
                                                Access to accounts -{' '}
                                                {model.selectedAccounts.size
                                                    ? model.selectedAccounts.size + ' Account' + (model.selectedAccounts.size == 1 ? '' : 's')
                                                    : 'All'}
                                            </Text>
                                            <Group position="left" spacing={6}>
                                                <Text color="gray" size="sm" style={{ margin: '0px', padding: '0px' }}>
                                                    {model.selectedAccounts.size
                                                        ? 'Role is allowed to use specific accounts'
                                                        : 'Role is allowed to use all accounts'}
                                                </Text>
                                                <Text color="error" size="sm" weight="700" style={{ margin: '0px', padding: '0px' }}>
                                                    {model.isReadonly ? '(cannot be modified)' : ''}
                                                </Text>
                                            </Group>
                                        </Accordion.Control>
                                        <Accordion.Panel>
                                            {expandedSection.includes('account') ? (
                                                <Box sx={{ height: 400 }}>
                                                    <Picker
                                                        key={model.role.Id}
                                                        width="100%"
                                                        readonly={model.isReadonly}
                                                        items={model.accounts}
                                                        data-atid="RoleAccountPicker"
                                                        onChange={(accounts) => model.selectAccounts(accounts)}
                                                        selections={model.getSelectedAccounts()}
                                                        nameAccessor={(ac) => ac.AwsAccountId + ' - ' + ac.Name}
                                                        resizeDeps={[...expandedSection]}
                                                    ></Picker>
                                                </Box>
                                            ) : null}
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                    <Accordion.Item value="tags" hidden={!canViewResources}>
                                        <Accordion.Control>
                                            <Text>Access by tag</Text>
                                            <Text color="gray" size="sm">
                                                {model.roleTags?.length
                                                    ? 'Role has access to certain resources according to tags'
                                                    : 'Role has access to all resources regardless of tags'}
                                            </Text>
                                        </Accordion.Control>
                                        <Accordion.Panel>
                                            <RoleTagPicker key={model.role.Id} model={model} />
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </>
                            ) : null}
                        </Accordion>
                    </PanelBody>
                    <Divider />
                    <PanelToolbar>
                        {mode === 'edit' && (
                            <div style={{ width: '100%' }}>
                                <Button color="error" data-atid="DeleteRoleButton" onClick={confirmDelete} disabled={model.isReadonly}>
                                    Delete Role
                                </Button>
                            </div>
                        )}
                        <Button data-atid="CancelButton" onClick={onCancel} variant="outline">
                            Cancel
                        </Button>
                        <Button data-atid="SaveRoleButton" onClick={save} disabled={!model.isDirty}>
                            Save Role
                        </Button>
                    </PanelToolbar>
                </PanelContent>
            )}
        />
    );
}

function createPopover(feature: Feature) {
    const details =
        feature.Name == 'Assessments' ? (
            <>
                {addDescription('High level overview for the environment based on tagging and optimization.')}
                {addBullets('View', ['See all assessments and results available'])}
            </>
        ) : feature.Name == 'Tag Dashboard' ? (
            <>
                {addDescription('Custom views for the resources and metrics that are most important to you.')}
                {addBullets('View', ['View your dashboard and create custom modules'])}
            </>
        ) : feature.Name == 'Tag Explorer' ? (
            <>
                {addDescription('Enables you to evaluate and update Resource Tags. Tag changes are updated in your CSP.')}
                {addBullets('View', ['See all tagged resources'])}
                {addBullets('Manage', [
                    'Apply new tags to resources',
                    'Remove existing tags from resources',
                    'Create new tags',
                    'Delete existing tags',
                ])}
            </>
        ) : feature.Name == 'Tag Intelligence' ? (
            <>
                {addDescription('View tag policies and untagged resources.')}
                {addBullets('View', [
                    'See environment summary',
                    'See tag health',
                    'See all tag policies',
                    'See untagged resources',
                    'See tag consolidation',
                    'See tag hierarchy',
                ])}
                {addBullets('Manage', ['Update resource tags via Tag Consolidation', 'Update resource tags via Tag Hierarchy'])}
            </>
        ) : feature.Name == 'Tag Automation' ? (
            <>
                {addDescription('Use rules to automate common tagging tasks.')}
                {addBullets('View', ['See all tag automation rules'])}
                {addBullets('Manage', ['Edit tag automation rules'])}
                {addBullets('Execute', ['Create tag automation rules', 'Delete tag automation rules'])}
            </>
        ) : feature.Name == 'MAP Monitoring' ? (
            <>
                {addDescription('Gain insight into how effective a MAP contract has been over time.')}
                {addBullets('View', [
                    'See the progress of a MAP contract',
                    'See which resources are tagged correctly, incorrectly, or not at all',
                    'View workload history',
                ])}
            </>
        ) : feature.Name == 'MAP Manager' ? (
            <>
                {addDescription('Manage Your AWS MAP Contract.')}
                {addBullets('View', ['View contract details', 'Access a MAP dashboard', 'See all MAP contracts'])}
                {addBullets('Manage', ['Create a new MAP contract', 'Delete an existing map contract', 'Update map-migrated and map-workload tags'])}
            </>
        ) : feature.Name == 'Invoice Dashboard' ? (
            <>
                {addDescription('View metrics and KPIs regarding your invoice.')}
                {addBullets('View', ['View charts and graphs', 'Create new charts and graphs', 'Delete charts and graphs'])}
            </>
        ) : feature.Name == 'Invoice Explorer' ? (
            <>
                {addDescription('View all of your invoices.')}
                {addBullets('View', ['See all invoices', 'Download an invoice'])}
            </>
        ) : feature.Name == 'Trend Analysis' ? (
            <>
                {addDescription('See how your invoice has changed over time.')}
                {addBullets('View', ['View monthly spend for past months', 'See top spending changes between months'])}
            </>
        ) : feature.Name == 'Invoice Comparison' ? (
            <>
                {addDescription('Compare invoices between any two months.')}
                {addBullets('View', ['View invoice variance, in both dollar amounts and percent'])}
            </>
        ) : feature.Name == 'Cost Forecasting' ? (
            <>
                {addDescription('Use machine learning predictive modeling to forecast your future spend.')}
                {addBullets('View', ['See all forecasting models'])}
                {addBullets('Manage', ['Configure forecasting models'])}
            </>
        ) : feature.Name == 'Showback' ? (
            <>
                {addDescription('Allocate invoice costs to the cost centers of your organization.')}
                {addBullets('View', ['See all invoice costs and allocations'])}
                {addBullets('Manage', ['Configure new ways to allocate invoice costs', 'Add new invoice costs'])}
            </>
        ) : feature.Name == 'Idle Resources' ? (
            <>
                {addDescription("View resources that don't appear to be in use.")}
                {addBullets('View', ['View all idle resources'])}
                {addBullets('Manage', ['Start/stop idle resources', 'Manage idle resource utilization metrics'])}
            </>
        ) : feature.Name == 'Rightsizing' ? (
            <>
                {addDescription('Select resources and apply new sizes.')}
                {addBullets('View', ['See all resources that might be too large'])}
                {addBullets('Manage', ['Manage rightsizing utilization metrics'])}
            </>
        ) : feature.Name == 'Users' ? (
            <>
                {addDescription('View all users who have access to the Cloudsaver system.')}
                {addBullets('View', ['See all users', 'View the activity log'])}
                {addBullets('Manage', ['Invite new users', 'Deactivate existing users'])}
            </>
        ) : feature.Name == 'Roles & Permissions' ? (
            <>
                {addDescription('Create roles to give similar system access to multiple users.')}
                {addBullets('View', ['See all roles and the permissions assigned to the role'])}
                {addBullets('Manage', ['Create new roles', 'Assign permissions', 'Delete existing roles'])}
            </>
        ) : feature.Name == 'Cloud Connections' ? (
            <>
                {addDescription('View cloud platforms and the accounts/subscriptions that are connected.')}
                {addBullets('View', ['See all cloud connections', 'See past connection issues between Cloudsaver and your cloud platform'])}
                {addBullets('Manage', ['Add new account/subscription connections'])}
            </>
        ) : feature.Name == 'Single Sign On' ? (
            <>
                {addDescription('Configure single sign-on to Cloudsaver.')}
                {addBullets('View', ['See whether or not SSO has been configured'])}
                {addBullets('Manage', ['Configure SSO'])}
            </>
        ) : feature.Name == 'Subscriptions' ? (
            <>
                {addDescription('View your Cloudsaver subscriptions.')}
                {addBullets('View', ['See all subscriptions'])}
                {addBullets('Manage', ['Change from monthly/yearly billing', 'Cancel auto-renewal'])}
            </>
        ) : feature.Name == 'Credit Usage' ? (
            <>
                {addDescription('View your credit usage.')}
                {addBullets('View', ['See credit usage history'])}
                {addBullets('Manage', ['Purchase additional credits'])}
            </>
        ) : feature.Name == 'Company Info' ? (
            <>
                {addDescription('View company name, address, and billing contacts.')}
                {addBullets('View', ['See all information about your company'])}
                {addBullets('Manage', ['Update company information'])}
            </>
        ) : feature.Name == 'Invoices' ? (
            <>
                {addDescription('View all Cloudsaver invoices.')}
                {addBullets('View', ['See all invoices', 'Download an invoice'])}
            </>
        ) : feature.Name == 'Payment Methods' ? (
            <>
                {addDescription('View payment methods on file with Cloudsaver.')}
                {addBullets('View', ['See all payment methods'])}
                {addBullets('Manage', ['Add new payment method', 'Change primary payment method', 'Delete an existing payment method'])}
            </>
        ) : null;

    return details != null ? (
        <>
            <Text style={{ fontSize: '16px', fontWeight: 'bold' }}>{feature.Name}</Text>
            <Space h={8} />
            {details}
        </>
    ) : null;
}

function addDescription(description: string) {
    return <Text>{description}</Text>;
}

function addBullets(label: string, bulletPoints: string[]) {
    return (
        <Row>
            <Col1>{label}</Col1>
            <Col2>
                <UL>
                    {bulletPoints.map((m) => (
                        <li>{m}</li>
                    ))}
                </UL>
            </Col2>
        </Row>
    );
}

const Row = styled.div`
    display: flex;
    margin-top: 8px;
    gap: 15px;
`;

const Col1 = styled.div`
    flex: 0 0 90px;
    font-weight: bold;
    text-align: right;
    padding-right: 8px;
`;

const Col2 = styled.div`
    flex: 1;
    padding-left: 12px;
`;

const UL = styled.ul`
    padding: 0px;
    margin: 0px;
`;

const GridHeaderCellEmpty = styled.td`
    border-bottom: 1px solid ${(p) => p.theme.colors.gray[4]} !important;
`;

const GridHeaderCell = styled.td<{ last: boolean }>`
    text-align: center;
    background: ${(p) => p.theme.colors.gray[2]};
    border-left: 1px solid ${(p) => p.theme.colors.gray[4]} !important;
    border-right: ${(p) => (p.last ? 0 : 1)}px solid ${(p) => p.theme.colors.gray[4]} !important;
    border-bottom: 1px solid ${(p) => p.theme.colors.gray[4]} !important;
    font-weight: bold;
    white-space: nowrap;
`;

const GridCell = styled.td<{ textAlign: string; first: boolean; last: boolean }>`
    text-align: ${(p) => p.textAlign};
    border-left: ${(p) => (p.first ? 0 : 1)}px solid ${(p) => p.theme.colors.gray[4]} !important;
    border-right: ${(p) => (p.last ? 0 : 1)}px solid ${(p) => p.theme.colors.gray[4]} !important;
    border-bottom: 1px solid ${(p) => p.theme.colors.gray[4]} !important;
`;

const HeaderCell = styled.td<{ width: string; textAlign: string; first: boolean; last: boolean }>`
    text-align: ${(p) => p.textAlign};
    background: ${(p) => p.theme.colors.primary[2]};
    border-left: ${(p) => (p.first ? 0 : 1)}px solid ${(p) => p.theme.colors.gray[4]} !important;
    border-right: ${(p) => (p.last ? 0 : 1)}px solid ${(p) => p.theme.colors.gray[4]} !important;
    border-top: 1px solid ${(p) => p.theme.colors.gray[4]} !important;
    border-bottom: 1px solid ${(p) => p.theme.colors.gray[4]} !important;
    font-weight: bold;
    width: ${(p) => p.width};
`;

const CircleIcon = styled.i<{ isSelected: boolean; cursor: string; fontSize: string }>`
    font-size: ${(p) => p.fontSize};
    color: ${(p) => (p.isSelected ? p.theme.colors.success[6] : p.theme.colors.gray[5])};
    cursor: ${(p) => p.cursor};
`;

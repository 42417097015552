import { ActionIcon, Box, Group, Popover, Select, Space, Text, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ToolTip } from '@root/Design/Primitives';
import { colorPalette, theme } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { useState, useEffect, useRef } from 'react';
import { ChevronDown } from 'tabler-icons-react';
import { Picker } from '../../Picker/Picker';

interface workloads {
    value: string;
    label: string;
}

export function MapWorkloadTag({
    topSpace,
    bottomSpace,
    mapWorkloadKey,
    mapWorkloadValue,
    newMapWorkloadTagValue,
}: {
    topSpace: boolean;
    bottomSpace: boolean;
    mapWorkloadKey: string;
    mapWorkloadValue: string;
    newMapWorkloadTagValue: (valueChanged: string) => void;
}) {
    const [data, setData] = useState<workloads[]>([]);
    const resourceSvc = useDi(ResourceService);
    const [popupOpened, popupHandlers] = useDisclosure(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const arrowRef = useRef<HTMLButtonElement>(null);
    const [value, setValue] = useState('');

    const onValueChange = (value: any) => {
        newMapWorkloadTagValue?.(value.value);
        setValue(value.value);
    };

    const handleToggle = () => {
        popupHandlers.toggle();
        inputRef.current?.focus();
    };

    useEffect(() => {
        (async () => {
            var mapWorkloadKeys = await resourceSvc.getTagValues(mapWorkloadKey);
            mapWorkloadKeys = removeItemsWithName(mapWorkloadKeys, '');
            mapWorkloadKeys.sort((a, b) => a.localeCompare(b));
            setData(mapWorkloadKeys.map((s) => ({ value: s, label: s })));
        })();
    }, []);

    function removeItemsWithName(items: string[], name: string): string[] {
        return items.filter((i) => i !== name);
    }

    const handlePicklistSelect = (e: workloads[]) => {
        onValueChange(e[0]);
        popupHandlers.close();
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onValueChange({ value: e.target.value, label: e.target.value });
        setValue(e.target.value);
    };

    return (
        <>
            {topSpace ? <Space h={8} /> : null}
            <Box
                style={{
                    border: '1px solid ' + theme.colors?.gray?.[4],
                    borderRadius: '5px',
                    background: theme.colors?.gray?.[2],
                    padding: '0 6px 6px 6px',
                }}
            >
                <Text size="xs" style={{ color: colorPalette.subHeaderTextColor }}>
                    <Group>
                        Enter the "map-workload" tag value.
                        <ToolTip
                            infoText="'map-workload' tag should reflect the program or processes that are being migrated to AWS"
                            infoSize="small"
                        ></ToolTip>
                    </Group>
                </Text>
                <Box sx={{ minWidth: 160, flex: '1' }}>
                    <Popover width="target" opened={popupOpened} position="bottom" offset={0} shadow="md">
                        <Popover.Dropdown sx={{ width: 250, minWidth: 350 }} p={0}>
                            <Picker
                                mode="single"
                                width="100%"
                                height={300}
                                minimizeHeight
                                items={data}
                                selections={[]}
                                onChange={handlePicklistSelect}
                                nameAccessor={(item) => item.label}
                                noFilter={true}
                            ></Picker>
                        </Popover.Dropdown>
                        <Popover.Target>
                            <TextInput
                                placeholder="Select or type new"
                                onChange={handleInputChange}
                                rightSection={
                                    <ActionIcon ref={arrowRef} tabIndex={-1} onClick={handleToggle}>
                                        <ChevronDown />
                                    </ActionIcon>
                                }
                                value={value}
                                data-atid="MapWorkloadTagInput"
                            />
                        </Popover.Target>
                    </Popover>
                </Box>
            </Box>
            {bottomSpace ? <Space h={8} /> : null}
        </>
    );
}

import { Fragment, useCallback, useMemo } from 'react';
import { Box, Collapse, Text, UnstyledButton, createStyles, Tooltip, Popover, Divider, Space } from '@mantine/core';
import styled from '@emotion/styled';
import { colorPalette, CustomColors, mspColorPalette } from '@root/Design/Themes';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { linkHandler } from '@root/Services/Router/Router';
import { ChevronDown, Headset, Icon, Settings } from 'tabler-icons-react';

const useStyles = createStyles((theme) => ({
    popover: {
        backgroundColor: mspColorPalette.LeftNavBgColor,
        color: colorPalette.navColor,
        fontWeight: 500,
        marginLeft: theme.spacing.xs - 4,
        border: 'none',
        minWidth: 200,
        boxShadow: '0px 8px 10px #0043, 1px 4px 10px #0002',
        padding: 12,
    },

    link: {
        fontWeight: 500,
        display: 'block',
        textDecoration: 'none',
        padding: `0 ${theme.spacing.md}px`,
        paddingLeft: 18,
        fontSize: theme.fontSizes.md,
        color: mspColorPalette.LeftNavTextColor,
        whiteSpace: 'nowrap',
        height: 40,
        lineHeight: `40px`,
        '&:hover': {
            color: mspColorPalette.LeftNavHoverText,
            borderRadius: `${theme.radius.md}px`,
        },
    },

    linkDisabled: {
        fontWeight: 500,
        display: 'block',
        textDecoration: 'none',
        padding: `0 ${theme.spacing.md}px`,
        paddingLeft: 32,
        fontSize: theme.fontSizes.md,
        color: theme.colors?.gray?.[6] as CustomColors,
        whiteSpace: 'nowrap',
        height: 40,
        lineHeight: `40px`,
    },
    //collapsed nav below Tag Manager
    popupLink: {
        fontWeight: 500,
        display: 'block',
        textDecoration: 'none',
        padding: `0 ${theme.spacing.md}px`,
        fontSize: theme.fontSizes.md,
        color: theme.other?.LeftNavTextColor as CustomColors,
        margin: `${theme.spacing.xs / 2}px 0`,
        height: 40,
        lineHeight: `40px`,
        '&:hover': {
            backgroundColor: mspColorPalette.LeftNavMainAndSelectedCollapsedBgColor,
            color: mspColorPalette.LeftNavHoverText,
            borderRadius: `${theme.radius.md}px`,
        },
    },
    popupLinkDisabled: {
        fontWeight: 500,
        display: 'block',
        textDecoration: 'none',
        padding: `0 ${theme.spacing.md}px`,
        fontSize: theme.fontSizes.md,
        color: theme?.colors?.gray?.[6] as CustomColors,
        margin: `${theme.spacing.xs / 2}px 0`,
        height: 40,
        lineHeight: `40px`,
    },
}));

interface LinksGroupProps {
    icon: string;
    name: string;
    opened?: boolean;
    href: string;
    activeChild?: { link: string };
    children?: {
        label: string;
        link: string;
        disabled: boolean;
        visible?: boolean;
    }[];
    expanded: boolean;
    onOpened: (name: string) => void;
    collapsedActiveItem: EventEmitter<string>;
    atid?: string;
}

const wellKnownIcons: Record<string, Icon> = {
    settings: Settings,
    support: Headset,
};

function MainNavIcon({ icon }: { icon: string }) {
    const WellKnownIcon = icon in wellKnownIcons ? wellKnownIcons[icon] : null;
    return WellKnownIcon ? (
        <Box className="mainnav-icon">
            <WellKnownIcon size={24} strokeWidth={1} />
        </Box>
    ) : (
        <>
            <img className="-on" src={`/assets/apps/icon-${icon}-on.svg`} />
            <img className="-off" src={`/assets/apps/icon-${icon}-off.svg`} />
            <img className="-hover" src={`/assets/apps/icon-${icon}-hover.svg`} />
        </>
    );
}

export function LinksGroup({ icon, name, opened, href, children, expanded, activeChild, onOpened, collapsedActiveItem, atid }: LinksGroupProps) {
    const hoverTimeout = useMemo(() => ({ handle: 0 }), []);
    const { classes, theme } = useStyles();
    const hasLinks = Array.isArray(children);
    const mainNavClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        linkHandler(e);
    };

    const subNavClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!expanded) {
            collapsedActiveItem.emit('');
        }
        linkHandler(e);
    };
    useEvent(collapsedActiveItem);

    const createSubItem = (
        item: {
            label: string;
            link: string;
            disabled: boolean;
            visible?: boolean;
            atid?: string;
        },
        className: string
    ) =>
        !item.disabled && item.visible ? (
            <ChildNavItem state={activeChild === item ? 'active' : 'inactive'} key={item.label} data-atid={item.atid}>
                <Text
                    component="a"
                    className={className}
                    href={item.link}
                    onClick={subNavClick}
                    target={item.link.startsWith('https://') ? '_blank' : '_self'}
                    data-atid={item.atid}
                >
                    {item.label}
                </Text>
            </ChildNavItem>
        ) : (
            <></>
        );

    const active = !!activeChild;

    const items = (hasLinks ? children : []).map((item, i) => (
        <Fragment key={i}>
            {i > 0 && item.visible !== false ? <Space h={8} /> : null}
            {createSubItem(item, classes.link)}
        </Fragment>
    ));

    const popupItems = (hasLinks ? children : []).map((item) => createSubItem(item, classes.popupLink));

    const hoverOpen = () => {
        if (!expanded) {
            clearTimeout(hoverTimeout.handle);
            collapsedActiveItem.emit(name);
        }
    };
    const hoverClose = () => {
        if (!expanded) {
            clearTimeout(hoverTimeout.handle);
            hoverTimeout.handle = setTimeout(() => {
                if (collapsedActiveItem.value === name) {
                    collapsedActiveItem.emit('');
                }
            }, 400) as any;
        }
    };
    const toggle = useCallback(() => onOpened(opened ? '' : name ?? ''), [opened, name, onOpened]);

    return (
        <>
            <Popover position="right-start" opened={!expanded && collapsedActiveItem.value === name && hasLinks} withinPortal>
                <Popover.Target>
                    <div>
                        <Tooltip disabled={expanded || hasLinks} label={name} withinPortal position="right-start">
                            <MainNavItem
                                onMouseEnter={hoverOpen}
                                onMouseLeave={hoverClose}
                                className={active ? '-active' : '-inactive'}
                                state={active ? 'active' : 'inactive'}
                                expanded={expanded}
                                opened={opened || collapsedActiveItem.value === name}
                            >
                                <MainNavLink href={href} onClick={mainNavClick} data-atid={atid}>
                                    <MainNavIcon icon={icon} />
                                    {expanded ? name : null}
                                </MainNavLink>
                                {hasLinks && expanded && (
                                    <>
                                        <UnstyledButton
                                            component={MainNavExpander}
                                            onClick={toggle}
                                            state={active ? 'active' : 'none'}
                                            opened={!!opened}
                                        >
                                            <ChevronDown strokeWidth={2.5} size={20} />
                                        </UnstyledButton>
                                    </>
                                )}
                            </MainNavItem>
                        </Tooltip>
                    </div>
                </Popover.Target>
                <Popover.Dropdown onMouseLeave={hoverClose} onMouseEnter={hoverOpen} className={classes.popover}>
                    <Box>
                        <PopoverHeader>{name}</PopoverHeader>
                        <Divider sx={{ borderTopColor: colorPalette.navHoverBgColor, marginBottom: 8, marginTop: 8 }} />
                        {popupItems}
                    </Box>
                </Popover.Dropdown>
            </Popover>
            {hasLinks && expanded ? (
                <Collapse
                    in={!!opened}
                    sx={{
                        borderBottomLeftRadius: theme.radius.md,
                        borderBottomRightRadius: theme.radius.md,
                        backgroundColor: mspColorPalette.LeftNavChildBgColor,
                    }}
                    p={16}
                    pl="lg"
                >
                    {items}
                </Collapse>
            ) : null}
        </>
    );
}

//Tag Manager Icon
const MainNavItem = styled.div<{ state: 'active' | 'inactive'; expanded: boolean; opened: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: ${(p) => p.theme.radius.sm}px;
    border-bottom-left-radius: ${(p) => (p.expanded && p.opened ? 0 : p.theme.radius.sm)}px;
    border-bottom-right-radius: ${(p) => (p.expanded && p.opened ? 0 : p.theme.radius.sm)}px;
    .-on,
    .-off,
    .-hover {
        display: none;
        width: 24px;
        height: 24px;
        margin-right: ${(p) => (p.expanded ? 8 : 0)}px;
        margin-left: ${(p) => (p.expanded ? 0 : 4)}px;
    }
    a {
        color: ${(p) => p.theme.other.TopNavTextColor};
    }
    .-hover {
        filter: invert(${(p) => (p.theme.other.LeftNavColorMode === 'dark' ? 0 : 0.5)});
    }
    .mainnav-icon {
        display: inline-block;
        width: 24px;
        margin-right: ${(p) => (p.expanded ? 8 : 0)}px;
        margin-left: ${(p) => (!p.expanded ? 4 : 0)}px;
        display: flex;
        align-items: center;
    }
    &.-active {
        .-on {
            display: inline-block;
        }
    }
    &.-inactive {
        .-off {
            display: inline-block;
        }
        :not(&:hover) a {
            color: ${(p) => p.theme.other.TopNavTextColor}B3;
        }
        &:hover {
            .-off {
                display: none;
            }
            .-hover {
                display: inline-block;
            }
        }
    }
    background-color: ${(p) =>
        p.state === 'active'
            ? p.expanded
                ? p.opened
                    ? p.theme.other.LeftNavMainAndSelectedCollapsedBgColor //expanded Tag Manager text
                    : p.theme.other.LeftNavMainAndSelectedCollapsedBgColor
                : p.theme.other.LeftNavMainAndSelectedCollapsedBgColor //collapsed Tag Manager icon
            : p.theme.other.LeftNavAppBackgroundColor};
`;

//Below Tag Manager (Dashboard, etc.)
const ChildNavItem = styled.div<{ state: 'active' | 'inactive' }>`
    border-radius: ${(p) => p.theme.radius.md}px;
    background-color: ${(p) => (p.state === 'active' ? p.theme.other.LeftNavMainAndSelectedCollapsedBgColor + '80' : 'none')};
    a {
        color: ${(p) => p.theme.other?.LeftNavHoverText}${(p) => (p.state === 'active' ? '' : 'b3')};
    }
    &:hover a {
        color: ${(p) => p.theme.other?.LeftNavHoverText};
    }
`;

//Tag Manager Icon (collapsed)/ Text (open)
const MainNavLink = styled.a`
    padding-left: ${(p) => p.theme.radius.md}px;
    height: 40px;
    display: flex;
    flex: 1;
    align-items: center;
    white-space: nowrap;
    justify-content: start;
    text-decoration: none;
    i {
        display: inline-block;
        text-align: center;
        width: 26px;
        margin-right: ${(p) => p.theme.spacing.xs}px;
        font-size: ${(p) => p.theme.fontSizes.lg}pt;
    }
`;
const MainNavExpander = styled.button<{ state: 'active' | 'none'; opened: boolean }>`
    font-size: ${(p) => p.theme.fontSizes.lg}px;
    color: ${(p) => (p.state === 'active' ? p.theme.other.LeftNavBgColor : p.theme.other.LeftNavCollapserColor ?? p.theme.other.LeftNavBgColor)};
    width: 20px;
    height: 20px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 400ms;
    transform-box: border-box;
    transform-origin: center;
    transform: rotate(${(p) => (p.opened ? -180 : 0)}deg);
    &:hover {
        filter: brightness(1.15);
    }
    i {
        display: inline-block;
        transition: all 200ms;
    }
`;
//Collapsed nav Tag Manager header
const PopoverHeader = styled.div`
    color: ${(p) => p.theme.other.LeftNavTextColor};
    font-size: 14px;
    height: 24;
    justify-content: 'center';
    font-weight: 600;
    align-items: 'center';
    padding-bottom: 8;
`;

import styled from '@emotion/styled';
import { colorPalette } from '@root/Design/Themes';

export const BillingBody = styled.div`
    padding: ${(p) => p.theme.spacing.lg}px ${(p) => p.theme.spacing.xl}px;
`;

export const CreditIcon = styled.div`
    width: 32px;
    height: 32px;
    background-color: ${colorPalette.greenIconBgColor};
    border-radius: 50%;

    i {
        color: ${colorPalette.greenIconColor};
        margin-top: 7px;
        margin-left: -1px;
    }
`;

import { getAccountGetCompanyAccounts, getCompanyGetCompanyCloudProviders } from '@apis/Customers';
import { closeModal, openConfirmModal } from '@mantine/modals';
import { Text, Title } from '@mantine/core';
import { ReactNode, useEffect, useState } from 'react';
import { useNav } from '@root/Services/NavigationService';
import { useDi } from '@root/Services/DI';
import { PlatformService } from '@root/Services/PlatformService';
import { singleton } from 'tsyringe';
import { useCompany } from '../Router/CompanyContent';

@singleton()
class ConnectionCheckCache {
    private connected: boolean = false;
    private companyId = 0;

    public async hasActiveConnection(companyId: number | undefined, platformSvc: PlatformService) {
        if (companyId !== this.companyId) {
            this.connected = false;
            this.companyId = companyId ?? 0;
        }
        if (this.connected) {
            return true;
        }
        await platformSvc.init();
        const connectedClouds = platformSvc.getPlatforms();
        this.connected = connectedClouds.size > 0;
        return this.connected;
    }
}

export function ConnectionCheck({ children }: { children?: () => ReactNode }) {
    const { goto } = useNav();
    const platformSvc = useDi(PlatformService);
    const connectionCache = useDi(ConnectionCheckCache);
    const company = useCompany();
    const [ready, setReady] = useState(false);
    useEffect(() => {
        (async () => {
            const connected = await connectionCache.hasActiveConnection(company?.Id, platformSvc);

            if (!connected) {
                openConfirmModal({
                    title: <Title order={3}>Get Connected</Title>,
                    children: <Text>Use the CloudSaver platform Connection Wizard to automate setup and connection.</Text>,
                    style: { outline: 'none' },
                    labels: {
                        confirm: 'Get Started',
                        cancel: 'Connect Later',
                    },
                    withCloseButton: false,
                    closeOnClickOutside: false,
                    overlayBlur: 5,
                    cancelProps: {
                        hidden: false,
                    },
                    onConfirm: () => {
                        goto('connections-wizard');
                    },
                    onCancel: () => {
                        closeModal;
                        goto('tag-dashboard');
                    },
                });
            } else {
                setReady(true);
            }
        })();
    }, []);

    return <>{ready ? children?.() : null}</>;
}

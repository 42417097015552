import { usePostObservabilityQuery } from '@apis/Resources';
import { useEffect, useState } from 'react';
import { useCompany } from '../../Router/CompanyContent';
import { KpiLineChart, KpiLineChartSettings } from '../../Charts/LineChart';
import { ResourceChart, DivCenterMarginTop, ResourceChartTitle } from '@root/Design/ResourceContainer';
import { SegmentedControl, Select, Table } from '@mantine/core';
import { BaseResource } from '@apis/Resources/model';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';

export function AwsEbsIopsUtilization({ resource }: { resource: BaseResource }) {
    const [currChartType, setChartType] = useState('Volume_Read_Ops');
    const [currDaysBack, setDaysBack] = useState(30);
    const [currFrequency, setFrequency] = useState<number>(60);

    const chartTypesOptions: { value: string; label: string }[] = [
        { value: 'Volume_Iops', label: 'IOPS Utilization (Read + Write)' },
        { value: 'Volume_Read_Ops', label: 'Read IOPS' },
        { value: 'Volume_Write_Ops', label: 'Write IOPS' },
        { value: 'Volume_Read_Bytes', label: 'Read MB' },
        { value: 'Volume_Write_Bytes', label: 'Write MB' },
        { value: 'Volume_Total_Read_Time', label: 'Total Read Time (Seconds)' },
        { value: 'Volume_Total_Write_Time', label: 'Total Write Time (Seconds)' },
        { value: 'Volume_Idle_Time', label: 'Idle Time (Seconds)' },
    ];

    var iopsChart = (
        <>
            <ResourceIopsUtilizationChart
                resourceType={resource.ResourceType ?? ''}
                resourceId={resource.Id ?? ''}
                chartType={currChartType}
                daysBack={currDaysBack}
                frequency={currFrequency}
            />
        </>
    );

    return (
        <div className="resourcesTableContainer">
            <Table>
                <tbody>
                    <tr>
                        <td>
                            <SegmentedControl
                                key="DaysBack"
                                onChange={(v) => setDaysBack(parseInt(v))}
                                defaultValue="30"
                                data={[
                                    { label: '30 Days', value: '30' },
                                    { label: '60 Days', value: '60' },
                                    { label: '180 Days', value: '180' },
                                    { label: '12 Months', value: '365' },
                                ]}
                            />
                        </td>
                        <td>
                            <Select onChange={(v) => setChartType(v || 'Volume_Read_Ops')} data={chartTypesOptions} value={currChartType} />
                        </td>
                        <td style={{ float: 'right' }}>
                            <SegmentedControl
                                key="Frequency"
                                onChange={(v) => setFrequency(parseInt(v))}
                                defaultValue="60"
                                data={[
                                    { label: '5 Minutes', value: '5' },
                                    { label: '15 Minutes', value: '15' },
                                    { label: '1 Hour', value: '60' },
                                    { label: '1 Day', value: '1440' },
                                ]}
                            />
                        </td>
                    </tr>
                </tbody>
            </Table>
            {iopsChart}
        </div>
    );
}

export function ResourceIopsUtilizationChart(props: {
    resourceType: string;
    resourceId: string;
    chartType: string;
    daysBack: number;
    frequency: number;
}) {
    const { data, isLoading, mutate: runQuery } = usePostObservabilityQuery();
    const company = useCompany();
    let min: string | number = 0;
    let avg: string | number = 0;
    let max: string | number = 0;
    let unit = '';
    const fmtSvc = useDi(FormatService);

    useEffect(() => {
        const endTime = new Date();
        const startTime = new Date();
        startTime.setDate(startTime.getDate() - props.daysBack);
        const frequency = props.frequency * 60;

        const StartTime =
            startTime.getFullYear() + '-' + ('00' + (startTime.getMonth() + 1)).slice(-2) + '-' + ('00' + startTime.getDate()).slice(-2);
        const EndTime = endTime.getFullYear() + '-' + ('00' + (endTime.getMonth() + 1)).slice(-2) + '-' + ('00' + endTime.getDate()).slice(-2);

        runQuery({
            data: {
                Requests: [
                    {
                        ResourceId: props.resourceId,
                        ResourceType: props.resourceType,
                        Metric: props.chartType.replace(/_/g, ''),
                        Operation: 'sum',
                    },
                ],
                StartTime: StartTime,
                EndTime: EndTime,
                Period: frequency,
            },
            params: { companyId: company?.Id },
        });
    }, [props.chartType, props.daysBack, props.frequency]);

    let lineChart;
    if (isLoading) {
        lineChart = (
            <>
                <DivCenterMarginTop>Loading...</DivCenterMarginTop>
            </>
        );
    } else {
        if (data == null || data.Results == null || data.Results[0] == null) {
            lineChart = (
                <>
                    <DivCenterMarginTop>No data available</DivCenterMarginTop>
                </>
            );
        } else {
            let format: KpiLineChartSettings['format'] = 'int';
            min = 1000000;
            let records: any = [];
            let total = 0;
            for (let j = 0; j < data.Results.length; j++) {
                let yVal = data.Results[j]?.Values?.[0] as number;
                if (yVal < min) {
                    min = yVal;
                }
                total += yVal;
                if (yVal > max) {
                    max = yVal;
                }

                let r = { id: props.chartType.replace(/_/g, ''), x: data.Results[j].Time, y: yVal };
                records.push(r);
            }
            if (data.Results.length > 0) {
                avg = total / (data.Results.length - 1);
            }
            min = Math.round(min * 10) / 10;
            avg = Math.round(avg * 10) / 10;
            max = Math.round(max * 10) / 10;

            let tickValues = [];
            if (data.Results.length > 10) {
                let l = data.Results.length - 1;
                tickValues.push(data.Results[0].Time);
                tickValues.push(data.Results[Math.round(l / 4)].Time);
                tickValues.push(data.Results[Math.round(l / 2)].Time);
                tickValues.push(data.Results[Math.round((l / 4) * 3)].Time);
                tickValues.push(data.Results[l].Time);
            }

            if (props.chartType == 'CPU_Utilization') {
                unit = '%';
                format = 'percent';
            } else if (props.chartType == 'Volume_Read_Bytes' || props.chartType == 'Volume_Write_Bytes') {
                min = fmtSvc.formatBytes(Math.round(min * 10) / 10);
                avg = fmtSvc.formatBytes(Math.round(avg * 10) / 10);
                max = fmtSvc.formatBytes(Math.round(max * 10) / 10);
                format = 'bytes';
            }

            const settings: KpiLineChartSettings = {
                format,
                tickValues,
                yMax: 0,
                margin: { left: 50 },
            };

            lineChart = (
                <>
                    <KpiLineChart data={records} groups={['id', 'x']} values={['y']} settings={settings} />
                </>
            );
        }
    }

    const title = `${props.chartType.replace(/_/g, ' ')} ${unit != '' ? '(' + unit + ')' : ''}`;
    return (
        <ResourceChart key={props.frequency + '_' + props.daysBack}>
            <ResourceChartTitle
                title={title}
                metrics={[
                    { label: 'Min', value: min, unit },
                    { label: 'Avg', value: avg, unit },
                    { label: 'Max', value: max, unit },
                ]}
            />
            {lineChart}
        </ResourceChart>
    );
}

import { Text, useMantineTheme } from '@mantine/core';
import { useEffect, useState } from 'react';
import { TagModel } from './TagModel';

export interface TagValidationFormProps {
    value: string;
    model?: TagModel;
}

export function isValidValue(val: string) {
    if (val === '') {
        return 1;
    } else if (val.length > 128) {
        return 2;
    } else if (val.startsWith('aws:')) {
        return 3;
    } else {
        var regexp = new RegExp('^[0-9a-zA-Z_+-=.:/@ ]+$');
        if (!regexp.test(val)) {
            return 4;
        } else {
            return 0;
        }
    }
}

export function validationReturnCode(code: number) {
    if (code === 0 || code === 1) {
        return '';
    } else if (code === 2) {
        return 'Max length = 128';
    } else if (code === 3) {
        return "Cannot begin with 'aws:'";
    } else if (code === 4) {
        return 'Invalid character';
    } else {
        return 'Invalid';
    }
}

export const TagValidation = (props: TagValidationFormProps) => {
    const [errorCode, setErrorCode] = useState<number>(0);
    const theme = useMantineTheme();

    useEffect(() => {
        setErrorCode(isValidValue(props.value));
    }, [props.value]);

    useEffect(() => {
        props.model!.TagValid.emit(errorCode === 0);
    }, [errorCode]);

    return errorCode ? (
        <Text size="xs" style={{ color: errorCode == 0 ? theme.colors.success[7] : errorCode == 1 ? theme.white : theme.colors.error[7] }}>
            {validationReturnCode(errorCode)}
        </Text>
    ) : (
        <></>
    );
};

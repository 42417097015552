import { Tag, TagJobEstimate, TagResourcesJob } from '@apis/Resources/model';
import { ActionIcon, Alert, createStyles, Loader, RingProgress, Space, Switch, Text, Tooltip } from '@mantine/core';
import { IResourceGridSelection } from '@root/Components/Resources/ResourcesGrid';
import { getCreditsCheckCreditCount, getCreditsGetCreditSummary } from '@apis/Customers';
import { CreditSummary, GetCreditsCheckCreditCountProduct } from '@apis/Customers/model';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { Clearfix } from '@root/Design/Primitives';
import { colorPalette } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useEffect, useMemo, useState } from 'react';
import { ResourceTagService } from '../Services/ResourceTagService';

export function CreditBalanceDetail({
    resourcesSelected,
    canSubmit,
    tagJob,
    setDisableSubmit,
    overwrite,
    setOverwrite,
    setDeleteRenamedTags,
    hideOverwrite,
}: {
    resourcesSelected: IResourceGridSelection;
    canSubmit: boolean;
    tagJob: TagResourcesJob;
    setDisableSubmit: (value: boolean) => void;
    setOverwrite: (value: boolean) => void;
    setDeleteRenamedTags?: (value: boolean) => void;
    overwrite: boolean;
    hideOverwrite?: boolean;
}) {
    const { classes } = useStyles();
    const [creditSummary, setCreditSummary] = useState<CreditSummary>();
    const [showCreditError, setShowCreditError] = useState(false);
    const [creditErrorMessage, setCreditErrorMessage] = useState('');
    const [creditAlertTitle, setCreditAlertTitle] = useState('');
    const [creditsUsed, setCreditsUsed] = useState<number>();
    const [estimateFailed, setEstimateFailed] = useState(false);
    const fmtSvc = useDi(FormatService);
    const resourceTagSvc = useDi(ResourceTagService);
    const estimationSvc = useMemo(
        () => ({
            throttleMs: 600,
            timeout: 0,
            estimate(tagJob: TagResourcesJob) {
                return new Promise<TagJobEstimate>((resolve, reject) => {
                    clearTimeout(this.timeout);
                    this.timeout = window.setTimeout(() => resourceTagSvc.estimateJobCredits(tagJob).then(resolve).catch(reject), this.throttleMs);
                });
            },
        }),
        []
    );

    const [creditsAvailable, setCreditsAvailable] = useState<number>(0);
    const company = useCompany();

    let creditsText = creditsUsed && creditsUsed === 1 ? 'credit' : 'credits';
    let resourcesText = resourcesSelected.count() === 1 ? 'resource' : 'resources';
    const prepaidAlertTitle = 'Credit Limit Reached';
    const prePaidErrorMessage = 'You have reached your credit usage limit for the month. Please deselect resources or add more credits.';
    const postPaidAlertTitle = 'Insufficient Credits';
    const postPaidErrorMessage = 'You have reached your credit usage limit for the month. Please deselect resources or add more credits.';

    const creditsUsedEl = creditsUsed === undefined && !estimateFailed ? <Loader size="xs" /> : fmtSvc.formatInt(creditsUsed!);
    const prepaidBalanceText = (
        <>
            {estimateFailed && resourcesSelected.count() > 0 ? (
                <>*Credits Est. Unavailable*</>
            ) : (
                <>
                    This will use {creditsUsedEl} {creditsText}.
                </>
            )}{' '}
            You have {fmtSvc.formatInt(creditsAvailable)} available.
        </>
    );

    const thresholdText = creditSummary?.CreditThreshold === 0 ? '.' : '. You have ' + fmtSvc.formatInt(creditsAvailable) + ' left.';
    const postPaidBalanceText = (
        <>
            This will use {creditsUsedEl} {creditsText} {thresholdText}
        </>
    );

    useEffect(() => {
        (async () => {
            await getCreditSummary();
            await getCreditsAvailable();
        })();
    }, []);

    useEffect(() => {
        if (creditsUsed === undefined || resourcesSelected.count() === 0) {
            setDisableSubmit(true);
        } else {
            setDisableSubmit(false);
            setEstimateFailed(false);
        }
    }, [creditsUsed, resourcesSelected.count()]);

    async function getCreditSummary() {
        await getCreditsGetCreditSummary({ product: 'TagManager' }).then(setCreditSummary);
    }

    async function getCreditsAvailable() {
        await getCreditsCheckCreditCount({ companyId: company?.Id, product: 'TagManager' }).then(setCreditsAvailable);
    }
    const canUseCredits = creditSummary?.CanUseCredits ?? true;
    useEffect(() => {
        if (canUseCredits && creditSummary?.PaymentType === 'Prepaid' && creditsUsed !== undefined) {
            if (creditsUsed > creditsAvailable) {
                setShowCreditError(true);
                setCreditErrorMessage(prePaidErrorMessage);
                setCreditAlertTitle(prepaidAlertTitle);
                setDisableSubmit(true);
            } else {
                setShowCreditError(false);
            }
        } else if (creditSummary?.CreditThreshold !== 0 && creditsUsed !== undefined) {
            if (creditsUsed > creditsAvailable) {
                setShowCreditError(true);
                setCreditErrorMessage(postPaidErrorMessage);
                setCreditAlertTitle(postPaidAlertTitle);
            } else {
                setShowCreditError(false);
            }
        } else {
            //no threshold = no credit restriction
            setShowCreditError(false);
        }
    }, [creditsUsed, creditSummary, canSubmit, creditsAvailable, overwrite]);

    useEffect(() => {
        if (showCreditError) {
            setDisableSubmit(true);
        } else {
            setDisableSubmit(false);
        }
    }, [showCreditError]);

    useEffect(() => {
        setDisableSubmit(true);
        countCredits();
    }, [JSON.stringify(tagJob)]);

    const countCredits = async () => {
        try {
            setCreditsUsed(undefined);
            setEstimateFailed(false);
            if (resourcesSelected && resourcesSelected.count() > 0) {
                const result = await estimationSvc.estimate(tagJob);
                setCreditsUsed(result.Expected);
                setDisableSubmit(false);
                setEstimateFailed(false);
            } else {
                setDisableSubmit(true);
                setCreditsUsed(0);
            }
        } catch (err) {
            console.log(err);
            setEstimateFailed(true);
        }
    };

    return (
        <>
            <Space w="lg" />
            <Text className={classes.textColor}>
                <i className="ti ti-box" /> {fmtSvc.formatInt(resourcesSelected.count())} {resourcesText} selected
                <Clearfix></Clearfix>
                {canUseCredits ? (
                    <>
                        <Space h={16}></Space>
                        <>
                            <i className="ti ti-chart-pie" style={{ paddingRight: 6 }} />
                            {creditSummary?.PaymentType === 'Prepaid' ? prepaidBalanceText : postPaidBalanceText}
                        </>
                    </>
                ) : null}
            </Text>
            <Space h={16}></Space>

            <Alert title={creditAlertTitle} color="warning" hidden={!showCreditError} icon={<i className="ti ti-alert-triangle" color="warning" />}>
                {creditErrorMessage}
            </Alert>
            {tagJob.AddTags || tagJob.Renames ? (
                <>
                    {tagJob.Renames ? (
                        <>
                            <div style={{ display: hideOverwrite ? 'none' : 'flex' }}>
                                <Switch
                                    data-atid={'DeleteRenamedTagsSwitch'}
                                    label="Delete Selected Tags After Rename"
                                    checked={tagJob.DeleteRenamedTags}
                                    onChange={() => setDeleteRenamedTags && setDeleteRenamedTags(!tagJob.DeleteRenamedTags)}
                                    size="md"
                                    color="primary"
                                />
                                <Tooltip
                                    style={{ whiteSpace: 'pre-wrap', maxWidth: 170 }}
                                    label="On will delete selected tags with this key after renaming. Off will preserve source tags."
                                >
                                    <ActionIcon color="gray" style={{ marginLeft: 4, verticalAlign: 'top' }}>
                                        <i className="ti ti-info-circle-filled"></i>
                                    </ActionIcon>
                                </Tooltip>
                            </div>
                            <Space h="sm"></Space>
                        </>
                    ) : null}
                    <div style={{ display: hideOverwrite ? 'none' : 'flex' }}>
                        <Switch
                            data-atid={'OverwriteSwitch'}
                            label="Overwrite Existing Tags"
                            checked={tagJob.OverwriteConflicts}
                            onChange={() => setOverwrite(!tagJob.OverwriteConflicts)}
                            size="md"
                            color="primary"
                        />
                        <Tooltip
                            style={{ whiteSpace: 'pre-wrap', maxWidth: 170 }}
                            label="On will replace values for tags with this key. Off will preserve existing value for tags with this key."
                        >
                            <ActionIcon color="gray" style={{ marginLeft: 4, verticalAlign: 'top' }}>
                                <i className="ti ti-info-circle-filled"></i>
                            </ActionIcon>
                        </Tooltip>
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    );
}

const useStyles = createStyles((theme) => ({
    textColor: {
        color: colorPalette.subHeaderTextColor,
    },

    footer: {
        position: 'absolute',
        bottom: 0,
    },
}));

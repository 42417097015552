import { postCompanyStatsQueryCompanyStats } from '@apis/Customers';
import { CompanyStat } from '@apis/Customers/model';
import { Box, Button, Text, useMantineTheme } from '@mantine/core';
import { DataGrid } from '@root/Components/DataGrid';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { FieldInfoColumnAdapter } from '@root/Components/DataGrid/FieldInfoColumnAdapter';
import { ColumnConfig, DataGridProps } from '@root/Components/DataGrid/Models';
import { Clearfix } from '@root/Design/Primitives';
import { CustomColors } from '@root/Design/Themes';
import { AuthorizationService } from '@root/Services/AuthorizationService';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { SchemaService, queryBuilder } from '@root/Services/QueryExpr';
import { useMemo, useState } from 'react';
import { inject, injectable, singleton } from 'tsyringe';
import { FooterBox, FooterSavingsCell, getAssessmentColor, SavingsCell, ScoreCell } from './Design';
import { MspPortfolioAssessmentRollup, getMspPortfolio } from './Model';
import { AssessmentsPlatformSelector } from './Components/AssessmentCommon';
import { useGetDefaultPageByCompany } from '@root/Components/Router/Hooks';
import { useNav } from '@root/Services/NavigationService';

interface AssessmentsGridProps {
    showRefresh?: boolean;
    onModelLoaded?: (model: MspPortfolioGridModel) => void;
    onRowClick?: (item: MspPortfolioAssessmentRollup) => void;
}

interface FooterRecord {
    AverageScore: number;
    TotalSavings: number;
    TotalOpportunities: number;
}

@singleton()
export class AssessmentsDetailsContext {
    public detailsOpen = new EventEmitter(false);
}

export function Portfolio(props: AssessmentsGridProps) {
    const theme = useMantineTheme();
    const di = useDiContainer();
    const formatSvc = useDi(FormatService);
    const [grid, setGrid] = useState<DataGridModel>();
    const [type, setType] = useState<string>('All');
    const model = useMemo(() => di.resolve(MspPortfolioGridModel).init(type), [type]);
    const loading = useEventValue(model.loading);

    const handleModelLoaded = (dataGrid: DataGridModel) => {
        model.platformType = 'All';
        model.attachGrid(dataGrid);
        setGrid(dataGrid);
    };

    //const companyDefaultPageProvider = useGetDefaultPageByCompany();
    //const { goto } = useNav();
    const { goto } = useNav();

    const handleRowClick = async (item: MspPortfolioAssessmentRollup) => {
        goto([
            { name: 'manage-company', data: { id: item.companyId?.toString() ?? '' } },
            { name: 'assessment', data: {} },
            { name: 'assessment-overview', data: {} },
        ]);
        //goto(await companyDefaultPageProvider(item.companyId));
        //alert('redirect user to company assessment page: company id = ' + item.companyId);
        //https://web.qa.cloudsaver.com/cloudsaverdemo/assessments/assessment-overview@tab:summary
    };

    function openManagedService() {
        window.open('https://www.cloudsaver.com/schedule-a-consultation/');
    }

    return loading || !model.gridProps ? null : (
        <>
            <Box
                sx={{
                    height: '100%',
                    minHeight: '300px',

                    flex: 1,
                    overflow: 'hidden',
                    ['tbody tr']: {
                        backgroundImage: 'linear-gradient(180deg, rgba(74, 74, 74, .25) 0%, rgba(74, 74, 74, 0) 10%, rgba(74, 74, 74, 0) 100%);',
                    },
                }}
            >
                <DataGrid
                    {...model.gridProps}
                    rightTopPlaceHolder={
                        <Box sx={{ width: '300px' }}>
                            <AssessmentsPlatformSelector style={{ float: 'right' }} selectedType={type} setType={setType} />
                            <Clearfix />
                        </Box>
                    }
                    onRowClick={handleRowClick}
                    onModelLoaded={handleModelLoaded}
                    itemHeight={60}
                    showRefresh
                    exportName="MspPortfolio"
                />
                <FooterBox>
                    <div style={{ float: 'left', width: '550px', padding: '15px 15px' }}>
                        <Text size="lg">TOTAL</Text>
                    </div>
                    <div style={{ float: 'left', width: '125px' }}>
                        <ScoreCell backgroundColor={getAssessmentColor(model.footerRecord?.AverageScore! / 100) as unknown as CustomColors}>
                            {formatSvc.formatInt(Math.round(model.footerRecord?.AverageScore!))}
                        </ScoreCell>
                    </div>
                    <div style={{ float: 'left', width: '175px', margin: '15px auto' }}>
                        <FooterSavingsCell>{formatSvc.formatMoneyNoDecimals(model.footerRecord?.TotalSavings!)}</FooterSavingsCell>
                    </div>
                    <div style={{ float: 'left', width: '150px', margin: '15px auto' }}>
                        <FooterSavingsCell>{formatSvc.formatInt(model.footerRecord?.TotalOpportunities!)}</FooterSavingsCell>
                    </div>
                    <div style={{ float: 'right', padding: '12px 15px ' }}>
                        <Button sx={{ backgroundColor: '#fff', color: theme.colors?.primary?.[5] }} onClick={openManagedService}>
                            Managed Services
                        </Button>
                    </div>
                    <Clearfix />
                </FooterBox>
            </Box>
        </>
    );
}

@injectable()
class MspPortfolioGridModel {
    public loading = new EventEmitter<boolean>(false);
    public schema = new SchemaService([]);
    public gridProps?: DataGridProps<MspPortfolioAssessmentRollup>;
    public dataGrid?: DataGridModel;
    public footerRecord?: FooterRecord;
    public platformType?: string;

    private readonly columnInfo = new Map<string, { exclude?: boolean; group?: string; name?: string }>([]);

    public constructor(
        @inject(FieldInfoColumnAdapter) private readonly columnAdapter: FieldInfoColumnAdapter<MspPortfolioAssessmentRollup>,
        @inject(FormatService) private readonly formatSvc: FormatService,
        @inject(AuthorizationService) private readonly authService: AuthorizationService
    ) {}

    public init(type: string) {
        this.loading.emit(true);
        this.load(type);
        return this;
    }

    private async load(type: string) {
        try {
            const companyList = await queryBuilder<CompanyStat>()
                .take(1000)
                .select((b) => ({
                    CompanyName: b.model.CompanyName,
                    Id: b.model.Id,
                }))
                .execute(postCompanyStatsQueryCompanyStats);

            let results = (await getMspPortfolio(type)).Results;
            //Use these lines to add additional data to the companyAssessments. Modify as necessary.
            // if (type == 'All' || type == 'Aws') {
            // results?.push({
            //     companyId: 40,
            //     companyName: 'company 1',
            //     platform: 'Aws',
            //     assessmentDate: '3/27/2014 8:32:23 am',
            //     score: 35,
            //     opportunityCount: 100000,
            //     totalCount: 125000,
            //     savings: 60000000,
            // });
            //     results?.push({
            //         companyId: 40,
            //         companyName: 'company 1',
            //         platform: 'Aws',
            //         assessmentDate: '3/27/2014 8:32:23 am',
            //         score: 40,
            //         opportunityCount: 70000,
            //         totalCount: 75000,
            //         savings: 30000000,
            //     });
            //     results?.push({
            //         companyId: 41,
            //         companyName: 'company 2',
            //         platform: 'Aws',
            //         assessmentDate: '3/28/2014 7:23 pm',
            //         score: 67,
            //         opportunityCount: 10000,
            //         totalCount: 15000,
            //         savings: 300000,
            //     });
            //     results?.push({
            //         companyId: 37,
            //         companyName: 'company 3',
            //         platform: 'Aws',
            //         assessmentDate: '3/29/2014 9:01 am',
            //         score: 98,
            //         opportunityCount: 100000,
            //         totalCount: 125000,
            //         savings: 40000000,
            //     });
            //     results?.push({
            //         companyId: 34,
            //         companyName: 'company 4',
            //         platform: 'Aws',
            //         assessmentDate: '4/1/2014 11:38 pm',
            //         score: 3,
            //         opportunityCount: 10000,
            //         totalCount: 15000,
            //         savings: 5000000,
            //     });
            //     results?.push({
            //         companyId: 27,
            //         companyName: 'company 5',
            //         platform: 'Aws',
            //         assessmentDate: '3/31/2014 3:15 am',
            //         score: 36,
            //         opportunityCount: 100000,
            //         totalCount: 125000,
            //         savings: 60000000,
            //     });
            // }
            // if (type == 'All' || type == 'Azure') {
            //     results?.push({
            //         companyId: 40,
            //         companyName: 'company 6',
            //         platform: 'Azure',
            //         assessmentDate: '3/29/2014 6:17 pm',
            //         score: 70,
            //         opportunityCount: 85000,
            //         totalCount: 95000,
            //         savings: 25000,
            //     });
            //     results?.push({
            //         companyId: 30,
            //         companyName: 'company 6',
            //         platform: 'Azure',
            //         assessmentDate: '3/29/2014 6:17 pm',
            //         score: 50,
            //         opportunityCount: 100000,
            //         totalCount: 125000,
            //         savings: 70000000,
            //     });
            //     results?.push({
            //         companyId: 1,
            //         companyName: 'company 7',
            //         platform: 'Azure',
            //         assessmentDate: '3/30/2014 11:00 am',
            //         score: 64,
            //         opportunityCount: 100000,
            //         totalCount: 125000,
            //         savings: 80000000,
            //     });
            // }

            results?.forEach((item) => {
                item.companyName = companyList.Results?.find((f) => f.Id == item.companyId)?.CompanyName ?? 'Unknown';
                item.totalCount == 0 ? (item.score = 0) : null;
            });

            this.updateFooter(results ?? []);
            this.gridProps = {
                dataSource: results ?? [],
                selectionMode: 'none',
                columns: this.createColumns(this.schema),
            };
        } finally {
            this.loading.emit(false);
        }
    }

    private getInitialColumns() {
        return [
            {
                id: 'CompanyId',
                header: 'Company',
                align: 'left',
                filter: {
                    filterType: 'string',
                    name: 'companyName',
                    filterField: 'companyName',
                },
                accessor: (item) => item.companyName,
                cellRenderer: (item: MspPortfolioAssessmentRollup) => <div style={{ textAlign: 'left' }}>{item.companyName}</div>,
                sortField: 'companyName',
                defaultWidth: 350,
            },
            {
                id: 'AssessmentDate',
                noResize: true,
                header: 'Last Assessment',
                filter: {
                    filterType: 'date',
                    name: 'Type',
                    filterField: 'AssessmentDate',
                },
                cellRenderer: (item: MspPortfolioAssessmentRollup) => (
                    <div>{this.formatSvc.formatDatetime(this.formatSvc.toLocalDate(new Date(item.assessmentDate)))}</div>
                ),
                accessor: (item: MspPortfolioAssessmentRollup) => item.assessmentDate,
                defaultWidth: 210,
            },
            {
                id: 'AssessmentScore.AssessmentScore',
                noResize: true,
                header: 'Score',
                align: 'center',
                cellRenderer: (item: MspPortfolioAssessmentRollup) => (
                    <ScoreCell
                        style={{ marginTop: '16px' }}
                        backgroundColor={getAssessmentColor(Math.round(item.score * 100)) as unknown as CustomColors}
                    >
                        {Math.round(item.score * 100)}
                    </ScoreCell>
                ),
                accessor: (item: MspPortfolioAssessmentRollup) => item.score,
                defaultWidth: 100,
            },
            {
                id: 'AssessmentScore.AssessmentSavings',
                noResize: true,
                header: 'Savings or Risk',
                align: 'right',
                cellRenderer: (item: MspPortfolioAssessmentRollup) => (
                    <SavingsCell>{this.formatSvc.formatMoneyNoDecimals(item.savings!)}</SavingsCell>
                ),
                accessor: (item: MspPortfolioAssessmentRollup) => item.savings,
                defaultWidth: 190,
            },
            {
                id: 'AssessmentScore.AssessmentOpportunityCount',
                noResize: true,
                header: 'Opportunities',
                align: 'right',
                cellRenderer: (item: MspPortfolioAssessmentRollup) => <SavingsCell>{this.formatSvc.formatInt(item.opportunityCount)}</SavingsCell>,
                accessor: (item: MspPortfolioAssessmentRollup) => item.opportunityCount,
                defaultWidth: 150,
            },
        ] as ColumnConfig<MspPortfolioAssessmentRollup>[];
    }

    public getGrid = () => {
        return this.dataGrid;
    };

    private createColumns(schema: SchemaService): ColumnConfig<MspPortfolioAssessmentRollup>[] {
        const columns = this.getInitialColumns();
        return columns;
    }

    public updateFooter(data: MspPortfolioAssessmentRollup[]) {
        const totals =
            data.reduce(
                (result, item) => {
                    result.AverageScore += item.score;
                    result.TotalSavings += item.savings;
                    result.TotalOpportunities += item.opportunityCount;
                    return result;
                },
                {
                    AverageScore: 0,
                    TotalSavings: 0,
                    TotalOpportunities: 0,
                }
            ) ?? {};
        totals.AverageScore = totals.AverageScore / data.length;
        this.footerRecord = totals;
    }

    public attachGrid = (grid: DataGridModel) => {
        this.dataGrid = grid;
    };
}

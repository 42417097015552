import styled from '@emotion/styled';
import { Box, Space, Text, Title } from '@mantine/core';
import { PanelHeader } from '@root/Design/Layout';
import { ReactNode } from 'react';
import {} from 'tabler-icons-react';

export const SplashBg = styled.div`
    height: 100%;
    background: url('/assets/costforecast/ai-spend-bg-2216.jpg');
    background-size: cover;
    background-repeat: no-repeat;
`;

export const FailedEffect = styled.div`
    border: 8px solid #d92d20;
    border-radius: 15px;
`;

export const LoadingEffect = styled.div`
    @keyframes rotate {
        100% {
            transform: rotate(1turn);
        }
    }

    opacity: 0.7;
    position: relative;
    z-index: 0;
    overflow: hidden !important;

    &::before {
        content: '';
        position: absolute;
        z-index: -2;
        left: -50%;
        top: -50%;
        width: 200%;
        height: 200%;
        background-color: #fbb300;
        background-repeat: no-repeat;
        background-size: 50% 50%, 50% 50%;
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
        background-image: linear-gradient(#fbb300, #fbb300), /*gold*/ linear-gradient(#377af5, #377af5), /*blue*/ linear-gradient(#fbb300, #fbb300),
            /*gold*/ linear-gradient(#377af5, #377af5), /*blue*/ linear-gradient(#fbb300, #fbb300), /*gold*/ linear-gradient(#377af5, #377af5); /*blue*/
        animation: rotate 10s linear infinite;
    }

    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 5px;
        top: 5px;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        background: #ffffff;
    }
`;

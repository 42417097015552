import { Company, CompanyStat } from '@apis/Customers/model';
import styled from '@emotion/styled';
import { CompanyContextService, ICompanyContextToken } from '@root/Services/Customers/CompanyContext';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { Logger } from '@root/Services/Logger';
import { NavigationService, useNav } from '@root/Services/NavigationService';
import { queryBuilder, SchemaService, TypeInfo } from '@root/Services/QueryExpr';
import { inject, injectable } from 'tsyringe';
import { DataGridModel } from '../DataGrid/DataGridModel';
import { GridFullCell } from '../DataGrid/Design';
import { FieldInfoColumnAdapter, FieldInfoColumnModifier } from '../DataGrid/FieldInfoColumnAdapter';
import { ColumnConfig, DataColumnConfig, DataGridProps } from '../DataGrid/Models';
import { Anchor, Text } from '@mantine/core';
import { DataGrid } from '../DataGrid';
import { postMapContractsQueryContractStats } from '@apis/TagManager';
import { MapContractDailySnapShot, MapContractDailySnapShotQueryResult } from '@apis/TagManager/model';
import { postCompanyStatsQueryCompanyStats } from '@apis/Customers';
import { ColumnSelectorOption, IColumnSelectorOption } from '../DataGrid/ColumnSelector';
import { useMemo } from 'react';
import { useDiContainer } from '@root/Services/DI';
import { Route } from '@root/Services/Router/RouteSerializer';

export interface MapContractStatsGridProps {}

export function MapContractStatsGrid(props: MapContractStatsGridProps) {
    const { goto } = useNav();

    return (
        <>
            <MapContractExplorerGrid />
        </>
    );
}

interface MapContractExplorerGridProps {}

function MapContractExplorerGrid(props: MapContractExplorerGridProps) {
    const di = useDiContainer();
    const model = useMemo(() => di.resolve(MapContractsExplorerGridModel).init(), []);
    const loading = useEventValue(model.loading);

    const handleModelLoaded = (dataGrid: DataGridModel) => {
        model.attachGrid(dataGrid);
    };

    return loading || !model.gridProps ? null : <DataGrid {...model.gridProps} onModelLoaded={handleModelLoaded} showRefresh />;
}

@injectable()
class MapContractsExplorerGridModel {
    public loading = new EventEmitter<boolean>(false);
    public schema = new SchemaService([]);
    public gridProps?: DataGridProps<MapContractDailySnapShot>;
    public dataGrid?: DataGridModel;
    public companyList: Company[] = [];

    private readonly columnInfo = new Map<string, FieldInfoColumnModifier>([
        ['Id', { exclude: true }],
        ['CompanyType', { exclude: true }],
        ['SnapshotId', { exclude: true }],
        ['RuleGroupId', { exclude: true }],
        ['CompanyId', { name: 'Company' }],
        ['ProgramId', { name: 'Program Id', group: 'MAP Contract' }],
        ['Name', { name: 'Contract Name', group: 'MAP Contract' }],
        ['Date', { name: 'Last Updated', format: 'date' }],
        ['ContractStartDate', { name: 'Contract Start Date', format: 'date', group: 'MAP Contract' }],
        ['ContractTermYears', { name: 'Contract Term Years', group: 'MAP Contract' }],
        ['MaximumContractValue', { name: 'Contract Value', format: 'money', group: 'MAP Contract' }],
        ['ContractDaysRemaining', { name: 'Days Remaining', group: 'MAP Contract' }],
        ['DistinctWorkloadCount', { name: 'Distinct Workloads #', group: 'Map Contract' }],
        ['EligibleTaggedCorrectCount', { name: 'Tagged Correctly #', group: 'Eligible Resources' }],
        ['EligibleWorkloadTaggedCorrectCount', { name: 'Workload Tagged Correctly #', group: 'Eligible Resources' }],
        ['EligibleTaggedInCorrectCount', { name: 'Tagged Incorrectly #', group: 'Eligible Resources' }],
        ['EligibleNotTaggedCount', { name: 'Not Tagged #', group: 'Eligible Resources' }],
        ['EligibleTotalCount', { name: 'Total #', group: 'Eligible Resources' }],
        ['IneligibleOutsideContractPeriodCount', { name: 'Outside Contract Period #', group: 'Ineligible Resources' }],
        ['IneligibleNotCoveredCount', { name: 'Not Covered #', group: 'Ineligible Resources' }],
        ['IneligibleNotEligibleWorkloadCount', { name: 'Not Eligible Workload #', group: 'Ineligible Resources' }],
        ['IneligibleOvertaggedCount', { name: 'Overtagged #', group: 'Ineligible Resources' }],
        ['IneligibleTotalCount', { name: 'Total Ineligible #', group: 'Ineligible Resources' }],
        ['TaggedCorrectlyPercent', { name: 'Tagged Correctly %', format: 'percent', group: 'Eligible Resources' }],
        ['WorkloadTaggedCorrectlyPercent', { name: 'Workload Tagged Correctly %', format: 'percent', group: 'Eligible Resources' }],
        ['ActionRequiredCount', { name: 'Actions Required #', group: 'Eligible Resources' }],
        ['TotalEligibleAnnualizedSpend', { name: 'Total $', format: 'money', group: 'Eligible Resources' }],
        ['EligibleTaggedCorrectAnnualizedSpend', { name: 'Correctly Tagged $', format: 'money', group: 'Eligible Resources' }],
        ['EligibleWorkloadTaggedCorrectAnnualizedSpend', { name: 'Workload Correctly Tagged $', format: 'money', group: 'Eligible Resources' }],
        ['EligibleTaggedIncorrectAnnualizedSpend', { name: 'Incorrectly Tagged $', format: 'money', group: 'Eligible Resources' }],
        ['EligibleNotTaggedAnnualizedSpend', { name: 'Not Tagged $', format: 'money', group: 'Eligible Resources' }],
        ['TotalIneligibleAnnualizedSpend', { name: 'Total $', format: 'money', group: 'Ineligible Resources' }],
        ['IneligibleOutsideContractPeriodAnnualizedSpend', { name: 'Outside Contract $', format: 'money', group: 'Ineligible Resources' }],
        ['IneligibleNotCoveredAnnualizedSpend', { name: 'Not Covered $', format: 'money', group: 'Ineligible Resources' }],
        ['IneligibleNotEligibleWorkloadAnnualizedSpend', { name: 'Not Eligible Workload $', format: 'money', group: 'Ineligible Resources' }],
        ['IneligibleOvertaggedAnnualizedSpend', { name: 'Overtagged $', format: 'money', group: 'Ineligible Resources' }],
        ['TagCoverageAnnualizedSpend', { name: 'Coverage %', format: 'percent', group: 'Eligible Resources' }],
        ['MigrationPartner', { name: 'Migration Partner', group: 'MAP Contract' }],
        ['District', { name: 'District', group: 'MAP Contract' }],
        ['AccountIds', { name: 'Accounts', group: 'MAP Contract' }],
    ]);

    public constructor(
        @inject(FieldInfoColumnAdapter) private readonly columnAdapter: FieldInfoColumnAdapter<MapContractDailySnapShot>,
        @inject(NavigationService) private readonly navigationSvc: NavigationService,
        @inject(Logger) private readonly logger: Logger,
        @inject(FormatService) private readonly formatSvc: FormatService,
        @inject(CompanyContextService) private readonly companyContextSvc: CompanyContextService<Company>,
        @inject(ICompanyContextToken) private readonly company: Company
    ) {}

    public init() {
        this.loading.emit(true);
        this.load();
        return this;
    }

    private async loadCompanies() {
        let query = queryBuilder<CompanyStat>()
            .where((c) => c.model.Id!.ne(this.companyContextSvc.parentCompany.Id!))
            .take(1000)
            .select((b) => ({
                CompanyName: b.model['CompanyName'],
                Id: b.model['Id'],
            }))
            .build();
        await postCompanyStatsQueryCompanyStats(query).then((results) => {
            this.companyList = results.Results ?? [];
        });
    }

    private async load() {
        try {
            const schemaResults = await postMapContractsQueryContractStats({ IncludeSchema: true, Take: 0 });
            await this.loadCompanies();
            const types = schemaResults.Types ?? [];
            this.schema = new SchemaService(types);

            this.gridProps = {
                dataSource: (query) => {
                    let resolve = (_: MapContractDailySnapShotQueryResult) => {};
                    const result = new Promise<MapContractDailySnapShotQueryResult>((r) => (resolve = r));
                    this.companyContextSvc.withParentCompany(async () => {
                        postMapContractsQueryContractStats(query).then(resolve);
                    });

                    return result;
                },
                allowPinning: true,
                columns: this.createColumns(this.schema),
                onModelLoaded: (model) => {
                    model.modifyColumnSelectorOptions = this.modifyColumnOptions;
                },
                groupConfig: {
                    ['Ineligible Resources']: { color: '#FF9900' },
                    ['Eligible Resources']: { color: '#007FFF' },
                    ['MAP Contract']: { color: '#71C562' },
                },
                showHeaderGroups: true,
                state: {
                    columns: this.getInitialColumns(),
                    filters: [],
                    sort: [{ Expr: { field: 'Name' }, Direction: 'Asc' }],
                },
                allowSavedViews: true,
                allowLoadAllPages: true,
                exportName: 'MAP Contracts',
                statePersistence: { key: 'map-contract-explorer' },
            };
        } finally {
            this.loading.emit(false);
        }
    }

    private modifyColumnOptions = (options: { options: ColumnSelectorOption[]; selections: IColumnSelectorOption[] }) => {
        options.options.sort((a, b) => {
            const isAGroup = 'name' in a;
            const isBGroup = 'name' in b;
            const nameA = isAGroup ? a.name : a.column.header ?? '';
            const nameB = isBGroup ? b.name : b.column.header ?? '';
            return isAGroup === isBGroup ? nameA.localeCompare(nameB, undefined, { sensitivity: 'base' }) : isAGroup ? -1 : 1;
        });
    };

    private getInitialColumns() {
        return [
            { id: 'map-contract-snapshots.CompanyId', width: 200 },
            { id: 'map-contract-snapshots.Name', width: 200 },
            { id: 'map-contract-snapshots.MaximumContractValue', width: 140 },
            { id: 'map-contract-snapshots.ContractDaysRemaining', width: 140 },
            { id: 'map-contract-snapshots.Arr.TotalEligibleAnnualizedSpend', width: 120 },
            { id: 'map-contract-snapshots.Arr.EligibleTaggedCorrectAnnualizedSpend', width: 160 },
            { id: 'map-contract-snapshots.Arr.EligibleNotTaggedAnnualizedSpend', width: 120 },
            { id: 'map-contract-snapshots.Arr.TagCoverageAnnualizedSpend', width: 110 },
            { id: 'map-contract-snapshots.Arr.IneligibleOvertaggedAnnualizedSpend', width: 120 },
            { id: 'map-contract-snapshots.Arr.TotalIneligibleAnnualizedSpend', width: 120 },
        ];
    }

    public getGrid = () => {
        return this.dataGrid;
    };

    private resolvePath = (object: any, path: string) => {
        return path.split('.').reduce((o, p) => (o ? o[p] : null), object);
    };

    private createColumns(schema: SchemaService): ColumnConfig<MapContractDailySnapShot>[] {
        const rootTypes = schema.rootTypeInfo;
        const columns = this.createBaseColumns(rootTypes, []);

        this.updateColumn(columns, 'Name', {
            defaultFixed: true,
            filter: {
                filterType: 'string',
                name: 'Name',
                filterField: 'Name',
            },
            cellRenderer: (item) => {
                return (
                    <GridFullCell style={{ padding: 0 }}>
                        <ContractNameCell contract={item} grid={this.getGrid} />
                    </GridFullCell>
                );
            },
        });

        this.updateColumn(columns, 'CompanyId', {
            defaultFixed: true,
            filter: {
                filterType: 'string',
                name: 'Company',
                filterField: 'CompanyName',
            },
            accessor: (item) => this.companyList.find((c) => c.Id == item.CompanyId)?.CompanyName,
            cellRenderer: (item) => {
                return <>{this.companyList.find((c) => c.Id == item.CompanyId)?.CompanyName} </>;
            },
        });

        return columns;
    }

    public refreshDataOnly() {
        setTimeout(() => {
            this.dataGrid?.refresh();
        }, 1500);
    }

    private createBaseColumns(types: TypeInfo[], columns: DataColumnConfig<MapContractDailySnapShot>[]) {
        for (const type of types) {
            for (const field of type.fields) {
                const columnInfo = this.columnInfo.get(field.fieldName);
                if (!columnInfo?.exclude) {
                    if (field.isPrimitive) {
                        const column = this.columnAdapter.adapt(field, columnInfo);
                        column.header = columnInfo?.name ?? column.header;
                        columns.push(column);
                    } else if (field.typeInfo) {
                        this.createBaseColumns([field.typeInfo], columns);
                    }
                }
            }
        }
        return columns;
    }

    private updateColumn(
        columns: DataColumnConfig<MapContractDailySnapShot>[],
        id: string,
        changes: Partial<DataColumnConfig<MapContractDailySnapShot>> | ((column: DataColumnConfig<MapContractDailySnapShot>) => void)
    ) {
        id = `map-contract-snapshots.${id}`;
        const column = columns.find((c) => c.id === id);
        if (column) {
            if (typeof changes === 'object') {
                Object.assign(column, changes);
            } else {
                changes(column);
            }
        } else {
            this.logger.warn(`No column found for ID ${id}`, columns);
        }
    }

    public attachGrid = (grid: DataGridModel) => {
        this.dataGrid = grid;
        //this.dataGrid.loadSchema(this.schema);
    };
}

function ContractNameCell({ contract, ...props }: { contract: MapContractDailySnapShot; grid: () => DataGridModel | undefined }) {
    const { goto } = useNav();
    const route: Route = [
        { name: 'manage-company', data: { id: contract.CompanyId?.toString() ?? '' } },
        { name: 'compliance', data: {} },
        { name: 'map-contract', data: { id: contract.Id?.toString() ?? '', section: 'manage-tags' } },
    ];

    return (
        <Anchor onClick={() => goto(route)}>
            <ContractNameBox>
                <Text
                    pl="xs"
                    color="primary"
                    sx={{
                        display: 'block',
                        flex: 1,
                        height: '100%',
                        lineHeight: '30px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {contract.Name ? contract.Name : 'No Name'}
                </Text>
            </ContractNameBox>
        </Anchor>
    );
}

const ContractNameBox = styled.div`
    display: flex;
`;

import { Box, Divider, Group, Title, useMantineTheme } from '@mantine/core';
import { CompanyFeaturesProvider, UserAccessContextProvider } from '@root/Components/Shell/AppFeatureAccess';
import { PageContent } from '@root/Design/Layout';
import { CustomColors } from '@root/Design/Themes';
import { ReactNode } from 'react';
import { SettingsNav } from './SettingsNav';

export function SettingsPageHeader({ text, children }: { text: string; children?: ReactNode }) {
    const theme = useMantineTheme();
    return (
        <div
            style={{
                paddingBottom: '1rem',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: `1px solid ${theme.colors.gray[3]}`,
            }}
        >
            <Group position="apart">
                <Title data-atid="SettingsMainHeader" order={3} px="xl" pt="md">
                    {text}
                </Title>
                <Group style={{ paddingRight: '26px', paddingTop: '11px' }}>{children}</Group>
            </Group>
        </div>
    );
}

export function SettingsPage({ children }: { children: ReactNode }) {
    const theme = useMantineTheme();
    return (
        <UserAccessContextProvider>
            {() => (
                <Box sx={{ display: 'flex', height: '100%' }}>
                    <Box sx={{ flex: 0, height: '100%' }}>
                        <SettingsNav />
                    </Box>
                    <Divider orientation="vertical" />
                    <PageContent style={{ background: theme.colors.gray[0] as CustomColors }}>{children}</PageContent>
                </Box>
            )}
        </UserAccessContextProvider>
    );
}

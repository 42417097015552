import { getRbacGetCompanyRoles, getRbacGetRolesByCompany } from '@apis/Customers';
import type { Company, RoleCompanyType } from '@apis/Customers/model';
import { inject, injectable } from 'tsyringe';
import { ICompanyContextToken } from '@root/Services/Customers/CompanyContext';

@injectable()
export class CompanyRoleService {
    public constructor(@inject(ICompanyContextToken) private readonly company: Company) {}

    public async getRoles() {
        const [globalRoles, companyRoles] = await Promise.all([getRbacGetRolesByCompany(), getRbacGetRolesByCompany({ companyId: this.company.Id })]);
        const roles = [...globalRoles, ...companyRoles];
        if (this.company.Type === 'StrategicPartner') {
            return roles.filter((r) => r.CompanyType === 'StrategicPartner');
        } else if (this.company.Type === 'PlatformSupport') {
            return roles.filter((r) => r.CompanyType === 'PlatformSupport');
        } else if (this.company.Type == 'Support') {
            return roles.filter((r) => r.CompanyType === 'Support');
        } else if (this.company.Type == 'Msp') {
            return roles.filter((r) => r.CompanyType === 'Msp');
        } else {
            return roles.filter((r) => !r.CompanyType || r.CompanyType === 'Customer');
        }
    }

    public async getRoleList() {
        const roles = await getRbacGetCompanyRoles();
        if (this.company.Type === 'StrategicPartner') {
            return roles.filter((r) => r.Role?.CompanyType === 'StrategicPartner');
        } else if (this.company.Type === 'PlatformSupport') {
            return roles.filter((r) => r.Role?.CompanyType === 'PlatformSupport');
        } else if (this.company.Type == 'Support') {
            return roles.filter((r) => r.Role?.CompanyType === 'Support');
        } else if (this.company.Type == 'Msp') {
            return roles.filter((r) => r.Role?.CompanyType === 'Msp');
        } else {
            return roles.filter((r) => !r.Role?.CompanyType || r.Role.CompanyType === 'Customer');
        }
    }

    public async getRolesByType(type?: RoleCompanyType) {
        const roles = await getRbacGetCompanyRoles();
        if (type === 'StrategicPartner') {
            return roles.filter((r) => r.Role?.CompanyType === 'StrategicPartner' && !r.Role?.CompanyId);
        } else if (type === 'PlatformSupport') {
            return roles.filter((r) => r.Role?.CompanyType === 'PlatformSupport' && !r.Role?.CompanyId);
        } else if (type == 'Support') {
            return roles.filter((r) => r.Role?.CompanyType === 'Support' && !r.Role?.CompanyId);
        } else if (type == 'Msp') {
            return roles.filter((r) => r.Role?.CompanyType === 'Msp' && !r.Role?.CompanyId);
        } else {
            return roles.filter((r) => (!r.Role?.CompanyType || r.Role.CompanyType === 'Customer') && !r.Role?.CompanyId);
        }
    }
}

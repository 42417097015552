import {
    getSubscriptionGetSubscriptionOptions,
    getUserGetMyCompanies,
    postInvoiceCreateEstimate,
    postPaymentAddPaymentSource,
    putCompanyRegisterCompany,
    postNewSubscriptionUpdateCompanyInfo,
} from '@apis/Customers';
import { Address, Card, Company, EstimateRequest, Plans, PromotionCode } from '@apis/Customers/model';
import {
    Anchor,
    Box,
    Button,
    Card as CardEl,
    Checkbox,
    Divider,
    Group,
    Loader,
    Modal,
    Space,
    Text,
    TextInput,
    Title,
    Transition,
    useMantineTheme,
    createStyles,
    ThemeIcon,
    LoadingOverlay,
    Grid,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useList } from '@react-hookz/web';
import { colorPalette, CustomColors, theme } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { JobHierarchyProgress, JobService } from '@root/Services/Jobs/JobService';
import { PollingPromise } from '@root/Services/Jobs/PollingService';
import { NavigationService, useNav } from '@root/Services/NavigationService';
import { System } from '@root/Services/System';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
// @ts-ignore
import { FormatService } from '@root/Services/FormatService';
import { Circle, CircleCheck } from 'tabler-icons-react';
import { BasicApi } from '@root/Services/BasicApi';
import { CompanyInfo as CompanyUpdate } from '@apis/Customers/model';
import { injectable } from 'tsyringe';
import { BasicRouteLoader } from '@root/Services/Router/BasicRouteLoader';
import { closeModal, useModals } from '@mantine/modals';
import { getProductIcon } from '@root/Components/Applications/AppIcons';
import { MspService } from '@root/Services/MspService';

interface CompanyInfo {
    companyName: string;
    webUrl: string;
}
interface ProvisionedCompany {
    provisionResult: boolean;
    company: Company;
}
interface SubscriptionInfo {
    subscriptionId?: string;
    promoId?: string;
    paymentMethodToken?: string;
    promoConfig?: PromoConfig;
    term?: string;
    price?: number | undefined | null;
}

export function RegisterCompany({ onFinished }: { onFinished: () => void }) {
    return (
        <>
            <RegisterCompanyWizard onDone={onFinished} />
        </>
    );
}

export function RegisterCompanyWizard({ onDone }: { onDone: () => void }) {
    const theme = useMantineTheme();
    const system = useDi(System);
    const siteReady = useEventValue(system.siteReady);
    const [company, setCompany] = useState<CompanyInfo>();
    const [subscription, setSubscription] = useState<SubscriptionInfo>();
    const [provisionedCompany, setProvisionedCompany] = useState<ProvisionedCompany>();
    const [completing, setCompleting] = useState(false);
    const [step, setStep] = useState(-1);
    const jobSvc = useDi(JobService);
    const api = useDi(BasicApi);
    const routeLoader = useDi(BasicRouteLoader);
    const nav = useNav();
    const regSvc = useMemo(() => new RegistrationService(jobSvc, api, nav), []);
    const { promoCode } = nav.getData('promoCode');
    const [checkCompany, setCheckCompany] = useState(true);
    const mspSvc = useDi(MspService);
    useEffect(() => {
        (async () => {
            if (!promoCode || !(await regSvc.checkCompany(promoCode))) {
                setStep(0);
                setCheckCompany(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (regSvc.companyInfo) {
            setCompany(regSvc.companyInfo);
            setStep(1);

            if (regSvc.subscriptionInfo) {
                setSubscription(regSvc.subscriptionInfo);
                setStep(2);
            }
        }
    }, []);

    const changeStep = useCallback(
        (step: number) => {
            if (!completing) {
                setStep(step);
            }
        },
        [setStep, completing]
    );
    const handleCompanySet = useCallback(
        (company: CompanyInfo) => {
            setCompany(company);
            regSvc.setCompany(company);
            changeStep(1);
        },
        [setCompany, setStep]
    );
    const handleSubscriptionSet = useCallback(
        (subscription: SubscriptionInfo) => {
            setSubscription(subscription);
            regSvc.init(company!, subscription);
            if (
                subscription.term === 'Trial' ||
                subscription.term === 'FullySubsidized' ||
                subscription.promoId === 'reinvent-6month-discount' ||
                subscription.promoConfig?.FullySubsidized
            ) {
                changeStep(3);
            } else {
                changeStep(2);
            }
        },
        [setSubscription, company]
    );
    const handlePaymentSet = useCallback(() => {
        changeStep(3);
    }, [changeStep]);

    return !siteReady || checkCompany ? (
        <></>
    ) : (
        <Modal
            opened={true}
            onClose={() => {}}
            withCloseButton={false}
            withinPortal
            overlayOpacity={1}
            overlayBlur={4}
            overlayColor={theme.colors.gray[1]}
            size={step === 2 ? 600 : 400}
            shadow="xl"
            lockScroll={false}
            overflow="outside"
        >
            <Box>
                <Box sx={{ textAlign: 'center' }}>
                    <img src={mspSvc.mspSupportAndLogos.Logo} style={{ width: 120 }} alt={mspSvc.mspSupportAndLogos.CompanyName} />
                </Box>
                <Space h="xl" />
                {step === 0 ? (
                    <>
                        <Title mb="xs" align="center" order={2}>
                            Create your company
                        </Title>
                        <Text align="center" color="dimmed">
                            Name your {mspSvc.mspSupportAndLogos.CompanyName} account
                        </Text>
                        <Space h="xl" />
                        <CompanyInfo onNext={handleCompanySet} companyDefaults={company} />
                    </>
                ) : step === 1 ? (
                    <>
                        <SubscriptionStep onNext={handleSubscriptionSet} selectedSubscription={subscription} />
                        <BackButton onClick={() => setStep(0)} width={400} />
                    </>
                ) : (
                    <>
                        <FinishingRegistration
                            provisionResult={provisionedCompany?.provisionResult}
                            company={provisionedCompany?.company}
                            subscription={subscription!}
                            companyInfo={company!}
                            onCompleted={onDone}
                            regSvc={regSvc}
                            onBack={() => changeStep(2)}
                        />
                    </>
                )}
            </Box>
        </Modal>
    );
}

function BackButton({ onClick, width }: { onClick: () => void; width: number }) {
    return (
        <Text size={16} align="center" pb={200} mt={48} sx={{ position: 'absolute', left: 0, width }}>
            <Anchor underline={false} onClick={onClick}>
                <i className="ti ti-chevron-left"></i> Back
            </Anchor>
        </Text>
    );
}

type PromoConfig = {
    SubscriptionName?: string;
    FullySubsidized?: boolean;
    HideTrialOption?: boolean;
    PaymentDescription?: string;
};
function SubscriptionStep({
    onNext,
    selectedSubscription,
}: {
    onNext: (subscription: SubscriptionInfo) => void;
    selectedSubscription?: SubscriptionInfo;
}) {
    const { promoCode, subscriptionType } = useNav()?.getData('promoCode', 'subscriptionType');
    const [promo, setPromo] = useState<PromotionCode>();
    const [selected, setSelected] = useState<Plans>();
    const [subscriptions, setsubscriptions] = useState<Plans[]>();
    const [loading, setLoading] = useState(true);
    const [promoConfig, setPromoConfig] = useState<PromoConfig>();
    const hasDefaultSelection = promo || subscriptionType;
    const trialId = 'Tag-Manager-Free-Trial';
    const [isSixMonthPromo, setSixMonthPromo] = useState(false);
    const AppIcon = getProductIcon('Tag Manager');
    useEffect(() => {
        (async () => {
            try {
                var plans = await getSubscriptionGetSubscriptionOptions();
                setsubscriptions(await plans.AllPlans?.filter((s) => s.IsActive && s.Term !== 'Trial'));
                // const promo = promoCode ? await getSubscriptionGetPromotionByCode({ promoCode }) : null;
                const promo = null;
                // if (promo) {
                //     const uses = promo.Uses ?? 0;
                //     const max = promo.MaxUses === null ? Infinity : promo.MaxUses ?? 0;
                //     if (uses < max) {
                //         setPromo(promo);
                //     }
                //     if (promo) {
                //         const config = JSON.parse(promo.Promotion?.ConfigJson ?? '{}') as PromoConfig;
                //         setPromoConfig(config);
                //         const plan = plans.AllPlans?.find((p) => p.Name === config.SubscriptionName);
                //         const selected = { ...plan };
                //         if (config.SubscriptionName === 'Tag-Manager-Standard-Special-Offer') {
                //             setSixMonthPromo(true);
                //             selected.FriendlyName = 'Tag Manger Standard - Special Offer';
                //         }
                //         setSelected(selected);
                //     }
                // } else {
                if (hasDefaultSelection) {
                    const plan = plans.AllPlans?.find((p) => p.Name === subscriptionType);
                    setSelected(plan);
                    // if (plan?.Term === 'Trial') {
                    //     onNext({ subscriptionId: trialId, term: 'Trial' });
                    // }
                } else {
                    if (selectedSubscription) {
                        setSelected(plans.AllPlans?.find((s) => s.Name == selectedSubscription?.subscriptionId));
                    }
                }
                //}
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const content = loading ? (
        <LoadingOverlay visible />
    ) : (promo || selected) && hasDefaultSelection ? (
        <>
            {promoConfig?.FullySubsidized ? (
                <SubsidizedSubscriptionOption
                    selected
                    name={selected?.FriendlyName ?? ''}
                    price={selected?.Price!}
                    term={selected?.Term!}
                    onSelect={() => {}}
                />
            ) : (
                <SubscriptionOption
                    selected
                    name={selected?.FriendlyName ?? ''}
                    price={selected?.Price ?? 0}
                    term={selected?.Term!}
                    onSelect={() => {}}
                    isSixMonthPromo={isSixMonthPromo}
                />
            )}
            <Space h="sm" />
        </>
    ) : (
        <>
            {subscriptions!.map((s) => (
                <Fragment key={s.ExternalId!}>
                    <SubscriptionOption
                        selected={selected === s}
                        name={s.FriendlyName!}
                        price={s.Price!}
                        term={s.Term!}
                        onSelect={() => setSelected(s)}
                        isSixMonthPromo={isSixMonthPromo}
                    />
                    <Space h="sm" />
                </Fragment>
            ))}
        </>
    );

    function UserAgrees({ type }: { type: string }) {
        const [userAgrees, setUserAgrees] = useState<boolean>(false);

        return (
            <Box>
                <Group position="left" spacing={6}>
                    <Checkbox
                        onChange={(event: any) => {
                            setUserAgrees(event.target.checked);
                        }}
                    />
                    I agree with the
                    <Anchor
                        href={'https://www.cloudsaver.com/legal/' + (type == 'trial' ? 'free-trial' : 'master-subscription') + '-agreement/'}
                        target="_blank"
                    >
                        terms of service
                    </Anchor>
                </Group>
                <Space h={15} />
                <Group position="right">
                    <Button
                        disabled={!userAgrees}
                        onClick={() => {
                            closeModal('UserAgrees');
                            if (type == 'trial') {
                                onNext({ subscriptionId: trialId, term: 'Trial' });
                            } else {
                                onNext({ subscriptionId: selected?.Name!, price: selected?.Price, promoId: promoCode, promoConfig });
                            }
                        }}
                    >
                        Proceed
                    </Button>
                    <Button
                        variant="outline"
                        onClick={() => {
                            closeModal('UserAgrees');
                        }}
                    >
                        Cancel
                    </Button>
                </Group>
            </Box>
        );
    }

    const modals = useModals();
    const openTermsOfServiceModal = (type: string) => {
        const id = modals.openModal({
            zIndex: 500,
            title: (
                <Text size={18} weight={600} color={colorPalette.darkTitleColor}>
                    CloudSaver Terms of Service
                </Text>
            ),
            children: <UserAgrees type={type} />,
            sx: { borderRadius: theme.radius?.lg, padding: '32px' },
        });
    };
    return (
        <>
            <Title style={{ fontWeight: 'bolder', fontSize: 20 }} align="center">
                {(promo || selected) && hasDefaultSelection ? '' : 'Select '} Subscription Payment Terms
            </Title>
            <Space h="sm" />
            <Text align="center" hidden={promoConfig?.HideTrialOption || selected?.Term === 'Trial'}>
                <Space h="xl" />
                <Anchor
                    onClick={() => {
                        openTermsOfServiceModal('trial');
                    }}
                >
                    Start 30 Day Free Trial
                </Anchor>
            </Text>
        </>
    );
}

function SubsidizedSubscriptionOption({
    onSelect,
    selected,
    name,
    price,
    term,
}: {
    onSelect: () => void;
    selected: boolean;
    name: string;
    price: number;
    term: string;
}) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const borderColor = selected ? (theme.colors?.primary?.[6] as CustomColors) : undefined;
    const background = selected ? theme.colors.primary[1] : undefined;
    const borderWidth = selected ? 2 : 1;
    const padding = selected ? 9 : 10;
    return (
        <CardEl
            radius="lg"
            withBorder
            onClick={onSelect}
            sx={{
                cursor: 'pointer',
                borderColor,
                borderWidth,
                padding,
                background,
            }}
        >
            <Group position="apart">
                <Box>
                    <Grid>
                        <Grid.Col span={1}>
                            <ThemeIcon variant="light" color={selected ? 'primary' : 'gray'} size="xs" sx={{ marginTop: term === 'Trial' ? 4 : 0 }}>
                                {selected ? <CircleCheck /> : <Circle />}
                            </ThemeIcon>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <Text size={14} weight="bolder" color={theme.colors?.gray?.[8] as CustomColors}>
                                Partner Program Offer
                            </Text>{' '}
                            <Text size={14} color={theme.colors?.gray?.[5] as CustomColors}>
                                AMD Partner Program Subscription
                            </Text>
                        </Grid.Col>
                        <Grid.Col span={3} style={{ float: 'right', justifyContent: 'right', gap: 0 }}>
                            <Text style={{ paddingRight: 0 }} size={14} strikethrough>
                                {fmtSvc.formatMoneyNoDecimals(price)}
                            </Text>
                            <Text size={14}>$0 / {term.toLowerCase()}</Text>
                        </Grid.Col>
                    </Grid>
                </Box>
            </Group>
        </CardEl>
    );
}

function SubscriptionOption({
    onSelect,
    selected,
    name,
    price,
    term,
    isSixMonthPromo,
}: {
    onSelect: () => void;
    selected: boolean;
    name: string;
    price: number;
    term: string;
    isSixMonthPromo: boolean;
}) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const borderColor = selected ? (theme.colors?.primary?.[6] as CustomColors) : undefined;
    const background = selected ? theme.colors.primary[1] : undefined;
    const borderWidth = selected ? 2 : 1;
    const padding = selected ? 9 : 10;
    return (
        <CardEl
            radius="lg"
            withBorder
            onClick={onSelect}
            sx={{
                cursor: 'pointer',
                borderColor,
                borderWidth,
                padding,
                background,
            }}
        >
            <Group position="apart">
                <Box>
                    {/* <Text color={selected ? 'primary' : undefined} weight="bold">
                        {name}
                    </Text> */}
                    <Grid>
                        <Grid.Col span={1}>
                            {term !== 'Trial' && (
                                <>
                                    {' '}
                                    <Space h="xs" />
                                </>
                            )}
                            <ThemeIcon variant="light" color={selected ? 'primary' : 'gray'} size="xs" sx={{ marginTop: term === 'Trial' ? 4 : 0 }}>
                                {selected ? <CircleCheck /> : <Circle />}
                            </ThemeIcon>
                        </Grid.Col>
                        <Grid.Col span={4} hidden={isSixMonthPromo || term === 'Trial'}>
                            <Text size={14} weight="bold">
                                {term === 'Year' ? 'Pay Upfront' : 'Pay Monthly'}
                            </Text>
                            <Text sx={{ whiteSpace: 'nowrap' }} size={12}>
                                Commit Annually
                            </Text>
                        </Grid.Col>
                        <Grid.Col hidden={term === 'Trial'} span={7}>
                            {' '}
                            <Group style={{ float: 'right', justifyContent: 'right', gap: 0 }}>
                                <Text size={14} hidden={price === 0} color={selected ? 'primary' : undefined}>
                                    {term === 'Year' ? fmtSvc.formatMoneyNoDecimals(price / 12) : fmtSvc.formatMoneyNoDecimals(price)} / month
                                </Text>
                                <Text hidden={term === 'Trial'} size={12}>
                                    Billed at {term === 'Year' ? fmtSvc.formatMoneyNoDecimals(price) : fmtSvc.formatMoneyNoDecimals(price)} /{' '}
                                    {term!.toLocaleLowerCase()}
                                </Text>
                            </Group>
                        </Grid.Col>

                        <Grid.Col span={11} hidden={term !== 'Trial'}>
                            <Group>
                                <Text sx={{ whiteSpace: 'nowrap' }}>30-Day Free Trial</Text>
                                <Text sx={{ marginLeft: 74 }} strikethrough>
                                    $436
                                </Text>
                                $0
                                <Text sx={{ marginLeft: -10 }}></Text>
                            </Group>
                        </Grid.Col>
                    </Grid>
                </Box>
            </Group>
        </CardEl>
    );
}

function CompanyInfo({ onNext, companyDefaults }: { onNext: (company: CompanyInfo) => void; companyDefaults?: CompanyInfo }) {
    const form = useForm({
        initialValues: {
            companyName: '',
            webUrl: '',
            ...companyDefaults,
        },
        validate: {
            companyName: (value) =>
                value.length > 50
                    ? 'Max length for company name is 50'
                    : value.replace(/[^0-9a-z]/gi, '').length < 3
                    ? 'Company name must contain at least 3 letters or numbers'
                    : null,
        },
    });

    const next = useCallback(() => {
        if (!form.validate().hasErrors) {
            onNext(form.values);
        }
    }, [form.validate, form.values, onNext]);
    return (
        <>
            <TextInput placeholder="Company name" {...form.getInputProps('companyName')} required maxLength={50} onBlur={() => form.validate()} />
            <Space h="lg" />
            <TextInput placeholder="Company website (optional)" {...form.getInputProps('companyWebsite')} />
            <Space h="xl" />
            <Button fullWidth onClick={next}>
                Create Company
            </Button>
        </>
    );
}

class ThrottledQueue<T> {
    private items: T[] = [];
    private dequeuing = false;

    public constructor(private readonly throttleMs: number) {}

    public enqueue = (item: T) => {
        this.items.push(item);
        if (!this.dequeuing) {
            this.dequeue();
        }
    };
    public onDequeued = new EventEmitter<T>(undefined!);
    private dequeue = () => {
        if (this.items.length) {
            this.dequeuing = true;
            this.onDequeued.emit(this.items.shift()!);
            setTimeout(this.dequeue, this.throttleMs);
        } else {
            this.dequeuing = false;
        }
    };
}

function FinishingRegistration({
    subscription,
    companyInfo,
    onCompleted,
    provisionResult,
    company,
    regSvc,
    onBack,
}: {
    subscription: SubscriptionInfo;
    companyInfo: CompanyInfo;
    onCompleted: () => void;
    provisionResult?: boolean;
    company?: Company;
    regSvc: RegistrationService;
    onBack: () => void;
}) {
    const messageDuration = 2000;
    const { goto } = useNav();
    useEffect(() => regSvc.dispose, [regSvc]);
    const { onDequeued, enqueue } = useMemo(() => new ThrottledQueue<string>(messageDuration), []);
    useEvent(regSvc.state, (state) => {
        if (state === 'provisioning') {
            enqueue(`Provisioning workspace...`);
        } else if (state === 'failed') {
            enqueue(`Provisioning failed...`);
        } else if (state === 'subscribing') {
            enqueue(`Starting subscription...`);
        } else if (state === 'finished') {
            enqueue(`Redirecting to dashboard`);
        }
    });

    useEffect(() => {
        regSvc.init(companyInfo, subscription);
        regSvc.register(provisionResult, company);
        pushMessage(`Setting up company...`);
    }, []);
    const [messageList, { push: pushMessage }] = useList<string>([]);
    useEvent(onDequeued, (msg) => {
        if (msg) {
            pushMessage(msg);
            if (regSvc.state.value === 'finished') {
                setTimeout(() => {
                    if (regSvc.company?.DatabaseName) {
                        window.location.href = `${regSvc.company?.DatabaseName}/home/dashboard`;
                    }
                    onCompleted();
                }, messageDuration);
            }
            if (regSvc.state.value === 'failed') {
                setTimeout(() => {
                    goto(`_registerfailed`);
                    onCompleted();
                }, messageDuration);
            }
        }
    });

    return (
        <>
            <Space h="xl" />
            <Divider mx={-24} />
            <Space h="xl" />
            <Group>
                <Loader />
                <Box sx={{ height: 32 }}>
                    {[
                        ...messageList.map((msg, i) => (
                            <Transition
                                key={i}
                                duration={300}
                                timingFunction="ease"
                                transition={i === messageList.length - 1 ? 'slide-down' : 'slide-up'}
                                mounted={i === messageList.length - 1}
                            >
                                {(styles) => (
                                    <Text
                                        size="xl"
                                        color={theme.colors?.primary?.[6] as CustomColors}
                                        style={{
                                            ...styles,
                                            position: 'absolute',
                                            overflow: 'hidden',
                                            width: 350,
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {msg}
                                    </Text>
                                )}
                            </Transition>
                        )),
                        <Transition key={messageList.length} mounted={false} transition="slide-down">
                            {() => <Text></Text>}
                        </Transition>,
                    ]}
                </Box>
            </Group>
        </>
    );
}

@injectable()
export class RegistrationService {
    private poller?: PollingPromise<JobHierarchyProgress | undefined>;
    public state = new EventEmitter<'ready' | 'creating' | 'provisioning' | 'subscribing' | 'failed' | 'finished'>('ready');
    public company?: Company;
    public companyInfo?: CompanyInfo;
    public subscriptionInfo?: SubscriptionInfo;
    public address?: Address;
    public payment?: { token: string; card: Card };

    public constructor(private readonly jobSvc: JobService, private readonly api: BasicApi, private readonly nav: NavigationService) {
        const locallyStored = localStorage.getItem('CS:Register');
        if (locallyStored) {
            const parsedlocallyStored = JSON.parse(locallyStored);
            if (parsedlocallyStored[0].companyInfo) {
                this.companyInfo = parsedlocallyStored[0].companyInfo;
            }

            if (parsedlocallyStored[1].subscriptionInfo) {
                this.subscriptionInfo = parsedlocallyStored[1].subscriptionInfo;
            }

            if (parsedlocallyStored[2].address) {
                this.address = parsedlocallyStored[2].address;
            }
        }
    }

    public init(companyInfo: CompanyInfo, subscriptionInfo: SubscriptionInfo) {
        this.companyInfo = companyInfo;
        this.subscriptionInfo = subscriptionInfo;
        this.setLocalStorage();
    }

    public setCompany(companyInfo: CompanyInfo) {
        this.companyInfo = companyInfo;
        this.setLocalStorage();
    }

    public setAddress(address: Address) {
        this.address = address;
        this.setLocalStorage();
    }

    private setLocalStorage() {
        localStorage.setItem(
            'CS:Register',
            JSON.stringify([{ companyInfo: this.companyInfo }, { subscriptionInfo: this.subscriptionInfo }, { address: this.address }])
        );
    }

    public dispose = () => {
        this.poller?.cancel;
    };

    public async createEstimate(estimateRequest: EstimateRequest) {
        return await postInvoiceCreateEstimate({
            SubscriptionType: estimateRequest.SubscriptionType,
            Address: estimateRequest.Address,
            PromoId: estimateRequest.PromoId,
            PlanId: estimateRequest.PlanId,
            CreditPackage: estimateRequest.CreditPackage,
            CompanyId: estimateRequest.CompanyId,
            ExistingSubscriptionId: estimateRequest.ExistingSubscriptionId,
        });
    }

    public async checkCompany(promoCode: string | undefined) {
        return false;
    }

    public setPayment(token: string, card: Card) {
        this.payment = { token, card };
    }

    public async register(provisionResult: boolean | undefined, company: Company | undefined) {
        this.state.emit('creating');
        try {
            if (provisionResult === undefined) {
                const { provisionResult: newProvisioned, company: newCompany } = await this.provisionCompany();
                provisionResult = newProvisioned;
                company = newCompany;
                //await postSubscriptionUpdatePrimaryContactToCurrentUser();
            }
            if (!provisionResult || !company) {
                this.state.emit('failed');
            } else {
                //var isAddPaymentSuccess = await this.addPayment();
                // if (isAddPaymentSuccess) {
                //     this.state.emit('subscribing');
                //     await this.createSubscription(company.Id!);
                //     this.loadCompany(company.Id!);
                // } else {
                //     this.state.emit('failed');
                // }
            }
            localStorage.removeItem('CS:Register');
        } catch (err) {
            //console.log(`error: ${JSON.stringify(err)}`);
            this.state.emit('failed');
        }
    }

    private async provisionCompany() {
        const company = await putCompanyRegisterCompany(this.companyInfo);
        this.state.emit('provisioning');
        this.api.registerPrerequestHandler((_, request) => {
            (request.headers as Record<string, any>)['x-tenant-id'] = company.Id;
        });
        const provisionResult = await this.awaitProvisionResult(company);
        return { provisionResult, company };
    }

    private async addPayment() {
        if (this.payment) {
            var isSuccess = await postPaymentAddPaymentSource(this.payment.card, { token: this.payment.token, isPrimary: true });
            if (isSuccess) {
                await postNewSubscriptionUpdateCompanyInfo({
                    BillingAddress: {
                        AddressLine1: this.address?.AddressLine1,
                        AddressLine2: this.address?.AddressLine2,
                        City: this.address?.City,
                        StateCode: this.address?.StateCode,
                        CountryCode: this.address?.CountryCode,
                        ZipCode: this.address?.ZipCode,
                    },
                    AgreementAccepted: true,
                    CompanyWebsite: this.companyInfo?.webUrl,
                } as CompanyUpdate);

                // Primary contact has to be an active user
                //await postSubscriptionUpdatePrimaryContactToCurrentUser();
            }
            return isSuccess;
        }
        return true;
    }

    private async awaitProvisionResult(company: Company) {
        const { poller } = await this.jobSvc.waitForJobHierarchyByJobId(company.ProvisionJobId!);
        this.poller = poller;
        const result = await poller;
        return !result?.Failed;
    }

    private async loadCompany(companyId: number) {
        const companies = await getUserGetMyCompanies();
        const company = companies.find((c) => c.Id === companyId);
        if (!company || !company.DatabaseName) {
            this.state.emit('failed');
        } else {
            this.company = company;
            this.state.emit('finished');
        }
    }
}

const useStyles = createStyles((theme) => ({
    cardStyles: {
        border: `solid 1px #0002`,
        borderRadius: `${theme.radius?.md}px`,
        backgroundColor: colorPalette.white,
        padding: `${theme.spacing.lg}px`,
        marginTop: `${theme.spacing.md}px`,
        maxWidth: '780px',
    },

    arrow: {
        i: {
            color: colorPalette.pendingVerificationArrowColor,
        },
    },

    linkStyles: {
        '&:hover': {
            color: colorPalette.linkHoverColor,
        },
    },

    cardNumber: {
        width: '400px',
        color: colorPalette.subHeaderTextColor,

        label: {
            fontSize: '14px',
            fontWeight: 500,
            color: colorPalette.subHeaderTextColor,
        },
    },

    cardCvv: {
        width: '96px',
        color: colorPalette.subHeaderTextColor,

        label: {
            fontSize: '14px',
            fontWeight: 500,
            color: colorPalette.subHeaderTextColor,
        },
    },

    cardExpiry: {
        width: '100px',
        color: colorPalette.subHeaderTextColor,

        label: {
            fontSize: '14px',
            fontWeight: 500,
            color: colorPalette.subHeaderTextColor,
        },
    },

    cardBox: {
        border: `solid 1px ${colorPalette.navPopupHeaderColor}`,
        borderRadius: `${theme.radius.sm}px`,
        padding: '10px 14px ',
        fontSize: '16px',
    },

    primaryBox: {
        label: {
            fontSize: '16px',
            fontWeight: 500,
            color: colorPalette.subHeaderTextColor,
        },
    },

    nonCardItems: {
        label: {
            fontSize: '14px',
            fontWeight: 500,
            color: colorPalette.subHeaderTextColor,
        },

        input: {
            border: `solid 1px ${colorPalette.navPopupHeaderColor}`,
            borderRadius: `${theme.radius.sm}px`,
            padding: '10px 14px ',
        },
    },

    iFrameModalStyles: {
        alignItems: 'center',
    },
}));

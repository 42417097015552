import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {
    Button,
    Drawer,
    Modal,
    Stepper,
    Textarea,
    UnstyledButton,
    Loader,
    Badge,
    Card,
    Title,
    Text,
    Box,
    Grid,
    Space,
    Anchor,
    useMantineTheme,
} from '@mantine/core';
import { useClipboard, useHover } from '@mantine/hooks';
import { ToolTip } from '@root/Design/Primitives';
import { CustomColors, theme } from '@root/Design/Themes';
import { useNav } from '@root/Services/NavigationService';
import { useLink } from '@root/Services/Router/Router';
import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { CircleCheck } from 'tabler-icons-react';

export function WizardFormTitle(props: { title: string }) {
    return (
        <Title my={32} order={1} align="center">
            {props.title}
        </Title>
    );
}

export function WizardFormStepTitle(props: { title: string; infoText?: string; infoSize?: 'small' | 'big' }) {
    if (props.infoText) {
        return (
            <Text size="xl" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                {props.title}
                {props.infoText ? <ToolTip infoText={props.infoText} infoSize={props.infoSize}></ToolTip> : null}
            </Text>
        );
    } else {
        return <Text size="xl">{props.title}</Text>;
    }
}

export function WizardFormStepSubTitle(props: { subTitle?: ReactNode }) {
    const theme = useTheme();
    return (
        <Text mt={theme.spacing.xs / 2} ml="xl" mb="lg">
            {props.subTitle}
        </Text>
    );
}

export function WizardFormStep(props: { children: ReactNode; title: string; subTitle?: ReactNode; infoText?: string; infoSize?: 'small' | 'big' }) {
    return (
        <Box p="xl">
            <WizardFormStepTitle title={props.title} infoText={props.infoText} infoSize={props.infoSize}></WizardFormStepTitle>
            {props.subTitle ? <WizardFormStepSubTitle subTitle={props.subTitle}></WizardFormStepSubTitle> : null}
            {props.children}
        </Box>
    );
}

export function WizardToolbar({ steps, activeState, platform }: { steps: { url: string; text: string }[]; activeState: number; platform: string }) {
    const { move, getMoveUrl } = useNav();
    const link = useLink();
    const getSteps = () => {
        let content = [];
        for (let i = 0; i < steps.length; i++) {
            const step = steps[i];
            const state = i === activeState ? 'stepProgress' : i < activeState ? 'stepCompleted' : 'stepInactive';
            content.push(
                <Stepper.Step
                    data-atid={'WizardStep' + (i + 1)}
                    completedIcon={<div>{i + 1}</div>}
                    style={{ cursor: state == 'stepCompleted' ? 'pointer' : 'default' }}
                    key={step.text}
                    description={step.text}
                    state={state}
                ></Stepper.Step>
            );
        }
        return content;
    };
    const handleStepClick = useCallback(
        (step: number) => {
            if (step < activeState) {
                move(steps[step].url, { platform });
            }
        },
        [steps, activeState]
    );

    return (
        <>
            <div>
                <WizardToolbarContainer>
                    {activeState > 0 ? (
                        <Anchor
                            size={40}
                            data-atid="WizardBackButton"
                            className="ti ti-chevron-left"
                            style={{ textDecoration: 'none', color: 'gray', float: 'left' }}
                            {...link(activeState < 2 ? getMoveUrl('connections') : getMoveUrl(steps[activeState - 2].url, { platform }))}
                            color={theme.colors?.primary?.[6] as CustomColors}
                        />
                    ) : null}
                    <Stepper onStepClick={handleStepClick} active={activeState} size="sm" breakpoint="sm">
                        {getSteps()}
                    </Stepper>
                </WizardToolbarContainer>
            </div>
        </>
    );
}

export function InfoSidebar(props: { children: ReactNode; title: string; buttonTitle: string }) {
    const [opened, setOpened] = useState(false);

    return (
        <>
            <StyledDrawer opened={opened} onClose={() => setOpened(false)} title={props.title} size="xl" padding="xl" position="right">
                <InfoSidebarContainer>{props.children}</InfoSidebarContainer>
            </StyledDrawer>
            <UnstyledButton onClick={() => setOpened(true)} style={{ color: theme.colors?.primary?.[6] as CustomColors, fontWeight: 'bold' }}>
                {props.buttonTitle}
            </UnstyledButton>
        </>
    );
}

export function FullPageModal(props: { children: ReactNode }) {
    const [opened, setOpened] = useState(false);

    return (
        <>
            <Modal opened={opened} onClose={() => setOpened(false)}>
                {props.children}
            </Modal>

            <Button onClick={() => setOpened(true)}>Open Modal</Button>
        </>
    );
}

export function ScriptTextArea(props: { label: string; placeholder?: string; minRows: number; maxRows: number; value: string }) {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const clipboard = useClipboard({ timeout: 500 });
    return (
        <ScriptTextAreaContainer>
            <Textarea
                ref={textAreaRef}
                placeholder={props.placeholder}
                onChange={() => {}}
                minRows={props.minRows}
                maxRows={props.maxRows}
                value={props.value}
            />
            <Button
                variant="white"
                color={clipboard.copied ? 'success' : 'primary'}
                leftIcon={<i className="ti ti-copy"></i>}
                onClick={() => clipboard.copy(textAreaRef.current?.value)}
            >
                {clipboard.copied ? 'Copied' : 'Copy'}
            </Button>
        </ScriptTextAreaContainer>
    );
}

export enum LoadingState {
    loading,
    success,
    failed,
    check,
}

export function LoadingValidation(props: { loadingState: LoadingState; text: string }) {
    let icon: ReactNode;
    const theme = useMantineTheme();
    if (props.loadingState == LoadingState.loading) {
        icon = <Loader color={theme.colors?.primary?.[6] as CustomColors} variant="dots" size="sm" mr="sm" />;
    } else if (props.loadingState == LoadingState.success) {
        icon = <i className="ti ti-check"></i>;
    } else if (props.loadingState == LoadingState.failed) {
        icon = <i className="ti ti-x"></i>;
    } else if (props.loadingState == LoadingState.check) {
        icon = <CircleCheck style={{ marginBottom: -6 }} fill={theme.colors.success[5]} color="#fff" />;
    }

    return (
        <LoadingValidationContainer>
            <Text color={props.loadingState === LoadingState.loading ? 'gray' : undefined}>
                {icon} {props.text}
            </Text>
        </LoadingValidationContainer>
    );
}

export function WizardDownload(props: {
    fileLocation: string;
    displayText: string;
    displayTitle: string;
    imgLocation: string;
    disable: boolean;
    disabledText: string;
    atid?: string;
}) {
    const { ref, hovered } = useHover<HTMLAnchorElement>();
    const linkClick = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            if (props.disable) {
                e.preventDefault();
            }
        },
        [props.disable]
    );
    return (
        <WizardDownloadWrapper>
            <Card
                component="a"
                ref={ref}
                py={6}
                px={12}
                withBorder
                sx={{ display: 'inline-block', width: 220, background: hovered ? ('#8881' as CustomColors) : undefined }}
                style={props.disable ? { pointerEvents: 'none', opacity: 0.4 } : {}}
                href={props.fileLocation}
                onClick={linkClick}
                download
                data-atid={props.atid}
            >
                <img src={props.imgLocation} />
                <Text weight="bold">{props.displayTitle}</Text>
                <Text color={theme.colors?.primary?.[6] as CustomColors} size="sm" sx={{ display: 'block' }}>
                    <span style={{ textDecoration: hovered ? 'underline' : undefined }}>{props.displayText}</span>
                    <Space w="sm" sx={{ display: 'inline-block' }} />
                    <i className="ti ti-download" />
                </Text>
            </Card>
        </WizardDownloadWrapper>
    );
}

export function CardBadge(props: { children: ReactNode; recommended?: boolean }) {
    return (
        <Badge size="lg" radius="lg" color={!props.recommended ? 'primary' : 'success'} sx={{ textTransform: 'none' }}>
            {props.children}
        </Badge>
    );
}

export const WizardFormHeader = styled.div`
    margin: ${(p) => p.theme.spacing.xl}px 0;
    padding: 0 ${(p) => p.theme.spacing.xl * 2}px;
`;

export function WizardForm(props: { children: ReactNode; maxWidth?: number }) {
    const theme = useTheme();
    return (
        <Grid columns={12} sx={{ maxWidth: props.maxWidth ?? theme.breakpoints.xl, margin: '0 auto' }}>
            <Grid.Col md={10} offsetMd={1} lg={8} offsetLg={2}>
                {props.children}
            </Grid.Col>
        </Grid>
    );
}

export function WizardFormStepContainer(props: { children: ReactNode }) {
    return (
        <Card shadow="sm" radius="md" withBorder p={0}>
            {props.children}
        </Card>
    );
}
export function WizardFormStepSection(props: { children: ReactNode }) {
    return <Box p="xl">{props.children}</Box>;
}
export const WizardFormFooter = styled.div`
    padding: 20px 40px;
    background-color: #f7f9fa;
    margin: -16px;
    margin-top: 0;
    border-top: solid 1px ${(p) => p.theme.colors.gray[4]};
`;

export const WizardToolbarContainer = styled.div`
    .mantine-Stepper-stepIcon {
        position: relative;
        margin: 0px auto ${(p) => p.theme.spacing.xs}px;
        background-color: ${(p) => p.theme.colors?.gray?.[3] as CustomColors as CustomColors};
        outline: ${(p) => p.theme.colors?.gray?.[4] as CustomColors};
    }

    .mantine-Stepper-stepIcon[data-progress] {
        background-color: ${(p) => p.theme.colors.gray[0] as CustomColors};
    }

    .mantine-Stepper-stepIcon[data-completed] {
        background-color: ${theme.colors?.primary?.[6] as CustomColors};
    }

    .mantine-Stepper-stepBody {
        width: 100%;
        margin: 0px;
        font-weight: bold;

        .mantine-Stepper-stepDescription {
            color: ${(p) => p.theme.colors?.gray?.[9]};
            font-size: ${(p) => p.theme.fontSizes.md}px;
        }
    }

    .mantine-Stepper-separator {
        margin-top: -${(p) => p.theme.spacing.xl + p.theme.spacing.md}px;
        background-color: ${(p) => p.theme.colors?.gray?.[4] as CustomColors};
        margin-left: 0px;
        margin-right: 0px;
    }

    .mantine-Stepper-separatorActive {
        background-color: ${theme.colors?.primary?.[6] as CustomColors};
    }

    .mantine-UnstyledButton-root {
        display: initial;
    }
`;

export const InfoSidebarContainer = styled.div`
    p {
        font-weight: normal;
        font-size: 12px;
    }

    h3 {
        font-size: 18px;
        font-weight: normal;
    }
`;

export const StyledDrawer = styled(Drawer)`
    .mantine-Drawer-closeButton {
        position: absolute;
        top: 0px;
        display: block;
        left: 0px;

        svg {
            width: 30px;
            height: 30px;
        }
    }
    .mantine-Drawer-title {
        font-size: 20px;
        margin-top: 10px;
        width: 100%;
        text-align: left;
    }
    overflow: auto;
`;

export const FullPageModalHeader = styled.div<{ dark?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
    margin: 0 auto;
    padding: 0 24px;
    background: ${(p) => (p.dark ? p.theme.colors.primary[9] : undefined)};
`;

export const ScriptTextAreaContainer = styled.div`
    position: relative;

    textarea {
        background-color: ${(p) => p.theme.white};
        border-color: ${(p) => p.theme.colors?.gray?.[2] as CustomColors};
        border-radius: 0px;
        min-height: 150px;
    }

    button {
        position: absolute;
        top: ${(p) => p.theme.spacing.xs}px;
        right: ${(p) => p.theme.spacing.xl}px;
    }
`;

export const LoadingValidationContainer = styled.div`
    span {
        font-size: 14px;
    }

    i {
        margin-right: ${(p) => p.theme.spacing.xs}px;
        font-size: 20px;
    }

    .ti-check,
    .ti-circle-check {
        color: green;
    }

    .ti-x {
        color: red;
    }
`;

export const WizardDownloadWrapper = styled.div`
    img {
        float: left;
        width: 36px;
        margin-top: 4px;
        margin-right: ${(p) => p.theme.spacing.xs}px;
    }
`;

export const BadgeHolder = styled.div`
    height: 55px;
`;

export function WizardCard(props: { children: ReactNode; button: ReactNode }) {
    return (
        <Card withBorder shadow="sm" radius="md" sx={{ height: '100%', flexDirection: 'column', justifyContent: 'stretch', display: 'flex' }}>
            <Box sx={{ flex: '1 1 100%' }}>
                {props.children}
                <Space h="lg" />
            </Box>
            {props.button}
        </Card>
    );
}

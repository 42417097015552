import { getAccountGetCompanyAccounts } from '@apis/Customers';
import { postResourcesQuery } from '@apis/Resources';
import {
    Accordion,
    ActionIcon,
    Badge,
    Box,
    Button,
    Card,
    Divider,
    Group,
    LoadingOverlay,
    Radio,
    Space,
    Tabs,
    Text,
    Title,
    Tooltip,
} from '@mantine/core';
import { DataGrid } from '@root/Components/DataGrid';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { ChildAccessor, ColumnConfig, DataGridState } from '@root/Components/DataGrid/Models';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';
import { PageBody, PagePanel, PanelBody, PaneledPage } from '@root/Design/Layout';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { useId } from '@root/Services/IdGen';
import { useNav } from '@root/Services/NavigationService';
import { queryBuilder, ValuesGroupOtherText } from '@root/Services/QueryExpr';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useLink } from '@root/Services/Router/Router';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { OpenSidebar } from '../Components/OpenSidebar';
import { TagConsolidationSidePanel } from '../Components/TagConsolidationSidePanel';
import { PendingChangesIndicator } from './PendingChangesIndicator';
import { TagIntelligencePage } from './TagIntelligencePage';
import { VisibleSpaces } from '@root/Components/Text/VisibleSpaces';
import { AnchorButton } from '@root/Design/Primitives';
import { CircleMinus, CirclePlus, Key, MilitaryRank, Trash } from 'tabler-icons-react';
import { useDisclosure } from '@mantine/hooks';
import { AlphabetPicker } from '@root/Components/Picker/AlphabetPicker';
import { FilterItem, FilterToken } from '@root/Components/Filter/Design';

export function UniqueTagsContent() {
    const [connected, setConnected] = useState<null | boolean>();
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [state, setState] = useState<DataGridState>();
    const [tagsToUpdate, setTagsToUpdate] = useState<string[]>();
    const [tagKey, setTagKey] = useState<string>();
    const [sidePanelState, setSidePanelState] = useState<'key' | 'value'>();
    const [operation, setOperation] = useState<OpenSidebar>();
    const panelStateChanged = useMemo(() => new EventEmitter(false), []);
    const [showHint, { toggle: toggleHint }] = useDisclosure(false);
    const model = useMemo(() => ({ clear() {} }), []);
    const provideClearMethod = useCallback((clearHandler: () => void) => (model.clear = clearHandler), [model]);
    const onButtonClick = useCallback((mode: 'key' | 'value') => {
        setOpenModal(true);
        setSidePanelState(mode);
        setOperation(mode === 'key' ? OpenSidebar.renameTags : OpenSidebar.changeTagValues);
    }, []);
    const onSelectionChange = useCallback(
        (tags: string[], tagKey: string, stateData: DataGridState) => {
            setTagsToUpdate(tags);
            setTagKey(tagKey);
            setState(stateData);
        },
        [setTagsToUpdate, setTagKey, setState]
    );

    const GridRenderer = useMemo(() => {
        return () => {
            const panelOpen = useEventValue(panelStateChanged);
            return (
                <UniqueTagsGrid
                    enableButtons={!panelOpen}
                    onButtonClick={onButtonClick}
                    onSelectionChange={onSelectionChange}
                    clearHandler={provideClearMethod}
                    onModeChange={() => setOpenModal(false)}
                />
            );
        };
    }, []);
    useEffect(() => {
        (async () => {
            const accounts = await getAccountGetCompanyAccounts();
            setConnected(accounts.length > 0);
            setLoading(false);
        })();
    }, []);
    useEffect(() => {
        panelStateChanged.emit(openModal);
    }, [openModal]);

    function closeModal() {
        model.clear();
        setOpenModal(false);
    }

    return (
        <>
            <TagIntelligencePage>
                <PaneledPage>
                    <PagePanel size="fill">
                        <PanelBody>
                            <Group position="apart" align="center">
                                <Title data-atid="IntelligenceHeader" order={2}>
                                    Tag Consolidation
                                </Title>
                                <AnchorButton
                                    iconPosition="right"
                                    icon={!showHint ? <CirclePlus size={16} /> : <CircleMinus size={16} />}
                                    text={showHint ? 'Hide Hint' : 'Show Hint'}
                                    color="gray"
                                    onClick={toggleHint}
                                />
                            </Group>
                            <Accordion
                                variant="separated"
                                radius="lg"
                                sx={{ display: showHint ? 'block' : 'none' }}
                                value={showHint ? 'CleanTags' : undefined}
                                my="xl"
                                chevron={<i className="ti ti-circle-minus" />}
                            >
                                <Accordion.Item value="CleanTags" px="xl" py="md">
                                    <Accordion.Panel color="gray">
                                        <div style={{ height: '500px' }}>
                                            {' '}
                                            <iframe
                                                src="https://app.tango.us/app/embed/fac30e04-3c1f-419f-a4bb-313240b2e0fe"
                                                sandbox="allow-scripts allow-top-navigation-by-user-activation allow-popups allow-same-origin"
                                                security="restricted"
                                                title="Using Consolidation Tool to Consolidate Tag Keys"
                                                width="100%"
                                                height="100%"
                                                frameBorder={0}
                                                referrerPolicy="strict-origin-when-cross-origin"
                                                allowFullScreen={true}
                                            ></iframe>
                                        </div>
                                    </Accordion.Panel>
                                </Accordion.Item>
                            </Accordion>
                            <GridRenderer />
                        </PanelBody>
                    </PagePanel>
                    {openModal ? (
                        <>
                            <Divider orientation="vertical"></Divider>
                            <PagePanel size="sm">
                                <PageBody>
                                    <TagConsolidationSidePanel
                                        onClose={closeModal}
                                        sidePanelState={sidePanelState!}
                                        state={state!}
                                        tagsToUpdate={tagsToUpdate!}
                                        operation={operation!}
                                        tagKeyGettingValue={tagKey!}
                                        onReviewClick={closeModal}
                                        enableReview={!!tagsToUpdate?.length}
                                    ></TagConsolidationSidePanel>
                                </PageBody>
                            </PagePanel>
                        </>
                    ) : (
                        ''
                    )}
                </PaneledPage>
            </TagIntelligencePage>
        </>
    );
}

function UniqueTags() {
    return <ConnectionCheck>{() => <UniqueTagsContent />}</ConnectionCheck>;
}
endpoint('tag-consolidation', UniqueTags, 'Tag Intelligence');

interface TagsGridProps {
    enableButtons: boolean;
    onButtonClick(mode: 'key' | 'value'): void;
    onSelectionChange: (tags: string[], tagKey: string, stateData: DataGridState) => void;
    clearHandler: (handler: () => void) => void;
    onModeChange: (mode: string) => void;
}

function UniqueTagsGrid(props: TagsGridProps) {
    const [selectedTab, setSelectedTab] = useState('TagKeys');
    const [keyCount, setKeyCount] = useState('');
    const [valueCount, setValueCount] = useState('');
    const resourceSvc = useDi(ResourceService);
    const formatSvc = useDi(FormatService);

    useEffect(() => {
        (async () => {
            setKeyCount(formatSvc.formatInt((await resourceSvc.getTags()).length ?? 0));
            const max = 10001;
            const valueCount = (await resourceSvc.getAllTagValues(max)).length ?? 0;
            setValueCount(valueCount === max ? '> 10,000' : formatSvc.formatInt(valueCount));
        })();
    }, []);

    useEffect(() => {
        props.onModeChange(selectedTab);
    }, [selectedTab]);

    const gridProps = {
        onSelectionChange: props.onSelectionChange,
        clearHandler: props.clearHandler,
        enableButton: props.enableButtons,
    };

    return (
        <Tabs defaultValue="TagKeys" onTabChange={(v) => setSelectedTab(v ?? '')}>
            <Tabs.List mx={'-24px'} px={'12px'} mt="lg" mb="sm">
                <Tabs.Tab value="TagKeys" data-atid="TagConsolidationTagKeys">
                    <Group spacing={8}>
                        <Key size={18} strokeWidth={1}></Key>
                        <Text>Keys</Text>
                        <Badge>{keyCount}</Badge>
                    </Group>
                </Tabs.Tab>
                <Tabs.Tab value="TagValues" data-atid="TagConsolidationTagValues">
                    <Group spacing={8}>
                        <MilitaryRank size={18} strokeWidth={1} />
                        <Text>Values</Text>
                        <Badge>{valueCount}</Badge>
                    </Group>
                </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel pt="xs" value="TagKeys" sx={{ height: 500 }}>
                {selectedTab === 'TagKeys' ? <TagKeysGrid {...gridProps} onButtonClick={() => props.onButtonClick('key')} /> : null}
            </Tabs.Panel>
            <Tabs.Panel pt="xs" value="TagValues" sx={{ height: 500 }}>
                {selectedTab === 'TagValues' ? <TagValuesGrid {...gridProps} onButtonClick={() => props.onButtonClick('value')} /> : null}
            </Tabs.Panel>
        </Tabs>
    );
}

type TagValuesDataItem = { tag: string; tagSort: string; count: number };
interface TagValueProps {
    onButtonClick(): void;
    enableButton: boolean;
    onSelectionChange: (tags: string[], tagKey: string, stateData: DataGridState) => void;
    clearHandler: (handler: () => void) => void;
}

type ChildKey = { tag: string; parentTag: string };

export function TagValuesGrid(props: TagValueProps) {
    const [data, setData] = useState<TagValuesDataItem[]>();
    const [enableButton, setEnableButton] = useState(false);
    const [state, setState] = useState<DataGridState>();
    const [key, setKey] = useState<string>('');
    const [value, setValue] = useState<string>('');
    const [alphabet, setAlphabet] = useState<string[]>();
    const [firstLetter, setFirstLetter] = useState<string>();
    const selectedItem = useMemo(
        () => ({
            key: new EventEmitter<ChildKey | undefined>(undefined),
            grid: undefined as DataGridModel | undefined,
        }),
        []
    );
    useEffect(
        () =>
            props.clearHandler(() => {
                selectedItem.key.emit(undefined);
                setKey('');
                setValue('');
                setState({ columns: [], filters: [], sort: [] });
            }),
        [props.clearHandler, selectedItem, setKey, setState, selectedItem.key]
    );

    const getKeysByValue = useCallback(async (item: TagValuesDataItem) => {
        const results = await queryBuilder<{ [key: string]: string }>()
            .where((b) => b.model['Tags.Value'].eq(b.param(item.tag, 'string')))
            .select((b) => ({
                key: {
                    operation: 'Values',
                    operands: [
                        { field: 'CsTagValueLookup' },
                        { operation: 'startsWith', operands: [{ Value: item.tag + '\\\\' }] },
                        { Value: ValuesGroupOtherText },
                    ],
                } as unknown as string,
                count: b.count(),
            }))
            .execute(postResourcesQuery);

        const rawResults = results?.Results ?? [];
        const result = rawResults.map((o) => {
            const key = o.key.split('\\\\')[1] ?? '';
            return {
                tag: key,
                count: o.count,
                sortableKey: key.trim(),
                parentTag: item.tag,
            };
        });
        result.sort((a, b) => a.sortableKey.localeCompare(b.sortableKey, undefined, { sensitivity: 'base' }));
        return result;
    }, []);

    const loadAlphabet = async () => {
        const results = await queryBuilder<{ 'Tags.Value': string }>()
            .select((b) => ({
                letter: {
                    operation: 'Values',
                    operands: [{ operation: 'substring', operands: [{ field: 'Tags.Value' }, { Value: 0 }, { Value: 1 }] }],
                } as unknown as string,
                count: b.count(),
            }))
            .execute(postResourcesQuery);
        const uniqueLetters = new Set((results.Results ?? []).map((l) => (l.letter ?? '').toLocaleUpperCase()));
        const letters = [...uniqueLetters].sort();
        setAlphabet(letters);
    };

    const loadValues = async (firstLetter: string) => {
        const maxItems = 10000;
        const results = await queryBuilder<{ 'Tags.Value': string }>()
            .take(maxItems)
            .select((b) => ({
                tag: (firstLetter
                    ? {
                          operation: 'Values',
                          operands: [
                              { field: 'Tags.Value' },
                              {
                                  operation: 'regexp',
                                  operands: [{ value: ` *[${firstLetter.toUpperCase()}${firstLetter.toLocaleLowerCase()}].*` }],
                              },
                          ],
                      }
                    : b.model['Tags.Value']) as unknown as string,
                count: b.count(),
            }))
            .execute(postResourcesQuery);
        const tags =
            results.Results === null || results.Results === undefined || results.Results![0].count === 0
                ? []
                : results!.Results!.map((t) => ({ ...t, tagSort: t.tag.trim() }));
        tags.sort((a, b) => a.tagSort.localeCompare(b.tagSort));
        setData(tags);
    };

    useEffect(() => {
        loadValues(firstLetter ?? '');
    }, [firstLetter]);

    useEffect(() => {
        loadAlphabet();
        loadValues('');
    }, []);

    useEffect(() => {
        const state = {
            columns: key.length > 0 ? [{ id: `Tags.CsTags.${key}`, width: 120 }] : [],
            filters: key.length > 0 ? [{ Operation: 'eq', Operands: [{ Field: `CsTags.${key}` }, { Value: value }] }] : [],
            sort: [],
        };
        if (key.length > 0) {
            setEnableButton(true);
        } else {
            setEnableButton(false);
        }
        setState(state);
        props.onSelectionChange(value ? [value] : [], key, state);
    }, [key, value]);

    const columns = useMemo(() => {
        return [
            {
                header: 'Tag Value',
                accessor: 'tag',
                defaultWidth: 400,
                id: 'tag',
                sortField: 'tagSort',
                filter: {
                    filterField: 'tag',
                    filterType: 'string',
                    name: 'Tag Value',
                    options: { getValueProvider: () => data?.map((d) => ({ value: d.tag, label: d.tag })) },
                },
                cellRenderer: (item) => {
                    const selectedKey = useEventValue(selectedItem.key);
                    if (!('parentTag' in item)) {
                        return item.tag === '' ? <>« Empty »</> : <VisibleSpaces value={item.tag} />;
                    } else {
                        return (
                            <Group align="center" sx={{ height: '100%' }} noWrap>
                                <Radio
                                    size="xs"
                                    className="no-animation"
                                    checked={item.tag === selectedKey?.tag && item.parentTag === selectedKey.parentTag}
                                    value={''}
                                />
                                <VisibleSpaces value={item.tag} />
                                {' : '}
                                <VisibleSpaces value={item.parentTag} />
                            </Group>
                        );
                    }
                },
            },
            {
                header: 'Resource Count',
                accessor: 'count',
                defaultWidth: 150,
                id: 'count',
                type: 'number',
                align: 'right',
                filter: {
                    filterField: 'count',
                    filterType: 'number',
                    name: 'Count',
                },
            },
        ] as ColumnConfig<TagValuesDataItem | ChildKey>[];
    }, [data]);
    const childAccessor = useMemo(
        () =>
            ({
                hasChildren: (item) => {
                    if (!('parentTag' in item)) {
                        return true;
                    }
                    return false;
                },
                getChildren: (item) => {
                    if (!('parentTag' in item)) {
                        return getKeysByValue(item);
                    }
                    return [] as unknown;
                },
            } as ChildAccessor<TagValuesDataItem | ChildKey>),
        []
    );

    const button = (
        <Button
            disabled={!props.enableButton || !enableButton}
            onClick={() => {
                props.onButtonClick();
            }}
            rightIcon={<i className="ti ti-chevron-right" />}
        >
            Update Value
        </Button>
    );

    return !data ? (
        <Box sx={{ position: 'relative', height: '100%' }}>
            <LoadingOverlay visible overlayOpacity={0} />
        </Box>
    ) : (
        <GridContainer
            title="List of Unique Values"
            description="Select a key-value pair to update the associated value in Tag Explorer."
            button={button}
        >
            <Box sx={{ display: 'flex', height: '100%' }}>
                <Box sx={{ flex: 1, height: '100%', padding: '16px', overflow: 'hidden' }}>
                    <DataGrid
                        childAccessor={childAccessor}
                        dataSource={data}
                        columns={columns}
                        onRowClick={(item: TagValuesDataItem | ChildKey) => {
                            if ('parentTag' in item) {
                                selectedItem.key.emit(item);
                                setKey(item.tag);
                                setValue(item.parentTag);
                            } else {
                                selectedItem.grid?.treeModel?.toggle(item);
                            }
                        }}
                        displayMode="grid"
                        hideHeader
                        hideMenu={false}
                        hideColumnSelector
                        exportName="Unique Tag Values"
                        onModelLoaded={(g) => (selectedItem.grid = g)}
                        rightTopPlaceHolder={
                            firstLetter ? (
                                <FilterItem state="valid" style={{ cursor: 'unset' }}>
                                    <FilterToken>Value starts with "{firstLetter}"</FilterToken>
                                    <FilterToken>
                                        <Tooltip label="Remove filter">
                                            <ActionIcon onClick={() => setFirstLetter('')} data-atid="UniqueTagRemoveFilter">
                                                <Trash size="16" />
                                            </ActionIcon>
                                        </Tooltip>
                                    </FilterToken>
                                </FilterItem>
                            ) : null
                        }
                    />
                </Box>
                <Divider orientation="vertical" color="gray.3" />
                <Box sx={{ minWidth: '60px', height: '100%', flex: 0 }} pl="sm">
                    {alphabet ? <AlphabetPicker values={alphabet} height="100%" onSelect={setFirstLetter} selected={firstLetter} /> : null}
                </Box>
            </Box>
        </GridContainer>
    );
}

type TagKeysDataItem = { tag: string; tagSort: string; count: number };
interface TagKeysProps {
    onButtonClick(): void;
    enableButton: boolean;
    onSelectionChange: (tags: string[], tagKey: string, stateData: DataGridState) => void;
    clearHandler: (handler: () => void) => void;
}
function TagKeysGrid(props: TagKeysProps) {
    const [grid, setGrid] = useState<DataGridModel>();
    const [data, setData] = useState<TagKeysDataItem[]>();
    const { getMoveUrl } = useNav();
    const link = useLink();
    const [selected, setSelected] = useState<string[]>();
    const [enableButton, setEnableButton] = useState(false);
    const [state, setState] = useState<DataGridState>();
    const id = useId(data ?? []);

    useEffect(
        () =>
            props.clearHandler(() => {
                setSelected([]);
                grid?.selections.setSelectAll(false);
                setState({ columns: [], filters: [], sort: [] });
            }),
        [props.clearHandler, grid, setState, setSelected]
    );

    useEvent(grid?.selectionChanged, async () => {
        const getselected = (await grid?.selections.getSelected()) as TagKeysDataItem[];
        setSelected(getselected.map((s) => s.tag));
    });

    useEffect(() => {
        const state = {
            columns: selected?.length ? selected?.map((c) => ({ id: `Tags.CsTags.${c}`, width: 120 })) : [],
            filters: selected?.length ? [{ Operation: 'eq', Operands: [{ Field: `Tags.Key` }, { Value: selected }] }] : [],
            sort: [],
        };
        if (selected?.length ?? 0 > 0) {
            setEnableButton(true);
        } else {
            setEnableButton(false);
        }
        setState(state);
        props.onSelectionChange(selected ?? [], '', state);
    }, [selected]);

    useEffect(() => {
        (async () => {
            const results = await queryBuilder<{ 'Tags.Key': string; AccountID: boolean; Region: boolean }>()
                .select((b) => ({
                    tag: b.model['Tags.Key'],
                    count: b.count(),
                    accounts: b.countValues(b.model.AccountID),
                    regions: b.countValues(b.model.Region),
                }))
                .execute(postResourcesQuery);
            const tags =
                results.Results === null || results.Results === undefined || results.Results![0].count === 0
                    ? []
                    : results.Results.map((t) => ({ ...t, tagSort: t.tag.trim() }));
            tags.sort((a, b) => a.tagSort.localeCompare(b.tagSort));
            setData(tags);
        })();
    }, []);

    const operation: OpenSidebar = OpenSidebar.renameTags;
    const operationData = JSON.stringify(operation);

    const columns = useMemo(() => {
        return [
            {
                header: 'Tag Key',
                accessor: 'tag',
                defaultWidth: 500,
                id: 'tag',
                sortField: 'tagSort',
                cellRenderer: (item) => (
                    <PendingChangesIndicator>
                        {item.tag === '' ? '« Empty »' : <VisibleSpaces value={item.tag}></VisibleSpaces>}
                    </PendingChangesIndicator>
                ),
                filter: {
                    filterField: 'tag',
                    filterType: 'string',
                    name: 'Tag Key',
                    options: { getValueProvider: () => data?.map((d) => ({ value: d.tag, label: d.tag })) },
                },
            },
            {
                header: 'Resource Count',
                accessor: 'count',
                defaultWidth: 200,
                id: 'count',
                type: 'number',
                align: 'right',
                cellRenderer: (item) => item.count.toLocaleString(),
                filter: {
                    filterField: 'count',
                    filterType: 'number',
                    name: 'Count',
                },
            },
        ] as ColumnConfig<TagKeysDataItem>[];
    }, [data]);

    const button = (
        <Button
            data-atid="UpdateKeysButton"
            disabled={!enableButton || !props.enableButton}
            onClick={() => {
                props.onButtonClick();
            }}
            rightIcon={<i className="ti ti-chevron-right" />}
        >
            Update Keys
        </Button>
    );

    return !data ? (
        <Box sx={{ position: 'relative', height: '100%' }}>
            <LoadingOverlay visible overlayOpacity={0} />
        </Box>
    ) : (
        <GridContainer padding button={button} title="List of Unique Keys" description="Select keys to merge in Tag Explorer">
            <DataGrid
                key={id}
                dataSource={data}
                columns={columns}
                onRowClick="select"
                displayMode="grid"
                selectionMode={'multiple'}
                hideHeader
                hideMenu={false}
                hideColumnSelector
                onModelLoaded={setGrid}
                exportName="Unique Tag Keys"
            />
        </GridContainer>
    );
}

function GridContainer({
    children,
    title,
    description,
    button,
    padding,
}: {
    children: React.ReactNode;
    title: string;
    description: string;
    button: React.ReactNode;
    padding?: boolean;
}) {
    return (
        <Card p={0} radius="md" withBorder>
            <Box p="lg">
                <Group noWrap position="apart" align="center">
                    <Box>
                        <Title order={4}>{title}</Title>
                        <Text size="sm">{description}</Text>
                    </Box>
                    {button}
                </Group>
            </Box>
            <Divider color="gray.3" />
            <Box p={padding ? 'md' : 0} sx={{ height: '600px' }}>
                {children}
            </Box>
        </Card>
    );
}

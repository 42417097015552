import { postRecommendationQuery } from '@apis/Recommendations';
import { Recommendation } from '@apis/Recommendations/model';
import { SchemaType } from '@apis/Resources/model';
import { inject, injectable } from 'tsyringe';
import { FormatService } from '../FormatService';
import { exprBuilder, queryBuilder } from '../QueryExpr';

@injectable()
export class RecommendationSchemaService {
    private readonly fieldDetails = new Map([['Cost', { name: 'Unit Price' }]]);

    public constructor(@inject(FormatService) private readonly formatSvc: FormatService) {}

    public async getSchema() {
        const result = await postRecommendationQuery({ IncludeSchema: true, Take: 0 });
        const types = result.Types ?? [];
        this.adjustFieldNames(types);
        return types;
    }

    private adjustFieldNames(types: SchemaType[]) {
        for (const type of types) {
            if (!type.TypeId?.includes('CsTags')) {
                for (const field of type.Fields ?? []) {
                    field.Name = this.formatSvc.userFriendlyCamelCase(field.Name!);
                }
            }
        }
    }
}

import {
    postResourceTagAutomationGetRulePreviewCount,
    postResourceTagAutomationGetRulePreviewChanges,
    postResourceTagAutomationGetInheritancePreviewCount,
} from '@apis/Resources';
import { QueryExpr } from '@apis/Resources';
import {
    getTagAutomationGetTagAutomationRule,
    getTagAutomationGetTagAutomationRules,
    postTagAutomationCreateTagAutomationRule,
    putTagAutomationSaveTagAutomationRule,
} from '@apis/TagManager';
import {
    TagAutomationRule,
    TagAutomationRuleDetails,
    TagAutomationRuleParametersParameterStatus,
    TagAutomationRuleParametersType,
    TagAutomationRuleStatus,
    TagAutomationTraceRecord,
} from '@apis/TagManager/model';
import { SchemaService, traverseExpr } from '@root/Services/QueryExpr';
import { ResourceSchemaProvider, ResourceService } from '@root/Services/Resources/ResourceService';
import { makeAutoObservable, reaction } from 'mobx';
import { inject, Lifecycle, scoped } from 'tsyringe';
import { FormatService } from '@root/Services/FormatService';
import { addDays } from 'date-fns';
import { EventEmitter } from '@root/Services/EventEmitter';
import { IQueryExpr } from '@apis/Resources/model';

interface Preview {
    loadingGrid: boolean;
    isCountRefreshing: boolean;
    count: number;
    results: PreviewGroup[];
    additionalColumns: string[];
    parentCount?: number;
}

export interface PreviewGroup {
    Id: string;
    Children: PreviewItem[];
    [key: string]: unknown;
}

export interface PreviewItem {
    Id: string;
    KeyValueAfter: string;
    KeyValueBefore: string;
    AdditionalColumns: string[];
    [key: string]: unknown;
}

export class RuleEditor {
    public loading = true;
    public saving = false;
    public rule?: TagAutomationRule = undefined;
    public filterValidation: string[] = [];
    public selectionValidation: string[] = [];
    public ruleTypeValidation: Partial<Record<TagAutomationRuleParametersType, string[]>> = {};
    private originalParams = '';
    public originalStatus?: TagAutomationRuleStatus = undefined;
    private previewThrottle?: number;
    public resourceQuery: string = '';

    public parametersChanged = () => {
        return this.originalParams !== this.serializeParams();
    };

    public preview: Preview = {
        loadingGrid: true,
        isCountRefreshing: false,
        count: 0,
        results: [],
        additionalColumns: [],
        parentCount: 0,
    };

    public updatePreviewCount = reaction(
        () => JSON.stringify(this.rule?.Parameters),
        () => {
            clearTimeout(this.previewThrottle);
            this.preview.isCountRefreshing = true;
            this.previewThrottle = setTimeout(() => {
                if (this.rule?.Parameters?.Type != TagAutomationRuleParametersType.Inheritance) {
                    this.refreshPreviewCount();
                } else this.refreshInheritancePreviewCount();
            }, 1000) as unknown as number;
        }
    );

    public async refreshPreviewCount() {
        if (!this.rule) {
            return;
        }
        if (this.filterValidation.length > 0) {
            return;
        }

        await postResourceTagAutomationGetRulePreviewCount(this.trimFilter(this.rule))
            .then((results) => {
                this.preview.count = results.Count ?? 0;
                const newQuery: IQueryExpr[] = [];
                this.generateTagExplorerQuery(newQuery, results.ResourceQuery?.Where);
                this.resourceQuery = JSON.stringify(newQuery);
            })
            .catch((error) => {
                this.preview.count = 0;
                this.resourceQuery = '{}';
            })
            .finally(() => {
                this.preview.isCountRefreshing = false;
            });
    }

    private generateTagExplorerQuery(result: IQueryExpr[], expr?: IQueryExpr) {
        if (expr) {
            traverseExpr(expr as QueryExpr, (q) => {
                if ('Operation' in q) {
                    q.Operation = q.Operation.toLocaleLowerCase();
                    if (q.Operation.toLocaleLowerCase() !== 'and') {
                        result.push(q);
                    }
                }
            });
        }
    }

    public async refreshInheritancePreviewCount() {
        if (!this.rule) {
            return;
        }

        await postResourceTagAutomationGetInheritancePreviewCount(this.trimFilter(this.rule))
            .then((count) => {
                this.preview.count = count;
            })
            .catch((error) => {
                this.preview.count = 0;
            })
            .finally(() => {
                this.preview.isCountRefreshing = false;
            });
    }

    public async refreshPreviewSample() {
        if (!this.rule) {
            return;
        }

        this.preview.loadingGrid = true;
        await postResourceTagAutomationGetRulePreviewChanges(this.trimFilter(this.rule))
            .then((results) => {
                const formattedResults = results
                    .map((r) => {
                        return {
                            Id: r.Resource?.Id,
                            KeyValueAfter: r.KeyValueAfter,
                            KeyValueBefore: r.KeyValueBefore,
                            AdditionalColumns: r.AdditionalColumns,
                            ...r.Resource,
                        } as PreviewItem;
                    })
                    .reduce((groups, item) => {
                        let key = item.Id;
                        if (!groups[key]) groups[key] = [];
                        groups[key].push(item);
                        return groups;
                    }, {} as { [Id: string]: PreviewItem[] });
                const groupedResults = Object.entries(formattedResults).map(([k, v]) => {
                    let resource = (v.length > 0 ? v[0] : {}) as PreviewItem;
                    return {
                        ...resource,
                        KeyValueAfter: null,
                        KeyValueBefore: null,
                        Children: v.map((item) => {
                            return { KeyValueAfter: item.KeyValueAfter, KeyValueBefore: item.KeyValueBefore, Id: '', AdditionalColumns: [] };
                        }),
                    };
                });
                if (results.length > 0) this.preview.additionalColumns = results[0].AdditionalColumns ?? [];
                this.preview.results = groupedResults;
            })
            .catch((error) => {
                this.preview.results = [];
            })
            .finally(() => {
                this.preview.loadingGrid = false;
            });
    }

    public get appliesToNew() {
        return ['New', 'Both'].includes(this.rule?.Parameters?.ParameterStatus ?? '');
    }
    public get appliesToExisting() {
        return ['Existing', 'Both'].includes(this.rule?.Parameters?.ParameterStatus ?? '');
    }
    public get ruleStatus() {
        return this.rule?.Status;
    }

    public constructor(private readonly formatSvc: FormatService) {
        makeAutoObservable(this);
    }

    public async init(id?: number) {
        if (id) {
            this.rule = await this.loadRule(id);
            this.originalParams = this.serializeParams();
            this.originalStatus = this.rule.Status;
        } else {
            this.rule = this.createRule();
            this.loading = false;
        }
    }

    public async initByRule(rule?: TagAutomationRuleDetails) {
        if (rule) {
            this.rule = rule;
            this.originalParams = this.serializeParams();
            this.originalStatus = this.rule.Status;
        } else {
            this.rule = this.createRule();
            this.loading = false;
        }
    }

    public setTypeErrors(type: TagAutomationRuleParametersType, errors: string[]) {
        this.ruleTypeValidation[type] = errors;
    }

    public getRuleTypeIssues() {
        const type = this.rule?.Parameters?.Type;
        const ruleIssues = type ? this.ruleTypeValidation[type] : null;
        return ruleIssues;
    }

    public canSave() {
        const hasName = !!this.rule?.RuleName;
        const filterIssues = this.filterValidation.length > 0;
        const status = this.rule?.Status;
        const type = this.rule?.Parameters?.Type;
        const hasType = type;
        const ruleIssues = type ? this.ruleTypeValidation[type]?.length : false;

        return hasName && hasType && (status === 'Draft' || (!filterIssues && !ruleIssues));
    }

    public async save() {
        this.saving = true;

        try {
            if (!this.rule) {
                return false;
            }
            this.rule = this.rule.Id
                ? await putTagAutomationSaveTagAutomationRule(this.trimFilter(this.rule))
                : await postTagAutomationCreateTagAutomationRule(this.trimFilter(this.rule));
            return true;
        } catch {
            return false;
        } finally {
            this.saving = false;
        }
    }

    public trimFilter(rule: TagAutomationRule) {
        return {
            ...rule,
            Parameters: {
                ...rule.Parameters,
                Filter:
                    rule.Parameters?.Filter && 'Operands' in rule.Parameters?.Filter && rule.Parameters?.Filter.Operands.length === 0
                        ? null
                        : rule.Parameters?.Filter,
            },
        } as TagAutomationRule;
    }

    public toggleAppliesToNew = () => {
        this.toggleAppliesTo('New');
    };
    public toggleAppliesToExisting = () => {
        this.toggleAppliesTo('Existing');
    };

    public setRuleStatus = (status: TagAutomationRuleStatus) => {
        if (this.rule) {
            this.rule.Status = status;
        }
    };

    public getTestEndDate() {
        return this.rule?.TestEndDate ? this.formatSvc.toLocalDate(this.rule.TestEndDate) : undefined;
    }
    public calculateTestEndDate() {
        return addDays(new Date(), 6);
    }
    public isTesting() {
        return this.rule?.Status === 'Test' && this.rule?.TestEndDate && this.formatSvc.toLocalDate(this.rule.TestEndDate) > new Date();
    }

    private serializeParams() {
        const normalizedFilter = !this.rule?.Parameters?.Filter
            ? null
            : traverseExpr(this.rule.Parameters.Filter as QueryExpr, (q) => {
                  if ('Type' in q) {
                      delete q.Type;
                  }
              }) || this.rule?.Parameters?.Filter;

        return JSON.stringify([this.rule?.Parameters?.Syntax, normalizedFilter]);
    }

    private toggleAppliesTo(target: TagAutomationRuleParametersParameterStatus) {
        if (this.rule?.Parameters) {
            const status = this.rule.Parameters.ParameterStatus;
            if (status === 'Both') {
                this.rule.Parameters.ParameterStatus = target === 'Existing' ? 'New' : 'Existing';
            } else if (status && status !== target) {
                this.rule.Parameters.ParameterStatus = 'Both';
            } else if (status === target) {
                this.rule.Parameters.ParameterStatus = undefined;
            } else {
                this.rule.Parameters.ParameterStatus = target;
            }
        }
    }

    private async loadRule(ruleId: number) {
        this.loading = true;
        try {
            return await getTagAutomationGetTagAutomationRule({ ruleId });
        } finally {
            this.loading = false;
        }
    }

    private createRule() {
        return {
            Parameters: {
                Syntax: {},
                ParameterStatus: 'Both',
            },
            Status: 'Draft',
        } as TagAutomationRule;
    }
}

@scoped(Lifecycle.ContainerScoped)
export class TagLookupService {
    public keys: string[] = [];
    public values: string[] = [];
    public schemaSvc = new SchemaService([]);

    public constructor(
        @inject(ResourceService) private readonly resourceSvc: ResourceService,
        @inject(ResourceSchemaProvider) private readonly resourceSchemaSvc: ResourceSchemaProvider
    ) {
        makeAutoObservable(this);
    }

    public async init() {
        this.keys = await this.resourceSvc.getTags();
        this.keys.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));

        this.values = await this.resourceSvc.getTagValuesUsingFilter('');
        this.values.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));

        const types = await this.resourceSchemaSvc.getSchema();
        this.schemaSvc = new SchemaService(types);
    }

    public async getTagValuesWithFilter(filter: string) {
        this.values = await this.resourceSvc.getTagValuesUsingFilter(filter);
        this.values.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
    }

    public async getTagKeysWithFilter(filter: string) {
        this.keys = await this.resourceSvc.getTagKeysUsingFilter(filter);
        this.keys.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
    }

    public async getValues(key: string) {
        const values = await this.resourceSvc.getTagValues(key);
        values.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));

        return values;
    }
}

@scoped(Lifecycle.ContainerScoped)
export class TagAutomationRuleActivityModel {
    public rule?: TagAutomationRule;
    public loading = new EventEmitter(true);
    public status = new EventEmitter<'success' | 'fail' | 'test' | 'testIssues' | undefined>(undefined);
    public traceSelectionChange = new EventEmitter<TagAutomationTraceRecord | undefined>(undefined);
    public ruleLookup = new Map<number, TagAutomationRule>();

    public constructor() {}

    public refresh = () => {
        this.load(this.rule?.Id?.toString());
    };

    public async load(rawRuleId?: string) {
        this.loading.emit(true);
        try {
            const ruleId = parseInt(rawRuleId ?? '');
            this.rule = await getTagAutomationGetTagAutomationRule({ ruleId });
            const rules = await getTagAutomationGetTagAutomationRules();
            this.ruleLookup = rules.reduce((result, rule) => result.set(rule.Id ?? 0, rule), new Map<number, TagAutomationRule>());
        } finally {
            this.loading.emit(false);
        }
    }

    public clearSelectedTrace = () => {
        this.traceSelectionChange.emit(undefined);
    };
}

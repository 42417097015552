import { Company, CustomerSubsidyViewModel } from '@apis/Customers/model';
import { Box, Divider, Tabs, useMantineTheme } from '@mantine/core';
import { PanelBody, PanelContent } from '@root/Design/Layout';
import { useState } from 'react';
import { CloudExplorerDashboard } from '../CloudExplorer/CloudExplorerDashboard';
import { CloudExplorerPage } from '../CloudExplorer/CloudExplorerPage';
import { CompanyDetailResources } from './CompanyDetailResources';

export function CompanyDetailBody({ company, customer }: { company: Company; customer: CustomerSubsidyViewModel }) {
    const theme = useMantineTheme();
    const [tab, setTab] = useState<string | null>('Financial');

    return (
        <PanelContent>
            <Box>
                <Tabs onTabChange={setTab} value={tab}>
                    <Tabs.List px="lg">
                        <Tabs.Tab value="Financial">Financial</Tabs.Tab>
                        <Tabs.Tab value="Resources">Resources</Tabs.Tab>
                    </Tabs.List>
                </Tabs>
            </Box>
            <PanelBody bg={theme.colors.gray[2]}>
                {company && tab === 'Resources' ? <CompanyDetailResources company={company} /> : null}
                {company && tab === 'Financial' ? <CloudExplorerDashboard customer={customer} /> : null}
                {company && tab === 'Observability' ? '' : null}
            </PanelBody>
        </PanelContent>
    );
}

import { JobOfCostForecast } from '@apis/Resources';
import { useMantineTheme, Box, Button, Center, Card, Group, Space, Stack, Text, Title, Loader } from '@mantine/core';
import { ActivityItemAdapter } from '@root/Components/Actions/ActivityPanel/ActivityTypes';
import { useDi } from '@root/Services/DI';
import { useEventValue, useToggle } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { JobService, JobHierarchyProgress, JobHierarchyIncProgress } from '@root/Services/Jobs/JobService';
import { PollingPromise, PollingService } from '@root/Services/Jobs/PollingService';
import { useState, useMemo, useEffect } from 'react';
import { CalendarStats, ChevronRight, Circle, CircleHalf, Shield } from 'tabler-icons-react';
import { ForecastHeader, useForecastDescription, DescriptionLine, useForecastRange } from './Components';
import { useSupportChat } from '@root/Components/Shell/SupportChat';
import { SplashBg, LoadingEffect, FailedEffect } from './Design';
import { ForecastDetailsDrawer } from './ForecastDetails';
import { SpendForecastModel, TenantForecastStatus } from './Models';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { ForecastConfig } from '@apis/Invoices/model';

export function ForecastingUnavailable({ model }: { model: SpendForecastModel }) {
    const theme = useMantineTheme();
    const remaining = model.historicalDaysNeeded - model.historicalDaysAvailable;
    const daysLabel = remaining === 1 ? 'day' : 'days';

    return (
        <SplashBg>
            <Box p="xl">
                <ForecastHeader>{}</ForecastHeader>
                <Center sx={{ height: 600 }}>
                    <Card sx={{ background: theme.colors.gray[0], opacity: 0.8 }} withBorder shadow="sm" radius="md" p="xl">
                        <Group noWrap>
                            <CalendarStats size={60} color={theme.colors.primary[6]} strokeWidth={1} />
                            <Box sx={{ width: 300 }}>
                                <Title order={3}>{`Ready in ${remaining} ${daysLabel}`}</Title>
                                <Text size="sm">
                                    Cost Forecasting requires at least {model.historicalDaysNeeded} days of historical cloud spend data. Please check
                                    back after {remaining} more {daysLabel}.
                                </Text>
                            </Box>
                        </Group>
                    </Card>
                </Center>
            </Box>
        </SplashBg>
    );
}

export function ForecastFailed({ model, job }: { job: JobOfCostForecast; model: SpendForecastModel }) {
    const theme = useMantineTheme();
    const forecastName = model.forecastPersistence.createJobName(job);

    const { loadChat, loadingChat } = useSupportChat();
    return (
        <SplashBg>
            <Box p="xl">
                <ForecastHeader>{}</ForecastHeader>
                <Center sx={{ height: 600 }}>
                    <Card component={FailedEffect} sx={{ background: theme.colors.gray[0], opacity: 0.8 }} withBorder shadow="sm" radius="md" p="xl">
                        <Box sx={{ width: 500 }} px="xl">
                            <Title order={3}>Forecast Failed</Title>
                            <Space h="xs" />
                            <DescriptionLine label="Forecast Name" value={forecastName} />
                            <Space h="md" />
                            <Text>An error occurred and the forecast could not be created.</Text>
                            <Text>Here are some options to try:</Text>
                            <Space h="md" />
                            <Box>
                                <Button variant="subtle" onClick={model.createForecast}>
                                    Start a New Forecast
                                </Button>
                            </Box>
                            <Box>
                                <Button variant="subtle" onClick={model.openLoader}>
                                    Load a Saved Forecast
                                </Button>
                            </Box>
                            <Box>
                                <Button rightIcon={loadingChat ? <Loader size="xs" /> : null} variant="subtle" onClick={loadChat}>
                                    Contact Support
                                </Button>
                            </Box>
                        </Box>
                    </Card>
                </Center>
            </Box>
            <ForecastDetailsDrawer model={model} />
        </SplashBg>
    );
}

export function TenantForecastNotConfigured({ model }: { model: SpendForecastModel }) {
    const theme = useMantineTheme();
    const { canManage } = useAuthZValues({ canManage: { CostForecasting: 'Manage' } });

    return (
        <SplashBg>
            <Box p="xl">
                <ForecastHeader>{}</ForecastHeader>
                <Center sx={{ height: 600 }}>
                    <Stack align="center">
                        <Card sx={{ background: theme.colors.gray[0], opacity: 0.8 }} withBorder shadow="sm" radius="md" p="xl">
                            <Box sx={{ width: 500 }} px="xl">
                                <Title order={3}>{canManage ? 'Ready to Configure...' : 'Configuration Required'}</Title>
                                <Space h="md" />
                                {canManage ? (
                                    <>
                                        <Text size="sm" italic>
                                            We've collected enough invoice data to begin running forecasts. Please, take a moment and cofigure how
                                            you'd like the forecast to work.
                                        </Text>
                                        <Space h="md" />
                                        <Box>
                                            <Button onClick={model.openConfig}>Configure Forecasting</Button>
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        <Text size="sm" align="center" italic>
                                            We've collected enough invoice data to begin running forecasts. However, only an administrator is allowed
                                            to configure forecasting. Please contact your administrator.
                                        </Text>
                                    </>
                                )}
                            </Box>
                        </Card>
                    </Stack>
                </Center>
            </Box>
        </SplashBg>
    );
}

export function TenantForecastInProgress({ model, onFinished }: { model: SpendForecastModel; onFinished: () => void }) {
    const theme = useMantineTheme();
    const pollingSvc = useDi(PollingService);
    const [poller, setPoller] = useState<PollingPromise<TenantForecastStatus>>();
    const status = useEventValue(poller?.progress);
    const pollerFailed = useEventValue(poller?.failed);

    useEffect(() => {
        let abandon = () => {};
        const startPolling = async () => {
            const poller = pollingSvc.pollUntil(
                async () => (await model.getTenantForecastStatus()).status,
                (status) => status === 'Ready',
                5000
            );
            setPoller(poller);
        };
        if (pollerFailed) {
            const abandonHandle = setTimeout(startPolling, 5000);
            abandon = () => clearTimeout(abandonHandle);
        } else if (!poller) {
            startPolling();
        }
        return abandon;
    }, [pollerFailed]);

    useEffect(() => {
        if (status === 'Ready') {
            onFinished();
        }
    }, [status, onFinished]);

    useEffect(() => {
        return () => poller?.cancel();
    }, [poller]);

    return (
        <SplashBg>
            <Box p="xl">
                <ForecastHeader>{}</ForecastHeader>
                <Center sx={{ height: 400 }}>
                    <Stack align="center">
                        <Card
                            component={LoadingEffect}
                            sx={{ background: theme.colors.gray[0], opacity: 0.8 }}
                            withBorder
                            shadow="sm"
                            radius="md"
                            p="xl"
                        >
                            <Box sx={{ width: 500 }} px="xl">
                                <Title order={3}>Processing...</Title>
                                <Space h="md" />
                                <Text size="sm" align="center" italic>
                                    Forecast processing can take some time, please check back later
                                </Text>
                            </Box>
                        </Card>
                    </Stack>
                </Center>
            </Box>
        </SplashBg>
    );
}

export function ForecastInProgress({ model, job, onFinished }: { job: JobOfCostForecast; model: SpendForecastModel; onFinished: () => void }) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const jobSvc = useDi(JobService);
    const pollingSvc = useDi(PollingService);
    const activityItemAdapter = useDi(ActivityItemAdapter);
    const [poller, setPoller] = useState<PollingPromise<JobHierarchyIncProgress | undefined>>();
    const progress = useEventValue(poller?.progress);
    const status = !progress ? null : jobSvc.getStatusInfo(progress);
    const activityModel = useMemo(() => {
        return !progress ? null : activityItemAdapter.getActivityModel({ job, status: progress });
    }, [progress]);
    const forecastName = model.forecastPersistence.createJobName(job);
    const haveOtherForecasts = model.mySavedForecasts.length + model.othersSavedForecasts.length > 0;
    const [records, setRecords] = useState<number>();
    const [estimate, setEstimate] = useState<string>();
    const preparing = status?.inProgress && progress?.Succeeded === 0;
    const totalProgressJobs = (progress?.Succeeded || 0) + (progress?.Created || 0);
    const forecasting = (progress?.Succeeded || 0) > 0 && (progress?.Created || 0) > 0 && totalProgressJobs <= 2;
    const indexing = (progress?.Succeeded || 0) > 0 && ((progress?.Started || 0) > 0 || totalProgressJobs > 2);
    const incProgress = progress?.IncProgress ?? 0;
    const incTotal = progress?.IncTotal ?? 0;
    const remainingLbl = incProgress || incTotal ? `step ${fmtSvc.formatInt(incProgress)} of ${fmtSvc.formatInt(incTotal)}` : null;

    const description = useForecastRange(job);
    const { forecastDays, forecastEndDate, accountIdList } = useForecastDescription(job);
    const startedAt = fmtSvc.toLocalDate(job.QueuedAt);
    const pollerFailed = useEventValue(poller?.failed);
    const historicalDays = (description?.days.length ?? 0) - forecastDays;

    useEffect(() => {
        let abandon = () => {};
        if (job?.HierarchyId) {
            const startPolling = async () => {
                const poller = pollingSvc.pollUntil(
                    async () => (await jobSvc.getHierarchyProgressInc([job.HierarchyId!]))?.[0],
                    (r) => !r || (!r.Created && !r.Started),
                    5000
                );
                setPoller(poller);
            };
            if (pollerFailed) {
                const abandonHandle = setTimeout(startPolling, 5000);
                abandon = () => clearTimeout(abandonHandle);
            } else if (!poller) {
                startPolling();
            }
        }
        return abandon;
    }, [job?.HierarchyId, pollerFailed]);

    useEffect(() => {
        return () => poller?.cancel();
    }, [poller]);

    useEffect(() => {
        if (forecasting) {
            (async () => {
                const updatedJob = (await jobSvc.getJobById(job.Id!)) as JobOfCostForecast;
                const records = updatedJob.ResultDetail?.Records ?? 0;
                const cardinality = updatedJob.ResultDetail?.Cardinality ?? 0;
                const { label } = model.forecastPersistence.getEta(historicalDays, cardinality, records);
                setRecords(records);
                setEstimate(`${label} expected`);
            })();
        }
    }, [forecasting, job.Id]);

    useEffect(() => {
        return () => poller?.cancel();
    }, [poller]);

    useEffect(() => {
        if (status && !status.inProgress) {
            model.reloadForecasts().then(() => onFinished());
        }
    }, [job?.Id, status, onFinished]);

    return (
        <SplashBg>
            <Box p="xl">
                <ForecastHeader>{}</ForecastHeader>
                <Center sx={{ height: 600 }}>
                    <Stack align="center">
                        <Card
                            component={LoadingEffect}
                            sx={{ background: theme.colors.gray[0], opacity: 0.8 }}
                            withBorder
                            shadow="sm"
                            radius="md"
                            p="xl"
                        >
                            <Box sx={{ width: 500 }} px="xl">
                                <Title order={3}>{activityModel?.title}...</Title>
                                <Space h="xs" />
                                <DescriptionLine label="Forecast Name" value={forecastName} />
                                <DescriptionLine label="Accounts" value={accountIdList} />
                                <DescriptionLine label="Forecast Horizon" value={`${forecastDays} Days (${fmtSvc.toShortDate(forecastEndDate)})`} />
                                <DescriptionLine label="Started At" value={fmtSvc.formatDateWithTodaysTime(startedAt)} />
                                <DescriptionLine
                                    label="Time Elapsed"
                                    value={`${fmtSvc.timeElapsed(startedAt)} (${remainingLbl ?? estimate ?? 'ETA pending'})`}
                                />
                                <Space h="md" />
                                {preparing ? (
                                    <Text size="sm" align="center" italic>
                                        Querying and staging {historicalDays} days of historical invoice data...
                                    </Text>
                                ) : forecasting ? (
                                    <Text size="sm" align="center" italic>
                                        Hyperparameter tuning and cross-validating hundreds of forecasting models on{' '}
                                        {fmtSvc.formatInt0Dec(records ?? 0)} historical data points...
                                    </Text>
                                ) : indexing ? (
                                    <Text size="sm" align="center" italic>
                                        Indexing historical data and forecast results...
                                    </Text>
                                ) : null}
                            </Box>
                        </Card>
                        {!haveOtherForecasts ? null : (
                            <Button radius="xl" size="md" onClick={model.openLoader}>
                                or Load a Forecast
                            </Button>
                        )}
                    </Stack>
                </Center>
            </Box>
        </SplashBg>
    );
}

export function ForecastPrompt({ model }: { model: SpendForecastModel }) {
    const { canManage } = useAuthZValues({
        canManage: { CostForecasting: 'Manage' },
    });

    const theme = useMantineTheme();
    const hasOthers = !!model.othersSavedForecasts.length;
    return (
        <SplashBg>
            <Box p="xl">
                <ForecastHeader>{}</ForecastHeader>
                <Center sx={{ height: 600 }}>
                    <Stack align="center">
                        {canManage ? (
                            <>
                                <Button
                                    radius="xl"
                                    size="xl"
                                    variant="outline"
                                    color="primary"
                                    onClick={model.createForecast}
                                    rightIcon={<ChevronRight />}
                                    sx={{ [':hover']: { background: theme.colors.gray[2] } }}
                                >
                                    Start a Cost Forecast
                                </Button>
                            </>
                        ) : !hasOthers ? (
                            <Card sx={{ background: theme.colors.gray[0], opacity: 0.8 }} withBorder shadow="sm" radius="md" p="xl">
                                <Group noWrap>
                                    <CircleHalf style={{ rotate: '-45deg' }} size={60} color={theme.colors.primary[6]} />
                                    <Box sx={{ width: 300 }}>
                                        <Title order={3}>No Forecast Available</Title>
                                        <Text size="sm">
                                            You do not have permission to create a new cost forecast. Please contact your administrator for
                                            assistance.
                                        </Text>
                                    </Box>
                                </Group>
                            </Card>
                        ) : null}

                        {hasOthers ? (
                            <Button radius="xl" size="md" onClick={model.openShared}>
                                or Load a Shared Forecast
                            </Button>
                        ) : null}
                    </Stack>
                </Center>
            </Box>
            <ForecastDetailsDrawer model={model} />
        </SplashBg>
    );
}

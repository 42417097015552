import { TagResourcesJob } from '@apis/Resources/model';
import styled from '@emotion/styled';
import {
    ActionIcon,
    Box,
    Button,
    Card,
    Checkbox,
    CloseButton,
    Divider,
    Group,
    Loader,
    MantineColor,
    Menu,
    Overlay,
    Popover,
    Portal,
    Space,
    Stack,
    Text,
    TextInput,
    Tooltip,
    useMantineTheme,
} from '@mantine/core';
import { ActivityDetailsPanelService } from '@root/Components/Actions/ActionDetails';
import { Combobox } from '@root/Components/Picker/Combobox';
import { FlyoverOverlay, useAnchoredFlyoverEvents } from '@root/Components/Picker/Flyover';
import { PropertyGrid, usePropertyGridConfig } from '@root/Components/PropertyGrid/PropertyGrid';
import { PropertyGridKey } from '@root/Components/PropertyGrid/Design';
import { PropertyGridItem } from '@root/Components/PropertyGrid/Models';
import { useUserAccessCheck } from '@root/Components/Shell/AppFeatureAccess';
import { VisibleSpaces } from '@root/Components/Text/VisibleSpaces';
import { FillerSwitch } from '@root/Design/Filler';
import { PanelBody, PanelContent } from '@root/Design/Layout';
import { AnchorButton, Score, SectionedPopover, SectionedPopoverToolbar } from '@root/Design/Primitives';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEvent, useToggle } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { InlineTaggingCreditBalance } from '@root/Site/TagManager/Components/InlineTaggingCreditBalance';
import { CSSProperties, MouseEventHandler, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { AlertTriangle, Check, DotsVertical, Edit, Filter, SortAscendingLetters, SortDescendingLetters, Tag, Trash } from 'tabler-icons-react';
import { NoDataMessage, ResourceDetailSection } from '../Design';
import { BaseResource } from '../ResourcesGrid';
import { InlineTagEditService } from '../Tags/InlineTagEditService';
import { InlineEditTag } from '../Tags/InlineTagging';
import { useFloatedFullText } from '@root/Components/Text/FloatedFullText';

export function ResourceTags({ resource, onChange }: { resource: BaseResource; onChange?: () => void }) {
    const accessCheck = useUserAccessCheck();
    const [allowTagging] = accessCheck('Tag Explorer', 'Manage');
    return (
        <FillerSwitch loading={allowTagging === 'loading'} noData={!resource}>
            {() => (
                <ResourceTagsReady
                    resource={resource}
                    allowEdit={allowTagging === 'ok'}
                    noSubscription={allowTagging === 'notSubscribed'}
                    onChange={onChange}
                />
            )}
        </FillerSwitch>
    );
}
interface ResourceTagsReadyProps {
    allowEdit: boolean;
    noSubscription: boolean;
    resource: BaseResource;
    onChange?: () => void;
}
function ResourceTagsReady({ resource, allowEdit, onChange }: ResourceTagsReadyProps) {
    const fmtSvc = useDi(FormatService);
    const theme = useMantineTheme();
    const [tags, setTags] = useState(resource.CsTags ?? {});
    const container = useDiContainer();
    const inlineTagEditSvc = useMemo(() => container.resolve(InlineTagEditService), []);
    const [listOptions, setListOptions] = useState<ITagListSettings>({ filter: '', sort: { sortBy: 'key', sortDir: 'asc' } });
    const {
        filter,
        sort: { sortBy, sortDir },
    } = listOptions;
    useEvent(
        inlineTagEditSvc.onTagChanged,
        useCallback(() => {
            setTags({ ...(resource.CsTags ?? {}) });
            onChange?.();
        }, [resource, onChange])
    );

    const model = useMemo(() => {
        return {
            inlineTagEditSvc,
            resource,
            allowEdit,
            onChanging: EventEmitter.empty(),
        };
    }, [resource.Id, resource.ResourceType, allowEdit]);
    const tagFlyover = useResourceTagFlyover();

    const tagsListOptions = usePropertyGridConfig(
        (rb) => [
            rb.all({
                valueRenderer: (item) => {
                    const { value, property } = item;
                    return <ResourceTagValueEditor flyover={tagFlyover} settings={model} tag={property as string} value={value as string} />;
                },
                label: (item) => {
                    return <TagKey flyover={tagFlyover} settings={model} item={item} />;
                },
                hidden: (item) => {
                    let result = false;
                    if (filter) {
                        const values = [item.valueAsStr(''), item.property as string];
                        const lcFilter = filter.toLowerCase();
                        result = !values.some((v) => v.toLowerCase().includes(lcFilter));
                    }
                    return result;
                },
                sortChildren: () => {
                    let accessor: (item: PropertyGridItem) => string =
                        sortBy === 'key' ? (item) => item.property as string : (item) => item.valueAsStr('');
                    const modifier = sortDir === 'asc' ? 1 : -1;
                    return (a, b) => {
                        const aVal = accessor(a).trim();
                        const bVal = accessor(b).trim();
                        return modifier * aVal.localeCompare(bVal, undefined, { sensitivity: 'base' });
                    };
                },
            }),
        ],
        {},
        [tagFlyover, model, filter, sortBy, sortDir]
    );
    const emptyMessage = <NoDataMessage>{filter ? `No tags found for '${filter}'` : 'No Tags'}</NoDataMessage>;

    return (
        <PanelContent>
            <PanelBody>
                {tagFlyover.host()}
                <ResourceDetailSection shadow>
                    <TagListToolbar settings={model} state={listOptions} onChange={setListOptions} />
                    <Divider color="gray.3" />
                    <PropertyGrid size="normal" target={tags} {...tagsListOptions} emptyState={emptyMessage} />
                </ResourceDetailSection>
            </PanelBody>
            <Divider />
            <Group py="xs" align="center" sx={{ background: theme.colors.gray[1], '> div': { flex: 1 } }}>
                <Score maxSize={22} title="Overall Health" value={fmtSvc.formatPercent(resource.TagHealthScore ?? 0)} />
                <Score maxSize={22} title="Clarity" value={fmtSvc.formatPercent(resource.ClarityScore ?? 0)} />
                <Score maxSize={22} title="Compliance" value={fmtSvc.formatPercent(resource.ComplianceScore ?? 0)} />
                <Score maxSize={22} title="Coverage" value={fmtSvc.formatPercent(resource.CoverageScore ?? 0)} />
            </Group>
        </PanelContent>
    );
}

interface ITagListSettings {
    filter: string;
    sort: { sortBy: 'key' | 'value'; sortDir: 'asc' | 'desc' };
}
function TagListToolbar(props: { settings: TagEditorSettings; onChange: (state: ITagListSettings) => void; state: ITagListSettings }) {
    const fmtSvc = useDi(FormatService);
    const { colors } = useMantineTheme();
    const { settings, onChange, state } = props;
    const [filter, setFilter] = useState(state.filter);
    const [sort, setSort] = useState(state.sort);
    const sortOptions = useMemo(() => {
        const sortDirs = ['asc', 'desc'] as ('asc' | 'desc')[];
        const sortTypes = (['key', 'value'] as ('key' | 'value')[]).flatMap((sortBy) => sortDirs.map((sortDir) => ({ sortBy, sortDir })));
        return sortTypes.map((sortType) => ({
            ...sortType,
            icon: sortType.sortDir === 'asc' ? <SortAscendingLetters size={16} /> : <SortDescendingLetters size={16} />,
            sortByLbl: fmtSvc.userFriendlyCamelCase(sortType.sortBy),
            fullLbl: `${fmtSvc.userFriendlyCamelCase(sortType.sortBy)} ${sortType.sortDir === 'asc' ? 'A-Z' : 'Z-A'}`,
            onClick: () => setSort(sortType),
        }));
    }, [filter]);

    useEffect(() => {
        onChange({ filter: filter, sort });
    }, [filter, sort]);

    const selectedOption = sortOptions.find((opt) => opt.sortBy === sort.sortBy && opt.sortDir === sort.sortDir);

    return (
        <Box sx={{ minHeight: 46, alignItems: 'center', display: 'grid', gridTemplateColumns: '1fr 580px' }}>
            <Text ml={30}>Key</Text>
            <Group position="left" spacing={0} noWrap>
                <Text sx={{ flex: 1 }}>Value</Text>
                <AddTagButton settings={settings} />
                <Space w={10} />
                <TextInput
                    sx={{ input: { minHeight: 30, height: 30 } }}
                    size="sm"
                    placeholder="Filter"
                    icon={<Filter size={16} />}
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    rightSection={<CloseButton hidden={!filter} radius={'lg'} onClick={() => setFilter('')} />}
                />
                <Space w={10} />
                <Menu shadow="md" offset={0} position="bottom-end">
                    <Menu.Target>
                        <span>
                            <Tooltip withinPortal position="top" label={`Sorted by ${selectedOption?.fullLbl}`}>
                                <ActionIcon sx={{ borderColor: colors.gray[4] }} variant="outline">
                                    {selectedOption?.icon}
                                </ActionIcon>
                            </Tooltip>
                        </span>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Label>Sort By</Menu.Label>
                        {sortOptions.map((opt) => (
                            <Menu.Item key={opt.fullLbl} onClick={opt.onClick} icon={opt.icon}>
                                {opt.fullLbl}
                            </Menu.Item>
                        ))}
                    </Menu.Dropdown>
                </Menu>
                <Space w={10} />
            </Group>
        </Box>
    );
}

interface TagEditorSettings {
    resource: BaseResource;
    inlineTagEditSvc: InlineTagEditService;
    allowEdit: boolean;
    onChanging: EventEmitter<void>;
}
interface ICommonTagEditorProps {
    settings: TagEditorSettings;
    flyover: ReturnType<typeof useResourceTagFlyover>;
}
interface TagEditorProps extends ICommonTagEditorProps {
    flyover: ReturnType<typeof useResourceTagFlyover>;
    settings: TagEditorSettings;
    tag: string;
    value: string | null;
}

function AddTagButton({ settings }: { settings: TagEditorSettings }) {
    const [opened, { toggle, close }] = useToggle(false);

    const popoverProps = useMemo(
        () => ({
            withArrow: true,
            arrowSize: 10,
            position: 'bottom' as const,
            withinPortal: true,
            shadow: 'md',
            closeOnClickOutside: false,
            closeOnEscape: false,
            offset: 0,
        }),
        []
    );
    return (
        <>
            <Portal>{opened ? <Overlay opacity={0} onClick={close} /> : null}</Portal>
            <Popover {...popoverProps} onClose={close} opened={opened}>
                <Popover.Target>
                    <Button size="xs" variant="outline" leftIcon={<Tag strokeWidth={1} size={16} />} onClick={toggle}>
                        Add Tag
                    </Button>
                </Popover.Target>
                <SectionedPopover sx={{ width: 300 }}>
                    <AddTagDialog settings={settings} onClose={close} />
                </SectionedPopover>
            </Popover>
        </>
    );
}

function AddTagDialog({ settings, onClose }: { settings: TagEditorSettings; onClose: () => void }) {
    const [keyText, setKeyText] = useState<string>('');
    const [valueText, setValueText] = useState<string>('');
    const { resource, onChanging, inlineTagEditSvc } = settings;
    const { state, showFailure } = useTaggingStatus(resource, keyText, inlineTagEditSvc);
    const [overwrite, setOverwrite] = useState(false);
    const [noCredits, setNoCredits] = useState(true);
    useEvent(onChanging);

    const onClick = useCallback(async () => {
        if (state === 'blank') {
            const { CloudPlatform, Id: ResourceId, ResourceType } = resource;
            const change: TagResourcesJob = {
                AddTags: [{ Key: keyText, Value: valueText }],
                ResourceIds: [{ CloudPlatform, ResourceId, ResourceType }],
                OverwriteConflicts: overwrite,
            };
            const progress = inlineTagEditSvc.update(settings.resource, change, keyText).finally(onChanging.emit);
            onChanging.emit();
            await progress;
            const status = inlineTagEditSvc.getStatus(resource, keyText);
            if (status?.status === 'done') {
                onClose();
            }
        } else if (state === 'failed') {
            showFailure?.();
            onChanging.emit();
            onClose();
        }
    }, [state, keyText, valueText, showFailure, overwrite, resource]);
    const existingValue = resource.CsTags?.[keyText];
    const addDisabled = noCredits ? true : state === 'blank' ? !keyText || !valueText || (!!existingValue && !overwrite) : state === 'started';
    const addLbl =
        state === 'done'
            ? 'Tag Added'
            : state === 'failed'
            ? 'View Failure'
            : state === 'started'
            ? 'Adding Tag...'
            : existingValue
            ? 'Update Tag'
            : 'Add Tag';
    const addColor: MantineColor | undefined = state === 'failed' ? 'error' : undefined;
    const cancelDisabled = state !== 'blank';
    const cancelLbl = state === 'blank' || state === 'started' ? 'Cancel' : 'Close';
    const icon = state === 'started' ? <Loader size={16} color="gray.0" /> : undefined;

    useEffect(() => {
        if (!existingValue) {
            setOverwrite(false);
        }
    }, [existingValue]);

    return (
        <Stack>
            <SectionedPopoverToolbar>
                <Group position="right">
                    <Button onClick={onClick} color={addColor} disabled={addDisabled} leftIcon={icon}>
                        {addLbl}
                    </Button>
                    <Button onClick={onClose} variant="outline" disabled={cancelDisabled}>
                        {cancelLbl}
                    </Button>
                </Group>
            </SectionedPopoverToolbar>
            <TagKeyCombobox onChange={setKeyText} value={keyText} disabled={state === 'started'} />
            <TagValueCombobox onChange={setValueText} value={valueText} tagKey={keyText} disabled={state === 'started'} />
            {!existingValue ? null : (
                <Checkbox label={`Overwrite Existing "${keyText}" Tag`} checked={overwrite} onChange={(e) => setOverwrite(e.target.checked)} />
            )}
            <InlineTaggingCreditBalance onOutOfCredits={setNoCredits} />
        </Stack>
    );
}

export function TagKeyCombobox({ value, onChange, disabled }: { value: string; onChange: (value: string) => void; disabled?: boolean }) {
    const resourceSvc = useDi(ResourceService);
    const fmtSvc = useDi(FormatService);

    const options = useMemo(() => {
        const results = resourceSvc.getTagKeyCountUsingFilter('', 1000);
        return async () => {
            const options = await results;
            return options.map((v) => {
                const label = <TagKvPickerItem info={fmtSvc.formatInt(v.count)} value={v.value} />;
                return {
                    value: v.value,
                    label,
                };
            });
        };
    }, []);

    const pickerHeader = <TagKvPickerItem header info="# Used" value="Existing Keys" />;
    const pickerProps = { width: 340 };
    return <Combobox {...{ pickerHeader, onChange, value, options, disabled, pickerProps }} inputProps={{ label: 'Tag Key' }} />;
}

export function TagValueCombobox(props: { value: string; onChange: (value: string) => void; tagKey: string; disabled?: boolean }) {
    const { value, onChange, tagKey, disabled } = props;
    const resourceSvc = useDi(ResourceService);
    const fmtSvc = useDi(FormatService);

    const model = useMemo(() => {
        return {
            keyChangeTimeout: 0,
            incomingKey: '',
            cachedResult: Promise.resolve([] as { value: string; label: ReactNode }[]),
            updateKey(tagKey: string) {
                if (tagKey !== this.incomingKey) {
                    let resolver = (_: { value: string; label: ReactNode }[]) => {};
                    this.cachedResult = new Promise((r) => (resolver = r));
                    this.incomingKey = tagKey;
                    clearTimeout(this.keyChangeTimeout);
                    this.keyChangeTimeout = setTimeout(() => this.createCachedResult(tagKey, resolver), 400) as unknown as number;
                }
            },
            async createCachedResult(tagKey: string, resolver: (result: { value: string; label: ReactNode }[]) => void) {
                const nextResult = await resourceSvc.getTagValueCounts('', tagKey, 1000);
                if (this.incomingKey === tagKey) {
                    const options = nextResult.map((v) => ({
                        value: v.value,
                        label: <TagKvPickerItem info={fmtSvc.formatInt(v.count)} value={v.value} />,
                    }));
                    resolver(options);
                }
            },
            createOptions() {
                return () => this.cachedResult;
            },
        };
    }, []);

    const options = useMemo(() => {
        model.updateKey(tagKey);
        return () => model.cachedResult;
    }, [tagKey]);

    const pickerHeader = <TagKvPickerItem header info="# Used" value="Existing Values" />;
    const pickerProps = { width: 340 };
    return <Combobox {...{ pickerHeader, onChange, value, options, disabled, pickerProps }} inputProps={{ label: 'Tag Value' }} />;
}

function TagKvPickerItem({ value, info, header }: { value: string; info: ReactNode; header?: boolean }) {
    const theme = useMantineTheme();
    const { floatOnMouseEnter } = useFloatedFullText({
        background: theme.colors.gray[0],
        padding: '2px 4px',
        borderRadius: '4px',
        fontSize: '.9rem',
        opacity: '0.9',
        boxShadow: theme.shadows.sm,
    });
    return (
        <TagKvPickerItemEl header={header}>
            <div onMouseEnter={floatOnMouseEnter}>
                <VisibleSpaces value={value} />
            </div>
            <div>{info}</div>
        </TagKvPickerItemEl>
    );
}
const TagKvPickerItemEl = styled.div<{ header?: boolean }>`
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 6px;
    padding: ${(p) => (p.header ? '3px 25px 3px 19px' : 0)};
    font-size: ${(p) => (p.header ? `${p.theme.fontSizes.sm}px` : undefined)};
    border-bottom: ${(p) => (p.header ? `1px solid ${p.theme.colors.gray[3]}` : 'none')};
    div:first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    div:last-child {
        white-space: nowrap;
        display: flex;
        align-items: center;
        color: ${(p) => p.theme.colors.gray[6]};
    }
`;

function ResourceTagValueEditor(props: TagEditorProps) {
    const { settings, tag, value, flyover } = props;
    const resource = settings.resource;
    const { model, state, getStatusIcon, showFailure } = useKvEditorModel(props, tag);
    const onChanged = useCallback(() => {
        settings.onChanging.emit();
        flyover.close();
    }, [close]);
    const onClick: MouseEventHandler<HTMLDivElement> = useCallback(
        (evt) => {
            if (state === 'failed') {
                showFailure?.();
            } else if (state === 'blank') {
                model.setEditing(true);
                evt.preventDefault();
                if (settings.allowEdit) {
                    flyover.onClickOpen(evt, {
                        renderer: (reposition) => (
                            <InlineTagEditWrapper
                                tag={tag}
                                close={() => model.closeMenu()}
                                item={resource}
                                onSizeChange={reposition}
                                onChange={onChanged}
                                onChanging={settings.onChanging.emit}
                                allowDelete={false}
                                owner={{ inlineTagEditSvc: settings.inlineTagEditSvc, inlineTaggingEnabled: true }}
                            />
                        ),
                    });
                }
            }
        },
        [tag, model, state, value, onChanged, flyover, resource, settings.inlineTagEditSvc, settings.allowEdit, showFailure]
    );

    return (
        <TagKvEl onClick={onClick} allowEdit={settings.allowEdit} editing={model.editing || state !== 'blank'}>
            <span>
                {value === null ? (
                    <></>
                ) : value === undefined ? (
                    <>« No Tag »</>
                ) : value === '' ? (
                    <>« Empty »</>
                ) : (
                    <VisibleSpaces value={value as string} />
                )}
            </span>
            {getStatusIcon(<Edit size={16} />)}
        </TagKvEl>
    );
}

function InlineTagEditWrapper(props: Parameters<typeof InlineEditTag>[0]) {
    return (
        <>
            <FlyoverOverlay opened onClick={props.close} />
            <Card shadow="lg" pt={12} sx={{ width: 300, position: 'static' }} px="xs" withBorder>
                <InlineEditTag {...props} />
            </Card>
        </>
    );
}

function useResourceTagFlyover() {
    return useAnchoredFlyoverEvents(
        {
            subjectAnchor: 'bottom-center',
            offsetY: -4,
            transition: 'opacity',
            anchor: ['top-center', 'bottom-center'],
        },
        'ResourceTagFlyover'
    );
}

interface TagKeyProps extends ICommonTagEditorProps {
    item: PropertyGridItem;
}
function TagKey(props: TagKeyProps) {
    const { flyover, settings, item } = props;
    const { allowEdit } = settings;
    const key = item.property as string;
    const { model, state, getStatusIcon, showFailure } = useKvEditorModel(props, key);

    const onClick: MouseEventHandler<HTMLDivElement> = useCallback(
        (evt) => {
            if (state === 'started') {
                return;
            } else if (state === 'failed') {
                showFailure?.();
            } else {
                model.setEditing(true);
                flyover.onClickOpen(evt, {
                    renderer: () => <TagKeyEditor close={() => model.closeMenu()} settings={settings} tag={key} />,
                });
            }
        },
        [state, key, model, flyover, showFailure]
    );

    return (
        <PropertyGridKey style={{ padding: 0 }}>
            <TagKeyEl onClick={onClick} allowEdit={allowEdit} editing={model.editing || state !== 'blank'}>
                <span>
                    <VisibleSpaces value={item.property as string} />
                </span>
                {getStatusIcon(<Edit size={16} />)}
            </TagKeyEl>
        </PropertyGridKey>
    );
}
function TagKeyEditor(props: { close: () => void; tag: string; settings: TagEditorSettings }) {
    const { close, settings, tag } = props;
    const { resource, inlineTagEditSvc: editSvc, onChanging } = settings;
    const [tagKey, setTagKey] = useState<null | string>(tag);
    const [noCredits, setNoCredits] = useState(true);
    useEvent(onChanging);
    const removeClick = () => {
        setTagKey(null);
    };
    const applyClick = async () => {
        const { CloudPlatform, Id: ResourceId, ResourceType } = resource;
        const change: TagResourcesJob = {
            DeleteRenamedTags: true,
            Renames: tagKey === null ? null : [{ NewTagKey: tagKey, OldTagKey: tag }],
            DeleteTags: tagKey !== null ? null : [{ Key: tag }],
            ResourceIds: [{ CloudPlatform, ResourceId, ResourceType }],
        };
        onChanging.emit();
        editSvc.update(resource, change, tag).finally(onChanging.emit);
        close();
    };
    const existingValue = resource.CsTags?.[tagKey ?? ''];
    const canApply = tagKey !== '' && !noCredits && (tagKey !== tag || !existingValue);

    return (
        <>
            <FlyoverOverlay opened onClick={close} />
            <Card shadow="lg" pt={12} withBorder sx={{ width: 300 }}>
                <Stack>
                    <SectionedPopoverToolbar>
                        <Group>
                            <Tooltip label="Remove Tag">
                                <ActionIcon onClick={removeClick}>
                                    <Trash size={16} />
                                </ActionIcon>
                            </Tooltip>
                            <Space w={1} sx={{ flex: 1 }} />
                            <Button color={tagKey === null ? 'error' : undefined} disabled={!canApply} onClick={applyClick}>
                                {tagKey === null ? 'Delete' : 'Apply'}
                            </Button>
                            <Button variant="outline" onClick={close}>
                                Cancel
                            </Button>
                        </Group>
                    </SectionedPopoverToolbar>
                    {tagKey === null ? (
                        <>
                            <Text size="sm" color="dimmed">
                                Are you sure you want to delete the "{tag}" tag?
                            </Text>
                        </>
                    ) : (
                        <TagKeyCombobox onChange={setTagKey} value={tagKey ?? ''} />
                    )}
                    <InlineTaggingCreditBalance onOutOfCredits={setNoCredits} />
                </Stack>
            </Card>
        </>
    );
}

function useKvEditorModel(editorProps: ICommonTagEditorProps, tag: string) {
    const {
        settings: { resource, onChanging, inlineTagEditSvc },
        flyover,
    } = editorProps;
    useEvent(onChanging);
    const result = useMemo(
        () => ({
            editing: false,
            setEditing(editing: boolean) {
                this.editing = editing;
                this.stateChanged.emit();
            },
            closeMenu() {
                if (this.editing) {
                    flyover.close();
                    this.editing = false;
                    this.stateChanged.emit();
                }
            },
            stateChanged: EventEmitter.empty(),
        }),
        [flyover]
    );
    useEvent(result.stateChanged);
    const status = useTaggingStatus(resource, tag, inlineTagEditSvc);
    const { state } = status;

    useEffect(() => {
        if (state === 'done') {
            result.closeMenu();
        }
    }, [state, result]);

    const showFailure = !status.showFailure
        ? undefined
        : () => {
              showFailure?.();
              onChanging.emit();
          };

    return { model: result, ...status, showFailure };
}

function useTaggingStatus(resource: BaseResource, tag: string, tagEditSvc: InlineTagEditService, successDelayMs: number = 2000) {
    const theme = useMantineTheme();
    const activityPanelSvc = useDi(ActivityDetailsPanelService);
    const job = tagEditSvc.getStatus(resource, tag);
    const status = job?.status;
    const [state, setState] = useState<typeof status | 'blank'>(status ?? 'blank');
    const [showFailDetails, setShowFailDetails] = useState<{ show: () => void }>();

    useEvent(tagEditSvc.onTagChanged);

    useEffect(() => {
        if (status === 'done') {
            if (successDelayMs > 0) {
                setTimeout(() => {
                    setState('blank');
                }, successDelayMs);
                setState('done');
            }
            tagEditSvc.consumeStatus(resource, tag);
        } else if (status === 'failed') {
            setState('failed');
            setShowFailDetails({
                show: () => {
                    activityPanelSvc.openByJobId(job?.jobId ?? '');
                    tagEditSvc.consumeStatus(resource, tag);
                },
            });
        } else if (status === 'bad-request') {
            setState('bad-request');
        } else if (status === 'started') {
            setState('started');
        } else {
            setState('blank');
        }
    }, [status, resource, tag]);

    const getIcon = (blankIcon?: ReactNode) => {
        return state === 'done' ? (
            <Check strokeWidth={1} size={18} stroke={theme.colors.success[6]} />
        ) : state === 'failed' || state === 'bad-request' ? (
            <AlertTriangle stroke={theme.colors.error[5]} size={18} />
        ) : state === 'started' ? (
            <Loader size={18} />
        ) : (
            blankIcon
        );
    };

    return {
        state,
        showFailure: showFailDetails?.show,
        getStatusIcon: getIcon,
    };
}

const TagKvEl = styled.div<{ allowEdit: boolean; editing: boolean }>`
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 6px;
    cursor: ${(p) => (p.allowEdit ? 'pointer' : 'default')};
    border-radius: 3px;
    padding: 2px 6px;
    background: ${(p) => (p.editing ? p.theme.colors.primary[2] : 'transparent')};
    svg {
        opacity: ${(p) => (p.editing ? 1 : 0)};
    }
    &:hover svg {
        opacity: ${(p) => (p.allowEdit ? 1 : 0)};
    }
`;

const TagKeyEl = styled(TagKvEl)`
    margin-left: 24px;
`;

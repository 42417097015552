import { TextInput, Text, Button, Grid, Select, ActionIcon, SelectItem, LoadingOverlay, Group, Title, Space, Divider, Anchor } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    getNewSubscriptionGetCompanyInfo,
    getUserGetCompanyUsers,
    postNewSubscriptionUpdateCompanyInfo,
    postNewSubscriptionUpdateMspCompanyInfo,
} from '@apis/Customers';
import { UserListItem, CompanyInfo, Address, ModelsCustomersAddress } from '@apis/Customers/model';
import { INavigatingEvent, useOnNavigating } from '@root/Services/Router/Router';
import { CompanyAddress, CompanyAddressFields } from '@root/Components/CompanyInfo/CompanyAddress';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { CompanyAdministrationPanelModel } from '@root/Components/CompanyInfo/Administration/CompanyAdministrationPanelModel';
import { useEvent } from '@root/Services/EventEmitter';
import { useCompany } from '@root/Components/Router/CompanyContent';

export interface CompanyInfoFormProps {
    ShowSaveHeader: boolean;
    OnNavigating?: (e: INavigatingEvent) => void;
    model?: CompanyAdministrationPanelModel;
    noHeader?: boolean;
    // undefined if no change, boolean value if changed to that value.
    mspActivateChange?: boolean;
    agreementAccepted?: boolean;
    statJobComplete?: () => void;
    companyIsActive?: boolean;
}

export const CompanyInfoForm = (props: CompanyInfoFormProps) => {
    const userPermissions = useAuthZValues({
        canManage: { Permissions: 'Access' },
    });
    const [users, setUsers] = useState<UserListItem[]>([] as UserListItem[]);
    const [isLoading, setIsLoading] = useState(true);
    const [compInfo, setCompInfo] = useState<CompanyInfo>({});
    const handlers = useMemo(() => ({ submit: () => {}, reset: () => {} }), []);
    useEvent(
        props.model?.discardChangesRequested,
        useCallback(() => handlers.reset(), [])
    );
    useEvent(
        props.model?.saveChangesRequested,
        useCallback(() => handlers.submit(), [])
    );

    const form = useForm({
        initialValues: {
            CompanyId: 0,
            CompanyName: '',
            CompanyWebsite: '',
            BillingAddress: { AddressLine1: '', AddressLine2: '', City: '', StateCode: '', CountryCode: '', ZipCode: '' } as Address,
        },

        validate: {
            CompanyName: (value: string) => (value.length > 0 ? null : 'Invalid company name'),
        },
    });

    useOnNavigating((e) => {
        if (form.isDirty()) {
            e.wait();
            if (props.OnNavigating) {
                props.OnNavigating(e);
            } else {
                e.continue();
            }
        }
    });

    const loadCompanyUsers = () => {
        getUserGetCompanyUsers()
            .then((users) => {
                setUsers(users.filter((u) => u.Status !== 'Deactivated'));
            })
            .catch((e) => {
                //console.log(e);
            });
    };

    const loadCompanyInfo = () => {
        getNewSubscriptionGetCompanyInfo()
            .then((info) => {
                setCompInfo(info);
            })
            .catch((e) => {
                //console.log(e);
            });
    };

    useEffect(() => {
        setIsLoading(true);
        loadCompanyInfo();
        loadCompanyUsers();
    }, []);

    // Prompt the user to save if there's been a change to the company info AND
    // either the address is completely filled out, or the address is completely blank.
    useEffect(() => {
        if (props.model) {
            props.model.isModifiedChanged.emit(form.isDirty());
        }
    }, [
        form.isDirty(),
        form.isDirty('BillingAddress.AddressLine1'),
        form.isDirty('BillingAddress.City'),
        form.isDirty('BillingAddress.CountryCode'),
        form.isDirty('BillingAddress.StateCode'),
        form.isDirty('BillingAddress.ZipCode'),
    ]);

    // If the billing address is completely filled out, enable the "Activate Company" option.
    useEffect(() => {
        if (props.model) {
            props.model.isAddressGood.emit(BillingAddressComplete());
        }
    }, [
        form.isDirty('BillingAddress.AddressLine1'),
        form.isDirty('BillingAddress.City'),
        form.isDirty('BillingAddress.CountryCode'),
        form.isDirty('BillingAddress.StateCode'),
        form.isDirty('BillingAddress.ZipCode'),
    ]);

    function BillingAddressComplete() {
        return (
            form.values.BillingAddress.AddressLine1 != '' &&
            form.values.BillingAddress.City != '' &&
            form.values.BillingAddress.CountryCode != '' &&
            form.values.BillingAddress.StateCode != '' &&
            form.values.BillingAddress.ZipCode != ''
        );
    }

    function BillingAddressEmpty() {
        return (
            form.values.BillingAddress.AddressLine1 == '' &&
            form.values.BillingAddress.City == '' &&
            form.values.BillingAddress.CountryCode == '' &&
            form.values.BillingAddress.StateCode == '' &&
            form.values.BillingAddress.ZipCode == ''
        );
    }

    useEffect(() => {
        // set fields to values from api call and reset touched/dirty (initial values)
        resetFormValues();
        setIsLoading(false);
    }, [compInfo]);

    const handleSubmit = async (e: any) => {
        if (e) {
            e.preventDefault();
        }

        let companyName = form.isDirty('CompanyName') ? form.values.CompanyName : null;
        let CompanyWebsite = form.isDirty('CompanyWebsite') ? form.values.CompanyWebsite : null;
        let billingAddress = form.isDirty('BillingAddress') && form.values.BillingAddress ? form.values.BillingAddress : null;
        let agreementAccepted = props.agreementAccepted;

        let companyInfo = {
            CompanyId: compInfo.CompanyId,
            CompanyName: companyName,
            CompanyWebsite: CompanyWebsite,
            BillingAddress: billingAddress,
            AgreementAccepted: agreementAccepted,
            IsActive: props.companyIsActive,
        } as CompanyInfo;

        if (hasChanges(companyInfo)) {
            await SubmitChanges(companyInfo);
        }
    };
    handlers.submit = () => handleSubmit(null);

    const hasChanges = (companyInfo: CompanyInfo) => {
        return (
            companyInfo.CompanyName ||
            companyInfo.CompanyWebsite ||
            companyInfo.BillingAddress ||
            props.mspActivateChange != undefined ||
            companyInfo.AgreementAccepted != undefined
        );
    };

    const SubmitChanges = async (changes: CompanyInfo) => {
        try {
            setIsLoading(true);
            if (props.mspActivateChange !== undefined) {
                await postNewSubscriptionUpdateMspCompanyInfo(changes, { activate: props.mspActivateChange });
            } else {
                await postNewSubscriptionUpdateCompanyInfo(changes);
            }
            if (props.statJobComplete) {
                props.statJobComplete();
            }
            loadCompanyInfo();
        } finally {
            setIsLoading(false);
        }
    };

    const resetFormValues = () => {
        form.setValues({
            CompanyId: compInfo.CompanyId ?? 0,
            CompanyName: compInfo.CompanyName ?? '',
            CompanyWebsite: compInfo.CompanyWebsite ?? '',
            BillingAddress: compInfo.BillingAddress
                ? ({
                      AddressLine1: compInfo.BillingAddress.AddressLine1 ?? '',
                      AddressLine2: compInfo.BillingAddress.AddressLine2 ?? '',
                      City: compInfo.BillingAddress.City ?? '',
                      StateCode: compInfo.BillingAddress.StateCode ?? '',
                      ZipCode: compInfo.BillingAddress.ZipCode ?? '',
                      CountryCode: compInfo.BillingAddress.CountryCode ?? '',
                  } as ModelsCustomersAddress)
                : ({ AddressLine1: '', AddressLine2: '', City: '', StateCode: '', ZipCode: '', CountryCode: '' } as ModelsCustomersAddress),
        });

        form.resetTouched();
        form.resetDirty();
    };

    handlers.reset = () => resetFormValues();
    return (
        <>
            {isLoading && <LoadingOverlay visible={true} />}
            <div>
                <form onSubmit={handleSubmit}>
                    <div
                        style={{
                            paddingBottom: '1rem',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        {props.noHeader ? (
                            <></>
                        ) : (
                            <>
                                <Group position="apart" mb="md">
                                    <Title data-atid="SettingsMainHeader" order={3} px="xl" pt="md">
                                        Company Info
                                    </Title>
                                    {userPermissions.canManage ? (
                                        <Group style={{ paddingRight: '26px', paddingTop: '11px' }}>
                                            <Button
                                                data-atid="RevertButton"
                                                disabled={!form.isDirty()}
                                                size="sm"
                                                variant="outline"
                                                onClick={() => resetFormValues()}
                                                styles={() => ({
                                                    root: {
                                                        marginRight: 10,
                                                    },
                                                })}
                                            >
                                                Revert Changes
                                            </Button>
                                            <Button data-atid="SaveButton" disabled={!form.isDirty()} type="submit" size="sm" color="primary">
                                                Save
                                            </Button>
                                        </Group>
                                    ) : (
                                        <></>
                                    )}
                                </Group>
                                <Divider />
                            </>
                        )}
                    </div>

                    {/* Need marginRight to elimiate horizontal scroll bar */}
                    <Grid style={{ padding: props.noHeader ? '0 24px' : '24px', marginRight: '1px' }}>
                        <Grid.Col span={4}>
                            <Text>Company Name</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <TextInput data-atid="CompanyNameInput" {...form.getInputProps('CompanyName')} />
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Text>Company Website</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <TextInput data-atid="CompanyWebsiteInput" {...form.getInputProps('CompanyWebsite')} />
                        </Grid.Col>
                        <Grid.Col span={12} style={{ borderBottom: '1px solid lightgray', marginBottom: '1rem' }}></Grid.Col>
                        <Grid.Col span={4}>
                            <Text>Company Address</Text>
                            <Text color="dimmed" size="sm">
                                This address will be added to invoices.
                            </Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <CompanyAddress prefix="BillingAddress." form={form} isWithinPortal={false} />
                        </Grid.Col>
                    </Grid>
                </form>
            </div>
        </>
    );
};

import { DataGrid } from '@root/Components/DataGrid';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { ColumnConfig, DataGridState } from '@root/Components/DataGrid/Models';
import { ActionIcon, Anchor, Button, LoadingOverlay, Menu, Textarea, Text } from '@mantine/core';
import { useState, useMemo, useEffect, useContext, useCallback, ReactNode } from 'react';
import { useId } from '@root/Services/IdGen';
import { CustomerSubsidyRequestCustomerSubsidyStatus as Status, CustomerSubsidyViewModel } from '@apis/Customers/model';
import CompanyRequestService from '@root/Services/CloudIntelligence/CompanyRequestService';
import { observer } from 'mobx-react';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { AnchorButton } from '@root/Design/Primitives';
import { ChevronDown } from 'tabler-icons-react';
import { openConfirmModal } from '@mantine/modals';
import { Trash } from 'tabler-icons-react';
import { useTheme } from '@emotion/react';
import { CustomColors } from '@root/Design/Themes';
import { useLink } from '@root/Services/Router/Router';
import { useNav } from '@root/Services/NavigationService';

interface ICustomerGridProps {
    renderToolbar: () => ReactNode;
    mode: 'picker' | 'full';
}
function CustomerGrid({ renderToolbar, mode }: ICustomerGridProps) {
    const [grid, setGrid] = useState<DataGridModel>();
    const companyRequestSvc = useContext(CompanyRequestService);
    const formatSvc = useDi(FormatService);
    const data: CustomerSubsidyViewModel[] = companyRequestSvc.companies;
    const { canApprove } = useAuthZValues({
        canApprove: { PartnerCompany: 'Approve' },
    });
    const theme = useTheme();
    const link = useLink();
    const { getDescendUrl } = useNav();

    useEffect(() => {
        if (data?.length && grid && mode === 'picker') {
            grid.scrollToColumn('company');
        }
    }, [mode, grid, data]);

    useEffect(() => {
        companyRequestSvc.refreshCompanies();
    }, []);

    const review = async (requestId: number, approve: boolean, rejectionReason: string) => {
        await companyRequestSvc.review(requestId, approve, rejectionReason);
        grid?.refresh();
    };

    const rejectReview = async (requestId: number, approve: boolean) => {
        let reason: string = '';
        completeRejection(requestId, approve, reason);
    };

    const deleteInvitation = async (requestId: number) => {
        await companyRequestSvc.deleteInvitation(requestId);
        grid?.refresh();
    };

    const completeRejection = (requestId: number, approve: boolean, reason: string) => {
        openConfirmModal({
            title: (
                <span style={{ color: 'red' }}>
                    <b>Reject this request?</b>
                </span>
            ),
            children: <Textarea minRows={3} label="Reason for rejection" onChange={(event) => (reason = event.currentTarget.value)}></Textarea>,
            centered: true,
            labels: { confirm: 'Reject request', cancel: 'Cancel' },
            onConfirm: async () => await review(requestId, approve, reason),
            confirmProps: { color: 'error' },
        });
    };

    const handleDeleteInvite = (item: CustomerSubsidyViewModel) => {
        openConfirmModal({
            title: (
                <span style={{ color: 'red' }}>
                    <b>Delete Invitation?</b>
                </span>
            ),
            children: <Text>Are you sure you want to delete the invitation for {item.Company}?</Text>,
            centered: true,
            labels: { confirm: 'Delete invitation', cancel: 'Cancel' },
            onConfirm: async () => await deleteInvitation(item.Id!),
            confirmProps: { color: 'error' },
        });
    };

    const columns = useMemo(() => {
        return [
            {
                accessor: '',
                defaultWidth: 40,
                id: 'delete',
                cellRenderer: (item) => {
                    return (
                        <>
                            {item.Status === 'PendingApproval' && canApprove && (
                                <ActionIcon onClick={() => handleDeleteInvite(item)}>
                                    <Trash size={18} color={theme.colors?.gray?.[5] as CustomColors} />
                                </ActionIcon>
                            )}
                        </>
                    );
                },
            },
            {
                header: 'Connection Status',
                accessor: 'Status',
                defaultWidth: 190,
                id: 'status',
                sortField: 'statusSort',
                filter: {
                    filterField: 'approved',
                    filterType: 'string',
                    name: 'Status',
                    options: {
                        getValueProvider: () =>
                            (Object.keys(Status) as Array<keyof typeof Status>).map((key) => ({
                                value: key,
                                label: formatSvc.userFriendlyCamelCase(key),
                            })),
                    },
                },
                cellRenderer: (item) => {
                    return !item.Status ? (
                        ''
                    ) : !canApprove || item.Status !== 'PendingApproval' ? (
                        formatSvc.userFriendlyCamelCase(item.Status)
                    ) : (
                        <>
                            <Menu shadow="md" width={200} withinPortal position="bottom-start">
                                <Menu.Target>
                                    <AnchorButton text="Pending Approval" icon={<ChevronDown />} />
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <Menu.Item icon={<i className="ti ti-check"></i>} onClick={() => review(item.Id!, true, '')}>
                                        Approve
                                    </Menu.Item>
                                    <Menu.Item icon={<i className="ti ti-ban"></i>} onClick={() => rejectReview(item.Id!, false)}>
                                        Reject
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                        </>
                    );
                },
            },
            {
                header: 'Rejection reason',
                accessor: 'RejectionReason',
                defaultWidth: 250,
                id: 'rejectionReason',
                sortField: 'rejectionReasonSort',
                filter: {
                    filterField: 'rejectionReason',
                    filterType: 'string',
                    name: 'Rejection reason',
                },
                cellRenderer: (item) => {
                    return item.RejectionReason === null ? '-' : item.RejectionReason;
                },
            },
            {
                header: 'Account Rep Name',
                accessor: 'AccountRepName',
                defaultWidth: 200,
                id: 'accountRepName',
                sortField: 'accountRepNameSort',
                filter: {
                    filterField: 'accountRepName',
                    filterType: 'string',
                    name: 'Account Rep Name',
                    options: {
                        getValueProvider: () => companyRequestSvc.getAccountReps().map((value) => ({ value, label: value ?? 'None' })),
                    },
                },
            },
            {
                header: 'Company',
                accessor: 'Company',
                defaultWidth: 200,
                id: 'company',
                sortField: 'companySort',
                filter: {
                    filterField: 'company',
                    filterType: 'string',
                    name: 'Company',
                    options: {
                        getValueProvider: () => companyRequestSvc.getCompanies().map((value) => ({ value, label: value ?? 'None' })),
                    },
                },
                cellRenderer: (item) => {
                    return item.CustomerCompanyId ? (
                        <Anchor {...link(getDescendUrl('company-detail', { companyId: item.Id?.toString() ?? '' }))}>{item.Company}</Anchor>
                    ) : (
                        item.Company
                    );
                },
            },
            {
                header: 'Invite Date',
                accessor: 'InvitationDate',
                defaultWidth: 150,
                id: 'inviteDate',
                sortField: 'inviteDateSort',
                filter: {
                    filterField: 'inviteDate',
                    filterType: 'date',
                    name: 'Invite Date',
                },
                cellRenderer: (item) => {
                    return !item.InvitationDate ? '-' : formatSvc.toLocalLongFormat(item.InvitationDate);
                },
            },
            {
                header: 'Reviewed Date',
                accessor: 'ReviewedDate',
                defaultWidth: 150,
                id: 'reviewedDate',
                sortField: 'reviewedDateSort',
                filter: {
                    filterField: 'reviewedDate',
                    filterType: 'date',
                    name: 'Reviewed Date',
                },
                cellRenderer: (item) => {
                    return !item.ReviewedDate ? '-' : formatSvc.toLocalLongFormat(item.ReviewedDate);
                },
            },
            {
                header: 'Sign Up Date',
                accessor: 'InvitationAcceptDate',
                defaultWidth: 150,
                id: 'InvitationAcceptDate',
                sortField: 'InvitationAcceptDate',
                filter: {
                    filterField: 'InvitationAcceptDate',
                    filterType: 'date',
                    name: 'Sign Up Date',
                },
                cellRenderer: (item) => {
                    return !item.InvitationAcceptDate ? '-' : formatSvc.toLocalLongFormat(item.InvitationAcceptDate);
                },
            },
        ] as ColumnConfig<CustomerSubsidyViewModel>[];
    }, [grid, data]);

    return companyRequestSvc.loading ? (
        <LoadingOverlay visible />
    ) : (
        <DataGrid
            rightTopPlaceHolder={renderToolbar()}
            selectionMode="none"
            disableHighlight
            dataSource={data}
            columns={columns}
            hideHeader
            displayMode="grid"
            onModelLoaded={setGrid}
        />
    );
}

export default observer(CustomerGrid);

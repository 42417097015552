import styled from '@emotion/styled';
import { Progress } from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { System } from '@root/Services/System';
import { ContentRouter } from '../Router/ContentRouter';
import { CompanyContent, useCompany } from '../Router/CompanyContent';
import { SiteMenu } from './SiteMenu';
import { Router } from '@root/Services/Router/Router';
import { HeaderBar } from './HeaderBar';
import { useEffect, useMemo, useState } from 'react';
import { AwsPrivateOfferSuccessPage, RegisterCompany } from '@root/Site';
import { UnknownUser } from '@root/Site/Error/UnknownUser';
import { VerifyEmail } from '@root/Site/Error/VerifyEmail';
import { LayoutService } from '@root/Design/Layout';
import { RegistrationError } from '@root/Site/Error/RegistrationError';
import { AzureMarketplaceLandingPage } from '@root/Site/Settings/Billing/AzureMarketplaceLandingPage';
import { AwsMarketplaceLandingPage } from '@root/Site/Settings/Billing/AwsMarketplaceLandingPage';
import { StagedCompanyLandingPage } from '@root/Site/StagedCompany';
import { _404Error } from '@root/Site/Error/_404Error';
import { UsageReportingService } from '@root/Services/UsageReportingService';

export const ShellEl = styled.div`
    height: 100%;
    display: flex;
`;

const PageContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 0;
`;

export function Shell() {
    const router = useDi(Router);
    const system = useDi(System);
    const shellChangeNeeded = useMemo(() => EventEmitter.empty(), []);
    const layoutSvc = useDi(LayoutService);
    useEffect(() => {
        window.addEventListener('resize', () => layoutSvc.windowSizeInvalidated.emit());
        return () => window.removeEventListener('resize', () => layoutSvc.windowSizeInvalidated.emit());
    }, []);

    useEvent(shellChangeNeeded);
    useEvent(system.siteReady);
    const routeStart = router.getCurrentRoute()?.newRoute[0]?.name?.toLocaleLowerCase();

    switch (routeStart) {
        case 'login':
            return <Login onReloadNeeded={shellChangeNeeded.emit} />;
        case '_unknownerror':
            return <UnknownUser />;
        case '_unverified':
            return <VerifyEmail />;
        case '_registerfailed':
            return <RegistrationError />;
        case '_az-forward':
            return <AzureMarketplaceLandingPage />;
        case '_aws-forward':
            return <AwsMarketplaceLandingPage />;
        case '_aws-success':
            return <AwsPrivateOfferSuccessPage />;
        case '_stage-check':
            return <StagedCompanyLandingPage />;
        case '404':
            return <_404Error />;
        case routeStart?.includes('chargebee-subscription'):
            return <CompanyContent>{() => <ShellContent content={'chargebeeSub'} />}</CompanyContent>;
        case '_register':
            return (
                <>
                    <RegisterCompany onFinished={shellChangeNeeded.emit}></RegisterCompany>
                </>
            );

        default:
            return <CompanyContent>{() => <ShellContent content={'default'} />}</CompanyContent>;
    }
}

function Login({ onReloadNeeded }: { onReloadNeeded: () => void }) {
    const router = useDi(Router);
    const route = useEventValue(router.route);
    const [start] = route?.newRoute ?? [];
    useEffect(() => {
        if (start?.name?.toLocaleLowerCase() === 'login') {
            const { redir } = start.data as { redir?: string };
            if (redir?.toLocaleLowerCase() === 'close') {
                window.close();
            } else {
                router.navigate(redir?.replace(/^\/*/g, '') || '/');
            }
        }
        onReloadNeeded();
    }, [start]);

    return <></>;
}

function ShellContent({ content }: { content: string }) {
    const system = useDi(System);
    const siteReady = useEventValue(system.siteReady);
    const company = useCompany();
    const usageReportingSvc = useDi(UsageReportingService);

    const layoutSvc = useDi(LayoutService);
    const [container, setContainer] = useState<HTMLDivElement | null>();
    const [width, setWidth] = useState(800);
    const updateWidth = () => {
        const containerWidth = container?.clientWidth;
        if (containerWidth && containerWidth > 0) {
            setWidth(containerWidth);
        }
    };
    useEvent(layoutSvc.windowSizeInvalidated, updateWidth);
    useEffect(() => {
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, [container]);
    useEffect(() => {
        usageReportingSvc.init();
        usageReportingSvc.ping();
    }, []);

    const defaultPage =
        content === 'default'
            ? company?.Type === 'StrategicPartner'
                ? `/${company.DatabaseName}/cloud-intelligence/customer-requests`
                : company?.Type === 'Msp'
                ? `/${company?.DatabaseName}/msp/my-companies`
                : company?.Type === 'Support' || company?.Type === 'PlatformSupport'
                ? `/${company?.DatabaseName}/read-only/support-companies`
                : `/${company?.DatabaseName}/landing/app-redirect`
            : content === 'chargebeeSub'
            ? `/${company?.DatabaseName}/settings/subscription`
            : '';

    return (
        <ShellEl>
            {!siteReady ? (
                <Progress />
            ) : (
                <>
                    <SiteMenu />
                    <PageContent ref={setContainer} data-atid="PageContent">
                        <HeaderBar headerWidth={width - 150} />
                        <ContentRouter startDepth={0} defaultPage={defaultPage} />
                    </PageContent>
                </>
            )}
        </ShellEl>
    );
}

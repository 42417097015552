import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { createStyles } from '@mantine/core';
import { Fragment, ReactNode } from 'react';
import { ChevronRight } from 'tabler-icons-react';
import { useFloatedFullText } from '../Text/FloatedFullText';

export const PropertyGridSectionBodyEl = styled.div<{
    isRoot?: boolean;
    valueWidth?: number;
    size?: 'compact';
    layout?: 'rows' | 'labels';
    columns?: number;
}>`
    --pg-mv: ${(p) => (p.isRoot ? 0 : p.size === 'compact' ? p.theme.spacing.xs * 0.75 : p.theme.spacing.xs)}px;
    --pg-mh: ${(p) => (p.isRoot ? 0 : p.size === 'compact' ? p.theme.spacing.md * 0.75 : p.theme.spacing.md)}px;
    --pg-w: ${(p) => p.valueWidth ?? 600}px;
    --pg-fs: ${(p) => (p.size === 'compact' ? p.theme.fontSizes.sm * 0.8 : p.theme.fontSizes.sm * 0.9)}px;
    --pg--key-fs: ${(p) => (p.size === 'compact' || p.layout === 'labels' ? p.theme.fontSizes.sm * 0.8 : p.theme.fontSizes.sm)}px;
    --pg-px: ${(p) => p.theme.spacing.xs}px;
    --pg-py: ${(p) => p.theme.spacing.xs / 2}px;
    --pg-p: var(--pg-py) var(--pg-px);
    font-size: var(--pg-fs);
    margin: var(--pg-mv) 0 var(--pg-mv) var(--pg-mh);
    border: solid ${(p) => (p.isRoot ? 0 : 1)}px ${(p) => p.theme.colors.gray[3]};
    border-right: none;
    flex: 1;

    ${(p) =>
        p.layout === 'labels' && p.columns && p.columns > 1
            ? css`
                  flex: 1;
                  display: grid;
                  grid-template-columns: repeat(${p.columns}, minmax(0, 1fr));
              `
            : ''};

    ${(p) =>
        p.layout !== 'labels'
            ? null
            : css`
                  .pg-row {
                      display: block;
                      border-top: none;
                      --pg-w: none;
                      align-items: flex-start;
                      border-radius: 4px;
                  }
                  .pg-expander {
                      display: none;
                  }
                  .pg-key,
                  .pg-index {
                      font-size: ${p.theme.fontSizes.sm * 0.85}px;
                      padding: ${p.theme.spacing.xs / 2}px ${p.theme.spacing.sm}px;
                      padding-bottom: 0;
                      color: ${p.theme.colors.gray[7]};
                      flex: 0;
                  }
                  .pg-value {
                      padding: ${p.theme.spacing.xs / 2}px ${p.theme.spacing.sm}px;
                      padding-top: 0;
                      font-size: ${p.theme.fontSizes.sm * 0.9}px;
                      flex: 1;
                  }
              `}
`;

export const PropertyGridExpander = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: calc(var(--pg-py) * -1) calc(var(--pg-px) * -1);
`;
PropertyGridExpander.defaultProps = {
    className: 'pg-expander',
};

export const PropertyGridRowEl = styled.div`
    display: flex;
    align-items: center;
    &:first-of-type {
        border-top: none;
    }
    &:hover {
        background: ${(p) => p.theme.colors.gray[1]};
    }
    &[data-optionsopen='y'] {
        background: ${(p) => p.theme.colors.primary[2]};
    }
    &[data-hasoptions='y'] {
        cursor: pointer;
        &:hover {
            background: ${(p) => p.theme.colors.primary[2]};
        }
    }
`;
PropertyGridRowEl.defaultProps = {
    className: 'pg-row',
};

export const PropertyGridHeader = styled.div`
    padding: ${(p) => p.theme.spacing.xs / 2}px;
    flex: 1;
    font-weight: bold;
    background-color: ${(p) => p.theme.colors.gray[4]};
`;

export const PropertyGridText = styled.div`
    padding: var(--pg-p);
    flex: 1;
`;

export function ChainedLabel({ items }: { items: ReactNode[] }) {
    const divider = <ChevronRight strokeWidth={1} size={16} />;
    const {
        classes: { label, labelHovered },
        cx,
    } = useChainedLblStyle();
    const { floatOnMouseEnter } = useFloatedFullText(cx(label, labelHovered));

    return (
        <ChainedLabelEl className={`pg-key ${label}`} onMouseEnter={floatOnMouseEnter}>
            <div className="pg-rtl-wrapper">
                {items.map((item, idx) => (
                    <Fragment key={idx}>
                        {idx === 0 ? null : divider}
                        {item}
                    </Fragment>
                ))}
            </div>
        </ChainedLabelEl>
    );
}
const ChainedLabelEl = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    text-align: left;
    flex: 0 0 min-content !important;
    .pg-rtl-wrapper {
        direction: ltr;
    }
`;
const useChainedLblStyle = createStyles((theme) => ({
    label: {
        '.pg-rtl-wrapper': {
            display: 'inline',
            '> .pg-key, > svg': {
                display: 'inline',
                fontSize: '0.75rem',
                padding: 0,
            },
            '> svg': {
                color: theme.colors.gray[5],
                marginBottom: '-4px',
            },
        },
    },
    labelHovered: {
        backgroundColor: theme.colors.primary[1],
        borderRadius: theme.radius.sm,
        padding: '0 6px',
        boxShadow: theme.shadows.sm,
    },
}));

export const PropertyGridKey = styled(PropertyGridText)``;
PropertyGridKey.defaultProps = {
    className: 'pg-key',
};

export const PropertyGridIndex = styled.div`
    flex: 0;
    border-right: solid 1px ${(p) => p.theme.colors.gray[3]};
    padding: var(--pg-p);
`;
PropertyGridIndex.defaultProps = {
    className: 'pg-index',
};

export const PropertyGridValueEl = styled.div`
    min-width: var(--pg-w);
    max-width: var(--pg-w);
    word-break: break-word;
    padding: var(--pg-p);
`;
PropertyGridValueEl.defaultProps = {
    className: 'pg-value',
};

export const PropertyGridArrayItem = styled.div`
    display: flex;
    border-top: solid 1px ${(p) => p.theme.colors.gray[3]};
    flex: 1;
`;
export const PropertyGridArrayItemValue = styled.div`
    &:first-of-type {
        border-top: none;
    }
    flex: 1;
`;

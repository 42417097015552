import { Query } from '@apis/Invoices/model';
import { QueryExpr, QueryResult } from '@apis/Resources';
import styled from '@emotion/styled';
import { ActionIcon, Box, Divider, Group, Space, Stack, Text, Tooltip, useMantineTheme } from '@mantine/core';
import { LineItemCompactToken } from '@root/Components/Filter/Design';
import { dataFilterFacetFactory } from '@root/Components/Filter/FacetPickerInput';
import { DataFilterModel, DataFilters, DataFilterValueRenderer, FilterExpr, UtcDataFilterSingleDate } from '@root/Components/Filter/Filters';
import { IFieldInfoProvider, SchemaFieldNameProvider } from '@root/Components/Filter/Services';
import { FieldPicker } from '@root/Components/Picker/FieldPicker';
import { AnchorButton, InfoIconTooltip, TooltipWhite } from '@root/Design/Primitives';
import { useDi } from '@root/Services/DI';
import { useEvent, useToggle } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { IInvoiceRollup } from '@root/Services/Invoices/InvoiceSchemaService';
import { queryBuilder, SchemaService, SchemaValueProvider } from '@root/Services/QueryExpr';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronDown, ChevronRight, InfoCircle, Plus, Trash } from 'tabler-icons-react';
import { NamedFilterSetModel, IRulesModel, INamedFilterModel } from './NamedFilterSetModel';

interface ISectionDescription {
    title?: string;
    helpText?: ReactNode;
}

interface IDescriptions {
    inclusion?: ISectionDescription;
    exclusion?: ISectionDescription;
}

export interface FilterSetEditorProps {
    filterSet: NamedFilterSetModel;
    disabled?: boolean;
    datasource: <T>(query: Query) => Promise<QueryResult<T>>;
    schemaSvc: SchemaService;
    descriptions?: IDescriptions;
}
export function FilterSetEditor({ filterSet, disabled, schemaSvc, datasource, descriptions }: FilterSetEditorProps) {
    const valueProvider = useMemo(() => new SchemaValueProvider(schemaSvc, datasource), [schemaSvc]);
    const fieldInfoProvider = useMemo(() => new SchemaFieldNameProvider(schemaSvc), [schemaSvc]);
    const commonProps = { datasource, valueProvider, fieldInfoProvider, schemaSvc, disabled, descriptions };

    return (
        <Stack>
            <FilterSection rulesModel={filterSet.inclusionRules} mode="inclusion" {...commonProps} />
            <FilterSection rulesModel={filterSet.exclusionRules} mode="exclusion" {...commonProps} />
        </Stack>
    );
}

interface IInvoiceFacetItem {
    value: string;
    count?: number;
    total?: number;
}
export function useFilterValueProviders(datasource: <T>(query: Query) => Promise<QueryResult<T>>) {
    const fmtSvc = useDi(FormatService);
    const createFacetFilter = useCallback(() => {
        const itemLookup = new Map<string, IInvoiceFacetItem>();
        return dataFilterFacetFactory<IInvoiceFacetItem>({
            columns: [
                {
                    accessor: (v) => v.value,
                    type: 'string',
                    fill: true,
                    header: 'Options',
                },
                {
                    accessor: (v) => v.total,
                    type: 'number',
                    formatter: (v) => (typeof v === 'number' ? fmtSvc.formatMoneyNonZeroTwoDecimals(v) : <>&mdash;</>),
                    header: 'Cost',
                },
            ],
            valuesProvider: async (facet: QueryExpr) => {
                const results = await queryBuilder<IInvoiceRollup>()
                    .take(10000)
                    .select((b) => ({
                        value: b.fromExpr<string>(facet),
                        count: b.count(),
                        total: b.sum(b.model['lineItem/UnblendedCost']),
                    }))
                    .execute(datasource);

                const items = results?.Results ?? [];
                const values = items.map((item) => {
                    itemLookup.set(item.value, item);
                    return item.value;
                }, itemLookup);
                return values;
            },
            itemLookup: (value) => itemLookup.get(value) ?? { value },
        });
    }, []);

    const getOrAdd = useMemo(() => {
        const results = new Map<string, DataFilterValueRenderer>();
        return (field: string, valueRendererFactory: () => DataFilterValueRenderer) =>
            results.get(field) ?? results.set(field, valueRendererFactory()).get(field)!;
    }, []);

    return useCallback(
        (filterModel: FilterExpr, model: DataFilterModel) => {
            const field = filterModel.field;
            const operation = filterModel.operation;
            const type = filterModel.getFilterType();
            return type === 'string' && (operation === 'eq' || operation === 'ne')
                ? getOrAdd(field, createFacetFilter)
                : type === 'date'
                ? UtcDataFilterSingleDate
                : undefined;
        },
        [datasource]
    );
}

interface FilterConfig {
    valueProvider: SchemaValueProvider;
    fieldInfoProvider: IFieldInfoProvider;
    schemaSvc: SchemaService;
    disabled?: boolean;
    datasource: <T>(query: Query) => Promise<QueryResult<T>>;
    descriptions?: IDescriptions;
}
interface FilterSectionProps extends FilterConfig {
    rulesModel: IRulesModel;
    mode: 'inclusion' | 'exclusion';
    helpText?: string;
}

const getUfText = (descriptions: IDescriptions | undefined, mode: 'inclusion' | 'exclusion') => {
    const description = mode === 'exclusion' ? descriptions?.exclusion : descriptions?.inclusion;
    const ufType = mode === 'inclusion' ? 'Included' : 'Excluded';
    const typeTitle = ufType === 'Included' ? 'Inclusion' : 'Exclusion';
    return {
        title: description?.title ?? `${typeTitle} conditions`,
        info: description?.helpText,
    };
};

function FilterSection({ rulesModel, ...props }: FilterSectionProps) {
    const theme = useMantineTheme();
    useEvent(rulesModel.onSetChanged);
    const { title, info } = getUfText(props.descriptions, props.mode);
    return (
        <RuleSectionEl>
            <Group px={4} position="apart">
                <Text size="sm" pl={8}>
                    {title}
                </Text>
                {!info ? null : <InfoIconTooltip label={info} />}
            </Group>
            <Space h={4} />
            <Stack>
                {rulesModel.items.map((item, idx) => (
                    <FilterSectionItem
                        key={idx}
                        disabled={props.disabled}
                        namedFilter={item}
                        schemaSvc={props.schemaSvc}
                        fieldInfoProvider={props.fieldInfoProvider}
                        valueProvider={props.valueProvider}
                        datasource={props.datasource}
                    />
                ))}
                {!props.disabled && (
                    <RuleSectionItemEl anchor onClick={() => rulesModel.addItem()}>
                        <AnchorButton icon={<Plus size={14} />} size="xs" text="Add condition group" onClick={() => {}} />
                    </RuleSectionItemEl>
                )}
            </Stack>
        </RuleSectionEl>
    );
}

const RuleSectionEl = styled.div`
    border: solid 1px ${(p) => p.theme.colors.gray[3]};
    border-radius: ${(p) => p.theme.radius.md}px;
    background: ${(p) => p.theme.colors.gray[2]};
    padding: 8px;
`;

function FilterSectionItem({ namedFilter, ...props }: { namedFilter: INamedFilterModel } & FilterConfig) {
    const { getFilters, setFilters, raiseFilterChanged } = namedFilter;
    const [filterModel, setFilterModel] = useState<DataFilterModel>();
    const [collapsed, { toggle }] = useToggle(false);
    useEffect(() => {
        const listener = filterModel?.filtersChanged.listen(() => {
            setFilters(filterModel.getFilters());
        });
        return () => listener?.dispose();
    }, [filterModel]);
    const addFilter = useCallback(() => {
        filterModel?.addEmptyFilter(true);
    }, [filterModel]);

    const defaultName = `Condition Group ${namedFilter.getIndex() + 1}`;
    const name = namedFilter.getName() || defaultName;
    const ExpanderIcon = collapsed ? ChevronRight : ChevronDown;
    const valueRendererProvider = useFilterValueProviders(props.datasource);

    return (
        <RuleSectionItemEl>
            <RuleSectionItemHeader>
                <ActionIcon onClick={toggle} variant="transparent">
                    <ExpanderIcon size={16} />
                </ActionIcon>
                <Text size="sm">{name}</Text>
                <Space w="lg" sx={{ flex: 1 }} />
                <Tooltip label="Remove condition" disabled={props.disabled}>
                    <ActionIcon className="hover-visible" onClick={namedFilter.remove} variant="transparent">
                        <Trash size={16} />
                    </ActionIcon>
                </Tooltip>
            </RuleSectionItemHeader>
            <Divider />
            <Box px={12} py={12} hidden={collapsed}>
                <DataFilters
                    filters={getFilters()}
                    disabled={props.disabled}
                    onChange={setFilters}
                    valueProvider={props.valueProvider}
                    fieldInfoProvider={props.fieldInfoProvider}
                    valueRendererProvider={valueRendererProvider}
                    renderFieldPicker={(select) => (
                        <FieldPicker mode="single" selections={[]} schema={props.schemaSvc} onChange={([f]) => select(f.path)} />
                    )}
                    onModelLoaded={setFilterModel}
                    dataFiltersAsLineItem
                    lineItemCompact
                />
                <Space h={4} />
                <LineItemCompactToken styles={{ width: '100%' }} onClick={addFilter}>
                    <Group spacing={4}>
                        <Plus size={16} /> Add Condition
                    </Group>
                </LineItemCompactToken>
            </Box>
        </RuleSectionItemEl>
    );
}

const RuleSectionItemHeader = styled.div`
    background: ${(p) => p.theme.colors.gray[1]};
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
    padding: 4px 8px;
    .hover-visible {
        visibility: hidden;
    }
    &:hover .hover-visible {
        visibility: visible;
    }
`;

const RuleSectionItemEl = styled.div<{ anchor?: boolean }>`
    border: solid 1px ${(p) => p.theme.colors.gray[4]};
    border-radius: ${(p) => p.theme.radius.sm}px;
    background: ${(p) => (p.anchor ? p.theme.colors.gray[1] : p.theme.white)};
    box-shadow: ${(p) => (p.anchor ? undefined : p.theme.shadows.xs)};
    &:hover {
        box-shadow: ${(p) => (p.anchor ? p.theme.shadows.sm : undefined)};
        background: ${(p) => (p.anchor ? p.theme.colors.gray[1] : undefined)};
    }
    display: ${(p) => (p.anchor ? 'flex' : 'block')};
    flex-direction: column;
    align-items: stretch;
    ${(p) => (!p.anchor ? '' : '&> a')} {
        &:hover {
            text-decoration: none;
        }
    }
`;

import { observer } from 'mobx-react';
import { ReplaceTagsCard } from './ReplaceTagsCard';
import { RuleEditor } from './Model';
import { StandardRuleLayout } from './StandardRuleLayout';

export const ReplacementRule = observer(function ReplacementRule({ ruleEditor }: { ruleEditor: RuleEditor }) {
    return (
        <StandardRuleLayout ruleEditor={ruleEditor}>
            <ReplaceTagsCard ruleEditor={ruleEditor} />
        </StandardRuleLayout>
    );
});

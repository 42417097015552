import { ISelectionStrategy } from '@root/Components/DataGrid/Models';
import { EventEmitter } from '@root/Services/EventEmitter';
import { chartColors } from '../Charts/Common';

export class BaseChartKeySelectionStrategy<T, TKey = T> implements ISelectionStrategy<T> {
    private readonly selectionLookup = new Map<T | TKey, string>();
    private selections: (TKey | T)[] = [];
    private readonly colors: string[];
    public constructor(private readonly maxSelections: number, private allSelected = true, private idAccessor?: (item: T) => TKey) {
        this.colors = chartColors.slice(0, maxSelections);
    }
    public selectionChanged = EventEmitter.empty();
    public isSelected(item: T) {
        return this.allSelected || this.selectionLookup.has(this.getKey(item));
    }
    public getSelectAllValidity() {
        return undefined;
    }
    public isAllSelected() {
        return this.allSelected;
    }
    public getSelectionColor(item: T) {
        return this.selectionLookup.get(this.getKey(item));
    }
    public getSelectionColorByKey(key: TKey) {
        return this.selectionLookup.get(key);
    }
    public setSelected(item: T, selected: boolean) {
        const key = this.getKey(item);
        if (this.allSelected) {
            this.allSelected = false;
            this.selectionLookup.set(key, this.colors.shift()!);
            this.selections.push(key);
        } else {
            if (selected) {
                while (this.selections.length >= this.maxSelections) {
                    const removed = this.selections.shift();
                    if (removed) {
                        this.colors.push(this.selectionLookup.get(removed)!);
                        this.selectionLookup.delete(removed);
                    }
                }
                this.selections.push(key);
                this.selectionLookup.set(key, this.colors.shift()!);
            } else {
                this.colors.unshift(this.selectionLookup.get(key)!);
                this.selectionLookup.delete(key);
                if (this.selectionLookup.size === 0) {
                    this.allSelected = true;
                }
            }
        }
        this.resolveSelections();
    }
    public async setSelectAll(selected: boolean) {
        this.allSelected = true;
        this.colors.push(...chartColors.slice(0, this.maxSelections));
        this.selectionLookup.clear();
        this.resolveSelections();
    }
    public async getSelected() {
        return this.selections.filter((s) => typeof s !== 'string') as T[];
    }
    public getSelectedIds(): TKey[] {
        return this.idAccessor ? (this.selections as TKey[]) : [];
    }
    public count() {
        return this.allSelected ? Infinity : this.selectionLookup.size;
    }

    private resolveSelections() {
        this.selections = this.selections.filter((s) => this.selectionLookup.has(s));
        this.selectionChanged.emit();
    }
    private getKey(item: T) {
        return this.idAccessor?.(item) ?? item;
    }
}

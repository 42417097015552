import { Company, User } from '@apis/Customers/model';
import { AnonymousJob } from '@apis/Resources';
import { EventEmitter } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { JobService } from '@root/Services/Jobs/JobService';
import { PollingService } from '@root/Services/Jobs/PollingService';
import { Logger } from '@root/Services/Logger';
import { add } from 'date-fns';
import { inject, singleton } from 'tsyringe';
import { JobStatus } from './ActivityTypes';

@singleton()
export class ActivityPoller {
    private jobsUpdated = new EventEmitter<JobStatus[]>([]);
    private polling = false;
    private pollFrequency = 5000;
    private maxDays = 5;
    private maxitems = 30;
    private company?: Company;
    private user?: User;

    public constructor(
        @inject(JobService) private readonly jobService: JobService,
        @inject(FormatService) private readonly formatSvc: FormatService,
        @inject(Logger) private readonly logger: Logger,
        @inject(PollingService) private readonly pollingSvc: PollingService
    ) {
        this.jobService.newJobStarted.listen(this.handleNewJob);
    }

    public load(user?: User, company?: Company) {
        if (this.company !== company || this.user !== user) {
            this.company = company;
            this.user = user;
            this.jobsUpdated.emit([]);
        }
        this.poll();
    }

    public listen = (listener: (jobStatuses: JobStatus[]) => void) => {
        this.poll();
        listener(this.jobsUpdated.value);
        return this.jobsUpdated.listen(listener);
    };

    private handleNewJob = () => {
        this.poll(true);
    };

    private async poll(force?: boolean) {
        if (!this.polling) {
            this.polling = true;
            const poller = await this.pollingSvc.pollUntil(this.updateStatus, this.donePolling, this.pollFrequency);
            await poller;
            this.polling = false;
        } else if (force) {
            this.updateStatus();
        }
    }

    private donePolling = (statuses: JobStatus[]) => {
        return !this.jobsUpdated.hasListeners || !statuses.some((s) => s.status.Created || s.status.Started);
    };

    private updateStatus = async () => {
        try {
            const statuses = await this.getJobStatus();
            this.jobsUpdated.emit(statuses);
            return statuses;
        } catch (err) {
            this.logger.error(`Error while polling for job status`, err);
            return this.jobsUpdated.value;
        }
    };

    private getJobStatus = async () => {
        const result: JobStatus[] = [];
        if (this.user && this.company) {
            const jobs = await this.getRootJobs();
            const hierarchyIds = jobs.map((j) => j.HierarchyId ?? '');
            const statuses = await this.jobService.getHierarchyProgressLookup(hierarchyIds);
            result.push(
                ...jobs.map((j) => ({
                    job: j as AnonymousJob,
                    status: statuses.get(j.HierarchyId ?? '') ?? { Created: 0, Failed: 0, Started: 0, Succeeded: 0, Canceled: 0 },
                }))
            );
        }
        return result;
    };

    private async getRootJobs() {
        const maxAge = add(this.formatSvc.toUtc(new Date()), { days: -this.maxDays });
        return await this.jobService.getRootJobs(this.maxitems, maxAge, undefined, this.user?.Id);
    }
}

import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ActionIcon, Alert, Badge, Box, Button, createStyles, Group, Menu, Overlay, Portal, Text, Tooltip, useMantineTheme } from '@mantine/core';
import { Popover } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { AnchorButton, SectionedPopover, SectionedPopoverToolbar } from '@root/Design/Primitives';
import { colorPalette } from '@root/Design/Themes';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { AlertCircle, GripVertical, Plus, Trash } from 'tabler-icons-react';
import { FilterItem, FilterToken } from '../Filter/Design';
import { FieldPicker } from '../Picker/FieldPicker';
import { DataGridColumn, DataGridModel } from './DataGridModel';
import { GridGroupByState } from './Models';

export function GroupByButton({
    grid,
    fieldPicker,
}: {
    grid: DataGridModel;
    fieldPicker: (select: (column: GroupBySelection) => void) => ReactNode;
}) {
    const theme = useMantineTheme();
    const [opened, { open, close, toggle }] = useDisclosure(false);
    useEvent(grid.groupByChanged);
    const groupBy = grid.getGroupBy();

    return (
        <>
            <Portal>{opened ? <Overlay onClick={close} opacity={0} zIndex={200} /> : null}</Portal>
            <Popover opened={opened} radius="sm" onClose={close} withinPortal closeOnClickOutside={false} withArrow offset={0} shadow="md">
                <SectionedPopover>{opened ? <GroupByEditor grid={grid} onClose={close} fieldPicker={fieldPicker} /> : null}</SectionedPopover>
                <Popover.Target>
                    <FilterItem onClick={toggle} state="valid" style={{ color: colorPalette.linkColor }}>
                        <FilterToken style={{ fontSize: theme.fontSizes.xs, marginTop: 2 }}>
                            <i className="ti ti-list-tree"></i>
                        </FilterToken>
                        <FilterToken data-atid="GroupByOpenButton">Group By</FilterToken>
                        {groupBy.length ? <Badge data-atid={'GroupByBadgeLength:' + groupBy.length}>{groupBy.length}</Badge> : null}
                    </FilterItem>
                </Popover.Target>
            </Popover>
        </>
    );
}

class GroupByEditorModel {
    public readonly groups: GroupByEditorItemModel[];
    public readonly groupsChanged = EventEmitter.empty();
    public readonly addingLayer = new EventEmitter(false);

    public constructor(private grid: DataGridModel, private onClose: () => void) {
        this.groups = this.createGroups();
    }
    public get canSort() {
        return this.grid.currentOptions?.groupByDisableSort !== true;
    }
    public get hasGroups() {
        return this.groups.length > 0;
    }
    public get canApply() {
        return this.groups.length >= this.requiredGroups;
    }
    public get requiredGroups() {
        return this.grid.currentOptions?.groupByRequired ?? 0;
    }
    public applyChanges = () => {
        this.grid.setGroupBy(this.groups);
        this.onClose();
    };
    public toggleAddingLayer = () => {
        this.addingLayer.emit(!this.addingLayer.value);
    };
    public stopAddingLayer = () => {
        this.addingLayer.emit(false);
    };
    public clear = () => {
        this.grid.clearGroupBy();
        this.onClose();
    };
    public addLayer = (selection: GroupBySelection) => {
        const id = this.getSelectionId(selection);
        if (!this.groups.find((g) => g.id === id)) {
            this.groups.push(this.createNewGroupModel(selection));
        }
        this.addingLayer.emit(false);
        this.groupsChanged.emit();
    };

    private createGroups() {
        return this.grid.getGroupBy().map((g) => this.createGroupModel(g));
    }
    private createNewGroupModel(item: GroupBySelection) {
        const id = this.getSelectionId(item);
        const group = {
            id,
            sortDir: 'Desc',
            sortMode: 'count',
            canSort: this.canSort,
        } as GridGroupByState;
        return this.createGroupModel(group);
    }
    private createGroupModel(item: GridGroupByState) {
        const group = {
            ...item,
            name: this.getGroupName(item),
            remove: () => {
                this.groups.splice(this.groups.indexOf(group), 1);
                this.groupsChanged.emit();
            },
            canSort: this.canSort,
            sort: (dir: 'Asc' | 'Desc', type: 'count' | 'value') => {
                group.sortDir = dir;
                group.sortMode = type;
                this.groupsChanged.emit();
            },
        };
        return group;
    }
    private getSelectionId(selection: GroupBySelection) {
        return 'id' in selection ? selection.id : selection.config.id;
    }
    private getGroupName(item: GridGroupByState) {
        return this.grid.currentOptions?.groupByNameLookup?.(item.id) ?? this.grid.getColumnById(item.id)?.header ?? item.id;
    }
    public move = (e: DragEndEvent) => {
        const active = this.groups.find((g) => g.id === e.active.id);
        const over = this.groups.find((g) => g.id === e.over?.id);
        if (over && active) {
            const currIdx = this.groups.indexOf(active);
            const nextIdx = this.groups.indexOf(over);
            this.groups.splice(0, Infinity, ...arrayMove(this.groups, currIdx, nextIdx));
            this.groupsChanged.emit();
        }
    };
}

type GroupBySelection = { id: string } | DataGridColumn;

function GroupByEditor({
    grid,
    onClose,
    fieldPicker,
}: {
    grid: DataGridModel;
    onClose: () => void;
    fieldPicker: (select: (selection: GroupBySelection) => void) => ReactNode;
}) {
    const model = useMemo(() => new GroupByEditorModel(grid, onClose), [grid]);
    const pickerOpened = useEventValue(model.addingLayer);
    useEvent(model.groupsChanged);
    const clear = useCallback(() => {
        grid.clearGroupBy();
        onClose();
    }, [grid.clearGroupBy]);

    return (
        <>
            <SectionedPopoverToolbar>
                <Group position="right">
                    <Button color="primary" onClick={model.applyChanges} disabled={!model.canApply} data-atid="GroupByApplyButton">
                        Apply
                    </Button>
                    <Button variant="outline" onClick={onClose} data-atid="GroupByCancelButton">
                        Cancel
                    </Button>
                </Group>
            </SectionedPopoverToolbar>
            <Box mt="md" sx={{ width: 300 }}>
                <Group position="apart">
                    <Text>Group By</Text>
                    {model.hasGroups && model.requiredGroups === 0 ? (
                        <AnchorButton
                            size="sm"
                            iconPosition="right"
                            onClick={clear}
                            icon={<Trash size={16} />}
                            text="Clear All"
                            color="error"
                            atid="GlobalGroupByClearAll"
                        />
                    ) : null}
                </Group>
                {model.canApply ? null : (
                    <Alert
                        color="warning"
                        icon={<AlertCircle />}
                        title={`Add at least ${model.requiredGroups} Layer${model.requiredGroups > 1 ? 's' : ''}`}
                    >
                        <></>
                    </Alert>
                )}
                <Box py="md">
                    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={model.move}>
                        <SortableContext strategy={verticalListSortingStrategy} items={model.groups.map((g) => g.id)}>
                            {model.groups.map((g) => (
                                <GroupByEditorItem key={g.id} item={g} grid={grid} atid={g.name} />
                            ))}
                        </SortableContext>
                    </DndContext>
                </Box>
                <Portal>{pickerOpened ? <Overlay onClick={model.stopAddingLayer} opacity={0} zIndex={300} /> : null}</Portal>
                <Popover opened={pickerOpened} withinPortal>
                    <Popover.Dropdown p={0}>{fieldPicker(model.addLayer)}</Popover.Dropdown>
                    <Popover.Target>
                        <Box>
                            <AnchorButton
                                onClick={model.toggleAddingLayer}
                                size="md"
                                icon={<Plus size={16} />}
                                text="Add Layer"
                                atid="GroupByAddLayerButton"
                            />
                        </Box>
                    </Popover.Target>
                </Popover>
            </Box>
        </>
    );
}

function GroupByEditorItem({ item, grid, atid }: { item: GroupByEditorItemModel; grid: DataGridModel; atid: string }) {
    const { count: countLabel, value: valueLabel } = grid.currentOptions?.groupByLabels ?? {};
    const sortOptions = useMemo(
        () =>
            [
                {
                    ufMode: countLabel ?? 'by Count',
                    ufDir: 'Ascending',
                    mode: 'count',
                    dir: 'Asc',
                    icon: 'ti-sort-ascending-numbers',
                    atid: 'GroupBySortCountAscending:' + atid,
                },
                {
                    ufMode: countLabel ?? 'by Count',
                    ufDir: 'Descending',
                    mode: 'count',
                    dir: 'Desc',
                    icon: 'ti-sort-descending-numbers',
                    atid: 'GroupBySortCountDescending:' + atid,
                },
                {
                    ufMode: valueLabel ?? 'Alphabetically',
                    ufDir: 'Ascending',
                    mode: 'value',
                    dir: 'Asc',
                    icon: 'ti-sort-ascending',
                    atid: 'GroupBySortAlphaAscending:' + atid,
                },
                {
                    ufMode: valueLabel ?? 'Alphabetically',
                    ufDir: 'Descending',
                    mode: 'value',
                    dir: 'Desc',
                    icon: 'ti-sort-descending',
                    atid: 'GroupBySortAlphaDescending:' + atid,
                },
            ].map((o) => ({
                sort: () => item.sort(o.dir as 'Asc' | 'Desc', o.mode as 'count' | 'value'),
                text: `Sort ${o.ufMode} ${o.ufDir}`,
                selected: o.dir === item.sortDir && o.mode === item.sortMode,
                icon: `ti ${o.icon}`,
                atid: o.atid,
            })),
        [item.id, item.sortDir, item.sortMode]
    );
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ data: item, id: item.id });
    const selectedSort = sortOptions.find((o) => o.selected);
    const selectedSortIcon = selectedSort?.icon || 'ti ti-sort-ascending';
    const selectedSortLabel = selectedSort?.text || 'Sort by Count Descending';
    const { classes } = useStyles();

    return (
        <Group ref={setNodeRef} sx={{ transform: CSS.Transform.toString(transform), transition }}>
            <Group {...listeners} noWrap spacing={4} {...attributes} className={(isDragging ? 'is' : 'not') + '-dragging ' + classes.editorItemText}>
                <GripVertical style={{ flexShrink: 0 }} size={16} />
                <Text data-atid={'GroupByApplied:' + item.name}>{item.name}</Text>
            </Group>
            <Menu withinPortal withArrow position="bottom" shadow="md" offset={0}>
                <Menu.Target>
                    <Box hidden={!item.canSort}>
                        <Tooltip label={selectedSortLabel} position="bottom" withArrow>
                            <ActionIcon key={selectedSortIcon} data-atid={'GroupBySort:' + item.name}>
                                <i className={selectedSortIcon}></i>
                            </ActionIcon>
                        </Tooltip>
                    </Box>
                </Menu.Target>
                <Menu.Dropdown>
                    {sortOptions.map((o, i) => (
                        <Menu.Item
                            key={i}
                            onClick={o.sort}
                            icon={<i className={o.icon}></i>}
                            rightSection={o.selected ? <i style={{ marginLeft: 10 }} className="ti ti-check"></i> : null}
                            data-atid={o.atid}
                        >
                            {o.text}
                        </Menu.Item>
                    ))}
                </Menu.Dropdown>
            </Menu>
            <Tooltip label="Remove Layer" position="right" withArrow>
                <ActionIcon onClick={item.remove} data-atid={'GroupByTrashCan:' + item.name}>
                    <Trash size={18} />
                </ActionIcon>
            </Tooltip>
        </Group>
    );
}

type GroupByEditorItemModel = GridGroupByState & {
    name: string;
    remove: () => void;
    sort: (dir: 'Asc' | 'Desc', type: 'count' | 'value') => void;
    canSort: boolean;
    atid?: string;
};

const useStyles = createStyles((theme) => ({
    editorItemText: {
        flex: 1,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        cursor: 'grab',
        borderRadius: theme.radius.md,
        padding: '4px 8px',
        [`&.is-dragging`]: {
            cursor: 'grabbing',
        },
    },
}));

import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { PageContent } from '@root/Design/Layout';
import {
    Anchor,
    Autocomplete,
    Box,
    Button,
    Card,
    CopyButton,
    createStyles,
    FileInput,
    Grid,
    Group,
    List,
    MantineColor,
    Modal,
    Radio,
    Select,
    Space,
    Stack,
    Text,
    TextInput,
    Title,
    useMantineTheme,
} from '@mantine/core';
import { useNav } from '@root/Services/NavigationService';
import { useEffect, useState } from 'react';
import { getCompanyGetSingleSignOn, postCompanySetupSingleSignOn, deleteCompanyRemoveSingleSignOn } from '@apis/Customers';
import { SingleSignOnConfig } from '@apis/Customers/model';
import { useForm } from '@mantine/form';
import { colorPalette, CustomColors, theme } from '@root/Design/Themes';
import { Copy, Download, InfoCircle, Upload } from 'tabler-icons-react';
import React from 'react';
import { ConfigService } from '@root/Services/ConfigService';
import { useDi } from '@root/Services/DI';
import { CompanyRoleService } from '../Users/CompanyRoleService';

export function ConfigureSSO() {
    const identityProvider = ['Azure', 'Okta', 'One Login', 'Ping Identity'];
    const signRequestAlgorithm = ['rsa-sha256', 'rsa-sha1'];
    const signReqAlgDigest = ['sha256', 'sha1'];
    const protocolBinding = ['HTTP-POST', 'HTTP-Redirect'];
    const auth0Domain = 'cloudsaver.auth0.com';
    const cerUrl = `https://${auth0Domain}/cer`;
    const pemUrl = `https://${auth0Domain}/pem`;
    const rawPemUrl = `https://${auth0Domain}/rawpem`;
    const pb7Url = `https://${auth0Domain}/pb7`;
    const fingerprintUrl = `https://${auth0Domain}/fingerprint`;
    const configSvc = useDi(ConfigService);

    const [configured, setConfigured] = useState<boolean>(false);
    const [connectionName, setConnectionName] = useState<string>('');
    const [metadataUrl, setMetadataUrl] = useState<string>('#');
    const [entityId, setEntityId] = useState<string>('');
    const [signOnUrl, setSignOnUrl] = useState<string>('');
    const [signOutUrl, setSignOutUrl] = useState<string>('');
    const [showConfirmRemove, setShowConfirmRemove] = useState<boolean>(false);
    const [disableConfirmRemove, setDisableConfirmRemove] = useState<boolean>(true);
    const [roles, setRoles] = useState([] as { value: number; label: string }[]);
    const [role, setRole] = useState<{ value: number; label: string }>();
    const companyRoleSvc = useDi(CompanyRoleService);
    const { classes } = useStyles();

    const theme = useMantineTheme();
    const { goto } = useNav();

    const validateURL = (value: string) => {
        try {
            new URL(value);
            return true;
        } catch {
            return false;
        }
    };

    const form = useForm({
        initialValues: {
            IdentityProvider: '',
            DomainName: '',
            SignInUrl: '',
            SignOutUrl: '',
            SignRequestAlgorithm: signRequestAlgorithm[0],
            SignRequestAlgorithmDigest: signReqAlgDigest[0],
            ProtocolBinding: protocolBinding[0],
            X509SigningCertificate: null,
        },
        validate: {
            IdentityProvider: (value) => (value?.length ? null : 'Identity provider required'),
            SignInUrl: (value) => (value?.length && validateURL(value) ? null : 'Invalid URL'),
        },
        validateInputOnChange: true,
    });

    const createRadioButtons = (items: string[]) =>
        items.map((item, index) => <Radio key={index} label={item.startsWith('HTTP') ? item : item.toUpperCase()} value={item} />);

    const submitSSOConfig = async (config: SingleSignOnConfig) => {
        return await postCompanySetupSingleSignOn(config);
    };

    const submitWithCertificate = (config: any) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(config.X509SigningCertificate);
        fileReader.onload = () => {
            let ssoConfig = config as SingleSignOnConfig;
            ssoConfig.Base64X509SigningCertificate = fileReader.result?.toString().split(',')[1];
            ssoConfig.ConnectionName = connectionName;
            (async () => await submitSSOConfig(ssoConfig))().then(() => goto('settings/sso'));
        };
    };

    const handleSubmit = (values: typeof form.values) => {
        let config = values;
        if (configured) {
            if (config.X509SigningCertificate) {
                submitWithCertificate(config);
            } else {
                let ssoConfig = config as SingleSignOnConfig;
                (async () => await submitSSOConfig(ssoConfig))();
                goto('settings/sso');
            }
        } else {
            if (config.X509SigningCertificate) {
                submitWithCertificate(config);
            }
        }
    };

    const handleConfirmDeleteChange = (e: React.ChangeEvent<HTMLInputElement>) => setDisableConfirmRemove(e.target.value !== 'DELETE');

    const handleRemoveSSO = () => {
        (async () => await deleteCompanyRemoveSingleSignOn())().then(() => goto('settings/sso'));
    };

    const handleCancel = () => {
        window.history.back();
    };

    useEffect(() => {
        (async () => {
            //console.log(configSvc.config.ssoDocumentationUrl);

            const userRoles = await companyRoleSvc.getRoleList();
            userRoles.sort((a, b) => a.Role?.Name?.localeCompare(b.Role?.Name ?? '') || a.Role?.Id! - b.Role?.Id!);
            setRoles(userRoles.map((role) => ({ value: role.Role!.Id!, label: role.Role!.Name! })));

            let config = await getCompanyGetSingleSignOn();
            if (config) {
                if (config.SignInUrl) {
                    setConfigured(true);
                }
                if (config.ConnectionName) {
                    setConnectionName(config.ConnectionName);
                    setMetadataUrl(`https://${auth0Domain}/samlp/metadata?connection=${config.ConnectionName}`);
                    setEntityId(`urn:auth0:cloudsaver:${config.ConnectionName}`);
                    setSignOnUrl(`https://${auth0Domain}/login/callback?connection=${config.ConnectionName}`);
                    setSignOutUrl(`https://${auth0Domain}/logout`);
                }
                if (config.DefaultUserRoleId! > 0) {
                    setRole(roles.find((role) => role.value === config.DefaultUserRoleId));
                }
                let values = config as any;
                form.setValues(values);
            }
        })();
    }, []);

    return (
        <PageContent>
            <Box p="xl">
                <Grid style={{ alignItems: 'flex-start' }}>
                    <Grid.Col xs={6}>
                        <Title order={3}>Configure Single-Sign On</Title>
                        <Space h="lg" />
                    </Grid.Col>
                    <Grid.Col xs={6}>
                        <div style={{ textAlign: 'right' }}>
                            <Anchor target="_blank" href={configSvc.config.ssoDocumentationUrl}>
                                Documentation
                            </Anchor>
                        </div>
                    </Grid.Col>
                    <Grid.Col sm={6} md={4} p="lg">
                        <Title order={4}>Configuration Details</Title>
                        <Space h="lg" />
                        <form onSubmit={form.onSubmit(handleSubmit)}>
                            <Autocomplete label="Identity Provider" data={identityProvider} limit={5} {...form.getInputProps('IdentityProvider')} />
                            <Space h="lg" />
                            <TextInput label="Domain Name" {...form.getInputProps('DomainName')} />
                            <Space h="lg" />
                            <TextInput label="Sign-in URL" {...form.getInputProps('SignInUrl')} />
                            <Space h="lg" />
                            <TextInput label="Sign-out URL" {...form.getInputProps('SignOutUrl')} />
                            <Space h="lg" />
                            <FileInput
                                label={`${configured ? 'Update' : ''} X509 Signing Certificate`}
                                icon={<Upload />}
                                placeholder="Upload"
                                {...form.getInputProps('X509SigningCertificate')}
                            />
                            <Space h="lg" />
                            <Select label="Default User Role" data={roles} {...form.getInputProps('DefaultUserRoleId')} />
                            <Space h="lg" />
                            <Radio.Group label="Sign Request Algorithm" orientation="vertical" {...form.getInputProps('SignRequestAlgorithm')}>
                                {createRadioButtons(signRequestAlgorithm)}
                            </Radio.Group>
                            <Space h="lg" />
                            <Radio.Group
                                label="Sign Request Algorithm Digest"
                                orientation="vertical"
                                {...form.getInputProps('SignRequestAlgorithmDigest')}
                            >
                                {createRadioButtons(signReqAlgDigest)}
                            </Radio.Group>
                            <Space h="lg" />
                            <Radio.Group label="Protocol Binding" orientation="vertical" {...form.getInputProps('ProtocolBinding')}>
                                {createRadioButtons(protocolBinding)}
                            </Radio.Group>
                            <Space h="lg" />
                            <Group>
                                <Button type="submit">Submit Configuration</Button>
                                <Button type="button" onClick={handleCancel} variant="outline">
                                    Cancel
                                </Button>
                            </Group>
                        </form>
                    </Grid.Col>
                    <Grid.Col
                        sm={6}
                        offsetMd={1}
                        md={4}
                        style={{
                            backgroundColor: 'white',
                            borderColor: theme.colors?.gray?.[3] as CustomColors,
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderRadius: 12,
                        }}
                        p="lg"
                    >
                        <Title order={4}>SAML Identity Provider Configuration</Title>
                        <Space h="lg" />
                        <a href={metadataUrl} download="cloudsaver-metadata.xml">
                            <Button type="button" rightIcon={<Download />} variant="outline" disabled={!configured}>
                                Download Metadata
                            </Button>
                        </a>
                        <Space h="lg" />
                        <Text size="sm">Signing Certificates</Text>
                        <Space h="sm" />
                        <List size="sm">
                            <List.Item key="cer">
                                <a href={cerUrl} download="cloudsaver-cer.xml">
                                    <Button px="sm" type="button" rightIcon={<Download />} variant="subtle" className={classes.hoverCerts}>
                                        CER Certificate
                                    </Button>
                                </a>
                            </List.Item>
                            <List.Item key="pem">
                                <a href={pemUrl} download="cloudsaver-pem.xml">
                                    <Button px="sm" type="button" rightIcon={<Download />} variant="subtle" className={classes.hoverCerts}>
                                        PEM Certificate
                                    </Button>
                                </a>
                            </List.Item>
                            <List.Item key="raw-pem">
                                <a href={rawPemUrl} download="cloudsaver-pem.xml">
                                    <Button px="sm" type="button" rightIcon={<Download />} variant="subtle" className={classes.hoverCerts}>
                                        raw PEM Certificate
                                    </Button>
                                </a>
                            </List.Item>
                            <List.Item key="pb7">
                                <a href={pb7Url} download="cloudsaver-pem.xml">
                                    <Button px="sm" type="button" rightIcon={<Download />} variant="subtle" className={classes.hoverCerts}>
                                        PB7 Certificate
                                    </Button>
                                </a>
                            </List.Item>
                            <List.Item key="fingerprint">
                                <a href={fingerprintUrl} download="cloudsaver-pem.xml">
                                    <Button px="sm" type="button" rightIcon={<Download />} variant="subtle" className={classes.hoverCerts}>
                                        Fingerprint Certificate
                                    </Button>
                                </a>
                            </List.Item>
                        </List>
                        <Space h="lg" />
                        <TextInput
                            label="Entity ID"
                            readOnly
                            color={colorPalette.darkTextCustomColor}
                            value={entityId}
                            rightSection={
                                <CopyButton value={entityId}>
                                    {({ copied, copy }) => (
                                        <Button
                                            leftIcon={<Copy />}
                                            variant={copied ? 'filled' : 'default'}
                                            color={colorPalette.darkTextCustomColor}
                                            onClick={copy}
                                        >
                                            {copied ? 'Copied' : 'Copy'}
                                        </Button>
                                    )}
                                </CopyButton>
                            }
                            rightSectionWidth={100}
                        />
                        <Space h="lg" />
                        <TextInput
                            label="Single Sign-On URL"
                            readOnly
                            value={signOnUrl}
                            rightSection={
                                <CopyButton value={signOnUrl}>
                                    {({ copied, copy }) => (
                                        <Button
                                            leftIcon={<Copy />}
                                            variant={copied ? 'filled' : 'default'}
                                            color={colorPalette.darkTextCustomColor}
                                            onClick={copy}
                                        >
                                            {copied ? 'Copied' : 'Copy'}
                                        </Button>
                                    )}
                                </CopyButton>
                            }
                            rightSectionWidth={100}
                        />
                        <Space h="lg" />
                        <TextInput
                            label="Single Sign-Out URL"
                            readOnly
                            value={signOutUrl}
                            rightSection={
                                <CopyButton value={signOutUrl}>
                                    {({ copied, copy }) => (
                                        <Button
                                            leftIcon={<Copy />}
                                            variant={copied ? 'filled' : 'default'}
                                            color={colorPalette.darkTextCustomColor}
                                            onClick={copy}
                                        >
                                            {copied ? 'Copied' : 'Copy'}
                                        </Button>
                                    )}
                                </CopyButton>
                            }
                            rightSectionWidth={100}
                        />
                        <Space h="lg" />
                        <Text size="sm">Recommended Attributes</Text>
                        <Space h="xs" />
                        <List size="sm">
                            <List.Item key="email">email</List.Item>
                            <List.Item key="given_name">given_name</List.Item>
                            <List.Item key="family_name">family_name</List.Item>
                        </List>
                    </Grid.Col>

                    {configured && (
                        <Grid.Col sm={9}>
                            <Space h="lg" />
                            <Title order={3}>Danger Zone</Title>
                            <Space h="lg" />
                            <Card radius="md" withBorder style={{ borderColor: colorPalette.errorSubText }}>
                                <Group>
                                    <InfoCircle style={{ alignSelf: 'flex-start' }} color={colorPalette.errorSubText} />
                                    <Stack style={{ flexGrow: 1 }} spacing="xs">
                                        <Text color={colorPalette.errorTextCustomColor}>Remove SSO Connection</Text>
                                        <Text color={colorPalette.errorTextCustomColor} size={'sm'}>
                                            Removing this connection will disable current user accounts that use SSO and require users to log in via
                                            email and password.
                                        </Text>
                                    </Stack>
                                    <Button
                                        color={colorPalette.errorTextCustomColor}
                                        type="button"
                                        onClick={() => {
                                            setShowConfirmRemove(true);
                                        }}
                                    >
                                        Remove SSO Connection
                                    </Button>
                                </Group>
                            </Card>
                        </Grid.Col>
                    )}
                </Grid>
            </Box>
            <Modal opened={showConfirmRemove} title="Remove SSO Connection" onClose={() => setShowConfirmRemove(false)}>
                <Text size="sm" color="dimmed">
                    This SSO is currently configured. Deleting this connection will force users to log in via email and password. Are you sure you
                    want to continue?
                </Text>
                <Space h="lg" />
                <Text size="sm">Type "DELETE" to confirm</Text>
                <TextInput onChange={handleConfirmDeleteChange}></TextInput>
                <Space h="lg" />
                <Group position="right">
                    <Button type="button" variant="outline">
                        Back
                    </Button>
                    <Button type="button" color={colorPalette.errorTextCustomColor} disabled={disableConfirmRemove} onClick={handleRemoveSSO}>
                        Confirm Delete
                    </Button>
                </Group>
            </Modal>
        </PageContent>
    );
}

endpoint('configure-sso', ConfigureSSO, 'Configure Single Sign-On');

const useStyles = createStyles(() => ({
    hoverCerts: {
        color: theme.colors?.primary?.[6] as CustomColors,
        backgroundColor: colorPalette.white,
        '&:hover': {
            color: theme.colors?.primary?.[7] as CustomColors,
            backgroundColor: colorPalette.white,
            textDecoration: 'underline',
        },
    },
}));

import { CustomerSubsidyViewModel, CustomerSubsidyRequestCustomerSubsidyStatus as Status } from '@apis/Customers/model';
import {
    getCloudIntelligenceGetCustomerSubsidyRequests,
    postCloudIntelligenceDeleteCompanyCandidate,
    postCloudIntelligenceReviewCompanyCandidate,
} from '@apis/Customers';
import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';

class CompanyRequestService {
    public companies: CustomerSubsidyViewModel[] = [];
    public loading: boolean = false;

    public constructor() {
        makeAutoObservable(this);
    }

    public async refreshCompanies() {
        this.loading = true;
        await getCloudIntelligenceGetCustomerSubsidyRequests()
            .then((results) => {
                this.companies = results ?? [];
            })
            .catch(() => {
                this.companies = [];
            })
            .finally(() => {
                this.loading = false;
            });
    }

    public async review(requestId: number, approve: boolean, rejectionReason: string) {
        await postCloudIntelligenceReviewCompanyCandidate({ approve, requestId, rejectionReason });
        await this.silentRefresh();
    }

    public async deleteInvitation(requestId: number) {
        await postCloudIntelligenceDeleteCompanyCandidate({ requestId });
        await this.silentRefresh();
    }

    public get info() {
        return {
            total: this.companies.length,
            pendingApproval: this.companies.filter((company) => company.Status === Status.PendingApproval),
            accepted: this.companies.filter((company) => company.Status === Status.Approved),
            rejected: this.companies.filter((company) => company.Status == Status.Rejected),
        };
    }

    public getAccountReps() {
        return this.getUniqueValues((item) => item.AccountRepName);
    }
    public getCompanies() {
        return this.getUniqueValues((item) => item.Company);
    }
    public getUniqueValues<T>(accessor: (item: CustomerSubsidyViewModel) => T) {
        return [...this.companies.reduce((result, item) => result.add(accessor(item)), new Set<T>())].sort();
    }

    private silentRefresh() {
        return getCloudIntelligenceGetCustomerSubsidyRequests().then((results) => (this.companies = results));
    }
}

export default createContext(new CompanyRequestService());

import { PageContent, PagePanel, PanelBody, PaneledPage } from '@root/Design/Layout';
import { useNav } from '@root/Services/NavigationService';
import { useLink } from '@root/Services/Router/Router';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { MapContractStatsGrid } from '@root/Components/MapContractStats/MapContractStatsGrid';

export function ContractsExplorer() {
    const { getDescendUrl } = useNav();
    const link = useLink();
    return (
        <PageContent>
            <PaneledPage>
                <PagePanel size="fill">
                    <PanelBody>
                        <MapContractStatsGrid />
                    </PanelBody>
                </PagePanel>
            </PaneledPage>
        </PageContent>
    );
}

endpoint('map-contract-explorer', ContractsExplorer, 'Contract List');

import { IQueryExpr, QuerySortExpr } from '@apis/Jobs/model';
import { QueryExpr, QueryOperation } from '@apis/Resources';
import { ObjectQueryResult, Query } from '@apis/Resources/model';
import { EventEmitter } from '@root/Services/EventEmitter';
import { ArrayDataSource, IValueProvider } from '@root/Services/Query/ArrayDataSource';
import { DataGridState, IDataSource } from './Models';

export class GridArrayDataSource extends ArrayDataSource implements IDataSource<any> {
    public constructor(public items: any[], valueProviders: IValueProvider[] = []) {
        super(items, valueProviders);
    }
    public async getPage(start: number, end: number, state: DataGridState, parent?: any) {
        if (parent) {
            throw new Error('Grid is misconfigured, an array-based datasource cannot provide children. ');
        }
        this.applyState(state);
        return {
            items: this.filteredItems,
            total: this.filteredItems.length,
        };
    }
}

export class QueryApiDataSource implements IDataSource<any> {
    public constructor(private queryApi: (query: Query) => Promise<ObjectQueryResult>) {}
    public async getPage(start: number, end: number, state: DataGridState, parent?: any) {
        const results = await this.queryApi({
            Skip: start,
            Take: end - start,
            IncludeCount: true,
            Where: state.filters?.length ? { Operation: 'and', Operands: state.filters } : undefined,
            Sort: state.sort ? state.sort : undefined,
        });
        return {
            items: results.Results || [],
            total: results.Count || 0,
        };
    }
}

class UnknownItem<T> {
    public constructor(public parent: T | undefined, public index: number, public items: T[]) {}
}
export class BufferingDataSource<T> {
    private requestingItems = new WeakSet<{}>();

    public dataLoaded = EventEmitter.empty();
    public isUnknown = (item: T) => item instanceof UnknownItem;
    public isLoading = (item: T) => this.requestingItems.has(item);
    public itemCount = new EventEmitter(0);

    public constructor(private baseDataSource: IDataSource<T>, private requestSize: number = 200, private backtrackSize = 20) {}

    public clear() {
        this.requestingItems = new WeakSet<{}>();
    }

    public async load(state: DataGridState, clearCache: boolean = false, requestSize?: number) {
        const page = await this.baseDataSource.getPage(0, requestSize ?? this.requestSize, state, undefined);
        const result = this.resolveUnknownItems(undefined, page);
        this.itemCount.emit(result.total);
        return result;
    }
    public async loadChildren(state: DataGridState, parent: T) {
        const children = await this.baseDataSource.getPage(0, this.requestSize, state, parent);
        const result = this.resolveUnknownItems(parent, children);
        return result;
    }
    public async loadUnknownItems(state: DataGridState, items: T[]) {
        const uniqueParents = this.getUniqueParents(items);
        const requestRanges = this.getRequestRanges(uniqueParents);
        if (requestRanges.length) {
            await this.requestRanges(state, requestRanges);
            this.dataLoaded.emit();
        }
    }
    private getUniqueParents(items: T[]) {
        const requestsByParent = new Map<T, UnknownItem<T>>();
        const nullParent = {} as T;
        for (const item of items) {
            if (item instanceof UnknownItem && !requestsByParent.has(item.parent ?? nullParent) && !this.requestingItems.has(item)) {
                requestsByParent.set(item.parent ?? nullParent, item);
            }
        }
        return [...requestsByParent.values()];
    }
    private getRequestRanges(items: UnknownItem<T>[]) {
        const results: { start: number; end: number; parent?: T; items: T[] }[] = [];
        for (const item of items) {
            const range = this.getUnknownRange(item.items, item.index);
            results.push({ parent: item.parent, ...range });
        }
        return results;
    }
    private getUnknownRange(items: T[], start: number) {
        const result = { items: [] as T[], start: start, end: start };
        const { start: distStart, end: distEnd } = this.getNearUnknown(items, start, this.requestSize);
        const bestRight = this.requestSize - 1 - this.backtrackSize + start;
        result.end = Math.min(distEnd, bestRight);
        result.start = Math.max(result.end - this.requestSize, distStart);
        result.items = items.slice(result.start, result.end + 1);
        return result;
    }
    private getNearUnknown(items: T[], start: number, max: number) {
        const result = { start: start, end: start };
        let foundStart = false;
        let foundEnd = false;
        for (let i = 0; i < max; i++) {
            const left = items[start - i];
            const right = items[start + i];
            foundStart = foundStart || !this.isUnknown(left) || this.isLoading(left);
            foundEnd = foundEnd || !this.isUnknown(right) || this.isLoading(right);
            if (!foundStart) {
                result.start = start - i;
            }
            if (!foundEnd) {
                result.end = start + i;
            }
            if (foundStart && foundEnd) {
                break;
            }
        }
        return result;
    }
    private async requestRanges(state: DataGridState, ranges: { parent?: T; start: number; end: number; items: T[] }[]) {
        const rangeRequests = ranges.map((r) => this.requestRange(state, r));
        await Promise.all(rangeRequests);
        this.dataLoaded.emit();
    }

    private async requestRange(state: DataGridState, range: { parent?: T; start: number; end: number; items: T[] }) {
        this.updateLoadingState(range.items);

        const results = await this.baseDataSource.getPage(range.start, range.end, state, range.parent);

        this.updateUnknownState(results.items, range);
    }

    private updateLoadingState(items: T[]) {
        for (const item of items) {
            this.requestingItems.add(item);
        }
    }

    private updateUnknownState(items: T[], range: { parent?: T | undefined; start: number; end: number; items: T[] }) {
        for (let i = 0; i < items.length; i++) {
            const placeholder = range.items[i];
            if (placeholder instanceof UnknownItem) {
                const unknownItem = placeholder;
                if (unknownItem) {
                    const resultItem = items[i];
                    unknownItem.items[unknownItem.index] = resultItem;
                }
                this.requestingItems.delete(placeholder);
            }
        }
    }

    private resolveUnknownItems(parent: T | undefined, { items, total }: { items: T[]; total: number }) {
        items = items ?? [];
        if (items.length < total) {
            for (let i = items.length; i < total; i++) {
                items[i] = new UnknownItem<T>(parent, i, items) as unknown as T;
            }
            return { items, total };
        }
        return { items, total };
    }
}

import { postCloudIntelligenceSubmitCompanyCandidate } from '@apis/Customers';
import { CustomerSubsidyRequest } from '@apis/Customers/model';
import { Button, Divider, Group, Loader, Space, TextInput } from '@mantine/core';
import { PageContent, PanelBody, PanelContent, PanelHeader, PanelToolbar } from '@root/Design/Layout';
import { useContext, useState } from 'react';
import { SubsidyInvitationProps } from './Interfaces';
import { useForm } from '@mantine/form';
import { OpenSidebar } from './Enums';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { useDi } from '@root/Services/DI';
import CompanyRequestService from '@root/Services/CloudIntelligence/CompanyRequestService';
import { observer } from 'mobx-react';
import { CustomColors, theme } from '@root/Design/Themes';

const CustomerInvitation = (props: SubsidyInvitationProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const notificationSvc = useDi(NotificationService);
    const companyRequestSvc = useContext(CompanyRequestService);

    const form = useForm({
        initialValues: {
            customerFirstName: '',
            customerLastName: '',
            customerEmailAddress: '',
            customerCompany: '',
        },
        validate: {
            customerCompany: (v: string) => (v ? null : 'Customer company is required'),
            customerFirstName: (v: string) => (v ? null : 'Customer first name is required'),
            customerLastName: (v: string) => (v ? null : 'Customer last name is required'),
            customerEmailAddress: (v: string) => (v ? null : 'Customer email address is required'),
        },
    });

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        let { customerCompany, customerFirstName, customerLastName, customerEmailAddress } = form.values;

        let candidate = {
            Company: customerCompany,
            FirstName: customerFirstName,
            LastName: customerLastName,
            Email: customerEmailAddress,
        } as CustomerSubsidyRequest;

        await SubmitChanges(candidate);
    };

    const SubmitChanges = async (changes: any) => {
        setIsLoading(true);

        await postCloudIntelligenceSubmitCompanyCandidate(changes)
            .then(() => {
                companyRequestSvc.refreshCompanies();
                notificationSvc.notify('Request sent for review.', '', 'success', null);
                props.onButtonClick(OpenSidebar.undefined, changes);
            })
            .catch(() => {
                notificationSvc.notify('Request failed', '', 'error', null);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <PageContent>
                <PanelContent>
                    <PanelBody>
                        <TextInput label="Company" {...form.getInputProps('customerCompany')} required maxLength={50} />
                        <Space h="md" />
                        <TextInput label="First Name" {...form.getInputProps('customerFirstName')} required maxLength={50} />
                        <Space h="md" />
                        <TextInput label="Last Name" {...form.getInputProps('customerLastName')} required maxLength={50} />
                        <Space h="md" />
                        <TextInput type="email" label="Email" {...form.getInputProps('customerEmailAddress')} required maxLength={50} />
                    </PanelBody>
                    <Divider />
                    <PanelToolbar>
                        <Group>
                            <Button
                                disabled={!form.isDirty() || isLoading}
                                type="submit"
                                size="sm"
                                color={theme.colors?.primary?.[6] as CustomColors}
                                leftIcon={isLoading && <Loader size="xs" />}
                            >
                                Invite Customer
                            </Button>
                            <Button onClick={() => props.onButtonClick(OpenSidebar.undefined, false)} variant="outline" color={theme.colors?.primary?.[6] as CustomColors}>
                                Cancel
                            </Button>
                        </Group>
                    </PanelToolbar>
                </PanelContent>
            </PageContent>
        </form>
    );
};

export default observer(CustomerInvitation);

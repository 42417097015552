import { postRecommendationQuery } from '@apis/Recommendations';
import { Recommendation } from '@apis/Recommendations/model';
import { postResourcesQuery, QueryExpr } from '@apis/Resources';
import { IQueryExpr } from '@apis/Resources/model';
import { Anchor, Group, Text, Tooltip } from '@mantine/core';
import { useUserAccessCheck } from '@root/Components/Shell/AppFeatureAccess';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useNav } from '@root/Services/NavigationService';
import { queryBuilder } from '@root/Services/QueryExpr';
import { IdleResourcesService } from '@root/Services/Resources/IdleResourcesService';
import { BasicRouteLoader } from '@root/Services/Router/BasicRouteLoader';
import { useLink } from '@root/Services/Router/Router';
import { useEffect, useState } from 'react';
import { InfoCircle } from 'tabler-icons-react';
import { BaseResource } from '../ResourcesGrid';

export function ResourceCostFieldValue({
    resource,
    costField,
}: {
    resource: BaseResource;
    costField: 'AnnualizedCost' | 'Last30DaysCost' | 'ExtendedAnnualizedCost';
}) {
    const formatSvc = useDi(FormatService);
    const link = useLink();
    const routeLoader = useDi(BasicRouteLoader);
    const nav = useNav(routeLoader.getTopRouteMeta());
    const { getDescendUrl } = nav;
    const url = getDescendUrl('invoice-explorer', { resourceId: resource.BillingKey });

    const accessCheck = useUserAccessCheck();
    const [invoicesLinkable] = accessCheck(['Invoice Explorer']);
    const cost = resource[costField];

    const formattedCost = typeof cost !== 'number' ? 'Unknown' : formatSvc.formatMoneyNonZeroTwoDecimals(cost ?? 0);

    return (
        <Group>
            <Text>{formattedCost}</Text>
            {formattedCost === 'Unknown' ? null : invoicesLinkable === 'ok' ? (
                <Anchor {...link(url)}>Detailed billing</Anchor>
            ) : invoicesLinkable === 'notSubscribed' ? (
                <Tooltip label="This feature is only available with a FinOps subscription" position="top-start">
                    <Anchor>
                        Detailed billing <InfoCircle size={20} style={{ verticalAlign: 'middle' }} />
                    </Anchor>
                </Tooltip>
            ) : null}
        </Group>
    );
}

export function RightSizingFieldValue({ resource, endpoint, presentSize }: { resource: BaseResource; endpoint: string; presentSize: string }) {
    const resourceId = resource.Id ?? '';
    const resourceType = resource.ResourceType ?? '';
    const [recommendation, setRecommendation] = useState<Recommendation>();
    useEffect(() => {
        const recoResults = queryBuilder<Recommendation & { 'ResourceIdentifier.ResourceId': string; 'ResourceIdentifier.ResourceType': string }>()
            .where((b) => b.and(b.model['ResourceIdentifier.ResourceId'].eq(resourceId), b.model['ResourceIdentifier.ResourceType'].eq(resourceType)))
            .execute(postRecommendationQuery);
        recoResults.then((results) => {
            setRecommendation(results.Results?.[0]);
        });
    }, [resource]);
    const link = useLink();
    const routeLoader = useDi(BasicRouteLoader);
    const nav = useNav(routeLoader.getTopRouteMeta());
    const { getDescendUrl } = nav;
    const url = getDescendUrl(endpoint, { resourceId: resourceId });

    const accessCheck = useUserAccessCheck();
    const [rightsizingLinkable] = accessCheck(['RightSizing']);

    const recSize = recommendation?.LowestRiskHighestSavings?.ChangeDetails?.SizeChange?.RecommendedSize;

    return (
        <Group sx={{ rowGap: 4 }}>
            <Text>{presentSize}</Text>
            {rightsizingLinkable === 'ok' && recSize ? (
                <Anchor {...link(url)}>Rightsizing Recommendation: {recSize}</Anchor>
            ) : rightsizingLinkable === 'notSubscribed' && recSize ? (
                <Tooltip label="Find out if there is any Rightsizing opporunity available with Optimization subscription">
                    <Anchor>
                        {presentSize} <InfoCircle size={20} style={{ verticalAlign: 'middle' }} />
                    </Anchor>
                </Tooltip>
            ) : null}
        </Group>
    );
}

export function IdleResourcesFieldValue({ resource, endpoint, presentState }: { resource: BaseResource; endpoint: string; presentState: string }) {
    const resourceId = resource.Id ?? '';
    const resourceType = resource.ResourceType ?? '';
    const idleResourceService = useDi(IdleResourcesService);
    const link = useLink();
    const routeLoader = useDi(BasicRouteLoader);
    const nav = useNav(routeLoader.getTopRouteMeta());
    const { getDescendUrl } = nav;
    const [isIdle, setIsIdle] = useState<boolean>(false);
    const url = getDescendUrl(endpoint, { resourceId: resourceId });
    const accessCheck = useUserAccessCheck();
    const [idleResourcesLinkable] = accessCheck(['Idle Resources']);

    useEffect(() => {
        (async () => {
            if (idleResourcesLinkable === 'ok') {
                const baseIdleQuery = await idleResourceService.getResourceTypeQuery(resourceType, false);
                const query = {
                    Operation: 'and',
                    Operands: [
                        baseIdleQuery!,
                        {
                            Operation: 'and',
                            Operands: [
                                {
                                    Operation: 'eq',
                                    Operands: [{ Field: 'Id' }, { Value: resourceId }],
                                },
                            ],
                        },
                    ],
                } as IQueryExpr;

                const results = await postResourcesQuery(
                    {
                        IncludeSchema: false,
                        IncludeCount: true,
                        Where: query,
                    },
                    { companyId: resource.CompanyID ?? undefined }
                );

                setIsIdle(results.Count! > 0);
            }
        })();
    }, [idleResourcesLinkable]);

    return idleResourcesLinkable === 'ok' && presentState !== 'stopped' && isIdle ? (
        <Text>
            {presentState} <Anchor {...link(url)}>Resource is Idle</Anchor>
        </Text>
    ) : idleResourcesLinkable === 'notSubscribed' && presentState !== 'stopped' ? (
        <Tooltip label="Find out if this resource is Idle with Optimization subscription">
            <Anchor>
                {presentState} <InfoCircle size={20} style={{ verticalAlign: 'middle' }} />
            </Anchor>
        </Tooltip>
    ) : (
        <Text>{presentState}</Text>
    );
}

import { QueryExpr } from '@apis/Resources';
import { Box, Card, Divider, Text } from '@mantine/core';
import { useEvent } from '@root/Services/EventEmitter';
import { useState } from 'react';
import { ChartRenderer } from './ChartRenderer';
import { ChartEditor } from './Models';
import { usePreviewSize } from './DashboardEditorOverlay';

export function ChartPreview(props: ChartPreviewProps) {
    const { dashboardItemModel, settings, onSettingsChanged } = props.editor;
    const datasource = dashboardItemModel.getDatasource(settings.datasourceName);
    const [filters, setFilters] = useState<QueryExpr[]>(dashboardItemModel.dashboard.getFilters());
    useEvent(dashboardItemModel.dashboard.filtersChanged, () => setFilters(dashboardItemModel.dashboard.getFilters() ?? []));
    const { width, height } = usePreviewSize(props.ratio);
    useEvent(props.editor.onSettingsChanged);
    return (
        <Card p={0} radius="md">
            <Text px="lg" py="xs">
                Preview
            </Text>
            <Divider />
            <Text px="lg" pt={8} m={0}>
                {props.editor.title}
            </Text>
            <Box sx={{ width, height }} p="md">
                <ChartRenderer datasource={datasource!} config={settings} filters={filters} rerenderNeeded={onSettingsChanged} />
            </Box>
        </Card>
    );
}
interface ChartPreviewProps {
    editor: ChartEditor;
    ratio: number;
}

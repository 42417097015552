import { Drawer, Modal } from '@mantine/core';
import { useWindowSize } from '@react-hookz/web';
import { ReactNode } from 'react';

export function DashboardEditorOverlay({ settings, preview, open, settingsWidth = 350, ratio = 1.5 }: DashboardEditorOverlayProps) {
    const { width } = usePreviewSize(ratio);
    return (
        <>
            <Modal
                opened={open}
                overlayBlur={4}
                shadow="lg"
                centered
                onClose={() => {}}
                withinPortal
                sx={{ width: `calc(100vw - ${settingsWidth}px)` }}
                withCloseButton={false}
                size={width}
                padding={0}
                style={{ background: 'none' }}
                trapFocus={false}
            >
                {!open ? null : preview()}
            </Modal>
            <Drawer
                shadow="lg"
                withOverlay={false}
                position="right"
                closeOnClickOutside={false}
                opened={open}
                onClose={() => {}}
                withCloseButton={false}
                withinPortal
                size={settingsWidth}
                sx={{ left: `calc(100vw - ${settingsWidth}px)` }}
            >
                {!open ? null : settings()}
            </Drawer>
        </>
    );
}
interface DashboardEditorOverlayProps {
    settings: () => ReactNode;
    preview: () => ReactNode;
    open: boolean;
    settingsWidth?: number;
    ratio?: number;
}
export function usePreviewSize(ratio: number) {
    const { height, width } = useWindowSize();
    return { height: height / 2, width: (height / 2) * ratio };
}

import { RuleTagChange, TagAutomationTraceRecord, TagChange } from '@apis/TagManager/model';
import styled from '@emotion/styled';
import {
    ActionIcon,
    Anchor,
    Badge,
    Box,
    Card,
    Collapse,
    Divider,
    Group,
    List,
    Loader,
    Space,
    Table,
    Text,
    ThemeIcon,
    Title,
    useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ResourceDetailsOpener } from '@root/Components/Resources/ResourceDetails';
import { VisibleSpaces } from '@root/Components/Text/VisibleSpaces';
import TagAutomationDrawer from './RuleDetailsDrawer';
import { generateColor } from '@root/Design/Primitives';
import { CustomColors } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useEffect, useMemo, useState } from 'react';
import {
    Replace,
    DotsCircleHorizontal,
    AlertCircle,
    CircleCheck,
    Divide,
    Check,
    ChevronDown,
    ChevronUp,
    Clock,
    Icon,
    CircleDot,
} from 'tabler-icons-react';
import { TagAutomationRuleActivityModel } from './Model';
import { TagAutomationRuleService } from './TagAutomationRuleService';
import { JobService } from '@root/Services/Jobs/JobService';
import { AnonymousQueueJobJob } from '@apis/Jobs/model';
import { getCreditsGetCreditSummary } from '@apis/Customers';
import { ResourceIdentifier } from '@apis/Resources/model';

export function RuleTraceViewer({
    trace,
    onOpenDetails,
}: {
    trace: TagAutomationTraceRecord;
    onOpenDetails: (resourceId: ResourceIdentifier) => void;
}) {
    const theme = useMantineTheme();
    return (
        <>
            <Box sx={{ background: '#fff', height: '100%', overflow: 'auto' }}>
                <Box p="lg">
                    <ActivitySummary trace={trace} />
                </Box>
                <Divider color={theme.colors.gray[3] as CustomColors} variant="dashed" />
                <Box p="lg">
                    <Title order={3}>Resource</Title>
                    <ResourceSummary onOpenDetails={onOpenDetails} trace={trace} />
                </Box>
                <Divider color={theme.colors.gray[3] as CustomColors} variant="dashed" />
                <Box p="lg">
                    <Title order={3}>Steps</Title>
                    <TraceSteps trace={trace} />
                </Box>
                {trace.JobStatus === 'Failed' ? (
                    <>
                        <Divider color={theme.colors.gray[3] as CustomColors} variant="dashed" />
                        <Box p="lg">
                            <Title order={3}>Failure Details</Title>
                            <JobFailureDetails trace={trace} />
                        </Box>
                    </>
                ) : null}
            </Box>
            <TagAutomationDrawer />
        </>
    );
}

function ActivitySummary({ trace }: { trace: TagAutomationTraceRecord }) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const { state, description, icon: Icon, type } = getResultDescription(trace);
    const [canUseCredits, setCanUseCredits] = useState<boolean>(false);
    useEffect(() => {
        getCreditsGetCreditSummary().then((o) => setCanUseCredits(o.CanUseCredits ?? false));
    }, []);
    const creditDescription =
        state === 'Tag Applied'
            ? `${trace.CompletedChanges} credit(s) used`
            : trace.Mode === 'Test'
            ? `${trace.RequestedChanges} potential credit(s) used. Tests use no credits`
            : trace.Status === 'Sent' && !trace.JobStatus
            ? `${trace.RequestedChanges} expected credit(s) used`
            : '0 credits used';

    return (
        <>
            <Group spacing="xs">
                <Icon color={type === 'success' ? theme.colors.success[6] : type === 'neutral' ? theme.colors.gray[5] : theme.colors.error[6]} />
                <Text size="lg">{state}</Text>
            </Group>
            <Space h="md" />
            <Box pl="sm">
                <Text size="sm" color="dimmed">
                    {trace.Mode === 'Test' ? 'Test' : 'Tagging'}{' '}
                    {type === 'success'
                        ? ' Succeeded '
                        : type === 'fail'
                        ? ' Failed '
                        : state === 'Change Requested'
                        ? ' In Progress '
                        : state === 'No Change'
                        ? ' Skipped '
                        : ''}
                    {fmtSvc.toLocal(trace.Timestamp as unknown as string)}
                </Text>
                <Text my="sm" size="sm" color="dimmed">
                    {description}
                </Text>
                {canUseCredits ? (
                    <Group spacing="xs">
                        <DotsCircleHorizontal size={16} />
                        <Text size="sm">{creditDescription}</Text>
                    </Group>
                ) : null}
            </Box>
        </>
    );
}

function getResultDescription(trace: TagAutomationTraceRecord) {
    const state = getState(trace);
    return { state: state as ReturnType<typeof getState>, ...stateDescriptions[state] };
}

const stateDescriptions: Record<ReturnType<typeof getState>, { icon: Icon; description: string; type: 'fail' | 'success' | 'neutral' }> = {
    'Automation Loop': { icon: Replace, description: 'Two or more rules are in conflict. When this happens, no tags are changed. ', type: 'fail' },
    'Credits Exhausted': { icon: DotsCircleHorizontal, description: 'Not enough credits were available to perform automation.', type: 'fail' },
    'Unknown Failure': { icon: AlertCircle, description: 'An unknown error occurred. Please contact support. ', type: 'fail' },
    'Tag Failure': {
        icon: AlertCircle,
        description: 'Tag changes failed to apply. Review the failure details, and consider contacting support.',
        type: 'fail',
    },
    'Test Finished': { icon: CircleCheck, description: 'Test completed successfully. No issues were found.', type: 'success' },
    'No Change': {
        icon: CircleCheck,
        description: 'Attempted to change a tag, but no change was needed. This may occur due to resource sync and timing anomolies.',
        type: 'neutral',
    },
    'Change Requested': { icon: Clock, description: 'Tag changes requested. The change will be applied shortly.', type: 'neutral' },
    'Tag Applied': { icon: CircleCheck, description: 'Tag changes applied successfully.', type: 'success' },
};

function getState(trace: TagAutomationTraceRecord) {
    return trace.Status === 'CycleDetected'
        ? 'Automation Loop'
        : trace.Status === 'CreditsExhausted'
        ? 'Credits Exhausted'
        : trace.Status === 'Fail'
        ? 'Unknown Failure'
        : trace.JobStatus === 'Failed'
        ? 'Tag Failure'
        : trace.Mode === 'Test'
        ? 'Test Finished'
        : trace.JobStatus === 'Succeeded' && trace.CompletedChanges === 0
        ? 'No Change'
        : trace.Status === 'Sent' && !trace.JobStatus
        ? 'Change Requested'
        : 'Tag Applied';
}

function ResourceSummary({ trace, onOpenDetails }: { trace: TagAutomationTraceRecord; onOpenDetails: (resourceId: ResourceIdentifier) => void }) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const [tagsOpened, { toggle: toggleTags }] = useDisclosure(false);
    const sortedTags = useMemo(
        () => trace.OriginalTags?.slice().sort((a, b) => a.Key?.localeCompare(b.Key ?? '', undefined, { sensitivity: 'base' }) ?? 0) ?? [],
        [trace]
    );
    return (
        <>
            <Box p="sm">
                <Text weight="bolder">
                    {fmtSvc.adjustCspName(trace.CloudPlatform ?? '')} - {trace.ResourceType}
                </Text>
                <Anchor onClick={() => onOpenDetails(trace)} size="sm">
                    {fmtSvc.awsIdToName(trace.ResourceId ?? '')}
                </Anchor>
            </Box>
            <Space h="xs" />
            <Card radius="md" withBorder p={0}>
                <Group position="apart" p="sm" onClick={toggleTags} sx={{ cursor: 'pointer' }}>
                    <Text>Original Tags</Text>
                    <ActionIcon>{tagsOpened ? <ChevronUp /> : <ChevronDown />}</ActionIcon>
                </Group>
                <Collapse in={tagsOpened}>
                    <Divider color={theme.colors.gray[3] as CustomColors} />
                    <Table sx={{ td: { whiteSpace: 'normal', wordBreak: 'break-word' } }}>
                        <thead style={{ background: theme.colors.gray[2] }}>
                            <tr>
                                <th>Key</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedTags.map((tag, i) => (
                                <tr key={i}>
                                    <td>
                                        <VisibleSpaces value={tag.Key ?? ''} />
                                    </td>
                                    <td>
                                        <VisibleSpaces value={tag.Value ?? ''} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Collapse>
            </Card>
        </>
    );
}

function TraceSteps({ trace }: { trace: TagAutomationTraceRecord }) {
    const theme = useMantineTheme();
    const model = useDi(TagAutomationRuleActivityModel);
    const { state, type } = getResultDescription(trace);
    return (
        <Box p="sm">
            {trace.Changes?.reverse().map((change, i) => (
                <TraceMultiChangeItem key={i} change={change} index={i} highlight={model.rule?.Id === change.RuleId} />
            ))}
            <Group spacing={0}>
                {type === 'success' ? (
                    <CircleCheck size={24} color={theme.colors.success[6]} />
                ) : type === 'neutral' ? (
                    <CircleCheck size={24} color={theme.colors.gray[5]} />
                ) : (
                    <AlertCircle size={24} color={theme.colors.error[6]} />
                )}
                <Text pl={8} size="sm">
                    {state}
                </Text>
            </Group>
        </Box>
    );
}

function TraceMultiChangeItem({ change, highlight, index }: { change: RuleTagChange; highlight?: boolean; index: number }) {
    const model = useDi(TagAutomationRuleActivityModel);
    const rule = model.ruleLookup.get(change.RuleId ?? -1);
    const ruleSvc = useDi(TagAutomationRuleService);
    const theme = useMantineTheme();

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <TraceChangeColumn>
                    <TraceChangeNumber color={highlight ? theme.colors.primary[3] : theme.colors.gray[4]}>{index + 1}</TraceChangeNumber>
                    <TraceChangeVerticalLine />
                </TraceChangeColumn>
                <Box pl="sm" sx={{ flex: 1 }}>
                    <Anchor onClick={() => (rule ? ruleSvc.openDetailsDrawer(rule) : undefined)} size="sm">
                        {rule?.RuleName ?? 'Unnamed'}
                    </Anchor>
                    <TagChangeList changes={change.Changes ?? []} />
                    <Space h="md" />
                </Box>
            </Box>
        </>
    );
}

function TagChangeList({ changes }: { changes: TagChange[] }) {
    return (
        <>
            {changes.map((c, i) => (
                <Text size="sm" key={i}>
                    <Badge style={{ textTransform: 'none' }}>{c.type}</Badge> {<VisibleSpaces value={c.Key ?? ''} />}
                    {c.Value ? (
                        <>
                            <Colon />
                            <VisibleSpaces value={c.Value} />
                        </>
                    ) : (
                        ''
                    )}
                </Text>
            ))}
        </>
    );
}

const TraceChangeColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0;
`;

const TraceChangeVerticalLine = styled.div`
    flex: 1;
    height: 100%;
    border-left: 2px solid ${(p) => p.theme.colors.gray[6]};
    margin: 4px 9px;
`;

const TraceChangeNumber = styled.div<{ color: string }>`
    flex: 0;
    background: ${(p) => p.color};
    color: ${(p) => p.theme.colors.primary[8]};
    border-radius: 50%;
    min-width: 20px;
    min-height: 20px;
    text-align: center;
    font-size: 13px;
`;

const Colon = styled.span`
    margin: 0 5px;
    ::before {
        font-size: 1.1rem;
        line-height: 0.8;
        content: ':';
    }
`;

function JobFailureDetails({ trace }: { trace: TagAutomationTraceRecord }) {
    const jobSvc = useDi(JobService);
    const [loading, setLoading] = useState(false);
    const [job, setJob] = useState<AnonymousQueueJobJob | undefined>(undefined);
    const loadJob = async (jobId: string) => {
        setLoading(true);
        setJob(undefined);
        try {
            if (jobId) {
                const job = await jobSvc.getJobById(jobId);
                setJob(job);
            }
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        loadJob(trace.JobId ?? '');
    }, [trace.JobId]);

    return <>{loading ? <Loader /> : <Text>{job?.FailureMessage}</Text>}</>;
}

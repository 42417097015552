import { postDailyRollupQuery, postMonthlyRollupQuery } from '@apis/Invoices';
import { inject, singleton } from 'tsyringe';
import { FormatService } from '../FormatService';
import { PlatformService } from '../PlatformService';
import { queryBuilder } from '../QueryExpr';
import { IDailyRollup, IMonthlyRollup, InvoiceSchemaService } from './InvoiceSchemaService';

@singleton()
export class InvoiceApiCache {
    private companyLoaded?: number;
    private dateRange?: Promise<{ from?: Date; to?: Date }>;
    private schemaCompanyLoaded?: number;
    private invoiceSchemaSvc?: InvoiceSchemaService;

    public constructor(@inject(FormatService) private readonly fmtSvc: FormatService) {}

    public getDateRange(companyId?: number) {
        if (!this.dateRange || this.companyLoaded !== companyId) {
            this.companyLoaded = companyId;
            this.dateRange = this.fetchDateRange();
        }
        return this.dateRange;
    }

    public getInvoiceSchemaSvc(platformSvc: PlatformService, companyId?: number) {
        if (!this.invoiceSchemaSvc || this.schemaCompanyLoaded !== companyId) {
            this.schemaCompanyLoaded = companyId;
            this.invoiceSchemaSvc = new InvoiceSchemaService(this.fmtSvc, platformSvc);
        }
        return this.invoiceSchemaSvc;
    }

    private async fetchDateRange() {
        const results = await queryBuilder<IMonthlyRollup>()
            .select((r) => ({
                from: r.min(r.model.UsageMonth),
                to: r.max(r.model.UsageMonth),
            }))
            .execute(postMonthlyRollupQuery);

        const [first] = results.Results ?? [];
        return {
            from: first?.from ? this.fmtSvc.parseDateNoTime(first.from) : undefined,
            to: first?.to ? this.fmtSvc.parseDateNoTime(first.to) : undefined,
        };
    }
}

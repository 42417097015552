import { injectable } from 'tsyringe';
import { EventEmitter } from '../EventEmitter';
import { FailDetailsReason } from '@apis/Jobs/model';

export interface PollingPromise<TResult> extends Promise<TResult> {
    cancel: () => void;
    progress: EventEmitter<TResult>;
    failed: EventEmitter<boolean>;
}

@injectable()
export class PollingService {
    public pollUntil<TResult>(poll: () => Promise<TResult>, done: (result: TResult) => boolean, frequency: number = 500) {
        let stopped = false;
        let timeout: any = 0;
        let resolver = (_: TResult) => {};
        let rejector = (_: any) => {};
        const progress = new EventEmitter<TResult>(undefined as unknown as TResult);
        const failed = new EventEmitter<boolean>(false);
        const stop = () => {
            stopped = true;
            clearTimeout(timeout);
        };
        const result = new Promise<TResult>((resolve, reject) => {
            resolver = resolve;
            rejector = reject;
        }) as PollingPromise<TResult>;
        const run = async () => {
            if (!stopped) {
                try {
                    const result = await poll();
                    if (done(result)) {
                        stop();
                        progress.emit(result);
                        resolver(result);
                    } else {
                        progress.emit(result);
                        timeout = setTimeout(run, frequency);
                    }
                } catch (err) {
                    stop();
                    failed.emit(true);
                    rejector(err);
                }
            }
        };

        result.cancel = stop;
        result.progress = progress;

        run();

        return result;
    }
}

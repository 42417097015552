import { CompanyRelationshipBundle } from '@apis/Customers/model';
import { FillerSwitch } from '@root/Design/Filler';
import {
    DataMarketplaceApiService,
    IDataDefinitionDigest,
    RelationshipBundleService,
    ScopedRelationshipBundleService,
} from '@root/Services/DataMarketplace/DataMarketplaceApiService';
import { useDi } from '@root/Services/DI';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useEffect, useState } from 'react';
import { ComputeMarketshareLandingPage } from './CustomViews/ComputeMarketshare/ComputeMarketshareLandingPage';
import { useParamsDataDef } from './RelationshipDataComponents';

export function RelationshipDataPage() {
    const { loading, digest, relationships, definition } = useParamsDataDef();
    const digestPres = digest?.Presentation;

    return (
        <FillerSwitch loading={loading} noData={!loading && !digestPres}>
            {() => (
                <>
                    {digestPres?.RelatedView === 'chip-mfg-marketshare' ? (
                        <ComputeMarketshareLandingPage digest={digest!} relationships={relationships!} definition={definition} />
                    ) : (
                        ''
                    )}
                </>
            )}
        </FillerSwitch>
    );
}

function RelationshipDataBreadcrumb() {
    const { loading, digest } = useParamsDataDef();

    const breadcrumb = digest?.Name;
    return <>{loading ? 'Loading...' : breadcrumb}</>;
}

endpoint('relationship-data', RelationshipDataPage, RelationshipDataBreadcrumb);

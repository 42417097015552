import { getUserLogin } from '@apis/Customers';
import { Box, Button, Modal, Space, Text, TextInput } from '@mantine/core';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { ConfigService } from '@root/Services/ConfigService';
import { useDi } from '@root/Services/DI';
import { useEventValue } from '@root/Services/EventEmitter';
import { Router } from '@root/Services/Router/Router';
import { SplashService } from '@root/Services/SplashService';
import { System } from '@root/Services/System';
import { TOSAgreement } from '@root/Site/Error/TOSAgreement';
import { UnknownUser } from '@root/Site/Error/UnknownUser';
import { VerifyEmail } from '@root/Site/Error/VerifyEmail';
import { makeAutoObservable } from 'mobx';
import { Observer } from 'mobx-react';
import { useCallback, useEffect, useMemo } from 'react';

export function AuthenticationProvider() {
    const authSvc = useDi(AuthenticationService);
    const initialLoginNeeded = useEventValue(authSvc.initialLoginNeeded);
    const authenticationNeeded = useEventValue(authSvc.authenticationNeeded);
    const tokenNeeded = useEventValue(authSvc.tokenLoginNeeded);
    const unknownError = useEventValue(authSvc.unknownError);
    const configSvc = useDi(ConfigService);
    const router = useDi(Router);
    const user = authSvc.user;
    const splashService = useDi(SplashService);
    const system = useDi(System);
    const siteReady = useEventValue(system.siteReady);
    useEffect(() => {
        if (initialLoginNeeded) {
            const rawCode = window.location.search;
            const inviteCode = rawCode
                .substring(1)
                .split('&')
                .reduce((result, item) => {
                    const [key, value] = item.split('=');
                    return key.toLocaleLowerCase() === 'invitecode' ? value : result;
                }, '');
            const value = encodeURIComponent(inviteCode || router.getCurrentPath());
            const key = inviteCode ? 'inviteCode' : 'redir';
            location.href = `${configSvc.config.loginUrl}?${key}=${value}&host=${window.location.hostname}`;
        }
    }, [initialLoginNeeded]);
    useEffect(() => {
        if (siteReady && user) {
            const inviteParams = [...new URL(location.href).searchParams].find((r) => r[0]?.toLowerCase() === 'invitecode');
            if (inviteParams) {
                location.href = `${configSvc.config.loginUrl}?inviteCode=${inviteParams[1]}&host=${window.location.hostname}`;
            }
        }
    }, [siteReady, user]);

    if (tokenNeeded) {
        splashService.removeSplash();
        return <VerifyEmail />;
    } else if (unknownError) {
        splashService.removeSplash();
        return <UnknownUser />;
    }

    return authenticationNeeded && !initialLoginNeeded ? (
        <Modal opened={true} withCloseButton={false} title="Login Needed" onClose={() => {}} withinPortal>
            {user ? <Text>Looks like your session has expired, please try logging back in. </Text> : <Text>Welcome! Please Login.</Text>}
            <Space h="lg" />
            <Auth0Signin />
        </Modal>
    ) : null;
}
function DummySignin() {
    const form = useMemo(() => makeAutoObservable({ username: '', password: '' }), []);
    const signin = useCallback(async () => {
        //await getUserLogin(form);
    }, []);
    const handleKeyup: React.KeyboardEventHandler<HTMLDivElement> = useCallback((e) => {
        if (e.key === 'Enter') {
            signin();
        }
    }, []);
    return (
        <Observer
            render={() => (
                <>
                    <Box mb={16} onKeyUp={handleKeyup}>
                        <TextInput value={form.username} label="Username" type="text" onChange={(e) => (form.username = e.target.value)} />
                        <TextInput value={form.password} label="Password" type="password" onChange={(e) => (form.password = e.target.value)} />
                    </Box>
                    <Box style={{ textAlign: 'right' }}>
                        <Button onClick={signin}>Sign In</Button>
                    </Box>
                </>
            )}
        />
    );
}
function Auth0Signin() {
    const configSvc = useDi(ConfigService);
    return (
        <Button
            component="a"
            href={`${configSvc.config.loginUrl}?host=${window.location.hostname}`}
            target="_blank"
            rightIcon={<i className="ti ti-new-window"></i>}
        >
            Log In
        </Button>
    );
}

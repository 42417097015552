import { ComponentType, ReactNode, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useDi, useDiContainer } from '@root/Services/DI';
import { useEffect } from 'react';
import { useEvent, useEventValue } from '@root/Services/EventEmitter';
import { ActionIcon, Box, Button, Group, LoadingOverlay, Popover, Space, Text, TextInput, ThemeIcon, Title, Tooltip } from '@mantine/core';
import {
    AnonymousDashboardItemConfig,
    CustomizableDashboardModel,
    IDashboardConfig,
    IDashboardItemType,
    LayoutItem,
    QueryDatasource,
} from './Models';
import { CustomizableDashboardContainer, DashboardTitle } from './Design';
import { DashboardLayout } from './DashboardLayout';
import { Filter, Folder, LayoutDashboard, Plus } from 'tabler-icons-react';
import { Picker } from '../Picker/Picker';
import { useDisclosure } from '@mantine/hooks';
import { chartTypeIcons } from './Charts/Design';
import { DataFilterModel, DataFilters, IValueProviderFactory } from '../Filter/Filters';
import { SchemaService, SchemaValueProvider } from '@root/Services/QueryExpr';
import { SchemaFieldNameProvider } from '../Filter/Services';
import { QueryExpr } from '@apis/Resources';
import { FieldPicker } from '../Picker/FieldPicker';
import { closeModal, useModals } from '@mantine/modals';
import { colorPalette, theme } from '@root/Design/Themes';
import { DashboardConfigPanel } from '../DashboardPersistence/DashboardConfigPanel';
import { getDashboardGetOrCreateDashboardUserSettings, postDashboardSave } from '@apis/Customers';
import { DashboardUserSettings } from '@apis/Customers/model';

export function CustomizableDashboard(props: CustomizableDashboardProps) {
    const di = useDiContainer();
    const model = useMemo(() => di.resolve(CustomizableDashboardModel), [di]);
    useEffect(() => model.updateImplicitFilter(props.implicitFilter), [model, props.implicitFilter]);
    useEffect(() => model.setItemTypes(props.itemTypes), [props.itemTypes]);
    useEffect(() => model.setDatasources(props.datasources), [props.datasources, model]);
    useEffect(() => {
        model.load(props.dashboardKey, props.defaultConfig, props.id, !!props.static);
    }, [props.dashboardKey, props.id, props.defaultConfig, model, props.static]);
    useEvent(model.configChanged);
    useEvent(model.layoutChanged);
    const layoutElements = useMemo(() => new WeakMap<LayoutItem<any>, HTMLDivElement | null>(), []);
    const handleElementLoaded = useCallback((layoutItem: LayoutItem<any>, element: HTMLDivElement) => {
        layoutElements.set(layoutItem, element);
    }, []);
    const ensureVisible = useEventValue(model.ensureVisible);
    useEffect(() => {
        const element = ensureVisible && layoutElements.get(ensureVisible);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [ensureVisible, ensureVisible && layoutElements.get(ensureVisible)]);

    const loading = useEventValue(model.loading);
    const schemasLoading = useEventValue(model.schemasLoading);
    useEvent(model.filtersChanged);
    return (
        <>
            <CustomizableDashboardContainer height={props.height} padding={props.padding}>
                {loading && <LoadingOverlay zIndex={1} visible={true} />}
                {props.hideHeader || schemasLoading ? null : (
                    <CustomizableDashboardHeader
                        model={model}
                        allowFilter={!!props.allowFilter}
                        allowAdd={!!props.allowAdd}
                        allowLoader={!!props.allowLoader}
                        addOptions={props.addOptions}
                        static={!!props.static}
                        onIdChanged={props.onIdChanged}
                        toolRightPlaceholder={props.toolRightPlaceholder}
                    />
                )}
                {props.headline?.()}
                <DashboardLayout
                    onElementLoaded={handleElementLoaded}
                    layout={model.currentLayout}
                    onLayoutChanged={model.saveLayoutChanges}
                    tileStatic={props.static}
                />
            </CustomizableDashboardContainer>
        </>
    );
}
export interface DashboardAddOption {
    label: string;
    settings: AnonymousDashboardItemConfig;
    category: 'recommended' | 'blank';
}

interface CustomizableDashboardProps {
    allowAdd?: boolean;
    allowFilter?: boolean;
    allowLoader?: boolean;
    hideHeader?: boolean;
    implicitFilter?: QueryExpr[];
    dashboardKey: string;
    addOptions: DashboardAddOption[];
    defaultConfig: IDashboardConfig;
    datasources: QueryDatasource[];
    itemTypes: IDashboardItemType[];
    id?: number;
    static?: boolean;
    headline?: () => ReactNode;
    height?: string;
    padding?: number;
    onIdChanged?: (id?: number) => void;
    toolRightPlaceholder?: ReactNode;
}

interface CustomizableDashboardHeaderProps {
    addOptions: DashboardAddOption[];
    model: CustomizableDashboardModel;
    allowFilter: boolean;
    allowAdd: boolean;
    allowLoader: boolean;
    static: boolean;
    onIdChanged?: (id?: number) => void;
    toolRightPlaceholder?: ReactNode;
}
function CustomizableDashboardHeader({
    model,
    allowAdd,
    allowFilter,
    allowLoader,
    addOptions,
    static: isStatic,
    onIdChanged,
    toolRightPlaceholder,
}: CustomizableDashboardHeaderProps) {
    const [filterModel, setFilterModel] = useState<DataFilterModel>();
    useEffect(() => {
        const disposer = filterModel?.filtersChanged.listen(() => model.handleFilterUpdate(filterModel?.getFilters(), false));
        return () => disposer?.dispose?.();
    }, [filterModel]);
    return (
        <>
            <DashboardTitle style={{ marginBottom: 32 }}>
                <Box>
                    <Group align="left">
                        <Title data-atid={'DashboardHeader:' + model.title} order={2}>
                            {model.title}
                        </Title>
                    </Group>
                </Box>
                <Box>
                    <Group spacing="sm">
                        {toolRightPlaceholder}
                        {allowFilter ? (
                            <Button
                                data-atid="AddDashboardFilterButton"
                                variant="outline"
                                onClick={() => filterModel?.addEmptyFilter()}
                                leftIcon={<Filter />}
                            >
                                Add Filter
                            </Button>
                        ) : null}
                        {allowAdd ? <AddTileButton model={model} addOptions={addOptions} /> : null}
                        {allowLoader ? <PersistencePanel model={model} onIdChanged={onIdChanged} /> : null}
                    </Group>
                    <GlobalFilter onFilterModelSet={setFilterModel} model={model} />
                </Box>
            </DashboardTitle>
        </>
    );
}

function GlobalFilter({ model, onFilterModelSet }: { model: CustomizableDashboardModel; onFilterModelSet: (model: DataFilterModel) => void }) {
    const datasource = model.getDefaultDatasource();
    const [meta, setMeta] = useState<{
        valueProvider: IValueProviderFactory;
        fieldInfoProvider: SchemaFieldNameProvider;
        schemaService: SchemaService;
    }>();
    useEffect(() => {
        (async () => {
            const schema = await datasource?.schema.getSchema();
            if (schema) {
                const schemaService = new SchemaService(schema);
                const fieldInfoProvider = new SchemaFieldNameProvider(schemaService);
                const valueProvider =
                    datasource?.getValueProviderFactory?.(schemaService) ?? new SchemaValueProvider(schemaService, datasource!.source);
                setMeta({ fieldInfoProvider, valueProvider, schemaService });
            }
        })();
    }, [datasource]);
    const handleFilterChanged = useCallback(
        (filters: QueryExpr[]) => {
            model.handleFilterUpdate(filters);
        },
        [model]
    );

    const handleOnPin = useCallback(
        (filters: QueryExpr[]) => {
            model.handlePinnedFiltersUpdate(filters);
        },
        [model]
    );

    return !meta ? null : (
        <DataFilters
            fieldInfoProvider={meta.fieldInfoProvider}
            valueProvider={meta.valueProvider}
            filters={model.filters || []}
            pinnedFilters={model.pinnedFilters || []}
            allowPin={true}
            onPin={handleOnPin}
            onChange={handleFilterChanged}
            align="end"
            showGlobalSearch={false}
            onModelLoaded={onFilterModelSet}
            renderAddFilter={() => <></>}
            renderFieldPicker={(select) => (
                <Box>
                    <FieldPicker mode="single" selections={[]} schema={meta.schemaService} onChange={([f]) => select(f.path)} />
                </Box>
            )}
        />
    );
}

function RenameDashboard({ model, id }: { model: CustomizableDashboardModel; id: string }) {
    const [dashboardTitle, setTitle] = useState<string>(model.title);
    return (
        <Box>
            Enter new name:
            <TextInput
                value={dashboardTitle}
                maxLength={64}
                onChange={(event: any) => {
                    setTitle(event.target.value);
                }}
            />
            <Space h={15} />
            <Group position="right">
                <Button
                    disabled={dashboardTitle == ''}
                    onClick={() => {
                        model.rename(dashboardTitle);
                        closeModal(id);
                    }}
                >
                    Save
                </Button>
                <Button
                    variant="outline"
                    onClick={() => {
                        closeModal(id);
                    }}
                >
                    Cancel
                </Button>
            </Group>
        </Box>
    );
}

function DashboardEdit({ model }: { model: CustomizableDashboardModel }) {
    const [opened, { open, close, toggle }] = useDisclosure(false);

    const modals = useModals();
    const openChangeNameModal = () => {
        const id = modals.openModal({
            zIndex: 500,
            title: (
                <Text size={18} weight={600} color={colorPalette.darkTitleColor}>
                    Rename Dashboard
                </Text>
            ),
            children: <RenameDashboard model={model} id="renameDashboard" />,
            sx: { borderRadius: theme.radius?.lg, padding: '32px' },
        });
    };

    return (
        <Popover opened={opened} onClose={close} position="bottom-end" withinPortal shadow="md">
            <Popover.Dropdown p={24} py="xs">
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        openChangeNameModal();
                    }}
                >
                    Rename Dashboard
                </div>
            </Popover.Dropdown>
            <Popover.Target>
                <i
                    className="ti ti-chevron-down"
                    style={{ fontSize: '26px', marginTop: '4px', cursor: 'pointer', color: theme.colors!.gray![6] }}
                    onClick={toggle}
                ></i>
            </Popover.Target>
        </Popover>
    );
}

function AddTileButton({ model, addOptions }: { model: CustomizableDashboardModel; addOptions: DashboardAddOption[] }) {
    const [opened, { open, close, toggle }] = useDisclosure(false);
    const onSelect = useCallback(
        (options: DashboardAddOption[]) => {
            const option = options[0];
            if (option) {
                const item = model.addItem(option.settings);
                const itemModel = item.getModel();
                if (itemModel && option.category === 'blank') {
                    itemModel.editOnLoad = true;
                }
            }
            close();
        },
        [close]
    );

    return !addOptions.length ? null : (
        <Popover opened={opened} onClose={close} position="bottom-end" withArrow withinPortal shadow="md">
            <Popover.Dropdown p={0}>
                <Picker
                    minimizeHeight
                    height={300}
                    itemHeight={44}
                    mode="single"
                    items={addOptions}
                    selections={[]}
                    onChange={onSelect}
                    nameAccessor={(o) => o.label}
                    noFilter
                    renderItem={(item) => (
                        <Group sx={{ height: '100%' }}>
                            <ThemeIcon variant="light">
                                <i className={chartTypeIcons.get(item.settings.chartType) ?? ''}></i>
                            </ThemeIcon>
                            <Text size="sm">{item.label}</Text>
                        </Group>
                    )}
                />
            </Popover.Dropdown>
            <Popover.Target>
                <Button data-atid="AddDashboardTileButton" onClick={toggle} leftIcon={<Plus />}>
                    Add Tile
                </Button>
            </Popover.Target>
        </Popover>
    );
}

function PersistencePanel({ model, onIdChanged }: { model: CustomizableDashboardModel; onIdChanged?: (id?: number) => void }) {
    const [opened, { close, toggle }] = useDisclosure(false);
    const load = useCallback((config: { id: number }) => onIdChanged?.(config.id), [model]);
    const save = useCallback((name: string) => model.saveCopy(name), [model]);
    const [userSettings, setUserSettings] = useState<DashboardUserSettings>();
    useMemo(async () => {
        const settings = await getDashboardGetOrCreateDashboardUserSettings();
        setUserSettings(settings);
    }, []);
    return (
        <>
            <Button variant="outline" leftIcon={<LayoutDashboard />} onClick={toggle} data-atid="SavedViewsButton">
                Saved Views
            </Button>
            <DashboardConfigPanel
                onConfigChange={model.handleConfigChange}
                onLoad={load}
                onSave={save}
                opened={opened}
                userSettings={userSettings!}
                hideAutosaveOption
                saveTitle="Save View"
                savePrompt="Save a copy of the current view"
                loadTitle="My Saved Views"
                loadPrompt=""
                onClose={close}
                configKey={model.persistenceKey}
                title="Saved Views"
            />
        </>
    );
}

import styled from '@emotion/styled';
import { Box, Text } from '@mantine/core';
import { colorPalette } from './Themes';

export const DivLeft = styled.div`
    float: left;
`;

export const DivRight = styled.div`
    float: right;
`;

export const DivClear = styled.div`
    clear: both;
`;

export const DivCenter = styled.div`
    text-align: center;
    margin: 0 auto;
`;

export const DivCenterMarginTop = styled.div`
    text-align: center;
    margin: 0 auto;
    margin-top: 50px;
`;

export const UsageSelector = styled.div`
    width: 100%;
    text-align: center;
    margin: 0 auto;

    div {
        float: left;
        width: 23%;
        border: 1px solid #000;
        padding: 5px;
    }

    div.selected {
        background: #000;
        color: ${colorPalette.fffColor};
    }
`;

export const ResourceInfoTable = styled.div`
    table {
        width: 100%;
        border-collapse: collapse;
        font-size: 14px;
    }
    table thead td {
        font-weight: bold;
        padding: 3px;
    }
    table tbody tr td {
        border-bottom: 1px solid ${(p) => p.theme.colors.gray[3]};
        padding: 3px;
    }
    table tbody tr:last-child td {
        border-bottom-width: 0;
        padding: 3px;
    }
`;

export const ResourceInfoContainerTable = styled.div`
    table {
        width: 100%;
        border-collapse: collapse;
    }
    table tr td {
        padding: 5px;
    }
    table tr:first-of-type td {
        text-align: center;
        cursor: pointer;
        border: 1px solid #006;
    }
    table tr:first-of-type td.selected {
        background: #006;
        color: ${colorPalette.fffColor};
    }
    table tr:first-of-type td.unselected {
        color: #006;
        background: ${colorPalette.fffColor};
    }
`;

export const ResourceInfoData = styled.div`
    .label {
        font-weight: bold;
        float: left;
        width: 49%;
        text-align: right;
        padding-right: 5px;
    }
    .value {
        float: left;
        width: 49%;
        padding-left: 5px;
    }
    .clear {
        clear: both;
    }
`;

export const ResourceChart = styled.div`
    width: 100%;
    height: 400px;
    padding: 15px;
    border-radius: ${(p) => p.theme.radius.md}px;
    box-shadow: ${(p) => p.theme.shadows.md};
    background: #fff;
`;

export function ResourceChartTitle({ title, metrics }: { title: string; metrics: IChartTitleMetric[] }) {
    return (
        <ResourceChartTitleEl>
            <Text>{title}</Text>
            <ResourceChartTitleMetrics metrics={metrics} />
        </ResourceChartTitleEl>
    );
}

export const ResourceChartTitleEl = styled.div`
    display: grid;
    grid-template-columns: auto auto;
`;

interface IChartTitleMetric {
    label: string;
    value: string | number;
    unit?: string;
}
export function ResourceChartTitleMetrics({ metrics }: { metrics: IChartTitleMetric[] }) {
    return (
        <ResourceChartTitleMetricsEl metrics={metrics.length}>
            {metrics.map(({ label, unit, value }, index) => (
                <Box key={index}>
                    <Text align="center">{label}</Text>
                    <Text align="center" sx={{ whiteSpace: 'nowrap' }} weight="bolder">
                        {value}
                        {unit}
                    </Text>
                </Box>
            ))}
        </ResourceChartTitleMetricsEl>
    );
}

const ResourceChartTitleMetricsEl = styled.div<{ metrics: number }>`
    display: grid;
    grid-template-columns: repeat(${(p) => p.metrics}, 1fr);
    font-size: 0.75rem;
    border-radius: 5px;
    background: ${(p) => p.theme.colors.gray[2]};
`;

import {
    getCreditsGetCreditTransactions,
    getCreditsGetCreditTransactionsForPeriod,
    getUserGetCompanyUsers,
    useGetCreditsGetCreditSummary,
} from '@apis/Customers';
import { CreditTransaction, UserListItem } from '@apis/Customers/model';
import styled from '@emotion/styled';
import { Box, createStyles, Group, Space, Text, Title } from '@mantine/core';
import { DataGrid } from '@root/Components/DataGrid';
import { ColumnConfig } from '@root/Components/DataGrid/Models';
import { PageContent } from '@root/Design/Layout';
import { colorPalette, theme } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { BillingBody, CreditIcon } from './Credits';

type CreditTransactionModel = CreditTransaction;

export interface CreditUsageOverviewProps {
    hideTotalCreditsBalance?: boolean;
    transactionHeaderSize?: number;
    rightTopControl?: ReactNode;
    startDate?: Date;
    endDate?: Date;
}

export function CreditUsageOverview(props: CreditUsageOverviewProps) {
    const { classes } = useStyles();
    const { data: creditSummary, refetch } = useGetCreditsGetCreditSummary();
    const formatSvc = useDi(FormatService);
    const [transactions, setTransactions] = useState<CreditTransactionModel[]>([]);
    const [uniqueDescriptions, setUniqueDescriptions] = useState<string[]>([]);

    const [users, setUsers] = useState<UserListItem[]>();
    useEffect(() => {
        (async () => {
            getUserGetCompanyUsers().then((users) => {
                setUsers(users);
            });
        })();
    }, []);

    const datagridColumns = useMemo(
        () =>
            [
                {
                    header: 'Date/Time',
                    accessor: 'TransactionDate',
                    defaultWidth: 250,
                    id: 'TransactionDate',
                    filter: {
                        filterType: 'date',
                        name: 'Date',
                        filterField: 'FilterableTransactionDate',
                    },
                    cellRenderer: (item) => formatSvc.toLocalNoSeconds(item.TransactionDate),
                },
                {
                    header: 'Description',
                    accessor: 'Description',
                    defaultWidth: 200,
                    id: 'Description',
                    filter: {
                        filterType: 'string',
                        name: 'Description',
                        filterField: 'Description',
                        options: {
                            getValueProvider: () => uniqueDescriptions.map((value) => ({ value, label: value ?? 'None' })),
                        },
                    },
                    cellRenderer: (item) => formatSvc.userFriendlyCamelCase(item.Description ?? ''),
                },
                {
                    header: 'User',
                    accessor: 'User',
                    defaultWidth: 150,
                    id: 'User',
                    noSort: true,
                    cellRenderer: (item) => {
                        const user = users?.find((data) => data.Id === item.UserId);
                        const name = user ? `${user.FirstName} ${user.LastName}` : item.UserId ? 'Support User' : 'System';
                        return <>{name}</>;
                    },
                    exportOptions: {
                        renderer: (c) => {
                            const user = users?.find((data) => data.Id === c.UserId);
                            return user ? `${user.FirstName} ${user.LastName}` : c.UserId ? 'Support User' : 'System';
                        },
                    },
                },
                {
                    header: '# of Credits',
                    accessor: (item) => {
                        if (item.CreditsAdded! > 0) {
                            return item.CreditsAdded;
                        } else if (item.JobCompleted) {
                            return -(item.CreditsUsed ?? 0);
                        } else {
                            return -(item.CreditsHeld ?? 0);
                        }
                    },
                    defaultWidth: 150,
                    id: 'CreditsUsed',
                    align: 'right',
                    type: 'number',
                    sortField: 'CreditsUsed',
                    cellRenderer: (item) => {
                        if (item.CreditsAdded! > 0) {
                            return <AddCredits>+ {item.CreditsAdded?.toLocaleString()}</AddCredits>;
                        } else if (item.JobCompleted) {
                            return <SubCredits>- {item.CreditsUsed?.toLocaleString()}</SubCredits>;
                        } else {
                            return <SubCredits>- {item.CreditsHeld?.toLocaleString()}</SubCredits>;
                        }
                    },
                },
                {
                    header: 'Job Status',
                    accessor: (item) => (item.JobCompleted ? 'Completed' : 'Pending (Reserved)'),
                    defaultWidth: 450,
                    id: 'JobCompleted',
                    sortField: 'Job Status',
                    filter: {
                        filterType: 'string',
                        name: 'Job Status',
                        filterField: 'Job Status',
                        options: {
                            getValueProvider: () => [
                                { value: 'Completed', label: 'Completed' },
                                { value: 'Pending (Reserved)', label: 'Pending (Reserved)' },
                            ],
                        },
                    },
                    cellRenderer: (item) => {
                        return <>{item.JobCompleted ? 'Completed' : 'Pending (Reserved)'}</>;
                    },
                },
            ] as ColumnConfig<CreditTransactionModel>[],
        [users, uniqueDescriptions]
    );

    useEffect(() => {
        (async () => {
            loadTransactionData();
        })();
    }, [props.startDate, props.endDate]);

    const loadTransactionData = async () => {
        let transactions: CreditTransaction[] = [];

        if (props.startDate && props.endDate) {
            transactions = await getCreditsGetCreditTransactionsForPeriod({
                startDate: props.startDate.toDateString(),
                endDate: props.endDate.toDateString(),
            });
        } else {
            transactions = await getCreditsGetCreditTransactions();
        }

        setTransactions(
            transactions
                .map((transaction) => ({
                    ...transaction,
                    FilterableTransactionDate: new Date(formatSvc.toShortDate(new Date(transaction.TransactionDate as string))),
                }))
                .sort((a, b) => new Date(b.TransactionDate ?? new Date()).getTime() - new Date(a.TransactionDate ?? new Date()).getTime())
        );
        const descrip = transactions.map((s) => s.Description as string);
        setUniqueDescriptions(descrip.filter((value, index, self) => self.indexOf(value) === index));
    };

    var formatter = new Intl.NumberFormat('en-US', {});

    return (
        <PageContent>
            <BillingBody
                style={{
                    padding: `${props.hideTotalCreditsBalance ? 0 : theme.spacing?.lg}px ${props.hideTotalCreditsBalance ? 0 : theme.spacing?.xl}px`,
                }}
            >
                {props.hideTotalCreditsBalance || (
                    <>
                        <Space h={20}></Space>
                        <Box className={classes.creditBalance}>
                            <Group position="apart">
                                <Group className={classes.creditBalanceIcon}>
                                    <CreditIcon>
                                        <i className="ti ti-chart-bar" />
                                    </CreditIcon>

                                    <Text size={18} weight={600} color={colorPalette.darkTitleColor}>
                                        Total Credits Balance
                                    </Text>
                                </Group>
                            </Group>
                            <Space h={10}></Space>
                            <Text size={30} weight={600} color={colorPalette.darkTitleColor}>
                                {creditSummary == null ? '' : formatter.format(creditSummary?.TotalCredits!)}
                            </Text>
                        </Box>
                        <Space h={20}></Space>
                        <Title order={6}>Credit Transactions</Title>
                        <Space h={20}></Space>{' '}
                    </>
                )}
                <Box sx={{ height: 400 }}>
                    {users && transactions ? (
                        <DataGrid
                            exportName="Credits"
                            dataSource={transactions}
                            columns={datagridColumns}
                            rightTopPlaceHolder={props.rightTopControl}
                        />
                    ) : null}
                </Box>
            </BillingBody>
        </PageContent>
    );
}

const AddCredits = styled.div`
    color: ${colorPalette.greenIconColor};
`;

const SubCredits = styled.div`
    color: ${colorPalette.errorSubText};
`;

const useStyles = createStyles((theme) => ({
    creditBalance: {
        padding: `${theme.spacing?.lg}px ${theme.spacing?.xl}px`,
        backgroundColor: colorPalette.white,
        border: `solid 1px #0002`,
        borderRadius: `${theme.radius?.md}px`,
    },

    disableLink: {
        pointerEvents: 'none',
    },

    creditBalanceIcon: {
        i: {
            size: `${theme.fontSizes.md}px`,
            color: colorPalette.greenIconColor,
            backgroundcolor: colorPalette.greenIconBgColor,
            borderradius: '50%',
            textAlign: 'center',
            width: '32px',
            height: '32px',
            display: 'block',
        },
    },
}));

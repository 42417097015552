import { getCompanyCheckCloudProviderConnectivity } from '@apis/Customers';
import { AzureSubscriptionInfo } from '@apis/Customers/model';
import { Anchor, Badge, Group, Loader, Text, Tooltip } from '@mantine/core';
import { useCallback, useMemo, useState } from 'react';
import { useDi } from '@root/Services/DI';
import { DataColumnConfig } from '@root/Components/DataGrid/Models';
import { DataGrid } from '@root/Components/DataGrid';
import { FormatService } from '@root/Services/FormatService';

export function AzureSubscriptionsGrid(props: { azureSubscriptions: AzureSubscriptionInfo[] }) {
    const fmtSvc = useDi(FormatService);
    const columns = useMemo(
        () =>
            [
                {
                    id: 'SubscriptionId',
                    header: 'Subscription ID',
                    accessor: 'SubscriptionId',
                    defaultWidth: 140,
                    sortField: 'SubscriptionId',
                    type: 'string',
                    filter: true,
                },
                {
                    id: 'SubscriptionName',
                    header: 'Subscription Name',
                    accessor: 'SubscriptionName',
                    defaultWidth: 250,
                    sortField: 'SubscriptionName',
                    type: 'string',
                    filter: true,
                },
                {
                    id: 'TenantId',
                    header: 'Tenant ID',
                    accessor: 'TenantId',
                    defaultWidth: 140,
                    sortField: 'TenantId',
                    type: 'string',
                    filter: true,
                },
                {
                    id: 'TenantName',
                    header: 'Tenant Name',
                    accessor: 'TenantName',
                    defaultWidth: 250,
                    sortField: 'TenantName',
                    type: 'string',
                    filter: true,
                },
                {
                    id: 'DateConnected',
                    header: 'Date Connected',
                    accessor: 'DateConnected',
                    defaultWidth: 140,
                    sortField: 'DateConnected',
                    type: 'date',
                    filter: true,
                    formatter: (r) => (r.DateConnected ? fmtSvc.formatDateWithTodaysTime(fmtSvc.toLocalDate(r.DateConnected)) : ''),
                },
                {
                    id: 'Connected',
                    header: 'Connected',
                    accessor: (r) => r.Status ?? 'Unchecked',
                    defaultWidth: 175,
                    cellRenderer: (r) => {
                        return r.Id ? <CheckCloudProviderConnection key={r.Id} azureSubscription={r} /> : '';
                    },
                },
            ] as DataColumnConfig<AzureSubscriptionInfo & { Status?: CloudProviderConnectionStatus }>[],
        []
    );
    return <DataGrid columns={columns} dataSource={props.azureSubscriptions} />;
}
export type CloudProviderConnectionStatus = 'Healthy' | 'Unhealthy' | 'Not Connected' | 'Failed';
function CheckCloudProviderConnection({
    azureSubscription,
}: {
    azureSubscription: AzureSubscriptionInfo & { Status?: CloudProviderConnectionStatus };
}) {
    const [connected, setConnected] = useState(azureSubscription.Status);
    const [checking, setChecking] = useState<boolean>(false);

    const check = useCallback(async () => {
        let failed = false;
        let result: CloudProviderConnectionStatus = 'Failed';
        try {
            setChecking(true);
            const status = await getCompanyCheckCloudProviderConnectivity({ cloudProviderId: azureSubscription.Id });
            const statuses = status.flatMap((s) => s.Connections ?? []);
            const connectedCt = statuses.filter((s) => !!s.IsConnected).length;
            result = statuses.length === connectedCt ? 'Healthy' : connectedCt > 0 ? 'Unhealthy' : 'Not Connected';
        } catch {
            failed = true;
        } finally {
            if (failed) {
                result = 'Failed';
            }
            setChecking(false);
        }
        azureSubscription.Status = result;
        setConnected(result);
    }, [azureSubscription.Id]);

    return (
        <>
            {checking ? (
                <Group sx={{ height: '100%' }} noWrap>
                    <Loader size="xs" />
                    <Text color="dimmed">Testing...</Text>
                </Group>
            ) : connected === undefined ? (
                <Anchor onClick={check}>{checking ? <Loader /> : null}Test Connection</Anchor>
            ) : connected === 'Healthy' ? (
                <Badge color="success">{connected}</Badge>
            ) : connected === 'Unhealthy' ? (
                <Tooltip withinPortal label="Connection test partially passed">
                    <Badge color="warning">{connected}</Badge>
                </Tooltip>
            ) : connected === 'Not Connected' ? (
                <Badge color="error">{connected}</Badge>
            ) : (
                <Tooltip withinPortal label="Error while testing connection">
                    <Badge color="error">{connected}</Badge>
                </Tooltip>
            )}
        </>
    );
}

import styled from '@emotion/styled';
import { Button, CSSObject, MantineTheme, Sx, UnstyledButton, useMantineTheme } from '@mantine/core';
import { ForwardedRef, forwardRef, ReactNode } from 'react';

export const FilterItem = styled.div<{ state: 'invalid' | 'valid'; corners?: number }>`
    display: inline-flex;
    align-items: center;
    border: solid 1px #0002;
    background: ${(p) => (p.state === 'valid' ? p.theme.white : '#8883')};
    cursor: pointer;
    border-radius: ${(p) => p.corners ?? p.theme.radius.lg}px;
    padding: 0 0.5rem;
    height: 30px;
    > div {
        padding: 0.25rem 0.25rem;
    }
    margin: var(--item-margin);
`;

export const FilterToken = styled.div<{ maxWidth?: number }>`
    font-size: ${(p) => p.theme.fontSizes.sm}px;
    max-width: ${(p) => (p.maxWidth === undefined ? undefined : p.maxWidth + 'px')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const FilterOptionsHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const FilterTokenDropdown = styled.div`
    font-size: ${(p) => p.theme.fontSizes.sm}px;
    align-items: center;
    display: flex;
    cursor: pointer;
    > div,
    i {
        padding: 0 0.25rem;
    }
`;

export const FiltersContainer = styled.div<{ align: 'start' | 'end'; mode: 'pill' | 'inline' }>`
    display: ${(p) => (p.mode === 'pill' ? 'flex' : 'block')};
    flex-wrap: wrap;
    justify-content: ${(p) => p.align};
    --item-margin: ${(p) => (p.align === 'start' ? '5px 10px 5px 0' : '5px 0 5px 10px')};
`;

interface CompactOperandProps {
    left?: boolean;
    right?: boolean;
    state?: 'error' | 'open' | 'empty' | 'disabled';
}
export const getCompactOperandStyles = (props: CompactOperandProps & { theme: MantineTheme }) => {
    const { theme, left, right, state } = props;
    const rad = theme.radius.xs + 'px';
    const radCorners = rad + ' ' + rad;
    const leftRad = left ? radCorners : '0 0';
    const rightRad = right ? radCorners : '0 0';
    return {
        borderRadius: leftRad + ' ' + rightRad,
        height: 30,
        lineHeight: '30px',
        padding: '0 0.5rem',
        flex: right ? 1 : '0 0 auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        background: state === 'error' ? theme.colors.error[2] : state === 'disabled' ? theme.colors.gray[2] : theme.colors.gray[3],
        color: state === 'disabled' ? theme.colors.gray[5] : theme.colors.gray[7],

        cursor: state === 'disabled' ? 'not-allowed' : 'pointer',
        fontSize: theme.fontSizes.sm,

        filter: state === 'open' ? 'brightness(1.05)' : '',
        ['&:hover, &:focus-within']: {
            filter: state === 'disabled' ? '' : 'brightness(1.05)',
            color: theme.colors.gray[9],
        },
    } as Sx;
};

export const getCompactValueInputPickerSx = (sx: Sx, inputType?: string) => {
    let inputSx = {
        border: 'none',
        backgroundColor: 'unset',
        padding: 0,
        lineHeight: 30,
        maxHeight: 30,
        minHeight: 30,
        height: 30,
    } as CSSObject;

    if (inputType === 'date') {
        inputSx = {
            ...inputSx,
            marginLeft: 36,
            width: 'calc(100% - 36px)',
            color: 'inherit',
        };
    }
    if (inputType === 'number') {
        inputSx = {
            ...inputSx,
            marginLeft: 36,
            width: 'calc(100% - 36px)',
            color: 'inherit',
        };
    }

    return {
        ...sx,
        ['input']: inputSx,
    } as Sx;
};

export const LineItemCompactRow = styled.div<{ components: number }>`
    display: grid;
    grid-template-columns: ${(p) =>
            p.components === 2
                ? 'minmax(50px, max-content) 1fr'
                : p.components === 3
                ? 'minmax(50px, max-content) minmax(25px, max-content) minmax(50%, 1fr)'
                : '1fr'} 30px;
    grid-gap: 3px;
    margin: 4px 0;
`;

interface LineItemCompactTokenProps extends CompactOperandProps {
    onClick?: () => void;
    children: ReactNode;
    styles?: CSSObject;
}
export const LineItemCompactToken = forwardRef((props: LineItemCompactTokenProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { onClick, children, styles } = props;
    const theme = useMantineTheme();
    return (
        <UnstyledButton ref={ref} styles={styles} onClick={onClick} sx={getCompactOperandStyles({ theme, ...props })}>
            {children}
        </UnstyledButton>
    );
});

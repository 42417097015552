import { BaseAwsResource, BaseResource } from '@apis/Resources/model';
import { Table, Text } from '@mantine/core';
import { NoDataMessage, ResourceDetailSection } from '../Design';

interface AwsRoute53Resource extends BaseAwsResource {
    ResourceRecordSets: {
        Name: string;
        Type: { Value: String };
        ResourceRecords: { Value: string }[];
    }[];
}
export function AwsRoute53Records({ resource }: { resource: BaseResource }) {
    const rte53 = resource as AwsRoute53Resource;

    return (
        <ResourceDetailSection>
            {!rte53.ResourceRecordSets?.length ? (
                <NoDataMessage>No Records Found</NoDataMessage>
            ) : (
                <Table verticalSpacing="sm" horizontalSpacing="lg">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Values</th>
                            <th>Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rte53.ResourceRecordSets.map((val, idx) => (
                            <>
                                <tr key={idx}>
                                    <td>{val.Type.Value}</td>
                                    <td>
                                        {val.ResourceRecords.map((rec, ii) => (
                                            <Text size="sm" key={ii}>
                                                {rec.Value}
                                            </Text>
                                        ))}
                                    </td>
                                    <td>{val.Name}</td>
                                </tr>
                            </>
                        ))}
                    </tbody>
                </Table>
            )}
        </ResourceDetailSection>
    );
}

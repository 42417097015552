import { getAccountGetCompanyAccounts, getUserGetCompanyUsers } from '@apis/Customers';
import { Account, Company, UserListItem } from '@apis/Customers/model';
import { postJobCompanyQuery } from '@apis/Jobs';
import { AnonymousQueueJobJob, IQueryExpr } from '@apis/Jobs/model';
import { AnonymousJob, Job, QueryExpr } from '@apis/Resources';
import { DataGridState } from '@root/Components/DataGrid/Models';
import { ICompanyContextToken } from '@root/Services/Customers/CompanyContext';
import { EventEmitter } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { JobHierarchyProgress, JobService, StatusInfo } from '@root/Services/Jobs/JobService';
import { IFluentOperators, IFluentQueryAdapter, queryBuilder } from '@root/Services/QueryExpr';
import { inject, injectable } from 'tsyringe';

@injectable()
export class ActivityDetailsService {
    public constructor(
        @inject(JobService) private readonly jobService: JobService,
        @inject(FormatService) private readonly formatSvc: FormatService
    ) {}

    public getUsers() {
        return getUserGetCompanyUsers();
    }
    public getAccounts() {
        return getAccountGetCompanyAccounts();
    }

    public async getLookups() {
        const [users, accounts] = await Promise.all([this.getUsers(), this.getAccounts()]);
        return {
            users: new Map(users.map((u) => [u.Id, u])),
            accounts: new Map(accounts.map((acc) => [acc.Id, acc])),
        };
    }

    public getStatusInfo(status: JobHierarchyProgress) {
        return this.jobService.getStatusInfo(status);
    }

    public getDatasource(hierarchyId: string, criteria?: (builder: IFluentQueryAdapter<AnonymousQueueJobJob>) => IFluentOperators<boolean>) {
        return {
            async getPage(start: number, end: number, state: DataGridState) {
                let query = queryBuilder<Job>()
                    .where((b) => {
                        const where = [b.model.HierarchyId!.eq(hierarchyId)];
                        if (criteria) {
                            where.push(criteria(b));
                        }
                        return where.length > 1 ? b.and(...where) : where[0];
                    })
                    .build();

                const response = await postJobCompanyQuery({
                    IncludeCount: true,
                    Where: state.filters.length > 0 ? [{ Operation: 'and', Operands: [...state.filters, query.Where] }] : query.Where,
                    Sort: state.sort,
                    Take: end - start,
                    Skip: start,
                });

                return { items: response.Results ?? [], total: response.Count ?? 0 };
            },
        };
    }

    public async getJobTime(hierarchyId: string) {
        const results = await queryBuilder<Job>()
            .where((b) => b.model.HierarchyId!.eq(hierarchyId))
            .select((b) => ({
                end: b.max(b.model.FinishedAt!) as unknown as number | undefined | null,
                start: b.min(b.model.QueuedAt!) as unknown as number | undefined | null,
            }))
            .execute(postJobCompanyQuery);
        const result = results?.Results?.[0] ?? { end: null, start: null };
        return {
            start: this.formatSvc.toLocal(result.start),
            end: this.formatSvc.toLocal(result.end),
        };
    }
}

@injectable()
export class ActivityDetailsModel {
    public loading = new EventEmitter(true);
    public requestedBy?: UserListItem;
    public accountLookup = new Map<number | undefined, Account>();
    public userLookup = new Map<number | undefined, UserListItem>();
    public jobTime = { start: '', end: '' };
    public job?: AnonymousJob;
    public statusCriteria?: (builder: IFluentQueryAdapter<AnonymousQueueJobJob>) => IFluentOperators<boolean>;
    public progress?: JobHierarchyProgress;
    public statusInfo?: StatusInfo;

    public constructor(
        @inject(ActivityDetailsService) public readonly activitySvc: ActivityDetailsService,
        @inject(JobService) public readonly jobService: JobService
    ) {}

    public init(job: AnonymousJob, statusCriteria?: (builder: IFluentQueryAdapter<AnonymousQueueJobJob>) => IFluentOperators<boolean>) {
        this.job = job;
        this.statusCriteria = statusCriteria;
        this.reload();
    }

    public async reload() {
        this.loading.emit(true);
        try {
            const [{ users, accounts }, jobTime, progress] = await Promise.all([
                this.activitySvc.getLookups(),
                this.activitySvc.getJobTime(this.job!.HierarchyId!),
                this.jobService.getHierarchyProgress(this.job!.HierarchyId!, this.statusCriteria),
            ]);
            this.userLookup = users;
            this.accountLookup = accounts;
            this.requestedBy = users.get(this.job?.RequestedById ?? 0);
            this.jobTime = jobTime;
            this.progress = progress;
            this.statusInfo = progress ? this.activitySvc.getStatusInfo(progress) : undefined;
            await this.afterReload();
        } finally {
            this.loading.emit(false);
        }
    }

    protected async afterReload() {}

    public getDatasource() {
        return this.activitySvc.getDatasource(this.job!.HierarchyId!, this.statusCriteria);
    }

    public getRequestedBy() {
        return !this.job?.RequestedById || !this.requestedBy
            ? 'System'
            : this.requestedBy.FirstName
            ? `${this.requestedBy.FirstName} ${this.requestedBy.LastName}`
            : this.requestedBy.EMail;
    }
}

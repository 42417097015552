import { Text, Collapse, Box, Divider, Group, ActionIcon, Tooltip, Space } from '@mantine/core';
import { postResourceChangedQueryResourceChangeLog, QueryResult } from '@apis/Resources';
import { useCallback, useEffect, useState } from 'react';
import { queryBuilder } from '@root/Services/QueryExpr';
import { BaseResource, ResourceChange } from '@apis/Resources/model';
import { UserListItem } from '@apis/Customers/model';
import { getUserGetCompanyUsers } from '@apis/Customers';
import { useToggle } from '@root/Services/EventEmitter';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { ChevronDown, Refresh } from 'tabler-icons-react';
import { ResourceDetailSection, ResourceHistoryRowEl, ResourceHistoryRowHeaderEl, ResourceHistoryToolbar } from '../Design';
import { useResourceChangeCount } from '../Common/OverviewCommon';

type ResourceHistory = ResourceChange;

export function AzureResourceHistory({ resource }: { resource: BaseResource }) {
    const [api, setResults] = useState<ResourceHistory[]>([]);
    const fmtSvc = useDi(FormatService);
    const [userLookup, setUserLookup] = useState(new Map<number, UserListItem>());
    const changeCt = useResourceChangeCount();
    const [loadedAt, setLoadedAt] = useState<Date>();
    const loadHistory = useCallback(async () => {
        try {
            setLoadedAt(undefined);
            let resourceHistoryQuery = queryBuilder<ResourceChange & { ['ResourceId.Id']: string; ['ResourceId.ResourceType']: string }>()
                .take(1000)
                .where((b) =>
                    b.and(b.model['ResourceId.ResourceType'].eq(resource.ResourceType ?? ''), b.model['ResourceId.Id'].eq(resource.Id ?? ''))
                )
                .sortDesc((b) => b.model.TimeStamp)
                .build();

            const resourceHistory = (await postResourceChangedQueryResourceChangeLog(resourceHistoryQuery)) as QueryResult<ResourceHistory>;
            setResults(
                [...(resourceHistory.Results ?? [])].sort(
                    (a, b) => -1 * (a.TimeStamp ?? '').localeCompare(b.TimeStamp ?? '', undefined, { sensitivity: 'base' })
                )
            );
        } finally {
            setLoadedAt(new Date());
        }
    }, [resource.Id]);

    useEffect(() => {
        getUserGetCompanyUsers()
            .then((users) => {
                setUserLookup(new Map(users.map((u) => [u.Id!, u])));
            })
            .catch();
    }, []);

    useEffect(() => {
        loadHistory();
    }, [changeCt]);

    return (
        <ResourceDetailSection>
            <ResourceHistoryToolbar items={api.length} title="Tag Change History" loadedAt={loadedAt} reload={loadHistory} />
            <div className="resourcesTableContainer">
                {api.length > 0 ? (
                    <>
                        {api.map((item, i) => (
                            <HistoryRow key={i} item={item} userLookup={userLookup} />
                        ))}
                    </>
                ) : (
                    <Text align="center" m="lg">
                        No historical changes found.
                    </Text>
                )}
            </div>
        </ResourceDetailSection>
    );
}

function HistoryRow({ item, userLookup }: { item: ResourceHistory; userLookup: Map<number, UserListItem> }) {
    const fmtSvc = useDi(FormatService);
    const [opened, { toggle }] = useToggle(false);
    const date = new Date(item.TimeStamp ?? '');
    const time = date ? fmtSvc.formatDatetimeNoSecs(date) : 'Unknown';
    const detailType = item.Description ? item.Description.split(':')[0] : '';

    return (
        <Box>
            <ResourceHistoryRowHeaderEl onClick={toggle} state={opened ? 'opened' : 'closed'}>
                <Text size="sm" pl="md">
                    {time}
                </Text>
                <Text size="sm">{detailType}</Text>
                <ChevronDown size={18} />
            </ResourceHistoryRowHeaderEl>
            <ResourceHistoryRowEl state={opened ? 'opened' : 'closed'}>
                <div></div>
                <Collapse in={opened}>
                    <Box py="xs">
                        <ResourceHistoryItemDisplay history={item} users={userLookup} />
                    </Box>
                </Collapse>
                <div></div>
            </ResourceHistoryRowEl>
            <Divider color="gray.3" />
        </Box>
    );
}

function ResourceHistoryItemDisplay({ history, users }: { history: ResourceHistory; users: Map<number, UserListItem> }) {
    const requestedBy = history.RequestedById ? users.get(history.RequestedById) : null;
    const requestedByName = requestedBy
        ? `${requestedBy.FirstName} ${requestedBy.LastName}`
        : typeof history.RequestedById === 'number'
        ? 'System'
        : 'Unknown';
    return (
        <>
            {history.Description} <br />
            Status: {history.Status} <br />
            Automated: {history.Automated ? 'Yes' : 'No'} <br />
            Inline: {history.Inline ? 'Yes' : 'No'} <br />
            Requested By: {requestedByName} <br />
        </>
    );
}

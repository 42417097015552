/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.TagManager.Host.Api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  MapContract,
  MapContractBase,
  DeleteMapContractsDeleteMapContractParams,
  GetMapContractsGetMapContractByIdParams,
  PostMapContractsUploadAgreementDocumentBody,
  PostMapContractsUploadAgreementDocumentParams,
  PostMapContractsGenerateDummySnapshotDataForCompanyContractParams,
  DeleteMapContractsDeleteAgreementDocumentParams,
  GetMapContractsDownloadAgreementDocumentParams,
  MapContractStatus,
  MapMigratedTagFixResult,
  PostMapContractsEnsureMapCostAllocationTagParams,
  MapSupportedService,
  MapQueryBundle,
  GetMapContractsGetMapEligibilityQueriesParams,
  TagAutomationRuleGroup,
  GetMapContractsEnsureRuleGroupParams,
  MapContractReadResponse,
  PostMapContractsExtractDataFromDocumentBody,
  MapManagerStat,
  GetMapContractsGetMapContractMetricsParams,
  MapContractDailySnapShotQueryResult,
  MapContractStatsQuery,
  MapContractRollup,
  PostMapContractsGetMapContractRollupParams,
  TagAutomationRuleDetails,
  GetTagAutomationGetTagAutomationRuleDetailsParams,
  TagAutomationRule,
  GetTagAutomationGetTagAutomationRulesParams,
  GetTagAutomationGetActiveTagAutomationRulesParams,
  PostTagAutomationGetActiveTagAutomationRulesCountForMspChildCompaniesAsync200One,
  PostTagAutomationGetActiveTagAutomationRulesCountForMspChildCompaniesAsync200Two,
  PostTagAutomationGetActiveTagAutomationRulesCountForMspChildCompaniesAsync200Three,
  GetTagAutomationGetTestModeTagAutomationRulesParams,
  GetTagAutomationGetTagAutomationRuleParams,
  ObjectQueryResult,
  Query,
  DeleteTagAutomationRemoveTagAutomationRuleParams,
  GetTagAutomationRuleGroupGetRuleGroupParams,
  PostTagAutomationRuleGroupSaveRuleGroupParams,
  GetTagAutomationRuleGroupGetGroupsParams,
  PostTagHealthRecordTagComplianceScoreParams,
  TagHealthMetric,
  GetTagHealthGetTagHealthMetricsForCurrentMonthParams,
  TagHealthMetricQueryResult,
  PostTagHealthInsertFakeDataParams,
  StringSearchIndexChange,
  TagHistoryItem,
  TagPolicy,
  GetTagManagerGetTagPoliciesParams,
  GetTagManagerGetTagPolicyParams,
  AwsPolicyDetailQueryResult,
  GetTagManagerGetAWSTagPoliciesParams,
  PolicyGrouping,
  PostTagManagerImportAWSPoliciesParams,
  DeleteTagManagerRemoveTagPolicyParams,
  RestrictedTag,
  GetTagManagerGetRestrictedTagsParams,
  GetTagManagerGetRestrictedTagParams,
  DeleteTagManagerRemoveRestrictedTagParams,
  GetTagManagerGetDraftPoliciesParams,
  DeleteTagManagerRemoveDraftPolicyGroupParams,
  PostVariantGroupGetAllTagsForAutocompleteParams,
  VariantGroup,
  GetVariantGroupGetVariantGroupsParams,
  GetVariantGroupGetVariantGroupParams,
  Variant,
  GetVariantGroupGetVariantsForGroupParams,
  GetVariantGroupGetVariantParams,
  VariantResource,
  PostVariantGroupConsolidateVariantGroupParams,
  DeleteVariantGroupDeleteVariantParams,
  DeleteVariantGroupDeleteVariantGroupParams,
  GetVariationDetectorCalculateLevensteinDistanceParams
} from './TagManager/model'
import { request } from '../Services/BasicApi'


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const postMapContractsCreateMapContract = (
    mapContractBase: MapContractBase,
 options?: SecondParameter<typeof request>,
) => {
      return request<MapContract>(
      {url: `/MapContracts/CreateMapContract`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: mapContractBase
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostMapContractsCreateMapContractMutationResult = NonNullable<Awaited<ReturnType<typeof postMapContractsCreateMapContract>>>
    export type PostMapContractsCreateMapContractMutationBody = MapContractBase
    export type PostMapContractsCreateMapContractMutationError = unknown

    export const usePostMapContractsCreateMapContract = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMapContractsCreateMapContract>>, TError,{data: MapContractBase}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMapContractsCreateMapContract>>, {data: MapContractBase}> = (props) => {
          const {data} = props ?? {};

          return  postMapContractsCreateMapContract(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMapContractsCreateMapContract>>, TError, {data: MapContractBase}, TContext>(mutationFn, mutationOptions)
    }
    
export const postMapContractsUpdateMapContract = (
    mapContract: MapContract,
 options?: SecondParameter<typeof request>,
) => {
      return request<MapContract>(
      {url: `/MapContracts/UpdateMapContract`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: mapContract
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostMapContractsUpdateMapContractMutationResult = NonNullable<Awaited<ReturnType<typeof postMapContractsUpdateMapContract>>>
    export type PostMapContractsUpdateMapContractMutationBody = MapContract
    export type PostMapContractsUpdateMapContractMutationError = unknown

    export const usePostMapContractsUpdateMapContract = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMapContractsUpdateMapContract>>, TError,{data: MapContract}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMapContractsUpdateMapContract>>, {data: MapContract}> = (props) => {
          const {data} = props ?? {};

          return  postMapContractsUpdateMapContract(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMapContractsUpdateMapContract>>, TError, {data: MapContract}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteMapContractsDeleteMapContract = (
    params?: DeleteMapContractsDeleteMapContractParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/MapContracts/DeleteMapContract`, method: 'delete',
        params,
    },
      { type: 'TagManager',  ...options});
    }
  


    export type DeleteMapContractsDeleteMapContractMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMapContractsDeleteMapContract>>>
    
    export type DeleteMapContractsDeleteMapContractMutationError = unknown

    export const useDeleteMapContractsDeleteMapContract = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMapContractsDeleteMapContract>>, TError,{params?: DeleteMapContractsDeleteMapContractParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMapContractsDeleteMapContract>>, {params?: DeleteMapContractsDeleteMapContractParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteMapContractsDeleteMapContract(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteMapContractsDeleteMapContract>>, TError, {params?: DeleteMapContractsDeleteMapContractParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getMapContractsGetMapContractById = (
    params?: GetMapContractsGetMapContractByIdParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<MapContract>(
      {url: `/MapContracts/GetMapContractById`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetMapContractsGetMapContractByIdQueryKey = (params?: GetMapContractsGetMapContractByIdParams,) => [`/MapContracts/GetMapContractById`, ...(params ? [params]: [])];

    
export type GetMapContractsGetMapContractByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMapContractsGetMapContractById>>>
export type GetMapContractsGetMapContractByIdQueryError = unknown

export const useGetMapContractsGetMapContractById = <TData = Awaited<ReturnType<typeof getMapContractsGetMapContractById>>, TError = unknown>(
 params?: GetMapContractsGetMapContractByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMapContractsGetMapContractById>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMapContractsGetMapContractByIdQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMapContractsGetMapContractById>>> = ({ signal }) => getMapContractsGetMapContractById(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMapContractsGetMapContractById>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getMapContractsGetMapContracts = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<MapContract[]>(
      {url: `/MapContracts/GetMapContracts`, method: 'get', signal
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetMapContractsGetMapContractsQueryKey = () => [`/MapContracts/GetMapContracts`];

    
export type GetMapContractsGetMapContractsQueryResult = NonNullable<Awaited<ReturnType<typeof getMapContractsGetMapContracts>>>
export type GetMapContractsGetMapContractsQueryError = unknown

export const useGetMapContractsGetMapContracts = <TData = Awaited<ReturnType<typeof getMapContractsGetMapContracts>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMapContractsGetMapContracts>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMapContractsGetMapContractsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMapContractsGetMapContracts>>> = ({ signal }) => getMapContractsGetMapContracts(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMapContractsGetMapContracts>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postMapContractsUploadAgreementDocument = (
    postMapContractsUploadAgreementDocumentBody: PostMapContractsUploadAgreementDocumentBody,
    params?: PostMapContractsUploadAgreementDocumentParams,
 options?: SecondParameter<typeof request>,
) => {const formData = new FormData();
if(postMapContractsUploadAgreementDocumentBody.file !== undefined) {
 formData.append('file', postMapContractsUploadAgreementDocumentBody.file)
 }

      return request<MapContract>(
      {url: `/MapContracts/UploadAgreementDocument`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data'},
       data: formData,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostMapContractsUploadAgreementDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof postMapContractsUploadAgreementDocument>>>
    export type PostMapContractsUploadAgreementDocumentMutationBody = PostMapContractsUploadAgreementDocumentBody
    export type PostMapContractsUploadAgreementDocumentMutationError = unknown

    export const usePostMapContractsUploadAgreementDocument = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMapContractsUploadAgreementDocument>>, TError,{data: PostMapContractsUploadAgreementDocumentBody;params?: PostMapContractsUploadAgreementDocumentParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMapContractsUploadAgreementDocument>>, {data: PostMapContractsUploadAgreementDocumentBody;params?: PostMapContractsUploadAgreementDocumentParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postMapContractsUploadAgreementDocument(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMapContractsUploadAgreementDocument>>, TError, {data: PostMapContractsUploadAgreementDocumentBody;params?: PostMapContractsUploadAgreementDocumentParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postMapContractsGenerateDummySnapshotDataForCompanyContract = (
    params?: PostMapContractsGenerateDummySnapshotDataForCompanyContractParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/MapContracts/GenerateDummySnapshotDataForCompanyContract`, method: 'post',
        params,
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostMapContractsGenerateDummySnapshotDataForCompanyContractMutationResult = NonNullable<Awaited<ReturnType<typeof postMapContractsGenerateDummySnapshotDataForCompanyContract>>>
    
    export type PostMapContractsGenerateDummySnapshotDataForCompanyContractMutationError = unknown

    export const usePostMapContractsGenerateDummySnapshotDataForCompanyContract = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMapContractsGenerateDummySnapshotDataForCompanyContract>>, TError,{params?: PostMapContractsGenerateDummySnapshotDataForCompanyContractParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMapContractsGenerateDummySnapshotDataForCompanyContract>>, {params?: PostMapContractsGenerateDummySnapshotDataForCompanyContractParams}> = (props) => {
          const {params} = props ?? {};

          return  postMapContractsGenerateDummySnapshotDataForCompanyContract(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMapContractsGenerateDummySnapshotDataForCompanyContract>>, TError, {params?: PostMapContractsGenerateDummySnapshotDataForCompanyContractParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteMapContractsDeleteAgreementDocument = (
    params?: DeleteMapContractsDeleteAgreementDocumentParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<MapContract>(
      {url: `/MapContracts/DeleteAgreementDocument`, method: 'delete',
        params,
    },
      { type: 'TagManager',  ...options});
    }
  


    export type DeleteMapContractsDeleteAgreementDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMapContractsDeleteAgreementDocument>>>
    
    export type DeleteMapContractsDeleteAgreementDocumentMutationError = unknown

    export const useDeleteMapContractsDeleteAgreementDocument = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMapContractsDeleteAgreementDocument>>, TError,{params?: DeleteMapContractsDeleteAgreementDocumentParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMapContractsDeleteAgreementDocument>>, {params?: DeleteMapContractsDeleteAgreementDocumentParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteMapContractsDeleteAgreementDocument(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteMapContractsDeleteAgreementDocument>>, TError, {params?: DeleteMapContractsDeleteAgreementDocumentParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getMapContractsDownloadAgreementDocument = (
    params?: GetMapContractsDownloadAgreementDocumentParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<void>(
      {url: `/MapContracts/DownloadAgreementDocument`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetMapContractsDownloadAgreementDocumentQueryKey = (params?: GetMapContractsDownloadAgreementDocumentParams,) => [`/MapContracts/DownloadAgreementDocument`, ...(params ? [params]: [])];

    
export type GetMapContractsDownloadAgreementDocumentQueryResult = NonNullable<Awaited<ReturnType<typeof getMapContractsDownloadAgreementDocument>>>
export type GetMapContractsDownloadAgreementDocumentQueryError = unknown

export const useGetMapContractsDownloadAgreementDocument = <TData = Awaited<ReturnType<typeof getMapContractsDownloadAgreementDocument>>, TError = unknown>(
 params?: GetMapContractsDownloadAgreementDocumentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMapContractsDownloadAgreementDocument>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMapContractsDownloadAgreementDocumentQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMapContractsDownloadAgreementDocument>>> = ({ signal }) => getMapContractsDownloadAgreementDocument(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMapContractsDownloadAgreementDocument>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postMapContractsValidateMapContract = (
    postMapContractsValidateMapContractBody: number[],
 options?: SecondParameter<typeof request>,
) => {
      return request<MapContractStatus[]>(
      {url: `/MapContracts/ValidateMapContract`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: postMapContractsValidateMapContractBody
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostMapContractsValidateMapContractMutationResult = NonNullable<Awaited<ReturnType<typeof postMapContractsValidateMapContract>>>
    export type PostMapContractsValidateMapContractMutationBody = number[]
    export type PostMapContractsValidateMapContractMutationError = unknown

    export const usePostMapContractsValidateMapContract = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMapContractsValidateMapContract>>, TError,{data: number[]}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMapContractsValidateMapContract>>, {data: number[]}> = (props) => {
          const {data} = props ?? {};

          return  postMapContractsValidateMapContract(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMapContractsValidateMapContract>>, TError, {data: number[]}, TContext>(mutationFn, mutationOptions)
    }
    
export const postMapContractsEnsureMapCostAllocationTag = (
    params?: PostMapContractsEnsureMapCostAllocationTagParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<MapMigratedTagFixResult>(
      {url: `/MapContracts/EnsureMapCostAllocationTag`, method: 'post',
        params,
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostMapContractsEnsureMapCostAllocationTagMutationResult = NonNullable<Awaited<ReturnType<typeof postMapContractsEnsureMapCostAllocationTag>>>
    
    export type PostMapContractsEnsureMapCostAllocationTagMutationError = unknown

    export const usePostMapContractsEnsureMapCostAllocationTag = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMapContractsEnsureMapCostAllocationTag>>, TError,{params?: PostMapContractsEnsureMapCostAllocationTagParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMapContractsEnsureMapCostAllocationTag>>, {params?: PostMapContractsEnsureMapCostAllocationTagParams}> = (props) => {
          const {params} = props ?? {};

          return  postMapContractsEnsureMapCostAllocationTag(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMapContractsEnsureMapCostAllocationTag>>, TError, {params?: PostMapContractsEnsureMapCostAllocationTagParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postMapContractsGetMapResourceTypes = (
    
 options?: SecondParameter<typeof request>,
) => {
      return request<MapSupportedService[]>(
      {url: `/MapContracts/GetMapResourceTypes`, method: 'post'
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostMapContractsGetMapResourceTypesMutationResult = NonNullable<Awaited<ReturnType<typeof postMapContractsGetMapResourceTypes>>>
    
    export type PostMapContractsGetMapResourceTypesMutationError = unknown

    export const usePostMapContractsGetMapResourceTypes = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMapContractsGetMapResourceTypes>>, TError,TVariables, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMapContractsGetMapResourceTypes>>, TVariables> = () => {
          ;

          return  postMapContractsGetMapResourceTypes(requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMapContractsGetMapResourceTypes>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const getMapContractsGetMapEligibilityQueries = (
    params?: GetMapContractsGetMapEligibilityQueriesParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<MapQueryBundle[]>(
      {url: `/MapContracts/GetMapEligibilityQueries`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetMapContractsGetMapEligibilityQueriesQueryKey = (params?: GetMapContractsGetMapEligibilityQueriesParams,) => [`/MapContracts/GetMapEligibilityQueries`, ...(params ? [params]: [])];

    
export type GetMapContractsGetMapEligibilityQueriesQueryResult = NonNullable<Awaited<ReturnType<typeof getMapContractsGetMapEligibilityQueries>>>
export type GetMapContractsGetMapEligibilityQueriesQueryError = unknown

export const useGetMapContractsGetMapEligibilityQueries = <TData = Awaited<ReturnType<typeof getMapContractsGetMapEligibilityQueries>>, TError = unknown>(
 params?: GetMapContractsGetMapEligibilityQueriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMapContractsGetMapEligibilityQueries>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMapContractsGetMapEligibilityQueriesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMapContractsGetMapEligibilityQueries>>> = ({ signal }) => getMapContractsGetMapEligibilityQueries(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMapContractsGetMapEligibilityQueries>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getMapContractsEnsureRuleGroup = (
    params?: GetMapContractsEnsureRuleGroupParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<TagAutomationRuleGroup>(
      {url: `/MapContracts/EnsureRuleGroup`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetMapContractsEnsureRuleGroupQueryKey = (params?: GetMapContractsEnsureRuleGroupParams,) => [`/MapContracts/EnsureRuleGroup`, ...(params ? [params]: [])];

    
export type GetMapContractsEnsureRuleGroupQueryResult = NonNullable<Awaited<ReturnType<typeof getMapContractsEnsureRuleGroup>>>
export type GetMapContractsEnsureRuleGroupQueryError = unknown

export const useGetMapContractsEnsureRuleGroup = <TData = Awaited<ReturnType<typeof getMapContractsEnsureRuleGroup>>, TError = unknown>(
 params?: GetMapContractsEnsureRuleGroupParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMapContractsEnsureRuleGroup>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMapContractsEnsureRuleGroupQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMapContractsEnsureRuleGroup>>> = ({ signal }) => getMapContractsEnsureRuleGroup(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMapContractsEnsureRuleGroup>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postMapContractsExtractDataFromDocument = (
    postMapContractsExtractDataFromDocumentBody: PostMapContractsExtractDataFromDocumentBody,
 options?: SecondParameter<typeof request>,
) => {const formData = new FormData();
if(postMapContractsExtractDataFromDocumentBody.file !== undefined) {
 formData.append('file', postMapContractsExtractDataFromDocumentBody.file)
 }

      return request<MapContractReadResponse>(
      {url: `/MapContracts/ExtractDataFromDocument`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data'},
       data: formData
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostMapContractsExtractDataFromDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof postMapContractsExtractDataFromDocument>>>
    export type PostMapContractsExtractDataFromDocumentMutationBody = PostMapContractsExtractDataFromDocumentBody
    export type PostMapContractsExtractDataFromDocumentMutationError = unknown

    export const usePostMapContractsExtractDataFromDocument = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMapContractsExtractDataFromDocument>>, TError,{data: PostMapContractsExtractDataFromDocumentBody}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMapContractsExtractDataFromDocument>>, {data: PostMapContractsExtractDataFromDocumentBody}> = (props) => {
          const {data} = props ?? {};

          return  postMapContractsExtractDataFromDocument(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMapContractsExtractDataFromDocument>>, TError, {data: PostMapContractsExtractDataFromDocumentBody}, TContext>(mutationFn, mutationOptions)
    }
    
export const getMapContractsGetMapContractMetrics = (
    params?: GetMapContractsGetMapContractMetricsParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<MapManagerStat>(
      {url: `/MapContracts/GetMapContractMetrics`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetMapContractsGetMapContractMetricsQueryKey = (params?: GetMapContractsGetMapContractMetricsParams,) => [`/MapContracts/GetMapContractMetrics`, ...(params ? [params]: [])];

    
export type GetMapContractsGetMapContractMetricsQueryResult = NonNullable<Awaited<ReturnType<typeof getMapContractsGetMapContractMetrics>>>
export type GetMapContractsGetMapContractMetricsQueryError = unknown

export const useGetMapContractsGetMapContractMetrics = <TData = Awaited<ReturnType<typeof getMapContractsGetMapContractMetrics>>, TError = unknown>(
 params?: GetMapContractsGetMapContractMetricsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMapContractsGetMapContractMetrics>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMapContractsGetMapContractMetricsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMapContractsGetMapContractMetrics>>> = ({ signal }) => getMapContractsGetMapContractMetrics(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMapContractsGetMapContractMetrics>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postMapContractsQueryContractStats = (
    mapContractStatsQuery: MapContractStatsQuery,
 options?: SecondParameter<typeof request>,
) => {
      return request<MapContractDailySnapShotQueryResult>(
      {url: `/MapContracts/QueryContractStats`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: mapContractStatsQuery
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostMapContractsQueryContractStatsMutationResult = NonNullable<Awaited<ReturnType<typeof postMapContractsQueryContractStats>>>
    export type PostMapContractsQueryContractStatsMutationBody = MapContractStatsQuery
    export type PostMapContractsQueryContractStatsMutationError = unknown

    export const usePostMapContractsQueryContractStats = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMapContractsQueryContractStats>>, TError,{data: MapContractStatsQuery}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMapContractsQueryContractStats>>, {data: MapContractStatsQuery}> = (props) => {
          const {data} = props ?? {};

          return  postMapContractsQueryContractStats(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMapContractsQueryContractStats>>, TError, {data: MapContractStatsQuery}, TContext>(mutationFn, mutationOptions)
    }
    
export const postMapContractsQueryMapContractStats = (
    mapContractStatsQuery: MapContractStatsQuery,
 options?: SecondParameter<typeof request>,
) => {
      return request<MapContractDailySnapShotQueryResult>(
      {url: `/MapContracts/QueryMapContractStats`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: mapContractStatsQuery
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostMapContractsQueryMapContractStatsMutationResult = NonNullable<Awaited<ReturnType<typeof postMapContractsQueryMapContractStats>>>
    export type PostMapContractsQueryMapContractStatsMutationBody = MapContractStatsQuery
    export type PostMapContractsQueryMapContractStatsMutationError = unknown

    export const usePostMapContractsQueryMapContractStats = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMapContractsQueryMapContractStats>>, TError,{data: MapContractStatsQuery}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMapContractsQueryMapContractStats>>, {data: MapContractStatsQuery}> = (props) => {
          const {data} = props ?? {};

          return  postMapContractsQueryMapContractStats(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMapContractsQueryMapContractStats>>, TError, {data: MapContractStatsQuery}, TContext>(mutationFn, mutationOptions)
    }
    
export const postMapContractsGetMapContractRollup = (
    params?: PostMapContractsGetMapContractRollupParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<MapContractRollup[]>(
      {url: `/MapContracts/GetMapContractRollup`, method: 'post',
        params,
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostMapContractsGetMapContractRollupMutationResult = NonNullable<Awaited<ReturnType<typeof postMapContractsGetMapContractRollup>>>
    
    export type PostMapContractsGetMapContractRollupMutationError = unknown

    export const usePostMapContractsGetMapContractRollup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMapContractsGetMapContractRollup>>, TError,{params?: PostMapContractsGetMapContractRollupParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMapContractsGetMapContractRollup>>, {params?: PostMapContractsGetMapContractRollupParams}> = (props) => {
          const {params} = props ?? {};

          return  postMapContractsGetMapContractRollup(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMapContractsGetMapContractRollup>>, TError, {params?: PostMapContractsGetMapContractRollupParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getTagAutomationGetTagAutomationRuleDetails = (
    params?: GetTagAutomationGetTagAutomationRuleDetailsParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<TagAutomationRuleDetails>(
      {url: `/TagAutomation/GetTagAutomationRuleDetails`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetTagAutomationGetTagAutomationRuleDetailsQueryKey = (params?: GetTagAutomationGetTagAutomationRuleDetailsParams,) => [`/TagAutomation/GetTagAutomationRuleDetails`, ...(params ? [params]: [])];

    
export type GetTagAutomationGetTagAutomationRuleDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getTagAutomationGetTagAutomationRuleDetails>>>
export type GetTagAutomationGetTagAutomationRuleDetailsQueryError = unknown

export const useGetTagAutomationGetTagAutomationRuleDetails = <TData = Awaited<ReturnType<typeof getTagAutomationGetTagAutomationRuleDetails>>, TError = unknown>(
 params?: GetTagAutomationGetTagAutomationRuleDetailsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTagAutomationGetTagAutomationRuleDetails>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTagAutomationGetTagAutomationRuleDetailsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagAutomationGetTagAutomationRuleDetails>>> = ({ signal }) => getTagAutomationGetTagAutomationRuleDetails(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTagAutomationGetTagAutomationRuleDetails>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getTagAutomationGetTagAutomationRules = (
    params?: GetTagAutomationGetTagAutomationRulesParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<TagAutomationRule[]>(
      {url: `/TagAutomation/GetTagAutomationRules`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetTagAutomationGetTagAutomationRulesQueryKey = (params?: GetTagAutomationGetTagAutomationRulesParams,) => [`/TagAutomation/GetTagAutomationRules`, ...(params ? [params]: [])];

    
export type GetTagAutomationGetTagAutomationRulesQueryResult = NonNullable<Awaited<ReturnType<typeof getTagAutomationGetTagAutomationRules>>>
export type GetTagAutomationGetTagAutomationRulesQueryError = unknown

export const useGetTagAutomationGetTagAutomationRules = <TData = Awaited<ReturnType<typeof getTagAutomationGetTagAutomationRules>>, TError = unknown>(
 params?: GetTagAutomationGetTagAutomationRulesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTagAutomationGetTagAutomationRules>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTagAutomationGetTagAutomationRulesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagAutomationGetTagAutomationRules>>> = ({ signal }) => getTagAutomationGetTagAutomationRules(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTagAutomationGetTagAutomationRules>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getTagAutomationGetActiveTagAutomationRules = (
    params?: GetTagAutomationGetActiveTagAutomationRulesParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<TagAutomationRule[]>(
      {url: `/TagAutomation/GetActiveTagAutomationRules`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetTagAutomationGetActiveTagAutomationRulesQueryKey = (params?: GetTagAutomationGetActiveTagAutomationRulesParams,) => [`/TagAutomation/GetActiveTagAutomationRules`, ...(params ? [params]: [])];

    
export type GetTagAutomationGetActiveTagAutomationRulesQueryResult = NonNullable<Awaited<ReturnType<typeof getTagAutomationGetActiveTagAutomationRules>>>
export type GetTagAutomationGetActiveTagAutomationRulesQueryError = unknown

export const useGetTagAutomationGetActiveTagAutomationRules = <TData = Awaited<ReturnType<typeof getTagAutomationGetActiveTagAutomationRules>>, TError = unknown>(
 params?: GetTagAutomationGetActiveTagAutomationRulesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTagAutomationGetActiveTagAutomationRules>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTagAutomationGetActiveTagAutomationRulesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagAutomationGetActiveTagAutomationRules>>> = ({ signal }) => getTagAutomationGetActiveTagAutomationRules(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTagAutomationGetActiveTagAutomationRules>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postTagAutomationGetActiveTagAutomationRulesCountForMspChildCompaniesAsync = (
    postTagAutomationGetActiveTagAutomationRulesCountForMspChildCompaniesAsyncBody: number[],
 options?: SecondParameter<typeof request>,
) => {
      return request<PostTagAutomationGetActiveTagAutomationRulesCountForMspChildCompaniesAsync200One | PostTagAutomationGetActiveTagAutomationRulesCountForMspChildCompaniesAsync200Two | PostTagAutomationGetActiveTagAutomationRulesCountForMspChildCompaniesAsync200Three>(
      {url: `/TagAutomation/GetActiveTagAutomationRulesCountForMspChildCompaniesAsync`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: postTagAutomationGetActiveTagAutomationRulesCountForMspChildCompaniesAsyncBody
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostTagAutomationGetActiveTagAutomationRulesCountForMspChildCompaniesAsyncMutationResult = NonNullable<Awaited<ReturnType<typeof postTagAutomationGetActiveTagAutomationRulesCountForMspChildCompaniesAsync>>>
    export type PostTagAutomationGetActiveTagAutomationRulesCountForMspChildCompaniesAsyncMutationBody = number[]
    export type PostTagAutomationGetActiveTagAutomationRulesCountForMspChildCompaniesAsyncMutationError = unknown

    export const usePostTagAutomationGetActiveTagAutomationRulesCountForMspChildCompaniesAsync = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTagAutomationGetActiveTagAutomationRulesCountForMspChildCompaniesAsync>>, TError,{data: number[]}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTagAutomationGetActiveTagAutomationRulesCountForMspChildCompaniesAsync>>, {data: number[]}> = (props) => {
          const {data} = props ?? {};

          return  postTagAutomationGetActiveTagAutomationRulesCountForMspChildCompaniesAsync(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTagAutomationGetActiveTagAutomationRulesCountForMspChildCompaniesAsync>>, TError, {data: number[]}, TContext>(mutationFn, mutationOptions)
    }
    
export const getTagAutomationGetTestModeTagAutomationRules = (
    params?: GetTagAutomationGetTestModeTagAutomationRulesParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<TagAutomationRule[]>(
      {url: `/TagAutomation/GetTestModeTagAutomationRules`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetTagAutomationGetTestModeTagAutomationRulesQueryKey = (params?: GetTagAutomationGetTestModeTagAutomationRulesParams,) => [`/TagAutomation/GetTestModeTagAutomationRules`, ...(params ? [params]: [])];

    
export type GetTagAutomationGetTestModeTagAutomationRulesQueryResult = NonNullable<Awaited<ReturnType<typeof getTagAutomationGetTestModeTagAutomationRules>>>
export type GetTagAutomationGetTestModeTagAutomationRulesQueryError = unknown

export const useGetTagAutomationGetTestModeTagAutomationRules = <TData = Awaited<ReturnType<typeof getTagAutomationGetTestModeTagAutomationRules>>, TError = unknown>(
 params?: GetTagAutomationGetTestModeTagAutomationRulesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTagAutomationGetTestModeTagAutomationRules>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTagAutomationGetTestModeTagAutomationRulesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagAutomationGetTestModeTagAutomationRules>>> = ({ signal }) => getTagAutomationGetTestModeTagAutomationRules(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTagAutomationGetTestModeTagAutomationRules>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getTagAutomationGetTagAutomationRule = (
    params?: GetTagAutomationGetTagAutomationRuleParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<TagAutomationRule>(
      {url: `/TagAutomation/GetTagAutomationRule`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetTagAutomationGetTagAutomationRuleQueryKey = (params?: GetTagAutomationGetTagAutomationRuleParams,) => [`/TagAutomation/GetTagAutomationRule`, ...(params ? [params]: [])];

    
export type GetTagAutomationGetTagAutomationRuleQueryResult = NonNullable<Awaited<ReturnType<typeof getTagAutomationGetTagAutomationRule>>>
export type GetTagAutomationGetTagAutomationRuleQueryError = unknown

export const useGetTagAutomationGetTagAutomationRule = <TData = Awaited<ReturnType<typeof getTagAutomationGetTagAutomationRule>>, TError = unknown>(
 params?: GetTagAutomationGetTagAutomationRuleParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTagAutomationGetTagAutomationRule>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTagAutomationGetTagAutomationRuleQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagAutomationGetTagAutomationRule>>> = ({ signal }) => getTagAutomationGetTagAutomationRule(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTagAutomationGetTagAutomationRule>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postTagAutomationQueryTrace = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult>(
      {url: `/TagAutomation/QueryTrace`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostTagAutomationQueryTraceMutationResult = NonNullable<Awaited<ReturnType<typeof postTagAutomationQueryTrace>>>
    export type PostTagAutomationQueryTraceMutationBody = Query
    export type PostTagAutomationQueryTraceMutationError = unknown

    export const usePostTagAutomationQueryTrace = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTagAutomationQueryTrace>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTagAutomationQueryTrace>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postTagAutomationQueryTrace(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTagAutomationQueryTrace>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const postTagAutomationQueryTraceByRule = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult>(
      {url: `/TagAutomation/QueryTraceByRule`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostTagAutomationQueryTraceByRuleMutationResult = NonNullable<Awaited<ReturnType<typeof postTagAutomationQueryTraceByRule>>>
    export type PostTagAutomationQueryTraceByRuleMutationBody = Query
    export type PostTagAutomationQueryTraceByRuleMutationError = unknown

    export const usePostTagAutomationQueryTraceByRule = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTagAutomationQueryTraceByRule>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTagAutomationQueryTraceByRule>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postTagAutomationQueryTraceByRule(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTagAutomationQueryTraceByRule>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const postTagAutomationCreateTagAutomationRule = (
    tagAutomationRule: TagAutomationRule,
 options?: SecondParameter<typeof request>,
) => {
      return request<TagAutomationRule>(
      {url: `/TagAutomation/CreateTagAutomationRule`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: tagAutomationRule
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostTagAutomationCreateTagAutomationRuleMutationResult = NonNullable<Awaited<ReturnType<typeof postTagAutomationCreateTagAutomationRule>>>
    export type PostTagAutomationCreateTagAutomationRuleMutationBody = TagAutomationRule
    export type PostTagAutomationCreateTagAutomationRuleMutationError = unknown

    export const usePostTagAutomationCreateTagAutomationRule = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTagAutomationCreateTagAutomationRule>>, TError,{data: TagAutomationRule}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTagAutomationCreateTagAutomationRule>>, {data: TagAutomationRule}> = (props) => {
          const {data} = props ?? {};

          return  postTagAutomationCreateTagAutomationRule(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTagAutomationCreateTagAutomationRule>>, TError, {data: TagAutomationRule}, TContext>(mutationFn, mutationOptions)
    }
    
export const putTagAutomationSaveTagAutomationRule = (
    tagAutomationRule: TagAutomationRule,
 options?: SecondParameter<typeof request>,
) => {
      return request<TagAutomationRule>(
      {url: `/TagAutomation/SaveTagAutomationRule`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: tagAutomationRule
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PutTagAutomationSaveTagAutomationRuleMutationResult = NonNullable<Awaited<ReturnType<typeof putTagAutomationSaveTagAutomationRule>>>
    export type PutTagAutomationSaveTagAutomationRuleMutationBody = TagAutomationRule
    export type PutTagAutomationSaveTagAutomationRuleMutationError = unknown

    export const usePutTagAutomationSaveTagAutomationRule = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putTagAutomationSaveTagAutomationRule>>, TError,{data: TagAutomationRule}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putTagAutomationSaveTagAutomationRule>>, {data: TagAutomationRule}> = (props) => {
          const {data} = props ?? {};

          return  putTagAutomationSaveTagAutomationRule(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putTagAutomationSaveTagAutomationRule>>, TError, {data: TagAutomationRule}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteTagAutomationRemoveTagAutomationRule = (
    params?: DeleteTagAutomationRemoveTagAutomationRuleParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/TagAutomation/RemoveTagAutomationRule`, method: 'delete',
        params,
    },
      { type: 'TagManager',  ...options});
    }
  


    export type DeleteTagAutomationRemoveTagAutomationRuleMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTagAutomationRemoveTagAutomationRule>>>
    
    export type DeleteTagAutomationRemoveTagAutomationRuleMutationError = unknown

    export const useDeleteTagAutomationRemoveTagAutomationRule = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTagAutomationRemoveTagAutomationRule>>, TError,{params?: DeleteTagAutomationRemoveTagAutomationRuleParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTagAutomationRemoveTagAutomationRule>>, {params?: DeleteTagAutomationRemoveTagAutomationRuleParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteTagAutomationRemoveTagAutomationRule(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteTagAutomationRemoveTagAutomationRule>>, TError, {params?: DeleteTagAutomationRemoveTagAutomationRuleParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postTagAutomationGetTotalActivityCount = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<number>(
      {url: `/TagAutomation/GetTotalActivityCount`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostTagAutomationGetTotalActivityCountMutationResult = NonNullable<Awaited<ReturnType<typeof postTagAutomationGetTotalActivityCount>>>
    export type PostTagAutomationGetTotalActivityCountMutationBody = Query
    export type PostTagAutomationGetTotalActivityCountMutationError = unknown

    export const usePostTagAutomationGetTotalActivityCount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTagAutomationGetTotalActivityCount>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTagAutomationGetTotalActivityCount>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postTagAutomationGetTotalActivityCount(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTagAutomationGetTotalActivityCount>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const getTagAutomationRuleGroupGetRuleGroup = (
    params?: GetTagAutomationRuleGroupGetRuleGroupParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<TagAutomationRuleGroup>(
      {url: `/TagAutomationRuleGroup/GetRuleGroup`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetTagAutomationRuleGroupGetRuleGroupQueryKey = (params?: GetTagAutomationRuleGroupGetRuleGroupParams,) => [`/TagAutomationRuleGroup/GetRuleGroup`, ...(params ? [params]: [])];

    
export type GetTagAutomationRuleGroupGetRuleGroupQueryResult = NonNullable<Awaited<ReturnType<typeof getTagAutomationRuleGroupGetRuleGroup>>>
export type GetTagAutomationRuleGroupGetRuleGroupQueryError = unknown

export const useGetTagAutomationRuleGroupGetRuleGroup = <TData = Awaited<ReturnType<typeof getTagAutomationRuleGroupGetRuleGroup>>, TError = unknown>(
 params?: GetTagAutomationRuleGroupGetRuleGroupParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTagAutomationRuleGroupGetRuleGroup>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTagAutomationRuleGroupGetRuleGroupQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagAutomationRuleGroupGetRuleGroup>>> = ({ signal }) => getTagAutomationRuleGroupGetRuleGroup(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTagAutomationRuleGroupGetRuleGroup>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postTagAutomationRuleGroupSaveRuleGroup = (
    postTagAutomationRuleGroupSaveRuleGroupBody: number[],
    params?: PostTagAutomationRuleGroupSaveRuleGroupParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<number>(
      {url: `/TagAutomationRuleGroup/SaveRuleGroup`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: postTagAutomationRuleGroupSaveRuleGroupBody,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostTagAutomationRuleGroupSaveRuleGroupMutationResult = NonNullable<Awaited<ReturnType<typeof postTagAutomationRuleGroupSaveRuleGroup>>>
    export type PostTagAutomationRuleGroupSaveRuleGroupMutationBody = number[]
    export type PostTagAutomationRuleGroupSaveRuleGroupMutationError = unknown

    export const usePostTagAutomationRuleGroupSaveRuleGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTagAutomationRuleGroupSaveRuleGroup>>, TError,{data: number[];params?: PostTagAutomationRuleGroupSaveRuleGroupParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTagAutomationRuleGroupSaveRuleGroup>>, {data: number[];params?: PostTagAutomationRuleGroupSaveRuleGroupParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postTagAutomationRuleGroupSaveRuleGroup(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTagAutomationRuleGroupSaveRuleGroup>>, TError, {data: number[];params?: PostTagAutomationRuleGroupSaveRuleGroupParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getTagAutomationRuleGroupGetGroups = (
    params?: GetTagAutomationRuleGroupGetGroupsParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<TagAutomationRuleGroup[]>(
      {url: `/TagAutomationRuleGroup/GetGroups`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetTagAutomationRuleGroupGetGroupsQueryKey = (params?: GetTagAutomationRuleGroupGetGroupsParams,) => [`/TagAutomationRuleGroup/GetGroups`, ...(params ? [params]: [])];

    
export type GetTagAutomationRuleGroupGetGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof getTagAutomationRuleGroupGetGroups>>>
export type GetTagAutomationRuleGroupGetGroupsQueryError = unknown

export const useGetTagAutomationRuleGroupGetGroups = <TData = Awaited<ReturnType<typeof getTagAutomationRuleGroupGetGroups>>, TError = unknown>(
 params?: GetTagAutomationRuleGroupGetGroupsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTagAutomationRuleGroupGetGroups>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTagAutomationRuleGroupGetGroupsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagAutomationRuleGroupGetGroups>>> = ({ signal }) => getTagAutomationRuleGroupGetGroups(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTagAutomationRuleGroupGetGroups>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postTagHealthRecordTagComplianceScore = (
    params?: PostTagHealthRecordTagComplianceScoreParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/TagHealth/RecordTagComplianceScore`, method: 'post',
        params,
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostTagHealthRecordTagComplianceScoreMutationResult = NonNullable<Awaited<ReturnType<typeof postTagHealthRecordTagComplianceScore>>>
    
    export type PostTagHealthRecordTagComplianceScoreMutationError = unknown

    export const usePostTagHealthRecordTagComplianceScore = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTagHealthRecordTagComplianceScore>>, TError,{params?: PostTagHealthRecordTagComplianceScoreParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTagHealthRecordTagComplianceScore>>, {params?: PostTagHealthRecordTagComplianceScoreParams}> = (props) => {
          const {params} = props ?? {};

          return  postTagHealthRecordTagComplianceScore(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTagHealthRecordTagComplianceScore>>, TError, {params?: PostTagHealthRecordTagComplianceScoreParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getTagHealthGetTagHealthMetricsForCurrentMonth = (
    params?: GetTagHealthGetTagHealthMetricsForCurrentMonthParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<TagHealthMetric[]>(
      {url: `/TagHealth/GetTagHealthMetricsForCurrentMonth`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetTagHealthGetTagHealthMetricsForCurrentMonthQueryKey = (params?: GetTagHealthGetTagHealthMetricsForCurrentMonthParams,) => [`/TagHealth/GetTagHealthMetricsForCurrentMonth`, ...(params ? [params]: [])];

    
export type GetTagHealthGetTagHealthMetricsForCurrentMonthQueryResult = NonNullable<Awaited<ReturnType<typeof getTagHealthGetTagHealthMetricsForCurrentMonth>>>
export type GetTagHealthGetTagHealthMetricsForCurrentMonthQueryError = unknown

export const useGetTagHealthGetTagHealthMetricsForCurrentMonth = <TData = Awaited<ReturnType<typeof getTagHealthGetTagHealthMetricsForCurrentMonth>>, TError = unknown>(
 params?: GetTagHealthGetTagHealthMetricsForCurrentMonthParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTagHealthGetTagHealthMetricsForCurrentMonth>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTagHealthGetTagHealthMetricsForCurrentMonthQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagHealthGetTagHealthMetricsForCurrentMonth>>> = ({ signal }) => getTagHealthGetTagHealthMetricsForCurrentMonth(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTagHealthGetTagHealthMetricsForCurrentMonth>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postTagHealthSearch = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<TagHealthMetricQueryResult>(
      {url: `/TagHealth/Search`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostTagHealthSearchMutationResult = NonNullable<Awaited<ReturnType<typeof postTagHealthSearch>>>
    export type PostTagHealthSearchMutationBody = Query
    export type PostTagHealthSearchMutationError = unknown

    export const usePostTagHealthSearch = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTagHealthSearch>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTagHealthSearch>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postTagHealthSearch(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTagHealthSearch>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const postTagHealthGetAvgScoreForQuery = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<number>(
      {url: `/TagHealth/GetAvgScoreForQuery`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostTagHealthGetAvgScoreForQueryMutationResult = NonNullable<Awaited<ReturnType<typeof postTagHealthGetAvgScoreForQuery>>>
    export type PostTagHealthGetAvgScoreForQueryMutationBody = Query
    export type PostTagHealthGetAvgScoreForQueryMutationError = unknown

    export const usePostTagHealthGetAvgScoreForQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTagHealthGetAvgScoreForQuery>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTagHealthGetAvgScoreForQuery>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postTagHealthGetAvgScoreForQuery(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTagHealthGetAvgScoreForQuery>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const postTagHealthInsertFakeData = (
    params?: PostTagHealthInsertFakeDataParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/TagHealth/InsertFakeData`, method: 'post',
        params,
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostTagHealthInsertFakeDataMutationResult = NonNullable<Awaited<ReturnType<typeof postTagHealthInsertFakeData>>>
    
    export type PostTagHealthInsertFakeDataMutationError = unknown

    export const usePostTagHealthInsertFakeData = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTagHealthInsertFakeData>>, TError,{params?: PostTagHealthInsertFakeDataParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTagHealthInsertFakeData>>, {params?: PostTagHealthInsertFakeDataParams}> = (props) => {
          const {params} = props ?? {};

          return  postTagHealthInsertFakeData(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTagHealthInsertFakeData>>, TError, {params?: PostTagHealthInsertFakeDataParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const putTagHistorySaveTagHistory = (
    tagHistoryItem: TagHistoryItem,
 options?: SecondParameter<typeof request>,
) => {
      return request<StringSearchIndexChange[]>(
      {url: `/TagHistory/SaveTagHistory`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: tagHistoryItem
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PutTagHistorySaveTagHistoryMutationResult = NonNullable<Awaited<ReturnType<typeof putTagHistorySaveTagHistory>>>
    export type PutTagHistorySaveTagHistoryMutationBody = TagHistoryItem
    export type PutTagHistorySaveTagHistoryMutationError = unknown

    export const usePutTagHistorySaveTagHistory = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putTagHistorySaveTagHistory>>, TError,{data: TagHistoryItem}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putTagHistorySaveTagHistory>>, {data: TagHistoryItem}> = (props) => {
          const {data} = props ?? {};

          return  putTagHistorySaveTagHistory(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putTagHistorySaveTagHistory>>, TError, {data: TagHistoryItem}, TContext>(mutationFn, mutationOptions)
    }
    
export const getTagManagerGetTagPolicies = (
    params?: GetTagManagerGetTagPoliciesParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<TagPolicy[]>(
      {url: `/TagManager/GetTagPolicies`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetTagManagerGetTagPoliciesQueryKey = (params?: GetTagManagerGetTagPoliciesParams,) => [`/TagManager/GetTagPolicies`, ...(params ? [params]: [])];

    
export type GetTagManagerGetTagPoliciesQueryResult = NonNullable<Awaited<ReturnType<typeof getTagManagerGetTagPolicies>>>
export type GetTagManagerGetTagPoliciesQueryError = unknown

export const useGetTagManagerGetTagPolicies = <TData = Awaited<ReturnType<typeof getTagManagerGetTagPolicies>>, TError = unknown>(
 params?: GetTagManagerGetTagPoliciesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTagManagerGetTagPolicies>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTagManagerGetTagPoliciesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagManagerGetTagPolicies>>> = ({ signal }) => getTagManagerGetTagPolicies(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTagManagerGetTagPolicies>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getTagManagerGetTagPolicy = (
    params?: GetTagManagerGetTagPolicyParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<TagPolicy>(
      {url: `/TagManager/GetTagPolicy`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetTagManagerGetTagPolicyQueryKey = (params?: GetTagManagerGetTagPolicyParams,) => [`/TagManager/GetTagPolicy`, ...(params ? [params]: [])];

    
export type GetTagManagerGetTagPolicyQueryResult = NonNullable<Awaited<ReturnType<typeof getTagManagerGetTagPolicy>>>
export type GetTagManagerGetTagPolicyQueryError = unknown

export const useGetTagManagerGetTagPolicy = <TData = Awaited<ReturnType<typeof getTagManagerGetTagPolicy>>, TError = unknown>(
 params?: GetTagManagerGetTagPolicyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTagManagerGetTagPolicy>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTagManagerGetTagPolicyQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagManagerGetTagPolicy>>> = ({ signal }) => getTagManagerGetTagPolicy(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTagManagerGetTagPolicy>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getTagManagerGetAWSTagPolicies = (
    params?: GetTagManagerGetAWSTagPoliciesParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<AwsPolicyDetailQueryResult>(
      {url: `/TagManager/GetAWSTagPolicies`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetTagManagerGetAWSTagPoliciesQueryKey = (params?: GetTagManagerGetAWSTagPoliciesParams,) => [`/TagManager/GetAWSTagPolicies`, ...(params ? [params]: [])];

    
export type GetTagManagerGetAWSTagPoliciesQueryResult = NonNullable<Awaited<ReturnType<typeof getTagManagerGetAWSTagPolicies>>>
export type GetTagManagerGetAWSTagPoliciesQueryError = unknown

export const useGetTagManagerGetAWSTagPolicies = <TData = Awaited<ReturnType<typeof getTagManagerGetAWSTagPolicies>>, TError = unknown>(
 params?: GetTagManagerGetAWSTagPoliciesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTagManagerGetAWSTagPolicies>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTagManagerGetAWSTagPoliciesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagManagerGetAWSTagPolicies>>> = ({ signal }) => getTagManagerGetAWSTagPolicies(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTagManagerGetAWSTagPolicies>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postTagManagerImportAWSPolicies = (
    params?: PostTagManagerImportAWSPoliciesParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<PolicyGrouping[]>(
      {url: `/TagManager/ImportAWSPolicies`, method: 'post',
        params,
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostTagManagerImportAWSPoliciesMutationResult = NonNullable<Awaited<ReturnType<typeof postTagManagerImportAWSPolicies>>>
    
    export type PostTagManagerImportAWSPoliciesMutationError = unknown

    export const usePostTagManagerImportAWSPolicies = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTagManagerImportAWSPolicies>>, TError,{params?: PostTagManagerImportAWSPoliciesParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTagManagerImportAWSPolicies>>, {params?: PostTagManagerImportAWSPoliciesParams}> = (props) => {
          const {params} = props ?? {};

          return  postTagManagerImportAWSPolicies(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTagManagerImportAWSPolicies>>, TError, {params?: PostTagManagerImportAWSPoliciesParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postTagManagerCreateTagPolicy = (
    tagPolicy: TagPolicy,
 options?: SecondParameter<typeof request>,
) => {
      return request<TagPolicy>(
      {url: `/TagManager/CreateTagPolicy`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: tagPolicy
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostTagManagerCreateTagPolicyMutationResult = NonNullable<Awaited<ReturnType<typeof postTagManagerCreateTagPolicy>>>
    export type PostTagManagerCreateTagPolicyMutationBody = TagPolicy
    export type PostTagManagerCreateTagPolicyMutationError = unknown

    export const usePostTagManagerCreateTagPolicy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTagManagerCreateTagPolicy>>, TError,{data: TagPolicy}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTagManagerCreateTagPolicy>>, {data: TagPolicy}> = (props) => {
          const {data} = props ?? {};

          return  postTagManagerCreateTagPolicy(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTagManagerCreateTagPolicy>>, TError, {data: TagPolicy}, TContext>(mutationFn, mutationOptions)
    }
    
export const putTagManagerSaveTagPolicy = (
    tagPolicy: TagPolicy,
 options?: SecondParameter<typeof request>,
) => {
      return request<TagPolicy>(
      {url: `/TagManager/SaveTagPolicy`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: tagPolicy
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PutTagManagerSaveTagPolicyMutationResult = NonNullable<Awaited<ReturnType<typeof putTagManagerSaveTagPolicy>>>
    export type PutTagManagerSaveTagPolicyMutationBody = TagPolicy
    export type PutTagManagerSaveTagPolicyMutationError = unknown

    export const usePutTagManagerSaveTagPolicy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putTagManagerSaveTagPolicy>>, TError,{data: TagPolicy}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putTagManagerSaveTagPolicy>>, {data: TagPolicy}> = (props) => {
          const {data} = props ?? {};

          return  putTagManagerSaveTagPolicy(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putTagManagerSaveTagPolicy>>, TError, {data: TagPolicy}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteTagManagerRemoveTagPolicy = (
    params?: DeleteTagManagerRemoveTagPolicyParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/TagManager/RemoveTagPolicy`, method: 'delete',
        params,
    },
      { type: 'TagManager',  ...options});
    }
  


    export type DeleteTagManagerRemoveTagPolicyMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTagManagerRemoveTagPolicy>>>
    
    export type DeleteTagManagerRemoveTagPolicyMutationError = unknown

    export const useDeleteTagManagerRemoveTagPolicy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTagManagerRemoveTagPolicy>>, TError,{params?: DeleteTagManagerRemoveTagPolicyParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTagManagerRemoveTagPolicy>>, {params?: DeleteTagManagerRemoveTagPolicyParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteTagManagerRemoveTagPolicy(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteTagManagerRemoveTagPolicy>>, TError, {params?: DeleteTagManagerRemoveTagPolicyParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getTagManagerGetRestrictedTags = (
    params?: GetTagManagerGetRestrictedTagsParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<RestrictedTag[]>(
      {url: `/TagManager/GetRestrictedTags`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetTagManagerGetRestrictedTagsQueryKey = (params?: GetTagManagerGetRestrictedTagsParams,) => [`/TagManager/GetRestrictedTags`, ...(params ? [params]: [])];

    
export type GetTagManagerGetRestrictedTagsQueryResult = NonNullable<Awaited<ReturnType<typeof getTagManagerGetRestrictedTags>>>
export type GetTagManagerGetRestrictedTagsQueryError = unknown

export const useGetTagManagerGetRestrictedTags = <TData = Awaited<ReturnType<typeof getTagManagerGetRestrictedTags>>, TError = unknown>(
 params?: GetTagManagerGetRestrictedTagsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTagManagerGetRestrictedTags>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTagManagerGetRestrictedTagsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagManagerGetRestrictedTags>>> = ({ signal }) => getTagManagerGetRestrictedTags(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTagManagerGetRestrictedTags>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getTagManagerGetRestrictedTag = (
    params?: GetTagManagerGetRestrictedTagParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<RestrictedTag>(
      {url: `/TagManager/GetRestrictedTag`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetTagManagerGetRestrictedTagQueryKey = (params?: GetTagManagerGetRestrictedTagParams,) => [`/TagManager/GetRestrictedTag`, ...(params ? [params]: [])];

    
export type GetTagManagerGetRestrictedTagQueryResult = NonNullable<Awaited<ReturnType<typeof getTagManagerGetRestrictedTag>>>
export type GetTagManagerGetRestrictedTagQueryError = unknown

export const useGetTagManagerGetRestrictedTag = <TData = Awaited<ReturnType<typeof getTagManagerGetRestrictedTag>>, TError = unknown>(
 params?: GetTagManagerGetRestrictedTagParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTagManagerGetRestrictedTag>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTagManagerGetRestrictedTagQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagManagerGetRestrictedTag>>> = ({ signal }) => getTagManagerGetRestrictedTag(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTagManagerGetRestrictedTag>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postTagManagerCreateRestrictedTag = (
    restrictedTag: RestrictedTag,
 options?: SecondParameter<typeof request>,
) => {
      return request<RestrictedTag>(
      {url: `/TagManager/CreateRestrictedTag`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: restrictedTag
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostTagManagerCreateRestrictedTagMutationResult = NonNullable<Awaited<ReturnType<typeof postTagManagerCreateRestrictedTag>>>
    export type PostTagManagerCreateRestrictedTagMutationBody = RestrictedTag
    export type PostTagManagerCreateRestrictedTagMutationError = unknown

    export const usePostTagManagerCreateRestrictedTag = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTagManagerCreateRestrictedTag>>, TError,{data: RestrictedTag}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTagManagerCreateRestrictedTag>>, {data: RestrictedTag}> = (props) => {
          const {data} = props ?? {};

          return  postTagManagerCreateRestrictedTag(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTagManagerCreateRestrictedTag>>, TError, {data: RestrictedTag}, TContext>(mutationFn, mutationOptions)
    }
    
export const putTagManagerSaveRestrictedTag = (
    restrictedTag: RestrictedTag,
 options?: SecondParameter<typeof request>,
) => {
      return request<RestrictedTag>(
      {url: `/TagManager/SaveRestrictedTag`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: restrictedTag
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PutTagManagerSaveRestrictedTagMutationResult = NonNullable<Awaited<ReturnType<typeof putTagManagerSaveRestrictedTag>>>
    export type PutTagManagerSaveRestrictedTagMutationBody = RestrictedTag
    export type PutTagManagerSaveRestrictedTagMutationError = unknown

    export const usePutTagManagerSaveRestrictedTag = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putTagManagerSaveRestrictedTag>>, TError,{data: RestrictedTag}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putTagManagerSaveRestrictedTag>>, {data: RestrictedTag}> = (props) => {
          const {data} = props ?? {};

          return  putTagManagerSaveRestrictedTag(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putTagManagerSaveRestrictedTag>>, TError, {data: RestrictedTag}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteTagManagerRemoveRestrictedTag = (
    params?: DeleteTagManagerRemoveRestrictedTagParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/TagManager/RemoveRestrictedTag`, method: 'delete',
        params,
    },
      { type: 'TagManager',  ...options});
    }
  


    export type DeleteTagManagerRemoveRestrictedTagMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTagManagerRemoveRestrictedTag>>>
    
    export type DeleteTagManagerRemoveRestrictedTagMutationError = unknown

    export const useDeleteTagManagerRemoveRestrictedTag = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTagManagerRemoveRestrictedTag>>, TError,{params?: DeleteTagManagerRemoveRestrictedTagParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTagManagerRemoveRestrictedTag>>, {params?: DeleteTagManagerRemoveRestrictedTagParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteTagManagerRemoveRestrictedTag(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteTagManagerRemoveRestrictedTag>>, TError, {params?: DeleteTagManagerRemoveRestrictedTagParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getTagManagerGetDraftPolicies = (
    params?: GetTagManagerGetDraftPoliciesParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<PolicyGrouping[]>(
      {url: `/TagManager/GetDraftPolicies`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetTagManagerGetDraftPoliciesQueryKey = (params?: GetTagManagerGetDraftPoliciesParams,) => [`/TagManager/GetDraftPolicies`, ...(params ? [params]: [])];

    
export type GetTagManagerGetDraftPoliciesQueryResult = NonNullable<Awaited<ReturnType<typeof getTagManagerGetDraftPolicies>>>
export type GetTagManagerGetDraftPoliciesQueryError = unknown

export const useGetTagManagerGetDraftPolicies = <TData = Awaited<ReturnType<typeof getTagManagerGetDraftPolicies>>, TError = unknown>(
 params?: GetTagManagerGetDraftPoliciesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTagManagerGetDraftPolicies>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTagManagerGetDraftPoliciesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagManagerGetDraftPolicies>>> = ({ signal }) => getTagManagerGetDraftPolicies(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTagManagerGetDraftPolicies>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postTagManagerCreateDraftPolicy = (
    policyGrouping: PolicyGrouping,
 options?: SecondParameter<typeof request>,
) => {
      return request<PolicyGrouping>(
      {url: `/TagManager/CreateDraftPolicy`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: policyGrouping
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostTagManagerCreateDraftPolicyMutationResult = NonNullable<Awaited<ReturnType<typeof postTagManagerCreateDraftPolicy>>>
    export type PostTagManagerCreateDraftPolicyMutationBody = PolicyGrouping
    export type PostTagManagerCreateDraftPolicyMutationError = unknown

    export const usePostTagManagerCreateDraftPolicy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTagManagerCreateDraftPolicy>>, TError,{data: PolicyGrouping}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTagManagerCreateDraftPolicy>>, {data: PolicyGrouping}> = (props) => {
          const {data} = props ?? {};

          return  postTagManagerCreateDraftPolicy(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTagManagerCreateDraftPolicy>>, TError, {data: PolicyGrouping}, TContext>(mutationFn, mutationOptions)
    }
    
export const putTagManagerSaveDraftPolicy = (
    policyGrouping: PolicyGrouping,
 options?: SecondParameter<typeof request>,
) => {
      return request<PolicyGrouping>(
      {url: `/TagManager/SaveDraftPolicy`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: policyGrouping
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PutTagManagerSaveDraftPolicyMutationResult = NonNullable<Awaited<ReturnType<typeof putTagManagerSaveDraftPolicy>>>
    export type PutTagManagerSaveDraftPolicyMutationBody = PolicyGrouping
    export type PutTagManagerSaveDraftPolicyMutationError = unknown

    export const usePutTagManagerSaveDraftPolicy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putTagManagerSaveDraftPolicy>>, TError,{data: PolicyGrouping}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putTagManagerSaveDraftPolicy>>, {data: PolicyGrouping}> = (props) => {
          const {data} = props ?? {};

          return  putTagManagerSaveDraftPolicy(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putTagManagerSaveDraftPolicy>>, TError, {data: PolicyGrouping}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteTagManagerRemoveDraftPolicyGroup = (
    params?: DeleteTagManagerRemoveDraftPolicyGroupParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/TagManager/RemoveDraftPolicyGroup`, method: 'delete',
        params,
    },
      { type: 'TagManager',  ...options});
    }
  


    export type DeleteTagManagerRemoveDraftPolicyGroupMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTagManagerRemoveDraftPolicyGroup>>>
    
    export type DeleteTagManagerRemoveDraftPolicyGroupMutationError = unknown

    export const useDeleteTagManagerRemoveDraftPolicyGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTagManagerRemoveDraftPolicyGroup>>, TError,{params?: DeleteTagManagerRemoveDraftPolicyGroupParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTagManagerRemoveDraftPolicyGroup>>, {params?: DeleteTagManagerRemoveDraftPolicyGroupParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteTagManagerRemoveDraftPolicyGroup(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteTagManagerRemoveDraftPolicyGroup>>, TError, {params?: DeleteTagManagerRemoveDraftPolicyGroupParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postVariantGroupGetAllTagsForAutocomplete = (
    params?: PostVariantGroupGetAllTagsForAutocompleteParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<string[]>(
      {url: `/VariantGroup/GetAllTagsForAutocomplete`, method: 'post',
        params,
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostVariantGroupGetAllTagsForAutocompleteMutationResult = NonNullable<Awaited<ReturnType<typeof postVariantGroupGetAllTagsForAutocomplete>>>
    
    export type PostVariantGroupGetAllTagsForAutocompleteMutationError = unknown

    export const usePostVariantGroupGetAllTagsForAutocomplete = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postVariantGroupGetAllTagsForAutocomplete>>, TError,{params?: PostVariantGroupGetAllTagsForAutocompleteParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postVariantGroupGetAllTagsForAutocomplete>>, {params?: PostVariantGroupGetAllTagsForAutocompleteParams}> = (props) => {
          const {params} = props ?? {};

          return  postVariantGroupGetAllTagsForAutocomplete(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postVariantGroupGetAllTagsForAutocomplete>>, TError, {params?: PostVariantGroupGetAllTagsForAutocompleteParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getVariantGroupGetVariantGroups = (
    params?: GetVariantGroupGetVariantGroupsParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<VariantGroup[]>(
      {url: `/VariantGroup/GetVariantGroups`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetVariantGroupGetVariantGroupsQueryKey = (params?: GetVariantGroupGetVariantGroupsParams,) => [`/VariantGroup/GetVariantGroups`, ...(params ? [params]: [])];

    
export type GetVariantGroupGetVariantGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof getVariantGroupGetVariantGroups>>>
export type GetVariantGroupGetVariantGroupsQueryError = unknown

export const useGetVariantGroupGetVariantGroups = <TData = Awaited<ReturnType<typeof getVariantGroupGetVariantGroups>>, TError = unknown>(
 params?: GetVariantGroupGetVariantGroupsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getVariantGroupGetVariantGroups>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetVariantGroupGetVariantGroupsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getVariantGroupGetVariantGroups>>> = ({ signal }) => getVariantGroupGetVariantGroups(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getVariantGroupGetVariantGroups>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getVariantGroupGetVariantGroup = (
    params?: GetVariantGroupGetVariantGroupParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<VariantGroup>(
      {url: `/VariantGroup/GetVariantGroup`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetVariantGroupGetVariantGroupQueryKey = (params?: GetVariantGroupGetVariantGroupParams,) => [`/VariantGroup/GetVariantGroup`, ...(params ? [params]: [])];

    
export type GetVariantGroupGetVariantGroupQueryResult = NonNullable<Awaited<ReturnType<typeof getVariantGroupGetVariantGroup>>>
export type GetVariantGroupGetVariantGroupQueryError = unknown

export const useGetVariantGroupGetVariantGroup = <TData = Awaited<ReturnType<typeof getVariantGroupGetVariantGroup>>, TError = unknown>(
 params?: GetVariantGroupGetVariantGroupParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getVariantGroupGetVariantGroup>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetVariantGroupGetVariantGroupQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getVariantGroupGetVariantGroup>>> = ({ signal }) => getVariantGroupGetVariantGroup(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getVariantGroupGetVariantGroup>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getVariantGroupGetVariantsForGroup = (
    params?: GetVariantGroupGetVariantsForGroupParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Variant[]>(
      {url: `/VariantGroup/GetVariantsForGroup`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetVariantGroupGetVariantsForGroupQueryKey = (params?: GetVariantGroupGetVariantsForGroupParams,) => [`/VariantGroup/GetVariantsForGroup`, ...(params ? [params]: [])];

    
export type GetVariantGroupGetVariantsForGroupQueryResult = NonNullable<Awaited<ReturnType<typeof getVariantGroupGetVariantsForGroup>>>
export type GetVariantGroupGetVariantsForGroupQueryError = unknown

export const useGetVariantGroupGetVariantsForGroup = <TData = Awaited<ReturnType<typeof getVariantGroupGetVariantsForGroup>>, TError = unknown>(
 params?: GetVariantGroupGetVariantsForGroupParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getVariantGroupGetVariantsForGroup>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetVariantGroupGetVariantsForGroupQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getVariantGroupGetVariantsForGroup>>> = ({ signal }) => getVariantGroupGetVariantsForGroup(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getVariantGroupGetVariantsForGroup>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getVariantGroupGetVariant = (
    params?: GetVariantGroupGetVariantParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<Variant>(
      {url: `/VariantGroup/GetVariant`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetVariantGroupGetVariantQueryKey = (params?: GetVariantGroupGetVariantParams,) => [`/VariantGroup/GetVariant`, ...(params ? [params]: [])];

    
export type GetVariantGroupGetVariantQueryResult = NonNullable<Awaited<ReturnType<typeof getVariantGroupGetVariant>>>
export type GetVariantGroupGetVariantQueryError = unknown

export const useGetVariantGroupGetVariant = <TData = Awaited<ReturnType<typeof getVariantGroupGetVariant>>, TError = unknown>(
 params?: GetVariantGroupGetVariantParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getVariantGroupGetVariant>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetVariantGroupGetVariantQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getVariantGroupGetVariant>>> = ({ signal }) => getVariantGroupGetVariant(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getVariantGroupGetVariant>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postVariantGroupCreateVariantGroup = (
    variantGroup: VariantGroup,
 options?: SecondParameter<typeof request>,
) => {
      return request<VariantGroup>(
      {url: `/VariantGroup/CreateVariantGroup`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: variantGroup
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostVariantGroupCreateVariantGroupMutationResult = NonNullable<Awaited<ReturnType<typeof postVariantGroupCreateVariantGroup>>>
    export type PostVariantGroupCreateVariantGroupMutationBody = VariantGroup
    export type PostVariantGroupCreateVariantGroupMutationError = unknown

    export const usePostVariantGroupCreateVariantGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postVariantGroupCreateVariantGroup>>, TError,{data: VariantGroup}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postVariantGroupCreateVariantGroup>>, {data: VariantGroup}> = (props) => {
          const {data} = props ?? {};

          return  postVariantGroupCreateVariantGroup(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postVariantGroupCreateVariantGroup>>, TError, {data: VariantGroup}, TContext>(mutationFn, mutationOptions)
    }
    
export const postVariantGroupAddVariantsToVariantGroup = (
    variant: Variant[],
 options?: SecondParameter<typeof request>,
) => {
      return request<VariantGroup>(
      {url: `/VariantGroup/AddVariantsToVariantGroup`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: variant
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostVariantGroupAddVariantsToVariantGroupMutationResult = NonNullable<Awaited<ReturnType<typeof postVariantGroupAddVariantsToVariantGroup>>>
    export type PostVariantGroupAddVariantsToVariantGroupMutationBody = Variant[]
    export type PostVariantGroupAddVariantsToVariantGroupMutationError = unknown

    export const usePostVariantGroupAddVariantsToVariantGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postVariantGroupAddVariantsToVariantGroup>>, TError,{data: Variant[]}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postVariantGroupAddVariantsToVariantGroup>>, {data: Variant[]}> = (props) => {
          const {data} = props ?? {};

          return  postVariantGroupAddVariantsToVariantGroup(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postVariantGroupAddVariantsToVariantGroup>>, TError, {data: Variant[]}, TContext>(mutationFn, mutationOptions)
    }
    
export const postVariantGroupConsolidateVariantGroup = (
    variantResource: VariantResource[],
    params?: PostVariantGroupConsolidateVariantGroupParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/VariantGroup/ConsolidateVariantGroup`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: variantResource,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PostVariantGroupConsolidateVariantGroupMutationResult = NonNullable<Awaited<ReturnType<typeof postVariantGroupConsolidateVariantGroup>>>
    export type PostVariantGroupConsolidateVariantGroupMutationBody = VariantResource[]
    export type PostVariantGroupConsolidateVariantGroupMutationError = unknown

    export const usePostVariantGroupConsolidateVariantGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postVariantGroupConsolidateVariantGroup>>, TError,{data: VariantResource[];params?: PostVariantGroupConsolidateVariantGroupParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postVariantGroupConsolidateVariantGroup>>, {data: VariantResource[];params?: PostVariantGroupConsolidateVariantGroupParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postVariantGroupConsolidateVariantGroup(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postVariantGroupConsolidateVariantGroup>>, TError, {data: VariantResource[];params?: PostVariantGroupConsolidateVariantGroupParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const putVariantGroupUpdateVariantGroup = (
    variantGroup: VariantGroup,
 options?: SecondParameter<typeof request>,
) => {
      return request<VariantGroup>(
      {url: `/VariantGroup/UpdateVariantGroup`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: variantGroup
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PutVariantGroupUpdateVariantGroupMutationResult = NonNullable<Awaited<ReturnType<typeof putVariantGroupUpdateVariantGroup>>>
    export type PutVariantGroupUpdateVariantGroupMutationBody = VariantGroup
    export type PutVariantGroupUpdateVariantGroupMutationError = unknown

    export const usePutVariantGroupUpdateVariantGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putVariantGroupUpdateVariantGroup>>, TError,{data: VariantGroup}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putVariantGroupUpdateVariantGroup>>, {data: VariantGroup}> = (props) => {
          const {data} = props ?? {};

          return  putVariantGroupUpdateVariantGroup(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putVariantGroupUpdateVariantGroup>>, TError, {data: VariantGroup}, TContext>(mutationFn, mutationOptions)
    }
    
export const putVariantGroupUpdateVariantResources = (
    variant: Variant[],
 options?: SecondParameter<typeof request>,
) => {
      return request<boolean>(
      {url: `/VariantGroup/UpdateVariantResources`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: variant
    },
      { type: 'TagManager',  ...options});
    }
  


    export type PutVariantGroupUpdateVariantResourcesMutationResult = NonNullable<Awaited<ReturnType<typeof putVariantGroupUpdateVariantResources>>>
    export type PutVariantGroupUpdateVariantResourcesMutationBody = Variant[]
    export type PutVariantGroupUpdateVariantResourcesMutationError = unknown

    export const usePutVariantGroupUpdateVariantResources = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putVariantGroupUpdateVariantResources>>, TError,{data: Variant[]}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putVariantGroupUpdateVariantResources>>, {data: Variant[]}> = (props) => {
          const {data} = props ?? {};

          return  putVariantGroupUpdateVariantResources(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putVariantGroupUpdateVariantResources>>, TError, {data: Variant[]}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteVariantGroupDeleteVariant = (
    params?: DeleteVariantGroupDeleteVariantParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/VariantGroup/DeleteVariant`, method: 'delete',
        params,
    },
      { type: 'TagManager',  ...options});
    }
  


    export type DeleteVariantGroupDeleteVariantMutationResult = NonNullable<Awaited<ReturnType<typeof deleteVariantGroupDeleteVariant>>>
    
    export type DeleteVariantGroupDeleteVariantMutationError = unknown

    export const useDeleteVariantGroupDeleteVariant = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVariantGroupDeleteVariant>>, TError,{params?: DeleteVariantGroupDeleteVariantParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteVariantGroupDeleteVariant>>, {params?: DeleteVariantGroupDeleteVariantParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteVariantGroupDeleteVariant(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteVariantGroupDeleteVariant>>, TError, {params?: DeleteVariantGroupDeleteVariantParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteVariantGroupDeleteVariantGroup = (
    params?: DeleteVariantGroupDeleteVariantGroupParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/VariantGroup/DeleteVariantGroup`, method: 'delete',
        params,
    },
      { type: 'TagManager',  ...options});
    }
  


    export type DeleteVariantGroupDeleteVariantGroupMutationResult = NonNullable<Awaited<ReturnType<typeof deleteVariantGroupDeleteVariantGroup>>>
    
    export type DeleteVariantGroupDeleteVariantGroupMutationError = unknown

    export const useDeleteVariantGroupDeleteVariantGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVariantGroupDeleteVariantGroup>>, TError,{params?: DeleteVariantGroupDeleteVariantGroupParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteVariantGroupDeleteVariantGroup>>, {params?: DeleteVariantGroupDeleteVariantGroupParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteVariantGroupDeleteVariantGroup(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteVariantGroupDeleteVariantGroup>>, TError, {params?: DeleteVariantGroupDeleteVariantGroupParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getVariationDetectorCalculateLevensteinDistance = (
    params?: GetVariationDetectorCalculateLevensteinDistanceParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<number>(
      {url: `/VariationDetector/CalculateLevensteinDistance`, method: 'get', signal,
        params,
    },
      { type: 'TagManager',  ...options});
    }
  

export const getGetVariationDetectorCalculateLevensteinDistanceQueryKey = (params?: GetVariationDetectorCalculateLevensteinDistanceParams,) => [`/VariationDetector/CalculateLevensteinDistance`, ...(params ? [params]: [])];

    
export type GetVariationDetectorCalculateLevensteinDistanceQueryResult = NonNullable<Awaited<ReturnType<typeof getVariationDetectorCalculateLevensteinDistance>>>
export type GetVariationDetectorCalculateLevensteinDistanceQueryError = unknown

export const useGetVariationDetectorCalculateLevensteinDistance = <TData = Awaited<ReturnType<typeof getVariationDetectorCalculateLevensteinDistance>>, TError = unknown>(
 params?: GetVariationDetectorCalculateLevensteinDistanceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getVariationDetectorCalculateLevensteinDistance>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetVariationDetectorCalculateLevensteinDistanceQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getVariationDetectorCalculateLevensteinDistance>>> = ({ signal }) => getVariationDetectorCalculateLevensteinDistance(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getVariationDetectorCalculateLevensteinDistance>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}



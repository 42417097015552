import { ActionIcon, Anchor, Badge, Button, ScrollArea, Table, Text } from '@mantine/core';
import { BarItem } from '@nivo/bar';
import { useNav } from '@root/Services/NavigationService';
import { makeAutoObservable } from 'mobx';
import { Observer } from 'mobx-react';
import { Component, ElementType, ReactElement, ReactNode } from 'react';

export function InfoListRow({ children }: { children: ReactNode[] }) {
    return (
        <tr>
            {children.map((c, i) => (
                <td key={i}>{c}</td>
            ))}
        </tr>
    );
}

export interface InfoListActionItemProps {
    title: string;
    isAnchor?: boolean;
    disabled?: string;
    onButtonClick?: string | (() => void);
}

export interface InfoListButtonProps {
    title: string;
    isAnchor?: boolean;
    onButtonClick?: () => void;
}

interface InfoListProps {
    columns: ReactNode[];
    children: ReactNode;
}

export function InfoList(props: InfoListProps) {
    return (
        <>
            <ScrollArea>
                <Table sx={{ minWidth: 200, maxWidth: 350, height: 100 }} verticalSpacing="md">
                    <thead>
                        <tr>
                            {props.columns.map((item, i) => (
                                <th key={i}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>{props.children}</tbody>
                </Table>
            </ScrollArea>
        </>
    );
}

import { BaseResource, Ec2, SnapshotEc2 } from '@apis/Resources/model';
import { queryBuilder } from '@root/Services/QueryExpr';
import { useEffect, useState } from 'react';
import { Table } from '@mantine/core';
import { FillerSwitch } from '@root/Design/Filler';
import { useDi } from '@root/Services/DI';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { ResourceDetailSection } from '../Design';
import { FormatService } from '@root/Services/FormatService';

interface AwsEc2Snapshot extends SnapshotEc2 {
    Encrypted?: boolean;
    VolumeSize?: number;
}
export function AwsEc2Snapshots({ resource }: { resource: BaseResource }) {
    const fmtSvc = useDi(FormatService);
    const resourceSvc = useDi(ResourceService);
    const ec2 = resource as Ec2;
    const [snapshots, setSnapshots] = useState<AwsEc2Snapshot[]>();

    useEffect(() => {
        (async () => {
            try {
                const volumeIds = ec2.BlockDeviceMappings?.map((v) => v.Ebs?.VolumeId).filter((v) => v) ?? [];
                const resp = await queryBuilder<AwsEc2Snapshot>()
                    .take(1000)
                    .where((r) => r.and(r.model.ResourceType!.eq('EC2 Snapshot'), r.model['VolumeId']!.eq(volumeIds)))
                    .execute(resourceSvc.query);

                setSnapshots(resp.Results ?? []);
            } catch {
                setSnapshots([]);
            }
        })();
    }, []);

    return (
        <ResourceDetailSection>
            <FillerSwitch loading={snapshots === null} noData={!snapshots?.length} noDataMessage="No Snapshots Found">
                {() => (
                    <Table verticalSpacing="sm" horizontalSpacing="lg">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Size</th>
                                <th>Encrypted</th>
                            </tr>
                        </thead>
                        <tbody>
                            {snapshots!.map((val, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{val.Name}</td>
                                        <td>{fmtSvc.formatDatetimeNoSecs(fmtSvc.toLocalDate(val.CreateDate))}</td>
                                        <td>{val.VolumeSize ?? 0} GB</td>
                                        <td>{val.Encrypted ? 'Yes' : 'No'}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                )}
            </FillerSwitch>
        </ResourceDetailSection>
    );
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactGridLayout, { WidthProvider, Responsive, Layout } from 'react-grid-layout';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { DashboardItemConfig, LayoutItem } from './Models';
import { useIdGen } from '@root/Services/IdGen';
import { useForceUpdate } from '@mantine/hooks';
import {
    DashboardCard,
    DashboardCardContainer,
    DashboardContainer,
    DashboardItem,
    DashboardItemHeader,
    DashboardItemHeaderText,
    DashboardItemMenu,
} from './Design';
import { Card } from '@mantine/core';
import { useEvent } from '@root/Services/EventEmitter';
import { useDi } from '@root/Services/DI';
import { LayoutService } from '@root/Design/Layout';

const ResponsiveGridLayout = Responsive;

export function DashboardLayout<T>({ onLayoutChanged, layout, tileStatic, onElementLoaded }: DashboardLayoutProps<T>) {
    const dragState = useMemo(() => {
        return { dragging: false };
    }, []);
    const layoutSvc = useDi(LayoutService);
    const [width, setWidth] = useState(800);
    const [container, setContainer] = useState<HTMLDivElement | null>();
    const { getId } = useIdGen();
    const setup = useMemo(() => {
        const layoutItemLookup = new Map<string, LayoutItem<T>>();
        const rglLayout: ReactGridLayout.Layout[] = [];

        for (let i = 0; i < layout.length; i++) {
            const item = layout[i];
            const key = getId(item)?.toString()!;
            layoutItemLookup.set(key, item);
            rglLayout.push({
                ...item.layout,
                isDraggable: !item.noMove && !tileStatic,
                isResizable: !item.noResize && !tileStatic,
                i: key,
                maxH: 8,
            });
        }
        return { layout: { lg: rglLayout }, lookup: (key: string) => layoutItemLookup.get(key) };
    }, [layout]);

    const margin = useMemo(() => [24, 24] as [number, number], []);
    const containerPadding = useMemo(() => [0, 0] as [number, number], []);

    const handleLayoutChange = useCallback(
        (rglLayout: ReactGridLayout.Layout[]) => {
            for (const rglItem of rglLayout) {
                const item = setup.lookup(rglItem.i);
                const { x, y, w, h } = rglItem;
                if (item) {
                    Object.assign(item.layout, { x, y, w, h });
                    item.onResize();
                }
            }
            onLayoutChanged(layout);
        },
        [setup, onLayoutChanged]
    );
    const updateWidth = () => {
        const containerWidth = container?.clientWidth;
        if (containerWidth && containerWidth > 0) {
            setWidth(containerWidth);
        }
    };
    useEvent(layoutSvc.windowSizeInvalidated, updateWidth);
    useEffect(() => {
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, [container]);

    const loadElementReference = (layout: LayoutItem<T>, element: HTMLDivElement | null) => {
        if (element) {
            onElementLoaded?.(layout, element);
        }
    };

    const handleDragStart = useCallback(() => (dragState.dragging = true), []);
    const handleDragStop = useCallback(() => (dragState.dragging = false), []);

    return (
        <DashboardContainer ref={setContainer}>
            <ResponsiveGridLayout
                style={{ position: 'relative' }}
                layouts={setup.layout}
                width={width}
                rowHeight={60}
                breakpoint="lg"
                draggableHandle=".dashboard-header-name"
                onLayoutChange={handleLayoutChange}
                onDragStart={handleDragStart}
                onDragStop={handleDragStop}
                margin={margin}
                containerPadding={containerPadding}
            >
                {layout.map((l, i) => (
                    <DashboardItem key={getId(l)} mode="freeform" drag={!tileStatic ? (l.noMove ? 'nomove' : 'move') : 'nomove'}>
                        {l.custom ? (
                            <DashboardContent layout={l} tileStatic={tileStatic} />
                        ) : (
                            <DashboardCard>
                                <DashboardContent layout={l} tileStatic={tileStatic} />
                            </DashboardCard>
                        )}
                        <div ref={(r) => loadElementReference(l, (r?.parentElement as HTMLDivElement) || null)} style={{ display: 'none' }}></div>
                    </DashboardItem>
                ))}
            </ResponsiveGridLayout>
        </DashboardContainer>
    );
}

interface DashboardLayoutProps<T> {
    tileStatic?: boolean;
    onLayoutChanged(layout: LayoutItem<T>[]): void;
    onElementLoaded?(layout: LayoutItem<T>, element: HTMLDivElement): void;
    layout: LayoutItem<T>[];
}

function DashboardContent<T>({ layout, tileStatic }: { layout: LayoutItem<T>; tileStatic?: boolean }) {
    const hasHeader = layout.header || layout.menu;
    useEvent(layout.headerChanged);
    const invalidate = useForceUpdate();
    return (
        <>
            {!hasHeader ? null : (
                <DashboardItemHeader>
                    <div className="dashboard-header-name">
                        {!layout.header ? null : typeof layout.header === 'string' ? (
                            <DashboardItemHeaderText>{layout.header}</DashboardItemHeaderText>
                        ) : (
                            layout.header(layout.layout)
                        )}
                    </div>
                    {layout.menu && !tileStatic ? <DashboardItemMenu>{layout.menu(layout.layout)}</DashboardItemMenu> : null}
                </DashboardItemHeader>
            )}
            {layout.render(layout.layout, invalidate)}
        </>
    );
}

import { BaseResource, Rds, RdsSnapshot } from '@apis/Resources/model';
import { queryBuilder } from '@root/Services/QueryExpr';
import { useEffect, useState } from 'react';
import { Table } from '@mantine/core';
import { FillerSwitch } from '@root/Design/Filler';
import { useDi } from '@root/Services/DI';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { ResourceDetailSection } from '../Design';
import { FormatService } from '@root/Services/FormatService';

interface AwsRdsSnapshot extends RdsSnapshot {
    InstanceCreateTime?: string;
    SnapshotType?: string;
    AllocatedStorage?: number;
}
export function AwsRdsSnapshots({ resource }: { resource: BaseResource }) {
    const resourceSvc = useDi(ResourceService);
    const fmtSvc = useDi(FormatService);
    const rds = resource as Rds;
    const [snapshots, setSnapshots] = useState<AwsRdsSnapshot[]>();

    useEffect(() => {
        (async () => {
            try {
                const resp = await queryBuilder<AwsRdsSnapshot>()
                    .take(1000)
                    .where((r) => r.and(r.model.ResourceType!.eq('RDS Snapshot'), r.model['DbiResourceId']!.eq(rds.DbiResourceId)))
                    .execute(resourceSvc.query);

                setSnapshots(resp.Results ?? []);
            } catch {
                setSnapshots([]);
            }
        })();
    }, []);

    return (
        <ResourceDetailSection>
            <FillerSwitch loading={snapshots === null} noData={!snapshots?.length} noDataMessage="No Snapshots Found">
                {() => (
                    <Table verticalSpacing="sm" horizontalSpacing="lg">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Date Created</th>
                                <th>Size</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {snapshots!.map((val, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{val.Name}</td>
                                        <td>{fmtSvc.formatDatetimeNoSecs(fmtSvc.toLocalDate(val.InstanceCreateTime ?? ''))}</td>
                                        <td>{val.AllocatedStorage ?? 0} GB</td>
                                        <td>{val.SnapshotType}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                )}
            </FillerSwitch>
        </ResourceDetailSection>
    );
}

import { Text } from '@mantine/core';
import { QueryExpr } from '@apis/Resources';
import { Sx } from '@mantine/core';
import { DatumValue } from '@nivo/core';
import { CustomLayer, CustomLayerProps, ResponsiveLine } from '@nivo/line';
import { useTooltip } from '@root/Components/Picker/Flyover';
import { format } from 'date-fns';
import { MouseEventHandler, useCallback, useMemo, useRef } from 'react';

export function useTickSpacing(lineDist: number = 3, textDist: number = 16) {
    return useMemo(
        () => ({
            lastTickX: {
                text: Infinity,
                line: Infinity,
            },
            reset() {
                this.lastTickX = {
                    text: Infinity,
                    line: Infinity,
                };
            },
            next(value: number) {
                const result = { text: false, line: false };
                if (value < this.lastTickX.text || value - this.lastTickX.text > textDist) {
                    result.text = true;
                    this.lastTickX.text = value;
                }
                if (value < this.lastTickX.line || value - this.lastTickX.line > lineDist) {
                    result.line = true;
                    this.lastTickX.line = value;
                }
                return result;
            },
        }),
        []
    );
}

export interface ChartMargin {
    top: number;
    right: number;
    bottom: number;
    left: number;
}
export type ChartTypes = 'bar' | 'pie' | 'gauge' | 'line' | 'kpi' | 'grid' | 'map' | 'list';
export const chartColors = [
    '#009FE1',
    '#00A79D',
    '#2C607B',
    '#5C4B8C',
    '#CA4127',
    '#E06600',
    '#F4A21E',
    '#67BFE5',
    '#5BC4B7',
    '#5496AF',
    '#847EC1',
    '#DD7D76',
    '#ED863A',
    '#FFBE5F',
    '#0076C1',
    '#00686B',
    '#154054',
    '#3F3E6B',
    '#AF2B19',
    '#AA4900',
    '#D38718',
];

export type IPlotExtremes = { minX: string; maxX: string; minY: number; maxY: number; xValues: string[] };
export type IPlotData = { id: string; data: { x: string; y: number; ct: number }[]; color: string; details: { label: string; value: string }[] };
export type IPlotRequest = { criteria: QueryExpr; agg: QueryExpr; key: `Group${number}`; data: { label: string; value: string }[]; color: string };

export function typedFormatter<T>(format: (value: T) => string) {
    return (value: DatumValue) => format(value as unknown as T);
}

// #region Chart Design Components
export type AdjustedCustomLayerProps = Omit<CustomLayerProps, 'xScale' | 'yScale'> & {
    xScale: (value: string | number | Date) => number;
    yScale: (value: string | number | Date) => number;
};

export function crispBorder(): CustomLayer {
    return function (props) {
        const { margin, innerHeight, innerWidth } = props as AdjustedCustomLayerProps;
        const top = -1 - (margin?.top ?? 0);
        const fullHeight = innerHeight + (margin?.top ?? 0) + (margin?.bottom ?? 0);
        return (
            <g data-layer-type="crispEdge">
                <rect
                    shapeRendering="crispEdges"
                    x={-1}
                    y={top}
                    width={2 + innerWidth}
                    height={2 + fullHeight}
                    fill="#fff"
                    stroke="#0004"
                    strokeWidth={0.5}
                />
            </g>
        );
    } as CustomLayer;
}

export function verticalStripes<TX extends DatumValue>(xValues: TX[], stripColor: (value: TX) => string): CustomLayer {
    const rawStripes = xValues.reduce((result, value) => {
        const color = stripColor(value);
        let item = result[result.length - 1];
        if (!item) {
            result.push((item = { x1: value, x2: value, color }));
        }
        result[result.length - 1].x2 = value;
        if (item.color !== color) {
            result.push({ x1: value, x2: value, color });
        }
        return result;
    }, [] as { x1: TX; x2: TX; color: string }[]);

    return function (props) {
        const { xScale, innerHeight } = props as AdjustedCustomLayerProps;

        return (
            <g data-layer-type="verticalStripes">
                {rawStripes.map(({ x1, x2, color }, idx) => {
                    const x1Pos = xScale(x1);
                    const x2Pos = xScale(x2);
                    const width = x2Pos - x1Pos;
                    return <rect key={idx} x={x1Pos} y={0} width={width} height={innerHeight} fill={color} />;
                })}
            </g>
        );
    } as CustomLayer;
}

interface IMissingRangesOptions {
    /** Option 1, provide missing ranges of date date */
    missingRanges?: { from: string; to: string }[];
    /** Option 2, provide plotData to have the missing ranges calculated */
    plotData?: IPlotData[];
    /** When using plotData, pass the function a function for overriding the meaning of "missing" */
    missingAccessor?: (item: { x: string; y: number; ct?: number }) => boolean;
}
export function getMissingRanges(options: IMissingRangesOptions) {
    const { plotData = [], missingAccessor } = options;
    const isMissing = missingAccessor ?? ((item) => ('ct' in item ? item.ct === 0 : item.y === 0));
    const xLookup = plotData.reduce((result, dataset) => {
        const { data } = dataset;
        for (const item of data) {
            result.set(item.x, result.get(item.x) !== false ? isMissing(item) : false);
        }
        return result;
    }, new Map<string, boolean>());
    const sortedX = Array.from(xLookup.keys()).sort();

    const { results } = sortedX.reduce(
        (result, x) => {
            const missing = xLookup.get(x);
            const { curr } = result;
            if (missing) {
                if (!curr) {
                    result.curr = { from: x, to: x };
                    result.results.push(result.curr);
                } else {
                    curr.to = x;
                }
            } else {
                if (curr) {
                    curr.to = x;
                    result.curr = undefined;
                }
            }
            return result;
        },
        { results: [] } as { curr?: { from: string; to: string }; results: { from: string; to: string }[] }
    );

    return results;
}
export function missingRangesLayer(options: IMissingRangesOptions): CustomLayer {
    const { missingRanges: providedRanges } = options;
    const missingRanges = providedRanges ?? getMissingRanges(options);
    return function (props: CustomLayerProps) {
        const { xScale, yScale } = props as AdjustedCustomLayerProps;
        return (
            <g data-layer-type="missingRanges">
                {missingRanges.map((range, i) => {
                    const from = xScale(range.from);
                    const to = xScale(range.to);
                    return <rect key={i} x={from} y={0} width={to - from} height={yScale(0)} fill="#000" opacity={0.05} />;
                })}
            </g>
        );
    };
}
// #endregion

// #region Axis Components
export type AxisChartProps = { innerHeight: number; margin?: { top?: number; bottom?: number } };

export type XDateAxisOptions = {
    minX: string | Date | number;
    maxX: string | Date | number;
    xValues?: (string | Date | number)[];
    position?: 'top' | 'bottom';
    offset?: number;
    height: number;
    lineColor?: string;
};

export function xWeekdaysAxis(options: XDateAxisOptions): CustomLayer {
    const { minX, maxX, lineColor } = options;
    const xValues = options.xValues ?? getDatesInRange(minX, maxX);
    return function (props) {
        const { xScale } = props as AdjustedCustomLayerProps;
        const { topLine, bottomLine, height, yMod, sharpY } = getAxisDimensions(options, props, 10);
        const weekLineHeight = height * 0.75;
        const dayLineHeight = height * 0.35;

        const path = xValues
            .reduce((result, value) => {
                const x = xScale(value);
                const day = (!(value instanceof Date) ? new Date(value) : value).getDay();
                const h = day === 0 ? weekLineHeight : dayLineHeight;
                result.push(`M${x} ${0} v ${h}`);
                return result;
            }, [] as string[])
            .join(' ');
        const color = lineColor ?? '#0004';

        return (
            <g data-layer-type="xWeekdayTicks">
                <g transform={`scale(1, ${yMod}) translate(0, ${sharpY})`}>
                    {!topLine ? null : <line x1={xScale(minX)} y1={0.5} x2={xScale(maxX)} y2={0.5} stroke="#0004" strokeWidth={0.5} />}
                    <path d={path} stroke={color} strokeWidth={0.5} />
                    {!bottomLine ? null : <line x1={xScale(minX)} y1={height} x2={xScale(maxX)} y2={height} stroke="#0004" strokeWidth={0.5} />}
                </g>
            </g>
        );
    };
}

export function getDatesInRange(from: number | string | Date, to: number | string | Date) {
    const minDate = new Date(from);
    const maxDate = new Date(to);
    const result = [];
    for (let date = minDate; date <= maxDate; date.setDate(date.getDate() + 1)) {
        result.push(new Date(date));
    }
    return result;
}

export function getAxisDimensions(options: XDateAxisOptions, chartProps: AxisChartProps, defaultHeight: number) {
    const { innerHeight, margin } = chartProps;
    const { top, bottom } = margin ?? {};
    const { position, offset } = options;
    const pos = position ?? ('bottom' as const);

    const fullHeight = innerHeight + (bottom ?? 0) + (top ?? 0);
    const yOffset = !offset ? 0 : pos === 'top' ? -offset : offset;
    const yDemargin = top ? -top : 0;

    const height = options?.height ?? (pos === 'top' ? top : bottom) ?? defaultHeight;
    const y = position === 'top' ? yDemargin : fullHeight - height - yOffset + yDemargin;
    const yMod = position === 'top' ? -1 : 1;
    const sharpY = y + 0.5 * yMod;

    return { y, height, topLine: pos === 'bottom', bottomLine: pos === 'top', yMod, sharpY };
}

export type XMonthsAxisOptions = XDateAxisOptions & {
    formatter: (value: number) => string;
    onHover?: (info: ChartTooltipInfo[], month: Date, target: Element) => void;
    monthData: IPlotData[];
};
export function xMonthsAxis(options: XMonthsAxisOptions): CustomLayer {
    const { monthData, formatter, onHover, minX, maxX, lineColor } = options;
    const xValues = options.xValues ?? getDatesInRange(minX, maxX);
    const minNamedW = 40;
    const wideNamedW = 100;

    type MonthPlotData = { xm: number; x1: number; x2: number; key: string; date: Date; ct: number };
    const getMonthPlotData = (xScale: AdjustedCustomLayerProps['xScale']) => {
        return xValues.reduce((result, value) => {
            const monthKey = (!(value instanceof Date) ? new Date(value) : value).toISOString().slice(0, 7);
            const x = xScale(value);
            let item = result[result.length - 1];
            const date = new Date(value);
            if (!item) {
                result.push((item = { xm: x, x1: x, x2: x, key: monthKey, date, ct: 1 }));
            }
            item.x2 = x;
            item.xm = item.x1 + (x - item.x1) / 2;
            if (item.key !== monthKey) {
                result.push({ xm: x, x1: x, x2: x, key: monthKey, date, ct: 1 });
            } else {
                item.ct++;
            }

            return result;
        }, [] as MonthPlotData[]);
    };

    type MonthDetails = { color: string; value: string; details: { label: string; value: string }[] };
    type MonthMetricData = Map<string, MonthDetails[]>;
    const getMonthDetails = (): MonthMetricData => {
        return monthData.reduce((result, plotData) => {
            for (const item of plotData.data) {
                const monthKey = item.x.slice(0, 7);
                let monthDetail = result.get(monthKey);
                if (!monthDetail) {
                    result.set(monthKey, (monthDetail = []));
                }
                const value = formatter(item.y);
                monthDetail.push({ color: plotData.color, value, details: plotData.details });
            }
            return result;
        }, new Map<string, MonthDetails[]>());
    };

    const getfullMonthWidth = (plotData: MonthPlotData[]) => {
        const fullMonths = plotData.filter((item) => item.ct >= 28);
        const minFullW = fullMonths.reduce((result, { x1, x2 }) => Math.min(result, x2 - x1), Infinity);
        return !fullMonths.length ? 0 : minFullW;
    };

    function MonthTick(props: { plotData: MonthPlotData; monthData?: MonthDetails[]; height: number; yMid: number; idx: number; fullMoW: number }) {
        const { plotData, yMid, height, monthData, idx, fullMoW } = props;
        const { x1, xm, x2, date } = plotData;
        const handleHover: undefined | MouseEventHandler = !monthData ? undefined : (evt) => onHover?.(monthData!, date, evt.target as Element);

        const color = lineColor ?? '#0004';
        const isFullMo = plotData.ct >= 28;
        const availW = isFullMo ? fullMoW : x2 - x1;
        const nameMode = availW >= wideNamedW ? 'wide' : availW >= minNamedW ? 'narrow' : ('none' as const);

        return (
            <>
                {idx > 0 && <line x1={x1 - 0.5} y1={0} x2={x1 - 0.5} y2={height} stroke={color} strokeWidth={0.5} />}
                <rect onMouseMove={handleHover} x={x1} y={0} width={x2 - x1} height={height} fill="#fff0" />
                {nameMode === 'wide' ? (
                    <text x={xm} y={yMid - 0} fontSize={14} textAnchor="middle" dominantBaseline="middle">
                        {format(date, 'MMM yyyy')}
                    </text>
                ) : nameMode === 'narrow' ? (
                    <>
                        <text x={xm} y={yMid - 8} fontSize={14} textAnchor="middle" dominantBaseline="middle">
                            {format(date, 'MMM')}
                        </text>
                        <text x={xm} y={yMid + 8} fontSize={11} textAnchor="middle" dominantBaseline="middle">
                            {format(date, 'yyyy')}
                        </text>
                    </>
                ) : null}
            </>
        );
    }

    return function (props) {
        const { xScale } = props as AdjustedCustomLayerProps;
        const monthPlotData = getMonthPlotData(xScale);
        const fullMoW = getfullMonthWidth(monthPlotData);
        const monthDetailLookup = getMonthDetails();

        const { y: yTop, bottomLine, topLine, height, sharpY } = getAxisDimensions(options, props, 50);
        const yMid = height / 2;

        return (
            <g data-layer-type="xMonthsAxis">
                <g transform={`translate(0, ${sharpY})`}>
                    {!topLine ? null : <line x1={xScale(minX)} y1={0} x2={xScale(maxX)} y2={0} stroke="#0004" strokeWidth={0.5} />}
                    {monthPlotData.map((plotData, idx) => {
                        const monthData = monthDetailLookup.get(plotData.key);
                        const monthTickProps = { plotData, monthData, height, yTop, yMid, idx, fullMoW };
                        return <MonthTick key={idx} {...monthTickProps} />;
                    })}
                    {!bottomLine ? null : <line x1={xScale(minX)} y1={height} x2={xScale(maxX)} y2={height} stroke="#0004" strokeWidth={0.5} />}
                </g>
            </g>
        );
    };
}

export function yAxisLabel(label: string): CustomLayer {
    return function (props) {
        const { innerHeight, margin } = props as AdjustedCustomLayerProps;
        const availHeight = innerHeight;
        const yMid = availHeight / 2;

        const textSx: Sx = {
            transformOrigin: 'top',
            transform: `translate(-${yMid - 5}px, ${yMid}px) rotate(-90deg)`,
            width: availHeight,
            display: 'inline-block',
        };
        return (
            <g data-layer-type="yAxisLabel">
                <foreignObject x={-(margin?.left ?? 0)} y={0} width="100%" height="100%">
                    <Text align="center" italic size="xs" sx={textSx}>
                        {label}
                    </Text>
                </foreignObject>
            </g>
        );
    };
}

// #endregion

// #region Chart Tooltip Components
export function useSliceTooltip(tooltipRenderer: NonNullable<ResponsiveLine['props']['sliceTooltip']>, tooltipId: string) {
    const pos = useRef({ x: 0, y: 0 });
    const [show, close] = useTooltip({ anchor: ['center-left', 'center-right'], offsetX: 25, delayMs: 20 }, tooltipId);
    const showTooltip = useCallback(
        (tooltipRenderer: () => React.ReactNode, xOffset?: number, yOffset?: number) => {
            show({ renderer: () => tooltipRenderer(), x: pos.current.x + (xOffset ?? 0), y: pos.current.y + (yOffset ?? 0) });
            return <></>;
        },
        [tooltipRenderer, show]
    );
    const onContainerMouseMove: MouseEventHandler = useCallback((evt) => {
        pos.current.x = evt.clientX;
        pos.current.y = evt.clientY;
    }, []);

    const sliceTooltip: NonNullable<ResponsiveLine['props']['sliceTooltip']> = useCallback(
        (props) => {
            const sliceX = props.slice.x0 - props.slice.x;
            showTooltip(() => tooltipRenderer(props), sliceX);
            return <></>;
        },
        [tooltipRenderer, showTooltip]
    );

    return { showTooltip, onContainerMouseMove, onMouseLeave: close, sliceTooltip };
}

export type ChartTooltipInfo = { color: string; value: string; details: { label: string; value: string }[] };
export function minimizeTooltipData(data: ChartTooltipInfo[]) {
    const allDetails = data.flatMap((item) => item.details);
    const fields = allDetails.reduce((result, { label }) => result.add(label), new Set<string>());
    let commonFieldLookup = allDetails.reduce(
        (result, { label, value }) => result.set(label, [value, undefined].includes(result.get(label)!) ? value : null),
        new Map<string, null | string>()
    );
    const distinctFields = Array.from(fields)
        .filter((f) => commonFieldLookup.get(f) === null)
        .sort();
    const rows = data.map((item) => {
        const labelValues = distinctFields.map((f) => item.details.find((d) => d.label === f)?.value ?? '');
        return { color: item.color, value: item.value, labelValues };
    });
    return { headers: distinctFields, rows };
}

// #endregion

import styled from '@emotion/styled';
import { GridFlexCell } from '@root/Components/DataGrid/Design';
import { ReactNode } from 'react';

export function getAssessmentColor(value: number) {
    return value >= 0.99
        ? '#12B76A'
        : value >= 0.81
        ? '#FEDF89'
        : value >= 0.61
        ? '#FDB022'
        : value >= 0.51
        ? '#F79009'
        : value >= 0.26
        ? '#D92D20'
        : '#f44336';
}

export const CardStyle = styled.div`
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.colors.gray[2]};
    filter: drop-shadow(0.5px 0.5px 0.5px rgba(0, 0, 0, 0.25));
`;

export const CardHeader = styled.div`
    font-weight: bold;
`;

export const FooterBox = styled.div`
    color: rgb(255, 255, 255);
    height: 60px;
    background-color: rgb(0, 159, 225);
    border-radius: 5px;
    align-items: center;
    display: flex;
    padding: 0 20px;
`;

export const FooterSavingsCell = styled.div`
    text-align: right;
    font-size: 20px;
    font-weight: bold;
    padding-right: 20px;
`;

export const StyledActionButton = styled.button`
    color: ${({ theme }) => theme.colors?.primary?.[5]};
    background-color: #fff;
    border: 1px solid ${({ theme }) => theme.colors?.primary?.[5]};
    border-radius: 5px;
    margin: 15px 15px;
`;

export function GridNACell({ children }: { children: ReactNode }) {
    return (
        <GridFlexCell>
            <NACell>{children}</NACell>
        </GridFlexCell>
    );
}

export function GridScoreCell({ backgroundColor, children }: { backgroundColor: string; children: ReactNode }) {
    return (
        <GridFlexCell>
            <ScoreCell backgroundColor={backgroundColor}>{children}</ScoreCell>
        </GridFlexCell>
    );
}

export const NACell = styled.div`
    text-align: center;
    padding: 5px;
    margin: 0px auto;
    line-height: 20px;
    position: relative;
`;

export const ScoreCell = styled.div<{ backgroundColor: string }>`
    text-align: center;
    padding: 5px;
    color: #fff;
    border-radius: 10px;
    height: 30px;
    margin: 0px auto;
    width: 60px;
    font-size: 20px;
    line-height: 20px;
    position: relative;
    background-color: ${(p) => p.backgroundColor};
`;

export const SavingsCell = styled.div`
    text-align: right;
    font-size: 20px;
    font-weight: bold;
    margin-right: 10px;
`;

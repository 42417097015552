import { observer } from 'mobx-react';
import { RuleEditCard } from '@root/Site/TagManager/TagAutomation/Components/Design';
import { RuleEditor } from '@root/Site/TagManager/TagAutomation/Components/Model';
import { useDisclosure } from '@mantine/hooks';
import { ValidationIssues } from '@root/Site/TagManager/TagAutomation/Components/FilterResources';
import { MapRuleResourceFilter } from './MapFilterResources';
import { useDi } from '@root/Services/DI';
import { MapContractHomeModel } from '../Models';
import { CardContentLayout } from './Components';
import { Anchor, Card, Checkbox, Loader, Space, Text, useMantineTheme } from '@mantine/core';
import { FormatService } from '@root/Services/FormatService';
import { useMemo } from 'react';
import { useNav } from '@root/Services/NavigationService';
import { useLink } from '@root/Services/Router/Router';

export const MapFilterResourcesCard = observer(function FilterResourcesCard({
    ruleEditor,
    onValidationIssuesChanged,
}: {
    ruleEditor: RuleEditor;
    onValidationIssuesChanged: (issues: ValidationIssues) => void;
}) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const [showingErrors, { open: showErrors }] = useDisclosure(false);
    const homeModel = useDi(MapContractHomeModel);
    const { getDescendUrl } = useNav();

    const previewLinkUrl = useMemo(
        () =>
            getDescendUrl('map-resource-browser', {
                type: 'Eligible Resources',
                filter: ruleEditor.resourceQuery,
                id: homeModel.contract?.Id?.toString() ?? '',
            }),
        [ruleEditor.resourceQuery]
    );
    const link = useLink();
    return (
        <RuleEditCard color="gray" title="Resource Selection" description="Identify which resources this rule applies to" onBlur={showErrors}>
            <MapRuleResourceFilter
                showingErrors={showingErrors}
                schema={homeModel.resourceSchema!}
                ruleEditor={ruleEditor}
                onValidationIssuesChanged={onValidationIssuesChanged}
            />
            <Space h="md" />
            <CardContentLayout>
                <Card sx={{ background: theme.colors.gray[1] }} radius="md">
                    <Text weight="bold">Apply To</Text>
                    <Space h="xs" />
                    <Checkbox
                        checked={ruleEditor.appliesToExisting}
                        onClick={ruleEditor.toggleAppliesToExisting}
                        label="Existing Eligible Resources"
                    />
                    <Space h="xs" />
                    <Checkbox defaultChecked={ruleEditor.appliesToNew} onClick={ruleEditor.toggleAppliesToNew} label="New Resources" />
                </Card>
                {ruleEditor.appliesToExisting && (
                    <Card sx={{ background: theme.colors.gray[0] }} radius="md">
                        <Text size="sm" weight="bold" align="center">
                            Applies to
                        </Text>
                        <Anchor {...link(previewLinkUrl)}>
                            <Text color="primary" weight="bold" size="xl" sx={{ fontSize: '2rem' }} align="center">
                                {!ruleEditor.preview.isCountRefreshing ? fmtSvc.formatInt(ruleEditor.preview.count) : <Loader />}
                            </Text>
                        </Anchor>
                        <Text size="sm" weight="bold" align="center">
                            Existing Eligible Resources
                        </Text>
                    </Card>
                )}
            </CardContentLayout>
        </RuleEditCard>
    );
});

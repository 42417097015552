import { BaseMapResource, MapResourceQueryService } from '../Services/MapResourceQueryService';
import { MapContract } from '@apis/TagManager/model';
import { IStringFluentOperators, queryBuilder } from '@root/Services/QueryExpr';
import { ContractStats } from './MapContractsList';
import { postResourcesQuery } from '@apis/Resources';
import { ListData } from '../Management/Dashboard/MapDashboardCustomizable';
import { getAccountGetAccountConnectionStatus, getAccountGetRelatedAccounts } from '@apis/Customers';
import { Account } from '@apis/Customers/model';

export interface ActionRequiredModel {
    name: string;
    count: number;
}

export class MapContractModel {
    public contractQuerySvc: MapResourceQueryService;
    public contractDetails: MapContract;
    public stats?: ContractStats;
    private aRValues: ActionRequiredModel[] | undefined;
    private keyValues: ListData[] | undefined;
    private top5Resources: ListData[] | undefined;
    private top5Owners: ListData[] | undefined;
    private accounts: Account[] | undefined;

    public async actionsRequired() {
        this.aRValues = this.aRValues ?? (this.aRValues = await this.getActionsRequiredData());
        return this.aRValues;
    }

    public async keyValuesApplied() {
        this.keyValues = this.keyValues ?? (this.keyValues = await this.getKeyValuesAppliedData());
        return this.keyValues;
    }

    public async top5ResourcesData() {
        this.top5Resources = this.top5Resources ?? (this.top5Resources = await this.getTop5ResourcesData());
        return this.top5Resources;
    }

    public async top5OwnersData() {
        this.top5Owners = this.top5Owners ?? (this.top5Owners = await this.getTop5OwnersData());
        return this.top5Owners;
    }

    public async getAccounts() {
        this.accounts = this.accounts ?? (this.accounts = await this.loadAccounts());
        return this.accounts;
    }

    public constructor(contract: MapContract, contractQuerySvc: MapResourceQueryService) {
        this.contractDetails = contract;
        this.contractQuerySvc = contractQuerySvc;
    }

    public isActive() {
        return this.contractQuerySvc ? this.contractQuerySvc.isActive : false;
    }

    public getStatsQuery() {
        if (this.contractDetails === undefined || this.contractQuerySvc === undefined) {
            return [];
        }

        return [
            queryBuilder<BaseMapResource>()
                .where((b) => b.and(b.model.ManagementAccount.eq(this.contractDetails!.AccountIds), this.contractQuerySvc!.getCorrectlyTaggedQuery()))
                .select((b) => ({
                    [`covered${this.contractDetails!.Id}`]: b.count(),
                    [`coveredValue${this.contractDetails!.Id}`]: b.sum(b.model.Last30DaysCost as unknown as number),
                }))
                .build(),

            queryBuilder<BaseMapResource>()
                .where((b) => b.model.ManagementAccount.eq(this.contractDetails!.AccountIds))
                .select((b) => ({
                    [`undecided${this.contractDetails!.Id}`]: b.countIf(this.contractQuerySvc!.getUndecidedQuery()),
                }))
                .build(),

            queryBuilder<BaseMapResource>()
                .where((b) => b.model.ManagementAccount.eq(this.contractDetails!.AccountIds))
                .select((b) => ({
                    [`incorrectlyCovered${this.contractDetails!.Id!}`]: b.countIf(this.contractQuerySvc!.getIncorrectlyTaggedQuery()),
                }))
                .build(),

            queryBuilder<BaseMapResource>()
                .where((b) => b.model.ManagementAccount.eq(this.contractDetails!.AccountIds))
                .select((b) => ({
                    [`override${this.contractDetails!.Id!}`]: b.countIf(this.contractQuerySvc!.getOverrideQuery()),
                }))
                .build(),
        ];
    }

    private async loadAccounts() {
        const connectionStatus = await getAccountGetAccountConnectionStatus({ companyId: this.contractDetails.CompanyId });
        const accountSets = await getAccountGetRelatedAccounts();
        const accounts = accountSets.flatMap((set) =>
            set.some((acc) => this.contractDetails.AccountIds?.includes(acc.AwsAccountId ?? '')) ? set : []
        );
        const connectionStatusMap = connectionStatus.reduce(
            (map, item) => map.set(item.AccountId ?? 0, item?.ConnectionStatus ?? false),
            new Map<number, boolean>()
        );
        accounts.forEach((account) => {
            account.IsConnected = connectionStatusMap.get(account.Id ?? 0) ?? false;
        });
        return accounts;
    }

    private async getActionsRequiredData() {
        const resources = await queryBuilder<BaseMapResource>()
            .where((b) => b.and(this.contractQuerySvc.getMgmtAccountQuery()))
            .select((b) => ({
                untagged: b.countIf(this.contractQuerySvc.getUndecidedQuery()),
                incorrectlyTagged: b.countIf(this.contractQuerySvc.getIncorrectlyTaggedQuery()),
                notCovered: b.countIf(this.contractQuerySvc.getNotCoveredQuery()),
                outsideContractTerm: b.countIf(this.contractQuerySvc.getOutsidePeriodQuery()),
            }))
            .execute(postResourcesQuery);

        const { untagged, incorrectlyTagged, notCovered, outsideContractTerm } = resources?.Results?.[0] ?? {
            untagged: 0,
            incorrect: 0,
            ineligible: 0,
        };

        return [
            { name: 'Tagged Incorrectly', count: incorrectlyTagged ?? 0 },
            { name: 'Not Tagged', count: untagged ?? 0 },
            { name: 'MAP Tag Overrides', count: (notCovered ?? 0) + (outsideContractTerm ?? 0) },
        ];
    }

    private async getKeyValuesAppliedData() {
        const results = await queryBuilder<BaseMapResource>()
            .where((b) => b.and(this.contractQuerySvc.getMgmtAccountQuery()))
            .select((b) => ({
                key: b.model['CsTags.map-migrated'] as unknown as IStringFluentOperators,
                value: b.countIf(b.model['CsTags.map-migrated'].eq(this.contractQuerySvc.validContractOptions)),
            }))
            .execute(postResourcesQuery);

        return results.Results?.filter((x) => x.value > 0).sort((a, b) => b.value - a.value) ?? [];
    }

    public updateStats(contractStats: ContractStats) {
        this.stats = contractStats;
    }

    private async getTop5ResourcesData() {
        const results = await queryBuilder<BaseMapResource>()
            .where((b) => b.and(this.contractQuerySvc.getMgmtAccountQuery(), this.contractQuerySvc.getValidResourcesQuery()))
            .select((b) => ({
                key: b.model['ResourceType'] as unknown as IStringFluentOperators,
                value: b.sum(b.model['Last30DaysCost'] as unknown as number),
            }))
            .execute(postResourcesQuery);

        return results.Results?.sort((a, b) => b.value - a.value).slice(0, 5) ?? [];
    }

    private async getTop5OwnersData() {
        const results = await queryBuilder<BaseMapResource>()
            .where((b) => b.and(this.contractQuerySvc.getMgmtAccountQuery()))
            .select((b) => ({
                key: b.model['CsTags.Owner'] as unknown as IStringFluentOperators,
                totalResources: b.count(),
            }))
            .execute(postResourcesQuery);

        return (
            results.Results?.map((x) => ({ key: x.key, value: x.totalResources / (results.Count || 1) }))
                .filter((x) => x.value > 0)
                .sort((a, b) => b.value - a.value)
                .slice(0, 5) ?? []
        );
    }
}

import { getUserGetMyAccessRules, getUserGetMyPermissions, getUserGetMyRoles } from '@apis/Customers';
import { Company, CompanyUserRole, EffectivePermissions } from '@apis/Customers/model';
import { IQueryExpr } from '@apis/Resources/model';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { container, inject, singleton } from 'tsyringe';
import { BasicApi } from './BasicApi';
import { ICompanyContextToken } from './Customers/CompanyContext';
import { useDi } from './DI';
import { EventEmitter } from './EventEmitter';
import { ArrayDataSource } from './Query/ArrayDataSource';

type ValidResourceVerbs = {
    Administration: 'Manage';
    Company: 'View' | 'Edit' | 'ManageBillingInfo';
    CspInvoiceData: 'View';
    Customer: 'Manage';
    IdleResources: 'View' | 'Manage' | 'ChangeState';
    Rightsizing: 'View';
    MarketplaceOffers: 'Manage';
    MapManager: 'View' | 'Manage';
    PartnerCompany: 'Approve' | 'Request';
    Permissions: 'Access' | 'Manage';
    Showback: 'View' | 'Manage' | 'ViewDashboard';
    StandardTags: 'Define';
    SSO: 'View' | 'Manage';
    SupportCompany: 'View' | 'Manage';
    SupportUser: 'View' | 'Manage';
    TagAutomation: 'View' | 'Edit' | 'Create';
    TagManager: 'View' | 'Tag';
    TagPolicy: 'View' | 'Manage';
    Transactions: 'ManageSubscription' | 'PurchaseCredits' | 'ManagePaymentMethods' | 'ViewInvoices';
    User: 'View' | 'Manage';
    Recommendations: 'View' | 'Manage';
    General: 'View';
    CompanyRelationships: 'View' | 'Manage';
    RelatedCompanyData: 'View';
    DiscountManager: 'View';
    Assessments: 'View';
    CostForecasting: 'View' | 'Manage';
    CustomerSubscription: 'View' | 'Manage';
};

export const appPermissions: Record<string, string[]> = {
    'tag manager': ['TagManager'],
    'map manager': ['MapManager'],
    'idle resources': ['IdleResources'],
    rightsizing: ['Recommendations'],
    'invoice manager': ['CspInvoiceData', 'DiscountManager', 'CostForecasting'],
    assessments: ['Assessments'],
};

@singleton()
export class AuthorizationService {
    private permissionLookup = new Set<string>();
    private accessRules: IQueryExpr[] = [];
    private companyUserRoles: CompanyUserRole[] = [];
    public companyRoleLookup = new Map<number, number[]>();
    public permissions: EffectivePermissions[] = [];
    public permissionError = new EventEmitter<{ wait: (shouldRetry: Promise<boolean>) => void }>({ wait: () => {} });

    public constructor(@inject(BasicApi) private readonly basicApi: BasicApi) {}

    public async init() {
        this.basicApi.registerResponseHandler(this.checkAuthZ);
        await this.loadPermissions();
    }

    public allow = (resource: string, verbs?: string | string[], companyId?: number) => {
        companyId ??= (container.resolve(ICompanyContextToken) as Company | undefined)?.Id;
        const resourceKey = this.createKey(companyId, resource);
        const verbKeys = !verbs ? [] : typeof verbs === 'string' ? [verbs] : verbs;
        const keys = [resourceKey, ...verbKeys.map((v) => this.createKey(companyId, resource, v))];
        return keys.every((k) => this.permissionLookup.has(k)) || this.checkAccessRules(resource, verbs, companyId);
    };

    public checkRules(companyId: number, requirements: { [resource: string]: string[] | null }) {
        return Object.keys(requirements).every((r) => this.checkAccessRules(r, requirements[r]?.filter((v) => !!v) ?? [], companyId));
    }

    private checkAccessRules(resource: string, verbs?: string | string[], companyId?: number) {
        if (this.accessRules?.length) {
            const normalizedVerbs = !verbs ? [null] : typeof verbs === 'string' ? [verbs] : verbs;
            const checks = normalizedVerbs.map((v) => ({ Resource: resource, Verb: v, CompanyId: companyId ?? null }));
            const evaluator = new ArrayDataSource(checks);
            const rules = { Operation: 'or', Operands: this.accessRules };
            return evaluator.filter([rules]).length === checks.length;
        }
        return false;
    }

    public async reloadPermissions() {
        await this.loadPermissions();
    }

    public async hasAppPermission(companyId: number, apps: string[]) {
        for (const app of apps) {
            const appResources = appPermissions[app.toLocaleLowerCase()];
            if (appResources) {
                return appResources.some((r) => this.allow(r, undefined, companyId));
            }
        }
        return false;
    }

    private async loadPermissions() {
        const [permissions, accessRules, userRoles] = await Promise.all([getUserGetMyPermissions(), getUserGetMyAccessRules(), getUserGetMyRoles()]);
        this.permissions = permissions;
        this.accessRules = accessRules;
        this.companyUserRoles = userRoles;
        for (const role of userRoles) {
            if (role.CompanyId) {
                const roles = this.companyRoleLookup.get(role.CompanyId) ?? [];
                roles.push(role.RoleId ?? 0);
                this.companyRoleLookup.set(role.CompanyId, roles);
            }
        }

        for (const p of permissions) {
            this.permissionLookup.add(this.createKey(p.CompanyId, p.Resource));
            for (const verb of p.AssignedVerbs ?? []) {
                this.permissionLookup.add(this.createKey(p.CompanyId, p.Resource, verb));
            }
        }
    }

    private createKey(companyId: number | undefined | null, resource: string | null | undefined, verb?: string) {
        return (verb ? `${companyId}-${resource}-${verb}` : `${companyId}-${resource}`).toLowerCase();
    }

    private checkAuthZ = async (response: Response) => {
        if (response.status === 403) {
            const retryPromises = [Promise.resolve(false)];
            const permissionHandler = {
                wait(shouldRetry: Promise<boolean>) {
                    retryPromises.push(shouldRetry);
                },
            };
            this.permissionError.emit(permissionHandler);
            const retryResolution = await Promise.all(retryPromises);
            const retry = retryResolution.some((r) => r);
            return { retry };
        } else {
            return { retry: false };
        }
    };
}

export function useAuthZ(resource: string, verbs?: string | string[]) {
    const company = useCompany();
    const authzSvc = useDi(AuthorizationService);
    return authzSvc.allow(resource, verbs, company?.Id);
}

export function useAuthZValues<
    T extends Record<string, Partial<{ [k in keyof ValidResourceVerbs]: ValidResourceVerbs[k][] | ValidResourceVerbs[k] }>>
>(checks: T): Record<keyof T, boolean> {
    const company = useCompany();
    const authzSvc = useDi(AuthorizationService);
    const result: Record<string, boolean> = {};
    for (const key of Object.keys(checks)) {
        const rvChecks = checks[key];
        for (const resource of Object.keys(rvChecks)) {
            const verbs = rvChecks[resource as keyof ValidResourceVerbs];
            result[key] = authzSvc.allow(resource, verbs, company?.Id);
        }
    }
    return result as Record<keyof T, boolean>;
}

import { postResourcesQuery, QueryExpr } from '@apis/Resources';
import { BaseAwsResource } from '@apis/Resources/model';
import { getMapContractsGetMapContracts } from '@apis/TagManager';
import { IQueryExpr, MapContract, MapSupportedService, MapSupportedServiceTargetType } from '@apis/TagManager/model';
import { FormatService } from '@root/Services/FormatService';
import { exprBuilder, IFluentOperators, queryBuilder } from '@root/Services/QueryExpr';
import { addYears } from 'date-fns';
import { injectable } from 'tsyringe';
import { getContractTagOptions } from './MapCostAllocationTagService';

export class MapResourceQueryService {
    public contractFrom: Date;
    public contractTo: Date;
    public validTagOptions: string[];
    public validContractOptions: string[];
    public allValidContractOptions: string[];
    public validTagMeta: ReturnType<typeof getContractTagOptions>;
    public validResourceTypes: string[];
    public validTagsByTarget: Map<MapSupportedServiceTargetType, Set<string>>;
    public validTagsByResourceType: Map<string, Set<string>>;
    public validResourceTypesByTag: Map<string, Set<string>>;
    public validServiceCodes: string[];

    public get isActive() {
        return (
            this.contractFrom &&
            this.contractFrom.getTime() < new Date().getTime() &&
            this.contractTo &&
            this.contractTo.getTime() > new Date().getTime()
        );
    }

    public constructor(
        public readonly contract: MapContract,
        public readonly mapResourceTypeMeta: MapSupportedService[],
        public readonly fmtSvc: FormatService,
        public readonly otherContracts: MapContract[] | undefined
    ) {
        const tagOptions = getContractTagOptions(contract.ProgramId!);
        this.validTagMeta = tagOptions;
        this.validContractOptions = [...tagOptions.map((o) => o.value)];
        this.validTagOptions = [...this.validContractOptions];
        this.allValidContractOptions = [...this.validContractOptions];
        this.getAllValidContractOptions();
        this.contractFrom = fmtSvc.toLocalDate(contract.ContractStartDate);
        this.contractTo = contract.ContractEndDate
            ? fmtSvc.toLocalDate(contract.ContractEndDate)
            : addYears(this.contractFrom, contract.ContractTermYears ?? 1);

        const filterByTarget = (m: MapSupportedService) => {
            if (m.Targets) {
                for (const target of m.Targets) {
                    if (!target.MinContractDate || fmtSvc.toLocalDate(contract.ContractStartDate) > fmtSvc.toLocalDate(target.MinContractDate)) {
                        return true;
                    }
                }
            }
            return false;
        };

        var validMapResourceTypeMeta = mapResourceTypeMeta.filter(filterByTarget);
        this.validResourceTypes = validMapResourceTypeMeta.flatMap((m) => m.ResourceTypes ?? []);
        this.validServiceCodes = validMapResourceTypeMeta.flatMap((m) => m.ProductServiceCode ?? '');
        this.validTagsByTarget = new Map<MapSupportedServiceTargetType, Set<string>>([
            ['Compute', new Set([`mig${contract.ProgramId}`])],
            [
                'DBA',
                new Set([`mig${contract.ProgramId}`, `comm${contract.ProgramId}`, `mig_ec2_${contract.ProgramId}`, `comm_ec2_${contract.ProgramId}`]),
            ],
            ['OracleSap', new Set([`sap${contract.ProgramId}`, `oracle${contract.ProgramId}`])],
        ]);
        this.validTagsByResourceType = validMapResourceTypeMeta.reduce((result, item) => {
            if (item.ResourceTypes) {
                for (const type of item.ResourceTypes) {
                    let tags = result.get(type);
                    if (!tags) {
                        result.set(type, (tags = new Set<string>()));
                    }
                    if (item.Targets) {
                        for (const target of item.Targets) {
                            const targetTags = target.Type && this.validTagsByTarget.get(target.Type);
                            if (targetTags && (!target.MinContractDate || this.fmtSvc.toLocalDate(target.MinContractDate) > this.contractFrom)) {
                                for (const tag of targetTags) {
                                    tags.add(tag);
                                }
                            }
                        }
                    }
                }
            }
            return result;
        }, new Map<string, Set<string>>());
        this.validResourceTypesByTag = [...this.validTagsByResourceType].reduce((result, [type, tags]) => {
            for (const tag of tags) {
                let types = result.get(tag);
                if (!types) {
                    result.set(tag, (types = new Set<string>()));
                }
                types.add(type);
            }
            return result;
        }, new Map<string, Set<string>>());
    }

    public getMgmtAccountQuery() {
        return exprBuilder<BaseMapResource>().createFluentExpr((b) => b.model.ManagementAccount.eq(this.contract.AccountIds));
    }

    public getValidResourcesQuery() {
        return exprBuilder<BaseMapResource>().createFluentExpr((b) =>
            b.and(
                b.or(
                    b.model.CreateDate.isNull(),
                    b.and(b.model.CreateDate.onOrAfter(this.contractFrom), b.model.CreateDate.onOrBefore(this.contractTo))
                ),
                b.or(
                    b.model.ParentCreateDate.isNull(),
                    b.and(b.model.ParentCreateDate.onOrAfter(this.contractFrom), b.model.ParentCreateDate.onOrBefore(this.contractTo))
                ),
                b.model.ResourceType.eq(this.validResourceTypes)
            )
        );
    }

    public getValidResourcesQueryOtherContracts(contract: MapContract) {
        const fmtSvc = new FormatService();
        const contractFrom = fmtSvc.toLocalDate(contract.ContractStartDate);
        const contractTo = contract.ContractEndDate
            ? fmtSvc.toLocalDate(contract.ContractEndDate)
            : addYears(this.contractFrom, contract.ContractTermYears ?? 1);

        return exprBuilder<BaseMapResource>().createFluentExpr((b) =>
            b.and(
                b.or(
                    b.model.CreateDate.isNull(),
                    b.and(b.model.CreateDate.onOrAfter(fmtSvc.toLocalDate(contractFrom)), b.model.CreateDate.onOrBefore(contractTo))
                ),
                b.model.ResourceType.eq(this.validResourceTypes)
            )
        );
    }

    public getCorrectlyTaggedQuery() {
        return exprBuilder<BaseMapResource>().createFluentExpr((b) =>
            b.and(
                this.getValidResourcesQuery(),
                b.model[`CsTags.map-migrated`].eq(this.validTagOptions),
                b.model[`CsTags.map-migrated`].ne('not-eligible'),
                b.model[`CsTags.map-migrated`].ne('not-eligible-workload')
            )
        );
    }

    public getCorrectlyTaggedOtherContractQuery() {
        const validTagOptions = this.allValidContractOptions.filter((o) => !this.validContractOptions.includes(o));
        return exprBuilder<BaseMapResource>().createFluentExpr((b) =>
            b.and(
                this.getValidResourcesQuery(),
                b.model[`CsTags.map-migrated`].eq(validTagOptions),
                b.model[`CsTags.map-migrated`].ne('not-eligible'),
                b.model[`CsTags.map-migrated`].ne('not-eligible-workload')
            )
        );
    }

    public getIneligbleTaggedQuery() {
        return exprBuilder<BaseMapResource>().createFluentExpr((b) => b.model[`CsTags.map-migrated`].eq(['not-eligible-workload']));
    }

    public getIncorrectlyTaggedQuery() {
        return exprBuilder<BaseMapResource>().createFluentExpr((b) =>
            b.and(
                this.getValidResourcesQuery(),
                b.model[`CsTags.map-migrated`].ne(this.allValidContractOptions),
                b.model[`CsTags.map-migrated`].ne('not-covered'),
                b.model[`CsTags.map-migrated`].isNotNull()
            )
        );
    }

    public getTagged30DaySpendQuery() {
        return exprBuilder<BaseMapResource>().createFluentExpr((b) =>
            b.iif(
                b.and(this.getValidResourcesQuery(), b.model[`CsTags.map-migrated`].eq(this.validTagOptions)),
                b.model['Last30DaysCost'],
                b.param(0)
            )
        );
    }
    public getIncorrectlyTagged30DaySpendQuery() {
        return exprBuilder<BaseMapResource>().createFluentExpr((b) =>
            b.iif(
                b.and(
                    this.getValidResourcesQuery(),
                    b.model[`CsTags.map-migrated`].ne(this.validTagOptions),
                    b.model[`CsTags.map-migrated`].isNotNull()
                ),
                b.model['Last30DaysCost'],
                b.param(0)
            )
        );
    }
    public getUntagged30DaySpendQuery() {
        return exprBuilder<BaseMapResource>().createFluentExpr((b) =>
            b.iif(b.and(this.getValidResourcesQuery(), b.model[`CsTags.map-migrated`].isNull()), b.model['Last30DaysCost'], b.param(0))
        );
    }
    public getUndecidedQuery() {
        return exprBuilder<BaseMapResource>().createFluentExpr((b) => b.and(this.getValidResourcesQuery(), b.model[`CsTags.map-migrated`].isNull()));
    }
    public getOutsidePeriodQuery() {
        return exprBuilder<BaseMapResource>().createFluentExpr((b) =>
            b.and(
                b.not(this.getIncorrectlyTaggedQuery()),
                b.model.ResourceType.eq(this.validResourceTypes),
                b.or(
                    b.model.CreateDate.before(this.contractFrom),
                    b.model.CreateDate.after(this.contractTo),
                    b.model.ParentCreateDate.before(this.contractFrom),
                    b.model.ParentCreateDate.after(this.contractTo)
                ),
                b.model[`CsTags.map-migrated`].ne(this.validContractOptions)
            )
        );
    }

    public getNotCoveredQuery() {
        return exprBuilder<BaseMapResource>().createFluentExpr((b) =>
            b.and(b.model.ResourceType.ne(this.validResourceTypes), b.model[`CsTags.map-migrated`].ne(this.validContractOptions))
        );
    }

    public getIneligibleQuery() {
        return exprBuilder<BaseMapResource>().createFluentExpr((b) =>
            b.or(
                b.and(b.model.ResourceType.ne(this.validResourceTypes), b.model[`CsTags.map-migrated`].ne(this.validContractOptions)),
                b.and(
                    b.not(this.getIncorrectlyTaggedQuery()),
                    b.model.ResourceType.eq(this.validResourceTypes),
                    b.or(
                        b.model.CreateDate.before(this.contractFrom),
                        b.model.CreateDate.after(this.contractTo),
                        b.model.ParentCreateDate.before(this.contractFrom),
                        b.model.ParentCreateDate.after(this.contractTo)
                    ),
                    b.model[`CsTags.map-migrated`].ne(this.validContractOptions)
                )
            )
        );
    }

    public getOverrideQuery() {
        return exprBuilder<BaseMapResource>().createFluentExpr((b) =>
            b.and(
                b.or(
                    b.model.CreateDate.before(this.contractFrom),
                    b.model.CreateDate.after(this.contractTo),
                    b.model.ParentCreateDate.before(this.contractFrom),
                    b.model.ParentCreateDate.after(this.contractTo),
                    b.model.ResourceType.ne(this.validResourceTypes)
                ),
                b.model[`CsTags.map-migrated`].eq(this.validContractOptions),
                b.model['CsTags.map-migrated'].ne(['not-eligible-workload'])
            )
        );
    }

    public getTagMetaByResourceType(resourceType: string) {
        const typeTags = this.validTagsByResourceType.get(resourceType);
        return this.validTagMeta.filter((t) => typeTags?.has(t.value) ?? false);
    }

    public async getScores() {
        const result = await queryBuilder<BaseMapResource>()
            .where((b) => b.and(b.model.ManagementAccount.eq(this.contract.AccountIds), b.model.ResourceType.eq(this.validResourceTypes)))
            .select((b) => ({
                errors: b.countIf(this.getIncorrectlyTaggedQuery()),
                undecided: b.countIf(this.getUndecidedQuery()),
                tagged: b.countIf(this.getCorrectlyTaggedQuery()),
            }))
            .execute(postResourcesQuery);
        return result?.Results?.[0];
    }

    public async getAllValidContractOptions() {
        let validContractOptions = [...this.validContractOptions];
        if (this.otherContracts && this.otherContracts.length > 0) {
            this.otherContracts.forEach((c) => {
                const otherContractTags = getContractTagOptions(c.ProgramId!);
                const validOtherContractTagOptions = [...otherContractTags.map((o) => o.value)];
                validContractOptions = validContractOptions.concat(validOtherContractTagOptions);
            });
        }
        this.allValidContractOptions = validContractOptions;
    }
}
export type BaseMapResource = Omit<BaseAwsResource, 'CreateDate'> & { CreateDate: Date; ParentCreateDate: Date };

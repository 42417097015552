/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Customers.Host.Api
 * OpenAPI spec version: 1.0
 */

export type CompanyStatCompanyType = typeof CompanyStatCompanyType[keyof typeof CompanyStatCompanyType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyStatCompanyType = {
  NonMsp: 'NonMsp',
  MspParent: 'MspParent',
  MspChild: 'MspChild',
} as const;

import styled from '@emotion/styled';
import { ActionIcon, Anchor, Button, Checkbox, Group, Space, Table, ThemeIcon, useMantineTheme } from '@mantine/core';
import { AnchorButton } from '@root/Design/Primitives';
import { theme } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEvent, useToggle } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronDown, ChevronRight, Trash } from 'tabler-icons-react';

export interface MiniDataGridItem {
    getName(): string;
    getAmount(): number | undefined;
    select?: () => void;
    remove?: () => void;
    onChanged?: EventEmitter<void>;
    hideRow?: boolean;
    linkedUniqueId?: string;
}

function MiniDataGridRow({
    index,
    item,
    detailsRenderer,
}: {
    index: number;
    item: MiniDataGridItem;
    detailsRenderer?: (item: MiniDataGridItem) => JSX.Element;
}) {
    const fmtSvc = useDi(FormatService);
    const [expanded, { toggle }] = useToggle(false);

    const handleRemove = useCallback(() => item.remove?.(), [item]);
    const handleSelect = useCallback(() => item.select?.(), [item]);

    const canSelect = item.select !== undefined;
    const amount = item.getAmount();

    return item.hideRow ? null : (
        <>
            <Row style={{ backgroundColor: 'white' }}>
                <td>
                    {!detailsRenderer ? null : <ThemeIcon onClick={toggle}>{expanded ? <ChevronDown /> : <ChevronRight />}</ThemeIcon>}
                    {!canSelect ? (
                        item.getName()
                    ) : (
                        <Anchor data-atid={`mini-grid-item-${index}`} onClick={handleSelect}>
                            {item.getName()}
                        </Anchor>
                    )}
                </td>
                <td style={{ textAlign: 'right' }}>{amount === undefined ? null : fmtSvc.formatMoney(amount)}</td>
                <td>
                    {!item.remove ? null : (
                        <ActionIcon size="sm" variant="subtle" onClick={handleRemove} sx={{ opacity: 0.6, ['&:hover']: { opacity: 1 } }}>
                            <Trash size={16} />
                        </ActionIcon>
                    )}
                </td>
            </Row>
            {expanded && detailsRenderer ? (
                <tr>
                    <td colSpan={3}>{detailsRenderer(item)}</td>
                </tr>
            ) : null}
        </>
    );
}

export interface IMiniDataGridProps {
    data: MiniDataGridItem[];
    nameHeader: string;
    amountHeader: string;
    displayTotal?: boolean;

    detailsRenderer?: (item: MiniDataGridItem) => JSX.Element;
}

export function MiniDataGrid({ data, nameHeader, amountHeader, detailsRenderer, displayTotal = false }: IMiniDataGridProps) {
    const fmtSvc = useDi(FormatService);
    const theme = useMantineTheme();
    const totalAmount = data.reduce((total, item) => total + (item.getAmount() ?? 0), 0);

    const rows = useMemo(
        () => data.map((item, idx) => <MiniDataGridRow key={idx} index={idx} item={item} detailsRenderer={detailsRenderer} />),
        [data, detailsRenderer]
    );

    return (
        <Table highlightOnHover verticalSpacing={0}>
            <thead>
                <Row style={{ backgroundColor: theme.colors?.gray[3] }}>
                    <th style={{ width: '60%' }}>{nameHeader}</th>
                    <th style={{ width: '30%', textAlign: 'right' }}>{amountHeader}</th>
                    <th style={{ width: '10%' }}></th>
                </Row>
            </thead>
            <tbody>
                {rows}
                {displayTotal && (
                    <Row key="total" style={{ backgroundColor: theme.colors?.primary[2] }}>
                        <td></td>
                        <td style={{ textAlign: 'right' }}>
                            <Group style={{ justifyContent: 'flex-end' }}>
                                Total <Space w="md"></Space> {fmtSvc.formatMoney(totalAmount)}
                            </Group>
                        </td>
                        <td></td>
                    </Row>
                )}
            </tbody>
        </Table>
    );
}

const Row = styled.tr`
    td,
    th {
        height: 30px;
        padding: 0 10px;
    }
    th {
        border-bottom: 1px solid ${(p) => p.theme.colors?.gray[4]} !important;
    }
`;

import styled from '@emotion/styled';
import { Box, Center, Grid, Group, Stack, Text, useMantineTheme } from '@mantine/core';
import { PieChart, PieChartSettings } from '@root/Components/Charts/PieChart';
import { FillerSwitch } from '@root/Design/Filler';
import { AnchorButton } from '@root/Design/Primitives';
import { useDi, useDiContainer } from '@root/Services/DI';
import { useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { ShowbackPersistence } from '@root/Services/Invoices/ShowbackService';
import { useEffect, useMemo } from 'react';
import { Settings } from 'tabler-icons-react';
import { ShowbackModel } from '../Models';
import { ActionCard, PieKeyCard } from './Components';
import { ShowbackSummaryModel } from './ShowbackSummaryModel';
import { SummarySankeyChart } from './SummarySankeyChart';
import { SummaryScoreboard } from './SummaryScoreboard';
import { SummaryStatList } from './SummaryStatList';

export function ShowbackSummary({ model }: { model: ShowbackModel }) {
    const showbackSvc = useDi(ShowbackPersistence);
    const dimName = showbackSvc.getDimensionName(model.selectedAllocationDimension);

    const container = useDiContainer();
    const summaryModel = useMemo(() => container.resolve(ShowbackSummaryModel).setSbModel(model), [model]);
    useEffect(() => summaryModel.init(), [summaryModel]);

    return (
        <SummaryLayout>
            <Stack sx={{ minWidth: 600 }}>
                <ActionCard>
                    <Group>
                        <AnchorButton icon={<Settings size={18} />} text={dimName} onClick={model.editDimensionSettings} size="md" />
                        <Text>Allocation Summary</Text>
                    </Group>
                    <SummaryStatList summaryModel={summaryModel} />
                </ActionCard>
                <ActionCard title="Invoice Spend Allocation">
                    <InvoiceSpendAllocation summaryModel={summaryModel} />
                </ActionCard>
            </Stack>
            <Stack sx={{ flex: 1 }}>
                <SummaryScoreboard summaryModel={summaryModel} />
                <Grid columns={3}>
                    <Grid.Col md={3}>
                        <ActionCard title="Showback Allocation">
                            <Center sx={{ height: 500 }}>
                                <SummarySankeyChart summaryModel={summaryModel} />
                            </Center>
                        </ActionCard>
                    </Grid.Col>
                </Grid>
            </Stack>
        </SummaryLayout>
    );
}

const SummaryLayout = styled.div`
    display: grid;
    grid-template-columns: 600px minmax(500px, 1fr);
    column-gap: ${(p) => p.theme.spacing.md}px;
`;

function InvoiceSpendAllocation({ summaryModel }: { summaryModel: ShowbackSummaryModel }) {
    const summaryData = useEventValue(summaryModel.dataChanged);
    const loading = useEventValue(summaryModel.loading);

    const data = useMemo(() => {
        if (!summaryData?.stats) {
            return undefined;
        }
        const { untagged, total } = summaryData.stats;
        if (!total) {
            return undefined;
        }
        const covered = total - untagged;
        const uncovered = Math.abs(untagged);
        const totalForPct = covered + uncovered;
        const coveredPct = covered / totalForPct;
        const uncoveredPct = uncovered / totalForPct;
        return {
            covered,
            uncovered,
            coveredPct,
            uncoveredPct,
            values: [
                { id: 'Allocated', value: covered },
                { id: 'Unallocated', value: uncovered },
            ],
        };
    }, [summaryData]);

    const theme = useMantineTheme();
    const allocColor = theme.colors.success[4];
    const unallocColor = theme.colors.warning[5];
    const pieSettings = useMemo(
        () =>
            ({
                chartColors: [allocColor, unallocColor],
                margin: { bottom: 10, top: 10, left: 10, right: 10 },
                enableArcLabels: false,
                enableArcLinkLabels: false,
                noWrapper: true,
                valueFormat: 'money',
            } as PieChartSettings),
        []
    );

    return (
        <Box sx={{ height: 170 }}>
            <FillerSwitch loading={!!loading} noData={!data}>
                {() => (
                    <>
                        <Grid columns={3} align="center" gutter="xl">
                            <Grid.Col xs={1} sx={{ overflow: 'hidden', height: 200 }}>
                                <PieChart data={data!.values} groups={['id']} values={['value']} settings={pieSettings} />
                            </Grid.Col>
                            <Grid.Col xs={1}>
                                <PieKeyCard label="Allocated" amount={data!.covered} color={allocColor} pct={data!.coveredPct} />
                            </Grid.Col>
                            <Grid.Col xs={1}>
                                <PieKeyCard label="Unallocated" amount={data!.uncovered} color={unallocColor} pct={data!.uncoveredPct} />
                            </Grid.Col>
                        </Grid>
                    </>
                )}
            </FillerSwitch>
        </Box>
    );
}

import React from 'react';
import { container, inject, singleton } from 'tsyringe';
import { Logger } from '../Logger';

export interface IEndpoint {
    name: string;
    type: React.ComponentType;
    breadCrumb: string | React.ComponentType | false;
    controlDescendants?: boolean;
}

export const IEndpointRegistryToken = 'IEndpointRegistryToken';
export interface IEndpointRegistry {
    get(name: string): IEndpoint | undefined;
    register(endpoint: IEndpoint): void;
}

@singleton()
export class EndpointRegistry implements IEndpointRegistry {
    private readonly lookup = new Map<string, IEndpoint>();
    public constructor(@inject(Logger) private logger: Logger) {}
    public get(name: string) {
        return this.lookup.get(name.toLocaleLowerCase());
    }
    public register(endpoint: IEndpoint) {
        const name = endpoint.name.toLocaleLowerCase();
        if (this.lookup.has(name)) {
            this.logger.warn(
                `Attempted to register endpoint ${name}, if you still see this message after refreshing, you may have mistakenly registered multiple components with the same endpoint(name). `
            );
        }
        this.lookup.set(name, endpoint);
    }
}

let staticRegistry: EndpointRegistry | undefined;
export function endpoint(
    name: string,
    type: React.ComponentType<any>,
    breadCrumb: string | React.ComponentType | false,
    controlDescendants?: boolean
) {
    if (!staticRegistry) {
        staticRegistry = container.resolve(EndpointRegistry);
    }
    staticRegistry.register({ name, type, breadCrumb, controlDescendants });
}

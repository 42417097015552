import styled from '@emotion/styled';
import { Box, Card, Divider, Grid, List, Space, Text, Title, useMantineTheme, Group, Sx } from '@mantine/core';
import { useTimeout } from '@mantine/hooks';
import { ReactNode } from 'react';

export function RuleEditCard({
    children,
    title,
    titleDescriptionControl,
    description,
    info,
    color,
    accent,
    onBlur,
    height,
}: {
    children: ReactNode;
    title?: string;
    titleDescriptionControl?: ReactNode;
    description?: string;
    info?: string;
    color?: 'white' | 'gray' | 'blue';
    accent?: boolean;
    onBlur?: () => void;
    height?: number;
}) {
    const theme = useMantineTheme();
    const background = color === 'blue' ? theme.colors.primary[1] : color === 'gray' ? theme.colors.gray[2] : theme.white;
    const { start, clear } = useTimeout(onBlur ?? (() => {}), 200);
    return (
        <Card
            p={0}
            sx={{ background, borderTopWidth: accent ? 0 : '1px', height: height ?? 'unset' }}
            withBorder
            radius="md"
            onBlur={start}
            onClick={clear}
            onFocus={clear}
        >
            {accent ? <Divider size={3} color="primary" /> : null}
            <Box p="xl" pt="md">
                <Group position="apart">
                    {title ? <Title order={4}>{title}</Title> : null}
                    {titleDescriptionControl}
                </Group>
                {description ? (
                    <Text size="sm" color="dimmed" mt={4}>
                        {description}
                    </Text>
                ) : null}
                {title || description ? <Space h="sm" /> : null}
                {children}
            </Box>
        </Card>
    );
}

export function ValidationIssues({ title, issues }: { title: string; issues: string[] }) {
    const theme = useMantineTheme();
    return (
        <>
            <Text size="sm" mt="xs">
                {title}
            </Text>
            <List ml="md">
                {issues.map((v) => (
                    <List.Item sx={{ fontSize: `14px`, color: theme.colors.gray[6] }} key={v}>
                        {v}
                    </List.Item>
                ))}
            </List>
        </>
    );
}

import { NavBarItem, PageNavBar } from '@root/Components/PageNavBar/PageNavBar';

const navbarItems = [
    { type: 'link', text: 'Autoscale', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'Autoscale' } },
    { type: 'link', text: 'Dynamo DB', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'Dynamo DB' } },
    { type: 'link', text: 'EBS', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'EBS' } },
    { type: 'link', text: 'EC2 Images', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'EC2 Image' } },
    { type: 'link', text: 'EC2 On Demand', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'EC2 On Demand' } },
    { type: 'link', text: 'EC2 Reserved', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'EC2 Reserved' } },
    { type: 'link', text: 'EKS', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'EKS' } },
    { type: 'link', text: 'Elastic Search', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'ElasticSearch' } },
    { type: 'link', text: 'ELB V1', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'ELBV1' } },
    { type: 'link', text: 'ELB V2', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'ELBV2' } },
    { type: 'link', text: 'EMR', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'EMR' } },
    { type: 'link', text: 'Lambda', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'Lambda' } },
    //{ type: 'link', text: 'Nat Gateway', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'Nat Gateway' } },
    {
        type: 'link',
        text: 'Network Interfaces',
        icon: <i className="ti ti-category-2"></i>,
        endpoint: 'overview',
        data: { type: 'Network Interface' },
    },
    { type: 'link', text: 'Open Search', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'OpenSearch' } },
    { type: 'link', text: 'RDS', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'RDS' } },
    { type: 'link', text: 'RDS Cluster', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'RDS Cluster' } },
    { type: 'link', text: 'RDS Reserved', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'RDS Reserved' } },
    { type: 'link', text: 'Redshift', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'Redshift' } },
    { type: 'link', text: 'Route 53', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'Route 53' } },
    { type: 'link', text: 'S3', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'S3' } },
    { type: 'link', text: 'Savings Plans', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'Savings Plan' } },
    { type: 'link', text: 'Workspaces', icon: <i className="ti ti-category-2"></i>, endpoint: 'overview', data: { type: 'Workspace' } },
] as NavBarItem[];
export function ResourcesNav() {
    return <PageNavBar items={navbarItems} />;
}

import { Center, Text, useMantineTheme } from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { AllocationRuleEditor } from '../Model';
import { ColumnConfig, DataGridStateChange, GridSplitBodyProps } from '@root/Components/DataGrid/Models';
import { DataGrid } from '@root/Components/DataGrid';
import { QueryResult } from '@apis/Resources';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { GridBodySection, GridTitleSection } from '@root/Components/DataGrid/Design';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { FillerSwitch } from '@root/Design/Filler';
import { InvoiceAllocationRule } from '@apis/Invoices/model';
import { values } from 'mobx';

type AllocationRulePreviewGridRow = {
    dimensionKey: string;
    lineItemType?: string;
    invoiceCost?: number;
    usageStartDate?: Date;
    adjustedCashCost?: number;
    onDemandCost?: number;
    name?: string;
};
export function AllocationRulePreviewGrid({ ruleEditor }: { ruleEditor: AllocationRuleEditor }) {
    const fmtSvc = useDi(FormatService);
    const previewGridStateChanged = new EventEmitter<DataGridStateChange | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>();
    const [gridModelState, setGridModelState] = useState<DataGridModel | undefined>(undefined);
    const [sourceDatasource, setSourceDatasource] = useState<AllocationRulePreviewGridRow[]>([]);
    const [sourceTotalDatasource, setSourceTotalDatasource] = useState<AllocationRulePreviewGridRow[]>([]);
    const [targetDatasource, setTargetDatasource] = useState<AllocationRulePreviewGridRow[]>([]);
    const [targetTotalDatasource, setTargetTotalDatasource] = useState<AllocationRulePreviewGridRow[]>([]);
    const theme = useMantineTheme();

    const updateWithTotals = useCallback(async (sourceRows: AllocationRulePreviewGridRow[], targetRows: AllocationRulePreviewGridRow[]) => {
        let {
            sourceCt,
            sourceInvoiceTotal,
            sourceTotalsByDay,
            sourceShowbackTotal,
            targetCt,
            targetShowbackTotal,
            targetTotalsByDay,
            targetInvoiceTotal,
        } = await ruleEditor.getSourceAndTargetTotals();

        const rule = ruleEditor.rule as InvoiceAllocationRule;
        const targetType = rule.Disbursement?.Targets?.[0]?.TargetType;
        const dimValues = rule.Disbursement?.Targets?.[0]?.DimensionValues;

        if (targetType === 'NewRecords' && dimValues?.length) {
            targetCt = sourceCt * dimValues.length;
            targetShowbackTotal = sourceInvoiceTotal;
        }

        if (targetRows.length === 0) {
            targetCt = 0;
            targetShowbackTotal = 0;
        }

        const sourceTotalData: AllocationRulePreviewGridRow = {
            dimensionKey: '',
            name: `${fmtSvc.formatInt(sourceCt)} Total source line items to redisburse`,
            invoiceCost: sourceInvoiceTotal,
            adjustedCashCost: targetType === 'NewRecords' ? 0 : sourceShowbackTotal,
        };
        const targetTotalData: AllocationRulePreviewGridRow = {
            dimensionKey: '',
            name: `${fmtSvc.formatInt(targetCt)} Total target line items to adjust`,
            invoiceCost: targetType === 'NewRecords' ? 0 : targetInvoiceTotal,
            adjustedCashCost: targetShowbackTotal,
        };

        sourceRows.forEach((r) => {
            const dailyTotal = targetTotalsByDay.get((r.usageStartDate ?? '') as unknown as string)?.total ?? 0;
            if (dailyTotal > 0) {
                r.adjustedCashCost = 0;
            }
        });

        if (targetType !== 'NewRecords') {
            targetRows.forEach((r) => {
                const sourceDayTotal = sourceTotalsByDay.get((r.usageStartDate ?? '') as unknown as string)?.total ?? 0;
                const targetDayTotal = targetTotalsByDay.get((r.usageStartDate ?? '') as unknown as string)?.total ?? 0;
                if (targetDayTotal) {
                    const invoiceCostPortion = r.invoiceCost! / targetDayTotal;
                    const adjustment = sourceDayTotal * invoiceCostPortion;
                    r.adjustedCashCost = (r.invoiceCost ?? 0) + adjustment;
                }
            });
        }

        return { sourceTotalData, targetTotalData };
    }, []);

    const getSampleSourceRows = useCallback(async () => {
        const results = await ruleEditor.getFundSourcePreview();

        const dimensionKey = ruleEditor.getDimensionKey();
        return results.map((r) => {
            return {
                dimensionKey: r[dimensionKey],
                lineItemType: r['lineItem/LineItemType'],
                invoiceCost: r['lineItem/LineItemType'] !== 'DiscountedUsage' ? r['lineItem/UnblendedCost'] : r['reservation/OnDemandCost'],
                adjustedCashCost: r.AdjustedCashCost,
                onDemandCost: r['reservation/OnDemandCost'],
                name: r.Name,
                usageStartDate: r.UsageStartDate,
            } as AllocationRulePreviewGridRow;
        });
    }, []);
    const getDimValues = useMemo(() => {
        let results: Promise<string[]> | undefined;
        const month = ruleEditor.month;
        return () => (results ??= ruleEditor.showbackSvc.getDimensionValueOptions(ruleEditor.allocDim, { from: month, to: month }));
    }, []);

    const getSampleTargetRowsFromSource = useCallback(async (values: string[], isExclusion: boolean) => {
        const results = await getSampleSourceRows();
        if (values.length === 0 && isExclusion) {
            return [];
        }
        const allValues = isExclusion ? await getDimValues() : values;
        const dimValues = isExclusion ? allValues.filter((v) => !values.includes(v)) : values;

        return dimValues.flatMap((d) => {
            return results.map((r) => {
                const adjustedCashCost = (r.invoiceCost ?? 0) / dimValues.length;
                return {
                    ...r,
                    dimensionKey: d,
                    adjustedCashCost,
                    invoiceCost: 0,
                } as AllocationRulePreviewGridRow;
            });
        });
    }, []);

    const getSampleTargetRows = useCallback(async () => {
        var results = await ruleEditor.getDisbursmentTargetPreview();
        const dimensionKey = ruleEditor.getDimensionKey();
        return results.map((r) => {
            return {
                dimensionKey: r[dimensionKey],
                lineItemType: r['lineItem/LineItemType'],
                invoiceCost: r['lineItem/LineItemType'] !== 'DiscountedUsage' ? r['lineItem/UnblendedCost'] : r['reservation/OnDemandCost'],
                adjustedCashCost: r.AdjustedCashCost,
                name: r.Name,
                usageStartDate: r.UsageStartDate,
            } as AllocationRulePreviewGridRow;
        });
    }, []);

    const getPreviewData = useCallback(async () => {
        const rule = ruleEditor.rule as InvoiceAllocationRule;
        const targetType = rule.Disbursement?.Targets?.[0]?.TargetType;
        const dimValues = rule.Disbursement?.Targets?.[0]?.DimensionValues;
        const isExclusion = rule.Disbursement?.Targets?.[0]?.IsDimensionValuesExclusionList ?? false;

        const sourceSamples = getSampleSourceRows();
        const targetSamples = targetType === 'Existing' ? getSampleTargetRows() : getSampleTargetRowsFromSource(dimValues ?? [], isExclusion);

        const [sourceRows, targetRows] = await Promise.all([sourceSamples, targetSamples]);
        const { sourceTotalData, targetTotalData } = await updateWithTotals(sourceRows, targetRows);
        return { sourceRows, targetRows, sourceTotalData, targetTotalData };
    }, []);
    const timeoutHandle = useMemo(() => ({ timeout: 0 }), []);

    const reloadPreviewData = useCallback(() => {
        clearTimeout(timeoutHandle.timeout);
        timeoutHandle.timeout = setTimeout(async () => {
            setLoading(true);
            let results: Partial<Awaited<ReturnType<typeof getPreviewData>>> = {};
            try {
                results = await getPreviewData();
            } finally {
                setLoading(false);
            }
            const { sourceRows, sourceTotalData, targetRows, targetTotalData } = results;
            setSourceDatasource(sourceRows ?? []);
            setSourceTotalDatasource(sourceTotalData ? [sourceTotalData] : []);
            setTargetDatasource(targetRows ?? []);
            setTargetTotalDatasource(targetTotalData ? [targetTotalData] : []);
        }, 500) as unknown as number;
    }, []);

    useEvent(ruleEditor.rulePropsChanged, reloadPreviewData);
    useEffect(reloadPreviewData, []);

    const columns = useMemo(() => {
        let columns: ColumnConfig<AllocationRulePreviewGridRow>[] = [];
        const dimName = ruleEditor.getDimensionName();

        columns.push(
            {
                accessor: 'dimensionKey',
                header: dimName,
                defaultWidth: 200,
                id: 'dimensionKey',
                type: 'string',
                noSort: true,
                noRemove: true,
            },
            {
                accessor: 'name',
                header: `Name`,
                defaultWidth: 250,
                id: 'name',
                type: 'string',
                noSort: true,
                noRemove: true,
            },
            {
                accessor: 'usageStartDate',
                header: `Usage Date`,
                defaultWidth: 125,
                id: 'usageStartDate',
                type: 'date',
                noSort: true,
                noRemove: true,
            },
            {
                accessor: 'invoiceCost',
                header: `Invoice`,
                defaultWidth: 100,
                id: 'invoiceCost',
                type: 'number',
                align: 'right',
                noSort: true,
                noRemove: true,
                cellRenderer: (item) => {
                    return fmtSvc.formatMoneyNonZeroTwoDecimals(item.invoiceCost ?? 0);
                },
            },
            {
                accessor: 'adjustedCashCost',
                header: `Showback`,
                defaultWidth: 100,
                id: 'adjustedCashCost',
                align: 'right',
                type: 'number',
                noSort: true,
                noRemove: true,
                cellRenderer: (item) => {
                    return fmtSvc.formatMoneyNonZeroTwoDecimals(item.adjustedCashCost ?? 0);
                },
            }
        );
        return columns;
    }, []);

    const splitBodyProps = useMemo(() => {
        return [
            {
                renderer: () => <GridTitleSection>Sample Allocation Sources</GridTitleSection>,
            },
            {
                renderer:
                    !loading && sourceDatasource?.length
                        ? undefined
                        : () => (
                              <GridBodySection style={{ flex: 1 }}>
                                  <FillerSwitch loading={loading} noData={!sourceDatasource?.length} noDataMessage="No sources configured">
                                      {() => <></>}
                                  </FillerSwitch>
                              </GridBodySection>
                          ),
                datasource: sourceDatasource ?? [],
                columns: columns,
                style: { background: theme.colors.gray[0], borderTopWidth: 0 },
                scrollable: !targetDatasource?.length,
            },
            {
                datasource: sourceTotalDatasource ?? [],
                columns: columns,
                scrollable: false,
                height: 32,
                style: { background: theme.colors.gray[0], borderTopWidth: 0, fontWeight: 'bold' },
            },
            {
                renderer: () => <GridTitleSection>Sample Disbursement Targets</GridTitleSection>,
            },
            {
                renderer:
                    !loading && targetDatasource?.length
                        ? undefined
                        : () => (
                              <GridBodySection style={{ flex: 1 }}>
                                  <FillerSwitch loading={loading} noData={!targetDatasource?.length} noDataMessage="No targets configured">
                                      {() => <></>}
                                  </FillerSwitch>
                              </GridBodySection>
                          ),
                datasource: targetDatasource ?? [],
                scrollable: true,
                columns: columns,
                style: { background: theme.colors.gray[0], borderTopWidth: 0 },
            },
            {
                datasource: targetTotalDatasource ?? [],
                columns: columns,
                height: 32,
                scrollable: false,
                style: { background: theme.colors.gray[0], borderTopWidth: 0, fontWeight: 'bold' },
            },
        ] as GridSplitBodyProps<AllocationRulePreviewGridRow>[];
    }, [columns, sourceDatasource, targetDatasource, loading]);

    const [dataCount, setDataCount] = useState<ReactNode>('');
    useEffect(() => {
        const count = sourceDatasource.length + targetDatasource.length;
        setDataCount(
            <Text data-atid={'DataGridResults:' + count?.toString()} color="dimmed">
                {fmtSvc.formatInt(count ?? 0)} result{count == 1 ? '' : 's'}
            </Text>
        );
    }, [sourceDatasource, targetDatasource]);

    const attach = useCallback((gridModel: DataGridModel) => {
        setGridModelState(gridModel);
        gridModel.gridStateChanged.listen((change) => {
            previewGridStateChanged.emit(change);
        });
    }, []);

    return (
        <>
            <DataGrid
                columns={columns}
                showCount={false}
                hideFilter
                leftResultsPlaceHolder={dataCount}
                hideMenu={true}
                dataSource={[]}
                minimumLoadingMs={0}
                splitBodyProps={splitBodyProps}
                onModelLoaded={attach}
            />
        </>
    );
}

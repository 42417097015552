import {
    ActionIcon,
    Anchor,
    Box,
    Button,
    ButtonVariant,
    Card,
    Checkbox,
    Collapse,
    Divider,
    FileInput,
    Grid,
    Group,
    Loader,
    MantineTheme,
    Modal,
    NumberInput,
    Select,
    Space,
    Text,
    Tooltip,
    useMantineTheme,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useDi } from '@root/Services/DI';
import { observer } from 'mobx-react';
import { FaqItem } from './Components';
import { StepTitle } from './Design';
import { IAccountGroup, IMapWizardStep, MapContractSetupModel } from './Model';
import { MpeIdInput } from '../Management/Components';
import { validateContractId } from '../Services/MapCostAllocationTagService';
import { useDropzone } from 'react-dropzone';
import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomColors } from '@root/Design/Themes';
import { ChevronDown, ChevronRight, Plus } from 'tabler-icons-react';
import { useLink } from '@root/Services/Router/Router';
import { useNav } from '@root/Services/NavigationService';
import { useDisclosure, useHover } from '@mantine/hooks';
import { AddConnection } from './ConnectionWizard';
import { Clearfix } from '@root/Design/Primitives';
import { MapContractReadResponse } from '@apis/TagManager/model';
function StepContent() {
    const model = useDi(MapContractSetupModel);
    const theme = useMantineTheme();
    const [uploadState, setUploadState] = useState<'uploading' | 'uploaded' | 'error' | undefined>(undefined);
    const onDrop = useCallback(async (acceptedFile: File[]) => {
        setUploadState('uploading');
        var file = acceptedFile[0];
        if (file !== null && file !== undefined) {
            try {
                model.contractFile = file;
                let data = await model.extractDataFromContractDocument();
                setUploadState('uploaded');
                SetContractData(data, model);
            } catch (error) {
                setUploadState('error');
            }
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf'],
        },
        maxFiles: 1,
    });

    const accounts = useMemo(() => {
        return model.accountGroups.slice().sort((a, b) => {
            const isAConnected = model.isAccountConnected(a.management.Id ?? 0);
            const isBConnected = model.isAccountConnected(b.management.Id ?? 0);
            return isAConnected === isBConnected
                ? (a.management.Name ?? a.management.AwsAccountId ?? '').localeCompare(
                      b.management.Name ?? b.management.AwsAccountId ?? '',
                      undefined,
                      { sensitivity: 'base' }
                  )
                : isAConnected
                ? -1
                : 1;
        });
    }, [model.accountGroups, model.loadingConnectivity, model.loadingAccounts]);

    const onConnectionAdded = useCallback(() => {
        model.reloadAccounts();
    }, [model]);

    useEffect(() => {
        let timeoutHandle = 0;
        const recheckConns = () => {
            model.loadConnectionStatus();
            timeoutHandle = window.setTimeout(recheckConns, 3000);
        };
        recheckConns();
        return () => {
            clearTimeout(timeoutHandle);
        };
    }, []);

    return (
        <>
            <StepTitle title="Let's get your MAP Contract Details" />
            <Space h="md" />
            <Card withBorder radius="md" p={32}>
                <Text weight="bolder" align="center">
                    Upload Your MAP Contract
                </Text>
                <Space h="sm" />

                <FileContainer {...getRootProps({ isFocused, isDragAccept, isDragReject, isDragActive, uploadState })} data-atid="MAPContractUpload">
                    <input {...getInputProps()} data-atid="ContractUploadInput" />
                    {uploadState === 'uploaded' ? (
                        <>
                            {' '}
                            <img
                                src="/assets/cloud-upload.svg"
                                style={{
                                    width: '75px',
                                    filter: '',
                                }}
                            />
                            <Space h="md" />
                            <p>Upload Success</p>
                            <Space h="md" />
                            <Button sx={{ margin: '0px auto', display: 'flex' }} onClick={() => setUploadState(undefined)}>
                                Upload Another Contract?
                            </Button>
                        </>
                    ) : uploadState === 'uploading' ? (
                        <>
                            <div style={{ marginTop: '20px', textAlign: 'center', position: 'relative' }}>
                                <Loader></Loader>
                            </div>

                            <Space h="lg" />
                            <p>Extracting Data from Contract</p>
                            <Space h="md" />
                            <p>Please Wait.</p>
                        </>
                    ) : uploadState === 'error' ? (
                        <>
                            <img src="/assets/cloud-upload-not.svg" style={{ width: '75px' }} />
                            <Space h="md" />
                            <p>Something Unexpected Happened</p>
                            <Space h="md" />
                            <p>
                                {' '}
                                <a
                                    style={{
                                        margin: '0px auto',
                                        color: theme.colors.error[7],
                                        textDecoration: 'underline',
                                        textAlign: 'center',
                                        position: 'relative',
                                    }}
                                    onClick={() => setUploadState(undefined)}
                                >
                                    Try Again
                                </a>
                            </p>
                        </>
                    ) : (
                        <>
                            {' '}
                            {isDragReject ? (
                                <img src="/assets/cloud-upload-not.svg" style={{ width: '75px' }} />
                            ) : (
                                <img
                                    src="/assets/cloud-upload.svg"
                                    style={{
                                        width: '75px',
                                        filter: '',
                                    }}
                                />
                            )}
                            <span>[PDF]</span>
                            <p>Drag & Drop</p>
                            <p>MAP Contract Here</p>
                            <p>
                                or <FakeLink onClick={(e) => e.preventDefault}>Browse</FakeLink>
                            </p>
                        </>
                    )}
                </FileContainer>

                <Space h="md" />
                <Text weight="bolder" align="center">
                    {uploadState === 'uploaded' ? 'Please verify details below:' : 'Or, Enter details manually below:'}
                </Text>
                <Space h="md" />
                <Grid>
                    <Grid.Col span={4}>
                        <DatePicker
                            value={model.contract.ContractStartDate}
                            onChange={(d) => (model.contract.ContractStartDate = d)}
                            label="Contract Start Date"
                        />
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Select
                            withinPortal
                            value={(model.contract.ContractTermYears ?? 3).toString()}
                            data={[
                                { value: '1', label: '1 Year' },
                                { value: '2', label: '2 Years' },
                                { value: '3', label: '3 Years' },
                            ]}
                            onChange={(v) => (model.contract.ContractTermYears = parseInt(v ?? '3'))}
                            label="Contract Period"
                        />
                    </Grid.Col>

                    <Grid.Col span={4}>
                        <MpeIdInput value={model.contract.ProgramId ?? ''} onChange={(id) => (model.contract.ProgramId = id)} />
                    </Grid.Col>
                </Grid>
            </Card>
            <Space h="md" />
            <Box px={60} sx={{ alignSelf: 'stretch' }}>
                <StepTitle title="Select accounts covered by this contract" />
                <Group sx={{ float: 'right' }} position="center" py="lg">
                    <AddManagementConnection onConnectionAdded={onConnectionAdded} buttonVariant="white" iconSize={20} />
                </Group>
                <Clearfix></Clearfix>
                {accounts.length ? (
                    accounts
                        .sort((a, b) => {
                            if (!model.isAccountInUse(a.management.AwsAccountId!) && model.isAccountInUse(b.management.AwsAccountId!)) return -1;
                            if (model.isAccountInUse(a.management.AwsAccountId!) && !model.isAccountInUse(b.management.AwsAccountId!)) return 1;
                            return 0;
                        })
                        .map((g) => <MgmtAccount key={g.management.Id} acctGroup={g} model={model} />)
                ) : (
                    <MgmtAccountEmpty />
                )}
            </Box>
        </>
    );
}
function SetContractData(data: MapContractReadResponse, model: MapContractSetupModel) {
    if (data.Accounts !== null && data.Accounts !== undefined) {
        model.contract.AccountIds = data.Accounts;
    }

    if (data.StartDate !== null && data.StartDate !== undefined) {
        model.contract.ContractStartDate = new Date(data.StartDate);
    }

    if (data.EndDate !== null && data.EndDate !== undefined) {
        model.contract.ContractEndDate = data.EndDate;
    }

    if (data.TermYears !== null && data.TermYears !== undefined) {
        model.contract.ContractTermYears = data.TermYears;
    } else if (data.StartDate !== null && data.StartDate !== undefined && data.EndDate !== null && data.EndDate !== undefined) {
        var start = new Date(data.StartDate);
        var end = new Date(data.EndDate);
        var years = end.getFullYear() - start.getFullYear();
        model.contract.ContractTermYears = years;
    }

    if (data.ContractId !== null && data.ContractId !== undefined) {
        model.contract.ProgramId = data.ContractId;
    }

    if (data.MaxCredits !== null && data.MaxCredits !== undefined) {
        var parsedAmount = parseDollarAmount(data.MaxCredits);
        if (parsedAmount !== null && parsedAmount !== undefined) {
            model.contract.MaximumContractValue = parsedAmount;
        }
    }

    if (data.AnnualSpend !== null && data.AnnualSpend !== undefined) {
        var parsedAmount = parseDollarAmount(data.AnnualSpend);
        if (parsedAmount !== null && parsedAmount !== undefined) {
            model.contract.AnticipatedAnnualSpend = parsedAmount;
        }
    }
}

function parseDollarAmount(dollarAmount: string): number | null {
    const cleanedString = dollarAmount.replace(/\$|,/g, '');

    const parsedNumber = parseFloat(cleanedString);

    if (!isNaN(parsedNumber)) {
        return parsedNumber;
    } else {
        return null;
    }
}

export function AddManagementConnection({
    onConnectionAdded,
    buttonVariant,
    iconSize,
    hideIcon,
    buttonText,
}: {
    onConnectionAdded: (accountId: string) => void;
    buttonVariant: ButtonVariant;
    iconSize: number;
    hideIcon?: boolean;
    buttonText?: string;
}) {
    const theme = useMantineTheme();
    const [opened, { open, close, toggle }] = useDisclosure(false);
    const onComplete = (accountId: string) => {
        onConnectionAdded(accountId);
        close();
    };

    return (
        <>
            <Button
                variant={buttonVariant}
                radius="lg"
                size="sm"
                leftIcon={(hideIcon == undefined || !hideIcon) && <Plus size={iconSize} />}
                onClick={open}
            >
                {buttonText == undefined ? 'Add Management Account' : buttonText}
            </Button>
            <Modal
                sx={{ '[role=dialog]': { background: theme.colors.gray[1] } }}
                closeOnClickOutside={false}
                opened={opened}
                onClose={close}
                size={900}
                withCloseButton={false}
                radius="lg"
            >
                {opened ? <AddConnection onClose={close} onComplete={onComplete} /> : null}
            </Modal>
        </>
    );
}

function StepHelp() {
    return (
        <>
            <FaqItem question="Why do you need this information?">
                You are only eligible to receive MAP credits for resources that are migrated during the MAP contract period. By capturing the contract
                start date and duration we are able to identify the resources that meet this criteria. Additionally, the MPE ID provides us with the
                unique MAP contract identifier that becomes part of the tag value we assign to eligible resources.
            </FaqItem>
            <FaqItem question="Where can I locate the requested information?">
                All the information requested on this page can be obtained directly from your MAP contract. See a sample contract indicating the
                specific location of the requested information.
            </FaqItem>
            <FaqItem question="What's my Contract Start Date?">
                The contract start date is the "Anticipated Migration Start Date" as indicated in your MAP contract.
            </FaqItem>
            <FaqItem question="What's my MPE ID?">
                The Migration Program Engagement number (MPE ID) is the unique 5-digit or 10-digit identifier AWS assigns to each MAP contract. This
                number is an important component of the tag value assigned to each eligible resource. It is generally located on the top of your MAP
                contract.
            </FaqItem>
            <FaqItem question="What are management accounts?">
                Management accounts are part of AWS Organizations and have full control over the entire organization's resources. They are responsible
                for managing billing, security, and access control. They can create and manage member accounts, set up organizational policies, and
                centrally manage billing and payment methods for all accounts in the organization. The management account is not typically used for
                day-to-day operational tasks or running workloads.
            </FaqItem>
            <FaqItem question="What are member accounts?">
                Member accounts are part of AWS Organizations and are linked to a management account. These accounts are separate AWS accounts that
                have their own resources, services, and billing. Member accounts are primarily used for running workloads, hosting applications, and
                managing resources specific to a business unit, project team, or individual application. Each member account has its own set of
                credentials and access controls, enabling independent management of resources and services. Member accounts can be consolidated under
                the AWS organization to centralize billing, enable cross-account resource sharing, and enforce organizational policies.
            </FaqItem>
            <FaqItem question="What do I do if my management account is not shown?">
                If your management account is not displayed on the screen then it is not currently connected to this application. You can connect a
                new management account by visiting the Connections Management screen and following the prompts to add a new AWS connection.
            </FaqItem>
            <FaqItem question="What does it mean if my member account is not connected?">
                Member accounts must be connected individually. If your member account is not connected then we cannot help you comply with your MAP
                contract within that particular member account. You can connect any member account by downloading the most current IAM Permissions
                Policy from our Connection Health Page, and applying it to the unconnected account in AWS
            </FaqItem>
            <FaqItem question="Why do we need this information?">
                Linking a management account to a MAP contract enables us to identify which specific accounts and resources are eligible for credits
                under your MAP contract.
            </FaqItem>
            <FaqItem question="Location of Requested Information">
                All the information requested on this page can be obtained directly from your MAP contract. See a sample contract indicating the
                specific location of the requested information.
            </FaqItem>
        </>
    );
}

export const Step1Contract = {
    Content: observer(StepContent),
    Help: StepHelp,
    canProceed: (model: any) => validateContractId(model.contract.ProgramId) && model.contract.AccountIds.length > 0,
} as IMapWizardStep;

function MgmtAccountEmpty() {
    return (
        <>
            <Space h="xl" />
            <Card radius="lg" py={60} withBorder sx={{ borderStyle: 'dashed', borderWidth: 2 }}>
                <Text color="dimmed" italic align="center">
                    No management accounts found
                </Text>
            </Card>
        </>
    );
}

const MgmtAccount = observer(function MgmtAccount({ acctGroup, model }: { acctGroup: IAccountGroup; model: MapContractSetupModel }) {
    const account = acctGroup.management;
    const memberAccts = acctGroup.members;
    const isInUse = model.isAccountInUse(acctGroup.management.AwsAccountId ?? '');
    const contract = model.existingContracts.find((c) => c.AccountIds?.includes(acctGroup.management.AwsAccountId ?? ''));
    const theme = useMantineTheme();
    const { getDescendUrl } = useNav();
    const { ref: clickableRef, hovered } = useHover();
    const link = useLink();
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const backgroundColor = isInUse ? theme.colors.gray[5] : theme.colors.gray[0];

    const contractEndDateCalc = () => {
        if (contract?.ContractEndDate != null && contract?.ContractEndDate != undefined) {
            return contract.ContractEndDate.toLocaleString();
        } else {
            if (contract?.ContractStartDate && contract.ContractTermYears) {
                let startDate = new Date(Date.parse(contract.ContractStartDate));
                let years = contract?.ContractTermYears;
                let endDate = startDate.setFullYear(startDate.getFullYear() + years);
                return new Date(endDate).toLocaleDateString();
            }
        }
    };

    return (
        <Card p={0} radius="md" my="lg" withBorder>
            {isInUse && (
                <Box pt="xs" sx={{ backgroundColor: backgroundColor, paddingBottom: '5px' }}>
                    <Text color={theme.colors.gray[0] as CustomColors} sx={{ fontSize: '.8rem', marginLeft: '10px' }}>
                        The <strong>{acctGroup.management.Name}</strong> account is linked to MPE ID {contract?.ProgramId}
                        {contractEndDateCalc() ? `, expires ${contractEndDateCalc()}` : ''}
                    </Text>
                </Box>
            )}
            <Divider color="gray.3" />
            <Box px={20} py="sm" sx={{ maxHeight: '120px', overflow: 'auto' }}>
                <Group position="apart">
                    <Group align="flex-start" noWrap sx={{ cursor: 'pointer' }} onClick={() => model.toggleAccountSelected(account)}>
                        <Box pt="xs">
                            <Checkbox
                                sx={{ input: { borderColor: theme.colors.primary[6], borderWidth: hovered ? 2 : 1 } }}
                                checked={model.isAccountSelected(account)}
                            />
                        </Box>
                        <Box>
                            <Text size="xs" weight="bolder">
                                Management Account
                            </Text>

                            <Text>
                                {account.AwsAccountId
                                    ? `${account.AwsAccountId} - ${account.Name ?? 'No Account Name'}`
                                    : 'Management Account not found'}
                            </Text>
                            <Text size="xs">
                                Status:{' '}
                                {model.isAccountConnected(account.Id ?? 0) ? (
                                    <Text span weight="bold" size="xs">
                                        Connected
                                    </Text>
                                ) : (
                                    <Tooltip label="Fix connection issues">
                                        <Anchor size="xs" {...link(getDescendUrl('connection-health'))}>
                                            Not Connected
                                        </Anchor>
                                    </Tooltip>
                                )}
                            </Text>
                        </Box>
                    </Group>
                    {!memberAccts.length ? (
                        <></>
                    ) : (
                        <Group spacing={0} sx={{ cursor: 'pointer' }} onClick={() => setIsExpanded(!isExpanded)}>
                            <Text size="sm">{`${memberAccts.length} member account${memberAccts.length > 1 ? 's' : ''}`}</Text>
                            <ActionIcon>{isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}</ActionIcon>
                        </Group>
                    )}
                </Group>
            </Box>
            <Collapse in={isExpanded}>
                <Divider color="gray.3" />
                <Box px={20} py="sm" sx={{ maxHeight: '120px', overflow: 'auto', marginLeft: '35px' }}>
                    <Text size="xs" weight="bolder" sx={{ leftMargin: '100px' }}>
                        Member Account
                    </Text>
                    {!memberAccts.length ? (
                        <Text size="sm" italic color="dimmed">
                            No member accounts
                        </Text>
                    ) : (
                        memberAccts.map((acct) => (
                            <Group position="apart">
                                <Text size="sm">{`${acct.AwsAccountId} - ${acct.Name ?? 'No Account Name'}`}</Text>
                                {model.isAccountConnected(acct.Id ?? 0) ? (
                                    <Text size="sm">Connected</Text>
                                ) : (
                                    <Tooltip label="Fix connection issues">
                                        <Anchor size="sm" {...link(getDescendUrl('connection-health'))}>
                                            Not Connected
                                        </Anchor>
                                    </Tooltip>
                                )}
                            </Group>
                        ))
                    )}
                </Box>
            </Collapse>
        </Card>
    );
});

const FileContainer = styled.div`
    width: 50%;
    margin: 0px auto;
    border: 2px dashed ${(p) => getColor(p)};
    height: 200px;
    background-color: ${(p) => getBackgroundColor(p)};

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 800;
        color: ${(p) => getLinkTextColor(p, p.theme)};
    }

    img {
        margin: 10px auto;
        display: flex;
        filter: ${(p) => getSvgFilter(p)}};
    }

    p {
        text-align: center;
        font-size: 14px;
        line-height: 0.25;
        font-weight: 800;
    }
`;

const FakeLink = styled.p`
    color: ${(p) => getLinkTextColor(p, p.theme)};
    text-decoration: underline;

    :hover {
        color: ${(p) => p.theme.colors.primary[8]};
        cursor: pointer;
    }
`;

const getLinkTextColor = (props: any, theme: MantineTheme) => {
    if (props.uploadState! === 'uploaded') {
        return '#fff';
    }

    if (props.isDragReject || props.uploadState! === 'error') {
        return theme.colors.error[7];
    }

    return theme.colors.primary[6];
};

const getColor = (props: any) => {
    const theme = useMantineTheme();

    if (props.isDragReject || props.uploadState! === 'error') {
        return theme.colors.error[5];
    }
    if (props.isFocused || props.isDragActive || props.uploadState! === 'uploaded') {
        return theme.colors.primary[5];
    }
    return theme.colors.gray[4];
};

const getBackgroundColor = (props: any) => {
    const theme = useMantineTheme();
    if (props.isDragReject || props.uploadState! === 'error') {
        return theme.colors.error[2];
    }
    if (props.isFocused || props.isDragActive || props.uploadState! === 'uploaded') {
        return theme.colors.primary[2];
    }
    return '#fff';
};

const getSvgFilter = (props: any) => {
    if (props.isDragReject || props.uploadState! === 'error') {
        return 'invert(21%) sepia(44%) saturate(6355%) hue-rotate(354deg) brightness(73%) contrast(90%)';
    }

    return 'invert(74%) sepia(48%) saturate(6723%) hue-rotate(162deg) brightness(86%) contrast(107%)';
};

import { Box, Divider } from '@mantine/core';
import { ResourcesGrid } from '@root/Components/Resources/ResourcesGrid';
import { PageContent, PageSection } from '@root/Design/Layout';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { ResourcesNav } from '../Settings/ResourcesNav';

function OverviewBody() {
    const { type } = useNav().getData('type');
    if (type == undefined) {
        return (
            <div id="div_NoResourceType">
                <img src="/assets/Illustration2.svg" />
                <br />
                <br />
                Please select a resource type from the menu on the left.
            </div>
        );
    } else {
        return <ResourcesGrid key={type} resourceType={type} />;
    }
}

export function Overview() {
    return (
        <>
            <Box sx={{ display: 'flex', height: '100%' }}>
                <Box sx={{ flex: 0, height: '100%', overflow: 'auto', minWidth: 250 }}>
                    <ResourcesNav />
                </Box>
                <Divider orientation="vertical" />
                <PageContent>
                    <PageSection height="full">
                        <OverviewBody />
                    </PageSection>
                </PageContent>
            </Box>
        </>
    );
}
endpoint('overview', Overview, 'Resources');

import { Box, Card, Group, Title, Button } from '@mantine/core';
import { PageBody, PageContent, PaneledPage, PanelHeader } from '@root/Design/Layout';
import { DataMarketplaceApiService, ScopedRelationshipBundleService } from '@root/Services/DataMarketplace/DataMarketplaceApiService';
import { useDi } from '@root/Services/DI';
import { useEvent, useEventValue } from '@root/Services/EventEmitter';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { IRelationshipDataPageProps } from '../../RelationshipDataModels';
import { ChipMfgMarketshareModel, DisplayMode } from './ComputeMktshrModels';
import { CspFilter, DateRangePicker, DisplayModePicker, MspBreadCrumb } from './ComputeMktshrComponents';
import { ChipMfgChart } from './ComputeMktshrChart';
import { ChipMfgGrid } from './ComputeMktshrGrid';
import { FillerSwitch } from '@root/Design/Filler';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { ParamsScopedDataDef, useParamsScopedDataDef } from '../../RelationshipDataComponents';
import { useUrlDateRangeParams } from '@root/Components/Picker/DateRangePicker';
import { FormatService } from '@root/Services/FormatService';

export function ComputeMarketshareLandingPage(props: IRelationshipDataPageProps) {
    const scopedParams = useParamsScopedDataDef();
    const { loading, participantType, relationshipId } = scopedParams;
    const showOverview = participantType === 'primary' && !relationshipId;
    return (
        <FillerSwitch key={relationshipId} loading={loading} noData={!loading && !participantType}>
            {() => (showOverview ? <ComputeMktBeneficiaryOverview {...scopedParams} /> : <ComputeMktOwnerDetails {...scopedParams} />)}
        </FillerSwitch>
    );
}

function ComputeMarketshareBreadcrumb() {
    const scopedParams = useParamsScopedDataDef();
    const { loading, digest, participants, participantType, relationshipId, relationships } = scopedParams;

    const label = loading ? 'Loading...' : !participants?.related ? digest?.Name : participants?.related.Name;
    const showPicker = !(participantType === 'primary' && !relationshipId);
    return (
        <>
            {relationships && showPicker ? (
                <MspBreadCrumb relationships={relationships} relationshipId={relationshipId}></MspBreadCrumb>
            ) : (
                label ?? 'Unknown'
            )}
        </>
    );
}

endpoint('cpu-mktshr', ComputeMarketshareLandingPage, ComputeMarketshareBreadcrumb);

function ComputeMktBeneficiaryOverview(props: ParamsScopedDataDef) {
    return <ComputeMktshrLayout scopedDataDefBundle={props} title="Compute Marketshare" displayModes={['msp']} />;
}

function ComputeMktOwnerDetails(props: ParamsScopedDataDef) {
    const { participantType, participants } = props;
    const title = participantType === 'related' || !participants?.related ? 'Compute Marketshare' : `${participants.related.Name} Compute`;
    return <ComputeMktshrLayout title={title} scopedDataDefBundle={props} displayModes={['company', 'instance-type']} />;
}

interface IChipMfgMarketshareProps extends IRelationshipDataPageProps {
    scopedDataDefBundle: ParamsScopedDataDef;
    displayModes: DisplayMode[];
    title: string;
}
export function ComputeMktshrLayout({ scopedDataDefBundle, displayModes, title }: IChipMfgMarketshareProps) {
    const { definition, relationships, relationshipId, marketplaceSvc: mktApiSvc } = scopedDataDefBundle;
    const company = useCompany();
    const fmtSvc = useDi(FormatService);

    const relSvc = useMemo(
        () => new ScopedRelationshipBundleService(definition?.Id!, company!, relationships!, mktApiSvc, relationshipId),
        [definition?.Id, relationships, company]
    );
    const chipSvc = useMemo(() => new ChipMfgMarketshareModel(relSvc, fmtSvc, displayModes[0]).init(), [relSvc]);
    const availDateRange = useEventValue(chipSvc.availDateRange);
    const cspFilter = useEventValue(chipSvc.cspFilter) ?? '';
    const displayMode = useEventValue(chipSvc.displayMode);
    useEvent(chipSvc.selectedDateRange);

    const { value: dateRange, setValue: setDateRange } = useUrlDateRangeParams({ onInit: chipSvc.selectedDateRange.emit });

    useEffect(() => {
        if (dateRange.from && dateRange.to) {
            chipSvc.selectedDateRange.emit(dateRange);
        }
    }, [JSON.stringify(dateRange)]);

    const pageElRef = useRef<HTMLDivElement>(null);

    return (
        <PageBody>
            <PageContent ref={pageElRef}>
                <PaneledPage>
                    <Box p="lg" sx={{ gap: 24, height: '100%', width: '100%', display: 'flex', flexDirection: 'column', minHeight: 850 }}>
                        <PanelHeader style={{ flex: 0, padding: 0 }}>
                            <Title order={3}>{title}</Title>
                            <Group sx={{ rowGap: 10 }} position="right">
                                {displayModes.length < 2 ? null : <DisplayModePicker value={displayMode!} onSelect={chipSvc.updateDisplayMode} />}
                                <CspFilter selected={cspFilter} onSelect={chipSvc.cspFilter.emit} />
                                <DateRangePicker constraint={availDateRange} loading={!availDateRange} onSelect={setDateRange} />
                            </Group>
                        </PanelHeader>
                        <Card withBorder sx={{ flexGrow: 1, minHeight: 250 }} radius="md">
                            <ChipMfgChart chipSvc={chipSvc} />
                        </Card>
                        <ChipMfgGrid scrollContainer={pageElRef.current} chipSvc={chipSvc} />
                    </Box>
                </PaneledPage>
            </PageContent>
        </PageBody>
    );
}

/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.TagManager.Host.Api
 * OpenAPI spec version: 1.0
 */

export type MapCreditsOptionsMapCreditType = typeof MapCreditsOptionsMapCreditType[keyof typeof MapCreditsOptionsMapCreditType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MapCreditsOptionsMapCreditType = {
  OnPrem: 'OnPrem',
  OnPremCommercial: 'OnPremCommercial',
  OnPremNonCommercial: 'OnPremNonCommercial',
  Ec2Commercial: 'Ec2Commercial',
  Ec2NonCommercial: 'Ec2NonCommercial',
  SAP: 'SAP',
  ORACLE: 'ORACLE',
} as const;

import { Button, Popover, Text, Menu, Divider, Group, Stack, Sx, Anchor, Center, Loader } from '@mantine/core';
import { DateRangePicker as CommonDateRangePicker, useUrlDateRangeParams } from '@root/Components/Picker/DateRangePicker';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { startOfMonth, addMonths, startOfQuarter, addQuarters, startOfYear, addYears, addDays, endOfMonth, endOfQuarter, endOfYear } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Check, ChevronDown } from 'tabler-icons-react';
import { stackedLabelSx } from './ComputeMktshrDesign';
import { DisplayMode } from './ComputeMktshrModels';
import { CompanyRelationshipBundle, RelatedCompanyItem } from '@apis/Customers/model';
import { useDisclosure } from '@mantine/hooks';
import { useNav } from '@root/Services/NavigationService';
import { Picker } from '@root/Components/Picker/Picker';
import styled from '@emotion/styled';

//#region Page toolbar
export function DisplayModePicker({ value, onSelect }: { value: DisplayMode; onSelect: (value: DisplayMode) => void }) {
    return (
        <Button.Group>
            <Button {...stackedLabelSx()} variant={value === 'instance-type' ? 'filled' : 'default'} onClick={() => onSelect('instance-type')}>
                <Text size="xs" sx={{ lineHeight: 1.5 }}>
                    Instance
                </Text>
                <Text size="xs" sx={{ lineHeight: 1 }}>
                    Type
                </Text>
            </Button>
            <Button variant={value === 'company' ? 'filled' : 'default'} onClick={() => onSelect('company')}>
                Company
            </Button>
            <Button variant={value === 'custom' ? 'filled' : 'default'} onClick={() => onSelect('custom')}>
                Custom
            </Button>
        </Button.Group>
    );
}

export function CspFilter({ selected, onSelect }: { selected: string; onSelect: (value: string) => void }) {
    const options = [
        { label: 'All', value: '' },
        { label: 'AWS', value: 'AWS' },
        { label: 'Azure', value: 'Azure' },
    ].map((o) => ({
        ...o,
        select: () => onSelect(o.value),
        selected: o.value === (selected ?? ''),
    }));
    const itemStyle: Sx = { svg: { opacity: 0.15 }, '&.selected svg, :hover svg': { opacity: 1 } };

    return (
        <Menu shadow="md" width={130}>
            <Menu.Target>
                <Button variant="default" sx={{ ...stackedLabelSx().sx, width: 100 }}>
                    <Text size="xs" weight="normal">
                        Cloud
                    </Text>{' '}
                    {!selected ? 'All' : selected}
                </Button>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Label>Cloud Provider</Menu.Label>
                {options.map((o) => (
                    <Menu.Item className={o.selected ? 'selected' : ''} sx={itemStyle} key={o.label} icon={<Check size={16} />} onClick={o.select}>
                        {o.label}
                    </Menu.Item>
                ))}
            </Menu.Dropdown>
        </Menu>
    );
}

export function DateRangePicker({
    constraint,
    onSelect,
    loading,
}: {
    constraint?: { min?: Date; max?: Date };
    loading: boolean;
    onSelect: (value: { from?: Date; to?: Date }) => void;
}) {
    const defaultSelection = useMemo(() => ({ from: constraint?.min, to: constraint?.max }), [constraint]);
    const { value, onChanged } = useUrlDateRangeParams({ handleChanged: onSelect });

    return loading || !constraint ? null : (
        <>
            <CommonDateRangePicker
                height={36}
                emptyLabel="All dates"
                corners={3}
                constraint={{ ...constraint, unenforced: true }}
                value={value?.from && value?.to ? value : defaultSelection ?? {}}
                onChange={onChanged}
                mode="calendar"
                onRenderCalendar={(calendar, close) => (
                    <>
                        <Group noWrap>
                            <DateRangePopoverPresets close={close} onSelect={onChanged} value={value} />
                            <Divider orientation="vertical" />
                            {calendar}
                        </Group>
                    </>
                )}
            />
        </>
    );
}

function DateRangePopoverPresets(props: {
    value?: { from?: Date; to?: Date };
    onSelect: (value: { from: Date; to: Date }) => void;
    close: () => void;
}) {
    const { value, onSelect, close } = props;
    const fmtSvc = useDi(FormatService);
    const getRangeKey = (range: { from: Date; to: Date }) => `${fmtSvc.to8DigitDate(range.from)}-${fmtSvc.to8DigitDate(range.to)}`;
    const options = useMemo(() => {
        const optionMeta = [
            { label: 'Current Month', startOf: endOfMonth, getFrom: () => startOfMonth(new Date()), backoff: 0 },
            { label: 'Current Quarter', startOf: endOfQuarter, add: addQuarters, backoff: 0 },
            { label: 'Current Year', startOf: endOfYear, add: addYears, backoff: 0 },
            { label: 'Last Month', startOf: startOfMonth, add: addMonths, backoff: -1 },
            { label: 'Last Quarter', startOf: startOfQuarter, add: addQuarters, backoff: -1 },
            { label: 'Last Year', startOf: startOfYear, add: addYears, backoff: -1 },
        ];
        const baseDate = new Date();
        return optionMeta.map(({ label, startOf, add, backoff, getFrom }) => {
            const baseTo = startOf(baseDate);
            const to = backoff ? addDays(baseTo, backoff) : baseTo;
            const from = add ? addDays(add(to, -1), 1) : getFrom ? getFrom() : to;
            return { label, from, to, key: getRangeKey({ from, to }) };
        });
    }, []);
    const selectedRangeKey = !value?.from || !value?.to ? '' : getRangeKey({ from: value.from!, to: value.to! });
    const selectedRange = !value?.from || !value?.to ? null : options.find((item) => selectedRangeKey === item.key)?.label;

    const handleClick = (range: { from: Date; to: Date }) => {
        onSelect(range);
        close();
    };

    return (
        <Stack spacing="xs">
            {options.map(({ label, ...range }) => (
                <Button size="sm" variant={label === selectedRange ? 'outline' : 'white'} key={label} onClick={() => handleClick(range)}>
                    {label}
                </Button>
            ))}
        </Stack>
    );
}

//#endregion

//#region BreadCrumb
export function MspBreadCrumb(props: { relationships?: CompanyRelationshipBundle; relationshipId?: number }) {
    const [pickerOpen, { close, open, toggle }] = useDisclosure(false);
    const [selectedMsp, setSelectedMsp] = useState<RelatedCompanyItem>();

    useEffect(() => {
        const companyId = props.relationships?.Relationships?.find((r) => r.Id === props.relationshipId)?.RelatedCompanyId;
        if (companyId) {
            setSelectedMsp(props.relationships?.Companies?.find((c) => c.CompanyId === companyId));
        }
    }, [props.relationshipId]);

    return (
        <>
            <Popover opened={pickerOpen} position="bottom-start" closeOnClickOutside onClose={close} shadow="lg" withArrow withinPortal>
                <Popover.Target>
                    <Anchor sx={{ display: 'flex' }} onClick={toggle}>
                        <Text mr={4}>{selectedMsp === undefined ? 'Loading' : selectedMsp === null ? 'Invalid' : selectedMsp.Name}</Text>
                        <ChevronDown />
                    </Anchor>
                </Popover.Target>
                <Popover.Dropdown p={0}>
                    {pickerOpen ? (
                        <MspCompanyPicker onClose={close} relationships={props.relationships} relationshipId={props.relationshipId} />
                    ) : null}
                </Popover.Dropdown>
            </Popover>
        </>
    );
}

function MspCompanyPicker({
    onClose,
    relationships,
    relationshipId,
}: {
    onClose: () => void;
    relationships?: CompanyRelationshipBundle | undefined;
    relationshipId?: number;
}) {
    const nav = useNav();
    const { range } = nav.getData('range');
    const { id } = nav.getData('id');
    const [companies, setCompanies] = useState<RelatedCompanyItem[]>();

    useEffect(() => {
        if (relationships) {
            const companyIds = relationships.Relationships?.map((r) => r.RelatedCompanyId);
            const companies = relationships.Companies?.filter((c) => companyIds?.includes(c.CompanyId));
            const relationshipIdCompanyId = relationships.Relationships?.find((r) => r.Id === relationshipId)?.RelatedCompanyId;
            setCompanies(companies?.filter((c) => c.CompanyId !== relationshipIdCompanyId));
        }
    }, []);

    const onSelect = useCallback((companies: RelatedCompanyItem[]) => {
        const selected = companies[0];
        const selectedRelationship = relationships?.Relationships?.find((r) => r.RelatedCompanyId === selected.CompanyId);
        const relId = (selectedRelationship?.Id ?? 0).toString();
        const params = {
            id: id ?? '',
            range: range ?? '',
            relId: relId,
        };
        const url = nav.getMoveUrl('cpu-mktshr', { ...params, relId: relId });
        //const url = nav.getDescendUrl('cpu-mktshr', { ...params, relId: relId });
        // const currentParams = nav.getData();
        // nav.mergeParams({ ...currentParams, relId: relId });
        nav.goto(url);
        onClose();
    }, []);

    return (
        <PickerContainer>
            <Picker
                items={companies ?? []}
                mode="single"
                selections={[]}
                minimizeHeight
                height={400}
                width={400}
                renderItem={(item) => {
                    return (
                        <CompanyPickerItem>
                            <Text color="primary" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {item.Name}
                            </Text>
                        </CompanyPickerItem>
                    );
                }}
                nameAccessor={(item) => item.Name ?? ''}
                onChange={onSelect}
            />
            {relationships === undefined ? (
                <Center my="lg">
                    <Loader />
                </Center>
            ) : null}
        </PickerContainer>
    );
}

const PickerContainer = styled.div`
    .picker-item {
        border-bottom: solid 1px ${(p) => p.theme.colors.gray[3]};
        padding: 0;
        cursor: pointer;
        > div {
            border-radius: 0;
            padding: 0;
            height: 100%;
        }
        :hover > div {
            background: ${(p) => p.theme.colors.primary[1]};
        }
    }
    .picker-item-text {
        flex: 1;
    }
`;

const CompanyPickerItem = styled.div`
    display: grid;
    grid-template-columns: auto 100px;
    width: 100%;
    .state {
        justify-self: center;
        display: flex;
        align-items: center;
    }
`;
//#endregion

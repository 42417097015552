import { Box, Button, Checkbox, Divider, Group, Popover, Space, Switch, Text } from '@mantine/core';
import { observer, useObserver } from 'mobx-react';
import styled from '@emotion/styled';
import { RuleEditCard } from './Design';
import { RuleEditor, TagLookupService } from './Model';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDi } from '@root/Services/DI';
import { Picker } from '@root/Components/Picker/Picker';
import { VisibleSpaces } from '@root/Components/Text/VisibleSpaces';
import { AnchorButton } from '@root/Design/Primitives';
import { Tag } from 'tabler-icons-react';
import { useDisclosure } from '@mantine/hooks';
import { FieldPicker } from '@root/Components/Picker/FieldPicker';

export const SyntaxActionCard = observer(function SyntaxActionCard({ ruleEditor }: { ruleEditor: RuleEditor }) {
    const rule = useMemo(() => {
        ruleEditor.rule ??= {};
        ruleEditor.rule.Parameters ??= {};
        ruleEditor.rule.Parameters.Syntax ??= {};
        ruleEditor.rule.Parameters.Syntax.SyntaxOptions ??= { ApplyTagValues: true };
        return ruleEditor.rule.Parameters.Syntax.SyntaxOptions;
    }, [ruleEditor.rule]);
    const tagLookupSvc = useDi(TagLookupService);
    const keyPickerDescription = useMemo(() => {
        const count = rule.TagKeysToApplyTo?.length ?? 0;
        return !count ? 'All keys' : `For ${count} key${count > 1 ? 's' : ''}`;
    }, [rule.TagKeysToApplyTo?.length, rule.ApplyTagKeys]);

    const valuePickerDescription = useMemo(() => {
        const count = rule.TagValuesToApplyTo?.length ?? 0;
        return !count ? 'All values' : `For ${count} value${count > 1 ? 's' : ''}`;
    }, [rule.TagValuesToApplyTo?.length, rule.ApplyTagValues]);

    const [keyPickerOpened, { close: closeKeyPicker, toggle: toggleKeyPicker }] = useDisclosure(false);
    const [valuePickerOpened, { close: closeValuePicker, toggle: toggleValuePicker }] = useDisclosure(false);
    const keyPickerOptions: string[] = tagLookupSvc.keys;
    const valuePickerOptions: string[] = tagLookupSvc.values;
    const clearKeyPicker = useCallback(() => {
        rule.TagKeysToApplyTo?.splice(0, Infinity);
        closeKeyPicker();
    }, [closeKeyPicker, rule]);

    const clearValuePicker = useCallback(() => {
        rule.TagValuesToApplyTo?.splice(0, Infinity);
        closeValuePicker();
    }, [closeValuePicker, rule]);

    useEffect(() => {}, []);

    const onChange = useCallback(() => {
        const issues: string[] = [];
        const options = ruleEditor.rule?.Parameters?.Syntax?.SyntaxOptions;
        if (![options?.ApplyTagKeys, options?.ApplyTagValues].includes(true)) {
            issues.push('Neither Keys nor Values are selected');
        }
        if (
            ![
                options?.CapitalizeAll,
                options?.CapitalizeFirstLetter,
                options?.LowercaseAll,
                options?.LowercaseFirstLetter,
                options?.RemoveSpacesAtEnd,
                options?.RemoveSpacesAtStart,
                options?.ReplaceEmptySpacesWithUnderScores,
            ].includes(true)
        ) {
            issues.push('No actions are selected');
        }
        ruleEditor.setTypeErrors('SyntaxCorrection', issues);
    }, [ruleEditor]);
    useObserver(onChange);

    return (
        <RuleEditCard accent title="Select Actions" description="Select at least one syntax correction to apply">
            <ActionsGroup>
                <FormOptionGroup>
                    <Checkbox
                        label={
                            <>
                                Remove spaces at the <Bold>beginning</Bold> of a tag
                            </>
                        }
                        checked={!!rule.RemoveSpacesAtStart}
                        onChange={(v) => (rule.RemoveSpacesAtStart = v.target.checked || null)}
                    />
                    <Checkbox
                        label={
                            <>
                                Remove spaces at the <Bold>end</Bold> of a tag
                            </>
                        }
                        checked={!!rule.RemoveSpacesAtEnd}
                        onChange={(v) => (rule.RemoveSpacesAtEnd = v.target.checked || null)}
                    />
                    <Checkbox
                        label='Change empty spaces in a tag to underscores "_"'
                        checked={!!rule.ReplaceEmptySpacesWithUnderScores}
                        onChange={(v) => (rule.ReplaceEmptySpacesWithUnderScores = v.target.checked || null)}
                    />
                    <Checkbox
                        label="Capitalize the first letter of a tag"
                        checked={!!rule.CapitalizeFirstLetter}
                        onChange={(v) => (rule.CapitalizeFirstLetter = v.target.checked || null)}
                    />
                </FormOptionGroup>
                <ActionDivider />
                <ActionScope>
                    <Text size="sm" my={10}>
                        Apply corrections to:
                    </Text>
                    <Group>
                        <Box>
                            <Box pt="sm" sx={{ minHeight: '42px' }}>
                                <Switch label="Tag Keys" checked={!!rule.ApplyTagKeys} onChange={(v) => (rule.ApplyTagKeys = v.target.checked)} />
                            </Box>
                            <Box pt="sm" sx={{ minHeight: '42px' }}>
                                <Switch
                                    label="Tag Values"
                                    checked={!!rule.ApplyTagValues}
                                    onChange={(v) => (rule.ApplyTagValues = v.target.checked)}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <Box pt="sm" sx={{ minHeight: '42px' }}>
                                <Popover
                                    opened={keyPickerOpened}
                                    onClose={closeKeyPicker}
                                    withinPortal
                                    shadow="lg"
                                    offset={0}
                                    position="bottom"
                                    withArrow
                                >
                                    <Popover.Target>
                                        <AnchorButton text={keyPickerDescription} onClick={toggleKeyPicker} icon={<Tag />} />
                                    </Popover.Target>
                                    <Popover.Dropdown p={0}>
                                        <Picker
                                            height={200}
                                            width={300}
                                            items={keyPickerOptions}
                                            onChange={(c) => (rule.TagKeysToApplyTo = c)}
                                            onFilterChange={(c) => {
                                                tagLookupSvc.getTagKeysWithFilter(c);
                                            }}
                                            selections={(rule.TagKeysToApplyTo ??= [])}
                                            childAccessor={() => undefined}
                                            nameAccessor={(v) => v}
                                            renderItem={(v) => <VisibleSpaces value={v} />}
                                            bubbleUpSelections={true}
                                        />
                                        <Divider />
                                        <Checkbox
                                            m="sm"
                                            disabled={!rule.TagKeysToApplyTo?.length}
                                            label="All tag keys"
                                            checked={!rule.TagKeysToApplyTo?.length}
                                            onClick={clearKeyPicker}
                                        />
                                    </Popover.Dropdown>
                                </Popover>
                            </Box>
                            <Box pt="xs" sx={{ minHeight: '42px' }}>
                                <Popover
                                    opened={valuePickerOpened}
                                    onClose={closeValuePicker}
                                    withinPortal
                                    shadow="lg"
                                    offset={0}
                                    position="bottom"
                                    withArrow
                                >
                                    <Popover.Target>
                                        <AnchorButton text={valuePickerDescription} onClick={toggleValuePicker} icon={<Tag />} />
                                    </Popover.Target>
                                    <Popover.Dropdown p={0}>
                                        <Picker
                                            height={200}
                                            width={300}
                                            items={valuePickerOptions}
                                            onChange={(c) => (rule.TagValuesToApplyTo = c)}
                                            onFilterChange={(c) => {
                                                tagLookupSvc.getTagValuesWithFilter(c);
                                            }}
                                            selections={(rule.TagValuesToApplyTo ??= [])}
                                            childAccessor={() => undefined}
                                            nameAccessor={(v) => v}
                                            renderItem={(v) => <VisibleSpaces value={v} />}
                                            bubbleUpSelections={true}
                                        />
                                        <Divider />
                                        <Checkbox
                                            m="sm"
                                            disabled={!rule.TagValuesToApplyTo?.length}
                                            label="All tag values"
                                            checked={!rule.TagValuesToApplyTo?.length}
                                            onClick={clearValuePicker}
                                        />
                                    </Popover.Dropdown>
                                </Popover>
                            </Box>
                        </Box>
                    </Group>
                    <Space h={10} />

                    <Space m="md" />
                </ActionScope>
            </ActionsGroup>
        </RuleEditCard>
    );
});

export const ActionsGroup = styled.div`
    display: flex;
    align-items: stretch;
    background-color: ${(p) => p.theme.colors.gray[0]};
    border: solid 1px ${(p) => p.theme.colors.gray[3]};
    border-radius: 8px;
`;
export const ActionDivider = styled.div`
    flex: 0;
    border-left: solid 1px ${(p) => p.theme.colors.gray[3]};
    display: flex;
    align-items: center;
    padding-right: 10px;
    &::after {
        content: '';
        height: 20px;
        width: 20px;
        margin-left: -10px;
        border-color: ${(p) => p.theme.colors.gray[3]};
        border-style: solid;
        border-width: 1px 1px 0 0;
        background: ${(p) => p.theme.colors.gray[0]};
        transform: rotate(45deg);
    }
`;
export const ActionScope = styled.div`
    flex: 0;
    min-width: 300px;
`;
export const FormOptionGroup = styled.div`
    flex: 1;
    padding: 10px 16px;
    > div {
        margin: 10px 0;
    }
`;
export const Bold = styled.strong`
    font-weight: bolder;
    text-shadow: 0px 0.25px, 0.25px 0px, 0.25px 0.25px;
`;

import { EventEmitter } from '@root/Services/EventEmitter';

export class PropertyGridViewModel {
    private readonly expandedItems = new Set<string>();

    public readonly expanded = EventEmitter.empty();
    public readonly items: PropertyGridItem[];

    public constructor(public readonly target: Record<string, unknown>) {
        this.items = PropertyGridItem.create(target);
    }

    public toggle = (...items: PropertyGridItem[]) => {
        for (const item of items) {
            if (this.expandedItems.has(item.fullPath)) {
                this.expandedItems.delete(item.fullPath);
            } else {
                this.expandedItems.add(item.fullPath);
            }
        }
        this.expanded.emit();
    };

    public flattened(flatten: boolean) {
        if (flatten) {
            const roots = this.items.splice(0, Infinity);
            roots.flatMap((item) =>
                item.visit((o) => {
                    this.items.push(o);
                    o.clearChildren();
                })
            );
        }
        return this;
    }

    public isExpanded = (item: PropertyGridItem) => this.expandedItems.has(item.fullPath);
}

type ValueTypes = 'date' | 'string' | 'number' | 'boolean' | 'array' | 'object' | 'null';
export class PropertyGridItem {
    private _valueType?: ValueTypes;
    private _path?: (string | number)[];
    private _fullPath?: string;
    private _objectPath?: string;
    public children: null | PropertyGridItem[];
    public type: string;
    public value: unknown;

    public get hasChildren() {
        return this.children !== null;
    }
    public get expandable() {
        return !!this.children?.length;
    }
    public itemType: 'property' | 'index';
    public get depth(): number {
        return !this.parent ? 0 : this.parent.depth + 1;
    }
    /**
     * Get property path as array of object keys and array indices
     */
    public get path(): (string | number)[] {
        return (this._path ??= !this.parent ? [this.property] : [...this.parent.path, this.property]);
    }
    /**
     * Get the full path of the property in the object, path includes array indexes, and object keys
     * e.g., Book.Chapters[0].Name
     */
    public get fullPath() {
        return (this._fullPath ??= this.path.reduce(
            (result, item) => (!result ? item.toString() : typeof item === 'number' ? `${result}[${item}]` : `${result}.${item}`),
            ''
        ) as string);
    }
    /**
     * Get the path of the property in the object, path includes only object keys, no array indices
     * e.g., Book.Chapters.Name
     */
    public get objectPath(): string {
        return (this._objectPath ??= this.path.reduce(
            (result, item) => (!result ? item.toString() : typeof item === 'number' ? result : `${result}.${item}`),
            ''
        ) as string);
    }
    public valueAsNum = (fallback: number) => (typeof this.value === 'number' ? this.value : fallback);
    public valueAsStr = (fallback: string) => (typeof this.value === 'string' ? this.value : fallback);
    public valueAsBool = (fallback: boolean) => (typeof this.value === 'boolean' ? this.value : fallback);
    public get isPrimitive() {
        return this.type === 'string' || this.type === 'number' || this.type === 'boolean';
    }
    public get valueType() {
        return (this._valueType ??= PropertyGridItem.getValueType(this.value));
    }

    public getSeriesFromRoot() {
        const series: PropertyGridItem[] = [];
        let current: PropertyGridItem | undefined = this;
        while (current) {
            series.unshift(current);
            current = current.parent;
        }
        return series;
    }

    public constructor(
        public target: Record<string | number, unknown> | Array<unknown>,
        public property: string | number,
        public root: Record<string, unknown> | Array<unknown>,
        public parent?: PropertyGridItem
    ) {
        this.value = target instanceof Array ? target[property as number] : target[property];
        this.itemType = typeof property === 'string' ? 'property' : 'index';
        this.children = this.resolveChildren(this.value);
        this.type = typeof this.value;
    }
    private resolveChildren(value: unknown) {
        if (typeof value === 'object') {
            if (value instanceof Array) {
                return value.map((_, index) => new PropertyGridItem(value, index, this.root, this));
            } else if (value === null) {
                return null;
            } else {
                return PropertyGridItem.createInternal(value as Record<string, unknown>, this.root, this);
            }
        }
        return null;
    }

    public visit(visitor: (item: PropertyGridItem) => void) {
        this.children?.forEach((child) => child.visit(visitor));
        visitor(this);
    }

    public clearChildren() {
        this.children = null;
    }

    public static create(target: Record<string, unknown>) {
        return PropertyGridItem.createInternal(target, target, undefined);
    }
    private static createInternal(target: Record<string, unknown>, root: Record<string, unknown> | Array<unknown>, parent?: PropertyGridItem) {
        return Object.keys(target).map((key) => new PropertyGridItem(target, key, root, parent));
    }

    private static getValueType(value: unknown): ValueTypes {
        switch (typeof value) {
            case 'boolean':
                return 'boolean';
            case 'number':
                return 'number';
            case 'string':
                return value.match(/^\d{4}-\d{2}-\d{2}/) ? 'date' : 'string';
            case 'undefined':
                return 'null';
            default:
                return value instanceof Array ? 'array' : !value ? 'null' : value instanceof Date ? 'date' : 'object';
        }
    }
}

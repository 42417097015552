import { Query } from '@apis/Resources/model';
import { Box, Card, Center, Loader } from '@mantine/core';
import { BarChartSettings } from '@root/Components/Charts/BarChart';
import { GaugeChartSettings } from '@root/Components/Charts/GaugeChart';
import { LineChartSettings } from '@root/Components/Charts/LineChart';
import { PieChartSettings } from '@root/Components/Charts/PieChart';
import { ChartDashboardItem } from '@root/Components/DashboardLayout/ChartDashboardItem';
import { CustomizableDashboard, DashboardAddOption } from '@root/Components/DashboardLayout/CustomizableDashboard';
import { DashboardItemProps, IDashboardConfig, IDashboardItemType, QueryDatasource } from '@root/Components/DashboardLayout/Models';
import { InvoicesGrid } from '@root/Components/Invoices/InvoicesGrid';
import { PageBody, PageContent } from '@root/Design/Layout';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { InvoiceSchemaService } from '@root/Services/Invoices/InvoiceSchemaService';
import { NavigationService, useNav } from '@root/Services/NavigationService';
import { SchemaValueProvider } from '@root/Services/QueryExpr';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { addDays, endOfMonth } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { inject, injectable } from 'tsyringe';
import { InvoiceDateRange } from '../../Components/Invoices/InvoiceDateRange';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';
import { withSchemaPreloader } from '@root/Components/Invoices/SchemaPreloader';

export function InvoiceViewer({ dashboard, model }: { dashboard?: string; model: InvoiceViewerModel }) {
    const invoiceSchemaSvc = useDi(InvoiceSchemaService);
    const invoiceApi = useDi(InvoiceApiService);
    const nav = useNav();
    let { field } = useNav().getData('field');

    useEffect(() => {
        model.init();
    }, []);

    //#region Dashboard Config
    const datasource = useMemo(
        () =>
            [
                {
                    name: 'cur',
                    source: (query: Query) => invoiceApi.query(query, model.dateRange.value),
                    schema: invoiceSchemaSvc,
                    getValueProviderFactory: (schemaSvc) => {
                        const schemaValueProvider = new SchemaValueProvider(schemaSvc, (q) => invoiceApi.query(q, model.dateRange.value));
                        return {
                            getValueProvider: schemaValueProvider.getValueProvider,
                        };
                    },
                    getDefaultGroup: () => ({ Expr: { Field: 'product/productFamily' }, Alias: 'Product Family' }),
                    getDefaultValue: () => ({ Expr: { Operation: 'sum', Operands: [{ Field: 'lineItem/UnblendedCost' }] }, Alias: 'Count' }),
                    getDefaultHistogram: () => ({
                        Expr: { Field: field == 'BilledDate' ? 'BilledDate' : 'UsageStartDate' },
                        Alias: 'Usage Start Date',
                    }),
                },
            ] as QueryDatasource[],
        []
    );

    const DetailExplorerGrid = useDetailExplorerGrid(model, field ?? '');

    const itemTypes = useMemo(() => {
        return [ChartDashboardItem.itemType, { component: DetailExplorerGrid, type: 'detail-explorer' }] as IDashboardItemType[];
    }, []);

    const defaultConfig = useMemo(
        () =>
            ({
                name: 'Invoice Dashboard',
                layout: [
                    {
                        data: {
                            type: 'chart',
                            chartType: 'kpi',
                            values: [
                                { Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: 'lineItem/UnblendedCost' }] } },
                                { Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: 'lineItem/UnblendedCost' }] } },
                                { Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: 'lineItem/UnblendedCost' }] } },
                            ],
                            groups: [],
                            settings: {
                                valueFilters: [
                                    [
                                        {
                                            Operation: 'eq',
                                            Operands: [{ Field: 'product/region' }, { Value: 'us-east-1' }],
                                        },
                                    ],
                                    [
                                        {
                                            Operation: 'eq',
                                            Operands: [{ Field: 'product/region' }, { Value: 'us-east-2' }],
                                        },
                                    ],
                                    [],
                                ],
                                labels: ['us-east-1', 'us-east-2', 'All Regions'],
                                format: ['money', 'money', 'money'],
                            },
                            datasourceName: 'cur',
                            title: 'Region Spend',
                        },
                        h: 3,
                        w: 6,
                        x: 0,
                        y: 0,
                    },
                    {
                        data: {
                            type: 'chart',
                            chartType: 'line',
                            groups: [{ Alias: 'Date', Expr: { Field: field == 'BilledDate' ? 'BilledDate' : 'UsageStartDate' } }],
                            values: [{ Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: 'lineItem/UnblendedCost' }] } }],
                            settings: {
                                format: 'money',
                                interval: 'week',
                                margin: { top: 30, bottom: 70, left: 70, right: 20 },
                            } as LineChartSettings,
                            datasourceName: 'cur',
                            title: 'Weekly Spend',
                        },
                        h: 6,
                        w: 6,
                        x: 0,
                        y: 3,
                    },
                    {
                        data: {
                            type: 'chart',
                            chartType: 'grid',
                            groups: [],
                            values: [],
                            settings: {
                                state: {
                                    columns: [
                                        { id: 'Product', width: 200 },
                                        { id: 'Spend', width: 100 },
                                    ],
                                    filters: [],
                                    sort: [{ id: 'Spend' }],
                                },
                                columns: [
                                    { type: 'string', id: 'Product', select: { Alias: 'Product', Expr: { Field: 'product/ProductName' } } },
                                    {
                                        type: 'number',
                                        id: 'Spend',
                                        select: { Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: 'lineItem/UnblendedCost' }] } },
                                    },
                                ],
                            },
                            datasourceName: 'cur',
                            title: 'Spend by Product',
                        },
                        h: 6,
                        w: 5,
                        x: 6,
                        y: 0,
                    },
                    {
                        data: {
                            type: 'detail-explorer',
                        },
                        h: 10,
                        w: 12,
                        x: 0,
                        y: 9,
                    },
                ],
            } as IDashboardConfig),
        []
    );

    const addOptions = useMemo(
        () =>
            [
                {
                    label: 'New KPI',
                    category: 'blank',
                    settings: {
                        type: 'chart',
                        chartType: 'kpi',
                        groups: [],
                        values: [{ Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: 'lineItem/UnblendedCost' }] } }],
                        settings: { valueFilters: [[]], labels: [''], format: ['money'] },
                        datasourceName: 'cur',
                        title: 'Spend',
                    },
                },
                {
                    label: 'New Table',
                    category: 'blank',
                    settings: {
                        type: 'chart',
                        chartType: 'grid',
                        groups: [],
                        values: [],
                        settings: {
                            state: {
                                columns: [
                                    { id: 'Account', width: 200 },
                                    { id: 'Spend', width: 100 },
                                ],
                                filters: [],
                                sort: [],
                            },
                            columns: [
                                { type: 'string', id: 'Account', select: { Alias: 'Account', Expr: { Field: 'lineItem/UsageAccountId' } } },
                                {
                                    type: 'number',
                                    id: 'Spend',
                                    select: { Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: 'lineItem/UnblendedCost' }] } },
                                },
                            ],
                        },
                        datasourceName: 'cur',
                        title: 'Spend by Account',
                    },
                },
                {
                    label: 'New Gauge',
                    category: 'blank',
                    settings: {
                        type: 'chart',
                        chartType: 'gauge',
                        groups: [{ Alias: 'Account', Expr: { Field: 'lineItem/UsageAccountId' } }],
                        values: [{ Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: 'lineItem/UnblendedCost' }] } }],
                        settings: { format: 'money', angle: 'large', margin: { top: 30, bottom: 30, left: 20, right: 20 } } as GaugeChartSettings,
                        datasourceName: 'cur',
                        title: 'Spend by Account',
                    },
                },
                {
                    label: 'New Pie',
                    category: 'blank',
                    settings: {
                        type: 'chart',
                        chartType: 'pie',
                        groups: [{ Alias: 'Product Family', Expr: { Field: 'product/productFamily' } }],
                        values: [{ Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: 'lineItem/UnblendedCost' }] } }],
                        settings: { valueFormat: 'money', margin: { top: 30, bottom: 30, left: 20, right: 20 }, threshold: 3 } as PieChartSettings,
                        datasourceName: 'cur',
                        title: 'Spend by Product',
                    },
                },
                {
                    label: 'New Bar',
                    category: 'blank',
                    settings: {
                        type: 'chart',
                        chartType: 'bar',
                        groups: [{ Alias: 'Region', Expr: { Field: 'product/region' } }],
                        values: [{ Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: 'lineItem/UnblendedCost' }] } }],
                        settings: { format: 'money', margin: { top: 30, bottom: 70, left: 70, right: 20 } } as BarChartSettings,
                        datasourceName: 'cur',
                        title: 'Spend by Region',
                    },
                },
                {
                    label: 'New Line',
                    category: 'blank',
                    settings: {
                        type: 'chart',
                        chartType: 'line',
                        groups: [{ Alias: 'Date', Expr: { Field: field == 'BilledDate' ? 'BilledDate' : 'UsageStartDate' } }],
                        values: [{ Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: 'lineItem/UnblendedCost' }] } }],
                        settings: { format: 'money', interval: 'week', margin: { top: 30, bottom: 70, left: 70, right: 20 } } as LineChartSettings,
                        datasourceName: 'cur',
                        title: 'Spend over Time',
                    },
                },
            ] as DashboardAddOption[],
        []
    );

    const loadDashboard = useCallback((id?: number) => {
        nav.mergeParams({ dashboardId: id?.toString() ?? '' });
    }, []);
    //#endregion

    const loading = useEventValue(model.loading);
    useEvent(model.dateRange);

    return (
        <ConnectionCheck>
            {() => (
                <PageBody>
                    <PageContent>
                        {loading ? (
                            <Center sx={{ height: 400 }}>
                                <Loader />
                            </Center>
                        ) : (
                            <>
                                <CustomizableDashboard
                                    addOptions={addOptions}
                                    dashboardKey="InvoiceExplorerDashboard"
                                    datasources={datasource}
                                    defaultConfig={defaultConfig}
                                    itemTypes={itemTypes}
                                    allowAdd
                                    allowFilter
                                    allowLoader
                                    implicitFilter={model.getDateFilter(field ?? '')}
                                    id={(dashboard && parseInt(dashboard)) || undefined}
                                    onIdChanged={loadDashboard}
                                    toolRightPlaceholder={
                                        <InvoiceDateRange
                                            constraint={model.constraint}
                                            onChange={model.navigateToDateRange}
                                            value={model.dateRange.value}
                                        />
                                    }
                                />
                            </>
                        )}
                    </PageContent>
                </PageBody>
            )}
        </ConnectionCheck>
    );
}

function InvoiceViewerPage() {
    const nav = useNav();
    const container = useDiContainer();
    const model = useMemo(() => container.resolve(InvoiceViewerModel), []);
    const { dashboardId, range } = nav.getData('dashboardId', 'range');

    useEffect(() => {
        model.updateDateRange(range ?? '');
    }, [range]);

    return <ConnectionCheck>{() => <InvoiceViewer model={model} dashboard={dashboardId} />}</ConnectionCheck>;
}

@injectable()
class InvoiceViewerModel {
    public dateRange = new EventEmitter<{ from?: Date; to?: Date }>({});
    public constraint: { min?: Date; max?: Date } = {};
    public loading = new EventEmitter<boolean>(false);

    public constructor(
        @inject(InvoiceApiService) private readonly invoiceApi: InvoiceApiService,
        @inject(FormatService) private readonly fmtSvc: FormatService,
        @inject(NavigationService) private readonly navSvc: NavigationService
    ) {}

    public async init() {
        try {
            this.loading.emit(true);
            const range = await this.invoiceApi.getDateRange();
            const max = range.to ? endOfMonth(range.to) : new Date();
            this.constraint = { min: range.from, max };
            if (!this.dateRange.value.from && !this.dateRange.value.to && range.to) {
                const to = range.to.getTime() > new Date().getTime() ? new Date() : range.to;
                this.dateRange.emit({ from: addDays(to, -30), to: to });
            }
        } finally {
            this.loading.emit(false);
        }
    }

    public updateDateRange(range: string) {
        const [rawFrom, rawTo] = range.split('-');
        const from = rawFrom ? this.fmtSvc.from8DigitDate(rawFrom) : undefined;
        const to = rawTo ? this.fmtSvc.from8DigitDate(rawTo) : undefined;
        this.dateRange.emit({ from, to });
    }

    public navigateToDateRange = (range: { from?: Date; to?: Date }) => {
        const from = range.from ? this.fmtSvc.to8DigitDate(range.from) : '';
        const to = range.to ? this.fmtSvc.to8DigitDate(range.to) : '';
        this.navSvc.mergeParams({ range: `${from}-${to}` });
    };

    public getDateFilter(field: string) {
        const { from, to } = this.dateRange.value;
        const filterParts = [
            { value: from, op: 'gte' },
            { value: to, op: 'lte' },
        ];
        const filters = filterParts
            .filter((f) => f.value)
            .map((f) => ({ Operation: f.op, Operands: [{ Field: field == 'BilledDate' ? 'BilledDate' : 'UsageStartDate' }, { Value: f.value }] }));

        return filters.length ? filters : undefined;
    }
}

function useDetailExplorerGrid(viewerModel: InvoiceViewerModel, field: string) {
    return useMemo(() => {
        return function GridWrapper(props: DashboardItemProps<{}>) {
            useEffect(() => {
                props.model.getHeader = () => <>Invoice Line Items</>;
            }, [props.model]);
            const dateRange = useEventValue(viewerModel.dateRange);
            return <InvoicesGrid dateRange={dateRange} persistenceKey="InvoiceViewer" field={field ?? ''} />;
        };
    }, [viewerModel]);
}

endpoint('invoice-viewer', withSchemaPreloader(InvoiceViewerPage), 'Invoice Viewer');

/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Export.Host.Api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ExportRequestJobJob,
  ExportRequest,
  GetExportDownloadParams
} from './Export/model'
import { request } from '../Services/BasicApi'


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const postExportExport = (
    exportRequest: ExportRequest,
 options?: SecondParameter<typeof request>,
) => {
      return request<ExportRequestJobJob>(
      {url: `/Export/Export`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: exportRequest
    },
      { type: 'Export',  ...options});
    }
  


    export type PostExportExportMutationResult = NonNullable<Awaited<ReturnType<typeof postExportExport>>>
    export type PostExportExportMutationBody = ExportRequest
    export type PostExportExportMutationError = unknown

    export const usePostExportExport = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExportExport>>, TError,{data: ExportRequest}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExportExport>>, {data: ExportRequest}> = (props) => {
          const {data} = props ?? {};

          return  postExportExport(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postExportExport>>, TError, {data: ExportRequest}, TContext>(mutationFn, mutationOptions)
    }
    
export const getExportDownload = (
    params?: GetExportDownloadParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<void>(
      {url: `/Export/Download`, method: 'get', signal,
        params,
    },
      { type: 'Export',  ...options});
    }
  

export const getGetExportDownloadQueryKey = (params?: GetExportDownloadParams,) => [`/Export/Download`, ...(params ? [params]: [])];

    
export type GetExportDownloadQueryResult = NonNullable<Awaited<ReturnType<typeof getExportDownload>>>
export type GetExportDownloadQueryError = unknown

export const useGetExportDownload = <TData = Awaited<ReturnType<typeof getExportDownload>>, TError = unknown>(
 params?: GetExportDownloadParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExportDownload>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetExportDownloadQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getExportDownload>>> = ({ signal }) => getExportDownload(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getExportDownload>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}



import { inject, injectable } from 'tsyringe';
import { singleton } from 'tsyringe';
import { CompanyFeatureService } from '../Customers/CompanyFeatureService';
import { getNotificationGetMyNotificationTypePreferences, getNotificationGetNotificationTypeConfigs } from '@apis/Notification';
import { NotificationTypeConfig, NotificationTypePreference, NotificationTypePreferenceNotificationType } from '@apis/Notification/model';
import { AuthorizationService } from '../AuthorizationService';
import { Company } from '@apis/Customers/model';
import { ICompanyContextToken } from '../Customers/CompanyContext';

@singleton()
export class NotificationPreferenceCache {
    private notificationConfigs?: Promise<NotificationTypeConfig[]>;
    public async getNotificationConfigs() {
        if (!this.notificationConfigs) {
            this.notificationConfigs = getNotificationGetNotificationTypeConfigs();
        }
        return await this.notificationConfigs;
    }
}

@injectable()
export class NotificationPreferenceService {
    public constructor(
        @inject(CompanyFeatureService) private readonly companyFeatureSvc: CompanyFeatureService,
        @inject(AuthorizationService) private readonly authZSvc: AuthorizationService,
        @inject(NotificationPreferenceCache) private readonly notificationCache: NotificationPreferenceCache,
        @inject(ICompanyContextToken) private readonly company: Company
    ) {}

    public async getNotificationConfigs() {
        return await this.notificationCache.getNotificationConfigs();
    }

    public async getApplicableNotificationsConfigs() {
        const companyFeatures = await this.companyFeatureSvc.getFeatures(this.company);
        const notificationConfigs = await this.getNotificationConfigs();
        return notificationConfigs.filter((c) => {
            const applicableApps = (c.ApplicableApps ?? []).map((p) => p.toLocaleLowerCase());
            return (
                applicableApps.some((p) => companyFeatures.hasLegacyApp(p)) && this.authZSvc.hasAppPermission(this.company.Id ?? 0, applicableApps)
            );
        });
    }

    public async getNotificationPreferenceLookup() {
        const prefs = await getNotificationGetMyNotificationTypePreferences();
        return new Map<NotificationTypePreferenceNotificationType, NotificationTypePreference>(prefs.map((p) => [p.NotificationType!, p]));
    }
}

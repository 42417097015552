/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Customers.Host.Api
 * OpenAPI spec version: 1.0
 */

export type FrontEndErrorType = typeof FrontEndErrorType[keyof typeof FrontEndErrorType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FrontEndErrorType = {
  Unknown: 'Unknown',
} as const;

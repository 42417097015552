import { inject, singleton } from 'tsyringe';
import { ConfigService } from './ConfigService';
import { datadogRum } from '@datadog/browser-rum';
import { AuthorizationService } from './AuthorizationService';
import { AuthenticationService } from './AuthenticationService';

@singleton()
export class MonitoringService {
    public constructor(
        @inject(ConfigService) private readonly configSvc: ConfigService,
        @inject(AuthenticationService) private readonly authSvc: AuthenticationService
    ) {}

    public init() {
        if (this.configSvc.config.monitoring?.dynatraceUrl) {
            this.initDynatrace();
        } else if (this.configSvc.config.monitoring?.datadog?.clientToken) {
            this.initDatadog();
        }
    }

    private initDynatrace() {
        const script = document.createElement('script');
        script.src = this.configSvc.config.monitoring.dynatraceUrl;
        script.type = 'text/javascript';
        script.crossOrigin = 'anonymous';
        document.body.appendChild(script);
    }

    private initDatadog() {
        datadogRum.init({
            applicationId: this.configSvc.config.monitoring.datadog.applicationId,
            clientToken: this.configSvc.config.monitoring.datadog.clientToken,
            site: this.configSvc.config.monitoring.datadog.site,
            service: 'app.cloudsaver.com',
            env: 'prod',
            // Specify a version number to identify the deployed version of your application in Datadog
            // version: '1.0.0',
            sessionSampleRate: 100,
            sessionReplaySampleRate: 20,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
        });
    }

    public setUser() {
        const user = this.authSvc.user;
        if (user !== null && user !== undefined) {
            datadogRum.setUser({
                id: user.Id?.toString(),
            });
        }
    }
}

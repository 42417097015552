import { RecommendationOption, RecommendationType } from '@apis/Recommendations/model';
import { Center, useMantineTheme } from '@mantine/core';
import { GridFlexCell } from '@root/Components/DataGrid/Design';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useCallback, useMemo } from 'react';
import { Check } from 'tabler-icons-react';
import { DataGrid } from '../../DataGrid';
import { ColumnConfig, DataGridState } from '../../DataGrid/Models';
import { DataFilterPercent } from '../../Filter/Filters';
import { createIncreaseIndicatorCellRenderer } from '../GridComponents';
import { IComparsonConfig } from './Models';

export function RecommendationOptionGrid({
    options,
    comparison,
    persistenceKey,
    type,
    selected,
    onClick,
}: {
    options: RecommendationOption[];
    comparison: IComparsonConfig;
    persistenceKey: string;
    type: RecommendationType;
    selected: RecommendationOption | null;
    onClick: (option: RecommendationOption) => void;
}) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const pKey = useMemo(() => ({ key: persistenceKey }), [persistenceKey]);
    const columns = useMemo(() => {
        const changeOptions = [...new Set(options.map((o) => (type === 'Size' ? o.ChangeDetails?.SizeChange?.RecommendedSize : '')))].sort();
        const result = [
            {
                accessor: type === 'Size' ? (o) => o.ChangeDetails?.SizeChange?.RecommendedSize : '',
                id: 'Change',
                header: type === 'Size' ? 'Size Option' : 'Change',
                defaultWidth: 160,
                type: 'string',
                defaultFixed: true,
                filter: {
                    filterType: 'string',
                    options: {
                        getValueProvider: () => changeOptions.map((v) => ({ label: v, value: v })),
                    },
                    filterField: 'ChangeDetails.SizeChange.RecommendedSize',
                },
                sortField: type === 'Size' ? 'ChangeDetails.SizeChange.RecommendedSize' : '',
            },
            {
                accessor: 'RiskRating',
                id: 'Risk',
                header: 'Risk',
                defaultWidth: 160,
                formatter: (_, value) => (typeof value === 'number' ? fmtSvc.formatPercent(value) : ''),
                type: 'number',
                align: 'right',
                filter: {
                    filterType: 'number',
                    name: 'Risk',
                    filterField: 'RiskRating',
                },
            },
            {
                accessor: 'ExpectedAnnualCost',
                id: 'ExpectedAnnualCost',
                header: 'Annual Cost (Expected)',
                defaultWidth: 160,
                type: 'number',
                align: 'right',
                formatter: (_, value) => (typeof value === 'number' ? fmtSvc.formatMoneyNonZeroTwoDecimals(value) : ''),
                filter: {
                    filterType: 'number',
                    name: 'Annual Cost (Expected)',
                    filterField: 'ExpectedAnnualCost',
                },
            },
            {
                accessor: 'ExpectedCost',
                id: 'ExpectedCost',
                header: 'Unit Price (Expected)',
                defaultWidth: 160,
                type: 'number',
                align: 'right',
                formatter: (_, value) => (typeof value === 'number' ? fmtSvc.formatMoney4Decimals(value) : ''),
                filter: {
                    filterType: 'number',
                    name: 'Expected Cost',
                    filterField: 'ExpectedCost',
                },
            },
            {
                accessor: 'ExpectedAnnualSavings',
                id: 'ExpectedAnnualSavings',
                header: 'Annual Savings (Expected)',
                defaultWidth: 160,
                type: 'number',
                align: 'right',
                filter: {
                    filterType: 'number',
                    name: 'Annual Savings (Expected)',
                    filterField: 'ExpectedAnnualSavings',
                },
                cellRenderer: createIncreaseIndicatorCellRenderer(
                    (o) => o.ExpectedAnnualSavings,
                    (v) => fmtSvc.formatMoneyNonZeroTwoDecimals(v),
                    false,
                    'Unavailable'
                ),
            },
            {
                accessor: 'ExpectedSavings',
                id: 'ExpectedSavings',
                header: 'Unit Price Savings (Expected)',
                defaultWidth: 160,
                formatter: (_, value) => (typeof value === 'number' ? fmtSvc.formatMoney4Decimals(value) : ''),
                type: 'number',
                align: 'right',
                filter: {
                    filterType: 'number',
                    name: 'Expected Savings',
                    filterField: 'ExpectedSavings',
                },
                cellRenderer: createIncreaseIndicatorCellRenderer(
                    (o) => o.ExpectedSavings,
                    (v) => fmtSvc.formatMoney4Decimals(v),
                    false,
                    'Unavailable'
                ),
            },
            {
                accessor: (o) => (!!o.IncompatibleReasons?.length ? 'Not Compatible' : 'OK'),
                id: 'Compatibility',
                header: 'Compatibility',
                defaultWidth: 160,
                type: 'string',
                filter: {
                    filterType: 'string',
                    options: {
                        getValueProvider: () => ['OK', 'Not Compatible'].map((v) => ({ label: v, value: v })),
                    },
                    filterField: 'Compatibility',
                },
            },
        ] as ColumnConfig<RecommendationOption>[];

        comparison.specMetrics.forEach((metric) => {
            result.push({
                accessor: (o) => metric.value(o.ExpectedUtilizationMetrics ?? {}),
                id: `ExpectedUtilizationMetrics.${metric.label}`,
                header: metric.label,
                defaultWidth: 160,
                formatter: (_, value) => metric.format(value ?? 0),
                type: 'number',
                align: 'right',
                sortField: `ExpectedUtilizationMetrics.${metric.label}`,
                filter: {
                    filterType: 'number',
                    name: metric.label,
                    filterField: `ExpectedUtilizationMetrics.${metric.label}`,
                },
            });
        });
        comparison.utilizationMetrics.forEach((metric) => {
            result.push({
                accessor: (o) => metric.value(o.ExpectedUtilizationMetrics ?? {}),
                id: `ExpectedUtilizationMetrics.${metric.label}`,
                header: metric.label + ' (Expected)',
                defaultWidth: 160,
                formatter: (_, value) => metric.format(value ?? 0),
                type: 'number',
                align: 'right',
                sortField: `ExpectedUtilizationMetrics.${metric.label}`,
                filter: {
                    filterType: 'number',
                    name: metric.label + ' (Expected)',
                    valueRenderer: DataFilterPercent,
                    filterField: `ExpectedUtilizationMetrics.${metric.label}`,
                },
            });
        });

        return result;
    }, [type, comparison]);

    const state = useMemo(
        () =>
            ({
                columns: columns.map((c) => ({ id: c.id, width: c.defaultWidth, fixed: c.defaultFixed ?? false })),
                filters: [
                    { Operation: 'eq', Operands: [{ Field: 'Compatibility' }, { Value: 'OK' }] },
                    { Operation: 'gt', Operands: [{ Field: 'ExpectedSavings' }, { Value: 0 }] },
                ],
                sort: [{ Direction: 'Asc', Expr: { Field: 'ExpectedCost' } }],
            } as DataGridState),
        []
    );

    const rowSelectorRenderer = useCallback((row: RecommendationOption | null, state: { selected?: boolean }) => {
        return row === null ? (
            <></>
        ) : state.selected ? (
            <Center>
                <Check size={16} />
            </Center>
        ) : (
            <></>
        );
    }, []);

    return (
        <DataGrid
            dataSource={options}
            columns={columns}
            statePersistence={pKey}
            onRowClick={onClick}
            selection={selected ?? undefined}
            selectionMode="single"
            state={state}
            highlightColor={theme.colors.primary[2]}
            renderRowSelector={rowSelectorRenderer}
        />
    );
}

import { getTagManagerGetTagPolicies } from '@apis/TagManager';
import { Checkbox } from '@mantine/core';
import { ColumnConfig } from '@root/Components/DataGrid/Models';
import { useMemo, useCallback, useEffect, useState } from 'react';
import { TagPolicy } from '@apis/TagManager/model';
import { DataGrid } from '@root/Components/DataGrid';
import { PageContent, PageSection } from '@root/Design/Layout';
import { HomeShell } from '@root/Site/CompanyHome/Common';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { CheckboxCell } from '@root/Components/DataGrid/Design';

type PolicyViewModel = TagPolicy;

export function TagPolicyDashboard() {
    const policyColumns = useMemo(
        () =>
            [
                {
                    header: 'Policy Id',
                    accessor: 'Id',
                    defaultWidth: 125,
                    sortField: 'policyId',
                    maxWidth: 100,
                },
                {
                    header: 'Company Id',
                    accessor: 'CompanyId',
                    defaultWidth: 125,
                    sortField: 'CompanyId',
                    maxWidth: 150,
                },
                {
                    header: 'Tag Name',
                    accessor: 'TagName',
                    defaultWidth: 150,
                    sortField: 'TagName',
                    maxWidth: 350,
                },
                {
                    header: 'Level',
                    accessor: 'Level',
                    defaultWidth: 150,
                    sortField: 'Level',
                    maxWidth: 350,
                },
                {
                    header: 'Level Value',
                    accessor: 'LevelValue',
                    defaultWidth: 150,
                    sortField: 'LevelValue',
                    maxWidth: 350,
                },
                {
                    header: 'Schema Format',
                    accessor: 'SchemaFormat',
                    defaultWidth: 300,
                    cellRenderer: (item) => {
                        if (item.SchemaFormat?.RegexPattern != null) {
                            return <span>{item.SchemaFormat?.RegexPattern}</span>;
                        } else if (item.SchemaFormat?.ValidOptions != null) {
                            return <span>{item.SchemaFormat.ValidOptions?.join(', ')}</span>;
                        }
                    },
                },
                {
                    header: 'Format Type',
                    defaultWidth: 150,
                    cellRenderer: (item) => {
                        if (item.SchemaFormat?.SchemaFormatType != null) {
                            return <span>{item.SchemaFormat?.SchemaFormatType}</span>;
                        }
                    },
                },
                {
                    header: 'Required',
                    accessor: 'IsRequired',
                    defaultWidth: 150,
                    sortField: 'IsRequired',
                    maxWidth: 350,
                    align: 'center',
                    cellRenderer: (item) => {
                        return (
                            <CheckboxCell>
                                <Checkbox checked={item.IsRequired} />
                            </CheckboxCell>
                        );
                    },
                },
            ] as ColumnConfig<PolicyViewModel>[],
        []
    );

    const [policies, setPolicies] = useState<PolicyViewModel[]>([]);

    useEffect(() => {
        (async () => {
            const policies: TagPolicy[] = await getTagManagerGetTagPolicies();

            setPolicies(policies);
        })();
    }, []);

    return (
        <HomeShell>
            <PageContent>
                <PageSection height="full">
                    <DataGrid columns={policyColumns} dataSource={policies} />
                </PageSection>
            </PageContent>
        </HomeShell>
    );
}
endpoint('tagPolicyDashboard', TagPolicyDashboard, 'TagPolicyDashboard');

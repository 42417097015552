/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Customers.Host.Api
 * OpenAPI spec version: 1.0
 */

export type CreditSummaryPaymentType = typeof CreditSummaryPaymentType[keyof typeof CreditSummaryPaymentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreditSummaryPaymentType = {
  Prepaid: 'Prepaid',
  Postpaid: 'Postpaid',
} as const;

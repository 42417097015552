import styled from '@emotion/styled';
import { Button, createStyles, Divider, Group, LoadingOverlay, Space, Text } from '@mantine/core';
import { BaseResource, ResourceGridModel, ResourcesGrid } from '@root/Components/Resources/ResourcesGrid';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { LayoutService, PageContent, PagePanel, PanelBody, PaneledPage, PanelHeader, PanelSubHeader } from '@root/Design/Layout';
import { useEvent } from '@root/Services/EventEmitter';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { Observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { AddTags } from './Components/AddTags';
import { RemoveTags } from './Components/RemoveTags';
import { RenameTag } from './Components/RenameTag';
import { UpdateTags } from './Components/UpdateTags';
import { OpenSidebar } from './Components/OpenSidebar';
import { DataGridState, GridColumnState } from '@root/Components/DataGrid/Models';
import { useDi } from '@root/Services/DI';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { ChangeTagValue } from './Components/ChangeTagValue';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';
import { NavigationService, useNav } from '@root/Services/NavigationService';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { InitialSyncCheck } from '@root/Components/Resources/IntialSyncCheck';
import { OperationState } from './Components/TagConsolidationSidePanel';
import { TagCell } from '@root/Components/Resources/Tags/TagCell';
import { VisibleSpaces } from '@root/Components/Text/VisibleSpaces';
import { PlatformService } from '@root/Services/PlatformService';
import { TagResourcesJob } from '@apis/Resources/model';
import { ResourceTagService } from './Services/ResourceTagService';
import { useLink } from '@root/Services/Router/Router';
import { ChevronLeft } from 'tabler-icons-react';
import { withAppFeatureCheck } from '@root/Components/Shell/AppFeatureAccess';
import { AppFeatureNames } from '@root/Services/Customers/CompanyFeatureService';

export function TagExplorerContent() {
    const { getData } = useDi(NavigationService);
    const {
        state: rawState,
        suggestedChange: rawSuggestedChange,
        operation: rawOperation,
        returnTo,
    } = getData('state', 'suggestedChange', 'operation', 'returnTo');
    let stateKey = rawState + '/' + rawSuggestedChange + '/' + rawOperation;

    const gridState = useMemo(() => {
        if (rawState) {
            try {
                return JSON.parse(rawState) as DataGridState;
            } catch (err) {
                console.error('Failed to parse tag explorer grid state ', err);
            }
        }
        return undefined;
    }, [rawState]);

    const gridOperation = useMemo(() => {
        if (rawOperation) {
            try {
                return JSON.parse(rawOperation) as OperationState;
            } catch (err) {
                console.error('Failed to parse raw operation ', err);
            }
        }
        return undefined;
    }, [rawOperation]);

    const suggestedChange = useMemo(() => {
        if (rawSuggestedChange) {
            try {
                return JSON.parse(rawSuggestedChange) as { type: OpenSidebar; inputs: {} };
            } catch (err) {
                console.error('Failed to load suggested change ', err);
            }
        }
        return undefined;
    }, [rawSuggestedChange]);

    return (
        <TagExplorerBody key={stateKey} gridState={gridState} gridOperation={gridOperation} suggestedChange={suggestedChange} returnTo={returnTo} />
    );
}

export function TagExplorerBody({
    gridState,
    gridOperation,
    suggestedChange,
    returnTo,
}: {
    gridState?: DataGridState;
    gridOperation?: OperationState;
    suggestedChange?: { type: OpenSidebar; inputs: {} };
    returnTo?: string;
}) {
    const userPermissions = useAuthZValues({ canTag: { TagManager: 'Tag' } });
    const com = useCompany();
    const layoutSvc = useDi(LayoutService);
    const tagSvc = useDi(ResourceTagService);
    const [selected, setSelected] = useState(0);
    const [disableButton, setDisableButton] = useState(false);
    const [showFooter, setShowFooter] = useState(true);
    const platformSvc = useDi(PlatformService);
    const resourceSvc = useDi(ResourceService);
    const [initialChange, setInitialChange] = useState(suggestedChange);
    const { getAscendUrl } = useNav();
    const link = useLink();

    const [defaultColumns, setDefaultColumns] = useState<GridColumnState[]>([]);
    const [openModal, setOpenModal] = useState<OpenSidebar>(initialChange?.type ?? OpenSidebar.undefined);
    const changeOpenModel = (sidebar: OpenSidebar) => {
        setOpenModal(sidebar);
        if (resourceGrid) {
            resourceGrid.allowTagEdit = sidebar === OpenSidebar.undefined;
        }
    };
    const [resourceGrid, setResourceGrid] = useState<ResourceGridModel>();
    useEffect(() => {
        (async () => {
            if (gridOperation && resourceGrid) {
                await resourceGrid?.selections.setSelectAll(true);
                setOpenModal(gridOperation.operation);
            }
        })();
    }, [resourceGrid, gridOperation, resourceGrid?.selections?.getTotalItems()]);

    useEffect(() => {
        (async () => {
            const mostUsed = await resourceSvc.getTags();
            const columns = [
                { id: 'Base.Name', width: 250, fixed: true },
                { id: 'Base.ResourceType', width: 180, fixed: true },
            ] as GridColumnState[];
            if (!platformSvc.hasPlatform('Azure')) {
                columns.push({ id: 'Base.Account', width: 150 }, { id: 'Base.Region', width: 100 });
            }

            if (!platformSvc.hasPlatform('Aws')) {
                columns.push({ id: 'Base.ResourceGroup', width: 150 });
            }

            columns.push(...mostUsed.slice(0, 5).map((c) => ({ id: `Tags.CsTags.${c}`, width: 120 })));
            if (gridState && gridState.columns.length) {
                if (gridOperation?.renameValue) {
                    gridState.columns = [{ id: `Tags.CsTags.${gridOperation?.renameValue}`, width: 120 }, ...gridState.columns];
                }
                gridState.columns = [
                    { id: 'Base.Name', width: 250, fixed: true },
                    { id: 'Base.ResourceType', width: 180, fixed: true },
                    ...gridState.columns,
                ];
                setDefaultColumns(gridState.columns);
            }
            setDefaultColumns(columns);
        })();
    }, [gridState]);

    const onColumnsLoaded = (grid: ResourceGridModel) => {
        if (gridOperation?.renameValue && gridOperation.operation !== OpenSidebar.changeTagValues) {
            const tagToAdd = gridOperation.renameValue;
            const tagPath = `CsTags.${tagToAdd}`;
            grid.addGridColumn({
                accessor: (item: BaseResource) => item.CsTags?.[tagToAdd],
                id: `Tags.${tagPath}`,
                sortField: tagPath,
                defaultWidth: 100,
                header: tagToAdd,
                noRemove: true,
                type: 'string',
                cellRenderer: (item: BaseResource) => <TagCell grid={grid} item={item} tag={tagToAdd} />,
                headerRenderer: () => <VisibleSpaces value={tagToAdd} />,
                groupName: 'Tags',
                filter: {
                    filterType: 'string',
                    name: 'Type',
                    filterField: tagToAdd,
                },
            });
        }
    };

    const onTagJobStarting = async (tagJob: TagResourcesJob) => {
        const query = resourceGrid?.getQuery();
        if (query && !resourceGrid?.isFilterPaused() && !resourceGrid?.dataGrid?.hasGroupBy()) {
            const savedSearch = await tagSvc.tryPinResults(tagJob, { Where: query.where, Sort: query.sort });
            if (savedSearch) {
                resourceGrid?.pauseFilter(savedSearch);
            }
        }
    };

    const onTagJobStarted = (tagJob: TagResourcesJob) => {
        if ((tagJob.Renames || tagJob.AddTags) && resourceGrid) {
            const newTags = tagJob.Renames ? tagJob.Renames?.map((t) => t.NewTagKey) : tagJob.AddTags?.map((t) => t.Key);
            if (newTags && newTags.length > 0) {
                const columnsToCheckForVisibility = newTags.map((c) => `Tags.CsTags.${c}`);
                resourceGrid.displayGridColumns(columnsToCheckForVisibility, tagJob.JobId!);
            }
        }
    };

    useEffect(() => {
        if (selected > 0) {
            setDisableButton(false);
        } else if (selected == 0) {
            setDisableButton(true);
        }
    }, [selected]);

    useEffect(() => {
        if (openModal != OpenSidebar.undefined) {
            setShowFooter(false);
        } else {
            setShowFooter(true);
        }
        layoutSvc.windowSizeInvalidated.emit();
    }, [openModal]);

    useEvent(resourceGrid?.dataGrid?.selectionChanged, () => {
        setSelected(resourceGrid?.selections.count() ?? 0);
    });
    useEvent(resourceGrid?.selections.queryChanged, () => {
        setSelected(resourceGrid?.selections.count() ?? 0);
    });

    function setPanelButtonClick(openSidebar: OpenSidebar, canClear: boolean) {
        if (canClear) {
            clearSelection();
        }
        changeOpenModel(openSidebar);
        setInitialChange(undefined);
    }

    function clearSelection() {
        resourceGrid?.selections.clearSelected();
    }

    return (
        <>
            <PageContent>
                <PaneledPage>
                    <PagePanel size="fill">
                        <Group position="apart" spacing="sm">
                            <div>
                                <PanelHeader style={{ paddingBottom: 0 }}>
                                    <Text data-atid="SiteSecondaryHeader" size={20}>
                                        Tag Explorer
                                    </Text>
                                </PanelHeader>
                                <PanelSubHeader>
                                    {userPermissions.canTag ? <Text>Select resources to add, remove, and rename tags</Text> : <></>}
                                </PanelSubHeader>
                            </div>
                            {userPermissions.canTag ? (
                                <div style={{ display: openModal == OpenSidebar.undefined ? 'block' : 'none' }}>
                                    <Button
                                        data-atid="UpdateTagsButton"
                                        style={{ marginRight: '24px' }}
                                        disabled={disableButton}
                                        onClick={() => changeOpenModel(OpenSidebar.updateTags)}
                                    >
                                        Update Tags &nbsp;&nbsp;<i className="ti ti-chevron-right"></i>
                                    </Button>
                                </div>
                            ) : (
                                <></>
                            )}
                        </Group>
                        <LoadingOverlay visible={resourceGrid?.isLoading.value ?? true} zIndex={0} />
                        <PanelBody style={{ position: 'relative', marginTop: 0, paddingTop: 0 }}>
                            <ResourceContainer>
                                {!defaultColumns || resourceGrid?.isLoading.value || resourceGrid === null ? (
                                    <LoadingOverlay visible={true} zIndex={0} />
                                ) : null}
                                {defaultColumns.length ? (
                                    <Observer
                                        render={() => (
                                            <ResourcesGrid
                                                title="Tag Explorer"
                                                leftTopPlaceHolder={
                                                    returnTo ? (
                                                        <Button
                                                            component="a"
                                                            {...link(getAscendUrl())}
                                                            variant="filled"
                                                            size="xs"
                                                            my={5}
                                                            sx={{ height: '30px' }}
                                                            leftIcon={<ChevronLeft size={16} />}
                                                            mr="xs"
                                                            radius="lg"
                                                        >
                                                            Back to {returnTo}
                                                        </Button>
                                                    ) : null
                                                }
                                                defaultColumns={defaultColumns}
                                                defaultState={gridState}
                                                onColumnsLoaded={onColumnsLoaded}
                                                showRefresh
                                                allowAutoAddTags
                                                persistenceKey={gridState ? undefined : 'Tag Explorer5'}
                                                onModelLoaded={setResourceGrid}
                                                allowSavedViews={!gridState}
                                            />
                                        )}
                                    />
                                ) : null}
                            </ResourceContainer>
                        </PanelBody>
                    </PagePanel>
                    {openModal != OpenSidebar.undefined ? (
                        <>
                            <Divider orientation="vertical" />
                            <PagePanel padded={false} size="sm" style={{ minWidth: 350, height: '100%' }}>
                                {openModal == OpenSidebar.updateTags ? (
                                    <UpdateTags
                                        onButtonClick={setPanelButtonClick}
                                        companyId={com?.Id ?? 0}
                                        resourceIds={resourceGrid!.selections}
                                        onTagJobStarting={onTagJobStarting}
                                        onTagJobStarted={onTagJobStarted}
                                        pageTitle="Update Tags"
                                        instructions="Which updates would you like to make on the selected resources?"
                                    ></UpdateTags>
                                ) : openModal == OpenSidebar.addTags ? (
                                    <AddTags
                                        onButtonClick={setPanelButtonClick}
                                        companyId={com?.Id ?? 0}
                                        resourceIds={resourceGrid!.selections}
                                        onTagJobStarting={onTagJobStarting}
                                        onTagJobStarted={onTagJobStarted}
                                        pageTitle="Add Tags"
                                        instructions="Set the key and value for the selected resources."
                                        tagKeyGettingValue={gridOperation?.tagKeyGettingValue}
                                    ></AddTags>
                                ) : openModal == OpenSidebar.removeTags ? (
                                    <RemoveTags
                                        onButtonClick={setPanelButtonClick}
                                        companyId={com?.Id ?? 0}
                                        resourceIds={resourceGrid!.selections}
                                        onTagJobStarting={onTagJobStarting}
                                        onTagJobStarted={onTagJobStarted}
                                        pageTitle="Remove Tags"
                                        instructions="Enter the name of the tag to remove from the selected resources."
                                    ></RemoveTags>
                                ) : openModal == OpenSidebar.renameTags ? (
                                    <RenameTag
                                        onButtonClick={setPanelButtonClick}
                                        companyId={com?.Id ?? 0}
                                        resourceIds={resourceGrid!.selections}
                                        onTagJobStarting={onTagJobStarting}
                                        onTagJobStarted={onTagJobStarted}
                                        pageTitle="Rename Tag Keys"
                                        instructions="Select a target key to rename. Tag values will remain the same."
                                        updateToValue={gridOperation?.renameValue}
                                        tagstoUpdate={gridOperation?.tagsToUpdate}
                                    ></RenameTag>
                                ) : openModal == OpenSidebar.changeTagValues ? (
                                    <ChangeTagValue
                                        onButtonClick={setPanelButtonClick}
                                        companyId={com?.Id ?? 0}
                                        resourceIds={resourceGrid!.selections}
                                        onTagJobStarting={onTagJobStarting}
                                        onTagJobStarted={onTagJobStarted}
                                        pageTitle="Rename Tag Values"
                                        instructions="Select the key, one or more values, and the replacement value."
                                        updateToValue={gridOperation?.renameValue}
                                        tagstoUpdate={gridOperation?.tagsToUpdate}
                                        tagKeyGettingValue={gridOperation?.tagKeyGettingValue}
                                        data-atid="ChangeTagValueReplacementValue"
                                    ></ChangeTagValue>
                                ) : null}
                            </PagePanel>
                        </>
                    ) : null}
                </PaneledPage>
            </PageContent>
        </>
    );
}

export function TagExplorer() {
    return <ConnectionCheck>{() => <InitialSyncCheck>{() => <TagExplorerContent />}</InitialSyncCheck>}</ConnectionCheck>;
}
endpoint('tag-explorer', withAppFeatureCheck(TagExplorer, 'Compliance', AppFeatureNames.TagManager), 'Tag Manager');

export const TagToolbar = styled.div`
    margin: ${(p) => p.theme.spacing.lg}px;
    display: flex;
    align-items: center;
    flex: 0;
    Button {
        margin-right: 10px;
    }
`;

const useStyles = createStyles((theme) => ({
    pendingButton: {
        float: 'right',
        i: {
            display: 'inline-block',
            textAlign: 'center',
            width: 24,
            marginRight: 5,
            fontSize: 20,
            fontWeight: 800,
        },
        span: {
            marginRight: 5,
            fontSize: 16,
        },
    },

    linkText: {
        i: {
            marginRight: '4px',
            '&:hover': {
                textDecoration: 'none',
            },
        },
    },
}));

const ResourceContainer = styled.div`
    height: 100%;
`;

/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Invoices.Host.Api
 * OpenAPI spec version: 1.0
 */

export type InvoiceAllocationRuleStatus = typeof InvoiceAllocationRuleStatus[keyof typeof InvoiceAllocationRuleStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceAllocationRuleStatus = {
  Draft: 'Draft',
  Test: 'Test',
  Active: 'Active',
} as const;

import { PageContent, PageSection } from '@root/Design/Layout';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { ActionLogGrid } from '@root/Components/Actions/ActionsGrid';
import { Tabs } from '@mantine/core';
import { useState } from 'react';
import { ResourceChangeGrid } from '@root/Components/Actions/ResourceChangeGrid';
export function ActionsLog() {
    return (
        <PageContent>
            <ActionsLogContent />
        </PageContent>
    );
}
export function ActionsLogContent() {
    const [activeTab, setActiveTab] = useState<string>('ActionsLog');
    const [jobId, setJobId] = useState<string | undefined>(undefined);
    function tabPropertyChange(e: any) {
        setActiveTab(e);
        setTimeout(() => window.dispatchEvent(new Event('resize')), 1);
    }
    function setJob(e: any, job: string | undefined) {
        setJobId(job ?? '');
        tabPropertyChange(e);
    }

    return (
        <PageSection height="full" style={{ padding: 0 }}>
            <Tabs value={activeTab} onTabChange={tabPropertyChange} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Tabs.List px="md" pt="xs">
                    <Tabs.Tab data-atid="ActionsLogTab" key="ActionsLog" value="ActionsLog">
                        Actions Log
                    </Tabs.Tab>
                    <Tabs.Tab data-atid="ResourceChangeLogTab" key="ResourcChangeLog" value="ResourcChangeLog">
                        Resource Change Log
                    </Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel sx={{ height: '100%', minHeight: 0 }} p="md" value="ActionsLog">
                    {<ActionLogGrid></ActionLogGrid>}
                </Tabs.Panel>
                <Tabs.Panel sx={{ height: '100%', minHeight: 0 }} value="ResourcChangeLog">
                    {<ResourceChangeGrid></ResourceChangeGrid>}
                </Tabs.Panel>
            </Tabs>
        </PageSection>
    );
}
endpoint('actionsLog', ActionsLog, 'Activity Log');

import { ChainedLabel, PropertyGridKey } from '@root/Components/PropertyGrid/Design';
import { VisibleSpaces } from '@root/Components/Text/VisibleSpaces';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { createContext, Fragment, useCallback, useContext, useMemo } from 'react';
import { Copy, Pin } from 'tabler-icons-react';
import {
    PropertyGridConfig,
    PropertyGridConfigOption,
    PropertyGridOptionItem,
    usePropertyGridConfig,
    usePropertyGridConfigBuilder,
} from '../../PropertyGrid/PropertyGrid';
import { PropertyGridItem, PropertyGridViewModel } from '../../PropertyGrid/Models';
import { BaseResource } from '../ResourcesGrid';
import { IPropertyPinningModel, usePinningModel } from './DetailPinning';
import { ResourceCostFieldValue } from './FieldValueRenderers';

export const systemFields = [
    '__preIndexState',
    '__preIndexRunningState',
    '__preIndexState.Value',
    '__preIndexRunningState.Value',
    'AccountID',
    'CsTagValueLookup',
    'ChildResourceIds',
    'CloudProviderId',
    'CompanyID',
    'HierarchyId',
    'ImportKey',
    'ImportSeq',
    'IsTopParent',
    'KeyValuePairs',
    'ParentResourceId',
    'Tags',
];

export const tagScoreFields = ['ClarityScore', 'CompletenessScore', 'ComplianceScore', 'CoverageScore', 'TagHealthScore'];

export const baseResourceFields = [
    'Id',
    'Name',
    'ResourceType',
    'CreateDate',
    'LastSyncDate',
    'CloudPlatform',
    'Last30DaysCost',
    'AnnualizedCost',
    'ExtendedAnnualizedCost',
];

export function getDefaultCommonPins() {
    return ['Name', 'ResourceType', 'ExtendedAnnualizedCost'];
}

export function getPinningOptions(
    item: PropertyGridItem,
    pinningModel: IPropertyPinningModel,
    getGroup: (item: PropertyGridItem) => string | undefined,
    orientationLbl: string = 'top'
) {
    const result: PropertyGridOptionItem[] = [];
    const group = getGroup(item);
    const validGroups = !group
        ? null
        : pinningModel.getGroupPriority().reduce((targets, g) => {
              if (targets.length || group === g) {
                  targets.push(g);
              }
              return targets;
          }, [] as string[]);

    if (validGroups?.length && item.isPrimitive && !item.path.some((f) => typeof f !== 'string')) {
        const alreadyPinned = pinningModel.getGroupsForPin(item.fullPath);
        for (const validGroup of validGroups) {
            const isPinned = alreadyPinned.has(validGroup);
            const [action, prepos] = isPinned ? ['Unpin', 'from'] : ['Pin', 'for'];
            const label = `${action} ${prepos} ${validGroup} Resources`;
            const icon = isPinned ? <Pin size={16} /> : <Pin fill="#000b" size={16} />;
            const onClick = () => {
                if (isPinned) {
                    pinningModel.unpin(item.fullPath, validGroup);
                    return { confirmation: `Unpinned` };
                } else {
                    pinningModel.pin(item.fullPath, validGroup);
                    return { confirmation: `Pinned${orientationLbl ? ' to ' + orientationLbl : ''}` };
                }
            };
            result.push({ label, icon, onClick });
        }
    }

    return result;
}

export function useFieldPinning(
    configKey: string,
    csp: string,
    cspSpecificFields: string[],
    resourceType: string,
    resourceTypeDefaultPins: string[],
    cspDefaultPins?: string[]
) {
    const [defaultPins, getFieldGroup] = useMemo(() => {
        const defaultPins = {
            All: getDefaultCommonPins(),
            [csp]: cspDefaultPins ?? cspSpecificFields,
            [resourceType]: resourceTypeDefaultPins,
        };
        const baseFields = new Set(baseResourceFields);
        const getFieldGroup = (item: PropertyGridItem) => {
            return baseFields.has(item.fullPath) || item.parent?.fullPath === 'CsTags'
                ? 'All'
                : cspSpecificFields.includes(item.fullPath)
                ? csp
                : resourceType;
        };
        return [defaultPins, getFieldGroup];
    }, []);

    const pinning = usePinningModel(configKey, ['All', csp, resourceType], defaultPins);

    const pinnedGroupSort = useMemo(() => {
        const sortFactory: PropertyGridConfigOption['sortChildren'] = (config, defaultSort, parent) => {
            const groups = pinning.getGroupPriority();
            const idxLookup = (path: string) => pinning.getPinnedFields().indexOf(path);
            return (a, b) => {
                const aGroupIdx = groups.indexOf(getFieldGroup(a));
                const bGroupIdx = groups.indexOf(getFieldGroup(b));
                const groupOrder = (aGroupIdx === -1 ? groups.length : aGroupIdx) - (bGroupIdx === -1 ? groups.length : bGroupIdx);
                const pinnedOrder = (idxLookup(a.fullPath) ?? -1) - (idxLookup(b.fullPath) ?? -1);
                return groupOrder || pinnedOrder || defaultSort(a, b);
            };
        };
        return sortFactory;
    }, [pinning]);

    const pinModelChangeKey = [pinning.getPinnedFields().join(','), pinning];

    const ruleBuilder = usePropertyGridConfigBuilder();
    const pinnedFieldConfig = useMemo(
        () => [
            ruleBuilder.all({
                sortChildren: pinnedGroupSort,
                hidden: true,
            }),
            ruleBuilder(pinning.getPinnedFields(), {
                hidden: false,
                label: (item, defaultRenderer, baseLabel) => {
                    return (
                        <ChainedLabel
                            items={item.getSeriesFromRoot().map((x) => {
                                return <>{x === item ? baseLabel() : defaultRenderer(x)}</>;
                            })}
                        />
                    );
                },
            }),
        ],
        pinModelChangeKey
    );

    const getMenuOptions = useCallback((item: PropertyGridItem) => {
        return getPinningOptions(item, pinning, getFieldGroup);
    }, pinModelChangeKey);

    const loading = useEventValue(pinning.loading);

    useEvent(pinning.changed);

    return { pinning, pinnedFieldConfig, loading, getMenuOptions };
}

export function getCopyOption(item: PropertyGridItem, config: PropertyGridConfig) {
    const { isPrimitive } = item;
    const result: PropertyGridOptionItem[] = [];
    if (isPrimitive) {
        result.push({
            label: 'Copy',
            icon: <Copy size={16} />,
            onClick: async () => {
                await navigator.clipboard.writeText(config.format?.(item) ?? (item.value as string));
                return { confirmation: 'Copied to clipboard' };
            },
        });
    }
    return result;
}

export function useCommonFieldConfig(hideSystemFields: boolean = true, hideTagScoreFields: boolean = true, hideIdField: boolean = true) {
    const fmtSvc = useDi(FormatService);
    const ruleBuilder = usePropertyGridConfigBuilder();
    return useMemo(() => {
        const result: PropertyGridConfigOption[] = [];

        result.push(
            {
                Id: {
                    label: 'System Id',
                    hidden: hideIdField,
                },
                CloudPlatform: {
                    label: 'Cloud Platform',
                    format: (item) => (item.valueAsStr('') === 'Aws' ? 'AWS' : item.valueAsStr('')),
                },
                CsTags: {
                    label: 'Tags',
                },
                Last30DaysCost: {
                    label: 'Last 30 Days Cost',
                    valueRenderer: (item) => <ResourceCostFieldValue costField="Last30DaysCost" resource={item.root as BaseResource} />,
                    format: (item) => fmtSvc.formatMoneyNonZeroTwoDecimals(item.valueAsNum(0)),
                },
                AnnualizedCost: {
                    label: 'Annualized Cost',
                    valueRenderer: (item) => <ResourceCostFieldValue costField="AnnualizedCost" resource={item.root as BaseResource} />,
                    format: (item) => fmtSvc.formatMoneyNonZeroTwoDecimals(item.valueAsNum(0)),
                },
                ExtendedAnnualizedCost: {
                    label: 'Extended Annualized Cost',
                    valueRenderer: (item) => <ResourceCostFieldValue costField="ExtendedAnnualizedCost" resource={item.root as BaseResource} />,
                    format: (item) => fmtSvc.formatMoneyNonZeroTwoDecimals(item.valueAsNum(0)),
                },
            },
            ruleBuilder(systemFields, { hidden: hideSystemFields }),
            ruleBuilder(tagScoreFields, { hidden: hideTagScoreFields }),
            ruleBuilder(/^CsTags\..*/, {
                label: (item) => (
                    <PropertyGridKey>
                        <VisibleSpaces value={item.property as string} />
                    </PropertyGridKey>
                ),
                valueRenderer: ({ value }) => {
                    return value === null || value === undefined ? (
                        <>« No Tag »</>
                    ) : value === '' ? (
                        <>« Empty »</>
                    ) : (
                        <VisibleSpaces value={value as string} />
                    );
                },
            })
        );

        return result;
    }, [hideSystemFields, hideTagScoreFields, hideIdField]);
}

export const ResourceChangeCountCtx = createContext<{ resourceChangeCt: EventEmitter<number> }>({ resourceChangeCt: new EventEmitter<number>(0) });
export function useResourceChangeCount() {
    const { resourceChangeCt } = useContext(ResourceChangeCountCtx);
    return useEventValue(resourceChangeCt);
}

import styled from '@emotion/styled';
import { Box, Button, Card, Divider, Grid, Group, RingProgress, Space, Text, useMantineTheme } from '@mantine/core';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { CustomColors } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useNav } from '@root/Services/NavigationService';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { AssessmentsPage } from './AssessmentsPage';
import { CardHeader, CardStyle, getAssessmentColor } from './Design';
import { PlatformService } from '@root/Services/PlatformService';
import { FillerSwitch } from '@root/Design/Filler';
import { CalcScores, CompanyAssessmentResultRollup, Score, getCompanySummary } from './Model';

export function AssesmentSummaries() {
    const company = useCompany();
    const platformSvc = useDi(PlatformService);
    const [assessments, setAssessments] = useState<CompanyAssessmentResultRollup[]>();
    useEffect(() => {
        (async () => {
            await platformSvc.initIfNeeded();
            const companyPlatforms = platformSvc.getPlatforms();
            const results = await getCompanySummary(company?.Id!, companyPlatforms);
            setAssessments(results.Results ?? []);
        })();
    }, []);

    const nav = useNav();
    const switchTab = (tab: string) => nav.replaceParams({ tab });
    return (
        <AssessmentsPage
            title="Summary"
            titleRightPlaceholder={
                <Button data-atid="CompanyReviewAssessments:Summary" onClick={() => switchTab('assessments')}>
                    Review Assessments
                </Button>
            }
        >
            {assessments ? (
                <>
                    <Scorecard type="Optimization" assessments={assessments} />
                    <Space h={16} />
                    <Scorecard type="Compliance" assessments={assessments} />
                </>
            ) : (
                <></>
            )}
        </AssessmentsPage>
    );
}

export function Scorecard({ type, assessments }: { type: string; assessments: CompanyAssessmentResultRollup[] }) {
    const results = useMemo(() => assessments.filter((m) => m.category == type && m.totalCount), [assessments, type]);
    const scores = CalcScores(results) as Score;

    const platformSet = new Set(results.map((m) => m.platform));
    const platforms = Array.from(platformSet);

    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    return (
        <Card component={CardStyle} sx={{ opacity: scores.noData ? 0.6 : 1 }} mb="md">
            <CardHeader>{type} Score</CardHeader>
            <Grid sx={{ filter: scores.noData ? 'grayscale(.4)' : undefined, height: 300 }}>
                <Grid.Col span={7}>
                    <FillerSwitch noData={scores.totalCount === 0}>
                        {() => (
                            <Group noWrap align="stretch">
                                <Box>
                                    <RingProgress
                                        size={275}
                                        thickness={40}
                                        label={
                                            <>
                                                <Text
                                                    style={{
                                                        color: theme.colors.warning[4],
                                                        fontSize: '42px',
                                                        fontWeight: 'bold',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {scores.totalOpportunity > 0 ? Math.round(scores.totalScore * 100) : 0}
                                                </Text>
                                                <Text size="xs" align="center" color="dimmed">
                                                    BLENDED
                                                </Text>
                                                <Text size="xs" align="center" color="dimmed">
                                                    SCORE
                                                </Text>
                                            </>
                                        }
                                        sections={[
                                            {
                                                value: Math.round(scores.totalScore * 100),
                                                color: getAssessmentColor(
                                                    scores.totalOpportunity > 0 ? scores.totalScore : 0
                                                ) as unknown as CustomColors,
                                            },
                                            {
                                                value: Math.round(100 - scores.totalScore * 100),
                                                color: 'gray.4',
                                            },
                                        ]}
                                    />
                                </Box>
                                <Bars assessments={results} />
                            </Group>
                        )}
                    </FillerSwitch>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Group position="apart" noWrap sx={{ height: '100%' }}>
                        <Space w={0} />
                        <Divider orientation="vertical" mb="lg" />
                        <RightBoxContainer>
                            <RightBoxBig backgroundColor={theme.colors.primary[2]}>
                                <Text>Potential Savings</Text>
                                <RightBoxBigNumber>{fmtSvc.formatMoneyNoDecimals(scores.savings)}</RightBoxBigNumber>
                            </RightBoxBig>
                            {platforms.length > 1 ? (
                                <ScoreBoxRow>
                                    {platforms.map((m, i, row) => (
                                        <Fragment key={i}>
                                            <ScoreBoxLabel backgroundColor={theme.colors.primary[1]} borderRadius={i + 1 == row.length ? 5 : 0}>
                                                {m}
                                            </ScoreBoxLabel>
                                            <ScoreBoxValue backgroundColor={theme.colors.primary[1]} borderRadius={i + 1 == row.length ? 5 : 0}>
                                                {fmtSvc.formatMoneyNoDecimals(
                                                    results.filter((f) => f.platform == m).reduce((sum, current) => sum + current.savings, 0) ?? 0
                                                )}
                                            </ScoreBoxValue>
                                        </Fragment>
                                    ))}
                                </ScoreBoxRow>
                            ) : (
                                <></>
                            )}
                        </RightBoxContainer>

                        <RightBoxContainer>
                            <RightBoxBig backgroundColor={theme.colors.gray[2]}>
                                <Text>Potential Opportunities</Text>
                                <RightBoxBigNumber>{fmtSvc.formatInt(scores.totalOpportunity)}</RightBoxBigNumber>
                            </RightBoxBig>
                            {platforms.length > 1 ? (
                                <ScoreBoxRow>
                                    {platforms.map((m, i, row) => (
                                        <Fragment key={i}>
                                            <ScoreBoxLabel backgroundColor={theme.colors.gray[2]} borderRadius={i + 1 == row.length ? 5 : 0}>
                                                {m}
                                            </ScoreBoxLabel>
                                            <ScoreBoxValue backgroundColor={theme.colors.gray[2]} borderRadius={i + 1 == row.length ? 5 : 0}>
                                                {fmtSvc.formatInt(
                                                    results
                                                        .filter((f) => f.platform == m)
                                                        .reduce((sum, current) => sum + current.opportunityCount, 0) ?? 0
                                                )}
                                            </ScoreBoxValue>
                                        </Fragment>
                                    ))}
                                </ScoreBoxRow>
                            ) : (
                                <></>
                            )}
                        </RightBoxContainer>
                    </Group>
                </Grid.Col>
            </Grid>
        </Card>
    );
}

export function Bars({ assessments }: { assessments: CompanyAssessmentResultRollup[] }) {
    return (
        <BarGroup count={Math.min(6, assessments.length)}>
            {assessments.slice(0, 6).map((m, i) => (
                <BarWrapper key={i} style={{ height: `${Math.max(0, Math.min(1, m.score)) * 100}%` }}>
                    <BarCap backgroundColor={getAssessmentColor(m.score)}>{Math.round(Math.max(0, Math.min(1, m.score)) * 100)}</BarCap>
                    <BarSpacer />
                </BarWrapper>
            ))}
            {assessments.slice(0, 6).map((m, i) => (
                <BarLabel key={i}>
                    <Text>{m.code?.replace(' Resources', '')}</Text>
                </BarLabel>
            ))}
        </BarGroup>
    );
}

const RightBoxContainer = styled.div`
    width: calc(50% - 60px);
`;

const RightBoxBig = styled.div<{ backgroundColor: string }>`
    background-color: ${(p) => p.backgroundColor};
    border-radius: 10px;
    padding: 15px;
`;

const RightBoxBigNumber = styled.div`
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    color: #00609f;
`;

const ScoreBoxRow = styled.div`
    display: grid;
    height: 36px;
    grid-template-columns: 2fr 3fr;
    gap: 3px;
    margin: 3px ${(p) => p.theme.spacing.md}px;
`;

const ScoreBoxLabel = styled.div<{ backgroundColor: string; borderRadius: number }>`
    text-align: right;
    background-color: ${(p) => p.backgroundColor};
    padding: 6px 12px;
    border-bottom-left-radius: ${(p) => p.borderRadius}px;
`;

const ScoreBoxValue = styled.div<{ backgroundColor: string; borderRadius: number }>`
    text-align: right;
    background-color: ${(p) => p.backgroundColor};
    padding: 5px 12px;
    font-weight: bold;
    font-size: ${(p) => p.theme.fontSizes.lg}px;
    border-bottom-right-radius: ${(p) => p.borderRadius}px;
`;

const BarGroup = styled.div<{ count: number }>`
    flex: 1;
    display: grid;
    max-width: ${(p) => p.count * 150}px;
    grid-template-columns: repeat(${(p) => p.count}, 1fr);
    grid-template-rows: 1fr 50px;
    margin-top: 60px;
    align-items: end;
    column-gap: 3px;
    row-gap: 16px;
`;

const BarWrapper = styled.div`
    padding: 0 10px;
    min-height: 25px;
    overflow: hidden;
    max-width: 100px;
    justify-self: center;
    width: 100%;
`;

const BarCap = styled.div<{ backgroundColor: string }>`
    height: 25px;
    text-align: center;
    color: white;
    font-size: 16px;
    margin-bottom: 3px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: ${(p) => p.backgroundColor};
`;

const BarSpacer = styled.div`
    background-color: ${(p) => p.theme.colors.gray[3]};
    height: calc(100% - 28px);
`;

const BarLabel = styled.div`
    height: 50px;
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    margin-top: 0.5rem;
`;

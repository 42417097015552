import { getAccountGetCompanyAccounts, getCompanyGetCompanyCloudProviders } from '@apis/Customers';
import { Lifecycle, scoped } from 'tsyringe';

export type Platform = 'Aws' | 'Azure';

@scoped(Lifecycle.ContainerScoped)
export class PlatformService {
    private platforms = new Set<Platform>();
    public constructor() {}

    public async init() {
        this.platforms = await this.getConnectedCloud();
    }

    public async initIfNeeded() {
        if (this.platforms.size === 0) {
            await this.init();
        }
    }

    public getPlatforms() {
        return this.platforms;
    }

    public hasPlatform(platform: Platform) {
        return this.platforms.has(platform);
    }

    public async getConnectedCloud() {
        const [providers, accounts] = await Promise.all([getCompanyGetCompanyCloudProviders(), getAccountGetCompanyAccounts()]);
        const result = new Set<Platform>();
        if (accounts.length) {
            result.add('Aws');
        }
        if (providers.length) {
            for (const provider of providers) {
                result.add(provider.CloudPlatform!);
            }
        }
        return result;
    }

    public getPickerOptions() {
        const result: { label: string; value: string }[] = [];
        if (this.hasPlatform('Aws')) {
            result.push({ label: 'AWS', value: 'Aws' });
        }
        if (this.hasPlatform('Azure')) {
            result.push({ label: 'Azure', value: 'Azure' });
        }
        return result;
    }
}

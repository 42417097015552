import { AllocationRuleEditor } from '../Model';
import {
    InvoiceAllocationRule,
    FundSourceSourceType,
    FundSource,
    DisbursementTargetTargetType,
    DisbursementTarget,
    NamedFilterSet,
    BasePresentationOptions,
} from '@apis/Invoices/model';
import { makeAutoObservable } from 'mobx';
import { LineItemFundSource, ExternalFundSource, ExistingDisbursementTarget, NewRecordsDisbursementTarget } from '../../Models';
import { EventEmitter } from '@root/Services/EventEmitter';
import { MiniDataGridItem } from '../MiniDataGrid';

type FundSourceType = 'LineItems' | 'External';

export class BaseAllocationRuleModel<
    TSourceDefPresOptions extends BasePresentationOptions = {},
    TTargetDefPresOptions extends BasePresentationOptions = {}
> {
    protected readonly rule: InvoiceAllocationRule;
    public sourcesChanged = EventEmitter.empty();
    public targetsChanged = EventEmitter.empty();
    public sourceSelected = new EventEmitter<FundSource | undefined>(undefined);
    private readonly amountCache = new Map<FundSource, number | undefined>();

    public constructor(protected ruleEditor: AllocationRuleEditor) {
        this.rule = (ruleEditor.rule ?? {}) as InvoiceAllocationRule;
        this.sourcesChanged.listen(ruleEditor.rulePropsChanged.emit);
        this.targetsChanged.listen(ruleEditor.rulePropsChanged.emit);
    }

    protected getFundSourceAmount(source: FundSource) {
        if (!this.amountCache.has(source)) {
            this.amountCache.set(source, undefined);
            this.ruleEditor.showbackSvc.getFundSourceAmount(source, this.ruleEditor.month).then((amount) => {
                this.amountCache.set(source, amount);
            });
        }
        return this.amountCache.get(source);
    }

    public getSourceGridItems(nameProvider: (s: FundSource) => string) {
        return this.getSources().map((s) => {
            return {
                getName: () => nameProvider(s),
                getAmount: () => this.getFundSourceAmount(s),
                onChanged: this.sourcesChanged,
                remove: () => this.removeSource(s),
                select: () => this.sourceSelected.emit(s),
            } as MiniDataGridItem;
        });
    }

    public getSourceGridItemsForAllocationPanel(nameProvider: (s: FundSource) => string) {
        return this.getSources().map((s) => {
            return {
                getName: () => nameProvider(s),
                getAmount: () => this.getFundSourceAmount(s),
                onChanged: this.sourcesChanged,
                remove: () => this.removeSource(s),
                select: () => this.sourceSelected.emit(s),
                check: (checked: boolean) => {
                    if (checked) {
                        this.addSource(s);
                    } else {
                        this.removeSource(s);
                    }
                },
            } as MiniDataGridItem;
        });
    }

    public getSourceDef() {
        if (!this.rule.FundSource) {
            this.rule.FundSource = { ExhaustionMethod: 'Sequential', Sources: [] };
        }
        return this.rule.FundSource;
    }

    public getSourceDefPresentation() {
        const def = this.getSourceDef();
        if (!def.PresentationOptions) {
            def.PresentationOptions = {};
        }
        return def.PresentationOptions as TSourceDefPresOptions;
    }

    public getSources() {
        const sourceDef = this.getSourceDef();
        let sources = sourceDef.Sources;
        if (!sources) {
            sources = sourceDef.Sources = [];
        }
        return sources;
    }

    public clearSources() {
        this.getSources().splice(0, Infinity);
        this.sourcesChanged.emit();
    }

    public getTargetDef() {
        if (!this.rule.Disbursement) {
            this.rule.Disbursement = { Targets: [] };
        }
        return this.rule.Disbursement;
    }

    public getTargetDefPresentation() {
        const def = this.getTargetDef();
        if (!def.PresentationOptions) {
            def.PresentationOptions = {};
        }
        return def.PresentationOptions as TTargetDefPresOptions;
    }

    public getTargets() {
        const targetDef = this.getTargetDef();
        let targets = targetDef.Targets;
        if (!targets) {
            targets = targetDef.Targets = [];
        }
        return targets;
    }

    public clearTargets() {
        this.getTargets().splice(0, Infinity);
        this.targetsChanged.emit();
    }

    public getDisbursementScope() {
        let scopeDef = this.rule.Disbursement;
        if (!scopeDef) {
            scopeDef = { Targets: null, Scope: undefined };
            this.rule.Disbursement = scopeDef;
        }
        let scope = scopeDef.Scope;
        if (!scope) {
            scope = { InclusionRules: [], ExclusionRules: [] };
            scopeDef.Scope = scope;
        }
        return scope;
    }

    public removeSource(source: FundSource) {
        const sources = this.getSources();
        const index = sources.indexOf(source);
        if (index !== -1) {
            sources.splice(index, 1);
            this.sourcesChanged.emit();
        }
    }

    public addSource(source: FundSourceSourceType | FundSource | LineItemFundSource | ExternalFundSource) {
        const sources = this.getSources();
        const newSource = typeof source !== 'string' ? source : (this.createFundSource(source, sources.length) as FundSource);
        sources.splice(0, Infinity, ...sources, newSource as FundSource);
        this.sourcesChanged.emit();

        return newSource;
    }

    public setSources(newSources: FundSource[]) {
        const sources = this.getSources();
        sources.splice(0, Infinity, ...newSources);
        this.sourcesChanged.emit();
    }

    public addTarget(target: DisbursementTargetTargetType | DisbursementTarget) {
        const targets = this.getTargets();
        const newTarget = typeof target !== 'string' ? target : (this.createDisbursementTarget(target, targets.length) as DisbursementTarget);
        targets.splice(0, Infinity, ...targets, newTarget);

        this.targetsChanged.emit();

        return newTarget;
    }

    public setScope(newScope: NamedFilterSet) {
        const scope = this.getDisbursementScope();
        scope.InclusionRules = newScope.InclusionRules;
        scope.ExclusionRules = newScope.ExclusionRules;

        this.targetsChanged.emit();
    }

    public setTargets(newTargets: DisbursementTarget[]) {
        const targets = this.getTargets();
        targets.splice(0, Infinity, ...newTargets);
        this.targetsChanged.emit();
    }

    protected createDisbursementTarget<T extends DisbursementTargetTargetType>(type: T, index?: number) {
        index = index ?? this.getTargets().length;
        const name = `Target ${index + 1}`;
        return type === 'Existing'
            ? ({
                  TargetType: 'Existing',
                  Name: name,
                  Maximum: null,
              } as ExistingDisbursementTarget)
            : ({
                  TargetType: 'NewRecords',
                  Name: name,
                  DimensionValues: null,
                  IsDimensionValuesExclusionList: false,
              } as NewRecordsDisbursementTarget);
    }

    protected createFundSource<T extends FundSourceType>(type: T, index?: number) {
        index = index ?? this.getSources().length;
        const name = `Source ${index + 1}`;
        return type === 'LineItems'
            ? {
                  SourceType: 'LineItems',
                  Name: name,
                  Filters: {
                      InclusionRules: [],
                      ExclusionRules: [],
                  },
                  Maximum: null,
              }
            : ({
                  SourceType: 'External',
                  Name: name,
                  Maximum: null,
                  ExternalDetail: {
                      Amount: 0,
                      Date: new Date().toISOString(),
                      Name: 'External Source',
                      Description: '',
                  },
              } as ExternalFundSource);
    }
}

import { QueryExpr } from '@apis/Resources';
import { Accordion, Group, NumberInput, SegmentedControl, Space } from '@mantine/core';
import { GaugeChartSettings } from '@root/Components/Charts/GaugeChart';
import { useEvent } from '@root/Services/EventEmitter';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { AggregateOperation, ChartMargins, DropdownSettings, EditorSelectExpr, FilterSettings, NumberSettings } from './Components';
import { SettingsLabel, SettingsDivider } from './Design';
import { ChartEditor } from './Models';

export const GaugeSettings = observer(function GaugeSettings({ editor }: { editor: ChartEditor }) {
    const settings = useMemo(() => editor.getChartSettings<GaugeChartSettings>(), []);
    useEvent(editor.onSettingsChanged);
    return (
        <>
            <Accordion.Item value="Data">
                <Accordion.Control value="Data">Data</Accordion.Control>
                <Accordion.Panel>
                    <Group position="apart">
                        <SettingsLabel>Value</SettingsLabel>
                        <AggregateOperation
                            options={['count', 'countvalues', 'countuniquevalues', 'sum', 'avg', 'min', 'max']}
                            editor={editor}
                            expr={editor.getValue(0).Expr as QueryExpr}
                            onChange={(x) => editor.setValue(0, { Alias: 'Value', Expr: x })}
                            types={['number']}
                        />
                    </Group>
                    <Group position="apart">
                        <SettingsLabel>Measure by</SettingsLabel>
                        <EditorSelectExpr
                            editor={editor}
                            expr={editor.getGroup(0)}
                            types={['string']}
                            onRemove={() => editor.removeGroup(0)}
                            onChange={(x) => editor.setGroup(0, x)}
                        />
                    </Group>
                    <DropdownSettings
                        options={[
                            { label: 'Currency', value: 'money' },
                            { label: 'Currency (whole)', value: 'money-whole' },
                            { label: 'Percent', value: 'percent' },
                        ]}
                        label="Format"
                        onChange={(value) => (settings.format = value as any)}
                        value={settings.format}
                    />
                    <NumberSettings label="Top N" onChange={(value) => (settings.topN = value ?? undefined)} value={settings.topN} min={0} />
                    <SettingsDivider />
                    <FilterSettings editor={editor} />
                </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="Options">
                <Accordion.Control value="Options">Options</Accordion.Control>
                <Accordion.Panel>
                    <Group position="apart">
                        <SettingsLabel icon="ti ti-chart-arcs">Size</SettingsLabel>
                        <SegmentedControl
                            data={[
                                { value: 'small', label: 'Small' },
                                { value: 'half', label: 'Half' },
                                { value: 'large', label: 'Full' },
                            ]}
                            value={settings.angle}
                            onChange={(v) => (settings.angle = v as 'small' | 'half' | 'large')}
                        ></SegmentedControl>
                    </Group>
                    <SettingsDivider />
                    <SettingsLabel icon="ti ti-temperature">Thresholds</SettingsLabel>
                    <Space h="md" />
                    <Group position="apart">
                        <SettingsLabel>Danger</SettingsLabel>
                        <NumberInput size="sm" sx={{ width: '6rem' }} value={settings.danger} onChange={(value) => (settings.danger = value)} />
                    </Group>
                    <Space h="sm" />
                    <Group position="apart">
                        <SettingsLabel>Target</SettingsLabel>
                        <NumberInput size="sm" sx={{ width: '6rem' }} value={settings.target} onChange={(value) => (settings.target = value)} />
                    </Group>
                    <Space h="sm" />
                    <Group position="apart">
                        <SettingsLabel>Maximum</SettingsLabel>
                        <NumberInput size="sm" sx={{ width: '6rem' }} value={settings.max} onChange={(value) => (settings.max = value)} />
                    </Group>
                    <ChartMargins settings={settings} />
                </Accordion.Panel>
            </Accordion.Item>
        </>
    );
});

import { MapContract, TagAutomationRule, TagAutomationRuleGroup, TagAutomationRuleGroupItem, TagAutomationRuleStatus } from '@apis/TagManager/model';
import {
    Box,
    Button,
    Card,
    Center,
    createStyles,
    Divider,
    Group,
    Space,
    Text,
    ThemeIcon,
    Title,
    Badge,
    Loader,
    UnstyledButton,
    LoadingOverlay,
    Anchor,
    Tooltip,
} from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReportContainer, ReportFooter, ReportHeader, ReportPanel } from '../Design';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useListState } from '@mantine/hooks';
import { GripVertical, ListCheck, Plus } from 'tabler-icons-react';
import { CustomColors, theme } from '@root/Design/Themes';
import { FormatService } from '@root/Services/FormatService';
import { MapAutoTagRule } from './MapAutoTagRule';
import { MapAutoTagModel } from './MapAutoTagModel';
import { observer } from 'mobx-react';
import { getSuggestedName } from './MapDefinitions';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { useId } from '@root/Services/IdGen';
import { MapContractHomeModel } from '../Models';
import { useNav } from '@root/Services/NavigationService';
import { useLink } from '@root/Services/Router/Router';
import { singleton } from 'tsyringe';

@singleton()
export class MapContractListContext {
    public refreshList = new EventEmitter(false);
}

export const MapAutoTag = observer(function MapAutoTag({ contract }: { contract: MapContract }) {
    const autoTagModel = useDi(MapAutoTagModel);
    const homeModel = useDi(MapContractHomeModel);
    const [activeRule, setActiveRule] = useState<TagAutomationRule>();
    const loading = useEventValue(autoTagModel.loading);
    const saving = useEventValue(autoTagModel.saving);
    const rearrangeEvt = useMemo(() => EventEmitter.empty(), []);
    useEvent(rearrangeEvt);

    const mapContractListCtx = useDi(MapContractListContext);
    const refreshList = useEventValue(mapContractListCtx.refreshList);
    useEffect(() => {
        if (refreshList) {
            autoTagModel.reload();
            mapContractListCtx.refreshList.emit(false);
        }
    }, [refreshList]);

    useEffect(() => {
        autoTagModel.init(contract);
    }, []);

    const editRule = (rule?: TagAutomationRule) => {
        setActiveRule(rule);
    };
    const addRule = () => {
        setActiveRule({});
    };
    const ruleGroup = autoTagModel.ruleGroup;
    const rules = ruleGroup?.Items ?? [];
    const canSave = autoTagModel.canSave();
    const saveRule = useCallback(
        async (rule: TagAutomationRule) => {
            if (rule.Id) {
                await autoTagModel.updateRule(rule);
            } else {
                await autoTagModel.addRule(rule);
            }
            setActiveRule(undefined);
        },
        [autoTagModel]
    );

    return (
        <ReportPanel>
            <ReportHeader>
                <Box>
                    <Title order={4}>Auto-Tagging</Title>
                    <Text pt={'sm'} size={'sm'}>
                        Create rules to assign MAP values to resources automatically.
                    </Text>
                </Box>
            </ReportHeader>
            <ReportContainer>
                <Space h="lg" />
                <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                    {loading ? <LoadingOverlay zIndex={1} visible /> : null}
                    {ruleGroup && rules.length > 0 ? <MapRuleCardList onChange={rearrangeEvt.emit} group={ruleGroup} onEdit={editRule} /> : null}
                    {ruleGroup && rules.length === 0 ? (
                        <Card radius="lg" withBorder sx={{ borderStyle: 'dashed', borderWidth: '2px' }}>
                            <Text size="sm" color="dimmed" italic>
                                No Auto-Tagging Rules
                            </Text>
                        </Card>
                    ) : null}
                    {!ruleGroup ? null : (
                        <Center p="lg">
                            <Button radius="xl" size="sm" color="primary" onClick={addRule} leftIcon={<Plus />}>
                                Add Rule
                            </Button>
                        </Center>
                    )}
                </Box>
                {!activeRule || !homeModel.mapResourceQuerySvc ? null : (
                    <MapAutoTagRule
                        contractId={contract.Id!}
                        automationRuleId={activeRule.Id ?? null}
                        onDelete={autoTagModel.deleteRule}
                        onSave={saveRule}
                        onClose={() => setActiveRule(undefined)}
                        mapQueryService={homeModel.mapResourceQuerySvc}
                    />
                )}
            </ReportContainer>
            <Divider />
            <ReportFooter>
                <Group position="right" mr="lg">
                    <Button disabled={!canSave} data-atid="UndoRuleGroupChangesButton" onClick={autoTagModel.reload} variant="outline">
                        Undo Changes
                    </Button>
                    <Button
                        data-atid="SaveRuleGroupButton"
                        onClick={autoTagModel.savePriority}
                        size="sm"
                        disabled={!canSave}
                        color="primary"
                        leftIcon={saving && <Loader color={'#fff' as CustomColors} size="xs" />}
                    >
                        Save
                    </Button>
                </Group>
            </ReportFooter>
        </ReportPanel>
    );
});

function MapRuleCard({
    groupItem,
    index,
    onEdit,
}: {
    groupItem: TagAutomationRuleGroupItem;
    index: number;
    onEdit: (rule: TagAutomationRule) => void;
}) {
    const { classes, cx } = useStyles();
    const formatSvc = useDi(FormatService);
    const homeModel = useDi(MapContractHomeModel);
    const { getDescendUrl, getData } = useNav();
    const { id } = getData('id');
    const link = useLink();
    const rule = groupItem.Rule;
    const item = groupItem.Rule?.Parameters?.Syntax?.MapCreditsOptions;
    const itemId = useId(item);
    const activityUrl = getDescendUrl('tag-automation-rule-activity', { ruleId: rule?.Id?.toString() ?? '', id: homeModel.contract!.Id!.toString() });
    const fmtSvc = useDi(FormatService);

    const getStatusColor = (ruleStatus?: TagAutomationRuleStatus) => {
        switch (ruleStatus) {
            case TagAutomationRuleStatus.Active:
                return theme.colors?.success?.[5];
            case TagAutomationRuleStatus.Draft:
                return theme.colors?.success?.[5];
            case TagAutomationRuleStatus.Test:
                return theme.colors?.warning?.[5];
            case TagAutomationRuleStatus.Deleted:
                return theme.colors?.gray?.[5];
            default:
                return theme.colors?.gray?.[5];
        }
    };

    const getStatusIcon = (ruleStatus?: TagAutomationRuleStatus) => {
        switch (ruleStatus) {
            case TagAutomationRuleStatus.Active:
                return 'ti ti-circle-filled';
            case TagAutomationRuleStatus.Draft:
                return 'ti ti-circle-dashed';
            case TagAutomationRuleStatus.Test:
                return 'ti ti-clock-hour-10';
            case TagAutomationRuleStatus.Deleted:
                return 'ti ti-square';
            default:
                return 'ti ti-square';
        }
    };

    return !item || !rule ? null : (
        <Draggable key={itemId} index={index} draggableId={itemId?.toString() ?? ''}>
            {(provided, snapshot) => (
                <Card
                    radius="lg"
                    withBorder
                    shadow="md"
                    mb="md"
                    className={cx({ [classes.itemDragging]: snapshot.isDragging })}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    <Card.Section withBorder sx={{ borderColor: theme.colors?.success?.[6] as CustomColors }}>
                        <Group p={'sm'} position="apart" data-atid={'MapRuleCard:' + index}>
                            <Group>
                                <ThemeIcon size="xl" color={'#FFFFFF' as CustomColors} {...provided.dragHandleProps}>
                                    <GripVertical color={theme.colors?.primary?.[6] as CustomColors} />
                                </ThemeIcon>
                                <Box>
                                    <Badge color="primary" size="xl">
                                        {formatSvc.formatInt(index + 1)}
                                    </Badge>
                                    <Text size="xs" color="primary">
                                        Priority
                                    </Text>
                                </Box>
                                <Box>
                                    <UnstyledButton
                                        onClick={() => {
                                            onEdit(rule);
                                        }}
                                    >
                                        <Text span size="sm">
                                            {groupItem.Rule?.RuleName ?? getSuggestedName(item)}
                                        </Text>
                                        {groupItem.Rule?.Status === 'Test' ? (
                                            <>
                                                <br />
                                                {new Date(groupItem.Rule.TestEndDate!) < new Date() ? (
                                                    <Text color={'error'} size={12}>
                                                        Expired {fmtSvc.toShortDate(fmtSvc.toLocalDate(rule.TestEndDate))}
                                                    </Text>
                                                ) : (
                                                    <Text size={12}>Expires {fmtSvc.toShortDate(fmtSvc.toLocalDate(rule.TestEndDate))}</Text>
                                                )}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </UnstyledButton>
                                </Box>
                                <Divider orientation="vertical" />
                                <Box>
                                    <Text span size="sm" color="dimmed">
                                        map-migrated:{' '}
                                    </Text>
                                    <Text span>
                                        {item.ValueToAdd}
                                        {homeModel.contract?.ProgramId}
                                    </Text>
                                </Box>
                            </Group>
                            <Group mr="lg">
                                <Tooltip
                                    offset={0}
                                    position="bottom"
                                    color={'#fff' as CustomColors}
                                    label={
                                        <Text size="sm" color={getStatusColor(groupItem.Rule?.Status) as CustomColors}>
                                            {groupItem.Rule?.Status}
                                        </Text>
                                    }
                                >
                                    <Box>
                                        <Text span color="dimmed">
                                            <i
                                                className={getStatusIcon(groupItem.Rule?.Status)}
                                                style={{ color: getStatusColor(groupItem.Rule?.Status) }}
                                            ></i>
                                        </Text>
                                    </Box>
                                </Tooltip>
                                <Anchor pt={'xs'} {...link(activityUrl)}>
                                    <ListCheck style={{ color: theme.colors?.primary?.[6] as CustomColors }} />
                                </Anchor>
                            </Group>
                        </Group>
                    </Card.Section>
                </Card>
            )}
        </Draggable>
    );
}

function MapRuleCardList({
    onEdit,
    group,
    onChange,
}: {
    onEdit: (rule: TagAutomationRule) => void;
    group: TagAutomationRuleGroup;
    onChange: () => void;
}) {
    const rules = group.Items ?? [];
    const [state, handlers] = useListState(rules);
    useEffect(() => {
        handlers.setState(rules);
    }, [rules]);

    const items = state.map((groupItem, index) => {
        return <MapRuleCard key={index} onEdit={onEdit} groupItem={groupItem} index={index} />;
    });
    useEffect(() => {
        group.Items = state;
        onChange();
    }, [state]);

    return (
        <DragDropContext onDragEnd={({ destination, source }) => handlers.reorder({ from: source.index, to: destination?.index || 0 })}>
            <Droppable droppableId="dnd-list" direction="vertical">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {items}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

const useStyles = createStyles((theme) => ({
    headerSection: {},
    switchLabel: {
        color: theme.colors.success[0],
    },
    switchInput: {
        backgroundColor: theme.colors.gray[6],
        ':checked': {
            backgroundColor: theme.colors.success[6],
            borderColor: theme.colors.success[0],
        },
    },
    root: {
        ':hover': {
            backgroundColor: theme.colors.error[1],
        },
    },
    itemDragging: {
        boxShadow: theme.shadows.sm,
    },
    dragHandle: {
        ...theme.fn.focusStyles(),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: theme.colors.gray[6],
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
    },
}));

import { Badge, Box, Button, CloseButton, Divider, Drawer, Group, MultiSelect, Popover, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { getFilterFromSelection, MonthlyInvoiceRow, MonthlyInvoicesGrid } from '@root/Components/Invoices/MonthlyInvoicesGrid';
import { PageBody, PageContent } from '@root/Design/Layout';
import { useDi, useDiMemo } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { getUniqueAccounts, getUniqueMonths, RangePickerItem, useRangePickerData } from './Components/MonthlyRangePicker';
import { useDisclosure } from '@mantine/hooks';
import { FillerSwitch } from '@root/Design/Filler';
import { ContextButton } from '@root/Components/ContextButton/ContextButton';
import { ChevronDown, Search } from 'tabler-icons-react';
import { Picker } from '@root/Components/Picker/Picker';
import { QueryExpr, QueryResult } from '@apis/Resources';
import { Query } from '@apis/Invoices/model';
import { VarianceDetails } from './Components/VarianceDetails';
import { ChartKeySelectionStrategy } from './Models/ChartKeySelectionStrategy';
import { useRowSelector } from './Components/ChartKeyRowSelector';
import { GridGroupByState } from '@root/Components/DataGrid/Models';
import { AccountPickerItem, AccountValueItem } from './Components/AccountPicker';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';
import { CostFieldSelector } from './Components/CostFieldSelector';
import { useEventValue } from '@root/Services/EventEmitter';
import { withSchemaPreloader } from '@root/Components/Invoices/SchemaPreloader';
import { useInvoiceCostField } from '@root/Services/Invoices/InvoiceCostFieldContext';

export function InvoiceComparison() {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const invoiceApi = useDiMemo(InvoiceApiService);
    const { data: ranges, loading: rangesLoading } = useRangePickerData(invoiceApi);
    const [months, setMonths] = useState<Date[]>([]);
    const [selectedAccounts, setSelectedAccounts] = useState<AccountSelection>();
    const [gridFilters, setGridFilters] = useState<QueryExpr[]>();
    const gridSelectionStrategy = useMemo(() => new ChartKeySelectionStrategy(10), []);
    const [gridSelectionFilters, setGridSelectionFilters] = useState<QueryExpr[]>();
    const availableMonths = useMemo(() => (ranges ? getUniqueMonths(ranges, fmtSvc).map((r) => r.date) : []), [ranges]);
    const groupBy = useMemo(() => [{ id: 'product/ProductName', sortDir: 'Desc', sortMode: 'count' }] as GridGroupByState[], []);

    const costField = useInvoiceCostField();

    useEffect(() => {
        if (!rangesLoading && ranges?.length) {
            setSelectedAccounts({
                accounts: getUniqueAccounts(ranges),
            });
            const months = getUniqueMonths(ranges, fmtSvc);
            setMonths(months.length > 1 ? months.slice(-2).map((m) => m.date) : []);
        }
    }, [rangesLoading, ranges]);
    const filters = useMemo(
        () =>
            selectedAccounts
                ? [{ Operation: 'eq', Operands: [{ Field: 'bill/PayerAccountId' }, { Value: selectedAccounts.accounts.map((a) => a.accountId) }] }]
                : undefined,
        [selectedAccounts]
    );

    const handleMonthChange = useCallback(
        (month: Date, index: number) => {
            months![index] = month;
            setMonths(months!.slice());
        },
        [months]
    );
    const varianceQueryApi = useMemo(() => {
        return function <T>(query: Query) {
            return invoiceApi.queryMonthlyRollup(query, months) as Promise<QueryResult<T>>;
        };
    }, [invoiceApi, months]);
    const varianceFilters = useMemo(() => {
        const result: QueryExpr[] = [];
        result.push({ Operation: 'eq', Operands: [{ Field: 'UsageMonth' }, { Value: months.map((m) => fmtSvc.formatYearMonth(m)) }] });
        if (filters) {
            result.push({ Operation: 'and', Operands: filters });
        }
        if (gridFilters) {
            result.push(...gridFilters);
        }
        if (gridSelectionFilters) {
            result.push(...gridSelectionFilters);
        }
        return result;
    }, [filters, months, gridFilters, gridSelectionFilters, groupBy]);
    const handleSelectionChanged = useCallback(async ({ getItems }: { getItems: () => Promise<MonthlyInvoiceRow[]> }) => {
        const selections = await getItems();
        const gridFilters = getFilterFromSelection(selections);
        setGridSelectionFilters(gridFilters);
    }, []);
    const updateLeftMonth = useCallback((month: Date) => handleMonthChange(month, 0), [handleMonthChange]);
    const updateRightMonth = useCallback((month: Date) => handleMonthChange(month, 1), [handleMonthChange]);
    const rowSelector = useRowSelector({ gridSelectionStrategy, color: theme.colors.primary[6], tooltip: 'Focus Variance Details' });

    return (
        <ConnectionCheck>
            {() => (
                <PageBody>
                    <PageContent>
                        <Stack sx={{ height: '100%', width: 1400 }} p="lg">
                            <Group position="apart">
                                <Text size={20} data-atid="InvoiceComparisonHeader">
                                    Invoice Comparison
                                </Text>
                                <Group>
                                    {selectedAccounts && ranges && months.length ? (
                                        <>
                                            <MonthPicker
                                                invoiceApi={invoiceApi}
                                                month={months[0]}
                                                onChange={updateLeftMonth}
                                                months={availableMonths}
                                            />
                                            <MonthPicker
                                                invoiceApi={invoiceApi}
                                                month={months[1]}
                                                onChange={updateRightMonth}
                                                months={availableMonths}
                                            />
                                            <CostFieldSelector />
                                            <AccountPickerButton onSelected={setSelectedAccounts} ranges={ranges} selected={selectedAccounts} />
                                        </>
                                    ) : null}
                                </Group>
                            </Group>
                            <Group sx={{ height: '100%', minHeight: 0 }} align="stretch" noWrap>
                                <Box sx={{ width: 1030 }}>
                                    <FillerSwitch loading={rangesLoading} noData={months.length !== 2}>
                                        {() => (
                                            <MonthlyInvoicesGrid
                                                persistenceKey="InvoiceComparison"
                                                filters={filters}
                                                invoiceApi={invoiceApi}
                                                onFilterChanged={setGridFilters}
                                                includeDifference
                                                months={months}
                                                onSelectionChanged={handleSelectionChanged}
                                                selectionStrategy={gridSelectionStrategy}
                                                renderRowSelector={rowSelector}
                                                defaultGroupBy={groupBy}
                                                costField={costField}
                                            />
                                        )}
                                    </FillerSwitch>
                                </Box>
                                <Box sx={{ width: 300 }}>
                                    <FillerSwitch loading={rangesLoading} noData={months.length !== 2}>
                                        {() => (
                                            <VarianceDetails
                                                filters={varianceFilters}
                                                from={months[0]}
                                                to={months[1]}
                                                groupBy="UsageMonth"
                                                queryApi={varianceQueryApi}
                                                value="Total"
                                                costField={costField}
                                            />
                                        )}
                                    </FillerSwitch>
                                </Box>
                            </Group>
                        </Stack>
                    </PageContent>
                </PageBody>
            )}
        </ConnectionCheck>
    );
}
endpoint('invoice-comparison', withSchemaPreloader(InvoiceComparison), 'Invoice Comparison');

interface AccountSelection {
    accounts: { accountId: string; accountName: string }[];
}

function AccountPickerButton({
    ranges,
    selected,
    onSelected,
}: {
    ranges: RangePickerItem[];
    selected: AccountSelection;
    onSelected: (item: AccountSelection) => void;
}) {
    const fmtSvc = useDi(FormatService);
    const [opened, { toggle, close }] = useDisclosure(false);
    const accountLabel: ReactNode =
        selected.accounts.length === 1 ? (
            <Text color="primary" size="sm">
                {fmtSvc.awsAccountId(selected.accounts[0].accountId) + ' ' + selected.accounts[0].accountName}
            </Text>
        ) : (
            <Badge mt={4} variant="light">
                {selected.accounts.length}
            </Badge>
        );
    const accountPlural = selected.accounts.length > 1 ? 's' : '';
    const handleChange = useCallback(
        (selected: AccountSelection) => {
            onSelected(selected);
            close();
        },
        [close, onSelected]
    );
    const buttonSections = [{ label: 'Account' + accountPlural + ' Selected', text: accountLabel }];

    return (
        <>
            <ContextButton icon={<Search />} onClick={toggle} sections={buttonSections} />
            <Drawer position="right" opened={opened} onClose={close} padding={0} size={400} withCloseButton={false}>
                <AccountPicker ranges={ranges} selected={selected} onSelected={handleChange} onClose={close} />
            </Drawer>
        </>
    );
}

interface IMonthPickerProps {
    month: Date;
    onChange: (month: Date) => void;
    months: Date[];
    invoiceApi: InvoiceApiService;
}

export function MonthPicker({ month, onChange, months }: IMonthPickerProps) {
    const fmtSvc = useDi(FormatService);
    const [opened, { toggle, close }] = useDisclosure(false);
    const handleChange = useCallback(
        (selected: Date[]) => {
            onChange(selected[0]);
            close();
        },
        [close, onChange]
    );
    const selected = useMemo(() => [month], [month]);

    return (
        <Popover opened={opened} onClose={close}>
            <Popover.Target>
                <Button
                    onClick={toggle}
                    size="xl"
                    color="primary"
                    sx={{ borderRadius: '4px 4px 8px 8px', borderTop: 'solid 5px #0003' }}
                    leftIcon={<i className="ti ti-calendar-filled" style={{ fontSize: '40px' }} />}
                >
                    <Box pl={6}>
                        <Text size="xs" sx={{ opacity: 0.8 }} weight="normal">
                            Month
                        </Text>
                        <Group spacing={4} mt={-4}>
                            <ChevronDown size={16} />
                            <Text size="md">{fmtSvc.formatLongMonthYear(month)}</Text>
                        </Group>
                    </Box>
                </Button>
            </Popover.Target>
            <Popover.Dropdown p={0}>
                <Picker
                    items={months}
                    onChange={handleChange}
                    selections={selected}
                    nameAccessor={(m) => fmtSvc.formatLongMonthYear(m)}
                    minimizeHeight
                    height={300}
                    width={200}
                    mode="single"
                    noFilter
                />
            </Popover.Dropdown>
        </Popover>
    );
}

function AccountPicker({
    ranges,
    selected,
    onSelected,
    onClose,
}: {
    ranges: RangePickerItem[];
    selected: AccountSelection;
    onSelected: (item: AccountSelection) => void;
    onClose: () => void;
}) {
    const fmtSvc = useDi(FormatService);
    const theme = useMantineTheme();
    const [selection, setSelection] = useState<AccountSelection>({ ...selected });
    useEffect(() => {
        setSelection({ ...selected });
    }, [selected]);
    const availableAccounts = useMemo(() => getUniqueAccounts(ranges), [ranges]);
    const accountOptions = useMemo(
        () => availableAccounts.map((a) => ({ value: a.accountId, label: fmtSvc.awsAccountId(a.accountId), accountName: a.accountName })),
        [availableAccounts]
    );
    const selectedAccounts = useMemo(() => selection.accounts.map((a) => a.accountId), [selection]);
    const updateAccounts = useCallback(
        (accounts: string[]) => {
            setSelection((s) => ({
                accounts: accounts.map((a) => availableAccounts.find((aa) => aa.accountId === a)).filter((a) => !!a) as RangePickerItem[],
            }));
        },
        [selection]
    );
    const apply = useCallback(() => onSelected(selection), [selection, onSelected]);
    const reset = useCallback(() => setSelection({ ...selected }), [selected]);

    const canApply = selection.accounts.length > 0;
    return (
        <Stack sx={{ height: '100%' }}>
            <Box>
                <Group position="apart" p="lg">
                    <Title order={4}>Account Selection</Title>
                    <CloseButton onClick={onClose} />
                </Group>
                <Divider />
            </Box>
            <Box p="lg" sx={{ flex: 1 }}>
                <MultiSelect
                    label="Accounts"
                    data={accountOptions}
                    value={selectedAccounts}
                    onChange={updateAccounts}
                    itemComponent={AccountPickerItem}
                    valueComponent={AccountValueItem}
                    clearable
                />
            </Box>
            <Box sx={{ background: theme.colors.gray[3] }}>
                <Divider />
                <Group position="apart" p="lg">
                    <Button variant="outline" onClick={reset}>
                        Reset
                    </Button>
                    <Group>
                        <Button variant="outline" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button disabled={!canApply} onClick={apply}>
                            Apply
                        </Button>
                    </Group>
                </Group>
            </Box>
        </Stack>
    );
}

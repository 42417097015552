import { QueryExpr, QueryOperation } from '@apis/Resources';
import { QuerySelectExpr } from '@apis/Resources/model';
import { Accordion, ActionIcon, Anchor, Box, Button, Divider, Group, TextInput, Tooltip } from '@mantine/core';
import { GridChartSettings } from '@root/Components/Charts/GridChart';
import { AddFilterButton, DataFilters } from '@root/Components/Filter/Filters';
import { operationLookup } from '@root/Components/Filter/Services';
import { FieldPicker } from '@root/Components/Picker/FieldPicker';
import { useEvent } from '@root/Services/EventEmitter';
import { traverseExpr } from '@root/Services/QueryExpr';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { LayoutColumns, Calculator } from 'tabler-icons-react';
import { AddAggregationOperation, AggregateOperation, EditorField, EditorSelectExpr, ExprTag } from './Components';
import { SettingsDivider, SettingsLabel } from './Design';
import { ChartEditor } from './Models';

export const GridSettings = observer(function GridSettings({ editor }: { editor: ChartEditor }) {
    const settings = useMemo(() => editor.getChartSettings<GridChartSettings>(), []);
    useEvent(editor.onSettingsChanged);
    const addColumn = (select: QuerySelectExpr | QueryExpr) => {
        if (!('Expr' in select)) {
            select = { Expr: select, Alias: '' };
        }
        if (select.Expr) {
            const type = editor.queryDescriptorSvc.getExprType(select.Expr as QueryExpr);
            const alias = editor.queryDescriptorSvc.getName(select.Expr as QueryExpr);
            select.Alias = alias;
            settings.columns.push({ type, select, id: alias + '-' + Date.now() });
        }
    };
    const updateColumn = (select: QuerySelectExpr, index: number) => {
        const type = editor.queryDescriptorSvc.getExprType(select.Expr as QueryExpr);
        settings.columns[index].select = select;
        settings.columns[index].type = type;
    };
    const updateAlias = (text: string, index: number) => {
        settings.columns[index].select.Alias = text;
    };

    const remove = (i: number) => {
        settings.columns.splice(i, 1);
    };
    return (
        <>
            <Accordion.Item value="Data">
                <Accordion.Control value="Data">Data</Accordion.Control>
                <Accordion.Panel>
                    <SettingsLabel>Columns</SettingsLabel>
                    <SettingsDivider />
                    {settings.columns.map((c, i) => (
                        <Box key={i}>
                            <Group position="apart">
                                <SettingsLabel size="xs">Column {i + 1}</SettingsLabel>
                                <Tooltip label="Remove Column">
                                    <ActionIcon onClick={() => remove(i)}>
                                        <i className="ti ti-x" />
                                    </ActionIcon>
                                </Tooltip>
                            </Group>
                            <Group position="apart">
                                <SettingsLabel>Header</SettingsLabel>
                                <TextInput
                                    size="xs"
                                    value={settings.columns[i].select?.Alias ?? ''}
                                    onChange={(e) => updateAlias(e.currentTarget.value, i)}
                                />
                            </Group>
                            <Group position="apart" noWrap align="start">
                                <SettingsLabel>Value</SettingsLabel>
                                <GridSettingsColumn key={i} select={c.select} editor={editor} onChange={(x) => updateColumn(x, i)} />
                            </Group>
                            <SettingsDivider />
                        </Box>
                    ))}
                    <Group position="right">
                        <EditorSelectExpr
                            editor={editor}
                            onChange={addColumn}
                            opener={(open) => (
                                <Anchor onClick={open}>
                                    <LayoutColumns size={16} /> Add Column
                                </Anchor>
                            )}
                        ></EditorSelectExpr>
                        <AddAggregationOperation
                            editor={editor}
                            onChange={addColumn}
                            expr={{ Operation: 'Count', Operands: [] }}
                            opener={(open) => (
                                <Anchor onClick={open}>
                                    <Calculator size={16} /> Add Calculation
                                </Anchor>
                            )}
                            types={['number', 'date']}
                        />
                    </Group>
                </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="Options">
                <Accordion.Control value="Options">Options</Accordion.Control>
                <Accordion.Panel></Accordion.Panel>
            </Accordion.Item>
        </>
    );
});

const GridSettingsColumn = observer(function GridSettingsColumn({
    editor,
    onChange,
    select,
}: {
    editor: ChartEditor;
    onChange: (select: QuerySelectExpr) => void;
    select: QuerySelectExpr;
}) {
    const operation = (select: QuerySelectExpr) =>
        traverseExpr((select.Expr as QueryExpr) ?? { Value: '' }, (x) => ('Operation' in x ? (x as QueryOperation) : undefined));
    const isAgg = (select: QuerySelectExpr) => operationLookup.get(operation(select)?.Operation?.toLowerCase() ?? '')?.aggregate;
    const operationName = (select: QuerySelectExpr) => operation(select)?.Operation?.toLowerCase();
    const canFilter = (select: QuerySelectExpr) => ['count', 'percent'].includes(operationName(select) ?? '');
    const agg = isAgg(select);
    const filterable = canFilter(select);
    const conditionOp = traverseExpr(select.Expr as QueryExpr, (x) =>
        'Operation' in x && x.Operation === 'and' ? (x as QueryOperation) : undefined
    );
    const conditions = conditionOp?.Operands;
    const applyFilters = (filters: QueryExpr[]) => {
        const op = operation(select);
        if (op) {
            if (!op.Operands) {
                op.Operands = [];
            }

            if (!filters.length) {
                op.Operands?.splice(0, Infinity);
            } else if (!op.Operands?.length) {
                op.Operands.push({ Operation: 'and', Operands: filters });
            } else {
                conditionOp!.Operands = filters;
            }
            onChange(select);
        }
    };

    return (
        <DataFilters
            align="end"
            valueProvider={editor.valueProvider}
            fieldInfoProvider={editor.queryDescriptorSvc.fieldInfoProvider}
            filters={(conditions ?? []) as QueryExpr[]}
            onChange={applyFilters}
            renderFieldPicker={(select) => (
                <Box>
                    <FieldPicker mode="single" selections={[]} schema={editor.shemaSvc} onChange={([f]) => select(f.path)} />
                </Box>
            )}
            renderAddFilter={(model) => (
                <>
                    <EditorSelectExpr
                        editor={editor}
                        onChange={onChange}
                        expr={select}
                        opener={(open) => <ExprTag editor={editor} onClick={open} expr={select.Expr as QueryExpr} />}
                        types={agg ? ['number', 'date'] : undefined}
                    />
                    {filterable ? <AddFilterButton onClick={model.addEmptyFilter} /> : null}
                </>
            )}
        />
    );
});

import { Button, UnstyledButton } from '@mantine/core';
import { PageContent } from '@root/Design/Layout';
import { Clearfix, FileDownload, SubHeading } from '@root/Design/Primitives';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { HomeShell } from './Common';

export function Recommendations() {
    return (
        <HomeShell>
            <PageContent></PageContent>
        </HomeShell>
    );
}
endpoint('recommendations', Recommendations, 'Recommendations');

import { LoadingOverlay, useMantineTheme } from '@mantine/core';
import { colorPalette } from '@root/Design/Themes';
import { useDi, useDiContainer } from '@root/Services/DI';
import { useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { useMemo } from 'react';
import { ActivityDetailsModel } from './ActivityDetailsService';
import { ActivityItemModel, JobStatus } from './ActivityTypes';
import { ActivityDetailsContainer, DetailRow, ProgressBadge, RowDivider } from './Design';

export function GenericDetails({ item: { job: item }, displayInfo }: { item: JobStatus; displayInfo: ActivityItemModel }) {
    const container = useDiContainer();
    const theme = useMantineTheme();
    const formatSvc = useDi(FormatService);
    const model = useMemo(() => {
        const result = container.resolve(ActivityDetailsModel);
        result.init(item);
        return result;
    }, []);
    const loading = useEventValue(model.loading);
    const status = model.statusInfo;
    const remaining = (model.progress?.Created ?? 0) + (model.progress?.Started ?? 0);
    const succeeded = model.progress?.Succeeded ?? 0;
    const failed = model.progress?.Failed ?? 0;

    return loading ? (
        <LoadingOverlay visible style={{ color: theme.colors?.primary?.[6] }} />
    ) : (
        <ActivityDetailsContainer>
            <DetailRow title="Started">{formatSvc.toLocal(item.StartedAt)}</DetailRow>
            {status?.inProgress ? null : <DetailRow title="Finished">{formatSvc.toLocal(model.progress?.lastDate)}</DetailRow>}
            <RowDivider />
            <DetailRow title="Status">
                <ProgressBadge value={remaining} text="Remaining" />
                <ProgressBadge value={succeeded} color="success" text="Succeeded" />
                <ProgressBadge value={failed} color="error" text="Failed" />
            </DetailRow>
            <RowDivider />
            <DetailRow title="Requested By">{model.getRequestedBy()}</DetailRow>
        </ActivityDetailsContainer>
    );
}

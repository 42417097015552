import { QueryExpr, QueryResult } from '@apis/Resources';
import { Query } from '@apis/Resources/model';
import { Center, Divider, Group, Loader, Text } from '@mantine/core';
import { useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { IDailyRollup, InvoiceSchemaService } from '@root/Services/Invoices/InvoiceSchemaService';
import { FieldInfo, SchemaService, SchemaValueProvider, ValuesGroupOtherText } from '@root/Services/QueryExpr';
import { ReactNode, useEffect, useMemo } from 'react';
import { inject, injectable } from 'tsyringe';
import { IDashboardConfig } from '../DashboardLayout/Models';
import { DataGrid } from '../DataGrid';
import { DataGridModel } from '../DataGrid/DataGridModel';
import { FieldInfoColumnAdapter } from '../DataGrid/FieldInfoColumnAdapter';
import { ChildAccessor, ColumnConfig, DataColumnConfig, DataGridState, DataGridStateChange, IDataSource } from '../DataGrid/Models';
import { QueryExprGroupHelper } from '../DataGrid/QueryExprGroupHelper';
import { IValueProviderFactory } from '../Filter/Filters';
import { Node } from '../VirtualTree/Node';

export function InvoicesGrid({
    persistenceKey,
    onStateChanged,
    dateRange,
    filterRightPlaceHolder,
    field,
}: {
    persistenceKey?: string;
    onStateChanged?: (state?: DataGridStateChange) => void;
    dateRange?: { from?: Date; to?: Date };
    filterRightPlaceHolder?: ReactNode;
    field: string;
}) {
    const container = useDiContainer();
    const model = useMemo(() => container.resolve(InvoicesGridModel).init(field), []);
    useEffect(() => {
        model.setStateChangeListener(onStateChanged);
    }, [model, model]);
    useEffect(() => {
        model.setDateRange(dateRange?.from, dateRange?.to);
    }, [dateRange?.from, dateRange?.to]);

    const initializing = useEventValue(model.initializing);

    return initializing ? (
        <Center>
            <Loader />
        </Center>
    ) : !model.schema || !model.datasource ? (
        <></>
    ) : (
        <DataGrid
            childAccessor={model.childAccessor}
            columns={model.columns}
            dataSource={model.datasource}
            onModelLoaded={model.attach}
            schemaSvc={model.schema}
            statePersistence={persistenceKey ? { key: persistenceKey } : undefined}
            state={model.defaultState}
            onStateLoaded={model.onGridStateLoaded}
            filterValueProvider={model.filterValueProvider}
            allowGroupBy
            allowPinning
            filterRightPlaceHolder={filterRightPlaceHolder}
            groupByLabels={model.groupByLabels}
            renderFooter
            showRefresh
            renderGroupBy={model.renderGroupByRow}
        />
    );
}

type InvoiceDataGroup = { cost: number; value: string; count: number };
type InvoiceRow = InvoiceDataGroup | IDailyRollup;

@injectable()
export class InvoicesGridModel {
    public initializing = new EventEmitter(true);
    public schema?: SchemaService;
    public datasource?: IDataSource<InvoiceRow>;
    public columns: ColumnConfig<InvoiceRow>[] = [];
    public defaultState?: DataGridState;
    public groupByLabels = { count: 'by Cost' };
    public filterValueProvider?: IValueProviderFactory;

    private dateRange?: { from?: Date; to?: Date } = {};
    private gridModel?: DataGridModel;
    private groupQueryHelper?: QueryExprGroupHelper<InvoiceRow>;
    private stateChangeListener?: { disposer?: () => void; listener: (state?: DataGridStateChange) => void };

    public constructor(
        @inject(FormatService) private readonly formatSvc: FormatService,
        @inject(InvoiceSchemaService) private readonly invoiceSchemaSvc: InvoiceSchemaService,
        @inject(InvoiceApiService) private readonly invoiceApiSvc: InvoiceApiService,
        @inject(FieldInfoColumnAdapter) private readonly fieldInfoColumnAdapter: FieldInfoColumnAdapter<InvoiceRow>
    ) {}

    public init(field: string) {
        this.initialize(field);
        return this;
    }

    public setDateRange(from?: Date, to?: Date) {
        if (this.dateRange?.from !== from || this.dateRange?.to !== to) {
            this.dateRange = { from, to };
            this.gridModel?.refresh();
        }
    }

    private async initialize(field: string) {
        try {
            this.initializing.emit(true);
            const types = await this.invoiceSchemaSvc.getSchema();
            this.schema = new SchemaService(types);
            this.filterValueProvider = new SchemaValueProvider(this.schema, (q) => this.invoiceApiSvc.query(q, this.dateRange ?? {}));
            this.schema.resolveChildren();
            //let { field } = useNav().getData('field');
            this.groupQueryHelper = this.createGroupQueryHelper(field ?? '');
            this.datasource = this.createDatasource(field ?? '');
            this.columns = this.createColumns();
            this.defaultState = this.createDefaultState(field ?? '');
        } finally {
            this.initializing.emit(false);
        }
    }

    //#region Grid Hooks
    public attach = (gridModel: DataGridModel) => {
        this.gridModel = gridModel;
        this.groupQueryHelper?.attach(gridModel);
        if (this.stateChangeListener?.listener) {
            this.setStateChangeListener(this.stateChangeListener.listener);
        }
    };

    public createFooterRenderer = (columnOptions: DataColumnConfig<InvoiceRow>, fieldInfo: FieldInfo) => {
        return () => <></>; // todo
    };

    public childAccessor = {
        hasChildren: (item: InvoiceRow) => {
            return 'cost' in item;
        },
    } as ChildAccessor<InvoiceRow>;

    public renderGroupByRow = (node: Node<InvoiceRow>) => {
        const groupDepth = this.gridModel?.getGroupByDepth() ?? 0;
        const depthW = (groupDepth - node.depth) * 25;
        const item = node.item as unknown as InvoiceDataGroup;
        const cost = item.cost < 0.01 && item.cost > 0 ? '< $0.01' : this.formatSvc.formatMoneyNoDecimals(item.cost);
        return (
            <Group>
                <Text align="right" sx={{ width: 100 + depthW }}>
                    {cost}
                </Text>
                <Divider orientation="vertical" />
                <Text>
                    {item.value === ValuesGroupOtherText ? 'Other' : item.value} ({this.formatSvc.formatInt(item.count)} Records)
                </Text>
            </Group>
        );
    };

    public setStateChangeListener(onStateChanged: ((state?: DataGridStateChange) => void) | undefined) {
        this.stateChangeListener?.disposer?.();
        if (onStateChanged) {
            this.stateChangeListener = {
                listener: onStateChanged,
                disposer: this.gridModel?.gridStateChanged.listen(onStateChanged).dispose,
            };
        }
    }

    public onGridStateLoaded = (config?: IDashboardConfig) => {
        const state = config?.layout?.length ? config.layout[0].data : null;
        if (state) {
            this.stateChangeListener?.listener?.({ state, changes: new Set(['filters', 'groupBy']) });
        }
    };
    //#endregion

    //#region Initialization
    private createGroupQueryHelper(field: string) {
        return new QueryExprGroupHelper<InvoiceRow>(
            (q) => this.invoiceApiSvc.query(q, this.dateRange ?? {}) as Promise<QueryResult<InvoiceRow>>,
            (q) => this.runGroupQuery(q),
            undefined,
            undefined,
            [{ Direction: 'Asc', Expr: { Field: field == 'BilledDate' ? 'BilledDate' : 'UsageStartDate' } }],
            { count: 'cost' }
        );
    }

    private runGroupQuery(query: Query) {
        if (query.Select && query.Select.length) {
            query = { ...query };
            query.Select!.push({ Alias: 'cost', Expr: { Operation: 'sum', Operands: [{ Field: 'lineItem/UnblendedCost' }] } });
        }
        return this.invoiceApiSvc.query(query, this.dateRange ?? {}) as Promise<QueryResult<InvoiceRow>>;
    }

    private createDatasource(field: string) {
        return {
            getPage: (start, end, state, parent) => {
                const rangeCriteria: QueryExpr[] = [];
                if (this.dateRange?.from) {
                    rangeCriteria.push({
                        Operation: 'gte',
                        Operands: [{ Field: field == 'BilledDate' ? 'BilledDate' : 'UsageStartDate' }, { Value: this.dateRange.from }],
                    });
                }
                if (this.dateRange?.to) {
                    rangeCriteria.push({
                        Operation: 'lte',
                        Operands: [{ Field: field == 'BilledDate' ? 'BilledDate' : 'UsageEndDate' }, { Value: this.dateRange.to }],
                    });
                }
                return this.groupQueryHelper?.getPage(
                    start,
                    end,
                    state,
                    parent,
                    rangeCriteria.length ? { Operation: 'and', Operands: rangeCriteria } : undefined
                );
            },
        } as IDataSource<InvoiceRow>;
    }

    private createColumns() {
        return this.schema!.rootTypeInfo.flatMap((t) => {
            return (
                t.fields?.map((f) => {
                    const column = this.fieldInfoColumnAdapter.adapt(f);
                    column.footerRenderer = this.createFooterRenderer(column, f);
                    if (column.type === 'string') {
                        column.allowGrouping = true;
                    }
                    return column;
                }) ?? []
            );
        });
    }

    private createDefaultState(field: string) {
        return {
            columns: [
                { id: field == 'BilledDate' ? 'Common.BilledDate' : 'Common.UsageStartDate', width: 130 },
                { id: 'Common.UsageEndDate', width: 130 },
                { id: 'bill.bill/PayerAccountId', width: 130 },
                { id: 'lineItem.lineItem/UsageAccountId', width: 130 },
                { id: 'product.product/productFamily', width: 200 },
                { id: 'product.product/ProductName', width: 200 },
                { id: 'lineItem.lineItem/ProductCode', width: 200 },
                { id: 'lineItem.lineItem/UsageType', width: 200 },
                { id: 'lineItem.lineItem/ResourceId', width: 200 },
                { id: 'bill.bill/BillingEntity', width: 130 },
                { id: 'product.product/region', width: 130 },
                { id: 'lineItem.lineItem/UnblendedCost', width: 130 },
            ],
            filters: [],
            sort: [{ Expr: { Field: 'lineItem/UnblendedCost' }, Direction: 'Desc' }],
            groupBy: [
                {
                    id: 'product.product/productFamily',
                    sortDir: 'Desc',
                    sortMode: 'count',
                },
            ],
        } as DataGridState;
    }
    //#endregion
}

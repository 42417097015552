import { Space } from '@mantine/core';
import { RuleResourceFilter, ValidationIssues } from './FilterResources';
import { PreviewRuleCard } from './PreviewRuleCard';
import RuleStatusCard from './RuleStatusCard';
import { RuleEditor } from './Model';
import { ReactNode, useCallback } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { FilterResourcesCard } from './FilterResourcesCard';
import { useFilterValidation } from './RuleDescriber';

export const StandardRuleLayout = observer(function StandardRuleLayout({ ruleEditor, children }: { ruleEditor: RuleEditor; children: ReactNode }) {
    const filterValidityChanged = useFilterValidation(ruleEditor);

    return (
        <>
            {children}
            <Space h="md" />
            {ruleEditor.rule ? <FilterResourcesCard ruleEditor={ruleEditor} onValidationIssuesChanged={filterValidityChanged} /> : null}
            <Space h="md" />
            <PreviewRuleCard ruleEditor={ruleEditor} />
            <Space h="md" />
            <RuleStatusCard ruleEditor={ruleEditor} />
        </>
    );
});

const FooterGroup = styled.div`
    display: grid;
    grid-template-columns: 33% auto;
    align-items: stretch;
    column-gap: ${(p) => p.theme.spacing.md}px;
`;

import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { SettingsPage } from '../SettingsPage';
import { TextInput, Text, Button, Grid, Select, ActionIcon, SelectItem, LoadingOverlay, Group, Title, createStyles } from '@mantine/core';
import { useForm } from '@mantine/form';
import { openConfirmModal, openModal } from '@mantine/modals';
import { useEffect, useState } from 'react';
import { getNewSubscriptionGetCompanyInfo, getUserGetCompanyUsers, postNewSubscriptionUpdateCompanyInfo } from '@apis/Customers';
import { UserListItem, CompanyInfo, Address, ModelsCustomersAddress } from '@apis/Customers/model';
import { INavigatingEvent, useOnNavigating } from '@root/Services/Router/Router';
import { CompanyAddress, CompanyAddressFields } from '@root/Components/CompanyInfo/CompanyAddress';
import { useAuthZValues } from '@root/Services/AuthorizationService';

export function CompanyInfoAdmin() {
    const userPermissions = useAuthZValues({
        canManage: { Permissions: 'Manage' },
    });
    const [users, setUsers] = useState<UserListItem[]>([] as UserListItem[]);
    const [info, setInfo] = useState<CompanyInfo>({} as CompanyInfo);
    const [isLoading, setIsLoading] = useState(true);

    const form = useForm({
        initialValues: {
            CompanyId: 0,
            CompanyName: '',
            CompanyWebsite: '',
            BillingAddress: { AddressLine1: '', AddressLine2: '', City: '', StateCode: '', CountryCode: '', ZipCode: '' } as ModelsCustomersAddress,
        },

        validate: {
            CompanyName: (value: string) => (value.length > 0 ? null : 'Invalid company name'),
        },
    });

    useOnNavigating((e) => {
        if (form.isDirty()) {
            e.wait();
            confirmAbandonChanges(e);
        }
    });

    const loadCompanyUsers = () => {
        getUserGetCompanyUsers()
            .then((users) => {
                setUsers(users.filter((u) => u.Status === 'Active'));
            })
            .catch((e) => {
                //console.log(e);
            });
    };

    useEffect(() => {
        loadCompanyInfo();
    }, []);

    const loadCompanyInfo = () => {
        getNewSubscriptionGetCompanyInfo()
            .then((info) => {
                setInfo(info);
            })
            .catch((e) => {
                //console.log(e);
            });
    };

    useEffect(() => {
        setIsLoading(true);
        loadCompanyUsers();
        loadCompanyInfo();
    }, []);

    useEffect(() => {
        // set fields to values from api call and reset touched/dirty (initial values)
        resetFormValues();
        setIsLoading(false);
    }, [info]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        let companyName = form.isDirty('CompanyName') ? form.values.CompanyName : null;
        let CompanyWebsite = form.isDirty('CompanyWebsite') ? form.values.CompanyWebsite : null;
        let billingAddress = form.isDirty('BillingAddress') && form.values.BillingAddress ? form.values.BillingAddress : null;

        let companyInfo = {
            CompanyId: info.CompanyId,
            CompanyName: companyName,
            CompanyWebsite: CompanyWebsite,
            BillingAddress: billingAddress,
        } as CompanyInfo;

        await SubmitChanges(companyInfo);
    };

    const SubmitChanges = async (changes: CompanyInfo) => {
        setIsLoading(true);
        await postNewSubscriptionUpdateCompanyInfo(changes).catch((error) => {
            //console.log(`Error: ${error}`);
        });
        loadCompanyInfo();
        setIsLoading(false);
    };

    const resetFormValues = () => {
        form.setValues({
            CompanyId: info.CompanyId ?? 0,
            CompanyName: info.CompanyName ?? '',
            CompanyWebsite: info.CompanyWebsite ?? '',
            BillingAddress: info.BillingAddress
                ? ({
                      AddressLine1: info.BillingAddress.AddressLine1 ?? '',
                      AddressLine2: info.BillingAddress.AddressLine2 ?? '',
                      City: info.BillingAddress.City ?? '',
                      StateCode: info.BillingAddress.StateCode ?? '',
                      ZipCode: info.BillingAddress.ZipCode ?? '',
                      CountryCode: info.BillingAddress.CountryCode ?? '',
                  } as ModelsCustomersAddress)
                : ({ AddressLine1: '', AddressLine2: '', City: '', StateCode: '', ZipCode: '', CountryCode: '' } as ModelsCustomersAddress),
        });

        form.resetTouched();
        form.resetDirty();
    };

    const confirmAbandonChanges = (e: INavigatingEvent) => {
        openConfirmModal({
            title: (
                <span style={{ color: 'black', fontWeight: 600 }}>
                    <b>Confirm Navigation</b>
                </span>
            ),

            children: <Text>You have unsaved changes. Are you sure you want to leave this page?</Text>,
            centered: true,
            labels: { confirm: 'Leave This Page', cancel: 'Stay On Page' },
            onConfirm: () => e.continue(),
        });
    };

    return (
        <SettingsPage>
            {isLoading && <LoadingOverlay visible={true} />}
            <div>
                <form onSubmit={handleSubmit}>
                    <div
                        style={{
                            paddingBottom: '1rem',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid lightgray',
                        }}
                    >
                        <Group position="apart">
                            <Title data-atid="SettingsMainHeader" order={3} px="xl" pt="md">
                                Company Info
                            </Title>
                            {userPermissions.canManage ? (
                                <Group style={{ paddingRight: '26px', paddingTop: '11px' }}>
                                    <Button
                                        data-atid="RevertButton"
                                        disabled={!form.isDirty()}
                                        size="sm"
                                        variant="outline"
                                        onClick={() => resetFormValues()}
                                        styles={() => ({
                                            root: {
                                                marginRight: 10,
                                            },
                                        })}
                                    >
                                        Revert Changes
                                    </Button>{' '}
                                    <Button data-atid="SaveButton" disabled={!form.isDirty()} type="submit" size="sm">
                                        Save
                                    </Button>{' '}
                                </Group>
                            ) : (
                                <></>
                            )}
                        </Group>
                    </div>

                    {/* Need marginRight to elimiate horizontal scroll bar */}
                    <Grid style={{ padding: '24px', marginRight: '1px' }}>
                        <Grid.Col span={4}>
                            <Text>Company Name</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <TextInput data-atid="CompanyNameInput" {...form.getInputProps('CompanyName')} />
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Text>Company Website</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <TextInput data-atid="CompanyWebsiteInput" {...form.getInputProps('CompanyWebsite')} />
                        </Grid.Col>
                        <Grid.Col span={12} style={{ borderBottom: '1px solid lightgray', marginBottom: '1rem' }}></Grid.Col>
                        <Grid.Col span={4}>
                            <Text>Company Address</Text>
                            <Text color="dimmed" size="sm">
                                This address will be added to invoices.
                            </Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <CompanyAddress
                                prefix="BillingAddress."
                                form={form}
                                requiredFields={[
                                    CompanyAddressFields.AddressLine1,
                                    CompanyAddressFields.City,
                                    CompanyAddressFields.State,
                                    CompanyAddressFields.Zip,
                                    CompanyAddressFields.Country,
                                ]}
                                isWithinPortal={false}
                            />
                        </Grid.Col>
                    </Grid>
                </form>
            </div>
        </SettingsPage>
    );
}
endpoint('company-info', CompanyInfoAdmin, 'Company Info');

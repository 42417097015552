import { MouseEventHandler, useCallback, useEffect } from 'react';

const globalFloatedItem = (() => {
    let clone: HTMLElement | null = null;
    let currentTarget: HTMLElement | null = null;
    const destroyClone = () => {
        if (clone) {
            clone.remove();
            clone = null;
        }
    };

    document.addEventListener('scroll', destroyClone, true);

    const handleEvent = (target: HTMLElement): undefined | [HTMLElement, DOMRect] => {
        if (clone && currentTarget === target) return;
        destroyClone();
        if (target.scrollWidth <= target.offsetWidth) {
            return;
        }
        clone = target.cloneNode(true) as HTMLElement;
        const dispose = () => {
            destroyClone();
            if (throttledNextTarget === target) {
                clearTimeout(throttleTo!);
            }
            currentTarget = null;
            target.removeEventListener('mouseleave', dispose);
        };
        target.addEventListener('mouseleave', dispose);
        return [clone, target.getBoundingClientRect()];
    };

    const applyStyles = (clone: HTMLElement, rect: DOMRect, floatedStyles: Partial<CSSStyleDeclaration>, className?: string) => {
        if (className) {
            clone.classList.add(className);
        }
        Object.assign(clone.style, {
            position: 'fixed',
            top: `${rect.top}px`,
            left: `${rect.left}px`,
            maxWidth: `calc(100% - ${rect.left}px)`,
            ...floatedStyles,
            zIndex: '10000',
            pointerEvents: 'none',
        });
        document.body.append(clone);
    };

    let throttledNextTarget: HTMLElement | null = null;
    let throttleTo: number | null = null;

    const floatOnMouseEnter = (target: HTMLElement, classOrStyles?: Partial<CSSStyleDeclaration> | string) => {
        clearTimeout(throttleTo!);
        throttledNextTarget = target;
        throttleTo = setTimeout(() => {
            const [clone, rect] = handleEvent(target) || [];
            if (clone && rect) {
                const className = typeof classOrStyles === 'string' ? classOrStyles : undefined;
                const styles = typeof classOrStyles === 'object' ? classOrStyles : {};
                applyStyles(clone, rect, styles, className);
            }
        }, 100) as unknown as number;
    };
    return {
        clear: destroyClone,
        floatOnMouseEnter,
    };
})();

type MouseEnterHandler = { floatOnMouseEnter: MouseEventHandler<HTMLElement> };
export function useFloatedFullText(styles?: Partial<CSSStyleDeclaration>): MouseEnterHandler;
export function useFloatedFullText(className?: string): MouseEnterHandler;
export function useFloatedFullText(styles?: Partial<CSSStyleDeclaration> | string): MouseEnterHandler {
    const floatOnMouseEnter: MouseEventHandler<HTMLElement> = useCallback(
        (e) => globalFloatedItem.floatOnMouseEnter(e.currentTarget, styles),
        [styles]
    );
    useEffect(() => {
        return () => globalFloatedItem.clear();
    }, []);
    return { floatOnMouseEnter };
}

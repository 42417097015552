import { Account } from '@apis/Customers/model';
import { MapMigratedTagFixResult } from '@apis/TagManager/model';
import styled from '@emotion/styled';
import { Anchor, Box, Button, Card, FileInput, Group, Loader, Space, Text, TextInput, useMantineTheme } from '@mantine/core';
import { CustomColors } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { useNav } from '@root/Services/NavigationService';
import { useLink } from '@root/Services/Router/Router';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { AlertTriangle, Check, CircleCheck, X } from 'tabler-icons-react';
import { MapCostAllocationTagService } from '../Services/MapCostAllocationTagService';
import { FaqItem } from './Components';
import { StepTitle } from './Design';
import { IMapWizardStep, MapContractSetupModel } from './Model';

function StepContent() {
    const theme = useMantineTheme();
    const model = useDi(MapContractSetupModel);
    const { ascend } = useNav();
    const [error, setError] = useState<boolean>();
    const accounts = model.accountGroups.slice().filter((g) => model.contract.AccountIds?.includes(g.management.AwsAccountId ?? ''));
    const longWait = model.fixRequiresWait();
    const canProceed = model.hasValidationResults && (longWait || model.allAccountsValid);

    const year = model.contract.ContractStartDate?.getFullYear() ?? new Date().getFullYear();
    useEffect(() => {
        if (year) {
            model.contract.Name = `MAP Contract ${year}`;
        }
    }, [year]);
    const finish = async () => {
        if (model.canSave()) {
            try {
                await model.save();
                ascend();
            } catch (err) {
                setError(true);
            }
        }
    };

    return (
        <>
            <StepTitle
                title={
                    <>
                        Let's make sure your management accounts
                        <br /> are configured correctly
                    </>
                }
            />
            <Space h="md" />
            <Button
                color={canProceed ? 'success' : 'primary'}
                onClick={model.checkConfigurations}
                leftIcon={
                    model.loadingValidation ? (
                        <Loader color={theme.white as CustomColors} size="sm" />
                    ) : canProceed ? (
                        <Check color={theme.white} />
                    ) : null
                }
                data-atid="CheckConfigurations"
            >
                {model.hasValidationResults ? 'Configurations Checked' : 'Check Configurations'}
            </Button>
            {canProceed && longWait ? (
                <Text size="sm">Some fixes will run in the background over the next 24 hours. Please proceed to Step 4. </Text>
            ) : null}
            <Box sx={{ maxWidth: '600px', minWidth: '600px' }}>
                {accounts.map((g) => (
                    <AccountValidation key={g.management.Id} account={g.management} model={model} />
                ))}
            </Box>

            <Space h={45} />
            <Card withBorder radius="md" p={32} sx={{ minWidth: '600px' }}>
                <TextInput
                    onChange={(evt) => (model.contract.Name = evt.currentTarget.value)}
                    value={model.contract.Name ?? ''}
                    placeholder={`e.g. "MAP Contract ${year}"`}
                    label="Contract Name"
                    data-atid="MapContractAddNameInput"
                />
                <Space h="md" />
                <FileInput
                    value={model.contractFile}
                    label="Contract File"
                    placeholder="Select the contract associated with the MAP Contract"
                    onChange={(evt) => (model.contractFile = evt)}
                />
            </Card>
            <Space h={45} />
            <Button
                size="md"
                leftIcon={model.saving ? <Loader size="xs" color="gray.0" /> : null}
                sx={{ width: '125px' }}
                disabled={!model.canSave() || error}
                onClick={finish}
                data-atid="MapContractFinish"
            >
                Finish
            </Button>
            {error ? <Text>There was an error saving the contract. Please contact Support.</Text> : null}
        </>
    );
}

function AccountValidation({ account, model }: { account: Account; model: MapContractSetupModel }) {
    const theme = useMantineTheme();
    const { getDescendUrl } = useNav();
    const link = useLink();
    const costAllocTagSvc = useDi(MapCostAllocationTagService);
    const status = model.getValidationStatus(account);
    const exists = !status ? 'neutral' : status.TagExists ? 'pass' : 'fail';
    const active = !status ? 'neutral' : status.TagActivated ? 'pass' : 'fail';
    const headerStatus = !status ? 'neutral' : exists === 'pass' && active === 'pass' ? 'pass' : 'fail';
    const [fixResult, setFixResult] = useState<MapMigratedTagFixResult>();
    const bg = headerStatus === 'neutral' ? theme.colors.gray[3] : headerStatus === 'pass' ? theme.colors.success[5] : theme.colors.warning[5];
    const fg = headerStatus === 'neutral' ? theme.colors.gray[6] : headerStatus === 'pass' ? theme.colors.success[0] : theme.colors.error[0];
    const fix = async () => {
        model.fixCostAllocationTags(account).then(setFixResult);
    };
    const connIssue = (status && !status.Permitted) || (fixResult && costAllocTagSvc.interpretFixResults(fixResult) === 'connection-error');

    return (
        <Card radius="md" p={0} my="lg">
            <Group px="lg" py="sm" position="apart" noWrap sx={{ background: bg, color: fg }}>
                <Text>{account.AwsAccountId}</Text>
                <Text sx={{ flex: 1 }} weight="bolder">
                    {account.Name ?? 'Unnamed Account'}
                </Text>
                <Box>
                    {headerStatus === 'neutral' ? null : headerStatus === 'pass' ? (
                        <Check color={theme.white} />
                    ) : status?.Permitted ? (
                        <Button size="sm" variant="white" color="warning" onClick={fix}>
                            Fix It
                        </Button>
                    ) : null}
                </Box>
            </Group>
            <Box px="lg" py="sm">
                <ValidationCheck status={exists} text={'"map-migrated" tag exists on at least one resource'} />
                <ValidationCheck status={active} text={'"map-migrated" is an active cost allocation tag'} />
            </Box>

            {connIssue && (
                <Group noWrap px="lg" py="sm" sx={{ background: theme.colors.gray[3] }}>
                    <AlertTriangle color={theme.colors.warning[6]} />
                    <Box>
                        <Text color="error.6">Permission error</Text>
                        Please ensure that the latest IAM policies are deployed.
                    </Box>
                    <Anchor {...link(getDescendUrl('connection-health'))}>Check Permissions</Anchor>
                </Group>
            )}
        </Card>
    );
}

function ValidationCheck({ text, status, messages }: { text: string; status: 'neutral' | 'fail' | 'pass'; messages?: string }) {
    const theme = useMantineTheme();
    const icon =
        status === 'fail' ? (
            <AlertTriangle color={theme.colors.error[6]} />
        ) : status === 'pass' ? (
            <CircleCheck color={theme.colors.success[6]} />
        ) : (
            <CircleCheck color={theme.colors.gray[3]} />
        );
    return (
        <Box>
            <Group m="lg" noWrap align="flex-start">
                {icon}
                <Box>
                    <Text>{text}</Text>
                    <Space h="xs" />
                    {messages?.split('\n').map((m, i) => (
                        <Text key={i} size="sm" color="dimmed">
                            {m}
                        </Text>
                    ))}
                </Box>
            </Group>
        </Box>
    );
}

function StepHelp() {
    return (
        <>
            <FaqItem question="What are Cost Allocation Tags?">
                AWS uses the Cost and Usage Report (CUR) to administer your MAP contract. Cost allocation tags are tags that are automatically added
                directly to the CUR and are an integral part of MAP program management. As such, cost allocation tags are required to be enabled.
            </FaqItem>
            <FaqItem question={'What are "map-migrated" tags?'}>
                The "map-migrated" tag is the specific tag key used by MAP contracts to identify the resources that are eligible for credits under the
                MAP program. The syntax for the tag key is case sensitive and must be added to each resource exactly as stated.
            </FaqItem>
            <FaqItem question="What do I do if my management account is not properly configured?">
                If your management account is not properly configured then you will be given the opportunity to update the configuration either
                manually or automatically through our application.
            </FaqItem>
            <FaqItem question="Why does this need a name?">
                Assigning a user friendly name to this MAP contract will help you easily distinguish it from other MAP contracts.
                <br />
                Example:
                <br />
                MAP Contract - Production Environment
                <br />
                MAP Contract - ACME Business Unit
                <br />
                MAP Contract - Company Name
            </FaqItem>
            <FaqItem question="Do I need to Upload my MAP Contract?">
                No, but you have the option to upload a copy of your AWS issued MAP contract. These contracts are seldom accessed after signing so
                this provides a convenient place to maintain a copy of your agreement for future reference.
            </FaqItem>
        </>
    );
}

export const Step3Validation = {
    Content: observer(StepContent),
    Help: StepHelp,
    canProceed: () => false,
} as IMapWizardStep;

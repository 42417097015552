import { CompanyStat } from '@apis/Customers/model';
import { useNav } from '@root/Services/NavigationService';
import { useDi } from '@root/Services/DI';
import { AppFeatureNames, AppTypes } from '@root/Services/Customers/CompanyFeatureService';
import { CompanyFeatureService } from '@root/Services/Customers/CompanyFeatureService';
import { Route } from '@root/Services/Router/RouteSerializer';

const getRedirectsPrioritized = (companyId: number | undefined): { route: Route; app?: AppTypes; feature?: string }[] => {
    const id = companyId?.toString() ?? '';
    return [
        {
            app: 'Compliance',
            feature: AppFeatureNames.TagManager,
            route: [
                { name: 'manage-company', data: { id } },
                { name: 'compliance', data: {} },
                { name: 'tag-explorer', data: {} },
            ],
        },
        {
            app: 'Compliance',
            feature: AppFeatureNames.MAPManager,
            route: [
                { name: 'manage-company', data: { id } },
                { name: 'compliance', data: {} },
                { name: 'map-contracts', data: {} },
            ],
        },
        {
            app: 'Optimization',
            feature: AppFeatureNames.IdleResources,
            route: [
                { name: 'manage-company', data: { id } },
                { name: 'optimization', data: {} },
                { name: 'idle-ec2', data: {} },
            ],
        },
        {
            app: 'Optimization',
            feature: AppFeatureNames.Rightsizing,
            route: [
                { name: 'manage-company', data: { id } },
                { name: 'optimization', data: {} },
                { name: 'rightsizing-ec2', data: {} },
            ],
        },
        {
            app: 'FinOps',
            feature: AppFeatureNames.InvoiceManager,
            route: [
                { name: 'manage-company', data: { id } },
                { name: 'finops', data: {} },
                { name: 'invoice-explorer', data: {} },
            ],
        },
        {
            route: [
                { name: 'manage-company', data: { id } },
                { name: 'settings', data: {} },
                { name: 'subscription', data: {} },
            ],
        },
    ];
};

export function useGetDefaultPageByCompany() {
    const { getRootUrl } = useNav();
    const companyFeatureSvc = useDi(CompanyFeatureService);
    return async (item: CompanyStat) => {
        const companyFeatures = await companyFeatureSvc.getFeatures(item);

        //user has an active subscription and app access toggled to true
        const { route } = getRedirectsPrioritized(item.Id ?? 0).find((r) => !r.app || companyFeatures.checkFeature(r.app, r.feature))!;
        let url = getRootUrl(route);
        return url;
    };
}

import { getAccountGetAccounts } from '@apis/Customers';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { IMonthlyRollup } from '@root/Services/Invoices/InvoiceSchemaService';
import { queryBuilder } from '@root/Services/QueryExpr';
import { useState, useEffect } from 'react';

export interface RangePickerItem {
    month: Date;
    accountId: string;
    accountName: string;
}

export function useRangePickerData(invoiceApi: InvoiceApiService) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<RangePickerItem[]>();
    const fmtSvc = useDi(FormatService);

    useEffect(() => {
        setLoading(true);
        (async () => {
            const builder = queryBuilder<IMonthlyRollup>().select((b) => ({
                month: b.model.UsageMonth,
                accountId: b.model['bill/PayerAccountId'],
                count: b.count(),
            }));

            const [accounts, accountMonths] = await Promise.all([
                getAccountGetAccounts(),
                builder.execute((q) => invoiceApi.queryMonthlyRollup(q, [])),
            ]);
            const accountLookup = accounts.reduce((result, acc) => result.set(acc.AwsAccountId ?? '', acc.Name ?? ''), new Map<string, string>());
            const items = (accountMonths.Results ?? []).map((r) => ({
                ...r,
                month: fmtSvc.parseDateNoTime(r.month),
                accountName: accountLookup.get(r.accountId) ?? '',
            }));
            setData(items.filter((r) => r.month.getTime() <= new Date().getTime()));
        })().finally(() => setLoading(false));
    }, []);

    return { loading, data };
}

export function getUniqueAccounts(ranges: RangePickerItem[]) {
    const result: RangePickerItem[] = [];
    for (const item of ranges.reduce((result, item) => result.set(item.accountId, item), new Map<string, RangePickerItem>()).values()) {
        result.push(item);
    }
    result.sort((a, b) => a.accountName.localeCompare(b.accountName, undefined, { sensitivity: 'base' }));
    return result;
}

export function getUniqueMonths(ranges: RangePickerItem[], fmtSvc: FormatService) {
    const monthsAdded = new Set<string>();
    const result: { label: string; value: string; date: Date }[] = [];
    const months = ranges.map((r) => r.month).sort((a, b) => a.getTime() - b.getTime());
    for (const month of months) {
        const label = fmtSvc.formatLongMonthYear(month);
        if (!monthsAdded.has(label)) {
            monthsAdded.add(label);
            result.push({ label, value: fmtSvc.formatYearMonth(month), date: month });
        }
    }
    return result;
}

export function formatMonths(ranges: Date[], fmtSvc: FormatService) {
    const monthsAdded = new Set<string>();
    const result: { label: string; value: string; date: Date }[] = [];
    const months = ranges.map((r) => r).sort((a, b) => a.getTime() - b.getTime());
    for (const month of months) {
        const label = fmtSvc.formatLongMonthYear(month);
        if (!monthsAdded.has(label)) {
            monthsAdded.add(label);
            result.push({ label, value: fmtSvc.formatYearMonth(month), date: month });
        }
    }
    return result;
}

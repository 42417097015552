import { postDailyRollupQuery } from '@apis/Invoices';
import { queryBuilder, ValuesGroupOtherText, SchemaService } from '@root/Services/QueryExpr';
import { makeAutoObservable } from 'mobx';
import { inject, injectable, Lifecycle, scoped } from 'tsyringe';
import { SchemaField } from '@apis/Jobs/model';
import { BaseCurModel } from '@apis/Invoices/model';
import { InvoiceSchemaService } from '../Invoices/InvoiceSchemaService';
import { FormatService } from '../FormatService';
import { add } from 'date-fns';
export type CurDataItem = {
    type: string;
    totalSpend: any;
    taggedSpend: number;
    unsupportedSpend: number;
    untaggedSpend: number;
};

@scoped(Lifecycle.ContainerScoped)
export class SpendingCoverageService {
    public spendingResults: CurDataItem[] = [];
    public loading: boolean = false;
    public months: string = '1';
    public viewByField: string = '';
    public filterByField: string = '';
    public schema: SchemaService | null = null;
    public filterSchema: SchemaService | null = null;

    public constructor(
        @inject(InvoiceSchemaService) private readonly invoiceSchemaProvider: InvoiceSchemaService,
        @inject(FormatService) private readonly formatSvc: FormatService
    ) {
        makeAutoObservable(this);
    }

    public spendTimeFrameOptions = [
        { value: '1', label: 'Last 30 Days' },
        { value: '2', label: 'Last 60 Days' },
        { value: '3', label: 'Last 90 Days' },
        { value: '12', label: 'Last Year' },
    ];

    public async refreshSchema() {
        const types = await this.invoiceSchemaProvider.getSchema();
        let filterTypes = types.filter((t) => t.TypeId === 'resourceTags');
        const defaultFilter: SchemaField = {
            Field: 'originalFilePath',
            HasMany: false,
            IsPrimitive: true,
            Name: 'Any',
            TypeName: 'resourceTags',
        };
        if (filterTypes.length > 0) filterTypes[0].Fields?.push(defaultFilter);
        this.filterByField = defaultFilter.Field!;
        this.filterSchema = new SchemaService(filterTypes);
        this.schema = new SchemaService(types.filter((t) => t.TypeId != 'resourceTags'));

        this.refreshSpendCoverage();
    }

    public setDateRange(value: string) {
        this.months = value;
    }

    public setViewByField(viewBy: string) {
        this.viewByField = viewBy;
    }

    public setFilterByField(filterBy: string) {
        this.filterByField = filterBy;
    }

    public async refreshSpendCoverage() {
        if (!this.schema || !this.filterSchema) return;

        this.loading = true;
        const months = -parseInt(this.months);
        var results = await queryBuilder<BaseCurModel & { ['lineItem/UnblendedCost']: number }>()
            .where((r) => r.and(r.model[this.filterByField]!.isNotNull()))
            .select((b) => ({
                type: {
                    Operation: 'values',
                    Operands: [{ Field: this.viewByField }, { Value: '' }, { Value: ValuesGroupOtherText }],
                } as unknown as string,
                totalSpend: b.sum(b.model['lineItem/UnblendedCost'] as unknown as number),
                taggedSpend: b.sum(b.iif(b.model.ResourceTags!.eq(true), b.model['lineItem/UnblendedCost'], b.param(0)) as unknown as number),
                untaggedSpend: b.sum(b.iif(b.model.ResourceTags!.eq(false), b.model['lineItem/UnblendedCost'], b.param(0)) as unknown as number),
                unsupportedSpend: b.sum(b.iif(b.model.resourceType!.isNull(), b.model['lineItem/UnblendedCost'], b.param(0)) as unknown as number),
            }))
            .execute((q) =>
                postDailyRollupQuery(q, {
                    from: this.formatSvc.toJsonShortDate(add(new Date(), { months })),
                    to: this.formatSvc.toJsonShortDate(new Date()),
                })
            );
        this.spendingResults = results.Results ?? [];
        this.loading = false;
    }

    public get aggregatedSpendData() {
        return {
            totalSpend: this.spendingResults.reduce((accum, curr) => accum + curr.totalSpend, 0),
            taggedSpend: this.spendingResults.reduce((accum, curr) => accum + curr.taggedSpend, 0),
            unsupportedSpend: this.spendingResults.reduce((accum, curr) => accum + curr.unsupportedSpend, 0),
            untaggedSpend: this.spendingResults.reduce((accum, curr) => accum + curr.untaggedSpend, 0),
        };
    }
}

import {
    getAccountGetAccounts,
    getCompanyGetCurrentCompany,
    getStagedCompanyGetById,
    getStagedCompanyGetConnectionStatus,
    postStagedCompanyConvert,
} from '@apis/Customers';
import { Account, StagedCompanyConnectionStatus } from '@apis/Customers/model';
import type { Company } from '@apis/Customers/model';
import styled from '@emotion/styled';
import {
    Box,
    Button,
    Card,
    Center,
    Checkbox,
    Divider,
    Group,
    Loader,
    Modal,
    NavLink,
    Progress,
    Space,
    Table,
    Text,
    ThemeIcon,
    Title,
    useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { SupportChatButton } from '@root/Components/Shell/SupportChat';
import { AnchorButton } from '@root/Design/Primitives';
import { CustomColors } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { JobService } from '@root/Services/Jobs/JobService';
import { Logger } from '@root/Services/Logger';
import { useNav } from '@root/Services/NavigationService';
import { BasicRouteLoader } from '@root/Services/Router/BasicRouteLoader';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ExternalLink, VectorBezier } from 'tabler-icons-react';
import { CompanyTenantPrereqService } from '@root/Components/Router/CompanyContent';
import { PollingPromise, PollingService } from '@root/Services/Jobs/PollingService';
import { MspService } from '@root/Services/MspService';

type ConnectionStates = 'loading' | 'ready' | 'failed' | 'no-accounts' | 'connecting' | 'connected';

export function StagedCompanyLandingPage() {
    const theme = useMantineTheme();
    useDi(BasicRouteLoader);
    const jobSvc = useDi(JobService);
    const logger = useDi(Logger);
    const tenantSvc = useDi(CompanyTenantPrereqService);
    const { source, id: rawId } = useNav().getData('source', 'id');
    const stagedCompanyId = rawId ? parseInt(rawId) : undefined;
    const [connectionState, setConnectionState] = useState<ConnectionStates>('loading');
    const [agreed, setAgreed] = useState<boolean>();
    const [company, setCompany] = useState<Company>();
    const agree = useCallback(() => setAgreed(true), []);
    const [progress, setProgress] = useState<number>();
    const connect = useCallback(() => {
        if (connectionState === 'ready') {
            setConnectionState('connecting');
            (async () => {
                try {
                    const company = await postStagedCompanyConvert({ source, stagedCompanyId });
                    tenantSvc.setCompanyId(company.Id);
                    const { poller } = await jobSvc.waitForJobHierarchyByJobId(company.ProvisionJobId!);
                    await poller;
                    setCompany(await getCompanyGetCurrentCompany());
                } catch (err) {
                    logger.error(`Failed while trying to convert staged company ${stagedCompanyId}`, err);
                    setConnectionState('failed');
                }
            })();
        }
    }, [connectionState]);
    useEffect(() => {
        (async () => {
            const company = await getStagedCompanyGetById({ source, stagedCompanyId });
            if (company?.CompanyId) {
                tenantSvc.setCompanyId(company.CompanyId);
                setConnectionState('connecting');
                setCompany(await getCompanyGetCurrentCompany());
                setAgreed(true);
            } else {
                setAgreed(false);
            }
        })();
    }, []);
    useEffect(() => {
        //console.log(connectionState);
    }, [connectionState]);

    return (
        <>
            {agreed === false ? <StagedCompanyTerms onAgree={agree} /> : null}
            <Box sx={{ minHeight: '100%', background: theme.colors.gray[2] }}>
                <Box p="lg" sx={{ background: theme.white }}>
                    <Text weight="bold" align="center" color="primary">
                        Connection Wizard
                    </Text>
                </Box>
                <Divider color={theme.colors.gray[3] as CustomColors} />
                <Space h={40} />
                <Title order={2} align="center">
                    Connect Your Cloud Environment to Tag Manager
                </Title>
                <Space h={40} />
                <Center>
                    <Box sx={{ maxWidth: theme.breakpoints.lg, width: 'calc(100% - 40px)' }}>
                        <Card radius="lg" shadow="lg" p={64}>
                            <Group position="apart">
                                <Group sx={{ alignItems: 'flex-start' }}>
                                    <ThemeIcon color="primary" size="xl" radius="xl" variant="light">
                                        <VectorBezier />
                                    </ThemeIcon>
                                    <Box>
                                        <Title>Connect your Cloud Account</Title>
                                        <Text color="dimmed">
                                            {connectionState === 'connected'
                                                ? 'Connection Complete'
                                                : 'Estimated time to complete: 10 minutes per account'}
                                        </Text>
                                        {progress !== undefined && connectionState !== 'connected' ? (
                                            <Group>
                                                <Box>
                                                    <Progress sx={{ width: 400 }} value={progress} />
                                                </Box>
                                                <Text>{progress}%</Text>
                                            </Group>
                                        ) : null}
                                    </Box>
                                </Group>
                                <Box sx={{ textAlign: 'right' }}>
                                    {connectionState === 'failed' || connectionState === 'no-accounts' ? (
                                        <SupportChatButton text="Get Connection Assistance" />
                                    ) : connectionState === 'connecting' ? (
                                        <>
                                            <Text>Connection in Progress...</Text>
                                            <Text size="sm" color="dimmed">
                                                You may wait here or check back later
                                            </Text>
                                        </>
                                    ) : connectionState === 'connected' && !!company ? (
                                        <Button component="a" href={`/${company.DatabaseName}`} radius="md" size="lg" rightIcon={<ExternalLink />}>
                                            Enter Tag Manager
                                        </Button>
                                    ) : company ? (
                                        <NotifyMe />
                                    ) : (
                                        <Button
                                            onClick={connect}
                                            disabled={connectionState !== 'ready'}
                                            radius="md"
                                            size="lg"
                                            leftIcon={connectionState === 'loading' ? <Loader size="sm" color={'white' as CustomColors} /> : null}
                                        >
                                            Connect My Accounts
                                        </Button>
                                    )}
                                    <Text mt="xs" size={connectionState === 'failed' ? 'md' : 'sm'} color="dimmed">
                                        {connectionState === 'loading'
                                            ? 'Checking for accounts...'
                                            : connectionState === 'failed'
                                            ? 'Please contact support to complete connection '
                                            : ''}
                                    </Text>
                                </Box>
                            </Group>
                        </Card>
                        <Space h={40} />
                        {agreed ? (
                            <Card radius="lg" p={0} withBorder>
                                <Box p="lg">
                                    <img src="/assets/Amazon_Web_Services_Logo.svg" style={{ width: 40, marginRight: 15 }} /> AWS Accounts
                                </Box>
                                <Divider />
                                <ConnectionStatus
                                    onLoaded={setConnectionState}
                                    onProgress={setProgress}
                                    source={source}
                                    stagedCompanyId={stagedCompanyId}
                                    poll={connectionState === 'connecting' && !!company}
                                />
                            </Card>
                        ) : null}
                    </Box>
                </Center>
            </Box>
        </>
    );
}

export function StagedCompanyTerms({ onAgree }: { onAgree: () => void }) {
    const [opened, { close }] = useDisclosure(true);
    const [agreed, setAgreed] = useState(false);
    const mspSvc = useDi(MspService);

    const back = useCallback(() => {
        history.back();
    }, []);
    const agree = useCallback(() => {
        if (agreed) {
            onAgree();
            close();
        }
    }, [close, agreed]);

    return (
        <Modal opened={opened} size={860} radius="lg" onClose={() => {}} withCloseButton={false} closeOnClickOutside={false} overlayBlur={5}>
            <Box px={80} pb={20} py={40}>
                <Title align="center" order={2}>
                    Welcome to {mspSvc.mspSupportAndLogos.CompanyName}
                </Title>
                {mspSvc.mspSupportAndLogos.CompanyName !== 'CloudSaver' ? (
                    <Title align="center" order={5}>
                        Powered by CloudSaver
                    </Title>
                ) : (
                    ''
                )}
                <Space h="xs" />
                <Title align="center" order={3}>
                    Please Accept the Terms of Service to Continue
                </Title>
                <Space h="xl" />
                <Text color="dimmed">
                    Before you can access our software, you must agree to our standard terms and conditions outlined in the following agreements, as
                    modified from time to time by CloudSaver, which together form the "CloudSaver Agreements"
                </Text>
                <Space h="md" />
                <Text>
                    <AnchorButton
                        href="https://www.cloudsaver.com/legal/master-services-agreement/"
                        external
                        icon={<ExternalLink size={20} />}
                        iconPosition="right"
                        text="Master Subscription Agreement"
                    />
                </Text>
                <Space h="md" />
                <Text>
                    <AnchorButton
                        href="https://www.cloudsaver.com/legal/mutual-non-disclosure-agreement/"
                        external
                        icon={<ExternalLink size={20} />}
                        iconPosition="right"
                        text="Mutual Non-Disclosure Agreement"
                    />
                </Text>
                <Space h="md" />
                <Text color="dimmed">
                    By checking "I Agree" and using our software you represent and warrant that you have read and understand the CloudSaver
                    Agreements. You also confirm that you have the authority to enter into the CloudSaver agreements on behalf of your company and
                    agree to be legally bound by the terms and conditions set out in these agreements. If you do not agree to these terms of service,
                    you may not use our software.
                </Text>
                <Space h="xl" />
                <Text>
                    <Checkbox
                        label={<Text weight="bold">I Agree to the Terms of Service</Text>}
                        checked={agreed}
                        onChange={(e) => setAgreed(e.target.checked)}
                    />
                </Text>
                <Space h="xl" />
                <Box sx={{ display: 'flex', justifyContent: 'stretch' }}>
                    <Button fullWidth variant="outline" onClick={back}>
                        Disagree
                    </Button>
                    <Space w="md" />
                    <Button disabled={!agreed} fullWidth color="primary" onClick={agree}>
                        Agree
                    </Button>
                </Box>
                {/* <Space h="lg" />
                <Text align="center" size="sm">
                    <AnchorButton href="#" icon={<ExternalLink size={16} />} iconPosition="right" text="View Fee Schedule" />
                </Text> */}
            </Box>
        </Modal>
    );
}

function NotifyMe() {
    return <></>;
}

function ConnectionStatus({
    onLoaded,
    source,
    stagedCompanyId,
    poll,
    onProgress,
}: {
    onLoaded: (state: ConnectionStates) => void;
    onProgress: (progress: number) => void;
    source?: string;
    stagedCompanyId?: number;
    poll: boolean;
}) {
    const [accounts, setAccounts] = useState<StagedCompanyConnectionStatus[]>();
    const [failed, setFailed] = useState(false);
    const logger = useDi(Logger);
    const jobSvc = useDi(JobService);
    const pollingSvc = useDi(PollingService);
    const [progressLookup, setProgressLookup] = useState(new Map<string, number>());
    useEffect(() => {
        if (source && stagedCompanyId) {
            (async () => {
                try {
                    const accounts = await getStagedCompanyGetConnectionStatus({ source, stagedCompanyId });
                    setAccounts(accounts);
                    if (!poll) {
                        onLoaded(accounts.length && accounts.some((c) => c.Ready) ? 'ready' : 'no-accounts');
                    }
                } catch (err) {
                    logger.error(`Failed while trying to load connection status for ${source}, staged company id ${stagedCompanyId}`, err);
                    onLoaded('failed');
                    setFailed(true);
                }
            })();
        }
    }, [source, stagedCompanyId]);

    useEffect(() => {
        if (poll) {
            (async () => {
                const accounts = await getAccountGetAccounts();
                const accountLookup = accounts.reduce((result, item) => result.set(item.Id ?? 0, item.AwsAccountId ?? ''), new Map<number, string>());
                await pollingSvc.pollUntil(
                    async () => {
                        try {
                            const jobs = await jobSvc.queryJobs((builder) =>
                                builder
                                    .where((b) =>
                                        b.and(
                                            b.model.Type!.eq(['Cloudsaver.Resources.Domain.Aws.Models.InitialCheckAwsResources']),
                                            b.model.ParentId!.isNull()
                                        )
                                    )
                                    .sortAsc((b) => b.model.QueuedAt)
                                    .take(Math.min(Math.max(accounts?.length ?? 0, 1) ?? 100))
                            );
                            return {
                                jobs,
                                progressLookup: await jobSvc.getHierarchyProgressLookup(
                                    jobs.filter((j) => !!j.HierarchyId).map((j) => j.HierarchyId!)
                                ),
                            };
                        } catch {
                            return {};
                        }
                    },
                    ({ jobs, progressLookup }) => {
                        try {
                            if (!jobs || !progressLookup) {
                                return false;
                            }

                            let total = 0;
                            let finished = 0;
                            const progressByAccount = jobs.reduce((result, job) => {
                                const account = (job.Parameters as { AccountId?: number }).AccountId ?? -1;
                                const progress = progressLookup.get(job.HierarchyId ?? '');
                                const status = progress ? jobSvc.getStatusInfo(progress) : null;
                                const percent = status ? Math.round(status.progress * 100) : 0;
                                const awsAccountId = account ? accountLookup.get(account ?? -1) : null;
                                total += status?.total ?? 0;
                                finished += (status?.progress ?? 0) * (status?.total ?? 0);

                                return result.set(awsAccountId ?? 'none', percent);
                            }, new Map<string, number>());
                            setProgressLookup(progressByAccount);
                            if (finished > 0) {
                                onProgress(Math.round((finished / total) * 100));
                            }
                            const done =
                                progressLookup.size > 0 &&
                                [...progressLookup.values()].every((v) => !v.Created && !v.Started && (v.Failed > 0 || v.Succeeded > 0));
                            if (done) {
                                onLoaded('connected');
                            }
                            return done;
                        } catch {
                            return false;
                        }
                    },
                    5000
                );
            })();
        }
    }, [poll, stagedCompanyId, source]);

    const theme = useMantineTheme();

    return (
        <Box sx={{ maxHeight: 400, display: 'flex', flexDirection: 'column' }}>
            {accounts?.length ? (
                <>
                    <Box>
                        <Table horizontalSpacing="xl" sx={{ background: theme.colors.gray[1] }}>
                            <thead>
                                <tr>
                                    <th>
                                        <Cell w={225}>Account #</Cell>
                                    </th>
                                    <th>
                                        <Cell w={225}>Name</Cell>
                                    </th>
                                    <th>
                                        <Cell w={225}>Type</Cell>
                                    </th>
                                    <th style={{ width: '100%' }}>
                                        <Cell w="100%">Status</Cell>
                                    </th>
                                </tr>
                            </thead>
                        </Table>
                    </Box>
                    <Box sx={{ flex: 1, overflow: 'auto' }}>
                        <Table horizontalSpacing="xl">
                            <tbody>
                                {accounts.map((c, i) => (
                                    <Row key={i}>
                                        <td>
                                            <Cell w={225}>{c.AccountId}</Cell>
                                        </td>
                                        <td>
                                            <Cell w={225}>{c.Name}</Cell>
                                        </td>
                                        <td>
                                            <Cell w={225}>{c.Type} Account</Cell>
                                        </td>
                                        <td style={{ width: '100%' }}>
                                            <Cell w="100%">
                                                <Box sx={{ flex: 1 }}>
                                                    {progressLookup.get(c.AccountId ?? '') === 100
                                                        ? 'Connection Complete'
                                                        : progressLookup.has(c.AccountId ?? '')
                                                        ? 'Synching resource data...'
                                                        : c.Ready
                                                        ? 'Ready to connect'
                                                        : 'Cannot connect'}
                                                    {c.Ready && progressLookup.get(c.AccountId ?? '') !== 100 ? (
                                                        <Progress mt="xs" value={progressLookup.get(c.AccountId ?? '') ?? 0} />
                                                    ) : null}
                                                </Box>
                                            </Cell>
                                        </td>
                                    </Row>
                                ))}
                            </tbody>
                        </Table>
                    </Box>
                </>
            ) : (
                <Text color="dimmed" align="center" p="lg" italic>
                    {failed ? 'No Accounts Founds' : !accounts ? 'Loading Connections' : 'No Accounts Found'}
                </Text>
            )}
        </Box>
    );
}

const Row = styled.tr`
    td > div {
        height: 50px;
        display: flex;
        align-items: center;
    }
`;

const Cell = styled.div<{ w: string | number }>`
    width: ${(p) => (typeof p.w === 'string' ? p.w : p.w + 'px')};
`;

import styled from '@emotion/styled';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PageContent, PagePanel, PanelBody, PaneledPage } from '@root/Design/Layout';
import { Button, Divider, Drawer, Group, Loader, Menu, Space, Text, TextInput } from '@mantine/core';
import { OpenSidebar } from './Components/Enums';
import CustomerInvitation from './Components/CustomerInvitation';
import CustomerGrid from './Components/CustomerGrid';
import { observer } from 'mobx-react';
import CompanyRequestService from '@root/Services/CloudIntelligence/CompanyRequestService';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { useInputState } from '@mantine/hooks';
import { postCloudIntelligenceInviteAccountRep, postCloudIntelligenceReviewCompanyCandidate } from '@apis/Customers';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { useDi, useDiComponent } from '@root/Services/DI';
import { useModals } from '@mantine/modals';
import { CustomColors, theme } from '@root/Design/Themes';
import { useNav } from '@root/Services/NavigationService';
import { CompanyDetailPanel } from './CompanyDetail/CompanyDetailPanel';
import { FillerSwitch } from '@root/Design/Filler';
import { DataMarketplaceApiService, RelationshipBundleService } from '@root/Services/DataMarketplace/DataMarketplaceApiService';

const CustomerRequest = observer(function CustomerRequest({ companyId }: { companyId?: number }) {
    const [openDrawer, setDrawer] = useState<OpenSidebar>(OpenSidebar.undefined);
    const companySelected = !!companyId;
    const modals = useModals();
    const DiContainer = useDiComponent();
    const { goto, getDataUrl } = useNav();

    const changeInvitationSetting = (sidebar: OpenSidebar) => {
        setDrawer(sidebar);
    };

    function setPanelButtonClick(openSidebar: OpenSidebar, onClear: boolean) {
        changeInvitationSetting(openSidebar);
    }

    useEffect(() => {
        if (companySelected) {
            setDrawer(OpenSidebar.undefined);
        }
    }, [companySelected]);

    const inviteAccountRep = useCallback(() => {
        const id = modals.openModal({
            title: 'Invite Account Rep',
            children: (
                <DiContainer>
                    <AccountRepInviteModal onClose={() => modals.closeModal(id)} />
                </DiContainer>
            ),
        });
    }, []);

    const { canApprove } = useAuthZValues({
        canApprove: { PartnerCompany: 'Approve' },
    });

    return (
        <>
            <PageContent>
                <PaneledPage>
                    <PagePanel size={companySelected ? 300 : 'fill'}>
                        <PanelBody>
                            <CustomerGrid
                                mode={companySelected ? 'picker' : 'full'}
                                renderToolbar={() =>
                                    companySelected ? null : canApprove ? (
                                        <Menu shadow="md" width={200}>
                                            <Menu.Target>
                                                <Button rightIcon={<i className="ti ti-chevron-down"></i>}>Invite</Button>
                                            </Menu.Target>
                                            <Menu.Dropdown>
                                                <Menu.Item
                                                    icon={<i className="ti ti-building-skyscraper"></i>}
                                                    onClick={() => changeInvitationSetting(OpenSidebar.addCustomer)}
                                                >
                                                    Company
                                                </Menu.Item>
                                                <Menu.Item icon={<i className="ti ti-user"></i>} onClick={inviteAccountRep}>
                                                    Account Rep
                                                </Menu.Item>
                                            </Menu.Dropdown>
                                        </Menu>
                                    ) : (
                                        <Button
                                            leftIcon={<i className="ti ti-plus"></i>}
                                            onClick={() => changeInvitationSetting(OpenSidebar.addCustomer)}
                                        >
                                            Invite Company
                                        </Button>
                                    )
                                }
                            />
                        </PanelBody>
                    </PagePanel>
                    {companySelected ? (
                        <>
                            <Divider orientation="vertical" />
                            <PagePanel size="fill">
                                <CompanyDetailPanel companyId={companyId} onClose={() => goto(getDataUrl({}))} />
                            </PagePanel>
                        </>
                    ) : null}
                    <Drawer
                        opened={openDrawer != OpenSidebar.undefined}
                        onClose={() => setDrawer(OpenSidebar.undefined)}
                        title={DrawerHeader()}
                        padding={0}
                        size="xl"
                        position="right"
                    >
                        {openDrawer == OpenSidebar.addCustomer ? <CustomerInvitation onButtonClick={setPanelButtonClick}></CustomerInvitation> : null}
                    </Drawer>
                </PaneledPage>
            </PageContent>
        </>
    );
});

export function CustomerRequests() {
    const nav = useNav();
    const { companyId: rawCompanyId } = nav.getData('companyId');
    const companyId = useMemo(() => (rawCompanyId ? parseInt(rawCompanyId) : 0), [rawCompanyId]);
    const mktApiSvc = useDi(DataMarketplaceApiService);
    const [loading, setLoading] = useState(true);
    const [redirecting, setRedirecting] = useState(false);

    useEffect(() => {
        setLoading(true);
        mktApiSvc
            .getBundleService()
            .then((relSvc) => {
                const defaultDef = relSvc.getDefaultDef();
                if (defaultDef) {
                    setRedirecting(true);
                    nav.move('relationship-data', { id: defaultDef?.Id?.toString() ?? '' });
                }
            })
            .finally(() => setLoading(false));
    }, []);

    return <FillerSwitch loading={loading || redirecting}>{() => <CustomerRequest companyId={companyId} />}</FillerSwitch>;
}

function DrawerHeader() {
    return (
        <InviteCompanyHeader>
            <Text size="xl">Invite Company</Text>
        </InviteCompanyHeader>
    );
}

function AccountRepInviteModal({ onClose }: { onClose: () => void }) {
    const notificationSvc = useDi(NotificationService);
    const [emailToInvite, setEmail] = useInputState('');
    const [sending, setSending] = useState(false);

    async function sendInvitation() {
        setSending(true);
        try {
            await postCloudIntelligenceInviteAccountRep({ email: emailToInvite });
            onClose();
            notificationSvc.notify(
                'Invite Sent',
                `${emailToInvite} should receive an invitation shortly. `,
                'success',
                <i className="ti ti-mail-forward" />
            );
        } catch {
            notificationSvc.notify(
                'Invite Failed!',
                `Failed to send invite, please contact support.  `,
                'error',
                <i className="ti ti-mail-forward" />
            );
        } finally {
            setSending(false);
        }
    }

    return (
        <>
            <TextInput size="md" type="email" label="Email" placeholder="account.rep@mycompany.com" value={emailToInvite} onChange={setEmail} />
            <Space h="md" />
            <Group position="right">
                <Button leftIcon={sending ? <Loader size="xs" /> : <i className="ti ti-mail-forward" />} disabled={sending} onClick={sendInvitation}>
                    Invite
                </Button>
                <Button variant="subtle" onClick={onClose}>
                    Cancel
                </Button>
            </Group>
        </>
    );
}

export const CustomerHeader = observer(function CustomerHeader() {
    const companyRequestSvc = useContext(CompanyRequestService);

    return (
        <>
            Companies
            <Text size="xs" span={true}>
                {' '}
                - {companyRequestSvc.info.total} records
            </Text>
        </>
    );
});

export const InviteCompanyHeader = styled.div`
    color: ${theme.colors?.primary?.[6] as CustomColors};
    padding: 12px 24px;
`;

endpoint('customer-requests', CustomerRequests, CustomerHeader);

import styled from '@emotion/styled';
import { Button, CloseButton, createStyles, Group, Space, Text } from '@mantine/core';
import { PagePanel, PanelBody } from '@root/Design/Layout';
import { colorPalette } from '@root/Design/Themes';
import { OpenSidebar } from './OpenSidebar';
import { BulkTagProps } from './BulkTagProps';

export function UpdateTags(props: BulkTagProps) {
    const { classes, theme } = useStyles();
    return (
        <PagePanel size="fill">
            <TagActionContainer>
                <Group position="apart" p="lg">
                    <Text data-atid="SidePanelHeader" size={20} weight={600}>
                        {props.pageTitle}
                    </Text>
                    <CloseButton data-atid="SidePanelCloseButton" mr="xl" onClick={() => props.onButtonClick(OpenSidebar.undefined, false)} />
                </Group>
                <PanelBody style={{ marginLeft: 0 }}>
                    <TagActionBody>
                        <Text className={classes.textColor}>{props.instructions}</Text>
                        <Space h="lg"></Space>
                        <Space h="sm"></Space>
                        <Button
                            data-atid="AddTagsButton"
                            leftIcon={<i className="ti ti-tag" />}
                            rightIcon={<i className="ti ti-chevron-right" />}
                            variant="outline"
                            onClick={() => props.onButtonClick(OpenSidebar.addTags, false)}
                            style={{
                                width: 210,
                            }}
                        >
                            Add Tags
                        </Button>
                        <Space h="sm"></Space>
                        <Button
                            data-atid="RemoveTagsButton"
                            leftIcon={<i className="ti ti-trash" />}
                            rightIcon={<i className="ti ti-chevron-right" />}
                            variant="outline"
                            onClick={() => props.onButtonClick(OpenSidebar.removeTags, false)}
                            sx={{
                                width: 210,
                            }}
                        >
                            Remove Tags
                        </Button>
                        <Space h="sm"></Space>
                        <Button
                            data-atid="RenameTagKeysButton"
                            leftIcon={<i className="ti ti-pencil" />}
                            rightIcon={<i className="ti ti-chevron-right" />}
                            variant="outline"
                            onClick={() => props.onButtonClick(OpenSidebar.renameTags, false)}
                            sx={{
                                width: 210,
                            }}
                        >
                            Rename Tag Keys
                        </Button>
                        <Space h="sm"></Space>
                        <Button
                            data-atid="RenameTagValuesButton"
                            leftIcon={<i className="ti ti-pencil" />}
                            rightIcon={<i className="ti ti-chevron-right" />}
                            variant="outline"
                            onClick={() => props.onButtonClick(OpenSidebar.changeTagValues, false)}
                            sx={{
                                width: 210,
                            }}
                        >
                            Rename Tag Values
                        </Button>
                    </TagActionBody>
                </PanelBody>
            </TagActionContainer>
        </PagePanel>
    );
}

const useStyles = createStyles((theme) => ({
    textColor: {
        color: colorPalette.subHeaderTextColor,
    },

    footer: {
        position: 'absolute',
        bottom: 0,
    },

    keyValues: {
        marginTop: `${theme.spacing.xs}px`,
    },
}));

export const TagActionContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const TagActionHeader = styled.div`
    height: 40px;
    justify-content: start;
    align-items: center;
    font-size: 20px;
    padding: 10px 0;
`;
export const TagActionSubHeader = styled.div`
    justify-content: start;
    align-items: center;
    font-size: 16px;
    color: ${colorPalette.subHeaderTextColor};
`;

export const TagActionBody = styled.div`
    justify-content: start;
    align-items: center;
    margin-top: ${(p) => p.theme.spacing.xs}px;
    margin-bottom: ${(p) => p.theme.spacing.xs}px;

    Text {
        color: ${colorPalette.subHeaderTextColor};
    }
`;

export const TagActionFooter = styled.div`
    justify-content: start;
    align-items: center;
    position: 'absolute';
    bottom: 0;
`;

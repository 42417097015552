import { ButtonStylesParams, Tuple } from '@mantine/core';
import { MantineThemeOverride } from '@mantine/styles';

export const navSize = {
    narrow: 70,
    itemSize: 44,
    wide: 256,
    secondary: 200,
};

export type CustomColors = 'gray' | 'primary' | 'error' | 'warning' | 'success';
export const theme: MantineThemeOverride = {
    primaryColor: 'primary',
    fontFamily: `'Open Sans', sans-serif`,
    headings: {
        fontFamily: `'Open Sans', sans-serif`,
    },
    black: '#333',
    primaryShade: 6,
    colors: {
        gray: ['#FCFCFD', '#F9FAFB', '#F2F4F7', '#EAECF0', '#D0D5DD', '#98A2B3', '#667085', '#475467', '#344054', '#1D2939'],
        primary: ['#F7FDFF', '#EFFAFF', '#DFF6FF', '#B0E8FF', '#31C2FF', '#009FE1', '#0075C1', '#00609F', '#004F83', '#003A61'],
        error: ['#FFFBFA', '#FEF3F2', '#FEE4E2', '#FECDCA', '#F97066', '#F04438', '#D92D20', '#B42318', '#912018', '#7A271A'],
        warning: ['#FFFCF5', '#FFFAEB', '#FEF0C7', '#FEDF89', '#FDB022', '#F79009', '#DC6803', '#B54708', '#93370D', '#7A2E0E'],
        success: ['#F6FEF9', '#ECFDF3', '#D1FADF', '#A6F4C5', '#32D583', '#12B76A', '#039855', '#027A48', '#05603A', '#054F31'],
    },
    other: {},
    components: {
        DatePicker: {
            defaultProps: {
                firstDayOfWeek: 'sunday',
            },
        },
        Calendar: {
            defaultProps: {
                firstDayOfWeek: 'sunday',
            },
        },
        TextInput: {
            defaultProps: {
                autoComplete: 'off',
            },
        },
        Button: {
            styles: (theme, params: ButtonStylesParams) => ({
                root: {
                    backgroundColor: params.variant === 'outline' ? ('#FFFFFF' as CustomColors) : undefined,
                    color: params.variant === 'outline' ? (theme.colors.primary[6] as CustomColors) : undefined,
                    borderColor: params.variant === 'outline' ? (theme.colors.gray[4] as CustomColors) : undefined,
                    '&hover': {
                        color: params.variant === 'outline' ? (theme.colors.primary[7] as CustomColors) : undefined,
                        backgroundColor: params.variant === 'outline' ? (theme.colors.gray[1] as CustomColors) : undefined,
                    },
                    '&:disabled': {
                        color: params.variant === 'outline' ? (theme.colors.gray[6] as CustomColors) : ('#FFFFFF' as CustomColors),
                        backgroundColor: params.variant === 'outline' ? ('#FFFFFF' as CustomColors) : (theme.colors.gray[4] as CustomColors),
                    },
                },
            }),
        },
        Divider: {
            styles: (theme) => ({
                root: {
                    borderColor: theme.colors.gray[3] as CustomColors,
                },
            }),
        },
        Tooltip: {
            defaultProps: {
                zIndex: 1001,
            },
        },
        Menu: {
            styles: (theme) => ({
                item: {
                    '&[data-hovered=true]': { background: theme.colors.primary[2] },
                },
            }),
        },
    },
};

declare module '@mantine/core' {
    export interface MantineThemeColorsOverride {
        colors: Record<CustomColors, Tuple<string, 10>> & Record<`${CustomColors}.${0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}`, Tuple<string, 10>>;
    }
}

export const mspColorPalette = {
    //Tag Manager
    get LeftNavAppBackgroundColor() {
        return theme.other?.LeftNavAppBackgroundColor as CustomColors;
    },
    //Left nav main background
    get LeftNavBgColor() {
        return theme.other?.LeftNavBgColor as CustomColors as CustomColors;
    },
    //nav hover except top level
    get LeftNavHoverColor() {
        return theme.other?.LeftNavHoverColor as CustomColors;
    },
    get LeftNavLogoBg() {
        return theme.other?.LeftNavLogoBg as CustomColors;
    },
    get LeftNavColorMode() {
        return theme.other?.LeftNavColorMode as 'light' | 'dark';
    },
    get CollapseNavAndLockColor() {
        return theme.other?.CollapseNavAndLockColor as CustomColors;
    },
    //Left nav child (uppper) background
    get LeftNavChildBgColor() {
        return theme.other?.LeftNavChildBgColor as CustomColors;
    },
    get LeftNavCollapseCaretColor() {
        return theme.other?.LeftNavCollapseCaretColor as CustomColors;
    },
    get LeftNavDividerColor() {
        return theme.other?.LeftNavDividerColor as CustomColors;
    },
    get LeftNavHoverText() {
        return theme.other?.LeftNavHoverText as CustomColors;
    },
    //Dashboards (top level nav) Link
    get LeftNavMainBackground() {
        return theme.other?.LeftNavMainBackground as CustomColors;
    },
    get LeftNavMainAndSelectedCollapsedBgColor() {
        return theme.other?.LeftNavMainAndSelectedCollapsedBgColor as CustomColors;
    },
    get LeftNavTextColor() {
        return theme.other?.LeftNavTextColor as CustomColors;
    },
    get LowerNavSelected() {
        return theme.other?.LowerNavSelected as CustomColors;
    },
    get OverviewHover() {
        return theme.other?.OverviewHover as CustomColors;
    },
    get SecondaryNavBackground() {
        return theme.other?.SecondaryNavBackground as CustomColors;
    },
    get SettingsNavHoverAndSelectedBgColor() {
        return theme.other?.SettingsNavHoverAndSelectedBgColor as CustomColors;
    },
    get SettingsNavHoverAndSelectedTextColor() {
        return theme.other?.SettingsNavHoverAndSelectedTextColor as CustomColors as CustomColors;
    },
    get TopNavTextColor() {
        return theme.other?.TopNavTextColor as CustomColors;
    },
    //Collapse main nav
    get CollapseNavCaretBackground() {
        return theme.other?.CollapseNavCaretBackground as CustomColors;
    },
};

export const colorPalette = {
    get navColor() {
        return theme.colors?.gray?.[8] as CustomColors;
    },
    get navHoverBgColor() {
        return theme.colors?.gray?.[8] as CustomColors;
    },
    get navPopupHeaderColor() {
        return theme.colors?.gray?.[4] as CustomColors;
    },
    get navChevronColor() {
        return theme.colors?.gray?.[8] as CustomColors;
    },
    get titleTextColor() {
        return theme.colors?.gray?.[8] as CustomColors;
    },
    get subHeaderTextColor() {
        return theme.colors?.gray?.[8] as CustomColors;
    },
    get subTextColor() {
        return theme.colors?.gray?.[7] as CustomColors;
    },
    get lightTextColor() {
        return theme.colors?.gray?.[6] as CustomColors;
    },
    get linkColor() {
        return theme.colors?.primary?.[6] as CustomColors; //primary button color
    },
    get linkHoverColor() {
        return theme.colors?.primary?.[7] as CustomColors; //hover color
    },
    get deleteColor() {
        return theme.colors?.error?.[6] as CustomColors;
    },
    get numberCircleColor() {
        return theme.colors?.gray?.[2] as CustomColors;
    },
    get darkTitleColor() {
        return '#101828' as CustomColors;
    },
    get white() {
        return '#FFFFFF' as CustomColors;
    },
    get pendingVerificationColor() {
        return theme.colors?.warning?.[7] as CustomColors;
    },
    get pendingVerificationArrowColor() {
        return theme.colors?.warning?.[5] as CustomColors;
    },
    get warningBadgeColor() {
        return theme.colors?.warning?.[1] as CustomColors;
    },
    get greenIconColor() {
        return theme.colors?.success?.[6] as CustomColors;
    },
    get greenIconBgColor() {
        return theme.colors?.success?.[2] as CustomColors;
    },
    get lightGreenIconBgColor() {
        return theme.colors?.success?.[1] as CustomColors;
    },
    get negativeNumberColor() {
        return theme.colors?.error?.[7] as CustomColors;
    },
    get errorTitleText() {
        return theme.colors?.error?.[7] as CustomColors;
    },
    get errorSubText() {
        return theme.colors?.error?.[6] as CustomColors;
    },
    get errorBgColor() {
        return theme.colors?.error?.[0] as CustomColors;
    },
    get errorIconColor() {
        return theme.colors?.error?.[6] as CustomColors;
    },
    get darkTextCustomColor() {
        return 'gray' as CustomColors;
    },
    get errorTextCustomColor() {
        return 'error' as CustomColors;
    },
    get fffColor() {
        return '#fff' as CustomColors;
    },
    get fff0Color() {
        return '#fff0' as CustomColors;
    },
    get fff8Color() {
        return '#fff8' as CustomColors;
    },
    get fff9Color() {
        return '#fff9' as CustomColors;
    },
};

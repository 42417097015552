import { getCloudIntelligenceGetCustomerSubsidyRequests, postCompanyStatsQueryCompanyStats } from '@apis/Customers';
import { CompanyStat, CustomerSubsidyViewModel, IQueryExpr } from '@apis/Customers/model';
import type { Company } from '@apis/Customers/model';
import { Anchor, useMantineTheme } from '@mantine/core';
import { DataGrid } from '@root/Components/DataGrid';
import { FieldInfoColumnAdapter } from '@root/Components/DataGrid/FieldInfoColumnAdapter';
import { ColumnConfig, DataColumnConfig, DataGridProps } from '@root/Components/DataGrid/Models';
import { PageBody, PageContent, PageSection } from '@root/Design/Layout';
import { useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { Logger } from '@root/Services/Logger';
import { NavigationService, useNav } from '@root/Services/NavigationService';
import { SchemaService, TypeInfo } from '@root/Services/QueryExpr';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { linkHandler, useLink } from '@root/Services/Router/Router';
import { useMemo } from 'react';
import { inject, injectable } from 'tsyringe';
import { ColumnSelectorOption, IColumnSelectorOption } from '@root/Components/DataGrid/ColumnSelector';
import { ICompanyContextToken } from '@root/Services/Customers/CompanyContext';

export function CloudExplorerPage() {
    const theme = useMantineTheme();
    return (
        <PageBody style={{ background: theme.colors.gray[2] }}>
            <PageContent>
                <PageSection height="full">
                    <CloudExplorerGrid />
                </PageSection>
            </PageContent>
        </PageBody>
    );
}
endpoint('cloud-explorer', CloudExplorerPage, 'Cloud Explorer');

@injectable()
class CloudExplorerGridModel {
    public loading = new EventEmitter<boolean>(false);
    public schema = new SchemaService([]);
    public gridProps?: DataGridProps<CompanyStat>;
    private customerLookup = new Map<number, CustomerSubsidyViewModel>();
    private readonly columnInfo = new Map<string, { exclude?: boolean; group?: string; name?: string }>([
        ['CPUSampleFrom', { exclude: true }],
        ['CPUSampleTo', { exclude: true }],
        ['Id', { exclude: true }],
        ['SubsidizingCompanyIds', { exclude: true }],
        ['MspCompanyId', { exclude: true }],
        ['CompanyName', { name: 'Company' }],
        ['ClarityScore', { group: 'Tag Quality', name: 'Clarity' }],
        ['CompletenessScore', { group: 'Tag Quality', name: 'Completeness' }],
        ['ComplianceScore', { group: 'Tag Quality', name: 'Compliance' }],
        ['CoverageScore', { group: 'Tag Quality', name: 'Coverage' }],
        ['Date', { exclude: true }],
        ['CPUAMD', { group: 'CPU Marketshare', name: '% AMD' }],
        ['CPUIntel', { group: 'CPU Marketshare', name: '% Intel' }],
        ['CPUOther', { group: 'CPU Marketshare', name: '% Other' }],
        ['AwsResources', { group: 'AWS Environment', name: 'Resources' }],
        ['AwsAnnualSpend', { group: 'AWS Environment', name: 'Spend' }],
        ['AwsConnectionDate', { group: 'AWS Environment', name: 'Date Connected' }],
        ['AzureResources', { group: 'Azure Environment', name: 'Resources' }],
        ['AzureAnnualSpend', { group: 'Azure Environment', name: 'Spend' }],
        ['AzureConnectionDate', { group: 'Azure Environment', name: 'Date Connected' }],
        ['SubscriptionActive', { name: 'Subscription' }],
    ]);
    public constructor(
        @inject(FieldInfoColumnAdapter) private readonly columnAdapter: FieldInfoColumnAdapter<CompanyStat>,
        @inject(NavigationService) private readonly navigationSvc: NavigationService,
        @inject(Logger) private readonly logger: Logger,
        @inject(FormatService) private readonly formatSvc: FormatService,
        @inject(ICompanyContextToken) private readonly company: Company
    ) {}

    public init() {
        this.loading.emit(true);
        this.load();
        return this;
    }

    private async load() {
        try {
            const schemaResults = await postCompanyStatsQueryCompanyStats({ IncludeSchema: true, Take: 0 });
            const customerRequests = await getCloudIntelligenceGetCustomerSubsidyRequests();
            this.customerLookup = customerRequests.reduce(
                (result, item) => (!item.CustomerCompanyId ? result : result.set(item.CustomerCompanyId, item)),
                new Map<number, CustomerSubsidyViewModel>()
            );
            const types = schemaResults.Types ?? [];
            this.schema = new SchemaService(types);
            this.gridProps = {
                dataSource: (query) => {
                    const where: IQueryExpr = {
                        Operation: 'and',
                        Operands: [{ Operation: 'ne', Operands: [{ field: 'Id' }, { value: this.company.Id }] }],
                    };
                    if (query.Where) {
                        where.Operands.push(query.Where);
                    }
                    query.Where = where;
                    return postCompanyStatsQueryCompanyStats(query);
                },
                statePersistence: {
                    key: 'partner-cloud-ex',
                },
                allowPinning: true,
                groupConfig: {
                    ['CPU Marketshare']: { color: '#46C1BE' },
                    ['AWS Environment']: { color: '#FF9900' },
                    ['Azure Environment']: { color: '#007FFF' },
                    ['Tag Quality']: { color: '#71C562' },
                },
                columns: this.createColumns(this.schema),
                onModelLoaded: (model) => {
                    model.modifyColumnSelectorOptions = this.modifyColumnOptions;
                },
                showHeaderGroups: true,
                state: {
                    columns: [
                        { id: 'company-stats.CompanyName', width: 250, fixed: true },
                        { id: 'company-stats.SubscriptionActive', width: 120, fixed: true },
                        { id: 'company-stats.AwsAnnualSpend', width: 80 },
                        { id: 'company-stats.AwsResources', width: 100 },
                        { id: 'company-stats.AwsConnectionDate', width: 140 },
                        { id: 'company-stats.AzureAnnualSpend', width: 80 },
                        { id: 'company-stats.AzureResources', width: 100 },
                        { id: 'company-stats.AzureConnectionDate', width: 140 },
                        { id: 'company-stats.Marketshare.CPUAMD', width: 90 },
                        { id: 'company-stats.Marketshare.CPUIntel', width: 90 },
                        { id: 'company-stats.Marketshare.CPUOther', width: 90 },
                    ],
                    filters: [],
                    sort: [{ Expr: { field: 'CompanyName' }, Direction: 'Asc' }],
                },
            };
        } finally {
            this.loading.emit(false);
        }
    }

    private createColumns(schema: SchemaService): ColumnConfig<CompanyStat>[] {
        const rootTypes = schema.rootTypeInfo;
        const columns = this.createBaseColumns(rootTypes, []);

        this.updateColumn(columns, 'CompanyName', {
            defaultFixed: true,
            cellRenderer: (item) => {
                const customer = this.customerLookup.get(item.Id!);

                return (
                    <Anchor
                        href={this.navigationSvc.getDescendUrl('company-detail', { companyId: customer?.Id?.toString() ?? '' })}
                        onClick={linkHandler}
                    >
                        {item.CompanyName}
                        {customer?.Company !== item.CompanyName ? ` - ${customer?.Company}` : ''}
                    </Anchor>
                );
            },
            filter: {
                filterType: 'string',
                name: 'Company',
                filterField: 'Id',
                options: {
                    getValueProvider: () => {
                        const items = [...this.customerLookup.entries()]
                            .map(([_, value]) => value)
                            .filter((item) => item.CustomerCompanyId)
                            .sort((a, b) => a.Company!.localeCompare(b.Company!, undefined, { sensitivity: 'base' }))
                            .map((item) => ({ label: item.Company!, value: item.CustomerCompanyId }));
                        return items;
                    },
                },
            },
        });
        this.updateColumn(columns, 'AwsAnnualSpend', {
            cellRenderer: (item) => (
                <>{typeof item.AwsAnnualSpend === 'number' ? this.formatSvc.formatMoneyNoDecimals(item.AwsAnnualSpend) : <>&mdash;</>}</>
            ),
        });
        this.updateColumn(columns, 'AwsResources', {
            cellRenderer: (item) => <>{item.AwsResources ? this.formatSvc.formatInt(item.AwsResources) : 0}</>,
        });
        this.updateColumn(columns, 'AzureResources', {
            cellRenderer: (item) => <>{item.AzureResources ? this.formatSvc.formatInt(item.AzureResources) : 0}</>,
        });
        this.updateColumn(columns, 'Marketshare.CPUAMD', {
            filter: undefined,
            cellRenderer: (item: any) => (
                <>{typeof item.Marketshare?.CPUAMD === 'number' ? this.formatSvc.formatPercent(item.Marketshare.CPUAMD) : <>&mdash;</>}</>
            ),
        });
        this.updateColumn(columns, 'Marketshare.CPUIntel', {
            filter: undefined,
            cellRenderer: (item: any) => (
                <>{typeof item.Marketshare?.CPUIntel === 'number' ? this.formatSvc.formatPercent(item.Marketshare.CPUIntel) : <>&mdash;</>}</>
            ),
        });
        this.updateColumn(columns, 'Marketshare.CPUOther', {
            filter: undefined,
            cellRenderer: (item: any) => (
                <>{typeof item.Marketshare?.CPUOther === 'number' ? this.formatSvc.formatPercent(item.Marketshare.CPUOther) : <>&mdash;</>}</>
            ),
        });
        for (const field of ['ClarityScore', 'CompletenessScore', 'CoverageScore', 'ComplianceScore']) {
            this.updateColumn(columns, field, {
                filter: undefined,
                cellRenderer: (item: any) => <>{typeof item[field] === 'number' ? this.formatSvc.formatPercent(item[field]) : <>&mdash;</>}</>,
            });
        }
        this.updateColumn(columns, 'SubscriptionActive', {
            filter: {
                name: 'Subscription',
                filterType: 'string',
                filterField: 'SubscriptionActive',
                options: {
                    getValueProvider() {
                        return [
                            { label: 'Active', value: true },
                            { label: 'Inactive', value: false },
                        ];
                    },
                },
            },
            cellRenderer: (item) => (item.SubscriptionActive ? 'Active' : 'Inactive'),
        });

        return columns;
    }

    private modifyColumnOptions = (options: { options: ColumnSelectorOption[]; selections: IColumnSelectorOption[] }) => {
        options.options.sort((a, b) => {
            const isAGroup = 'name' in a;
            const isBGroup = 'name' in b;
            const nameA = isAGroup ? a.name : a.column.header ?? '';
            const nameB = isBGroup ? b.name : b.column.header ?? '';
            return isAGroup === isBGroup ? nameA.localeCompare(nameB, undefined, { sensitivity: 'base' }) : isAGroup ? -1 : 1;
        });
    };

    private createBaseColumns(types: TypeInfo[], columns: DataColumnConfig<CompanyStat>[]) {
        for (const type of types) {
            for (const field of type.fields) {
                const columnInfo = this.columnInfo.get(field.fieldName);
                if (!columnInfo?.exclude) {
                    if (field.isPrimitive) {
                        const column = this.columnAdapter.adapt(field);
                        column.groupName = columnInfo?.group;
                        column.header = columnInfo?.name ?? column.header;
                        columns.push(column);
                    } else if (field.typeInfo) {
                        this.createBaseColumns([field.typeInfo], columns);
                    }
                }
            }
        }
        return columns;
    }

    private updateColumn(
        columns: DataColumnConfig<CompanyStat>[],
        id: string,
        changes: Partial<DataColumnConfig<CompanyStat>> | ((column: DataColumnConfig<CompanyStat>) => void)
    ) {
        id = `company-stats.${id}`;
        const column = columns.find((c) => c.id === id);
        if (column) {
            if (typeof changes === 'object') {
                Object.assign(column, changes);
            } else {
                changes(column);
            }
        } else {
            this.logger.warn(`No column found for ID ${id}`, columns);
        }
    }
}
function CloudExplorerGrid() {
    const di = useDiContainer();
    const model = useMemo(() => di.resolve(CloudExplorerGridModel).init(), []);
    const loading = useEventValue(model.loading);
    useLink;

    return loading || !model.gridProps ? null : <DataGrid {...model.gridProps} />;
}

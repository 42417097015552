import { CreditRates, Plans, SubscriptionType } from '@apis/Customers/model';
import { Box, Divider, Group, Table, Text, ThemeIcon } from '@mantine/core';
import { getProductIcon } from '@root/Components/Applications/AppIcons';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { Cloud, DotsCircleHorizontal } from 'tabler-icons-react';

const visibleSubscriptionPricing = new Set<string>([]);

export function useSubscriptionDetailsVisible(application: string) {
    return visibleSubscriptionPricing.has(application);
}

export function SubscriptionPriceDetails({
    plan,
    creditRate,
    subscriptionType,
}: {
    subscriptionType: SubscriptionType;
    plan: Plans;
    creditRate: CreditRates;
}) {
    const ProductIcon = getProductIcon(subscriptionType?.Application ?? '') ?? Cloud;
    const formatSvc = useDi(FormatService);
    const subscriptionPricingVisible = useSubscriptionDetailsVisible(subscriptionType?.Application ?? '');
    return (
        <>
            {subscriptionPricingVisible ? (
                <>
                    <Group noWrap align="flex-start" p="md">
                        <ThemeIcon variant="light" size="xl" radius="xl">
                            <ProductIcon />
                        </ThemeIcon>
                        <Box>
                            <Group position="apart">
                                <Text>{subscriptionType?.FriendlyName}</Text>
                                <Text align="right" pr="lg">
                                    {formatSvc.formatMoneyNoDecimals(plan.Price ?? 0)} / {plan.Term}
                                </Text>
                            </Group>
                            <Text color="dimmed" size="sm">
                                {subscriptionType?.Description}
                            </Text>
                        </Box>
                    </Group>
                    <Divider />
                    <Box m="md">
                        <Group noWrap>
                            <ThemeIcon variant="light" size="xl" radius="xl">
                                <DotsCircleHorizontal />
                            </ThemeIcon>
                            <Text>Credit Pricing</Text>
                        </Group>
                        <Table horizontalSpacing="xl">
                            <tbody>
                                {creditRate.Ranges?.map((r, i) => (
                                    <tr key={i}>
                                        <td>
                                            {formatSvc.formatInt(r.From ?? 0)}{' '}
                                            {r.To ? <>&mdash; {formatSvc.formatInt(r.To ?? 0)}</> : <>&amp; above</>}
                                        </td>
                                        <td style={{ textAlign: 'right' }}>${formatSvc.formatDecimal(r.Rate ?? 0, 3)}</td>
                                    </tr>
                                ))}
                            </tbody>
                            <thead>
                                <tr>
                                    <th>Tiers</th>
                                    <th style={{ textAlign: 'right' }}>Price per unit</th>
                                </tr>
                            </thead>
                        </Table>
                    </Box>
                </>
            ) : null}
        </>
    );
}

import { IdleResourcesStartStopJob, ResourceIdentifier } from '@apis/Resources/model';
import { Anchor, Box, Group, LoadingOverlay, Title } from '@mantine/core';
import { DataGrid } from '@root/Components/DataGrid';
import { DataColumnConfig, DataGridState } from '@root/Components/DataGrid/Models';
import { ResourceDetailsOpener } from '@root/Components/Resources/ResourceDetails';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { useMemo } from 'react';
import { ActivityDetailsModel } from './ActivityDetailsService';
import { IdleActionResult, IdleActionResultStatuses } from './ActivityTypes';
import { ActivityDetailsContainer, DetailRow, ProgressBadge, RowDivider } from './Design';
import { ActivityDetailsProps } from './Models';

function ResourceCell({ result, onSelect }: { result: IdleActionResult['Results'][number]; onSelect: (resourceId?: string) => void }) {
    const fmtSvc = useDi(FormatService);
    const resourceId = result.Id ?? '';
    const name = fmtSvc.awsIdToName(resourceId);
    return (
        <Group sx={{ width: '100%', height: '100%', lineHeight: 1 }}>
            <Anchor onClick={() => onSelect(resourceId)}>{name}</Anchor>
        </Group>
    );
}

export function IdleStartStopActivityDetails({ displayInfo, item: { job } }: ActivityDetailsProps) {
    const container = useDiContainer();
    const fmtSvc = useDi(FormatService);
    const idleData = job.Parameters as IdleResourcesStartStopJob;
    const resultData = job.ResultDetail as IdleActionResult | undefined;
    const model = useMemo(() => {
        const result = container.resolve(ActivityDetailsModel);
        result.init(job, (b) => {
            return b.and(b.model.Id!.eq(job.Id!), b.model.Type!.eq('Cloudsaver.Resources.Domain.Models.IdleResources.IdleResourcesStartStopJob'));
        });
        return result;
    }, []);
    const startedLbl = job.Status === 'Created' ? 'Queued' : 'Started';
    const finishedLbl = job.Status !== 'Created' && job.Status !== 'Started' ? 'Finished' : '';
    const startedAtDt = fmtSvc.formatDatetime(fmtSvc.toLocalDate((job.StartedAt ?? job.QueuedAt)!));
    const finishedAtDt = fmtSvc.formatDatetime(fmtSvc.toLocalDate(job.FinishedAt!));
    const inProgress = !['Failed', 'Succeeded', 'Canceled'].includes(job.Status ?? '');
    const resultCt = resultData?.Results?.length ?? 0;
    const { successCt, noopCt, failCt } =
        resultData?.Results.reduce(
            (result, item) => {
                if (item.Result === IdleActionResultStatuses.Success) {
                    result.successCt++;
                } else if (item.Result === IdleActionResultStatuses.Noop) {
                    result.noopCt++;
                } else {
                    result.failCt++;
                }
                return result;
            },
            { successCt: 0, noopCt: 0, failCt: 0 }
        ) ?? {};
    const invalidCt = (idleData.Resources?.length ?? 0) - (resultData?.Results?.length ?? 0);
    const allFailed = job.Status === 'Failed' || failCt === failCt;
    const resourceType = idleData.ResourceType === 'EC2 On Demand' ? 'EC2' : idleData.ResourceType;
    const requestCt = idleData.Resources?.length ?? 0;
    const pluralReq = requestCt > 1 ? 's' : '';
    const requestLbl = `${idleData.ActionType} ${requestCt} ${resourceType} resource${pluralReq}`;
    const pastTenseAction = idleData.ActionType === 'Start' ? 'Started' : 'Stopped';
    const noopStatus = idleData.ActionType === 'Start' ? 'Already Running' : 'Already Stopped';

    const resourceSelectedEvent = useMemo(() => new EventEmitter<string | undefined>(undefined), []);
    const selectedResourceId = useEventValue(resourceSelectedEvent);

    const loading = useEventValue(model.loading);
    const columns = useMemo(
        () =>
            [
                {
                    accessor: 'ResourceId',
                    id: 'Resource',
                    header: 'Resource',
                    defaultWidth: 150,
                    type: 'string',
                    noRemove: true,
                    cellRenderer: (item) => <ResourceCell onSelect={resourceSelectedEvent.emit} result={item} />,
                    noSort: true,
                },
                {
                    accessor: 'Result',
                    id: 'Result',
                    header: 'Result',
                    defaultWidth: 100,
                    type: 'string',
                    noRemove: true,
                    cellRenderer: (item) =>
                        item.Result === IdleActionResultStatuses.Success
                            ? pastTenseAction
                            : item.Result === IdleActionResultStatuses.Noop
                            ? noopStatus
                            : 'Failed ' + item.FailMessage,
                },
            ] as DataColumnConfig<IdleActionResult['Results'][number]>[],
        []
    );
    const defaultState = useMemo(
        () =>
            ({
                sort: [{ Direction: 'Asc', Expr: { Field: 'Status' } }],
                filters: [],
                columns: [
                    { id: 'Resource', width: 150 },
                    { id: 'Result', width: 300 },
                ],
            } as DataGridState),
        []
    );

    return loading ? (
        <LoadingOverlay visible />
    ) : (
        <ActivityDetailsContainer>
            <DetailRow title={startedLbl}>{startedAtDt}</DetailRow>
            {finishedLbl ? <DetailRow title={finishedLbl}>{finishedAtDt}</DetailRow> : null}
            <RowDivider />
            <DetailRow title="Request">{requestLbl}</DetailRow>
            <DetailRow title="Requested By">{model.getRequestedBy()}</DetailRow>
            <DetailRow title="Status">{finishedLbl ? finishedLbl : startedLbl}</DetailRow>
            <RowDivider />
            {inProgress ? null : (
                <>
                    <DetailRow title="Results">
                        <ProgressBadge text={pastTenseAction} value={successCt ?? 0} color="success" />
                        <ProgressBadge text={noopStatus} value={noopCt ?? 0} />
                        <ProgressBadge text="Failed" value={failCt ?? 0} color="error" />
                        <ProgressBadge text="Invalid" value={invalidCt ?? 0} color="error" />
                    </DetailRow>
                    <Group position="apart" mx="md">
                        <Title order={5} my="sm">
                            Details
                        </Title>
                    </Group>
                    <Box sx={{ flex: 1, minHeight: 0 }}>
                        <DataGrid state={defaultState} hideHeader hideFilter dataSource={resultData?.Results ?? []} columns={columns} />
                    </Box>
                    <ResourceDetailsOpener
                        onClose={() => resourceSelectedEvent.emit(undefined)}
                        resourceId={selectedResourceId}
                        platform={'Aws'}
                        resourceType={idleData.ResourceType ?? ''}
                    />
                </>
            )}
        </ActivityDetailsContainer>
    );
}

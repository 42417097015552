import { TagAutomationRule, TagAutomationRuleDetails, TagAutomationTraceByRule } from '@apis/TagManager/model';
import styled from '@emotion/styled';
import {
    Anchor,
    Button,
    Card,
    Center,
    CloseButton,
    Divider,
    Drawer,
    Group,
    LoadingOverlay,
    Space,
    Text,
    Title,
    useMantineTheme,
} from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { TagAutomationRuleService } from '@root/Site/TagManager/TagAutomation/Components/TagAutomationRuleService';
import { observer } from 'mobx-react';
import { Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import { useNav } from '@root/Services/NavigationService';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { CustomColors, theme } from '@root/Design/Themes';
import { getTagAutomationGetTagAutomationRule, postTagAutomationQueryTrace, postTagAutomationQueryTraceByRule } from '@apis/TagManager';
import { RuleDescription } from './RuleDescriber';
import { PanelBody, PanelContent } from '@root/Design/Layout';
import { IDateFluentOperators, queryBuilder } from '@root/Services/QueryExpr';
import { ColumnConfig } from '@root/Components/DataGrid/Models';
import { DataGrid } from '@root/Components/DataGrid';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { ObjectQueryResult } from '@apis/Jobs/model';
import { BarChart, BarChartSettings } from '@root/Components/Charts/BarChart';
import { Activity } from 'tabler-icons-react';
import { useLink } from '@root/Services/Router/Router';
import { QueryResult } from '@apis/Resources';
import { FillerSwitch } from '@root/Design/Filler';
import endOfToday from 'date-fns/endOfToday';

function TagAutomationDrawer() {
    const nav = useNav();
    const fmtSvc = useDi(FormatService);
    const tagAutomationRulesSvc = useDi(TagAutomationRuleService);
    const drawerOpen: boolean = tagAutomationRulesSvc.drawerOpen;
    const ruleDetails: TagAutomationRuleDetails = tagAutomationRulesSvc.ruleDetails;
    const isLoading: boolean = tagAutomationRulesSvc.loading.details;
    const tagPermissions = useAuthZValues({
        canEdit: { TagAutomation: 'Edit' },
    });
    const [rule, setRule] = useState<TagAutomationRule>();
    const [activity, setActivity] = useState<ActivityGridModel[]>();
    const { getDescendUrl } = useNav();
    const link = useLink();
    const activityUrl = getDescendUrl('tag-automation-rule-activity', { ruleId: ruleDetails.Id?.toString() ?? '' });

    useEffect(() => {
        if (ruleDetails.Id && drawerOpen) {
            (async () => {
                const rule = await getTagAutomationGetTagAutomationRule({ ruleId: ruleDetails.Id });
                setRule(rule);
                const activity = await createActivityQuery(rule);
                setActivity(activity);
            })();
        }
    }, [ruleDetails.Id, drawerOpen]);

    const navigateToEditRule = () => {
        const ruleId = ruleDetails.Id ? ruleDetails.Id.toString() : '';
        tagAutomationRulesSvc.closeDetailsDrawer();
        nav.descend('automation-rule', { ruleId });
    };

    const createActivityQuery = async (rule: TagAutomationRule) => {
        const status = rule?.Status == 'Test' ? 'Test' : 'Run';
        let oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 6);

        const result = await queryBuilder<
            TagAutomationTraceByRule & { ['Changes.RuleId']: number; RequestedChanges: number; CompletedChanges: number; JobStatus: string }
        >()
            .where((b) =>
                b.and(
                    b.model.Mode!.eq(status),
                    b.model['Changes.RuleId'].eq(ruleDetails?.Id!),
                    (b.model.Timestamp as unknown as IDateFluentOperators).onOrAfter(oneWeekAgo)
                )
            )
            .select((b) => ({
                Date: b.truncDate('day', b.model.Timestamp as unknown as Date, -fmtSvc.getTzOffsetHours(), oneWeekAgo, endOfToday()),
                Status: b.values(b.model.JobStatus, undefined, 'Test'),
                RequestedCount: b.sum(b.model.RequestedChanges),
                CompletedCount: b.sum(b.model.CompletedChanges),
                Count: b.count(),
            }))
            .execute((q) => postTagAutomationQueryTraceByRule(q));

        return (result?.Results ?? []).map((r) => ({
            ...r,
            Date: new Date(r.Date),
        }));
    };

    return (
        <>
            <Drawer opened={drawerOpen} position="right" size={500} withCloseButton={false} onClose={tagAutomationRulesSvc.closeDetailsDrawer}>
                <PanelContent>
                    <Group position="apart" py="sm" px="lg">
                        <Title order={4}>Rule Detail</Title>
                        <CloseButton size="lg" onClick={tagAutomationRulesSvc.closeDetailsDrawer} />
                    </Group>
                    <Divider />
                    <PanelBody>
                        <LoadingOverlay visible={isLoading || !rule} />
                        <RuleDetailsHeader>
                            {tagPermissions.canEdit && (
                                <Button variant="outline" rightIcon={<i className="ti ti-chevron-right"></i>} onClick={() => navigateToEditRule()}>
                                    Edit This Rule
                                </Button>
                            )}
                        </RuleDetailsHeader>
                        <Space h="lg" />
                        {isLoading || !rule ? null : (
                            <>
                                <RuleDetailsBody>
                                    <GeneralInformationCard details={ruleDetails} rule={rule} />
                                    <FillerSwitch loading={!activity}>
                                        {() => (
                                            <>
                                                <RecentActivityCard data={activity!} />
                                                <Anchor {...link(activityUrl)}>
                                                    <Text onClick={tagAutomationRulesSvc.closeDetailsDrawer} size="sm" align="right" pt="xs">
                                                        See full Activity Log
                                                    </Text>
                                                </Anchor>
                                                <RecentActivityBarGraph rule={rule} data={activity!} />
                                            </>
                                        )}
                                    </FillerSwitch>
                                </RuleDetailsBody>
                            </>
                        )}
                    </PanelBody>
                </PanelContent>
            </Drawer>
        </>
    );
}

interface GeneralInformationCardProps {
    details: TagAutomationRuleDetails;
    rule: TagAutomationRule;
}

function GeneralInformationCard({ details, rule }: GeneralInformationCardProps) {
    const formatSvc = useDi(FormatService);
    const theme = useMantineTheme();

    const detailRows = useMemo(
        () =>
            [
                ['Rule Type', formatSvc.userFriendlyCamelCase(details.Type ?? '')],
                ['Name', details.RuleName],
                ['Status', details.Status],
                ['Description', details.RuleDescription],
                ['Rule Definition', <RuleDescription rule={rule} />],
                ['Created By', details.CreatedByUserName],
                ['Created Date', formatSvc.toLocal(details.CreatedAt)],
                ['Last Modified By ', details.ModifiedByUserName || <>&mdash;</>],
                ['Last Modified Date', formatSvc.toLocal(details.ModifiedAt) || <>&mdash;</>],
            ] as [string, ReactNode][],
        [details, rule]
    );

    return (
        <>
            {detailRows.map(([name, value], i) => (
                <Fragment key={i}>
                    {i > 0 ? <Divider color={theme.colors.gray[3] as CustomColors} /> : null}
                    <DetailsLayout>
                        <Text size="sm" weight="bold">
                            {name}
                        </Text>
                        <Text size="sm" color="dimmed">
                            {value}
                        </Text>
                    </DetailsLayout>
                </Fragment>
            ))}
        </>
    );
}

interface ActivityGridModel {
    Count: number;
    RequestedCount: number;
    CompletedCount: number;
    Date: Date;
    Status: string;
}

function RecentActivityCard({ data }: { data: ActivityGridModel[] }) {
    const formatSvc = useDi(FormatService);
    const [grid, setGrid] = useState<DataGridModel>();

    const columns = useMemo(() => {
        return [
            {
                header: 'Activity',
                accessor: (item) => item.Count,
                defaultWidth: 100,
                id: 'Count',
                align: 'center',
                sortField: 'Count',
                type: 'number',
                cellRenderer: (item) => {
                    let color =
                        item.Status == 'Succeeded' ? (theme.colors?.success![8] as CustomColors) : (theme.colors?.warning![8] as CustomColors);

                    return (
                        <Text align="center" color={color} pt={4}>
                            {formatSvc.formatInt(item.RequestedCount ?? 0)} Actions
                        </Text>
                    );
                },
            },
            {
                header: 'Date',
                accessor: (item) => item.Date,
                defaultWidth: 100,
                id: 'Date',
                type: 'date',
                allowGrouping: false,
                cellRenderer: (item) => <>{formatSvc.formatDate(item.Date)} </>,
            },
            {
                header: 'Status',
                accessor: (item) => item.Status,
                defaultWidth: 100,
                id: 'Status',
                sortField: 'Status',
                cellRenderer: (item) => <>{item.RequestedCount ? item.Status : 'N/A'}</>,
            },
        ] as ColumnConfig<ActivityGridModel>[];
    }, [grid, data]);

    return (
        <>
            <Text weight="bold" pt="sm" pb="sm">
                Recent Activity
            </Text>
            <DataGridContainer>
                {!data.length ? (
                    <Card radius="md" sx={{ height: '100%' }} withBorder>
                        <Center sx={{ height: '100%' }}>
                            <Text color="dimmed">No Recent Activity</Text>
                        </Center>
                    </Card>
                ) : (
                    <DataGrid
                        rightTopPlaceHolder={<></>}
                        selectionMode="none"
                        disableHighlight
                        displayMode="list"
                        dataSource={data}
                        columns={columns}
                        hideHeader
                        hideFilter
                        onModelLoaded={setGrid}
                    />
                )}
            </DataGridContainer>
        </>
    );
}

function RecentActivityBarGraph({ data, rule }: { data: ActivityGridModel[]; rule: TagAutomationRule }) {
    const formatSvc = useDi(FormatService);
    const [activityBarData, setActivityBarData] = useState<{ x: string; status: string; count: number }[]>([]);

    const activityBarSettings = {
        margin: { top: 30, bottom: 55, left: 40, right: 20 },
        orientation: 'Vertical',
        sortBy: 'group',
        sort: 'ascending',
        format: 'integer',
        noWrapper: true,
        gridLines: true,
        enableLabel: 'on-hover',
    } as BarChartSettings;

    useEffect(() => {
        if (!!data) {
            let results = data;
            let activity: { x: string; status: string; count: number }[] = [];

            let activityDate = new Date();
            for (let i = 0; i < 7; i++) {
                activity.push({ x: formatSvc.formatDate(activityDate), status: '', count: 0 });
                activityDate.setDate(activityDate.getDate() - 1);
            }

            results.forEach((r) => {
                let activityDate = formatSvc.formatDate(r.Date);
                let existingIndex = activity.findIndex((a) => a.x == activityDate && a.status == r.Status);

                if (existingIndex >= 0) activity[existingIndex].count += r.RequestedCount;
                else activity.push({ x: activityDate, status: r.Status, count: r.RequestedCount });
            });

            setActivityBarData(activity);
        } else setActivityBarData([]);
    }, [data]);

    return (
        <>
            <Text weight="bold" pt="sm" pb="sm">
                {rule.Status === 'Test' ? 'Tests' : 'Tag Changes'}
            </Text>
            <DataBarGraphContainer>
                <BarChart settings={activityBarSettings} groups={['x', 'status']} values={['count']} data={activityBarData} />
            </DataBarGraphContainer>
        </>
    );
}

const DetailsLayout = styled.div`
    display: grid;
    grid-template-columns: 140px auto;
    column-gap: 16px;
    padding: 8px;
    > div:first-child {
        text-align: right;
    }
`;

export const RuleDetailsHeader = styled.div`
    text-align: right;
`;

export const RuleDetailsBody = styled.div``;

export const Bold = styled.strong`
    font-weight: bolder;
    text-shadow: 0px 0.25px, 0.25px 0px, 0.25px 0.25px;
`;

export const DataGridContainer = styled.div`
    height: 201px;
    overflow: auto;
`;

export const DataBarGraphContainer = styled.div`
    height: 300px;
    border: solid 1px ${(p) => p.theme.colors.gray[3] as CustomColors};
    border-radius: ${(p) => p.theme.radius.md}px;
`;

export default observer(TagAutomationDrawer);

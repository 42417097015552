import { getTagAutomationGetTagAutomationRules, postTagAutomationGetTotalActivityCount, postTagAutomationQueryTraceByRule } from '@apis/TagManager';
import styled from '@emotion/styled';
import { Box, Button, Card, Group, LoadingOverlay, Space, Text, Title, useMantineTheme } from '@mantine/core';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';
import { InitialSyncCheck } from '@root/Components/Resources/IntialSyncCheck';
import { withAppFeatureCheck } from '@root/Components/Shell/AppFeatureAccess';
import { PageContent, PagePanel, PanelBody, PaneledPage, PanelHeader, PanelSubHeader } from '@root/Design/Layout';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useNav } from '@root/Services/NavigationService';
import { IDateFluentOperators, IFluentOperators, queryBuilder } from '@root/Services/QueryExpr';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import TagAutomationDrawer from './Components/RuleDetailsDrawer';
import RuleGrid from './Components/RuleGrid';
import { TagAutomationDateRangeFilter } from './Components/TagAutoDateRangeFilter';
import { addDays, set } from 'date-fns';
import { LineChart } from '@root/Components/Charts/LineChart';
import { FillerSwitch } from '@root/Design/Filler';
import { useThrottledEffect } from '@react-hookz/web';

interface TimeSavedModel {
    Days: number;
    Hours: number;
    Minutes: number;
}

export function TagAutomationDashboardContent() {
    const nav = useNav();
    const tagPermissions = useAuthZValues({
        canCreate: { TagAutomation: 'Create' },
    });
    const [dateFilter, setDateFilter] = useState<{ from?: Date; to?: Date }>();
    const [activityCount, setActivityCount] = useState<number>();
    const [rules, setRules] = useState<boolean>();
    const [activityCountLoading, setActivityCountLoading] = useState(false);

    const navigateToNewRule = () => {
        nav.descend('automation-rule');
    };

    useEffect(() => {
        getTagAutomationGetTagAutomationRules().then((r) => setRules(!!r?.length));
    }, []);

    useEffect(() => {
        if (!dateFilter?.from && !dateFilter?.to) return;
        setActivityCountLoading(true);
        const query = queryBuilder<{ ['Mode']?: string; ['JobStatus']?: string; ['Timestamp']?: Date }>()
            .where((b) => {
                const where = [b.model.Mode!.ne('Test'), b.model.JobStatus!.eq('Succeeded')];
                if (dateFilter?.from) where.push((b.model.Timestamp as unknown as IDateFluentOperators).onOrAfter(dateFilter.from));
                if (dateFilter?.to) where.push((b.model.Timestamp as unknown as IDateFluentOperators).onOrBefore(dateFilter.to));
                return b.and(...where);
            })
            .build();

        postTagAutomationGetTotalActivityCount({
            Where: query.Where,
        }).then((activityCount) => {
            setActivityCount(activityCount);
            setActivityCountLoading(false);
        });
    }, [dateFilter]);

    return (
        <PageContent>
            <PaneledPage>
                <PagePanel size="fill">
                    <Group position="apart" spacing="sm">
                        <div>
                            <PanelHeader style={{ paddingBottom: 0 }}>
                                <Text data-atid="TagAutomationDashboardHeader" size={20}>
                                    Tag Automation
                                </Text>
                            </PanelHeader>
                            <PanelSubHeader>
                                <Text>Use rules to automate common tagging tasks</Text>
                            </PanelSubHeader>
                            <Space h="md" />
                        </div>
                        <Box pr="lg">
                            <TagAutomationDateRangeFilter onChange={setDateFilter} />
                        </Box>
                    </Group>
                    <Group style={{ minHeight: 175, maxHeight: 175 }} align="stretch" px="lg">
                        <ScoreCard pad rules={rules} loading={activityCountLoading}>
                            <AutomationKPI value={activityCount ?? 0} title="Activity" />
                        </ScoreCard>
                        <ScoreCard pad rules={rules} loading={activityCountLoading}>
                            <TimeSavedKPI value={activityCount ?? 0} title="Time Saved" />
                        </ScoreCard>
                        <ScoreCard fill rules={rules}>
                            <AutomationActivity dateRange={{ from: dateFilter?.from, to: dateFilter?.to }} />
                        </ScoreCard>
                    </Group>

                    <PanelBody>
                        {dateFilter ? (
                            <RuleGrid
                                dateFilter={dateFilter}
                                renderToolbar={() =>
                                    tagPermissions.canCreate ? (
                                        <Button
                                            leftIcon={<i className="ti ti-plus"></i>}
                                            rightIcon={<i className="ti ti-chevron-right"></i>}
                                            onClick={() => navigateToNewRule()}
                                        >
                                            Add New Rule
                                        </Button>
                                    ) : (
                                        <></>
                                    )
                                }
                            />
                        ) : null}
                        <TagAutomationDrawer />
                    </PanelBody>
                </PagePanel>
            </PaneledPage>
        </PageContent>
    );
}

function ScoreCard(props: { rules?: boolean; pad?: boolean; children: ReactNode; fill?: boolean; loading?: boolean }) {
    const { rules, pad, children, fill, loading } = props;
    const theme = useMantineTheme();
    const background = !rules ? theme.colors.gray[3] : undefined;
    return (
        <Card
            sx={{ background: background, flex: fill ? 1 : undefined, minWidth: 275, height: '100%' }}
            withBorder
            radius="lg"
            p={pad ? 'lg' : undefined}
        >
            <FillerSwitch loading={loading}>{() => children}</FillerSwitch>
        </Card>
    );
}

function AutomationKPI({ width, value, title }: { width?: string; value?: number; title: string }) {
    const formatSvc = useDi(FormatService);

    return (
        <Box sx={{ width, minHeight: 100, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box>
                <Title order={6}>{title}</Title>
            </Box>
            <Group pt="lg" position="center" sx={{ textAlign: 'center' }}>
                {!value ? (
                    <Text pb="xs" color="dimmed" italic>
                        No Activity
                    </Text>
                ) : (
                    <Box>
                        <BigNumber>{formatSvc.formatInt(value)}</BigNumber>
                        <Text size={'xs'}>Tags Updated</Text>
                    </Box>
                )}
            </Group>
        </Box>
    );
}

function TimeSavedKPI({ width, value, title }: { width?: string; value?: number; title: string }) {
    const secondsSavedPerActivity = 47;
    const formatSvc = useDi(FormatService);
    const [timeSaved, setTimeSaved] = useState<TimeSavedModel>({ Days: 0, Hours: 0, Minutes: 0 });

    useEffect(() => {
        let timeSavedInSeconds = (value ?? 0) * secondsSavedPerActivity;
        const days = Math.floor(timeSavedInSeconds / 86400);
        timeSavedInSeconds -= days * 86400;
        const hours = Math.floor(timeSavedInSeconds / 3600);
        timeSavedInSeconds -= hours * 3600;
        const minutes = Math.floor(timeSavedInSeconds / 60);
        setTimeSaved({ Days: days, Hours: hours, Minutes: minutes });
    }, [value]);
    return (
        <Box
            sx={{
                width,
                minHeight: 100,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <Box>
                <Title order={6}>{title}</Title>
            </Box>
            {value === undefined ? (
                <Text pb="xs" align="center" color="dimmed" italic>
                    N/A
                </Text>
            ) : timeSaved.Days > 10 ? (
                <Group pt="lg" position="center">
                    <Box sx={{ textAlign: 'center' }}>
                        <TimeSaved>{formatSvc.formatInt(timeSaved.Days)}</TimeSaved>
                        <Text size={'xs'}>Days</Text>
                    </Box>
                </Group>
            ) : value === 0 ? (
                <Group position="center">
                    <Text pb="xs" align="center" italic color="dimmed">
                        None
                    </Text>
                </Group>
            ) : (
                <Group pt="lg" position="center" sx={{}}>
                    <Box>
                        <TimeSaved>{formatSvc.formatInt(timeSaved.Days)}</TimeSaved>
                        <Text size={'xs'}>Days</Text>
                    </Box>
                    <Box>
                        <TimeSaved>{formatSvc.formatInt(timeSaved.Hours)}</TimeSaved>
                        <Text size={'xs'}>Hours</Text>
                    </Box>
                    <Box>
                        <TimeSaved>{formatSvc.formatInt(timeSaved.Minutes)}</TimeSaved>
                        <Text size={'xs'}>Minutes</Text>
                    </Box>
                </Group>
            )}
        </Box>
    );
}

function AutomationActivity({ dateRange }: { dateRange: { from?: Date; to?: Date } }) {
    const [data, setData] = useState<Record<string, string | number>[]>([]);
    const fmtSvc = useDi(FormatService);
    const [loading, setLoading] = useState(true);
    const [intervals, setIntervals] = useState<number[]>([]);
    const [dates, setDates] = useState<number>(0);
    const requestKey = useMemo(() => ({ value: '' }), []);

    const loadActivity = async (dateRange: { from?: Date; to?: Date }) => {
        setLoading(true);
        try {
            const from = dateRange.from;
            const to = dateRange.to ?? new Date();
            const key = JSON.stringify(dateRange);
            if (!from || requestKey.value === key) return;

            requestKey.value = key;

            const response = await queryBuilder<{
                Timestamp: Date;
                RequestedChanges: number;
                CompletedChanges: number;
            }>()
                .where((b) => {
                    const filters: IFluentOperators<boolean>[] = [];
                    if (from) {
                        filters.push(b.model.Timestamp!.onOrAfter(fmtSvc.toUtcJsonShortDate(from) as unknown as Date));
                    }
                    if (to) {
                        filters.push(b.model.Timestamp!.before(to));
                    }
                    return b.and(...filters);
                })
                .select((b) => ({
                    date: b.truncDate('day', b.model.Timestamp!, -fmtSvc.getTzOffsetHours(), from, addDays(to, -1)),
                    requested: b.sum(b.model.RequestedChanges),
                    completed: b.sum(b.model.CompletedChanges),
                    count: b.count(),
                }))
                .execute(postTagAutomationQueryTraceByRule);

            if (key === requestKey.value) {
                setDates(response?.Results?.length ?? 0);
                const data =
                    response?.Results?.reduce((result, item) => {
                        result.push(
                            {
                                Date: fmtSvc.toShortDate(item.date),
                                Activity: 'Requested',
                                Resources: item.requested,
                            },
                            {
                                Date: fmtSvc.toShortDate(item.date),
                                Activity: 'Changed',
                                Resources: item.completed,
                            }
                        );
                        return result;
                    }, [] as Record<string, number | string>[]) ?? [];
                setData(data);

                const getMaxCount = (data: Record<string, string | number>[]) => {
                    return data.reduce((max, item) => {
                        const count = typeof item.Resources === 'number' ? item.Resources : 0;
                        return count > max ? count : max;
                    }, 0);
                };

                const max = getMaxCount(data);
                const nearestDigitEndingIn5 = Math.ceil(max / 5) * 5;

                // Determine the interval step size to ensure a maximum of 6 intervals
                let stepSize = Math.ceil(nearestDigitEndingIn5 / 6 / 5) * 5;
                if (stepSize <= 0) {
                    stepSize = 5;
                }

                // Round stepSize to the nearest multiple of 5
                stepSize = Math.ceil(stepSize / 5) * 5;

                // Generate intervals from 0 to nearestDigitEndingIn5
                const intervalsToUse = [];
                for (let i = 0; i <= nearestDigitEndingIn5; i += stepSize) {
                    intervalsToUse.push(i);
                }

                setIntervals(intervalsToUse);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadActivity(dateRange);
    }, [dateRange]);

    return (
        <>
            <Title order={6}>Tag Changes</Title>
            <FillerSwitch loading={loading} noData={!data.length} noDataMessage={`No Changes`}>
                {() => (
                    <LineChart
                        settings={{
                            chartColors: ['#157CAD', '#14BDD4'],
                            noWrapper: true,
                            margin: { top: 10, right: 10, bottom: 50, left: 60 },
                            interval: 'day',
                            labelAngle: 0,
                            format: 'int',
                            enableArea: false,
                            hideGrid: true,
                            hidePoints: dates > 1,
                            gridYValues: intervals,
                        }}
                        data={data}
                        groups={['Date', 'Activity']}
                        values={['Resources']}
                    />
                )}
            </FillerSwitch>
        </>
    );
}

function TagAutomationDashboard() {
    return <ConnectionCheck>{() => <InitialSyncCheck>{() => <TagAutomationDashboardContent />}</InitialSyncCheck>}</ConnectionCheck>;
}

const BigNumber = styled.div`
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    color: ${(p) => p.theme.colors.success[6]};
`;

const TimeSaved = styled.div`
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    color: ${(p) => p.theme.colors.primary[6]};
`;

endpoint('tag-automation', withAppFeatureCheck(TagAutomationDashboard, 'Compliance'), 'Tag Automation');

import { AllocationDimension } from '@apis/Invoices/model';
import { EventEmitter } from '@root/Services/EventEmitter';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { ShowbackPersistence } from '@root/Services/Invoices/ShowbackService';
import { inject, Lifecycle, scoped } from 'tsyringe';
import { ShowbackModel } from '../Models';

@scoped(Lifecycle.ContainerScoped)
export class ShowbackSummaryModel {
    public showbackModel!: ShowbackModel;
    private requestKey = 0;

    public dataChanged = new EventEmitter<Awaited<ReturnType<ShowbackSummaryModel['getSummaryPageData']>> | undefined>(undefined);
    public loading = new EventEmitter<boolean>(true);

    public constructor(
        @inject(ShowbackPersistence) private readonly showbackSvc: ShowbackPersistence,
        @inject(InvoiceApiService) private readonly invoiceApi: InvoiceApiService
    ) {}

    public setSbModel(showbackModel: ShowbackModel) {
        this.showbackModel = showbackModel;
        return this;
    }

    public init() {
        const { dispose } = this.showbackModel.selectedMonthChanged.listen(this.updateData);
        this.updateData();
        return dispose;
    }

    private updateData = async () => {
        const reqKey = ++this.requestKey;

        this.loading.emit(true);
        try {
            const month = this.showbackModel.selectedMonthChanged.value;
            const allocDim = this.showbackModel.selectedAllocationDimension;
            if (!month || !allocDim) {
                this.dataChanged.emit(undefined);
            } else {
                const results = await this.getSummaryPageData(month, allocDim);
                if (reqKey === this.requestKey) {
                    this.dataChanged.emit(results);
                }
            }
        } finally {
            this.loading.emit(false);
        }
    };

    private async getSummaryPageData(month: Date, allocDim: AllocationDimension) {
        const qb = this.showbackSvc.getMetricQb();

        const costField = await this.showbackSvc.getCostField(month);

        const statsQuery = this.showbackSvc.getAllocationStats(month, allocDim, costField, 'unallocated');

        const allocDimSizesQuery = qb
            .select((b) => ({
                dim: b.model[this.showbackSvc.getDimensionInvoiceField(allocDim)],
                amount: b.sum(costField.field),
            }))
            .execute((q) => this.invoiceApi.queryByUsageMonth(q, month));

        const [statsResp, dimSizeResp] = await Promise.all([statsQuery, allocDimSizesQuery]);

        return {
            stats: statsResp?.Results?.[0],
            dimSizes: dimSizeResp?.Results ?? [],
        };
    }
}

import { Address, Company, CompanyTypeRequest, RoleCompanyType } from '@apis/Customers/model';
import { useEffect, useRef, useState } from 'react';
import { useDi } from '@root/Services/DI';
import {
    Box,
    Button,
    Card,
    Center,
    Checkbox,
    Divider,
    Grid,
    Group,
    Loader,
    MultiSelect,
    Select,
    Space,
    Text,
    TextInput,
    ThemeIcon,
    Tooltip,
    useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { MspService } from '@root/Services/MspService';
import { CompanyContextService } from '@root/Services/Customers/CompanyContext';
import { AuthorizationService, useAuthZValues } from '@root/Services/AuthorizationService';
import { postNewSubscriptionUpdateCompanyInfo, putCompanyAdminRegisterCompany, putCompanyUpdateCompanyTypeById } from '@apis/Customers';
import { CompanyTenantPrereqService } from '../Router/CompanyContent';
import { JobService } from '@root/Services/Jobs/JobService';
import { CompanyRoleService } from '@root/Site/Settings/Users/CompanyRoleService';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { postNotificationSendNotification } from '@apis/Notification';
import { CompanyAddress } from '../CompanyInfo/CompanyAddress';
import { AlertTriangle, Circle, CircleCheck, CircleOff, CircleTriangle } from 'tabler-icons-react';
import styled from '@emotion/styled';

type NewUser = { email: string; roles: number[]; userId: number };

enum RegistrationStep {
    Register = 1,
    Provision,
    Update,
    Invite,
    Completed,
}

interface RegistrationStatus {
    step: RegistrationStep;
    label: string;
}

export function AddCompanyInfo({ onClose, onSubmit }: { onClose: () => void; onSubmit: (companyId: number) => void }) {
    const mspSvc = useDi(MspService);
    const authSvc = useDi(AuthorizationService);
    const contextSvc = useDi(CompanyContextService) as CompanyContextService<Company>;
    const tenantSvc = useDi(CompanyTenantPrereqService);
    const jobSvc = useDi(JobService);
    const theme = useMantineTheme();
    const [loading, setLoading] = useState(false);
    const defaultUser = { email: '', roles: [], userId: 0 };
    const [roles, setRoles] = useState([] as { value: number; label: string }[]);
    const [registrationStep, setRegistrationStep] = useState<RegistrationStep>(RegistrationStep.Register);
    const [registrationFailed, setRegistrationFailed] = useState(false);
    const [company, setCompany] = useState<Company>();
    const companyTypes = ['Customer', 'Msp', 'Support'];
    const companyRoleSvc = useDi(CompanyRoleService);
    const { canChangeType } = useAuthZValues({ canChangeType: { SupportCompany: 'Manage' } });
    const emailRegEx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const authNSvc = useDi(AuthenticationService);
    const user = authNSvc.user;
    const userId = user?.ExternalId!;
    const registrationStatuses: RegistrationStatus[] = [
        { step: RegistrationStep.Register, label: 'Creating company' },
        { step: RegistrationStep.Provision, label: 'Provisioning company' },
        { step: RegistrationStep.Update, label: 'Updating company details' },
        { step: RegistrationStep.Invite, label: 'Inviting users' },
        { step: RegistrationStep.Completed, label: 'Completed' },
    ];
    const userRoleSelectRef = useRef<HTMLDivElement>(null);

    const setRolesByType = async (companyType?: RoleCompanyType) => {
        const roles = await companyRoleSvc.getRolesByType(companyType);
        roles.sort((a, b) => a.Role?.Name?.localeCompare(b.Role?.Name ?? '') || a.Role?.Id! - b.Role?.Id!);
        setRoles(roles.map((role) => ({ value: role.Role!.Id!, label: role.Role!.Name! })));
    };

    const handleCompanySet = async () => {
        let disposer = () => {};
        try {
            setLoading(true);
            setRegistrationStep(RegistrationStep.Register);
            const company = await putCompanyAdminRegisterCompany({
                companyName: form.values.companyName,
                companyWebsite: form.values.companyWebsite,
            });
            setCompany(company);
            if (company && company.Id && company.ProvisionJobId) {
                if (canChangeType) {
                    await putCompanyUpdateCompanyTypeById({ CompanyId: company.Id, Type: form.values.companyType } as CompanyTypeRequest);
                }

                setRegistrationStep(RegistrationStep.Provision);
                disposer = tenantSvc.setCompanyId(company.Id);
                const provisionProgress = await jobSvc.waitForJobHierarchyByJobId(company.ProvisionJobId);
                await provisionProgress.poller;
                setRegistrationStep(RegistrationStep.Update);
                await updateCompanyInfo(company.Id);
                setRegistrationStep(RegistrationStep.Invite);
                if (form.values.users && form.values.users.length > 0) {
                    await inviteUsers(company.Id, form.values.users);
                }
                setRegistrationStep(RegistrationStep.Completed);
                authSvc.reloadPermissions();
                mspSvc.notifyCompanyListChanged();
            } else {
                setRegistrationFailed(true);
            }
        } catch (error) {
            setRegistrationFailed(true);
        } finally {
            disposer();
        }
    };

    const updateCompanyInfo = async (companyId: number) => {
        let billingAddress: Address | undefined = undefined;

        if (form.values.AddressLine1.length > 0) {
            billingAddress = {
                AddressLine1: form.values.AddressLine1,
                AddressLine2: form.values.AddressLine2,
                City: form.values.City,
                StateCode: form.values.StateCode,
                ZipCode: form.values.ZipCode,
                CountryCode: form.values.CountryCode,
            };
        }

        if (billingAddress) {
            await postNewSubscriptionUpdateCompanyInfo({
                BillingAddress: billingAddress,
                CompanyId: companyId,
            });
        }
    };

    const inviteUsers = async (companyId: number, users: NewUser[]) => {
        await Promise.all(
            users.map((user) =>
                postNotificationSendNotification(
                    { companyId, userId, WelcomeInviteData: [{ emails: user.email, roles: user.roles }] },
                    { notificationType: 'Welcome' }
                )
            )
        );
    };

    const form = useForm({
        initialValues: {
            companyName: '',
            companyType: RoleCompanyType.Customer,
            companyWebsite: '',
            AddressLine1: '',
            AddressLine2: '',
            City: '',
            CountryCode: '',
            StateCode: '',
            ZipCode: '',
            users: [] as NewUser[],
        },
        validate: {
            companyName: (value) =>
                value.length > 50
                    ? 'Max length for company name is 50.'
                    : value.replace(/[^0-9a-z]/gi, '').length < 3
                    ? 'Company name must contain at least 3 letters or numbers.'
                    : null,
            users: {
                email: (value) => value.length > 0 && (emailRegEx.test(value) ? null : 'Invalid email address.'),
                roles: (values) => (values.length > 0 ? null : 'Role assignment required'),
            },
            companyType: (value) => (value.length > 0 ? null : 'Company type is required.'),
            AddressLine1: () => (!isValidAddress('AddressLine1') ? 'Address Line 1 is required!' : null),
            City: () => (!isValidAddress('City') ? 'City is required!' : null),
            CountryCode: () => (!isValidAddress('CountryCode') ? 'Country is required!' : null),
            StateCode: () => (!isValidAddress('StateCode') ? 'State is required!' : null),
            ZipCode: () => (!isValidAddress('ZipCode') ? 'Zip Code is required!' : null),
        },
    });

    useEffect(() => {
        (async () => {
            setRolesByType(form.values.companyType);
        })();
    }, [form.values.companyType]);

    const isValidAddress = (field: string) => {
        let isValid: boolean;
        type fieldLength = { field: string; length: number };
        let fieldMap: fieldLength[] = [];

        fieldMap.push({ field: 'AddressLine1', length: form.values.AddressLine1.length });
        fieldMap.push({ field: 'City', length: form.values.City.length });
        fieldMap.push({ field: 'StateCode', length: form.values.StateCode.length });
        fieldMap.push({ field: 'ZipCode', length: form.values.ZipCode.length });
        fieldMap.push({ field: 'CountryCode', length: form.values.CountryCode.length });

        let thisField = fieldMap.filter((f) => f.field === field);
        let completedFields = fieldMap.filter((f) => f.length > 0);

        isValid = completedFields.length === 0 || (completedFields.length > 0 && thisField.length > 0 && thisField[0].length > 0);

        if (!isValid) {
            form.setFieldError(field, `${field} is a required field.`);
        }

        return isValid;
    };

    const handleSubmit = async (e: any) => {
        if (e) {
            e.preventDefault();
        }

        const validationResult = form.validate();
        const userRolesHasError = UserRolesHasError();

        if (!validationResult.hasErrors && !userRolesHasError) {
            await handleCompanySet();
        }
    };

    const UserRolesHasError = () => {
        let hasError = false;
        for (let i = 0; i < form.values.users.length; i++) {
            if (form.values.users[i].email && form.values.users[i].email.length > 0 && form.values.users[i].roles.length === 0) {
                form.setFieldError(`users.${i}.roles`, 'Role assignment required');
                hasError = true;
            }
        }
        return hasError;
    };

    const onCompanyCreated = () => {
        !company || registrationStep != RegistrationStep.Completed ? onClose() : onSubmit(company.Id!);
    };

    const getStatusState = (step: RegistrationStep) => {
        if (step < registrationStep) {
            return (
                <ThemeIcon variant="light">
                    <CircleCheck color={theme.colors.success[6]} />
                </ThemeIcon>
            );
        } else if (step === registrationStep) {
            if (registrationFailed) {
                return (
                    <ThemeIcon variant="light">
                        <AlertTriangle color={step < 3 ? theme.colors.error[6] : theme.colors.warning[6]} />
                    </ThemeIcon>
                );
            } else {
                if (step === RegistrationStep.Completed) {
                    return (
                        <ThemeIcon variant="light">
                            <CircleCheck color={theme.colors.success[6]} />
                        </ThemeIcon>
                    );
                } else return <Loader color="primary.6" size={'xs'} />;
            }
        } else
            return (
                <ThemeIcon variant="light">
                    <Circle color={theme.colors.gray[6]} />
                </ThemeIcon>
            );
    };

    const gridRow = (status: RegistrationStatus) => {
        return (
            <>
                <Grid.Col span={2}>
                    <Center>{getStatusState(status.step)}</Center>
                </Grid.Col>
                <Grid.Col span={10}>
                    <Text>{status.label}</Text>
                </Grid.Col>
            </>
        );
    };

    const registerCompanyProgress = () => {
        const finished = registrationStep === RegistrationStep.Completed || registrationFailed;
        const errorMessage =
            registrationStep < 3 ? 'Errors occurred during the process.' : 'The company was created, but errors occurred during the process.';
        return (
            <>
                <Box>
                    <Divider />
                    <Space h="md" />
                    <Grid>{registrationStatuses.map((status) => gridRow(status))}</Grid>
                    {registrationFailed && (
                        <Box px={'md'} pt={'md'}>
                            <Text>{errorMessage}</Text>
                        </Box>
                    )}
                    <Group p="lg" position="right">
                        <Button disabled={!finished} onClick={onCompanyCreated} type="submit">
                            OK
                        </Button>
                    </Group>
                </Box>
            </>
        );
    };

    const addCompanyForm = () => {
        return (
            <Box>
                <Divider />
                <form style={{ overflow: 'auto', height: '500px' }}>
                    <Box p="md">
                        <Text size="sm" weight={700}>
                            Company Information
                        </Text>
                        <Space h="xs" />
                        <Card sx={{ backgroundColor: theme.colors?.gray?.[2] }} radius="md">
                            <TextInput
                                label="Company Name"
                                placeholder="My Company"
                                {...form.getInputProps('companyName')}
                                required
                                maxLength={50}
                                onBlur={() => form.validate()}
                            />
                            {canChangeType && (
                                <>
                                    <Space h="xs" />
                                    <Select
                                        withinPortal={true}
                                        data-atid="CompanyTypeSelect"
                                        placeholder="Select Company Type"
                                        searchable={true}
                                        label="Company Type"
                                        required={true}
                                        data={companyTypes}
                                        {...form.getInputProps('companyType')}
                                    ></Select>
                                </>
                            )}
                            <Space h="xs" />
                            <TextInput label="Company Website" placeholder="www.mycompany.com" {...form.getInputProps('companyWebsite')} />
                            <Space h="xs" />
                            <CompanyAddress prefix="" form={form} isWithinPortal={true} />
                        </Card>
                        <Space h="xs" />
                        <Text size="sm" weight={700}>
                            Users
                        </Text>
                        {form.values.users.map((user, index) => (
                            <Box key={index}>
                                <Space h="xs" />
                                <Card sx={{ height: '225px', backgroundColor: theme.colors?.gray?.[2] }} radius="md">
                                    <RemoveButton onClick={() => form.removeListItem('users', index)}>- Remove User</RemoveButton>

                                    <TextInput
                                        label="E-Mail Address"
                                        placeholder="myemail@myprovider.com"
                                        {...form.getInputProps(`users.${index}.email`)}
                                    />
                                    <Space h="xs" />
                                    <MultiSelect
                                        label="Assign Roles"
                                        searchable={true}
                                        data={roles.map((i) => {
                                            return { label: i.label, value: i.value };
                                        })}
                                        {...form.getInputProps(`users.${index}.roles`)}
                                    />
                                </Card>
                            </Box>
                        ))}
                        <AddButton onClick={() => form.insertListItem('users', defaultUser)}>+ Add User</AddButton>
                    </Box>
                </form>
                <Divider />
                <Group p="lg" position="right" sx={{ background: theme.colors?.gray?.[2], borderRadius: `0 0 4px 4px` }}>
                    <Button variant="outline" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} type="submit">
                        Add Company
                    </Button>
                </Group>
            </Box>
        );
    };

    return (
        <Box mx={-20} mb={-20}>
            {loading ? registerCompanyProgress() : addCompanyForm()}
        </Box>
    );
}

const AddButton = styled.div`
    color: ${(p) => p.theme.colors.primary[6]};
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
    margin-left: 16px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

const RemoveButton = styled.div`
    color: ${(p) => p.theme.colors.error[6]};
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

import { container, inject, singleton } from 'tsyringe';
import { ConfigService } from './ConfigService';

export type ResponseHandler = (response: Response, request: RequestInit, url: string) => Promise<{ retry: boolean }>;

export type ServceError = Error & { response: Response };

@singleton()
export class BasicApi {
    private prerequestHandlers = new Set<(url: string, request: RequestInit) => void>();
    private responseHandlers: ResponseHandler[] = [];
    private maxTries = 5;
    private downloadAnchor?: HTMLAnchorElement;

    public constructor(@inject(ConfigService) private configSvc: ConfigService) {}

    private getDownloadAnchor() {
        if (!this.downloadAnchor) {
            this.downloadAnchor = document.createElement('a');
            document.body.append(this.downloadAnchor);
        }
        return this.downloadAnchor;
    }

    public async download(fileName: string, config: IRequestInfo, type: string) {
        const response = await this.getResponse(config, type);
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        const anchor = this.getDownloadAnchor();
        anchor.href = objectUrl;
        anchor.download = fileName;
        anchor.click();
    }

    public async request<T>(config: IRequestInfo, type: string): Promise<T> {
        const response = await this.getResponse<T>(config, type);
        const responseText = await response.text();
        if (response.status >= 400) {
            const err = new Error('Error processing request');
            (err as ServceError).response = response;
            throw err;
        } else if (response.status === 204 || !responseText) {
            return undefined as unknown as T;
        } else {
            return JSON.parse(responseText) as T;
        }
    }

    private async getResponse<T>(config: IRequestInfo, type: string) {
        const { url, request } = this.createRequest(config, type);
        const requestor = async () => await fetch(url, request);
        const response = await this.executeRequest(requestor, url, request);
        return response;
    }

    public registerResponseHandler(handler: ResponseHandler) {
        this.responseHandlers.push(handler);
    }

    public registerPrerequestHandler(handler: (url: string, request: RequestInit) => void) {
        this.prerequestHandlers.add(handler);
        return () => {
            this.prerequestHandlers.delete(handler);
        };
    }

    public createRequest(config: IRequestInfo, type: string) {
        const params = this.getParams(config.params);
        const url = this.getUrl(type, config.url) + params;

        if (config.data instanceof FormData) {
            delete config.headers['Content-Type'];
        }

        const requestBody: RequestInit = {
            body: this.getBody(config.data),
            method: config.method,
            headers:
                config.data instanceof FormData
                    ? {
                          'X-Requested-With': 'XMLHttpRequest',
                          ...config.headers,
                      }
                    : {
                          'Content-Type': 'application/json',
                          'X-Requested-With': 'XMLHttpRequest',
                          ...config.headers,
                      },
            credentials: 'include',
        };

        for (const handler of this.prerequestHandlers) {
            handler(url, requestBody);
        }
        return { request: requestBody, url };
    }

    private async executeRequest(requestor: () => Promise<Response>, url: string, request: RequestInit) {
        let response = await requestor();
        for (let tries = 1; tries <= this.maxTries; tries++) {
            let retry = false;
            for (const handler of this.responseHandlers) {
                const handlerResult = await handler(response, request, url);
                if (handlerResult.retry) {
                    retry = true;
                    break;
                }
            }
            if (!retry) {
                return response;
            } else {
                response = await requestor();
            }
        }
        return response;
    }

    private getUrl(serviceType: string, url: string) {
        const baseUrl = this.configSvc.config.apis[serviceType]?.baseUrl;
        if (!baseUrl) throw new Error(`No base URL is configured for ${serviceType}`);

        return baseUrl + url;
    }

    private getBody(data?: any) {
        if (data instanceof FormData) return data;
        if (data === undefined) {
            return null;
        } else {
            return JSON.stringify(data);
        }
    }

    private getParams(params?: any) {
        const urlParams = new URLSearchParams();
        if (params) {
            for (const key in params) {
                const values = params[key] instanceof Array ? params[key] : [params[key]];
                for (const value of values) {
                    urlParams.append(key, value);
                }
            }
        }
        const result = urlParams.toString();
        return result ? `?${result}` : '';
    }
}

interface IRequestInfo {
    url: string;
    method: string;
    signal?: AbortSignal;
    data?: any;
    headers?: any;
    params?: any;
}
interface IServiceType {
    type: string;
}

export function request<TResponse>(config: IRequestInfo, type: IServiceType): Promise<TResponse> {
    const basicApi = container.resolve(BasicApi);
    return basicApi.request(config, type.type);
}

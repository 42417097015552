import { observer } from 'mobx-react';
import { AllocationRuleEditor } from '../Model';
import { useCallback, useEffect, useMemo } from 'react';
import { AllocationSources } from '../AllocationSources';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { IMiniDataGridProps, MiniDataGrid, MiniDataGridItem } from '../MiniDataGrid';
import { BaseAllocationRuleModel } from './BaseAllocationRuleModel';
import { DisbursementTarget, FundSource, NamedFilterSet } from '@apis/Invoices/model';
import { LineItemFundSource } from '../../Models';
import { AdvancedAllocSettingsDrawer } from '../AdvancedAllocSettings/AdvancedAllocSettingsDrawer';
import { AllocSettingsEditRequest, TargetEditRequest } from '../AdvancedAllocSettings/Models';
import { Group, Button, Space } from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import { EditorCardWhiteWrap, EditorCard } from '../Design';

export const AdvancedRuleCard = observer(function AdvancedRuleCard({ ruleEditor }: { ruleEditor: AllocationRuleEditor }) {
    const model = useMemo(() => new AdvancedRuleModel(ruleEditor), [ruleEditor.rule]);

    return (
        <>
            <AllocationSourcesSection model={model} />
            <DisbursementTargetSection model={model} />
            <AdvancedAllocSettingsDrawer
                allocationDimension={ruleEditor.allocDim}
                schemaSvc={ruleEditor.schemaSvc}
                month={ruleEditor.month}
                editRequest={model.editRequest}
            />
        </>
    );
});

class AdvancedRuleModel extends BaseAllocationRuleModel {
    private readonly defaultQueryFields = ['lineItem/UsageAccountName', 'lineItem/LineItemType', 'product/ProductName', 'product/region'];
    public editRequest = new EventEmitter<AllocSettingsEditRequest>(undefined);

    public constructor(ruleEditor: AllocationRuleEditor) {
        super(ruleEditor);
        this.defaultQueryFields.push(ruleEditor.showbackSvc.getDimensionInvoiceField(ruleEditor.allocDim));
    }

    public createEditRequest(fundSource: FundSource) {
        return {
            source: fundSource,
            onApply: (updatedSource: FundSource) => {
                const sources = this.getSources();
                if (!sources.includes(fundSource)) {
                    this.addSource(updatedSource);
                } else {
                    const index = sources.indexOf(fundSource);
                    if (index !== -1) {
                        sources.splice(index, 1, updatedSource);
                    }
                }
                this.sourcesChanged.emit();
            },
        };
    }

    public createNewSource() {
        const item = this.createFundSource('LineItems') as LineItemFundSource;
        item.Filters = this.createUfDefaultFilters();
        return this.createEditRequest(item as FundSource);
    }

    private createUfDefaultFilters(): NamedFilterSet {
        return {
            InclusionRules: [
                {
                    Filter: {
                        Operation: 'and',
                        Operands: this.defaultQueryFields.map((f) => ({ Operation: 'eq', Operands: [{ Field: f }] })),
                    },
                },
            ],
        };
    }

    public createEditRequestForTarget(disbursementTarget: DisbursementTarget) {
        return {
            target: disbursementTarget,
            onApply: (updatedTarget: DisbursementTarget) => {
                const targets = this.getTargets();
                const index = targets.indexOf(disbursementTarget);
                if (index !== -1) {
                    targets.splice(index, 1, updatedTarget);
                }
                this.targetsChanged.emit();
            },
        };
    }

    public addNewTarget = () => {
        const target = this.createDisbursementTarget('Existing') as DisbursementTarget;
        target.UsageStatFilter = this.createUfDefaultFilters();
        target.TargetExistingFilter = this.createUfDefaultFilters();

        this.editRequest.emit({
            target,
            onApply: (updatedTarget: DisbursementTarget) => {
                this.addTarget(updatedTarget);
                this.targetsChanged.emit();
            },
        });
    };

    public getTargetGridItems() {
        return this.getTargets().map(
            (t) =>
                ({
                    getName: () => t.Name,
                    getAmount: () => undefined,
                    remove: () => {
                        this.removeTarget(t);
                    },
                    select: () => this.editRequest.emit(this.createEditRequestForTarget(t)),
                } as MiniDataGridItem)
        );
    }

    private removeTarget(target: DisbursementTarget) {
        const targets = this.getTargets();
        const index = targets.indexOf(target);
        if (index !== -1) {
            targets.splice(index, 1);
            this.targetsChanged.emit();
        }
    }
}

function AllocationSourcesSection({ model }: { model: AdvancedRuleModel }) {
    const addSourceButtons = useMemo(
        () => [
            {
                label: 'Add Allocation Source',
                onClick: () => model.editRequest.emit(model.createNewSource()),
            },
        ],
        []
    );

    const sourceListProps = useMemo(
        () =>
            ({
                nameHeader: 'Source',
                amountHeader: 'Amount',
                data: [],
                displayTotal: true,
            } as IMiniDataGridProps),
        []
    );

    useEffect(() => {
        const disposer = model.sourceSelected.listen((source) => {
            if (source) {
                model.editRequest.emit(model.createEditRequest(source));
            }
        });
        return () => disposer?.dispose();
    }, [model.editRequest]);

    const sourceGridData = useCallback(() => model.getSourceGridItems((s) => s.Name ?? ''), []);
    return (
        <AllocationSources gridProps={sourceListProps} sourceChanged={model.sourcesChanged} gridData={sourceGridData} buttons={addSourceButtons} />
    );
}

function DisbursementTargetSection({ model }: { model: AdvancedRuleModel }) {
    const gridProps = useMemo(
        () =>
            ({
                amountHeader: '',
                displayTotal: false,
                nameHeader: 'Targets',
            } as IMiniDataGridProps),
        []
    );
    const data = model.getTargetGridItems();
    useEvent(model.targetsChanged);
    return (
        <EditorCardWhiteWrap>
            <EditorCard title="Disbursement Targets" color="gray" info="" wrapped>
                {data?.length ? (
                    <>
                        <MiniDataGrid {...gridProps} data={data}></MiniDataGrid>
                        <Space h="md" />
                    </>
                ) : null}

                <Group>
                    <Button variant="outline" radius="xl" size="sm" leftIcon={<Plus size={16} />} onClick={model.addNewTarget}>
                        Add Disbursement Target
                    </Button>
                </Group>
            </EditorCard>
        </EditorCardWhiteWrap>
    );
}

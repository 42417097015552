import styled from '@emotion/styled';
import { colorPalette, CustomColors } from '@root/Design/Themes';

export const GridContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    --grid-cell-padding: ${(p) => p.theme.spacing.sm}px;
    font-size: ${(p) => p.theme.fontSizes.sm}px;
    table,
    thead,
    tr,
    td,
    th {
        border-spacing: 0;
        border-collapse: collapse;
        margin: 0;
        padding: 0;
    }
`;

export const GridToolbar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

export const ListBodyContainer = styled.div<{
    mode: 'footer' | 'none';
    height?: number | undefined;
    radius?: number | undefined;
}>`
    flex: 1;
    overflow: hidden;

    tr {
        background: ${(p) => p.theme.white};
    }
    border: solid 1px ${(p) => p.theme.colors.gray[3]};
    ${(p) => (p.mode === 'footer' ? 'border-width: 0 1px;' : '')}
    border-radius: 0 0 ${(p) => p.theme.radius.md}px ${(p) => p.theme.radius.md}px;
`;

export const GridBodyContainer = styled.div<{
    mode: 'footer' | 'none';
    height?: number | undefined;
    radius?: number | undefined;
}>`
    flex: ${(p) => (p.height ? '0' : '1')};
    min-height: ${(p) => (p.height ? p.height + 'px' : '0')};
    overflow: hidden;
    border: solid 1px #0002;
    border-radius: ${(p) => (p.mode === 'footer' ? '0' : p.radius !== undefined ? p.radius : '0 0 7px 7px')};
    background: ${(p) => p.theme.white};
`;

export const GridFooterContainer = styled.div<{ position: 'top' | 'bottom' }>`
    flex-shrink: 0;
    overflow: hidden;
    border: solid 1px #0002;
    border-width: ${(p) => (p.position === 'bottom' ? '0 1px 1px 1px' : '1px 1px 0 1px')};
    border-radius: ${(p) => (p.position === 'bottom' ? '0 0 7px 7px' : '0')};
    background: ${(p) => p.theme.white};
`;

export const FixedColumnSet = styled.div`
    transform: translateY(var(--tree-top-buffer));
    position: absolute;
    left: var(--grid-horz-scroll);
    box-shadow: 0 0 var(--grid-fixed-shadow) #8886;
`;
export const ScrollingColumnSet = styled.div`
    transform: translateY(var(--tree-top-buffer));
    clip-path: inset(0 0 0 var(--grid-horz-scroll));
    border-right: solid 1px ${(p) => p.theme.colors.gray[3]};
`;

export const GridFullCell = styled.div`
    margin: 0 calc(-1 * var(--grid-cell-padding));
    line-height: var(--grid-item-height);
    height: var(--grid-item-height);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 var(--grid-cell-padding);
`;

export const GridFlexCell = styled(GridFullCell)<{ align?: 'left' | 'right' | 'center' | 'apart' }>`
    display: flex;
    align-items: center;
    justify-content: ${(p) =>
        p.align === 'apart' ? 'space-between' : p.align === 'center' ? 'center' : p.align === 'right' ? 'flex-end' : 'flex-start'};
`;

export const GridCell = styled.div<{ noBorder?: boolean }>`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: var(--grid-item-height);
    box-sizing: border-box;
    padding: 0 var(--grid-cell-padding);
    line-height: var(--grid-item-height);
    border-bottom: solid ${(p) => (p.noBorder ? '0' : '1px')} ${(p) => p.theme.colors.gray[3]};
    .no-animation * {
        transition: none;
    }
`;

export const GridExpander = styled.div<{ mode: 'expanded' | 'collapsed'; depth: number }>`
    display: inline-block;
    transition: all 0.5s;
    cursor: pointer;
    transform: rotate(${(p) => (p.mode === 'expanded' ? '90deg' : '0')});
    text-align: center;
    margin-left: ${(p) => p.depth * 25 - p.theme.spacing.sm}px;
    min-width: 25px;
`;

export const GridRow = styled.tr<{ mode?: 'clickable' | 'selectable'; highlighted?: 'highlight'; hovered?: 'hovered'; highlightColor?: string }>`
    cursor: ${(p) => (p.mode ? 'pointer' : undefined)};
    background: ${(p) =>
        p.highlighted === 'highlight'
            ? p.highlightColor ?? p.theme.colors.gray[2]
            : p.hovered
            ? (p.highlightColor ?? p.theme.colors.gray[4]) + '40'
            : 'none'};
`;

export const HeaderContainer = styled.div<{ mode: 'grid' | 'list' }>`
    border-radius: ${(p) => (p.mode === 'grid' ? 7 : p.theme.radius.md)}px ${(p) => (p.mode === 'grid' ? 7 : p.theme.radius.md)}px 0 0;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: ${(p) => (p.mode === 'grid' ? '#0002' : p.theme.colors.gray[3])};
    background-color: ${(p) => p.theme.white};
`;

export const GridTitleContainer = styled.div`
    border-width: 1px 1px 0 1px;
    border-style: solid;
    padding: 0 ${(p) => p.theme.spacing.md}px;
    border-color: ${(p) => p.theme.colors.gray[3]};
    background-color: ${(p) => p.theme.colors.primary[2]};
    height: 30px;
    display: flex;
    align-items: center;
`;

export const GridHeaderGroupCell = styled.div<{ noOverlay?: boolean }>`
    height: var(--grid-header-row-height);
    position: absolute;
    text-align: center;
    font-weight: bold;
    background-color: var(--grid-group-color);
    width: var(--grid-group-width);
    left: var(--grid-group-offset);
    > div {
        padding: 0 ${(p) => p.theme.spacing.xs}px;
        border-color: var(--grid-group-color);
        background-color: ${(p) => (p.noOverlay ? 'transparent' : colorPalette.fff9Color)};
        border-style: solid;
        border-width: 0 1px;
        line-height: var(--grid-header-row-group-height);
        --grid-group-visible-left: calc(var(--grid-horz-scroll) + var(--grid-set-offset));
        --grid-group-visible-right: calc(var(--grid-horz-scroll) + var(--grid-set-visible-width) + var(--grid-group-visible-left));
        --grid-group-left-trim: max(calc(var(--grid-horz-scroll) - var(--grid-group-offset)), 0px);
        --grid-group-right-trim: max(
            calc(var(--grid-group-offset) + var(--grid-group-width) - var(--grid-horz-scroll) - var(--grid-set-visible-width)),
            0px
        );
        --grid-group-visible-width: calc(
            var(--grid-group-width) - var(--grid-group-left-trim) - var(--grid-group-right-trim) - ${(p) => p.theme.spacing.xs * 2}px
        );

        span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
        }
    }
    &.groups-scrollable > div {
        span {
            width: var(--grid-group-visible-width);
            margin-left: var(--grid-group-left-trim);
        }
    }
`;

export const GridHeaderCell = styled.div`
    overflow: hidden;
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: #0002;
    box-sizing: border-box;
    font-weight: bold;
    * {
        animation: none;
    }
    .opener {
        display: none;
        height: 15px;
        position: absolute;
        right: 0.5rem;
        top: 8px;
        display: inline;
    }
    &:hover {
        background-color: ${(p) => p.theme.colors.gray[3]};
        background: linear-gradient(to bottom, #0002 1px, ${(p) => p.theme.colors.gray[3]} 0%);
    }
`;
export const GridHeaderCellNoFilter = styled.div`
    overflow: hidden;
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: #0002;
    box-sizing: border-box;
    font-weight: bold;
    * {
        animation: none;
    }
    .opener {
        display: none;
        height: 15px;
        position: absolute;
        right: 0.5rem;
        top: 8px;
    }
    &:hover {
        background: ${(p) => p.theme.colors.gray[3]};
    }
`;

export const ListHeaderCellContainerEl = styled.div``;

export const ListHeaderCell = styled.div``;

export const GridHeaderCellResizer = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 20px;
    user-select: none;
    cursor: col-resize;
    &:hover {
        border-right: solid 3px #0002;
    }
`;

export const GridHeaderCellText = styled.div`
    cursor: pointer;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 var(--grid-cell-padding);
`;

export const ListHeaderCellText = styled.div`
    overflow: hidden;
    cursor: pointer;
    position: relative;
    padding: 0 var(--grid-cell-padding);
    .opener {
        display: none;
        height: 15px;
        position: absolute;
        right: -5px;
    }
    &:hover {
        .opener {
            display: inline;
        }
        .header-text {
            padding-right: 2rem;
        }
    }
`;

export const ListHeaderContainer = styled.div<{ mode?: 'some-fixed' }>`
    .groups {
        display: none;
    }
    .headers {
        display: flex;
        align-items: center;
    }
`;

export const GridHeaderContainerFixed = styled.div<{ mode?: 'some-fixed' }>`
    overflow: hidden;
    min-width: calc(var(--grid-set-width) + 1px);
    height: var(--grid-header-height);
    position: relative;

    .headers,
    .groups {
        position: relative;
        height: var(--grid-header-row-height);
    }
`;
export const GridHeaderContainerScroll = styled.div<{ mode?: 'some-fixed' }>`
    overflow: hidden;
    position: relative;
    flex: 1;
    height: var(--grid-header-height);
    .headers,
    .groups {
        margin-left: var(--grid-horz-scroll-neg);
        position: relative;
        height: var(--grid-header-row-height);
    }
    .groups {
        height: var(--grid-header-row-group-height);
    }
    .headers:before {
        height: 100%;
        content: '';
        width: 1px;
        box-shadow: 0 0 var(--grid-fixed-shadow) #000;
        top: 0;
        position: absolute;
        left: var(--grid-horz-scroll);
        margin-left: -1px;
    }
`;

export const ListHeaderSetContainer = styled.div<{ hideHeader: boolean }>`
    padding: 9px 17px 9px 0;
    border-color: ${(p) => p.theme.colors.gray[3]};
    border-width: ${(p) => (p.hideHeader ? 0 : '1px')} 0 0 0;
    border-style: solid;
    background: ${(p) => p.theme.colors.gray[2]};
`;

export const GridHeaderSetContainer = styled.div<{ hideHeader: boolean }>`
    display: flex;
    flex: 0;
    align-items: flex-end;
    .headers {
        border-width: ${(p) => (p.hideHeader ? 0 : '1px')} 0 0 0;
        border-color: #0002;
        border-style: solid;
        background: ${(p) => p.theme.colors.gray[2]};
    }
`;

export const GridMenuItem = styled.a<{ mode?: 'selected' | 'disabled' }>`
    color: ${(p) => (p.mode === 'selected' ? p.theme.white : 'inherit')};
    opacity: ${(p) => (p.mode === 'disabled' ? 0.7 : 1)};
    cursor: ${(p) => (p.mode === 'disabled' ? 'not-allowed' : 'pointer')};
    background: ${(p) => (p.mode === 'selected' ? p.theme.colors[p.theme.primaryColor][5] : 'none')};
    padding-top: 4px;
    padding-bottom: 4px;
    &:hover {
        background: ${(p) =>
            p.mode === 'selected' ? p.theme.colors[p.theme.primaryColor][4] : p.mode === 'disabled' ? 'none' : p.theme.colors.gray[2]};
    }
`;

export const GridReorderableContainer = styled.div<{ mode: 'reordering' | 'resizing' | 'transition' }>`
    position: absolute;
    bottom: 0;
    transition: ${(p) => (p.mode === 'transition' ? '0.5s' : '0s')} all;
    user-select: none;
    background: ${(p) => (p.mode === 'reordering' ? p.theme.fn.rgba(p.theme.colors.gray[5], 0.3) : 'none')};
    ${(p) => (p.mode === 'reordering' ? 'z-index: 1;' : null)}
`;

export const CheckboxCell = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const GridTitleSection = styled.div`
    background-color: ${(p) => p.theme.colors.primary[2]};
    padding: 5px 15px;
    border-width: 0 1px;
    border: solid 1px #0002;
`;
export const GridBodySection = styled.div`
    border-width: 0 1px;
    border: solid 1px #0002;
`;

import {
    deleteUserDeleteUser,
    getMspGetCompaniesForMsp,
    getMspGetMspUserCompanies,
    getRbacGetCompanyRoles,
    getUserGetCompanyUsers,
    postMspSaveMspUserCompanies,
    postRbacSaveUserRoles,
    postUserSaveUserStatus,
    postUserSaveUserIncludeFutureCompanies,
} from '@apis/Customers';
import { DataGrid } from '@root/Components/DataGrid';
import { DataColumnConfig, DataGridState } from '@root/Components/DataGrid/Models';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Card, CloseButton, Divider, Group, Input, MultiSelect, Progress, Space, Switch, Text, Title } from '@mantine/core';
import { BulkUserRoleChange, Role, User, UserListItem } from '@apis/Customers/model';
import type { Company } from '@apis/Customers/model';
import { UserImage } from '@root/Components/Users/UserImage';
import { PagePanel, PanelBody, PanelContent, PaneledPage, PanelToolbar } from '@root/Design/Layout';
import { openConfirmModal, useModals } from '@mantine/modals';
import { useDi, useDiComponent, useDiContainer } from '@root/Services/DI';
import { inject, injectable } from 'tsyringe';
import { makeAutoObservable, toJS } from 'mobx';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { AnchorButton, Clearfix, ListBadge, ListBadgeCell } from '@root/Design/Primitives';
import { SettingsPage, SettingsPageHeader } from '../SettingsPage';
import { UserInviteModal } from '../UserInviteModal';
import { MailForward, Trash } from 'tabler-icons-react';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { CompanyRoleService } from './CompanyRoleService';
import { colorPalette } from '@root/Design/Themes';
import { Checkbox } from '@mantine/core';
import { postNotificationSendNotification } from '@apis/Notification';
import { MspService } from '@root/Services/MspService';

type UserStatus = (typeof UserStatus)[keyof typeof UserStatus];
const UserStatus = {
    Active: 'Active',
    Deactivated: 'Deactivated',
    Invited: 'Invited',
} as const;
export function UsersList() {
    const userPermissions = useAuthZValues({
        canManage: { User: 'Manage' },
    });
    const DiContainer = useDiComponent();
    const modals = useModals();
    const [users, setUsers] = useState<UserListItem[]>();
    const [selectedUser, setSelectedUser] = useState<UserListItem>();
    const [loading, setLoading] = useState(true);
    const state = useMemo(() => ({ roles: [] as string[] }), []);

    const defaultState = useMemo(() => {
        return {
            columns: [
                { id: 'Name', width: 300 },

                { id: 'Roles', width: 200 },

                { id: 'Status', width: 100 },
            ],

            filters: [{ Operation: 'ne', Operands: [{ Field: 'Status' }, { Value: 'Deactivated' }] }],

            sort: [{ Direction: 'Asc', Expr: { Field: 'Firstname' } }],
        } as DataGridState;
    }, [selectedUser]);

    const loadUsers = () => {
        setSelectedUser(undefined);
        setLoading(true);
        getUserGetCompanyUsers().then((users) => {
            setLoading(false);
            setUsers(users);
        });
        getRbacGetCompanyRoles().then((roles) => {
            state.roles.splice(0, Infinity);
            state.roles.push(...roles.map((r) => r.Role!.Name ?? ''));
        });
    };

    const onSave = () => {
        setSelectedUser(() => {
            loadUsers();
            return undefined;
        });
    };

    const onCancel = () => {
        setSelectedUser(undefined);
    };

    useEffect(loadUsers, []);

    const renderRole = (r: Role) => {
        return <ListBadge key={r.Name} text={r.Name ? r.Name : ''} colored />;
    };

    const columns = useMemo(
        () =>
            [
                {
                    id: 'Name',
                    header: 'Name',
                    accessor: (u) => u.FirstName + ' ' + u.LastName + ' ' + u.EMail,
                    defaultWidth: 300,
                    itemHeight: 100,
                    sortField: 'Name',
                    noSort: false,
                    filter: {
                        filterType: 'string',
                        name: 'Name',
                        filterField: 'Name',
                        options: {
                            getValueProvider: () =>
                                users?.map((u) => ({ value: u.FirstName + ' ' + u.LastName + ' ' + u.EMail, label: u.EMail ?? 'No Name' })),
                        },
                    },
                    exportOptions: {
                        renderer: (u) => u.EMail,
                    },
                    cellRenderer: (u) => {
                        return (
                            <Box sx={{ alignItems: 'center', display: 'flex', height: '100%' }}>
                                <div>
                                    <GridImageWrapper style={{ paddingLeft: 10 }}>
                                        <UserImage
                                            firstName={u?.FirstName ?? ''}
                                            lastName={u?.LastName ?? ''}
                                            email={u?.EMail ?? ''}
                                            picture={u?.Picture ?? ''}
                                            id={u?.Id ?? 0}
                                            diameter={48}
                                        />
                                    </GridImageWrapper>
                                </div>
                                <div>
                                    {(u.FirstName && u.LastName && u.FirstName.trim().length > 0 && u.LastName.trim().length > 0) || u.EMail ? (
                                        <Text weight="bold">{u.FirstName + ' ' + u.LastName}</Text>
                                    ) : (
                                        <Text weight="dim" italic>
                                            No Name
                                        </Text>
                                    )}

                                    <Text size="xs">{u.EMail}</Text>
                                </div>
                            </Box>
                        );
                    },
                },
                {
                    id: 'Roles',
                    header: 'Roles',
                    exportOptions: {
                        renderer: (u) => u.Roles?.map((r) => r.Name).join(', '),
                    },
                    cellRenderer: (u) => {
                        if (u.Roles?.toString() != '') {
                            const items = u.Roles?.reduce((result, r) => [...result, renderRole(r)], [] as ReactNode[]);
                            const result = items?.slice(0, 5);
                            if (items && result && items.length > 5) {
                                result.push(<ListBadge key="count" text={items.length - 5 + ' others'} />);
                            }
                            return <ListBadgeCell>{result && result.length ? result : 'None'}</ListBadgeCell>;
                        } else {
                            return <ListBadgeCell>None</ListBadgeCell>;
                        }
                    },
                    type: 'string',
                    noSort: true,
                    accessor: (item) => item.Roles?.map((r) => r.Name),
                    filter: {
                        filterType: 'string',
                        name: 'Roles',
                        filterField: 'Roles',
                        options: {
                            getValueProvider: () => state.roles.map((r) => ({ label: r, value: r })),
                        },
                    },
                    defaultWidth: 200,
                },
                {
                    id: 'Status',
                    header: 'Status',
                    accessor: 'Status',
                    cellRenderer: (u) => {
                        return <div>{u.Status}</div>;
                    },
                    defaultWidth: 100,
                    filter: {
                        filterType: 'string',
                        name: 'Status',
                        filterField: 'Status',
                        options: {
                            getValueProvider: () => ['Active', 'Deactivated', 'Invited'].map((s) => ({ label: s, value: s })),
                        },
                    },
                },
            ] as DataColumnConfig<UserListItem>[],
        [users]
    );
    const openUserInvitewModal = () => {
        const onClose = (didInvite: boolean) => {
            modals.closeModal(id);
            if (didInvite) {
                loadUsers();
            }
        };
        const id = modals.openModal({
            title: 'Add Users And Grant Permissions',
            children: (
                <DiContainer>
                    <UserInviteModal onClose={onClose}></UserInviteModal>
                </DiContainer>
            ),
        });
    };

    return (
        <SettingsPage>
            <PaneledPage>
                <PagePanel size="fill">
                    <SettingsPageHeader text="Users">
                        {userPermissions.canManage ? (
                            <Button data-atid="InviteUsersButton" onClick={openUserInvitewModal}>
                                Invite Users
                            </Button>
                        ) : (
                            <></>
                        )}
                    </SettingsPageHeader>
                    <PanelBody>
                        {!users ? (
                            <Progress />
                        ) : (
                            <DataGrid
                                statePersistence={{ key: 'UsersAdmin' }}
                                selectionMode="single"
                                onRowClick="select"
                                onSelectionChanged={setSelectedUser}
                                selection={selectedUser}
                                displayMode="list"
                                itemHeight={60}
                                state={defaultState}
                                dataSource={users!}
                                columns={columns}
                                exportName="Users"
                            />
                        )}
                    </PanelBody>
                </PagePanel>
                {!selectedUser ? null : (
                    <>
                        <Divider orientation="vertical" />
                        <PagePanel size="md">
                            <Group position="apart">
                                <Title py="md" px="lg" order={3}>
                                    User Details
                                </Title>
                                <CloseButton mr="lg" onClick={() => setSelectedUser(undefined)} />
                            </Group>
                            <Divider color="gray.3" />

                            <UserDetails key={selectedUser.Id} onSave={onSave} onDelete={loadUsers} user={selectedUser} onCancel={onCancel} />
                        </PagePanel>
                    </>
                )}
            </PaneledPage>
        </SettingsPage>
    );
}
endpoint('user-admin', UsersList, 'Users');
export interface RoleData {
    value: string;
    label: string;
}

@injectable()
class UserDetailsModel {
    public isLoading = true;
    public user: User = {};
    public company: Company = {};
    public allCustomers: Company[] = [];
    public selectedCompanies = new Set<number>();
    public initSelectedCompanies = new Set<number>();
    public status: UserStatus = 'Active';
    public existingRoles: string[] = [];
    public addedRoles: string[] = [];
    public removedRoles: string[] = [];
    public possibleRoles: RoleData[] = [];
    public isReadonly = true;
    public isDirty = false;
    public isActivated = false;
    public showCustomerPicker = false;
    public initSelectAll = false;
    public selectAll = false;
    public selectAllDisabled = false;
    public initIncludeFutureCompanies = false;
    public includeFutureCompanies = false;

    public constructor(
        @inject(ResourceService) private readonly resourceSvc: ResourceService,
        @inject(CompanyRoleService) private readonly companyRoleSvc: CompanyRoleService,
        @inject(NotificationService) private readonly notificationService: NotificationService
    ) {
        makeAutoObservable(this);
    }

    public async init(company: Company, user: UserListItem) {
        this.isLoading = true;
        try {
            this.loadCompany(company);
            if (company.Type === 'Msp') {
                this.showCustomerPicker = true;
                const customers = await getMspGetCompaniesForMsp();
                this.allCustomers = customers.sort((a, b) => a.CompanyName!.localeCompare(b.CompanyName!));
                const userCompanies = await getMspGetMspUserCompanies({ parentCompanyId: company.Id, userId: user.Id });
                this.selectedCompanies = new Set(userCompanies);
                this.initSelectedCompanies = new Set(userCompanies);
            }
            await this.loadRoles();
            this.loadUser(user);
        } finally {
            this.isLoading = false;
        }
    }

    public async loadUser(nextUser: UserListItem) {
        const user = makeAutoObservable({
            Id: 0,
            FirstName: '',
            LastName: '',
            Email: '',
            Picture: '',
            ExternalId: '',
            IncludeFutureCompanies: false,
            ...nextUser,
        });
        this.status = nextUser.Status ?? 'Active';
        this.user = user;
        this.isActivated = user.Status == UserStatus.Active ? true : false;
        this.existingRoles.splice(0, Infinity);
        const roles = nextUser.Roles ? nextUser.Roles : [];
        this.existingRoles = roles.map((x) => (x?.Id?.toString() ? x.Id.toString() : ''));
        this.isReadonly = false;
        this.initIncludeFutureCompanies = user.IncludeFutureCompanies!;
        this.includeFutureCompanies = user.IncludeFutureCompanies!;
        this.initSelectAll = user.IncludeFutureCompanies!;
        this.selectAll = user.IncludeFutureCompanies!;
        this.selectAllDisabled = user.IncludeFutureCompanies!;
    }

    public save = async () => {
        const rolesPayload: BulkUserRoleChange = {};
        rolesPayload.CompanyId = this.company.Id;
        rolesPayload.Add = [];
        rolesPayload.Delete = [];
        this.addedRoles.forEach((roleToAdd) => {
            rolesPayload.Add?.push({ UserId: this.user.Id, RoleId: parseInt(roleToAdd) });
        });

        this.removedRoles.forEach((roleToDelete) => {
            rolesPayload.Delete?.push({ UserId: this.user.Id, RoleId: parseInt(roleToDelete) });
        });

        if (this.status !== 'Invited') {
            await postUserSaveUserStatus({ userId: this.user.Id, userStatus: this.isActivated ? UserStatus.Active : UserStatus.Deactivated });
        }

        if (this.showCustomerPicker) {
            await postMspSaveMspUserCompanies([...this.selectedCompanies], { userId: this.user.Id });
        }

        await postUserSaveUserIncludeFutureCompanies({ userId: this.user.Id, includeFutureCompanies: this.includeFutureCompanies });

        const result = await postRbacSaveUserRoles(toJS(rolesPayload));

        return result;
    };

    public cancel = () => {
        this.init(this.company, this.user);
        this.isDirty = false;
    };

    public deleteInvite = () => {
        this.user = {};
    };

    public resendInvite = async () => {
        var companyId = this.company.Id;
        var userId = this.user.Id?.toString();
        var userEmail = this.user.EMail;

        await postNotificationSendNotification(
            { companyId, userId, WelcomeInviteData: [{ emails: userEmail, roles: [] }] },
            { notificationType: 'Welcome' }
        );
        this.notificationService.notify(
            'Invitation Resent',
            "The user's invitation has been resent",
            'success',
            <i className="ti ti-mail-forward" />
        );
    };

    public delete = async () => {
        await deleteUserDeleteUser({ userId: this.user.Id });
    };

    public changeStatus = async (newStatus: boolean) => {
        this.isActivated = newStatus;
        this.checkIfDirty();
    };

    public changeSelectAll = async (selectAllChecked: boolean) => {
        if (selectAllChecked) {
            this.allCustomers.map((c) => {
                if (!this.selectedCompanies.has(c.Id!)) {
                    this.selectedCompanies.add(c.Id!);
                }
            });
        } else {
            this.selectedCompanies = new Set(this.initSelectedCompanies);
        }
        this.selectAll = selectAllChecked;
        this.checkIfDirty();
    };

    public changeIncludeAllFuture = async (newIncludeAllFuture: boolean) => {
        this.includeFutureCompanies = newIncludeAllFuture;
        this.selectAllDisabled = this.includeFutureCompanies;
        this.checkIfDirty();
    };

    public handleOnChange = async (values: string[]) => {
        this.addedRoles = values.filter((val) => !this.existingRoles.includes(val));
        this.removedRoles = this.existingRoles.filter((val) => !values.includes(val));
        this.checkIfDirty();
    };

    public async loadCompany(company?: Company) {
        if (company?.Id !== this.company.Id) {
            this.company = company ? company : {};
        }
    }

    public toggleCustomer(company: Company) {
        if (this.isCustomerSelected(company)) {
            this.selectedCompanies.delete(company.Id!);
        } else {
            this.selectedCompanies.add(company.Id!);
        }
        this.checkIfDirty();
    }

    public checkIfDirty() {
        const initArr = Array.from(this.initSelectedCompanies).sort();
        const selectedArr = Array.from(this.selectedCompanies).sort();

        this.isDirty =
            !(selectedArr.length === initArr.length && initArr.every((value, index) => value === selectedArr[index])) ||
            this.addedRoles.length + this.removedRoles.length > 0 ||
            this.isActivated !== (this.status === UserStatus.Active) ||
            this.selectAll != this.initSelectAll ||
            this.includeFutureCompanies != this.initIncludeFutureCompanies;
    }

    public isCustomerSelected(company: Company) {
        return this.selectedCompanies.has(company.Id!) || this.selectAll;
    }

    public async loadRoles() {
        this.addedRoles.splice(0, Infinity);
        this.removedRoles.splice(0, Infinity);
        this.possibleRoles.splice(0, Infinity);
        const roles = await this.companyRoleSvc.getRoles();

        roles.forEach((x) => {
            const possibleRole: RoleData = { value: '', label: '' };
            possibleRole.value = x.Id?.toString() ? x.Id.toString() : '';
            possibleRole.label = x.Name ? x.Name : '';
            this.possibleRoles.push(possibleRole);
        });
    }
}

const UserDetails = observer(function UserDetails({
    user,
    onSave,
    onDelete,
    onResend,
    onCancel,
}: {
    user: UserListItem;
    onSave?: () => void;
    onDelete?: () => void;
    onResend?: () => void;
    onCancel?: () => void;
}) {
    const container = useDiContainer();
    const company = useCompany();
    const notificationSvc = useDi(NotificationService);
    const [filterValue, setFilterValue] = useState('');
    const userPermissions = useAuthZValues({
        canManage: { User: 'Manage' },
    });
    const model = useMemo(() => {
        const result = container.resolve(UserDetailsModel);
        result.init(company!, user);
        return result;
    }, []);

    const save = useCallback(() => {
        model
            .save()
            .then(() => {
                onSave?.();
                notificationSvc.notify('User saved', '', 'success', null);
            })
            .catch(() => {
                notificationSvc.notify('Save failed', '', 'error', null);
            });
    }, [model, onSave]);

    const cancel = useCallback(() => {
        model.cancel();
        onCancel?.();
    }, [model, onCancel]);

    const deleteInvitee = useCallback(() => {
        model
            .delete()
            .then(() => {
                onDelete?.();
                notificationSvc.notify('User invite deleted', '', 'success', null);
            })
            .catch(() => {
                notificationSvc.notify('User invite deletion failed', '', 'error', null);
            });
        model.deleteInvite();
    }, [model, onDelete]);

    const resendInvite = useCallback(() => {
        model.resendInvite();
    }, [model, onResend]);

    const confirmDelete = useCallback(() => {
        openConfirmModal({
            title: 'Delete Invite?',
            children: (
                <>
                    <p>
                        Are you sure you want to delete the invitation to <strong>{model.user.EMail}</strong>?
                    </p>
                </>
            ),
            centered: true,
            labels: { confirm: 'Yes, delete it', cancel: 'Cancel' },
            onConfirm: deleteInvitee,
            confirmProps: { color: 'error' },
        });
    }, [model.user]);

    const confirmResend = useCallback(() => {
        openConfirmModal({
            title: 'Resend Invite?',
            children: (
                <>
                    <p>
                        Are you sure you want to resend the invitation to <strong>{model.user.EMail}</strong>?
                    </p>
                </>
            ),
            centered: true,
            labels: { confirm: 'Yes, resend it', cancel: 'Cancel' },
            onConfirm: resendInvite,
            confirmProps: { color: 'primary.6' },
        });
    }, [model.user]);

    const filteredCompanyOptions = model.allCustomers.filter((s) => s.CompanyName?.toLowerCase().includes(filterValue.toLowerCase()));

    return model.isLoading ? null : (
        <PanelContent>
            <PanelBody>
                <DetailsInfoWrapper>
                    <Group>
                        <Box>
                            <UserImage
                                firstName={model.user?.FirstName ?? ''}
                                lastName={model.user?.LastName ?? ''}
                                email={model.user?.EMail ?? ''}
                                picture={model.user?.Picture ?? ''}
                                id={model.user?.Id ?? 0}
                                diameter={48}
                            />
                        </Box>
                        <div data-atid={'UserEmail:' + model.user.EMail}>
                            {(model.user.FirstName &&
                                model.user.LastName &&
                                model.user.FirstName.trim().length > 0 &&
                                model.user.LastName.trim().length > 0) ||
                            model.user.EMail ? (
                                <Text weight="bold">{model.user.FirstName + ' ' + model.user.LastName}</Text>
                            ) : (
                                <Text weight="dim" italic>
                                    No Name
                                </Text>
                            )}
                            <Text>{model.user.EMail}</Text>
                        </div>
                    </Group>
                    {model.status === 'Invited' && userPermissions.canManage ? (
                        <Group spacing={32} style={{ marginLeft: '60px' }}>
                            <AnchorButton icon={<MailForward />} text="Resend" onClick={confirmResend} atid="ResendInvite" />

                            <AnchorButton color="error.6" icon={<Trash />} text="Delete" onClick={confirmDelete} atid="DeleteInvite" />
                        </Group>
                    ) : (
                        <></>
                    )}
                </DetailsInfoWrapper>
                <StatusButtonWrapper>
                    {model.status === 'Invited' ? (
                        <></>
                    ) : (
                        <Switch
                            disabled={!userPermissions.canManage}
                            checked={model.isActivated}
                            onChange={(event) => model.changeStatus(event.currentTarget.checked)}
                            label={model.isActivated ? 'Active' : 'Deactivated'}
                        />
                    )}
                </StatusButtonWrapper>
                <Clearfix />
                <Space h="md" />
                <RolesWrapper>
                    <MultiSelect
                        data={model.possibleRoles}
                        label="Roles"
                        disabled={!userPermissions.canManage}
                        defaultValue={model.existingRoles}
                        onChange={(values) => {
                            model.handleOnChange(values);
                        }}
                    />
                </RolesWrapper>
                {model.showCustomerPicker && userPermissions.canManage ? (
                    <>
                        <Space h="lg" />
                        <Box>
                            <Group position="apart" spacing={0}>
                                <Title order={4}>Customer Access</Title>
                                <Group style={{ display: model.selectAll ? 'block' : 'none' }}>
                                    <Group>
                                        <Checkbox
                                            name="includeAllFuture"
                                            id="includeAllFuture"
                                            onChange={(event) => model.changeIncludeAllFuture(event.currentTarget.checked)}
                                            checked={model.includeFutureCompanies}
                                        />
                                        Include Future Companies
                                    </Group>
                                </Group>
                                <Group>
                                    <Checkbox
                                        name="selectAll"
                                        id="selectAll"
                                        onChange={(event) => model.changeSelectAll(event.currentTarget.checked)}
                                        checked={model.selectAll}
                                        disabled={model.selectAllDisabled}
                                    />
                                    Select All
                                </Group>
                            </Group>
                            <Space h="sm" />
                            <Input
                                icon={<i className="ti ti-filter"></i>}
                                placeholder="Filter"
                                value={filterValue}
                                onChange={(event: any) => setFilterValue(event.currentTarget.value)}
                            />
                            <Space h="sm" />
                            <Card radius="md" p={0} withBorder sx={{ maxHeight: 475, overflow: 'auto' }}>
                                {filteredCompanyOptions.map((c) => (
                                    <>
                                        <Box
                                            m="xs"
                                            key={c.Id}
                                            onClick={() => model.toggleCustomer(c)}
                                            onKeyDown={() => model.toggleCustomer(c)}
                                            sx={{ cursor: 'pointer' }}
                                            tabIndex={0}
                                        >
                                            <Group spacing="xs">
                                                <Checkbox id={'Customer' + model.company.Id!} checked={model.isCustomerSelected(c)} tabIndex={-1} />
                                                <Text>{c.CompanyName}</Text>
                                            </Group>
                                        </Box>
                                        <Divider />
                                    </>
                                ))}
                            </Card>
                        </Box>
                    </>
                ) : null}
            </PanelBody>
            <Divider />
            <PanelToolbar>
                <Button variant="outline" onClick={cancel}>
                    Cancel
                </Button>
                <Space w="sm" />
                <Button disabled={!model.isDirty} onClick={save}>
                    Save
                </Button>
            </PanelToolbar>
        </PanelContent>
    );
});

const DetailsInfoWrapper = styled.div`
    float: left;
    img {
        width: 100px;
        border-radius: 100%;
    }
`;

const StatusButtonWrapper = styled.div`
    float: right;
    margin-top: 12px;
    .mantine-Button-root {
        margin: 10px;
    }

    .mantine-Switch-input {
        background-color: #d6d6d6;
    }

    .mantine-Switch-input:checked {
        background-color: ${colorPalette.linkColor};
    }
`;

const RolesWrapper = styled.div``;

const GridImageWrapper = styled.div`
    img {
        width: 50px;
        border-radius: 100%;
        margin: 0px 15px 0px 0px;
    }
`;

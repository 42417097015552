type AwsUsageChartInput = {
    metric: string;
    unit: string;
    operation: string;
};
const _awsResourceDefaultMetrics = {
    'EC2 On Demand': [
        {
            metric: 'CPU_Utilization',
            unit: 'Percent',
            operation: 'avg',
        },
        {
            metric: 'Network_In',
            unit: 'Bytes',
            operation: 'avg',
        },
        {
            metric: 'Network_Out',
            unit: 'Bytes',
            operation: 'avg',
        },
        {
            metric: 'Memory Used',
            unit: 'Percent',
            operation: 'avg',
        },
    ],
    RDS: [
        {
            metric: 'Read_IOPS',
            unit: 'Count',
            operation: 'avg',
        },
        {
            metric: 'Write_IOPS',
            unit: 'Count',
            operation: 'avg',
        },
        {
            metric: 'Freeable_Memory',
            unit: 'Bytes',
            operation: 'avg',
        },
        {
            metric: 'CPU_Utilization',
            unit: 'Percent',
            operation: 'avg',
        },
        {
            metric: 'Network_Receive_Throughput',
            unit: 'Bytes',
            operation: 'avg',
        },
        {
            metric: 'Network_Transmit_Throughput',
            unit: 'Bytes',
            operation: 'avg',
        },
        {
            metric: 'Database_Connections',
            unit: 'Count',
            operation: 'avg',
        },
        {
            metric: 'Active_Memory',
            unit: 'Bytes',
            operation: 'avg',
        },
    ],
    EMR: [
        {
            metric: 'Is_Idle',
            unit: 'Boolean',
            operation: 'avg',
        },
    ],
    Redshift: [
        { metric: 'CPU_Utilization', unit: 'Percent', operation: 'avg' },
        {
            metric: 'Percentage_Disk_Space_Used',
            unit: 'Percent',
            operation: 'avg',
        },
        {
            metric: 'Read_IOPS',
            unit: 'Count',
            operation: 'avg',
        },
        {
            metric: 'Write_IOPS',
            unit: 'Count',
            operation: 'avg',
        },
        {
            metric: 'Read_Throughput',
            unit: 'Bytes',
            operation: 'avg',
        },
        {
            metric: 'Write_Throughput',
            unit: 'Bytes',
            operation: 'avg',
        },
        {
            metric: 'Database_Connections',
            unit: 'Count',
            operation: 'avg',
        },
    ],
    ELBV1: [
        {
            metric: 'RequestCount',
            unit: 'Count',
            operation: 'sum',
        },
    ],
    ELBV2: [
        {
            metric: 'RequestCount',
            unit: 'Count',
            operation: 'sum',
        },
    ],
};

const types = Object.keys(_awsResourceDefaultMetrics) as (keyof typeof _awsResourceDefaultMetrics)[];
export const awsResourceDefaultMetrics = types.reduce(
    (result, key) => result.set(key, _awsResourceDefaultMetrics[key]),
    new Map<string, AwsUsageChartInput[]>()
);

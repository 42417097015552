import { getAccountGetAccountConnectionStatus, getAccountGetRelatedAccounts } from '@apis/Customers';
import { Account } from '@apis/Customers/model';
import { postResourcesQuery } from '@apis/Resources';
import { BaseAwsResource } from '@apis/Resources/model';
import {
    getMapContractsGetMapContractById,
    getMapContractsGetMapContracts,
    postMapContractsGetMapResourceTypes,
    postMapContractsUpdateMapContract,
    postMapContractsValidateMapContract,
} from '@apis/TagManager';
import { MapContract, MapContractStatus } from '@apis/TagManager/model';
import { EventEmitter } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { queryBuilder, SchemaService } from '@root/Services/QueryExpr';
import { ResourceSchemaProvider } from '@root/Services/Resources/ResourceService';
import { addYears } from 'date-fns';
import { inject, Lifecycle, scoped } from 'tsyringe';
import { MapResourceQueryService } from '../Services/MapResourceQueryService';

@scoped(Lifecycle.ContainerScoped)
export class MapContractHomeModel {
    public contract?: MapContract;
    public otherContracts?: MapContract[];
    public loading = new EventEmitter(false);
    public tagCoverageInvalidated = EventEmitter.empty();
    public mapResourceQuerySvc?: MapResourceQueryService;
    public accounts: Account[] = [];
    public accountSets: Account[][] = [];
    public contractStatus = new Map<number, MapContractStatus>();
    public resourceSchema?: SchemaService;
    public WarningsChanged = new EventEmitter<{ sectionId: string; hasWarnings: boolean }>({ sectionId: 'none', hasWarnings: false });

    public constructor(
        @inject(FormatService) private readonly fmtSvc: FormatService,
        @inject(ResourceSchemaProvider) private readonly resourceSchemaProvider: ResourceSchemaProvider
    ) {}

    public init(rawId: string | number) {
        this.load(rawId);
        return this;
    }

    public reloadContract() {
        this.load(this.contract!.Id!);
    }

    public async reloadContractOnly() {
        this.contract = await getMapContractsGetMapContractById({ id: this.contract!.Id! });
    }

    public async addManagementAccount(accountId: string) {
        if (this.contract?.AccountIds && !this.contract.AccountIds.includes(accountId)) {
            this.contract.AccountIds.push(accountId);
            await postMapContractsUpdateMapContract({ ...this.contract });

            this.reloadContract();
        }
    }

    private async load(rawId: string | number) {
        this.loading.emit(true);
        try {
            const id = typeof rawId === 'number' ? rawId : parseInt(rawId);
            this.contract = await getMapContractsGetMapContractById({ id });
            const allContracts = await getMapContractsGetMapContracts();
            this.otherContracts = allContracts.filter((c) => c.Id !== this.contract?.Id);
            const resourceTypes = await this.resourceSchemaProvider.getSchema();
            this.resourceSchema = new SchemaService(resourceTypes);
            const mapResourceTypeMeta = await postMapContractsGetMapResourceTypes();
            this.mapResourceQuerySvc = new MapResourceQueryService(this.contract, mapResourceTypeMeta, this.fmtSvc, this.otherContracts);
            this.accounts = await this.loadAccounts();
            this.contractStatus = await this.loadMapContractStatus(this.accounts);
        } finally {
            this.loading.emit(false);
        }
    }

    public checkWarnings = async () => {
        if (this.checkConnectionHealth() > 0) {
            this.WarningsChanged.emit({ sectionId: 'connection', hasWarnings: true });
        } else {
            this.WarningsChanged.emit({ sectionId: 'connection', hasWarnings: false });
        }

        let manageTagsIssueCount = await this.checkManageTagsHealth();
        if (manageTagsIssueCount.incorrect > 0) {
            this.WarningsChanged.emit({ sectionId: 'manage-tags', hasWarnings: true });
        } else {
            this.WarningsChanged.emit({ sectionId: 'manage-tags', hasWarnings: false });
        }
    };

    private async checkManageTagsHealth() {
        let result = await this.getManageTagsIssueCount();
        let issueCount = result?.Results?.reduce((result, item) => {
            result.incorrect += item.incorrect;
            return result;
        });

        return issueCount ?? { incorrect: 0 };
    }

    private checkConnectionHealth = () => {
        return this.accounts.filter((m) => m.IsConnected === false).length;
    };

    private async loadAccounts() {
        const connectionStatus = await getAccountGetAccountConnectionStatus({ companyId: this.contract!.CompanyId });
        this.accountSets = await getAccountGetRelatedAccounts();
        const accounts = this.accountSets.flatMap((set) =>
            set.some((acc) => this.contract?.AccountIds?.includes(acc.AwsAccountId ?? '')) ? set : []
        );
        const connectionStatusMap = connectionStatus.reduce(
            (map, item) => map.set(item.AccountId ?? 0, item?.ConnectionStatus ?? false),
            new Map<number, boolean>()
        );
        accounts.forEach((account) => {
            account.IsConnected = connectionStatusMap.get(account.Id ?? 0) ?? false;
        });
        return accounts;
    }

    private async loadMapContractStatus(accounts: Account[]) {
        const statuses = await postMapContractsValidateMapContract(accounts.filter((a) => a.IsMasterAccount).map((ac) => ac.Id!));
        return new Map<number, MapContractStatus>(statuses.map((s) => [s.AccountId, s] as [number, MapContractStatus]));
    }

    public getName() {
        return this.contract?.Name ?? `MAP Contract #${this.contract?.ProgramId}`;
    }

    public getManagementAccounts() {
        const managementAccounts = this.accounts.filter((a) => a.IsMasterAccount);
        return managementAccounts.map((ma) => {
            return {
                ...ma,
                accounts: this.accounts.filter((a) => a.MasterAccount === ma.AccountArn && !a.IsMasterAccount),
            };
        });
    }

    public getNonManagementAccounts() {
        return this.accounts.filter((a) => !a.IsMasterAccount);
    }

    public getStatus() {
        const startDate = this.fmtSvc.toLocalDate(this.contract!.ContractStartDate);
        const endDate = addYears(startDate, this.contract!.ContractTermYears ?? 1);
        const isActive = startDate.getTime() < new Date().getTime() && endDate.getTime() > new Date().getTime();
        const description = isActive
            ? `Active until ${this.fmtSvc.toShortDate(endDate)}`
            : startDate.getTime() > new Date().getTime()
            ? `Effective ${this.fmtSvc.toShortDate(startDate)}`
            : `Inactive since ${this.fmtSvc.toShortDate(endDate)}`;
        const start = this.fmtSvc.toShortDate(startDate);
        const end = this.fmtSvc.toShortDate(endDate);
        const accounts = this.contract?.AccountIds?.length ?? 0;
        return { isActive, description, start, end, accounts };
    }

    private getManageTagsIssueCount = async () => {
        if (!this.mapResourceQuerySvc) return;

        const results = await queryBuilder<Omit<BaseAwsResource, 'CreateDate'> & { CreateDate: Date }>()
            .where((b) => b.model.ManagementAccount!.eq(this.contract!.AccountIds))
            .select((b) => ({
                incorrect: b.countIf(this.mapResourceQuerySvc!.getIncorrectlyTaggedQuery()),
            }))
            .execute(postResourcesQuery);

        return results;
    };
}

import { ActionIcon, Loader } from '@mantine/core';
import { MouseEventHandler } from 'react';
import { ChevronDown } from 'tabler-icons-react';

type DropdownStates = 'opened' | 'closed' | 'loading' | 'disabled' | 'error';
interface IPickerOpenerProps {
    onClick: MouseEventHandler;
    state: DropdownStates | Partial<Record<DropdownStates, boolean>>;
}
export function DropdownOpener({ state: stateProp, onClick }: IPickerOpenerProps) {
    const state = typeof stateProp !== 'string' ? stateProp : ({ [stateProp]: true } as Record<DropdownStates, boolean | undefined>);
    const { loading, opened, disabled, error } = state;
    return loading ? (
        <Loader size={16} />
    ) : (
        <ActionIcon
            variant={opened ? 'default' : undefined}
            color={opened ? 'primary' : disabled ? 'gray.5' : error ? 'error.6' : undefined}
            sx={{ flex: '0 0 auto' }}
            tabIndex={-1}
            onClick={onClick}
        >
            <ChevronDown style={{ transition: 'linear .25s', transform: opened ? 'rotateX(180deg)' : undefined }} strokeWidth={3} size={20} />
        </ActionIcon>
    );
}

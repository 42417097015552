import { Accordion, ActionIcon, Box, Button, createStyles, Divider, Group, Space, Text, TextInput, Tooltip, useMantineTheme } from '@mantine/core';
import { ChartTypes } from '@root/Components/Charts/Common';
import { PanelContent, PanelHeader, PanelBody, PanelToolbar } from '@root/Design/Layout';
import { colorPalette, CustomColors } from '@root/Design/Themes';
import { useEvent, useEventValue } from '@root/Services/EventEmitter';
import { useCallback, useEffect, useMemo } from 'react';
import { BarSettings } from './BarSettings';
import { chartTypeIcons } from './Design';
import { GaugeSettings } from './GaugeSettings';
import { GridSettings } from './GridSettings';
import { KpiSettings } from './KpiSettings';
import { LineSettings } from './LineSettings';
import { ChartEditor } from './Models';
import { PieSettings } from './PieSettings';

export function ChartDashboardItemEditor({ editor, width }: { editor: ChartEditor; width: number }) {
    const titleChanged = useCallback((e: { currentTarget: HTMLInputElement }) => editor.setTitle(e.currentTarget.value), [editor]);
    useEffect(() => {
        editor.init();
    }, [editor.dashboardItemModel.settings.datasourceName]);
    const applyChanges = () => {
        editor.saveChanges();
        editor.onClose.emit();
    };
    const theme = useMantineTheme();
    useEvent(editor.onSettingsChanged);
    const visOptions = useMemo(
        () =>
            [
                { type: 'kpi', label: 'KPI' },
                { type: 'grid', label: 'Table' },
                { type: 'gauge', label: 'Gauge' },
                { type: 'pie', label: 'Pie' },
                { type: 'bar', label: 'Bar' },
                { type: 'line', label: 'Line' },
            ] as { type: ChartTypes; label: string }[],
        []
    );
    const loading = useEventValue(editor.loading);
    const { classes } = useStyles();

    return (
        <PanelContent>
            <PanelHeader>
                <Text size={20}>Customize Card</Text>
            </PanelHeader>
            <Divider />
            <PanelBody noPadding>
                {loading ? null : (
                    <Box p="lg" sx={{ overflow: 'auto', height: '100%' }}>
                        <TextInput label="Title" value={editor.title} onChange={titleChanged} />
                        <Space h="md" />
                        <Accordion variant="separated" multiple defaultValue={['Type', 'Data']}>
                            <Accordion.Item value="Type">
                                <Accordion.Control value="Type">Type</Accordion.Control>
                                <Accordion.Panel>
                                    <Group position="center">
                                        {visOptions.map((o) => (
                                            <Tooltip key={o.type} label={o.label} position="bottom" withArrow withinPortal>
                                                <ActionIcon
                                                    size="lg"
                                                    className={classes.chartIcons}
                                                    style={{
                                                        backgroundColor: editor.chartType === o.type ? theme.colors?.primary?.[6] : '',
                                                        color: editor.chartType === o.type ? colorPalette.white : '',
                                                    }}
                                                    onClick={() => editor.setChartType(o.type)}
                                                >
                                                    <i className={chartTypeIcons.get(o.type)}></i>
                                                </ActionIcon>
                                            </Tooltip>
                                        ))}
                                    </Group>
                                </Accordion.Panel>
                            </Accordion.Item>
                            {editor.chartType === 'pie' ? (
                                <PieSettings editor={editor} />
                            ) : editor.chartType === 'bar' ? (
                                <BarSettings editor={editor} />
                            ) : editor.chartType === 'gauge' ? (
                                <GaugeSettings editor={editor} />
                            ) : editor.chartType === 'line' ? (
                                <LineSettings editor={editor} />
                            ) : editor.chartType === 'kpi' ? (
                                <KpiSettings editor={editor} />
                            ) : editor.chartType === 'grid' ? (
                                <GridSettings editor={editor} />
                            ) : null}
                        </Accordion>
                    </Box>
                )}
            </PanelBody>
            <Divider />
            <PanelToolbar padding="md">
                <Group position="right">
                    <Button onClick={applyChanges}>Apply Changes</Button>
                    <Button
                        onClick={() => {
                            if(!editor.isEditMode) {
                                editor.dashboardItemModel.remove();
                            }
                            editor.onClose.emit();
                        }}
                        variant="outline"
                    >
                        Cancel
                    </Button>
                </Group>
            </PanelToolbar>
        </PanelContent>
    );
}

const useStyles = createStyles((theme) => ({
    chartIcons: {
        borderColor: theme.colors?.gray?.[4] as CustomColors as CustomColors,
        backgroundColor: colorPalette.white,
        color: theme.colors?.primary?.[6] as CustomColors,
        '&:hover': {
            borderColor: theme.colors?.gray?.[4] as CustomColors as CustomColors,
            backgroundColor: theme.colors?.gray?.[2] as CustomColors,
            color: theme.colors?.primary?.[6] as CustomColors,
        },
    },
}));

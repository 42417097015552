import {
    DisbursementDefinition,
    DisbursementTarget,
    DisbursementTargetTargetType,
    InvoiceAllocationRule,
    NamedFilter,
    Query,
} from '@apis/Invoices/model';
import { QueryExpr, QueryResult } from '@apis/Resources';
import { Button, Card, Group, Text, Space, Stack, Box, useMantineTheme, TextInput, Select, Popover, ActionIcon, Input } from '@mantine/core';
import { Radio } from '@mantine/core';
import { DataFilterModel, DataFilters } from '@root/Components/Filter/Filters';
import { SchemaFieldNameProvider } from '@root/Components/Filter/Services';
import { FieldPicker } from '@root/Components/Picker/FieldPicker';
import { Picker } from '@root/Components/Picker/Picker';
import { useReadonlyInputStyles } from '@root/Design/Primitives';
import { useDiMemo } from '@root/Services/DI';
import { useEvent, useToggle } from '@root/Services/EventEmitter';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { cleanBoolExpr, groupExprs, SchemaValueProvider } from '@root/Services/QueryExpr';
import { useObserver } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronDown, ChevronUp, Plus } from 'tabler-icons-react';
import { EditorCard, EditorCardWhiteWrap } from './Design';
import { NamedFilterSetModel } from './FilterSetEditor/NamedFilterSetModel';
import { AllocationRuleEditor } from './Model';

export function DisbursementTargetPicker({
    ruleEditor,
    buttons,
    disableDimension,
    helpText,
}: {
    ruleEditor: AllocationRuleEditor;
    buttons: { label: string; onClick: () => void }[];
    disableDimension?: boolean;
    helpText?: string;
}) {
    const invoiceApi = useDiMemo(InvoiceApiService);
    const dimensionValue = ruleEditor.showbackSvc.getDimensionName(ruleEditor.allocDim);
    const target = ruleEditor.getSingleTarget();
    const disburseMethodValues =
        ruleEditor.rule?.RuleType === 'SharedCosts' || ruleEditor.rule?.RuleType === 'Marketplace' ? ['Proportional', 'Equal'] : [];

    const disburseLevelValues =
        ruleEditor.rule?.RuleType === 'SharedCosts' || ruleEditor.rule?.RuleType === 'Marketplace' ? [dimensionValue, 'Resources'] : [];
    const [disbursementMethod, setDisbursementMethod] = useState<string>(target.DisbursementMethod === 'SplitByUsage' ? 'Proportional' : 'Equal');
    const [disbursementLevel, setDisbursementLevel] = useState<string>(target.TargetType === 'NewRecords' ? dimensionValue : 'Resources');

    useEffect(() => {
        if (disableDimension) {
            setDisbursementLevel(disburseLevelValues[1]);
        }
    }, [disableDimension]);

    const datasource = useMemo(
        () =>
            function query<T>(query: Query) {
                return invoiceApi.query(query, { from: ruleEditor.month, to: ruleEditor.month }, false) as Promise<QueryResult<T>>;
            },
        []
    );

    // filter props
    const [filterModel, setFilterModel] = useState<DataFilterModel>();
    const usageStatsFilterSet = useMemo(() => NamedFilterSetModel.create(target, 'UsageStatFilter'), [target.UsageStatFilter]);
    const usageStatsInclusion = useMemo(() => usageStatsFilterSet.inclusionRules.getOrAdd(), [usageStatsFilterSet]);
    const targetExFilterSet = useMemo(() => NamedFilterSetModel.create(target, 'TargetExistingFilter'), [target.TargetExistingFilter]);
    const targetExInclusion = useMemo(() => targetExFilterSet.inclusionRules.getOrAdd(), [targetExFilterSet]);
    useEvent(targetExFilterSet.onFilterChanged);
    useEvent(usageStatsFilterSet.onFilterChanged);

    const schemaSvc = ruleEditor.schemaSvc;

    const valueProvider = useMemo(() => new SchemaValueProvider(schemaSvc, datasource), [schemaSvc]);
    const fieldInfoProvider = useMemo(() => new SchemaFieldNameProvider(schemaSvc!), [schemaSvc]);

    const addFilter = useCallback(() => {
        filterModel?.addEmptyFilter(true);
    }, [filterModel]);

    const theme = useMantineTheme();
    const {
        classes: { readonly },
    } = useReadonlyInputStyles();
    const [availableDimValues, setAvailableDimValues] = useState<string[]>([]);
    const [isExclusion, setIsExclusion] = useState(target.IsDimensionValuesExclusionList ?? false);
    useEffect(() => {
        loadExistingDimensionValues().then(setAvailableDimValues);
    }, [ruleEditor.allocDim?.DimensionName, ruleEditor.rule]);

    const [costCenterValues, setCostCenterValues] = useState<string[]>([]);
    const [dimensionValues, setDimensionValues] = useState<string[]>(target?.DimensionValues ?? []);

    const { getFilters: getStatsFilters, setFilters: setStatsFilters } = usageStatsInclusion;
    const { getFilters: getTgtFilters, setFilters: setTgtFilters } = targetExInclusion;

    useEvent(
        filterModel?.filtersChanged,
        useCallback(() => (target.TargetType === 'NewRecords' ? setStatsFilters : setTgtFilters)(filterModel?.getFilters() ?? []), [filterModel])
    );

    const cleanStatsFilters = cleanBoolExpr(groupExprs('and', getStatsFilters()));
    const cleanTargetExFilters = cleanBoolExpr(groupExprs('and', getTgtFilters()));

    useEffect(() => {
        const targetType: DisbursementTargetTargetType = disbursementLevel === dimensionValue ? 'NewRecords' : 'Existing';
        const target = {
            TargetType: targetType,
            DisbursementShare: 'FixedAmount',
            DisbursementMethod:
                targetType === 'Existing' ? 'SplitByUsage' : disbursementMethod === 'Proportional' ? 'SplitByUsage' : 'SplitByDimension',
            DimensionValues: dimensionValues,
            IsDimensionValuesExclusionList: isExclusion,
            UsageStatFilter: usageStatsFilterSet.filterSet,
            TargetExistingFilter: targetExFilterSet.filterSet,
        } as DisbursementTarget;
        const disbursement = {
            Targets: [target],
        } as DisbursementDefinition;
        ruleEditor.setDisbursement(disbursement);
        ruleEditor.rulePropsChanged.emit();
        if (targetType === 'Existing') {
            setDisbursementMethod('Proportional');
        }
    }, [
        costCenterValues,
        disbursementLevel,
        isExclusion,
        disbursementMethod,
        dimensionValues,
        isExclusion,
        JSON.stringify([cleanStatsFilters, cleanTargetExFilters]),
    ]);

    useEffect(() => {}, [target]);

    const valueOptions = useMemo(() => availableDimValues.map((value) => ({ value })), [availableDimValues]);
    const handleExclusionChange = useCallback((value: string) => {
        setIsExclusion(value === 'not');
    }, []);
    const handleDimValuesChange = useCallback((values: { value: string }[]) => {
        setDimensionValues(values.map((v) => v.value));
    }, []);

    return (
        <>
            <EditorCardWhiteWrap>
                <EditorCard title="Disbursement Targets" color="gray" info={helpText} wrapped>
                    <Group>
                        {disburseLevelValues.length > 0 ? (
                            <>
                                <Card radius="md" style={{ backgroundColor: 'white', width: '40%' }}>
                                    <Text size="sm" weight={400}>
                                        Disburse Level
                                    </Text>
                                    <Radio.Group value={disbursementLevel}>
                                        <Stack>
                                            {disburseLevelValues.map((value, index) => (
                                                <Radio
                                                    key={index}
                                                    value={value}
                                                    label={value}
                                                    onClick={() => setDisbursementLevel(value)}
                                                    disabled={disableDimension && index === 0}
                                                />
                                            ))}
                                        </Stack>
                                    </Radio.Group>
                                </Card>
                                <Space h="md" />
                            </>
                        ) : null}
                        {disburseMethodValues.length > 0 ? (
                            <>
                                <Card radius="md" style={{ backgroundColor: 'white', width: '40%' }}>
                                    <Text size="sm" weight={400}>
                                        Disburse Method
                                    </Text>
                                    <Radio.Group value={disbursementMethod}>
                                        <Stack>
                                            {disburseMethodValues.map((value, index) => (
                                                <Radio
                                                    disabled={disbursementLevel === 'Resources' && index === 1}
                                                    key={index}
                                                    value={value}
                                                    label={value}
                                                    onClick={() => setDisbursementMethod(value)}
                                                />
                                            ))}
                                        </Stack>
                                    </Radio.Group>
                                </Card>
                            </>
                        ) : null}
                    </Group>
                    <Space h="md" />
                    {disbursementLevel === dimensionValue ? (
                        <>
                            <Text sx={{ fontWeight: 'bold', color: theme.colors.gray[6] }}>Allocation Dimension Key</Text>
                            <Group noWrap>
                                <Box>
                                    <TextInput
                                        className={readonly}
                                        data-atid="DimensionKeyDisabledInput"
                                        disabled={true}
                                        value={ruleEditor.showbackSvc.getDimensionName(ruleEditor.allocDim)}
                                    />
                                </Box>
                                <Select
                                    data={[
                                        { label: 'Is', value: 'is' },
                                        { label: 'Is Not', value: 'not' },
                                    ]}
                                    sx={{ maxWidth: 120 }}
                                    onChange={handleExclusionChange}
                                    value={isExclusion ? 'not' : 'is'}
                                />
                                <Box sx={{ flex: '1' }}>
                                    <DimensionValuePicker values={dimensionValues} options={valueOptions} onChange={handleDimValuesChange} />
                                </Box>
                            </Group>
                            <Space h="md" />
                        </>
                    ) : null}
                    <Box>
                        {target.DisbursementMethod === 'SplitByDimension' ? null : (
                            <>
                                <DataFilters
                                    key={disbursementMethod}
                                    filters={target.TargetType === 'NewRecords' ? getStatsFilters() : getTgtFilters()}
                                    valueProvider={valueProvider}
                                    onChange={() => {}}
                                    fieldInfoProvider={fieldInfoProvider!}
                                    renderFieldPicker={(select) => (
                                        <FieldPicker
                                            mode="single"
                                            selections={[]}
                                            schema={schemaSvc!}
                                            onChange={([f]) => select(f.path)}
                                            types={['string', 'date']}
                                        />
                                    )}
                                    onModelLoaded={setFilterModel}
                                    dataFiltersAsLineItem
                                />
                                {buttons.map((button, index) => (
                                    <Button key={index} variant="outline" radius="xl" size="sm" leftIcon={<Plus size={16} />} onClick={addFilter}>
                                        {button.label}
                                    </Button>
                                ))}
                            </>
                        )}
                    </Box>
                </EditorCard>
            </EditorCardWhiteWrap>
        </>
    );

    async function loadExistingDimensionValues() {
        return await ruleEditor.showbackSvc.getDimensionValueOptions(ruleEditor.allocDim);
    }
}

function DimensionValuePicker({
    options,
    values,
    onChange,
}: {
    options: { value: string }[];
    values: string[];
    onChange: (values: { value: string }[]) => void;
}) {
    const [opened, { close, toggle }] = useToggle(false);
    const selections = useMemo(() => options.filter((option) => values.includes(option.value)), [options, values]);
    return (
        <Popover opened={opened} onClose={close} position="bottom" offset={0} shadow="md" withinPortal>
            <Popover.Dropdown p={0}>
                <Picker
                    mode="multiple"
                    height={300}
                    minimizeHeight
                    items={options}
                    selections={selections}
                    onChange={onChange}
                    nameAccessor={(item) => item.value}
                ></Picker>
            </Popover.Dropdown>
            <Popover.Target>
                <Input
                    component={'button'}
                    rightSection={<ActionIcon onClick={toggle}>{opened ? <ChevronUp /> : <ChevronDown />}</ActionIcon>}
                    data-atid="DimensionValueInput"
                    onClick={toggle}
                >{`${values.length} Selections`}</Input>
            </Popover.Target>
        </Popover>
    );
}

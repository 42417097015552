import styled from '@emotion/styled';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { ActionIcon, Card, Center, Container, Grid, Group, LoadingOverlay, Space, Textarea, TextInput, Text, Title } from '@mantine/core';
import { useState, useContext, useCallback } from 'react';
import { observer } from 'mobx-react';
import { TagAutomationRuleService } from '@root/Site/TagManager/TagAutomation/Components/TagAutomationRuleService';
import { CustomColors, theme } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useNav } from '@root/Services/NavigationService';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { UseFormReturnType } from '@mantine/form';
import { RuleTypeCard } from './RuleTypeCard';
import { RuleEditCard } from './Design';
import { RuleEditor } from './Model';
import { TagAutomationRule, TagAutomationRuleParametersType } from '@apis/TagManager/model';
import { useDisclosure } from '@mantine/hooks';
import { PlatformService } from '@root/Services/PlatformService';

function EditRuleForm({ rule }: { rule: TagAutomationRule }) {
    const tagPermissions = useAuthZValues({
        canEdit: { TagAutomation: 'Edit' },
    });

    const ruleTypes: TagAutomationRuleParametersType[] = ['AutoTag', 'Replacement', 'SyntaxCorrection', 'Inheritance', 'ResourceGroup'];

    const selectType = useCallback(
        (type: TagAutomationRuleParametersType) => {
            if (!rule.Id) {
                rule.Parameters ??= {};
                rule.Parameters.Type = type;
            }
            showErrors();
        },
        [rule]
    );
    const [errorsShown, { open: showErrors }] = useDisclosure(false);

    return (
        <>
            <RuleEditCard onBlur={showErrors}>
                <TextInput
                    data-atid="RuleName"
                    label="Rule Name"
                    error={errorsShown && !rule.RuleName}
                    value={rule.RuleName ?? ''}
                    onChange={(e) => (rule.RuleName = e.currentTarget.value)}
                    maxLength={75}
                />
                <Space h="md" />
                <ResizeableTextarea>
                    <Textarea
                        data-atid="RuleName"
                        label="Rule Description"
                        minRows={1}
                        autosize
                        value={rule.RuleDescription ?? ''}
                        onChange={(e) => (rule.RuleDescription = e.currentTarget.value)}
                        maxLength={300}
                    />
                </ResizeableTextarea>
            </RuleEditCard>
            <Space h="md" />
            {rule.Id ? null : (
                <Text mx="1rem" size="lg" weight="bold">
                    Select a Rule Type
                </Text>
            )}
            <Group p={0} spacing={0} mb="-1px">
                {ruleTypes.map((t) => (
                    <RuleTypeCard
                        key={t}
                        disabled={!!rule.Id && rule.Parameters?.Type !== t}
                        selected={rule.Parameters?.Type === t}
                        ruleType={t}
                        onClick={selectType}
                    />
                ))}
            </Group>
        </>
    );
}

export default observer(EditRuleForm);

const ResizeableTextarea = styled.div`
    textarea {
        resize: vertical;
    }
`;

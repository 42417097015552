import { Button, Popover, Menu, Divider, Tooltip, Box, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useDi } from '@root/Services/DI';
import { useEvent } from '@root/Services/EventEmitter';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Check, ChevronDown, LayoutDashboard, DeviceFloppy as Save, Lock, ChevronUp, Tool } from 'tabler-icons-react';
import { IDashboardConfig } from '../DashboardLayout/Models';
import { DashboardConfigLoadPanel } from '../DashboardPersistence/DashboardConfigLoadPanel';
import { DashboardConfigPanel } from '../DashboardPersistence/DashboardConfigPanel';
import { DashboardPersistenceService, IDashboardConfigBase } from '../DashboardPersistence/DashboardPersistenceService';
import { Picker } from '../Picker/Picker';
import { DataGridModel } from './DataGridModel';
import { DashboardUserSettings } from '@apis/Customers/model';
import { getDashboardGetOrCreateDashboardUserSettings, getGetDashboardGetOrCreateDashboardUserSettingsQueryKey } from '@apis/Customers';
import { AuthenticationService } from '@root/Services/AuthenticationService';

export function GridSavedViews({ grid }: { grid: DataGridModel }) {
    type ViewOption = { type: 'view' | 'label'; id: number; name: string; owned?: boolean };
    const [panelOpened, { close: closePanel, toggle: togglePanel }] = useDisclosure(false);
    const [menuOpened, { close: closeMenu, toggle: toggleMenu }] = useDisclosure(false);
    const dashboardPersistenceSvc = useDi(DashboardPersistenceService);
    const [userSettings, setUserSettings] = useState<DashboardUserSettings>();
    const [options, setOptions] = useState<ViewOption[]>([]);
    const loadOptions = useCallback(async () => {
        const settings = await getDashboardGetOrCreateDashboardUserSettings();
        setUserSettings(settings);
        if (!grid.currentOptions?.statePersistence?.key) {
            return;
        }
        const viewOptions = await dashboardPersistenceSvc.getLayouts<IDashboardConfigBase>(grid.currentOptions?.statePersistence?.key ?? '');
        let options = viewOptions.map(
            (o) => ({ type: 'view', id: o.id, name: o.layout.name, owned: o.ownerUserId === settings.UserId } as ViewOption)
        );
        options.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
        const { owned, shared } = options.reduce(
            (result, item) => {
                const group = result[item.owned ? 'owned' : 'shared'];
                if (!group.length) {
                    const name = item.owned ? 'My Views' : 'Shared With Me';
                    group.push({ type: 'label', id: 0, name });
                }
                group.push(item);
                return result;
            },
            { owned: [] as ViewOption[], shared: [] as ViewOption[] }
        );

        setOptions([...owned, ...shared]);
    }, [grid]);

    const handleLoad = useCallback(
        async ({ id }: { id: number }) => {
            if (id !== grid.getConfigId()) {
                const layout = await dashboardPersistenceSvc.load(grid.currentOptions?.statePersistence?.key ?? '', id);
                grid.loadStateConfig(id, layout, true);
            }
        },
        [grid]
    );

    const handleQuickLoad = useCallback(
        async (ids: { id: number }[]) => {
            if (ids.length) {
                await handleLoad(ids[0]);
            }
            closeMenu();
        },
        [handleLoad, closeMenu]
    );

    const handleSave = useCallback(
        async (name: string) => {
            await grid.saveStateCopy(name);
            loadOptions();
        },
        [grid]
    );

    const handleConfigChange = useCallback(
        ({ id, layout: { name } }: { id: number; layout: { name: string } }, action: 'rename' | 'delete') => {
            if (action === 'delete') {
                grid.handleStateDelete(id);
                loadOptions();
            } else if (action === 'rename') {
                grid.handleStateRename(id, name);
                loadOptions();
            }
        },
        [grid]
    );
    const selecteds = useMemo(() => options?.filter((o) => o.id === grid.getConfigId()), [options, grid.getConfigId()]);
    const selected = selecteds.length ? selecteds[0] : undefined;

    const saveability = !selected ? 'none' : !selected.owned ? 'shared' : userSettings?.DashboardAutoSave ? 'auto' : ('owned' as const);
    const saveLbl = saveability === 'shared' ? 'Save Disallowed' : saveability === 'auto' ? 'Auto-Saved' : 'Save View';
    const saveDisabled = saveability === 'shared' || saveability === 'auto';
    const saveTooltip =
        saveability === 'shared' ? (
            <>
                Changes can only be saved your own views.
                <br /> "Manage Saved Views" to create a saveable copy
            </>
        ) : saveability === 'auto' ? (
            <>Changes are auto-saved. "Manage Saved Views" to change this setting.</>
        ) : saveability === 'none' ? (
            <>Save this view. "Manage Saved Views" to rename.</>
        ) : null;
    const saveIcon = saveability === 'shared' ? <Lock size={16} /> : saveability === 'auto' ? <Check size={16} /> : <Save size={16} />;
    const dropdownIcon = saveability === 'shared' ? <Lock size={16} /> : <LayoutDashboard size={16} />;

    useEvent(grid.stateSaved, loadOptions);

    const handleManageClicked = useCallback(() => {
        closeMenu();
        togglePanel();
    }, [togglePanel, closeMenu]);

    const handleQuickSaveClicked = useCallback(() => {
        if (saveability === 'owned') {
            grid.saveState();
        } else if (saveability === 'none') {
            grid.saveStateCopy('Unnamed View');
        }
    }, [grid, saveability]);

    useEffect(() => {
        loadOptions();
    }, [grid?.currentOptions?.statePersistence?.key]);
    return (
        <>
            <Menu position="bottom-end" opened={menuOpened} onClose={closeMenu} shadow="md" withinPortal offset={0} withArrow>
                <Menu.Target>
                    <Button
                        my={5}
                        variant="outline"
                        radius="lg"
                        leftIcon={dropdownIcon}
                        onClick={toggleMenu}
                        size="xs"
                        rightIcon={menuOpened ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                    >
                        <Box sx={{ maxWidth: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {!selected ? 'Saved Views' : selected?.name ?? 'Unnamed View'}
                        </Box>
                    </Button>
                </Menu.Target>
                <Menu.Dropdown p={0} pt={4}>
                    <Picker
                        noFilter
                        mode="single"
                        items={options}
                        selections={selecteds}
                        onChange={handleQuickLoad}
                        renderItem={(o) =>
                            o.type === 'label' ? (
                                <Text size="xs" color="dimmed" ml={-8}>
                                    {o.name}
                                </Text>
                            ) : (
                                <>{o.name}</>
                            )
                        }
                        isSelectable={(o) => o.type === 'view'}
                        nameAccessor="name"
                        minimizeHeight
                        height={200}
                    />
                    <Divider />
                    <Box p={3}>
                        <Tooltip disabled={saveTooltip === null} label={saveTooltip ?? ''} position="left" withArrow>
                            <Menu.Item
                                onClick={handleQuickSaveClicked}
                                icon={saveIcon}
                                sx={{ opacity: saveDisabled ? 0.7 : 1, '&:hover': { background: saveDisabled ? 'none' : undefined } }}
                            >
                                {saveLbl}
                            </Menu.Item>
                        </Tooltip>
                        <Tooltip label="Share, copy, rename, and delete Saved Views" position="left" withArrow>
                            <Menu.Item icon={<LayoutDashboard size={16} />} onClick={handleManageClicked}>
                                Manage Saved Views
                            </Menu.Item>
                        </Tooltip>
                    </Box>
                </Menu.Dropdown>
            </Menu>
            <DashboardConfigPanel
                onConfigChange={handleConfigChange}
                configKey={grid.currentOptions?.statePersistence?.key ?? ''}
                loadPrompt=""
                loadTitle="My Saved Views"
                savePrompt="Save a copy of the current view"
                saveTitle="Save View"
                title="Saved Views"
                userSettings={userSettings!}
                onClose={closePanel}
                opened={panelOpened}
                onLoad={handleLoad}
                onSave={handleSave}
            />
        </>
    );
}

export function useGridSave<T>(saveState?: () => T, loadState?: (state: T) => void, grid?: DataGridModel) {
    const { onStateLoaded, onStateSaving, save } = useMemo(() => {
        const result = {
            onStateSavingHandler: (config: IDashboardConfig) => {
                if (saveState) {
                    config.layout.push({ x: 0, y: 0, h: 0, w: 0, data: saveState() });
                }
                result.defaultOnStateSaving?.(config);
            },
            onStateLoadedHandler: (config?: IDashboardConfig) => {
                if (config?.layout?.length) {
                    const [, layout] = config?.layout;
                    if (layout?.data) {
                        if (loadState) {
                            loadState(layout.data);
                        }
                    }
                }
                result.defaultOnStateLoaded?.(config);
            },
            defaultOnStateLoaded: undefined as undefined | ((config?: IDashboardConfig) => void),
            defaultOnStateSaving: undefined as undefined | ((config: IDashboardConfig) => void),
            onStateSaving: (handler?: (config?: IDashboardConfig) => void) => {
                result.defaultOnStateSaving = handler;
                return result.onStateSavingHandler;
            },
            onStateLoaded: (handler?: (config?: IDashboardConfig) => void) => {
                result.defaultOnStateLoaded = handler;
                return result.onStateLoadedHandler;
            },
            save: () => {
                if (grid) {
                    grid.saveState(true);
                }
            },
        };
        return result;
    }, [grid, saveState, loadState]);

    return { onStateLoaded, onStateSaving, save };
}

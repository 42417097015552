/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Invoices.Host.Api
 * OpenAPI spec version: 1.0
 */

export type InvoiceTaggingRuleRuleType = typeof InvoiceTaggingRuleRuleType[keyof typeof InvoiceTaggingRuleRuleType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceTaggingRuleRuleType = {
  UntaggedCosts: 'UntaggedCosts',
  SharedCosts: 'SharedCosts',
  Discounts: 'Discounts',
  Marketplace: 'Marketplace',
  Advanced: 'Advanced',
} as const;

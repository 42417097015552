import { Address } from '@apis/Customers/model';
import { Select, SelectItem, Space, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useEffect, useState } from 'react';
import { World } from 'tabler-icons-react';
import { CountryData } from '../AccountSignup/CountryData';

export class CompanyAddressFields {
    public static AddressLine1: string = 'addressLine1';
    public static AddressLine2: string = 'addressLine2';
    public static City: string = 'city';
    public static State: string = 'state';
    public static Zip: string = 'zip';
    public static Country: string = 'country';
}

export function CompanyAddress<T>({
    prefix,
    form,
    isWithinPortal,
    requiredFields,
}: {
    prefix: string;
    form: UseFormReturnType<T>;
    isWithinPortal?: boolean;
    requiredFields?: string[];
}) {
    const addressLine1IsRequired = requiredFields != undefined && requiredFields.includes(CompanyAddressFields.AddressLine1);
    const addressLine2IsRequired = requiredFields != undefined && requiredFields.includes(CompanyAddressFields.AddressLine2);
    const cityIsRequired = requiredFields != undefined && requiredFields.includes(CompanyAddressFields.City);
    const stateIsRequired = requiredFields != undefined && requiredFields.includes(CompanyAddressFields.State);
    const zipIsRequired = requiredFields != undefined && requiredFields.includes(CompanyAddressFields.Zip);
    const countryIsRequired = requiredFields != undefined && requiredFields.includes(CompanyAddressFields.Country);

    return (
        <>
            <TextInput
                data-atid="AddressLineOneInput"
                required={addressLine1IsRequired}
                label="Address Line 1"
                {...form.getInputProps(prefix + 'AddressLine1')}
            />
            <TextInput
                data-atid="AddressLineTwoInput"
                required={addressLine2IsRequired}
                mt="md"
                label="Address Line 2 (optional)"
                {...form.getInputProps(prefix + 'AddressLine2')}
            />
            <TextInput data-atid="CityInput" mt="md" required={cityIsRequired} label="City" {...form.getInputProps(prefix + 'City')} />
            <Space h="md" />
            <CountryPicker {...form.getInputProps(prefix + 'CountryCode')} isRequired={countryIsRequired} isWithinPortal={isWithinPortal} />
            <div
                style={{
                    paddingBottom: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <TextInput
                    data-atid="StateInput"
                    mt="md"
                    required={stateIsRequired}
                    mr="md"
                    label="State (abbr)"
                    {...form.getInputProps(prefix + 'StateCode')}
                />
                <TextInput data-atid="ZipCodeInput" mt="md" required={zipIsRequired} label="Zip Code" {...form.getInputProps(prefix + 'ZipCode')} />
            </div>
        </>
    );
}

export function CountryPicker({
    value,
    onChange,
    isRequired,
    isWithinPortal,
}: {
    value: string;
    onChange: (country: string) => void;
    isRequired?: boolean;
    isWithinPortal?: boolean;
}) {
    const [countries, setCountries] = useState<{ value: string; label: string }[]>([]);
    useEffect(() => {
        const countries = CountryData.filter((c) => c.value !== 'US');
        countries.sort((a, b) => (a.text > b.text ? 1 : -1));
        let us = CountryData.find((c) => c.value === 'US');
        if (us) {
            countries.splice(0, 0, us);
        }
        setCountries(countries.map((country) => ({ value: country.value, label: country.text })));
        isWithinPortal = isWithinPortal ?? false;
        isRequired = isRequired ?? true;
    }, []);
    return (
        <Select
            withinPortal={isWithinPortal}
            data-atid="CountrySelect"
            placeholder="Select Country"
            icon={<World size={20} color="#369edb" />}
            searchable={true}
            label="Country"
            required={isRequired}
            value={value}
            onChange={onChange}
            data={countries}
        ></Select>
    );
}

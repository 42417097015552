import { Box, Divider, Group, Text, useMantineTheme } from '@mantine/core';
import { useObserver } from 'mobx-react';
import { AllocationRuleEditor } from '../Model';

import { TaggingRulePreviewGrid } from './TaggingRulePreviewGrid';
import { AllocationRulePreviewGrid } from './AllocationRulePreviewGrid';
import styled from '@emotion/styled';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';

export function RulePreviewContainer({ ruleEditor }: { ruleEditor: AllocationRuleEditor }) {
    const ruleType = useObserver(() => ruleEditor.rule?.RuleType);
    const fmtSvc = useDi(FormatService);
    return (
        <PreviewContainer>
            <Group px="lg" py="sm" position="apart">
                <PreviewTitle>Live Preview</PreviewTitle>
                <Box>
                    <Text size="xs" color="gray.6" align="right">
                        Billing Period
                    </Text>
                    <Text align="right" size="sm">
                        {fmtSvc.formatLongMonthYear(ruleEditor.month)}
                    </Text>
                </Box>
            </Group>
            <Divider />
            <Box sx={{ flex: 1, overflow: 'hidden', minWidth: 0 }} p="lg">
                {ruleType !== 'UntaggedCosts' ? (
                    <AllocationRulePreviewGrid ruleEditor={ruleEditor} />
                ) : (
                    <TaggingRulePreviewGrid ruleEditor={ruleEditor} />
                )}
            </Box>
        </PreviewContainer>
    );
}

export const PreviewContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    background-color: ${(p) => p.theme.colors.gray[2]};
    > div {
        background-image: linear-gradient(90deg, #0001, transparent 12px);
    }
`;
export const PreviewTitle = styled.div`
    font-size: 20px;
    font-weight: 600;
    color: #333;
`;

import { container } from 'tsyringe';
import { getCloudIntelligenceGetCustomerSubsidyRequestById } from '@apis/Customers';
import type { Company, CustomerSubsidyViewModel } from '@apis/Customers/model';
import { CompanyTenantPrereqService } from '@root/Components/Router/CompanyContent';
import { EventEmitter } from '@root/Services/EventEmitter';
import { DependencyContainer, inject, singleton } from 'tsyringe';

export const ICompanyContextToken = 'ICompanyContextToken';
container.register(ICompanyContextToken, { useValue: {} });

@singleton()
export class CompanyContextService<T> {
    public loading = new EventEmitter<boolean>(true);
    public unloaded = EventEmitter.empty();
    public customer?: T;
    public company?: Company;
    private nextCompanyId = 0;
    private container?: DependencyContainer;
    private disposer = () => {};
    private companyProvider?: () => Promise<{ customer: T; company: Company }>;

    public constructor(
        @inject(ICompanyContextToken) public readonly parentCompany: Company,
        @inject(CompanyTenantPrereqService) private readonly tenantPrereqSvc: CompanyTenantPrereqService
    ) {}

    public init(container: DependencyContainer, companyProvider: () => Promise<{ customer: T; company: Company }>) {
        this.loading.emit(true);
        this.container = container;
        this.companyProvider = companyProvider;
    }

    public async loadCompany(companyId: number) {
        this.loading.emit(true);
        try {
            this.nextCompanyId = companyId;
            if (this.company) {
                this.unload();
            }
            const company = await this.tryGetCompany();
            if (this.nextCompanyId === companyId && company) {
                this.loadCompanyContext(company);
                this.company = company;
            }
        } finally {
            if (this.nextCompanyId === companyId) {
                this.loading.emit(false);
            }
        }
    }

    private async tryGetCompany() {
        const { customer, company } = await this.companyProvider!();
        this.customer = customer;
        return company;
    }

    public unload() {
        this.loadCompanyContext(this.parentCompany);
        this.company = undefined;
        this.disposer();
        this.unloaded.emit();
    }

    public withParentCompany<T>(requestHandler: () => void) {
        let disposer: null | (() => void) = null;
        try {
            disposer = this.tenantPrereqSvc.setCompanyId(this.parentCompany.Id);
            requestHandler();
        } finally {
            disposer?.();
        }
    }

    public async getCustomer(customerId: number) {
        let resolve = (_: CustomerSubsidyViewModel) => {};
        const result = new Promise<CustomerSubsidyViewModel>((r) => (resolve = r));
        this.withParentCompany(() => getCloudIntelligenceGetCustomerSubsidyRequestById({ customerId }).then(resolve));
        return result;
    }

    private loadCompanyContext(company: Company) {
        this.container?.register(ICompanyContextToken, { useValue: company });

        const disposer = this.tenantPrereqSvc.setCompanyId(company.Id);
        if (!this.disposer) {
            this.disposer = disposer;
        }
    }
}

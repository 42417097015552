import styled from '@emotion/styled';
import { DataGrid } from '@root/Components/DataGrid';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { ColumnConfig } from '@root/Components/DataGrid/Models';
import { ActionIcon, Anchor, Box, Group, Loader, LoadingOverlay, Menu, Text, Tooltip, useMantineTheme } from '@mantine/core';
import { useState, useMemo, useEffect, ReactNode, useCallback } from 'react';
import { observer } from 'mobx-react';
import { TagAutomationRuleEvents, TagAutomationRuleService } from '@root/Site/TagManager/TagAutomation/Components/TagAutomationRuleService';
import { TagAutomationRule, TagAutomationRuleParametersType, TagAutomationRuleStatus, TagAutomationTraceByRule } from '@apis/TagManager/model';
import { CustomColors, theme } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useNav } from '@root/Services/NavigationService';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { useLink } from '@root/Services/Router/Router';
import { GridFullCell } from '@root/Components/DataGrid/Design';
import { Activity, CircleCaretRight, CircleCheck, CircleX, Edit, Refresh, Trash } from 'tabler-icons-react';
import { openConfirmModal } from '@mantine/modals';
import { IFluentOperators, queryBuilder, ValuesGroupOtherText } from '@root/Services/QueryExpr';
import { postTagAutomationQueryTraceByRule } from '@apis/TagManager';
import { Clearfix } from '@root/Design/Primitives';
import { addDays } from 'date-fns';

interface IRuleGridProps {
    renderToolbar: () => ReactNode;
    dateFilter: { from?: Date; to?: Date };
}

function StatusPopover({
    status,
    icon,
    color,
    endDate,
}: {
    status: TagAutomationRuleStatus | undefined;
    endDate?: string;
    icon: string;
    color: string | undefined;
}) {
    const fmtSvc = useDi(FormatService);
    const theme = useMantineTheme();
    const date = status === 'Test' && endDate ? ` until ${fmtSvc.toShortDate(fmtSvc.toLocalDate(endDate))}` : '';
    return (
        <Tooltip
            offset={0}
            withinPortal
            position="bottom"
            color={'#fff' as CustomColors}
            sx={{ border: `solid 1px ${theme.colors.gray[3]}` }}
            label={
                <Text size="sm" color={color as CustomColors}>
                    {status}
                    {date}
                </Text>
            }
        >
            <div>
                <i className={icon} style={{ color: color, fontSize: '18px' }}></i>
            </div>
        </Tooltip>
    );
}

type ActivityTypeCount = Partial<Record<'Test' | 'Run' | 'Issues' | 'Pending', number>>;
type ActivityRollup = Map<number, ActivityTypeCount>;

type EventsCellProps = {
    rule: TagAutomationRule;
    loading: EventEmitter<boolean>;
    events: ActivityRollup;
    dateFilter: EventEmitter<{ from?: Date; to?: Date }>;
    type: 'Test' | 'Run' | 'Issues';
};
function EventsCell({ rule, loading, events, dateFilter, type }: EventsCellProps) {
    const fmtSvc = useDi(FormatService);
    const theme = useMantineTheme();
    const testCount = events.get(rule.Id ?? 0)?.Test ?? 0;
    const runCount = events.get(rule.Id ?? 0)?.Run ?? 0;
    const issueCount = events.get(rule.Id ?? 0)?.Issues ?? 0;
    const isLoading = useEventValue(loading);
    const { getDescendUrl } = useNav();

    const { from, to } = useEventValue(dateFilter) ?? {};
    const filter = { from: from ? fmtSvc.toJsonShortDate(from) : '', to: to ? fmtSvc.toJsonShortDate(addDays(to, -1)) : '' };
    const url = getDescendUrl('tag-automation-rule-activity', { ruleId: rule.Id?.toString() ?? '', ...filter });

    const actionCount = type === 'Issues' ? issueCount : type === 'Test' ? testCount : runCount;
    const color =
        type === 'Issues' && issueCount > 0
            ? theme.colors.error[6]
            : type === 'Test' && actionCount > 0
            ? theme.colors.warning[6]
            : actionCount > 0
            ? theme.colors.success[6]
            : theme.colors.primary[6];
    const actionLabel = type === 'Run' ? 'Changes' : type === 'Test' ? 'Tests' : 'Issues';
    const actionText = actionCount > 0 ? `${fmtSvc.formatInt(actionCount)} ${actionLabel}` : `No ${actionLabel}`;
    const icon = type === 'Issues' && issueCount > 0 ? 'x' : issueCount === 0 ? 'play' : 'check';

    return (
        <Box sx={{ textAlign: 'center', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {isLoading ? <Loader size="xs" /> : <EventCellButton url={url} color={color} text={actionText} icon={icon} />}
        </Box>
    );
}

const EventsFakeButton = styled.a<{ eventColor: string }>`
    border: 1px solid ${({ eventColor }) => eventColor};
    border-radius: 5px;
    color: ${({ eventColor }) => eventColor};
    font-size: 12px;
    positon: relative;
    top: 10px;
    height: 20px;
    text-decoration: none;
    width: 135px;
    display: inline-flex;
    line-height: 1.1;
    padding: 2px 4px;
    transition: all 0.2s ease-in-out;

    &:hover {
        text-decoration: none !important;
        color: #fff;
        background-color: ${({ eventColor }) => eventColor};
    }
`;

function EventCellButton({ url, color, text, icon }: { url: string; color: string; text: string; icon: 'play' | 'x' | 'check' }) {
    const link = useLink();
    return (
        <EventsFakeButton {...link(url)} eventColor={color}>
            <div style={{ textAlign: 'center', flex: 1 }}>{text}</div>
            <div>{icon == 'check' ? <CircleCheck size={14} /> : icon == 'x' ? <CircleX size={14} /> : <CircleCaretRight size={14} />}</div>
            <Clearfix />
        </EventsFakeButton>
    );
}

function RuleGrid({ renderToolbar, dateFilter }: IRuleGridProps) {
    const [grid, setGrid] = useState<DataGridModel>();
    const nav = useNav();
    const formatSvc = useDi(FormatService);
    const tagAutomationRulesSvc = useDi(TagAutomationRuleService);
    const ruleEvents = useDi(TagAutomationRuleEvents);
    const data: TagAutomationRule[] = tagAutomationRulesSvc.rules.slice().sort((a, b) => {
        if (a.Status! < b.Status!) return -1;
        if (a.Status! > b.Status!) return 1;
        return 0;
    });
    const link = useLink();
    const tagPermissions = useAuthZValues({
        canEdit: { TagAutomation: 'Edit' },
    });
    const activityRollup = useMemo(
        () => ({
            loading: new EventEmitter<boolean>(true),
            events: new Map<number, ActivityTypeCount>(),
            dateFilter: new EventEmitter<{ from?: Date; to?: Date }>(dateFilter),
        }),
        []
    );

    const loadStats = useCallback(async (dateFilter: { from?: Date; to?: Date }) => {
        activityRollup.dateFilter.emit(dateFilter);
        activityRollup.loading.emit(true);
        try {
            if (dateFilter?.from && dateFilter?.to) {
                const results = await queryBuilder<Omit<TagAutomationTraceByRule, 'Timestamp'> & { Timestamp: Date }>()
                    .where((b) => {
                        const filters: IFluentOperators<boolean>[] = [];
                        if (dateFilter.from) {
                            filters.push(b.model.Timestamp!.onOrAfter(dateFilter.from));
                        }
                        if (dateFilter.to) {
                            filters.push(b.model.Timestamp!.before(dateFilter.to));
                        }
                        return filters.length ? b.and(...filters) : b.model.RuleId!.isNotNull();
                    })
                    .select((b) => ({
                        ruleId: b.model.RuleId!,
                        status: b.model.Status,
                        jobStatus: {
                            Operation: 'values',
                            Operands: [{ Field: 'JobStatus' }, { Value: '' }, { Value: ValuesGroupOtherText }],
                        } as unknown as string,
                        mode: b.model.Mode,
                        changes: b.count(),
                    }))
                    .execute(postTagAutomationQueryTraceByRule);

                activityRollup.events.clear();
                results.Results?.reduce((result, item) => {
                    let count = result.get(item.ruleId ?? 0);
                    if (!count) {
                        result.set(item.ruleId ?? 0, (count = {}));
                    }
                    const countKey =
                        item.status !== 'Sent'
                            ? 'Issues'
                            : item.mode === 'Test'
                            ? 'Test'
                            : item.jobStatus !== ValuesGroupOtherText && item.jobStatus !== 'Succeeded'
                            ? 'Issues'
                            : item.jobStatus === ValuesGroupOtherText
                            ? 'Pending'
                            : 'Run';

                    count[countKey] = (count[countKey] ?? 0) + item.changes;
                    return result;
                }, activityRollup.events);
            }
        } finally {
            activityRollup.loading.emit(false);
        }
    }, []);

    useEffect(() => {
        loadStats(dateFilter);
    }, [dateFilter?.from, dateFilter?.to]);

    useEffect(() => {
        tagAutomationRulesSvc.refreshRules();
    }, []);

    const reload = useCallback(() => {
        tagAutomationRulesSvc.refreshRules();
        loadStats(dateFilter);
    }, [dateFilter]);
    useEvent(ruleEvents.ruleSaved, reload);

    const deleteRule = async (rule: TagAutomationRule) => {
        openConfirmModal({
            title: 'Confirm Rule Deletion',
            children: (
                <>
                    Are you sure you want to delete rule <strong>{rule.RuleName}?</strong>
                </>
            ),
            labels: {
                confirm: 'Yes, Delete',
                cancel: 'Cancel',
            },
            onConfirm: () => tagAutomationRulesSvc.deleteRule(rule),
        });
    };

    const editRule = (rule: TagAutomationRule) => {
        const ruleId = rule.Id ? rule.Id.toString() : '';
        nav.descend('automation-rule', { ruleId });
    };
    const viewActivity = (rule: TagAutomationRule) => {
        nav.descend('tag-automation-rule-activity', { ruleId: rule.Id?.toString() ?? '' });
    };

    const openRuleDetails = (rule: TagAutomationRule) => {
        tagAutomationRulesSvc.openDetailsDrawer(rule);
    };

    const columns = useMemo(() => {
        return [
            {
                header: '',
                accessor: '',
                defaultWidth: 40,
                id: 'actions',
                noResize: true,
                noReorder: true,
                defaultFixed: true,
                cellRenderer: (item) => {
                    return (
                        tagPermissions.canEdit && (
                            <GridFullCell style={{ padding: 0, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <Menu shadow="md" position="bottom-start" withinPortal offset={0} withArrow width={160}>
                                    <Menu.Target>
                                        <ActionIcon>
                                            <i className="ti ti-dots-vertical" color={theme.colors?.primary?.[6]}></i>
                                        </ActionIcon>
                                    </Menu.Target>
                                    <Menu.Dropdown>
                                        <Menu.Item icon={<Edit size={16} />} onClick={() => editRule(item)}>
                                            Edit
                                        </Menu.Item>
                                        <Menu.Item icon={<Activity size={16} />} onClick={() => viewActivity(item)}>
                                            View Activity
                                        </Menu.Item>
                                        <Menu.Item icon={<Trash size={16} />} onClick={() => deleteRule(item)}>
                                            Delete
                                        </Menu.Item>
                                    </Menu.Dropdown>
                                </Menu>
                            </GridFullCell>
                        )
                    );
                },
            },
            {
                header: 'Status',
                accessor: (item) => item.Status,
                defaultWidth: 70,
                id: 'Status',
                allowGrouping: false,
                align: 'center',
                noResize: true,
                noReorder: true,
                defaultFixed: true,
                filter: {
                    filterField: 'Status',
                    filterType: 'string',
                    name: 'Status',
                    options: {
                        getValueProvider: () =>
                            (Object.keys(TagAutomationRuleStatus) as Array<keyof typeof TagAutomationRuleStatus>).map((key) => ({
                                value: key,
                                label: key,
                            })),
                    },
                },
                cellRenderer: (item) => {
                    let color: string | undefined = 'green';
                    let icon: string = 'ti ti-circle-filled';

                    switch (item.Status) {
                        case TagAutomationRuleStatus.Active:
                            color = theme.colors?.success?.[5];
                            icon = 'ti ti-circle-filled';
                            break;
                        case TagAutomationRuleStatus.Draft:
                            color = theme.colors?.success?.[5];
                            icon = 'ti ti-circle-dashed';
                            break;
                        case TagAutomationRuleStatus.Test:
                            color = theme.colors?.warning?.[5];
                            icon = 'ti ti-circle-dashed';
                            break;
                        case TagAutomationRuleStatus.Deleted:
                            color = theme.colors?.gray?.[5];
                            icon = 'ti ti-square';
                            break;
                        default:
                            break;
                    }
                    return <StatusPopover color={color} icon={icon} endDate={item.TestEndDate ?? ''} status={item.Status} />;
                },
            },
            {
                header: 'Rule Type',
                accessor: (item) => item.Parameters?.Type,
                defaultWidth: 200,
                noResize: true,
                noReorder: true,
                defaultFixed: true,
                sortField: 'RuleType',
                filter: {
                    filterField: 'Parameters.Type',
                    filterType: 'string',
                    name: 'Rule Type',
                    options: {
                        getValueProvider: () =>
                            (Object.keys(TagAutomationRuleParametersType) as Array<keyof typeof TagAutomationRuleParametersType>).map((key) => ({
                                value: key,
                                label: formatSvc.userFriendlyCamelCase(key),
                            })),
                    },
                },
                cellRenderer: (item) => <>{!!item.Parameters?.Type ? formatSvc.userFriendlyCamelCase(item.Parameters.Type) : ''}</>,
                id: 'ruleType',
            },
            {
                header: 'Name',
                accessor: (item) => item.RuleName,
                defaultWidth: 200,
                id: 'RuleName',
                sortField: 'RuleName',
                filter: {
                    filterField: 'RuleName',
                    filterType: 'string',
                    name: 'Name',
                },
                cellRenderer: (item) => {
                    return <Anchor onClick={() => openRuleDetails(item)}>{item.RuleName}</Anchor>;
                },
            },
            {
                header: 'Description',
                accessor: (item) => item.RuleDescription,
                defaultWidth: 300,
                id: 'RuleDescription',
                sortField: 'RuleDescription',
                filter: {
                    filterField: 'RuleDescription',
                    filterType: 'string',
                    name: 'Description',
                },
            },
            {
                header: 'Changes',
                accessor: '',
                defaultWidth: 165,
                align: 'center',
                id: 'events',
                cellRenderer: (item) => (
                    <EventsCell
                        type={item.Status === 'Test' ? 'Test' : 'Run'}
                        events={activityRollup.events}
                        dateFilter={activityRollup.dateFilter}
                        loading={activityRollup.loading}
                        rule={item}
                    />
                ),
            },
            {
                header: 'Issues',
                accessor: '',
                defaultWidth: 165,
                align: 'center',
                id: 'issues',
                cellRenderer: (item) => (
                    <EventsCell
                        type="Issues"
                        events={activityRollup.events}
                        dateFilter={activityRollup.dateFilter}
                        loading={activityRollup.loading}
                        rule={item}
                    />
                ),
            },
        ] as ColumnConfig<TagAutomationRule>[];
    }, [grid, data]);

    return tagAutomationRulesSvc.loading.dashboard ? (
        <Box sx={{ position: 'relative', height: '500px' }}>
            <LoadingOverlay overlayOpacity={0} visible />
        </Box>
    ) : (
        <DataGrid
            rightTopPlaceHolder={
                <Group>
                    <Tooltip label="Refresh">
                        <ActionIcon onClick={reload}>
                            <Refresh size={20} />
                        </ActionIcon>
                    </Tooltip>
                    {renderToolbar()}
                </Group>
            }
            rowStyle={(item, index, hovered) => ({
                backgroundColor:
                    item.Status == TagAutomationRuleStatus.Draft
                        ? hovered
                            ? theme.colors?.primary?.[2]
                            : theme.colors?.primary?.[1]
                        : item.Status == TagAutomationRuleStatus.Test
                        ? hovered
                            ? theme.colors?.warning?.[2]
                            : theme.colors?.warning?.[1]
                        : hovered
                        ? theme.colors?.gray?.[2]
                        : '#fff',
            })}
            selectionMode="none"
            disableHighlight
            dataSource={data}
            statePersistence={{ key: 'tag-rule-grid' }}
            columns={columns}
            hideHeader
            itemHeight={33}
            onModelLoaded={setGrid}
        />
    );
}

export default observer(RuleGrid);

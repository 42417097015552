import { CompanyTenantPrereqService } from '@root/Components/Router/CompanyContent';

export class BaseCacheByTenant<T> {
    private tenantCache: Map<number, Promise<any>> = new Map<number, Promise<any>>();

    public constructor(protected tenantPrereqSvc: CompanyTenantPrereqService) {
        this.tenantPrereqSvc.handleCacheInvalidate((companyId) => this.invalidate(companyId));
    }

    public invalidate(tenantId: number) {
        this.tenantCache.delete(tenantId);
    }

    public get(tenantId: number, callback: () => Promise<T>) {
        if (!this.tenantCache.has(tenantId)) {
            this.tenantCache.set(tenantId, this.tenantPrereqSvc!.withCompanyId(tenantId, callback));
        }
        return this.tenantCache.get(tenantId)! as Promise<T>;
    }
}

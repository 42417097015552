import { ActionIcon, Box, Card, Collapse, Divider, Group, Space, Stack, Text, Tooltip, useMantineTheme } from '@mantine/core';
import { Fragment, ReactNode } from 'react';
import { colorPalette } from '@root/Design/Themes';
import { useToggle } from '@root/Services/EventEmitter';
import { ChevronDown, ChevronRight, Refresh } from 'tabler-icons-react';
import styled from '@emotion/styled';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';

interface CollapsibleDetailsSectionProps {
    label: string;
    defaultCollapsed?: boolean;
    collapsible?: boolean;
}
export function CollapsibleDetailsSection(props: CollapsibleDetailsSectionProps & ResourceDetailSectionProps) {
    const { label, defaultCollapsed, collapsible, children, ...sectionProps } = props;
    const stuck = collapsible === false;
    const [open, { toggle }] = useToggle(!(defaultCollapsed ?? false));

    return (
        <ResourceDetailSection {...sectionProps}>
            <CollapsibleSectionHeader mode={stuck ? 'stuck' : open ? 'expanded' : 'collapsed'} onClick={toggle}>
                <span>{label}</span>
                <ChevronDown size={18} />
            </CollapsibleSectionHeader>
            <Collapse in={open}>
                <Divider color="gray.3" />
                {children}
            </Collapse>
        </ResourceDetailSection>
    );
}

interface ResourceDetailSectionProps {
    children: ReactNode;
    color?: 'gray' | 'white';
    shadow?: boolean;
}
export function ResourceDetailSection({ children, color, shadow }: ResourceDetailSectionProps) {
    const theme = useMantineTheme();
    const bg = color === 'gray' ? theme.colors.gray[1] : theme.white;
    return (
        <Card shadow={shadow === false ? undefined : 'md'} p={0} radius="md" sx={{ backgroundColor: bg }} withBorder>
            {children}
        </Card>
    );
}

export function NoDataMessage({ children }: { children: ReactNode }) {
    return (
        <Text m="lg" align="center">
            {children}
        </Text>
    );
}

export const SummarySection = styled.div<{ cols?: number }>`
    display: grid;
    grid-template-columns: repeat(${(p) => p.cols}, 1fr);
    gap: ${(p) => p.theme.spacing.sm}px;
`;
SummarySection.defaultProps = { cols: 3 };

export const CollapsibleSectionEl = styled.div``;

export const CollapsibleSectionHeader = styled.div<{ mode: 'expanded' | 'collapsed' | 'stuck' }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: ${(p) => (p.mode === 'stuck' ? 'default' : 'pointer')};
    min-height: 40px;
    padding: 0 ${(p) => p.theme.spacing.sm}px;
    &:hover {
        background: ${(p) => (p.mode === 'stuck' ? 'none' : p.theme.colors.primary[1])};
    }
    svg {
        rotate: ${(p) => (p.mode === 'expanded' ? '180deg' : '0deg')};
        transition: all 300ms;
    }
`;

export function ResourceDetailContainer({ children }: { children: ReactNode }) {
    return <Stack p="lg">{children}</Stack>;
}

export const ResourceHistoryRowEl = styled.div<{ state: 'opened' | 'closed' }>`
    display: grid;
    align-items: center;
    grid-template-columns: 200px 1fr 30px;
    background-color: ${(p) => (p.state === 'opened' ? p.theme.colors.primary[0] : 'transparent')};
`;
export const ResourceHistoryRowHeaderEl = styled(ResourceHistoryRowEl)<{ state: 'opened' | 'closed' }>`
    &:hover {
        cursor: pointer;
        background-color: ${(p) => p.theme.colors.primary[1]};
    }
    svg {
        rotate: ${(p) => (p.state === 'opened' ? '180deg' : '0deg')};
        transition: all 300ms;
    }
    background-color: ${(p) => (p.state === 'opened' ? p.theme.colors.primary[1] : 'transparent')};
    height: 40px;
`;

export function ResourceHistoryToolbar(props: { title: string; loadedAt: Date | undefined; reload: () => void; items: number }) {
    const fmtSvc = useDi(FormatService);
    const { title, loadedAt, reload, items } = props;
    return (
        <>
            <Group position="apart" px="md" py="sm">
                <Text size="sm">{title}</Text>
                <Space sx={{ flex: 1 }} />
                <Text>{!loadedAt ? 'Loading...' : `${fmtSvc.formatInt0Dec(items)} results as of ${fmtSvc.timeAgo(loadedAt)}`}</Text>
                <Tooltip label={!loadedAt ? 'Loading...' : 'Refresh'} position="left">
                    <ActionIcon disabled={!loadedAt} className={!loadedAt ? 'ti-rotate-2' : undefined} onClick={reload}>
                        <Refresh size={18} />
                    </ActionIcon>
                </Tooltip>
            </Group>
            <Divider color="gray.3" />
        </>
    );
}

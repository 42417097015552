import { getMspConfigGetMspConfigByHost } from '@apis/Customers';
import { singleton } from 'tsyringe';
import { createStyles, MantineTheme } from '@mantine/core';
import { theme } from '@root/Design/Themes';
import { EventEmitter } from './EventEmitter';
import { MspConfig as BaseMspConfig } from '@apis/Customers/model';

type MspConfig = BaseMspConfig & {
    LogoPath?: string;
    IconPath?: string;
    WhiteLabelJson?: string;
    ColorPalette: ColorPalette;
    isWhiteLabelEnabled: boolean;
    RedirectUrl?: string;
    SupportEmail?: string;
    DisplayName?: string;
};

type MspSupportAndLogos = {
    SupportEmail: string;
    CompanyName: string;
    Logo: string;
};

type ColorPalette = {
    other: {} | undefined;
    colors: { gray: string[]; primary: string[]; warning: string[]; error: string[]; success: string[] };
};

@singleton()
export class MspService {
    public config?: MspConfig;
    public theme?: MantineTheme;
    public themeReady = new EventEmitter(false);
    public defaultNavColors = {
        LeftNavAppBackgroundColor: '#002334',
        LeftNavBgColor: '#002334',
        LeftNavCollapserColor: '#00609F',
        LeftNavHoverColor: '#667085',
        LeftNavLogoBg: '#011a29',
        LeftNavColorMode: 'dark' as 'light' | 'dark',
        CollapseNavAndLockColor: '#F9FAFB',
        LeftNavChildBgColor: '#101828',
        LeftNavCollapseCaretColor: '#2778AE',
        LeftNavDividerColor: '#344054',
        LeftNavHoverText: '#F9FAFB',
        LeftNavMainBackground: '#344054',
        LeftNavMainAndSelectedCollapsedBgColor: '#186483',
        LeftNavTextColor: '#F9FAFB',
        LowerNavSelected: '#344054',
        OverviewHover: '#31C2FF',
        SecondaryNavBackground: '#009FE1',
        SettingsNavHoverAndSelectedBgColor: '#EFFAFF',
        SettingsNavHoverAndSelectedTextColor: '#0075C1',
        TopNavTextColor: '#FFFFFF',
        CollapseNavCaretBackground: '#002334',
    };
    public defaultSupportAndLogos = {
        Logo: '/assets/cloudsaver-logo-2024-blue.svg',
        CompanyName: 'CloudSaver',
        SupportEmail: 'mailto:support@cloudsaver.com',
    };
    public mspSupportAndLogos: MspSupportAndLogos = this.defaultSupportAndLogos;

    public async init() {
        try {
            var domain = window.location.hostname;
            var config = (await getMspConfigGetMspConfigByHost({ host: domain })) as MspConfig;
            if (config === undefined || !config.isWhiteLabelEnabled) {
                theme.other! = this.defaultNavColors as object;
                return;
            }
            const supportInfo = {
                CompanyName: config.DisplayName ?? this.defaultSupportAndLogos.CompanyName,
                Logo: config.LogoPath ?? this.defaultSupportAndLogos.Logo,
                SupportEmail: config.SupportEmail ?? this.defaultSupportAndLogos.SupportEmail,
            } as MspSupportAndLogos;

            this.mspSupportAndLogos = supportInfo;

            config.ColorPalette = (await JSON.parse(config?.WhiteLabelJson ?? '{}')) as ColorPalette;
            if (config.ColorPalette === undefined) {
                return;
            }
            theme.colors!.gray! = [];
            config.ColorPalette.colors.gray.forEach((a) => theme.colors!.gray!.push(a));
            theme.colors!.primary! = [];
            config.ColorPalette.colors.primary.forEach((a) => theme.colors!.primary!.push(a));
            theme.colors!.error! = [];
            config.ColorPalette.colors.error.forEach((a) => theme.colors!.error!.push(a));
            theme.colors!.warning! = [];
            config.ColorPalette.colors.warning.forEach((a) => theme.colors!.warning!.push(a));
            theme.colors!.success! = [];
            config.ColorPalette.colors.success.forEach((a) => theme.colors!.success!.push(a));
            theme.other! = config.ColorPalette.other as object;
            this.config = config;
        } finally {
            this.themeReady.emit(true);
        }
    }

    public companyListChanged = EventEmitter.empty();

    public notifyCompanyListChanged() {
        this.companyListChanged.emit();
    }
    public newAccountAdded = EventEmitter.empty();

    public notifyNewAccount() {
        this.newAccountAdded.emit();
    }
}

const useStyles = createStyles((theme) => ({}));

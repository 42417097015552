import { getCreditsGetCreditSummary } from '@apis/Customers';
import { Box, Card, Grid, Space, Text, useMantineTheme } from '@mantine/core';
import { useCompany } from '@root/Components/Router/CompanyContent';
import {
    CompanyFeaturesProvider,
    useCompanyFeaturesParentContext,
    UserAccessContextProvider,
    useUserAccessContext,
} from '@root/Components/Shell/AppFeatureAccess';
import { colorPalette } from '@root/Design/Themes';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useLink } from '@root/Services/Router/Router';
import { useEffect, useState } from 'react';
import { SettingsPage, SettingsPageHeader } from './SettingsPage';

export function SummaryCard(props: { iconClass: string; header: string; text: string; navigateTo: string; atid: string }) {
    const theme = useMantineTheme();
    const link = useLink();
    const { getMoveUrl } = useNav();
    return (
        <Card
            id={props.header}
            withBorder
            shadow="xs"
            color={colorPalette.white}
            component="a"
            {...link(getMoveUrl(props.navigateTo))}
            radius="lg"
            sx={{
                minHeight: '135px',
                cursor: 'pointer',
                margin: '10px 10px 10px 0',
                backgroundColor: colorPalette.white,
                borderColor: theme.colors?.gray?.[3],
                '&:hover': {
                    borderColor: theme.colors?.primary?.[5],
                },
            }}
        >
            <Box data-atid={props.atid} style={{ textDecoration: 'none' }}>
                <Text weight={700} style={{ color: theme.black }}>
                    <i className={props.iconClass} />
                    &nbsp; {props.header}
                </Text>
                <Space h="sm" />
                <Text size="sm" color="dimmed">
                    {props.text}
                </Text>
            </Box>
        </Card>
    );
}

export function SettingsOverview() {
    const company = useCompany();
    return (
        <UserAccessContextProvider>
            {() => (company?.Type === 'StrategicPartner' ? <StrategicPartnerSettingsOverview /> : <CustomerSettingsOverview />)}
        </UserAccessContextProvider>
    );
}
endpoint('settings-overview', SettingsOverview, 'Settings');
endpoint('msp-settings-overview', SettingsOverview, 'MSP Settings');

function CustomerSettingsOverview() {
    const theme = useMantineTheme();

    const currentCompany = useCompany();
    const isSupport = !(currentCompany?.Type === 'Customer' || !currentCompany?.Type);

    const accessLookup = useUserAccessContext();
    const hasFeature = (feature: string) => accessLookup.checkAccessByName(feature);

    return (
        <SettingsPage>
            <SettingsPageHeader text="Overview" />
            <Space h="lg" />
            <Box style={{ margin: '26px', maxWidth: theme.breakpoints.lg }}>
                {hasFeature('Users') ||
                hasFeature('Roles & Permissions') ||
                !hasFeature('Cloud Connections') ||
                hasFeature('Single Sign-On') ||
                hasFeature('Company Info') ||
                hasFeature('Payment Methods') ? (
                    <>
                        <Grid>
                            {!hasFeature('Users') ? null : (
                                <Grid.Col md={6} lg={3}>
                                    <SummaryCard
                                        iconClass="ti ti-users"
                                        header="Users"
                                        text="Invite and manage users"
                                        navigateTo="user-admin"
                                        atid="OverViewUsers"
                                    />
                                </Grid.Col>
                            )}
                            {!hasFeature('Roles & Permissions') ? null : (
                                <Grid.Col md={6} lg={3}>
                                    <SummaryCard
                                        iconClass="ti ti-lock"
                                        header="Roles & Permissions"
                                        text="Add roles and permissions"
                                        navigateTo="user-roles"
                                        atid="OverViewRoles"
                                    />
                                </Grid.Col>
                            )}
                            {isSupport || !hasFeature('Cloud Connections') ? null : (
                                <Grid.Col md={6} lg={3}>
                                    <SummaryCard
                                        iconClass="ti ti-vector-bezier"
                                        header="Connections"
                                        text="Add connections to your cloud"
                                        navigateTo="connections"
                                        atid="OverViewConnections"
                                    />
                                </Grid.Col>
                            )}
                            {isSupport || !hasFeature('Connection Health') ? null : (
                                <Grid.Col md={6} lg={3}>
                                    <SummaryCard
                                        iconClass="ti ti-checkup-list"
                                        header="Connection Health"
                                        text="Resolve connection issues and access latest permission policies"
                                        navigateTo="connection-health"
                                        atid="OverViewConnectionHealth"
                                    />
                                </Grid.Col>
                            )}
                            {isSupport || !hasFeature('Users') ? null : (
                                <Grid.Col md={6} lg={3}>
                                    <SummaryCard
                                        iconClass="ti ti-list-check"
                                        header="Activity Log"
                                        text="View past tag actions"
                                        navigateTo="settings-activity-log"
                                        atid="OverViewActivityLog"
                                    />
                                </Grid.Col>
                            )}

                            {!hasFeature('Single Sign-On') ? null : (
                                <Grid.Col md={6} lg={3}>
                                    <SummaryCard
                                        iconClass="ti ti-cloud-lock"
                                        header="Single Sign-On"
                                        text="Configure Single Sign-On for your organization"
                                        navigateTo="sso"
                                        atid="OverViewSSO"
                                    />
                                </Grid.Col>
                            )}

                            {!hasFeature('Company Info') ? null : (
                                <Grid.Col md={6} lg={3}>
                                    <SummaryCard
                                        iconClass="ti ti-building-skyscraper"
                                        header="Company Info"
                                        text="Manage your company details"
                                        navigateTo="company-info"
                                        atid="OverViewCompanyInfo"
                                    />
                                </Grid.Col>
                            )}
                            {isSupport || !hasFeature('Payment Methods') ? null : (
                                <Grid.Col md={6} lg={3}>
                                    <SummaryCard
                                        iconClass="ti ti-credit-card"
                                        header="Payment Methods"
                                        text="Set your primary payment method"
                                        navigateTo="payment-methods"
                                        atid="OverViewPaymentMethods"
                                    />
                                </Grid.Col>
                            )}
                        </Grid>
                        <Space h="lg" />
                    </>
                ) : null}
            </Box>
        </SettingsPage>
    );
}

function StrategicPartnerSettingsOverview() {
    const theme = useMantineTheme();
    const { users } = useAuthZValues({
        users: { User: 'View' },
    });

    return (
        <SettingsPage>
            <SettingsPageHeader text="Overview" />
            <Space h="lg" />
            <Box style={{ margin: '26px', maxWidth: theme.breakpoints.lg }}>
                {users ? (
                    <>
                        <Grid>
                            {!users ? null : (
                                <Grid.Col md={6} lg={3}>
                                    <SummaryCard
                                        iconClass="ti ti-users"
                                        header="Users"
                                        text="Invite and manage users"
                                        navigateTo="user-admin"
                                        atid="SettingsUsersInviteCardStrategicPartner"
                                    />
                                </Grid.Col>
                            )}
                        </Grid>
                        <Space h="lg" />
                    </>
                ) : null}
            </Box>
        </SettingsPage>
    );
}

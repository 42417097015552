import { singleton } from 'tsyringe';

interface IConfig {
    apis: Record<string, { baseUrl: string }>;
    loginUrl: string;
    logoutUrl: string;
    chargebee: {
        site: string;
        publishableKey: string;
    };
    monitoring: {
        dynatraceUrl: string;
        datadog: {
            clientToken: string;
            site: string;
            applicationId: string;
        };
    };
    ssoDocumentationUrl: string;
}

@singleton()
export class ConfigService {
    public readonly config: IConfig;
    public constructor() {
        // window.config is populated by sync http request in index.html
        this.config = (window as any).config;
    }
}

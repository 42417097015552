import { QueryExpr, QueryOperation } from '@apis/Resources';
import { IQueryExpr, QuerySortExpr } from '@apis/Resources/model';

export interface IValueProvider {
    field: string;
    type: string;
    getValue(item: any): any;
}

interface ArrayDataSourceState {
    filters: IQueryExpr[];
    sort: QuerySortExpr[];
}

export class ArrayDataSource {
    private valueProviders = new Map<string, IValueProvider>();
    public constructor(public items: any[], valueProviders: IValueProvider[] = []) {
        for (const valueProvider of valueProviders) {
            this.valueProviders.set(valueProvider.field, valueProvider);
        }
    }
    protected filteredItems: any[] = [];
    public async getPage(start: number, end: number, state: ArrayDataSourceState, parent?: any) {
        if (parent) {
            throw new Error('Grid is misconfigured, an array-based datasource cannot provide children. ');
        }
        this.applyState(state);
        return {
            items: this.filteredItems,
            total: this.filteredItems.length,
        };
    }
    public filter(filter: IQueryExpr[]) {
        return this.applyFilter(this.items, filter);
    }
    public applyState(state: ArrayDataSourceState) {
        let filteredItems = this.items.slice();
        this.applySort(filteredItems, state.sort);
        filteredItems = this.applyFilter(filteredItems, state.filters);
        return (this.filteredItems = filteredItems);
    }

    public setValueProviders(valueProviders: IValueProvider[]) {
        this.valueProviders.clear();
        for (const valueProvider of valueProviders) {
            this.valueProviders.set(valueProvider.field, valueProvider);
        }
    }

    public getValueProviders() {
        return Array.from(this.valueProviders.values());
    }

    private applyFilter(items: any[], filters: IQueryExpr[]) {
        const filterHandler = this.createFilterHandler(filters);
        return items.filter(filterHandler);
    }

    private createFilterHandler(filters: IQueryExpr[]): (item: any) => boolean {
        return this.createFilter({ Operation: 'and', Operands: filters });
    }

    private createFilter(expr: QueryOperation): (item: any) => boolean {
        switch (expr.Operation.toLowerCase()) {
            case 'and':
                return this.createAnd(expr.Operands as QueryExpr[]);
            case 'or':
                return this.createOr(expr.Operands as QueryExpr[]);
            case 'not':
                return this.createNot(expr.Operands[0] as QueryExpr);
            case 'isnull':
                return this.createNullCheck(expr.Operands[0] as QueryExpr, true);
            case 'isnotnull':
                return this.createNullCheck(expr.Operands[0] as QueryExpr, false);
            default:
                return this.createComparitor(expr);
        }
    }

    private createEvaluator(expr: QueryExpr): (item: any) => any {
        if ('Field' in expr) {
            const valueProvider = this.valueProviders.get(expr.Field) ?? { getValue: (item: any) => item[expr.Field] };
            const accessor = (item: any) => valueProvider?.getValue(item);
            return accessor;
        } else if ('Value' in expr) {
            return () => expr.Value;
        } else {
            return this.createFilter(expr);
        }
    }

    private *valueEnumerator(value: any, converter: (value: any) => any) {
        if (value instanceof Array) {
            for (const item of value) {
                yield converter(item);
            }
        } else {
            yield converter(value);
        }
    }

    private compareEach(left: any[], right: any[], comparer: (left: any, right: any) => boolean) {
        for (const leftItem of left) {
            for (const rightItem of right) {
                if (comparer(leftItem, rightItem)) {
                    return true;
                }
            }
        }
        return false;
    }

    private createComparitor(expr: QueryOperation) {
        const type = this.getBestType(expr.Operands as QueryExpr[]);
        const converter = this.getConverter(type);
        const leftEval = this.createEvaluator(expr.Operands[0] as QueryExpr);
        const rightEval = this.createEvaluator(expr.Operands[1] as QueryExpr);
        const left = (item: any) => converter(leftEval(item));
        const right = (item: any) => converter(rightEval(item));

        switch (expr.Operation.toLowerCase()) {
            case 'eq':
            case 'ne':
                const comparer =
                    type === 'string'
                        ? (left: string, right: string) => left.localeCompare(right, undefined, { sensitivity: 'base' }) === 0
                        : type === 'date'
                        ? (left: Date, right: Date) => left.getTime() === right.getTime()
                        : (left: any, right: any) => left === right;
                const negate = expr.Operation.toLowerCase() === 'ne';
                return (item: any) => {
                    const left = [...this.valueEnumerator(leftEval(item), converter)];
                    const right = [...this.valueEnumerator(rightEval(item), converter)];
                    const result = this.compareEach(left, right, comparer);
                    return negate ? !result : result;
                };
            case 'gt':
                return (item: any) => left(item) > right(item);
            case 'gte':
                return (item: any) => left(item) >= right(item);
            case 'lt':
                return (item: any) => left(item) < right(item);
            case 'lte':
                return (item: any) => left(item) <= right(item);
            case 'startswith':
                return (item: any) => (left(item) as string).toLocaleLowerCase().startsWith(right(item).toLocaleLowerCase());
            case 'endswith':
                return (item: any) => (left(item) as string).toLocaleLowerCase().endsWith(right(item).toLocaleLowerCase());
            case 'contains':
                return (item: any) => (left(item) as string).toLocaleLowerCase().indexOf(right(item).toLocaleLowerCase()) >= 0;
            case 'notcontains':
                return (item: any) => (left(item) as string).toLocaleLowerCase().indexOf(right(item).toLocaleLowerCase()) === -1;
            default:
                return () => true;
        }
    }

    private createNullCheck(expr: QueryExpr, not: boolean) {
        const valueEval = this.createEvaluator(expr as QueryExpr);
        const matches = new Set([null, undefined, '']);
        return (item: any) => matches.has(valueEval(item)) === not;
    }

    private getBestType(exprs: QueryExpr[]) {
        return exprs.map((x) => this.getType(x)).find((t) => t !== 'unknown');
    }

    private getType(expr: QueryExpr) {
        if ('Field' in expr) {
            const valueProvider = this.valueProviders.get(expr.Field);
            return valueProvider?.type || 'unknown';
        } else if ('Value' in expr) {
            const value = expr.Value;
            if (value instanceof Date) {
                return 'date';
            } else if (typeof value === 'number') {
                return 'number';
            } else if (typeof value === 'boolean') {
                return 'boolean';
            } else if (typeof value === 'string') {
                return 'string';
            } else {
                return 'unknown';
            }
        } else {
            return this.getOperationType(expr);
        }
    }

    private getOperationType(expr: QueryOperation) {
        return 'boolean';
    }

    private createAnd(exprs: QueryExpr[]) {
        const filters = exprs.map((x) => this.createFilter(x as QueryOperation));
        return (item: any) => filters.every((f) => f(item));
    }

    private createOr(exprs: QueryExpr[]) {
        const filters = exprs.map((x) => this.createFilter(x as QueryOperation));
        return (item: any) => filters.some((f) => f(item));
    }

    private createNot(expr: QueryExpr) {
        const filter = this.createFilter(expr as QueryOperation);
        return (item: any) => !filter(item);
    }

    private applySort(items: any[], sort: QuerySortExpr[]) {
        const sortHandler = this.createCombinedSortHandler(sort);
        return sortHandler(items);
    }

    private createCombinedSortHandler(sort: QuerySortExpr[]) {
        const sortHandlers = sort.reduce((result, item) => {
            const sorter = this.createSortHandler(item);
            if (sorter) {
                result.push(sorter);
            }
            return result;
        }, [] as ((a: any, b: any) => number)[]);
        const sorter = (a: any, b: any) => {
            for (const handler of sortHandlers) {
                const result = handler(a, b);
                if (result !== 0) {
                    return result;
                }
            }
            return 0;
        };
        return (items: any[]) => (sortHandlers.length ? items.sort(sorter) : items);
    }

    private createSortHandler(sort: QuerySortExpr) {
        const field = sort.Expr?.Field;
        const valueProvider = !field
            ? null
            : this.valueProviders.get(field) ?? ({ field, type: sort.Expr?.Type, getValue: (item: any) => item[field] } as IValueProvider);
        const sorter = valueProvider ? this.createSort(valueProvider) : null;
        const modifier = sorter ? this.createSortModifier(sorter, sort.Direction ?? 'Asc') : null;

        return modifier;
    }

    private createSortModifier(sorter: (a: any, b: any) => number, direction: 'Asc' | 'Desc') {
        return direction === 'Asc' ? sorter : (a: any, b: any) => sorter(a, b) * -1;
    }

    private createSort(valueProvider: IValueProvider): (a: any, b: any) => number {
        const converter = this.getConverter(valueProvider.type);
        const comparer = this.getComparer(valueProvider);
        return (a: any, b: any) => comparer(converter(valueProvider.getValue(a)), converter(valueProvider.getValue(b)));
    }

    private getConverter(type?: string) {
        switch (type) {
            case 'date':
                return this.dateConverter;
            case 'number':
                return this.numberConverter;
            case 'string':
                return this.stringConverter;
            default:
                return (value: any) => value;
        }
    }

    private getComparer(valueProvider: IValueProvider) {
        switch (valueProvider.type) {
            case 'number':
            case 'boolean':
            case 'date':
                return this.numberComparer;
            default:
                return this.stringComparer;
        }
    }

    private dateConverter = (date?: Date | string) =>
        !date ? new Date(0, 0, 0) : date instanceof Date ? date : typeof date === 'number' ? new Date(date) : new Date(Date.parse(date));
    private numberConverter = (value?: number) => (typeof value === 'number' ? value : !value ? 0 : parseFloat(value));
    private stringConverter = (value?: string) => (typeof value === 'string' ? value : value === null || value === undefined ? '' : value + '');
    private numberComparer = (a?: number, b?: number) => (a ?? 0) - (b ?? 0);
    private stringComparer = (a?: string, b?: string) => (a ?? '').localeCompare(b ?? '', [], { sensitivity: 'base' });
}

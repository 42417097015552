import {
    getCspMktGetStagedAzureSubscription,
    getSubscriptionGetCreditRates,
    getSubscriptionGetSubscriptionOptions,
    postCspMktActivateAzureSubscription,
} from '@apis/Customers';
import { AzureMarketplaceSubscription, CreditRates, Plans } from '@apis/Customers/model';
import { Box, Button, Card, Center, Divider, Group, List, Loader, Space, Text, ThemeIcon, Title } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { PageBody, PageContent, PagePanel } from '@root/Design/Layout';
import { useDi } from '@root/Services/DI';
import { Logger } from '@root/Services/Logger';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useCallback, useEffect, useState } from 'react';
import { DotsCircleHorizontal } from 'tabler-icons-react';
import { SubscriptionPriceDetails } from './Components/SubscriptionPriceDetails';

export function AzureConfirmPaymentMethod() {
    const { getData, goto, getRootUrl } = useNav();
    const { id: rawId } = getData('id');
    const id = rawId ? parseInt(rawId) : '';
    const logger = useDi(Logger);
    const [subscription, setSubscription] = useState<AzureMarketplaceSubscription>();
    const [subscriptionInfo, setSubscriptionInfo] = useState<{ plan: Plans; creditRate: CreditRates }>();
    const fail = useCallback(() => {
        showError(() => goto(getRootUrl('support/support-home')));
    }, [goto, getRootUrl]);
    const activate = useCallback(async () => {
        try {
            await postCspMktActivateAzureSubscription({ subscriptionId: subscription?.Id });
            goto('/');
        } catch (err) {
            logger.error('Error activating subscription', err);
            fail();
        }
    }, [subscription]);

    useEffect(() => {
        if (id) {
            (async () => {
                try {
                    const subscription = await getCspMktGetStagedAzureSubscription({ subscriptionId: id });
                    setSubscription(subscription);

                    const creditPackageId = subscription.Offer?.CreditPackageId ?? 0;
                    const subscriptionTypeId = subscription.Offer?.SubscriptionType?.ExternalId ?? '';
                    const [subscriptionOptions, creditRate] = await Promise.all([
                        getSubscriptionGetSubscriptionOptions({ applicationType: subscription?.Offer?.SubscriptionType?.Application ?? undefined }),
                        getSubscriptionGetCreditRates({ creditPackageId }),
                    ]);
                    const plan = [...(subscriptionOptions.MonthlyPlans ?? []), ...(subscriptionOptions.YearlyPlans ?? [])].find(
                        (o) => o.ExternalId === subscriptionTypeId
                    );
                    if (!plan) {
                        throw new Error('No subscription option found for ID ' + subscriptionTypeId);
                    }
                    creditRate.Ranges = creditRate.Ranges?.filter((r) => r.Type === 'Azure');

                    setSubscriptionInfo({ creditRate, plan });
                } catch (err) {
                    logger.error('Error getting subscription options and credit rates', err);
                    fail();
                }
            })();
        }
    }, []);

    return (
        <PageContent>
            <Box sx={{ width: 600 }} m="xl">
                <Card withBorder shadow="md" radius="lg" p={0}>
                    {!subscription || !subscriptionInfo ? (
                        <Center p="xl">
                            <Loader />
                        </Center>
                    ) : (
                        <>
                            <Box p="xl">
                                <Title order={2}>Azure SaaS Confirmation</Title>
                                <Space h="md" />
                                <Text>Would you like to activate this Azure SaaS offer as a payment method?</Text>
                            </Box>
                            <Divider />
                            <Box p="md">
                                <Text m="md">
                                    <Text size="sm" color="dimmed">
                                        Billed Tenant ID:
                                    </Text>
                                    {subscription.PurchaserTenantId}
                                </Text>
                                <Text m="md">
                                    <Text size="sm" color="dimmed">
                                        SaaS Resource Name:
                                    </Text>
                                    {subscription.SubscriptionName} ({subscription.SubscriptionId})
                                </Text>

                                <Center p="md">
                                    <Button onClick={activate} sx={{ width: 300 }}>
                                        Activate
                                    </Button>
                                </Center>
                            </Box>
                            <Divider />
                            <Box px="xl">
                                <SubscriptionPriceDetails
                                    plan={subscriptionInfo.plan}
                                    creditRate={subscriptionInfo.creditRate}
                                    subscriptionType={subscription.Offer?.SubscriptionType!}
                                />
                            </Box>
                        </>
                    )}
                </Card>
            </Box>
        </PageContent>
    );
}
endpoint('azure-confirm-payment-method', AzureConfirmPaymentMethod, 'Confirm Azure Subscription');

function showError(confirm: () => void) {
    openConfirmModal({
        children: <Text>There seems to be a problem with the Azure SaaS offer. Please contact support</Text>,
        labels: {
            confirm: 'Contact Support',
            cancel: null,
        },
        cancelProps: {
            hidden: true,
        },
        withCloseButton: false,
        closeOnClickOutside: false,
        closeOnConfirm: true,
        closeOnEscape: false,
        onCancel: undefined,
        onConfirm: confirm,
    });
}

import { getAccountGetAccountConnectionStatus, getAccountGetCompanyAccounts, getOrganizationGetOrganizations } from '@apis/Customers';
import { Account, AccountConnectedStatus, Organization } from '@apis/Customers/model';
import type { Company } from '@apis/Customers/model';
import {
    getMapContractsGetMapContracts,
    postMapContractsCreateMapContract,
    postMapContractsEnsureMapCostAllocationTag,
    postMapContractsExtractDataFromDocument,
    postMapContractsUploadAgreementDocument,
    postMapContractsValidateMapContract,
} from '@apis/TagManager';
import {
    MapContract,
    MapContractStatus,
    PostMapContractsExtractDataFromDocumentBody,
    PostMapContractsUploadAgreementDocumentBody,
    PostMapContractsUploadAgreementDocumentParams,
} from '@apis/TagManager/model';
import { BasicApi } from '@root/Services/BasicApi';
import { ICompanyContextToken } from '@root/Services/Customers/CompanyContext';
import { startOfMonth } from 'date-fns';
import { fi } from 'date-fns/locale';
import { makeAutoObservable } from 'mobx';
import { inject, Lifecycle, scoped } from 'tsyringe';
import { MapContractsEvents } from '../Services/MapContractsEvents';

export interface IMapWizardStep {
    Content: React.ComponentType<any>;
    Help: React.ComponentType<any>;
    canProceed: (model: MapContractSetupModel) => boolean;
}

export interface IAccountGroup {
    management: Account;
    members: Account[];
}

@scoped(Lifecycle.ContainerScoped)
export class MapContractSetupModel {
    public currentStep = 1;
    public steps: IMapWizardStep[] = [];
    public loadingAccounts = false;
    public loadingContracts = false;
    public loadingConnectivity = false;
    public loadingValidation = false;
    public saving = false;

    public contract = {
        AccountIds: [],
        ProgramId: '',
        ContractStartDate: startOfMonth(new Date()),
        ContractTermYears: 3,
        Name: '',
        MaximumContractValue: 0,
        AnticipatedAnnualSpend: 0,
        CompanyType: '',
        District: '',
        MigrationPartner: '',
    } as Omit<MapContract, 'ContractStartDate'> & { ContractStartDate: Date | null; AnticipatedAnnualSpend: number | null };
    public existingContracts: MapContract[] = [];
    public accountGroups: IAccountGroup[] = [];
    private connectionLookup = new Map<number, AccountConnectedStatus>();
    private inUseLookup = new Set<string>();
    private validationLookup = new Map<number, MapContractStatus>();
    public contractFile: File | null = null;

    public get hasValidationResults() {
        return this.validationLookup.size > 0;
    }

    public get allAccountsValid() {
        return [...this.validationLookup].every(([, s]) => s.TagActivated && s.TagExists);
    }

    public get activeStep() {
        return this.steps[this.currentStep - 1];
    }

    public constructor(
        @inject(ICompanyContextToken) private readonly company: Company,
        @inject(MapContractsEvents) private readonly globalEvents: MapContractsEvents,
        @inject(BasicApi) private readonly basicApi: BasicApi
    ) {
        makeAutoObservable(this);
    }

    public async init(...steps: IMapWizardStep[]) {
        this.steps = steps;
        this.loadExistingContracts();
        this.loadAccounts();
        this.loadConnectionStatus();
    }

    public async reloadAccounts() {
        await this.loadAccounts();
        await this.loadConnectionStatus();
    }

    public canProceed = () => {
        return this.steps[this.currentStep - 1].canProceed(this);
    };

    public goto = (step: number) => {
        this.currentStep = step;
    };

    public isAccountInUse(awsAccountId: string) {
        return this.inUseLookup.has(awsAccountId);
    }

    public isAccountConnected(accountId: number) {
        return this.connectionLookup.get(accountId)?.ConnectionStatus ?? false;
    }

    public isAccountSelected(account: Account) {
        return this.contract.AccountIds?.includes(account.AwsAccountId ?? '');
    }

    public setAccountSelected(account: Account, selected: boolean) {
        const isSelected = this.isAccountSelected(account);
        if (selected && !isSelected) {
            this.contract.AccountIds?.push(account.AwsAccountId ?? '');
        } else if (!selected && isSelected) {
            this.contract.AccountIds = this.contract.AccountIds?.filter((a) => a !== account.AwsAccountId ?? '');
        }
    }

    public toggleAccountSelected(account: Account) {
        this.setAccountSelected(account, !this.isAccountSelected(account));
    }

    private async loadExistingContracts() {
        this.loadingContracts = true;
        try {
            const contracts = await getMapContractsGetMapContracts();
            this.existingContracts = contracts;
            this.inUseLookup = new Set(contracts.flatMap((c) => c.AccountIds ?? []));
        } finally {
            this.loadingContracts = false;
        }
    }

    public async loadConnectionStatus() {
        this.loadingConnectivity = true;
        try {
            const connStatus = await getAccountGetAccountConnectionStatus({ companyId: this.company.Id });
            this.connectionLookup = new Map(connStatus.map((c) => [c.AccountId!, c]));
        } finally {
            this.loadingConnectivity = false;
        }
    }

    private async loadAccounts() {
        this.loadingAccounts = true;
        try {
            const accounts = await getAccountGetCompanyAccounts();
            const mgmtAcctLookup = new Map<number, IAccountGroup>();
            const organizations = await getOrganizationGetOrganizations();
            const { orgLookup, parentLookup } = organizations.reduce(
                (result, item) => {
                    result.orgLookup.set(item.Id!, item);
                    result.parentLookup.set(item.AwsOrganizationalUnitId ?? '', item);
                    return result;
                },
                { parentLookup: new Map<string, Organization>(), orgLookup: new Map<number, Organization>() }
            );
            const getOrgGroup: (orgId: number) => number = (orgId: number) => {
                const org = orgLookup.get(orgId);
                const parent = org && org.ParentId ? parentLookup.get(org.ParentId) : undefined;
                return !org ? -1 : !parent ? org.Id! : getOrgGroup(parent.Id!);
            };

            const accountGroups = accounts.reduce((result, item) => {
                const orgId = getOrgGroup(item.OrganizationUnitId);
                let accountGroup = mgmtAcctLookup.get(orgId);
                if (!accountGroup) {
                    mgmtAcctLookup.set(
                        orgId,
                        (accountGroup = {
                            members: [],
                            management: {} as unknown as Account,
                        })
                    );
                    result.push(accountGroup);
                }
                if (item.IsMasterAccount) {
                    accountGroup.management = item;
                } else {
                    accountGroup.members.push(item);
                }
                return result;
            }, [] as typeof this.accountGroups);
            accountGroups.forEach((g) => g.members.sort((a, b) => (a.Name ?? a.AwsAccountId ?? '').localeCompare(b.Name ?? b.AwsAccountId ?? '')));
            this.accountGroups = accountGroups;
        } finally {
            this.loadingAccounts = false;
        }
    }

    public checkConfigurations = async () => {
        const accountIds = this.contract.AccountIds?.map(
            (acc) => this.accountGroups.find((g) => g.management.AwsAccountId === acc)?.management.Id! ?? 0
        ).filter((id) => id > 0);
        if (accountIds?.length) {
            this.loadingValidation = true;
            try {
                const validation = await postMapContractsValidateMapContract(accountIds);
                this.validationLookup = new Map(validation.map((v) => [v.AccountId!, v]));
            } catch (error) {
                let theError = error;
            } finally {
                this.loadingValidation = false;
            }
        }
    };

    public fixRequiresWait() {
        return [...this.validationLookup].some(([, s]) => !s.TagExists && s.Permitted);
    }

    public getValidationStatus(account: Account) {
        return this.validationLookup.get(account.Id ?? 0);
    }

    public async fixCostAllocationTags(account: Account) {
        this.loadingValidation = true;
        try {
            const result = await postMapContractsEnsureMapCostAllocationTag({ accountId: account.Id! });
            if (result.Activated) {
                await this.checkConfigurations();
            }
            return result;
        } finally {
            this.loadingValidation = false;
        }
    }

    public canSave() {
        return !!this.contract.Name;
    }

    public async save() {
        this.saving = true;
        try {
            const contract = await postMapContractsCreateMapContract(this.contract as unknown as MapContract);
            await this.uploadAgreementDocument(contract.Id!);
            this.globalEvents.onContractsChanged.emit();
        } finally {
            this.saving = false;
        }
    }

    public async uploadAgreementDocument(mapContractId: number) {
        if (!this.contractFile) return;

        await postMapContractsUploadAgreementDocument(
            { file: this.contractFile } as PostMapContractsUploadAgreementDocumentBody,
            { mapContractId: mapContractId } as PostMapContractsUploadAgreementDocumentParams
        );
    }

    public async extractDataFromContractDocument() {
        return await postMapContractsExtractDataFromDocument({ file: this.contractFile } as PostMapContractsExtractDataFromDocumentBody);
    }
}

import { postCspMktActivateAwsSubscription } from '@apis/Customers';
import { AwsMarketplaceOffer, AwsMarketplaceSubscription, Company } from '@apis/Customers/model';
import { Box, Center, Card, Text, Group, Loader } from '@mantine/core';
import { CompanyTenantPrereqService } from '@root/Components/Router/CompanyContent';
import { useFullScreenCloudBg } from '@root/Design/Layout';
import { theme } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { MspService } from '@root/Services/MspService';
import { useEffect } from 'react';
import {} from 'tabler-icons-react';

export function PrivateOfferFulfillment({
    subscription,
    companies,
}: {
    companies: Company[];
    offer: AwsMarketplaceOffer;
    subscription: AwsMarketplaceSubscription;
}) {
    useFullScreenCloudBg();
    const mspSvc = useDi(MspService);
    const tenantSvc = useDi(CompanyTenantPrereqService);
    const redirectToApp = () => {
        const company = companies.find((c) => c.Id == subscription.CompanyId);
        if (company) {
            window.location.href = `/${company.DatabaseName}`;
        }
    };
    useEffect(() => {
        if (subscription.Status !== 'Staged') {
            redirectToApp();
        } else {
            tenantSvc.setCompanyId(subscription.CompanyId!);
            postCspMktActivateAwsSubscription({ subscriptionId: subscription.Id }).finally(redirectToApp);
        }
    }, []);
    return (
        <Center mt="xl">
            <Box sx={{ width: 450 }}>
                <Card color={theme.white} shadow="xl" radius="lg" p="xl">
                    <Center p="xl">
                        <img src={mspSvc.defaultSupportAndLogos.Logo} style={{ width: 150 }} alt={mspSvc.defaultSupportAndLogos.CompanyName} />
                    </Center>
                    <Group noWrap>
                        <Loader size="lg" />
                        <Box>
                            <Text size="lg">Activating {subscription.SubscriptionType?.FriendlyName}</Text>
                            <Text color="dimmed">You will be redirected to momentarily</Text>
                        </Box>
                    </Group>
                </Card>
            </Box>
        </Center>
    );
}

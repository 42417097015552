/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Export.Host.Api
 * OpenAPI spec version: 1.0
 */

export type ExportRequestType = typeof ExportRequestType[keyof typeof ExportRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExportRequestType = {
  Excel: 'Excel',
  PDF: 'PDF',
} as const;

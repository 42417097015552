import { Company } from '@apis/Customers/model';
import { DataGridState } from '@root/Components/DataGrid/Models';
import { ResourcesGrid } from '@root/Components/Resources/ResourcesGrid';
import { useMemo } from 'react';

export function CompanyDetailResources({ company }: { company: Company }) {
    const defaultState = useMemo(
        () =>
            ({
                filters: [
                    {
                        Operation: 'eq',
                        Operands: [{ Field: 'ResourceType' }, { Value: ['EC2 On Demand', 'EC2 Reserved', 'RDS', 'RDS Reserved'] }],
                    },
                ],
                columns: [],
                sort: [],
            } as DataGridState),
        []
    );
    return <ResourcesGrid noSelect persistenceKey="CloudIntel" defaultState={defaultState} />;
}

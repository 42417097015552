import { IQueryExpr, ResourceIdentifier, TagResourcesJob } from '@apis/Resources/model';
import { ThemeIcon, useMantineTheme } from '@mantine/core';
import { CustomColors, theme } from '@root/Design/Themes';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { useMemo } from 'react';
import { ListCheck } from 'tabler-icons-react';
import { inject, injectable } from 'tsyringe';
import { ActivityPoller } from '../../Actions/ActivityPanel/ActivityPoller';
import { JobStatus } from '../../Actions/ActivityPanel/ActivityTypes';
import { GridFullCell } from '../../DataGrid/Design';
import { VisibleSpaces } from '../../Text/VisibleSpaces';
import { InlineEditTagPopover } from './InlineTagging';
import { BaseResource, ResourceGridModel } from '../ResourcesGrid';
import { GridArrayDataSource } from '../../DataGrid/DataSources';

export function TagCell({ item, tag, grid }: { item: BaseResource; tag: string; grid: ResourceGridModel }) {
    const evt = useMemo(() => EventEmitter.empty(), []);
    const theme = useMantineTheme();
    const value = item?.CsTags?.[tag];
    const styles = !value ? { fontStyle: 'italic', color: theme?.colors?.gray?.[6] as CustomColors } : undefined;
    useEvent(evt);

    return (
        <InlineEditTagPopover grid={grid} item={item} tag={tag} onChange={evt.emit}>
            <GridFullCell style={{ ...styles, cursor: grid.allowTagEdit ? 'pointer' : undefined }} className="selector">
                {value === null ? <></> : value === undefined ? <>« No Tag »</> : value === '' ? <>« Empty »</> : <VisibleSpaces value={value} />}
            </GridFullCell>
        </InlineEditTagPopover>
    );
}

@injectable()
export class TagJobListener {
    public readonly newTagJobs = EventEmitter.empty();
    public readonly jobsCompleted = new EventEmitter<TagResourcesJob[]>([]);

    private arrayDatasourceProvider?: (resource: BaseResource) => GridArrayDataSource | undefined;
    private jobFilterLookup = new Map<string, IQueryExpr>();
    private filterBasedJobLookup = new Map<string, string[]>();
    private filterEvaluationCache = new WeakMap<BaseResource, Record<string, boolean>>();
    private readonly disposer: () => void;
    private readonly pendingTags = new Map<string, Set<string>>();
    private knownTagJobs = new Set<string>();
    private maxLastDate = new Date();
    public constructor(
        @inject(ActivityPoller) private readonly poller: ActivityPoller,
        @inject(NotificationService) private readonly notificationSvc: NotificationService,
        @inject(FormatService) private readonly formatSvc: FormatService
    ) {
        this.disposer = this.poller.listen(this.updateStatus).dispose;
    }

    public provideArrayDatasource(provider: (resource: BaseResource) => GridArrayDataSource | undefined) {
        this.arrayDatasourceProvider = provider;
    }

    public dispose() {
        this.disposer();
    }

    private updateStatus = (jobStatuses: JobStatus[]) => {
        const newTagJobs: string[] = [];
        const nextKnownTagJobs = new Set<string>();
        const maxDate = this.maxLastDate;
        const completedJobs: TagResourcesJob[] = [];

        this.pendingTags.clear();
        this.filterBasedJobLookup.clear();
        this.jobFilterLookup.clear();
        let nextMax = maxDate;

        for (const status of jobStatuses) {
            if (status.job.Type?.endsWith('TagResourcesJob')) {
                const jobParameters = status.job.Parameters as TagResourcesJob;
                const tags = this.getTagsFromJob(jobParameters);
                nextKnownTagJobs.add(status.job.Id!);

                if (!this.knownTagJobs.has(status.job.Id!)) {
                    newTagJobs.push(status.job.Id!);
                }
                const date = this.formatSvc.toLocalDate(status.status.lastDate);
                nextMax = date > nextMax ? date : nextMax;
                if (status.status.lastDate && !status.status.Created && !status.status.Started && date > maxDate) {
                    completedJobs.push(jobParameters);
                }

                if (jobParameters.ResourceIds && tags) {
                    for (const resourceId of jobParameters.ResourceIds) {
                        const key = this.createKeyByResourceId(resourceId);
                        if (status.status.Created || status.status.Started) {
                            let tagsLookup = this.pendingTags.get(key);
                            if (!tagsLookup) {
                                this.pendingTags.set(key, (tagsLookup = new Set<string>()));
                            }
                            for (const tag of tags) {
                                tagsLookup.add(tag);
                            }
                        }
                    }
                }

                if (!jobParameters.ResourceIds?.length && jobParameters.Filter && tags?.length && (status.status.Created || status.status.Started)) {
                    this.jobFilterLookup.set(jobParameters.JobId!, jobParameters.Filter);
                    for (const tag of tags) {
                        if (!this.filterBasedJobLookup.has(tag)) {
                            this.filterBasedJobLookup.set(tag, []);
                        }
                        this.filterBasedJobLookup.get(tag)?.push(jobParameters.JobId!);
                    }
                }
            }
        }
        this.maxLastDate = nextMax;

        if (newTagJobs.length) {
            this.newTagJobs.emit();
        }
        if (completedJobs.length) {
            this.handleCompltedJobs(completedJobs);
        }
        this.knownTagJobs = nextKnownTagJobs;
    };

    public handleCompltedJobs(completedJobs: TagResourcesJob[]) {
        this.jobsCompleted.emit(completedJobs);
        const jobsDone = completedJobs.length;
        const jobPlural = jobsDone > 1 ? 's' : '';
        const uniqueRes = completedJobs.reduce((result, item) => {
            if (item.ResourceIds) {
                for (const resource of item.ResourceIds) {
                    result.add(`${resource.ResourceId}-${resource.ResourceType}-${resource.CloudPlatform}`);
                }
            }
            return result;
        }, new Set<string>());
        const pluralRes = uniqueRes.size > 1 ? 's' : '';
        const pluralPos = jobsDone > 1 ? 'have' : 'has';

        this.notificationSvc.notify(
            `Tag Task${jobPlural} Finished`,
            `${jobsDone} Tag job${jobPlural} for ${uniqueRes.size} resource${pluralRes} ${pluralPos} finished. Check the activity log for details.`,
            'primary',
            <ThemeIcon style={{ backgroundColor: theme.colors?.gray?.[2] as CustomColors }} variant="light" size="xl" radius="xl">
                <ListCheck style={{ color: theme.colors?.primary?.[8] }} />
            </ThemeIcon>
        );
    }

    public hasPendingChanges(resource: BaseResource, tag: string) {
        const key = this.createKeyByResource(resource);
        return (this.pendingTags.get(key)?.has(tag) || this.isResourceInJob(resource, tag)) ?? false;
    }

    private isResourceInJob(resource: BaseResource, tag: string) {
        let result = false;
        const queryJobs = this.filterBasedJobLookup.get(tag);
        if (queryJobs?.length) {
            const cachedEvaluations = this.filterEvaluationCache.get(resource);
            const datasource = this.arrayDatasourceProvider?.(resource) ?? new GridArrayDataSource([resource]);
            for (const jobId of queryJobs) {
                if (cachedEvaluations && cachedEvaluations.hasOwnProperty(jobId)) {
                    if (cachedEvaluations[jobId]) {
                        result = true;
                        break;
                    }
                } else {
                    const query = this.jobFilterLookup.get(jobId);
                    if (query) {
                        const filteredResources = datasource.filter([query]);
                        if (!this.filterEvaluationCache.has(resource)) {
                            this.filterEvaluationCache.set(resource, {});
                        }
                        this.filterEvaluationCache.get(resource)![jobId] = !!filteredResources.length;
                        if (filteredResources.length) {
                            result = true;
                            break;
                        }
                    }
                }
            }
        }
        return result;
    }

    private createKeyByResourceId(resourceId: ResourceIdentifier) {
        return JSON.stringify([resourceId.CloudPlatform, resourceId.ResourceType, resourceId.ResourceId]);
    }
    private createKeyByResource(resource: BaseResource) {
        return JSON.stringify([resource.CloudPlatform, resource.ResourceType, resource.Id]);
    }
    private getTagsFromJob(job: TagResourcesJob) {
        if (job.AddTags) {
            return job.AddTags.map((t) => t.Key ?? '');
        } else if (job.DeleteTags) {
            return job.DeleteTags.map((t) => t.Key ?? '');
        } else if (job.Renames) {
            return job.Renames.reduce((result, item) => {
                result.push(item.OldTagKey ?? '', item.NewTagKey ?? '');
                return result;
            }, [] as string[]);
        } else if (job.ReplaceValues) {
            return [job.ReplaceValues.Key ?? ''];
        }
    }
}

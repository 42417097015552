import { Box, Button, Card, Divider, Group, JsonInput, LoadingOverlay, Space, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { PageBody, PageContent, PagePanel, PanelBody, PanelContent, PaneledPage, PanelHeader } from '@root/Design/Layout';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useGridResizer } from './Components/GridResizer';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';
import { useDi, useDiContainer, useDiMemo } from '@root/Services/DI';
import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { LineChart, LineChartSettings } from '@root/Components/Charts/LineChart';
import { FillerSwitch, LoadingFiller } from '@root/Design/Filler';

export function DiscountManager() {
    const { Resizer, containerStyle, setScrollContainer } = useGridResizer();

    return (
        <ConnectionCheck>
            {() => (
                <PageBody>
                    <PageContent ref={setScrollContainer}>
                        <PaneledPage>
                            <Box p="lg" sx={{ gap: 24, height: '100%', width: '100%', display: 'flex', flexDirection: 'column', minHeight: 850 }}>
                                <PanelHeader style={{ flex: 0, padding: 0 }}>
                                    <Title order={3} data-atid="DiscountManagerHeader">
                                        Discount Manager
                                    </Title>
                                    <Box></Box>
                                </PanelHeader>
                            </Box>
                        </PaneledPage>
                    </PageContent>
                </PageBody>
            )}
        </ConnectionCheck>
    );
}

endpoint('discount-manager', DiscountManager, 'Discount Manager');

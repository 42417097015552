import { Button, Group, Modal, Grid, Text, TextInput, Divider, Space, Box, Tabs } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import { useDi, useDiContainer } from '@root/Services/DI';
import { closeModal } from '@mantine/modals';
import { useInputState } from '@mantine/hooks';
import { LoadingState, LoadingValidation, ScriptTextArea, WizardDownload } from '@root/Components/Wizard/WizardForm';
import { useEvent } from '@root/Services/EventEmitter';
import { PlatformService } from '@root/Services/PlatformService';
import { CliConnectionInfo } from '@apis/Resources/model/cliConnectionInfo';
import { PendingCliConnection } from '@apis/Resources/model/pendingCliConnection';
import { getConnectionGetCliConnectionInfo, getConnectionTryAssumeRole, postConnectionGetPendingConnection } from '@apis/Resources';
import { ConfigService } from '@root/Services/ConfigService';
import { MspService } from '@root/Services/MspService';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { IacConnector } from '@root/Components/Connections/IacConnector';
import { PlatformBox, PlatformButton } from '@root/Site/Settings/ConnectChoosePlatform';
import { AccountIdValidator, AccountNameValidator } from '@root/Site/Settings/Common';

export function AdminConnectionModal() {
    const platformSvc = useDi(PlatformService);
    const [platform, setPlatform] = useState<string[]>();
    useEffect(() => {
        (async () => {
            const platforms = [...(await platformSvc.getConnectedCloud())];
            setPlatform([]);
        })();
    }, []);

    const di = useDiContainer();
    const iacConn = useMemo(() => di.resolve(IacConnector), []);
    const company = useCompany();
    const [accountId, setAccountId] = useInputState('');
    const [accountName, setAccountName] = useInputState('');
    const [isMasterAccount, setIsMasterAccount] = useState<Boolean>(true);
    const mspSvc = useDi(MspService);

    useEvent(iacConn.status);

    useEffect(() => {
        return () => {
            iacConn.dispose();
        };
    }, []);

    useEffect(() => {
        iacConn.updateAccount(accountId, company!.UniqueId!);
    }, [accountId]);

    function setThePlatform(which: string) {
        const platforms = [];
        platforms.push(which);
        setPlatform(platforms);
    }

    const [connState, setConnState] = useState<LoadingState>(LoadingState.loading);
    const [connMsg, setConnMsg] = useState<string>('Awaiting connection');
    const [cliScriptWin, setCliScriptWin] = useState<string>('');
    const [cliScriptLinux, setCliScriptLinux] = useState<string>('');
    const connChecker = useMemo(
        () => ({
            connInfo: null as CliConnectionInfo | null,
            pendingConn: null as PendingCliConnection | null,
            shouldPoll: true,
            poll() {
                setTimeout(async () => {
                    if (!this.shouldPoll) {
                        return;
                    }
                    try {
                        if (this.connInfo) {
                            if (this.pendingConn && this.pendingConn.Complete) {
                                setConnState(LoadingState.check);
                                setConnMsg('Connection successful');
                                this.shouldPoll = false;
                            } else if (this.pendingConn && this.pendingConn.MasterAccount && this.pendingConn.ExternalId) {
                                const assumeResult = await getConnectionTryAssumeRole({
                                    awsAccountId: this.pendingConn.MasterAccount,
                                    externalId: this.pendingConn.ExternalId,
                                });
                                setIsMasterAccount(assumeResult?.isMasterAccount!);
                                if (assumeResult?.Success) {
                                    setConnState(LoadingState.check);
                                    setConnMsg('Connection successful');
                                    this.shouldPoll = false;
                                }
                            } else {
                                this.pendingConn = await postConnectionGetPendingConnection({ token: this.connInfo.Token! });
                            }
                        }
                    } finally {
                        this.poll();
                    }
                }, 3000);
            },
            stopPolling() {
                this.shouldPoll = false;
            },
        }),
        []
    );

    const configSvc = useDi(ConfigService);
    useEffect(() => {
        (async () => {
            const baseUrl = configSvc.config.apis['Resources'].baseUrl;
            const connInfo = await getConnectionGetCliConnectionInfo();
            connChecker.connInfo = connInfo;
            const [, creds] = (connInfo.Creds ?? '').split(' ');
            const [name, value] = creds.replace(/"/g, '').split('=');
            const domain = new URL(baseUrl).hostname;
            setCliScriptWin(
                `$session=New-Object Microsoft.PowerShell.Commands.WebRequestSession; $cookie=New-Object System.Net.Cookie; $cookie.Name='${name}'; $cookie.Domain='${domain}'; $cookie.Value='${value}'; $session.Cookies.Add($cookie); iwr ${baseUrl}/connection/scripts/${connInfo.Token}/azure-cs-connector.ps1 -WebSession $session -OutFile azure-cs-connector.ps1;  .\\azure-cs-connector.ps1`
            );
            const linux = `bash <(curl ${connInfo.Creds} -sL ${baseUrl}/connection/scripts/${connInfo.Token}/azure-cs-connector.sh)`;
            setCliScriptLinux(linux);
        })();
    }, []);

    useEffect(() => {
        connChecker.poll();
        return () => connChecker.stopPolling();
    }, []);

    return (
        <>
            {platform == null ? null : !platform?.length || platform?.length == 0 ? (
                <>
                    <Text>Which cloud platform do you want to connect?</Text>
                    <Space h="xl" />
                    <PlatformButton onClick={() => setThePlatform('Aws')}>{PlatformBox('AWS')}</PlatformButton>
                    <Space h="md" />
                    <PlatformButton onClick={() => setThePlatform('Azure')}>{PlatformBox('Azure')}</PlatformButton>
                </>
            ) : platform[0] === 'Aws' ? (
                <>
                    <Text>1. Enter your 12-digit Account ID and the Account Name</Text>
                    <Grid style={{ padding: '24px', marginRight: '1px' }}>
                        <Grid.Col span={3}>
                            <Text style={{ marginTop: '5px' }}>Account Id</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <TextInput
                                data-atid="AccountIdInput"
                                value={accountId}
                                onChange={setAccountId}
                                size="sm"
                                type="number"
                                maxLength={12}
                                placeholder="012345678910"
                                sx={{ width: 175 }}
                            />
                        </Grid.Col>
                        <Grid.Col span={5} style={{ marginTop: '5px' }}>
                            <AccountIdValidator condition={accountId.length == 12} />
                        </Grid.Col>
                        <Grid.Col span={3} style={{ marginTop: '5px' }}>
                            <Text>Account Name</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <TextInput data-atid="AccountNameInput" value={accountName} onChange={setAccountName} sx={{ width: 175 }} />
                        </Grid.Col>
                        <Grid.Col span={5} style={{ marginTop: '5px' }}>
                            <AccountNameValidator condition={accountName.length > 0} />
                        </Grid.Col>
                    </Grid>
                    <Divider />
                    <Space h={16} />
                    <Text>2. Click a button to download the appropriate file</Text>
                    <Space h={16} />
                    <Group position="left">
                        <Box ml="xl">
                            <WizardDownload
                                imgLocation="/assets/terraformio-icon.svg"
                                displayTitle="Terraform"
                                displayText="Download .tf"
                                fileLocation={iacConn.getDownloadUrl('cs-terraform.tf')}
                                disable={!iacConn.isAccountValid()}
                                disabledText="Account ID is Required"
                            ></WizardDownload>
                        </Box>
                        <Box ml="xl">
                            <WizardDownload
                                imgLocation="/assets/aws-cloudformation.svg"
                                displayTitle="CloudFormation"
                                displayText="Download YAML"
                                fileLocation={iacConn.getDownloadUrl('cs-cloudformation.yml')}
                                disable={!iacConn.isAccountValid()}
                                disabledText="Account ID is Required"
                            ></WizardDownload>
                        </Box>
                    </Group>
                    <Space h={16} />
                    <Divider />
                    <Space h={16} />

                    {isMasterAccount === true ? (
                        <Text>3. CloudSaver will check for connectivity to the Management Account.</Text>
                    ) : (
                        <Text>3. CloudSaver will check for connectivity to the Account.</Text>
                    )}

                    <Space h={16} />

                    <Box ml="xl">
                        {iacConn.getStatusMessage() === '' ? (
                            <Text color="dimmed">enter valid account ID</Text>
                        ) : (
                            <LoadingValidation
                                loadingState={iacConn.getLoadingState() as LoadingState}
                                text={iacConn.getStatusMessage()}
                            ></LoadingValidation>
                        )}
                    </Box>

                    <Space h={16} />
                    <Divider />
                    <Space h={16} />

                    <Group position="right">
                        <Button
                            onClick={() => {
                                closeModal('NewConnectionModal');
                            }}
                            variant="outline"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                mspSvc.notifyNewAccount();
                                closeModal('NewConnectionModal');
                            }}
                            disabled={!iacConn.isConnected()}
                            style={{ pointerEvents: !iacConn.isConnected() ? 'none' : undefined }}
                        >
                            OK
                        </Button>
                    </Group>
                </>
            ) : platform[0] === 'Azure' ? (
                <>
                    <Text>1. Copy and paste the script into your preferred CLI</Text>
                    <Space h="md" />
                    <Tabs defaultValue="windows">
                        <Tabs.List>
                            <Tabs.Tab value="windows">Windows</Tabs.Tab>
                            <Tabs.Tab value="linux">Linux</Tabs.Tab>
                        </Tabs.List>
                        <Tabs.Panel value="windows">
                            <ScriptTextArea value={cliScriptWin} label="script area" minRows={3} maxRows={4} />
                        </Tabs.Panel>
                        <Tabs.Panel value="linux">
                            <ScriptTextArea value={cliScriptLinux} label="script area" minRows={3} maxRows={4} />
                        </Tabs.Panel>
                    </Tabs>
                    <Space h={16} />
                    <Divider />
                    <Space h={16} />
                    <Text>2. CloudSaver will detect when the script is finished</Text>
                    <Box ml="lg">
                        <LoadingValidation loadingState={connState} text={connMsg}></LoadingValidation>
                    </Box>
                    <Space h={16} />
                    <Divider />
                    <Space h={16} />

                    <Group position="right">
                        <Button
                            onClick={() => {
                                closeModal('NewConnectionModal');
                            }}
                            variant="outline"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                mspSvc.notifyNewAccount();
                                closeModal('NewConnectionModal');
                            }}
                            disabled={!(connState === LoadingState.success)}
                            style={{ pointerEvents: !(connState === LoadingState.success) ? 'none' : undefined }}
                        >
                            OK
                        </Button>
                    </Group>
                </>
            ) : (
                <>Error!</>
            )}
        </>
    );
}

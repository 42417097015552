import { MapMigratedTagFixResult } from '@apis/TagManager/model';
import { SchemaService } from '@root/Services/QueryExpr';
import { Cpu, Database } from 'tabler-icons-react';
import { injectable } from 'tsyringe';
import { MapResourceQueryService } from './MapResourceQueryService';

@injectable()
export class MapCostAllocationTagService {
    public interpretFixResults(fixResults: MapMigratedTagFixResult) {
        if (fixResults.CheckExistsError && fixResults.CheckExistsError.includes('is not authorized to perform')) {
            return 'connection-error';
        }
        if (fixResults.ActivationError && fixResults.ActivationError.includes('is not authorized to perform')) {
            return 'connection-error';
        }
        return null;
    }
}

export interface TagOption {
    value: string;
    title: string;
    disabled?: boolean;
    other?: boolean;
}

export function getContractTagOptions(contractId: string) {
    return [
        {
            value: `mig${contractId}`,
            title: 'Standard migrations',
        },
        {
            value: `comm${contractId}`,
            title: 'Migrations from on-premises commercial DB&A to AWS DB&A',
        },
        {
            value: `mig_ec2_${contractId}`,
            title: 'Migrations from EC2 non-commercial DB&A to AWS DB&A',
        },
        {
            value: `comm_ec2_${contractId}`,
            title: 'Migrations from EC2 commercial DB&A to AWS DB&A',
        },
        {
            value: `sap${contractId}`,
            title: 'Migrations from SAP applications to AWS',
        },
        {
            value: `oracle${contractId}`,
            title: 'Migrations from Oracle applications to AWS',
        },
        {
            value: `not-eligible-workload`,
            title: 'Not eligible for contract',
            other: true,
        },
    ];
}

export function getTypeTagOptions(querySvc: MapResourceQueryService, types?: string[]) {
    const items = getContractTagOptions(querySvc.contract.ProgramId!);
    return items.map((item) => ({
        ...item,
        disabled: item.value !== 'not-eligible-workload' && types && !types.some((t) => querySvc.validTagsByResourceType.get(t)?.has(item.value)),
    }));
}

export function validateContractId(value: string | null | undefined) {
    return value && value.match(/^[a-z0-9]{10}$|^[0-9]{5}$/i) ? true : false;
}

import { createBrowserHistory } from 'history';
import { useEffect } from 'react';
import { container, inject, singleton } from 'tsyringe';
import { useDi } from '../DI';
import { EventEmitter } from '../EventEmitter';
import { Route, RouteSerializer } from './RouteSerializer';

export interface IRouteChange {
    newRoute: Route;
}

const history = createBrowserHistory({ window });

export interface INavigatingEvent {
    next: string;
    current: string;
    continue: () => void;
    wait: () => void;
}
@singleton()
export class Router {
    private history = history;
    public route = new EventEmitter<IRouteChange | undefined>(undefined);
    public onNavigating = new EventEmitter<INavigatingEvent | null>(null);
    public constructor(@inject(RouteSerializer) private serializer: RouteSerializer) {}
    public init() {
        this.history.listen((l) => this.handleHashChange(l.location.pathname));
        this.handleHashChange(this.history.location.pathname);
    }
    public getCurrentPath() {
        return this.history.location.pathname;
    }
    private handleHashChange = (newURL: string) => {
        const newRoute = this.serializer.deserialize(newURL);
        this.route.emit({ newRoute });
    };
    public getCurrentRoute() {
        return this.route.value;
    }
    public navigate(path: string) {
        const navigate = () => this.history.push(path);
        let shouldNavigate = true;
        this.onNavigating.emit({
            next: path,
            current: this.getCurrentPath(),
            continue: navigate,
            wait: () => (shouldNavigate = false),
        });
        if (shouldNavigate) {
            navigate();
        }
    }
}

export function useOnNavigating(handler: (evt: INavigatingEvent) => void) {
    const router = useDi(Router);
    useEffect(() => {
        return router.onNavigating.listen((e) => (e ? handler(e) : null)).dispose;
    }, [handler]);
}

export function linkHandler<E extends Element = HTMLAnchorElement>(event: React.MouseEvent<E, MouseEvent>) {
    if (
        event.button === 0 &&
        (!(event.target as any).target || (event.target as any).target === '_self') &&
        !(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
    ) {
        event.preventDefault();
        const router = container.resolve(Router);
        const href = (event.currentTarget as any).href;
        router.navigate(href);
    }
}

export function useLink() {
    return (href: string) => {
        return { href, onClick: linkHandler };
    };
}

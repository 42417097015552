import { useMantineTheme } from '@mantine/core';
import { ComputedBar, RadialBarSerie, ResponsiveRadialBar } from '@nivo/radial-bar';
import { useMemo } from 'react';
import { ChartMargin } from './Common';
import { StandardChartProps } from './Models';
import './styles.css';
import styled from '@emotion/styled';
import { ChartWrapper } from './Design';

export function GaugeChart<T extends Record<string, string | number>>(props: GaugeChartProps<T>) {
    const {
        colors: {
            success: { [6]: good },
            warning: { [6]: warn },
            error: { [6]: bad },
        },
    } = useMantineTheme();
    const bindData = useMemo(() => transformRadialBarData(props), [props.data, props.groups, props.values[0]]);
    const { angle, danger, target, max, margin } = { angle: 'half', ...props.settings };
    const startAngle = angle === 'small' ? -30 : angle === 'half' ? -90 : -120;
    const endAngle = angle === 'small' ? 90 : angle === 'half' ? 90 : 120;
    const singleTarget = target || danger!;
    const color =
        danger === undefined && target === undefined
            ? () => warn
            : danger === undefined || target === undefined
            ? (bar: RBarArg) => (bar.value > singleTarget ? good : warn)
            : danger > target
            ? (bar: RBarArg) => (bar.value <= target ? good : bar.value <= danger ? warn : bad)
            : (bar: RBarArg) => (bar.value >= target ? good : bar.value >= danger ? warn : bad);

    return (
        <ChartWrapper className="chartWrapper">
            <ResponsiveRadialBar
                data={bindData}
                valueFormat={
                    props.settings?.format === 'money'
                        ? '>-$,.2f'
                        : props.settings?.format === 'money-whole'
                        ? '>-$,.0f'
                        : props.settings?.format === 'percent'
                        ? '>-.0%'
                        : undefined
                }
                startAngle={startAngle}
                endAngle={endAngle}
                colors={color}
                maxValue={max}
                margin={margin}
            />
        </ChartWrapper>
    );
}
export interface GaugeChartSettings {
    danger?: number;
    target?: number;
    max?: number;
    topN?: number;
    format?: 'money' | 'money-whole' | 'percent';
    angle: 'small' | 'half' | 'large';
    margin: ChartMargin;
}
interface GaugeChartProps<T> extends StandardChartProps<string | number, T> {
    settings?: GaugeChartSettings;
}
type RBarArg = Omit<ComputedBar<{ x: string; y: number }>, 'color'>;
type RBarItem = RadialBarSerie<{ x: string; y: number }> & { total: number };
function transformRadialBarData<T extends Record<string, string | number>>(props: GaugeChartProps<T>): RBarItem[] {
    const result: RBarItem[] = [];
    const idLookup = new Map<string, RBarItem>();
    const serieGroup = props.groups[0] ?? '';
    const barGroup = props.groups[1] ?? '';
    for (const item of props.data) {
        const id = (item[serieGroup] || '')?.toString();
        if (id) {
            let serie = idLookup.get(id);
            if (!serie) {
                serie = { id, data: [], total: 0 };
                idLookup.set(id, serie);
                result.push(serie);
            }
            const x = (barGroup ? item[barGroup] : id) as string;
            const y = item[props.values[0]] as number;
            serie.data.push({ x, y });
            serie.total += y;
        }
    }
    if (!props.groups.length) {
        const total = props.data?.[0]['Value'] as number;
        result.push({ id: 'Value', data: [{ x: 'Value', y: total }], total });
    }

    result.sort((a, b) => b.total - a.total);

    if (props.settings?.topN) {
        result.splice(props.settings.topN);
    }

    result.reverse();

    return result;
}

import { postResourcesCheckAwsResources } from '@apis/Resources';
import { BaseResource, CheckAwsResourcesJob } from '@apis/Resources/model';
import styled from '@emotion/styled';
import { Anchor, Box, Text, Drawer, Group, Stack, Tabs, Title, Tooltip, useMantineTheme, Divider, ActionIcon, Sx } from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { JobService } from '@root/Services/Jobs/JobService';
import { PollingService } from '@root/Services/Jobs/PollingService';
import { useNav } from '@root/Services/NavigationService';
import { Platform } from '@root/Services/PlatformService';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { addHours } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { Refresh } from 'tabler-icons-react';
import { AzureResourceDetails } from './Azure/AzureResourceDetails';
import { useLink } from '@root/Services/Router/Router';
import { AwsResourceDetailsTabs } from './Aws/AwsResourceDetailsTabs';
import { useRouteBoundPortal } from '../Router/RouteBoundPortal';

export function ResourceDetails(props: {
    resourceType: string;
    resourceId: string;
    platform: Platform;
    onModelLoaded: (resource: BaseResource) => void;
}) {
    return (
        <ResourceDetailsContainer className="resourcesContainer">
            {props.platform === 'Azure' ? (
                <AzureResourceDetails resourceId={props.resourceId} onModelLoaded={props.onModelLoaded} />
            ) : (
                <Box className="awsResourceDetailsContainer">
                    <AwsResourceDetailsTabs resourceType={props.resourceType} resourceId={props.resourceId} onModelLoaded={props.onModelLoaded} />
                </Box>
            )}
        </ResourceDetailsContainer>
    );
}

const ResourceDetailsContainer = styled.div`
    .awsResourceDetailsContainer {
        height: 100%;
        .mantine-Tabs-tabsList {
            padding-left: ${(p) => p.theme.spacing.lg}px;
        }
        .mantine-Tabs-root {
            height: 100%;
            .mantine-Tabs-panel {
                height: calc(100% - 40px);
                overflow: auto;
                padding: ${(p) => p.theme.spacing.lg}px;
            }
        }
    }
`;

export function ResourceDetailsOpener({
    resourceId,
    resourceType,
    platform,
    onClose,
    refreshDataOnly,
    ...props
}: {
    resourceType: string;
    resourceId: string | null | undefined;
    platform: Platform;
    onClose: () => void;
    resource?: BaseResource;
    refreshDataOnly?: () => void;
}) {
    const resourceService = useDi(ResourceService);
    const [resource, setResource] = useState(props.resource);
    const [refreshKey, setRefreshKey] = useState(0);
    const target = useRouteBoundPortal();

    useEffect(() => {
        if (resourceId && resourceType) {
            resourceService.getResource(resourceId, resourceType).then(setResource);
        }
    }, [resourceId, resourceType, refreshKey]);

    const handleRefresh = useCallback(() => {
        if (resourceId && resourceType) {
            refreshDataOnly?.();
            setRefreshKey((prev) => prev + 1);
        }
    }, [resourceId, resourceType, refreshDataOnly]);

    return (
        <Drawer
            onClose={onClose}
            opened={!!resourceId}
            title={
                <ResourceDrawerTitle
                    key={refreshKey}
                    resource={resource!}
                    resourceName={resource?.Name ?? ''}
                    resourceCsTagsName={platform !== 'Aws' ? '' : resource?.CsTags?.Name ?? ''}
                    resourceId={resource?.Id ?? ''}
                    type={resource?.ResourceType ?? ''}
                    refreshDataOnly={handleRefresh}
                ></ResourceDrawerTitle>
            }
            position="right"
            closeOnEscape={false}
            target={target}
            overlayOpacity={0.2}
            size="1000px"
            className="resourceDetailsDrawer"
            sx={{ width: '100%', ['.mantine-Drawer-header']: { background: `linear-gradient(0deg, #06b1, #0080f005 7px, #0080f005)` } }}
        >
            <ResourceDetails
                key={refreshKey}
                platform={platform}
                resourceType={resourceType}
                onModelLoaded={setResource}
                resourceId={resourceId ?? ''}
            />
        </Drawer>
    );
}

export function ResourceDrawerTitle(props: {
    resource: BaseResource;
    resourceName: string;
    resourceCsTagsName: string;
    resourceId: string;
    type?: string;
    refreshDataOnly?: () => void;
}) {
    const dispResourceName = props.resourceCsTagsName != '' ? props.resourceCsTagsName : props.resourceName;
    let dispResourceId = props.resourceId;
    const dotPos = dispResourceId.indexOf('.');
    dispResourceId = dispResourceId.substring(dotPos + 1);
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const jobSvc = useDi(JobService);
    const pollingSvc = useDi(PollingService);
    const [lastSyncDate, setLastSyncDate] = useState<Date>(new Date(props.resource?.LastSyncDate ?? '12/31/9999'));
    const [nextSyncDate, setNextSyncDate] = useState<Date>(new Date('12/31/9999'));
    const hoursBetweenRefresh = 1;
    const [sync, setSync] = useState<boolean>(false);
    const [syncNeeded, setSyncNeeded] = useState(false);
    function canSync() {
        return !sync && new Date(Date.now()) > nextSyncDate;
    }

    useEffect(() => {
        if (props.resource?.LastSyncDate) {
            setLastSyncDate(new Date(props.resource.LastSyncDate));
            setSync(false);
        }
    }, [props.resource?.LastSyncDate]);

    const updateResource = () => {
        if (props.refreshDataOnly) {
            props.refreshDataOnly();
        }

        setNextSyncDate(addHours(lastSyncDate, hoursBetweenRefresh));
        setSyncNeeded(false);
    };

    const resync = async () => {
        if (canSync() || syncNeeded) {
            if ((!sync || syncNeeded) && props.resource) {
                setSync(true);
                if (props.resource.CloudPlatform === 'Aws') {
                    const job = await postResourcesCheckAwsResources({
                        CompanyId: props.resource.CompanyID!,
                        AccountId: props.resource.AccountID!,
                        Region: props.resource.Region as string,
                        ResourceType: props.resource.ResourceType!,
                        ResourceIds: [props.resource.Id!],
                    });
                    pollCheckAwsResourcesJob(job);
                }
            }
        }
    };

    const pollCheckAwsResourcesJob = async (job: CheckAwsResourcesJob) => {
        const poller = pollingSvc.pollUntil(
            async () => {
                const progressLookup = await jobSvc.getHierarchyProgressLookup([job.HierarchyId!]);
                const progress = [...progressLookup.values()].reduce(
                    (result, item) => {
                        const statusInfo = jobSvc.getStatusInfo(item);
                        result.progress += statusInfo?.progress ?? 0;
                        result.inProgress = result.inProgress || (statusInfo?.inProgress ?? false);
                        return result;
                    },
                    { progress: 0, inProgress: false }
                );
                return progress;
            },
            (result) => !result?.inProgress,
            3000
        );

        await poller;
        updateResource();
    };

    useEffect(() => {
        setNextSyncDate(addHours(lastSyncDate, hoursBetweenRefresh));
    }, [lastSyncDate]);
    const { getDescendUrl } = useNav();
    const link = useLink();

    const title =
        dispResourceId == dispResourceName ? (
            <>
                <Title order={4} m={0} p={0}>
                    {dispResourceName}
                </Title>
            </>
        ) : (
            <>
                <Title order={4} m={0} p={0}>
                    {dispResourceName}
                </Title>
                <Title sx={{ fontWeight: 'normal' }} order={5} m={0} p={0}>
                    {props.type ?? dispResourceId}
                </Title>
            </>
        );

    const resourceSupportsResync = (resource?: BaseResource) => {
        return resource?.CloudPlatform === 'Aws';
    };

    const syncButton = (
        <Box sx={{ flex: 0 }}>
            <Text size="sm">Last Sync Date</Text>
            <Group
                noWrap
                spacing={0}
                align="stretch"
                sx={{ background: theme.white, borderRadius: theme.radius.sm, border: `solid 1px ${theme.colors.gray[4]}` }}
            >
                <Stack px="xs" justify="center" sx={{ background: theme.colors.gray[2] }}>
                    {sync ? (
                        <Text size="sm">Updating Resource</Text>
                    ) : (
                        <Text size="sm" sx={{ whiteSpace: 'nowrap' }}>
                            {lastSyncDate.getFullYear() == 9999
                                ? 'Loading last sync...'
                                : lastSyncDate.getFullYear() == 2000
                                ? 'No results found'
                                : fmtSvc.formatDatetimeNoSecs(lastSyncDate).toString().replace(',', '')}
                        </Text>
                    )}
                </Stack>
                <Divider orientation="vertical" />
                <Tooltip
                    label={sync ? 'Resync in progress' : canSync() ? 'Resync resource' : 'Resync available every ' + hoursBetweenRefresh + ' hour'}
                >
                    <ActionIcon disabled={sync || !canSync()} onClick={resync}>
                        <Refresh
                            style={{
                                color: sync ? theme.black : canSync() ? theme.colors.primary[6] : theme.colors.warning[6],
                                height: '16px',
                                width: '16px',
                                cursor: !sync && canSync() ? 'pointer' : 'default',
                            }}
                            className={sync ? 'ti-spin' : ''}
                        />
                    </ActionIcon>
                </Tooltip>
            </Group>
            <Anchor {...link(getDescendUrl('connection-health'))} size="xs">
                Check connection health
            </Anchor>
        </Box>
    );
    return (
        <Group noWrap position="apart" style={{ width: '900px' }}>
            <div>{title}</div>
            {resourceSupportsResync(props.resource) ? <div>{syncButton}</div> : null}
        </Group>
    );
}

import styled from '@emotion/styled';

export const ActionPageContent = styled.div`
    display: flex;
    justify-content: center;
`;

export const InfoBoxContainer = styled.div`
    height: 500px;
    position: relative;
    margin: 4px;
`;

export const InfoBoxSubHeader = styled.div`
    height: 40px;
    position: relative;
    border: solid 1px #0004;
    padding-left: 16px;
`;

export const ColumnHeader = styled.div`
    height: 40px;
    position: relative;
    border: solid 1px #0001;
    padding-left: 16px;
    padding-right: 100px;
    padding-top: 8px;
    justify-content: center;
`;

export const ActionPageContainer = styled.div`
    height: 100%;
    display: flex;
    position: relative;
    margin: 4 4 4 4;
`;

export const InfoListContainer = styled.div`
    height: 100%;
    position: relative;
`;

export const InfoListTitle = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`;

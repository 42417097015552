import {
    deleteCspMktStagePrivateAwsSubscription,
    getCspMktGetAwsMarketplaceOffers,
    getCspMktGetAwsSubscriptions,
    getSubscriptionGetSubscriptionTypes,
    postCspMktStagePrivateAwsSubscription,
} from '@apis/Customers';
import { AwsMarketplaceOffer, AwsMarketplaceSubscription, Company } from '@apis/Customers/model';
import styled from '@emotion/styled';
import { Modal, Group, Button, Box, LoadingOverlay, Space, Text, Tooltip, ActionIcon, TextInput, Select, CopyButton, Anchor } from '@mantine/core';
import { useDisclosure, useInputState } from '@mantine/hooks';
import { DataGrid } from '@root/Components/DataGrid';
import { GridFullCell } from '@root/Components/DataGrid/Design';
import { ColumnConfig } from '@root/Components/DataGrid/Models';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { useDi, useDiComponent } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AlertTriangle, Trash } from 'tabler-icons-react';
import { confirmAction } from './ConfirmActionDialog';
import { AdminPageTitle, AdminPanel } from './Design';

export function AdminMarketplaceOffers({ companyInfo }: { companyInfo: Company }) {
    const [addOfferOpened, { open: openAddOffer, close: closeAddOffer }] = useDisclosure(false);
    const [loading, setLoading] = useState(false);
    const [offers, setOffers] = useState<AwsMarketplaceSubscription[]>([]);
    const fmtSvc = useDi(FormatService);
    const DiContainer = useDiComponent();
    const loadOffers = async () => {
        setLoading(true);
        try {
            const offers = await getCspMktGetAwsSubscriptions();
            setOffers(offers.filter((x) => !!x.PrivateOfferUrl));
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        loadOffers();
    }, []);

    const removeOffer = async (item: AwsMarketplaceSubscription) => {
        setLoading(true);
        try {
            await deleteCspMktStagePrivateAwsSubscription({ subscriptionId: item.Id });
            await loadOffers();
        } finally {
            setLoading(false);
        }
    };

    const confirmDeleteOffer = useCallback((item: AwsMarketplaceSubscription) => {
        confirmAction({
            headerText: 'Remove Marketplace Offer?',
            promptText: `Are you sure you want to remove offer for "${item.SubscriptionType?.FriendlyName}"?`,
            confirmButtonText: 'Remove Offer',
            cancelButtonText: 'Cancel',
            confirmAction: () => removeOffer(item),
        });
    }, []);

    const columns = useMemo(() => {
        return [
            {
                id: 'subscription',
                header: 'Subscription',
                accessor: (x: AwsMarketplaceSubscription) => x.SubscriptionType?.FriendlyName,
                defaultWidth: 250,
                type: 'string',
            },
            {
                id: 'accountId',
                header: 'Account ID',
                accessor: 'AwsAccountId',
                defaultWidth: 150,
            },
            {
                id: 'url',
                header: 'Private Offer URL',
                accessor: 'PrivateOfferUrl',
                defaultWidth: 200,
                cellRenderer: (item: AwsMarketplaceSubscription) => (
                    <GridFullCell>
                        <CopyButton value={item.PrivateOfferUrl ?? ''}>
                            {({ copy, copied }) => (
                                <Tooltip position="bottom-start" withinPortal label={copied ? 'Copied' : 'Click to Copy URL'}>
                                    <Anchor onClick={copy}>{item.PrivateOfferUrl ?? ''}</Anchor>
                                </Tooltip>
                            )}
                        </CopyButton>
                    </GridFullCell>
                ),
            },
            {
                id: 'status',
                header: 'Status',
                accessor: 'Status',
                defaultWidth: 75,
                align: 'center',
            },
            {
                id: 'createdAt',
                header: 'Created At',
                accessor: 'CreatedAt',
                defaultWidth: 125,
                align: 'center',
                type: 'date',
                cellRenderer: (item: AwsMarketplaceSubscription) => (
                    <Tooltip position="bottom" withinPortal label={fmtSvc.formatDatetime(fmtSvc.toLocalDate(item.CreatedAt))}>
                        <GridFullCell>{fmtSvc.timeAgo(fmtSvc.toLocalDate(item.CreatedAt ?? ''))}</GridFullCell>
                    </Tooltip>
                ),
            },
            {
                id: 'remove',
                header: 'Remove',
                accessor: () => null,
                headerRenderer: () => <></>,
                noSort: true,
                defaultWidth: 50,
                align: 'center',
                cellRenderer: (item: AwsMarketplaceSubscription) => {
                    const canRemove = item.Status === 'Staged';
                    return (
                        <GridFullCell className="delete-icon" style={{ justifyContent: 'center', display: 'flex' }}>
                            <Tooltip
                                withinPortal
                                position="bottom-start"
                                label={canRemove ? 'Remove offer' : 'Offer cannot be removed while status is not Staged'}
                            >
                                <ActionIcon disabled={!canRemove} onClick={() => confirmDeleteOffer(item)}>
                                    <Trash size={16} />
                                </ActionIcon>
                            </Tooltip>
                        </GridFullCell>
                    );
                },
                exportOptions: {
                    hidden: true,
                },
            },
        ] as ColumnConfig<AwsMarketplaceSubscription>[];
    }, []);
    return (
        <AdminPanel>
            <Modal opened={addOfferOpened} closeOnClickOutside onClose={closeAddOffer} title="Add Private Offer">
                <DiContainer>{addOfferOpened && <CreateAwsPrivateOfferModal onClose={closeAddOffer} onOfferAdded={loadOffers} />}</DiContainer>
            </Modal>
            <Group position="apart">
                <AdminPageTitle data-atid="OffersMainTitle">Marketplace Offers</AdminPageTitle>
                <Button data-atid="InviteUserButton" onClick={openAddOffer}>
                    Add Offer
                </Button>
            </Group>
            <Space h="sm" />
            {loading ? (
                <Box sx={{ height: 400, position: 'relative' }}>
                    <LoadingOverlay visible />
                </Box>
            ) : !offers?.length ? (
                <Text my={200} align="center" italic color="dimmed">
                    No Marketplace Offers
                </Text>
            ) : (
                <OfferGridContainer>
                    <DataGrid hideFilter dataSource={offers} columns={columns} />
                </OfferGridContainer>
            )}
        </AdminPanel>
    );
}

function CreateAwsPrivateOfferModal({ onClose, onOfferAdded }: { onClose: () => void; onOfferAdded: () => void }) {
    const [offers, setOffers] = useState<({ label: string; value: string } & AwsMarketplaceOffer)[]>([]);
    const [loading, setLoading] = useState(false);
    const [offerUrl, setOfferUrl] = useInputState('');
    const [accountId, setAccountId] = useInputState('');
    const [selectedOfferId, setSelectedOfferId] = useInputState('');
    const company = useCompany();
    const notificationSvc = useDi(NotificationService);

    const load = async () => {
        try {
            setLoading(true);
            const [rawOffers, rawSubscriptionTypes] = await Promise.all([getCspMktGetAwsMarketplaceOffers(), getSubscriptionGetSubscriptionTypes()]);
            const typeLookup = new Map(rawSubscriptionTypes.map((s) => [s.Id!, s]));
            const offers = rawOffers
                .filter((o) => o.IsPublicOffer)
                .map((o) => ({ ...o, value: o.Id?.toString() ?? '', label: typeLookup.get(o.SubscriptionTypeId ?? 0)?.FriendlyName ?? '' }));
            offers.sort((a, b) => a.label.localeCompare(b.label));

            setOffers(offers);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        load();
    }, []);

    const createOffer = async () => {
        try {
            setLoading(true);
            await postCspMktStagePrivateAwsSubscription({
                CompanyId: company?.Id,
                AwsAccountId: accountId,
                PrivateOfferUrl: offerUrl,
                PublicOfferId: parseInt(selectedOfferId ?? '0'),
            });
            onOfferAdded();
            onClose();
        } catch {
            notificationSvc.notify('Error', 'Failed to create offer, please contact support', 'error', <AlertTriangle />);
        } finally {
            setLoading(false);
        }
    };

    const canCreate = accountId.length === 12 && offerUrl.length > 0 && selectedOfferId && !loading;

    return (
        <>
            <TextInput
                label="AWS Account ID"
                required
                description="12 digit AWS account ID"
                placeholder="000000000000"
                maxLength={12}
                value={accountId}
                onChange={setAccountId}
            />
            <Space h="xs" />
            <TextInput
                label="Private Offer URL"
                required
                description="Enter the AWS-provided URL for this offer"
                value={offerUrl}
                placeholder="https://aws.amazon.com/marketplace/saas/ordering?productId=***"
                onChange={setOfferUrl}
            />
            <Space h="xs" />
            <Select
                label="Public Offer"
                required
                description="Select the public offer on which this private offer is based"
                data={offers}
                value={selectedOfferId}
                onChange={setSelectedOfferId}
            ></Select>
            <Space h="lg" />
            <Group position="right">
                <Button disabled={!canCreate} onClick={createOffer}>
                    Create Offer
                </Button>
                <Button variant="outline" onClick={onClose}>
                    Cancel
                </Button>
            </Group>
        </>
    );
}

const OfferGridContainer = styled.div`
    height: 500px;
    svg {
        font-size: 16px;
    }
    tr:hover .delete-icon {
        visibility: visible;
    }
    .delete-icon {
        visibility: hidden;
    }
`;

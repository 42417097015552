import { CompanyStat, CompanyType, Int32StringKeyValuePair } from '@apis/Customers/model';
import type { Role, UserListItem } from '@apis/Customers/model';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    getRbacGetRolesByCompany,
    getSupportGetExternalSupportCompanies,
    getUserGetCompanyUsers,
    postRbacBulkAssignSupportUsers,
    postRbacBulkRemoveSupportUsers,
    postSupportBulkAssignSupportCompanies,
} from '@apis/Customers';
import { useDi, useDiComponent } from '@root/Services/DI';
import { Button, Group, MultiSelect, Popover, SelectItem, Space, Text } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { colorPalette } from '@root/Design/Themes';
import { MspService } from '@root/Services/MspService';
import { EventEmitter } from '@root/Services/EventEmitter';
import { useEvent } from '@root/Services/EventEmitter';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import styled from '@emotion/styled';
import { CompanyStatsGrid } from '@root/Components/CompanyStats/CompanyStatsGrid';
import { AddCompanyInfo } from '@root/Components/CompanyStats/AddCompanyInfo';
import { NotificationService } from '@root/Services/Notification/NotificationService';
export interface MyCompaniesGridProps {
    selectCompany: (selectedCompanyId: number) => void;
}

export const MyCompaniesGrid = (props: MyCompaniesGridProps) => {
    const refreshNeeded = useMemo(() => EventEmitter.empty(), []);
    const authZValues = useAuthZValues({ supportCompany: { SupportCompany: 'Manage' }, supportUser: { SupportUser: 'Manage' } });
    const { supportCompany, supportUser } = useMemo(() => authZValues, []);
    const mspSvc = useDi(MspService);
    const notificationSvc = useDi(NotificationService);
    const [enableBulkActions, setEnableBulkAcctions] = useState(false);
    const [bulkActionsOpened, setBulkActionsOpened] = useState(false);
    const [usersList, setUsersList] = useState<UserListItem[]>([]);
    const [rolesList, setRolesList] = useState<Role[]>([]);
    const [supportCompaniesList, setSupportCompaniesList] = useState<Int32StringKeyValuePair[]>([]);
    let selectedCompanies = useRef<number[]>([]);
    let selectedUsers = useRef<number[]>([]);
    let selectedSupportCompanies = useRef<number[]>([]);

    const loadUsers = () => {
        getUserGetCompanyUsers().then((users) => {
            setUsersList(() => users);
        });
    };

    const loadRoles = () => {
        getRbacGetRolesByCompany().then((roles) => {
            setRolesList(() =>
                roles
                    .filter(
                        (r) =>
                            (r.CompanyType == null || r.CompanyType == CompanyType.Support || r.CompanyType == CompanyType.PlatformSupport) &&
                            r.CompanyId == null
                    )
                    .sort((a, b) => (a.Name ?? '').localeCompare(b.Name ?? ''))
            );
        });
    };

    const loadSupportCompanies = () => {
        getSupportGetExternalSupportCompanies().then((companies) => {
            setSupportCompaniesList(() => companies);
        });
    };

    useEffect(() => {
        loadUsers();
        loadRoles();
        if (supportCompany) loadSupportCompanies();
    }, []);

    useEffect(() => {
        if (selectedCompanies.current.length > 0) {
            setEnableBulkAcctions(true);
        } else if (selectedCompanies.current.length == 0) {
            setEnableBulkAcctions(false);
        }
    }, [selectedCompanies]);

    useEvent(mspSvc.companyListChanged, () => {
        refreshNeeded.emit();
    });

    const onCheckedCompanyChange = useCallback(async (items: CompanyStat[] | undefined) => {
        let hasCheckedItems = items && items?.length > 0;
        setEnableBulkAcctions(hasCheckedItems!);
        if (hasCheckedItems) {
            let companiesIds = items?.map((i) => +i.Id!) as number[];
            selectedCompanies.current = companiesIds;
        }
    }, []);

    const modals = useModals();
    const DiContainer = useDiComponent();

    const openAddCompanyModal = () => {
        const id = modals.openModal({
            title: (
                <Text size={18} weight={600} color={colorPalette.darkTitleColor} align="center">
                    Add a company
                </Text>
            ),
            children: (
                <DiContainer>
                    <AddCompanyInfo
                        onClose={() => {
                            modals.closeModal(id);
                        }}
                        onSubmit={(companyId) => {
                            refreshNeeded.emit();
                            modals.closeModal(id);
                            props.selectCompany(companyId);
                        }}
                    />
                </DiContainer>
            ),
            closeOnClickOutside: false,
        });
    };

    async function handleOnSelectUsers(e: string[]) {
        let values = e.map((val) => +val);
        selectedUsers.current = values;
    }

    async function handleOnSelectSupportCompanies(e: string[]) {
        let values = e.map((val) => +val);
        selectedSupportCompanies.current = values;
    }

    const assignCompaniesToSupportModal = () => {
        setBulkActionsOpened(false);
        const id = modals.openModal({
            size: 'lg',
            title: (
                <Text size={18} weight={600} color={colorPalette.darkTitleColor} align="center">
                    Assign Companies for External Support
                </Text>
            ),
            children: (
                <>
                    <Text size={15} weight={400} color={colorPalette.subTextColor} align="left">
                        Select the support companies to assign to the selected companies.
                    </Text>
                    <MultiSelectWraper>
                        <MultiSelect
                            label=""
                            searchable
                            data={supportCompaniesList.map((i) => {
                                return {
                                    label: i.Value,
                                    value: i.Key?.toString(),
                                } as SelectItem;
                            })}
                            onChange={handleOnSelectSupportCompanies}
                        />
                    </MultiSelectWraper>
                    <Space h="xl" />
                    <Group position="right">
                        <Button
                            variant="outline"
                            sx={{ backgroundColor: colorPalette.white, width: '190px' }}
                            onClick={() => {
                                modals.closeModal(id);
                                selectedUsers.current = [];
                            }}
                        >
                            <Text weight={600} color={colorPalette.subHeaderTextColor} size={16}>
                                Cancel
                            </Text>
                        </Button>
                        <Button sx={{ width: '190px' }} type="submit">
                            <Text
                                weight={600}
                                color={colorPalette.white}
                                size={16}
                                onClick={() => {
                                    assignCompaniesToSupport(id);
                                }}
                            >
                                Assign Companies
                            </Text>
                        </Button>
                    </Group>
                </>
            ),
        });
    };

    const assignCompaniesToSupport = (modalId: string) => {
        postSupportBulkAssignSupportCompanies({
            CompaniesIds: selectedCompanies.current,
            SupportCompaniesIds: selectedSupportCompanies.current,
        }).then(() => {
            modals.closeModal(modalId);
            notificationSvc.notify('Bulk Assign Companies.', 'Added companies to selected support companies.', 'success', <></>);
        });
    };

    const openAssignUsersModal = () => {
        setBulkActionsOpened(false);
        const id = modals.openModal({
            size: 'lg',
            title: (
                <Text size={18} weight={600} color={colorPalette.darkTitleColor} align="center">
                    Assign Support Users
                </Text>
            ),
            children: (
                <>
                    <Text size={15} weight={400} color={colorPalette.subTextColor} align="left">
                        Select users to assign to the selected companies.
                    </Text>
                    <MultiSelectWraper>
                        <MultiSelect
                            label="Select Users"
                            searchable
                            data={usersList.map((i) => {
                                return {
                                    label: !i.FirstName && !i.LastName ? i.EMail : i.FirstName + ' ' + i.LastName,
                                    value: i.Id?.toString(),
                                } as SelectItem;
                            })}
                            onChange={handleOnSelectUsers}
                        />
                    </MultiSelectWraper>
                    <Space h="xl" />
                    <Group position="right">
                        <Button
                            variant="outline"
                            sx={{ backgroundColor: colorPalette.white, width: '190px' }}
                            onClick={() => {
                                modals.closeModal(id);
                                selectedUsers.current = [];
                            }}
                        >
                            <Text weight={600} color={colorPalette.subHeaderTextColor} size={16}>
                                Cancel
                            </Text>
                        </Button>
                        <Button sx={{ width: '190px' }} type="submit">
                            <Text
                                weight={600}
                                color={colorPalette.white}
                                size={16}
                                onClick={() => {
                                    assignUsersToCompanies(id);
                                }}
                            >
                                Assign Users
                            </Text>
                        </Button>
                    </Group>
                </>
            ),
        });
    };

    const assignUsersToCompanies = (modalId: string) => {
        postRbacBulkAssignSupportUsers({
            CompaniesIds: selectedCompanies.current,
            UsersIds: selectedUsers.current,
        }).then(() => {
            modals.closeModal(modalId);
            notificationSvc.notify('Bulk Assign Users.', 'Added users to selected companies.', 'success', <></>);
        });
    };

    const openRemoveUsersModal = () => {
        setBulkActionsOpened(false);
        const id = modals.openModal({
            size: 'lg',
            title: (
                <Text size={18} weight={600} color={colorPalette.darkTitleColor} align="center">
                    Remove Support Users
                </Text>
            ),
            children: (
                <>
                    <Text size={15} weight={400} color={colorPalette.subTextColor} align="left">
                        Which Support users do you want to remove from the selected companies?
                    </Text>
                    <MultiSelectWraper>
                        <MultiSelect
                            label="Select Users to Remove"
                            searchable
                            data={usersList.map((i) => {
                                return {
                                    label: !i.FirstName && !i.LastName ? i.EMail : i.FirstName + ' ' + i.LastName,
                                    value: i.Id?.toString(),
                                } as SelectItem;
                            })}
                            onChange={handleOnSelectUsers}
                        />
                    </MultiSelectWraper>
                    <Space h="xl" />
                    <Group position="right">
                        <Button
                            variant="outline"
                            sx={{ backgroundColor: colorPalette.white, width: '190px' }}
                            onClick={() => {
                                modals.closeModal(id);
                                selectedUsers.current = [];
                            }}
                        >
                            <Text weight={600} color={colorPalette.subHeaderTextColor} size={16}>
                                Cancel
                            </Text>
                        </Button>
                        <Button sx={{ width: '190px' }} type="submit">
                            <Text
                                weight={600}
                                color={colorPalette.white}
                                size={16}
                                onClick={() => {
                                    removeUsersFromCompanies(id);
                                }}
                            >
                                Remove Users
                            </Text>
                        </Button>
                    </Group>
                </>
            ),
        });
    };

    const removeUsersFromCompanies = (modalId: string) => {
        postRbacBulkRemoveSupportUsers({
            CompaniesIds: selectedCompanies.current,
            UsersIds: selectedUsers.current,
        }).then(() => {
            modals.closeModal(modalId);
            notificationSvc.notify('Bulk Remove Users.', 'Removed users to selected companies.', 'success', <></>);
        });
    };
    const buttons = (supportCompany || supportUser) && (
        <>
            <Space w="sm" />
            <Popover width={225} position="bottom" withArrow shadow="md" opened={bulkActionsOpened} onChange={setBulkActionsOpened}>
                <Popover.Target>
                    <Button
                        onClick={() => setBulkActionsOpened((o) => !o)}
                        disabled={!enableBulkActions}
                        style={{ marginRight: '15px' }}
                        rightIcon={<i className="ti ti-chevron-down" />}
                    >
                        Actions
                    </Button>
                </Popover.Target>
                <Popover.Dropdown>
                    <BulkActionMenuOption
                        onClick={() => {
                            openAssignUsersModal();
                        }}
                    >
                        Assign Support Users
                    </BulkActionMenuOption>
                    <BulkActionMenuOption
                        onClick={() => {
                            openRemoveUsersModal();
                        }}
                    >
                        Remove Support Users
                    </BulkActionMenuOption>
                    {supportCompany && (
                        <BulkActionMenuOption
                            onClick={() => {
                                assignCompaniesToSupportModal();
                            }}
                        >
                            Assign Companies to CSM
                        </BulkActionMenuOption>
                    )}
                </Popover.Dropdown>
            </Popover>
            {supportCompany && (
                <Button
                    leftIcon={<i className="ti ti-plus" />}
                    variant="outline"
                    data-atid="AddCompanyButton"
                    onClick={() => {
                        openAddCompanyModal();
                    }}
                >
                    Add Company
                </Button>
            )}
        </>
    );

    return (
        <>
            <CompanyStatsGrid
                refreshNeeded={refreshNeeded}
                selectCompany={props.selectCompany}
                rightTopPlaceHolder={buttons}
                onCheckedCompanyChange={onCheckedCompanyChange}
            />
        </>
    );
};

const BulkActionMenuOption = styled.div`
    cursor: pointer;
    height: 30px;
    margin-top: 15px;
`;

const MultiSelectWraper = styled.div`
    margin-top: 15px;
`;

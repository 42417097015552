import { useMantineTheme, Center, Divider, Title, Grid, Box } from '@mantine/core';
import { PageContent, PaneledPage, PagePanel, PanelBody } from '@root/Design/Layout';
import { CustomColors } from '@root/Design/Themes';
import { useEventValue } from '@root/Services/EventEmitter';
import { useNotificationCrudSvc } from '@root/Services/Notification/NotificationCrudService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { Loader } from 'tabler-icons-react';
import { NotificatioNavBar, AppNotificationPreferences } from './NotificationSettings';

export const InvoiceManagerNotificationPage = () => {
    const notificationCrudSvc = useNotificationCrudSvc();
    const loading = useEventValue(notificationCrudSvc.loading);

    const theme = useMantineTheme();
    return loading ? (
        <Center sx={{ height: 400 }}>
            <Loader />
        </Center>
    ) : (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <NotificatioNavBar />
            <Divider orientation="vertical" />
            <PageContent style={{ background: theme.colors.gray[0] as CustomColors }}>
                <PaneledPage>
                    <PagePanel size="fill">
                        <Title order={3} px="xl" pt="md" style={{ paddingBottom: '10px' }}>
                            Invoice Manager Notifications
                        </Title>
                        <Divider orientation="horizontal" />
                        <PanelBody>
                            <Grid>
                                <Grid.Col span={12}>
                                    <AppNotificationPreferences app="Invoice Manager" crudSvc={notificationCrudSvc} />
                                </Grid.Col>
                            </Grid>
                        </PanelBody>
                    </PagePanel>
                </PaneledPage>
            </PageContent>
        </Box>
    );
};
endpoint('invoice-notification-settings', InvoiceManagerNotificationPage, 'Invoice Manager Notification Settings');

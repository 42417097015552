import { InvoiceAllocationRuleStatus } from '@apis/Invoices/model';
import styled from '@emotion/styled';
import { Box, Group, Select, Space, useMantineTheme, Text, Card, ThemeIcon } from '@mantine/core';
import { useObserver } from 'mobx-react';
import { forwardRef, ReactNode } from 'react';
import { AlertTriangle } from 'tabler-icons-react';
import { EditorCard, RuleStatusIcon } from './Design';
import { AllocationRuleEditor } from './Model';
import { ValidationIssues } from './ValidationIssues';

const statusDescription: Record<InvoiceAllocationRuleStatus, ReactNode> = {
    Active: 'Rule is active and will be applied to invoices.',
    Draft: (
        <>
            This rule will be <strong>inactive</strong> when saved
        </>
    ),
    Test: 'This rule is in test mode and will not be applied to invoices',
};

const selectOptions = [
    { value: 'Active', label: 'Active' },
    { value: 'Draft', label: 'Inactive' },
] as { value: InvoiceAllocationRuleStatus; label: string }[];

const SelectItem = forwardRef<HTMLDivElement, { value: InvoiceAllocationRuleStatus; label: string }>(
    ({ label, value, ...others }: { value: InvoiceAllocationRuleStatus; label: string }, ref) => {
        const theme = useMantineTheme();
        return (
            <SelectItemWrapper ref={ref} {...others}>
                <Group noWrap>
                    <RuleStatusIcon active={value === 'Active'} />

                    <div>
                        <Text size="sm">{label}</Text>
                    </div>
                </Group>
            </SelectItemWrapper>
        );
    }
);
const SelectItemWrapper = styled.div`
    background: #fff !important;
    color: ${(p) => p.theme.colors.gray[7]} !important;
    &:hover {
        background: ${(p) => p.theme.colors.primary[1]} !important;
    }
`;

export function RuleStatus({ ruleEditor }: { ruleEditor: AllocationRuleEditor }) {
    const theme = useMantineTheme();
    const supplementalText = ruleEditor.rule?.Status ? statusDescription[ruleEditor.rule.Status] : '';
    const ruleIssues = ruleEditor.getRuleTypeIssues();
    const hasValidationErrors = ruleEditor.filterValidation.length || ruleIssues?.length;
    const status = useObserver(() => ruleEditor.rule?.Status);
    return (
        <EditorCard title="Rule Status" color="blue">
            <Group noWrap>
                <Box mt="md" sx={{ minWidth: 250 }}>
                    <Flex>
                        <Select
                            sx={{ maxWidth: 150 }}
                            withinPortal
                            value={status}
                            onChange={ruleEditor.setRuleStatus}
                            data={selectOptions}
                            icon={<RuleStatusIcon active={status === 'Active'} />}
                            itemComponent={SelectItem}
                        />
                        <Space w="md" />
                        <Text size="sm">{supplementalText}</Text>
                    </Flex>
                </Box>
                <Box>
                    {!hasValidationErrors ? null : (
                        <Card radius="lg" sx={{ background: theme.white }}>
                            <Group noWrap>
                                <ThemeIcon radius="lg" variant="light" color="warning">
                                    <AlertTriangle size={20} />
                                </ThemeIcon>
                                <Text size="sm">
                                    The rule may only be saved in <strong>Inactive</strong> mode, because of validation errors.
                                </Text>
                            </Group>
                        </Card>
                    )}
                    <Box px="lg">
                        {!ruleIssues?.length ? null : <ValidationIssues title="There are issues with the rule type setup" issues={ruleIssues} />}
                        {!ruleEditor.filterValidation.length ? null : (
                            <ValidationIssues title="Some filters are not valid" issues={ruleEditor.filterValidation} />
                        )}
                    </Box>
                </Box>
            </Group>
        </EditorCard>
    );
}

const Flex = styled.div`
    display: flex;
    align-items: center;
`;

import { PageContent, PageSection } from '@root/Design/Layout';
import { endpoint } from '@root/Services/Router/EndpointRegistry';

export function Reports() {
    return (
        <PageContent>
            <PageSection>Welcome to Resources Reports Page</PageSection>
        </PageContent>
    );
}
endpoint('reports', Reports, 'Reports');

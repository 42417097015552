export enum OpenSidebar {
    addFieldRep,
    addMSP,
    addCustomer,
    undefined,
}

export enum CandidateType {
    FieldRep,
    MSP,
    Customer,
}

export enum ApprovalStatus {
    Approved = 1,
    Rejected = 0,
}

/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.TagManager.Host.Api
 * OpenAPI spec version: 1.0
 */

export type MapCreditsOptionsMapDestination = typeof MapCreditsOptionsMapDestination[keyof typeof MapCreditsOptionsMapDestination];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MapCreditsOptionsMapDestination = {
  AWS: 'AWS',
  AnyAWSDBAService: 'AnyAWSDBAService',
  EC2: 'EC2',
} as const;

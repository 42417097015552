import ExcelJS from 'exceljs';

interface ExcelTableColumn {
    value: (item: any) => string | number | null | undefined;
    header: string;
    format: string;
}

export class InBrowserExportService {
    private readonly minColumnWidth = 15;
    private readonly maxColumnWidth = 60;
    public async export(data: any[], columns: ExcelTableColumn[], name: string) {
        const { sheet, workbook } = this.createDefaultWorkbook(name);
        this.addTable(sheet, data, columns);
        const buffer = await workbook.xlsx.writeBuffer();
        this.download(buffer, `${name} - ${data.length} records`);
    }

    private addTable(sheet: ExcelJS.Worksheet, data: any[], columns: ExcelTableColumn[]) {
        const colChars = columns.map((column) => column.header.length);
        const tableData = data.map((item) => {
            return columns.map((column, i) => {
                const result = this.cleanValue(column.value(item));
                if (result) {
                    colChars[i] = Math.max(colChars[i], result.toString().length);
                }
                return result;
            });
        });
        sheet.addTable({
            name: sheet.name.replaceAll(' ', ''),
            ref: 'A1',
            headerRow: true,
            style: {
                theme: 'TableStyleLight1',
                showRowStripes: true,
            },
            columns: columns.map((column) => ({
                name: column.header,
                filterButton: true,
            })),
            rows: tableData,
        });
        for (let i = 0; i < columns.length; i++) {
            sheet.getColumn(i + 1).width = Math.max(this.minColumnWidth, Math.min(this.maxColumnWidth, colChars[i] + 2));
        }
    }

    private cleanValue(value: any) {
        if (value && typeof value === 'object' && 'first' in value && 'values' in value) {
            return value.first;
        }
        return value;
    }

    private createDefaultWorkbook(name: string) {
        const workbook = new ExcelJS.Workbook();
        workbook.creator = '';
        workbook.created = new Date();
        workbook.modified = new Date();

        const sheet = workbook.addWorksheet(name);

        return { sheet, workbook };
    }
    private download = (data: any, name: string) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${name}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    };
}

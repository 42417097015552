import {
    getMapContractsGetMapContractById,
    getMapContractsGetMapContracts,
    postMapContractsGetMapResourceTypes,
    postMapContractsQueryMapContractStats,
    postMapContractsUpdateMapContract,
} from '@apis/TagManager';
import { MapContract, MapContractDailySnapShot } from '@apis/TagManager/model';
import { BaseResource } from '@root/Components/Resources/ResourcesGrid';
import { useDi } from '@root/Services/DI';
import { EventEmitter } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { IStringFluentOperators, exprBuilder, queryBuilder } from '@root/Services/QueryExpr';
import { makeAutoObservable } from 'mobx';
import { Lifecycle, scoped } from 'tsyringe';
import { MapResourceQueryService } from '../../Services/MapResourceQueryService';
import { BaseAwsResource, IQueryExpr } from '@apis/Resources/model';
import { QueryExpr, QueryResult, postResourcesQuery, postResourcesSearch } from '@apis/Resources';
import { ResourceDetails } from '@root/Components/Resources/ResourceDetails';
import { QueryExprGroupHelper } from '@root/Components/DataGrid/QueryExprGroupHelper';
import { WorkloadDetailsGridDataItem } from './WorkloadSection';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { DataGridState, IDataSource } from '@root/Components/DataGrid/Models';

export interface ProgressArrDataPoint {
    date: Date;
    arrValue: number;
}

@scoped(Lifecycle.ContainerScoped)
export class MapMonitoringHomeModel {
    public fmtSvc = useDi(FormatService);
    public company = useCompany();
    public contract?: MapContract;
    public mapQuerySvc!: MapResourceQueryService;
    public latestContractSnapshot?: MapContractDailySnapShot;
    public loading = new EventEmitter(true);
    public todaysSnapshotId?: string;
    public workloadDetailsQueryHelper?: QueryExprGroupHelper<BaseResource>;
    private readonly maxGroupByRecords = 1000;
    public workloadDetailsDefaultCriteria?: IQueryExpr;

    public constructor() {
        makeAutoObservable(this);
    }

    public init(rawId: string | number) {
        this.load(rawId);
        return this;
    }

    public reloadContract() {
        this.load(this.contract!.Id!);
    }

    public async reloadContractOnly() {
        this.contract = await getMapContractsGetMapContractById({ id: this.contract!.Id! });
    }

    public getContractStartDate() {
        if (!this.contract?.ContractStartDate) return undefined;
        return new Date(this.contract?.ContractStartDate!);
    }

    public getContractEndDate() {
        const startDate = new Date(this.contract?.ContractStartDate!);
        if (this.contract?.ContractTermYears == null && !this.contract?.ContractEndDate) return undefined;

        if (this.contract?.ContractEndDate) return new Date(this.contract?.ContractEndDate!);

        return new Date(new Date(startDate).setFullYear(startDate.getFullYear() + this.contract.ContractTermYears!));
    }

    private async load(rawId: string | number) {
        this.loading.emit(true);
        try {
            const id = typeof rawId === 'number' ? rawId : parseInt(rawId);
            this.contract = await getMapContractsGetMapContractById({ id });
            const mapResourceMeta = await postMapContractsGetMapResourceTypes();
            const allContracts = await getMapContractsGetMapContracts();
            const otherContracts = allContracts.filter((c) => c.Id !== this.contract?.Id);
            this.mapQuerySvc = new MapResourceQueryService(this.contract, mapResourceMeta, this.fmtSvc, otherContracts);
            this.todaysSnapshotId =
                this.fmtSvc.to8DigitDate(new Date()).toString() + '/' + (this.contract!.CompanyId! ?? 0) + '/' + (this.contract!.Id! ?? 0);
            await this.getLatestSnapshot();
        } finally {
            this.loading.emit(false);
        }
    }

    public async saveContract() {
        await postMapContractsUpdateMapContract({ ...this.contract });
        this.reloadContract();
    }

    public async getLatestSnapshot() {
        const response = await queryBuilder<MapContractDailySnapShot>()
            .where(() => {
                return this.getCompanyContractSnapshotQuery();
            })
            .sortDesc((b) => b.model.Date!)
            .take(1)
            .execute(postMapContractsQueryMapContractStats);

        this.latestContractSnapshot = response.Results![0];
    }

    public async getDateRangeSnapshotArrData(startDate: Date, endDate: Date) {
        const results = await queryBuilder<Omit<MapContractDailySnapShot, 'Date'> & { Date: Date }>()
            .where((b) => {
                return b.and(this.getCompanyContractSnapshotQuery(), b.model.Date.onOrAfter(startDate), b.model.Date.onOrBefore(endDate));
            })
            .take(1000)
            .sortAsc((b) => b.model.Date!)
            .execute(postMapContractsQueryMapContractStats);
        return results.Results!.map((r) => {
            return {
                date: r.Date,
                arrValue: r.Arr?.EligibleTaggedCorrectAnnualizedSpend === undefined ? null : r.Arr?.EligibleTaggedCorrectAnnualizedSpend!,
            } as ProgressArrDataPoint;
        });
    }

    public async getSpendSnapshotDate(spend: number) {
        var results = await queryBuilder<MapContractDailySnapShot & { 'Arr.EligibleTaggedCorrectAnnualizedSpend': number }>()
            .where((b) => {
                return b.and(this.getCompanyContractSnapshotQuery(), b.model['Arr.EligibleTaggedCorrectAnnualizedSpend'].gte(spend));
            })
            .sortAsc((b) => b.model.Date!)
            .take(1)
            .execute(postMapContractsQueryMapContractStats);
        return results.Results![0] ? results.Results![0].Date : '';
    }

    public getCompanyContractSnapshotQuery() {
        return exprBuilder<MapContractDailySnapShot>().createFluentExpr((b) =>
            b.and(b.model.CompanyId!.eq(this.contract!.CompanyId!), b.model.Id!.eq(this.contract!.Id!))
        );
    }

    public async getWorkloadResourceData() {
        const results = await queryBuilder<BaseAwsResource>()
            .where((b) => b.model[`CsTags.map-workload`].isNotNull())
            .select((b) => ({
                workloadName: b.model[`CsTags.map-workload`] as IStringFluentOperators,
                eligibleSpend: b.aggIf(this.mapQuerySvc.getValidResourcesQuery(), b.sum(b.model.ExtendedAnnualizedCost)),
                eligibleCount: b.countIf(this.mapQuerySvc.getValidResourcesQuery()),
                eligibleTaggedCorrectlyCount: b.countIf(this.mapQuerySvc.getCorrectlyTaggedQuery()),
                outsidePeriodSpend: b.aggIf(this.mapQuerySvc.getOutsidePeriodQuery(), b.sum(b.model.ExtendedAnnualizedCost)),
                outsidePeriodCount: b.countIf(this.mapQuerySvc.getOutsidePeriodQuery()),
                invalidResourceSpend: b.aggIf(this.mapQuerySvc.getNotCoveredQuery(), b.sum(b.model.ExtendedAnnualizedCost)),
                invalidResourceCount: b.countIf(this.mapQuerySvc.getNotCoveredQuery()),
                overtaggedSpend: b.aggIf(this.mapQuerySvc.getOverrideQuery(), b.sum(b.model.ExtendedAnnualizedCost)),
                overtaggedCount: b.countIf(this.mapQuerySvc.getOverrideQuery()),
                uniqueResourceTypesCount: b.countUniqueValues(b.model.ResourceType!),
                taggedCorrectlyResourceCount: b.countIf(this.mapQuerySvc.getCorrectlyTaggedQuery()),
            }))
            .execute(postResourcesQuery);

        return results.Results!;
    }

    public async getWorkloadDetailsDataSource(selectedWorkload: string, detailsGrid: DataGridModel) {
        this.workloadDetailsQueryHelper = new QueryExprGroupHelper<BaseResource>(
            (q) => postResourcesSearch(q, { companyId: this.company!.Id }),
            (q) => postResourcesQuery(q, { companyId: this.company!.Id }) as unknown as Promise<QueryResult<BaseResource>>,
            this.maxGroupByRecords
        );
        this.workloadDetailsQueryHelper?.attach(detailsGrid);
        this.workloadDetailsDefaultCriteria = {
            Operation: 'and',
            Operands: [{ Operation: 'eq', Operands: [{ Field: 'CsTags.map-workload' }, { Value: selectedWorkload }] }],
        };
        const grid = this;
        return {
            async getPage(start: number, end: number, state: DataGridState, parent?: BaseResource) {
                return await grid.getWorkloadDetailsPage(start, end, state, parent);
            },
        } as IDataSource<BaseResource>;
    }

    private async getWorkloadDetailsPage(start: number, end: number, state: DataGridState, parent?: BaseResource) {
        var page = await this.workloadDetailsQueryHelper!.getPage(start, end, state, parent, this.workloadDetailsDefaultCriteria as QueryExpr);
        return page;
    }

    public getCompanyContractBaseResourceQuery() {
        return exprBuilder<BaseResource>().createFluentExpr((b) => b.model.CompanyId!.eq(this.contract!.CompanyId!));
    }
}

import { getAccountGetAccountConnectionStatus, getCompanyGetAzureSubscriptions } from '@apis/Customers';
import styled from '@emotion/styled';
import { Tabs, Title } from '@mantine/core';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { PageContent, PanelBody, PanelContent } from '@root/Design/Layout';
import { CustomColors, theme } from '@root/Design/Themes';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { useDi } from '@root/Services/DI';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useEffect, useState } from 'react';
import { TagIntelligencePage } from './TagIntelligence/TagIntelligencePage';
import { AwsTagPolicySection } from './TagPolicy/Components/AwsTagPolicySection';
import { AzureTagPolicySection } from './TagPolicy/Components/AzureTagPolicySection';

export function TagPoliciesContent() {
    const company = useCompany()!;
    const auth = useDi(AuthenticationService);
    const [awsConnected, setAwsConnected] = useState<boolean>(false);
    const [azureConnected, setAzureConnected] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const hiddenGridFix = () => {
        setTimeout(() => window.dispatchEvent(new Event('resize')), 1);
    };

    useEffect(() => {
        (async () => {
            const awsConnectedStatus = await getAccountGetAccountConnectionStatus({ companyId: company?.Id });
            setAwsConnected(awsConnectedStatus.filter((aws) => aws.ConnectionStatus === true).length > 0);
            const azureSubscriptions = await getCompanyGetAzureSubscriptions();
            setAzureConnected(azureSubscriptions.length > 0);
        })();
    }, []);

    return (
        <TagIntelligencePage>
            <PageContent>
                <PanelContent>
                    <div
                        style={{
                            paddingBottom: '1rem',
                            borderBottom: '1px solid lightgray',
                        }}
                    >
                        <Title data-atid="IntelligenceHeader" order={3} px="xl" pt="md">
                            {awsConnected && azureConnected ? '' : awsConnected ? 'AWS ' : azureConnected ? 'Azure ' : null}Tag Policies
                        </Title>
                    </div>
                    {!loading && ((awsConnected && !azureConnected) || (!awsConnected && azureConnected)) && (
                        <>
                            {awsConnected && <AwsTagPolicySection />}
                            {azureConnected && <AzureTagPolicySection />}
                        </>
                    )}

                    {/* Only show tabs if both AWS and Azure are connected */}
                    {!loading && awsConnected && azureConnected && (
                        <PanelBody>
                            <Tabs defaultValue="aws" onTabChange={hiddenGridFix}>
                                <Tabs.List>
                                    <Tabs.Tab
                                        sx={{ marginRight: '16px' }}
                                        value="aws"
                                        icon={<img src="/assets/Amazon_Web_Services_Logo.svg" style={{ width: '25px', marginRight: '8px' }} />}
                                    >
                                        AWS
                                    </Tabs.Tab>
                                    <Tabs.Tab value="azure" icon={<img src="/assets/azure_logo.svg" style={{ width: '16px' }} />}>
                                        Azure
                                    </Tabs.Tab>
                                </Tabs.List>

                                <Tabs.Panel value="aws" pt="xs">
                                    <AwsTagPolicySection />
                                </Tabs.Panel>

                                <Tabs.Panel value="azure" pt="xs">
                                    <AzureTagPolicySection />
                                </Tabs.Panel>
                            </Tabs>
                        </PanelBody>
                    )}
                </PanelContent>
            </PageContent>
        </TagIntelligencePage>
    );
}

export const Container = styled.div`
    border: 1px solid ${theme?.colors?.gray?.[3] as CustomColors};
    border-radius: 10px;
    padding: 16px;
    height: 220px;
    background: ${theme?.colors?.gray?.[0] as CustomColors};
`;
export const Header = styled.div`
    font-size: 14px;
    font-weight: bold;
`;
export const PercentCovered = styled.div`
    font-size: 42px;
    font-weight: bold;
    padding: 10px;
`;
export const CoverageText = styled.div`
    padding: 5px 0 5px 0;
    width: 33.3%;
    height: 45px;
    color: ${theme?.colors?.gray?.[6] as CustomColors};
    font-size: 12px;
    text-align: center;
`;

export const NoEffectivePolicies = styled.div`
    font-size: 18px;
    margin-top: 25px;
    font-weight: bold;
    text-align: center;
`;

export function TagPolicies() {
    return <TagPoliciesContent />;
}
endpoint('tag-policies', TagPolicies, 'Tag Intelligence');

/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Customers.Host.Api
 * OpenAPI spec version: 1.0
 */

export type PostCWMetricsConfigSaveCWMetricsConfigAppType = typeof PostCWMetricsConfigSaveCWMetricsConfigAppType[keyof typeof PostCWMetricsConfigSaveCWMetricsConfigAppType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostCWMetricsConfigSaveCWMetricsConfigAppType = {
  IdleResources: 'IdleResources',
  RightSizing: 'RightSizing',
} as const;

import { getCompanyGetCompany, postCompanyStatsQueryCompanyStats } from '@apis/Customers';
import { Company, CompanyStat } from '@apis/Customers/model';
import styled from '@emotion/styled';
import { Anchor, Box, Center, Divider, Drawer, Loader, Popover, Space, Text, ThemeIcon } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Picker } from '@root/Components/Picker/Picker';
import { CompanyTenantPrereqService, useCompany } from '@root/Components/Router/CompanyContent';
import { SiteMenuCompanyService } from '@root/Components/Shell/SiteMenuCompanyService';
import { PageContent, PagePanel, PanelBody, PaneledPage } from '@root/Design/Layout';
import { useDi } from '@root/Services/DI';
import { EventEmitter } from '@root/Services/EventEmitter';
import { MspService } from '@root/Services/MspService';
import { useNav } from '@root/Services/NavigationService';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { queryBuilder } from '@root/Services/QueryExpr';
import { IChildRouteReady } from '@root/Services/Router/BasicRouteLoader';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useState, useEffect, useCallback } from 'react';
import { ChevronDown, Circle } from 'tabler-icons-react';
import { MyCompaniesGrid } from './MyCompaniesGrid';
import { CompanyContext } from '@root/Components/Router/CompanyContext';
import { CompanyContextService } from '@root/Services/Customers/CompanyContext';
import { useGetDefaultPageByCompany } from '@root/Components/Router/Hooks';
import { AdminSidePanel } from '@root/Components/CompanyInfo/Administration/AdminSidePanel';

export const SupportCompanies = () => {
    const [showSidePanel, setshowSidePanel] = useState(false);
    const [sidePanelDefaultTab, setSidePanelDefaultTab] = useState<string>();
    const [selectedCompanyId, setSelectedCompanyId] = useState<number>();
    const company = useCompany();
    const notificationSvc = useDi(NotificationService);
    const mspSvc = useDi(MspService);
    const nav = useNav();

    const onUnloadCompany = useCallback(() => {
        setSelectedCompanyId(0);
    }, [setSelectedCompanyId]);

    const handleToggleSidePanel = (showSidePanel: boolean) => {
        setshowSidePanel(showSidePanel);
    };

    const selectCompany = (selectedCompanyId: number): void => {
        if (selectedCompanyId && selectedCompanyId > 0) {
            setSelectedCompanyId(selectedCompanyId);
            handleToggleSidePanel(true);
        }
    };

    useEffect(() => {
        const { select: rawId, tab } = nav.getData('select', 'tab');
        const childIdNum = rawId ? parseInt(rawId) : '';
        if (childIdNum) {
            setSelectedCompanyId(childIdNum);
            setshowSidePanel(true);
            setSidePanelDefaultTab(tab);
        }
    }, []);

    return (
        <PageContent>
            <PaneledPage>
                <PagePanel size="fill">
                    <PanelBody>
                        <MyCompaniesGrid selectCompany={selectCompany}></MyCompaniesGrid>
                    </PanelBody>
                </PagePanel>
                <Divider orientation="vertical" />

                {showSidePanel && (
                    <Drawer
                        opened={showSidePanel}
                        onClose={() => setshowSidePanel(false)}
                        size={900}
                        padding={0}
                        position="right"
                        overlayOpacity={0.1}
                        withinPortal={false}
                        withCloseButton={false}
                        withOverlay={false}
                        shadow="md"
                    >
                        <CompanyContext unloadOnUnmount companyId={selectedCompanyId!} type="company" onUnload={onUnloadCompany}>
                            {() => (
                                <PagePanel padded={false} size="md" style={{ minWidth: '350px', width: '100%', height: '100%' }}>
                                    <AdminSidePanel
                                        defaultTab={sidePanelDefaultTab}
                                        parentCompany={company!}
                                        toggleSidePanel={handleToggleSidePanel}
                                        toggleCompanyDeleted={() => {}}
                                    />
                                </PagePanel>
                            )}
                        </CompanyContext>
                    </Drawer>
                )}
            </PaneledPage>
        </PageContent>
    );
};
endpoint('support-companies', SupportCompanies, 'My Companies');

export function ManageSupportCompanyShim() {
    const { getData } = useNav();
    const { id: rawId } = getData('id');
    const id = rawId ? parseInt(rawId) : null;
    const childReady = useDi(IChildRouteReady) as EventEmitter<boolean>;
    const siteMenuCompanySvc = useDi(SiteMenuCompanyService);

    useEffect(() => {
        if (childReady.value) {
            childReady.emit(false);
            siteMenuCompanySvc.company.emit(undefined);
        }
    }, [id]);
    useEffect(() => {
        return () => siteMenuCompanySvc.company.emit(undefined);
    }, []);
    return typeof id === 'number' ? (
        <CompanyContext key={id} type="company" companyId={id}>
            {(c) => {
                if (!!c?.Id && !childReady.value) {
                    childReady.emit(true);
                    siteMenuCompanySvc.company.emit(c);
                }
                return <></>;
            }}
        </CompanyContext>
    ) : null;
}

function CompanyPicker({ onClose }: { onClose: () => void }) {
    const companyContextSvc = useDi(CompanyContextService);
    const [companies, setCompanies] = useState<CompanyStat[]>();
    const { goto } = useNav();
    const companyDefaultPageProvider = useGetDefaultPageByCompany();
    useEffect(() => {
        companyContextSvc.withParentCompany(async () => {
            queryBuilder<CompanyStat>()
                .where((c) => c.model.Id!.ne(companyContextSvc.parentCompany.Id!))
                .take(1000)
                .execute(postCompanyStatsQueryCompanyStats)
                .then((results) => {
                    const r = results.Results ?? [];
                    r.sort((a, b) => {
                        if (a.SubscriptionActive !== b.SubscriptionActive) {
                            return a.SubscriptionActive ? -1 : 1;
                        }
                        return a.CompanyName!.localeCompare(b.CompanyName!, undefined, { sensitivity: 'base' });
                    });
                    setCompanies(r);
                });
        });
    }, []);
    const onSelect = useCallback(async (companies: CompanyStat[]) => {
        const url = companies[0] ? await companyDefaultPageProvider(companies[0]) : null;
        if (url) {
            goto(url);
        }
        onClose();
    }, []);
    return (
        <PickerContainer>
            <Picker
                items={companies ?? []}
                mode="single"
                selections={[]}
                minimizeHeight
                height={400}
                width={400}
                renderItem={(item) => {
                    return (
                        <CompanyPickerItem>
                            <Text color="primary" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {item.CompanyName}
                            </Text>
                            <Box className="state">
                                <StatusIndicator status={item.SubscriptionActive ? 'Active' : 'Inactive'} />
                                {item.SubscriptionActive ? 'Active' : 'Inactive'}
                            </Box>
                        </CompanyPickerItem>
                    );
                }}
                nameAccessor={(c) => c.CompanyName ?? ''}
                onChange={onSelect}
            />
            {companies === undefined ? (
                <Center my="lg">
                    <Loader />
                </Center>
            ) : null}
        </PickerContainer>
    );
}

const PickerContainer = styled.div`
    .picker-item {
        border-bottom: solid 1px ${(p) => p.theme.colors.gray[3]};
        padding: 0;
        cursor: pointer;
        > div {
            border-radius: 0;
            padding: 0;
            height: 100%;
        }
        :hover > div {
            background: ${(p) => p.theme.colors.primary[1]};
        }
    }
    .picker-item-text {
        flex: 1;
    }
`;
const StatusIndicator = styled.div<{ status: 'Active' | 'Inactive' }>`
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 5px;
    background: ${(p) => (p.status === 'Active' ? p.theme.colors.success[6] : p.theme.colors.gray[4])};
`;
const CompanyPickerItem = styled.div`
    display: grid;
    grid-template-columns: auto 100px;
    width: 100%;
    .state {
        justify-self: center;
        display: flex;
        align-items: center;
    }
`;

function ManageCompanyBreadCrumb() {
    const { getData } = useNav();
    const { id: rawId } = getData('id');
    const id = rawId ? parseInt(rawId) : null;
    const [customer, setCustomer] = useState<Company | null | undefined>();
    const [pickerOpen, { close, open, toggle }] = useDisclosure(false);
    const tenantSvc = useDi(CompanyTenantPrereqService);

    useEffect(() => {
        if (id) {
            tenantSvc.getCompanyById(id).then(setCustomer);
        }
    }, [id]);
    return (
        <>
            <Popover opened={pickerOpen} position="bottom-start" closeOnClickOutside onClose={close} shadow="lg" withArrow withinPortal>
                <Popover.Target>
                    <Anchor sx={{ display: 'flex' }} onClick={toggle}>
                        <Text mr={4}>{customer === undefined ? 'Loading' : customer === null ? 'Invalid' : customer.CompanyName}</Text>
                        <ChevronDown />
                    </Anchor>
                </Popover.Target>
                <Popover.Dropdown p={0}>{pickerOpen ? <CompanyPicker onClose={close} /> : null}</Popover.Dropdown>
            </Popover>
        </>
    );
}
ManageCompanyBreadCrumb.noLink = true;

endpoint('manage-support-company', ManageSupportCompanyShim, ManageCompanyBreadCrumb, true);

/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Customers.Host.Api
 * OpenAPI spec version: 1.0
 */

export type CspCloudPlatform = typeof CspCloudPlatform[keyof typeof CspCloudPlatform];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CspCloudPlatform = {
  Aws: 'Aws',
  Azure: 'Azure',
} as const;

import { Anchor, Box, Button, Divider, Group, TextInput } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import {
    WizardForm,
    WizardFormStep,
    WizardFormTitle,
    LoadingValidation,
    WizardFormFooter,
    WizardDownload,
    LoadingState,
    WizardFormStepContainer,
} from '@root/Components/Wizard/WizardForm';
import { PageContent } from '@root/Design/Layout';
import { useDi, useDiContainer } from '@root/Services/DI';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useCallback, useEffect, useMemo } from 'react';
import { ConnectionWizardFooterText, ConnectionWizardStepper, AccountIdValidator, WizardHeader } from './Common';
import { Link } from '@root/Services/Router/Links';
import { useEvent } from '@root/Services/EventEmitter';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { IacConnector } from '@root/Components/Connections/IacConnector';

export function ConnectCf() {
    const { platform } = useNav().getData('platform');

    return (
        <PageContent>
            <WizardHeader platform={platform!} />
            <WizardForm>
                <ConnectionWizardStepper step={3} platform={platform!} />
                <WizardFormTitle title={'Connect to AWS Using CloudFormation'}></WizardFormTitle>
                <WizardFormStepContainer>
                    <ConnectCfContent />
                </WizardFormStepContainer>
            </WizardForm>
        </PageContent>
    );
}
endpoint('connect-cf', ConnectCf, 'Connection Wizard');

export function ConnectCfContent({
    hideFooter,
    onReadyChanged,
}: {
    hideFooter?: boolean;
    onReadyChanged?: (ready: boolean, accountId: string) => void;
}) {
    const di = useDiContainer();
    const iacConn = useMemo(() => di.resolve(IacConnector), []);
    const company = useCompany();
    const { move } = useNav();

    useEvent(iacConn.status);
    const [accountId, setAccountId] = useInputState('');
    useEffect(() => {
        iacConn.updateAccount(accountId, company!.UniqueId!);
    }, [accountId]);
    function stackSets() {
        window.open('https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/what-is-cfnstacksets.html');
    }
    useEffect(() => {
        return () => {
            iacConn.dispose();
        };
    }, []);
    useEffect(() => {
        onReadyChanged?.(iacConn.isConnected(), accountId);
    }, [iacConn.isConnected()]);
    return (
        <>
            <WizardFormStep
                title="1. Enter your 12-digit Management Account ID"
                infoText="Also known as master account or master payor, we'll need to connect to this account for resource information."
                infoSize="small"
                subTitle="We use this to find and connect to your AWS Management Account."
            >
                <Group spacing="xs" ml="xl">
                    <TextInput
                        value={accountId}
                        onChange={setAccountId}
                        size="sm"
                        type="number"
                        maxLength={12}
                        placeholder="012345678910"
                        sx={{ width: 200 }}
                    />
                    <AccountIdValidator condition={accountId.length == 12} />
                </Group>
            </WizardFormStep>
            <Divider />
            <WizardFormStep
                title="2. Upload the .yaml file to the Management Account."
                subTitle={
                    <>
                        Use <Anchor onClick={stackSets}>stack sets</Anchor> to apply to all accounts you want to connect to CloudSaver.
                    </>
                }
            >
                <Box ml="xl">
                    <WizardDownload
                        imgLocation="/assets/aws-cloudformation.svg"
                        displayTitle="CloudFormation"
                        displayText="Download YAML"
                        fileLocation={iacConn.getDownloadUrl('cs-cloudformation.yml')}
                        disable={!iacConn.isAccountValid()}
                        disabledText="Account ID is Required"
                    ></WizardDownload>
                </Box>
            </WizardFormStep>
            <Divider />
            <WizardFormStep
                title="3. CloudSaver will check for connectivity to the Management Account."
                subTitle="We will use your entered Management Account ID to attempt connection every 3 seconds."
            >
                <Box ml="xl">
                    <LoadingValidation loadingState={iacConn.getLoadingState() as LoadingState} text={iacConn.getStatusMessage()}></LoadingValidation>
                </Box>
            </WizardFormStep>
            {hideFooter ? null : (
                <>
                    <Divider />
                    <WizardFormFooter>
                        <Group position="apart">
                            <ConnectionWizardFooterText company={company!} />
                            <Button
                                disabled={!iacConn.isConnected()}
                                component={Link}
                                onClick={() => move('connections')}
                                style={{ pointerEvents: !iacConn.isConnected() ? 'none' : undefined }}
                            >
                                View Connections
                            </Button>
                        </Group>
                    </WizardFormFooter>
                </>
            )}
        </>
    );
}

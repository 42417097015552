import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { IdleResourceGrid, IdleRunningStatusCell } from '../Components/IdleResourcesGrid';
import { IdleResourcesPage } from '../Components/IdleResourcesPage';
import { IdleResourcesService } from '../../../Services/Resources/IdleResourcesService';
import { useDi } from '@root/Services/DI';
import { ColumnConfig, GridColumnState } from '@root/Components/DataGrid/Models';
import { SubscriptionCheck } from '@root/Components/CompanyInfo/SubscriptionCheck';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { ThemeIcon, useMantineTheme } from '@mantine/core';
import { useMemo, useState } from 'react';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { BaseResource, SelectedIdleResourceRequest } from '@apis/Resources/model';
import { IdleResourcesActionCard } from '../Components/IdleResourcesActionCard';
import { ListCheck } from 'tabler-icons-react';
import { postIdleActionStartIdleResources, postIdleActionStopIdleResources, postResourcesQuery } from '@apis/Resources';
import { useEvent } from '@root/Services/EventEmitter';
import { QueryBasedResourceSelection } from '@root/Components/Resources/ResourcesGrid';
import { CustomColors } from '@root/Design/Themes';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';
import { AppFeatureNames } from '@root/Services/Customers/CompanyFeatureService';

export function IdleRedshiftContent() {
    const company = useCompany();
    const idleResourceService = useDi(IdleResourcesService);
    const notificationSvc = useDi(NotificationService);
    const theme = useMantineTheme();
    const resourceColumns: GridColumnState[] = [
        { id: 'Idle.RunningStatusRedshift', width: 145 },
        { id: 'Metrics.IdleResources.DatabaseConnections', width: 190 },
        { id: 'Metrics.IdleResources.ReadIOPS', width: 110 },
        { id: 'Metrics.IdleResources.WriteIOPS', width: 110 },
    ];

    const [idleResourceGrid, setIdleResourceGrid] = useState<DataGridModel>();
    const [selectedResources, setSelectedResources] = useState<BaseResource[]>();
    const [selectedResourceIds, setSelectedResourceIds] = useState<string[]>();
    const [selectedResourcesState, setSelectedResourcesState] = useState<'running' | 'stopped' | 'terminated' | ''>('');
    const [showSidePanel, setShowSidePanel] = useState<boolean>();

    const customDefaultColumns = useMemo(() => {
        return [
            {
                accessor: 'ClusterState',
                id: 'Idle.RunningStatusRedshift',
                defaultWidth: 145,
                header: 'Status',
                type: 'string',
                filter: {
                    filterField: 'ClusterState',
                    filterType: 'string',
                    name: 'Running Status',
                    fieldPickerRenderer: () => 'ClusterState',
                },
                groupName: 'Redshift',
                cellRenderer: (item) => <IdleRunningStatusCell item={item} resourceType="Redshift" />,
            },
        ] as ColumnConfig<BaseResource>[];
    }, []);

    useEvent(idleResourceGrid?.selectionChanged, async () => {
        const selections = idleResourceGrid?.selections as QueryBasedResourceSelection;
        if (selections.count() > 0) {
            const selectedResourcesQuery = selections.createIdCriteria();
            const gridCriteria = selections.getResourceQuery();
            const queryToUse = selectedResourcesQuery ? selectedResourcesQuery : gridCriteria;

            const results = await postResourcesQuery(
                {
                    IncludeSchema: false,
                    IncludeCount: true,
                    Where: queryToUse,
                    Take: idleResourceGrid?.selections.count(),
                },
                { companyId: company?.Id }
            );

            if (results.Results != undefined && results.Results != null && results.Results.length > 0) {
                const selectedResources = results.Results as BaseResource[];
                if (selectedResources.length > 0) {
                    //group by running state
                    var state = selectedResources[0]['ClusterState'] as string;
                    if (state == 'paused' || state == 'pausing') {
                        state = 'stopped';
                    } else if (state == 'resuming' || state == 'available') {
                        state = 'running';
                    }
                    setSelectedResourcesState(state as 'running' | 'stopped' | 'terminated' | '');

                    setSelectedResources(selectedResources);
                    setSelectedResourceIds(selectedResources.map((x) => x.Id!));
                    setShowSidePanel(true);
                } else {
                    setShowSidePanel(false);
                }
            } else {
                clearSelections();
            }
        } else {
            clearSelections();
        }
    });

    const onStart = async () => {
        if (selectedResources) {
            var resources = selectedResources.map((x) => {
                return {
                    ResourceId: x.Id,
                    AccountId: x.AccountID,
                    Region: x.Region,
                } as SelectedIdleResourceRequest;
            });

            await postIdleActionStartIdleResources(resources, { resourceType: 'Redshift', companyId: company?.Id });

            notificationSvc.notify(
                'Starting Stopped Resources',
                `The selected resources will be started. There may be a brief delay before their status updates.`,
                'primary',
                <ThemeIcon style={{ backgroundColor: theme.colors?.gray?.[2] as CustomColors }} variant="light" size="xl" radius="xl">
                    <ListCheck style={{ color: theme.colors!.primary![8] }} />
                </ThemeIcon>
            );

            clearSelections();
        }
    };

    const onStop = async () => {
        if (selectedResources) {
            var resources = selectedResources.map((x) => {
                return {
                    ResourceId: x.Id,
                    AccountId: x.AccountID,
                    Region: x.Region,
                } as SelectedIdleResourceRequest;
            });

            await postIdleActionStopIdleResources(resources, { resourceType: 'Redshift', companyId: company?.Id });

            notificationSvc.notify(
                'Stopping Idle Resources',
                `The selected resources will be stopped. There may be a brief delay before their status updates.`,
                'primary',
                <ThemeIcon style={{ backgroundColor: theme.colors?.gray?.[2] as CustomColors }} variant="light" size="xl" radius="xl">
                    <ListCheck style={{ color: theme.colors!.primary![8] }} />
                </ThemeIcon>
            );

            clearSelections();
        }
    };

    const clearSelections = () => {
        setSelectedResources([]);
        setSelectedResourceIds([]);
        setShowSidePanel(false);
        idleResourceGrid?.selections.setSelectAll(false);
    };

    const onCancel = () => {
        clearSelections();
    };

    return (
        <IdleResourcesPage title="Redshift">
            <div style={{ height: '100%', width: showSidePanel ? '70%' : '100%', float: showSidePanel ? 'left' : 'unset' }}>
                <IdleResourceGrid
                    refreshNeeded={idleResourceService.refreshNeeded}
                    title="Redshift"
                    includeMemory={false}
                    resourceType="Redshift"
                    extraColumns={resourceColumns}
                    setIdleResourcesGrid={setIdleResourceGrid}
                    hideHierarchyToggle={true}
                    customDefaultColumns={customDefaultColumns}
                />
            </div>

            {showSidePanel && selectedResourceIds != undefined && selectedResourceIds?.length! > 0 && (
                <IdleResourcesActionCard
                    selectedResourceIds={selectedResourceIds}
                    selectedResourceState={selectedResourcesState}
                    onStart={onStart}
                    onStop={onStop}
                    onCancel={onCancel}
                />
            )}
        </IdleResourcesPage>
    );
}

function IdleRedshift() {
    return (
        <SubscriptionCheck app="Optimization" featureName={AppFeatureNames.IdleResources}>
            {() => <ConnectionCheck>{() => <IdleRedshiftContent />}</ConnectionCheck>}
        </SubscriptionCheck>
    );
}
endpoint('idle-redshift', IdleRedshift, 'Idle Resources');

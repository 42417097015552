import { Box, Button, Group, Space, Text, Title } from '@mantine/core';
import { WizardForm, WizardFormTitle, WizardFormStepTitle, WizardFormStepContainer } from '@root/Components/Wizard/WizardForm';
import { PageContent } from '@root/Design/Layout';
import { ToolTip } from '@root/Design/Primitives';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { Link } from '@root/Services/Router/Links';
import { ConnectionWizardStepper, WizardHeader } from './Common';
import { useModals } from '@mantine/modals';
import { AdminInviteModal } from './AdminInviteModal';
import { postNotificationSendNotification } from '@apis/Notification';
import { PostNotificationSendNotificationParams } from '@apis/Notification/model';
import { useDiComponent } from '@root/Services/DI';

export function ConnectOne() {
    let { platform } = useNav().getData('platform');

    const { getMoveUrl } = useNav();

    const accountInfo = "Also known as master account or master payor, we'll need to connect to this account for resource information.";
    const credsInfo = "You'll need access to the management account to deploy the connection";
    const permissionInfo = "We'll use roles you create to make the connection";

    const cliInfo = 'CloudSaver will use CLI to connect to your Azure subscription.';
    const appInfo = "You'll need to be the Application Administrator Azure AD or have a role that allows application registration.";
    const rolePermissionInfo = "You'll need to have permissions that allow creation of custom role/permissions on the subscription level/scope.";

    const emailToInvite = '';

    /* Add AdminParty Modal */
    const modals = useModals();
    const DiContainer = useDiComponent();
    const openAdminInviteModal = (type: string) => {
        const id = modals.openModal({
            title: 'Invite AWS Administrator',
            children: (
                <DiContainer>
                    <AdminInviteModal onClose={() => modals.closeModal(id)} type={type}></AdminInviteModal>
                </DiContainer>
            ),
        });
    };
    const isAzure = platform?.toLowerCase() === 'azure';
    if (platform === 'Aws') {
        platform = 'AWS';
    }

    return (
        <PageContent>
            <WizardHeader platform={platform!} />
            <WizardForm>
                <ConnectionWizardStepper step={1} platform={platform!} />
                <WizardFormTitle title="Let's Get Connected" />
                <WizardFormStepContainer>
                    <Box p="xl">
                        <Title order={2}>To connect CloudSaver to {platform}, you'll need:</Title>
                        <Space h="xl" />
                        {!isAzure ? (
                            <>
                                <Group spacing={0}>
                                    <Text color="success" size="xl">
                                        <i className="ti ti-check"></i>
                                    </Text>
                                    <Space w="xs" />
                                    <Text>Your {platform} Management Account ID</Text>
                                    <ToolTip infoText={accountInfo} infoSize="small"></ToolTip>
                                </Group>
                                <Space h="sm" />
                                <Group spacing={0}>
                                    <Text color="success" size="xl">
                                        <i className="ti ti-check"></i>
                                    </Text>
                                    <Space w="xs" />
                                    <Text>Management account admin login credentials</Text>
                                    <ToolTip infoText={credsInfo} infoSize="small"></ToolTip>
                                </Group>
                                <Space h="sm" />
                                <Group spacing={0}>
                                    <Text color="success" size="xl">
                                        <i className="ti ti-check"></i>
                                    </Text>
                                    <Space w="xs" />
                                    <Text>Permission to create IAM roles and policies</Text>
                                    <ToolTip infoText={permissionInfo} infoSize="small"></ToolTip>
                                </Group>
                            </>
                        ) : (
                            <>
                                <Group spacing={0}>
                                    <Text color="success" size="xl">
                                        <i className="ti ti-check"></i>
                                    </Text>
                                    <Space w="xs" />
                                    <Text>{platform} CLI installed and configured</Text>
                                    <ToolTip infoText={cliInfo} infoSize="small"></ToolTip>
                                </Group>
                                <Space h="sm" />
                                <Group spacing={0}>
                                    <Text color="success" size="xl">
                                        <i className="ti ti-check"></i>
                                    </Text>
                                    <Space w="xs" />
                                    <Text>Application Administrator Azure AD or equivalent role</Text>
                                    <ToolTip infoText={appInfo} infoSize="small"></ToolTip>
                                </Group>
                                <Space h="sm" />
                                <Group spacing={0}>
                                    <Text color="success" size="xl">
                                        <i className="ti ti-check"></i>
                                    </Text>
                                    <Space w="xs" />
                                    <Text>Permission to create custom roles</Text>
                                    <ToolTip infoText={rolePermissionInfo} infoSize="small"></ToolTip>
                                </Group>
                            </>
                        )}
                        <Space h="xl" />
                        <Button data-atid="WizardNextButton" component={Link} sx={{ width: 150 }} href={'connect-two@platform:' + platform}>
                            Next
                        </Button>
                    </Box>
                </WizardFormStepContainer>
                <Box p="xl">
                    <WizardFormStepTitle title="Don't have one of these?"></WizardFormStepTitle>
                    <Text>Invite your {platform} administrator and team members to CloudSaver</Text>
                    <Space h="sm" />
                    <Button
                        data-atid="UsersInviteButton"
                        variant="outline"
                        sx={{ width: 100 }}
                        type="submit"
                        value={emailToInvite}
                        onClick={() => {
                            openAdminInviteModal('bob');
                        }}
                    >
                        Invite
                    </Button>
                </Box>
            </WizardForm>
        </PageContent>
    );
}
endpoint('connect-one', ConnectOne, 'Connection Wizard');

export async function SendNotification(emailToInvite: string, messageFromInviter: string, companyId: number, userId: string): Promise<void> {
    try {
        const userHasAccount = false;
        if (userHasAccount) {
            //will we have a different modal for the welcome??
            //maybe the Invite button click will call method to determine message type??
        } else {
            var emailFromForm = {
                companyId: companyId,
                userId: userId,
                emails: [emailToInvite],
                usergreeting: messageFromInviter,
            };

            const params: PostNotificationSendNotificationParams = {
                notificationType: 'Invite',
            };
            await postNotificationSendNotification(emailFromForm, params);
        }
    } catch (err) {
        //console.log(err);
    }
}

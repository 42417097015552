import { Box, Button, Card, Group, Menu, Popover, Space, Text, Title } from '@mantine/core';
import { observer } from 'mobx-react';
import { UseFormReturnType } from '@mantine/form';
import { postResourcesQuery, QueryExpr, QueryOperation } from '@apis/Resources';
import { DataFilters, AddFilterButton, DataFilterModel } from '@root/Components/Filter/Filters';
import { FieldPicker } from '@root/Components/Picker/FieldPicker';
import { exprBuilder, SchemaService, SchemaValueProvider } from '@root/Services/QueryExpr';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { ResourceSchemaProvider } from '@root/Services/Resources/ResourceService';
import { useDi } from '@root/Services/DI';
import { SchemaFieldNameProvider } from '@root/Components/Filter/Services';
import { Calendar, Plus } from 'tabler-icons-react';
import { RuleEditCard } from './Design';
import { TagAutomationRule } from '@apis/TagManager/model';
import { RuleEditor, TagLookupService } from './Model';
import { useDisclosure } from '@mantine/hooks';
import { FilterResources, RuleResourceFilter, ValidationIssues } from './FilterResources';

export const FilterResourcesCard = observer(function FilterResourcesCard({
    ruleEditor,
    onValidationIssuesChanged,
}: {
    ruleEditor: RuleEditor;
    onValidationIssuesChanged: (issues: ValidationIssues) => void;
}) {
    const [showingErrors, { open: showErrors }] = useDisclosure(false);
    return (
        <RuleEditCard title="Select Filters" description="Add filtering to narrow down which resources will be impacted" onBlur={showErrors}>
            <RuleResourceFilter ruleEditor={ruleEditor} onValidationIssuesChanged={onValidationIssuesChanged} />
        </RuleEditCard>
    );
});

import { showNotification, hideNotification } from '@mantine/notifications';
import { CustomColors } from '@root/Design/Themes';
import { ReactNode } from 'react';
import { singleton } from 'tsyringe';

@singleton()
export class NotificationService {
    private idSeed = 0;
    public notify(title: string, message: string, color: CustomColors, icon: ReactNode, onClick?: () => void) {
        const id = (++this.idSeed).toString() + 'Notification:' + title;
        showNotification({
            title,
            message,
            color,
            icon,
            autoClose: 6000,
            id,
            onClick: () => {
                if (onClick) {
                    onClick();
                    hideNotification(id);
                }
            },
        });
    }
}

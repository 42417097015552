import { QueryExpr } from '@apis/Resources';
import styled from '@emotion/styled';
import { Text, Title } from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { StandardChartProps } from './Models';

export function KpiChart<T extends Record<string, number>>(props: KpiChartProps<T>) {
    const data = transformKpiData(props.data as unknown as Record<string, number>[][], props.settings?.labels, props.values as string[]);
    const fmtSvc = useDi(FormatService);
    return (
        <KpiContainer>
            {data.map((d, i) => {
                const format = props.settings?.format[i];
                return (
                    <KpiBox key={i}>
                        <Title sx={{ flex: 1 }}>
                            {typeof d.value !== 'number'
                                ? d.value
                                : format === 'percent'
                                ? (d.value * 100).toFixed() + '%'
                                : format === 'money'
                                ? fmtSvc.formatMoney(d.value)
                                : format === 'money-whole'
                                ? fmtSvc.formatMoneyNoDecimals(d.value)
                                : format && typeof format === 'object' && 'decimals' in format
                                ? d.value.toFixed(format.decimals).toString()
                                : d.value
                                      .toFixed()
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </Title>
                        <Text sx={{ flex: 0 }}>{d.label}</Text>
                    </KpiBox>
                );
            })}
        </KpiContainer>
    );
}
export interface KpiChartSettings {
    valueFilters: QueryExpr[][];
    labels: string[];
    format: ('percent' | 'money' | 'money-whole' | { decimals: number } | undefined)[];
}

interface KpiChartProps<T extends Record<string, any>> extends StandardChartProps<number, T> {
    settings?: KpiChartSettings;
}

function transformKpiData(data: Record<string, number>[][], labels: string[] | undefined, aliases: string[] | undefined) {
    return data.map((d, i) => ({
        value: d[0]?.[aliases?.[i] ?? 'Value'] ?? 0,
        label: labels?.[i] ?? '',
    }));
}

const KpiBox = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    div {
        text-align: center;
        white-space: nowrap;
    }
`;
const KpiContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
`;

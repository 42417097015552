import { Tooltip, useMantineTheme } from '@mantine/core';
import { DailyInvoiceRow } from '@root/Components/Invoices/DailyInvoiceGrid';
import { MonthlyInvoiceRow } from '@root/Components/Invoices/MonthlyInvoicesGrid';
import { useEvent } from '@root/Services/EventEmitter';
import { useCallback } from 'react';
import { ChartKeySelectionStrategy, ChartKeySelectionStrategyDaily } from '../Models/ChartKeySelectionStrategy';

export function ChartKeyRowSelector({
    selectionStrategy,
    item,
    toggle,
    color,
    tooltip,
}: {
    selectionStrategy: ChartKeySelectionStrategy;
    item: MonthlyInvoiceRow | null;
    toggle: (selected: boolean) => void;
    color?: string;
    tooltip?: string;
}) {
    const theme = useMantineTheme();
    useEvent(selectionStrategy.selectionChanged);
    const isSelected = item ? selectionStrategy.isSelected(item) : false;
    const itemColor = item ? selectionStrategy.getSelectionColor(item) : undefined;
    const allSelected = selectionStrategy.isAllSelected();
    const selectAll = useCallback(() => {
        toggle(true);
    }, [selectionStrategy, toggle]);
    const iconColor = allSelected ? theme.colors.primary[6] : isSelected ? color ?? itemColor ?? theme.colors.primary[6] : theme.colors.gray[4];

    return (
        <Tooltip withinPortal label={tooltip} position="right">
            {item ? (
                <i className="ti ti-chart-histogram" style={{ color: iconColor }} />
            ) : (
                <i className="ti ti-chart-histogram" style={{ color: theme.colors.primary[6] }} onClick={selectAll} />
            )}
        </Tooltip>
    );
}

export function ChartKeyRowSelectorDaily({
    selectionStrategy,
    item,
    toggle,
    color,
    tooltip,
}: {
    selectionStrategy: ChartKeySelectionStrategyDaily;
    item: DailyInvoiceRow | null;
    toggle: (selected: boolean) => void;
    color?: string;
    tooltip?: string;
}) {
    const theme = useMantineTheme();
    useEvent(selectionStrategy.selectionChanged);
    const isSelected = item ? selectionStrategy.isSelected(item) : false;
    const itemColor = item ? selectionStrategy.getSelectionColor(item) : undefined;
    const allSelected = selectionStrategy.isAllSelected();
    const selectAll = useCallback(() => {
        toggle(true);
    }, [selectionStrategy, toggle]);
    const iconColor = allSelected ? theme.colors.primary[6] : isSelected ? color ?? itemColor ?? theme.colors.primary[6] : theme.colors.gray[4];

    return (
        <Tooltip withinPortal label={tooltip} position="right">
            {item ? (
                <i className="ti ti-chart-histogram" style={{ color: iconColor }} />
            ) : (
                <i className="ti ti-chart-histogram" style={{ color: theme.colors.primary[6] }} onClick={selectAll} />
            )}
        </Tooltip>
    );
}

export function useRowSelector({
    gridSelectionStrategy,
    color,
    tooltip,
}: {
    gridSelectionStrategy: ChartKeySelectionStrategy;
    color?: string;
    tooltip?: string;
}) {
    return useCallback(
        (item: MonthlyInvoiceRow | null, { toggle }: { toggle: (selected: boolean) => void }) => (
            <ChartKeyRowSelector selectionStrategy={gridSelectionStrategy} toggle={toggle} item={item} color={color} tooltip={tooltip} />
        ),
        [gridSelectionStrategy, color, tooltip]
    );
}

export function useRowSelectorDaily({
    gridSelectionStrategy,
    color,
    tooltip,
}: {
    gridSelectionStrategy: ChartKeySelectionStrategyDaily;
    color?: string;
    tooltip?: string;
}) {
    return useCallback(
        (item: DailyInvoiceRow | null, { toggle }: { toggle: (selected: boolean) => void }) => (
            <ChartKeyRowSelectorDaily selectionStrategy={gridSelectionStrategy} toggle={toggle} item={item} color={color} tooltip={tooltip} />
        ),
        [gridSelectionStrategy, color, tooltip]
    );
}

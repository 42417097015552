import { Anchor, Box, Divider, Modal, Space, Text, useMantineTheme } from '@mantine/core';
import { ErrorLogout } from './ErrorLogout';
import { MspService } from '@root/Services/MspService';
import { useDi } from '@root/Services/DI';

export function UnknownUser() {
    const theme = useMantineTheme();
    const mspSvc = useDi(MspService);
    const emailSupport = () => {
        document.location.href = mspSvc.mspSupportAndLogos.SupportEmail!;
    };
    const redirectToRegister = () => {
        document.location.href = window.location.origin + '/_register';
    };
    return (
        <Modal
            padding={theme.spacing.xl}
            opened={true}
            onClose={() => {}}
            withCloseButton={false}
            closeOnEscape={false}
            closeOnClickOutside={false}
            withinPortal
            overlayBlur={4}
            overlayOpacity={1}
            overlayColor={theme.colors.gray[1]}
            size={450}
            shadow="xl"
        >
            <Box style={{ textAlign: 'center', marginTop: '50px' }}>
                <img src={mspSvc.mspSupportAndLogos.Logo} style={{ maxWidth: '180px' }} />
                <br />
                <br />
                <br />
                <Text>It looks like there's a problem with your user profile.</Text>
                <Text>If you arrived here from an email invitation, please use that email address.</Text>
                <br />
                <br />
                <Text> Are you trying to set up your company</Text>
                <Text>
                    {' '}
                    for the first time?
                    <Anchor onClick={redirectToRegister}> Go Here</Anchor>
                </Text>
                <br />
                <br />
                Please contact {mspSvc.mspSupportAndLogos.CompanyName} customer support at{' '}
                <Anchor onClick={emailSupport}>{mspSvc.mspSupportAndLogos.SupportEmail.replace('mailto:', '')}</Anchor>.
                <ErrorLogout />
            </Box>
        </Modal>
    );
}

import { Button, Card, Group, Space } from '@mantine/core';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Plus } from 'tabler-icons-react';
import { EditorCard, EditorCardWhiteWrap } from './Design';
import { IMiniDataGridProps, MiniDataGrid, MiniDataGridItem } from './MiniDataGrid';

export function AllocationSources({
    buttons,
    gridProps,
    gridData,
    title,
    helpText,
    sourceChanged,
}: {
    buttons: { label: string; onClick: () => void }[];
    gridProps: Exclude<IMiniDataGridProps, 'data'>;
    gridData: () => MiniDataGridItem[];
    title?: string;
    helpText?: ReactNode;
    sourceChanged?: EventEmitter<void>;
}) {
    const [data, setData] = useState<MiniDataGridItem[]>([]);
    const refreshData = useCallback(() => setData(gridData()), [gridData]);
    useEvent(sourceChanged, refreshData);
    useEffect(refreshData, []);
    return (
        <EditorCardWhiteWrap>
            <EditorCard title={title ?? 'Allocation Sources'} color="gray" info={helpText} wrapped>
                {data?.length ? (
                    <>
                        <MiniDataGrid {...gridProps} data={data}></MiniDataGrid>
                        <Space h="md" />
                    </>
                ) : null}

                <Group>
                    {buttons.map((button, index) => (
                        <Button key={index} variant="outline" radius="xl" size="sm" leftIcon={<Plus size={16} />} onClick={button.onClick}>
                            {button.label}
                        </Button>
                    ))}
                </Group>
            </EditorCard>
        </EditorCardWhiteWrap>
    );
}

import { PageContent, PageSection } from '@root/Design/Layout';
import { endpoint } from '@root/Services/Router/EndpointRegistry';

export function TagInheritance() {
    return (
        <PageContent>
            <PageSection>Welcome to Tag Manager - Tag Inheritance Page</PageSection>
        </PageContent>
    );
}
endpoint('tag-inheritance', TagInheritance, 'TagInheritance');

/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.TagManager.Host.Api
 * OpenAPI spec version: 1.0
 */

export type AwsOUAccountGroupRuleOptionsGroupingType = typeof AwsOUAccountGroupRuleOptionsGroupingType[keyof typeof AwsOUAccountGroupRuleOptionsGroupingType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AwsOUAccountGroupRuleOptionsGroupingType = {
  Account: 'Account',
  OrganizationalUnit: 'OrganizationalUnit',
} as const;

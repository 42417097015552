import { Company, CompanyType } from '@apis/Customers/model';
import { Anchor, Text, Space } from '@mantine/core';
import {
    FullPageModalHeader,
    InfoSidebar,
    LoadingState,
    LoadingValidation,
    WizardFormHeader,
    WizardToolbar,
} from '@root/Components/Wizard/WizardForm';
import { PageBody } from '@root/Design/Layout';
import { SubHeading } from '@root/Design/Primitives';
import { CustomColors, theme } from '@root/Design/Themes';
import { ReactNode } from 'react';

export function SettingsShell(props: { children: ReactNode }) {
    return <PageBody>{props.children}</PageBody>;
}

export function AccountIdValidator(props: { condition: boolean }) {
    return props.condition ? <LoadingValidation loadingState={LoadingState.success} text="Valid account ID"></LoadingValidation> : <></>;
}

export function AccountNameValidator(props: { condition: boolean }) {
    return props.condition ? <LoadingValidation loadingState={LoadingState.success} text="Valid account name"></LoadingValidation> : <></>;
}

function SecurityWhitePaperAws() {
    return (
        <InfoSidebar title="CloudSaver Security Standards" buttonTitle="How We Connect">
            <p>
                Product security is of paramount importance at CloudSaver. CloudSaver uses a software development lifecycle in line with general Agile
                principles. When security effort is applied throughout the Agile release cycle, security-oriented software defects are able to be
                discovered and addressed more rapidly than in longer release cycle development methodologies. Software patches are released as part of
                our continuous integration process. Patches that can impact end users will be applied as soon as possible but may necessitate end user
                notification.
            </p>
            <p>
                CloudSaver performs continuous integration. In this way we are able to respond rapidly to both functional and security issues. Well
                defined change management policies and procedures determine when and how changes occur. This philosophy is central to DevOps security
                and the development methodologies that have driven CloudSaver adoption. In this way, CloudSaver is able to achieve extremely short
                mean time to resolution for security vulnerabilities and functional issues alike. CloudSaver is continuously improving our DevOps
                practice in an iterative fashion.
            </p>
            <SubHeading>SOC 2 Type 2 Compliant</SubHeading>
            <p>
                CloudSaver is SOC 2 Type 2 is compliant. SOC 2 defines criteria for managing customer data based on five "trust service principles" -
                security, availability, processing integrity, confidentiality and privacy. The SOC 2 Type 2 certification validates the operational
                effectiveness of CloudSaver's systems in adhering to these principles.
            </p>
            <SubHeading>How CloudSaver Connects to Your Environment</SubHeading>
            <p>
                CloudSaver follows industry best practices and uses a role-based access methodology which includes the use of unique external IDs and
                tokens that automatically expire at intervals of no longer than one hour.
            </p>
            <p>
                Clients create a role for the CloudSaver platform and then assign permission policies to that role. The CloudSaver platform must
                separately connect, with a role and permission policies, to each AWS account intended to be used with CloudSaver applications.
            </p>
            <SubHeading>Roles &amp; Permissions</SubHeading>
            <p>
                The required AWS IAM cross-account role must be configured with a trust policy which allows the CloudSaver application to assume the
                role. The trust policy allows role assumption from CloudSaver's AWS account, and is limited by the unique External ID provided to the
                client within the IaC templates or through CLI script.
            </p>
        </InfoSidebar>
    );
}

function SecurityWhitePaperAzure() {
    return (
        <InfoSidebar title="CloudSaver Security Standards" buttonTitle="How We Connect">
            <p>
                Product security is of paramount importance at CloudSaver. CloudSaver uses a software development lifecycle in line with general Agile
                principles. When security effort is applied throughout the Agile release cycle, security-oriented software defects are able to be
                discovered and addressed more rapidly than in longer release cycle development methodologies. Software patches are released as part of
                our continuous integration process. Patches that can impact end users will be applied as soon as possible but may necessitate end user
                notification.
            </p>
            <p>
                CloudSaver performs continuous integration. In this way we are able to respond rapidly to both functional and security issues. Well
                defined change management policies and procedures determine when and how changes occur. This philosophy is central to DevOps security
                and the development methodologies that have driven CloudSaver adoption. In this way, CloudSaver is able to achieve extremely short
                mean time to resolution for security vulnerabilities and functional issues alike. CloudSaver is continuously improving our DevOps
                practice in an iterative fashion.
            </p>
            <SubHeading>SOC 2 Type 2 Compliant</SubHeading>
            <p>
                CloudSaver is SOC 2 Type 2 is compliant. SOC 2 defines criteria for managing customer data based on five "trust service principles" -
                security, availability, processing integrity, confidentiality and privacy. The SOC 2 Type 2 certification validates the operational
                effectiveness of CloudSaver's systems in adhering to these principles.
            </p>
            <SubHeading>How CloudSaver Connects to Your Environment</SubHeading>
            <p>
                CloudSaver follows industry best practices and uses a role-based access methodology which includes the use of unique external IDs and
                tokens that automatically expire at intervals of no longer than one hour.
            </p>
            <p>
                Clients create a role for the CloudSaver platform and then assign permission policies to that role. The CloudSaver platform must
                separately connect, with a role and permission policies, to each Azure account intended to be used with CloudSaver applications.
            </p>
            <SubHeading>Azure Authorization</SubHeading>
            <p>
                CloudSaver's integration with a client's Azure environment is enabled through configuration of an Azure Active Directory (Azure AD)
                application service principal and client secrets. Authorization for the CloudSaver app is implemented through Azure Role-Based Access
                Control (Azure RBAC) and uses a custom role definition and assignment.
            </p>
        </InfoSidebar>
    );
}

function ConnectionFaq() {
    return (
        <InfoSidebar title="CloudSaver Connection - Setup Requirements" buttonTitle="Help">
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ut sem ac ipsum placerat rutrum. Cras vulputate tempus ligula. Etiam sed
                hendrerit nisi, eget elementum justo. Pellentesque elit diam, ullamcorper bibendum augue a, fringilla fringilla eros. Aliquam nunc
                metus, posuere a laoreet eget, maximus nec massa. Vestibulum porta auctor lobortis. Etiam nec neque nec massa laoreet sodales ut non
                odio. Interdum et malesuada fames ac ante ipsum primis in faucibus. Etiam vitae nisl eu erat sodales vulputate eget id eros. Quisque
                et fermentum nisl, ut consequat sapien.
            </p>
            <p>
                Vivamus et mauris placerat libero tincidunt aliquet id eget velit. In vitae sollicitudin nisi, nec porttitor libero. Sed condimentum,
                metus tempus rutrum finibus, turpis turpis feugiat est, a faucibus magna tortor non risus.
            </p>
            <SubHeading>Aws Management Account ID</SubHeading>
            <p>
                Vivamus et mauris placerat libero tincidunt aliquet id eget velit. In vitae sollicitudin nisi, nec porttitor libero. Sed condimentum,
                metus tempus rutrum finibus, turpis turpis feugiat est, a faucibus magna tortor non risus.
            </p>
            <SubHeading>Aws Management Account Access</SubHeading>
            <p>
                Vivamus et mauris placerat libero tincidunt aliquet id eget velit. In vitae sollicitudin nisi, nec porttitor libero. Sed condimentum,
                metus tempus rutrum finibus, turpis turpis feugiat est, a faucibus magna tortor non risus.
            </p>
            <p>
                Vivamus et mauris placerat libero tincidunt aliquet id eget velit. In vitae sollicitudin nisi, nec porttitor libero. Sed condimentum,
                metus tempus rutrum finibus, turpis turpis feugiat est, a faucibus magna tortor non risus.
            </p>
            <SubHeading>Permission to create IAM roles and policies</SubHeading>
            <p>
                Vivamus et mauris placerat libero tincidunt aliquet id eget velit. In vitae sollicitudin nisi, nec porttitor libero. Sed condimentum,
                metus tempus rutrum finibus, turpis turpis feugiat est, a faucibus magna tortor non risus.
            </p>
            <p>
                Vivamus et mauris placerat libero tincidunt aliquet id eget velit. In vitae sollicitudin nisi, nec porttitor libero. Sed condimentum,
                metus tempus rutrum finibus, turpis turpis feugiat est, a faucibus magna tortor non risus.
            </p>
        </InfoSidebar>
    );
}

function openHelp() {
    window.open('https://knowledge.cloudsaver.com/s/article/Connection-Wizard');
}

export function HelpLink(props: { text: string; color: string; fontWeight: string }) {
    return (
        <Anchor onClick={openHelp} style={{ color: props.color, fontWeight: props.fontWeight }}>
            {props.text}
        </Anchor>
    );
}

export function WizardHeader(props: { platform?: string }) {
    return (
        <>
            <FullPageModalHeader data-atid="WizardHeader">
                <div>{/* logo here */}</div>
                <div>
                    {props.platform == 'AWS' ? <SecurityWhitePaperAws /> : <SecurityWhitePaperAzure />}
                    <Space w="xl" sx={{ display: 'inline-block' }} />
                    <HelpLink text="Help" color={theme.colors?.primary?.[6] as CustomColors} fontWeight={'bold'}></HelpLink>
                </div>
            </FullPageModalHeader>
        </>
    );
}

export function ConnectionWizardStepper(props: { step: number; platform: string }) {
    return (
        <WizardFormHeader>
            <WizardToolbar steps={steps} activeState={props.step} platform={props.platform}></WizardToolbar>
        </WizardFormHeader>
    );
}

export function ConnectionWizardFooterText(props: { company: Company }) {
    return (
        <Text weight="bold" color="gray">
            Review {!(props.company.Type === CompanyType.Msp) ? 'your' : props.company.CompanyName} accounts as they connect
        </Text>
    );
}

const steps = [
    { url: 'connect-one', text: 'Requirements' },
    { url: 'connect-two', text: 'Select Method' },
    { url: '', text: 'Connect Account' },
];

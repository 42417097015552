import type { Company, CWMetricsConfigData, CWMetricsLabelConfig, SchemaField } from '@apis/Customers/model';
import { getMetadataIntegrationGetSchema, postResourcesMultiQuery, postResourcesQuery, postResourcesSearch, QueryField } from '@apis/Resources';
import { BaseAwsResource, BaseAzureResource, BaseResource, Query, SchemaType, TagResourcesJobJob } from '@apis/Resources/model';
import { inject, injectable, singleton } from 'tsyringe';
import { ICompanyContextToken } from '../Customers/CompanyContext';
import { FormatService } from '../FormatService';
import { JobService } from '../Jobs/JobService';
import { PollingService } from '../Jobs/PollingService';
import { Platform, PlatformService } from '../PlatformService';
import { FieldInfo, ISchemaProvider, queryBuilder, SchemaService, SchemaValueProvider } from '../QueryExpr';
import { getCWMetricsConfigGetCWMetricsConfigData } from '@apis/Customers';
import { AsyncBundler } from '../AsyncBundler';

@singleton()
export class ResourceQueryService {
    public constructor(@inject(AsyncBundler) private readonly bundler: AsyncBundler) {}

    public async query(query: Query, companyId: number) {
        const requestor = (queries: Query[]) => postResourcesMultiQuery(queries, { companyId });
        return this.bundler.bundle(`${companyId}`, query, requestor);
    }
}

@injectable()
export class ResourceService {
    public constructor(
        @inject(ICompanyContextToken) private readonly company: Company,
        @inject(ResourceQueryService) private readonly querySvc: ResourceQueryService
    ) {}

    public async getTags() {
        const tagsResult = await queryBuilder<{ ['Tags.Key']?: string }>()
            .select((b) => ({
                tag: b.model['Tags.Key'],
                count: b.count(),
            }))
            .execute((q) => postResourcesQuery(q, { companyId: this.company.Id }));

        return tagsResult?.Results?.map((r) => r.tag) ?? [];
    }

    public async getTagValuesUsingFilter(filter: string) {
        const tagsResult = await queryBuilder<{ ['Tags.Value']?: string }>()
            .where((r) => r.and(r.model['Tags.Value']!.contains(filter)))
            .take(100)
            .select((b) => ({
                value: { Operation: 'values', Operands: [{ Field: 'Tags.Value' }, { Value: filter }] } as unknown as string,
                count: b.count(),
            }))
            .execute((q) => postResourcesQuery(q, { companyId: this.company.Id }));

        return tagsResult?.Results?.map((r) => r.value) ?? [];
    }

    public async getTagKeysUsingFilter(filter: string, max: number = 100) {
        const tagsResult = await this.getTagKeyCountUsingFilter(filter, max);

        return tagsResult.map((r) => r.value);
    }

    public async getTagKeyCountUsingFilter(filter: string, max: number = 100) {
        const qb = await queryBuilder<{ ['Tags.Key']?: string }>();
        if (filter) {
            qb.where((r) => r.and(r.model['Tags.Key']!.contains(filter)));
        }
        const tagsResult = await qb
            .take(max)
            .select((b) => ({
                value: { Operation: 'values', Operands: [{ Field: 'Tags.Key' }, { Value: filter }] } as unknown as string,
                count: b.count(),
            }))
            .execute(this.query);

        return tagsResult?.Results ?? [];
    }

    public async getTagValueCounts(filter: string, tagKey: string, max: number = 1000) {
        const field = `CsTags.${tagKey}`;
        const qb = await queryBuilder<Record<string, string>>();
        if (filter) {
            qb.where((r) => r.and(r.model[field]!.contains(filter)));
        }
        const tagsResult = await qb
            .take(max)
            .select((b) => ({
                value: { Operation: 'values', Operands: [{ Field: field }, { Value: filter }] } as unknown as string,
                count: b.count(),
            }))
            .execute(this.query);

        return tagsResult?.Results ?? [];
    }

    public async getAllTagValues(maxResults: number = 10001) {
        const tagsResult = await queryBuilder<{ ['Tags.Value']?: string }>()
            .take(maxResults)
            .select((b) => ({
                tag: b.model['Tags.Value'],
                count: b.count(),
            }))
            .execute((q) => postResourcesQuery(q, { companyId: this.company.Id }));

        return tagsResult?.Results?.map((r) => r.tag) ?? [];
    }

    public async getPlatformResource<TPlatform extends Platform>(resourceId: string, platform: TPlatform, resourceType?: string) {
        const results = await queryBuilder<BaseResource>()
            .where((b) => {
                const crit = [b.model.CloudPlatform!.eq(platform), b.model.Id!.eq(resourceId)];
                if (resourceType) {
                    crit.push(b.model.ResourceType!.eq(resourceType));
                }
                return b.and(...crit);
            })
            .take(1)
            .execute((q) => postResourcesSearch(q, { companyId: this.company.Id }));

        const result = results?.Results?.[0];
        return !result ? null : (result as TPlatform extends 'Aws' ? BaseAwsResource : TPlatform extends 'Azure' ? BaseAzureResource : BaseResource);
    }

    public async getResource(resourceId: string, resourceType: string) {
        const results = await queryBuilder<BaseResource>()
            .where((r) => r.and(r.model.ResourceType!.eq(resourceType), r.model.Id!.eq(resourceId)))
            .take(1)
            .execute((q) => postResourcesSearch(q, { companyId: this.company.Id }));

        return results.Results?.[0];
    }

    public async checkIfTagIsPresent(key: string, resources: string[]) {
        const query = queryBuilder<BaseResource>().take(0).build();

        query.Where =
            resources.length > 0
                ? {
                      Operation: 'and',
                      Operands: [
                          { Operation: 'isnotnull', Operands: [{ field: `CsTags.${key}` }] },
                          { Operation: 'eq', Operands: [{ field: 'Id' }, { value: resources }] },
                      ],
                  }
                : {};

        const result = await postResourcesQuery(query, { companyId: this.company.Id });
        if (result.Count ?? 0 > 0) {
            return true;
        }
        return false;
    }

    public async getTagValues(key: string) {
        const tagsResult = await queryBuilder<Record<string, string>>()
            .select((b) => ({
                value: b.model[`CsTags.${key}`],
                count: b.count(),
            }))
            .execute((q) => postResourcesQuery(q, { companyId: this.company.Id }));

        return tagsResult?.Results?.filter((v) => v.value !== null).map((r) => r.value) ?? [];
    }

    public query = (query: Query) => {
        return this.querySvc.query(query, this.company.Id ?? 0);
    };
}

@singleton()
class ResourceSyncState {
    private progressLookup = new Map<number, number>();

    public getProgress(companyId: number) {
        return this.progressLookup.get(companyId) ?? 0;
    }
    public update(companyId: number, progress: number) {
        this.progressLookup.set(companyId, progress);
    }
}

@injectable()
export class ResourceSyncService {
    public constructor(
        @inject(JobService) private readonly jobService: JobService,
        @inject(PollingService) private readonly pollingSvc: PollingService,
        @inject(ICompanyContextToken) private readonly company: Company,
        @inject(ResourceSyncState) private readonly resourceSyncState: ResourceSyncState
    ) {}

    public async getInitialSync(progressPct: (percent: number) => void, stopPolling: () => boolean) {
        const companyId = this.company.Id!;
        let initialSync = this.resourceSyncState.getProgress(companyId);
        if (initialSync < 1) {
            const initialSyncJob = await this.getInitialSyncJob();
            if (initialSyncJob) {
                const progress = await this.jobService.getHierarchyProgress(initialSyncJob.HierarchyId!);
                if (progress) {
                    initialSync = this.jobService.getStatusInfo(progress).progress;
                    this.pollingSvc.pollUntil(
                        () => this.jobService.getHierarchyProgress(initialSyncJob.HierarchyId!),
                        (progress) => {
                            const status = progress ? this.jobService.getStatusInfo(progress) : null;
                            const pct = status?.progress ?? 0;
                            this.resourceSyncState.update(companyId, pct);
                            progressPct(pct);
                            return stopPolling() || !status || !status.inProgress;
                        },
                        3000
                    );
                }
            }
        }

        return {
            initialSyncDone: !(initialSync < 1),
            initialSync,
        };
    }

    private async getInitialSyncJob() {
        const results = await this.jobService.queryJobs((builder) =>
            builder
                .where((b) =>
                    b.model.Type!.eq([
                        'Cloudsaver.Resources.Domain.Aws.Models.InitialCheckAwsResources',
                        'Cloudsaver.Resources.Domain.Azure.Models.InitialCheckAzureResources',
                    ])
                )
                .sortAsc((b) => b.model.QueuedAt)
                .take(1)
        );
        return results[0];
    }
}

@singleton()
export class ResourceSchemaCache {
    private schema: SchemaType[] | null = null;
    private knownTags = new Set<string>();
    private loadedCompanyId = 0;

    public constructor(
        @inject(ResourceService) private readonly resourceSvc: ResourceService,
        @inject(JobService) private readonly jobSvc: JobService
    ) {
        this.jobSvc.newJobStarted.listen((job) =>
            job && job.Type === 'Cloudsaver.Resources.Domain.Models.TagResourcesJob' ? this.checkForNewTags(job as TagResourcesJobJob) : null
        );
    }

    public invalidate() {
        this.loadedCompanyId = 0;
    }

    public async getSchema(companyId: number, schemaHandler: (schema: SchemaType[]) => Promise<void>) {
        if (this.loadedCompanyId !== companyId) {
            const results = await postResourcesQuery({ IncludeSchema: true, Take: 0 }, { companyId });
            this.schema = results?.Types ?? [];
            await schemaHandler(this.schema);
            this.loadKnownTags();
            this.loadedCompanyId = companyId;
        }
        return this.schema ?? [];
    }

    private checkForNewTags(job: TagResourcesJobJob) {
        const tags = [
            job.Parameters?.AddTags?.map((t) => t.Key ?? '') ?? [],
            job.Parameters?.DeleteTags?.map((t) => t.Key ?? '') ?? [],
            job.Parameters?.Renames?.map((r) => r.OldTagKey ?? '') ?? [],
            job.Parameters?.Renames?.map((r) => r.NewTagKey ?? '') ?? [],
        ]
            .flatMap((t) => t)
            .filter((t) => !!t);
        for (const tag of tags) {
            if (!this.knownTags.has(tag)) {
                this.loadedCompanyId = 0;
            }
        }
    }

    private loadKnownTags() {
        const schemaTags = this.schema?.find((t) => t.IsRoot && t.TypeId === 'Tags');
        if (schemaTags) {
            this.knownTags = new Set<string>(schemaTags.Fields?.map((f) => f.Name ?? '') ?? []);
        }
    }
}

@injectable()
export class ResourceSchemaProvider implements ISchemaProvider {
    public constructor(
        @inject(ResourceService) private readonly resourceSvc: ResourceService,
        @inject(ResourceSchemaCache) private readonly schemaCache: ResourceSchemaCache,
        @inject(FormatService) private readonly formatSvc: FormatService,
        @inject(ICompanyContextToken) private readonly company: Company,
        @inject(PlatformService) private readonly platformSvc: PlatformService
    ) {}

    public async getSchema(): Promise<SchemaType[]> {
        return await this.schemaCache.getSchema(this.company.Id ?? 0, async (types: SchemaType[]) => {
            if (types) {
                const [, integrationSchema, metricConfigs] = await Promise.all([
                    this.platformSvc.init(),
                    this.getIntegrationSchema(),
                    this.getMetricConfigs(),
                ]);
                this.promoteTagType(types);
                this.addIntegrationSchema(types, integrationSchema);
                this.promoteAwsEnums(types);
                this.rootAzureTypes(types);
                this.removePlatformSpecificFields(types);
                this.removeTagFields(types);
                this.removeBadFields(types);
                this.removeEmptyTypes(types);
                this.removeDuplicatedFields(types, integrationSchema);
                this.adjustAllTags(types);
                this.adjustAllNonTagFields(types);
                this.adjustFieldFormats(types, metricConfigs);
            }
        });
    }

    public createValueProviderFactory() {
        const schemaProvider = this;
        return (schemaSvc: SchemaService) => {
            const schemaValueProvider = new SchemaValueProvider(schemaSvc, (query: Query) => postResourcesQuery(query));
            return {
                getValueProvider(field: QueryField) {
                    if (field.Field === 'CloudPlatform') {
                        return schemaProvider.platformSvc.getPickerOptions();
                    } else {
                        return schemaValueProvider.getValueProvider(field);
                    }
                },
            };
        };
    }

    private getIntegrationSchema() {
        return getMetadataIntegrationGetSchema();
    }

    private async getMetricConfigs() {
        const results = new Map<string, CWMetricsLabelConfig>();
        const [idle, rightsizing] = await Promise.all([
            getCWMetricsConfigGetCWMetricsConfigData({ appType: 'IdleResources' }),
            getCWMetricsConfigGetCWMetricsConfigData({ appType: 'RightSizing' }),
        ]);
        const appConfigs: [string, CWMetricsConfigData[]][] = [
            ['IdleResources', idle],
            ['RightSizing', rightsizing],
        ];
        for (const [appType, configs] of appConfigs) {
            if (configs) {
                for (const resourceTypeConfigs of configs) {
                    for (const config of resourceTypeConfigs.cWMetricLabelConfig ?? []) {
                        results.set(`${appType}-${resourceTypeConfigs.ResourceType}-${config.label}`, config);
                    }
                }
            }
        }
        return results;
    }

    private removeEmptyTypes(types: SchemaType[]) {
        types.splice(0, Infinity, ...types.filter((t) => (t.Fields?.length ?? 0) > 0));
    }

    private promoteTagType(types: SchemaType[]) {
        const tags = types.find((t) => t.TypeId == 'Tags');
        if (tags) {
            tags.IsRoot = true;
            const idx = types.indexOf(tags);
            if (idx >= 0) {
                types.splice(idx, 1);
                types.splice(1, 0, tags);
            }
            for (const tag of tags.Fields ?? []) {
                tag.Field = `CsTags.${tag.Field}`;
            }
        }
    }

    private addIntegrationSchema(types: SchemaType[], integrationSchema: SchemaType[]) {
        if (integrationSchema?.length) {
            const index = types.find((t) => t.TypeId === 'Tags') ? 2 : 1;
            const integrationNames = new Set(integrationSchema.map((t) => t.Name));
            const validTypes: SchemaType[] = [];
            for (const type of types) {
                const [, name] = (type.TypeId ?? '').split('/');
                if (!name || !integrationNames.has(name)) {
                    validTypes.push(type);
                }
            }
            types.splice(0, Infinity, ...validTypes);
            types.splice(index, 0, ...integrationSchema);
        }
    }

    private removePlatformSpecificFields(types: SchemaType[]) {
        const platforms = this.platformSvc.getPlatforms();
        if (platforms.size === 1 && platforms.has('Azure')) {
            const base = types.find((t) => t.TypeId === 'Base');
            if (base?.Fields) {
                const platformSpecific = new Set([
                    'Account',
                    'AccountName',
                    'Region',
                    'CompletenessScore',
                    'CreateDate',
                    'ParentCreateDate',
                    'Last30DaysCost',
                    'ManagementAccount',
                ]);
                base.Fields = base.Fields.filter((f) => !platformSpecific.has(f.Field!));
            }
        } else if (platforms.size === 1 && platforms.has('Aws')) {
            const base = types.find((t) => t.TypeId === 'Base');
            if (base?.Fields) {
                const platformSpecific = new Set(['ResourceGroup', 'Subscription', 'SubscriptionId']);
                base.Fields = base.Fields.filter((f) => !platformSpecific.has(f.Field!));
            }
        }
    }

    private rootAzureTypes(types: SchemaType[]) {
        const newTypes: SchemaType[] = [];
        const rootTypes = new Map<string, SchemaType>();

        for (const type of types) {
            if (type.TypeId?.startsWith('azure/') && type.Name) {
                const [_, ns, typeName, rest] = type.TypeId.split('/');
                if (!rest && ns?.indexOf('_') >= 0) {
                    const [_, nsName] = ns.split('_');
                    const [index, nsType] = type.TypeId.split('/');
                    const rootTypeId = `${index}/${nsType}`;
                    let rootType = rootTypes.get(nsName);
                    if (!rootType) {
                        rootTypes.set(
                            nsName,
                            (rootType = {
                                TypeId: rootTypeId,
                                Name: nsName,
                                IsRoot: true,
                                Fields: [],
                            })
                        );
                        newTypes.push(rootType);
                    }
                    rootType.Fields?.push({
                        Field: type.Name,
                        HasMany: false,
                        TypeName: type.TypeId,
                        IsPrimitive: false,
                        Name: typeName,
                    });
                }
            }
        }

        newTypes.sort((a, b) => a.Name!.localeCompare(b.Name!));
        types.push(...newTypes);
    }

    private removeTagFields(types: SchemaType[]) {
        for (const type of types) {
            if (type.IsRoot) {
                type.Fields = type.Fields?.filter((f) => f.TypeName !== 'Tags' && f.Field !== 'Tags.Key' && f.Field !== 'Tags.Value');
            }
        }
    }

    private removeDuplicatedFields(types: SchemaType[], integrationSchema: SchemaType[]) {
        const integrationFieldNames = new Set(integrationSchema.map((t) => t.Name));
        for (const type of types) {
            if (type.IsRoot) {
                type.Fields = type.Fields?.filter(
                    (f) => f.Name !== 'LastSyncDate' && f.Name !== 'ManagementAccount' && !integrationFieldNames.has(f.Name)
                );
            }
        }
    }

    private adjustAllNonTagFields(types: SchemaType[]) {
        const allButTags = types.filter((t) => t.TypeId != 'Tags');
        if (allButTags) {
            for (const nonTag of allButTags) {
                for (const field of nonTag.Fields!) {
                    if (field?.Name) {
                        field.Name = this.formatSvc.userFriendlyCamelCase(field.Name!);
                        field.Name = this.overrideSpecificFieldName(field) ?? field.Name;
                    }
                }
            }
        }
    }

    private removeBadFields(types: SchemaType[]) {
        const badFields = [
            { typeSuffix: 'ec2_on_demand/InstanceTypeAttributes', fields: new Set(['vCpu', 'InstanceType']) },
            { typeSuffix: 'ec2_on_demand/Metrics/IdleResources', fields: new Set(['CPUUtilization']) },
            { typeSuffix: 'ec2_on_demand', fields: new Set(['__preIndexRunningState.Value']) },
            { typeSuffix: 'ebs', fields: new Set(['__preIndexState.Value']) },
            {
                typeSuffix: 'ec2_on_demand/Metrics',
                fields: new Set(['CpuUtilization', 'NetworkOut', 'CpuSamples', 'NetworkIn', 'MemoryUtilization']),
            },
        ];
        for (const type of types) {
            const badFieldsForType = badFields.find((f) => type.TypeId?.endsWith(f.typeSuffix));
            if (badFieldsForType) {
                type.Fields = type.Fields?.filter((f) => !badFieldsForType.fields.has(f.Field!));
            }
        }
    }

    //add specific overrides for specific column fields
    private overrideSpecificFieldName(field: SchemaField) {
        switch (field.Field) {
            //refers to ELBV State.Code.Value field
            case 'Code.Value':
                return 'State';
            case 'CpuUtilization':
                return 'CPU Utilization';
            case 'vCPUs':
                return 'vCPUs';
            default:
                return null;
        }
    }

    private adjustAllTags(types: SchemaType[]) {
        const base = types.find((t) => t.TypeId === 'Base');
        if (base) {
            base.Fields = base.Fields?.filter(
                (f) => f.Field !== 'CsTagValueLookup' && f.Field !== 'CompanyID' && f.Field !== 'AccountID' && f.Field !== 'Id'
            );
            base.Fields?.push(
                { Field: 'Tags.Key', HasMany: true, IsPrimitive: true, Name: 'Tag Keys', TypeName: 'string' },
                { Field: 'Tags.Value', HasMany: true, IsPrimitive: true, Name: 'Tag Values', TypeName: 'string' }
            );
        }
    }

    private adjustFieldFormats(types: SchemaType[], metricConfigs: Map<string, CWMetricsLabelConfig>) {
        const standardMetricDescription = (metricName: string, defaultLookback: number = 30) => {
            return (stat: string = 'Average', lookback?: number | null, period: string | number = '1 hour') => {
                const periodName = typeof period !== 'number' ? period : period === 3600 ? '1 hour' : `${period / 60} min`;
                return `Top ${stat} Cloudwatch ${metricName} in the last ${
                    typeof lookback === 'number' ? lookback : defaultLookback
                } day(s) per ${periodName} interval.`;
            };
        };
        const metricFieldFormatLookup = [
            {
                resourceType: 'RDS',
                typeSuffix: 'rds/Metrics',
                fieldFormats: [
                    {
                        field: 'ReadIOPS',
                        format: 'number-with-two-decimals',
                        description: standardMetricDescription('Read IOPS'),
                    },
                    {
                        field: 'WriteIOPS',
                        format: 'number-with-two-decimals',
                        description: standardMetricDescription('Write IOPS'),
                    },
                    {
                        field: 'DatabaseConnections',
                        format: 'number-with-two-decimals',
                        description: standardMetricDescription('Network Connections'),
                    },
                ],
            },
            {
                resourceType: 'EC2 On Demand',
                typeSuffix: 'ec2_on_demand/Metrics',
                fieldFormats: [
                    {
                        field: 'CPUUtilization',
                        format: 'whole-percent',
                        description: standardMetricDescription('CPU'),
                    },
                    {
                        field: 'CpuUtilization',
                        format: 'whole-percent',
                        description: standardMetricDescription('CPU'),
                    },
                    {
                        field: 'NetworkIn',
                        format: 'bytes',
                        description: standardMetricDescription('Network In'),
                    },
                    {
                        field: 'NetworkOut',
                        format: 'bytes',
                        description: standardMetricDescription('Network Out'),
                    },
                    {
                        field: 'CpuSamples',
                        format: 'bytes',
                        description: () => 'Count of CPU Metrics available from the last 30 days per 1 hour interval.',
                    },
                    {
                        field: 'MemoryUtilization',
                        format: 'whole-percent',
                        description: standardMetricDescription('Memory'),
                    },
                ],
            },
            {
                resourceType: 'EMR',
                typeSuffix: 'emr/Metrics',
                fieldFormats: [
                    {
                        field: 'IsIdle',
                        format: undefined,
                        description: (stat: string = 'Minimum', lookback: number = 1, period: string | number = '5 mins') =>
                            `Indicates that a cluster has performed no work for any ${period} for that last ${lookback} day(s), but is still alive and accruing charges.`,
                    },
                ],
            },
            {
                resourceType: 'ELBV1',
                typeSuffix: 'elbv1/Metrics',
                fieldFormats: [
                    {
                        field: 'SumOfRequests',
                        format: 'int',
                        description: (stat: string = 'Sum', lookback: number = 7, period: string | number = '1 hour') =>
                            `${stat === 'Sum' ? 'Total' : stat} CloudWatch sum of requests in last 7 days per 1 minute interval.`,
                    },
                ],
            },
            {
                resourceType: 'Redshift',
                typeSuffix: 'redshift/Metrics',
                fieldFormats: [
                    {
                        field: 'ReadIOPS',
                        format: 'number-with-two-decimals',
                        description: standardMetricDescription('Read IOPS', 7),
                    },
                    {
                        field: 'WriteIOPS',
                        format: 'number-with-two-decimals',
                        description: standardMetricDescription('Read IOPS', 7),
                    },
                    {
                        field: 'DatabaseConnections',
                        format: 'number-with-two-decimals',
                        description: standardMetricDescription('Read IOPS', 7),
                    },
                ],
            },
        ];

        for (const type of types) {
            for (const metricApp of ['IdleResources', 'RightSizing']) {
                const fieldFormats = metricFieldFormatLookup.find((f) => type.TypeId?.endsWith(f.typeSuffix + '/' + metricApp));
                if (fieldFormats) {
                    for (const fieldFormat of fieldFormats.fieldFormats) {
                        const field = type.Fields?.find((f) => f.Field === fieldFormat.field);
                        if (field) {
                            const metricConfig = metricConfigs.get(`${metricApp}-${fieldFormats.resourceType}-${fieldFormat.field}`);
                            if (fieldFormat.format) this.applyFieldFormat(field, fieldFormat.format);
                            field.Description = metricConfig
                                ? fieldFormat.description(metricConfig.stat!, metricConfig.lookBackDate!, metricConfig.pollingInterval!)
                                : fieldFormat.description();
                            field.GroupName = 'Idle Metrics';
                        }
                    }
                }
            }
        }
    }

    private applyFieldFormat(field: SchemaField, format: string) {
        (field as { Format: string }).Format = format;
    }

    public resolveAccessPath(fieldInfo: FieldInfo) {
        const accessorPath = fieldInfo.getPath();
        if (this.isAwsEnum(fieldInfo)) {
            accessorPath.splice(-1, 1);
            accessorPath.push(fieldInfo.fieldName.split('.')[0], 'Value');
        }
        return accessorPath;
    }

    public isAwsEnum(fieldInfo: FieldInfo) {
        return fieldInfo.owner.name !== 'Tags' && fieldInfo.isPrimitive && fieldInfo.typeName === 'string' && fieldInfo.fieldName.match(/\.Value$/);
    }

    private promoteAwsEnums(types: SchemaType[]) {
        const enumTypes = types.reduce((result, item) => {
            if (item.Fields?.length === 1) {
                const {
                    Fields: { [0]: field },
                } = item;
                if (field.Field === 'Value' && field.IsPrimitive && field.TypeName === 'string') {
                    result.set(item.TypeId ?? '', item);
                }
            }
            return result;
        }, new Map<string, SchemaType>());
        for (const type of types) {
            for (const field of type.Fields ?? []) {
                const enumType = enumTypes.get(field.TypeName ?? '');
                if (enumType) {
                    field.IsPrimitive = true;
                    field.Name = enumType.Name;
                    field.TypeName = 'string';
                    field.Field += '.Value';
                }
            }
        }
    }
}

@injectable()
export class PendingTagService {
    public constructor() {}
}

import { postTagAutomationQueryTrace } from '@apis/TagManager';
import { TagAutomationTraceByRule } from '@apis/TagManager/model';
import { DateRangeFilter } from '@root/Components/Filter/DateRangeFilter';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { queryBuilder } from '@root/Services/QueryExpr';
import { addDays, startOfDay } from 'date-fns';
import { useEffect, useState } from 'react';

export function TagAutomationDateRangeFilter({
    initialValue,
    onChange,
}: {
    initialValue?: { from?: Date; to?: Date };
    onChange: (dateRange: { from?: Date; to?: Date }) => void;
}) {
    const fmtSvc = useDi(FormatService);

    const [hasData, setHasData] = useState(false);
    const [dateFilterValue, setDateFilterValue] = useState<{ from?: Date; to?: Date; id?: string }>(initialValue ?? {});
    const [dateFilter, setDateFilter] = useState<{ from?: Date; to?: Date }>({});
    useEffect(() => {
        if (initialValue) {
            setHasData(true);
            return;
        }
        (async () => {
            const rangeResults = await queryBuilder<TagAutomationTraceByRule>()
                .select((b) => ({
                    from: b.min(b.model.Timestamp),
                    to: b.max(b.model.Timestamp),
                    ct: b.count(),
                }))
                .execute(postTagAutomationQueryTrace);
            const { from, to } = rangeResults.Results?.[0] ?? {};
            const dateRange = { min: from ? fmtSvc.toLocalDate(from) : undefined, max: to ? fmtSvc.toLocalDate(to) : undefined };
            setHasData(!!rangeResults.Results?.[0]?.ct);

            if (dateRange && dateRange.min && dateRange.max) {
                const prior30Days = addDays(startOfDay(dateRange.min), -30);
                setDateFilterValue({
                    to: dateRange.max,
                    from: prior30Days > dateRange.min ? prior30Days : dateRange.min,
                });
            }
        })();
    }, []);

    useEffect(() => {
        const pastDays = dateFilterValue.id === 'last-7' ? -6 : dateFilterValue.id === 'last-30' ? -29 : null;
        const from = pastDays ? addDays(new Date(), pastDays) : dateFilterValue.from;
        const to = pastDays ? new Date() : dateFilterValue.to;
        setDateFilter({
            to: to ? addDays(startOfDay(to), 1) : undefined,
            from: from ? startOfDay(from) : undefined,
        });
    }, [dateFilterValue.from, dateFilterValue.to, dateFilterValue.id]);

    useEffect(() => {
        onChange(dateFilter);
    }, [dateFilter.from, dateFilter.to]);

    return (
        <DateRangeFilter
            options={[
                { id: 'last-7', label: 'Last 7 days' },
                { id: 'last-30', label: 'Last 30 days' },
            ]}
            disabled={!hasData}
            constraint={{}}
            onChange={setDateFilterValue}
            value={dateFilterValue}
        />
    );
}

import { postResourcesQuery } from '@apis/Resources';
import { BaseResource, IQueryExpr } from '@apis/Resources/model';
import styled from '@emotion/styled';
import { Box, Card, Divider, Grid, Group, Text, Title, useMantineTheme } from '@mantine/core';
import { GridArrayDataSource } from '@root/Components/DataGrid/DataSources';
import { ColumnConfig } from '@root/Components/DataGrid/Models';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';
import { InitialSyncCheck } from '@root/Components/Resources/IntialSyncCheck';
import { PaneledPage, PagePanel, PanelBody } from '@root/Design/Layout';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { PlatformService } from '@root/Services/PlatformService';
import { queryBuilder, SchemaService, ValuesGroupOtherText } from '@root/Services/QueryExpr';
import { ResourceSchemaProvider } from '@root/Services/Resources/ResourceService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useEffect, useMemo, useState } from 'react';
import { TagIntelligencePage } from './TagIntelligencePage';
import { ViewByGrid } from './ViewByGrid';
import { KpiTile } from '@root/Site/CompanyHome/IntelligenceDashboard';

interface HealthKpis {
    tagHealthScore: number;
    clarityScore: number;
    coverageScore: number;
    complianceScore: number;
}
type TagHealthDataItem = HealthKpis & {
    type: string;
    platform: string;
    resourceCount: number;
    tagCount: number;
    clarityScoreNonNullCount: number;
};
export function TagHealthContent() {
    const [kpis, setKpis] = useState<HealthKpis>();
    const [filter, setFilter] = useState<IQueryExpr[]>([]);
    const [viewBy, setViewBy] = useState<string>('ResourceType');
    const theme = useMantineTheme();
    const formatSvc = useDi(FormatService);
    const datasourceEvent = useMemo(
        () =>
            new EventEmitter(
                new GridArrayDataSource(
                    [],
                    [
                        { field: 'type', type: 'string', getValue: (item: TagHealthDataItem) => item.type },
                        { field: 'platform', type: 'string', getValue: (item: TagHealthDataItem) => item.platform },
                        { field: 'resourceCount', type: 'number', getValue: (item: TagHealthDataItem) => item.resourceCount },
                        { field: 'tagCount', type: 'number', getValue: (item: TagHealthDataItem) => item.tagCount },
                        { field: 'clarityScoreNonNullCount', type: 'number', getValue: (item: TagHealthDataItem) => item.clarityScoreNonNullCount },
                        { field: 'tagHealthScore', type: 'number', getValue: (item: TagHealthDataItem) => item.tagHealthScore * 100 },
                        { field: 'clarityScore', type: 'number', getValue: (item: TagHealthDataItem) => item.clarityScore * 100 },
                        { field: 'coverageScore', type: 'number', getValue: (item: TagHealthDataItem) => item.coverageScore * 100 },
                        { field: 'complianceScore', type: 'number', getValue: (item: TagHealthDataItem) => item.complianceScore * 100 },
                    ]
                )
            ),
        []
    );
    const datasource = useEventValue(datasourceEvent)!;

    const udpateKpis = () => {
        const filteredData = datasource.filter(filter) as TagHealthDataItem[];
        const totalResources = filteredData.reduce((result, item) => (result += item.resourceCount), 0);

        setKpis(
            filteredData.reduce(
                (result, item) => {
                    const factor = item.resourceCount / totalResources;
                    result.clarityScore += item.clarityScore * factor;
                    result.complianceScore += item.complianceScore * factor;
                    result.coverageScore += item.coverageScore * factor;
                    result.tagHealthScore += item.tagHealthScore * factor;
                    return result;
                },
                { clarityScore: 0, complianceScore: 0, coverageScore: 0, tagHealthScore: 0 } as HealthKpis
            )
        );
    };

    useEffect(() => {
        (async () => {
            const results = await queryBuilder<BaseResource>()
                .take(500)
                .select((b) => ({
                    type: {
                        Operation: 'values',
                        Operands: [{ Field: viewBy }, { Value: '' }, { Value: ValuesGroupOtherText }],
                    } as unknown as string,
                    platform: {
                        Operation: 'values',
                        Operands: [{ Field: 'CloudPlatform' }, { Value: '' }, { Value: ValuesGroupOtherText }],
                    } as unknown as string,
                    clarityScoreNonNullCount: b.countValues(b.model['ComplianceScore']!) as unknown as number,
                    resourceCount: b.count(),
                    tagCount: b.countValues(b.model['Tags.Key']),
                    tagHealthScore: b.avg(b.model['TagHealthScore'] as unknown as number),
                    coverageScore: b.avg(b.model['CoverageScore'] as unknown as number),
                    clarityScore: b.avg(b.model['ClarityScore'] as unknown as number),
                    complianceScore: b.avg(b.model['ComplianceScore'] as unknown as number),
                }))
                .execute(postResourcesQuery);

            datasource.items = results?.Results ?? [];
            datasourceEvent.emitCurrent();
            udpateKpis();
        })();
    }, [viewBy]);

    useEffect(udpateKpis, [JSON.stringify(filter), viewBy, datasource.items]);

    const hasCompliance = true;
    const spanSize = hasCompliance ? 3 : 4;

    return (
        <TagIntelligencePage>
            <PaneledPage>
                <PagePanel size="fill">
                    <PanelBody>
                        <Title data-atid="IntelligenceHeader" order={2}>
                            Tag Health
                        </Title>
                        <Grid py="xl">
                            <Grid.Col span={spanSize}>
                                <KpiTile
                                    topColor={
                                        kpis?.tagHealthScore !== undefined
                                            ? kpis?.tagHealthScore >= 0.8
                                                ? theme.colors.success[5]
                                                : kpis?.tagHealthScore >= 0.4
                                                ? theme.colors.warning[5]
                                                : theme.colors.error[5]
                                            : '#f0f0f0'
                                    }
                                    title="Overall Tag Health"
                                    value={kpis?.tagHealthScore !== undefined ? formatSvc.formatPercent(kpis?.tagHealthScore) : undefined}
                                    fontSize="32px"
                                />
                            </Grid.Col>
                            <Grid.Col span={spanSize}>
                                <KpiTile
                                    topColor={
                                        kpis?.coverageScore !== undefined
                                            ? kpis?.coverageScore >= 0.8
                                                ? theme.colors.success[5]
                                                : kpis?.coverageScore >= 0.4
                                                ? theme.colors.warning[5]
                                                : theme.colors.error[5]
                                            : '#f0f0f0'
                                    }
                                    title="Coverage"
                                    value={kpis?.coverageScore !== undefined ? formatSvc.formatPercent(kpis?.coverageScore) : undefined}
                                    fontSize="32px"
                                />
                            </Grid.Col>
                            {hasCompliance ? (
                                <>
                                    <Grid.Col span={spanSize}>
                                        <KpiTile
                                            topColor={
                                                kpis?.complianceScore !== undefined
                                                    ? kpis?.complianceScore >= 0.8
                                                        ? theme.colors.success[5]
                                                        : kpis?.complianceScore >= 0.4
                                                        ? theme.colors.warning[5]
                                                        : theme.colors.error[5]
                                                    : '#f0f0f0'
                                            }
                                            title="Compliance"
                                            value={kpis?.complianceScore !== undefined ? formatSvc.formatPercent(kpis?.complianceScore) : undefined}
                                            fontSize="32px"
                                        />
                                    </Grid.Col>
                                </>
                            ) : null}

                            <Grid.Col span={spanSize}>
                                <KpiTile
                                    topColor={
                                        kpis?.clarityScore !== undefined
                                            ? kpis?.clarityScore >= 0.8
                                                ? theme.colors.success[5]
                                                : kpis?.clarityScore >= 0.4
                                                ? theme.colors.warning[5]
                                                : theme.colors.error[5]
                                            : '#f0f0f0'
                                    }
                                    title="Clarity"
                                    value={kpis?.clarityScore !== undefined ? formatSvc.formatPercent(kpis?.clarityScore) : undefined}
                                    fontSize="32px"
                                />
                            </Grid.Col>
                        </Grid>
                        <Card withBorder radius="lg" sx={{ height: 600 }}>
                            <TagHealthGrid
                                hasCompliance={hasCompliance}
                                data={datasource.items ?? []}
                                onViewByChanged={setViewBy}
                                onFilter={setFilter}
                            />
                        </Card>
                    </PanelBody>
                </PagePanel>
            </PaneledPage>
        </TagIntelligencePage>
    );
}
function TagHealth() {
    return <ConnectionCheck>{() => <InitialSyncCheck>{() => <TagHealthContent />}</InitialSyncCheck>}</ConnectionCheck>;
}
endpoint('tag-health', TagHealth, 'Tag Intelligence');

function HealthStat({
    width,
    value,
    title,
    description,
}: {
    width?: string;
    value?: number;
    title: string;
    description: string;
    isNumber?: boolean;
}) {
    const formatSvc = useDi(FormatService);
    return (
        <Box sx={{ width, minHeight: 130, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ textAlign: 'center' }}>
                <Title order={4}>{title}</Title>
                <Text size="sm">{description}</Text>
            </Box>
            <BigNumber>{value !== undefined ? formatSvc.formatPercentRoundDown(value) : null}</BigNumber>
        </Box>
    );
}

const BigNumber = styled.div`
    font-size: 32px;
    font-weight: bold;
    text-align: center;
`;

function TagHealthGrid({
    hasCompliance,
    data,
    onViewByChanged,
    onFilter,
}: {
    hasCompliance: boolean;
    data: TagHealthDataItem[];
    onViewByChanged: (field: string) => void;
    onFilter: (filters: IQueryExpr[]) => void;
}) {
    const formatSvc = useDi(FormatService);
    const resourceSchemaProvider = useDi(ResourceSchemaProvider);
    const [schema, setSchema] = useState<SchemaService>();
    useEffect(() => {
        (async () => {
            const types = await resourceSchemaProvider.getSchema();
            setSchema(new SchemaService(types));
        })();
    }, []);

    const columns = useMemo(() => {
        const result: ColumnConfig<TagHealthDataItem>[] = [
            {
                header: 'Platform',
                accessor: 'platform',
                defaultWidth: 100,
                id: 'platform',
                type: 'string',
                align: 'center',
                filter: {
                    filterField: 'platform',
                    filterType: 'string',
                    name: 'platform',
                },
                cellRenderer: (item) => item.platform,
            },
            {
                header: 'Taggable Resources',
                accessor: 'resourceCount',
                defaultWidth: 100,
                id: 'resourceCount',
                type: 'number',
                align: 'center',
                filter: {
                    filterField: 'resourceCount',
                    filterType: 'number',
                    name: 'Resources',
                },
                cellRenderer: (item) => item.resourceCount.toLocaleString(),
            },
            {
                header: 'Number of Tags',
                accessor: 'tagCount',
                defaultWidth: 100,
                id: 'tagCount',
                type: 'number',
                align: 'center',
                filter: {
                    filterField: 'tagCount',
                    filterType: 'number',
                    name: 'Number of Tags',
                },
                cellRenderer: (item) => item.tagCount.toLocaleString(),
            },
            {
                header: 'Overall Tag Health',
                accessor: (item) => item.tagHealthScore * 100,
                defaultWidth: 100,
                id: 'tagHealth',
                sortField: 'tagHealthScore',
                type: 'number',
                align: 'center',
                filter: {
                    filterField: 'tagHealthScore',
                    filterType: 'number',
                    name: 'Overall Tag Health',
                },
                cellRenderer: (item) => formatSvc.formatPercentRoundDown(item.tagHealthScore),
            },
            {
                header: 'Coverage Score',
                accessor: (item) => item.coverageScore * 100,
                defaultWidth: 100,
                id: 'coverageScore',
                sortField: 'coverageScore',
                type: 'number',
                align: 'center',
                filter: {
                    filterField: 'coverageScore',
                    filterType: 'number',
                    name: 'Coverage Score',
                },
                cellRenderer: (item) => formatSvc.formatPercentRoundDown(item.coverageScore),
            },
        ];
        if (hasCompliance) {
            result.push({
                header: 'Compliance Score',
                accessor: (item) => item.complianceScore * 100,
                defaultWidth: 100,
                id: 'complianceScore',
                sortField: 'complianceScore',
                type: 'number',
                align: 'center',
                filter: {
                    filterField: 'complianceScore',
                    filterType: 'number',
                    name: 'Compliance Score',
                },
                cellRenderer: (item) => (item.clarityScoreNonNullCount == 0 ? 'N/A' : formatSvc.formatPercentRoundDown(item.complianceScore)),
            });
        }
        result.push({
            header: 'Clarity Score',
            accessor: (item) => item.clarityScore * 100,
            defaultWidth: 100,
            id: 'clarityScore',
            sortField: 'clarityScore',
            type: 'number',
            align: 'center',
            filter: {
                filterField: 'clarityScore',
                filterType: 'number',
                name: 'Clarity Score',
            },
            cellRenderer: (item) => formatSvc.formatPercentRoundDown(item.clarityScore),
        });
        return result;
    }, []);

    if (schema) {
        return (
            <ViewByGrid<TagHealthDataItem>
                data={data}
                onViewByChanged={onViewByChanged}
                metricColumns={columns}
                schema={schema}
                onFilter={onFilter}
            />
        );
    } else {
        return null;
    }
}

const WrappingHeader = styled.div`
    white-space: normal;
    text-overflow: unset;
`;

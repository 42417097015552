import { Box, Divider, Text, useMantineTheme } from '@mantine/core';
import { useDiContainer } from '@root/Services/DI';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { Observer, observer } from 'mobx-react';
import { useMemo } from 'react';
import { MapWizardSteps, WizardHeavyLifting } from './Components';
import { MapWizardPageOuter, MapWizardPageContent, MapWizardPageContentBody, MapWizardPageHelp } from './Design';
import { MapContractSetupModel } from './Model';
import { Step1Contract } from './Step1Contract';
import { Step3Validation } from './Step3Validation';

export function MapWizard() {
    const di = useDiContainer();
    const model = useMemo(() => {
        const result = di.resolve(MapContractSetupModel);
        result.init(Step1Contract, Step3Validation);
        return result;
    }, []);
    const theme = useMantineTheme();
    const pages = [Step1Contract, Step3Validation].map((Step, i) => {
        return (
            <MapWizardPageContent key={i} style={{ display: i === model.currentStep - 1 ? undefined : 'none' }}>
                <MapWizardPageContentBody>
                    <Step.Content />
                </MapWizardPageContentBody>
                <Divider orientation="vertical" />
                <MapWizardPageHelp>
                    <Box>
                        <Step.Help />
                    </Box>
                    <WizardHeavyLifting />
                </MapWizardPageHelp>
            </MapWizardPageContent>
        );
    });
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ background: theme.white, flexShrink: 0 }}>
                <MapWizardSteps step={model.currentStep} steps={model.steps.length} canProceed={model.canProceed()} goto={model.goto} />
            </Box>
            <Divider />
            <MapWizardPageOuter>{pages}</MapWizardPageOuter>
        </Box>
    );
}

endpoint('map-contract-setup', observer(MapWizard), 'MAP Contract Setup');

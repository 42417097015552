import { observer } from 'mobx-react';
import { AllocationRuleEditor } from '../Model';
import { createContext, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AllocationSources } from '../AllocationSources';
import { IMiniDataGridProps, MiniDataGridItem } from '../MiniDataGrid';
import { BaseAllocationRuleModel } from './BaseAllocationRuleModel';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { BasePresentationOptions, DisbursementTarget, FundSource } from '@apis/Invoices/model';
import {
    SchemaValueProvider,
    exprBuilder,
    queryBuilder,
    IFluentOperators,
    sortedObjectId,
    cleanBoolExpr,
    ValuesGroupOtherText,
} from '@root/Services/QueryExpr';
import {
    Box,
    Card,
    Space,
    Button,
    Checkbox,
    Stack,
    useMantineTheme,
    Group,
    Divider,
    ActionIcon,
    Badge,
    Sx,
    Switch,
    Text,
    Tooltip,
    CopyButton,
    Collapse,
} from '@mantine/core';
import { BaseResource, Query, ReservedRds, ReservedEc2, SavingsPlan, IQueryExpr } from '@apis/Resources/model';
import { QueryExpr, QueryResult, postResourcesQuery } from '@apis/Resources';
import styled from '@emotion/styled';
import { useDi, useDiMemo } from '@root/Services/DI';
import { SchemaFieldNameProvider } from '@root/Components/Filter/Services';
import { DataFilterModel, DataFilters } from '@root/Components/Filter/Filters';
import { FieldPicker } from '@root/Components/Picker/FieldPicker';
import { Check, ChevronDown, ChevronRight, Copy, Discount, Plus } from 'tabler-icons-react';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { FormatService } from '@root/Services/FormatService';
import { EditorCard, EditorCardWhiteWrap } from '../Design';
import { SidePanel, useSidePanelOpener } from '@root/Design/SidePanel';
import { FillerSwitch } from '@root/Design/Filler';
import { IDailyRollup } from '@root/Services/Invoices/InvoiceSchemaService';
import { addDays, differenceInDays, differenceInMonths, differenceInYears, endOfMonth, format, startOfMonth } from 'date-fns';
import { ITypedTreeConfig, VirtualTree } from '@root/Components/VirtualTree';
import { Node } from '@root/Components/VirtualTree/Node';
import { InfoIconTooltip } from '@root/Design/Primitives';

export const DiscountsRuleCard = observer(function DiscountsRuleCard({ ruleEditor }: { ruleEditor: AllocationRuleEditor }) {
    const model = useMemo(() => new DiscountEditorModel(ruleEditor).init(), [ruleEditor.rule]);
    const invoiceApi = useDiMemo(InvoiceApiService);
    const [filterModel, setFilterModel] = useState<DataFilterModel>();

    const disountsLoading = useEventValue(model.loading);

    useEvent(model.sourcesChanged);
    const addSourceButtons = useMemo(
        () => [
            {
                label: 'Add Allocation Source',
                onClick: () => {
                    model.openAddSavingsPlanDrawer();
                },
            },
        ],
        []
    );

    const sourceListProps = {
        nameHeader: 'Description',
        amountHeader: 'Commitment',
    } as IMiniDataGridProps;

    const showDrawerRequest = useEventValue(model.showingDiscountPicker);

    const addFilter = useCallback(() => {
        filterModel?.addEmptyFilter(true);
    }, [filterModel]);

    const datasource = useMemo(
        () =>
            function query<T>(query: Query) {
                const selectedMonth = new Date(ruleEditor.month);
                const startOfMonth = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1);
                const endOfMonth = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0);
                return invoiceApi.query(query, { from: startOfMonth, to: endOfMonth }, false) as Promise<QueryResult<T>>;
            },
        [ruleEditor.month]
    );

    const valueProvider = useMemo(() => {
        return new SchemaValueProvider(ruleEditor.schemaSvc, datasource);
    }, [ruleEditor.schemaSvc]);

    const fieldInfoProvider = useMemo(() => {
        return new SchemaFieldNameProvider(ruleEditor.schemaSvc);
    }, [ruleEditor.schemaSvc]);

    const sourceGridDataProvider = useCallback(() => {
        return model.getDiscountGridItems();
    }, []);

    return (
        <FillerSwitch loading={ruleEditor.loading || disountsLoading}>
            {() => (
                <>
                    <AllocationSources
                        gridProps={sourceListProps}
                        sourceChanged={model.sourcesChanged}
                        gridData={sourceGridDataProvider}
                        title="Discounts"
                        helpText="Add saving plans or reserved instances to bundle discounts."
                        buttons={addSourceButtons}
                    />
                    <EditorCardWhiteWrap>
                        <EditorCard color="gray" title="Apply To" wrapped>
                            <DiscountTargetOptions model={model} />
                            <Space h="md" />
                            <Box>
                                <DataFilters
                                    filters={model.getScopeFilters()}
                                    onChange={model.setScopeFilters}
                                    valueProvider={valueProvider}
                                    fieldInfoProvider={fieldInfoProvider!}
                                    renderFieldPicker={(select) => (
                                        <FieldPicker
                                            mode="single"
                                            selections={[]}
                                            schema={ruleEditor.schemaSvc!}
                                            onChange={([f]) => select(f.path)}
                                        />
                                    )}
                                    onModelLoaded={setFilterModel}
                                    dataFiltersAsLineItem
                                />
                                <Box sx={{ margin: '15px 0px' }}>
                                    <Button variant="outline" size="xs" radius="xl" leftIcon={<Plus size={16} />} onClick={addFilter}>
                                        Add Filter
                                    </Button>
                                </Box>
                            </Box>
                        </EditorCard>
                    </EditorCardWhiteWrap>
                    {showDrawerRequest ? <DiscountResourcesDrawer model={model} /> : null}
                </>
            )}
        </FillerSwitch>
    );

    function getReservedInstanceSpecificQuery(resource: BaseResource) {
        const riType = resource.ResourceType as string;
        switch (riType) {
            case 'RDS Reserved':
                var supportedTypes = ['AmazonRDS'];
                return exprBuilder<{
                    ['lineItem/ProductCode']: string;
                    ['lineItem/LineItemType']: string;
                    ['reservation/ReservationARN']: string;
                }>().createExpr((b) =>
                    b.and(
                        b.model['lineItem/ProductCode']!.eq(supportedTypes),
                        b.model['lineItem/LineItemType']!.eq('DiscountedUsage'),
                        b.model['reservation/ReservationARN']!.eq(resource.ReservedDBInstanceArn! as string)
                    )
                );
            case 'EC2 Reserved':
                var supportedTypes = ['AmazonEC2'];
                var reservationArn = GetEC2ReservedArn(resource);
                return exprBuilder<{
                    ['lineItem/ProductCode']: string;
                    ['lineItem/LineItemType']: string;
                    ['reservation/ReservationARN']: string;
                }>().createExpr((b) =>
                    b.and(
                        b.model['lineItem/ProductCode']!.eq(supportedTypes),
                        b.model['lineItem/LineItemType']!.eq('DiscountedUsage'),
                        b.model['reservation/ReservationARN']!.eq(reservationArn)
                    )
                );
            default:
                //empty condition?
                return exprBuilder<{}>().createExpr((b) => b.and());
        }
    }
});

function DiscountTargetOptions({ model }: { model: DiscountEditorModel }) {
    useEvent(model.optionsChanged);
    const { allEligible, amortizeUpfrontFees, balanceUpfrontFees, cancelOriginalUpfrontFees } = model.getTargetOptions();

    return (
        <Card radius="md" style={{ backgroundColor: 'white' }}>
            <Stack>
                <Checkbox
                    sx={{ alignItems: 'flex-start' }}
                    label={
                        <>
                            All Eligible Resources
                            <Text size="sm" color="dimmed">
                                Automatically configure reallocation of discount plan recurring fees and benefits to plan-eligible usage.
                            </Text>
                        </>
                    }
                    checked={allEligible}
                    onChange={(e) => model.updateTargetOptions({ allEligible: e.currentTarget.checked })}
                />
                <Checkbox
                    sx={{ alignItems: 'flex-start' }}
                    disabled={!allEligible}
                    label={
                        <>
                            Amortize Upfront Fees
                            <Text size="sm" color="dimmed">
                                Amortize plan upfront fees by reallocating their costs to the Adjusted Amortized Cost of plan-eligible usage.
                            </Text>
                        </>
                    }
                    checked={amortizeUpfrontFees}
                    onChange={(e) => model.updateTargetOptions({ amortizeUpfrontFees: e.currentTarget.checked })}
                />
            </Stack>
        </Card>
    );
}

type SavingsPlanProductTypes = 'SageMaker' | 'Fargate' | 'EC2' | 'Lambda';
type SavingsPlanType = 'Compute' | 'EC2Instance' | 'SageMaker';
type SavingsPlanPaymentOption = 'All Upfront' | 'No Upfront' | 'Partial Upfront';
interface AwsSavingsPlan extends SavingsPlan {
    SavingsPlanId?: string;
    SavingsPlanArn?: string;
    /**
     * AWS mysteriously provides this as a string, it's a number, representing the recurring fee(at an hourly rate) for the SP
     */
    RecurringPaymentAmount?: string;
    /**
     * AWS mysteriously provides this as a string, it's the amount paid upfront for the SP, lump sum
     *
     * To capture upfront cost from the CUR, consider using `savingsPlan/AmortizedUpfrontCommitmentForBillingPeriod`
     * It appears only on SavingsPlanRecurringFee line item types, regardless of partial or all upfront payment options
     */
    UpfrontPaymentAmount?: string;
    /**
     * AWS mysteriously provides this as a string, it's an number, repesenting (hourly) committed cost
     */
    Commitment?: string;
    /**
     * Types can be used to determine rules for what resources are eligible for this SP. Each type has a different set of
     * possible criteria, and each individual SP may prescibe some specific criteria for allocating sources and targets
     *
     * Compute:
     *  - `savingsPlan/OfferingType` == 'ComputeSavingsPlans'
     *
     * EC2Instance:
     *  - `savingsPlan/OfferingType` == 'EC2InstanceSavingsPlans'
     *
     * EC2Instance:
     *  - `savingsPlan/OfferingType` == 'SageMakerSavingsPlans'
     *
     * Other fields of interest:
     *  - `savingsPlan/StartTime`
     *  - `savingsPlan/EndTime`
     *  - `savingsPlan/SavingsPlanEffectiveCost`
     *  - `savingsPlan/SavingsPlanRate`
     *
     */
    SavingsPlanType?: { Value: SavingsPlanType };
    Start?: string;
    End?: string;
    /**
     * Applies to SavingsPlanType=EC2Instance, example values: 't3', 't3a', 'm5'
     * Only instances of the same family and region are eligible, but different sizes (t3.nano, t3.micro) are eligible
     * This can match eligible resources in the cur with a `product/instanceType startsWith ...`
     * Additionally, (SavingsPlanType=EC2Instance only) the region must match the cur's `product/region`
     */
    Ec2InstanceFamily?: string;
    /**
     * Expect `All Upfront` to have a "0.0" RecurringPaymentAmount and a ">0" UpfrontPaymentAmount.
     */
    PaymentOption?: { Value: SavingsPlanPaymentOption };
    /**
     * List of eligible "product types", these seem to consistently match what is eligible according to the SavingsPlanType
     * For example, if the SavingsPlanType is 'EC2Instance', the ProductTypes will only be ['EC2']
     * SavingsPlanType:Compute ProductTypes: ['Fargate', 'EC2', 'Lambda']
     *
     * Compute Criteria:
     *  - Fargate:
     *      `product/servicecode` == 'AmazonECS' && `lineItem/Operation` == 'FargateTask'
     *      || `product/servicecode` == 'AmazonEKS' && `lineItem/Operation` == 'FargatePod'
     * - EC2:
     *      `product/servicecode` == 'AmazonEC2' && `lineItem/Operation` startsWith 'RunInstances'
     * - Lambda:
     *      `product/servicecode` == 'AWSLambda' && lineItem/Operation is not null
     *
     * SageMaker Criteria:
     * - SageMaker:
     *      `product/servicecode` == 'AmazonSageMaker' && `product/productFamily` == 'ML Instance'
     *
     * EC2Instance Criteria:
     * - EC2:
     *      `product/servicecode` == 'AmazonEC2'
     *      && `lineItem/Operation` startsWith 'RunInstances'
     *      && `product/instanceType` startsWith {resource.Ec2InstanceFamily} + '.'
     *      && `product/region` == {resource.Region}
     *
     */
    ProductTypes?: SavingsPlanProductTypes[];
}

type AwsReservationOfferingType = 'All Upfront' | 'Heavy Utilization' | 'Light Utilization' | 'Medium Utilization' | 'No Upfront' | 'Partial Upfront';
type AwsReservationState = 'active' | 'payment-failed' | 'payment-pending' | 'retired' | 'queued' | 'queued-deleted';
interface AwsReservedEc2 extends ReservedEc2 {
    ResourceType: 'EC2 Reserved';
    /**
     * Duration of the reserved instance in seconds
     */
    Duration?: number;
    /**
     * It's the upfront charge
     * From AWS' docs "The purchase price of the Reserved Instance."
     */
    FixedPrice?: number;
    InstanceCount?: number;
    /**
     * The instance type on which the Reserved Instance can be used, e.g., a1.2xlarge, c5.12xlarge
     */
    InstanceType?: { Value: string };
    ProductDescription?: { Value: 'Linux/UNIX' | 'Linux/UNIX (Amazon VPC)' | 'Windows' | 'Windows (Amazon VPC)' };
    /**
     * The offering class of the Reserved Instance.
     */
    OfferingClass?: { Value: 'convertible' | 'standard' };
    /**
     * The offering type of the Reserved Instance.
     */
    OfferingType?: { Value: AwsReservationOfferingType };
    /**
     * A list of recurring charges? I've only ever seen one per instance, but there are none when the OfferingType is "All Upfront"
     */
    RecurringCharges?: { Amount: number; Frequency?: { Value: 'Hourly' } }[];
    /**
     * Just some guid, not an ARN
     */
    ReservedInstancesId?: string;
    /**
     * AZ or Region
     */
    Scope?: { Value: 'Availability Zone' | 'Region' };
    /**
     * The date and time the Reserved Instance started.
     */
    Start?: string;
    /**
     * The state of the Reserved Instance purchase.
     */
    State?: { Value: AwsReservationState };
    /**
     * AWS says "The usage price of the Reserved Instance, per hour." but I've never seen this populated
     */
    UsagePrice?: number;
}

interface AwsReservedRds extends ReservedRds {
    ResourceType: 'RDS Reserved';
    /**
     * Duration of the reserved instance in seconds
     */
    Duration?: number;
    DBInstanceCount?: number;
    /**
     * Like an instance type, e.g., db.t3.micro, db.r5.4xlarge
     */
    DBInstanceClass?: string;
    /**
     * Looks to be the upfront cost of the reservation
     * From AWS' docs "The fixed price charged for this reserved DB instance."
     */
    FixedPrice?: number;
    /**
     * Indicates if the reservation applies to Multi-AZ deployments.
     */
    MultiAZ?: boolean;
    /**
     * The offering type of the Reserved Instance.
     */
    OfferingType?: AwsReservationOfferingType;
    /**
     * Seems to be like, the DB type, e.g., 'postgresql'
     */
    ProductDescription?: string;
    /**
     * A list of recurring charges? I've only ever seen one per instance, but there are none when the OfferingType is "All Upfront"
     */
    RecurringCharges?: { RecurringChargeAmount: number; RecurringChargeFrequency: 'Hourly' }[];
    ReservedDBInstanceArn?: string;
    ReservedDBInstancesOfferingId?: string;
    /**
     * The RI ID
     */
    ReservedDBInstanceId?: string;
    /**
     * The date and time the Reserved Instance started.
     */
    StartTime?: string;
    /**
     * The state of the Reserved Instance purchase.
     */
    State?: AwsReservationState;
    /**
     * AWS says "The usage price of the Reserved Instance, per hour." but I've never seen this populated
     */
    UsagePrice?: number;
}

type DiscountResourceModelType = 'Savings Plan' | 'AWS Reservation';

interface IBaseDiscountResourceModel {
    id: string;
    type: DiscountResourceModelType;
    upfrontCost: number;
    recurringCost: number;
    start?: string;
    end?: string;
    isCustom?: boolean;
    getTotalValue(): number;
    getRecurringAmount(): number;
    getMonthTotal(month: Date): number;
}
abstract class BaseDiscountResourceModel implements IBaseDiscountResourceModel {
    public id: string = '';
    public type: DiscountResourceModelType = 'Savings Plan';
    public upfrontCost: number = 0;
    /**
     * Hourly recurring cost
     */
    public recurringCost: number = 0;
    public start?: string;
    public end?: string;
    public isCustom?: boolean;

    public getPeriodName() {
        const startDt = this.getStartDate();
        const startLbl = !startDt ? null : format(startDt, 'MMM yyyy');
        const endDt = this.getEndDate();
        const endLbl = !endDt ? null : format(endDt, 'yyyy');
        const periodLbl = !startLbl || !endLbl ? 'Unknown Period' : `${startLbl} - ${endLbl}`;
        return periodLbl;
    }

    public constructor(resource: Partial<IBaseDiscountResourceModel>) {
        Object.assign(this, resource);
    }

    public getStartDate() {
        return this.start ? new Date(this.start) : null;
    }
    public getEndDate() {
        return this.end ? new Date(this.end) : null;
    }

    public getYears() {
        return Math.round(differenceInMonths(this.getEndDate()!, this.getStartDate()!) / 12);
    }

    public getRecurringAmount() {
        const hours = this.getAmortDays() * 24;
        return this.recurringCost * hours;
    }

    public getMonthlyRecurring() {
        const totalRecurring = this.getRecurringAmount();
        const months = this.getYears() * 12;
        return months !== 0 ? totalRecurring / months : 0;
    }

    public getPlanDaysForMonth(month: Date) {
        const monthStart = startOfMonth(month);
        const monthEnd = endOfMonth(month);
        const start = this.getStartDate();
        const end = this.getEndDate();
        if (!start || !end || monthEnd < start || monthStart > end) {
            return 0;
        }
        const maxStart = start < monthStart ? monthStart : start;
        const minEnd = end > monthEnd ? monthEnd : end;
        return differenceInDays(minEnd, maxStart);
    }

    public getMonthTotal(month: Date) {
        const days = this.getPlanDaysForMonth(month);
        const amortPortion = this.getAmortDays() === 0 ? 0 : days / this.getAmortDays();
        return this.recurringCost * 24 * days + this.upfrontCost * amortPortion;
    }
    public getMonthTotals(month: Date) {
        const days = this.getPlanDaysForMonth(month);
        const amortPortion = this.getAmortDays() === 0 ? 0 : days / this.getAmortDays();
        return { recurring: this.recurringCost * 24 * days, upfront: this.upfrontCost * amortPortion };
    }

    public getTotalValue() {
        return this.upfrontCost + this.getRecurringAmount();
    }

    public getAmortDays() {
        return !this.start || !this.end ? 0 : differenceInDays(this.getEndDate()!, this.getStartDate()!);
    }
}
type SavingsPlanOfferType = 'ComputeSavingsPlans' | 'EC2InstanceSavingsPlans' | 'SageMakerSavingsPlans';
class AwsSavingsPlanModel extends BaseDiscountResourceModel {
    public planType!: SavingsPlanType;
    public paymentType?: SavingsPlanPaymentOption;
    public name!: string;
    public region?: string;
    public instanceType?: string;
    public arn?: string;

    public static fromResource(resource: Partial<AwsSavingsPlan>) {
        const result = new AwsSavingsPlanModel({
            id: resource.SavingsPlanId ?? '',
            type: 'Savings Plan',
            upfrontCost: parseFloat(resource.UpfrontPaymentAmount ?? '0'),
            recurringCost: parseFloat(resource.RecurringPaymentAmount ?? '0'),
            start: resource.Start ?? '',
            end: resource.End ?? '',
        });
        result.planType = resource.SavingsPlanType?.Value ?? 'Compute';
        result.paymentType = resource.PaymentOption?.Value ?? 'No Upfront';
        result.name = resource.Name ?? '';
        result.region = resource.Region ?? '';
        result.instanceType = resource.Ec2InstanceFamily ?? '';
        result.arn = resource.SavingsPlanArn ?? '';

        return result;
    }

    public getName() {
        return `${this.planType} ${this.getYears()} Year ${this.paymentType}`;
    }

    public isValid() {
        const planTypeValidity =
            (this.planType === 'EC2Instance' && !!this.instanceType && !!this.region && this.region !== 'Global') ||
            this.planType === 'Compute' ||
            this.planType === 'SageMaker';
        const baseValidity = !!this.paymentType;
        return baseValidity && planTypeValidity;
    }

    public getOfferType(): SavingsPlanOfferType {
        return this.planType === 'Compute'
            ? 'ComputeSavingsPlans'
            : this.planType === 'EC2Instance'
            ? 'EC2InstanceSavingsPlans'
            : 'SageMakerSavingsPlans';
    }

    public getConstraintLabels() {
        const region = this.planType === 'EC2Instance' ? this.region : 'Any';
        const instanceType = this.planType === 'EC2Instance' ? this.instanceType : 'Any';
        const services =
            this.planType === 'Compute'
                ? ['EC2', 'Fargate', 'Lambda'].join(', ')
                : this.planType === 'EC2Instance'
                ? 'EC2'
                : this.planType === 'SageMaker'
                ? 'SageMaker'
                : 'Unknown';
        return [
            ['Region', region],
            ['Services', services],
            ['Instance Type', instanceType],
        ];
    }
}
class AwsReservedInstanceModel extends BaseDiscountResourceModel {
    public type: DiscountResourceModelType = 'AWS Reservation';
    public resType!: string;
    public name!: string;
    public paymentType?: AwsReservationOfferingType;
    public constraints?: { type: string; value: string }[];
    public reservationId?: string;
    public instanceCt?: number;
    public region?: string;

    public static fromResource(resource: Partial<BaseResource>) {
        if (resource.ResourceType === 'RDS Reserved') {
            return AwsReservedInstanceModel.fromRdsResource(resource as AwsReservedRds);
        } else if (resource.ResourceType === 'EC2 Reserved') {
            return AwsReservedInstanceModel.fromEc2Resource(resource as AwsReservedEc2);
        } else {
            return new AwsReservedInstanceModel({
                id: resource.Id ?? '',
                type: 'AWS Reservation',
                upfrontCost: 0,
                recurringCost: 0,
            });
        }
    }
    public getName() {
        return `${this.resType} Reservation ${this.paymentType}`;
    }

    private static fromRdsResource(resource: Partial<AwsReservedRds>) {
        const startDate = resource.StartTime ? new Date(resource.StartTime) : null;
        const durationDays = resource.Duration ? Math.round(resource.Duration / (60 * 60 * 24)) : 0;
        const endDate = startDate && resource.Duration ? addDays(startDate, durationDays) : null;
        const end = endDate?.toISOString() ?? '';
        const result = new AwsReservedInstanceModel({
            id: resource.Id ?? '',
            type: 'AWS Reservation',
            upfrontCost: resource.FixedPrice ?? 0,
            recurringCost: resource.RecurringCharges?.find((r) => r.RecurringChargeFrequency === 'Hourly')?.RecurringChargeAmount ?? 0,
            start: resource.StartTime ?? '',
            end,
            isCustom: false,
        });
        result.resType = 'RDS';
        result.region = resource.Region ?? '';
        result.reservationId = resource.ReservedDBInstanceId;
        result.paymentType = resource.OfferingType ?? 'No Upfront';
        result.name = resource.Name ?? '';
        result.constraints = [
            { type: 'Instance', value: resource.DBInstanceClass ?? '' },
            { type: 'Multi-AZ', value: resource.MultiAZ ? 'Yes' : 'No' },
            { type: 'Product', value: resource.ProductDescription ?? '' },
        ];
        result.instanceCt = resource.DBInstanceCount;
        return result;
    }

    private static fromEc2Resource(resource: Partial<AwsReservedEc2>) {
        const startDate = resource.Start ? new Date(resource.Start) : null;
        const durationDays = resource.Duration ? Math.round(resource.Duration / (60 * 60 * 24)) : 0;
        const endDate = startDate && resource.Duration ? addDays(startDate, durationDays) : null;
        const end = endDate?.toISOString() ?? '';
        const result = new AwsReservedInstanceModel({
            id: resource.Id ?? '',
            type: 'AWS Reservation',
            upfrontCost: resource.FixedPrice ?? 0,
            recurringCost: resource.RecurringCharges?.find((r) => r.Frequency?.Value === 'Hourly')?.Amount ?? 0,
            start: resource.Start ?? '',
            end,
            isCustom: false,
        });
        result.region = resource.Region ?? '';
        result.resType = 'EC2';
        result.reservationId = resource.ReservedInstancesId;
        result.paymentType = resource.OfferingType?.Value ?? 'No Upfront';
        result.name = resource.Name ?? '';
        result.constraints = [
            { type: 'Instance', value: resource.InstanceType?.Value ?? '' },
            { type: 'Scope', value: resource.Scope?.Value ?? '' },
            { type: 'Product', value: resource.ProductDescription?.Value ?? '' },
        ];
        result.instanceCt = resource.InstanceCount;
        return result;
    }
}

type DiscountResourceModel = AwsSavingsPlanModel | AwsReservedInstanceModel;

type DiscountStats = { arn: string; recurring: number; upfront: number };

class DiscountModelDataService {
    private mapperLookup = new Map<
        string,
        (new (resource: IBaseDiscountResourceModel) => DiscountResourceModel) & { fromResource: (resource: BaseResource) => DiscountResourceModel }
    >([
        ['Savings Plan', AwsSavingsPlanModel],
        ['RDS Reserved', AwsReservedInstanceModel],
        ['EC2 Reserved', AwsReservedInstanceModel],
    ]);

    public constructor(private readonly invoiceApi: InvoiceApiService) {}

    public async getDiscounts(): Promise<DiscountResourceModel[]> {
        const results = await queryBuilder<BaseResource>()
            .where((b) => b.model.ResourceType!.eq(['Savings Plan', 'RDS Reserved', 'EC2 Reserved']))
            .take(1000)
            .execute((q) => postResourcesQuery(q));

        return results?.Results?.map((r) => this.createModel(r)!).filter((r) => r !== null) ?? [];
    }

    public createModel(resource: BaseResource | IBaseDiscountResourceModel) {
        if ('ResourceType' in resource) {
            const mapper = this.mapperLookup.get(resource.ResourceType as string);
            return mapper?.fromResource(resource) ?? null;
        } else {
            const mapper = this.mapperLookup.get(resource.type as string);
            return !mapper ? null : new mapper(resource as IBaseDiscountResourceModel);
        }
    }

    public async getDiscountStats(month: Date) {
        const discountResults = await queryBuilder<IDailyRollup>()
            .where((b) =>
                b.or(
                    b.model['lineItem/LineItemType']!.eq(['SavingsPlanRecurringFee', 'SavingsPlanUpfrontFee', 'RIFee']),
                    b.and(b.model['lineItem/LineItemType']!.eq('Fee'), b.model['reservation/ReservationARN']!.isNotNull())
                )
            )
            .select((b) => ({
                spArn: b.values(b.model['savingsPlan/SavingsPlanARN']!, undefined, ValuesGroupOtherText),
                riArn: b.values(b.model['reservation/ReservationARN']!, undefined, ValuesGroupOtherText),
                spUpfrontAmort: b.sum(b.model['savingsPlan/AmortizedUpfrontCommitmentForBillingPeriod']),
                spRecurring: b.aggIf(b.model['lineItem/LineItemType']!.eq('SavingsPlanRecurringFee'), b.sum(b.model['lineItem/UnblendedCost']!)),
                spUpfront: b.aggIf(b.model['lineItem/LineItemType']!.eq('SavingsPlanUpfrontFee'), b.sum(b.model['lineItem/UnblendedCost']!)),
            }))
            .execute((q) => this.invoiceApi.query(q, { from: month, to: month }, false));

        const discountStats = new Map<string, DiscountStats>();
        discountResults?.Results?.reduce((result, item) => {
            let stats: null | DiscountStats = null;
            if (item.spArn !== ValuesGroupOtherText) {
                stats = {
                    arn: item.spArn,
                    recurring: item.spRecurring ?? 0,
                    upfront: item.spUpfrontAmort ?? 0,
                };
            } else if (item.riArn !== ValuesGroupOtherText) {
                stats = {
                    arn: item.riArn,
                    recurring: 0,
                    upfront: 0,
                };
            }
            return !stats ? result : result.set(stats.arn, stats);
        }, discountStats);

        return discountStats;
    }
}

interface DiscountModelSelection {
    type: DiscountResourceModelType;
    typeSelected: boolean;
    selectedItems: IBaseDiscountResourceModel[];
}
interface SourcePresentationOptions extends BasePresentationOptions {
    /**
     * Discount model selections
     */
    selections?: DiscountModelSelection[];
}
interface TargetPresentationOptions extends BasePresentationOptions {
    allEligible: boolean;
    amortizeUpfrontFees: boolean;
    balanceUpfrontFees: boolean;
    cancelOriginalUpfrontFees: boolean;
}

class DiscountEditorModel extends BaseAllocationRuleModel<SourcePresentationOptions, TargetPresentationOptions> {
    public showingDiscountPicker = new EventEmitter<boolean>(false);
    public selectedResourcesChanged = new EventEmitter<boolean>(false);
    public linkedSourcesResourceDictionary = new Map<string, BaseResource[]>();

    public loading = new EventEmitter(true);
    public optionsChanged = EventEmitter.empty();
    public discountModelOptions: IBaseDiscountResourceModel[] = [];

    private discountModelsById = new Map<string, IBaseDiscountResourceModel>();
    private readonly discountDataService: DiscountModelDataService;
    private discountStats?: ReturnType<DiscountModelDataService['getDiscountStats']>;

    public constructor(ruleEditor: AllocationRuleEditor) {
        super(ruleEditor);
        this.discountDataService = new DiscountModelDataService(ruleEditor.showbackSvc.invoiceApi);
    }

    public get month() {
        return this.ruleEditor.month;
    }

    public init() {
        this.load();
        return this;
    }

    private async load() {
        try {
            this.loading.emit(true);

            const metadataBasedDiscounts = await this.discountDataService.getDiscounts();
            const loadedDiscountLookup = metadataBasedDiscounts.reduce((r, item) => r.set(item.id, item), new Map<string, DiscountResourceModel>());
            const rawSelectedDiscounts = this.getSourceDefPresentation().selections?.flatMap((s) => s.selectedItems) ?? [];
            const previouslySelectedDiscounts = rawSelectedDiscounts
                .map((r) => loadedDiscountLookup.get(r.id) ?? this.discountDataService.createModel(r)!)
                .filter((r) => !!r && !loadedDiscountLookup.has(r.id));

            this.discountModelOptions = metadataBasedDiscounts.concat(...previouslySelectedDiscounts);
            this.discountModelsById = new Map(this.discountModelOptions.map((r) => [r.id, r]));
            this.getSourceDefPresentation().selections = (this.getSourceDefPresentation().selections ?? []).map((s) => ({
                type: s.type,
                typeSelected: s.typeSelected,
                selectedItems: s.selectedItems.map((r) => this.discountModelsById.get(r.id)!),
            }));
        } finally {
            this.loading.emit(false);
        }
    }

    public getTargetOptions() {
        return this.getTargetDefPresentation();
    }

    public updateTargetOptions(options: Partial<TargetPresentationOptions>) {
        if (options.allEligible === true) {
            options.amortizeUpfrontFees = true;
            options.balanceUpfrontFees = true;
            options.cancelOriginalUpfrontFees = true;
        }
        if (options.allEligible === false) {
            options.amortizeUpfrontFees = false;
        }
        if (options.amortizeUpfrontFees === false) {
            options.balanceUpfrontFees = false;
            options.cancelOriginalUpfrontFees = false;
        }
        Object.assign(this.getTargetDefPresentation(), options);
        this.optionsChanged.emit();
        this.reapplyDiscountModels();
    }

    public getAvailableOptions() {
        return this.discountModelOptions;
    }

    public getSelectedOptions() {
        return this.getSourceDefPresentation().selections ?? [];
    }

    private reapplyDiscountModels() {
        this.applyDiscountModels(this.getSourceDefPresentation().selections ?? []);
    }

    public applyCustomModelChanges(added: IBaseDiscountResourceModel[], removed: IBaseDiscountResourceModel[]) {
        for (const item of added) {
            this.discountModelsById.set(item.id, item);
            this.discountModelOptions.push(item);
        }
        for (const item of removed) {
            this.discountModelsById.delete(item.id);
            const index = this.discountModelOptions.findIndex((r) => r.id === item.id);
            if (index >= 0) {
                this.discountModelOptions.splice(index, 1);
            }
        }
    }

    public applyDiscountModels(selections: DiscountModelSelection[]) {
        this.clearSources();
        this.clearTargets();
        this.getSourceDefPresentation().selections = selections;

        for (const selection of selections) {
            const reallocations = this.createReallocations(selection);

            for (const { target, source } of reallocations) {
                if (source) {
                    this.addSource(source);
                }
                if (target) {
                    this.addTarget(target);
                }
            }
        }

        this.sourcesChanged.emit();
        this.targetsChanged.emit();
    }

    public getScopeFilters() {
        const inclusionRules = (this.getDisbursementScope().InclusionRules ??= []);
        let namedFilter = inclusionRules[0];
        if (!namedFilter) {
            inclusionRules?.push((namedFilter = {}));
        }
        let filter = namedFilter.Filter as QueryExpr | undefined;
        if (!filter) {
            namedFilter.Filter = filter = { Operation: 'and', Operands: [] };
        }
        let result: null | IQueryExpr[] = null;
        if ('Operation' in filter && filter.Operation?.toLowerCase() === 'and') {
            result = filter.Operands ??= [];
        } else {
            namedFilter.Filter = { Operation: 'and', Operands: (result = [filter]) };
        }
        return result as QueryExpr[];
    }

    public setScopeFilters = (filters: QueryExpr[]) => {
        const currentFilters = this.getScopeFilters();
        currentFilters.splice(0, Infinity, ...filters);
        this.sourcesChanged.emit();
    };

    private createReallocations(selection: DiscountModelSelection) {
        return selection.type === 'Savings Plan' ? this.createSavingsPlanReallocation(selection) : selection.type === 'AWS Reservation' ? [] : [];
    }

    private *createSavingsPlanReallocation(selection: DiscountModelSelection) {
        const savingsPlans = (
            !selection.typeSelected ? selection.selectedItems : this.discountModelOptions.filter((o) => o.type === 'Savings Plan')
        ) as AwsSavingsPlanModel[];

        const plansByType = savingsPlans.reduce((result, plan) => {
            if (!result[plan.planType]) {
                result[plan.planType] = [];
            }
            result[plan.planType].push(plan);
            return result;
        }, {} as Record<SavingsPlanType, AwsSavingsPlanModel[]>);

        const allEligible = this.getTargetDefPresentation().allEligible;
        const amortUpfront = this.getTargetDefPresentation().amortizeUpfrontFees;

        for (const [planType, planSelection] of Object.entries(plansByType)) {
            for (const source of this.createAwsSpSource(planSelection, selection.typeSelected, amortUpfront)) {
                const target = !allEligible ? null : this.createAwsSpTargetBySource(planSelection, source.Id, planType);
                yield { source, target };
            }
        }

        if (allEligible && amortUpfront) {
            yield this.createSpUpfrontFeeAmortization(savingsPlans, selection.typeSelected);
        }

        if (!allEligible) {
            yield { source: null, target: this.createAwsSpTargetAny() };
        }
    }

    private *createAwsSpSource(savingsPlans: AwsSavingsPlanModel[], all: boolean, amortUpfront: boolean) {
        const { builder: xb } = exprBuilder<IDailyRollup>();
        for (const expr of this.groupAwsSpSourceFilter(savingsPlans, all)) {
            yield {
                SourceType: 'LineItems',
                Filters: {
                    InclusionRules: [
                        {
                            Filter: expr,
                        },
                    ],
                },
                PreTransforms: !amortUpfront
                    ? null
                    : [
                          {
                              FieldName: 'AdjustedAmortizedCost',
                              Transformation: xb.resolve(
                                  xb.model.AdjustedAmortizedCost!.plus(xb.model['savingsPlan/AmortizedUpfrontCommitmentForBillingPeriod']!)
                              ),
                              ApplyTo: xb.resolve(xb.model['lineItem/LineItemType']!.eq('SavingsPlanRecurringFee')),
                          },
                      ],
                Name: `${savingsPlans.length} Savings Plans`,
                Id: sortedObjectId(expr),
            } as FundSource;
        }
    }

    private *groupAwsSpSourceFilter(savingsPlans: AwsSavingsPlanModel[], all: boolean) {
        const { builder: xb } = exprBuilder<IDailyRollup>();
        const spGroups = savingsPlans.reduce((result, sp) => {
            const exprs = this.createAwsSpSourceCriteria(sp);
            const key = JSON.stringify(exprs);
            if (!result[key]) {
                result[key] = { exprs, plans: [] };
            }
            result[key].plans.push(sp);
            return result;
        }, {} as Record<string, { exprs: IFluentOperators<boolean>[]; plans: AwsSavingsPlanModel[] }>);

        for (const { exprs, plans } of Object.values(spGroups)) {
            const result = exprs;
            if (!all) {
                const planIds = plans.map((sp) => sp.arn!).filter((id) => !!id);
                if (planIds.length) {
                    result.push(xb.model['savingsPlan/SavingsPlanARN']!.eq(planIds));
                } else {
                    continue;
                }
            }
            yield xb.resolve(xb.and(...result));
        }
    }

    private createAwsSpSourceCriteria(savingsPlan: AwsSavingsPlanModel) {
        const { builder: xb } = exprBuilder<IDailyRollup>();

        const result = [xb.model['savingsPlan/OfferingType']!.eq(savingsPlan.getOfferType())];

        if (savingsPlan.planType === 'EC2Instance') {
            result.push(
                xb.or(
                    xb.and(
                        xb.model['product/instanceType']!.eq(savingsPlan?.instanceType!),
                        xb.model['lineItem/LineItemType']!.eq('SavingsPlanRecurringFee')
                    ),
                    xb.and(
                        xb.model['product/instanceType']!.startsWithX(savingsPlan?.instanceType! + '.'),
                        xb.model['lineItem/LineItemType']!.eq('SavingsPlanNegation')
                    )
                ),
                xb.model['product/region']!.eq(savingsPlan?.region!)
            );
        } else if (savingsPlan.planType === 'Compute') {
            result.push(xb.model['lineItem/LineItemType']!.eq(['SavingsPlanRecurringFee', 'SavingsPlanNegation']));
        } else if (savingsPlan.planType === 'SageMaker') {
            result.push(xb.model['lineItem/LineItemType']!.eq(['SavingsPlanRecurringFee', 'SavingsPlanNegation']));
        }

        return result;
    }

    private createAwsSpTargetAny() {
        return {
            TargetType: 'Existing',
            DisbursementMethod: 'SplitByUsage',
            Name: 'Custom SP Target',
            Filters: {
                InclusionRules: [], // filtering is handled by scope at disb-level
            },
        } as DisbursementTarget;
    }

    private createAwsSpTargetBySource(savingsPlans: AwsSavingsPlanModel[], sourceId?: string | null, planType?: string) {
        const { builder: xb } = exprBuilder<IDailyRollup>();

        const targetCriteria = savingsPlans.reduce((result, sp) => {
            const expr = xb.resolve(xb.and(...this.createAwsSpTargetCriteria(sp)));
            result.set(sortedObjectId(expr), expr);
            return result;
        }, new Map<string, QueryExpr>());

        return {
            SourceAffinity: sourceId ? [sourceId] : null,
            TargetType: 'Existing',
            DisbursementMethod: 'SplitByUsage',
            Name: `${planType} SP Target`,
            UsageStatsExpr: xb.resolve(
                xb
                    .coalesce(xb.model['reservation/OnDemandCost']!, xb.param(0.0))
                    .plus(xb.coalesce(xb.model['lineItem/UnblendedCost']!, xb.param(0.0)))
            ),
            TargetExistingFilter: {
                InclusionRules: [
                    {
                        Filter: cleanBoolExpr({ Operation: 'or', Operands: [...targetCriteria.values()] }),
                    },
                ],
            },
        } as DisbursementTarget;
    }

    private createSpUpfrontFeeAmortization(savingsPlans: AwsSavingsPlanModel[], all: boolean): { source?: FundSource; target?: DisbursementTarget } {
        const { builder: xb } = exprBuilder<IDailyRollup>();
        const planIds = savingsPlans.map((sp) => sp.arn!).filter((id) => !!id);
        if (!all || !planIds.length) {
            return {};
        }
        const filters = [xb.model['lineItem/LineItemType']!.eq('SavingsPlanUpfrontFee')];
        if (!all) {
            filters.push(xb.model['savingsPlan/SavingsPlanARN']!.eq(planIds));
        }
        const sourceId = sortedObjectId(filters);

        return {
            source: {
                Name: 'SP Upfront Fee Amortization Source',
                Filters: { InclusionRules: [{ Filter: xb.resolve(filters.length > 1 ? xb.and(...filters) : filters[0]) }] },
                PreTransforms: [
                    {
                        FieldName: 'AdjustedAmortizedCost',
                        Transformation: { Value: 0.0 },
                    },
                ],
                SourceType: 'LineItems',
                Id: sourceId,
            },
            target: {
                SourceAffinity: [sourceId],
                TargetType: 'Existing',
                DisbursementMethod: 'SplitByUsage',
                Name: 'SP Upfront Fee Amortization Target',
                TargetExistingFilter: { InclusionRules: [{ Filter: xb.resolve(xb.model['lineItem/LineItemType']!.isNull()) }] }, // match nothing, preTransform does the work
            },
        };
    }

    private createAwsSpTargetCriteria(savingsPlan: AwsSavingsPlanModel) {
        const { builder: xb } = exprBuilder<IDailyRollup>();

        const result = [xb.model['lineItem/LineItemType']!.eq(['Usage', 'SavingsPlanCoveredUsage', 'DiscountedUsage'])];

        if (savingsPlan.planType === 'EC2Instance') {
            result.push(
                xb.model['product/servicecode']!.eq('AmazonEC2'),
                xb.model['lineItem/Operation']!.startsWithX('RunInstances'),
                xb.model['product/region']!.eq(savingsPlan.region ?? ''),
                xb.model['product/instanceType']!.startsWithX(savingsPlan.instanceType + '.')
            );
        } else if (savingsPlan.planType === 'Compute') {
            result.push(
                xb.or(
                    xb.and(xb.model['product/servicecode']!.eq('AmazonECS'), xb.model['lineItem/Operation']!.eq('FargateTask')),
                    xb.and(xb.model['product/servicecode']!.eq('AmazonEKS'), xb.model['lineItem/Operation']!.eq('FargatePod')),
                    xb.and(xb.model['product/servicecode']!.eq('AmazonEC2'), xb.model['lineItem/Operation']!.startsWithX('RunInstances')),
                    xb.and(xb.model['product/servicecode']!.eq('AWSLambda'), xb.model['lineItem/Operation']!.isNotNull())
                )
            );
        } else if (savingsPlan.planType === 'SageMaker') {
            result.push(xb.model['product/servicecode']!.eq('AmazonSageMaker'), xb.model['product/productFamily']!.eq('ML Instance'));
        }

        return result;
    }

    public removeHandler = (linkedFundSourceId: string) => {
        this.linkedSourcesResourceDictionary.delete(linkedFundSourceId);
    };

    public getDiscountGridItems() {
        const selections = this.getSourceDefPresentation().selections;
        const types: DiscountResourceModelType[] = ['Savings Plan', 'AWS Reservation'];
        const typesDefaulted = types.map((t) => selections?.find((s) => s.type === t) ?? { type: t, typeSelected: false, selectedItems: [] });

        return typesDefaulted?.map((s) => {
            return {
                getName: () => `${typeLabels.get(s.type)} (${s.typeSelected ? 'All' : s.selectedItems.length})`,
                getAmount: () => {
                    return this.discountModelOptions.reduce((total, item) => {
                        if (item.type === s.type && (s.typeSelected || s.selectedItems.some((i) => i.id === item.id))) {
                            total += item.getMonthTotal(this.month);
                        }
                        return total;
                    }, 0);
                },
                onChanged: this.sourcesChanged,
                remove: () => {
                    const selections = this.getSourceDefPresentation().selections;
                    const index = selections?.findIndex((i) => i.type === s.type);
                    if (index !== undefined && index >= 0) {
                        selections?.splice(index, 1);
                    }
                    this.reapplyDiscountModels();
                },
            } as MiniDataGridItem;
        });
    }

    public async getDiscountStats() {
        if (!this.discountStats) {
            this.discountStats = this.discountDataService.getDiscountStats(this.ruleEditor.month);
        }
        return await this.discountStats;
    }

    public closeAddSavingsPlanDrawer = () => this.showingDiscountPicker.emit(false);
    public openAddSavingsPlanDrawer = () => this.showingDiscountPicker.emit(true);
}

function GetEC2ReservedArn(resource: BaseResource) {
    return ('arn:aws:ec2:' + resource.Region + ':' + resource.Account + ':reserved-instances/' + resource.ReservedInstancesId) as string;
}

const typeLabels = new Map<DiscountResourceModelType, string>([
    ['Savings Plan', 'AWS Savings Plans'],
    ['AWS Reservation', 'AWS Reservations'],
]);

function createDiscountPickerModels(
    options: IBaseDiscountResourceModel[],
    selections: DiscountModelSelection[],
    stats: Promise<Map<string, DiscountStats>>,
    month: Date
) {
    const groups: {
        type: DiscountResourceModelType;
        canSelectAll: boolean;
        itemFilter: (option: IBaseDiscountResourceModel) => boolean;
    }[] = [
        {
            type: 'Savings Plan',
            canSelectAll: true,
            itemFilter: (option) => option.type === 'Savings Plan',
        },
        {
            type: 'AWS Reservation',
            canSelectAll: true,
            itemFilter: (option) => option.type === 'AWS Reservation',
        },
    ];
    const selectionsLookup = selections.reduce((result, selection) => {
        const selectedItems = selection.selectedItems.reduce((result, item) => result.add(item.id), new Set<string>());
        return result.set(selection.type, { typeSelected: selection.typeSelected, itemSelected: (id: string) => selectedItems.has(id) });
    }, new Map<string, { typeSelected: boolean; itemSelected: (id: string) => boolean }>());

    const addedOptions: IBaseDiscountResourceModel[] = [];
    const removedOptions: IBaseDiscountResourceModel[] = [];
    const monthStats = new EventEmitter<Map<string, DiscountStats> | undefined>(undefined);
    stats.then(monthStats.emit);

    const optionGroups = groups.map((group) => {
        const groupChanged = EventEmitter.empty();
        const selection = selectionsLookup.get(group.type) ?? { typeSelected: false, itemSelected: () => false };

        const typeSelected = selection.typeSelected;
        const createOption = (item: IBaseDiscountResourceModel) => {
            let selected = selection.itemSelected(item.id);
            const selectionChanged = EventEmitter.empty();
            const option = {
                item,
                isSelected: () => optionGroup.typeSelected || selected,
                selectionChanged,
                select: (value: boolean, emit: boolean = true) => {
                    selected = value;
                    if (emit) {
                        selectionChanged.emit();
                        groupChanged.emit();
                    }
                },
                toggle: () => {
                    option.select(!selected);
                },
            };
            return option;
        };

        const groupOptions = options.filter(group.itemFilter).map(createOption);
        type GroupOptionModel = ReturnType<typeof createOption>;
        const optionGroup = {
            name: typeLabels.get(group.type),
            type: group.type,
            groupChanged,
            typeSelected,
            monthStats,
            month,
            selectType: (selected: boolean) => {
                if (group.canSelectAll) {
                    optionGroup.typeSelected = selected;
                    groupChanged.emit();
                }
            },
            toggleTypeSelected: () => {
                if (group.canSelectAll) {
                    optionGroup.typeSelected = !optionGroup.typeSelected;
                    optionGroup.selectAll(false);
                    groupChanged.emit();
                }
            },
            selectAll: (selected: boolean) => {
                groupOptions.forEach((o) => o.select(selected, false));
                groupChanged.emit();
            },
            toggleSelectAll: () => {
                const someSelected = optionGroup.someSelected();
                optionGroup.selectAll(!someSelected);
            },
            someSelected: () => groupOptions.some((o) => o.isSelected()),
            allSelected: () => groupOptions.every((o) => o.isSelected()),
            canSelectAll: group.canSelectAll,
            options: groupOptions as GroupOptionModel[],
            addCustom: (item: IBaseDiscountResourceModel) => {
                const option = createOption(item);
                option.select(true);
                groupOptions.push(option);
                groupChanged.emit();
                addedOptions.push(item);
            },
            getStats: () => {
                return groupOptions.reduce(
                    (result, o) => {
                        result.available++;
                        result.valueTotal += o.item.getTotalValue();
                        if (o.isSelected()) {
                            result.valueSelected += o.item.getTotalValue();
                            result.selected++;
                            result.upfrontSelected += o.item.upfrontCost;
                            result.recurringSelected += o.item.getRecurringAmount();
                        }
                        return result;
                    },
                    { selected: 0, upfrontSelected: 0, recurringSelected: 0, valueSelected: 0, valueTotal: 0, available: 0 }
                );
            },
            removeCustom: (item: IBaseDiscountResourceModel) => {
                if (item.isCustom) {
                    const index = groupOptions.findIndex((o) => o.item.id === item.id);
                    if (index >= 0) {
                        groupOptions.splice(index, 1);
                        groupChanged.emit();
                        removedOptions.push(item);
                    }
                }
            },
        };
        return optionGroup;
    }, []);

    const getSelections = () => {
        return optionGroups.map((g) => {
            return {
                type: g.type,
                typeSelected: g.typeSelected,
                selectedItems: g.typeSelected ? [] : g.options.filter((o) => o.isSelected()).map((o) => o.item),
            };
        });
    };

    const groupsChanged = EventEmitter.empty();
    optionGroups.forEach((g) => g.groupChanged.listen(groupsChanged.emit));

    return { optionGroups, addedOptions, removedOptions, getSelections, groupsChanged };
}
type DiscountOptionGroupModel = ReturnType<typeof createDiscountPickerModels>['optionGroups'][number];
type DiscountOptionGroupItemModel<T = IBaseDiscountResourceModel> = Omit<DiscountOptionGroupModel['options'][number], 'item'> & { item: T };
export function DiscountResourcesDrawer({ model }: { model: DiscountEditorModel }) {
    const fmtSvc = useDi(FormatService);
    const sourcePicker = useSidePanelOpener(true);
    useEvent(sourcePicker.evt, model.showingDiscountPicker.emit);
    const { optionGroups, addedOptions, removedOptions, getSelections, groupsChanged } = useMemo(() => {
        const options = model.getAvailableOptions();
        const selections = model.getSelectedOptions();

        return createDiscountPickerModels(options, selections, model.getDiscountStats(), model.month);
    }, []);

    useEvent(groupsChanged);

    const saveSources = async () => {
        model.applyDiscountModels(getSelections());
        model.applyCustomModelChanges(addedOptions, removedOptions);
        sourcePicker.close();
    };

    const [openGroup, setOpenGroup] = useState<string>();
    const toggleGroup = useCallback(
        (group: DiscountOptionGroupModel) => {
            setOpenGroup(openGroup === group.name ? '' : group.name);
        },
        [openGroup, setOpenGroup]
    );

    return (
        <SidePanel
            opener={sourcePicker}
            size={950}
            title="Select Discounts"
            toolbar={
                <>
                    <Button variant="subtle" onClick={sourcePicker.close}>
                        Cancel
                    </Button>
                    <Button disabled={!saveSources} onClick={saveSources}>
                        Apply Selections
                    </Button>
                </>
            }
        >
            {() => (
                <Stack spacing={8} sx={{ height: '100%' }}>
                    {optionGroups.map((g) => (
                        <DiscountGroupItems key={g.name} expanded={openGroup ? g.name === openGroup : null} group={g} onToggle={toggleGroup} />
                    ))}
                </Stack>
            )}
        </SidePanel>
    );
}

const GroupExpandedCtx = createContext({ expanded: false });
function DiscountGroupItems({
    group,
    expanded,
    onToggle,
}: {
    group: DiscountOptionGroupModel;
    expanded: boolean | null;
    onToggle: (group: DiscountOptionGroupModel) => void;
}) {
    const fmtSvc = useDi(FormatService);
    const theme = useMantineTheme();

    const toggle = useCallback(() => onToggle(group), [onToggle, group]);
    const cardStyle = useMemo(
        () =>
            ({
                flex: expanded ? 1 : 0,
                overflow: 'hidden',
                display: 'flex',
                minHeight: expanded === false ? 32 : 50,
                flexDirection: 'column',
                transition: 'all 300ms',
                background: theme.colors.gray[0],
                borderColor: theme.colors.gray[3],
            } as Sx),
        [expanded]
    );
    const { selected, available, valueTotal, valueSelected } = group.getStats();

    return (
        <Card p={0} radius="md" withBorder sx={{ ...cardStyle }}>
            <GroupItemHeader mode={expanded === false ? 'collapsed' : expanded ? 'expanded' : 'default'} onClick={toggle}>
                <ActionIcon variant="transparent">{expanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}</ActionIcon>
                <Box>
                    <Text size="sm" sx={{ flex: 1 }}>
                        {group.name}
                    </Text>
                    <Tooltip position="bottom" withinPortal label={`Discount plan commitment: Selected / Available`}>
                        <Text size="xs" color="dimmed" sx={{ width: 'max-content', height: expanded === false ? 0 : 20 }}>
                            Total Commitment{' '}
                            {`${fmtSvc.formatMoneyNonZeroTwoDecimals(valueSelected)} / ${fmtSvc.formatMoneyNonZeroTwoDecimals(valueTotal)}`}
                        </Text>
                    </Tooltip>
                </Box>
                <Tooltip position="bottom" withinPortal label={`# Selected / Available`}>
                    <Badge variant={(selected === available && available > 0) || group.typeSelected ? 'filled' : selected ? 'outline' : 'light'}>
                        {group.typeSelected ? 'All' : `${fmtSvc.formatInt0Dec(selected)} / ${fmtSvc.formatInt0Dec(available)}`}
                    </Badge>
                </Tooltip>
            </GroupItemHeader>
            <Divider />
            <GroupExpandedCtx.Provider value={{ expanded: expanded === true }}>
                <DiscountGroupRenderer group={group} />
            </GroupExpandedCtx.Provider>
        </Card>
    );
}
const GroupItemHeader = styled.div<{ mode: 'expanded' | 'collapsed' | 'default' }>`
    background: ${(p) => (p.mode === 'expanded' ? p.theme.colors.primary[2] : p.theme.colors.gray[0])};
    &:hover {
        background: ${(p) => (p.mode === 'expanded' ? p.theme.colors.primary[1] : p.theme.colors.primary[2])};
    }
    align-items: center;
    padding: 0 10px;
    display: grid;
    grid-template-columns: 20px 1fr min-content;
    gap: ${(p) => p.theme.spacing.sm}px;
    min-height: ${(p) => (p.mode === 'collapsed' ? 32 : 50)}px;
    cursor: pointer;
`;

function DiscountGroupRenderer({ group }: { group: DiscountOptionGroupModel }) {
    return (
        <>
            {group.type === 'Savings Plan' ? (
                <AwsSpDiscountGroup group={group} />
            ) : group.type === 'AWS Reservation' ? (
                <AwsReservationDiscountGroup group={group} />
            ) : null}
        </>
    );
}

function AwsSpDiscountGroup({ group }: { group: DiscountOptionGroupModel }) {
    return (
        <DiscountGroupLayout group={group}>
            {{
                header: (
                    <AwsDiscountLayout>
                        <Text size="xs">Savings Plan</Text>
                        <Text size="xs" align="center">
                            Period
                        </Text>
                        <Text size="xs" align="right">
                            Upfront
                        </Text>
                        <Text size="xs" align="right">
                            Recurring/mo
                        </Text>
                        <Text size="xs" align="right">
                            Commitment
                        </Text>
                    </AwsDiscountLayout>
                ),
                Details: AwsSpDiscountItem,
                footer: (
                    <TypeSelector disabled={!group.canSelectAll} onClick={group.toggleTypeSelected}>
                        <Switch readOnly disabled={!group.canSelectAll} checked={group.typeSelected} />
                        <Text size="sm" color={!group.canSelectAll ? 'dimmed' : undefined}>
                            Select all Savings Plans going forward
                        </Text>
                    </TypeSelector>
                ),
            }}
        </DiscountGroupLayout>
    );
}

function AwsSpDiscountItem({ discount, group }: { discount: AwsSavingsPlanModel; group: DiscountOptionGroupModel }) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const monthStats = useEventValue(group.monthStats);
    const statsLoading = monthStats === undefined;
    const stats = monthStats?.get(discount.arn ?? '');

    return (
        <AwsDiscountLayout>
            <Text size="sm">
                {discount.getName()}
                {discount.isCustom ? null : (
                    <CopyButton value={discount.arn ?? ''}>
                        {({ copy, copied }) => (
                            <Tooltip label="Copy ARN">
                                <ActionIcon
                                    sx={{ display: 'inline-block' }}
                                    variant="transparent"
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                        e.stopPropagation();
                                        copy();
                                    }}
                                >
                                    {copied ? <Check size={16} /> : <Copy size={16} />}
                                </ActionIcon>
                            </Tooltip>
                        )}
                    </CopyButton>
                )}
            </Text>
            <Text size="sm" align="center">
                {discount.getPeriodName()}
            </Text>
            <Text size="sm" align="right">
                {fmtSvc.formatMoneyNonZeroTwoDecimals(discount.upfrontCost)}
            </Text>
            <Text size="sm" align="right">
                {fmtSvc.formatMoneyNonZeroTwoDecimals(discount.getMonthlyRecurring())}
            </Text>
            <Text size="sm" align="right">
                {fmtSvc.formatMoneyNonZeroTwoDecimals(discount.getTotalValue())}
            </Text>
            <Group position="left" noWrap sx={{ gridColumnEnd: 'span 2' }}>
                {discount.getConstraintLabels().map(([label, value], i) => (
                    <Group key={i} spacing={4}>
                        <Text size="xs" color="dimmed">
                            {label}:
                        </Text>
                        <Text weight="bold" size="xs">
                            {value}
                        </Text>
                    </Group>
                ))}
            </Group>
            {discount.isCustom ? (
                <Text color="dimmed" size="xs" sx={{ gridColumnEnd: 'span 3' }}>
                    Custom Savings Plan {discount.name}
                </Text>
            ) : (
                <Group noWrap spacing={8} sx={{ gridColumnEnd: 'span 3' }}>
                    <Text size="xs" italic color="dimmed">
                        {fmtSvc.formatMonth(group.month)} {statsLoading ? 'Loading...' : null}
                    </Text>
                    {statsLoading ? null : (
                        <>
                            <Group spacing={4}>
                                <Text size="xs" color="dimmed">
                                    Upfront amortized:
                                </Text>
                                <Text weight="bold" size="xs">
                                    {stats?.upfront ? fmtSvc.formatMoneyNonZeroTwoDecimals(stats?.upfront ?? 0) : <>&mdash;</>}
                                </Text>
                            </Group>
                            <Group spacing={4}>
                                <Text size="xs" color="dimmed">
                                    Recurring:
                                </Text>
                                <Text weight="bold" size="xs">
                                    {stats?.recurring ? fmtSvc.formatMoneyNonZeroTwoDecimals(stats?.recurring ?? 0) : <>&mdash;</>}
                                </Text>
                            </Group>
                        </>
                    )}
                </Group>
            )}
        </AwsDiscountLayout>
    );
}

function AwsReservationDiscountGroup({ group }: { group: DiscountOptionGroupModel }) {
    return (
        <DiscountGroupLayout group={group}>
            {{
                header: (
                    <AwsDiscountLayout>
                        <Text size="xs">Reservation</Text>
                        <Text size="xs" align="center">
                            Period
                        </Text>
                        <Text size="xs" align="right">
                            Upfront
                        </Text>
                        <Text size="xs" align="right">
                            Recurring/mo
                        </Text>
                        <Text size="xs" align="right">
                            Commitment
                        </Text>
                    </AwsDiscountLayout>
                ),
                Details: AwsReservationDiscountItem,
                footer: (
                    <TypeSelector disabled={!group.canSelectAll} onClick={group.toggleTypeSelected}>
                        <Switch readOnly disabled={!group.canSelectAll} checked={group.typeSelected} />
                        <Text size="sm" color={!group.canSelectAll ? 'dimmed' : undefined}>
                            Select all Reservations going forward
                        </Text>
                    </TypeSelector>
                ),
            }}
        </DiscountGroupLayout>
    );
}
function AwsReservationDiscountItem({ discount, group }: { discount: AwsReservedInstanceModel; group: DiscountOptionGroupModel }) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const stats = discount.getMonthTotals(group.month);

    return (
        <AwsDiscountLayout>
            <Text size="sm">
                {discount.getName()}
                {discount.isCustom ? null : <></>}
            </Text>
            <Text size="sm" align="center">
                {discount.getPeriodName()}
            </Text>
            <Text size="sm" align="right">
                {fmtSvc.formatMoneyNonZeroTwoDecimals(discount.upfrontCost)}
            </Text>
            <Text size="sm" align="right">
                {fmtSvc.formatMoneyNonZeroTwoDecimals(discount.getMonthlyRecurring())}
            </Text>
            <Text size="sm" align="right">
                {fmtSvc.formatMoneyNonZeroTwoDecimals(discount.getTotalValue())}
            </Text>
            <Group position="left" noWrap sx={{ gridColumnEnd: 'span 2' }}>
                {(discount.constraints ?? []).map(({ type, value }, i) => (
                    <Group key={i} spacing={4}>
                        <Text size="xs" color="dimmed">
                            {type}:
                        </Text>
                        <Text weight="bold" size="xs">
                            {value}
                        </Text>
                    </Group>
                ))}
            </Group>
            {discount.isCustom ? (
                <Text color="dimmed" size="xs" sx={{ gridColumnEnd: 'span 3' }}>
                    Custom Reservation {discount.name}
                </Text>
            ) : (
                <Group noWrap spacing={8} sx={{ gridColumnEnd: 'span 3' }}>
                    <>
                        <Group spacing={4}>
                            <Text size="xs" color="dimmed">
                                Upfront amortized:
                            </Text>
                            <Text weight="bold" size="xs">
                                {stats?.upfront ? fmtSvc.formatMoneyNonZeroTwoDecimals(stats?.upfront ?? 0) : <>&mdash;</>}
                            </Text>
                        </Group>
                        <Group spacing={4}>
                            <Text size="xs" color="dimmed">
                                Recurring:
                            </Text>
                            <Text weight="bold" size="xs">
                                {stats?.recurring ? fmtSvc.formatMoneyNonZeroTwoDecimals(stats?.recurring ?? 0) : <>&mdash;</>}
                            </Text>
                        </Group>
                    </>
                </Group>
            )}
        </AwsDiscountLayout>
    );
}

function DiscountGroupLayout<TDiscountModel>({
    group,
    children,
    itemHeight = 60,
}: {
    group: DiscountOptionGroupModel;
    itemHeight?: number;
    children: { header: ReactNode; Details: DiscountDetails<TDiscountModel>; footer?: ReactNode };
}) {
    const theme = useMantineTheme();
    return (
        <GroupExpandedCtx.Consumer>
            {({ expanded }) => (
                <Stack hidden={!expanded} spacing={0} sx={{ height: '100%' }}>
                    <Group pl="xs" noWrap sx={{ background: theme.colors.gray[1], minHeight: 30 }}>
                        <Checkbox
                            readOnly
                            size="xs"
                            color={group.typeSelected ? 'gray.6' : 'primary'}
                            onClick={group.toggleSelectAll}
                            checked={group.typeSelected || group.allSelected()}
                        />
                        {children.header}
                    </Group>
                    <Divider />
                    <DiscountItemList group={group} expanded={expanded} itemHeight={itemHeight} DiscountDetails={children.Details} />
                    <Divider color="gray.3" />
                    {children.footer ? children.footer : null}
                </Stack>
            )}
        </GroupExpandedCtx.Consumer>
    );
}

interface IDiscountItemListProps<TDiscountModel> {
    group: DiscountOptionGroupModel;
    expanded: boolean;
    DiscountDetails: DiscountDetails<TDiscountModel>;
    itemHeight: number;
}
function DiscountItemList<TDiscountModel>({ group, expanded, DiscountDetails, itemHeight }: IDiscountItemListProps<TDiscountModel>) {
    const virtualTree = useRef<VirtualTree | null>();
    const config = useMemo(
        () =>
            ({
                itemHeight: itemHeight,
                renderNode: (node: Node<DiscountOptionGroupItemModel>) => (
                    <DiscountItem option={node.item} group={group} DiscountDetails={DiscountDetails} />
                ),
            } as ITypedTreeConfig<DiscountOptionGroupItemModel>),
        [group, itemHeight]
    );
    useEffect(() => {
        setTimeout(() => virtualTree.current?.invalidateSize(), 400);
    }, [expanded]);

    return (
        <Box sx={{ height: '100%' }}>
            <VirtualTree ref={(r) => (virtualTree.current = r)} config={config} data={group.options} />
        </Box>
    );
}

type DiscountDetails<TDiscountModel> = (props: { discount: TDiscountModel; group: DiscountOptionGroupModel }) => JSX.Element;
interface DiscountItemProps<TDiscountModel> {
    option: DiscountOptionGroupItemModel;
    group: DiscountOptionGroupModel;
    DiscountDetails: DiscountDetails<TDiscountModel>;
}
function DiscountItem<TDiscountModel>({ option, group, DiscountDetails }: DiscountItemProps<TDiscountModel>) {
    const theme = useMantineTheme();
    useEvent(option.selectionChanged);

    return (
        <>
            <Group
                pl="xs"
                sx={{
                    height: 60,
                    cursor: 'pointer',
                    borderBottom: `solid 1px ${theme.colors.gray[2]}`,
                    ['&:hover']: { background: theme.colors.primary[1] },
                }}
                onClick={option.toggle}
                noWrap
            >
                <Checkbox readOnly size="xs" color={group.typeSelected ? 'gray.6' : 'primary'} checked={option.isSelected()} />
                <DiscountDetails discount={option.item as unknown as TDiscountModel} group={group} />
            </Group>
        </>
    );
}

const AwsDiscountLayout = styled.div`
    display: grid;
    grid-template-columns: 300px 150px 130px 130px 130px;
    row-gap: 4px;
    column-gap: 1px;
`;

function TypeSelector({ children, onClick, disabled }: { children: ReactNode; onClick: () => void; disabled: boolean }) {
    const theme = useMantineTheme();
    const cardSwitchStyle: Sx = {
        ['&:hover']: { background: disabled ? undefined : theme.colors.gray[1], cursor: 'pointer' },
        flex: '0 0 min-content 0',
    };

    return (
        <Box mx="md" my="sm" onClick={onClick} sx={cardSwitchStyle}>
            <Group noWrap>{children}</Group>
        </Box>
    );
}

import { getCloudIntelligenceGetCustomerCompany, getCloudIntelligenceGetCustomerSubsidyRequestById, getCompanyGetCompany } from '@apis/Customers';
import { Company, CustomerSubsidyViewModel } from '@apis/Customers/model';
import { useUnmountEffect } from '@react-hookz/web';
import { CompanyContextService } from '@root/Services/Customers/CompanyContext';
import { useDi, useDiContainer } from '@root/Services/DI';
import { useEventValue } from '@root/Services/EventEmitter';
import { useEndpointUnloading } from '@root/Services/Router/BasicRouteLoader';
import { ReactNode, useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { CompanyTenantPrereqService } from './CompanyContent';

export function CompanyContext<T extends CustomerSubsidyViewModel | Company>({
    companyId,
    children,
    type,
    onUnload,
    onCompanyLoaded,
    unloadOnUnmount,
}: {
    type: 'company' | 'customer';
    companyId: number;
    children: (customer?: T) => ReactNode;
    onUnload?: () => void;
    onCompanyLoaded?: (company?: Company) => void;
    unloadOnUnmount?: boolean;
}) {
    const di = useDiContainer();
    const tenantSvc = useDi(CompanyTenantPrereqService);

    const contextSvc = useMemo(() => {
        const result = di.resolve(CompanyContextService);
        result.init(di, async () => {
            if (type === 'company') {
                const customer = (await tenantSvc.getCompanyById(companyId)) as T;
                return {
                    customer,
                    company: customer,
                };
            } else {
                const customer = await getCloudIntelligenceGetCustomerSubsidyRequestById({ customerId: companyId });
                return {
                    customer: customer as T,
                    company: await getCloudIntelligenceGetCustomerCompany({ companyId: customer.CustomerCompanyId }),
                };
            }
        });
        return result as CompanyContextService<T>;
    }, []);
    const loading = useEventValue(contextSvc.loading);
    useEffect(() => {
        contextSvc.loadCompany(companyId);
    }, [companyId]);
    useEndpointUnloading(
        useCallback(() => {
            contextSvc.unload();
            onUnload?.();
        }, [])
    );
    useEffect(() => {
        onCompanyLoaded?.(companyId === contextSvc.company?.Id ? contextSvc.company : undefined);
    }, [companyId, contextSvc.company?.Id, onCompanyLoaded]);

    useUnmountEffect(() => {
        if (unloadOnUnmount) {
            contextSvc.unload();
            onUnload?.();
        }
    });

    return <>{!loading ? children(contextSvc.customer) : null}</>;
}

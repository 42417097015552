import { Text, Paper, Button, Anchor, useMantineTheme, Grid, Group, Loader, Space } from '@mantine/core';
import { Book } from 'tabler-icons-react';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { PageContent } from '@root/Design/Layout';
import { useEffect, useState } from 'react';
import { PollingService } from '@root/Services/Jobs/PollingService';
import { useDi } from '@root/Services/DI';
import styled from '@emotion/styled';
import { colorPalette, CustomColors } from '@root/Design/Themes';
import { getMspConfigGetMspConfigByCompany } from '@apis/Customers';
import { MspConfig } from '@apis/Customers/model';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { useSupportChat } from '@root/Components/Shell/SupportChat';

const icon_book = {
    marginRight: '10px',
    marginBottom: '10px',
};
const icon_2 = {
    marginRight: '10px',
    marginBottom: '50px',
};
const anchor = {
    marginTop: '20px',
    marginBottom: '15px',
};
const chat_now_button = {
    marginTop: '10px',
    marginBottom: '10px',
};
declare global {
    interface Window {
        embedded_svc: { liveAgentAPI: { startChat: () => Promise<void> } };
    }
}

const SupportPage = () => {
    const [mspConfig, setMspConfig] = useState<MspConfig | undefined>();
    const [loading, setLoading] = useState(true);
    const company = useCompany();

    useEffect(() => {
        getMspConfig(company?.Id!);
    }, []);

    const getMspConfig = async (mspCompanyId: number) => {
        try {
            setMspConfig(await getMspConfigGetMspConfigByCompany({ companyId: mspCompanyId }));
        } finally {
            setLoading(false);
        }
    };

    const { loadingChat, loadChat } = useSupportChat();

    const theme = useMantineTheme();
    return loading ? (
        <></>
    ) : (
        <PageContent style={{ background: theme.colors.gray[1] as CustomColors }}>
            <Grid m="xl" gutter="xl" sx={{ maxWidth: theme.breakpoints.lg }}>
                <Grid.Col span={12}>
                    <div>
                        <Text size="xl" weight={600}>
                            Support
                        </Text>
                    </div>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Paper shadow="xs" p={45} radius="md" style={{ padding: '64px' }}>
                        <Group position="apart">
                            <Group>
                                <div>
                                    <CircleOuter>
                                        <CircleInner>
                                            <Book size={24} style={{ margin: '8px 0' }} color={theme.colors?.primary?.[8] as CustomColors} />
                                        </CircleInner>
                                    </CircleOuter>
                                </div>
                                <div>
                                    <div>
                                        <Text size={26} weight={600}>
                                            CloudSaver Knowledge base
                                        </Text>
                                    </div>
                                    <div>
                                        <Text size="sm" weight={600} color="gray">
                                            Detailed guides and videos for Tag Manager
                                        </Text>
                                    </div>
                                </div>
                            </Group>
                            <Button component="a" target="_blank" href="http://knowledge.cloudsaver.com" style={{ height: '50px', width: '200px' }}>
                                View Knowledge Base
                            </Button>
                        </Group>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Paper shadow="xs" p="md" radius="md" style={{ padding: '24px' }}>
                        <div>
                            <Square style={{ margin: '0 0 40px 0' }}>
                                <Space h={14} />
                                <i className="ti ti-messages" style={{ color: theme.colors?.primary?.[8], marginLeft: '14px', fontSize: '20px' }} />
                            </Square>
                        </div>
                        <div>
                            <Text size="md" weight={600}>
                                Live Support Chat
                            </Text>
                        </div>
                        {(mspConfig == undefined || !mspConfig.isWhiteLabelEnabled) && (
                            <div>
                                <Text size="xs" color="gray">
                                    Available Monday-Friday, 8am-5pm CST
                                </Text>
                            </div>
                        )}
                        {mspConfig && mspConfig.isWhiteLabelEnabled && mspConfig.ChatWidgetHtml ? (
                            <div dangerouslySetInnerHTML={{ __html: mspConfig.ChatWidgetHtml }} />
                        ) : (
                            <div style={chat_now_button}>
                                <Button leftIcon={loadingChat ? <Loader color={colorPalette.fffColor} size="sm" /> : undefined} onClick={loadChat}>
                                    Chat Now
                                </Button>
                            </div>
                        )}
                    </Paper>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Paper shadow="xs" p="md" radius="md" style={{ padding: '24px' }}>
                        <div>
                            <Square style={{ margin: '0 0 40px 0' }}>
                                <Space h={14} />
                                <i className="ti ti-mail" style={{ color: theme.colors?.primary?.[8], marginLeft: '14px', fontSize: '20px' }} />
                            </Square>
                        </div>
                        <div>
                            <Text size="md" weight={600}>
                                Email Us
                            </Text>
                        </div>
                        <div>
                            <Text size="xs" color="gray">
                                We'll get back to you soon.
                            </Text>
                        </div>
                        <div style={anchor}>
                            {mspConfig && mspConfig.isWhiteLabelEnabled && mspConfig.SupportEmail ? (
                                <Anchor href={`mailto:${mspConfig.SupportEmail}?Subject=CloudSaver Support`} title="title" target="_blank">
                                    <Text size="xs" weight={600}>
                                        {mspConfig.SupportEmail}
                                    </Text>
                                </Anchor>
                            ) : (
                                <Anchor href="mailto:support@cloudsaver.com?Subject=CloudSaver" title="title" target="_blank">
                                    <Text size="xs" weight={600}>
                                        support@cloudsaver.com
                                    </Text>
                                </Anchor>
                            )}
                        </div>
                    </Paper>
                </Grid.Col>
            </Grid>
        </PageContent>
    );
};
export default SupportPage;
endpoint('support-home', SupportPage, 'Support');
endpoint('msp-support-home', SupportPage, 'Support');

export const CircleOuter = styled.div`
    background-color: ${(p) => p.theme.colors?.primary?.[1] as CustomColors};
    border-radius: 50%;
    width: 56px;
    height: 56px;
    text-align: center;
`;

export const CircleInner = styled.div`
    display: inline-block;
    background-color: ${(p) => p.theme.colors?.primary?.[2] as CustomColors};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 8px 0;
`;

export const Square = styled.div`
    display: inline-block;
    background-color: ${(p) => p.theme.colors?.primary?.[2] as CustomColors};
    border-radius: 25%;
    width: 48px;
    height: 48px;
`;

import { Card, Group, Space, Title } from '@mantine/core';
import { observer } from 'mobx-react';
import { UseFormReturnType } from '@mantine/form';
import { StandardRuleLayout } from './StandardRuleLayout';
import { RuleEditor } from './Model';
import { SyntaxActionCard } from './SyntaxActionCard';

export function SyntaxRuleCard({ ruleEditor }: { ruleEditor: RuleEditor }) {
    return (
        <StandardRuleLayout ruleEditor={ruleEditor}>
            <SyntaxActionCard ruleEditor={ruleEditor} />
        </StandardRuleLayout>
    );
}

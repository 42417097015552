import { ChangeDetails, Recommendation } from '@apis/Recommendations/model';
import { useMantineTheme, Space, Text, ThemeIcon, Box, Group, Tooltip, MantineColor } from '@mantine/core';
import { IncreaseIndicator } from '@root/Design/Data';
import { Fragment } from 'react';
import { ArrowsUpDown, Check } from 'tabler-icons-react';
import { GridFlexCell, GridFullCell } from '../DataGrid/Design';
import { VisibleSpaces } from '../Text/VisibleSpaces';
import { IMetricInfo } from './Options/Models';

export function IncreaseIndicatorCell(props: {
    value: number | undefined;
    format: (v: number) => string;
    preferDecrease?: boolean;
    missingValue?: string;
}) {
    const { value, format, missingValue, preferDecrease } = props;
    return (
        <>
            {typeof value === 'number' ? (
                <GridFlexCell align="right">
                    {format(value)}
                    <IncreaseIndicator size="sm" value={value} preferDecrease={preferDecrease} />
                </GridFlexCell>
            ) : missingValue ? (
                missingValue
            ) : (
                ''
            )}
        </>
    );
}

export function createIncreaseIndicatorCellRenderer<T>(
    accessor: (item: T) => number | undefined,
    format: (v: number) => string,
    preferDecrease?: boolean,
    missingValue?: string
) {
    return (item: T) => <IncreaseIndicatorCell value={accessor(item)} format={format} preferDecrease={preferDecrease} missingValue={missingValue} />;
}

export function TagCell({ item, tag }: { item: Recommendation; tag: string }) {
    const theme = useMantineTheme();
    const value = item.ResourceMetadata?.CsTags?.[tag];
    const styles = !value ? { fontStyle: 'italic', color: theme?.colors?.gray?.[6] } : undefined;
    return (
        <GridFullCell style={{ ...styles }}>
            {value === null ? <></> : value === undefined ? <>« No Tag »</> : <VisibleSpaces value={value} />}
        </GridFullCell>
    );
}

export function ChangeSelector({
    item,
    children,
    isSelected,
    changeFieldAccessor,
}: {
    item: Recommendation;
    children: React.ReactNode;
    isSelected: (item: Recommendation) => boolean;
    changeFieldAccessor: (details: ChangeDetails) => string | null | undefined;
}) {
    const details = item.OptionSelected?.ChangeDetails;
    const selected = !!details;
    const highlight = isSelected(item);
    const ineligibleText = item.IneligibleReasons?.join('\n') ?? '';
    const optionsAvailable = !!item.Options?.length;
    const noRecommendation = !item.LowestRiskHighestSavings?.ChangeDetails;
    const doNotChange = details && changeFieldAccessor(details) === 'No Change';
    const theme = useMantineTheme();
    const iconColor =
        ineligibleText || noRecommendation || doNotChange
            ? theme.colors.gray[4]
            : highlight
            ? theme.colors.primary[6]
            : selected
            ? theme.colors.success[5]
            : optionsAvailable
            ? theme.colors.primary[3]
            : theme.colors.gray[4];
    const Icon = selected || ineligibleText || noRecommendation || doNotChange ? Check : null;
    const textProps = { color: 'dimmed' as 'dimmed', pl: 8 };
    return (
        <GridFlexCell style={{ flexWrap: 'nowrap' }}>
            <ThemeIcon size="sm" variant="filled" color={iconColor as MantineColor}>
                {Icon ? <Icon size={16} /> : <span></span>}
            </ThemeIcon>
            {doNotChange ? (
                <Text {...textProps}>Do not change</Text>
            ) : selected ? (
                children
            ) : ineligibleText ? (
                <Tooltip
                    withinPortal
                    label={
                        <>
                            {item.IneligibleReasons?.length
                                ? item.IneligibleReasons?.map((r, i) => (
                                      <Fragment key={i}>
                                          {r}
                                          <br />
                                      </Fragment>
                                  ))
                                : 'No Valid Resize Options'}
                        </>
                    }
                >
                    <Text {...textProps}>Ineligible</Text>
                </Tooltip>
            ) : optionsAvailable && !noRecommendation ? (
                <Text {...textProps}>Options available</Text>
            ) : (
                <Text {...textProps}>No recommendation</Text>
            )}
        </GridFlexCell>
    );
}

export function SpecColumn({
    specMetrics,
    metrics,
    state,
}: {
    specMetrics: IMetricInfo[];
    state: string;
    metrics: { [key: string]: number | null };
}) {
    return (
        <GridFlexCell>
            <Box>
                <Box sx={{ height: 25 }}>
                    <Text>{state ? state : 'Unknown'}</Text>
                </Box>
                {!state ? null : (
                    <Group sx={{ height: 20 }} noWrap spacing={0}>
                        {specMetrics.map((m, i) => (
                            <Fragment key={i}>
                                {i > 0 ? ', ' : ''}
                                <Text ml={4} size="xs" color="dimmed">
                                    {m.label}: {m.format(m.value(metrics))}
                                </Text>
                            </Fragment>
                        ))}
                    </Group>
                )}
            </Box>
        </GridFlexCell>
    );
}

import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useNav } from '@root/Services/NavigationService';
import { useCallback, useEffect, useMemo } from 'react';
import { DateRangeFilter, DateRangeFilterProps } from '../Filter/DateRangeFilter';

type DrfValue = DateRangeFilterProps['value'];
type DrfOnChange = DateRangeFilterProps['onChange'];

export function DateRangePicker(props: DateRangeFilterProps) {
    return <DateRangeFilter {...props} />;
}

function parseUrlDateRange(rawRange: undefined | string, fmtSvc: FormatService): DrfValue {
    if (!rawRange) {
        return {};
    }
    if (/^(\d{8})?-(\d{8})?$/.test(rawRange)) {
        const [from, to] = rawRange.split('-');
        return { from: !from ? undefined : fmtSvc.from8DigitDate(from), to: !to ? undefined : fmtSvc.from8DigitDate(to) };
    }
    return { id: rawRange };
}

interface IUrlDateRangePickerOptions {
    name?: string;
    handleChanged?: (value: DrfValue) => void;
    onInit?: (value: DrfValue) => void;
}
export function useUrlDateRangeParams({ name = 'range', handleChanged, onInit }: IUrlDateRangePickerOptions) {
    const nav = useNav();
    const fmtSvc = useDi(FormatService);
    const { [name]: rawRange } = nav.getData(name);

    const value: DrfValue = useMemo(() => parseUrlDateRange(rawRange, fmtSvc), [rawRange]);

    useEffect(() => {
        onInit?.(value);
    }, []);

    const update = useCallback(
        (value: DrfValue, triggerChangedEvent?: boolean) => {
            const { id, from, to } = value ?? {};
            const fromParam = from ? fmtSvc.to8DigitDate(from) : '';
            const toParam = to ? fmtSvc.to8DigitDate(to) : '';
            const paramValue = id ? id : fromParam || toParam ? `${fromParam}-${toParam}` : '';
            const nextParam = paramValue ? { [name]: paramValue } : {};
            nav.mergeParams(nextParam);
            if (triggerChangedEvent) {
                handleChanged?.(value);
            }
        },
        [name, handleChanged]
    );

    const onChanged: DrfOnChange = useCallback((value: DrfValue) => update(value, true), [update]);

    return { value, setValue: update, onChanged, rawRange };
}

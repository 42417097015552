/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.TagManager.Host.Api
 * OpenAPI spec version: 1.0
 */

export type TagAutomationRuleParametersType = typeof TagAutomationRuleParametersType[keyof typeof TagAutomationRuleParametersType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TagAutomationRuleParametersType = {
  Inheritance: 'Inheritance',
  SyntaxCorrection: 'SyntaxCorrection',
  Replacement: 'Replacement',
  AutoTag: 'AutoTag',
  MapCredits: 'MapCredits',
  ResourceGroup: 'ResourceGroup',
  AwsOUAccountGroup: 'AwsOUAccountGroup',
} as const;

import { CustomerSubsidyViewModel } from '@apis/Customers/model';
import { CloseButton, Group, Skeleton, Text } from '@mantine/core';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { CompanyContext } from '@root/Components/Router/CompanyContext';
import { PanelContent } from '@root/Design/Layout';
import { CompanyDetailBody } from './CompanyDetail';

export function CompanyDetailPanel({ companyId, onClose }: { companyId: number; onClose: () => void }) {
    return (
        <CompanyContext type="customer" companyId={companyId}>
            {(customer) => <CompanyDetailPanelBody onClose={onClose} customer={customer} />}
        </CompanyContext>
    );
}
function CompanyDetailPanelBody({ onClose, customer }: { onClose: () => void; customer?: CustomerSubsidyViewModel }) {
    const company = useCompany();
    return (
        <PanelContent>
            <Group position="apart" p={0}>
                <Group py="xs" px="lg">
                    {company ? <Text size={20}>{customer?.Company}</Text> : <Skeleton height={20} />}
                </Group>
                <CloseButton mr="xl" onClick={onClose} />
            </Group>
            {company && customer ? <CompanyDetailBody customer={customer} company={company} /> : null}
        </PanelContent>
    );
}

import { Title, Grid, Card, Box, Loader, Container, Group } from '@mantine/core';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';
import { InitialSyncCheck } from '@root/Components/Resources/IntialSyncCheck';
import { PagePanel, PanelBody, PaneledPage } from '@root/Design/Layout';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useContext } from 'react';
import { TagIntelligencePage } from './TagIntelligencePage';
import SpendCoverageGrid from './Components/SpendCoverageGrid';
import { Select } from '@mantine/core';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { PieChart, PieChartSettings } from '@root/Components/Charts/PieChart';
import { SpendingCoverageService } from '@root/Services/TagIntelligence/SpendingCoverageService';

export function SpendCoverageContent() {
    return (
        <TagIntelligencePage>
            <PaneledPage>
                <PagePanel size="fill">
                    <PanelBody>
                        <Group position="left">
                            <Title data-atid={'IntelligenceHeader'} order={2}>
                                Tag Intelligence: Spend Coverage
                            </Title>
                            <DateRangeSelector />
                        </Group>
                        <KPIs></KPIs>
                        <Card withBorder radius="lg" sx={{ height: 600 }}>
                            <SpendCoverageGrid />
                        </Card>
                    </PanelBody>
                </PagePanel>
            </PaneledPage>
        </TagIntelligencePage>
    );
}

export const KPIs = observer(function KPIs() {
    const spendingCoverageSvc = useDi(SpendingCoverageService);
    const totalSpend = spendingCoverageSvc.aggregatedSpendData;
    const formatSvc = useDi(FormatService);
    const settings = {
        angle: 'large',
        margin: { top: 30, bottom: 30, left: 20, right: 20 },
        threshold: 3,
        enableArcLinkLabels: false,
        valueFormat: ' >-$,',
        legend: [
            {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000',
                        },
                    },
                ],
            },
            {
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: 0,
                translateY: 0,
                itemWidth: 100,
                itemHeight: 24,
                itemsSpacing: 10,
                symbolSize: 20,
                itemDirection: 'left-to-right',
            },
        ],
    } as PieChartSettings;

    return (
        <>
            <Grid py="xl">
                <Grid.Col span={4}>
                    <Container>
                        <div style={{ height: '150px' }}>
                            <PieChart
                                groups={['x']}
                                values={['y']}
                                data={[
                                    { x: 'Tagged', y: Math.round(totalSpend.taggedSpend) },
                                    { x: 'Untagged', y: Math.round(totalSpend.untaggedSpend) },
                                    { x: 'Unsupported', y: Math.round(totalSpend.unsupportedSpend) },
                                ]}
                                settings={settings}
                            />
                        </div>
                    </Container>
                </Grid.Col>
                <Grid.Col span={4}>
                    <KpiTile
                        title="Total Spend"
                        value={totalSpend?.totalSpend !== undefined ? formatSvc.formatMoney(totalSpend?.totalSpend) : undefined}
                    />
                </Grid.Col>
                <Grid.Col span={4}>
                    <KpiTile
                        title="Untagged Spend"
                        value={totalSpend?.untaggedSpend !== undefined ? formatSvc.formatMoney(totalSpend?.untaggedSpend) : undefined}
                    />
                </Grid.Col>
            </Grid>
        </>
    );
});

export const DateRangeSelector = observer(function DateRangeSelector() {
    const spendingCoverageSvc = useDi(SpendingCoverageService);
    const data: string = spendingCoverageSvc.months;

    function updateDateRange(value: string) {
        spendingCoverageSvc.setDateRange(value);
        spendingCoverageSvc.refreshSpendCoverage();
    }

    return (
        <>
            <Select
                data-atid={'SpendCoverageRangeSelector'}
                className="ListBadgeCell"
                value={data}
                data={spendingCoverageSvc.spendTimeFrameOptions}
                onChange={updateDateRange}
            />
        </>
    );
});

function KpiTile({ value, title }: { value?: string; title: string }) {
    return (
        <Card withBorder radius="lg" p="lg" sx={{ height: '100%' }}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box sx={{ textAlign: 'center' }}>
                    <Title order={4}>{title}</Title>
                </Box>
                <BigNumber>{value === undefined ? <Loader /> : value}</BigNumber>
            </Box>
        </Card>
    );
}

const BigNumber = styled.div`
    font-size: 32px;
    font-weight: bold;
    text-align: center;
`;

function SpendCoverage() {
    return <ConnectionCheck>{() => <InitialSyncCheck>{() => <SpendCoverageContent />}</InitialSyncCheck>}</ConnectionCheck>;
}
endpoint('spend-coverage', SpendCoverage, 'Spend Coverage');

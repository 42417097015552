import { Box, Grid, Select, Text, useMantineTheme, Checkbox } from '@mantine/core';
import { observer } from 'mobx-react';
import { TagAutomationRuleStatus } from '@apis/TagManager/model';
import { RuleEditCard } from '@root/Site/TagManager/TagAutomation/Components/Design';
import { useEffect, useState } from 'react';

const statusDescription: Record<TagAutomationRuleStatus, string> = {
    Active: 'This rule is active.  The rule is running in the background and automatically adding tags to the resources that meet the rule’s criteria',
    Deleted: 'This rule will be deleted and will not be shown with the other rules',
    Test: 'This rule is running in test mode.  The rule is running in the background to identify resources that meet the rule’s criteria, but no tags are added to any resources.  This mode is used to validate your auto-tagging rule is functioning as intended',
    Draft: 'This rule will be saved as a Draft. It will not make any tag changes',
};

const selectOptions = [
    { value: 'Draft', label: 'Draft' },
    { value: 'Test', label: 'Test' },
    { value: 'Active', label: 'Active' },
] as { value: TagAutomationRuleStatus; label: string }[];

function MapRuleStatusCard({
    ruleStatus,
    onChange,
    onConfirmed,
}: {
    ruleStatus?: TagAutomationRuleStatus;
    onChange: (value: TagAutomationRuleStatus) => void;
    onConfirmed: (value: boolean) => void;
}) {
    const supplementalText = ruleStatus ? statusDescription[ruleStatus] : '';
    const ruleIssues: any[] = [];
    const hasValidationErrors: any[] = [];
    const filterValidation: any[] = [];
    const theme = useMantineTheme();
    const [color, setColor] = useState<string | undefined>();
    const [icon, setIcon] = useState<string>('ti ti-circle-dashed');
    const [confirmed, setConfirmed] = useState<boolean>(false);

    useEffect(() => {
        switch (ruleStatus) {
            case TagAutomationRuleStatus.Active:
                setColor(theme.colors?.success?.[5]);
                setIcon('ti ti-circle-filled');
                break;
            case TagAutomationRuleStatus.Draft:
                setColor(theme.colors?.success?.[5]);
                setIcon('ti ti-circle-dashed');
                break;
            case TagAutomationRuleStatus.Test:
                setColor(theme.colors?.warning?.[5]);
                setIcon('ti ti-clock-hour-10');
                break;
            case TagAutomationRuleStatus.Deleted:
                setColor(theme.colors?.gray?.[5]);
                setIcon('ti ti-square');
                break;
            default:
                setColor(theme.colors?.success?.[5]);
                setIcon('ti ti-circle-dashed');
                break;
        }
    }, [ruleStatus]);

    useEffect(() => {
        onConfirmed(confirmed);
    }, [confirmed]);

    return (
        <RuleEditCard
            title="Status"
            color="gray"
            titleDescriptionControl={
                ruleStatus === TagAutomationRuleStatus.Active ? (
                    <Checkbox
                        style={{ width: '415px', marginRight: '80px' }}
                        size="md"
                        checked={confirmed}
                        onChange={(event) => setConfirmed(event.target.checked)}
                        label={
                            <Text color="primary.6" size="sm">
                                I confirm the selected workloads are included in my MAP Migration Plan.
                            </Text>
                        }
                    />
                ) : (
                    <></>
                )
            }
        >
            <Box mt="md">
                <Grid>
                    <Grid.Col span={4}>
                        <Box sx={{ width: '200px' }}>
                            <Select
                                key={icon}
                                icon={<i className={icon} style={{ color: color }}></i>}
                                width={200}
                                withinPortal
                                value={ruleStatus}
                                onChange={onChange}
                                data={selectOptions}
                            />
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <Box sx={{ width: '400px' }}>
                            <Text size="sm">{supplementalText}</Text>
                        </Box>
                    </Grid.Col>
                </Grid>
            </Box>
            {/* <Box>
                    {!hasValidationErrors.length ? null : (
                        <Card radius="lg" sx={{ background: theme.white }}>
                            <Group noWrap>
                                <ThemeIcon radius="lg" variant="light" color="warning">
                                    <AlertTriangle size={20} />
                                </ThemeIcon>
                                <Text size="sm">
                                    The rule may only be saved in <strong>Draft</strong> mode, because of validation errors.
                                </Text>
                            </Group>
                        </Card>
                    )}
                    <Box px="lg">
                        {!ruleIssues?.length ? null : <ValidationIssues title="There are issues with the rule type setup" issues={ruleIssues} />}
                        {!filterValidation.length ? null : <ValidationIssues title="Some filters are not valid" issues={[]} />}
                    </Box>
                </Box> */}
        </RuleEditCard>
    );
}

export default observer(MapRuleStatusCard);

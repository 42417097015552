import { singleton } from 'tsyringe';

@singleton()
export class AwsService {
    public cspToken: string | null = null;
    private awsCookieKey: string = 'awsMarketToken';

    public async init() {
        this.checkForExistingCookie();
    }

    public setCookie(cspToken: string) {
        localStorage.setItem(this.awsCookieKey, cspToken);
        this.cspToken = cspToken;
    }

    public checkForExistingCookie() {
        var token = localStorage.getItem(this.awsCookieKey);

        if (token != null) this.cspToken = token;
    }

    public removeCookie() {
        localStorage.removeItem(this.awsCookieKey);
    }
}

import styled from '@emotion/styled';
import { Anchor, ScrollArea } from '@mantine/core';

export function AlphabetPicker({
    onSelect,
    selected,
    values,
    height,
}: {
    onSelect: (value: string) => void;
    selected?: string;
    values: string[];
    height?: string;
}) {
    return (
        <ScrollArea style={{ height }} offsetScrollbars pr="10px" sx={{ '.mantine-ScrollArea-thumb:before': { minWidth: '15px' } }}>
            {values.map((v) => (
                <Anchor component={AlphabetEl} key={v} onClick={() => onSelect(v)} selected={v === selected}>
                    {v}
                </Anchor>
            ))}
        </ScrollArea>
    );
}
const AlphabetEl = styled.a<{ selected?: boolean }>`
    display: block;
    padding: 0 4px;
    text-align: center;
    color: ${(p) => p.theme.colors.primary[6]};
    background: ${(p) => (p.selected ? p.theme.colors.primary[2] : 'transparent')};
    border-radius: 4px;
    &:hover {
        text-decoration: none;
        background: ${(p) => p.theme.colors.primary[3]};
    }
`;

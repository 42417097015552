import { getUserAuth0ResendEmailValidation, useGetUserAuth0ResendEmailValidation } from '@apis/Customers';
import { Anchor, Box, Button, Divider, Group, Modal, Space, Text, ThemeIcon, useMantineTheme } from '@mantine/core';
import { AnchorButton } from '@root/Design/Primitives';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { ConfigService } from '@root/Services/ConfigService';
import { useDi } from '@root/Services/DI';
import { useNav } from '@root/Services/NavigationService';
import { BasicRouteLoader } from '@root/Services/Router/BasicRouteLoader';
import { SplashService } from '@root/Services/SplashService';
import { useEffect, useState } from 'react';
import { Check, DoorExit } from 'tabler-icons-react';
import { ErrorLogout } from './ErrorLogout';
import { MspService } from '@root/Services/MspService';

export function VerifyEmail() {
    const routeLoader = useDi(BasicRouteLoader);
    const { getData, goto } = useNav();
    const { redir, inviteCode } = getData('redir', 'inviteCode');
    const theme = useMantineTheme();
    const mspSvc = useDi(MspService);
    const [resent, setResent] = useState(false);
    const authNSvc = useDi(AuthenticationService);
    const user = authNSvc.user;
    const configSvc = useDi(ConfigService);
    const splashSvc = useDi(SplashService);
    useEffect(() => {
        (async () => {
            splashSvc.removeSplash();
            await authNSvc.waitForVerification('emailverified');
            if (inviteCode) {
                location.href = `${configSvc.config.loginUrl}callback?inviteCode=${inviteCode}&host=${window.location.hostname}`;
            } else {
                goto(redir ?? '/');
                location.reload();
            }
        })();
    }, []);
    const emailSupport = () => {
        document.location.href = mspSvc.mspSupportAndLogos.SupportEmail!;
    };
    const resend = () => {
        getUserAuth0ResendEmailValidation();
        setResent(true);
    };

    return (
        <Modal
            padding={theme.spacing.xl}
            opened={true}
            onClose={() => {}}
            withCloseButton={false}
            withinPortal
            overlayBlur={4}
            overlayOpacity={1}
            overlayColor={theme.colors.gray[1]}
            size={450}
            shadow="xl"
        >
            <Box>
                <Box sx={{ textAlign: 'center' }}>
                    <img src={mspSvc.mspSupportAndLogos.Logo} style={{ width: 120 }} alt="CloudSaver" />
                </Box>
                <Box sx={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center' }}>
                    <Space h="xl" />
                    <div>Verify Your Email Address</div>
                    <Space h="xl" />
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                    Check your email for the verification link.
                    <Space h="xl" />
                    If you don't see it, check your spam or junk folder.
                    <Space h="xl" />
                    {!resent ? (
                        <Button onClick={resend}>Resend verification</Button>
                    ) : (
                        <Group position="center">
                            <ThemeIcon variant="light" color="success">
                                <Check />
                            </ThemeIcon>
                            Verification resent
                        </Group>
                    )}
                    <Space h="xl" />
                    <Box sx={{ fontSize: '12px' }}>
                        <b>Need help?</b> Contact us at{' '}
                        <Anchor onClick={emailSupport}>{mspSvc.mspSupportAndLogos.SupportEmail.replace('mailto:', '')}</Anchor>
                    </Box>
                </Box>
                <ErrorLogout />
            </Box>
        </Modal>
    );
}

import { Company } from '@apis/Customers/model';
import { AssessmentDefinition, AssessmentResult, PresentationAction, PresentationActionNavigation } from '@apis/Resources/model';
import { Button } from '@mantine/core';
import { AuthorizationService } from '@root/Services/AuthorizationService';
import { ICompanyContextToken } from '@root/Services/Customers/CompanyContext';
import { CompanyFeatureService } from '@root/Services/Customers/CompanyFeatureService';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { useNav } from '@root/Services/NavigationService';
import { IRouteSegment } from '@root/Services/Router/RouteSerializer';
import { useCallback, useMemo } from 'react';
import { inject, Lifecycle, scoped } from 'tsyringe';

@scoped(Lifecycle.ContainerScoped)
class SubscriptionCheckService {
    public loading = new EventEmitter<boolean>(true);
    private subscribedApps: Set<string> | null = null;
    public constructor(
        @inject(CompanyFeatureService) private readonly companyFeatureSvc: CompanyFeatureService,
        @inject(ICompanyContextToken) private readonly company: Company
    ) {
        this.init();
    }
    public async init() {
        try {
            this.loading.emit(true);
            const companyFeatures = await this.companyFeatureSvc.getFeatures(this.company);
            this.subscribedApps = new Set(companyFeatures.getLegacyApps().map((app) => this.normalizeAppName(app)));
        } finally {
            this.loading.emit(false);
        }
    }

    public hasSubscribedApps(requiredApps: string[]) {
        return !!requiredApps.every((x) => this.subscribedApps?.has(this.normalizeAppName(x)));
    }

    private normalizeAppName(appName: string) {
        return appName.replace(/ /g, '').toLocaleLowerCase();
    }
}

export function AssessmentAction({ definition, assessment }: { definition: AssessmentDefinition; assessment?: AssessmentResult }) {
    return (
        <>
            {!assessment
                ? null
                : definition.PresentationOptions?.Actions?.map((action, i) => (
                      <AssessmentActionComponent key={i} assessment={assessment} definition={definition} action={action} />
                  )) ?? null}
        </>
    );
}

enum ActionComponentTypes {
    Button = 'button',
    Link = 'link',
}

interface AssessmentActionComponentProps {
    action: PresentationAction;
    definition: AssessmentDefinition;
    assessment: AssessmentResult;
}
function AssessmentActionComponent(props: AssessmentActionComponentProps) {
    const allow = useActionPermissionCheck(props.action);
    const { loading, canAct } = useActionSubscriptionCheck(props.action);
    return (
        <>
            {!allow || loading || !canAct ? null : props.action.Appearance === ActionComponentTypes.Button ? (
                <AssessmentActionButton {...props} />
            ) : null}
        </>
    );
}

function AssessmentActionButton({ action, definition, assessment }: AssessmentActionComponentProps) {
    const executeHandler = useCallback(
        (execute: () => Promise<void>, evt: React.MouseEvent<HTMLButtonElement>) => {
            evt.stopPropagation();
            evt.preventDefault();
            execute();
        },
        [action, assessment]
    );
    return (
        <PresentationActionTypeHoc action={action} assessment={assessment}>
            {(canExecute, execute) => (
                <Button
                    sx={{ width: 125 }}
                    variant="outline"
                    disabled={!canExecute}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => executeHandler(execute, e)}
                >
                    {action.Label}
                </Button>
            )}
        </PresentationActionTypeHoc>
    );
}

export const useActionSubscriptionCheck = (action: PresentationAction) => {
    const subCheckSvc = useDi(SubscriptionCheckService);
    const loading = useEventValue(subCheckSvc.loading);
    return { loading, canAct: subCheckSvc.hasSubscribedApps(action.RequiredSubscriptions ?? []) };
};

export const useActionPermissionCheck = (action: PresentationAction) => {
    const authZSvc = useDi(AuthorizationService);
    const canAct = useMemo(() => {
        return (
            !action.RequiredPermissions?.length || action.RequiredPermissions.every((p) => !p.Resource || authZSvc.allow(p.Resource, p.Verbs ?? []))
        );
    }, [action]);

    return canAct;
};

type PresentationActionTypes = 'navigation';
function PresentationActionTypeHoc({
    children,
    ...props
}: {
    action: PresentationAction;
    assessment: AssessmentResult;
    children: (canExecute: boolean, execute: () => Promise<void>) => JSX.Element;
}) {
    const type = props.action.Type as PresentationActionTypes;
    return <>{type === 'navigation' ? <PresentationActionNavigationHoc {...props}>{children}</PresentationActionNavigationHoc> : null}</>;
}

const navigationPageHandler: Record<string, (assessment: AssessmentResult, parameters: Record<string, string>) => IRouteSegment> = {
    RightSizing: (_, parameters) => {
        return { name: 'rightsizing-' + parameters['type'], data: {} };
    },
    IdleResources: (_, parameters) => {
        return { name: 'idle-' + parameters['type'], data: {} };
    },
    TagIntelligence: (_, parameters) => {
        return { name: parameters['page'], data: {} };
    },
};
function PresentationActionNavigationHoc({
    action,
    assessment,
    children,
}: {
    action: PresentationAction;
    assessment: AssessmentResult;
    children: (canExecute: boolean, execute: () => Promise<void>) => JSX.Element;
}) {
    const navAction = action.Type === 'navigation' ? (action as PresentationActionNavigation) : undefined;
    const nav = useNav();
    const pageHandler = navigationPageHandler[navAction?.Page ?? ''];
    const execute = useCallback(async () => {
        if (pageHandler) {
            const route = pageHandler(assessment, navAction?.PageParams ?? {});
            if (route) {
                nav.descend(route.name, route.data);
            }
        }
    }, [pageHandler, assessment.AssessmentResultId]);

    return <>{children(!!pageHandler, execute)}</>;
}

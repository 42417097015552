import { InvoiceAllocationRuleRuleType, InvoiceTaggingRule, InvoiceTaggingRuleRuleType } from '@apis/Invoices/model';
import { InvoiceAllocationRule } from '@apis/Invoices/model/invoiceAllocationRule';
import { Group, Sx, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import { Box, UnstyledButton, Image } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { useNav } from '@root/Services/NavigationService';

const typeDescriptorLookup: Record<InvoiceAllocationRuleRuleType | InvoiceTaggingRuleRuleType, { image: string; ruleName: string }> = {
    UntaggedCosts: { image: 'untaggedCosts', ruleName: 'Untagged Costs' },
    SharedCosts: { image: 'sharedCosts', ruleName: 'Shared Costs' },
    Discounts: { image: 'discounts', ruleName: 'Discounts' },
    Marketplace: { image: 'marketplace', ruleName: 'Marketplace' },
    Advanced: { image: 'advanced', ruleName: 'Advanced' },
};

const getImage = (type: InvoiceAllocationRuleRuleType, state?: 'selected' | 'hover') => {
    const imgType = typeDescriptorLookup[type].image;
    return `/assets/showback/ruletypes/card-${imgType}-${state == 'selected' ? 'on' : state == 'hover' ? 'hover' : 'off'}.png`;
};

export function AllocationRuleTypeCard({
    ruleType,
    selected,
    disabled,
    onClick,
}: {
    ruleType: InvoiceAllocationRuleRuleType | InvoiceTaggingRuleRuleType;
    selected: boolean;
    disabled?: boolean;
    onClick: (type: InvoiceAllocationRuleRuleType | InvoiceTaggingRuleRuleType) => void;
}) {
    const { hovered, ref } = useHover();
    const src = getImage(ruleType, selected ? 'selected' : !disabled && hovered ? 'hover' : undefined);
    const ruleName = typeDescriptorLookup[ruleType].ruleName;
    const select = useCallback(() => onClick(ruleType), [ruleType]);
    const disabledStyle: Sx = disabled && !selected ? { filter: `saturate(0.1) opacity(0.5)`, cursor: 'not-allowed' } : {};
    return (
        <Box ref={ref} sx={{ lineHeight: 0 }}>
            <UnstyledButton onClick={select} sx={{ cursor: 'pointer', ...disabledStyle }}>
                <Image src={src} alt={ruleName} height={122} />
            </UnstyledButton>
        </Box>
    );
}

export const AllocationRuleTypeList = observer(function AllocationRuleTypeList({ rule }: { rule: InvoiceAllocationRule | InvoiceTaggingRule }) {
    const ruleTypes: InvoiceAllocationRuleRuleType[] = ['UntaggedCosts', 'SharedCosts', 'Discounts', 'Marketplace', 'Advanced'];
    const [errorsShown, { open: showErrors }] = useDisclosure(false);
    const theme = useMantineTheme();
    const navSvc = useNav();
    const selectType = useCallback(
        (type: InvoiceAllocationRuleRuleType) => {
            if (!rule.Id) {
                rule.RuleType = type;
                navSvc.replaceParams({ ...navSvc.getParams(), ruleType: type });
            }
            showErrors();
        },
        [rule]
    );
    return (
        <>
            <Group p="md" spacing="md" sx={{ margin: '0px 8px' }}>
                {ruleTypes.map((t) => (
                    <AllocationRuleTypeCard key={t} selected={rule.RuleType === t} ruleType={t} disabled={!!rule.Id} onClick={selectType} />
                ))}
            </Group>
        </>
    );
});

import { Box, Card, Group, LoadingOverlay, Notification, Progress, Space, Text } from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { ResourceSyncService } from '@root/Services/Resources/ResourceService';
import { ReactNode, useEffect, useState } from 'react';

export function InitialSyncCheck({ children }: { children: () => ReactNode }) {
    const resourceSyncSvc = useDi(ResourceSyncService);
    const [loading, setLoading] = useState(true);
    const [initialSyncDone, setInitialSyncDone] = useState<boolean>();
    const [progressValue, setProgressValue] = useState(0);
    useEffect(() => {
        let stopPolling = false;
        (async () => {
            try {
                const state = await resourceSyncSvc.getInitialSync(setProgressValue, () => stopPolling);
                setInitialSyncDone(state.initialSync > 0 ? state.initialSyncDone : true);
            } finally {
                setLoading(false);
            }
        })();
        return () => {
            stopPolling = true;
        };
    }, []);

    return (
        <>
            {loading || (!initialSyncDone && progressValue < 1) ? (
                <>
                    {initialSyncDone === false ? (
                        <Box sx={{ position: 'relative', height: '100%' }}>
                            <LoadingOverlay
                                visible
                                zIndex={0}
                                loader={
                                    <Card p="lg" withBorder shadow="lg" mt={-200}>
                                        <Text>First-Time Import from Cloud Service Provider</Text>
                                        <Text color="dimmed">Performing baseline sync with your cloud environment metadata</Text>
                                        <Space h="xs" />
                                        <Group>
                                            <Progress sx={{ flex: 1 }} value={Math.floor(progressValue * 100)} />
                                            <Text align="right" sx={{ minWidth: 40 }}>
                                                {Math.floor(progressValue * 100) + '%'}
                                            </Text>
                                        </Group>
                                    </Card>
                                }
                            />
                        </Box>
                    ) : null}
                </>
            ) : (
                children()
            )}
        </>
    );
}

import { useCallback, useMemo } from 'react';
import { BaseResource } from '../ResourcesGrid';
import { CollapsibleDetailsSection, SummarySection } from '../Design';
import {
    PropertyGrid,
    PropertyGridConfig,
    PropertyGridOptionItem,
    usePropertyGridConfig,
    usePropertyGridConfigBuilder,
} from '@root/Components/PropertyGrid/PropertyGrid';
import { BaseAzureResource } from '@apis/Resources/model';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { Stack } from '@mantine/core';
import { getCopyOption, useCommonFieldConfig, useFieldPinning, useResourceChangeCount } from '../Common/OverviewCommon';
import { FillerSwitch } from '@root/Design/Filler';
import { useAzureResourceFieldConfig } from './AzureFieldConfig';

export function AzureResourceOverview({ resource }: { resource: BaseResource }) {
    const fmtSvc = useDi(FormatService);
    const changeCt = useResourceChangeCount();
    const resourceType = resource.ResourceType ?? 'Type';
    const rtFieldName = resourceType.replace(/\./g, '_');

    const azRes = useMemo(() => {
        const result = structuredClone(resource) as BaseAzureResource;
        const typeFields = result[rtFieldName] as undefined | Record<string, unknown>;
        if (typeFields) {
            delete result[rtFieldName];
            const typeFieldKeys = Object.keys(typeFields) as (keyof typeof typeFields)[];
            typeFieldKeys.forEach((key) => {
                result[`${rtFieldName}.${key}`] = typeFields[key];
            });
        }
        return result;
    }, [resource, changeCt]);

    const { azureCommonFields } = useAzureResourceFieldConfig();
    const azureTypeFields = Object.keys(azureCommonFields);

    const {
        loading: pinFieldsLoading,
        getMenuOptions: getPinMenuOptions,
        pinnedFieldConfig,
    } = useFieldPinning('ResourceOverview', 'Azure', azureTypeFields, resourceType, [], ['ResourceGroup', 'Subscription', 'SubscriptionName']);

    const commonFieldConfig = useCommonFieldConfig();

    const commonMenuOptions = useMemo(
        () =>
            ({
                getOptions: (item, _, config) => {
                    const menuSections = [
                        { header: null, options: getCopyOption(item, config) },
                        { header: 'Pinning', options: getPinMenuOptions(item) },
                    ];

                    const result = menuSections.reduce((items, section) => {
                        if (items.length) {
                            items.push('divider');
                        }
                        if (section.header && section.options.length) {
                            items.push({ type: 'header', label: section.header });
                        }
                        items.push(...section.options);

                        return items;
                    }, [] as PropertyGridOptionItem[]);

                    return result;
                },
            } as PropertyGridConfig),
        [resource, getPinMenuOptions]
    );

    const ruleBuilder = usePropertyGridConfigBuilder();
    const adjustedTypeFieldConfig = useMemo(() => {
        return [
            ruleBuilder((item) => !item.parent && item.property.toString().startsWith(rtFieldName), {
                label: (item, defaultRenderer) => {
                    if (item.itemType === 'property') {
                        const propStr = item.property.toString();
                        const prop = propStr.substring(rtFieldName.length + 1);
                        return defaultRenderer(fmtSvc.userFriendlyCamelCase(prop));
                    } else {
                        return defaultRenderer();
                    }
                },
            }),
            ruleBuilder(
                ['subscription', 'tags', 'resourceGroup', 'kind', 'name'].map((p) => `${rtFieldName}.${p}`),
                {
                    hidden: true,
                }
            ),
        ];
    }, [azRes]);

    const sharedOptions = useMemo(() => [...commonFieldConfig, ...adjustedTypeFieldConfig, azureCommonFields], [adjustedTypeFieldConfig]);

    const pinnedConfig = usePropertyGridConfig(() => [...sharedOptions, ...pinnedFieldConfig], commonMenuOptions, [pinnedFieldConfig]);
    const baseConfig = usePropertyGridConfig(() => sharedOptions, commonMenuOptions);

    return (
        <Stack>
            <FillerSwitch loading={pinFieldsLoading}>
                {() => <PropertyGrid target={azRes} {...pinnedConfig} flatten layout="labels" columns={3} />}
            </FillerSwitch>
            <CollapsibleDetailsSection label="Resource Details" shadow>
                <PropertyGrid target={azRes} {...baseConfig} />
            </CollapsibleDetailsSection>
        </Stack>
    );
}

import { getCspMktGetAwsSubscriptions, getSubscriptionGetAllSubscriptionsByCompany } from '@apis/Customers';
import { AwsMarketplaceSubscription, SubscriptionDetail } from '@apis/Customers/model';
import {
    Badge,
    Box,
    Button,
    Card,
    Divider,
    Grid,
    Group,
    Space,
    Text,
    ThemeIcon,
    useMantineTheme,
    LoadingOverlay,
    MantineColor,
    createStyles,
    Title,
} from '@mantine/core';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { Fragment, useEffect, useState } from 'react';
import { SettingsPage, SettingsPageHeader } from '../SettingsPage';
import { useLink } from '@root/Services/Router/Router';
import { getProductIcon } from '@root/Components/Applications/AppIcons';
// import { Backhoe, FileX } from 'tabler-icons-react';
import { FormatService } from '@root/Services/FormatService';
import { useDi } from '@root/Services/DI';
import { CustomColors } from '@root/Design/Themes';
import { AlertTriangle, ExternalLink } from 'tabler-icons-react';

export function Subscription() {
    const { getDescendUrl } = useNav();
    const link = useLink();
    const [subscriptions, setSubscriptions] = useState([] as SubscriptionDetail[]);
    const [offers, setOffers] = useState<AwsMarketplaceSubscription[]>([]);
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        Promise.all([
            getSubscriptionGetAllSubscriptionsByCompany().then(setSubscriptions),
            getCspMktGetAwsSubscriptions().then((o) => setOffers(o.filter((o) => o.PrivateOfferUrl && o.Status === 'Staged'))),
        ]).finally(() => setIsLoading(false));
    }, []);

    function getSubscriptionDays(sub: SubscriptionDetail) {
        if (sub.DaysRemaining! == 1) {
            return 'DAY';
        }
        return 'DAYS';
    }

    function renewalDateLabel(sub: SubscriptionDetail) {
        if (sub.DaysRemaining! < 0) {
            return 'Expired';
        }
        if (
            sub.Status === 'NonRenewing' ||
            sub.Status === 'Cancelled' ||
            sub.Term === 'Trial' ||
            sub.DaysRemaining === 0 ||
            (sub?.Renewing === 0 && sub.BillingCycles! === 0) ||
            sub.FriendlyName === 'Tag Manager - Special Offer'
        ) {
            return 'Set to Expire';
        }
        return 'Next Billing Date';
    }

    function renewalDate(sub: SubscriptionDetail) {
        var expirationDate;
        if (sub.Renewing === 1 || sub.BillingCycles! > 0) {
            expirationDate = sub.NextBillDate;
        } else {
            expirationDate = sub.EndDate;
        }
        var datedate = new Date(Date.parse(expirationDate!));
        return fmtSvc.toShortDate(datedate);
    }

    function badgeHighlight(sub: SubscriptionDetail) {
        if (sub.DaysRemaining! <= 31 && sub.Tier !== 'Trial' && (sub.Status === 'NonRenewing' || sub.Status === 'Cancelled')) {
            return 'warning';
        }
        return 'success';
    }

    function getBadgeText(sub: SubscriptionDetail) {
        if (sub.DaysRemaining! > 0 && sub.DaysRemaining! <= 31) {
            if (sub.Term === 'Trial') {
                return `${sub.DaysRemaining!} ${getSubscriptionDays(sub)} REMAINING IN TRIAL`;
            }
            if (sub.BillingCycles === 0 && sub.Status !== 'Active') {
                return `${sub.DaysRemaining!} ${getSubscriptionDays(sub)} REMAINING`;
            }
        }

        if (sub.DaysRemaining! === 0) {
            return 'EXPIRES TODAY';
        }
        if (sub.DaysRemaining! < 0) {
            return 'EXPIRED';
        }
        return '';
    }

    function setEndDate(sub: SubscriptionDetail) {
        var subEndDate;
        if (sub.NextBillDate !== null) {
            subEndDate = new Date(Date.parse(sub.NextBillDate!));
        } else {
            subEndDate = new Date(Date.parse(sub.EndDate!));
        }
        return `Your subscription will renew on ${fmtSvc.toShortDate(subEndDate)} with ${sub.FriendlyName} plan features.`;
    }

    function hideRenewal(sub: SubscriptionDetail) {
        if (sub.Status === 'NonRenewing' || sub.Status === 'Cancelled' || sub.Term === 'Trial') {
            return true;
        }
        if (sub.DaysRemaining! < 31) {
            return false;
        }
        return true;
    }

    function subscriptionTermText(sub: SubscriptionDetail) {
        if (sub.Term === 'Trial') {
            return '30 Day Trial';
        }
        if (sub.ApplicationId === 'Tag-Manager-Standard-Special-Offer-USD-Monthly') {
            return '6 Month Subscription';
        }
        if (sub.Term === 'Year') {
            return 'One-year contract, billed anually';
        }
        if (sub.Term === 'Month' && sub.Type === 'Msp') {
            return 'Billed monthly';
        }

        return 'One-year contract, billed monthly';
    }

    return (
        <>
            {isLoading && <LoadingOverlay visible={true} />}

            <SettingsPage>
                <SettingsPageHeader text="Subscriptions" />
                {isLoading ? (
                    <></>
                ) : (
                    <Box p="xl" sx={{ maxWidth: theme.breakpoints.lg }}>
                        {subscriptions?.length === 0 ? (
                            <Box>
                                <Text size={20}> No Subscriptions Found</Text>
                            </Box>
                        ) : (
                            subscriptions?.map((item) => {
                                const AppIcon = getProductIcon(item.AppType ?? '');
                                const badgeText = getBadgeText(item);
                                const renewDate = renewalDate(item);
                                return (
                                    <Fragment key={item.SubscriptionId}>
                                        <Card shadow="sm" radius="md" withBorder p={0}>
                                            <>
                                                <Group position="apart" p="md">
                                                    <Group>
                                                        <ThemeIcon radius="xl" size="lg" variant="light">
                                                            {AppIcon ? <AppIcon color={theme.colors?.primary?.[8] as CustomColors} /> : null}
                                                        </ThemeIcon>
                                                        <Group sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
                                                            <Text>{item.AppType}</Text>
                                                            <Text sx={{ marginLeft: item.Term === 'Trial' ? -70 : -40 }} size="xs">
                                                                {item.Tier === 'Exclusive' ? '' : item.Tier}
                                                            </Text>
                                                        </Group>
                                                        <Badge color={badgeHighlight(item)!} hidden={badgeText === ''}>
                                                            {badgeText!}
                                                        </Badge>
                                                    </Group>
                                                    <Button
                                                        variant="outline"
                                                        data-atid="ManageSubsButton"
                                                        component="a"
                                                        radius="md"
                                                        {...link(
                                                            getDescendUrl('subscriptionmanage', {
                                                                subscriptionId: item.SubscriptionId ?? '',
                                                                appType: item.AppType ?? '',
                                                            })
                                                        )}
                                                    >
                                                        {item.DaysRemaining! < 0 ? 'Renew Subscription' : 'Manage Subscription'}
                                                    </Button>
                                                </Group>
                                                <Divider />
                                                <Box p="lg">
                                                    <Grid>
                                                        <Grid.Col span={3}>
                                                            <Text size="sm">Subscription Term</Text>
                                                            <Text>{subscriptionTermText(item)}</Text>
                                                        </Grid.Col>
                                                        <Grid.Col span={3}>
                                                            <Text size="sm">{renewalDateLabel(item)}</Text>
                                                            <Text>{renewDate === '' ? 'Today' : renewDate}</Text>
                                                        </Grid.Col>
                                                        <Grid.Col span={3}>
                                                            <Text size="sm">Billing Frequency</Text>
                                                            {item.FirstYearDiscountApplied ? (
                                                                <>
                                                                    <Text
                                                                        color={theme.colors?.gray?.[5] as MantineColor}
                                                                        mr={4}
                                                                        sx={{ display: 'inline', fontWeight: 'lighter' }}
                                                                        strikethrough
                                                                    >
                                                                        {fmtSvc.formatMoneyNoDecimals(item.Amount!)}
                                                                    </Text>
                                                                    <Text sx={{ display: 'inline' }}>
                                                                        {fmtSvc.formatMoneyNoDecimals(item.Amount! / 2)}
                                                                    </Text>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {' '}
                                                                    {item.IsSixMonthPromo ? (
                                                                        <>
                                                                            <Text
                                                                                color={theme.colors?.gray?.[5] as MantineColor}
                                                                                sx={{ display: 'inline', fontWeight: 'lighter' }}
                                                                                strikethrough
                                                                            >
                                                                                $2616
                                                                            </Text>{' '}
                                                                            $0
                                                                        </>
                                                                    ) : (
                                                                        <Text sx={{ display: 'inline' }}>
                                                                            {fmtSvc.formatMoneyNoDecimals(item.Amount!)}
                                                                        </Text>
                                                                    )}
                                                                </>
                                                            )}
                                                            {item.Tier !== 'Trial' && !item.IsSixMonthPromo ? (
                                                                <Text sx={{ display: 'inline' }}>/ {item.Term?.toLocaleLowerCase()}</Text>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Grid.Col>
                                                        <Grid.Col span={3}>
                                                            <Text size="sm">Auto-Renewal</Text>
                                                            <Text sx={{ display: 'inline' }}>
                                                                {item.Renewing && item!.DaysRemaining! > -1 ? 'On' : 'Off'}
                                                            </Text>
                                                        </Grid.Col>
                                                    </Grid>
                                                    <Card shadow="sm" radius="md" withBorder p="xl" m="xs" hidden={hideRenewal(item)}>
                                                        <Grid>
                                                            <Grid.Col span={1}>
                                                                <i className="ti ti-info-circle-filled"></i>
                                                            </Grid.Col>
                                                            <Grid.Col span={11}>
                                                                <Text>Your subscription is set to renew as {item.FriendlyName}.</Text>
                                                                <Text>{setEndDate(item)}</Text>
                                                            </Grid.Col>
                                                        </Grid>
                                                    </Card>
                                                </Box>
                                            </>
                                        </Card>
                                        <Space h="lg" />
                                    </Fragment>
                                );
                            })
                        )}
                        {!offers.length ? null : (
                            <>
                                <Space h="xl" />
                                {offers.map((offer) => (
                                    <Card
                                        key={offer.Id}
                                        radius="lg"
                                        sx={{
                                            background: theme.colors.primary[0],
                                            borderColor: theme.colors?.primary?.[6] as CustomColors,
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                        }}
                                        shadow="xl"
                                        color={theme.colors?.primary?.[6] as CustomColors}
                                    >
                                        <Group noWrap position="apart" p="lg" align="start">
                                            <Group noWrap align="start" spacing="md">
                                                <ThemeIcon
                                                    sx={{
                                                        background: theme.colors?.primary?.[2] as CustomColors,
                                                        color: theme.colors?.primary?.[6] as CustomColors,
                                                    }}
                                                    radius="xl"
                                                    p={8}
                                                    size="xl"
                                                    mt={3}
                                                >
                                                    <AlertTriangle />
                                                </ThemeIcon>
                                                <Box>
                                                    <Title sx={{ color: theme.colors.primary[9] }}>Private Offer Available</Title>
                                                    <Space h="xs" />
                                                    <Text sx={{ color: theme.colors.primary[8] }}>
                                                        A private offer for a {offer.SubscriptionType?.FriendlyName} subscription is available.
                                                    </Text>
                                                </Box>
                                            </Group>
                                            <Button
                                                size="lg"
                                                component="a"
                                                target="_blank"
                                                href={offer.PrivateOfferUrl ?? ''}
                                                leftIcon={<ExternalLink />}
                                            >
                                                View Offer
                                            </Button>
                                        </Group>
                                    </Card>
                                ))}
                            </>
                        )}
                    </Box>
                )}
            </SettingsPage>
        </>
    );
}
endpoint('subscription', Subscription, 'Subscriptions');

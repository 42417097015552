import { MapContract, MapContractDocument } from '@apis/TagManager/model';
import styled from '@emotion/styled';
import {
    ActionIcon,
    Anchor,
    Box,
    Button,
    Card,
    FileButton,
    Group,
    LoadingOverlay,
    NumberInput,
    Select,
    Stack,
    Text,
    TextInput,
    Title,
    Tooltip,
    UnstyledButton,
    useMantineTheme,
} from '@mantine/core';
import { Clearfix, Score } from '@root/Design/Primitives';
import { CustomColors, theme } from '@root/Design/Themes';
import { BasicApi } from '@root/Services/BasicApi';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { AlertTriangle, FileDownload, CircleCheck, FilePencil, CircleOff, Trash } from 'tabler-icons-react';
import { TagOption, getContractTagOptions, validateContractId } from '../../Services/MapCostAllocationTagService';
import { ReportContainer, ReportHeader, ReportPanel } from '../Design';
import { MapContractHomeModel } from '../Models';
import { Account } from '@apis/Customers/model';
import { ColumnConfig, DataGridState } from '@root/Components/DataGrid/Models';
import { DataGrid } from '@root/Components/DataGrid';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { Observer } from 'mobx-react';
import {
    deleteMapContractsDeleteAgreementDocument,
    postMapContractsUpdateMapContract,
    postMapContractsUploadAgreementDocument,
} from '@apis/TagManager';
import addYears from 'date-fns/addYears';
import { MapContractsEvents } from '../../Services/MapContractsEvents';
import { observable, toJS } from 'mobx';
import { DatePicker } from '@mantine/dates';
import { useLink } from '@root/Services/Router/Router';
import { useNav } from '@root/Services/NavigationService';
import { GridFullCell } from '@root/Components/DataGrid/Design';
import addDays from 'date-fns/addDays';
import { openConfirmModal } from '@mantine/modals';
import { MpeIdInput } from '../Components';

function ContractDetails({ contract }: { contract: MapContract }) {
    const theme = useMantineTheme();
    const model = useDi(MapContractHomeModel);
    const mapEvents = useDi(MapContractsEvents);
    const fmtSvc = useDi(FormatService);
    const [editMode, setEditMode] = useState(false);

    const contractModel = useMemo(() => {
        return observable({
            loading: false,
            contract,
            get startDate() {
                return fmtSvc.toLocalDate(this.contract.ContractStartDate);
            },
            set startDate(date: Date | null) {
                this.contract.ContractStartDate = date as unknown as string;
            },
            get years() {
                return this.contract.ContractTermYears?.toString() ?? '1';
            },
            set years(value: string | null) {
                this.contract.ContractTermYears = !value ? 1 : parseInt(value);
                this.contract.ContractEndDate = undefined;
            },
            get endDate() {
                const start = this.contract.ContractStartDate;
                const term = this.contract.ContractTermYears ?? 1;
                const result = this.contract.ContractEndDate ? fmtSvc.toLocalDate(this.contract.ContractEndDate) : undefined;

                return result ?? addDays(addYears(fmtSvc.toLocalDate(start), term), -1);
            },
            set endDate(date: Date | null) {
                this.contract.ContractEndDate = date as unknown as string;
            },
            get isValid() {
                return this.contract.Name && this.contract.ProgramId && validateContractId(this.contract.ProgramId);
            },
        });
    }, [contract]);

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const save = async () => {
        if (contractModel.isValid) {
            await postMapContractsUpdateMapContract(toJS(contractModel.contract));
            model.reloadContract();
            mapEvents.onContractsChanged.emit();
        }
    };

    return (
        <Observer
            render={() => (
                <>
                    <Group mt="-1.5rem" position="right">
                        {!editMode ? (
                            <Button variant="outline" leftIcon={<FilePencil />} onClick={toggleEditMode} data-atid="EditContractButton">
                                Edit Contract
                            </Button>
                        ) : (
                            <>
                                <Button variant="outline" onClick={toggleEditMode} data-atid="ContractEditCancel">
                                    Cancel
                                </Button>
                                <Button disabled={!contractModel.isValid} onClick={save} data-atid="ContractEditSave">
                                    Save
                                </Button>
                            </>
                        )}
                    </Group>
                    <Box>
                        <Box component={DetailRow}>
                            <Text component={DetailLabel} size="sm">
                                Contract Name:
                            </Text>
                            {!editMode ? (
                                <Text component={DetailField} size="sm">
                                    {model.getName()}
                                </Text>
                            ) : (
                                <TextInput
                                    sx={{ width: '400px' }}
                                    error={!contractModel.contract.Name}
                                    value={contractModel.contract.Name ?? ''}
                                    onChange={(e) => (contractModel.contract.Name = e.currentTarget.value)}
                                    data-atid="EditNameInput"
                                />
                            )}
                        </Box>
                        <Box component={DetailRow} sx={{ input: { width: 300 } }}>
                            <Text component={DetailLabel} size="sm">
                                MPE ID:
                            </Text>

                            {!editMode ? (
                                <Text component={DetailField} size="sm">
                                    {contract.ProgramId}
                                </Text>
                            ) : (
                                <MpeIdInput
                                    hideLabel
                                    value={contractModel.contract.ProgramId ?? ''}
                                    onChange={(e) => (contractModel.contract.ProgramId = e)}
                                    data-atid="MPEInput"
                                />
                            )}
                        </Box>
                        <Box component={DetailRow} sx={{ input: { width: 300 } }}>
                            <Text component={DetailLabel} size="sm">
                                Incremental ARR:
                            </Text>

                            {!editMode ? (
                                <Text component={DetailField} size="sm">
                                    ${' '}
                                    {contractModel.contract.AnticipatedAnnualSpend
                                        ? contractModel.contract.AnticipatedAnnualSpend.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                                        : '0'}
                                </Text>
                            ) : (
                                <NumberInput
                                    hideControls
                                    value={contractModel.contract.AnticipatedAnnualSpend ?? 0}
                                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                                    formatter={(value) => {
                                        value = value?.toString().replace(/[^0-9]/g, '');
                                        contractModel.contract.AnticipatedAnnualSpend = Number(value);
                                        return !Number.isNaN(parseFloat(value!))
                                            ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                                            : '$ ';
                                    }}
                                />
                            )}
                        </Box>
                        <Box component={DetailRow}>
                            <Text component={DetailLabel} size="sm">
                                Contract Duration:
                            </Text>
                            {!editMode ? (
                                <Text component={DetailField} size="sm">
                                    {contract.ContractTermYears} year{contract.ContractTermYears === 1 ? '' : 's'}
                                </Text>
                            ) : (
                                <Select
                                    sx={{ width: '300px' }}
                                    value={contractModel.years}
                                    onChange={(e) => (contractModel.years = e)}
                                    data={[
                                        { label: '1 Year', value: '1' },
                                        { label: '2 Years', value: '2' },
                                        { label: '3 Years', value: '3' },
                                    ]}
                                    data-atid="EditDurationSelect"
                                />
                            )}
                        </Box>
                        <Box component={DetailRow}>
                            <Text component={DetailLabel} size="sm">
                                Start Date:
                            </Text>
                            {!editMode ? (
                                <Text component={DetailField} size="sm">
                                    {fmtSvc.toShortDate(fmtSvc.toLocalDate(contract.ContractStartDate))}
                                </Text>
                            ) : (
                                <DatePicker sx={{ width: '300px' }} value={contractModel.startDate} onChange={(e) => (contractModel.startDate = e)} />
                            )}
                        </Box>
                        <Box component={DetailRow}>
                            <Text component={DetailLabel} size="sm">
                                End Date:
                            </Text>
                            {!editMode ? (
                                <Text component={DetailField} size="sm">
                                    {contract.ContractEndDate
                                        ? fmtSvc.toShortDate(fmtSvc.toLocalDate(addDays(new Date(contract.ContractEndDate), -1)))
                                        : fmtSvc.toShortDate(
                                              addDays(addYears(fmtSvc.toLocalDate(contract.ContractStartDate), contract.ContractTermYears ?? 1), -1)
                                          )}
                                </Text>
                            ) : (
                                <DatePicker sx={{ width: '300px' }} value={contractModel.endDate} onChange={(e) => (contractModel.endDate = e)} />
                            )}
                        </Box>
                    </Box>
                </>
            )}
        />
    );
}

const fileTypeInfo = new Map<string, ReactNode>([
    ['pdf', <i className="ti ti-file-type-pdf" />],
    ['docx', <i className="ti ti-file-type-docx" />],
    ['html', <i className="ti ti-file-type-html" />],
    ['other', <i className="ti ti-file" />],
]);
const getFileInfo = (name: string) => {
    const parts = name.split('.');
    const ext = parts[parts.length - 1];
    const filename = parts.slice(0, parts.length - 1)?.join('.') ?? '';
    return {
        icon: fileTypeInfo.get(ext) ?? fileTypeInfo.get('other'),
        name: filename,
        ext,
    };
};

function ContractDocuments({ contract }: { contract: MapContract }) {
    const [grid, setGrid] = useState<DataGridModel>();
    const [loading, setLoading] = useState(false);
    const model = useDi(MapContractHomeModel);
    const docs = model.contract?.AgreementDocument ?? [];
    const fmtSvc = useDi(FormatService);

    const uploadFile = async (file: File) => {
        setLoading(true);
        try {
            await postMapContractsUploadAgreementDocument({ file: file ?? undefined }, { mapContractId: contract.Id });
            await model.reloadContractOnly();
        } finally {
            setLoading(false);
        }
    };

    const deleteFile = async (doc: MapContractDocument) => {
        setLoading(true);
        try {
            await deleteMapContractsDeleteAgreementDocument({ fileId: doc.Id ?? '', mapContractId: contract.Id });
            await model.reloadContractOnly();
        } finally {
            setLoading(false);
        }
    };

    const confirmDeleteFile = async (doc: MapContractDocument) => {
        openConfirmModal({
            title: <Title order={3}>Remove Attachment</Title>,
            children: <Text>Are you sure you want to remove the attachment {doc.Name}?</Text>,
            style: { outline: 'none' },
            labels: {
                confirm: 'Remove Attachment',
                cancel: 'Cancel',
            },
            onConfirm: () => {
                deleteFile(doc);
            },
        });
    };

    const columnResult: ColumnConfig<MapContractDocument>[] = [
        {
            accessor: (item) => item.Name,
            id: 'Name',
            sortField: 'Name',
            header: 'Name',
            defaultWidth: 350,
            type: 'string',
            cellRenderer: (item) => {
                const { name, icon } = getFileInfo(item.Name ?? '');
                return (
                    <DownloadContract contract={contract} doc={item}>
                        <Text span>
                            {icon} {name}
                        </Text>
                    </DownloadContract>
                );
            },
        },
        {
            accessor: 'Date',
            defaultWidth: 150,
            header: 'Date Uploaded',
            id: 'Date',
            type: 'date',
            formatter: (item, value) => (!value ? '' : fmtSvc.toShortDate(fmtSvc.toLocalDate(value))),
        },
        {
            accessor: () => '',
            id: 'delete',
            defaultWidth: 40,
            type: 'string',
            cellRenderer: (item) => {
                return (
                    <GridFullCell className="delete-icon" style={{ justifyContent: 'center', display: 'flex' }}>
                        <Tooltip withinPortal withArrow position="bottom" label="Remove Attachment">
                            <ActionIcon onClick={() => confirmDeleteFile(item)} data-atid="RemoveAttachment">
                                <Trash size={16} />
                            </ActionIcon>
                        </Tooltip>
                    </GridFullCell>
                );
            },
            exportOptions: {
                hidden: true,
            },
        },
    ];

    return (
        <>
            <LoadingOverlay visible={loading} />
            <Box component={AttachmentGridContainer}>
                <DataGrid
                    dataSource={docs}
                    columns={columnResult}
                    hideColumnSelector
                    showRefresh
                    hideFilter={true}
                    hideGlobalSearch={true}
                    onModelLoaded={setGrid}
                />
            </Box>

            <FileButton onChange={(f) => uploadFile(f!)}>
                {({ onClick }) => (
                    <Observer
                        render={() => (
                            <Text onClick={onClick} component={AddButton} sx={{ fontSize: 15, marginLeft: '5px' }} data-atid="AddAttachmentLink">
                                + Add Attachment
                            </Text>
                        )}
                    />
                )}
            </FileButton>
        </>
    );
}

const AttachmentGridContainer = styled.div`
    height: 200px;
    svg {
        font-size: 16px;
    }
    tr:hover .delete-icon {
        visibility: visible;
    }
    .delete-icon {
        visibility: hidden;
    }
`;

function DownloadContract({ contract, doc, children }: { contract: MapContract; doc: MapContractDocument; children: ReactNode }) {
    const basicApi = useDi(BasicApi);
    const actualName = doc.Name ?? 'MapContract.pdf';
    const nameParts = actualName.split('.');
    const ext = nameParts[nameParts.length - 1];
    const name = nameParts.slice(0, nameParts.length - 1).join('.');
    const shortName = name.length > 20 ? name.slice(0, 20) + '..' : name;
    const displayName = `${shortName}.${ext}`;

    const downloadFile = useCallback(() => {
        const params = {
            mapContractId: contract.Id,
            fileId: doc.Id,
        };
        basicApi.download(doc?.Name ?? 'MapContract.pdf', { url: '/MapContracts/DownloadAgreementDocument', method: 'GET', params }, 'TagManager');
    }, [doc.Id, contract.Id, doc.Name]);

    return (
        <GridFullCell>
            {contract.AgreementDocument ? (
                <Tooltip withinPortal position="bottom-start" withArrow label={`Download ${displayName}`}>
                    <Anchor onClick={downloadFile}>{children}</Anchor>
                </Tooltip>
            ) : null}
        </GridFullCell>
    );
}

function ContractAccounts() {
    const theme = useMantineTheme();
    const model = useDi(MapContractHomeModel);
    const [grid, setGrid] = useState<DataGridModel>();

    const accounts = model.accounts;

    const columnResult: ColumnConfig<Account>[] = [
        {
            accessor: (item) => item.AwsAccountId,
            id: 'AwsAccountId',
            sortField: 'AwsAccountId',
            header: 'Account ID',
            defaultWidth: 150,
            defaultFixed: true,
            type: 'string',
        },
        {
            accessor: (item) => item.Name,
            id: 'AccountName',
            sortField: 'AccountName',
            header: 'Account Name',
            defaultWidth: 350,
            defaultFixed: true,
            type: 'string',
        },
        {
            accessor: (item) => item.IsMasterAccount,
            id: 'isMasterAccount',
            sortField: 'isMasterAccount',
            header: 'Account Type',
            defaultWidth: 200,
            defaultFixed: true,
            type: 'boolean',
            cellRenderer: (item) => {
                return item.IsMasterAccount ? 'Management Account' : 'Member Account';
            },
            exportOptions: {
                renderer: (item) => (item.IsMasterAccount ? 'Management Account' : 'Member Account'),
            },
        },
    ];

    return (
        <>
            <div style={{ height: 200 }}>
                <DataGrid
                    dataSource={accounts}
                    columns={columnResult}
                    hideColumnSelector
                    showRefresh
                    hideFilter={true}
                    hideGlobalSearch={true}
                    onModelLoaded={setGrid}
                />
            </div>
        </>
    );
}

function ContractTags({ contractId }: { contractId: string }) {
    var options = getContractTagOptions(contractId);
    const [grid, setGrid] = useState<DataGridModel>();
    options = options.filter((x) => x.other == undefined);
    const columnResult: ColumnConfig<TagOption>[] = [
        {
            accessor: (item) => 'Key',
            id: 'Key',
            sortField: 'Key',
            header: 'Key',
            defaultWidth: 150,
            defaultFixed: true,
            type: 'string',
            cellRenderer: (item) => {
                return 'map-migrated';
            },
        },
        {
            accessor: (item) => item.value,
            id: 'Value',
            sortField: 'Value',
            header: 'Value',
            defaultWidth: 200,
            defaultFixed: true,
            type: 'string',
        },
        {
            accessor: (item) => item.title,
            id: 'title',
            sortField: 'title',
            header: 'Use Case',
            defaultWidth: 600,
            defaultFixed: true,
            type: 'string',
        },
    ];

    return (
        <>
            <div style={{ height: '252px' }}>
                <DataGrid
                    dataSource={options}
                    columns={columnResult}
                    hideColumnSelector
                    showRefresh
                    hideFilter={true}
                    hideGlobalSearch={true}
                    onModelLoaded={setGrid}
                />
            </div>
        </>
    );
}

export function ContractOverview({ contract }: { contract: MapContract }) {
    const theme = useMantineTheme();
    const link = useLink();
    const { getDataUrl } = useNav();
    return (
        <ReportPanel>
            <ReportHeader>
                <Box>Contract Details</Box>
            </ReportHeader>
            <ReportContainer bg="gray">
                <Stack>
                    <SectionCard title="General Information">
                        <ContractDetails contract={contract} />
                    </SectionCard>
                    <SectionCard title="Attachments">
                        <Box component={SectionBody}>
                            <ContractDocuments contract={contract} />
                        </Box>
                    </SectionCard>
                    <SectionCard
                        title={
                            <Group position="apart">
                                Accounts
                                <Button
                                    component="a"
                                    {...link(getDataUrl({ id: contract?.Id?.toString() ?? '', section: 'connection' }))}
                                    variant="outline"
                                    data-atid="ManageAccountsButton"
                                >
                                    Manage Accounts
                                </Button>
                            </Group>
                        }
                    >
                        <Box component={SectionBody}>
                            <ContractAccounts />
                        </Box>
                    </SectionCard>
                    <SectionCard
                        title={
                            <Group position="apart">
                                Eligible MAP Tags
                                <Button
                                    variant="outline"
                                    component="a"
                                    target="_blank"
                                    href="https://s3.us-west-2.amazonaws.com/map-2.0-customer-documentation/html/AWSMapDocs/setting-up.html"
                                >
                                    Tagging Guide
                                </Button>
                            </Group>
                        }
                    >
                        <Text size="sm" component={SectionHeader}></Text>
                        <Box component={SectionBody}>
                            <ContractTags contractId={contract.ProgramId!.toString()} />
                        </Box>
                    </SectionCard>
                </Stack>
            </ReportContainer>
        </ReportPanel>
    );
}

function SectionCard({ children, title }: { children: ReactNode; title: ReactNode }) {
    return (
        <Card radius="md" withBorder>
            <Text mb="sm" weight="bolder">
                {title}
            </Text>
            {children}
        </Card>
    );
}

const SectionHeader = styled.div`
    font-weight: bold;
    margin-bottom: 10px;
`;
const SectionBody = styled.div`
    height: 100%;
`;
const ContractBackground = styled.div`
    background: ${(p) => p.theme.colors.gray[2]};
`;

const ConnectionSuccess = styled.div`
    color: ${(p) => p.theme.colors.success[6]};
`;

const ConnectionFailure = styled.div`
    color: ${(p) => p.theme.colors.warning[6]};
`;

const EditFormControls = styled.div`
    float: right;
`;

const AddButton = styled.div`
    color: ${(p) => p.theme.colors.primary[6]};
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

const AddAnchor = styled.a`
    color: ${(p) => p.theme.colors.primary[6]};
    font-weight: bold;
    margin-top: 15px;
    display: block;
    cursor: pointer;
`;

const DetailRow = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 0.5rem;
`;

const DetailLabel = styled.div`
    width: 180px;
    text-align: right;
    padding: 8px 0;
    font-weight: bold;
`;
const DetailField = styled.div`
    text-align: left;
`;

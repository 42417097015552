import { BaseResource, TagResourcesJob } from '@apis/Resources/model';
import styled from '@emotion/styled';
import { TextInput, Group, Button, useMantineTheme, Popover, Tooltip, Anchor, Space } from '@mantine/core';
import { useDisclosure, useForceUpdate } from '@mantine/hooks';
import { useDi, useDiContainer } from '@root/Services/DI';
import { useEvent } from '@root/Services/EventEmitter';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { InlineTaggingCreditBalance } from '@root/Site/TagManager/Components/InlineTaggingCreditBalance';
import { useState, useCallback, ReactNode, useEffect, useMemo } from 'react';
import { ActivityDetailsPanelService } from '../../Actions/ActionDetails';
import type { ResourceGridModel } from '../ResourcesGrid';
import { TagValidation } from './TagValidation';
import { TagModel } from './TagModel';
import { MapWorkloadTag } from './MapWorkloadTag';
import { InlineTagEditService } from './InlineTagEditService';
import { SectionedPopover, SectionedPopoverToolbar } from '@root/Design/Primitives';
import { Trash } from 'tabler-icons-react';

interface InlineEditTagProps {
    item: BaseResource;
    tag: string;
    close: () => void;
    owner: { inlineTagEditSvc: InlineTagEditService; inlineTaggingEnabled: boolean };
    onChanging?: () => void;
    onChange: () => void;
    onSizeChange: () => void;
    allowDelete?: boolean;
    hideCredits?: boolean;
    renderInput?: (mode: 'edit' | 'delete', value: string, setValue: (value: string | null) => void) => ReactNode;
    disableApply?: boolean;
}

const mapMigratedKey = 'map-migrated';
const mapWorkloadKey = 'map-workload';

export function InlineEditTag({
    item,
    tag,
    close,
    owner,
    onChange,
    onChanging,
    onSizeChange,
    renderInput,
    allowDelete,
    hideCredits,
    disableApply,
}: InlineEditTagProps) {
    const initialValue = item.CsTags?.[tag];
    const [outOfCredits, setOutOfCredits] = useState<boolean>(hideCredits === true ? false : true);
    const originalValue = item.CsTags?.[tag] ?? '';
    const [value, setValue] = useState(item.CsTags?.[tag] ?? '');
    const [valueSelected, setValueSelected] = useState<boolean>(false);
    const notificationSvc = useDi(NotificationService);
    const [mode, setMode] = useState<'edit' | 'delete'>('edit');
    const [deleteValue, setDeleteValue] = useState<string>('');
    const [tagValid, setTagValid] = useState<boolean>(true);
    const container = useDiContainer();
    const tagModel = container.resolve(TagModel);
    allowDelete = allowDelete ?? true;
    useEvent(tagModel.TagValid, (value) => setTagValid(value));
    const [newMapWorkloadValue, setNewMapWorkloadValue] = useState<string>('');

    const [mapWorkloadValue, setMapWorkloadValue] = useState<string>(item.CsTags?.[mapWorkloadKey] ?? '');
    const handleNewMapWorkloadTagValue = (value: string) => {
        setNewMapWorkloadValue(value);
    };

    const applyDisabled = useCallback(
        () =>
            tag == mapWorkloadKey
                ? (newMapWorkloadValue == '' || mapWorkloadValue == newMapWorkloadValue) && mode != 'delete'
                : outOfCredits ||
                  value == originalValue ||
                  (mode !== 'delete' && !tagValid) ||
                  (disableApply != undefined && disableApply) ||
                  (renderInput && mode !== 'delete' && tag == mapMigratedKey && value == originalValue && mapWorkloadValue == ''),
        [outOfCredits, value, mapWorkloadValue, originalValue, mode, tagValid, disableApply, newMapWorkloadValue]
    );

    const apply = useCallback(() => {
        if (!applyDisabled()) {
            onChanging?.();
            owner.inlineTagEditSvc.update(item, mode === 'delete' ? deleteTag() : updateTag(), tag).then(() => {
                const status = owner.inlineTagEditSvc.getStatus(item, tag);
                if (status?.status === 'bad-request') {
                    notificationSvc.notify('Tagging failed', 'An unexpected error occurred while tagging', 'error', <></>);
                    owner.inlineTagEditSvc.consumeStatus(item, tag);
                }
                onChange();
            });
            close();
        }
    }, [value, applyDisabled]);

    const updateTag = useCallback(() => {
        var tagsToAdd = [];
        if (value != '') {
            tagsToAdd.push({ Key: tag, Value: value });
        }
        if (mode !== 'delete' && mapWorkloadValue !== newMapWorkloadValue && newMapWorkloadValue !== '') {
            tagsToAdd.splice(0, 1, { Key: mapWorkloadKey, Value: newMapWorkloadValue });
        }
        return {
            OverwriteConflicts: true,
            AddTags: tagsToAdd,
            ResourceIds: [{ CloudPlatform: item.CloudPlatform, ResourceId: item.Id, ResourceType: item.ResourceType }],
        } as TagResourcesJob;
    }, [value, mapWorkloadValue, newMapWorkloadValue]);

    const deleteTag = useCallback(() => {
        var tagsToDelete = [{ Key: tag, Value: deleteValue }];
        if (tag == mapMigratedKey) {
            tagsToDelete.push({ Key: mapWorkloadKey, Value: mapWorkloadValue });
        }
        return {
            DeleteTags: tagsToDelete,
            ResourceIds: [{ CloudPlatform: item.CloudPlatform, ResourceId: item.Id, ResourceType: item.ResourceType }],
        } as TagResourcesJob;
    }, [value]);

    const prepareDelete = useCallback(() => {
        setMode('delete');
        setDeleteValue(value);
        setValue('<< No Tag >>');
    }, [mode]);

    const handleEnter = useCallback(
        (evt: React.KeyboardEvent<HTMLDivElement>) => {
            if (evt.key === 'Enter' && !applyDisabled()) {
                apply();
            }
        },
        [apply, applyDisabled]
    );

    const rendererSetValue = useCallback(
        (value: string | null) => {
            setValueSelected(true);
            if (value === null) {
                prepareDelete();
            } else {
                setMode('edit');
                setValue(value ?? '');
            }
        },
        [prepareDelete]
    );

    return (
        <div onKeyUp={handleEnter}>
            <SectionedPopoverToolbar>
                <Group position="right">
                    {initialValue === undefined || outOfCredits || !allowDelete ? null : (
                        <>
                            <Tooltip label="Delete this tag">
                                <Anchor underline={false} onClick={() => prepareDelete()} sx={{ lineHeight: 1 }}>
                                    <Trash size={20} />
                                </Anchor>
                            </Tooltip>
                            <Space w={1} sx={{ flex: 1 }} />
                        </>
                    )}
                    <Button onClick={apply} disabled={applyDisabled()} data-atid="ApplyInlineTagButton">
                        Apply
                    </Button>

                    <Button variant="outline" onClick={close} data-atid="CancelInlineTagButton">
                        Cancel
                    </Button>
                </Group>
            </SectionedPopoverToolbar>

            {!hideCredits ? (
                <>
                    <Space h={5} />
                    <InlineTaggingCreditBalance onUpdate={onSizeChange} onOutOfCredits={setOutOfCredits}></InlineTaggingCreditBalance>
                </>
            ) : null}
            <Space h={5} />
            {tag !== mapWorkloadKey ? (
                renderInput ? (
                    renderInput(mode, value, rendererSetValue)
                ) : (
                    <TextInput
                        disabled={mode === 'delete'}
                        autoFocus
                        value={value}
                        onChange={(e) => setValue(e.currentTarget.value)}
                        data-atid="InlineTagTextInput"
                    />
                )
            ) : null}

            {tag == mapWorkloadKey ? (
                <MapWorkloadTag
                    topSpace={true}
                    bottomSpace={false}
                    mapWorkloadKey={mapWorkloadKey}
                    mapWorkloadValue={mapWorkloadValue}
                    newMapWorkloadTagValue={handleNewMapWorkloadTagValue}
                />
            ) : null}

            <Space h={5} />
            {mode !== 'delete' ? <TagValidation value={value} model={tagModel}></TagValidation> : null}
            <Space h={5} />
        </div>
    );
}

export const EditableTagCell = styled.div`
    position: relative;
    .status {
        background-color: #fff;
        height: 20px;
        width: 20px;
        top: 5px;
        right: -5px;
        position: absolute;
        ::after {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            left: 4.5px;
            top: 5px;
            position: absolute;
            border-radius: 5px;
            box-sizing: border-box;
            animation: anim-beat 2s infinite linear;
        }
    }
    &.side-popover {
        margin: 0 -8px;
        padding: 0 8px;
        border: solid 2px ${(p) => p.theme.colors.primary[5]};
        height: 100%;
        > div {
            line-height: 26px;
        }
        ::after {
            content: '';
            width: 10px;
            height: 10px;
            position: absolute;
            right: -5px;
            top: 8px;
            transform: rotate(45deg);
            background-color: ${(p) => p.theme.colors.primary[5]};
        }
    }
    &.status.empty .selector {
        background-color: ${(p) => p.theme.colors.gray[5] + '10'};
    }
    &.status.failed .selector {
        background-color: ${(p) => p.theme.colors.error[2]} !important;
        transition: background-color 2s;
        position: relative;
        &::after {
            font-family: 'Font Awesome 6 Pro';
            content: '\\f071';
            position: absolute;
            display: block;
            width: 15px;
            height: 15px;
            right: 10px;
            top: 1px;
            color: ${(p) => p.theme.colors.error[5]};
        }
    }
    &.status.completed .selector {
        background-color: #fff0;
        transition: background-color 2s;
    }
    &.status.started .selector {
        background-color: ${(p) => p.theme.colors.primary[2]} !important;
        background-image: linear-gradient(90deg, #fff0, #fff8, #fff0);
        background-size: 40px 100%; // width of the shine
        background-repeat: no-repeat; // No need to repeat the shine effect
        background-position: left -40px top 0;
        animation: shine 1s ease infinite;
    }
    &.status.done .selector {
        animation: singletag 1.5s;
    }
    &.status.bulkdone .selector {
        animation: bulktags 1.5s;
    }
    &.status.bad-request .selector {
        background-color: ${(p) => p.theme.colors.error[2]} !important;
        transition: background-color 2s;
        position: relative;
        &::after {
            font-family: 'Font Awesome 6 Pro';
            content: '\\f071';
            position: absolute;
            display: block;
            width: 15px;
            height: 15px;
            right: 10px;
            top: 1px;
            color: ${(p) => p.theme.colors.error[5]};
        }
    }

    @keyframes shine {
        to {
            // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
            background-position: right -40px top 0;
        }
    }

    @keyframes singletag {
        0% {
            background-color: ${(p) => p.theme.colors.gray[2]};
        }
        50% {
            background-color: ${(p) => p.theme.colors.success[2]};
        }
        100% {
            background-color: #fff0;
        }
    }

    @keyframes bulktags {
        0% {
            background-color: ${(p) => p.theme.colors.gray[2]};
        }
        50% {
            background-color: ${(p) => p.theme.colors.success[2]};
        }
        100% {
            background-color: #fff0;
        }
    }

    @keyframes errorstatus {
        50% {
            background-color: ${(p) => p.theme.colors.error[3]};
        }
        100% {
            background-color: #fff;
        }
    }
`;

interface InlineEditTagPopoverProps {
    item: BaseResource;
    tag: string;
    children: ReactNode;
    grid: ResourceGridModel;
    onChange: () => void;
    allowDelete?: boolean;
    hideCredits?: boolean;
    renderInput?: (mode: 'edit' | 'delete', value: string, setValue: (value: string | null) => void) => ReactNode;
    popoverMode?: 'side';
    disableApply?: boolean;
}
export function InlineEditTagPopover({
    tag,
    item,
    children,
    grid,
    onChange,
    allowDelete,
    renderInput,
    popoverMode,
    hideCredits,
    disableApply,
}: InlineEditTagPopoverProps) {
    const theme = useMantineTheme();
    const [opened, { open, close, toggle }] = useDisclosure(false);
    const activityPanelSvc = useDi(ActivityDetailsPanelService);
    const [cellStatus, setCellStatus] = useState<string>('init');
    const refreshData = useCallback(() => grid.refreshDataOnly(), [grid]);
    useEvent(grid.tagJobListener.newTagJobs);
    useEvent(grid.tagJobListener.jobsCompleted, refreshData);
    const status = grid.inlineTagEditSvc.getStatus(item, tag);
    const hasBulkPendingChanges = grid.tagJobListener.hasPendingChanges(item, tag);
    const pendingChanges = status?.status === 'started' || hasBulkPendingChanges;
    const valueEmpty = !item.CsTags?.[tag];
    const error = status?.status === 'failed';
    const [positionChanges, setPositionChanges] = useState(0);
    const handleSizeChange = useCallback(() => setPositionChanges((p) => p + 1), [setPositionChanges]);
    const positionChangeDep = useMemo(() => [positionChanges], [positionChanges]);

    const handleToggle = useCallback(() => {
        if (status?.status === 'failed') {
            grid.inlineTagEditSvc.consumeStatus(item, tag);
            if (status.jobId) {
                activityPanelSvc.openByJobId(status.jobId);
                setCellStatus('completed');
            }
            refresh();
        } else if (grid.inlineTaggingEnabled) {
            open();
        }
    }, [status, open]);
    useEffect(close, [item]);

    if (status?.status === 'done') {
        setTimeout(function () {
            grid.inlineTagEditSvc.consumeStatus(item, tag);
        }, 2000);
    }

    useEffect(() => {
        if (pendingChanges) {
            setCellStatus('started');
        } else if (status?.status === 'done') {
            setCellStatus('done');
        } else if (!status?.status && cellStatus === 'started' && !hasBulkPendingChanges) {
            setCellStatus('bulkdone');
        } else if (status?.status === 'failed') {
            setCellStatus('failed');
        } else {
            setCellStatus('init');
        }
    }, [status?.status, hasBulkPendingChanges]);

    const refresh = useForceUpdate();
    return opened ? (
        <Popover
            opened={true}
            styles={{
                dropdown: {
                    border: `solid 1px ${theme.colors.gray[4]}`,
                },
                arrow: {
                    borderWidth: '1px 0 0 1px',
                    borderColor: theme.colors.gray[4],
                    borderStyle: 'solid',
                },
            }}
            radius="md"
            closeOnClickOutside
            exitTransitionDuration={0}
            transitionDuration={0}
            withinPortal
            onClose={close}
            closeOnEscape
            positionDependencies={positionChangeDep}
            withArrow={popoverMode === 'side' ? false : true}
            arrowSize={15}
            position={popoverMode === 'side' ? 'right' : undefined}
            offset={0}
            shadow="md"
        >
            <Popover.Target>
                <EditableTagCell className={`${status?.status} ${popoverMode === 'side' ? 'side-popover' : ''}`} onClick={handleToggle}>
                    {children}
                </EditableTagCell>
            </Popover.Target>
            <SectionedPopover sx={{ width: 300 }}>
                <InlineEditTag
                    hideCredits={hideCredits}
                    allowDelete={allowDelete}
                    renderInput={renderInput}
                    onSizeChange={handleSizeChange}
                    close={close}
                    tag={tag}
                    item={item}
                    owner={grid}
                    onChange={onChange}
                    disableApply={disableApply}
                />
            </SectionedPopover>
        </Popover>
    ) : (
        <EditableTagCell
            className={`status ${pendingChanges ? 'started' : cellStatus ? cellStatus : status?.status} ${valueEmpty ? 'empty' : ''}`}
            onClick={handleToggle}
        >
            {children}
            {pendingChanges || error ? (
                <Tooltip label={error ? 'Change failed' : 'Change pending'} withinPortal position="right">
                    <div></div>
                </Tooltip>
            ) : null}
        </EditableTagCell>
    );
}

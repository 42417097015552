import { DataGrid } from '@root/Components/DataGrid';
import { ColumnConfig, DataGridState } from '@root/Components/DataGrid/Models';
import { Anchor, LoadingOverlay, Popover, Space, UnstyledButton } from '@mantine/core';
import { useState, useMemo, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { useDi, useDiContainer } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { ValuesGroupOtherText, SchemaService, FieldInfo } from '@root/Services/QueryExpr';
import { EventEmitter } from '@root/Services/EventEmitter';
import { useLink } from '@root/Services/Router/Router';
import { useNav } from '@root/Services/NavigationService';
import { useDisclosure } from '@mantine/hooks';
import { FieldPicker } from '@root/Components/Picker/FieldPicker';
import { colorPalette } from '@root/Design/Themes';
import { SpendingCoverageService } from '@root/Services/TagIntelligence/SpendingCoverageService';

export type CurDataItem = {
    type: string;
    totalSpend: number;
    taggedSpend: number;
    unsupportedSpend: number;
    untaggedSpend: number;
};

function SpendCoverageGrid() {
    const spendingCoverageSvc = useDi(SpendingCoverageService);
    const formatSvc = useDi(FormatService);
    const spendData: CurDataItem[] = spendingCoverageSvc.spendingResults;
    const schema: SchemaService | null = spendingCoverageSvc.schema;
    const filterSchema: SchemaService | null = spendingCoverageSvc.filterSchema;
    const loading: boolean = spendingCoverageSvc.loading;
    const [viewBy, setViewBy] = useState(schema?.getField('ResourceType'));
    const [filterBy, setFilterTag] = useState(schema?.getField('ResourceType'));
    const dataEvent = useMemo(() => new EventEmitter<CurDataItem[] | undefined>(undefined), []);
    const link = useLink();
    const { getMoveUrl } = useNav();

    useEffect(() => {
        spendingCoverageSvc.refreshSchema();
    }, []);

    useEffect(() => {
        (async () => {
            const viewByField = viewBy?.path ?? 'resourceType';
            spendingCoverageSvc.setViewByField(viewByField);
            spendingCoverageSvc.refreshSpendCoverage();
        })();
    }, [viewBy]);

    useEffect(() => {
        (async () => {
            const filterByField = filterBy?.path ?? 'originalFilePath';
            spendingCoverageSvc.setFilterByField(filterByField);
            spendingCoverageSvc.refreshSpendCoverage();
        })();
    }, [filterBy]);

    const columns = useMemo(() => {
        const result: ColumnConfig<CurDataItem>[] = [
            {
                header: viewBy?.name ?? 'Resource Type',
                accessor: (item) => (item.type === ValuesGroupOtherText ? 'Other' : item.type),
                defaultWidth: 250,
                id: 'type',
                sortField: 'type',
                filter: {
                    filterField: 'type',
                    filterType: 'string',
                    name: viewBy?.name ?? 'Resource Type',
                    options: {
                        getValueProvider: () =>
                            dataEvent.value?.map((d) => ({ value: d.type, label: d.type === ValuesGroupOtherText ? 'Other' : d.type })),
                    },
                },
                headerRenderer: (col) => viewBy?.name ?? 'Resource Type',
            },
            {
                header: 'Total Spend',
                accessor: 'totalSpend',
                defaultWidth: 200,
                id: 'totalSpend',
                type: 'number',
                align: 'center',
                cellRenderer: (item) => formatSvc.formatMoney(item.totalSpend),
                filter: {
                    filterField: 'totalSpend',
                    filterType: 'number',
                    name: 'Total Spend',
                },
            },
            {
                header: 'Tagged Spend',
                accessor: 'taggedSpend',
                defaultWidth: 200,
                id: 'taggedSpend',
                type: 'number',
                align: 'center',
                cellRenderer: (item) => formatSvc.formatMoney(item.taggedSpend),
                filter: {
                    filterField: 'taggedSpend',
                    filterType: 'number',
                    name: 'Tagged Spend',
                },
            },
            {
                header: 'Untagged Spend',
                accessor: 'untaggedSpend',
                defaultWidth: 200,
                id: 'untaggedSpend',
                type: 'number',
                align: 'center',
                cellRenderer: (item) => formatSvc.formatMoney(item.untaggedSpend),
                filter: {
                    filterField: 'untaggedSpend',
                    filterType: 'number',
                    name: 'Untagged Spend',
                },
            },
            {
                header: 'Unsupported Spend',
                accessor: 'unsupportedSpend',
                defaultWidth: 200,
                id: 'unsupportedSpend',
                type: 'number',
                align: 'center',
                cellRenderer: (item) => formatSvc.formatMoney(item.unsupportedSpend),
                filter: {
                    filterField: 'unsupportedSpend',
                    filterType: 'number',
                    name: 'Unsupported Spend',
                },
            },
        ];
        return result;
    }, []);

    return loading || !schema || !filterSchema ? (
        <LoadingOverlay visible />
    ) : (
        <DataGrid
            key={viewBy?.path}
            hideGlobalSearch
            hideMenu
            leftTopPlaceHolder={
                <>
                    <ViewByPicker field={viewBy} schema={schema} onChange={setViewBy} />
                    <Space w={10} />
                    <TagPicker field={filterBy} schema={filterSchema} onChange={setFilterTag} />
                </>
            }
            dataSource={spendData}
            columns={columns}
            hideHeader
        />
    );
}

function ViewByPicker(props: { schema: SchemaService; field?: FieldInfo; onChange: (field: FieldInfo) => void }) {
    const [opened, { open, close, toggle }] = useDisclosure(false);
    const onSelect = (fields: FieldInfo[]) => {
        props.onChange(fields[0]);
        close();
    };
    return (
        <Popover opened={opened} onClose={close}>
            <Popover.Target>
                <UnstyledButton
                    px={15}
                    onClick={open}
                    sx={{
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderRadius: 15,
                        fontSize: 14,
                        borderColor: '#0002',
                        color: colorPalette.linkColor,
                        background: '#fff',
                        margin: 'var(--item-margin)',
                        height: '30px',
                    }}
                >
                    <i className={'ti ti-eye'}></i> View By: {props.field?.name ?? 'Resource Type'}
                </UnstyledButton>
            </Popover.Target>
            <Popover.Dropdown p={0}>
                <FieldPicker mode="single" schema={props.schema} onChange={onSelect} selections={props.field ? [props.field] : []} />
            </Popover.Dropdown>
        </Popover>
    );
}

function TagPicker(props: { schema: SchemaService; field?: FieldInfo; onChange: (field: FieldInfo) => void }) {
    const [opened, { open, close, toggle }] = useDisclosure(false);
    const onSelect = (fields: FieldInfo[]) => {
        props.onChange(fields[0]);
        close();
    };
    return (
        <Popover opened={opened} onClose={close}>
            <Popover.Target>
                <UnstyledButton
                    px={15}
                    onClick={open}
                    sx={{
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderRadius: 15,
                        fontSize: 14,
                        borderColor: '#0002',
                        color: colorPalette.linkHoverColor,
                        background: '#fff',
                        margin: 'var(--item-margin)',
                        height: '30px',
                    }}
                >
                    <i className={'ti ti-filter'}></i> Key: {props.field?.name ?? 'Any'}
                </UnstyledButton>
            </Popover.Target>
            <Popover.Dropdown p={0}>
                <FieldPicker mode="single" schema={props.schema} onChange={onSelect} selections={props.field ? [props.field] : []} />
            </Popover.Dropdown>
        </Popover>
    );
}

export default observer(SpendCoverageGrid);

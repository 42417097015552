import { postResourcesMultiQuery } from '@apis/Resources';
import { deleteMapContractsDeleteMapContract, postMapContractsGetMapResourceTypes, postMapContractsUpdateMapContract } from '@apis/TagManager';
import { MapContract, MapSupportedService, Query } from '@apis/TagManager/model';
import {
    ActionIcon,
    Box,
    Button,
    Card,
    Center,
    CloseButton,
    Divider,
    Group,
    Loader,
    LoadingOverlay,
    Menu,
    RingProgress,
    Select,
    Space,
    Tabs,
    Text,
    useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import { PageContent } from '@root/Design/Layout';
import { CustomColors, theme } from '@root/Design/Themes';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { useNav } from '@root/Services/NavigationService';
import { useLink } from '@root/Services/Router/Router';
import { addDays, differenceInCalendarDays } from 'date-fns';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronRight, Circle, DotsVertical, FileDescription, Trash } from 'tabler-icons-react';
import { inject, injectable } from 'tsyringe';
import { MapContractsEvents } from '../Services/MapContractsEvents';
import { MapResourceQueryService } from '../Services/MapResourceQueryService';
import { IntroText } from './Components';
import { MapSplashBg } from './Design';
import { ListData, MapBarChart, MapBarChartTypes } from '../Management/Dashboard/MapDashboardCustomizable';
import { MapListChart } from '../Management/Dashboard/MapListChartRenderer';
import { ActionRequiredModel, MapContractModel } from './Models';

export type ContractsStats = Record<
    | `covered${number}`
    | `coveredValue${number}`
    | `total${number}`
    | `undecided${number}`
    | `incorrectlyCovered${number}`
    | `override${number}`
    | `keyValuesApplied${number}`,
    number
>;
export type ContractStats = {
    startDate?: Date;
    endDate?: Date;
    daysRemaining: number;
    noValue: boolean;
    coveredValue: number;
    covered: number;
    coveredCt: number;
    incorrectlyCovered: number;
    incorrectlyCoveredCt: number;
    undecided: number;
    undecidedCt: number;
    overrideCt: number;
    mgmtAccounts: string[];
    memAccounts: number;
};

@injectable()
export class MapContractListService {
    public loading = new EventEmitter(true);
    public loadingStats = new EventEmitter(false);
    public active: MapContractModel[] = [];
    public inactive: MapContractModel[] = [];
    private contractStats: ContractsStats = {} as ContractsStats;
    private contracts: MapContractModel[] = [];
    public mapResourceMeta: MapSupportedService[] = [];

    public constructor(@inject(FormatService) private readonly fmtSvc: FormatService) {}

    public async load(contractList: MapContract[]) {
        this.loading.emit(true);
        try {
            if (this.mapResourceMeta.length <= 0) this.mapResourceMeta = await postMapContractsGetMapResourceTypes();

            contractList.forEach(async (c) => {
                if (
                    !this.contracts.find((x) => {
                        c.Id === x.contractDetails?.Id;
                    })
                ) {
                    this.contracts.push(
                        new MapContractModel(
                            c,
                            new MapResourceQueryService(
                                c,
                                this.mapResourceMeta,
                                this.fmtSvc,
                                contractList.filter((cl) => cl.Id != c.Id)
                            )
                        )
                    );
                }
            });

            this.active = this.contracts.filter((c) => c.isActive());
            this.inactive = this.contracts.filter((c) => !c.isActive());
            this.loadStats();
        } finally {
            this.loading.emit(false);
        }
    }

    private async loadStats() {
        try {
            const resourceStatsQueries: Query[] = [];
            this.contracts.forEach((c) => {
                resourceStatsQueries.push(...c.getStatsQuery());
            });

            const result = await postResourcesMultiQuery(resourceStatsQueries);
            this.contractStats = result.reduce((result, item) => {
                for (const set of item.Results ?? []) {
                    Object.assign(result, set);
                }
                return result;
            }, {} as ContractsStats);

            this.contracts.forEach((c) => {
                c.updateStats(this.getStats(c));
            });
        } finally {
            this.loadingStats.emit(false);
        }
    }

    public getStats(contract: MapContractModel): ContractStats {
        const svc = contract.contractQuerySvc;
        const contractId = contract.contractDetails.Id!;
        const coveredCt = this.contractStats[`covered${contractId}`] as number;
        const inCorrectlyCoveredCt = this.contractStats[`incorrectlyCovered${contractId}`] as number;
        const undecidedCt = this.contractStats[`undecided${contractId}`] as number;
        const overrideCt = this.contractStats[`override${contractId}`] as number;
        const totalCt = coveredCt + undecidedCt + inCorrectlyCoveredCt;

        return {
            noValue: !this.contractStats[`coveredValue${contractId}`],
            coveredValue: this.contractStats[`coveredValue${contractId}`] as number,
            covered: totalCt > 0 ? (coveredCt as number) / totalCt : 0,
            coveredCt: coveredCt as number,
            incorrectlyCovered: totalCt > 0 ? (inCorrectlyCoveredCt as number) / totalCt : 0,
            incorrectlyCoveredCt: inCorrectlyCoveredCt as number,
            undecided: totalCt > 0 ? (undecidedCt as number) / totalCt : 0,
            undecidedCt: undecidedCt as number,
            overrideCt: overrideCt as number,
            startDate: svc?.contractFrom,
            endDate: svc?.contractTo,
            daysRemaining: svc?.contractTo ? Math.max(0, differenceInCalendarDays(svc?.contractTo, new Date())) : 0,
            mgmtAccounts: (svc?.contract.AccountIds ?? []).map((id) => this.fmtSvc.awsAccountId(id)),
            memAccounts: svc?.contract.AccountIds?.length ?? 0,
        };
    }
}

export function MapContractsList({ contracts }: { contracts: MapContract[] }) {
    const theme = useMantineTheme();
    const { getDescendUrl } = useNav();
    const link = useLink();
    const di = useDiContainer();
    const listSvc = useMemo(() => di.resolve(MapContractListService), []);
    useEffect(() => {
        listSvc.load(contracts);
    }, [contracts]);
    const loading = useEventValue(listSvc.loading);
    const haveContracts = !(contracts.length === 0);

    return (
        <PageContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Box sx={{ background: theme.white }}>
                    <IntroText
                        action={
                            <Button
                                component="a"
                                {...link(getDescendUrl('map-contract-setup'))}
                                rightIcon={<ChevronRight />}
                                data-atid="AddMapContractFromList"
                            >
                                Add MAP Contract
                            </Button>
                        }
                        displayText={!haveContracts}
                    />
                </Box>
                {loading && (
                    <Box sx={{ position: 'relative', height: '400px' }}>
                        <LoadingOverlay visible />
                    </Box>
                )}

                {!loading && <MapContractListContent listService={listSvc} haveContracts={haveContracts} />}

                <MapSplashBg />
            </Box>
        </PageContent>
    );
}

function MapContractListContent({ listService, haveContracts }: { listService: MapContractListService; haveContracts: boolean }) {
    const { active, inactive } = listService;
    const defaultTab = active.length > 0 ? 'active' : 'inactive';
    useEvent(listService.loadingStats);

    return (
        <Tabs defaultValue={defaultTab}>
            {haveContracts ? (
                <>
                    <Tabs.List sx={{ background: theme.white }} px="xl">
                        <Tabs.Tab value="active" data-atid="ActiveMapContracts">
                            Active ({active.length})
                        </Tabs.Tab>
                        <Tabs.Tab value="inactive" data-atid="InactiveMapContracts">
                            Inactive ({inactive.length})
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="active" px="xl">
                        {active.map((contract) => (
                            <MapContractListItem key={contract.contractDetails.Id} contract={contract} active atid="Active" />
                        ))}
                        {active.length === 0 ? <MapContractEmpty type="Active" /> : null}
                    </Tabs.Panel>
                    <Tabs.Panel value="inactive" px="xl">
                        {inactive.map((contract) => (
                            <MapContractListItem key={contract.contractDetails.Id} contract={contract} active={false} atid="Inactive" />
                        ))}
                        {inactive.length === 0 ? <MapContractEmpty type="Inactive" /> : null}
                    </Tabs.Panel>
                </>
            ) : (
                <Box px="xl">
                    <MapContractPlaceHolder></MapContractPlaceHolder>
                </Box>
            )}
        </Tabs>
    );
}

function MapContractPlaceHolder() {
    const theme = useMantineTheme();
    const small = useMediaQuery('(max-width: 1200px)');
    return (
        <Card my="lg" sx={{ maxWidth: '1400px', '.mantine-Text-root': { filter: 'blur(3px)' } }} radius="lg" shadow="md" p={0}>
            <Group p="xl" align="stretch" sx={{ background: theme.colors.gray[2], justifyContent: 'stretch' }}>
                <Card color="dimmed" radius="md" sx={{ width: small ? '250px' : '450px' }}>
                    <Text weight="bolder" color="dimmed">
                        MAP Contract Rule Details
                    </Text>
                    <Box px={small ? 'md' : 0}>
                        <ContractDetailDimmed label="Status" small={small}>
                            <Box sx={{ height: '25px', backgroundColor: theme.colors.gray[2], maxWidth: '125px' }}></Box>
                        </ContractDetailDimmed>
                        {!small && <Divider />}
                        {!small && <Space h="md" />}
                        <ContractDetailDimmed label="End Date" small={small}>
                            <Box sx={{ height: '25px', backgroundColor: theme.colors.gray[2], maxWidth: '125px' }}></Box>
                        </ContractDetailDimmed>
                        {!small && <Divider />}
                        <ContractDetailDimmed label={'Management Account'} small={small}>
                            <Box sx={{ height: '25px', backgroundColor: theme.colors.gray[2], maxWidth: '175px' }}></Box>
                        </ContractDetailDimmed>
                        {!small && <Divider />}
                        <ContractDetailDimmed label={'Member Accounts'} small={small}>
                            <Box sx={{ height: '25px', backgroundColor: theme.colors.gray[2], maxWidth: '175px' }}></Box>
                        </ContractDetailDimmed>
                    </Box>
                </Card>
                <Card radius="md" sx={{ display: 'grid', gridTemplateRows: 'min-content min-content', width: '400px' }}>
                    <Group position="apart">
                        <Text weight="bolder" color="dimmed">
                            Tag Coverage
                        </Text>
                    </Group>
                    <Group position="apart">
                        <Box sx={{ height: '200px' }}>
                            <RingProgress
                                size={210}
                                thickness={25}
                                label={
                                    <>
                                        <Text size="lg" weight="bold" align="center">
                                            --
                                        </Text>
                                    </>
                                }
                                sections={[{ value: 100, color: theme.colors.gray[3] as CustomColors }]}
                            />
                        </Box>
                        <Box sx={{ height: '200px' }}>
                            <Box>
                                <Text size="xl" weight="bold" align="center" color="dimmed">
                                    --
                                </Text>
                                <Text size="md" align="center" color="dimmed">
                                    Tagged Incorrectly
                                </Text>
                            </Box>
                            <Space h="sm" />
                            <Box>
                                <Text size="xl" weight="bold" align="center" color="dimmed">
                                    --
                                </Text>
                                <Text size="md" align="center" color="dimmed">
                                    Not Tagged
                                </Text>
                            </Box>
                            <Space h="sm" />
                            <Box>
                                <Text size="xl" weight="bold" align="center" color="dimmed">
                                    --
                                </Text>
                                <Text size="md" align="center" color="dimmed">
                                    Tagged Correctly
                                </Text>
                            </Box>
                        </Box>
                    </Group>
                </Card>
                <Card radius="md" sx={{ display: 'grid', gridTemplateRows: 'min-content auto', width: '350px' }}>
                    <Group position="apart">
                        <Text weight="bolder" color="dimmed">
                            Action Required
                        </Text>
                    </Group>

                    <Group position="center">
                        <Tabs defaultValue="Resources">
                            <Tabs.Panel value="Resources">
                                <Text align="center" size={50} weight="bold" color="dimmed">
                                    --
                                </Text>
                            </Tabs.Panel>
                            <Tabs.Panel value="Cost">
                                <Text align="center" size={50} weight="bold" color="dimmed">
                                    --
                                </Text>
                            </Tabs.Panel>
                        </Tabs>
                    </Group>
                </Card>
            </Group>
        </Card>
    );
}

function MapContractEmpty({ type }: { type?: string }) {
    return (
        <Card mx="xl" withBorder my="lg" sx={{ maxWidth: '1400px', borderStyle: 'dashed', borderWidth: '2px' }} radius="lg" shadow="md" p={0}>
            <Text sx={{ lineHeight: '200px', textAlign: 'center' }} size="lg" color="dimmed">
                No {type} Contracts
            </Text>
        </Card>
    );
}

function MapContractListItem({ contract, active, atid }: { contract: MapContractModel; active?: boolean; atid?: string }) {
    const evts = useDi(MapContractsEvents);
    const theme = useMantineTheme();
    const { getDescendUrl, descend } = useNav();
    const link = useLink();
    const confirmRemove = useCallback(() => {
        openConfirmModal({
            title: 'Remove MAP Contract',
            children: (
                <>
                    Are you sure you want to remove{' '}
                    <strong>{contract.contractDetails.Name ?? `MAP Contract ${contract.contractDetails.ProgramId}`}</strong>? It can be added back
                    later.
                </>
            ),
            labels: {
                cancel: 'Cancel',
                confirm: 'Yes, Remove',
            },
            onConfirm: () => deleteMapContractsDeleteMapContract({ id: contract.contractDetails.Id! }).then(() => evts.onContractsChanged.emit()),
        });
    }, []);
    const gotoContract = useCallback(() => {
        descend('map-contract', { section: 'overview', id: contract.contractDetails.Id!.toString() });
    }, []);

    return (
        <Card my="lg" sx={{ maxWidth: '1400px' }} radius="lg" shadow="md" p={0}>
            <Group px="xl" py="xs" sx={{ background: theme.colors.gray[5] }} position="apart">
                <Text
                    weight="bolder"
                    size="lg"
                    color={theme.white as CustomColors}
                    data-atid={'MapContractName:' + contract.contractDetails.Name ?? `MAP Contract #${contract.contractDetails.ProgramId}`}
                >
                    {contract.contractDetails.Name ?? `MAP Contract #${contract.contractDetails.ProgramId}`}
                </Text>
                <Group>
                    <Button
                        component="a"
                        {...link(getDescendUrl('map-contract', { section: 'manage-tags', id: contract.contractDetails.Id!.toString() }))}
                        size="sm"
                        variant="white"
                        data-atid={'ManageContract:' + contract.contractDetails.Name ?? `${contract.contractDetails.ProgramId}`}
                    >
                        Manage Contract
                    </Button>
                    <Menu withinPortal>
                        <Menu.Target data-atid="ContractEllipsis">
                            <ActionIcon variant="transparent" color="gray.1" data-atid={'ContractEllipsis:' + atid}>
                                <DotsVertical />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item onClick={gotoContract} icon={<FileDescription size={16} />} data-atid="ContractDetails">
                                Contract Details
                            </Menu.Item>
                            <Menu.Item onClick={confirmRemove} icon={<Trash size={16} />} data-atid="RemoveContract">
                                Remove Contract
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Group>
            <Group p="xl" align="stretch" sx={{ background: theme.colors.gray[4], justifyContent: 'stretch' }}>
                {ContractListItemCard(
                    contract.contractDetails.ListItemConfig?.CardOneType != undefined && contract.contractDetails.ListItemConfig?.CardOneType != null
                        ? contract.contractDetails.ListItemConfig.CardOneType
                        : 'contract-status',
                    contract,
                    1,
                    active
                )}
                {ContractListItemCard(
                    contract.contractDetails.ListItemConfig?.CardTwoType != undefined && contract.contractDetails.ListItemConfig?.CardTwoType != null
                        ? contract.contractDetails.ListItemConfig.CardTwoType
                        : 'tag-coverage',
                    contract,
                    2,
                    active
                )}
                {ContractListItemCard(
                    contract.contractDetails.ListItemConfig?.CardThreeType != undefined &&
                        contract.contractDetails.ListItemConfig?.CardThreeType != null
                        ? contract.contractDetails.ListItemConfig.CardThreeType
                        : 'actions-required',
                    contract,
                    3,
                    active
                )}
            </Group>
        </Card>
    );
}

function ContractListItemCard(type: string, contract: MapContractModel, order: number, active?: boolean) {
    const small = useMediaQuery('(max-width: 1200px)');
    const [switching, setSwitching] = useState<boolean>(false);
    const [newValue, setNewValue] = useState<string>('');
    const [showChangeButton, setShowChangeButton] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const theme = useMantineTheme();
    const selectData = [
        { label: 'Contract Status', value: 'contract-status' },
        { label: 'Tag Coverage', value: 'tag-coverage' },
        { label: 'Actions Required', value: 'actions-required' },
        { label: 'Accounts Connected', value: 'accounts-connected' },
        { label: 'Top 5 Resource Types, By Spend', value: 'top-5-resources' },
        { label: 'Key Values Applied', value: 'key-values-applied' },
        { label: 'Top 5 Owners', value: 'top-5-owners' },
    ];

    const saveSelection = async (value: string) => {
        if (value != type) {
            setLoading(true);
            if (order === 1) {
                contract.contractDetails.ListItemConfig = {
                    ...contract.contractDetails.ListItemConfig,
                    CardOneType: value,
                };
            } else if (order === 2) {
                contract.contractDetails.ListItemConfig = {
                    ...contract.contractDetails.ListItemConfig,
                    CardTwoType: value,
                };
            } else if (order === 3) {
                contract.contractDetails.ListItemConfig = {
                    ...contract.contractDetails.ListItemConfig,
                    CardThreeType: value,
                };
            }
            await postMapContractsUpdateMapContract(contract.contractDetails).then(() => {
                setLoading(false);
                setSwitching(false);
            });
        }

        setSwitching(false);
    };
    return (
        <Card
            radius="md"
            sx={{ display: 'grid', width: '400px' }}
            onMouseEnter={() => setShowChangeButton(true)}
            onMouseLeave={() => setShowChangeButton(false)}
        >
            {!loading ? (
                <>
                    {switching ? (
                        <>
                            <Group sx={{ height: '260px' }}>
                                <Text size="lg" weight="bold" sx={{ width: '100%', display: 'flex', marginBottom: '-100px' }} align="left">
                                    Select a tile
                                </Text>
                                <Select
                                    sx={{ width: '100%' }}
                                    onChange={(e) => setNewValue(e!)}
                                    withinPortal
                                    data={selectData}
                                    defaultValue={type}
                                ></Select>
                                <Space h="sm" />
                                <Group sx={{ marginTop: '-100px', marginLeft: '-16px' }}>
                                    <Button
                                        variant="white"
                                        sx={{ border: `1px solid ${theme.colors.primary[5]}` }}
                                        onClick={() => setSwitching(false)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button onClick={() => saveSelection(newValue)}>OK</Button>
                                </Group>
                            </Group>
                        </>
                    ) : (
                        <>
                            <Group position="apart">
                                <Text weight="bolder">
                                    {type === 'contract-status'
                                        ? 'Contract Status'
                                        : type === 'tag-coverage'
                                        ? 'Tag Coverage'
                                        : type === 'actions-required'
                                        ? 'Actions Required'
                                        : type === 'top-5-resources'
                                        ? 'Top 5 Resource Types, By Spend'
                                        : type === 'top-5-owners'
                                        ? 'Top 5 owners'
                                        : type === 'key-values-applied'
                                        ? 'Key Values Applied'
                                        : type === 'accounts-connected'
                                        ? 'Accounts Connected'
                                        : null}
                                </Text>

                                <CloseButton sx={{ visibility: showChangeButton ? 'visible' : 'hidden' }} onClick={() => setSwitching(true)}>
                                    X
                                </CloseButton>
                            </Group>

                            <Group position="apart" sx={{ height: '230px' }}>
                                {type === 'contract-status' && <ContractStatusCard small={small} active={active} stats={contract.stats} />}
                                {type === 'tag-coverage' && <TagCoverageCard mapCoverageStats={contract.stats!} />}
                                {type === 'actions-required' && <ActionRequiredCard contract={contract} />}
                                {type === 'top-5-resources' && <Top5ResourceTypesCard contract={contract} />}
                                {type === 'top-5-owners' && <Top5OwnersCard contract={contract} />}
                                {type === 'key-values-applied' && <KeyValuesAppliedCard contract={contract} />}
                                {type === 'accounts-connected' && <AccountsConnectedCard contract={contract} />}
                            </Group>
                        </>
                    )}
                </>
            ) : (
                <Center sx={{ height: 235 }}>
                    <Loader />
                </Center>
            )}
        </Card>
    );
}

function Top5ResourceTypesCard({ contract }: { contract: MapContractModel }) {
    const [data, setData] = useState<ListData[]>();

    useEffect(() => {
        contract.top5ResourcesData().then((top5resources) => setData(top5resources));
    }, []);

    return <>{data && <MapListChart field={'ResourceType'} data={data} type={'top-5-resources'} />}</>;
}
function Top5OwnersCard({ contract }: { contract: MapContractModel }) {
    const [data, setData] = useState<ListData[]>();

    useEffect(() => {
        contract.top5OwnersData().then((top5owners) => setData(top5owners));
    }, []);

    return <>{data && <MapListChart field={'CsTags.Owner'} data={data} type={'top-5-owners'} />}</>;
}

function KeyValuesAppliedCard({ contract }: { contract: MapContractModel }) {
    const [data, setData] = useState<ListData[]>();

    useEffect(() => {
        contract.keyValuesApplied().then((keyValues) => setData(keyValues));
    }, []);

    return <>{data && <MapListChart field={'CsTags.map-migrated'} data={data} type={'key-values-applied'} />}</>;
}

function ContractStatusCard({ small, active, stats }: { small: boolean; active: boolean | undefined; stats?: ContractStats }) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);

    return (
        <>
            {stats && (
                <Box px={small ? 'md' : 0}>
                    {!small && <Space h="md" />}
                    <ContractDetail label="Status" small={small}>
                        {!active ? (
                            <Text>Inactive</Text>
                        ) : (
                            <Group spacing={4}>
                                <Circle strokeWidth={0} fill={theme.colors.success[6]} size={16} />
                                <Text>Active</Text>
                            </Group>
                        )}
                    </ContractDetail>
                    {!small && <Divider />}
                    <ContractDetail label="End Date" small={small}>
                        <Text color="dimmed" data-atid={'MapContractEndDate:' + (stats.endDate ? fmtSvc.toShortDate(stats.endDate) : 'N/A')}>
                            {stats.endDate ? fmtSvc.toShortDate(fmtSvc.toLocalDate(addDays(stats.endDate, -1))) : 'N/A'}
                        </Text>
                    </ContractDetail>
                    {!small && <Divider />}
                    <ContractDetail label={stats.mgmtAccounts.length > 1 ? 'Management Accounts' : 'Management Account'} small={small}>
                        <Text color="dimmed">
                            {stats.mgmtAccounts[0] ?? 'None'}
                            {stats.mgmtAccounts.length > 1 ? (
                                <Text>
                                    {stats.mgmtAccounts.length - 1} other{stats.mgmtAccounts.length > 2 ? 's' : ''}
                                </Text>
                            ) : null}
                        </Text>
                    </ContractDetail>
                    {!small && <Divider />}
                    <ContractDetail label="Member Accounts" small={small}>
                        <Text color="dimmed">{stats.memAccounts}</Text>
                    </ContractDetail>
                </Box>
            )}
        </>
    );
}

function TagCoverageCard({ mapCoverageStats }: { mapCoverageStats?: ContractStats }) {
    const [stats, setStats] = useState<ContractStats>();
    const [tagHealthPct, setTagHealthPct] = useState<number>();
    useEffect(() => {
        setStats(mapCoverageStats);
        if (mapCoverageStats != undefined) {
            const total = mapCoverageStats.covered + mapCoverageStats.incorrectlyCovered + mapCoverageStats.undecided;
            const pct = total === 0 ? 0 : mapCoverageStats.covered / total;
            setTagHealthPct(pct);
        } else {
            setTagHealthPct(0);
        }
    }, [mapCoverageStats]);

    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    return (
        <>
            {stats && (
                <>
                    <Box sx={{ height: '200px' }}>
                        <RingProgress
                            size={210}
                            thickness={25}
                            label={
                                <>
                                    <Text size="lg" weight="bold" align="center">
                                        {fmtSvc.formatPercent(tagHealthPct ?? 0)}
                                    </Text>
                                    <Text size="xs" align="center" color="dimmed">
                                        Tag Coverage
                                    </Text>
                                </>
                            }
                            sections={[
                                { value: stats.covered * 100, color: theme.colors.success[5] as CustomColors },
                                { value: stats.incorrectlyCovered * 100, color: theme.colors.error[4] as CustomColors },
                                { value: stats.undecided * 100, color: theme.colors.primary[5] as CustomColors },
                            ]}
                        />
                    </Box>
                    <Box sx={{ height: '200px' }}>
                        <Box>
                            <Text
                                size="xl"
                                weight="bold"
                                align="center"
                                color={theme.colors.error[4] as CustomColors}
                                data-atid={'TaggedIncorrectly:' + fmtSvc.formatInt(stats.incorrectlyCoveredCt ?? 0)}
                            >
                                {fmtSvc.formatInt(stats.incorrectlyCoveredCt ?? 0)}
                            </Text>
                            <Text size="md" align="center" color="dimmed">
                                Tagged Incorrectly
                            </Text>
                        </Box>
                        <Space h="sm" />
                        <Box>
                            <Text
                                size="xl"
                                weight="bold"
                                align="center"
                                color={theme.colors.primary[5] as CustomColors}
                                data-atid={'NotTagged:' + fmtSvc.formatInt(stats.undecidedCt ?? 0)}
                            >
                                {fmtSvc.formatInt(stats.undecidedCt ?? 0)}
                            </Text>
                            <Text size="md" align="center" color="dimmed">
                                Not Tagged
                            </Text>
                        </Box>
                        <Space h="sm" />
                        <Box>
                            <Text
                                size="xl"
                                weight="bold"
                                align="center"
                                color={theme.colors.success[5] as CustomColors}
                                data-atid={'TaggedCorrectly:' + fmtSvc.formatInt(stats.coveredCt ?? 0)}
                            >
                                {fmtSvc.formatInt(stats.coveredCt ?? 0)}
                            </Text>
                            <Text size="md" align="center" color="dimmed">
                                Tagged Correctly
                            </Text>
                        </Box>
                    </Box>
                </>
            )}
        </>
    );
}

function AccountsConnectedCard({ contract }: { contract: MapContractModel }) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const [connectedCount, setConnectedCount] = useState<number>();
    const [notConnectedCount, setNotConnectedCount] = useState<number>();
    const [connectedPct, setConnectedPct] = useState<number>();
    const [notConnectedPct, setNotConnectedPct] = useState<number>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        contract.getAccounts().then((accounts) => {
            let connectedCount = accounts.filter((a) => a.IsConnected).length;
            let notConnectedCount = accounts.length - accounts.filter((a) => a.IsConnected).length;
            setConnectedCount(connectedCount);
            setNotConnectedCount(notConnectedCount);
            setConnectedPct(connectedCount / (connectedCount + notConnectedCount));
            setNotConnectedPct(notConnectedCount / (connectedCount + notConnectedCount));
        });
    }, []);

    useEffect(() => {
        setLoading(notConnectedCount == undefined || connectedCount == undefined || connectedPct == undefined || notConnectedPct == undefined);
    }, [connectedCount, notConnectedCount, connectedPct, notConnectedPct]);

    return (
        <>
            {!loading && (
                <>
                    <Box sx={{ height: '200px' }}>
                        <RingProgress
                            size={210}
                            thickness={25}
                            label={
                                <>
                                    <Text size="lg" weight="bold" align="center">
                                        {fmtSvc.formatPercent(connectedPct ?? 0)}
                                    </Text>
                                    <Text size="xs" align="center" color="dimmed">
                                        Accounts Connected
                                    </Text>
                                </>
                            }
                            sections={[
                                { value: connectedPct! * 100, color: theme.colors.success[5] as CustomColors },
                                { value: notConnectedPct! * 100, color: theme.colors.error[4] as CustomColors },
                            ]}
                        />
                    </Box>
                    <Box sx={{ height: '200px' }}>
                        <Box>
                            <Text size="xl" weight="bold" align="center" color={theme.colors.error[4] as CustomColors}>
                                {fmtSvc.formatInt(notConnectedCount!)}
                            </Text>
                            <Text size="md" align="center" color="dimmed">
                                Not Connected
                            </Text>
                        </Box>
                        <Space h="sm" />
                        <Box>
                            <Text size="xl" weight="bold" align="center" color={theme.colors.success[5] as CustomColors}>
                                {connectedCount}
                            </Text>
                            <Text size="md" align="center" color="dimmed">
                                Connected
                            </Text>
                        </Box>
                    </Box>
                </>
            )}
        </>
    );
}

function ActionRequiredCard({ contract }: { contract: MapContractModel }) {
    const [data, setData] = useState<ActionRequiredModel[]>();

    useEffect(() => {
        contract.actionsRequired().then((actions) => {
            setData(actions);
        });
    }, []);

    return <>{data && <MapBarChart data={data} type={'actions-required' as MapBarChartTypes} />}</>;
}

function ContractDetail({ label, children, small }: { label: string; children: ReactNode; small: boolean }) {
    return small ? (
        <Box my="md">
            <Text size="xs" weight="bold">
                {label}
            </Text>
            {children}
        </Box>
    ) : (
        <Box py="xs" sx={{ display: 'grid', gridTemplateColumns: '0.75fr 1fr', columnGap: '15px' }}>
            <Text align="right" weight="bold">
                {label}
            </Text>
            {children}
        </Box>
    );
}

function ContractDetailDimmed({ label, children, small }: { label: string; children: ReactNode; small: boolean }) {
    return small ? (
        <Box my="md">
            <Text size="xs" weight="bold" color="dimmed">
                {label}
            </Text>
            {children}
        </Box>
    ) : (
        <Box py="xs" sx={{ display: 'grid', gridTemplateColumns: '0.75fr 1fr', columnGap: '15px' }}>
            <Text align="right" weight="bold" color="dimmed">
                {label}
            </Text>
            {children}
        </Box>
    );
}

import { singleton } from 'tsyringe';

export type Route = IRouteSegment[];

export type RouteSegmentData = Record<string, string>;

export interface IRouteSegment {
    name: string;
    data: RouteSegmentData;
}

@singleton()
export class RouteSerializer {
    public deserialize(url: string): IRouteSegment[] {
        const rawSegments = url.replace(/^\/*/, '').split('/'),
            result = rawSegments.map((s) => this.deserializeSegment(s));

        return result;
    }

    public serialize(route: IRouteSegment[]) {
        return route.map((r) => this.serializeSegment(r)).join('/');
    }
    public serializeNoEncode(route: IRouteSegment[]) {
        return route.map((r) => this.serializeSegmentNoEncode(r)).join('/');
    }

    private deserializeSegment(segment: string): IRouteSegment {
        const [rawName, rawData] = segment.split('@'),
            name = decodeURIComponent(rawName),
            data = rawData ? this.deserializeData(rawData || '') : {};
        return { name, data };
    }

    private serializeSegment(segment: IRouteSegment) {
        const name = encodeURIComponent(segment.name),
            data = this.serializeData(segment.data),
            result = data ? `${name}@${data}` : name;

        return result;
    }
    private serializeSegmentNoEncode(segment: IRouteSegment) {
        const name = segment.name,
            data = this.serializeData(segment.data),
            result = data ? `${name}@${data}` : name;

        return result;
    }

    private serializeData(data: RouteSegmentData) {
        const props: string[] = [];
        if (data) {
            for (const rawKey in data) {
                const value = encodeURIComponent(data[rawKey]),
                    key = encodeURIComponent(rawKey);

                props.push(`${key}:${value}`);
            }
        }
        return props.join(',');
    }

    private deserializeData(data: string) {
        const result: RouteSegmentData = {},
            props = data.split(',');
        for (const prop of props) {
            const [rawKey, rawValue] = prop.split(':'),
                key = decodeURIComponent(rawKey),
                value = decodeURIComponent(rawValue);

            result[key] = value;
        }
        return result;
    }
}

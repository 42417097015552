import { FillerSwitch } from '@root/Design/Filler';
import { useDi, useDiContainer } from '@root/Services/DI';
import { useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { Observer } from 'mobx-react';
import { useMemo } from 'react';
import { AllocationRuleEditorContainer } from './Components/AllocationRuleEditor';
import { AllocationRuleEditorContext } from './Components/Model';
import { SplashBg } from './ShowbackShellPage';

export function AllocationRuleEditorPage() {
    const editorCtx = useScopeRuleEditorCtx();
    const ruleEditorLoading = useEventValue(editorCtx.loading);
    const { ascend } = useNav();

    return (
        <FillerSwitch loading={ruleEditorLoading} noData={!ruleEditorLoading && !editorCtx?.ruleEditor?.rule}>
            {() => <AllocationRuleEditorContainer editorCtx={editorCtx} onClose={ascend} ruleEditor={editorCtx.ruleEditor} />}
        </FillerSwitch>
    );
}

function AllocationRuleEditorPageBreadcrumb() {
    const editorCtx = useScopeRuleEditorCtx();
    const loading = useEventValue(editorCtx.loading);

    return (
        <Observer>
            {() => {
                const name = loading ? 'Loading...' : editorCtx.ruleEditor?.rule?.Name || 'New Rule';
                return <>{name}</>;
            }}
        </Observer>
    );
}
endpoint('rule-editor', AllocationRuleEditorPage, AllocationRuleEditorPageBreadcrumb);

function useScopeRuleEditorCtx() {
    const nav = useNav();
    const fmtSvc = useDi(FormatService);

    const { ruleId, ruleType, month: rawMonth, dimId: rawDimId } = nav.getData('ruleId', 'ruleType', 'month', 'dimId');
    const month = useMemo(() => (rawMonth ? fmtSvc.fromMonthYear(rawMonth) : new Date()), [rawMonth]);
    const dimId = parseInt(rawDimId ?? '0');

    const container = useDiContainer();
    const editorCtx = useMemo(
        () => container.resolve(AllocationRuleEditorContext).tryInit(month, dimId, ruleType, ruleId),
        [month, dimId, ruleId, ruleType]
    );

    return editorCtx;
}

import { AnonymousQueueJobJob } from '@apis/Jobs/model';
import { AnonymousJob } from '@apis/Resources';
import { BaseResource, ResourceIdentifier, TagResourcesJob } from '@apis/Resources/model';
import { ActionIcon, Anchor, Badge, Box, Group, LoadingOverlay, Popover, Space, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { DataGrid } from '@root/Components/DataGrid';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { ColumnConfig, DataColumnConfig, DataGridState } from '@root/Components/DataGrid/Models';
import { ResourceDetailsOpener } from '@root/Components/Resources/ResourceDetails';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { JobHierarchyProgress, JobService } from '@root/Services/Jobs/JobService';
import { IFluentQueryAdapter, IFluentOperators } from '@root/Services/QueryExpr';
import { useEffect, useMemo, useState } from 'react';
import { Refresh } from 'tabler-icons-react';
import { inject, injectable } from 'tsyringe';
import { ActivityDetailsModel, ActivityDetailsService } from './ActivityDetailsService';
import { ActivityDetailsContainer, DetailRow, ProgressBadge, ProgressBadges, RowDivider } from './Design';
import { ActivityDetailsProps } from './Models';

function ResourceCell({ request, onSelect }: { request: TagResourcesJob; onSelect: (resource?: ResourceIdentifier) => void }) {
    const resource = request.ResourceIds?.[0];
    const [acc, ...idParts] = resource?.ResourceId?.split('.') ?? [];
    const name = resource?.CloudPlatform === 'Aws' ? idParts.join('.') : resource?.ResourceId;
    return (
        <Group sx={{ width: '100%', height: '100%', lineHeight: 1 }}>
            <div>
                <Anchor onClick={() => onSelect(resource)}>{name}</Anchor>
                <Text m={0} size="xs" color="dimmed">
                    {resource?.CloudPlatform === 'Aws' ? 'AWS' : resource?.CloudPlatform} {resource?.ResourceType}
                </Text>
            </div>
        </Group>
    );
}

function getUserFriendlyError(message: string, details: string) {
    const [opened, { close, open }] = useDisclosure(false);
    if (
        message.indexOf('is not authorized to') >= 0 ||
        message.indexOf('Failed to assume role') >= 0 ||
        message.indexOf('Access Denied') >= 0 ||
        message.indexOf('You are not authorized') >= 0
    ) {
        return 'Permission error, check AWS policies for CloudSaver-Role';
    } else if (message.indexOf('does not exist') >= 0 || message.endsWith('not found.') || /No [a-z ]+ found for/.test(message)) {
        return 'Resource was not found or is no longer taggable';
    }
    return (
        <Popover
            width={300}
            position="top"
            withArrow
            shadow="0px 5px 5px #ccc"
            opened={opened}
            id={message.replace(/ /g, '')}
            withinPortal={true}
            arrowSize={10}
            radius="md"
            offset={2}
        >
            <Popover.Target>
                <Text
                    m={0}
                    size="xs"
                    color="dimmed"
                    sx={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    onMouseEnter={open}
                    onMouseLeave={close}
                >
                    {message}
                </Text>
            </Popover.Target>
            <Popover.Dropdown>
                <Text size="xs">{message}</Text>
            </Popover.Dropdown>
        </Popover>
    );
}

function StatusCell({ job }: { job: AnonymousJob }) {
    return (
        <Group sx={{ width: '100%', height: '100%', lineHeight: 1 }}>
            <div style={{ width: '100%', overflow: 'hidden' }}>
                {job.Status === 'Created' ? 'Queued' : job.Status}

                {getUserFriendlyError(job.FailureMessage ?? '', job.FailureDetails ?? '')}
            </div>
        </Group>
    );
}

@injectable()
class TagActivityDetailsModel extends ActivityDetailsModel {
    public resyncStatus?: JobHierarchyProgress;

    public constructor(
        @inject(ActivityDetailsService) public readonly activitySvc: ActivityDetailsService,
        @inject(JobService) public readonly jobService: JobService
    ) {
        super(activitySvc, jobService);
    }

    protected override async afterReload() {
        this.resyncStatus = await this.jobService.getHierarchyProgress(this.job!.HierarchyId!, (b) =>
            b.model.Type!.ne('Cloudsaver.Resources.Domain.Models.TagResourcesJob')
        );
    }
}

export function TagActivityDetail({ item: { job: item }, displayInfo }: ActivityDetailsProps) {
    const container = useDiContainer();
    const formatSvc = useDi(FormatService);
    const request = item.Parameters as TagResourcesJob;
    const model = useMemo(() => {
        const result = container.resolve(TagActivityDetailsModel);
        result.init(item, (b) => {
            const crit = [b.model.Type!.eq('Cloudsaver.Resources.Domain.Models.TagResourcesJob')];
            if (!request.ResourceIds?.length || request.ResourceIds.length > 1) {
                crit.push(b.model.Id!.ne(item.Id!));
            }
            return crit.length > 1 ? b.and(...crit) : crit[0];
        });
        return result;
    }, []);
    useEffect(() => {}, []);
    const datasource = useMemo(() => model.getDatasource(), []);
    const loading = useEventValue(model.loading);
    const status = model.statusInfo;
    const resourceSelectedEvent = useMemo(() => new EventEmitter<ResourceIdentifier | undefined>(undefined), []);
    const selectedResource = useEventValue(resourceSelectedEvent);
    const columns = useMemo(
        () =>
            [
                {
                    accessor: 'Resource',
                    id: 'Resource',
                    header: 'Resource',
                    defaultWidth: 150,
                    type: 'string',
                    noRemove: true,
                    cellRenderer: (item) => (
                        <ResourceCell onSelect={(id) => resourceSelectedEvent.emit(id)} request={item.Parameters as TagResourcesJob} />
                    ),
                    noSort: true,
                },
                {
                    accessor: 'Status',
                    id: 'Status',
                    header: 'Status',
                    defaultWidth: 100,
                    type: 'string',
                    noRemove: true,
                    cellRenderer: (item) => <StatusCell job={item} />,
                },
            ] as DataColumnConfig<AnonymousJob>[],
        []
    );
    const defaultState = useMemo(
        () =>
            ({
                sort: [{ Direction: 'Asc', Expr: { Field: 'Status' } }],
                filters: [],
                columns: [
                    { id: 'Resource', width: 150 },
                    { id: 'Status', width: 300 },
                ],
            } as DataGridState),
        []
    );

    return loading ? (
        <LoadingOverlay visible />
    ) : (
        <ActivityDetailsContainer>
            <DetailRow title="Started">{formatSvc.toLocal(item.StartedAt)}</DetailRow>
            {status?.inProgress ? null : <DetailRow title="Finished">{formatSvc.toLocal(model.progress?.lastDate)}</DetailRow>}
            <RowDivider />
            <DetailRow title="Tag Status">
                <ProgressBadges progress={model.progress} />
            </DetailRow>
            <RowDivider />
            <DetailRow title="Resync Status">
                <ProgressBadges progress={model.resyncStatus} />
            </DetailRow>
            <RowDivider />
            <DetailRow title="Requested By">{model.getRequestedBy()}</DetailRow>
            <RowDivider />
            <DetailRow title={displayInfo.title}>
                {request.AddTags
                    ? request.AddTags.map((t) => `${t.Key}: ${t.Value}`).join(', ')
                    : request.DeleteTags
                    ? request.DeleteTags.map((t) => t.Key).join(', ')
                    : request.Renames
                    ? request.Renames.map((t) => `${t.OldTagKey} to ${t.NewTagKey}`).join(', ')
                    : request.ReplaceValues
                    ? request.ReplaceValues.Key + ': ' + request.ReplaceValues.Values?.join(', ') + ' to ' + request.ReplaceValues.Replacement
                    : null}
            </DetailRow>
            <Space h="lg" />
            <Group position="apart" mx="md">
                <Title order={5} my="sm">
                    Resources
                </Title>
                {status?.inProgress ? (
                    <ActionIcon size="xs" onClick={() => model.reload()}>
                        <Refresh />
                    </ActionIcon>
                ) : null}
            </Group>
            <Box sx={{ flex: 1, minHeight: 0 }}>
                <DataGrid itemHeight={50} state={defaultState} hideHeader hideFilter dataSource={datasource} columns={columns} />
            </Box>
            <ResourceDetailsOpener
                onClose={() => resourceSelectedEvent.emit(undefined)}
                resourceId={selectedResource?.ResourceId}
                platform={selectedResource?.CloudPlatform ?? 'Aws'}
                resourceType={selectedResource?.ResourceType ?? ''}
            />
        </ActivityDetailsContainer>
    );
}

import { IQueryExpr } from '@apis/Jobs/model';
import { Anchor, Popover, UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { DataGrid } from '@root/Components/DataGrid';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { DataGridState, ColumnConfig, ColumnGroupConfig } from '@root/Components/DataGrid/Models';
import { FieldPicker } from '@root/Components/Picker/FieldPicker';
import { colorPalette } from '@root/Design/Themes';
import { useId } from '@root/Services/IdGen';
import { useNav } from '@root/Services/NavigationService';
import { SchemaService, ValuesGroupOtherText, FieldInfo } from '@root/Services/QueryExpr';
import { useLink } from '@root/Services/Router/Router';
import { useState, useEffect, useMemo, useCallback } from 'react';

export function ViewByGrid<T extends { type: string }>({
    schema,
    metricColumns,
    data,
    onViewByChanged,
    onFilter,
    onGridLoaded,
    headerHeight,
    groupConfig,
    noTypeLink,
}: {
    schema: SchemaService;
    metricColumns: ColumnConfig<T>[];
    onViewByChanged: (viewBy: string) => void;
    data: T[];
    onFilter?: (filters: IQueryExpr[]) => void;
    onGridLoaded?: (grid: DataGridModel) => void;
    headerHeight?: number;
    groupConfig?: Record<string, ColumnGroupConfig>;
    noTypeLink?: boolean;
}) {
    const { getDescendUrl } = useNav();
    const link = useLink();
    const [grid, setGrid] = useState<DataGridModel>();
    const [viewBy, setViewBy] = useState(schema.getField('ResourceType'));
    const gridKey = (useId(data) ?? 0) + (viewBy?.path ?? '');

    useEffect(() => {
        onViewByChanged(viewBy?.path ?? 'ResourceType');
        onFilter?.([]);
        if (grid) {
            grid.refresh(true);
            onGridLoaded?.(grid);
        }
    }, [viewBy, grid]);

    const columns = useMemo(() => {
        return [
            {
                header: viewBy?.name ?? 'Resource Type',
                accessor: (item) => (item.type === ValuesGroupOtherText ? 'Other' : item.type),
                defaultWidth: 250,
                id: 'type',
                sortField: 'type',
                filter: {
                    filterField: 'type',
                    filterType: 'string',
                    name: viewBy?.name ?? 'Resource Type',
                    options: {
                        getValueProvider: () =>
                            data
                                .filter((d) => d.type !== ValuesGroupOtherText)
                                .map((d) => ({ value: d.type, label: d.type === ValuesGroupOtherText ? 'Other' : d.type })),
                    },
                },
                headerRenderer: (col) => viewBy?.name ?? 'Resource Type',
                cellRenderer: (item) => {
                    const filter =
                        item.type === ValuesGroupOtherText
                            ? { Operation: 'isNull', Operands: [{ Field: viewBy?.path }] }
                            : { Operation: 'eq', Operands: [{ Field: viewBy?.path }, { Value: item.type }] };
                    const state: DataGridState = {
                        columns: [],
                        filters: [filter],
                        sort: [],
                    };

                    const stateData = JSON.stringify(state);
                    return noTypeLink ? (
                        <>{item.type === ValuesGroupOtherText ? 'Other' : item.type}</>
                    ) : (
                        <Anchor {...link(getDescendUrl('tag-explorer', { state: stateData }))}>
                            {item.type === ValuesGroupOtherText ? 'Other' : item.type}
                        </Anchor>
                    );
                },
            },
            ...metricColumns,
        ] as ColumnConfig<T>[];
    }, [viewBy, data]);

    return (
        <DataGrid
            key={gridKey}
            onModelLoaded={setGrid}
            onFilterAdded={onFilter}
            hideGlobalSearch
            leftTopPlaceHolder={<ViewByPicker field={viewBy} schema={schema} onChange={setViewBy} />}
            dataSource={data}
            columns={columns}
            hideHeader
            hideMenu={false}
            hideColumnSelector
            exportName="Tag Stats"
            showHeaderGroups={!!groupConfig}
            groupConfig={groupConfig}
            headerHeight={headerHeight}
        />
    );
}

export function ViewByPicker(props: { schema: SchemaService; field?: FieldInfo; onChange: (field: FieldInfo) => void }) {
    const [opened, { open, close, toggle }] = useDisclosure(false);
    const onSelect = (fields: FieldInfo[]) => {
        props.onChange(fields[0]);
        close();
    };
    return (
        <Popover opened={opened} onClose={close} withinPortal shadow="md" withArrow>
            <Popover.Target>
                <UnstyledButton
                    px={15}
                    onClick={open}
                    sx={{
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderRadius: 15,
                        fontSize: 14,
                        borderColor: '#0002',
                        color: colorPalette.linkColor,
                        background: colorPalette.fffColor,
                        margin: 'var(--item-margin)',
                        marginRight: '10px',
                        height: '30px',
                    }}
                >
                    <i className={'ti ti-eye'}></i> View By: {props.field?.name ?? 'Resource Type'}
                </UnstyledButton>
            </Popover.Target>
            <Popover.Dropdown p={0}>
                <FieldPicker
                    mode="single"
                    schema={props.schema}
                    onChange={onSelect}
                    selections={props.field ? [props.field] : []}
                    schemaFilter={(info) => {
                        return 'typeName' in info && info.typeName === 'string';
                    }}
                />
            </Popover.Dropdown>
        </Popover>
    );
}

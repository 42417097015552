const generateColor = (firstName: string, lastName: string, email: string, id: number) => {
    let hash = 0;
    const str = email ?? `${firstName} ${lastName} + ${id}`;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return `hsl(${hash % 360}, 50%, 60%)`;
};

function generateImage(firstName: string, lastName: string, email: string, id: number, diameter: number) {
    var initials = (firstName?.[0] ?? '') + (lastName?.[0] ?? '');
    if (initials == '') {
        initials = (email?.[0] ?? '') + (email?.[1] ?? '');
    }
    initials = initials.toLocaleUpperCase();
    const color = generateColor(firstName, lastName, email, id);

    return `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='${diameter}' height='${diameter}'%3E%3Ccircle r='${
        diameter / 2
    }' cx='${diameter / 2}' cy='${
        diameter / 2
    }' fill='${color}' /%3E%3Ctext x='50%25' y='50%25' dominant-baseline='central' text-anchor='middle' fill='%23000000' font-size='${
        diameter / 2.5
    }' font-family='arial'%3E${initials}%3C/text%3E%3C/svg%3E`;
}

export function UserImage(props: { firstName: string; lastName: string; email: string; picture: string; id: number; diameter: number }) {
    let userInitials = (props.firstName?.[0] ?? '') + (props.lastName?.[0] ?? '');
    if (props.picture && userInitials && props.picture.toLowerCase().endsWith('%2f' + userInitials?.toLowerCase() + '.png')) {
        return (
            <>
                <img
                    src={props.picture}
                    style={{
                        height: '' + props.diameter + 'px',
                        width: '' + props.diameter + 'px',
                        borderRadius: '' + props.diameter / 2 + 'px',
                    }}
                    onError={(e) => (e.currentTarget.src = generateImage(props.firstName, props.lastName, props.email, props.id, props.diameter))}
                ></img>
            </>
        );
    } else {
        return (
            <>
                <img
                    src={generateImage(props.firstName, props.lastName, props.email, props.id, props.diameter)}
                    style={{
                        height: '' + props.diameter + 'px',
                        width: '' + props.diameter + 'px',
                        borderRadius: '' + props.diameter / 2 + 'px',
                    }}
                ></img>
            </>
        );
    }
}

import { QueryExpr } from '@apis/Resources';
import { QuerySortExpr, QuerySortExprDirection } from '@apis/Resources/model';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ActionIcon, Box, Checkbox, Divider, Group, Popover, Text, TextInput, Tooltip } from '@mantine/core';
import { FillerSwitch } from '@root/Design/Filler';
import { EventEmitter, useEvent, useToggle } from '@root/Services/EventEmitter';
import { ArrayDataSource } from '@root/Services/Query/ArrayDataSource';
import { ForwardedRef, forwardRef, Fragment, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Check, Eraser, Filter, SortAscending, SortDescending } from 'tabler-icons-react';
import { ITypedTreeConfig, VirtualTree } from '../VirtualTree';
import { Node } from '../VirtualTree/Node';
import { LineItemCompactToken } from './Design';
import { DataFilterAppearanceCtx, DataFilterOperandButton, DataFilterValueRenderer, FilterExpr } from './Filters';

export function dataFilterFacetFactory<T>(config: IDataFilterFacetPickerConfig<T>): DataFilterValueRenderer {
    return function DataFilterFacet({ filter, apply }: { filter: FilterExpr; apply: () => void }) {
        return <BaseDataFilterFacet<T> filter={filter} apply={apply} {...config} />;
    };
}

function BaseDataFilterFacet<T>({
    filter,
    apply,
    ...facetPickerOptions
}: { filter: FilterExpr; apply: () => void } & IDataFilterFacetPickerConfig<T>) {
    const [opened, { close, toggle }] = useToggle(false);
    const pickerState = useMemo(() => {
        const selections = new Set<string>(filter.getValueAsArray());
        return {
            selections,
            facet: { Field: filter.field },
            onChange: () => filter.setValue(selections.size > 0 ? [...selections] : null),
        };
    }, [filter, opened]);

    return (
        <Popover opened={opened} onClose={close} position="bottom" withinPortal offset={0} closeOnClickOutside closeOnEscape>
            <Popover.Target>
                <FilterSelectionDisplay filter={filter} opened={opened} onClick={toggle} />
            </Popover.Target>
            <Popover.Dropdown p={0} sx={{ background: 'transparent' }}>
                {opened ? <DataFilterFacetPicker {...facetPickerOptions} {...pickerState} /> : null}
            </Popover.Dropdown>
        </Popover>
    );
}

interface SelectionDisplayProps {
    filter: FilterExpr;
    opened: boolean;
    onClick: () => void;
}
const FilterSelectionDisplay = forwardRef(({ filter, opened, onClick }: SelectionDisplayProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const [error, setError] = useState<boolean | undefined>(undefined);
    const [values, setValues] = useState<any[]>([]);
    const [text, setText] = useState<string>('');

    const refreshDetails = useCallback(() => {
        const values = !filter.value ? [] : filter.value instanceof Array ? filter.value : [filter.value];
        setValues(values);
        setError(!filter.isValid());
        setText(values.join(', ') || 'Any');
    }, [filter]);
    useEvent(filter.changed, refreshDetails);
    useEffect(refreshDetails, [filter]);

    const appearance = useContext(DataFilterAppearanceCtx);

    return (
        <>
            {appearance.compact ? (
                <LineItemCompactToken ref={ref} right state={opened ? 'open' : undefined} onClick={onClick}>
                    <SelectionText>
                        {values.length > 0 ? <span>({values.length})</span> : null}
                        <TruncatedText>{text}</TruncatedText>
                    </SelectionText>
                </LineItemCompactToken>
            ) : (
                <DataFilterOperandButton ref={ref} open={opened} error={error} multiline text={text} onClick={onClick} />
            )}
        </>
    );
});

interface ValueAccessor<TItemType, TValueType> {
    accessor: (item: TItemType) => TValueType;
}
interface Column<TItemType, TValueType> extends ValueAccessor<TItemType, TValueType> {
    header?: string;
    type: 'string' | 'number' | 'boolean' | 'date';
    formatter?: (value: TValueType, item: TItemType) => ReactNode;
    align?: 'left' | 'right' | 'center';
    fill?: boolean;
}
interface IDataFilterFacetPickerConfig<T> {
    itemLookup: (value: string) => T;
    valuesProvider: (facet: QueryExpr) => Promise<string[]>;
    valuesInvalidated?: EventEmitter<any>;
    columns: Column<T, unknown>[];
    width?: number;
}
interface IDataFilterFacetPickerProps<T> extends IDataFilterFacetPickerConfig<T> {
    selections: Set<string>;
    onChange: () => void;
    facet: QueryExpr;
}

export function DataFilterFacetPicker<T>(props: IDataFilterFacetPickerProps<T>) {
    const [loading, setLoading] = useState(true);
    const [values, setValues] = useState<string[]>([]);
    const changed = useMemo(() => EventEmitter.empty(), []);
    const itemLookup = useMemo(() => {
        const lookup = new Map<string, T>();
        return {
            get: (value: string) => lookup.get(value) ?? lookup.set(value, props.itemLookup(value)).get(value),
            reset: () => lookup.clear(),
        };
    }, [props.itemLookup]);
    const refreshValues = useCallback(() => {
        itemLookup.reset();
        props
            .valuesProvider(props.facet)
            .then(setValues)
            .finally(() => setLoading(false));
    }, []);
    useEffect(refreshValues, []);
    useEvent(props.valuesInvalidated, refreshValues);

    const [filterText, setFilterText] = useState<string>('');
    const columns = useMemo(() => props.columns.map((c, i) => ({ ...c, name: `col${i}` })), [props.columns]);
    const selectionState = useMemo(
        () => ({
            setSelected: (value: string, selected: boolean) => {
                if (props.selections.has(value) !== selected) {
                    if (selected) {
                        props.selections.add(value);
                    } else {
                        props.selections.delete(value);
                    }
                    props.onChange();
                    changed.emit();
                }
            },
            isSelected: (value: string) => props.selections.has(value),
        }),
        [props.selections, props.onChange]
    );
    const items: IFacetGridRow<T>[] = useMemo(
        () =>
            values.map((v) => ({
                item: itemLookup.get(v)!,
                value: v,
                isSelected: () => selectionState.isSelected(v),
                setSelected: (selected: boolean) => selectionState.setSelected(v, selected),
                allSelected: () => props.selections.size === 0,
                changed,
            })),
        [values, selectionState, itemLookup]
    );
    const filterExprs = useMemo(() => {
        return !filterText
            ? []
            : columns
                  .filter((c) => c.type === 'string')
                  .map((c) => ({ Operation: 'contains', Operands: [{ Field: c.name }, { Value: filterText }] }));
    }, [filterText, columns]);
    const [sortCol, setSortCol] = useState<string>();
    const [sortDir, setSortDir] = useState<QuerySortExprDirection>();
    const onSort = useCallback(
        (col: string) => {
            if (col === sortCol) {
                setSortDir(sortDir === 'Asc' ? 'Desc' : 'Asc');
            } else {
                setSortCol(col);
                setSortDir('Asc');
            }
        },
        [sortCol, sortDir]
    );
    const sortExpr = useMemo(() => (!sortCol ? undefined : ({ Direction: sortDir, Expr: { Field: sortCol } } as QuerySortExpr)), [sortCol, sortDir]);
    const datasource = useMemo(
        () =>
            new ArrayDataSource(
                items,
                columns.map((c) => ({ getValue: (v) => c.accessor(v.item), field: c.name, type: c.type }))
            ),
        [items, columns]
    );
    const datasourceState = useMemo(() => {
        const filters = filterExprs.length ? [{ Operation: 'or', Operands: filterExprs }] : [];
        const sort = sortExpr ? [sortExpr] : [];
        return { filters, sort };
    }, [filterExprs, sortExpr]);

    const treeConfig = useMemo(
        () =>
            ({
                itemHeight: 28,
                renderNode: () => <></>,
                canExpand: () => false,
                minimizeHeight: true,
                height: 300,
                renderBody: (nodes) => <FacetGridBody columns={columns} nodes={nodes} />,
            } as ITypedTreeConfig<IFacetGridRow<T>>),
        []
    );

    const displayItems = useMemo(() => datasource.applyState(datasourceState), [datasource, datasourceState]);

    const clearSelections = useCallback(() => {
        props.selections.clear();
        props.onChange();
    }, [props.selections, props.onChange]);

    return (
        <PickerContainer style={{ width: props.width ?? 400 }}>
            {values.length > 10 ? (
                <>
                    <Group noWrap px={4} py={2}>
                        <TextInput
                            sx={{ flex: 1 }}
                            size="xs"
                            icon={<Filter size={16} />}
                            value={filterText}
                            autoFocus
                            onChange={(event) => setFilterText(event.currentTarget.value)}
                            placeholder="Filter"
                        />
                        <Tooltip label="Clear selections" withinPortal position="right">
                            <ActionIcon size="sm" disabled={props.selections.size == 0} onClick={clearSelections}>
                                <Eraser />
                            </ActionIcon>
                        </Tooltip>
                    </Group>
                    <Divider />
                </>
            ) : null}
            <FacetContentEl>
                <FillerSwitch
                    loading={loading}
                    noData={!displayItems.length}
                    noDataMessage={!filterText || !values.length ? 'Nothing here' : `No matches for "${filterText}"`}
                >
                    {() => (
                        <FacetGrid columns={columns}>
                            <FacetHeaderRow columns={columns} onSort={onSort} sortCol={sortCol} sortDir={sortDir} />
                            <VirtualTree data={displayItems} config={treeConfig} />
                        </FacetGrid>
                    )}
                </FillerSwitch>
            </FacetContentEl>
        </PickerContainer>
    );
}

interface IFacetGridRow<T> {
    item: T;
    value: string;
    isSelected: () => boolean;
    setSelected: (selected: boolean) => void;
    allSelected: () => boolean;
    changed: EventEmitter<void>;
}

function FacetRowItem<T>({ row, columns }: { row: IFacetGridRow<T>; columns: Column<T, any>[] }) {
    const toggle = useCallback(() => row.setSelected(!row.isSelected()), [row]);
    return (
        <FacetGridRow onClick={toggle} cols={columns.length + 1}>
            <FacetRowCheckbox row={row} />
            {columns.map((c, i) => {
                const value = c.accessor(row.item);
                const displayValue = c.formatter ? c.formatter(value, row.item) : value;
                const align = c.align ?? (c.type === 'number' ? 'right' : 'left');
                const wrapped =
                    typeof displayValue === 'object' ? displayValue : <TruncatedText style={{ textAlign: align }}>{displayValue}</TruncatedText>;
                return <Fragment key={i}>{wrapped}</Fragment>;
            })}
        </FacetGridRow>
    );
}

function FacetRowCheckbox({ row }: { row: IFacetGridRow<any> }) {
    useEvent(row.changed);
    return <Checkbox color={row.allSelected() ? 'gray.5' : 'primary'} px={8} size="xs" readOnly checked={row.isSelected() || row.allSelected()} />;
}

const FacetGridBody = forwardRef(
    ({ nodes, columns }: { nodes: Node<IFacetGridRow<any>>[]; columns: Column<any, any>[] }, ref: ForwardedRef<HTMLDivElement>) => {
        return (
            <FacetGridContentEl ref={ref} cols={columns.length + 1} style={{ transform: `translateY(var(--tree-top-buffer))` }}>
                {nodes.map((node) => (
                    <FacetRowItem key={node.index} row={node.item} columns={columns} />
                ))}
            </FacetGridContentEl>
        );
    }
);

function FacetHeaderRow({
    columns,
    onSort,
    sortCol,
    sortDir,
}: {
    columns: ({ name: string } & Column<any, any>)[];
    onSort: (col: string) => void;
    sortCol?: string;
    sortDir?: QuerySortExprDirection;
}) {
    const bindCols = useMemo(() => columns.map((c) => ({ col: c, sort: () => onSort(c.name) })), [columns, onSort]);
    const hasHeaders = columns.some((c) => c.header);
    return !hasHeaders ? null : (
        <>
            <FacetGridHeaderEl cols={columns.length + 1}>
                <div></div>
                {bindCols.map(({ col, sort }, i) => (
                    <Fragment key={i}>
                        {!col.header ? (
                            <div></div>
                        ) : (
                            <Group
                                spacing={4}
                                position={col.align ? col.align : col.type === 'number' ? 'right' : 'left'}
                                onClick={sort}
                                sx={{ ['button']: { opacity: sortCol === col.name ? 0.7 : 0 }, '&:hover button': { opacity: 1 } }}
                            >
                                <Text sx={{ cursor: 'pointer' }} color="dimmed" size="xs" key={i}>
                                    {col.header ?? ''}
                                </Text>
                                <ActionIcon size="xs">
                                    {col.name === sortCol && sortDir === 'Desc' ? <SortDescending /> : <SortAscending />}
                                </ActionIcon>
                            </Group>
                        )}
                    </Fragment>
                ))}
            </FacetGridHeaderEl>{' '}
            <Divider />
        </>
    );
}

function FacetGrid({ columns, children }: { columns: Column<any, any>[]; children: ReactNode; onSort?: (expr: QuerySortExpr) => void }) {
    const templateCols = useMemo(() => ['min-content', ...columns.map(({ fill }) => (fill ? 'minmax(0px, 1fr)' : 'max-content'))], [columns]);
    return <FacetGridEl cols={templateCols}>{children}</FacetGridEl>;
}

const PickerContainer = styled.div`
    border-radius: ${(p) => p.theme.radius.xs}px;
    background: ${(p) => p.theme.white};
    box-shadow: ${(p) => p.theme.shadows.sm};
    border: 1px solid ${(p) => p.theme.colors.gray[4]};
`;

const baseGridContent = (cols: number) => css`
    display: grid;
    grid-template-columns: subgrid;
    grid-column-start: span ${cols};
`;

const FacetContentEl = styled.div`
    max-height: 350px;
    min-height: 100px;
    align-items: center;
    display: flex;
    justify-content: center;
`;

const FacetGridEl = styled.div<{ cols: string[] }>`
    display: grid;
    grid-template-columns: ${(p) => p.cols.join(' ')};
    column-gap: 4px;
    flex: 1;
    > div {
        ${(p) => baseGridContent(p.cols.length)}
        overflow-x: hidden;
    }
`;
const FacetGridContentEl = styled.div<{ cols: number }>`
    ${(p) => baseGridContent(p.cols)}
    > div {
        ${(p) => baseGridContent(p.cols)}
    }
`;
const FacetGridHeaderEl = styled.div<{ cols: number }>`
    ${(p) => baseGridContent(p.cols)}
    padding-right: 1.5rem;
    height: 28px;
`;

const FacetGridRow = styled.a<{ cols: number }>`
    ${(p) => baseGridContent(p.cols)}
    font-size: ${(p) => p.theme.fontSizes.sm}px;
    color: ${(p) => p.theme.colors.gray[8]};
    cursor: pointer;
    height: 28px;
    align-items: center;
    padding-right: 1.5rem;
    &:hover {
        background: ${(p) => p.theme.colors.primary[2]};
    }
`;

const SelectionText = styled.div`
    display: grid;
    column-gap: 4px;
    grid-template-columns: min-content minmax(0, 1fr);
`;
const TruncatedText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

import { postResourcesCheckAwsResources } from '@apis/Resources';
import styled from '@emotion/styled';
import { ActionIcon, Badge, Box, Divider, Group, Loader, Space, Stack, Text, Tooltip, useMantineTheme } from '@mantine/core';
import { NavBarItem, PageNavBar } from '@root/Components/PageNavBar/PageNavBar';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { PageContent, PagePanel, PanelBody, PaneledPage, PanelHeader } from '@root/Design/Layout';
import { useDi, useDiMemo } from '@root/Services/DI';
import { useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { IdleResourcesService } from '../../../Services/Resources/IdleResourcesService';
import { JobService } from '@root/Services/Jobs/JobService';
import { CheckAwsResourcesJob } from '@apis/Resources/model/checkAwsResourcesJob';
import { Refresh } from 'tabler-icons-react';
import { PollingService } from '@root/Services/Jobs/PollingService';
import { addHours } from 'date-fns';
import { ConfigAdministrationModel } from './ConfigAdministrationModel';

export interface Params {
    jobId: string;
    CompanyId: number;
    AccountId: number;
    ResourceType: string;
    Region: string;
    ResourceIds: string;
}

export function IdleResourcesPage({
    children,
    IdleResourcesTooltip,
    title,
    types,
}: {
    children: ReactNode;
    IdleResourcesTooltip?: ReactNode;
    title: string;
    types?: string[];
}) {
    const idleResSvc = useDi(IdleResourcesService);
    const componentDisposed = useMemo(() => ({ disposed: false }), []);
    const statsLoading = useEventValue(idleResSvc.statsLoading);
    const [stats, setStats] = useState<Record<string, number>>();
    const fmtSvc = useDi(FormatService);
    const jobSvc = useDi(JobService);
    const com = useCompany();
    const [lastSyncDate, setLastSyncDate] = useState<Date>(new Date('12/31/9999'));
    const [nextSyncDate, setNextSyncDate] = useState<Date>(new Date('12/31/9999'));
    const [sync, setSync] = useState<boolean>(false);
    const resourceTypes = useMemo(() => types ?? [title], [JSON.stringify(types ?? [title])]);
    const theme = useMantineTheme();
    const [percentComplete, setPercentComplete] = useState(0);
    const pollingSvc = useDi(PollingService);
    const hoursBetweenRefresh = 1;
    const model = useDiMemo(ConfigAdministrationModel);
    const [refresh, setRefresh] = useState(false);
    const [syncNeeded, setSyncNeeded] = useState(false);
    useEvent(
        model.isRefreshNeeded,
        useCallback(() => {
            idleResSvc.getStats().then((r) => setStats(r));
            setRefresh(false);
        }, [])
    );

    useEvent(model.isResyncNeeded, (value) => setSyncNeeded(value));
    useEffect(() => {
        if (syncNeeded) {
            resync();
            setSyncNeeded(false);
        }
    }, [syncNeeded]);

    const updateLastSync = () => {
        idleResSvc.getLastSyncDate(title).then((r) => {
            setLastSyncDate(r);
            setNextSyncDate(addHours(r, hoursBetweenRefresh));
        });
        idleResSvc.getStats().then((r) => setStats(r));
    };

    useEffect(() => {
        updateLastSync();
        return () => {
            componentDisposed.disposed = true;
        };
    }, []);

    useEffect(() => {
        checkForExistingJobs();
    }, [resourceTypes]);

    useEffect(() => {}, [refresh]);

    const checkForExistingJobs = async () => {
        var existingJobs: CheckAwsResourcesJob[] = await jobSvc.getRootJobs(
            10,
            addHours(new Date(), -1),
            undefined,
            undefined,
            false,
            'Cloudsaver.Resources.Domain.Aws.Models.CheckAwsResources'
        );

        const applicableSyncJobs = existingJobs.filter(
            (job) =>
                !job.Parameters?.AccountId &&
                !job.Parameters?.Region &&
                !job.Parameters?.ResourceIds &&
                resourceTypes.includes(job.Parameters?.ResourceType ?? '')
        );
        const mostRecentJobs = applicableSyncJobs.filter((j) => !j.FinishedAt).sort((a, b) => (b.QueuedAt ?? '').localeCompare(a.QueuedAt ?? ''));
        if (mostRecentJobs.length > 0) {
            setSync(true);
            pollCheckAwsResourcesJob(mostRecentJobs);
        }
    };

    const menuItems = useMemo(() => {
        return [
            { endpoint: 'idle-customizable-dashboard', icon: <></>, text: 'Dashboard', type: 'link', atid: 'idle-dashboard-nav' },
            { endpoint: 'idle-ec2', icon: <></>, text: 'EC2', type: 'link', atid: 'ec2-nav' },
            { endpoint: 'idle-rds', icon: <></>, text: 'RDS', type: 'link', atid: 'rds-nav' },
            { endpoint: 'idle-emr', icon: <></>, text: 'EMR', type: 'link', atid: 'emr-nav' },
            { endpoint: 'idle-elbv', icon: <></>, text: 'ELB', type: 'link', atid: 'elb-nav' },
            { endpoint: 'idle-redshift', icon: <></>, text: 'Redshift', type: 'link', atid: 'redshift-nav' },
        ].map((r) => {
            return {
                ...r,
                rightIcon:
                    r.endpoint === 'idle-customizable-dashboard' ? null : !stats || statsLoading ? (
                        <Loader size="xs" />
                    ) : (
                        <Badge>{fmtSvc.formatInt(stats[r.text] ?? 0)}</Badge>
                    ),
            };
        }) as NavBarItem[];
    }, [statsLoading, stats]);

    function canSync() {
        return !sync && new Date(Date.now()) > nextSyncDate;
    }
    const resync = async () => {
        if (canSync() || syncNeeded) {
            if (!sync || syncNeeded) {
                setPercentComplete(0);
                setSync(true);
                const jobs = await Promise.all(
                    resourceTypes.map((type) => postResourcesCheckAwsResources({ CompanyId: com?.Id, ResourceType: type }))
                );
                pollCheckAwsResourcesJob(jobs);
            }
        }
    };

    const pollCheckAwsResourcesJob = async (jobs: CheckAwsResourcesJob[]) => {
        const poller = pollingSvc.pollUntil(
            async () => {
                const progressLookup = await jobSvc.getHierarchyProgressLookup(jobs.map((j) => j.HierarchyId!));
                const progress = [...progressLookup.values()].reduce(
                    (result, item) => {
                        const statusInfo = jobSvc.getStatusInfo(item);
                        result.progress += (statusInfo?.progress ?? 0) / jobs.length;
                        result.inProgress = result.inProgress || (statusInfo?.inProgress ?? false);
                        return result;
                    },
                    { progress: 0, inProgress: false }
                );
                return progress;
            },
            (result) => !result?.inProgress || componentDisposed.disposed,
            3000
        );
        poller.progress.listen((v) => {
            setPercentComplete((v?.progress ?? 0) * 100);
        });
        await poller;
        idleResSvc.invalidateCache();
        updateLastSync();
        setSync(false);
        idleResSvc.refreshNeeded.emit();
    };

    const nonResourcePage = !title && !types?.length;

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ flex: 0, height: '100%' }}>
                <PageNavBar items={menuItems} />
            </Box>
            <Divider orientation="vertical" />
            <PageContent>
                <PaneledPage>
                    <PagePanel size="fill">
                        <Group position="apart" spacing="sm" hidden={nonResourcePage}>
                            <div>
                                <PanelHeader style={{ paddingBottom: 0 }}>
                                    <Group spacing={8}>
                                        <Text data-atid={'SiteSecondaryHeader:' + title} size={20}>
                                            {title}
                                        </Text>
                                        {IdleResourcesTooltip}
                                    </Group>
                                </PanelHeader>
                                <Space h="md" />
                            </div>
                            <div style={{ marginRight: '25px' }}>
                                <Group
                                    spacing={0}
                                    align="stretch"
                                    sx={{ background: theme.white, borderRadius: theme.radius.sm, border: `solid 1px ${theme.colors.gray[4]}` }}
                                >
                                    <Stack px="xs" justify="center" sx={{ background: theme.colors.gray[2] }}>
                                        {sync ? (
                                            <Text size="sm">Updating - {Math.round(percentComplete)}% complete</Text>
                                        ) : (
                                            <Tooltip label="Date last updated">
                                                <Text size="sm">
                                                    {lastSyncDate.getFullYear() == 9999
                                                        ? 'Loading last update...'
                                                        : lastSyncDate.getFullYear() == 2000
                                                        ? 'No results found'
                                                        : fmtSvc.formatDatetimeNoSecs(lastSyncDate).toString().replace(',', '')}
                                                </Text>
                                            </Tooltip>
                                        )}
                                    </Stack>
                                    <Divider orientation="vertical" />
                                    <Tooltip
                                        label={
                                            sync
                                                ? 'Resync in progress'
                                                : canSync()
                                                ? 'Resync stats and resources'
                                                : 'Resync available every ' + hoursBetweenRefresh + ' hours'
                                        }
                                    >
                                        <ActionIcon disabled={sync || !canSync()} onClick={resync}>
                                            <Refresh
                                                style={{
                                                    color: sync ? theme.black : canSync() ? theme.colors.primary[6] : theme.colors.warning[6],
                                                    height: '16px',
                                                    width: '16px',
                                                    cursor: !sync && canSync() ? 'pointer' : 'default',
                                                }}
                                                className={sync ? 'ti-spin' : ''}
                                            />
                                        </ActionIcon>
                                    </Tooltip>
                                </Group>
                            </div>
                        </Group>
                        <PanelBody noPadding style={{ position: 'relative', marginTop: 0, padding: nonResourcePage ? 0 : '0 24px 24px 24px' }}>
                            <ResourceContainer>{children}</ResourceContainer>
                        </PanelBody>
                    </PagePanel>
                </PaneledPage>
            </PageContent>
        </Box>
    );
}

const ResourceContainer = styled.div`
    height: 100%;
`;

import { Box, Button, Card, CloseButton, Grid, Group, Space, Text, Title } from '@mantine/core';
import { AnchorButton } from '@root/Design/Primitives';
import { ConnectCfContent, ConnectCliContent, ConnectTfContent } from '@root/Site/Settings';
import { AwsConnectionOptions } from '@root/Site/Settings/ConnectTwo';
import { ReactNode, useCallback, useState } from 'react';
import { ChevronDownLeft, ChevronLeft, CircleCheck } from 'tabler-icons-react';

export function AddConnection({ onClose, onComplete }: { onClose: () => void; onComplete: (newAccount: string) => void }) {
    const [method, setMethod] = useState<'terraform' | 'cli' | 'cloudformation' | null>();
    const back = useCallback(() => setMethod(null), []);
    return !method ? (
        <>
            <ConnectionContent onClose={onClose} title="Select Connection Method">
                <Grid>
                    <AwsConnectionOptions
                        onTerraformClick={{ onClick: () => setMethod('terraform') }}
                        onCliClick={{ onClick: () => setMethod('cli') }}
                        onCloudFormationClick={{ onClick: () => setMethod('cloudformation') }}
                    />
                </Grid>
            </ConnectionContent>
        </>
    ) : method === 'cloudformation' ? (
        <ConnectionCloudFormation onBack={back} onClose={onClose} onComplete={onComplete} />
    ) : method === 'cli' ? (
        <ConnectionCli onBack={back} onClose={onClose} onComplete={onComplete} />
    ) : method === 'terraform' ? (
        <ConnectionTerraform onBack={back} onClose={onClose} onComplete={onComplete} />
    ) : (
        <></>
    );
}

function useConnectionReady(onComplete: (newAccount: string) => void) {
    const [ready, setReady] = useState(false);
    const [accountId, setAccountId] = useState('');
    const handleContinue = useCallback(() => {
        onComplete(accountId);
    }, [ready, accountId, onComplete]);
    const handleReadyChange = useCallback((ready: boolean, accountId: string) => {
        setReady(ready);
        setAccountId(accountId);
    }, []);
    return { ready, handleReadyChange, handleContinue };
}

function ConnectionTerraform({ onBack, onComplete, onClose }: { onBack: () => void; onComplete: (newAccount: string) => void; onClose: () => void }) {
    const { ready, handleContinue, handleReadyChange } = useConnectionReady(onComplete);
    return (
        <ConnectionMethodContent onBack={onBack} onClose={onClose} title="Connect to AWS Using Terraform" onComplete={handleContinue} ready={ready}>
            <ConnectTfContent onReadyChanged={handleReadyChange} hideFooter />
        </ConnectionMethodContent>
    );
}

function ConnectionCli({ onBack, onComplete, onClose }: { onBack: () => void; onComplete: (newAccount: string) => void; onClose: () => void }) {
    const { ready, handleContinue, handleReadyChange } = useConnectionReady(onComplete);
    return (
        <ConnectionMethodContent onBack={onBack} onClose={onClose} title="Connect to AWS Using CLI" onComplete={handleContinue} ready={ready}>
            <ConnectCliContent platform="aws" onReadyChanged={handleReadyChange} hideFooter />
        </ConnectionMethodContent>
    );
}

function ConnectionCloudFormation({
    onBack,
    onComplete,
    onClose,
}: {
    onBack: () => void;
    onComplete: (newAccount: string) => void;
    onClose: () => void;
}) {
    const { ready, handleContinue, handleReadyChange } = useConnectionReady(onComplete);
    return (
        <ConnectionMethodContent
            onBack={onBack}
            onClose={onClose}
            title="Connect to AWS Using CloudFormation"
            onComplete={handleContinue}
            ready={ready}
        >
            <ConnectCfContent onReadyChanged={handleReadyChange} hideFooter />
        </ConnectionMethodContent>
    );
}

function ConnectionMethodContent({
    onClose,
    onBack,
    title,
    children,
    onComplete,
    ready,
}: {
    onClose: () => void;
    onBack?: () => void;
    title: string;
    children: ReactNode;
    onComplete: () => void;
    ready: boolean;
}) {
    return (
        <ConnectionContent onBack={onBack} onClose={onClose} title={title}>
            <Card shadow="sm" radius="md" withBorder p={0}>
                {children}
            </Card>
            <Space h="lg" />
            <Group position="apart" px="lg">
                <Text>{!ready ? 'Waiting for Account Connection' : ''}</Text>
                <Button disabled={!ready} onClick={onComplete}>
                    Continue
                </Button>
            </Group>
        </ConnectionContent>
    );
}

function ConnectionContent({ onClose, onBack, title, children }: { onClose: () => void; onBack?: () => void; title: string; children: ReactNode }) {
    return (
        <Box p="xl">
            {!onBack ? null : (
                <Box mt={'-1rem'}>
                    <AnchorButton size="sm" text="Change Connection Mode" icon={<ChevronLeft size={16} />} onClick={onBack} />
                </Box>
            )}
            <Group position="apart">
                <Title order={3}>{title}</Title>
                <CloseButton size="lg" onClick={onClose} />
            </Group>
            <Space h="lg" />
            {children}
        </Box>
    );
}

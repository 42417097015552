import React, { useEffect, useMemo, useState } from 'react';
import { Group, LoadingOverlay, Title, Stack, Text, Space } from '@mantine/core';
// Not sure why the path shortcuts (@root, etc.) aren't working.
import { DataGrid } from '../../Components/DataGrid';
import { endpoint } from '../../Services/Router/EndpointRegistry';
import { CreditFeeCards } from './Components/CreditFeeCards';
import { DatePeriodPicker } from './Components/DatePeriodPicker';
import { DataColumnConfig, DataGridState } from '../../Components/DataGrid/Models';
import { getCreditsGetCreditsSpentForPeriod } from '../../__generated__/Customers';
import { CompanyCreditUsage } from '../../__generated__/Customers/model';
import { FormatService } from '../../Services/FormatService';
import { useDi } from '../../Services/DI';
import { PageContent, PagePanel, PanelBody, PaneledPage } from '../../Design/Layout';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { SettingsPage } from '../Settings/SettingsPage';

export const CreditUsageReport = () => {
    const [isLoading, setIsLoading] = useState<Boolean>(false);
    const [selectedPeriod, setSelectedPeriod] = useState<string>('This Month');
    const [dateRange, setDateRange] = useState<{ startDate: Date; endDate: Date }>({
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
    });
    const [creditsUsed, setCreditsUsed] = useState<number>(0);
    const [creditUsageFees, setCreditUsageFees] = useState<number>(0);
    const [companyCreditUsageList, setCompanyCreditUsageList] = useState<CompanyCreditUsage[]>([]);
    const [datagridModel, setDatagridModel] = useState<DataGridModel>();
    const formatSvc = useDi(FormatService);

    useEffect(() => {
        LoadCreditData();
    }, [dateRange]);

    const LoadCreditData = async () => {
        try {
            setIsLoading(true);
            let usedCredits = await getCreditsGetCreditsSpentForPeriod({
                startDate: dateRange.startDate!.toDateString(),
                endDate: dateRange.endDate!.toDateString(),
                isMsp: true,
            });

            if (usedCredits && usedCredits[0]) setCompanyCreditUsageList(usedCredits ?? []);
            setCreditsUsed(calculateTotal(usedCredits.map((c) => c.CreditsUsed!)));
            setCreditUsageFees(calculateTotal(usedCredits.map((c) => c.CreditUsageFees!)));
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const calculateTotal = (numbers: number[]) => {
        return numbers.reduce((a, b) => {
            return a + b;
        }, 0);
    };

    const handleModelLoaded = (model: DataGridModel) => {
        setDatagridModel(model);
    };

    const handleStateChanged = async (state: DataGridState) => {
        let ds = (await datagridModel?.getData()) as CompanyCreditUsage[];
        setCreditsUsed(calculateTotal(ds.map((c) => c.CreditsUsed!)));
        setCreditUsageFees(calculateTotal(ds.map((c) => c.CreditUsageFees!)));
    };

    const columns = useMemo(
        () =>
            [
                {
                    id: 'Company',
                    accessor: (item) => item.CompanyName,
                    header: 'Company',
                    align: 'left',
                    type: 'string',
                    defaultWidth: 300,
                    filter: {
                        filterType: 'string',
                        name: 'Company',
                        filterField: 'CompanyName',
                    },
                },
                {
                    id: 'CreditsConsumed',
                    accessor: (item) => item.CreditsUsed,
                    cellRenderer: (item) => formatSvc.formatInt(item.CreditsUsed!),
                    header: 'Credits Consumed',
                    align: 'left',
                    type: 'number',
                    defaultWidth: 200,
                    filter: {
                        filterType: 'string',
                        name: 'Credits Consumed',
                        filterField: 'CreditsUsed',
                    },
                },
                {
                    id: 'CreditFees',
                    accessor: (item) => item.CreditUsageFees,
                    cellRenderer: (item) => formatSvc.formatMoney(item.CreditUsageFees!),
                    header: 'Credit Fees',
                    align: 'left',
                    type: 'number',
                    defaultWidth: 200,
                    filter: {
                        filterType: 'string',
                        name: 'Credit Fees',
                        filterField: 'CreditUsageFees',
                    },
                },
            ] as DataColumnConfig<CompanyCreditUsage>[],
        []
    );

    return (
        <SettingsPage>
            {isLoading && <LoadingOverlay visible={true} />}
            <PageContent>
                <PaneledPage>
                    <PagePanel size="fill">
                        <PanelBody>
                            <Group position="apart">
                                <Title>Credit Usage Report</Title>
                                <Stack sx={{ gap: '0px' }}>
                                    <Text style={{ paddingLeft: '16px', marginBottom: '8px' }}>Reporting Period</Text>
                                    <DatePeriodPicker range={dateRange} selectedPeriodChanged={setSelectedPeriod} onRangeChanged={setDateRange} />
                                </Stack>
                            </Group>
                            <Space h={16} />
                            <CreditFeeCards creditUsageFees={creditUsageFees} creditsUsed={creditsUsed} selectedDate={selectedPeriod} />
                            <Space h={16} />
                            <DataGrid
                                onModelLoaded={handleModelLoaded}
                                onStateChanged={handleStateChanged}
                                hideGlobalSearch
                                showRefresh={true}
                                dataSource={companyCreditUsageList}
                                columns={columns}
                            />
                        </PanelBody>
                    </PagePanel>
                </PaneledPage>
            </PageContent>
        </SettingsPage>
    );
};

endpoint('credit-usage-report', CreditUsageReport, 'Credit Usage Report');

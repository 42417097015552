/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.TagManager.Host.Api
 * OpenAPI spec version: 1.0
 */

export type TagAutomationRuleStatus = typeof TagAutomationRuleStatus[keyof typeof TagAutomationRuleStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TagAutomationRuleStatus = {
  Draft: 'Draft',
  Active: 'Active',
  Deleted: 'Deleted',
  Test: 'Test',
} as const;

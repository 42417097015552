import styled from '@emotion/styled';
import { useMantineTheme } from '@mantine/core';
import { ReactNode } from 'react';

export const ReportPanel = styled.div<{ maxWidth?: NumberConstructor }>`
    display: flex;
    flex-direction: column;
    height: 100%;
    --report-panel-max-width: ${(p) => p.maxWidth ?? 1248}px;
`;

export function ReportContainer({ children, fullHeight, padding, bg }: { children: ReactNode; fullHeight?: boolean; padding?: number; bg?: 'gray' }) {
    const theme = useMantineTheme();
    return (
        <ReportContainerEl style={{ background: bg === 'gray' ? theme.colors.gray[1] : undefined }}>
            <ReportContainerContentEl style={{ padding: padding ?? 24, height: fullHeight ? '100%' : undefined }}>
                {children}
            </ReportContainerContentEl>
        </ReportContainerEl>
    );
}

export const ReportContainerEl = styled.div`
    overflow: auto;
    flex: 1;
`;

export const ReportContainerContentEl = styled.div`
    max-width: var(--report-panel-max-width);
    padding: 24px;
`;

export const ReportFooter = styled.div`
    max-width: var(--report-panel-max-width);
    flex-grow: 0;
    flex-shrink: 0;
    padding: 16px 0;
`;

export function ReportHeader({ children }: { children: ReactNode }) {
    return (
        <ReportHeaderEl>
            <ReportHeaderContentEl>{children}</ReportHeaderContentEl>
        </ReportHeaderEl>
    );
}

const ReportHeaderEl = styled.div`
    border-bottom: solid 1px ${(p) => p.theme.colors.gray[3]};
    min-height: 64px;
    flex-grow: 0;
    flex-shrink: 0;
`;

const ReportHeaderContentEl = styled.div`
    display: flex;
    max-width: var(--report-panel-max-width);
    justify-content: space-between;
    align-items: center;
    min-height: 64px;
    padding: 16px 24px;
`;

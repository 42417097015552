import { Query } from '@apis/Resources/model';
import { ScopedRelationshipBundleService } from '@root/Services/DataMarketplace/DataMarketplaceApiService';
import { EventEmitter } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { cleanQueryDates, queryBuilder } from '@root/Services/QueryExpr';
import { addDays, startOfDay } from 'date-fns';

export type DisplayMode = 'instance-type' | 'company' | 'msp' | 'custom';

type ChipMfgs = 'AMD' | 'Intel' | 'AWS' | 'Azure';
export type ChipMfgDescriptor = { mfg: ChipMfgs; chip: string; dark: string; light: string; universal?: boolean };
const mfgChipNames: ChipMfgDescriptor[] = [
    { mfg: 'AMD', chip: 'AMD', universal: true, dark: '#AD1717', light: '#EBC7C7' },
    { mfg: 'Intel', chip: 'Intel', universal: true, dark: '#157CAD', light: '#C7E0EB' },
    { mfg: 'AWS', chip: 'Graviton', dark: '#AD9117', light: '#EBE5C7' },
    { mfg: 'Azure', chip: 'Azure', dark: '#14BDD4', light: '#9FDBEB' },
];
const chipMfgs = mfgChipNames.reduce((acc, { mfg, chip, dark, light, ...rest }) => {
    acc[mfg] = { mfg, chip, dark, light, ...rest };
    return acc;
}, {} as Record<ChipMfgs, ChipMfgDescriptor>);

export const computeMktshrMfgs = {
    chipMfgs,
    mfgChipNames,
};

export interface ChipMfgMarketshareDigest {
    Date: Date;
    MspName: string;
    MspId: number;
    CustomerName: string;
    CustomerId: number;
    PhysicalProcessor: string;
    ProcessorFamily: string;
    InstanceType: string;
    CloudProvider: string;
    Region: string;
    InstanceVCPUs: number;
    PurchaseMethod: string;
    ListPrice: number;
    ListCost: number;
    DistinctInstanceCount: number;
    HoursConsumed: number;
    vCPUHours: number;
    vCPUQuantity: number;
    CalculatedRate: number;
    ActualCost: number;
    DataAccessRelationshipId: number;
}
export interface IChipMfgGridRow extends ChipMfgMarketshareDigest {
    Month: string;
    MfgStats: Record<ChipMfgs | 'any', { qty: number; share: number; delta: null | number }>;
}

export interface ChipMfgFieldDescriptor {
    field: keyof ChipMfgMarketshareDigest;
    label: string;
    description: string;
    type: 'string' | 'number' | 'date';
    aggs: ('group' | 'sum' | 'avg' | 'avg-day-sum')[];
    format?: 'date' | 'currency-2dec' | 'currency-4dec' | 'whole-number' | 'number-2dec';
}
const chipMfgFields: ChipMfgFieldDescriptor[] = [
    {
        field: 'Date',
        label: 'Date',
        description: 'Date',
        type: 'date',
        aggs: ['group'],
        format: 'date',
    },
    {
        field: 'MspName',
        label: 'MSP',
        description: 'Managed service provider or partner name',
        type: 'string',
        aggs: ['group'],
    },
    {
        field: 'CustomerName',
        label: 'End Customer',
        description: 'End customer name, which may be obfuscated',
        type: 'string',
        aggs: ['group'],
    },
    {
        field: 'PhysicalProcessor',
        label: 'Processor',
        description: 'Manufacturer and model(s) of the processor',
        type: 'string',
        aggs: ['group'],
    },
    {
        field: 'ProcessorFamily',
        label: 'Vendor',
        description: 'Processor vendor',
        type: 'string',
        aggs: ['group'],
    },
    {
        field: 'InstanceType',
        label: 'Instance Type',
        description: 'Cloud provider specific name of instance type',
        type: 'string',
        aggs: ['group'],
    },
    {
        field: 'CloudProvider',
        label: 'Cloud Provider',
        description: 'Cloud provider',
        type: 'string',
        aggs: ['group'],
    },
    {
        field: 'Region',
        label: 'Region',
        description: 'Cloud provider specific name of region',
        type: 'string',
        aggs: ['group'],
    },
    {
        field: 'InstanceVCPUs',
        label: 'Instance vCPUs',
        description: 'Number of vCPUs for the instance type',
        type: 'number',
        aggs: ['group'],
    },
    {
        field: 'PurchaseMethod',
        label: 'Purchase Method',
        description: 'Purchase method, On-Demand, Reserved, Savings Plan, Spot',
        type: 'string',
        aggs: ['group'],
    },
    {
        field: 'ListPrice',
        label: 'List Price',
        description: 'List price of the instance type',
        type: 'number',
        aggs: ['avg'],
        format: 'currency-4dec',
    },
    {
        field: 'ListCost',
        label: 'List Cost',
        description: 'List cost of the usage',
        type: 'number',
        aggs: ['sum'],
        format: 'currency-2dec',
    },
    {
        field: 'DistinctInstanceCount',
        label: 'Instance Count',
        description: 'Number of daily distinct instances',
        type: 'number',
        aggs: ['avg'],
        format: 'number-2dec',
    },
    {
        field: 'HoursConsumed',
        label: 'Hours Consumed',
        description: 'Total hours of usage',
        type: 'number',
        aggs: ['sum'],
        format: 'number-2dec',
    },
    {
        field: 'vCPUHours',
        label: 'vCPU Hours',
        description: 'Total hours of usage times number of vCPUs',
        type: 'number',
        aggs: ['sum'],
        format: 'number-2dec',
    },
    {
        field: 'vCPUQuantity',
        label: 'vCPUs',
        description: 'Total vCPU Hours over total hours in period',
        type: 'number',
        aggs: ['avg-day-sum'],
        format: 'number-2dec',
    },
    {
        field: 'CalculatedRate',
        label: 'Usage Rate',
        description: 'Actual cost over hours consumed',
        type: 'number',
        aggs: ['avg'],
        format: 'currency-4dec',
    },
    {
        field: 'ActualCost',
        label: 'Actual Cost',
        description: 'Actual cost to end customer',
        type: 'number',
        aggs: ['sum'],
        format: 'currency-2dec',
    },
];

export class ChipMfgMarketshareModel {
    public readonly availDateRange = new EventEmitter<{ min?: Date; max?: Date } | undefined>(undefined);
    public readonly cspFilter = new EventEmitter<string>('');
    public readonly selectedDateRange = new EventEmitter<{ from?: Date; to?: Date }>({});
    public readonly displayMode: EventEmitter<DisplayMode>;
    public readonly rowsSelected = new EventEmitter<{ item: IChipMfgGridRow; color: string }[]>([]);
    public readonly initializing = new EventEmitter(true);

    private readonly fieldDescriptorLookup = new Map<keyof ChipMfgMarketshareDigest, ChipMfgFieldDescriptor>(chipMfgFields.map((f) => [f.field, f]));

    public constructor(private readonly relSvc: ScopedRelationshipBundleService, private readonly fmtSvc: FormatService, mode: DisplayMode) {
        this.displayMode = new EventEmitter<DisplayMode>(mode);
    }

    public init() {
        this.loadDateRange();
        return this;
    }

    public updateDisplayMode = (mode: DisplayMode) => {
        this.rowsSelected.emit([]);
        this.displayMode.emit(mode);
    };

    public query<TResult>(query: Query) {
        cleanQueryDates(query);
        return this.relSvc.query<TResult>(query);
    }

    public isRelationshipScoped() {
        return !!this.relSvc.relationshipId;
    }

    public getRelId(relatedCompanyId: number) {
        return this.relSvc.getRelByRelCompanyId(relatedCompanyId)?.Id ?? 0;
    }

    public getDefId() {
        return this.relSvc.defId;
    }

    public getDataRelationshipParameters() {
        const { primaryCompanyId, relatedCompanyId, dataDefinition } = this.relSvc.getQueryParams();
        return { primaryCompanyId, relatedCompanyId, dataDefinitionId: dataDefinition?.Id };
    }

    public getFieldDescriptors() {
        return chipMfgFields;
    }

    public getFieldFormatter(format: ChipMfgFieldDescriptor['format']) {
        return (value: number) =>
            format === 'currency-2dec'
                ? this.fmtSvc.formatMoneyNonZeroTwoDecimals(value)
                : format === 'currency-4dec'
                ? this.fmtSvc.formatMoney4Decimals(value)
                : format === 'number-2dec'
                ? this.fmtSvc.formatDecimal2(value)
                : format === 'whole-number'
                ? this.fmtSvc.formatInt0Dec(value)
                : this.fmtSvc.formatDecimal2(value);
    }

    public getFieldDescriptor(field: string) {
        return this.fieldDescriptorLookup.get(field as keyof ChipMfgMarketshareDigest);
    }

    public getLinkParams(additional: Record<string, string> = {}) {
        const result: Record<string, string> = {
            id: this.relSvc.defId.toString(),
        };
        const range = this.selectedDateRange.value;
        if (range.from && range.to) {
            result.range = `${this.fmtSvc.to8DigitDate(range.from)}-${this.fmtSvc.to8DigitDate(range.to)}`;
        }
        if (this.relSvc.relationshipId) {
            result.relId = this.relSvc.relationshipId.toString();
        }

        return { ...result, ...additional };
    }

    public getSelectedAvailDateRange() {
        const { min, max } = this.availDateRange.value ?? {};
        const { from, to } = this.selectedDateRange.value ?? {};
        const yday = addDays(startOfDay(new Date()), -1);
        const dayBefore = addDays(yday, -1);

        return {
            from: !min || !from ? from ?? min ?? dayBefore : from.getTime() < min.getTime() ? min : from,
            to: !max || !to ? to ?? max ?? yday : to.getTime() > max.getTime() ? max : to,
        };
    }

    public *getDatesInPeriod() {
        const { from, to } = this.getSelectedAvailDateRange();
        for (let date = from; date <= to; date = addDays(date, 1)) {
            yield date;
        }
    }

    public getDaysInPeriod() {
        const { from, to } = this.getSelectedAvailDateRange();
        return !from || !to ? 1 : Math.round((to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24)) + 1;
    }

    public async loadDateRange() {
        try {
            const dateRangeResponse = await queryBuilder<ChipMfgMarketshareDigest>()
                .select((b) => ({
                    start: b.min(b.model.Date),
                    end: b.max(b.model.Date),
                }))
                .execute((q) => this.relSvc.query(q));
            const result = dateRangeResponse.Results?.[0] ?? { start: null, end: null };
            const from = result.start ? this.fmtSvc.parseDateNoTime(result.start) : undefined;
            const to = result.end ? this.fmtSvc.parseDateNoTime(result.end) : undefined;

            this.availDateRange.emit({
                min: from,
                max: to,
            });

            if (from && to && (!this.selectedDateRange.value.from || !this.selectedDateRange.value.to)) {
                this.selectedDateRange.emit({ from, to });
            }
        } finally {
            this.initializing.emit(false);
        }
    }
}

import { JobOfCostForecast, QueryExpr } from '@apis/Resources';
import { Card, Text, Box, Space, useMantineTheme, Title, Button, Anchor, Tooltip, Group, ThemeIcon } from '@mantine/core';
import { PageBody, PageContent, PaneledPage, PanelHeader } from '@root/Design/Layout';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { useRowSelector } from '../Components/ChartKeyRowSelector';
import { useGridResizer } from '../Components/GridResizer';
import { ChartKeySelectionStrategy } from '../Models/ChartKeySelectionStrategy';
import { useForecastRange } from './Components';
import { SpendForecastModel } from './Models';
import { CostForecastRow, CostForecastsGrid } from './CostForecastsGrid';
import { CostForecastChart } from './CostForecastChart';
import { Check, FileSpreadsheet, Folders, LayoutSidebarRightExpand, Settings, Square, SquareCheck } from 'tabler-icons-react';
import { ForecastDetailsDrawer } from './ForecastDetails';
import { useEvent } from '@root/Services/EventEmitter';
import { TooltipWhite } from '@root/Design/Primitives';
import { useDi } from '@root/Services/DI';
import { ActivityPanelModel } from '@root/Components/Actions/ActivityPanel/Models';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { ForecastConfig } from '@apis/Invoices/model';
import { CommonForecastDetails } from '@root/Services/Invoices/CostForecastService';
import { IQueryExpr } from '@apis/Resources/model';

interface IOnDemandLoadedCostForecastProps {
    model: SpendForecastModel;
    job: JobOfCostForecast;
    tenantForecast: false | undefined;
}
interface ITenantLoadedCostForecastProps {
    model: SpendForecastModel;
    job: undefined;
    tenantForecast: true;
}

type LoadedCostForecastProps = IOnDemandLoadedCostForecastProps | ITenantLoadedCostForecastProps;

export function LoadedCostForecast({ model, job, tenantForecast }: LoadedCostForecastProps) {
    const theme = useMantineTheme();
    const { canManage } = useAuthZValues({
        canManage: { CostForecasting: 'Manage' },
    });
    const [gridFilters, setGridFilters] = useState<QueryExpr[]>();
    const [gridSelectionFilters, setGridSelectionFilters] = useState<QueryExpr[]>();
    const [showHistorical, setShowHistorical] = useState(true);
    const [loading, setLoading] = useState(false);
    const [tenantForecastConfig, setTenantForecastConfig] = useState<ForecastConfig>();
    const [commonDetails, setCommonDetails] = useState<CommonForecastDetails>();
    const range = useForecastRange(job ?? commonDetails);
    const gridSelectionStrategy = useMemo(() => new ChartKeySelectionStrategy(10), [job?.Id]);
    const rowSelector = useRowSelector({ gridSelectionStrategy, color: theme.colors.primary[6], tooltip: 'Chart this row' });

    const { Resizer, containerStyle, setScrollContainer } = useGridResizer();

    useEffect(() => {
        if (tenantForecast) {
            (async () => {
                setLoading(true);
                try {
                    const { config, ...details } = await model.getTenantForecastDetails();
                    setTenantForecastConfig(config);
                    setCommonDetails(details);
                } finally {
                    setLoading(true);
                }
            })();
        }
    }, [tenantForecast]);

    const handleSelectionChanged = useCallback(async ({ getItems }: { getItems: () => Promise<CostForecastRow[]> }) => {
        const selections = await getItems();
        const createRowExpr = (row: CostForecastRow, exprs: QueryExpr[]) => {
            exprs.push(
                row.nullValue
                    ? { Operation: 'isnull', Operands: [{ Field: row.type }] }
                    : { Operation: 'eq', Operands: [{ Field: row.type }, { Value: row.value }] }
            );
            if (row.parent) {
                createRowExpr(row.parent, exprs);
            }
        };
        let selectionFilters: QueryExpr[] = [];
        if (selections.length) {
            selections.forEach((row) => {
                const selectionFilter: QueryExpr[] = [];
                createRowExpr(row, selectionFilter);
                if (selectionFilter.length > 1) {
                    selectionFilters.push({ Operation: 'and', Operands: selectionFilter });
                } else if (selectionFilter.length === 1) {
                    selectionFilters.push(selectionFilter[0]);
                }
            });
        }
        setGridSelectionFilters(
            selectionFilters.length === 1 ? selectionFilters : selectionFilters.length > 1 ? [{ Operation: 'or', Operands: selectionFilters }] : []
        );
    }, []);

    useEffect(() => {
        setGridSelectionFilters([]);
    }, [JSON.stringify(gridFilters)]);

    const handleGridFiltersChanged = useCallback((filters: QueryExpr[]) => {
        setGridFilters(filters.slice());
    }, []);

    let chartFilters = useMemo(() => [...(gridFilters ?? []), ...(gridSelectionFilters ?? [])] as QueryExpr[], [gridFilters, gridSelectionFilters]);

    const gridKey = tenantForecast ? 'TenantCostForecast-' + tenantForecastConfig?.Version : 'CostForecastGrid-' + job.Id;

    const loadDetails = useCallback(() => {
        if (job) {
            model.loadDetails(job);
        }
    }, [job?.Id, model]);

    useEvent(model.forecastRenamed);

    const exportData = useCallback(
        async (filters: IQueryExpr[], groups: string[], granularity: 'Daily' | 'Monthly', showHistorical: boolean) => {
            await model.exportData(job, range, filters, granularity, showHistorical, groups);
        },
        [model, job, range]
    );

    return tenantForecast && !tenantForecastConfig ? null : (
        <PageBody>
            <PageContent ref={setScrollContainer}>
                <PaneledPage>
                    <Box
                        p="xl"
                        sx={{
                            gap: 24,
                            height: '100%',
                            width: '100%',
                            weight: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: 850,
                        }}
                    >
                        <PanelHeader style={{ flex: 0, padding: 0 }}>
                            <Box>
                                <Title order={3} data-atid="AISpendForecastHeader">
                                    {tenantForecast ? 'Cost Forecast' : model.getForecastName(job)}
                                </Title>
                                <Space h="xs" />
                                {tenantForecast ? null : (
                                    <Tooltip withinPortal label="View Forecast Details" position="right" withArrow arrowSize={8}>
                                        <Anchor
                                            onClick={loadDetails}
                                            size="sm"
                                            data-atid="open-forecast-details-line"
                                            sx={{
                                                ['svg']: { display: 'none', verticalAlign: 'text-bottom', marginLeft: 4 },
                                                ['&:hover svg']: { display: 'inline-block' },
                                            }}
                                        >
                                            {model.forecastPersistence.createDescription(job).description}
                                            <LayoutSidebarRightExpand color="#0008" size={20} strokeWidth={1.5} />
                                        </Anchor>
                                    </Tooltip>
                                )}
                            </Box>
                            <Group>
                                {tenantForecast ? null : (
                                    <Button variant="outline" onClick={model.openLoader} data-atid="LoadForecastGridButton" leftIcon={<Folders />}>
                                        Open Saved Forecast
                                    </Button>
                                )}
                                {!canManage ? null : tenantForecast ? (
                                    <Button variant="light" onClick={model.openConfig} data-atid="ConfigureTenantForecast" rightIcon={<Settings />}>
                                        Configure
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={model.createForecast}
                                        data-atid="NewForecastButton"
                                        rightIcon={<i className="ti ti-chevron-right" />}
                                    >
                                        Start New Forecast
                                    </Button>
                                )}
                            </Group>
                        </PanelHeader>
                        <Card
                            withBorder
                            sx={{ overflow: 'visible', flexGrow: 1, minHeight: 300, background: '#fafafa' }}
                            radius="md"
                            shadow="sm"
                            p={0}
                        >
                            <CostForecastChart
                                invoiceApi={model.invoiceApi}
                                filters={chartFilters}
                                job={job}
                                range={range}
                                forecastStartDate={range?.forecastRange.from!}
                                showHistorical={showHistorical}
                            />
                        </Card>
                        <Box sx={{ height: '100%', overflow: 'hidden', ...containerStyle }}>
                            <CostForecastsGrid
                                invoiceApi={model.invoiceApi}
                                persistenceKey={gridKey}
                                job={job}
                                exportHandler={exportData}
                                forecastDetails={commonDetails}
                                onShowHistoricalChanged={setShowHistorical}
                                onFilterChanged={handleGridFiltersChanged}
                                onSelectionChanged={handleSelectionChanged}
                                range={range}
                                selectionStrategy={gridSelectionStrategy}
                                renderRowSelector={rowSelector}
                                rightPlaceholder={<Resizer />}
                            />
                        </Box>
                    </Box>
                </PaneledPage>
            </PageContent>
            <ForecastDetailsDrawer model={model} />
        </PageBody>
    );
}

import { Drawer } from '@mantine/core';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { useCallback } from 'react';
import { AdvancedDisbTargetEditor } from './AdvancedDisbTargetEditor';
import { AdvancedLineItemSourceEditor } from './AdvancedLineItemSourceEditor';
import { AllocSettingsEditRequest, BaseAllocSettingsEditorProps } from './Models';

type AdvancedAllocSettingsDrawerProps = {
    editRequest: EventEmitter<AllocSettingsEditRequest>;
} & BaseAllocSettingsEditorProps;
export function AdvancedAllocSettingsDrawer({ editRequest, ...props }: AdvancedAllocSettingsDrawerProps) {
    const request = useEventValue(editRequest);
    const close = useCallback(() => editRequest.emit(undefined), []);
    return (
        <Drawer opened={!!request} withCloseButton={false} size={1400} withinPortal closeOnEscape={false} onClose={close} position="right">
            {!request ? null : 'source' in request ? (
                <AdvancedLineItemSourceEditor {...props} {...request} onCancel={close} />
            ) : 'target' in request ? (
                <AdvancedDisbTargetEditor {...props} {...request} onCancel={close} />
            ) : null}
        </Drawer>
    );
}

import styled from '@emotion/styled';
import { NavBarItem, PageNavBar } from '@root/Components/PageNavBar/PageNavBar';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { useDi } from '@root/Services/DI';
import { Platform, PlatformService } from '@root/Services/PlatformService';
import { useEffect, useMemo, useState } from 'react';

export function TagIntelligenceNav() {
    const platformSvc = useDi(PlatformService);
    const [platforms, setPlatforms] = useState<Set<Platform>>();
    const userPermissions = useAuthZValues({
        canViewTagPolicies: { TagPolicy: 'View' },
    });

    useEffect(() => {
        (async () => {
            await platformSvc.init();
            setPlatforms(platformSvc.getPlatforms());
        })();
    }, []);
    const items = useMemo(() => {
        const aws = platforms?.has('Aws');
        const result: (NavBarItem | null)[] = [];
        result.push(
            {
                type: 'link',
                atid: 'EnvironmentSummaryNavLink',
                text: 'Environment Summary',
                icon: <i style={{ fontSize: '24px' }} className="ti ti-chart-line"></i>,
                endpoint: 'intelligence-dashboard',
            },
            {
                type: 'link',
                atid: 'TagHealthNavLink',
                text: 'Tag Health',
                icon: <i style={{ fontSize: '24px' }} className="ti ti-wave-saw-tool"></i>,
                endpoint: 'tag-health',
            },
            {
                type: 'link',
                atid: 'UntaggedResourcesNavLink',
                text: 'Untagged Resources',
                icon: <NavIcon src="/assets/untagged-resources.svg" />,
                endpoint: 'untagged-resources',
            },
            {
                type: 'link',
                atid: 'TagPoliciesNavLink',
                text: 'Tag Policies',
                icon: <i style={{ fontSize: '24px' }} className="ti ti-badge"></i>,
                endpoint: 'tag-policies',
                visible: userPermissions.canViewTagPolicies,
            },
            {
                type: 'link',
                atid: 'TagConsolidationNavLink',
                text: 'Tag Consolidation',
                icon: <i style={{ fontSize: '24px' }} className="ti ti-layers-intersect"></i>,
                endpoint: 'tag-consolidation',
            },
            //!aws ? null : { type: 'link', text: 'Spend Coverage', icon: <i className="ti ti-chart-line"></i>, endpoint: 'spend-coverage' },
            !aws
                ? null
                : {
                      type: 'link',
                      atid: 'TagHierarchyNavLink',
                      text: 'Tag Hierarchy',
                      icon: <i style={{ fontSize: '24px' }} className="ti ti-list-tree"></i>,
                      endpoint: 'tag-hierarchy',
                  }
        );
        return result.filter((item) => !!item) as NavBarItem[];
    }, [platforms]);

    return platforms ? <PageNavBar items={items} /> : null;
}

const NavIcon = styled.img`
    height: 20px;
`;

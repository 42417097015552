import { Box, Button, Tabs } from '@mantine/core';
import { useCompany } from '../Router/CompanyContent';

function openHelp() {
    window.open('https://knowledge.cloudsaver.com/s/article/Connection-Wizard');
}

export function RemoveAccountBody() {
    return (
        <>
            <Tabs defaultValue="Azure">
                <Tabs.List>
                    <Tabs.Tab value="AWS">AWS</Tabs.Tab>
                    <Tabs.Tab value="Azure">Azure</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="AWS" pt="xs">
                    Delete the IAM role for the CloudSaver platform from within your account(s). By default the role is named "CloudSaver-Role".
                </Tabs.Panel>
                <Tabs.Panel value="Azure" pt="xs">
                    <ol style={{ margin: '0', paddingLeft: '16px' }}>
                        <li>Delete the Cloudsaver subscription, then delete role assignments named "Cloudsaver-App-xx".</li>
                        <li>
                            Delete the "Cloudsaver-Role" role <span style={{ fontWeight: 'bold' }}>ONLY IF</span> there are no other Azure
                            subscriptions connected to Cloudsaver.
                        </li>
                        <li>
                            Delete owned applications named "Cloudsaver-App-xx" <span style={{ fontWeight: 'bold' }}>ONLY IF</span> there are no other
                            Azure subscriptions connected to Cloudsaver.
                        </li>
                    </ol>
                </Tabs.Panel>
            </Tabs>
            <Button variant="light" fullWidth mt="md" onClick={openHelp}>
                View Knowledge Base&nbsp;&nbsp;
                <i className="ti ti-external-link"></i>
            </Button>
        </>
    );
}

import { CompanyRelationshipBundle } from '@apis/Customers/model';
import { useCompany } from '@root/Components/Router/CompanyContent';
import {
    DataMarketplaceApiService,
    IDataDefinitionDigest,
    RelationshipBundleService,
} from '@root/Services/DataMarketplace/DataMarketplaceApiService';
import { useDi } from '@root/Services/DI';
import { useNav } from '@root/Services/NavigationService';
import { useState, useEffect, useMemo } from 'react';

export function useParamsDataDef() {
    const marketplaceSvc = useDi(DataMarketplaceApiService);
    const { id: rawDefId } = useNav().getData('id');
    const defId = parseInt(rawDefId ?? '0');
    const [relationships, setRelationships] = useState<CompanyRelationshipBundle>();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        marketplaceSvc
            .getCompanyRelationships()
            .then((data) => {
                setRelationships(data);
            })
            .finally(() => setLoading(false));
    }, [defId]);
    const definition = relationships?.Definitions?.find((d) => d.Id === defId) ?? relationships?.Definitions?.[0];
    const digest = definition?.Digest as unknown as IDataDefinitionDigest | undefined;

    return { loading, digest, relationships, definition, marketplaceSvc };
}

export type ParamsScopedDataDef = ReturnType<typeof useParamsScopedDataDef>;
export function useParamsScopedDataDef() {
    const company = useCompany();
    const dataDefParams = useParamsDataDef();
    const { relId: rawRelId } = useNav().getData('relId');
    const relId = !rawRelId ? undefined : parseInt(rawRelId ?? '0');

    const scoped = useMemo(() => {
        const { definition, relationships, marketplaceSvc } = dataDefParams;
        if (!definition || !company || !relationships) {
            return {};
        } else {
            const scope = RelationshipBundleService.create(relationships, company, marketplaceSvc).scope(definition.Id!, relId);
            const participantType = scope.getParticipationType();
            const participants = scope.getParticipants();

            return { scope, participantType, participants };
        }
    }, [dataDefParams.definition, relId, company]);

    return { ...dataDefParams, ...scoped, relationshipId: relId };
}

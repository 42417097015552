import styled from '@emotion/styled';
import { Box, Group, Space, Title } from '@mantine/core';
import { WizardForm, WizardFormTitle, WizardFormStepContainer } from '@root/Components/Wizard/WizardForm';
import { PageContent } from '@root/Design/Layout';
import { CustomColors } from '@root/Design/Themes';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useLink } from '@root/Services/Router/Router';
import { WizardHeader } from './Common';

export const PlatformButton = styled.a`
    display: block;
    padding: 0 ${(p) => p.theme.spacing.md}px;
    line-height: 40px;
    border: 1px solid ${(p) => p.theme.colors?.gray?.[3] as CustomColors};
    border-radius: 10px;
    color: ${(p) => p.theme.black};
    text-decoration: none;
    font-weight: 500;
    &:hover {
        border-color: ${(p) => p.theme.colors.primary[4]};
        box-shadow: 0 0 2px 2px ${(p) => p.theme.colors?.primary?.[2]};
        cursor: pointer;
    }
`;

export function PlatformBox(platform: string) {
    return (
        <Group p="md" position="apart">
            <Group position="left">
                <div>
                    {platform === 'AWS' ? (
                        <img src="/assets/Amazon_Web_Services_Logo.svg" style={{ width: '25px' }} />
                    ) : (
                        <img src="/assets/azure_logo.svg" style={{ width: '25px' }} />
                    )}
                </div>
                <div>Connect {platform} Account</div>
            </Group>
            <i className="ti ti-chevron-right" />
        </Group>
    );
}

export function ConnectChoosePlatform() {
    const { getMoveUrl, goto } = useNav();
    const link = useLink();

    goto('connections-wizard');

    return (
        <PageContent>
            <WizardHeader />
            <WizardForm maxWidth={900}>
                <WizardFormTitle title="Select Cloud Platform" />
                <WizardFormStepContainer>
                    <Box p="xl">
                        <Title order={2}>Which cloud platform do you want to connect?</Title>

                        <Space h="xl" />
                        <PlatformButton {...link(getMoveUrl('connect-one', { platform: 'AWS' }))}>{PlatformBox('AWS')}</PlatformButton>
                        <Space h="md" />

                        <PlatformButton {...link(getMoveUrl('connect-one', { platform: 'Azure' }))}>{PlatformBox('Azure')}</PlatformButton>
                    </Box>
                </WizardFormStepContainer>
            </WizardForm>
        </PageContent>
    );
}
endpoint('connect-choose-platform', ConnectChoosePlatform, 'Connection Wizard');

import { SubscriptionCheck } from '@root/Components/CompanyInfo/SubscriptionCheck';
import { FieldInfoColumnAdapter } from '@root/Components/DataGrid/FieldInfoColumnAdapter';
import { GridColumnState } from '@root/Components/DataGrid/Models';
import { ResourceGridModel } from '@root/Components/Resources/ResourcesGrid';
import { useDi } from '@root/Services/DI';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useCallback } from 'react';
import { IdleResourceGrid } from '../Components/IdleResourcesGrid';
import { IdleResourcesPage } from '../Components/IdleResourcesPage';
import { IdleResourcesService } from '../../../Services/Resources/IdleResourcesService';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';
import { IdleResourcesTooltip } from '../Components/IdleResourcesTooltip';
import { AppFeatureNames } from '@root/Services/Customers/CompanyFeatureService';

export function IdleELBVContent() {
    const idleResourceService = useDi(IdleResourcesService);

    const resourceColumns: GridColumnState[] = [
        { id: 'ResourceType', width: 130 },
        { id: 'Metrics.IdleResources.SumOfRequests', width: 150 },
        { id: 'State.Code.Value', width: 90 },
        { id: 'InstancesCount', width: 140 },
        { id: 'Autoscaling', width: 120 },
    ];
    const columnAdapter = useDi(FieldInfoColumnAdapter);

    const updateCommonColumns = useCallback((grid: ResourceGridModel) => {
        const idleCheckColumns = grid.availableColumns.filter((c) => resourceColumns.map((a) => a.id).includes(c.sortField ?? ''));
        if (idleCheckColumns) {
            idleCheckColumns.forEach((c) => {
                const path = c.sortField?.split('.') ?? [];
                if (c.sortField !== 'ResourceType') {
                    const accessorPath = path.map((accessor, i) => ({ accessor, isLeaf: 1 + i === path.length }));
                    const accessor = columnAdapter.createAccessor(accessorPath);
                    c.accessor = (item) => accessor(item);
                    c.groupName = 'Idle Metrics';
                }
            });
        }
    }, []);

    return (
        <IdleResourcesPage title="ELB" types={['ELBV1', 'ELBV2']} IdleResourcesTooltip={<IdleResourcesTooltip resourceType="ELB" />}>
            <IdleResourceGrid
                refreshNeeded={idleResourceService.refreshNeeded}
                title="ELB"
                resourceType="ELB"
                includeMemory={false}
                extraColumns={resourceColumns}
                onColumnsLoaded={updateCommonColumns}
            />
        </IdleResourcesPage>
    );
}

function IdleELBV() {
    return (
        <SubscriptionCheck app="Optimization" featureName={AppFeatureNames.IdleResources}>
            {() => <ConnectionCheck>{() => <IdleELBVContent />}</ConnectionCheck>}
        </SubscriptionCheck>
    );
}
endpoint('idle-elbv', IdleELBV, 'Idle Resources');
endpoint('idle-elb', IdleELBV, 'Idle Resources');

import { Button, Group, Modal, Space, SelectItem, Select, createStyles } from '@mantine/core';
import { useMemo, useState } from 'react';
import { useDi, useDiContainer } from '@root/Services/DI';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { Account } from '@apis/Customers/model';
import { closeModal } from '@mantine/modals';
import { BasicApi } from '@root/Services/BasicApi';
import { GetConnectionScriptsCsCloudformationYmlPlatform, GetConnectionScriptsCsCloudformationYmlProductsItem } from '@apis/Resources/model';
import { IacConnector } from '@root/Components/Connections/IacConnector';

var account = '0';

export function ConnectionHealthModal(props: { type: string; accounts: Account[] }) {
    const di = useDiContainer();
    const iacConn = useMemo(() => di.resolve(IacConnector), []);
    const company = useCompany();
    const [disabled, setDisabled] = useState(true);
    const basicApi = useDi(BasicApi);

    const downloadPolicy = () => {
        {
            const params = {
                products: [
                    GetConnectionScriptsCsCloudformationYmlProductsItem.TagManager,
                    GetConnectionScriptsCsCloudformationYmlProductsItem.RightSizing,
                ],
                platform: GetConnectionScriptsCsCloudformationYmlPlatform.Aws,
                accountid: account,
            };
            switch (props.type) {
                case 'CloudFormation':
                    basicApi.download(
                        'cs-cloudformation.yml',
                        { url: '/connection/scripts/update-cs-cloudformation.yml', method: 'GET', params },
                        'Resources'
                    );
                    break;
                case 'Terraform':
                    basicApi.download('cs-terraform.tf', { url: '/connection/scripts/update-cs-terraform.tf', method: 'GET', params }, 'Resources');
                    break;
            }
        }
    };

    const accountSelected = (selectedValue: any) => {
        account = selectedValue;
        setDisabled(account == '0');
    };

    return (
        <Modal
            opened={true}
            size="lg"
            onClose={() => {
                closeModal('ConnectionHealthModal');
            }}
            title="Download IAM Permission Policy"
        >
            Select an account to generate a policy.
            <Select
                onChange={(value) => accountSelected(value)}
                placeholder="Select account"
                label="Select Account:"
                style={{ width: '100%', marginTop: '.3rem' }}
                data={props.accounts.map<SelectItem>((accounts) => {
                    return { value: `${accounts.AwsAccountId}`, label: `${accounts.Name}` };
                })}
            ></Select>
            <Space h={16} />
            <Group position="right">
                <Button
                    onClick={() => {
                        closeModal('ConnectionHealthModal');
                    }}
                    variant="outline"
                >
                    Cancel
                </Button>
                <Button disabled={disabled} onClick={downloadPolicy} leftIcon={<i className="ti ti-download" />}>
                    Download {props.type}
                </Button>
            </Group>
        </Modal>
    );
}

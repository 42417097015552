/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Recommendations.Host.Api
 * OpenAPI spec version: 1.0
 */

export type RecommendationType = typeof RecommendationType[keyof typeof RecommendationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecommendationType = {
  Size: 'Size',
  Scale: 'Scale',
  Schedule: 'Schedule',
} as const;

import styled from '@emotion/styled';
import { Button, Card, Divider, Space, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { Clearfix } from '@root/Design/Primitives';
import React, { useState } from 'react';

export function IdleResourcesActionCard({
    selectedResourceIds,
    selectedResourceState,
    warning,
    onStart,
    onStop,
    onTerminate,
    onCancel,
}: {
    selectedResourceIds: string[];
    selectedResourceState: 'running' | 'stopped' | 'terminated' | '';
    warning?: string;
    onStart?: () => void;
    onStop?: () => void;
    onTerminate?: () => void;
    onCancel?: () => void;
}) {
    const theme = useMantineTheme();
    const [selectedAction, setSelectedAction] = useState<string>();

    const fireSelectedAction = () => {
        switch (selectedAction) {
            case 'start':
                if (onStart) onStart();
                break;
            case 'stop':
                if (onStop) onStop();
                break;
            case 'terminate':
                if (onTerminate) onTerminate();
                break;
        }
    };

    const cancelAction = () => {
        setSelectedAction(undefined);
        if (onCancel) {
            onCancel();
        }
    };

    return (
        <>
            <div style={{ width: '25%', margin: '20px', marginTop: '50px', float: 'left' }}>
                <Card
                    sx={{
                        border: '1px solid',
                        borderRadius: '3%',
                        borderColor: theme.colors.gray[4],
                    }}
                >
                    <h3>Options</h3>
                    <Text>{selectedResourceIds!.length} resources selected</Text>
                    <Space h="md" />
                    <Divider />
                    <UnstyledButton
                        disabled={!onStart}
                        sx={{
                            backgroundColor:
                                (selectedAction === 'start' && selectedResourceState === 'running') || !onStart
                                    ? theme.colors.gray[3]
                                    : selectedAction === 'start' && selectedResourceState !== 'running'
                                    ? theme.colors.primary[2]
                                    : 'initial',
                            width: '100%',
                            height: '100%',
                            padding: '10px',
                            border: '2px solid',
                            borderColor: selectedAction === 'start' && selectedResourceState !== 'running' ? theme.colors.primary[4] : 'transparent',
                        }}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => setSelectedAction('start')}
                    >
                        <div
                            style={{
                                background: !onStart ? theme.colors.gray[4] : theme.colors.success[3],
                                height: '32px',
                                width: '32px',
                                borderRadius: '5px',
                                display: 'inline-block',
                                textAlign: 'center',
                            }}
                        >
                            <i className="ti ti-player-play-filled" style={{ fontSize: '24px', position: 'relative', top: '4px', color: '#fff' }}></i>
                        </div>

                        <Text
                            sx={{
                                color: !onStart
                                    ? theme.colors.gray[5]
                                    : selectedAction === 'start' && selectedResourceState !== 'running'
                                    ? theme.colors.primary[5]
                                    : 'initial',
                                display: 'inline-block',
                                marginLeft: '10px',
                            }}
                        >
                            {selectedResourceState === 'running' ? 'Start (Running)' : 'Start'}
                        </Text>
                    </UnstyledButton>
                    <Divider />
                    <UnstyledButton
                        disabled={!onStop}
                        sx={{
                            backgroundColor:
                                (selectedAction === 'stop' && selectedResourceState === 'stopped') || !onStop
                                    ? theme.colors.gray[3]
                                    : selectedAction === 'stop' && selectedResourceState !== 'stopped'
                                    ? theme.colors.primary[2]
                                    : 'initial',
                            width: '100%',
                            height: '100%',
                            padding: '10px',
                            border: '2px solid',
                            borderColor: selectedAction === 'stop' && selectedResourceState !== 'stopped' ? theme.colors.primary[4] : 'transparent',
                        }}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => setSelectedAction('stop')}
                    >
                        <div
                            style={{
                                background: !onStop ? theme.colors.gray[4] : theme.colors.warning[4],
                                height: '32px',
                                width: '32px',
                                borderRadius: '5px',
                                display: 'inline-block',
                                textAlign: 'center',
                            }}
                        >
                            <i className="ti ti-player-stop-filled" style={{ fontSize: '24px', position: 'relative', top: '4px', color: '#fff' }}></i>
                        </div>

                        <Text
                            sx={{
                                color: !onStop
                                    ? theme.colors.gray[5]
                                    : selectedAction === 'stop' && selectedResourceState !== 'stopped'
                                    ? theme.colors.primary[5]
                                    : 'initial',
                                display: 'inline-block',
                                marginLeft: '10px',
                            }}
                        >
                            {selectedResourceState === 'stopped' ? 'Stop (Stopped)' : 'Stop'}
                        </Text>
                    </UnstyledButton>
                    <Divider />
                    <Button
                        sx={{
                            margin: '10px',
                            float: 'left',
                            backgroundColor: theme.colors.error[6],
                            ':hover': {
                                backgroundColor: theme.colors.error[7],
                            },
                        }}
                        onClick={cancelAction}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{
                            margin: '10px',
                            float: 'right',
                        }}
                        onClick={fireSelectedAction}
                        disabled={!selectedAction}
                    >
                        Apply
                    </Button>
                    <Clearfix />
                </Card>
                {warning && (
                    <Card
                        sx={{
                            marginTop: '20px',
                            border: '1px solid',
                            borderRadius: '3%',
                            borderColor: theme.colors.gray[4],
                            backgroundColor: theme.colors.gray[1],
                        }}
                    >
                        <i className="ti ti-alert-circle" style={{ fontSize: '32px', width: '10%', float: 'left', top: '4px' }}></i>

                        <div style={{ float: 'right', width: '80%' }}>
                            {' '}
                            <h4 style={{ marginTop: '0px', marginBottom: '0px' }}>Caution</h4>
                            <Text style={{ marginTop: '0px', fontSize: '15px', color: theme.colors.gray[5] }}>{warning}</Text>
                        </div>
                    </Card>
                )}
            </div>
        </>
    );
}

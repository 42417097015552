import { useCompany } from '../../Router/CompanyContent';
import { Text, Collapse, Box, Divider } from '@mantine/core';
import {
    postResourceChangedQueryResourceChangeLog,
    postResourcesGetCompanyEventBridgeHistory,
    postResourcesQuery,
    QueryResult,
} from '@apis/Resources';
import { useCallback, useEffect, useState } from 'react';
import { queryBuilder } from '@root/Services/QueryExpr';
import { BaseAwsResource, BackupCommon, Elb, Keyspaces, ECSCommon, ResourceChange } from '@apis/Resources/model';
import { UserListItem } from '@apis/Customers/model';
import { getUserGetCompanyUsers } from '@apis/Customers';
import { useToggle } from '@root/Services/EventEmitter';
import styled from '@emotion/styled';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { ChevronDown } from 'tabler-icons-react';
import { ResourceDetailSection, ResourceHistoryRowEl, ResourceHistoryRowHeaderEl, ResourceHistoryToolbar } from '../Design';
import { useResourceChangeCount } from '../Common/OverviewCommon';

interface SessionIssuer {
    type?: string | null;
    userName?: string | null;
}
interface RequestParameters {
    repositoryNames?: string[] | null;
    name?: string | null;
    functionName?: string | null;
}
interface ResponseParameters {
    volumeId?: string[] | null;
    status?: string | null;
}
interface UserIdentity {
    userName?: string | null;
    type?: string | null;
    sessionContext?: SessionIssuer;
}

interface AWSAPICallviaCloudTrail {
    awsRegion?: string | null;
    eventName?: string | null;
    eventSource?: string | null;
    userIdentity?: UserIdentity;
    requestParameters?: RequestParameters;
    responseParameters?: ResponseParameters;
}
interface EC2InstanceStatechangeNotification {
    ['instance-id']?: string | null;
    state?: string | null;
}
interface EC2SpotInstanceRequestFulfillment {
    ['spot-instance-request-id']?: string | null;
    ['instance-id']?: string | null;
}
interface EBSSnapshotNotification {
    event?: string | null;
    result?: string | null;
    snapshot_id?: string | null;
    source?: string | null;
}
interface EBSMultiVolumeSnapshotsCompletionStatus {
    event?: string | null;
    result?: string | null;
    snapshot_id?: string | null;
    source?: string | null;
}
interface EBSVolumeNotification {
    result?: string | null;
    event?: string | null;
    cause?: string | null;
}
interface EMRClusterStateChange {
    name?: string | null;
    state?: string | null;
    stateChangeReason?: string | null;
    clusterId?: string | null;
}

interface EMRStepStatusChange {
    actionOnFailure?: string | null;
    name?: string | null;
    state?: string | null;
    message?: string | null;
    userIdentity?: UserIdentity;
}
interface RDSDBInstanceEvent {
    awsRegion?: string | null;
    eventName?: string | null;
    eventSource?: string | null;
}
interface EC2SpotInstaceInterruptionWarning {
    awsRegion?: string | null;
    eventName?: string | null;
    eventSource?: string | null;
}
interface RDSDBClusterSnapshotEvent {
    SourceType?: string | null;
    Message?: string | null;
    SourceIdentifier?: string | null;
}
interface ServiceTypeEvent {
    awsRegion?: string | null;
    eventName?: string | null;
    eventSource?: string | null;
}
interface TagsChange {
    service?: string | null;
    ['changed-tag-keys']?: string[] | null;
    ['detail-type']?: string[] | null;
}
interface RDSDBInstanceEvent extends ServiceTypeEvent {}
interface EMRStepStatusChange extends ServiceTypeEvent {}
interface EMRClusterStateChange extends ServiceTypeEvent {}
type DetailTypes =
    | { ['detail-type']: 'AWS API Call via CloudTrail'; detail: AWSAPICallviaCloudTrail }
    | { ['detail-type']: 'EC2 Instance State-change Notification'; detail: EC2InstanceStatechangeNotification }
    | { ['detail-type']: 'EC2 Spot Instance Request Fulfillment'; detail: EC2SpotInstanceRequestFulfillment }
    | { ['detail-type']: 'EC2 Spot Instance Interruption Warning'; detail: EC2SpotInstaceInterruptionWarning }
    | { ['detail-type']: 'EBS Multi-Volume Snapshots Completion Status'; detail: EBSMultiVolumeSnapshotsCompletionStatus }
    | { ['detail-type']: 'EBS Snapshot Notification'; detail: EBSSnapshotNotification }
    | { ['detail-type']: 'EBS Volume Notification'; detail: EBSVolumeNotification }
    | { ['detail-type']: 'EMR Cluster State Change'; detail: EMRClusterStateChange }
    | { ['detail-type']: 'EMR Step Status Change'; detail: EMRStepStatusChange }
    | { ['detail-type']: 'RDS DB Instance Event'; detail: RDSDBInstanceEvent }
    | { ['detail-type']: 'RDS DB Cluster Event'; detail: EMRClusterStateChange }
    | { ['detail-type']: 'RDS DB Cluster Snapshot Event'; detail: RDSDBClusterSnapshotEvent }
    | { ['detail-type']: 'Tag Change on Resource'; detail: TagsChange };
interface IResourceHistory {
    id: string;
    source: string;
    account: string;
    time: Date;
    region: string;
    ResourceIds: string[];
    CompanyId: number;
    AccountId: number;
    ResourceType: string;
}

type ResourceHistory = IResourceHistory & DetailTypes & ResourceChange;

function AWSAPICallviaCloudTrailEvent({ history }: { history: ResourceHistory & { ['detail-type']: 'AWS API Call via CloudTrail' } }) {
    return (
        <>
            <div>EventName : {history.detail.eventName}</div>
        </>
    );
}

function EMRClusterStateChangeEvent({ history }: { history: ResourceHistory & { ['detail-type']: 'EMR Cluster State Change' } }) {
    return (
        <>
            <div> ClusterId: {history.detail.clusterId}</div> <div> Name : {history.detail.name} </div>
        </>
    );
}
function EC2InstanceStatechangeNotificationEvent({
    history,
}: {
    history: ResourceHistory & { ['detail-type']: 'EC2 Instance State-change Notification' };
}) {
    return (
        <>
            <div> InstanceId: {history.detail['instance-id']}</div> <div> State : {history.detail.state} </div>
        </>
    );
}
function EBSSnapshotNotificationEvent({ history }: { history: ResourceHistory & { ['detail-type']: 'EBS Snapshot Notification' } }) {
    return (
        <>
            <div> Event: {history.detail.event}</div> <div> Result : {history.detail.result}</div>{' '}
            <div> SnapshotArn : {history.detail.snapshot_id}</div> <div> Source : {history.detail.source}</div>
        </>
    );
}
function EBSMultiVolumeSnapshotsCompletionStatusEvent({
    history,
}: {
    history: ResourceHistory & { ['detail-type']: 'EBS Multi-Volume Snapshots Completion Status' };
}) {
    return (
        <>
            <div> Event : {history.detail.event}</div> <div>Result : {history.detail.result}</div>{' '}
            <div>SnapShotId : {history.detail.snapshot_id}</div> <div>Source : {history.detail.source}</div>{' '}
        </>
    );
}
function EC2SpotInstanceRequestFulfillmentEvent({
    history,
}: {
    history: ResourceHistory & { ['detail-type']: 'EC2 Spot Instance Request Fulfillment' };
}) {
    return (
        <>
            <div> SpotInstanceRequestId: {history.detail['spot-instance-request-id']}</div>{' '}
            <div> SpotInstanceId : {history.detail['instance-id']}</div>{' '}
        </>
    );
}
function EBSVolumeNotificationEvent({ history }: { history: ResourceHistory & { ['detail-type']: 'EBS Volume Notification' } }) {
    return (
        <>
            <div>Event : {history.detail.event}</div>{' '}
        </>
    );
}
function RDSDBClusterSnapshotEventEvent({ history }: { history: ResourceHistory & { ['detail-type']: 'RDS DB Cluster Snapshot Event' } }) {
    return (
        <>
            <div>Message : {history.detail.Message}</div> <div> SourceIdentifier : {history.detail.SourceIdentifier} </div>{' '}
            <div> SourceType : {history.detail.SourceType} </div>
        </>
    );
}
function EMRStepStatusChangeEvent({ history }: { history: ResourceHistory & { ['detail-type']: 'EMR Step Status Change' } }) {
    return (
        <>
            <div> Name : {history.detail.name} </div>
            <div> State : {history.detail.state} </div>
            <div>Message : {history.detail.message}</div> <div>Name : {history.detail.name} </div>{' '}
        </>
    );
}

function TagsChangeEvent({ history }: { history: ResourceHistory & { ['detail-type']: 'Tag Change on Resource' } }) {
    var tags = history.detail['changed-tag-keys'];
    return (
        <>
            <div>Changed tag keys : {tags?.join(',')}</div>
        </>
    );
}

export function AwsResourceHistory(props: { resourceType: string; resourceId: string }) {
    const company = useCompany();
    const [api, setResults] = useState<ResourceHistory[]>([]);
    const [userLookup, setUserLookup] = useState(new Map<number, UserListItem>());
    const changeCt = useResourceChangeCount();
    const [loadedAt, setLoadedAt] = useState<Date>();
    useEffect(() => {
        getUserGetCompanyUsers()
            .then((users) => {
                setUserLookup(new Map(users.map((u) => [u.Id!, u])));
            })
            .catch();
    }, []);

    const loadHistory = useCallback(async () => {
        try {
            var resourceType = props.resourceType == 'ELBV1' || props.resourceType == 'ELBV2' ? 'ELBV' : props.resourceType;
            var resourceType = props.resourceType == 'ElasticSearch' ? 'OpenSearch' : resourceType;
            var resourceType = props.resourceType == 'Neptune Cluster' ? 'RDS Cluster' : resourceType;
            var resourceType = props.resourceType == 'DocumentDB Cluster' ? 'RDS Cluster' : resourceType;
            var resourceType = props.resourceType == 'Neptune Instance' ? 'RDS' : resourceType;
            var resourceType = props.resourceType == 'DocumentDB Instance' ? 'RDS' : resourceType;
            var resourceType = props.resourceType == 'Storage Gateway Volume' ? 'Storage Gateway' : resourceType;
            var resourceType = props.resourceType == 'Keyspaces Table' ? 'Keyspaces' : resourceType;

            var resourceId = props.resourceId;

            if (
                props.resourceType == 'Sagemaker' ||
                props.resourceType == 'RDS Cluster' ||
                props.resourceType == 'Neptune Cluster' ||
                props.resourceType == 'Neptune Instance' ||
                props.resourceType == 'DocumentDB Cluster' ||
                props.resourceType == 'DocumentDB Instance' ||
                props.resourceType == 'Keyspaces Table' ||
                props.resourceType == 'MSK' ||
                props.resourceType == 'Backup Plan' ||
                props.resourceType == 'Backup Framework' ||
                props.resourceType == 'Backup ReportPlan' ||
                props.resourceType == 'Backup RecoveryPoint' ||
                props.resourceType == 'ELBV2' ||
                props.resourceType == 'ECS Service' ||
                props.resourceType == 'ECS Task' ||
                props.resourceType == 'ECS'
            ) {
                var prequery = queryBuilder<BaseAwsResource>()
                    .where((r) => r.and(r.model.ResourceType!.eq(props.resourceType), r.model.Id!.eq(props.resourceId)))
                    .build();
                const result = await postResourcesQuery(prequery, { companyId: company?.Id });

                if (props.resourceType == 'Sagemaker') {
                    const sagemaker = result?.Results![0] as { DomainId: string };
                    resourceId = sagemaker.DomainId!;
                } else if (props.resourceType == 'RDS Cluster') {
                    const rdsCluster = result?.Results![0] as BaseAwsResource;
                    resourceId = (rdsCluster.DbClusterResourceId as string).toLowerCase();
                } else if (props.resourceType == 'Keyspaces Table') {
                    const keyspacesTable = result?.Results![0] as Keyspaces;
                    resourceId = keyspacesTable.AccountID! + '.' + keyspacesTable.Region! + '.' + keyspacesTable.Name;
                } else if (props.resourceType == 'Neptune Cluster' || props.resourceType == 'DocumentDB Cluster') {
                    const cluster = result?.Results![0] as BaseAwsResource;
                    resourceId = (cluster.DBClusterIdentifier as string).toLowerCase();
                } else if (props.resourceType == 'Neptune Instance' || props.resourceType == 'DocumentDB Instance') {
                    const instance = result?.Results![0] as BaseAwsResource;
                    resourceId = instance.AccountID! + '.' + instance.Name;
                } else if (props.resourceType == 'MSK') {
                    const res = result?.Results![0] as BaseAwsResource;
                    resourceId = res.AccountID! + '.' + res.Region! + '.' + res.ClusterName;
                } else if (props.resourceType == 'Backup Plan') {
                    const res = result?.Results![0] as BaseAwsResource;
                    resourceId = res.AccountID! + '.' + res.Region! + '.' + res.BackupPlanId;
                } else if (props.resourceType == 'Backup ReportPlan' || props.resourceType == 'Backup Framework') {
                    const res = result?.Results![0] as BackupCommon;
                    var id = res.ResourceArn?.split(':').pop();
                    resourceId = res.AccountID! + '.' + res.Region! + '.' + id;
                } else if (props.resourceType == 'Backup RecoveryPoint') {
                    const res = result?.Results![0] as BackupCommon;
                    resourceId = res.AccountID! + '.' + res.Region! + '.' + res.BackupVaultName! + '.' + res.ResourceArn;
                } else if (props.resourceType == 'ECS Service' || props.resourceType == 'ECS Task' || props.resourceType == 'ECS') {
                    const res = result?.Results![0] as ECSCommon;
                    resourceId = res.AccountID! + '.' + res.Region! + '.' + res.ResourceArn?.split('/').pop();
                } else {
                    const elb = result?.Results![0] as Elb;
                    var id = elb.LoadBalancerArn?.split('/').pop();
                    resourceId = elb.AccountID! + '.' + elb.Region! + '.' + id;
                }
            }
            var query = queryBuilder<ResourceHistory>()
                .take(1000)
                .where((b) => b.and(b.model.ResourceType.eq(resourceType), b.model.ResourceIds.eq([resourceId])))
                .sortDesc((b) => b.model.time)
                .build();

            const eventBridgeHistory = (await postResourcesGetCompanyEventBridgeHistory(query, {
                companyId: company?.Id,
            })) as QueryResult<ResourceHistory>;

            let resourceHistoryQuery = queryBuilder<ResourceChange & { ['ResourceId.Id']: string; ['ResourceId.ResourceType']: string }>()
                .take(1000)
                .where((b) =>
                    b.and(
                        b.model.Status!.eq('Succeeded'),
                        b.model.AccountId!.isNotNull(),
                        b.model['ResourceId.ResourceType'].eq(resourceType),
                        b.model['ResourceId.Id'].eq(resourceId)
                    )
                )
                .sortDesc((b) => b.model.TimeStamp)
                .build();

            const resourceHistory = (await postResourceChangedQueryResourceChangeLog(resourceHistoryQuery)) as QueryResult<ResourceHistory>;
            setResults(() =>
                [...(eventBridgeHistory.Results ?? []), ...(resourceHistory.Results ?? [])].sort((a, b) => {
                    const timeA = a.time || a.TimeStamp;
                    const timeB = b.time || b.TimeStamp;

                    if (timeA > timeB) return -1;
                    if (timeA < timeB) return 1;
                    return 0;
                })
            );
        } finally {
            setLoadedAt(new Date());
        }
    }, [props.resourceType, props.resourceId]);

    useEffect(() => {
        loadHistory();
    }, [changeCt]);
    return (
        <ResourceDetailSection shadow>
            <ResourceHistoryToolbar title="Change History" items={api.length} loadedAt={loadedAt} reload={loadHistory} />
            <div className="resourcesTableContainer">
                {api.length > 0 ? (
                    <>
                        {api.map((item, i) => (
                            <HistoryRow key={i} item={item} userLookup={userLookup} />
                        ))}
                    </>
                ) : (
                    <Text align="center" m="lg">
                        No historical changes found.
                    </Text>
                )}
            </div>
        </ResourceDetailSection>
    );
}

function HistoryRow({ item, userLookup }: { item: ResourceHistory; userLookup: Map<number, UserListItem> }) {
    const fmtSvc = useDi(FormatService);
    const [opened, { toggle }] = useToggle(false);
    const date = new Date(item.time || item.TimeStamp);
    const time = date ? fmtSvc.formatDatetimeNoSecs(date) : 'Unknown';
    const detailType = item.Description ? item.Description.split(':')[0] : item['detail-type'];

    return (
        <Box>
            <ResourceHistoryRowHeaderEl onClick={toggle} state={opened ? 'opened' : 'closed'}>
                <Text size="sm" pl="md">
                    {time}
                </Text>
                <Text size="sm">{detailType}</Text>
                <ChevronDown size={18} />
            </ResourceHistoryRowHeaderEl>
            <ResourceHistoryRowEl state={opened ? 'opened' : 'closed'}>
                <div></div>
                <Collapse in={opened}>
                    <Box py="xs">
                        <ResourceHistoryItemDisplay history={item} users={userLookup} />
                    </Box>
                </Collapse>
                <div></div>
            </ResourceHistoryRowEl>
            <Divider color="gray.3" />
        </Box>
    );
}

function UnknownItem({ history }: { history: ResourceHistory }) {
    return <>{JSON.stringify(history)}</>;
}

function ResourceHistoryItemDisplay({ history, users }: { history: ResourceHistory; users: Map<number, UserListItem> }) {
    const requestedBy = history.RequestedById ? users.get(history.RequestedById) : null;
    const requestedByName = requestedBy
        ? `${requestedBy.FirstName} ${requestedBy.LastName}`
        : typeof history.RequestedById === 'number'
        ? 'System'
        : 'Unknown';
    return (
        <>
            {history.time !== undefined ? (
                <>
                    Type: {history['detail-type']} <br />
                    Account Id: {history.account} <br />
                    Resource Type: {history.ResourceType} <br />
                    Region: {history.region} <br />
                    <ResourceHistoryItem history={history} /> <br />{' '}
                </>
            ) : (
                <>
                    {history.Description} <br />
                    Status: {history.Status} <br />
                    Automated: {history.Automated ? 'Yes' : 'No'} <br />
                    Inline: {history.Inline ? 'Yes' : 'No'} <br />
                    Requested By: {requestedByName} <br />
                </>
            )}
        </>
    );
}

function ResourceHistoryItem({ history }: { history: ResourceHistory }) {
    switch (history['detail-type']) {
        case 'AWS API Call via CloudTrail':
            return <AWSAPICallviaCloudTrailEvent history={history} />;
        case 'EC2 Instance State-change Notification':
            return <EC2InstanceStatechangeNotificationEvent history={history} />;
        case 'EMR Cluster State Change':
            return <EMRClusterStateChangeEvent history={history} />;
        case 'EBS Snapshot Notification':
            return <EBSSnapshotNotificationEvent history={history} />;
        case 'EBS Multi-Volume Snapshots Completion Status':
            return <EBSMultiVolumeSnapshotsCompletionStatusEvent history={history} />;
        case 'EC2 Spot Instance Request Fulfillment':
            return <EC2SpotInstanceRequestFulfillmentEvent history={history} />;
        case 'EBS Volume Notification':
            return <EBSVolumeNotificationEvent history={history} />;
        case 'RDS DB Cluster Snapshot Event':
            return <RDSDBClusterSnapshotEventEvent history={history} />;
        case 'Tag Change on Resource':
            return <TagsChangeEvent history={history} />;
        case 'EMR Step Status Change':
            return <EMRStepStatusChangeEvent history={history} />;
        default:
            return <UnknownItem history={history} />;
    }
}

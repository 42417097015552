import { Button, Space } from '@mantine/core';
import { FullPageModalHeader } from '@root/Components/Wizard/WizardForm';
import { PageBody } from '@root/Design/Layout';
import { ReactNode } from 'react';

export function TagsShell(props: { children: ReactNode }) {
    return <PageBody>{props.children}</PageBody>;
}

export function TagHeader() {
    return (
        <FullPageModalHeader>
            <div>
                <Button variant="outline">
                    <i className="ti ti-clock-hour-2"></i>
                    <Space></Space>
                    <span>Pending changes</span>
                    <Space></Space>
                    <span>(10)</span>
                </Button>
            </div>
        </FullPageModalHeader>
    );
}

/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Invoices.Host.Api
 * OpenAPI spec version: 1.0
 */

export type CostForecastRequestedJobStatus = typeof CostForecastRequestedJobStatus[keyof typeof CostForecastRequestedJobStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CostForecastRequestedJobStatus = {
  Created: 'Created',
  Started: 'Started',
  Failed: 'Failed',
  Succeeded: 'Succeeded',
  Canceled: 'Canceled',
} as const;

import { JobOfCostForecast } from '@apis/Resources';
import { Box, Card, Group, LoadingOverlay, Text, ThemeIcon, Tooltip, useMantineTheme } from '@mantine/core';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { useDi, useDiContainer } from '@root/Services/DI';
import { useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { CostForecastPersistence } from '@root/Services/Invoices/CostForecastService';
import { useNav } from '@root/Services/NavigationService';
import { useEffect, useMemo, useState } from 'react';
import { ChartArrowsVertical } from 'tabler-icons-react';
import { ActivityDetailsModel } from './ActivityDetailsService';
import { ActivityDetailsContainer, DetailRow, ProgressBadge, RowDivider } from './Design';
import { ActivityDetailsProps } from './Models';

export function CostForecastActivityDetails({ item: { job: item }, displayInfo }: ActivityDetailsProps) {
    const nav = useNav();
    const container = useDiContainer();
    const theme = useMantineTheme();
    const job = item as JobOfCostForecast;
    const formatSvc = useDi(FormatService);
    const forecastSvc = useDi(CostForecastPersistence);
    const [canAccess, setCanAccess] = useState<boolean>();
    const userId = useDi(AuthenticationService).user?.Id;
    const model = useMemo(() => {
        const result = container.resolve(ActivityDetailsModel);
        result.init(item);
        return result;
    }, []);
    useEffect(() => {
        (async () => {
            if (userId === item.RequestedById) {
                setCanAccess(true);
            } else {
                const forecasts = await forecastSvc.getMyCostForecasts();
                setCanAccess(!!forecasts.find((f) => f.savedForecast.forecastJobId === item.Id));
            }
        })();
    }, []);
    const loading = useEventValue(model.loading);
    const status = model.statusInfo;
    const durationLbl = loading
        ? ''
        : displayInfo.inProgress
        ? formatSvc.timeAgo(formatSvc.toLocalDate(item.QueuedAt))
        : formatSvc.timeElapsed(formatSvc.toLocalDate(model.progress?.lastDate), formatSvc.toLocalDate(item.StartedAt));
    const durationDescription = displayInfo.inProgress ? 'started' : 'after';
    const description = forecastSvc.createDescription(job);
    const defaultName = forecastSvc.createJobName(job);

    return loading ? (
        <LoadingOverlay visible style={{ color: theme.colors?.primary?.[6] }} />
    ) : (
        <ActivityDetailsContainer>
            <DetailRow title="Started">{formatSvc.toLocal(item.StartedAt)}</DetailRow>
            {status?.inProgress ? null : <DetailRow title="Finished">{formatSvc.toLocal(model.progress?.lastDate)}</DetailRow>}
            <RowDivider />
            <DetailRow title="Status">
                {displayInfo.inProgress ? 'Running' : displayInfo.hasErrors ? 'Failed' : 'Finished'}
                <>
                    {' '}
                    ({durationDescription} {durationLbl})
                </>
            </DetailRow>
            <RowDivider />
            <DetailRow title="Requested By">{model.getRequestedBy()}</DetailRow>
            <DetailRow alignTop title={description.accountLbls.length > 1 ? 'Accounts' : 'Account'}>
                {description.accountLbls.map((l) => (
                    <Text key={l}>{l}</Text>
                ))}
            </DetailRow>
            <DetailRow alignTop title="Filters">
                {description.filtersLbl}
            </DetailRow>
            <DetailRow alignTop title="Dimensions">
                {description.dimensionsLbl || 'No extra dimensions'}
            </DetailRow>
            <DetailRow alignTop title="Analysis Range">
                {description.trainingPeriodLbl}
            </DetailRow>
            {canAccess === undefined ? (
                <Card radius="lg" withBorder shadow="md" m="xl">
                    <Group>
                        <ThemeIcon variant="light" color="gray" size="xl" radius="xl">
                            <ChartArrowsVertical />
                        </ThemeIcon>
                        <Box sx={{ flex: 1 }}>
                            <Text color="dimmed" weight="bold">
                                Loading
                            </Text>
                        </Box>
                    </Group>
                </Card>
            ) : displayInfo.hasErrors ? null : (
                <Tooltip hidden={canAccess} position="bottom" label={`Forecast is only available to ${model.getRequestedBy()}`}>
                    <Card
                        component="a"
                        sx={{ cursor: canAccess ? 'pointer' : 'not-allowed' }}
                        onClick={() => (canAccess ? displayInfo.action?.execute(nav) : null)}
                        radius="lg"
                        withBorder
                        shadow="md"
                        m="xl"
                    >
                        <Group>
                            <ThemeIcon color={canAccess ? 'primary' : 'gray'} variant="light" size="xl" radius="xl">
                                <ChartArrowsVertical />
                            </ThemeIcon>
                            <Box sx={{ flex: 1 }}>
                                <Text color={canAccess ? 'primary' : 'gray'} weight="bold">
                                    View Forecast
                                </Text>
                            </Box>
                        </Group>
                    </Card>
                </Tooltip>
            )}
        </ActivityDetailsContainer>
    );
}

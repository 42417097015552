import { Recommendation, RecommendationOption } from '@apis/Recommendations/model';
import { Box, Card, Divider, Group, Space, Table, Text, useMantineTheme } from '@mantine/core';
import { IncreaseIndicator } from '@root/Design/Data';
import { CustomColors } from '@root/Design/Themes';
import { useMemo } from 'react';
import { AlertTriangle, Check, Forbid } from 'tabler-icons-react';
import { OptionPerformance } from './RecommendationObservability';
import { IComparsonConfig, IMetricInfo } from './Models';
import { DiagnosticMessage } from './Design';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';

export function RecommendationOptionComparison({
    option,
    recommendation,
    comparison,
}: {
    option: RecommendationOption;
    recommendation: Recommendation;
    comparison: IComparsonConfig;
}) {
    const theme = useMantineTheme();
    return (
        <Box py="lg" sx={{ background: '#fff' }}>
            <Text px="lg">Comparison</Text>
            <Card sx={{ background: theme.colors.gray[1] }} m="lg" radius="md" shadow="md">
                {recommendation.Type === 'Size' ? (
                    <SizeChangeDetails option={option} recommendation={recommendation} comparison={comparison} />
                ) : null}
            </Card>
            <Card sx={{ background: theme.colors.gray[1] }} m="lg" radius="md" shadow="md">
                <OptionIssues option={option} recommendation={recommendation} />
            </Card>
            <Card sx={{ background: theme.colors.gray[1] }} m="lg" radius="md" shadow="md">
                <OptionPerformance option={option} metrics={comparison.observabilityMetrics} recommendation={recommendation} />
            </Card>
        </Box>
    );
}

export function useMetricComparison(
    option: RecommendationOption | undefined,
    recommendation: Recommendation,
    metrics: IMetricInfo[],
    preferDecrease = false
) {
    return useMemo(
        () =>
            metrics.map((m) => ({
                label: m.label,
                before: m.value(recommendation.HistoricalUtilizationMetrics ?? {}),
                after: m.value(option?.ExpectedUtilizationMetrics ?? {}),
                fmt: m.format,
                preferDecrease: m.preferDecrease ?? preferDecrease,
            })),
        [option, recommendation, metrics]
    );
}

function SizeChangeDetails({
    option,
    recommendation,
    comparison,
}: {
    option: RecommendationOption;
    recommendation: Recommendation;
    comparison: IComparsonConfig;
}) {
    const currentSize = comparison.currentStateAccessor(recommendation);
    const selectedSize = option.ChangeDetails?.SizeChange?.RecommendedSize;
    const specs = useMetricComparison(option, recommendation, comparison.specMetrics, false);
    const utilization = useMetricComparison(option, recommendation, comparison.utilizationMetrics, true);

    return (
        <Table>
            <thead>
                <tr>
                    <th></th>
                    <th style={{ textAlign: 'right' }}>
                        <Text component="span" color="dimmed">
                            Current:{' '}
                        </Text>
                        {currentSize}
                    </th>
                    <th style={{ textAlign: 'right' }}>
                        <Text component="span" color="dimmed">
                            Selected:{' '}
                        </Text>
                        {selectedSize}
                    </th>
                </tr>
            </thead>
            <tbody>
                <ComparisonRows title="Specifications" data={specs} />
                <ComparisonRows title="Expected Utilization" data={utilization} />
            </tbody>
        </Table>
    );
}

function ComparisonRows({
    title,
    data,
}: {
    title: string;
    data: { label: string; before: number; after: number; fmt: (value: number) => string; preferDecrease: boolean }[];
}) {
    return (
        <>
            <tr>
                <td colSpan={3}>
                    <Text color="dimmed" size="xs" weight="bolder">
                        {title}
                    </Text>
                </td>
            </tr>
            {data.map((d, i) => {
                const beforeFmt = d.fmt(d.before);
                const afterFmt = d.fmt(d.after);
                return (
                    <tr key={i}>
                        <td>
                            <Text size="sm">{d.label}</Text>
                        </td>
                        <td>
                            <Text align="right" size="sm">
                                {beforeFmt}
                            </Text>
                        </td>
                        <td>
                            <Group spacing={0} position="right">
                                <Text size="sm">{afterFmt}</Text>
                                <IncreaseIndicator
                                    value={beforeFmt === afterFmt ? 0 : d.after - d.before}
                                    preferDecrease={d.preferDecrease}
                                    size="md"
                                />
                            </Group>
                        </td>
                    </tr>
                );
            })}
        </>
    );
}

function OptionIssues({ option, recommendation }: { option: RecommendationOption; recommendation: Recommendation }) {
    const fmtSvc = useDi(FormatService);
    return (
        <>
            <Text>Compatibility Issues</Text>
            <Box p="xs">
                {!option.IncompatibleReasons?.length ? <DiagnosticMessage message="No known compatibility issues" type="clear" /> : null}
                {option.IncompatibleReasons?.map((r, i) => (
                    <DiagnosticMessage key={i} message={r.Reason!} type="incompatibility" />
                ))}
            </Box>
            <Space h="xs" />
            <Text>Risks</Text>
            <Box p="xs">
                {!option.Risks?.length ? <DiagnosticMessage message="No known risks detected" type="clear" /> : null}
                {option.Risks?.map((r, i) => (
                    <DiagnosticMessage key={i} message={`${fmtSvc.formatPercent(r.RiskRating ?? 0)} ${r.Metric} - ${r.Description}`} type="risk" />
                ))}
            </Box>
            {!recommendation.EligibiltyWarnings?.length ? null : (
                <>
                    <Space h="xs" />
                    <Text>Warnings</Text>
                    <Box p="xs">
                        {recommendation.EligibiltyWarnings?.map((r, i) => (
                            <DiagnosticMessage key={i} message={r} type="risk" />
                        ))}
                    </Box>
                </>
            )}
        </>
    );
}

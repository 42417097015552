import styled from '@emotion/styled';
import { useMantineTheme, Card, Box, Text, Title } from '@mantine/core';

export function EditorCard({ children }: { children: React.ReactNode }) {
    const theme = useMantineTheme();
    return (
        <Card sx={{ background: theme.colors.gray[2] }} p="lg" radius="md">
            {children}
        </Card>
    );
}

export const CardContentLayout = styled.div`
    display: grid;
    column-gap: 1.5rem;
    grid-template-columns: auto 150px;
    align-items: stretch;
`;

export function EditorCardTitle({ title, description }: { title: string; description?: string }) {
    return (
        <Box>
            <Title order={4}>{title}</Title>
            <Text pb={'xs'} color="dimmed" size="sm">
                {description}
            </Text>
        </Box>
    );
}

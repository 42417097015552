import { getUserGetMyCompanies, postCustomerFeedbackSendLead } from '@apis/Customers';
import { AwsMarketplaceSubscription } from '@apis/Customers/model';
import { Anchor, Box, Button, Card, Center, Grid, Group, Loader, Space, Stack, Text, TextInput, Title, useMantineTheme } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { useFullScreenCloudBg } from '@root/Design/Layout';
import { AnchorButton } from '@root/Design/Primitives';
import { CustomColors } from '@root/Design/Themes';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { useDi } from '@root/Services/DI';
import { MspService } from '@root/Services/MspService';
import { useCallback, useEffect, useState } from 'react';
import { ExternalLink } from 'tabler-icons-react';

export function CollectSalesInfo({ subscription }: { subscription: AwsMarketplaceSubscription }) {
    useFullScreenCloudBg();
    const theme = useMantineTheme();
    const mspSvc = useDi(MspService);
    const auth = useDi(AuthenticationService);
    const [firstName, setFirstName] = useInputState(auth.user?.FirstName ?? '');
    const [lastName, setLastName] = useInputState(auth.user?.LastName ?? '');
    const [companyName, setCompanyName] = useInputState('');
    const [phoneNumber, setPhoneNumber] = useInputState('');
    const [sent, setSent] = useState(false);
    const [sending, setSending] = useState(false);
    const [loading, setLoading] = useState(true);
    const [redirecting, setRedirecting] = useState(false);
    const [redirectPath, setRedirectPath] = useState<string>();

    const send = useCallback(async () => {
        try {
            setSending(true);
            await postCustomerFeedbackSendLead({
                Subject: `Interest in Offer for ${subscription.SubscriptionType?.FriendlyName} (follow up)`,
                Message: `User interested in an offer for ${subscription.SubscriptionType?.FriendlyName} would like a prompt follow up and has provided additional contact details. Please follow up right away to discuss. `,
                CompanyName: companyName,
                FirstName: firstName,
                LastName: lastName,
                PhoneNumber: phoneNumber,
            });
        } finally {
            setSent(true);
            setSending(false);
        }
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const freeTrial = subscription.IsFreeTrial;
                postCustomerFeedbackSendLead({
                    Subject: `Interest in Offer for ${subscription.SubscriptionType?.FriendlyName}`,
                    Message: `User expressed interested in ${subscription.SubscriptionType?.FriendlyName} ${
                        freeTrial ? 'free trial' : 'via public offer'
                    }. Please follow up right away to discuss. `,
                });
                const myCompanies = await getUserGetMyCompanies();
                const defaultCompany = myCompanies.length ? myCompanies[0] : null;
                if (defaultCompany) {
                    setRedirectPath(`/${defaultCompany.DatabaseName}`);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return (
        <Center py="xl">
            <Box sx={{ width: 500 }}>
                <Card color={theme.white} shadow="xl" radius="lg" p={32}>
                    <Center p="xl">
                        <img src={mspSvc.defaultSupportAndLogos.Logo} style={{ width: 150 }} alt={mspSvc.defaultSupportAndLogos.CompanyName} />
                    </Center>
                    {loading || redirecting ? (
                        <Center sx={{ height: 200 }}>
                            <Loader />
                        </Center>
                    ) : (
                        <>
                            <Title order={3} align="center">
                                Thank You!
                            </Title>
                            <Box p="xl">
                                <Text>
                                    {subscription.IsFreeTrial ? (
                                        <>
                                            You have requested a free trial for <strong>{subscription.SubscriptionType?.FriendlyName}</strong>.
                                        </>
                                    ) : (
                                        <>
                                            <strong>{subscription.SubscriptionType?.FriendlyName}</strong> is currently only available through private
                                            offers.
                                        </>
                                    )}
                                </Text>
                                <Space h="xl" />
                                <Text size="sm">
                                    We're excited to share the product with you! For the best experience, we're going to have someone from our team
                                    reach out to set up a subscription and work through the connection process.
                                </Text>
                            </Box>
                            <Card p="xl" radius="sm" sx={{ background: theme.colors.gray[2] }}>
                                {sent ? (
                                    <Text align="center" color="dimmed" italic>
                                        Sent. We'll be in touch soon!
                                    </Text>
                                ) : (
                                    <Stack>
                                        <TextInput label="Company Name" value={companyName} onChange={setCompanyName} />
                                        <TextInput label="First Name" value={firstName} onChange={setFirstName} />
                                        <TextInput label="Last Name" value={lastName} onChange={setLastName} />
                                        <TextInput label="Phone" type="tel" width={180} value={phoneNumber} onChange={setPhoneNumber} />
                                    </Stack>
                                )}
                            </Card>
                            <Space h="xl" />
                            <Group position="apart">
                                <AnchorButton
                                    text="Learn more about CloudSaver solutions"
                                    external
                                    icon={<ExternalLink />}
                                    href="https://www.cloudsaver.com"
                                    size="sm"
                                />
                                {sent ? null : (
                                    <Button leftIcon={sending ? <Loader size="sm" color={theme.white as CustomColors} /> : undefined} onClick={send}>
                                        {sending ? 'Sending' : 'Send'}
                                    </Button>
                                )}
                            </Group>
                            {!redirectPath ? null : (
                                <>
                                    <Space h="xl" />
                                    <Group position="center">
                                        <Anchor href={redirectPath}>Return Home</Anchor>
                                    </Group>
                                </>
                            )}
                        </>
                    )}
                </Card>
            </Box>
        </Center>
    );
}

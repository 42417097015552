import {
    getCspMktGetAwsSubscription,
    getCspMktGetAwsSubscriptionById,
    getSubscriptionGetCreditRates,
    getSubscriptionGetSubscriptionOptions,
    postCspMktActivateAwsPayment,
} from '@apis/Customers';
import { AwsMarketplaceSubscription, CreditRates, Plans } from '@apis/Customers/model';
import { Box, Button, Card, Center, Divider, Loader, Space, Text, Title } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { PageContent } from '@root/Design/Layout';
import { AwsOnboardingService } from '@root/Services/Customers/AwsOnboarding';
import { CompanyContextService } from '@root/Services/Customers/CompanyContext';
import { useDi } from '@root/Services/DI';
import { Logger } from '@root/Services/Logger';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useCallback, useEffect, useState } from 'react';
import { SubscriptionPriceDetails, useSubscriptionDetailsVisible } from './Components/SubscriptionPriceDetails';

export function AwsConfirmPaymentMethod() {
    const { getData, goto, getRootUrl, getAscendUrl } = useNav();
    const { id: rawId } = getData('id');
    const id = rawId ? parseInt(rawId) : '';
    const logger = useDi(Logger);
    const awsOnboardingSvc = useDi(AwsOnboardingService);
    const company = useCompany();
    const companyContext = useDi(CompanyContextService);
    const [subscription, setSubscription] = useState<AwsMarketplaceSubscription>();
    const [subscriptionInfo, setSubscriptionInfo] = useState<{ plan: Plans; creditRate: CreditRates }>();

    const fail = useCallback(() => {
        showError(() => goto(getRootUrl('support/support-home')));
    }, [goto, getRootUrl]);

    const activate = useCallback(async () => {
        try {
            await postCspMktActivateAwsPayment({ subscriptionId: subscription?.Id });
            const redirectToMsp = getMspCompanyPath();
            awsOnboardingSvc.removeCookie();
            goto(redirectToMsp);
        } catch (err) {
            logger.error('Error activating subscription', err);
            fail();
        }
    }, [subscription]);

    const getMspCompanyPath = () => {
        if (companyContext.parentCompany?.Id && companyContext.parentCompany.Id !== company?.Id) {
            return getRootUrl(`msp/my-companies@select:${company?.Id},tab:payment_methods`);
        }
        return getAscendUrl();
    };

    useEffect(() => {
        if (id) {
            (async () => {
                try {
                    const subscription = await getCspMktGetAwsSubscriptionById({ subscriptionId: id });
                    setSubscription(subscription);

                    const creditPackageId = subscription.CreditPackageId ?? 0;
                    const subscriptionTypeId = subscription.SubscriptionType?.ExternalId ?? '';
                    const [subscriptionOptions, creditRate] = await Promise.all([
                        getSubscriptionGetSubscriptionOptions({ applicationType: subscription.SubscriptionType?.Application ?? undefined }),
                        getSubscriptionGetCreditRates({ creditPackageId }),
                    ]);
                    const plan = [...(subscriptionOptions.MonthlyPlans ?? []), ...(subscriptionOptions.YearlyPlans ?? [])].find(
                        (o) => o.ExternalId === subscriptionTypeId
                    );
                    if (!plan) {
                        throw new Error('No subscription option found for ID ' + subscriptionTypeId);
                    }

                    setSubscriptionInfo({ creditRate, plan });
                } catch (err) {
                    logger.error('Error getting subscription options and credit rates', err);
                    fail();
                }
            })();
        }
    }, []);
    const subscriptionPricingVisible = useSubscriptionDetailsVisible(subscription?.SubscriptionType?.Application ?? '');

    return (
        <PageContent>
            <Box sx={{ width: 600 }} m="xl">
                <Card withBorder shadow="md" radius="lg" p={0}>
                    {!subscription || !subscriptionInfo ? (
                        <Center p="xl">
                            <Loader />
                        </Center>
                    ) : (
                        <>
                            <Box p="xl">
                                <Title order={2}>Aws SaaS Confirmation</Title>
                                <Space h="md" />
                                <Text>Would you like to activate AWS Account {subscription.AwsAccountId} as a payment method?</Text>
                            </Box>
                            <Divider />
                            <Box p="md">
                                <Center p="md">
                                    {' '}
                                    <Button onClick={activate} sx={{ width: 300 }}>
                                        Activate
                                    </Button>
                                </Center>
                            </Box>
                            {subscriptionPricingVisible ? (
                                <>
                                    <Divider />
                                    <Box px="xl">
                                        <Space h="md" />
                                        <Title order={4}>Subscription Details</Title>
                                        <SubscriptionPriceDetails
                                            plan={subscriptionInfo.plan}
                                            creditRate={subscriptionInfo.creditRate}
                                            subscriptionType={subscription.SubscriptionType!}
                                        />
                                    </Box>
                                </>
                            ) : null}
                        </>
                    )}
                </Card>
            </Box>
        </PageContent>
    );
}
endpoint('aws-confirm-payment-method', AwsConfirmPaymentMethod, 'Confirm Aws Subscription');

function showError(confirm: () => void) {
    openConfirmModal({
        children: <Text>There seems to be a problem with the Aws SaaS offer. Please contact support</Text>,
        labels: {
            confirm: 'Contact Support',
            cancel: null,
        },
        cancelProps: {
            hidden: true,
        },
        withCloseButton: false,
        closeOnClickOutside: false,
        closeOnConfirm: true,
        closeOnEscape: false,
        onCancel: undefined,
        onConfirm: confirm,
    });
}

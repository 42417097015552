import { Company, IQueryExpr } from '@apis/Customers/model';
import { getInvoiceRuleGetProcessingReport, postDailyRollupMultiQuery, postForecastMultiQuery, postMonthlyRollupMultiQuery } from '@apis/Invoices';
import { ObjectQueryResult, PostDailyRollupQueryParams, PostMonthlyRollupQueryParams, Query } from '@apis/Invoices/model';
import { QueryConstant, QueryResult } from '@apis/Resources';
import { inject, injectable } from 'tsyringe';
import { AsyncBundler } from '../AsyncBundler';
import { ICompanyContextToken } from '../Customers/CompanyContext';
import { FormatService } from '../FormatService';
import { cleanExprDates, cleanQueryDates, normalizeAndTraverseQuery, traverseExpr, traverseQueryExprsDepthFirst } from '../QueryExpr';
import { InvoiceApiCache } from './InvoiceApiCache';

@injectable()
export class InvoiceApiService {
    private asyncBundler = new AsyncBundler();

    public constructor(
        @inject(InvoiceApiCache) private readonly cache: InvoiceApiCache,
        @inject(FormatService) private readonly fmtSvc: FormatService,
        @inject(ICompanyContextToken) private readonly company: Company
    ) {}

    public getDateRange() {
        return this.cache.getDateRange(this.company.Id);
    }

    public async getProcessingReport(month: Date) {
        return await getInvoiceRuleGetProcessingReport({ month: this.fmtSvc.toJsonShortDate(month) });
    }

    public queryByUsageMonth(query: Query, usageDate: Date) {
        const month = this.fmtSvc.formatYearMonth(usageDate);
        const params = { from: month, to: month } as PostDailyRollupQueryParams;
        const dateCriteria: IQueryExpr[] = [{ Operation: 'eq', Operands: [{ Field: 'UsageMonth' }, { Value: month }] }];
        const where = { Operation: 'and', Operands: dateCriteria };
        if (query.Where) {
            where.Operands.push(query.Where);
        }
        query.Where = where;
        const key = `queryByUsageMonth-${month}`;
        return this.cleanAndBundle(key, query, (queries: Query[]) => postDailyRollupMultiQuery(queries, params));
    }

    public query<T = unknown>(query: Query, { from, to }: { from?: Date; to?: Date }, appendDateCriteria: boolean = true): Promise<QueryResult<T>> {
        const params = {} as PostDailyRollupQueryParams;
        const dateCriteria: IQueryExpr[] = [];
        if (from) {
            params.from = this.fmtSvc.toJsonShortDate(from);
        }
        if (to) {
            params.to = this.fmtSvc.toJsonShortDate(to);
        }
        if (appendDateCriteria) {
            if (params.from) {
                dateCriteria.push({ Operation: 'gte', Operands: [{ Field: 'UsageStartDate' }, { Value: params.from }] });
            }
            if (params.to) {
                dateCriteria.push({ Operation: 'lte', Operands: [{ Field: 'UsageStartDate' }, { Value: params.to }] });
            }
        }
        if (dateCriteria.length) {
            const where = { Operation: 'and', Operands: dateCriteria };
            if (query.Where) {
                where.Operands.push(query.Where);
            }
            query.Where = where;
        }

        const key = `${params.from}-${params.to}`;
        return this.cleanAndBundle(key, query, (queries: Query[]) => postDailyRollupMultiQuery(queries, params)) as Promise<QueryResult<T>>;
    }

    public queryMonthlyRollup(query: Query, months: Date[]) {
        const params = {
            months: months.map((m) => this.fmtSvc.toJsonShortDate(m)),
        } as PostMonthlyRollupQueryParams;
        const key = JSON.stringify(params);
        return this.cleanAndBundle(key, query, (queries: Query[]) => postMonthlyRollupMultiQuery(queries, params));
    }

    public queryForecastData(query: Query, jobId?: string) {
        const where: IQueryExpr[] = !jobId ? [] : [{ Operation: 'eq', Operands: [{ Field: 'JobId' }, { Value: jobId }] }];
        if (query.Where) {
            where.push(query.Where);
        }
        query.Where = !where.length ? undefined : where.length === 1 ? where[0] : { Operation: 'and', Operands: where };
        const key = `forecast-${jobId}`;
        return this.cleanAndBundle(key, query, (queries: Query[]) => postForecastMultiQuery(queries));
    }

    private async cleanAndBundle(key: string, query: Query, handler: (queries: Query[]) => Promise<ObjectQueryResult[]>) {
        return this.asyncBundler.bundle(key, query, (queries: Query[]) => handler(queries));
    }

    private cleanQueryDates(queries: Query[], handler: (queries: Query[]) => Promise<ObjectQueryResult[]>): Promise<ObjectQueryResult[]> {
        const dateFields = new Set(['UsageStartDate', 'BilledDate', 'UsageEndDate', 'UsageMonth']);
        const operations = new Set(['eq', 'ne', 'gte', 'lte', 'gt', 'lt', 'truncdate']);
        const castAndTruncate = (expr?: QueryConstant, field?: string) => {
            if (field && dateFields.has(field) && expr?.Value && !(expr.Value instanceof Array)) {
                const value =
                    typeof expr.Value === 'string'
                        ? expr.Value
                        : expr.Value instanceof Date
                        ? this.fmtSvc.toUtcJsonShortDate(expr.Value)
                        : typeof expr.Value === 'number'
                        ? this.fmtSvc.toUtcJsonShortDate(new Date(expr.Value))
                        : expr.Value.toString();
                const firstOfMo = field === 'UsageMonth';
                const truncated = firstOfMo ? value.substring(0, 7) + '-01' : value.substring(0, 10);

                delete expr.Value;
                expr.__cast = 'string';
                expr.Value = truncated;
            }
        };

        const updatedQueries = queries.map((q) =>
            normalizeAndTraverseQuery(q, (expr, parents) => {
                if ('Value' in expr) {
                    const lastParent = parents[parents.length - 1];
                    if (lastParent && 'Operation' in lastParent) {
                        const opLc = lastParent.Operation.toLowerCase();
                        if (operations.has(opLc)) {
                            const valueIdx = lastParent.Operands.indexOf(expr);
                            if (opLc === 'truncdate') {
                                if (valueIdx === 3 || valueIdx === 4) {
                                    castAndTruncate(expr, lastParent.Operands[1]?.Field);
                                }
                                if (valueIdx === 2 && expr.Value !== 0) {
                                    expr.Value = 0;
                                }
                            } else {
                                if (valueIdx === 1) {
                                    castAndTruncate(expr, lastParent.Operands[0]?.Field);
                                }
                            }
                        }
                    }
                }
            })
        );
        return handler(updatedQueries);
    }
}

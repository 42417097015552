import { AppFeaturesSystemName } from '@apis/Customers/model';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { AppFeatureNames } from '@root/Services/Customers/CompanyFeatureService';
import { UserFeatureAccessService } from '@root/Services/Customers/UserFeatureAccessService';
import { useDi } from '@root/Services/DI';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useEffect } from 'react';

const apps: [AppFeaturesSystemName, string | undefined, string][] = [
    ['Compliance', AppFeatureNames.TagManager, 'tag-manager/tag-dashboard'],
    ['Optimization', AppFeatureNames.IdleResources, 'idle-resources/idle-customizable-dashboard'],
    ['Optimization', AppFeatureNames.Rightsizing, 'optimization-tools/rightsizing-ec2'],
    ['FinOps', AppFeatureNames.InvoiceManager, 'invoice-manager/invoice-explorer'],
    ['Assessments', undefined, 'assessments/summary'],
    ['Compliance', AppFeatureNames.MAPManager, 'map-manager/map-contracts'],
];

export function AppRedirect() {
    const userFeatureAccess = useDi(UserFeatureAccessService);

    const { getRootUrl, goto } = useNav();
    const company = useCompany();
    useEffect(() => {
        let disposed = false;
        if (!company) return;
        userFeatureAccess.getAccessLookup(company).then((accessLookup) => {
            if (disposed) return;
            const [, , appRedirectUrl] = apps.find(([app, feature]) => accessLookup.checkAccessByName(feature ?? app)) ?? [];
            const redirectUrl = appRedirectUrl ?? 'settings/settings-overview';
            goto(getRootUrl(redirectUrl));
        });
        return () => {
            disposed = true;
        };
    }, [company]);
    return <></>;
}
endpoint('app-redirect', AppRedirect, 'App Redirect');
endpoint('dashboard', AppRedirect, 'App Redirect');

import { Box, Button, Card, Group, Menu, Popover, Space, Text, Title } from '@mantine/core';
import { observer } from 'mobx-react';
import { UseFormReturnType } from '@mantine/form';
import { postResourcesQuery, QueryExpr, QueryOperation } from '@apis/Resources';
import { DataFilters, AddFilterButton, DataFilterModel } from '@root/Components/Filter/Filters';
import { FieldPicker } from '@root/Components/Picker/FieldPicker';
import { exprBuilder, SchemaService, SchemaValueProvider } from '@root/Services/QueryExpr';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { ResourceSchemaProvider } from '@root/Services/Resources/ResourceService';
import { useDi } from '@root/Services/DI';
import { SchemaFieldNameProvider } from '@root/Components/Filter/Services';
import { Calendar, Plus } from 'tabler-icons-react';
import { RuleEditCard } from './Design';
import { TagAutomationRule } from '@apis/TagManager/model';
import { RuleEditor, TagLookupService } from './Model';
import { useDisclosure } from '@mantine/hooks';

export type ValidationIssues = { total: number } & Partial<Record<'Missing Field' | 'Missing Value' | 'Missing Child Type', number>>;

export const FilterResources = observer(function FilterResources({
    filter,
    onChange,
    onValidationIssuesChanged,
}: {
    filter: QueryExpr[];
    onChange: (filter: QueryExpr[]) => void;
    onValidationIssuesChanged: (issues: ValidationIssues) => void;
}) {
    const company = useCompany();
    const tagLookupSvc = useDi(TagLookupService);
    const schema = tagLookupSvc.schemaSvc;
    const [validationIssues, setValidationIssues] = useState<ValidationIssues>({ total: 0 });
    const schemaValueProvider = useMemo(
        () => new SchemaValueProvider(schema, (query) => postResourcesQuery(query, { companyId: company?.Id })),
        [schema, company?.Id]
    );
    const fieldInfoProvider = useMemo(() => new SchemaFieldNameProvider(schema), [schema]);
    const [filterModel, setFilterModel] = useState<DataFilterModel>();
    const [showingErrors, { open: showErrors }] = useDisclosure(false);
    const onFilterChange = useCallback(
        (filter: QueryExpr[]) => {
            onChange(filter);
            if (filterModel) {
                const validationIssues = filterModel.getValidationIssues();
                setValidationIssues(
                    validationIssues.reduce(
                        (result, { issue }) => {
                            result[issue] = result && result[issue] ? (result[issue] ?? 0) + 1 : 1;
                            result.total++;
                            return result;
                        },
                        { total: 0 } as ValidationIssues
                    )
                );
            }
        },
        [onChange, filterModel]
    );
    useEffect(() => {
        onValidationIssuesChanged(validationIssues);
    }, [onValidationIssuesChanged, validationIssues, validationIssues['Missing Field'], validationIssues['Missing Value']]);
    const existingResources = useCallback(
        () => filterModel?.addFilters(exprBuilder<any>().createExpr((b) => b.model.CreatedTime.lte(new Date())) as QueryOperation),
        [filterModel]
    );
    const newResources = useCallback(
        () => filterModel?.addFilters(exprBuilder<any>().createExpr((b) => b.model.CreatedTime.gte(new Date())) as QueryOperation),
        [filterModel]
    );

    return (
        <>
            {!!fieldInfoProvider && !!schema && !!schemaValueProvider && (
                <>
                    <DataFilters
                        dataFiltersAsLineItem
                        valueProvider={schemaValueProvider}
                        fieldInfoProvider={fieldInfoProvider}
                        filters={filter}
                        onModelLoaded={setFilterModel}
                        onChange={onFilterChange}
                        showErrors={showingErrors}
                        renderFieldPicker={(select) => (
                            <Box>
                                <FieldPicker mode="single" selections={[]} schema={schema!} onChange={([f]) => select(f.path)} />
                            </Box>
                        )}
                    />
                    <Group>
                        <Button radius="xl" size="xs" color="primary" onClick={() => filterModel?.addEmptyFilter(true, false)} leftIcon={<Plus />}>
                            Add Filter
                        </Button>
                        <Menu withinPortal position="bottom-start" withArrow shadow="sm" offset={0}>
                            <Menu.Target>
                                <Button radius="xl" size="xs" variant="outline" leftIcon={<Plus />}>
                                    Recommended Filters
                                </Button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Item onClick={existingResources} icon={<Calendar size={20} />}>
                                    Existing Resources<Text color="dimmed">Only affect resources created before today</Text>
                                </Menu.Item>
                                <Menu.Item onClick={newResources} icon={<Calendar size={20} />}>
                                    New Resources<Text color="dimmed">Only affect resources created after today</Text>
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    </Group>
                </>
            )}
        </>
    );
});

export function RuleResourceFilter({
    ruleEditor,
    onValidationIssuesChanged,
}: {
    ruleEditor: RuleEditor;
    onValidationIssuesChanged: (issues: ValidationIssues) => void;
}) {
    let rule: TagAutomationRule = ruleEditor.rule!;
    const normalizeFilter = (rule: TagAutomationRule) => {
        let result: QueryExpr[];
        rule.Parameters ??= {};
        rule.Parameters.Filter ??= { Operation: 'and', Operands: (result = []) };
        if (!('Operation' in rule.Parameters.Filter)) {
            rule.Parameters.Filter = { Operation: 'and', Operands: (result = []) };
        } else {
            result = rule.Parameters.Filter.Operands ??= [];
        }
        return result;
    };
    const filter = useMemo(() => normalizeFilter(rule), [rule, rule.Parameters?.Filter]);
    const onChange = useCallback((nextFilter: QueryExpr[]) => {
        filter.splice(0, Infinity, ...nextFilter);
        (async () => {
            await ruleEditor.refreshPreviewCount();
        })();
    }, []);
    return <FilterResources filter={filter} onChange={onChange} onValidationIssuesChanged={onValidationIssuesChanged} />;
}

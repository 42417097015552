import { Portal } from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { BasicRouteLoader, IRouteMeta } from '@root/Services/Router/BasicRouteLoader';
import { ReactNode, useEffect, useLayoutEffect, useMemo } from 'react';
import { inject, singleton } from 'tsyringe';

@singleton()
class RouteBoundPortalProvider {
    private readonly targetHosts = (() => {
        const hosts = new Map<string, HTMLElement>();
        const hostKey = (route: string) => route.toLowerCase();
        return {
            get: (key: string) => hosts.get(hostKey(key)),
            set: (key: string, value: HTMLElement) => hosts.set(hostKey(key), value),
            delete: (key: string) => hosts.delete(hostKey(key)),
        };
    })();

    public constructor(@inject(BasicRouteLoader) private readonly router: BasicRouteLoader) {
        router.routeMeta.listen(this.handleRouteChange);
        this.init();
    }

    private init() {
        const initializer = (disposer?: () => void) => {
            this.handleRouteChange(this.router.routeMeta.value, []);
            disposer?.();
        };
        if (this.router.ready.value) {
            initializer();
        } else {
            const { dispose } = this.router.ready.listen(() => initializer(dispose));
        }
    }

    public getTarget = (route?: string) => {
        route ??= this.getCurrentRoute();
        const targetHost = this.requireTargetHost(route);
        const target = document.createElement('div');
        targetHost.appendChild(target);
        return { target, dispose: () => target.remove() };
    };

    public getCurrentRoute() {
        return this.router.getTopRouteMeta().currentPathWithoutParams;
    }

    private requireTargetHost(route: string) {
        let targetHost = this.targetHosts.get(route);
        if (!targetHost) {
            targetHost = document.createElement('div');
            targetHost.setAttribute('data-route-bound-portal', route);
            document.body.appendChild(targetHost);
            this.targetHosts.set(route, targetHost);
        }
        return targetHost;
    }

    private handleRouteChange = (routeMeta: IRouteMeta[], prev: IRouteMeta[]) => {
        const currentRoute = this.getCurrentRoute();
        const removedRoutes = new Set(prev.map((r) => r.currentPathWithoutParams));

        for (const { currentPathWithoutParams: validRoute } of routeMeta) {
            this.requireTargetHost(validRoute);
            removedRoutes.delete(validRoute);
            this.toggleTarget(validRoute, validRoute === currentRoute);
        }

        this.disposeTargets(removedRoutes);
    };

    private disposeTargets(routes: Iterable<string>) {
        for (const route of routes) {
            const target = this.targetHosts.get(route);
            if (target) {
                target.remove();
                this.targetHosts.delete(route);
            }
        }
    }

    private toggleTarget(route: string, visible: boolean) {
        const target = this.targetHosts.get(route);
        if (target) {
            target.style.display = visible ? 'contents' : 'none';
        }
    }
}

/**
 * Hook to provide a target element for drawers or modals which should only be visible when
 * the page that opened them is at the top of the route stack
 */
export function useRouteBoundPortal() {
    const provider = useDi(RouteBoundPortalProvider);
    const currentRoute = useMemo(() => provider.getCurrentRoute(), []);
    const { target, dispose } = useMemo(() => provider.getTarget(currentRoute), []);
    useEffect(() => {
        return () => {
            dispose();
        };
    }, [dispose]);

    return target;
}

/**
 * For portaled content that whose visibility should match the page(aka route) that opened it
 * E.g.
 * Route: /page1 -> opens drawer
 * Route: /page1 -> drawer link to ./page2 clicked
 * Route: /page1/page2 -> page1's drawer should be _not visible_
 * Route: /page1/page2 -> breadcrumb to /page1 clicked
 * Route: /page1 -> page1's drawer should be _visible_
 */
export function RouteBoundPortal({ children }: { children: ReactNode }) {
    const target = useRouteBoundPortal();
    return <Portal target={target}>{children}</Portal>;
}

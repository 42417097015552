import { QueryExpr } from '@apis/Resources';
import { Query } from '@apis/Resources/model';
import { Box, Card, Divider, LoadingOverlay, Space, Stack, Sx, Text, useMantineTheme } from '@mantine/core';
import { ResponsiveLine } from '@nivo/line';
import {
    crispBorder,
    ChartTooltipInfo,
    verticalStripes,
    yAxisLabel,
    xMonthsAxis,
    xWeekdaysAxis,
    typedFormatter,
    IPlotData,
    useSliceTooltip,
    minimizeTooltipData,
    IPlotExtremes,
    IPlotRequest,
    missingRangesLayer,
} from '@root/Components/Charts/Common';
import { FlyoverHost, useTooltip } from '@root/Components/Picker/Flyover';
import { FillerSwitch } from '@root/Design/Filler';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { useIdGen } from '@root/Services/IdGen';
import { exprBuilder, groupExprs, INumericFluentOperators, queryBuilder } from '@root/Services/QueryExpr';
import { format } from 'date-fns';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
    ChipMfgMarketshareModel,
    ChipMfgDescriptor,
    computeMktshrMfgs,
    ChipMfgMarketshareDigest,
    IChipMfgGridRow,
    ChipMfgFieldDescriptor,
} from './ComputeMktshrModels';

interface ChipMfgChartProps {
    chipSvc: ChipMfgMarketshareModel;
}
export function ChipMfgChart({ chipSvc }: ChipMfgChartProps) {
    const fmtSvc = useDi(FormatService);
    const theme = useMantineTheme();
    const csp = useEventValue(chipSvc.cspFilter);
    const dateRange = useEventValue(chipSvc.selectedDateRange);
    const [totals, setTotals] = useState<(ChipMfgDescriptor & { value: number; pct: number })[]>([]);
    const initializing = useEventValue(chipSvc.initializing);
    const [totalLoading, setTotalLoading] = useState(false);
    const queryKey = useMemo(() => ({ value: '', totalValue: '' }), []);
    const { mfgChipNames } = computeMktshrMfgs;

    const dataSvc = useMemo(() => new ChartDataService(chipSvc, fmtSvc, 'vCPUQuantity'), [chipSvc]);
    const [plotData, setPlotData] = useState<Awaited<ReturnType<ChartDataService['getLineData']>>>();
    const selections = useEventValue(chipSvc.rowsSelected);

    useEffect(() => {
        (async () => {
            let isCurrent = false;
            try {
                const currentKey = JSON.stringify({ csp, dateRange });
                setTotalLoading(true);
                queryKey.totalValue = currentKey;

                const expr = exprBuilder<ChipMfgMarketshareDigest>().createFluentExpr;
                const criteria = [
                    csp ? expr((b) => b.model.CloudProvider.eq(csp)) : null!,
                    dateRange?.from ? expr((b) => b.model.Date.onOrAfter(dateRange.from!)) : null!,
                    dateRange?.to ? expr((b) => b.model.Date.onOrBefore(dateRange.to!)) : null!,
                ].filter((c) => !!c);
                const where =
                    criteria.length > 1
                        ? expr((b) => b.and(...criteria))
                        : criteria.length > 0
                        ? criteria[0]!
                        : expr((b) => b.model.Date.isNotNull());

                const totalsResponse = await queryBuilder<ChipMfgMarketshareDigest>()
                    .where(() => where)
                    .select((b) => ({
                        AMD: b.aggIf(b.model.ProcessorFamily.eq('AMD'), b.sum(b.model.vCPUQuantity)),
                        Intel: b.aggIf(b.model.ProcessorFamily.eq('Intel'), b.sum(b.model.vCPUQuantity)),
                        AWS: b.aggIf(b.model.ProcessorFamily.eq('Graviton'), b.sum(b.model.vCPUQuantity)),
                        Azure: b.aggIf(b.model.ProcessorFamily.eq('Ampere'), b.sum(b.model.vCPUQuantity)),
                    }))
                    .execute((q) => chipSvc.query(q));

                const days = chipSvc.getDaysInPeriod();
                const cpuTotals = (totalsResponse.Results?.[0] ?? { AMD: 0, Intel: 0, AWS: 0, Azure: 0 }) as Record<string, number>;
                const { AMD, Intel, AWS, Azure } = cpuTotals;
                const total = AMD + Intel + AWS + Azure;
                const totals = mfgChipNames
                    .filter((n) => !csp || n.universal || n.mfg === csp)
                    .map((n) => ({ ...n, value: (cpuTotals[n.mfg] ?? 0) / days, pct: total ? (cpuTotals[n.mfg] ?? 0) / total : 0 }));
                totals.sort((a, b) => b.value - a.value);
                setTotals(totals);

                if (queryKey.totalValue === currentKey) {
                    isCurrent = true;
                }
                const data = await dataSvc.getLineData();
                setPlotData(data);
            } finally {
                if (isCurrent) {
                    setTotalLoading(false);
                }
            }
        })();
    }, [csp, dateRange?.from, dateRange?.to, initializing, JSON.stringify(selections)]);

    const totalHeaderStyle = { borderBottom: `solid 1px ${theme.colors.gray[2]}` };

    return (
        <Box sx={{ height: '100%', display: 'flex' }}>
            <Box sx={{ height: '100%', flexBasis: `100%`, minWidth: 0, position: 'relative' }}>
                <ComputeMktshrChart plotData={plotData} chipSvc={chipSvc} />
            </Box>
            <Space w="md" />
            <Card p={0} sx={{ borderRadius: 5, border: `solid 1px ${theme.colors.gray[2]}`, flex: `0 0 400px`, height: '100%' }}>
                <FillerSwitch loading={totalLoading || initializing}>
                    {() => (
                        <Box
                            sx={{
                                display: 'grid',
                                height: '100%',
                                gridTemplateColumns: '1fr 1fr 1fr',
                            }}
                        >
                            <Box sx={totalHeaderStyle}></Box>
                            <Stack justify="end" sx={totalHeaderStyle}>
                                <Text px="lg" size="sm" align="right">
                                    vCPU qty
                                </Text>
                            </Stack>
                            <Stack justify="end" sx={totalHeaderStyle}>
                                <Text px="lg" size="sm" align="right">
                                    Marketshare
                                </Text>
                            </Stack>
                            {totals.map((t, i) => (
                                <Fragment key={i}>
                                    <Stack sx={{ background: t.light }} justify="center">
                                        <Text align="center" size="sm">
                                            {t.mfg}
                                        </Text>
                                    </Stack>
                                    <Stack sx={{ background: i % 2 === 1 ? theme.colors.gray[2] : undefined }} justify="center">
                                        <Text px="lg" size="sm" align="right">
                                            {fmtSvc.formatDecimal2(t.value)}
                                        </Text>
                                    </Stack>
                                    <Stack sx={{ background: i % 2 === 1 ? theme.colors.gray[2] : undefined }} justify="center">
                                        <Text px="lg" size="sm" align="right">
                                            {fmtSvc.formatPercent(t.pct)}
                                        </Text>
                                    </Stack>
                                </Fragment>
                            ))}
                        </Box>
                    )}
                </FillerSwitch>
            </Card>
        </Box>
    );
}

function ComputeMktshrChart({
    plotData,
    chipSvc,
}: {
    plotData: Awaited<ReturnType<ChartDataService['getLineData']>>;
    chipSvc: ChipMfgMarketshareModel;
}) {
    const fmtSvc = useDi(FormatService);
    const dataSvc = useMemo(() => new ChartDataService(chipSvc, fmtSvc, 'vCPUQuantity'), [chipSvc]);
    const plotField = useEventValue(dataSvc.plotField);
    const loading = useEventValue(dataSvc.loading);
    const initializing = useEventValue(chipSvc.initializing);
    const yFormatter = chipSvc.getFieldFormatter(plotField?.format);

    return (
        <Box sx={{ height: 'calc(100% + 34px)' }} m={-16}>
            <LoadingOverlay visible={!!loading && !initializing} />
            <FillerSwitch loading={initializing} noData={!plotData}>
                {() => <ComputeMktshrLineChart data={plotData!} yFormatter={yFormatter} />}
            </FillerSwitch>
        </Box>
    );
}

function ComputeMktshrLineChart(props: { data: Awaited<ReturnType<ChartDataService['getLineData']>>; yFormatter: (value: number) => string }) {
    const { data, yFormatter } = props;
    const fmtSvc = useDi(FormatService);
    const { getId } = useIdGen();
    const tooltipId = useMemo(() => `tooltip-${getId({})}`, []);
    const { colors } = useMantineTheme();
    const {
        metricInfo: { label: yLabel },
        dailyData: { data: lineData, extremes },
        monthlyData: { data: monthData },
    } = data!;

    const { onContainerMouseMove, onMouseLeave, sliceTooltip } = useComputeMktshrTooltip(yLabel, monthData, tooltipId);
    const onHover = useComputeMktshrMonthTooltip(yLabel, tooltipId);

    const margin = { top: 10, right: 10, bottom: 60, left: 70 };

    const xFormatter = (value: string) => fmtSvc.toShortDate(new Date(value), true);

    const monthColors = (value: string) => (new Date(value).getMonth() % 2 === 0 ? colors.gray[1] : '#fff0');
    const borderLayer = crispBorder();
    const monthStripesLayer = verticalStripes(extremes.xValues, monthColors);
    const yAxixLblLayer = yAxisLabel(yLabel);
    const monthAxisLayer = xMonthsAxis({ ...extremes, monthData, formatter: yFormatter, onHover, height: 45 });
    const dayAxisLayer = xWeekdaysAxis({ ...extremes, offset: 45, height: 15, lineColor: '#0006' });
    const missingRanges = missingRangesLayer({ plotData: lineData });
    const layers: ResponsiveLine['props']['layers'] = [
        borderLayer,
        monthStripesLayer,
        'axes',
        yAxixLblLayer,
        monthAxisLayer,
        dayAxisLayer,
        'lines',
        missingRanges,
        'slices',
        'mesh',
        'crosshair',
    ];

    return (
        <Box sx={{ overflow: 'hidden', height: '100%' }} onMouseLeave={onMouseLeave} onMouseMove={onContainerMouseMove}>
            <ResponsiveLine
                xFormat={typedFormatter(xFormatter)}
                yFormat={typedFormatter(yFormatter)}
                margin={margin}
                data={lineData}
                layers={layers}
                enableSlices="x"
                sliceTooltip={sliceTooltip}
                enablePoints={false}
                axisBottom={null}
                lineWidth={2}
                colors={(d) => d.color}
            />
            <FlyoverHost flyoverKey={tooltipId} />
        </Box>
    );
}

function useComputeMktshrMonthTooltip(metricLabel: string, tooltipId: string = '') {
    const [show] = useTooltip({ anchor: 'top-center', nonInteractive: true, offsetY: 12 }, tooltipId);
    return useCallback(
        (info: ChartTooltipInfo[], month: Date, target: Element) => {
            const pos = target.getBoundingClientRect();
            const x = pos.left + pos.width / 2;
            const y = pos.bottom;
            const monthLbl = format(month, 'MMMM yyyy');

            show({ x, y, renderer: () => <ComputeMktshrTooltip date={monthLbl} items={info} total="" valueLbl={metricLabel} /> });
        },
        [show]
    );
}

function useComputeMktshrTooltip(metricLabel: string, monthlyData: IPlotData[] = [], tooltipId: string = '') {
    const getKey = (date: string, group: string) => `${date.slice(0, 7)}-${group}`;
    const getPtMonthItem = (group: IPlotData, item: { x: string; y: number }) => [getKey(item.x, group.id), { group, item }] as const;
    const monthItemLookup = useMemo(() => new Map(monthlyData.flatMap((d) => d.data.map((item) => getPtMonthItem(d, item)))), [monthlyData]);
    return useSliceTooltip(
        useCallback(
            (props) => {
                const { slice } = props;
                const pt = slice.points[0].data;
                const { xFormatted: datelbl, x: rawDate } = pt;
                const date = rawDate as string;
                type PtType = (typeof slice.points)[number] & { data: { ct: number } };
                const monthItems = (slice.points as PtType[])
                    .filter((pt) => ('ct' in pt.data ? pt.data.ct !== 0 : true))
                    .map((item) => ({ key: getKey(date, item.serieId as string), value: item.data.yFormatted as string }))
                    .map(({ key, value }) => ({ group: monthItemLookup.get(key)?.group, value }));
                const items = monthItems.map(({ group, value }) => ({ color: group?.color ?? '', details: group?.details ?? [], value }));

                return <ComputeMktshrTooltip date={datelbl as string} total="" items={items} valueLbl={metricLabel} />;
            },
            [monthItemLookup, monthlyData]
        ),
        tooltipId
    );
}

function ComputeMktshrTooltip(props: { date: string; total: string; valueLbl: string; items: ChartTooltipInfo[] }) {
    const { date, items, valueLbl, total } = props;

    const { headers, rows } = minimizeTooltipData(items);
    const tableData = rows;
    if (total) {
        tableData.push({ color: '#fff0', labelValues: ['Total'], value: total });
    }

    return (
        <Card p={0} shadow="md" radius="md" withBorder>
            <Text align="center" py="xs" p="sm">
                {date}
            </Text>
            <Divider />
            <Box p="xs" px="lg">
                {!items.length ? <>No Data</> : <ComputeMktshrTooltipTable headers={headers} valueLbl={valueLbl} rows={tableData} />}
            </Box>
        </Card>
    );
}

function ComputeMktshrTooltipTable(props: { headers: string[]; valueLbl: string; rows: { color: string; value: string; labelValues: string[] }[] }) {
    const { headers, rows, valueLbl } = props;

    const lblSx: Sx = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 200 };
    const lblProps = { color: 'dimmed' as const, size: 12, sx: lblSx };
    const colorSx: Sx = { width: 16, height: 16, borderRadius: 3 };
    const valueProps = { size: 12, align: 'right' as const };

    return (
        <Box sx={{ columnGap: 8, display: 'grid', gridTemplateColumns: '16px min-content max-content' }}>
            <Box sx={colorSx}></Box>
            <Text {...lblProps}>{headers.join(', ')}</Text>
            <Text {...valueProps}>{valueLbl}</Text>
            {rows.map((row, idx) => (
                <Fragment key={idx}>
                    <Box sx={{ ...colorSx, backgroundColor: row.color }}></Box>
                    <Text {...lblProps}>{row.labelValues.join(', ')}</Text>
                    <Text {...valueProps}>{row.value}</Text>
                </Fragment>
            ))}
        </Box>
    );
}

type ChartDataResultRecord = { date: Date; ct: number } & Record<`Group${number}`, number>;
type ChartDataServiceDataResult = {
    dailyData: { data: IPlotData[]; extremes: IPlotExtremes };
    monthlyData: { data: IPlotData[]; extremes: IPlotExtremes };
    metricInfo: ChipMfgFieldDescriptor;
    aggLbl: string;
};
class ChartDataService {
    public readonly loading = new EventEmitter<boolean>(false);
    public readonly plotField: EventEmitter<ChipMfgFieldDescriptor>;
    private queryResultCache: { key?: string; result?: Promise<ChartDataServiceDataResult | undefined> } = {};

    private readonly mfgSpecificFields = new Set<keyof ChipMfgMarketshareDigest>(['InstanceType', 'ProcessorFamily', 'PhysicalProcessor', 'Region']);
    private readonly fieldSubstitutionOptions = {
        CustomerName: ['CustomerId'],
        MspName: ['MspId'],
        ProcessorFamily: ['InstanceType', 'PhysicalProcessor'],
        InstanceVCPUs: ['InstanceType'],
        PhysicalProcessor: ['InstanceType'],
        CloudProvider: ['InstanceType'],
    } as Record<keyof ChipMfgMarketshareDigest, (keyof ChipMfgMarketshareDigest)[]>;

    public constructor(private readonly chipSvc: ChipMfgMarketshareModel, private readonly fmtSvc: FormatService, plotField: string) {
        this.plotField = new EventEmitter<ChipMfgFieldDescriptor>(this.chipSvc.getFieldDescriptor(plotField)!);
    }

    public getLineData() {
        const { query, runQuery } = this.prepareQuery() ?? {};
        if (!query || !runQuery) {
            return;
        }

        const queryKey = this.createQueryKey(query);
        if (queryKey !== this.queryResultCache.key) {
            const result = runQuery();
            this.queryResultCache = {
                key: queryKey,
                result,
            };
        }

        return this.queryResultCache.result;
    }

    private prepareQuery(): { query: Query; runQuery: () => Promise<ChartDataServiceDataResult | undefined> } | undefined {
        const aggFieldInfo = this.getAggField(this.plotField.value);
        const lineMetricInfo = this.getLineMetricExprs();
        const ambientCrit = this.getAmbientCriteria();
        const { from, to } = this.chipSvc.selectedDateRange.value ?? {};
        if (!aggFieldInfo || !lineMetricInfo.length || !ambientCrit || !from || !to) {
            return;
        }

        const { query, execute } = queryBuilder<ChipMfgMarketshareDigest>()
            .where((b) => b.fromExpr(ambientCrit as QueryExpr))
            .select((b) => ({
                date: b.truncDate('day', b.model.Date, 0, from, to),
                ...lineMetricInfo.reduce((result, item) => ({ ...result, [item.key]: b.aggIf(b.fromExpr(item.criteria), b.fromExpr(item.agg)) }), {}),
                ct: b.count(),
            }))
            .prepare((q) => this.chipSvc.query(q));

        const runQuery = async () => {
            const response = await execute();

            if (!response?.Results) {
                return;
            }

            const dailyData = this.convertToPlotDataGrouped(response.Results, lineMetricInfo, (x) => this.fmtSvc.formatAsLocal(x));
            const monthlyData = this.convertToPlotDataGrouped(response.Results, lineMetricInfo, (x) => this.fmtSvc.formatAsLocal(x).slice(0, 7));
            [dailyData, monthlyData].forEach((data) => this.applyValueAgg(data.data, aggFieldInfo.valueAgg));

            return { dailyData, monthlyData, metricInfo: aggFieldInfo.descriptor, aggLbl: aggFieldInfo.aggLbl };
        };

        return { query, runQuery };
    }

    private convertToPlotDataGrouped(data: ChartDataResultRecord[], lineMetricInfo: IPlotRequest[], groupBy: (xValue: Date) => string) {
        const resultLookup = new Map<string, IPlotData>();
        const extremes: IPlotExtremes = { minX: '9999-99-99', maxX: '0000-00-00', minY: Infinity, maxY: -Infinity, xValues: [] as string[] };

        for (const item of data) {
            const date = groupBy(new Date(item.date));
            extremes.xValues.push(date);
            extremes.minX = date < extremes.minX ? date : extremes.minX;
            extremes.maxX = date > extremes.maxX ? date : extremes.maxX;

            for (const { key, data: details, color } of lineMetricInfo) {
                let plotSeries = resultLookup.get(key);
                if (!plotSeries) {
                    resultLookup.set(key, (plotSeries = { id: key, data: [], details, color }));
                }

                const y = item[key] ?? 0;
                extremes.minY = Math.min(y, extremes.minY);
                extremes.maxY = Math.max(y, extremes.maxY);
                const prev = plotSeries.data[plotSeries.data.length - 1];
                if (plotSeries.data.length === 0 || prev.x !== date) {
                    plotSeries.data.push({ x: date, y, ct: item.ct !== 0 ? 1 : 0 });
                } else {
                    prev.ct += 1;
                    prev.y += y;
                }
            }
        }

        return { data: [...resultLookup.values()], extremes };
    }

    private applyValueAgg(data: IPlotData[], valueAgg: ({ x, y, ct }: { x: string; y: number; ct: number }) => number) {
        for (const series of data) {
            for (const item of series.data) {
                item.y = valueAgg(item);
            }
        }
    }

    private createQueryKey(query: Query) {
        const ctxParams = this.chipSvc.getLinkParams();
        return JSON.stringify({ query, ctxParams });
    }

    private getLineMetricExprs() {
        const aggFieldInfo = this.getAggField(this.plotField.value);
        const lineMode = this.getLineMode();
        const lineReqs = !aggFieldInfo
            ? []
            : lineMode === 'selections'
            ? this.getSelectionLineRequests(aggFieldInfo.aggExpr)
            : this.getMfgLineRequests(aggFieldInfo.aggExpr);

        return lineReqs;
    }

    private getLineMode() {
        const mfgSpecificFields = this.getMfgSpecificFields();
        const selections = this.chipSvc.rowsSelected.value;
        const result = selections.length > 1 || mfgSpecificFields.size > 0 ? 'selections' : ('mfg' as const);

        return result;
    }

    private getAggField(descriptor: ChipMfgFieldDescriptor) {
        const xb = exprBuilder<ChipMfgMarketshareDigest>().builder;
        const fieldExpr = xb.model[descriptor.field] as number & INumericFluentOperators;
        const { aggs } = descriptor;
        const aggType = aggs.includes('sum')
            ? 'sum'
            : aggs.includes('avg')
            ? 'avg'
            : aggs.includes('avg-day-sum')
            ? 'avg-day-sum'
            : ('group' as const);
        const aggLbl = aggType === 'sum' ? 'Sum' : aggType === 'avg-day-sum' || aggType === 'avg' ? 'Average' : null;
        const expr = ['sum', 'avg-day-sum'].includes(aggType) ? xb.sum(fieldExpr) : aggType === 'avg' ? xb.avg(fieldExpr) : null;
        const valueAgg = ({ y, ct }: { x: string; y: number; ct: number }) => (aggType === 'sum' ? y : aggType === 'group' ? y : ct > 0 ? y / ct : 0);

        return aggLbl && expr ? { aggExpr: xb.resolve(expr), descriptor, aggLbl, valueAgg } : null;
    }

    private getMfgSpecificFields() {
        const selections = this.chipSvc.rowsSelected.value;
        const fields = selections.flatMap((item) =>
            Object.keys(item).filter((key) => this.mfgSpecificFields.has(key as keyof ChipMfgMarketshareDigest))
        );
        return new Set(fields);
    }

    private getCriteriaFromSelection(selection: IChipMfgGridRow) {
        const groupableFields = new Set(
            this.chipSvc
                .getFieldDescriptors()
                .filter((f) => f.aggs.includes('group'))
                .map((f) => f.field)
        );
        const selectionFields = Object.keys(selection) as (keyof ChipMfgMarketshareDigest)[];
        const filterFields = new Set(
            selectionFields
                .filter((f) => groupableFields.has(f))
                .map((f) => {
                    const subs = this.fieldSubstitutionOptions[f];
                    const bestSub = subs?.filter((s) => selection[s])[0];
                    return bestSub || f;
                })
        );
        const selectionValues = [...filterFields].map((f) => ({ field: f, value: selection[f] }));

        const xb = exprBuilder<ChipMfgMarketshareDigest>().builder;
        const result = selectionValues.map((v) => xb.resolve(xb.model[v.field].eq(v.value as any)));

        return result;
    }

    private getAmbientCriteria() {
        const xb = exprBuilder<ChipMfgMarketshareDigest>().builder;
        const dateRange = this.chipSvc.selectedDateRange.value;
        const csp = this.chipSvc.cspFilter.value;
        const selections = this.chipSvc.rowsSelected.value.map((s) => groupExprs('and', this.getCriteriaFromSelection(s.item)));
        const selectionCrit = this.getLineMode() === 'mfg' && selections.length ? groupExprs('or', selections) : null;

        const criteria = [
            csp ? xb.model.CloudProvider.eq(csp) : null!,
            dateRange?.from ? xb.model.Date.onOrAfter(dateRange.from!) : null!,
            dateRange?.to ? xb.model.Date.onOrBefore(dateRange.to!) : null!,
            selectionCrit ? xb.fromExpr(selectionCrit as QueryExpr) : null!,
        ].filter((c) => !!c);

        return groupExprs(
            'and',
            criteria.map((c) => xb.resolve(c))
        );
    }

    private getMfgLineRequests(agg: QueryExpr): IPlotRequest[] {
        const xb = exprBuilder<ChipMfgMarketshareDigest>().builder;
        return computeMktshrMfgs.mfgChipNames.map((c, idx) => ({
            criteria: xb.resolve(xb.model.ProcessorFamily.eq(c.mfg)),
            key: `Group${idx}`,
            data: [{ label: 'Vendor', value: c.chip }],
            color: c.dark,
            agg,
        }));
    }

    private getSelectionLineRequests(agg: QueryExpr): IPlotRequest[] {
        const descriptorLookup = this.chipSvc
            .getFieldDescriptors()
            .reduce((result, item) => result.set(item.field, item), new Map<string, ChipMfgFieldDescriptor>());
        return this.chipSvc.rowsSelected.value
            .map(({ color, item: selection }, idx) => {
                const criteria = groupExprs('and', this.getCriteriaFromSelection(selection)) as QueryExpr;
                const data = Object.keys(selection)
                    .map((key) => {
                        const descriptor = descriptorLookup.get(key);
                        return !descriptor?.aggs.includes('group')
                            ? null!
                            : {
                                  label: descriptor?.label ?? key,
                                  value: selection[key as keyof ChipMfgMarketshareDigest]?.toString() ?? '',
                              };
                    })
                    .filter((d) => !!d);
                const key = `Group${idx}` as IPlotRequest['key'];
                return { key, criteria, data, color, agg };
            })
            .filter((r) => !!r.criteria);
    }
}

import React, { useEffect, useState } from 'react';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { Card, Stack, Title, Text, Space } from '@mantine/core';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { Company } from '@apis/Customers/model';
import styled from '@emotion/styled';
import { useNav } from '@root/Services/NavigationService';
import { getMspGetMyMsp } from '@apis/Customers';

const MspInactiveCompany = () => {
    const [mspCompany, setMspCompany] = useState<Company>({});
    const company = useCompany();
    const { getRootUrl } = useNav();

    useEffect(() => {
        LoadData();
    }, []);

    const LoadData = async () => {
        try {
            const mspCompany = await getMspGetMyMsp();
            if (mspCompany) {
                setMspCompany(mspCompany);
            }
        } catch (error) {
            //console.log(error);
        }
    };

    return (
        <PageContainer>
            <Card withBorder shadow="sm" radius="lg" sx={{ width: 'fit-content', maxWidth: '480px', textAlign: 'center' }}>
                <Title order={3}>{company?.CompanyName} is Inactive</Title>
                <Space h={16} />
                <Text>
                    {mspCompany.CompanyName} has {company?.CompanyName} set to inactive. If this is unexpected, please contact support.
                </Text>
                <Space h={32} />
                <a href={getRootUrl('support/support-home')}>Contact Support</a>
            </Card>
        </PageContainer>
    );
};

const PageContainer = styled.div`
    padding: 64px;
    text-align: center;
    display: flex;
    justify-content: center;
`;

endpoint('msp-inactive-company', MspInactiveCompany, 'Inactive Company');

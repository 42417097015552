import { BaseAzureResource } from '@apis/Resources/model';
import { PropertyGridItem } from '@root/Components/PropertyGrid/Models';
import { ReactNode } from 'react';

type ResourceTypeFieldConfig = {
    [resourceType: string]: {
        [fieldPath: string]: {
            label?: string;
            valueRenderer?: (item: PropertyGridItem) => ReactNode;
            format?: (item: PropertyGridItem) => string | undefined;
            value?: (resource: BaseAzureResource) => unknown;
            hidden?: boolean;
        };
    };
};

export function useAzureResourceFieldConfig() {
    const azureCommonFields: ResourceTypeFieldConfig[''] = {
        Account: { hidden: true },
        AccountID: { hidden: true },
        AzureId: {},
        kind: {},
        location: {},
        'Properties.provisioningState': {},
        ParentCreateDate: { hidden: true },
        ResourceGroup: {},
        SubscriptionId: {},
        Subscription: {},
        'sku.name': {},
        'sku.tier': {},
        Type: {
            hidden: true,
        },
    };
    return { azureCommonFields };
}

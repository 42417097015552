import { EventEmitter } from '@root/Services/EventEmitter';
import { injectable } from 'tsyringe';

@injectable()
export class CompanyAdministrationPanelModel {
    public saveChangesRequested = EventEmitter.empty();
    public discardChangesRequested = EventEmitter.empty();
    public isAddressGood = new EventEmitter<boolean>(true);
    public isModifiedChanged = new EventEmitter<boolean>(false);
    public tabChanged = new EventEmitter<string>('company');
    public isSubscriptionUpdated = new EventEmitter<boolean>(false);
}

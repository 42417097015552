import { Company } from '@apis/Customers/model';
import { getInvoiceRuleGetAllocationDimensions, postInvoiceRuleSaveAllocationDimension } from '@apis/Invoices';
import {
    AllocationDimension,
    DisbursementTargetDisbursementMethod,
    DisbursementTargetDisbursementShare,
    DisbursementTargetTargetType,
    FundSourceAmortization,
    FundSourceExternalDetail,
    FundSourceSourceType,
    InvoiceAllocationRule,
    InvoiceAllocationRuleRuleType,
    InvoiceProcessingGroupStatus,
    InvoiceProcessingLogEntry,
    InvoiceProcessingReport,
    InvoiceTaggingRule,
    NamedFilterSet,
} from '@apis/Invoices/model';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { ICompanyContextToken } from '@root/Services/Customers/CompanyContext';
import { EventEmitter } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { ShowbackEvents, ShowbackPersistence } from '@root/Services/Invoices/ShowbackService';
import { NavigationService } from '@root/Services/NavigationService';
import { queryBuilder } from '@root/Services/QueryExpr';
import { addHours, differenceInHours } from 'date-fns';

import { inject, injectable } from 'tsyringe';

export interface GenericFundSource<TPresentationOptions> {
    Name: string | null | undefined;
    SourceType: FundSourceSourceType;
    Maximum?: number | null;
    PresentationOptions?: TPresentationOptions;
    Amortization?: FundSourceAmortization;
}

export interface LineItemFundSource extends GenericFundSource<unknown> {
    SourceType: 'LineItems';
    Filters: NamedFilterSet;
}

export interface ExternalFundSource extends GenericFundSource<unknown> {
    SourceType: 'External';
    ExternalDetail: FundSourceExternalDetail;
}

export interface GenericDisbursementTarget<TPresentationOptions> {
    Name: string | null | undefined;
    TargetType: DisbursementTargetTargetType;
    DisbursementShare?: DisbursementTargetDisbursementShare;
    DisbursementMethod?: DisbursementTargetDisbursementMethod;
    FixedAmount?: number | null;
    Percent?: number | null;
    Maximum?: number | null;
    UsageStatFilter?: NamedFilterSet;
    PresentationOptions?: TPresentationOptions;
}

export interface ExistingDisbursementTarget extends GenericDisbursementTarget<unknown> {
    TargetExistingFilter?: NamedFilterSet;
}

export interface NewRecordsDisbursementTarget extends GenericDisbursementTarget<unknown> {
    DimensionValues?: string[] | null;
    IsDimensionValuesExclusionList?: boolean;
}

type ProcessingReportInfo = {
    entries: Record<keyof InvoiceProcessingReport, InvoiceProcessingLogEntry[]>;
    lastStarted: Record<keyof InvoiceProcessingReport, Date>;
    lastFinished: Record<keyof InvoiceProcessingReport, Date>;
};
type ProcessingReport = InvoiceProcessingReport & ProcessingReportInfo;

@injectable()
export class ShowbackModel {
    public showbackConfigured = new EventEmitter<boolean | undefined>(undefined);
    public loading = new EventEmitter<boolean>(false);
    public saving = new EventEmitter(false);
    public showbackSettingsRequested = new EventEmitter<AllocationDimension | undefined>(undefined);
    public allocationDimensions: AllocationDimension[] | undefined = undefined;
    public selectedAllocationDimension: AllocationDimension | undefined = undefined;
    public availableMonths: Date[] = [];
    public selectedMonthChanged = new EventEmitter<Date | undefined>(undefined);
    public rulesChanged = EventEmitter.empty();
    public processingReport = new EventEmitter<ProcessingReport | undefined>(undefined);
    public userId = -1;
    private originalTaggingPriority = '';
    private originalAllocationPriority = '';
    private readonly disposers: (() => void)[] = [];

    public constructor(
        @inject(FormatService) private readonly fmtSvc: FormatService,
        @inject(AuthenticationService) private readonly authSvc: AuthenticationService,
        @inject(ICompanyContextToken) private readonly company: Company,
        @inject(ShowbackPersistence) private readonly showbackSvc: ShowbackPersistence,
        @inject(ShowbackEvents) private readonly showbackEvents: ShowbackEvents,
        @inject(InvoiceApiService) private readonly invoiceApiSvc: InvoiceApiService
    ) {
        this.userId = this.authSvc.user?.Id ?? -1;
        this.init();
    }

    public dispose() {
        this.disposers.forEach((dispose) => dispose());
    }

    public async init() {
        const [, dateRange] = await Promise.all([this.loadDimensions(), this.invoiceApiSvc.getDateRange()]);

        this.availableMonths = this.showbackSvc.getMonthList(dateRange);
        const lastMonth = this.availableMonths[this.availableMonths.length - 1];
        this.selectedMonthChanged.emit(lastMonth ?? new Date());
        this.loadProcessingReport(this.selectedMonthChanged.value!);

        if (this.allocationDimensions && this.allocationDimensions!.length > 0) {
            this.showbackConfigured.emit(true);
        } else {
            this.showbackConfigured.emit(false);
        }

        const { dispose } = this.showbackEvents.invoiceRuleUpdated.listen(() => this.reloadDimensions());
        this.disposers.push(dispose);
    }

    public openTab(navSvc: NavigationService, tab: 'summary' | 'reconciliation' | 'allocationRules') {
        navSvc.replaceParams({ tab });
    }

    public openRuleEditor(navSvc: NavigationService, ruleId?: number, ruleType?: InvoiceAllocationRuleRuleType) {
        const params: Record<string, string> = {
            dimId: this.selectedAllocationDimension?.Id?.toString() ?? '',
            month: this.fmtSvc.formatYearMonth(this.selectedMonthChanged.value ?? new Date()),
        };
        if (ruleId) {
            params.ruleId = ruleId.toString();
        }
        if (ruleType) {
            params.ruleType = ruleType;
        }
        navSvc.descend('rule-editor', params);
    }

    public setSelectedMonth = (selectedMonth: Date) => {
        this.selectedMonthChanged.emit(selectedMonth);
        this.loadProcessingReport(selectedMonth);
    };

    public reloadDimensions = () => {
        this.loadDimensions();
    };

    private async loadDimensions() {
        const allocationDimensions = await getInvoiceRuleGetAllocationDimensions();
        this.allocationDimensions = allocationDimensions;
        //set to first by default for now, will eventually support multiple
        this.selectedAllocationDimension = this.allocationDimensions![0];
        ShowbackModel.sortRules(this.selectedAllocationDimension?.TaggingRules);
        ShowbackModel.sortRules(this.selectedAllocationDimension?.AllocationRules);
        this.originalTaggingPriority = this.serializeTaggingPriority();
        this.originalAllocationPriority = this.serializeAllocationPriority();
        this.rulesChanged.emit();
    }

    public static sortRules = <T extends { Order: number }>(rules?: T[] | null) => {
        return rules?.sort((a, b) => a.Order - b.Order);
    };

    public createBasicDimension() {
        return {
            CompanyId: this.company.Id,
            CreatedBy: this.userId,
            DefaultValue: '',
            DimensionName: '',
            TaggingRules: [] as InvoiceTaggingRule[],
            AllocationRules: [] as InvoiceAllocationRule[],
        } as AllocationDimension;
    }

    public saveDimension = async (dimension: AllocationDimension) => {
        await postInvoiceRuleSaveAllocationDimension(dimension);
        await this.loadDimensions();
        this.showbackConfigured.emit(true);
    };

    public saveChanges = async () => {
        try {
            this.loading.emit(true);
            this.saving.emit(true);
            if (!this.selectedAllocationDimension) {
                return;
            }
            await postInvoiceRuleSaveAllocationDimension(this.selectedAllocationDimension);
            await this.loadDimensions();
        } finally {
            this.loading.emit(false);
            this.saving.emit(false);
        }
    };

    public getDimensionInvoiceField() {
        return this.showbackSvc.getDimensionInvoiceField(this.selectedAllocationDimension);
    }

    public getAllocationStats() {
        return this.showbackSvc.getAllocationStats(this.selectedMonthChanged.value!, this.selectedAllocationDimension!, undefined, 'unallocated');
    }

    public getNextProcessingRun() {
        const date = this.fmtSvc.toUtc(new Date());
        const utcMidnight = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
        const runOpportunities = Math.ceil(differenceInHours(date, utcMidnight) / 8);
        const utcNextRun = addHours(utcMidnight, runOpportunities * 8);
        return this.fmtSvc.toLocalDate(utcNextRun);
    }

    private async loadProcessingReport(month: Date) {
        const rawReport = await this.invoiceApiSvc.getProcessingReport(month);
        const getStatusList = ({ IndexingJobs, ProcessingJob, StageJob, Transfer }: InvoiceProcessingGroupStatus) =>
            [...(IndexingJobs ?? []), ProcessingJob, StageJob, Transfer].filter((x) => !!x).map((x) => x!);

        const result = {
            ...rawReport,
            ...Object.keys(rawReport).reduce(
                (acc, key) => {
                    const prop = key as keyof InvoiceProcessingReport;
                    const value = rawReport[prop];
                    if (value) {
                        const statusList = getStatusList(value);
                        acc.entries[prop] = statusList;
                        acc.lastStarted[prop] = statusList.reduce((result, item) => {
                            const dt = this.fmtSvc.toLocalDate(item.StartedAt);
                            return dt < result ? dt : result;
                        }, new Date());
                        acc.lastFinished[prop] = statusList.reduce((result, item) => {
                            const rawDt = item.SucceededAt ?? item.FailedAt;
                            const finished = rawDt ? this.fmtSvc.toLocalDate(rawDt) : undefined;
                            return finished !== undefined && finished > result ? finished : result;
                        }, new Date(0));
                    }
                    return acc;
                },
                { entries: {}, lastStarted: {}, lastFinished: {} } as ProcessingReportInfo
            ),
        };
        this.processingReport.emit(result);
    }

    public getDiscountLineItemValues() {
        return [
            'BundledDiscount',
            'SavingsPlanUpfrontFee',
            'SavingsPlanRecurringFee',
            'SavingsPlanCoveredUsage',
            'SavingsPlanNegation',
            'DiscountedUsage',
            'RIFee',
            'EdpDiscount',
            'PrivateRateDiscount',
        ];
    }

    public getDimensionUniqueValuesQuery() {
        var dimensionName = this.getDimensionInvoiceField();
        return queryBuilder<{
            [key: string]: string;
        }>()
            .select((b) => ({
                key: b.values(b.model[dimensionName]),
            }))
            .build();
    }

    public canSavePriority() {
        return (
            this.serializeTaggingPriority() !== this.originalTaggingPriority || this.serializeAllocationPriority() !== this.originalAllocationPriority
        );
    }

    private serializeTaggingPriority() {
        return (
            this.selectedAllocationDimension?.TaggingRules?.filter((x) => !x.IsDeleted)
                .map((x) => x.Id ?? 0)
                .join(',') ?? ''
        );
    }

    private serializeAllocationPriority() {
        return (
            this.selectedAllocationDimension?.AllocationRules?.filter((x) => !x.IsDeleted)
                .map((x) => x.Id ?? 0)
                .join(',') ?? ''
        );
    }

    public updateProcessingReport() {
        this.loadProcessingReport(this.selectedMonthChanged.value!);
    }
    public closeAllocationDimension = () => this.showbackSettingsRequested.emit(undefined);
    public createAllocationDimension = () => this.showbackSettingsRequested.emit(this.createBasicDimension());
    public editDimensionSettings = () => this.showbackSettingsRequested.emit(this.selectedAllocationDimension);
}

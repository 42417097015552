import { QuerySelectExpr } from '@apis/Resources/model';
import { ChartTypes } from '@root/Components/Charts/Common';
import { QueryDescriptorService } from '@root/Components/Filter/Services';
import { EventEmitter } from '@root/Services/EventEmitter';
import { SchemaService, SchemaValueProvider } from '@root/Services/QueryExpr';
import { makeAutoObservable, toJS } from 'mobx';
import { deepObserve } from 'mobx-utils';
import { DashboardItemConfig, DashboardItemModel } from '../Models';
import { ChartConfig, chartInfo } from './ChartRenderer';

export class ChartEditor {
    public loading = new EventEmitter(true);
    public onClose = EventEmitter.empty();
    public shemaSvc!: SchemaService;
    public queryDescriptorSvc!: QueryDescriptorService;
    public onSettingsChanged = EventEmitter.empty();
    public settings: DashboardItemConfig<ChartConfig>;
    public valueProvider!: SchemaValueProvider;
    public isEditMode = false;

    public constructor(public dashboardItemModel: DashboardItemModel<ChartConfig>, isEditMode = false) {
        this.settings = structuredClone(this.dashboardItemModel.settings);
        this.isEditMode = isEditMode;
    }

    private getDatasource() {
        return this.dashboardItemModel.getDatasource(this.dashboardItemModel.settings.datasourceName);
    }

    public async init() {
        this.loading.emit(true);
        try {
            const datasource = this.getDatasource();
            if (datasource) {
                const types = await datasource.schema.getSchema();
                this.shemaSvc = new SchemaService(types);
                this.queryDescriptorSvc = QueryDescriptorService.create(this.shemaSvc);
                this.valueProvider = new SchemaValueProvider(this.shemaSvc, datasource.source);
            }
        } finally {
            this.loading.emit(false);
        }
    }

    public setTitle = (title: string) => {
        this.settings.title = title;
        this.settings.title = title;
        this.invalidate();
    };
    public get title() {
        return this.settings.title;
    }

    public setChartType = (type: ChartTypes) => {
        this.settings.chartType = type;
        const typeInfo = chartInfo.get(type);
        this.settings.settings = typeInfo?.getDefaults(this.getDatasource()!);
        typeInfo?.cleanConfig?.(this.settings, this.getDatasource()!);
        this.invalidate();
    };
    public get chartType() {
        return this.settings.chartType;
    }

    public getGroup(index: number) {
        return this.settings.groups[index];
    }
    public setGroup(index: number, expr: QuerySelectExpr) {
        this.settings.groups[index] = expr;
        this.invalidate();
    }
    public removeGroup(index: number) {
        this.settings.groups.splice(index, 1);
        this.invalidate();
    }

    public setValue(index: number, expr: QuerySelectExpr) {
        this.settings.values[index] = expr;
        this.invalidate();
    }
    public getValue(index: number) {
        return this.settings.values[index];
    }
    public removeValue(index: number) {
        this.settings.values.splice(index, 1);
        this.invalidate();
    }

    public getChartSettings<TSettings>() {
        const defaults = chartInfo.get(this.chartType)?.getDefaults(this.getDatasource());
        const settings = { ...defaults, ...this.settings.settings! };
        const observableSettings = makeAutoObservable(toJS(settings));
        deepObserve(observableSettings, () => this.applyChartSettings(settings));
        return settings as TSettings;
    }

    public applyChartSettings<TSettings>(settings: TSettings) {
        this.settings.settings = toJS(settings)!;
        this.onSettingsChanged.emit();
    }

    public saveChanges() {
        this.dashboardItemModel.settings.title = this.settings.title;
        this.dashboardItemModel.updateSettings(this.settings);
        this.dashboardItemModel.saveLayout();
    }

    private invalidate() {
        this.onSettingsChanged.emit();
    }
}

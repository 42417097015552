import { BaseResource } from '@apis/Resources/model';
import { LoadingOverlay, useMantineTheme } from '@mantine/core';
import { DataGrid } from '@root/Components/DataGrid';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { GridFullCell } from '@root/Components/DataGrid/Design';
import { ChildAccessor, ColumnConfig, ColumnGroupConfig, DataSourceConfig, ISelectionStrategy } from '@root/Components/DataGrid/Models';
import { VisibleSpaces } from '@root/Components/Text/VisibleSpaces';
import { SchemaService } from '@root/Services/QueryExpr';
import { useMemo } from 'react';

class SingleItemSelection implements ISelectionStrategy<BaseResource> {
    private selectedItem?: BaseResource;
    public isSelected(item: BaseResource): boolean {
        return (
            this.selectedItem?.Id === item.Id &&
            this.selectedItem?.CloudPlatform === item.CloudPlatform &&
            this.selectedItem?.ResourceType === item.ResourceType
        );
    }
    public getSelectAllValidity(): string | undefined {
        return undefined;
    }
    public setSelected(item: BaseResource, selected: boolean): void {
        if (selected) {
            this.selectedItem = item;
        } else {
            this.selectedItem = undefined;
        }
    }
    public async setSelectAll(selected: boolean) {
        this.selectedItem = undefined;
    }
    public async getSelected(): Promise<BaseResource[]> {
        return this.selectedItem ? [this.selectedItem] : [];
    }
    public count(): number {
        return this.selectedItem ? 1 : 0;
    }
}

export function HierarchyGrid<T extends object>({
    metricColumns,
    data,
    schemaSvc,
    childAccessor,
    onModelLoaded,
    rightTopPlaceHolder,
    rightToolPlaceHolder,
}: {
    metricColumns: ColumnConfig<T>[];
    data: DataSourceConfig<T>;
    schemaSvc: SchemaService;
    childAccessor: ChildAccessor<T>;
    onModelLoaded: (model: DataGridModel) => void;
    rightTopPlaceHolder: React.ReactNode;
    rightToolPlaceHolder: React.ReactNode;
}) {
    const theme = useMantineTheme();
    const selector = useMemo(() => new SingleItemSelection(), []);
    const groupConfig: { [groupName: string]: ColumnGroupConfig } = {};
    groupConfig['Tags'] = { color: theme.colors.primary[3] };

    return !data ? (
        <LoadingOverlay visible />
    ) : (
        <DataGrid
            indentLeafNodes
            dataSource={data}
            groupConfig={groupConfig}
            selectionStrategy={selector}
            selectionMode={'multiple'}
            showHeaderGroups={true}
            columns={metricColumns}
            hideMenu={false}
            showRefresh
            rightTopPlaceHolder={rightTopPlaceHolder}
            rightToolPlaceHolder={rightToolPlaceHolder}
            childAccessor={childAccessor}
            onModelLoaded={onModelLoaded}
        />
    );
}

export function HierarchyTagCell({ item, tag }: { item: BaseResource; tag: string }) {
    const theme = useMantineTheme();
    const value = item?.CsTags?.[tag];
    const styles = !value ? { background: theme.colors.gray[5] + '10', fontStyle: 'italic', color: theme.colors.gray[6] } : undefined;
    return (
        <GridFullCell style={{ ...styles }}>
            {value === null ? <></> : value === undefined ? <>« No Tag »</> : value === '' ? <>« Empty »</> : <VisibleSpaces value={value} />}
        </GridFullCell>
    );
}

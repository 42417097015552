import { postDailyRollupQuery } from '@apis/Invoices';
import { Query } from '@apis/Invoices/model';
import { postResourceChangedQueryResourceChangeLog, postResourcesQuery, QueryResult } from '@apis/Resources';
import { BaseAwsResource, ResourceChange } from '@apis/Resources/model';
import { MapContract } from '@apis/TagManager/model';
import styled from '@emotion/styled';
import { Box, Card, Group, Loader, Text, Title, useMantineTheme } from '@mantine/core';
import { BarChart } from '@root/Components/Charts/BarChart';
import { LineChart } from '@root/Components/Charts/LineChart';
import { ListChart } from '@root/Components/Charts/ListChart';
import { PieChart } from '@root/Components/Charts/PieChart';
import { Score } from '@root/Design/Primitives';
import { CustomColors } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { IDailyRollup } from '@root/Services/Invoices/InvoiceSchemaService';
import { IStringFluentOperators, queryBuilder } from '@root/Services/QueryExpr';
import { addDays, differenceInDays } from 'date-fns';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { BaseMapResource, MapResourceQueryService } from '../../Services/MapResourceQueryService';
import { MapContractHomeModel } from '../Models';
import { useNav } from '@root/Services/NavigationService';
import { ChartWrapper } from '@root/Components/Charts/Design';

export class MapDashboardModel {
    public loading = new EventEmitter(true);
    public dateRange: EventEmitter<{ from?: Date; to?: Date }>;

    constructor(public contract: MapContract, public querySvc: MapResourceQueryService) {
        const daysAvail = differenceInDays(new Date(), this.querySvc.contractFrom);
        this.dateRange = new EventEmitter<{ from?: Date; to?: Date }>({
            from: daysAvail > 30 ? addDays(new Date(), -30) : daysAvail < 5 ? addDays(new Date(), -5) : this.querySvc.contractFrom,
            to: new Date(),
        });
    }
}

function Top5Owners({ model }: { model: MapDashboardModel }) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const { mapResourceQuerySvc, resourceSchema } = useDi(MapContractHomeModel);

    if (!mapResourceQuerySvc) return null;

    const countCellRendered = (item: { key: string; value: number }) => {
        return (
            <Text align="right" color="primary" weight="bolder">
                {fmtSvc.formatPercent(item.value)}
            </Text>
        );
    };

    const keyStyling = { width: '100px' };

    const { loading, data } = useTileLoader(model.dateRange, async (from, to) => {
        const results = await queryBuilder<Omit<BaseAwsResource, 'CreateDate'> & { CreateDate: Date }>()
            .where((b) =>
                b.and(
                    model.querySvc.getMgmtAccountQuery(),
                    b.model.CreateDate.onOrAfter(from ?? model.querySvc.contractFrom),
                    b.model.CreateDate.onOrBefore(to ?? new Date())
                )
            )
            .select((b) => ({
                key: b.model['CsTags.Owner'] as unknown as IStringFluentOperators,
                totalResources: b.count(),
                taggedCorrectly: b.countIf(mapResourceQuerySvc.getCorrectlyTaggedQuery()),
            }))
            .execute(postResourcesQuery);

        return {
            keyValuesApplied: results.Results?.map((x) => ({ key: x.key, value: x.taggedCorrectly / x.totalResources }))
                .filter((x) => x.value > 0)
                .sort((a, b) => b.value - a.value),
        };
    });

    return (
        <>
            <WhiteCard>
                <Text mb="xs">Top 5 Owners</Text>
                {loading ? (
                    <Group position="center">
                        <Loader />
                    </Group>
                ) : data?.keyValuesApplied?.length ? (
                    <ListChart
                        data={data?.keyValuesApplied ?? []}
                        groups={[]}
                        values={[]}
                        settings={{ keyProperty: 'value', valueProperty: 'key', keyRenderer: countCellRendered, keyStyling: keyStyling }}
                    />
                ) : (
                    <Group>
                        <Text color="dimmed">No data</Text>
                    </Group>
                )}
            </WhiteCard>
        </>
    );
}

function KeyValuesApplied({ model }: { model: MapDashboardModel }) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const { mapResourceQuerySvc } = useDi(MapContractHomeModel);

    if (!mapResourceQuerySvc) return null;

    const countCellRendered = (item: { key: string; value: number }) => {
        return (
            <Text align="center" color="primary" weight="bolder">
                {fmtSvc.formatInt(item.value)}
            </Text>
        );
    };

    const keyStyling = { backgroundColor: theme.colors?.primary?.[2] as CustomColors, width: '100px' };

    const { loading, data } = useTileLoader(model.dateRange, async (from, to) => {
        const results = await queryBuilder<Omit<BaseAwsResource, 'CreateDate'> & { CreateDate: Date }>()
            .where((b) =>
                b.and(
                    model.querySvc.getMgmtAccountQuery(),
                    b.model.ResourceType.eq(mapResourceQuerySvc.validResourceTypes),
                    b.model.CreateDate.onOrAfter(from ?? model.querySvc.contractFrom),
                    b.model.CreateDate.onOrBefore(to ?? new Date())
                )
            )
            .select((b) => ({
                key: b.model['CsTags.map-migrated'] as unknown as IStringFluentOperators,
                value: b.countIf(mapResourceQuerySvc.getCorrectlyTaggedQuery()),
            }))
            .execute(postResourcesQuery);

        return { keyValuesApplied: results.Results?.filter((x) => x.value > 0).sort((a, b) => b.value - a.value) };
    });
    return (
        <>
            <WhiteCard>
                <Text mb="xs">Key Values Applied</Text>
                {loading ? (
                    <Group position="center">
                        <Loader />
                    </Group>
                ) : data?.keyValuesApplied?.length ? (
                    <ListChart
                        data={data?.keyValuesApplied ?? []}
                        groups={[]}
                        values={[]}
                        settings={{ keyProperty: 'value', valueProperty: 'key', keyRenderer: countCellRendered, keyStyling: keyStyling }}
                    />
                ) : (
                    <Group>
                        <Text color="dimmed">No data</Text>
                    </Group>
                )}
            </WhiteCard>
        </>
    );
}

function TagActivity({ model }: { model: MapDashboardModel }) {
    let { field } = useNav().getData('field');
    const dateField = field === 'BilledDate' ? 'BilledDate' : 'UsageStartDate';
    const homeModel = useDi(MapContractHomeModel);
    const fmtSvc = useDi(FormatService);
    const { loading, data } = useTileLoader(model.dateRange, async (from, to) => {
        const untaggedTask = queryBuilder<BaseMapResource>()
            .where((b) => model.querySvc.getMgmtAccountQuery())
            .select((b) => ({
                untagged: b.countIf(model.querySvc.getUndecidedQuery()),
                count: b.count(),
            }))
            .execute(postResourcesQuery);

        let valueTask: Promise<QueryResult<{ cost: number; count: number }>>;
        valueTask = queryBuilder<IDailyRollup>()
            .where((b) =>
                b.and(
                    b.model['product/servicecode']!.eq(model.querySvc.validServiceCodes),
                    b.model['bill/PayerAccountId']!.eq(model.contract.AccountIds ?? []),
                    b.model[dateField]!.onOrAfter(from ?? model.querySvc.contractFrom),
                    b.model[dateField]!.onOrBefore(to ?? new Date()),
                    b.model['resourceTags/user:map-migrated']!.isNull()
                )
            )
            .select((b) => ({
                cost: b.sum(b.model['lineItem/UnblendedCost'] as unknown as number),
                count: b.count(),
            }))
            .execute((q) =>
                postDailyRollupQuery(q, {
                    from: fmtSvc.toJsonShortDate(from ?? model.querySvc.contractFrom),
                    to: fmtSvc.toJsonShortDate(to ?? new Date()),
                })
            );

        const accounts = homeModel.accounts.map((acc) => acc.AwsAccountId ?? '');
        const tagTrendTask = queryBuilder<ResourceChange>()
            .where((b) => b.and(b.model.Keywords!.eq(['map-migrated']), b.model.AccountId!.eq(accounts)))
            .select((b) => ({
                date: b.truncDate('day', b.model.TimeStamp as unknown as Date, -fmtSvc.getTzOffsetHours(), from, to),
                tags: b.count(),
            }))
            .execute(postResourceChangedQueryResourceChangeLog);

        const [untagged, value, tagTrend] = await Promise.all([untaggedTask, valueTask, tagTrendTask]);

        return {
            untagged: untagged.Results?.[0] ?? { untagged: 0, count: 0 },
            value: value.Results?.[0] ?? { cost: 0, count: 0 },
            tagTrend: (tagTrend.Results ?? []).map((t) => ({ date: fmtSvc.toShortDate(t.date), tags: t.tags })),
        };
    });
    return (
        <Group position="center" sx={{ height: '300px' }}>
            {loading ? (
                <Loader />
            ) : !data?.tagTrend ? (
                <Text italic color="dimmed">
                    No Data
                </Text>
            ) : (
                <BarChart
                    settings={{ margin: { bottom: 80, top: 20, left: 40, right: 0 }, enableLabel: false }}
                    data={data.tagTrend}
                    groups={['date']}
                    values={['tags']}
                />
            )}
        </Group>
    );
}

function FirstRow({ model }: { model: MapDashboardModel }) {
    const fmtSvc = useDi(FormatService);
    const theme = useMantineTheme();
    const { loading, data } = useTileLoader(model.dateRange, async (from, to) => {
        const resources = await queryBuilder<BaseMapResource>()
            .where((b) => b.and(model.querySvc.getMgmtAccountQuery()))
            .select((b) => ({
                anyData: b.count(),
                tagged: b.countIf(model.querySvc.getCorrectlyTaggedQuery()),
                untagged: b.countIf(model.querySvc.getUndecidedQuery()),
                incorrect: b.countIf(model.querySvc.getIncorrectlyTaggedQuery()),
            }))
            .execute(postResourcesQuery);

        const { tagged, untagged, incorrect, anyData } = resources?.Results?.[0] ?? { tagged: 0, untagged: 0, incorrect: 0, anyData: 0 };
        const total = tagged + untagged + incorrect;
        const taggedPct = total ? tagged / total : 0;
        const untaggedPct = total ? untagged / total : 0;
        const incorrectPct = total ? incorrect / total : 0;

        return { taggedPct, untaggedPct, incorrectPct, anyData, tagged, untagged, incorrect };
    });

    return (
        <ThreeColRow>
            <GrayCard>
                <Text weight="bold">Current Tag Coverage</Text>
                <Group sx={{ height: 200 }}>
                    {loading ? (
                        <Loader />
                    ) : !data?.anyData ? (
                        <Text italic color="dimmed">
                            No Data
                        </Text>
                    ) : (
                        <PieChart
                            data={[
                                { label: 'Tagged Incorrectly', value: data.incorrectPct },
                                { label: 'Not Tagged', value: 0.5 },
                                { label: 'Tagged Correctly', value: 0.2 },
                            ]}
                            groups={['label']}
                            values={['value']}
                            settings={{
                                valueFormat: ' ^-.0~%',
                                chartColors: ['#F97066', '#009FE1', '#12B76A'],
                                enableArcLinkLabels: false,
                                enableArcLabels: false,
                                legend: [{ anchor: 'bottom', translateY: 70, itemWidth: 130, direction: 'column', itemHeight: 18 }],
                                margin: { left: 0, bottom: 70, top: 10, right: 0 },
                                showTotal: true,
                                total: fmtSvc.formatInt(data.incorrect + data.untagged + data.tagged),
                                totalLabelPosition: 'bottom',
                                totalLabel: 'Resources',
                            }}
                        />
                    )}
                </Group>
            </GrayCard>
            <GrayCard>
                <Text weight="bold">Resource Summary</Text>
                <Group sx={{ height: 200 }}>
                    {loading ? (
                        <Loader />
                    ) : !data?.anyData ? (
                        <Text italic color="dimmed">
                            No Data
                        </Text>
                    ) : (
                        <PieChart
                            data={[
                                { label: 'MAP Eligible', value: data.incorrectPct },
                                { label: 'Not Map Eligible', value: data.untaggedPct },
                            ]}
                            groups={['label']}
                            values={['value']}
                            settings={{
                                valueFormat: ' ^-.0~%',
                                chartColors: ['#0075c1', '#d0d5dd'],
                                enableArcLinkLabels: false,
                                enableArcLabels: false,
                                legend: [
                                    { anchor: 'bottom-left', translateX: -25, translateY: 60, direction: 'column', itemWidth: 18, itemHeight: 18 },
                                ],
                                margin: { left: 20, bottom: 60, top: 20, right: 20 },
                                showTotal: false,
                            }}
                        />
                    )}
                </Group>
            </GrayCard>
            <GrayCard>
                <Text weight="bold">Tag Activity</Text>
                <Text sx={{ color: theme.colors.gray[5] }}>Number of new tags added to eligible resources</Text>
                <TagActivity model={model} />
            </GrayCard>
        </ThreeColRow>
    );
}

function ResourceCoverageByValue({ model }: { model: MapDashboardModel }) {
    let { field } = useNav().getData('field');
    const dateField = field === 'BilledDate' ? 'BilledDate' : 'UsageStartDate';
    const fmtSvc = useDi(FormatService);
    const { loading, data } = useTileLoader(model.dateRange, async (from, to) => {
        const queryApi = (q: Query) =>
            postDailyRollupQuery(q, {
                from: fmtSvc.toJsonShortDate(from ?? model.querySvc.contractFrom),
                to: fmtSvc.toJsonShortDate(to ?? new Date()),
            });
        var queryTasks: [
            Promise<QueryResult<{ potential: number; count: number }>>,
            Promise<QueryResult<{ value: number; count: number }>>,
            Promise<QueryResult<{ date: Date; amount: number }>>
        ];
        queryTasks = [
            queryBuilder<IDailyRollup>()
                .where((b) =>
                    b.and(
                        b.model['bill/PayerAccountId']!.eq(model.contract.AccountIds ?? []),
                        b.model[dateField]!.onOrAfter(from ?? model.querySvc.contractFrom),
                        b.model[dateField]!.onOrBefore(to ?? new Date()),
                        b.model['product/servicecode']!.eq(model.querySvc.validServiceCodes)
                    )
                )
                .select((b) => ({
                    potential: b.sum(b.model['lineItem/UnblendedCost'] as unknown as number),
                    count: b.count(),
                }))
                .execute(queryApi),
            queryBuilder<IDailyRollup>()
                .where((b) =>
                    b.and(
                        b.model['bill/PayerAccountId']!.eq(model.contract.AccountIds ?? []),
                        b.model[dateField]!.onOrAfter(from ?? model.querySvc.contractFrom),
                        b.model[dateField]!.onOrBefore(to ?? new Date()),
                        b.model['product/servicecode']!.eq(model.querySvc.validServiceCodes),
                        b.model['resourceTags/user:map-migrated']!.eq(model.querySvc.validTagOptions)
                    )
                )
                .select((b) => ({
                    value: b.sum(b.model['lineItem/UnblendedCost'] as unknown as number),
                    count: b.count(),
                }))
                .execute(queryApi),
            queryBuilder<IDailyRollup>()
                .where((b) =>
                    b.and(
                        b.model['bill/PayerAccountId']!.eq(model.contract.AccountIds ?? []),
                        b.model[dateField]!.onOrAfter(from ?? model.querySvc.contractFrom),
                        b.model[dateField]!.onOrBefore(to ?? new Date()),
                        b.model['product/servicecode']!.eq(model.querySvc.validServiceCodes),
                        b.model['resourceTags/user:map-migrated']!.eq(model.querySvc.validTagOptions)
                    )
                )
                .select((b) => ({
                    date: b.truncDate('day', b.model[dateField]! as unknown as Date, -fmtSvc.getTzOffsetHours(), from, to),
                    amount: b.sum(b.model['lineItem/UnblendedCost'] as unknown as number),
                }))
                .execute(queryApi),
        ];

        const [potential, value, trend] = await Promise.all(queryTasks);

        return {
            potential: potential?.Results?.[0] ?? null,
            tagged: value?.Results?.[0] ?? null,
            trend: (trend?.Results ?? []).map((t) => ({ date: fmtSvc.toShortDate(t.date), amount: t.amount })),
        };
    });
    return (
        <ThreeColRow>
            <GrayCard>
                <Text>By Cost</Text>
                <Group position="center">
                    <Score
                        loading={loading}
                        title="Resources"
                        titlePos="bottom"
                        value={data?.potential?.count ? fmtSvc.formatMoneyNoDecimals(data?.potential.potential) : 'N/A'}
                        helpText={<>Total cost of resources eligible under this contract</>}
                    />
                </Group>
            </GrayCard>
            <GrayCard>
                <Text>By Cost - Percentage</Text>
                <Group position="center" sx={{ height: 200 }}>
                    {loading ? (
                        <Loader />
                    ) : !data?.potential?.count ? (
                        <Text italic color="dimmed">
                            No Data
                        </Text>
                    ) : (
                        <PieChart
                            data={[
                                { label: 'Tagged', value: !data.potential?.potential ? 0 : (data.tagged?.value ?? 0) / data.potential?.potential },
                                {
                                    label: 'Untagged',
                                    value: !data.potential?.potential
                                        ? 0
                                        : (data.potential?.potential - (data.tagged?.value ?? 0)) / data.potential?.potential,
                                },
                            ]}
                            groups={['label']}
                            values={['value']}
                            settings={{ valueFormat: ' ^-.2~%' }}
                        />
                    )}
                </Group>
            </GrayCard>
            <GrayCard>
                <Text>By Cost - Trend</Text>
                <Group position="center" sx={{ height: 200 }}>
                    {loading ? (
                        <Loader />
                    ) : !data?.trend.length ? (
                        <Text italic color="dimmed">
                            No Data
                        </Text>
                    ) : (
                        <LineChart data={data.trend} groups={['date']} values={['amount']} />
                    )}
                </Group>
            </GrayCard>
        </ThreeColRow>
    );
}

function NewResources({ model }: { model: MapDashboardModel }) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const { loading, data } = useTileLoader(model.dateRange, async (from, to) => {
        const results = await queryBuilder<BaseMapResource>()
            .where((b) =>
                b.and(
                    model.querySvc.getMgmtAccountQuery(),
                    b.model.CreateDate.onOrAfter(from ?? model.querySvc.contractFrom),
                    b.model.CreateDate.onOrBefore(to ?? new Date())
                )
            )
            .select((b) => ({
                count: b.count(),
                date: b.truncDate('day', b.model.CreateDate, -fmtSvc.getTzOffsetHours(), from, to),
            }))
            .execute(postResourcesQuery);
        const days = differenceInDays(model.querySvc.contractTo, new Date());

        return { trend: results.Results?.map((r) => ({ Added: r.count, date: fmtSvc.toShortDate(r.date) })) ?? [], days: Math.max(0, days) };
    });
    return (
        <TwoColRow>
            <Box sx={{ height: '300px' }}>
                {loading ? (
                    <Group position="center">
                        <Loader />
                    </Group>
                ) : data?.trend.length ? (
                    <BarChart
                        data={data?.trend ?? []}
                        groups={['date']}
                        values={['Added']}
                        settings={{ margin: { top: 20, bottom: 70, left: 40, right: 0 } }}
                    />
                ) : (
                    <Group>
                        <Text color="dimmed">No data</Text>
                    </Group>
                )}
            </Box>
            <GrayCard>
                <Text mb="xs">Time Remaining on Contract</Text>
                <Group sx={{ background: theme.colors.gray[1], borderRadius: theme.radius.sm }} position="center" align="center">
                    <Score title="Days" titlePos="bottom" value={fmtSvc.formatInt(data?.days ?? 0)} loading={loading} />
                </Group>
            </GrayCard>
        </TwoColRow>
    );
}

export function useTileLoader<T>(dateRange: EventEmitter<{ from?: Date; to?: Date }>, fetch: (from?: Date, to?: Date) => Promise<T>) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<T>();
    const load = useCallback(async () => {
        setLoading(true);
        try {
            setData(await fetch(dateRange.value.from, dateRange.value.to));
        } finally {
            setLoading(false);
        }
    }, [dateRange, fetch]);
    useEffect(() => {
        load();
    }, []);
    useEvent(dateRange, load);
    return { loading, data };
}

function SectionHeader({ text }: { text: string }) {
    return (
        <Title order={4} my="md">
            {text}
        </Title>
    );
}

const ThreeColRow = styled.div`
    display: grid;
    grid-template-columns: 300px 300px auto;
    align-items: stretch;
    column-gap: ${(p) => p.theme.spacing.md}px;
`;

const TwoColRow = styled.div`
    display: grid;
    grid-template-columns: auto 300px;
    align-items: stretch;
    column-gap: ${(p) => p.theme.spacing.md}px;
`;

function GrayCard({ children }: { children: ReactNode }) {
    return (
        <Card component={GrayCardEl} radius="md">
            {children}
        </Card>
    );
}

export function WhiteCard({ children }: { children: ReactNode }) {
    return (
        <Card component={WhiteCardEl} radius="md">
            {children}
        </Card>
    );
}

const GrayCardEl = styled.div`
    background: ${(p) => p.theme.colors.gray[2]};
    display: grid;
    grid-template-rows: min-content auto;
`;

const WhiteCardEl = styled.div`
    display: grid;
    grid-template-rows: min-content auto;
`;

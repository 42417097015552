import styled from '@emotion/styled';
import { useMemo } from 'react';

const spaceRegex = /^\s+|\s+$|\s{2,}/g;
export function VisibleSpaces({ value }: { value: string }) {
    const fragments = useMemo(() => {
        return value.replace(spaceRegex, (v) => v.replaceAll(/\s/g, '\0')).split('\0');
    }, [value]);

    return <>{fragments.map((frag, i) => (frag === '' ? <VisibleSpace key={i} /> : frag))}</>;
}

export function VisibleSpace() {
    return <VisibleSpaceEl>⎵</VisibleSpaceEl>;
}

export const VisibleSpaceEl = styled.span`
    background: #0001;
    padding: 2px;
    display: inline-block;
    height: 15px;
    line-height: 10px;
    border-radius: 1px;
    color: #889273;
    box-sizing: border-box;
    margin-right: 2px;
`;

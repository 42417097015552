import {
    AllocationDimensionDimensionType,
    InvoiceAllocationRule,
    InvoiceAllocationRuleStatus,
    InvoiceTaggingRule,
    InvoiceTaggingRuleRuleType,
    InvoiceTaggingRuleStatus,
} from '@apis/Invoices/model';
import styled from '@emotion/styled';
import {
    Anchor,
    Box,
    Button,
    Card,
    Center,
    Divider,
    Grid,
    Group,
    Space,
    Text,
    ThemeIcon,
    UnstyledButton,
    createStyles,
    useMantineTheme,
    Stack,
    Tooltip,
    Title,
    Switch,
} from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue, useToggle } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { useCallback, useEffect, useState } from 'react';
import { useNav } from '@root/Services/NavigationService';
import { CustomColors } from '@root/Design/Themes';
import { ShowbackModel } from './Models';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { Check, ChevronRight, Circle, CircleDashed, Clock, ClockHour10, GripVertical, Plus, Square, Trash } from 'tabler-icons-react';
import { FillerSwitch } from '@root/Design/Filler';
import { RuleStatusIcon } from './Components/Design';
import { ShowbackEvents, ShowbackPersistence } from '@root/Services/Invoices/ShowbackService';
import { openConfirmModal } from '@mantine/modals';
import { FilterItem, FilterToken } from '@root/Components/Filter/Design';
import { useAsync } from '@react-hookz/web';
import { addHours, differenceInHours } from 'date-fns';
import { postInvoiceRuleProcessInvoiceData } from '@apis/Invoices';

interface RuleSummaryStats {
    unallocatedResourceCharges: number;
    supportCharges: number;
    discountCharges: number;
    marketPlaceCharges: number;
    mapProgramCharges: number;
}

function CostAllocationSummary({ model }: { model: ShowbackModel }) {
    const selectedMonth = useEventValue(model.selectedMonthChanged)!;
    const [{ status, result: stats }, statActions] = useAsync(async () => {
        const results = await model.getAllocationStats();
        if (!results?.Results?.length) {
            return undefined;
        }
        const {
            unallocatedUntagged,
            discountSp,
            discountRi,
            discountCredits,
            chargeSupport,
            chargeFees,
            upfrontRiFees,
            upfrontSpFees,
            chargeMarketplace,
        } = results.Results[0];
        return {
            unallocatedUntagged,
            discounts: discountSp + discountRi + upfrontSpFees + upfrontRiFees,
            discountCredits,
            chargeSupport,
            chargeFees,
            chargeMarketplace,
        };
    });
    useEffect(() => {
        statActions.execute();
    }, [model.selectedAllocationDimension, selectedMonth]);

    const navSvc = useNav();
    const addRule = (ruleType: InvoiceTaggingRuleRuleType) => {
        model.openRuleEditor(navSvc, undefined, ruleType);
    };
    return (
        <Card py="md" px="lg" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} withBorder radius="md" shadow="xs">
            <Text size={'lg'}>Costs to Allocate</Text>
            <Box sx={{ flex: 1 }}>
                <FillerSwitch loading={status === 'loading'} noData={!stats}>
                    {() => (
                        <Stack spacing={4} mt="sm">
                            {model.selectedAllocationDimension?.DimensionType === AllocationDimensionDimensionType.CostAllocationTag ||
                            model.selectedAllocationDimension?.DimensionType === AllocationDimensionDimensionType.IntegrationDatapoint ? (
                                <RuleSummaryCtaCard
                                    type={InvoiceTaggingRuleRuleType.UntaggedCosts}
                                    value={stats!.unallocatedUntagged}
                                    title={'Untagged Costs'}
                                    addRule={addRule}
                                />
                            ) : null}
                            <RuleSummaryCtaCard
                                type={InvoiceTaggingRuleRuleType.Discounts}
                                value={stats!.discounts}
                                title={'Discounts & Commitments'}
                                addRule={addRule}
                            />
                            <RuleSummaryCtaCard
                                type={InvoiceTaggingRuleRuleType.SharedCosts}
                                value={stats!.discountCredits}
                                title={'Credits'}
                                addRule={addRule}
                            />
                            <RuleSummaryCtaCard
                                type={InvoiceTaggingRuleRuleType.SharedCosts}
                                value={stats!.chargeSupport}
                                title={'Support'}
                                addRule={addRule}
                            />
                            <RuleSummaryCtaCard
                                type={InvoiceTaggingRuleRuleType.SharedCosts}
                                value={stats!.chargeFees}
                                title={'Fees'}
                                addRule={addRule}
                            />
                            <RuleSummaryCtaCard
                                type={InvoiceTaggingRuleRuleType.Marketplace}
                                value={stats!.chargeMarketplace}
                                title={'Marketplace Charges'}
                                addRule={addRule}
                            />
                        </Stack>
                    )}
                </FillerSwitch>
            </Box>
        </Card>
    );
}

enum KnownRollupTypes {
    BasicDailyRollupIndexer = 'BasicDailyRollupIndexer',
    DailySpendByResourceId = 'DailySpendByResourceId',
    BasicMonthlyRollupIndexer = 'BasicMonthlyRollupIndexer',
}

type InvoiceRule = InvoiceAllocationRule | InvoiceTaggingRule;

export function AllocationRules({ model }: { model: ShowbackModel }) {
    const theme = useMantineTheme();

    useEvent(model.rulesChanged);

    return (
        <Stack spacing={0} sx={{ height: '100%', background: theme.colors.gray[2], overflow: 'hidden' }}>
            <Group noWrap m={0} sx={{ height: '100%', flex: 1, minHeight: 0 }}>
                <Box p="lg" pr={0} sx={{ height: '100%', minWidth: 400 }}>
                    <CostAllocationSummary model={model} />
                </Box>
                <Box p="lg" pl={0} sx={{ height: '100%', overflow: 'auto', flex: 1 }}>
                    <AllocationRuleLists model={model} />
                </Box>
            </Group>
        </Stack>
    );
}

function AllocationRuleLists({ model }: { model: ShowbackModel }) {
    const theme = useMantineTheme();
    const modelLoading = useEventValue(model.loading);
    const showbackSvc = useDi(ShowbackPersistence);
    const selectedAllocDim = model.selectedAllocationDimension;
    const [showingInactive, { open: showInactive, toggle: toggleInactive }] = useToggle(false);
    const showbackEvts = useDi(ShowbackEvents);
    const fmtSvc = useDi(FormatService);
    const onNewRuleSaved = useCallback(
        (rule?: { Status: InvoiceTaggingRuleStatus | InvoiceAllocationRuleStatus }) => {
            if (rule?.Status !== 'Active') {
                showInactive();
            }
        },
        [showInactive]
    );

    useEvent(showbackEvts.invoiceRuleUpdated, onNewRuleSaved);

    const saving = useEventValue(model.saving);
    const rulesChanged = model.rulesChanged;

    const navSvc = useNav();
    const addTagRule = useCallback(() => {
        model.openRuleEditor(navSvc, undefined, 'UntaggedCosts');
    }, [model]);
    const addAllocationRule = useCallback(() => {
        model.openRuleEditor(navSvc, undefined, 'SharedCosts');
    }, [model]);

    const editRule = useCallback(
        async (rule: InvoiceRule, remove?: boolean) => {
            if (remove) {
                rule.IsDeleted = true;
                await model.saveChanges();
                rulesChanged.emit();
            } else {
                model.openRuleEditor(navSvc, rule.Id, rule.RuleType);
            }
        },
        [model, selectedAllocDim]
    );

    const handleTagRuleListChange = useCallback(
        async (rules: InvoiceTaggingRule[]) => {
            if (selectedAllocDim) {
                selectedAllocDim.TaggingRules = ShowbackModel.sortRules(rules);
                await model.saveChanges();
                rulesChanged.emit();
            }
        },
        [selectedAllocDim, rulesChanged]
    );
    const handleAllocRuleListChange = useCallback(
        async (rules: InvoiceAllocationRule[]) => {
            if (selectedAllocDim) {
                selectedAllocDim.AllocationRules = ShowbackModel.sortRules(rules);
                await model.saveChanges();
                rulesChanged.emit();
            }
        },
        [selectedAllocDim, rulesChanged]
    );

    const taggingRules = selectedAllocDim?.TaggingRules ?? [];
    const allocationRules = selectedAllocDim?.AllocationRules ?? [];

    const report = useEventValue(model.processingReport);
    const lastFail = report?.lastFinished?.LastFailure;
    const lastSuccess = report?.lastFinished?.LastSuccess;
    const lastFinish = lastFail ?? lastSuccess ?? null;
    const lastSuccessLbl = lastSuccess ? fmtSvc.formatDatetimeNoSecs(lastSuccess) : 'Unknown';
    const nextRun = model.getNextProcessingRun();
    const nextRunLbl = nextRun ? fmtSvc.formatDatetimeNoSecs(nextRun) : 'Unknown';

    const [processingRequestedAt, setProcessingRequestedAt] = useState<Date>();
    const runRules = useCallback(async () => {
        setProcessingRequestedAt(new Date());
        postInvoiceRuleProcessInvoiceData({ month: fmtSvc.toJsonShortDate(model.selectedMonthChanged.value!) });
    }, []);
    const processingStarted = report?.lastStarted?.InProgress;
    const isInProgress = processingStarted && (!lastFinish || lastFinish < processingStarted);
    const isInProgressOrRequested = processingRequestedAt || isInProgress;
    const processingStartedLbl = processingRequestedAt
        ? fmtSvc.formatDatetimeNoSecs(processingRequestedAt)
        : processingStarted
        ? fmtSvc.formatDatetimeNoSecs(processingStarted)
        : '';
    useEffect(() => {
        if (isInProgressOrRequested) {
            if (isInProgress) {
                setProcessingRequestedAt(undefined);
            }
            const interval = setTimeout(() => model.updateProcessingReport(), 10000);
            return () => clearTimeout(interval);
        }
    }, [report, processingRequestedAt]);

    return (
        <FillerSwitch loading={modelLoading && !model.selectedAllocationDimension}>
            {() => (
                <>
                    <Box py={12} px={16} sx={{ backgroundColor: `#E3EDFD`, borderRadius: 10 }}>
                        <Group position="apart" align="stretch">
                            {isInProgressOrRequested ? (
                                <>
                                    <ShowbackTimeCard title="Processing Started At" time={processingStartedLbl}></ShowbackTimeCard>
                                    <Divider my={-12} orientation="vertical" color="gray.0" />
                                </>
                            ) : (
                                <>
                                    <ShowbackTimeCard title={'Last Processed At'} time={lastSuccessLbl}></ShowbackTimeCard>
                                    <Divider my={-12} orientation="vertical" color="gray.0" />
                                    <ShowbackTimeCard title={'Estimated Next Run'} time={nextRunLbl}></ShowbackTimeCard>
                                    <Divider my={-12} orientation="vertical" color="gray.0" />
                                </>
                            )}
                            <Tooltip label="Processing in progress..." disabled={!isInProgressOrRequested}>
                                <Button disabled={!!isInProgressOrRequested} onClick={runRules}>
                                    Process Rules Now
                                </Button>
                            </Tooltip>
                            <Group position="right" sx={{ flex: 1 }}>
                                <Switch
                                    size="xs"
                                    checked={showingInactive}
                                    onClick={toggleInactive}
                                    label={
                                        <Text span size="sm">
                                            Show inactive
                                        </Text>
                                    }
                                />
                            </Group>
                        </Group>
                    </Box>
                    <Space h="lg" />
                    <ShowbackAllocationRuleContainer
                        imgUrl="/assets/showback/icon-costallocation.png"
                        title="Cost Allocation Tag Rules"
                        description={`Use rules to apply ${showbackSvc.getDimensionName(
                            model.selectedAllocationDimension
                        )} values to invoice line items`}
                        onChange={handleTagRuleListChange}
                        onAdd={addTagRule}
                        onEdit={editRule}
                        changeEvt={rulesChanged}
                        showInactive={showingInactive}
                        rules={taggingRules}
                        lastSuccessRunDate={lastFinish}
                    />
                    <Space h="lg" />
                    <ShowbackAllocationRuleContainer
                        imgUrl="/assets/showback/icon-showbackallocation.png"
                        title="Showback Allocation Rules"
                        description="Use rules to distribute costs"
                        onChange={handleAllocRuleListChange}
                        onAdd={addAllocationRule}
                        onEdit={editRule}
                        changeEvt={rulesChanged}
                        showInactive={showingInactive}
                        rules={allocationRules}
                        lastSuccessRunDate={lastFinish}
                    />
                </>
            )}
        </FillerSwitch>
    );
}

function ShowbackTimeCard({ title, time }: { title: string; time: string }) {
    const theme = useMantineTheme();
    return (
        <Box>
            <Text size="xs" weight="400" sx={{ color: theme.colors.gray[5] }}>
                {title}
            </Text>
            <Text weight="400" style={{ display: 'flex', alignItems: 'center', color: theme.colors.gray[6] }}>
                <Clock size="15px" style={{ marginRight: '5px' }}></Clock> {time}
            </Text>
        </Box>
    );
}

function ShowbackAllocationRuleContainer<TRuleType extends InvoiceRule>(props: {
    onAdd: () => void;
    onEdit: (rule: TRuleType, remove?: boolean) => void;
    rules?: TRuleType[];
    onChange: (rules: TRuleType[]) => void;
    changeEvt: EventEmitter<void>;
    title: string;
    description: string;
    showInactive: boolean;
    imgUrl: string;
    lastSuccessRunDate: Date | null;
}) {
    const theme = useMantineTheme();

    const { changeEvt, onChange, onAdd, onEdit, title, description, imgUrl } = props;
    const rules = (props.rules?.filter((r) => !r.IsDeleted && (props.showInactive || r.Status === 'Active')) ?? []).slice();
    useEvent(changeEvt);

    return (
        <>
            <Group ml="xs">
                <Box>
                    <img src={imgUrl} alt={title} height={50} />
                </Box>
                <Box style={{ flex: 1 }}>
                    <Text size="lg">{title}</Text>
                    <Divider />
                    <Text color="gray.5">{description}</Text>
                </Box>
            </Group>
            <Space h="sm" />
            {rules && rules.length > 0 ? (
                <ShowBackAllocationRuleCardList onChange={onChange} onEdit={onEdit} rules={rules} lastSuccessfulRunDate={props.lastSuccessRunDate} />
            ) : null}
            {rules && rules.length === 0 ? (
                <Card radius="lg" mb="lg" withBorder sx={{ borderStyle: 'dashed', borderWidth: '2px' }}>
                    <Text color="dimmed" italic>
                        No rules created. Click + Add Rule to create a rule.
                    </Text>
                </Card>
            ) : null}
            <Center>
                <Button
                    radius="xl"
                    variant="white"
                    sx={{
                        '&:hover': {
                            backgroundColor: theme.colors.primary[6],
                            color: '#fff',
                        },
                    }}
                    onClick={onAdd}
                    leftIcon={<Plus />}
                >
                    Add Rule
                </Button>
            </Center>
        </>
    );
}

function ShowBackAllocationRuleCardList<TRuleType extends InvoiceRule>({
    onEdit,
    rules,
    onChange,
    lastSuccessfulRunDate,
}: {
    onEdit: (rule: TRuleType, remove?: boolean) => void;
    rules: TRuleType[];
    onChange: (rules: TRuleType[]) => void;
    lastSuccessfulRunDate: Date | null;
}) {
    const handleDragEnd: OnDragEndResponder = useCallback(
        ({ destination, source: { index: startIdx } }) => {
            const endIdx = destination?.index;
            if (typeof endIdx === 'number') {
                const [removed] = rules.splice(startIdx, 1);
                rules.splice(endIdx, 0, removed);
                let order = 0;
                for (const rule of rules) {
                    if (!rule.IsDeleted) {
                        rule.Order = ++order;
                    }
                }
                onChange(rules);
            }
        },
        [rules]
    );

    const items = rules.map((rule, index) => {
        return <ShowBackRuleCard onEdit={onEdit} rule={rule} index={index} lastSuccessfulRunDate={lastSuccessfulRunDate} />;
    });

    return (
        <>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="dnd-list" direction="vertical">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {items}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
}

function RuleSummaryCtaCard({
    value,
    title,
    type,
    addRule,
}: {
    value: number;
    title: string;
    type: InvoiceTaggingRuleRuleType;
    addRule: (selectedType: InvoiceTaggingRuleRuleType) => void;
}) {
    const theme = useMantineTheme();
    const formatSvc = useDi(FormatService);
    const handleClick = useCallback(() => (value === 0 ? null : addRule(type)), [type, addRule]);
    return (
        <>
            <UnstyledButton component={CtaCard} py="sm" px="md" onClick={handleClick} state={(value ?? 0) === 0 ? 'finished' : 'unfinished'}>
                <div>
                    <Text size="sm" color="dimmed">
                        {title}
                    </Text>
                    <Text
                        sx={{
                            color: (value ?? 0) === 0 ? theme.colors.success[5] : 'inherit',
                            fontWeight: 'bold',
                        }}
                        size={'lg'}
                    >
                        {!value || value === 0 ? '$0' : formatSvc.formatMoneyNoDecimals(value ?? 0)}
                    </Text>
                </div>
                {(value ?? 0) === 0 ? (
                    <Check size={24} style={{ marginTop: '10px', float: 'right', color: theme.colors.success[6] }} />
                ) : (
                    <Group spacing={4}>
                        <Text color="primary.6" size={'sm'}>
                            Allocate
                        </Text>
                        <ChevronRight stroke={theme.colors.primary[6]} />
                    </Group>
                )}
            </UnstyledButton>
        </>
    );
}

function ShowBackRuleCard<TRuleType extends InvoiceAllocationRule | InvoiceTaggingRule>({
    rule,
    index,
    onEdit,
    lastSuccessfulRunDate,
}: {
    rule: TRuleType;
    index: number;
    onEdit: (rule: TRuleType, remove?: boolean) => void;
    lastSuccessfulRunDate: Date | null;
}) {
    const theme = useMantineTheme();
    const formatSvc = useDi(FormatService);
    const { classes, cx } = useStyles();

    const getStatusColor = (ruleStatus?: InvoiceTaggingRuleStatus) => {
        switch (ruleStatus) {
            case InvoiceTaggingRuleStatus.Active:
                return theme.colors?.success?.[6];
            case InvoiceTaggingRuleStatus.Draft:
                return theme.colors?.success?.[6];
            case InvoiceTaggingRuleStatus.Test:
                return theme.colors?.warning?.[6];
            default:
                return theme.colors?.gray?.[6];
        }
    };

    const getRuleTypeText = (rt?: InvoiceTaggingRuleRuleType) => {
        switch (rt) {
            case InvoiceTaggingRuleRuleType.UntaggedCosts:
                return 'Untagged Costs';
            case InvoiceTaggingRuleRuleType.SharedCosts:
                return 'Shared Costs';
            case InvoiceTaggingRuleRuleType.Discounts:
                return 'Discounts';
            case InvoiceTaggingRuleRuleType.Marketplace:
                return 'Marketplace';
            case InvoiceTaggingRuleRuleType.Advanced:
                return 'Advanced';
            default:
                return 'No Rule Type';
        }
    };

    const getStatusIcon = (ruleStatus?: InvoiceTaggingRuleStatus) => {
        switch (ruleStatus) {
            case InvoiceTaggingRuleStatus.Active:
                return <Circle size={'24px'} style={{ color: getStatusColor(rule.Status) }} />;
            case InvoiceTaggingRuleStatus.Draft:
                return <CircleDashed size={'24px'} style={{ color: getStatusColor(rule.Status) }} />;
            case InvoiceTaggingRuleStatus.Test:
                return <ClockHour10 size={'24px'} style={{ color: getStatusColor(rule.Status) }} />;
            default:
                return <Square size={'24px'} style={{ color: getStatusColor(rule.Status) }} />;
        }
    };

    const handleDelete = useCallback(async () => {
        await new Promise<void>((confirm) =>
            openConfirmModal({
                title: <Title order={4}>Confirm Delete</Title>,
                children: (
                    <Text>
                        Are you sure you want to delete allocation rule "<strong>{rule.Name}</strong>"?
                    </Text>
                ),
                centered: true,
                labels: { confirm: 'Delete Rule', cancel: 'Cancel' },
                onConfirm: confirm,
                zIndex: 10000,
            })
        );
        onEdit(rule, true);
    }, [rule, onEdit]);

    const modifiedAt = rule.ModifiedAt;
    const modifiedAtDate = formatSvc.toLocalDate(modifiedAt);

    const modifiedAtDateFormatted = formatSvc.formatDatetimeNoSecs(modifiedAtDate);
    const showModifiedDate = rule.Status === 'Active' && lastSuccessfulRunDate && modifiedAtDate && modifiedAtDate > lastSuccessfulRunDate;

    return !rule ? null : (
        <Draggable key={rule.Id} index={index} draggableId={rule.Id?.toString() ?? ''}>
            {(provided, snapshot) => {
                return (
                    <Card
                        withBorder
                        shadow="xs"
                        mb="sm"
                        className={cx({ [classes.itemDragging]: snapshot.isDragging })}
                        ref={provided.innerRef}
                        sx={{ position: 'static', background: rule.Status === 'Active' ? '#fff' : theme.colors.gray[1] }}
                        p={0}
                        radius="md"
                        {...provided.draggableProps}
                    >
                        <RuleListItemLayout>
                            <Group noWrap pr="md" spacing={0} position="left">
                                <ThemeIcon
                                    size="xl"
                                    color={(rule.Status === 'Active' ? '#fff' : theme.colors.gray[1]) as CustomColors}
                                    {...provided.dragHandleProps}
                                >
                                    <GripVertical color={theme.colors?.primary?.[6] as CustomColors} />
                                </ThemeIcon>

                                <Box
                                    sx={{
                                        color: rule.Status === 'Active' ? theme.colors.primary[6] : theme.colors.gray[6],
                                        backgroundColor: rule.Status === 'Active' ? theme.colors.primary[2] : theme.colors.gray[3],
                                        padding: '5px 12px',
                                        borderRadius: '10%',
                                    }}
                                >
                                    {formatSvc.formatInt(index + 1)}
                                </Box>
                            </Group>

                            <Divider color="gray.3" orientation="vertical" />

                            <Text px="md" size="sm">
                                {getRuleTypeText(rule.RuleType)}
                            </Text>

                            <Divider color="gray.3" orientation="vertical" />

                            <UnstyledButton onClick={() => onEdit(rule)} px="md" py="sm">
                                <Group position="apart">
                                    <Box>
                                        <Text color="primary.6" size="sm" weight="bold">
                                            {rule.Name}
                                        </Text>
                                        <Text sx={{}} color="gray.5" span size="xs">
                                            {rule.RuleDescription}
                                        </Text>
                                    </Box>
                                    {showModifiedDate ? (
                                        <Box>
                                            <Text color="warning.6" size="sm" weight="bold">
                                                MODIFIED
                                            </Text>
                                            <Text sx={{}} color="gray.5" span size="xs">
                                                {modifiedAtDateFormatted}
                                            </Text>
                                        </Box>
                                    ) : null}
                                </Group>
                            </UnstyledButton>

                            <Divider color="gray.3" orientation="vertical" />
                            <Group spacing={6} position="center">
                                <RuleStatusIcon active={rule.Status === 'Active'} />
                                <Text size="sm">{rule.Status === 'Active' ? 'Active' : 'Inactive'}</Text>
                            </Group>

                            <Divider color="gray.3" orientation="vertical" />
                            <Center>
                                <Tooltip label="Delete Rule" withinPortal>
                                    <Anchor color="gray.7" sx={{ opacity: 0.4, ['&:hover']: { opacity: 1 } }} onClick={handleDelete}>
                                        <Trash />
                                    </Anchor>
                                </Tooltip>
                            </Center>
                        </RuleListItemLayout>
                    </Card>
                );
            }}
        </Draggable>
    );
}

export function TitleBox({ title, subTitle, imgPath }: { title: string; subTitle: string; imgPath: string }) {
    return (
        <Group>
            <Box>
                <img src={imgPath} />
            </Box>
            <Box>
                <Text size="md" weight={700}>
                    {title}
                </Text>
                <Text size="sm">{subTitle}</Text>
            </Box>
        </Group>
    );
}

const RuleListItemLayout = styled.div`
    display: grid;
    min-height: 60px;
    align-items: center;
    grid-template-columns: 92px 1px 160px 1px 1fr 1px 120px 1px 72px;
`;

const CtaCard = styled.button<{ state?: 'unfinished' | 'finished' }>`
    box-sizing: border-box;
    background-color: ${(p) => (p.state === 'unfinished' ? '#fff' : p.theme.colors.success[1])};
    display: block;
    border-radius: ${(p) => p.theme.radius.md}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: ${(p) => (p.state === 'unfinished' ? 'pointer' : 'default')};
    &: ${({ state }) => (state === 'unfinished' ? 'hover' : 'none')} {
        background-color: ${(p) => p.theme.colors.primary[6]};
        * {
            color: #fff;
        }
        svg {
            stroke: #fff;
        }
    }
`;

const useStyles = createStyles((theme) => ({
    headerSection: {},
    switchLabel: {
        color: theme.colors.success[0],
    },
    switchInput: {
        backgroundColor: theme.colors.gray[6],
        ':checked': {
            backgroundColor: theme.colors.success[6],
            borderColor: theme.colors.success[0],
        },
    },
    root: {
        ':hover': {
            backgroundColor: theme.colors.error[1],
        },
    },
    itemDragging: {
        boxShadow: theme.shadows.md,
    },
    dragHandle: {
        ...theme.fn.focusStyles(),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: theme.colors.gray[6],
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
    },
}));

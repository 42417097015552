import { TagAutomationRuleParametersType } from '@apis/TagManager/model';
import { Box, Image, UnstyledButton } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { useCallback } from 'react';

const typeDescriptorLookup: Record<TagAutomationRuleParametersType, { image: string; ufName: string }> = {
    AutoTag: { image: 'autotag', ufName: 'Auto-Tag' },
    Inheritance: { image: 'taginherit', ufName: 'Tag Inheritance' },
    MapCredits: { image: 'mapcredit', ufName: 'MAP Credit' },
    Replacement: { image: 'tagreplace', ufName: 'Tag Replacement' },
    SyntaxCorrection: { image: 'syntax', ufName: 'Syntax Correction' },
    ResourceGroup: { image: 'resourcegroup', ufName: 'Resource Group' },
    AwsOUAccountGroup: { image: 'resourcegroup', ufName: 'AWS Org Entity' },
};

const getImage = (type: TagAutomationRuleParametersType, state?: 'selected' | 'disabled' | 'hover') => {
    const imgType = typeDescriptorLookup[type].image;
    return `/assets/ruletypes/card-${imgType}-${state ?? 'default'}.png`;
};

export function RuleTypeCard({
    ruleType,
    disabled,
    selected,
    onClick,
}: {
    ruleType: TagAutomationRuleParametersType;
    disabled: boolean;
    selected: boolean;
    onClick: (type: TagAutomationRuleParametersType) => void;
}) {
    const { hovered, ref } = useHover();
    const src = getImage(ruleType, selected ? 'selected' : disabled ? 'disabled' : hovered ? 'hover' : undefined);
    const ufName = typeDescriptorLookup[ruleType].ufName;
    const select = useCallback(() => onClick(ruleType), [ruleType]);
    return (
        <Box ref={ref} sx={{ lineHeight: 0 }}>
            <UnstyledButton onClick={select} style={{ cursor: disabled ? 'default' : 'pointer' }}>
                <Image src={src} alt={ufName} height={144} />
            </UnstyledButton>
        </Box>
    );
}

import styled from '@emotion/styled';
import { Box, Divider, LoadingOverlay, useMantineTheme } from '@mantine/core';
import { PageContent, PagePanel, PanelBody, PaneledPage } from '@root/Design/Layout';
import { useDi, useDiContainer } from '@root/Services/DI';
import { useEvent, useEventValue } from '@root/Services/EventEmitter';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useLink } from '@root/Services/Router/Router';
import { useEffect, useMemo, useState } from 'react';
import { Activity, AlertTriangle, ChartPie, FileDescription, PlugConnected, SettingsAutomation, Tag } from 'tabler-icons-react';
import { ContractOverview } from './Overview/ContractOverview';
import { CoverageOverview } from './ManageTags/ManageTags';
import { MapConnectionHealth } from './ConnectionHealth/MapConnectionHealth';
import { MapContractActivity } from './Activity/MapContractActivity';
import { MapAutoTag } from './AutoTag/MapAutoTag';
import { MapContractHomeModel } from './Models';
import { CustomColors } from '@root/Design/Themes';
import { MapDashboardCustomizable } from './Dashboard/MapDashboardCustomizable';

export function MapContractHomePage() {
    const theme = useMantineTheme();
    const { getData } = useNav();
    const { id, section } = getData('id', 'section');
    const container = useDiContainer();
    const model = useMemo(() => container.resolve(MapContractHomeModel).init(id ?? ''), [id]);
    const loading = useEventValue(model.loading);
    const [warnings, setWarnings] = useState<string[]>([]);

    // TODO: Move the monitoring responsibility into model once model inconsistent exception in load is found.
    useEffect(() => {
        model.checkWarnings();
    }, [JSON.stringify(model.accounts)]);

    const sectionHasWarnings = (id: string) => {
        return warnings.includes(id);
    };

    const handleWarningsChanged = (sectionData: { sectionId: string; hasWarnings: boolean }) => {
        if (warnings.includes(sectionData.sectionId) && sectionData.hasWarnings === false) {
            setWarnings((prevState) => {
                var newState = prevState.filter((p) => p !== sectionData.sectionId);
                return newState;
            });
        }

        if (!warnings.includes(sectionData.sectionId) && sectionData.hasWarnings === true) {
            setWarnings((prevState) => {
                var newState = [...prevState];
                newState.push(sectionData.sectionId);
                return newState;
            });
        }
    };

    useEvent(model.WarningsChanged, handleWarningsChanged);

    return (
        <PageContent>
            {loading && (
                <Box sx={{ minWidth: '100%', position: 'relative', height: '400px' }}>
                    <LoadingOverlay overlayOpacity={0} visible />
                </Box>
            )}
            {!loading && model.contract && (
                <PaneledPage>
                    <PagePanel size={250}>
                        <PanelBody noPadding style={{ padding: 10 }}>
                            <Menu hasWarnings={sectionHasWarnings} />
                        </PanelBody>
                    </PagePanel>
                    <Divider orientation="vertical" />
                    <PagePanel size="fill">
                        <PanelBody noPadding style={{ background: theme.white }}>
                            {section === 'map-dashboard' && <MapDashboardCustomizable contract={model.contract} />}
                            {!section || section === 'overview' ? <ContractOverview contract={model.contract} /> : null}
                            {section === 'manage-tags' && <CoverageOverview contract={model.contract} otherContracts={model.otherContracts!} />}
                            {section === 'connection' && <MapConnectionHealth contract={model.contract} />}
                            {section === 'activity' && <MapContractActivity contract={model.contract} />}
                            {section === 'auto-tagging' && <MapAutoTag contract={model.contract} />}
                        </PanelBody>
                    </PagePanel>
                </PaneledPage>
            )}
        </PageContent>
    );
}

function Menu({ hasWarnings }: { hasWarnings: (sectionId: string) => boolean }) {
    const theme = useMantineTheme();
    const { getData, getDataUrl } = useNav();
    const link = useLink();
    const { id, section } = getData('id', 'section');
    const sections = [
        {
            section: 'overview',
            label: 'Contract Details',
            Icon: FileDescription,
            needAttn: hasWarnings('overview'),
        },
        {
            section: 'map-dashboard',
            label: 'Dashboards',
            Icon: ChartPie,
            needAttn: hasWarnings('map-dashboard'),
        },
        {
            section: 'manage-tags',
            label: 'Manage Tags',
            Icon: Tag,
            needAttn: hasWarnings('manage-tags'),
        },
        {
            section: 'auto-tagging',
            label: 'Auto-Tagging',
            Icon: SettingsAutomation,
            needAttn: hasWarnings('auto-tagging'),
        },
        {
            section: 'connection',
            label: 'Connection Health',
            Icon: PlugConnected,
            needAttn: hasWarnings('connection'),
        },
        {
            section: 'activity',
            label: 'Activity',
            Icon: Activity,
            needAttn: hasWarnings('activity'),
        },
    ];
    return (
        <>
            {sections.map(({ Icon, ...s }) => {
                return (
                    <SimpleMenuItem
                        key={s.section}
                        mode={s.section === section ? 'active' : 'inactive'}
                        {...link(getDataUrl({ id: id!, section: s.section }))}
                        data-atid={`MapContractHome:${s.section}`}
                    >
                        <Icon size={16} />
                        <span>{s.label}</span>
                        {s.needAttn && <AlertTriangle color={theme.colors?.warning?.[6] as CustomColors} size={16} />}
                    </SimpleMenuItem>
                );
            })}
        </>
    );
}

const SimpleMenuItem = styled.a<{ mode: 'active' | 'inactive' }>`
    display: block;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
    margin: 6px 10px;
    padding: 4px 10px;
    border-radius: 5px;
    font-size: 14px;
    background: ${({ theme, mode }) => (mode === 'active' ? theme.colors.primary[1] : 'transparent')};
    color: ${({ theme, mode }) => (mode === 'active' ? theme.colors.primary[6] : 'inherit')};
    text-decoration: none;
    &:hover {
        background: ${({ theme }) => theme.colors.primary[1]};
        color: ${({ theme }) => theme.colors.primary[6]};
    }
`;

function MapContractHomeBreadcrumb() {
    const model = useDi(MapContractHomeModel);
    const loading = useEventValue(model.loading);

    return <>{loading ? 'Loading...' : model.getName()}</>;
}

endpoint('map-contract', MapContractHomePage, MapContractHomeBreadcrumb);

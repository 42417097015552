import { ThemeIcon, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useResizeNeeded } from '@root/Design/Primitives';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ArrowsMaximize, ArrowsMinimize } from 'tabler-icons-react';

export const useGridResizer = () => {
    const [containerStyle, setContainerStyle] = useState<{ paddingBottom?: number; minHeight?: string; transition?: string }>({});
    const scrollContainer = useMemo(() => {
        const result = {
            el: null as HTMLElement | null,
            setScrollContainer: (el: HTMLElement | null) => (result.el = el),
        };
        return result;
    }, []);
    const Resizer = useMemo(() => {
        return function Resizer() {
            const [tooltipOpened, setTooltipOpened] = useState(false);
            const openTooltip = useCallback(() => setTooltipOpened(true), []);
            const closeTooltip = useCallback(() => setTooltipOpened(false), []);
            const [fullScreen, setFullScreen] = useState(false);
            const resizeNeeded = useResizeNeeded();
            const toggleFullScreen = useCallback(() => {
                setFullScreen(!fullScreen);
                if (fullScreen) {
                    setContainerStyle({});
                } else {
                    setContainerStyle({ paddingBottom: 16, minHeight: 'calc(100vh - 70px)' });
                }
                setTimeout(() => {
                    scrollContainer.el?.scrollTo({ top: 2000, behavior: 'smooth' });
                    resizeNeeded();
                    closeTooltip();
                }, 50);
            }, [fullScreen]);

            return (
                <Tooltip
                    opened={tooltipOpened}
                    label={!fullScreen ? 'Enlarge Table' : 'Shrink Table'}
                    events={{ hover: false, focus: false, touch: false }}
                >
                    <ThemeIcon
                        onMouseEnter={openTooltip}
                        onMouseLeave={closeTooltip}
                        sx={{ cursor: 'pointer ' }}
                        variant="light"
                        onClick={toggleFullScreen}
                        data-atid="grid-resizer"
                    >
                        {fullScreen ? <ArrowsMinimize size={16} /> : <ArrowsMaximize size={16} />}
                    </ThemeIcon>
                </Tooltip>
            );
        };
    }, [setContainerStyle]);
    return { Resizer, containerStyle, setScrollContainer: scrollContainer.setScrollContainer };
};

import { getAccountGetAccounts, getServiceActionGetServiceActions, postServiceActionSaveServiceAction } from '@apis/Customers';
import { Account, Company, ServiceAction } from '@apis/Customers/model';
import {
    getConnectionGetAwsPolicyVersion,
    postResourcesCheckAwsResources,
    postResourceSyncStatusQuery,
    QueryField,
    QueryResult,
} from '@apis/Resources';
import { AwsPolicyVersionInfo, CheckAwsResourcesJob, Query, ResourceSyncStatus, ResourceSyncStatusErrorType } from '@apis/Resources/model';
import styled from '@emotion/styled';
import {
    Anchor,
    Box,
    Button,
    CloseButton,
    Divider,
    Group,
    LoadingOverlay,
    Space,
    Switch,
    Table,
    Text,
    Title,
    Tooltip,
    useMantineTheme,
} from '@mantine/core';
import { useModals } from '@mantine/modals';
import { ActivityPoller } from '@root/Components/Actions/ActivityPanel/ActivityPoller';
import { JobStatus } from '@root/Components/Actions/ActivityPanel/ActivityTypes';
import { DataGrid } from '@root/Components/DataGrid';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { ColumnConfig, DataColumnConfig, DataGridState, IDataSource } from '@root/Components/DataGrid/Models';
import { GroupRow, QueryExprGroupHelper } from '@root/Components/DataGrid/QueryExprGroupHelper';
import { Node } from '@root/Components/VirtualTree/Node';
import { FillerSwitch } from '@root/Design/Filler';
import { PagePanel, PanelBody, PanelContent, PaneledPage, PanelHeader, PanelToolbar } from '@root/Design/Layout';
import { useDi, useDiComponent, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { JobService } from '@root/Services/Jobs/JobService';
import { PollingService } from '@root/Services/Jobs/PollingService';
import { NavigationService } from '@root/Services/NavigationService';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { PlatformService } from '@root/Services/PlatformService';
import { queryBuilder, ValuesGroupOtherText } from '@root/Services/QueryExpr';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { injectable } from 'tsyringe';
import { ConnectionHealthModal } from './ConnectionHealthModal';
import { SettingsPage } from './SettingsPage';

export function ConnectionHealth() {
    const [serviceActions, setServiceActions] = useState<ServiceAction[]>();
    const formatSvc = useDi(FormatService);
    const [selectedServiceAction, setSelectedServiceAction] = useState<ServiceAction>();
    const [loading, setLoading] = useState(true);
    const [accounts, setAccounts] = useState<Account[]>([]);
    const platformSvc = useDi(PlatformService);
    const { getData } = useDi(NavigationService);
    const { state: rawState } = getData('state');

    const gridState = useMemo(() => {
        if (rawState) {
            try {
                return JSON.parse(rawState) as DataGridState;
            } catch (err) {
                console.error('Failed to parse tag explorer grid state ', err);
            }
        }
        return undefined;
    }, [rawState]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const accs = await getAccountGetAccounts();
                await platformSvc.init();
                setAccounts(accs);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const defaultState: DataGridState = {
        sort: [{ Direction: 'Desc', Expr: { Field: 'StartTime' } }],
        filters: [
            { Operation: 'eq', Operands: [{ Field: 'Success' }, { Value: false }] },
            { Operation: 'eq', Operands: [{ Field: 'Ignored' }, { Value: false }] },
        ],
        columns: [
            { id: 'CloudPlatform', width: 200 },
            { id: 'AwsAccountId', width: 200 },
            { id: 'Region', width: 180 },
            { id: 'StartTime', width: 200 },
            { id: 'FunctionName', width: 180 },
            { id: 'ErrorMessage', width: 350 },
        ],
    };

    if (gridState != undefined) {
        defaultState.filters.push(...gridState.filters);
    }

    return (
        <SettingsPage>
            <PaneledPage>
                <PagePanel size="fill">
                    {loading ? null : (
                        <PanelHeader style={{ paddingBottom: 0 }}>
                            <Box>
                                <Text data-atid="SettingsMainHeader" size={20}>
                                    Cloud Provider Permissions
                                </Text>
                                {accounts && platformSvc.hasPlatform('Aws') ? (
                                    <>
                                        <Space h="lg" />
                                        <Text size={16} weight="bold">
                                            IAM Permission Policy
                                        </Text>
                                        <Space h="sm" />
                                        <Text size={14}>Update your permissions to the latest file to resolve service connection issues.</Text>
                                        <Space h="sm" />
                                        {/* <PermissionPolicyBox type="JSON" accounts={accounts} /> */}
                                        <PermissionPolicyBox type="CloudFormation" accounts={accounts} />
                                        <PermissionPolicyBox type="Terraform" accounts={accounts} />
                                        <Clear />
                                    </>
                                ) : null}
                                <Space h="lg" />
                                <Text size={16} weight="bold">
                                    Resource Sync Status
                                </Text>
                            </Box>
                        </PanelHeader>
                    )}

                    <PanelBody style={{ paddingTop: '10px' }}>
                        <FillerSwitch loading={loading} noData={!platformSvc.hasPlatform('Aws')}>
                            {() => <AwsResourceSyncStatusGrid />}
                        </FillerSwitch>
                    </PanelBody>
                    <Divider />
                </PagePanel>
                {!selectedServiceAction ? null : (
                    <>
                        <Divider orientation="vertical" />
                        <PagePanel size="md">
                            <Group position="apart">
                                <Title p="lg" order={3}>
                                    Connection Details
                                </Title>
                                <CloseButton mr="lg" onClick={() => setSelectedServiceAction(undefined)} />
                            </Group>
                            <Divider />
                        </PagePanel>
                    </>
                )}
            </PaneledPage>
        </SettingsPage>
    );
}
endpoint('connection-health', ConnectionHealth, 'Settings');

function AwsResourceSyncStatusGrid() {
    const fmtSvc = useDi(FormatService);
    const theme = useMantineTheme();
    const jobSvc = useDi(JobService);
    const pollingSvc = useDi(PollingService);
    const hoursBetweenRefresh = 1;
    const [sync, setSync] = useState(new Map<string, string>());
    const activityPoller = useDi(ActivityPoller);
    function canSync(item: ResourceSyncStatus) {
        const timeToUse = item.SyncEndedAt ? new Date(item.SyncEndedAt) : new Date(item.SyncStartedAt!);
        timeToUse.setHours(timeToUse.getHours() + hoursBetweenRefresh);
        const syncValue = !sync?.has(item.Id as string) ?? true;

        return syncValue && timeToUse < new Date();
    }

    const userFriendlyErrorTypes = useMemo(() => {
        return new Map<ResourceSyncStatusErrorType, string>([
            ['ServiceNotEnabled', 'Service Not Enabled'],
            ['PermissionError', 'Permission Issue'],
            ['UnknownType', 'Unhandled Scenario'],
            ['None', 'Success'],
            ['AccountUnavailable', 'Account Unavailable'],
            ['LogicError', 'Unhandled Data Scenario'],
            ['UnavailableInRegion', 'Resource Type Unavailable in Region'],
            ['ResourceNotFound', 'Resource Not Found'],
        ]);
    }, []);
    const textColFactory = useCallback((field: string, name: string, defaultWidth: number) => {
        return {
            id: field,
            accessor: field,
            header: name,
            align: 'left',
            type: 'string',
            sortField: field,
            defaultWidth,
            allowGrouping: true,
            filter: {
                filterField: field,
                filterType: 'string',
                name: name,
                options: {
                    getValueProvider:
                        () =>
                        async (filter: string, max: number = 100) => {
                            const qb = queryBuilder<Record<string, string>>();
                            if (filter) {
                                qb.where((b) => b.model[field].contains(filter));
                            }
                            const queryResult = await qb
                                .take(max)
                                .select((b) => ({
                                    value: { Operation: 'values', Operands: [{ Field: field }, { Value: filter }] } as unknown as string,
                                    count: b.count(),
                                }))
                                .execute(postResourceSyncStatusQuery);

                            const comparer = new Intl.Collator(undefined, { sensitivity: 'base' }).compare;
                            return queryResult.Results?.map((r) => r.value).sort(comparer);
                        },
                },
            },
        } as ColumnConfig<ResourceSyncStatus>;
    }, []);
    const columns = useMemo(
        () =>
            [
                textColFactory('AwsAccountId', 'Account ID', 140),
                textColFactory('AccountName', 'Account', 240),
                textColFactory('Region', 'Region', 140),
                textColFactory('ResourceType', 'Resource Type', 220),
                {
                    id: 'SyncEndedAt',
                    accessor: 'SyncEndedAt',
                    header: 'Last Run',
                    align: 'left',
                    type: 'date',
                    sortField: 'SyncEndedAt',
                    defaultWidth: 160,
                    filter: true,
                    formatter: (item: ResourceSyncStatus) => (item.SyncEndedAt ? fmtSvc.formatDateWithTodaysTime(item.SyncEndedAt) : ''),
                },
                {
                    id: 'ResourceCount',
                    accessor: 'ResourceCount',
                    header: 'Resources',
                    align: 'right',
                    type: 'number',
                    sortField: 'ResourceCount',
                    defaultWidth: 100,
                    filter: true,
                    formatter: (item: ResourceSyncStatus) => fmtSvc.formatInt(item.ResourceCount ?? 0),
                },
                {
                    ...textColFactory('ErrorType', 'Status', 200),
                    filter: {
                        filterType: 'string',
                        name: 'Status',
                        filterField: 'ErrorType',
                        options: {
                            getValueProvider: () => [...userFriendlyErrorTypes.entries()].map(([key, value]) => ({ value: key, label: value })),
                        },
                    },
                    formatter: (item: ResourceSyncStatus) => userFriendlyErrorTypes.get(item.ErrorType ?? 'None') ?? item.ErrorType,
                    exportOptions: {
                        renderer: (item: ResourceSyncStatus) => userFriendlyErrorTypes.get(item.ErrorType ?? 'None') ?? item.ErrorType,
                    },
                },
                {
                    id: 'Resync',
                    accessor: 'Resync',
                    header: 'Resync',
                    align: 'center',
                    type: 'custom',
                    defaultWidth: 160,
                    cellRenderer: (item: ResourceSyncStatus) => {
                        useEvent(reRenderCell);
                        const buttonText = sync?.has(item.Id!) ? 'Updating Resources' : 'Resync Resources';
                        return (
                            <Tooltip
                                label={sync?.has(item.Id!) ? 'Resync in progress' : 'Resync available every ' + hoursBetweenRefresh + ' hour'}
                                disabled={canSync(item)}
                            >
                                <Button
                                    onClick={() => {
                                        if (canSync(item)) {
                                            resyncResource(item);
                                        }
                                    }}
                                    style={{
                                        cursor: canSync(item) ? 'pointer' : 'default',
                                        color: canSync(item) ? theme.colors.primary[6] : theme.colors.gray[6],
                                        textDecoration: 'underline',
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        padding: 0,
                                        font: 'inherit',
                                        outline: 'inherit',
                                    }}
                                >
                                    {buttonText}
                                </Button>
                            </Tooltip>
                        );
                    },
                },
            ] as ColumnConfig<GroupRow<ResourceSyncStatus> | ResourceSyncStatus>[],
        []
    );

    const reRenderCell = useMemo(() => EventEmitter.empty(), []);
    const resyncResource = async (item: ResourceSyncStatus) => {
        const syncValue = sync?.get(item.Id as string) ?? false;
        if (syncValue) {
            return;
        }
        sync.set(item.Id as string, 'started');
        reRenderCell.emit();

        if (canSync(item) || item.SyncEndedAt == null || new Date(Date.now()) > new Date(item.SyncEndedAt)) {
            const job = await postResourcesCheckAwsResources({
                CompanyId: item.CompanyId!,
                AccountId: item.AccountId as number,
                Region: item.Region as string,
                ResourceType: item.ResourceType as string,
            });
            jobSvc.newJobStarted.emit(job.Parameters);
            model.inProgressJobs.set(job.Id ?? '', item);
        }
    };

    const model = useMemo(
        () => ({
            gridModel: undefined as DataGridModel | undefined,
            inProgressJobs: new Map<string, ResourceSyncStatus>(),
            statusRefreshNeeded: new Map<string, { syncStatus: ResourceSyncStatus; jobId: string }>(),
        }),
        []
    );
    const handleJobStatusUpdate = useCallback(async (statuses: JobStatus[]) => {
        for (const status of statuses) {
            const jobId = status.job.Id ?? '';
            const syncStatus = model.inProgressJobs.get(jobId);
            if (syncStatus) {
                model.statusRefreshNeeded.set(syncStatus.Id!, { syncStatus, jobId });
            }
        }
    }, []);

    useEffect(() => {
        const disposer = activityPoller.listen(handleJobStatusUpdate);
        let disposed = false;
        pollingSvc.pollUntil(
            async () => {
                const statusRefreshNeeded = [...model.statusRefreshNeeded.values()];
                const statusResults = !statusRefreshNeeded.length
                    ? null
                    : await queryBuilder<ResourceSyncStatus>()
                          .where((b) => b.or(...statusRefreshNeeded.map((v) => b.and(b.model.Id!.eq(v.syncStatus.Id), b.model.JobId!.eq(v.jobId)))))
                          .execute(postResourceSyncStatusQuery);
                return statusResults?.Results ?? [];
            },
            (result) => {
                if (!disposed) {
                    for (const syncStatus of result) {
                        const gridRowSyncStatus = model.statusRefreshNeeded.get(syncStatus.Id!)?.syncStatus;
                        if (gridRowSyncStatus) {
                            Object.assign(gridRowSyncStatus, syncStatus);
                            model.gridModel?.treeModel?.invalidateItem(gridRowSyncStatus, false);
                            model.statusRefreshNeeded.delete(syncStatus.Id!);
                            sync.delete(syncStatus.Id ?? '');
                            reRenderCell.emit();
                        }
                    }
                }
                return disposed;
            },
            3000
        );
        return () => {
            disposer.dispose();
            disposed = true;
        };
    }, []);

    const gridState = useMemo(
        () =>
            ({
                columns: columns.map((c) => ({ id: c.id, width: c.defaultWidth })),
                filters: [],
                sort: [{ Expr: { Field: 'SyncEndedAt' }, Direction: 'Desc' }],
            } as DataGridState),
        [columns]
    );
    const queryApi = postResourceSyncStatusQuery as (query: Query) => Promise<QueryResult<ResourceSyncStatus>>;
    const datasource = useMemo(() => new QueryExprGroupHelper<ResourceSyncStatus>(queryApi, queryApi), []);
    const attach = useCallback(
        (grid: DataGridModel) => {
            datasource.attach(grid);
            model.gridModel = grid;
        },
        [datasource]
    );
    const childAccessor = useMemo(() => ({ hasChildren: (row: GroupRow<ResourceSyncStatus> | ResourceSyncStatus) => 'value' in row }), []);
    const renderGroupBy = useCallback(
        (row: Node<any>) => (
            <>
                {row.item.value === ValuesGroupOtherText
                    ? 'Other'
                    : row.item.groupId === 'ErrorType'
                    ? userFriendlyErrorTypes.get(row.item.value)
                    : row.item.value}{' '}
                ({fmtSvc.formatInt(row.item.count)})
            </>
        ),
        []
    );

    return (
        <DataGrid
            dataSource={datasource as IDataSource<ResourceSyncStatus>}
            onModelLoaded={attach}
            columns={columns}
            state={gridState}
            childAccessor={childAccessor}
            statePersistence={{ key: 'Resource Sync Status' }}
            allowSavedViews
            showRefresh
            allowGroupBy
            renderGroupBy={renderGroupBy}
            exportName="Connection Health"
            allowLoadAllPages
        />
    );
}

export function PermissionPolicyBox({ type, accounts }: { type: string; accounts: Account[] }) {
    const modals = useModals();
    const DiContainer = useDiComponent();
    const [{ Version, Date }, setVersionInfo] = useState<AwsPolicyVersionInfo>({});

    useEffect(() => {
        getConnectionGetAwsPolicyVersion().then(setVersionInfo);
    }, []);

    const openUserInvitewModal = () => {
        const id = modals.openModal({
            title: 'Download IAM Permision Policy',
            children: (
                <DiContainer>
                    <ConnectionHealthModal accounts={accounts} type={type}></ConnectionHealthModal>
                </DiContainer>
            ),
        });
    };

    return (
        <PolicyBox>
            <Text size={14} weight="bold">
                {type}
            </Text>
            <Text size={12}>{Version}</Text>
            <Text size={12}>{Date}</Text>
            <Space h="sm" />
            <Button
                size="xs"
                variant="outline"
                onClick={() => {
                    openUserInvitewModal();
                }}
            >
                Create Policy
            </Button>
        </PolicyBox>
    );
}

export function Clear() {
    return <DivClear></DivClear>;
}

const PolicyBox = styled.div`
    width: 200px;
    float: left;
    margin-right: 25px;
    padding: 16px;
    border: 1px solid ${(p) => p.theme.colors.gray[3]};
    background: ${(p) => p.theme.white};
    border-radius: ${(p) => p.theme.radius.lg}px;
`;

const DivClear = styled.div`
    clear: both;
`;

@injectable()
class ServiceActionDetailsModel {
    public isLoading = true;
    public serviceAction: ServiceAction = {};
    public company: Company = {};
    public isIgnored = false;
    public initialIsIgnored = false;

    public constructor() {
        makeAutoObservable(this);
    }

    public async init(serviceAction: ServiceAction) {
        this.isLoading = true;
        try {
            this.loadServiceAction(serviceAction);
        } finally {
            this.isLoading = false;
        }
    }
    public async loadServiceAction(thisServiceAction: ServiceAction) {
        const serviceAction = makeAutoObservable({
            Id: 0,
            Ignored: false,
            ...thisServiceAction,
        });
        this.serviceAction = thisServiceAction;
        this.isIgnored = thisServiceAction.Ignored ? true : false;
        this.initialIsIgnored = this.isIgnored;
    }

    public save = async () => {
        const result = await postServiceActionSaveServiceAction({ serviceActionId: this.serviceAction.Id, ignored: this.isIgnored });
        return result;
    };

    public changeStatus = async (status: boolean) => {
        this.isIgnored = status;
    };
}

const ServiceActionDetails = observer(function ServiceActionDetails({
    serviceAction,
    onSave,
}: {
    serviceAction: ServiceAction;
    onSave?: () => void;
}) {
    const container = useDiContainer();
    const notificationSvc = useDi(NotificationService);
    const model = useMemo(() => {
        const result = container.resolve(ServiceActionDetailsModel);
        result.init(serviceAction);
        return result;
    }, []);

    const save = useCallback(() => {
        model
            .save()
            .then(() => {
                onSave?.();
                notificationSvc.notify('Connection updated', '', 'success', null);
            })
            .catch(() => {
                notificationSvc.notify('Save failed', '', 'error', null);
            });
    }, [model, onSave]);
    const formatSvc = useDi(FormatService);

    return model.isLoading ? null : (
        <PanelContent>
            <PanelBody>
                <Table>
                    <tbody>
                        <tr>
                            <td>
                                <Text weight="bold">Ignored: </Text>
                            </td>
                            <td>
                                <Switch
                                    checked={model.isIgnored}
                                    onChange={(event) => model.changeStatus(event.currentTarget.checked)}
                                    label={model.isIgnored ? 'Yes' : 'No'}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Text weight="bold">Account ID: </Text>
                            </td>
                            <td>
                                <Text>{model.serviceAction.AwsAccountId}</Text>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Text weight="bold">Cloud&nbsp;Platform: </Text>
                            </td>
                            <td>{model.serviceAction.CloudPlatform}</td>
                        </tr>
                        <tr>
                            <td>
                                <Text weight="bold">Region: </Text>
                            </td>
                            <td>{model.serviceAction.Region}</td>
                        </tr>
                        <tr>
                            <td>
                                <Text weight="bold">Date: </Text>
                            </td>
                            <td>{formatSvc.toLocal(model.serviceAction.StartTime)}</td>
                        </tr>
                        <tr>
                            <td>
                                <Text weight="bold">Resource&nbsp;Type: </Text>
                            </td>
                            <td>{model.serviceAction.FunctionName}</td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top' }}>
                                <Text weight="bold">Required&nbsp;Permissions: </Text>
                            </td>
                            <td>
                                {model.serviceAction.ServiceActionPermissions?.map((p) => (
                                    <>
                                        {p.Permission!}
                                        <br />
                                    </>
                                ))}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top' }}>
                                <Text weight="bold">Error&nbsp;Message: </Text>
                            </td>
                            <td>{model.serviceAction.ErrorMessage}</td>
                        </tr>
                    </tbody>
                </Table>
            </PanelBody>
            <Divider />
            <PanelToolbar>
                <Button onClick={save} disabled={model.isIgnored == model.initialIsIgnored}>
                    Update Connection
                </Button>
            </PanelToolbar>
        </PanelContent>
    );
});

import { postResourcesQuery } from '@apis/Resources';
import { BaseResource } from '@apis/Resources/model';
import { getVariantGroupGetVariantGroup, getVariantGroupGetVariantsForGroup } from '@apis/TagManager';
import { Variant, VariantGroup } from '@apis/TagManager/model';
import { Badge, Button, Divider, Text } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { ResourcesGrid } from '@root/Components/Resources/ResourcesGrid';
import { ActionPageContainer, InfoListContainer } from '@root/Design/ActionPage';
import { PageContent, PageSection } from '@root/Design/Layout';
import { useDiComponent } from '@root/Services/DI';
import { EventEmitter } from '@root/Services/EventEmitter';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { makeAutoObservable } from 'mobx';
import { Observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { TagsShell } from './Common';
import { AddVariantModal } from './Components/AddVariantModal';
import { InfoListActionItemProps, InfoList, InfoListButtonProps, InfoListRow } from './Components/InfoListTable';
import { VViewModel } from './ViewModels/ViewModels';

export function Variants() {
    const { getParams } = useNav();
    const { vgid } = getParams();
    const numvgid = Number(vgid);

    const badgeColors: Record<string, string> = {
        'Common Tag Value': 'primary',
        'write logic for this': 'warning',
        'Low Utilization': 'warning',
    };

    const [Variants, setVariants] = useState<VViewModel[]>([]);
    const [Resources, setResources] = useState<BaseResource[]>([]);
    const [SelectedVariant, setSelectedVariant] = useState<VViewModel>();

    const changes = useMemo(() => {
        return makeAutoObservable({
            changedResources: new Set<BaseResource>(),
            add(resource: BaseResource) {
                this.changedResources.add(resource);
            },
            clear() {
                this.changedResources.clear();
            },
        });
    }, []);

    const VGroup = GetVariantGroup(numvgid);

    useEffect(() => {
        (async () => {
            const Vars: Variant[] = await getVariantGroupGetVariantsForGroup({ groupId: numvgid });
            const varVMs: VViewModel[] = [];
            for (const varient of Vars) {
                const varVM: VViewModel = {
                    ...varient,
                    Comments: getComments(varient, Vars),
                    TagType: (await VGroup).ParentKey ? 'Value' : 'Key',
                    invalidated: EventEmitter.empty(),
                };

                varVMs.push(varVM);
            }
            setVariants(varVMs);
        })();
    }, []);

    async function handleResourceList(item: VViewModel): Promise<void> {
        const Resources = await getResourcesForVariants(item.VariantString as string, item.TagType);
        setResources(Resources);
        setSelectedVariant(item);
        changes.clear();
    }

    //Add Variant Modal
    const modals = useModals();
    const DiContainer = useDiComponent();
    const openAddVariantModal = (type: string) => {
        const id = modals.openModal({
            title: 'Add Variant',
            children: (
                <DiContainer>
                    <AddVariantModal onClose={() => modals.closeModal(id)} type={type} id={numvgid} variants={Variants}></AddVariantModal>
                </DiContainer>
            ),
        });
    };

    const variantColumns = ['Tag Name', 'Resource Count', ''];

    return (
        <TagsShell>
            <PageContent>
                <h1>Variants Page</h1>
                <ActionPageContainer>
                    <InfoListContainer>
                        <InfoList columns={variantColumns}>
                            {Variants.map((variant, i) => (
                                <InfoListRow>
                                    {[
                                        variant.VariantString,
                                        <Observer
                                            render={() => (
                                                <Button onClick={() => handleResourceList(variant as VViewModel)}>
                                                    <Text size="sm">{variant.Count}</Text>
                                                </Button>
                                            )}
                                        />,
                                        <Badge color={'primary'}>{variant.Comments}</Badge>,
                                    ]}
                                </InfoListRow>
                            ))}
                        </InfoList>
                        {/* <Divider />
                        <Observer
                            render={() => (
                                <Button type="submit" onClick={async () => openAddVariantModal((await VGroup).ParentId == 0 ? 'Key' : 'Value')}>
                                    Add Variant
                                </Button>
                            )}
                        /> */}
                    </InfoListContainer>
                    <Divider orientation="vertical" />
                </ActionPageContainer>
            </PageContent>
        </TagsShell>
    );
}
endpoint('variants', Variants, 'Variants');

function getComments(varient: Variant, Vars: Variant[]): string {
    if (varient.IsPrimary) {
        return 'Common Tag Value';
    }

    if (varient.Count == 0) {
        return 'Low Utilization';
    }

    return 'write logic for this';
}

async function getResourcesForVariants(variantString: string, variantType: string) {
    let fieldValue = 'Tags.Key';
    if (variantType == 'Value') {
        fieldValue = 'Tags.Value';
    }

    const RS = await postResourcesQuery(
        {
            Where: {
                operation: 'eq',
                operands: [{ field: fieldValue }, { value: variantString }],
            },
        },
        { companyId: 71 }
    );
    return RS.Results as BaseResource[];
}

async function GetVariantGroup(numvgid: number) {
    return await getVariantGroupGetVariantGroup({ groupId: numvgid });
}

import { createStyles } from '@mantine/core';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useCompany } from '@root/Components/Router/CompanyContent';

export function _404Error() {
    const { classes } = useStyles();
    const company = useCompany();
    const homePage = `/${company?.DatabaseName}/landing/app-redirect`;
    return (
        <html>
            <body className={classes.body}>
                <div>
                    <h1>404 - Not Found</h1>
                    <p>The page you are looking for could not be found.</p>
                    <p>
                        Please check the URL or <a href={homePage}>return to the site</a>.
                    </p>
                </div>
            </body>
        </html>
    );
}
endpoint('_404Error', _404Error, '404 Not Found');

const useStyles = createStyles((theme) => ({
    body: {
        textAlign: 'center',
        backgroundColor: '#f0f0f0',
        padding: 150,
    },
}));

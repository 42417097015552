/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Recommendations.Host.Api
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  UseMutationOptions,
  MutationFunction
} from 'react-query'
import type {
  ObjectQueryResult,
  Query,
  Recommendation,
  RecommendationOption,
  PostRecommendationSelectOptionParams,
  RecommendationQueryResult,
  PostRecommendationQueryRecommendationsParams
} from './Recommendations/model'
import { request } from '../Services/BasicApi'


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const postRecommendationQuery = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult>(
      {url: `/Recommendation/Query`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'Recommendations',  ...options});
    }
  


    export type PostRecommendationQueryMutationResult = NonNullable<Awaited<ReturnType<typeof postRecommendationQuery>>>
    export type PostRecommendationQueryMutationBody = Query
    export type PostRecommendationQueryMutationError = unknown

    export const usePostRecommendationQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postRecommendationQuery>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postRecommendationQuery>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postRecommendationQuery(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postRecommendationQuery>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const postRecommendationStream = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Recommendation/Stream`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'Recommendations',  ...options});
    }
  


    export type PostRecommendationStreamMutationResult = NonNullable<Awaited<ReturnType<typeof postRecommendationStream>>>
    export type PostRecommendationStreamMutationBody = Query
    export type PostRecommendationStreamMutationError = unknown

    export const usePostRecommendationStream = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postRecommendationStream>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postRecommendationStream>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postRecommendationStream(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postRecommendationStream>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const postRecommendationSelectOption = (
    recommendationOption: RecommendationOption,
    params?: PostRecommendationSelectOptionParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<Recommendation>(
      {url: `/Recommendation/SelectOption`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: recommendationOption,
        params,
    },
      { type: 'Recommendations',  ...options});
    }
  


    export type PostRecommendationSelectOptionMutationResult = NonNullable<Awaited<ReturnType<typeof postRecommendationSelectOption>>>
    export type PostRecommendationSelectOptionMutationBody = RecommendationOption
    export type PostRecommendationSelectOptionMutationError = unknown

    export const usePostRecommendationSelectOption = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postRecommendationSelectOption>>, TError,{data: RecommendationOption;params?: PostRecommendationSelectOptionParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postRecommendationSelectOption>>, {data: RecommendationOption;params?: PostRecommendationSelectOptionParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postRecommendationSelectOption(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postRecommendationSelectOption>>, TError, {data: RecommendationOption;params?: PostRecommendationSelectOptionParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postRecommendationClearSelection = (
    postRecommendationClearSelectionBody: string[],
 options?: SecondParameter<typeof request>,
) => {
      return request<string[]>(
      {url: `/Recommendation/ClearSelection`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: postRecommendationClearSelectionBody
    },
      { type: 'Recommendations',  ...options});
    }
  


    export type PostRecommendationClearSelectionMutationResult = NonNullable<Awaited<ReturnType<typeof postRecommendationClearSelection>>>
    export type PostRecommendationClearSelectionMutationBody = string[]
    export type PostRecommendationClearSelectionMutationError = unknown

    export const usePostRecommendationClearSelection = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postRecommendationClearSelection>>, TError,{data: string[]}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postRecommendationClearSelection>>, {data: string[]}> = (props) => {
          const {data} = props ?? {};

          return  postRecommendationClearSelection(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postRecommendationClearSelection>>, TError, {data: string[]}, TContext>(mutationFn, mutationOptions)
    }
    
export const postRecommendationQueryRecommendations = (
    query: Query,
    params?: PostRecommendationQueryRecommendationsParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<RecommendationQueryResult>(
      {url: `/Recommendation/QueryRecommendations`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query,
        params,
    },
      { type: 'Recommendations',  ...options});
    }
  


    export type PostRecommendationQueryRecommendationsMutationResult = NonNullable<Awaited<ReturnType<typeof postRecommendationQueryRecommendations>>>
    export type PostRecommendationQueryRecommendationsMutationBody = Query
    export type PostRecommendationQueryRecommendationsMutationError = unknown

    export const usePostRecommendationQueryRecommendations = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postRecommendationQueryRecommendations>>, TError,{data: Query;params?: PostRecommendationQueryRecommendationsParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postRecommendationQueryRecommendations>>, {data: Query;params?: PostRecommendationQueryRecommendationsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postRecommendationQueryRecommendations(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postRecommendationQueryRecommendations>>, TError, {data: Query;params?: PostRecommendationQueryRecommendationsParams}, TContext>(mutationFn, mutationOptions)
    }
    

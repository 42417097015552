import { Box, Button, Group, Loader, MantineColor, Modal, useMantineTheme } from '@mantine/core';
import { colorPalette } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { PollingService } from '@root/Services/Jobs/PollingService';
import { useState } from 'react';

export function RegistrationError() {
    const [loadingChat, setLoadingChat] = useState(false);
    const pollingSvc = useDi(PollingService);
    const loadChat = async () => {
        setLoadingChat(true);
        try {
            window.embedded_svc.liveAgentAPI.startChat();
            await pollingSvc.pollUntil(
                async () => {},
                () => document.getElementsByClassName('embeddedServiceSidebar').length > 0
            );
        } finally {
            setLoadingChat(false);
        }
    };

    const theme = useMantineTheme();

    return (
        <Modal
            padding={theme.spacing.xl}
            opened={true}
            onClose={() => {}}
            withCloseButton={false}
            closeOnEscape={false}
            closeOnClickOutside={false}
            withinPortal
            overlayBlur={4}
            overlayOpacity={1}
            overlayColor={theme.colors.gray[1]}
            size={600}
            shadow="xl"
        >
            <Group>
                <Box>We have encountered some difficulties provisioning your account. Let's open a support ticket to get this solved.</Box>
                <Button leftIcon={loadingChat ? <Loader color={colorPalette.fffColor } size="sm" /> : undefined} onClick={loadChat}>
                    Contact Support
                </Button>
            </Group>
        </Modal>
    );
}

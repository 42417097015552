import styled from '@emotion/styled';
import { Box, Button, Card, Center, Divider, Group, Skeleton, Stack, Sx, Text, useMantineTheme } from '@mantine/core';
import { Score } from '@root/Design/Primitives';
import { useDi } from '@root/Services/DI';
import { useToggle } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { ReactNode, useCallback, useState } from 'react';
import { ChevronDown, ChevronRight, Minus } from 'tabler-icons-react';

export function KpiCard(props: { title: string; value: string; label?: string; loading?: boolean }) {
    const { title, value, label, loading } = props;
    const theme = useMantineTheme();
    const cardStyles = {
        borderWidth: 0,
        borderColor: theme.colors.primary[6],
        borderTopWidth: '1px',
    } as Sx;

    return (
        <Card p={0} sx={cardStyles} withBorder radius="md" shadow="xs">
            <Divider size={3} color="primary" />
            <Stack sx={{ height: 100 }} px="md" pt={5} spacing={5}>
                <Text weight="bold" size="xs" color="dimmed">
                    {title}
                </Text>
                <Box sx={{ flex: 1 }}>
                    {loading ? (
                        <Center sx={{ height: 60 }}>
                            <Skeleton width="80%" height={30} />
                        </Center>
                    ) : (
                        <Score
                            title=""
                            titleAlign="left"
                            loading={loading}
                            sizeDependency={label + value}
                            value={(style) => (
                                <Group style={style} noWrap position="center" spacing={15}>
                                    {label && (
                                        <Text color="primary.5" sx={{ fontSize: '2rem', whiteSpace: 'nowrap' }} weight="lighter">
                                            {label}
                                        </Text>
                                    )}
                                    <Text color="primary.5" sx={{ fontSize: '2rem' }} weight="bolder">
                                        {value}
                                    </Text>
                                </Group>
                            )}
                        />
                    )}
                </Box>
            </Stack>
        </Card>
    );
}

export function ActionCard(props: { children: ReactNode; title?: string }) {
    const { children, title } = props;
    return (
        <Card p="md" withBorder radius="md" shadow="xs">
            <Stack spacing="md" sx={{ height: '100%' }}>
                {title && (
                    <Text size="sm" weight="bold">
                        {title}
                    </Text>
                )}
                {children}
            </Stack>
        </Card>
    );
}

export function PieKeyCard(props: { color: string; label: string; pct: number; amount: number }) {
    const fmtSvc = useDi(FormatService);
    const { color, label, pct, amount } = props;

    return (
        <Card sx={{ background: color }} radius="lg">
            <Stack spacing={0}>
                <Text weight="bolder">{label}</Text>
                <Text color="gray.0" weight="bolder" size={48}>
                    {fmtSvc.formatPercent(pct)}
                </Text>
                <Text size="md" weight="bolder">
                    {fmtSvc.formatMoneyNoDecimals(amount)}
                </Text>
            </Stack>
        </Card>
    );
}

interface IActionListItem {
    action?: string;
    onAction?: () => void;
    metric?: string;
}
type ActionListItemLeaf = { name: string } & IActionListItem;
type ActionListItemSection = { section: string; children: ActionListItemLeaf[]; initExpanded?: boolean } & IActionListItem;
type ActionListItemTitle = { title: string; metric?: string };
type ActionListItemTotal = { total: string; metric?: string };
type ActionListItemSpacer = { spacer: true };
export type ActionListItem = ActionListItemLeaf | ActionListItemSection | ActionListItemTitle | ActionListItemTotal | ActionListItemSpacer;
interface IActionListProps {
    items: ActionListItem[];
}
export function ActionList({ items }: IActionListProps) {
    return (
        <ActionListItemLayout>
            {items.map((item, idx) => (
                <ActionListItem key={idx} item={item} />
            ))}
        </ActionListItemLayout>
    );
}
function ActionListItem({ item }: { item: ActionListItem }) {
    if ('name' in item) {
        return (
            <ActionListItemCascade>
                <Box></Box>
                <Text color="primary.5">&bull; {item.name}</Text>
                <Box>
                    {item.onAction ? (
                        <Button size="xs" radius="lg" variant="light" onClick={item.onAction} rightIcon={<ChevronRight size={16} />}>
                            {item.action}
                        </Button>
                    ) : null}
                </Box>
                <Text align="right">{item.metric}</Text>
            </ActionListItemCascade>
        );
    } else if ('title' in item) {
        return (
            <TitleActionListRow>
                <Text size="sm" weight="bold" sx={{ gridColumn: 'span 3' }}>
                    {item.title}
                </Text>
                <Text align="right" weight="bold">
                    {item.metric}
                </Text>
            </TitleActionListRow>
        );
    } else if ('total' in item) {
        return (
            <TotalActionListRow>
                <Text size="sm" weight="bold" sx={{ gridColumn: 'span 3' }}>
                    Total
                </Text>
                <Text align="right" weight="bold">
                    {item.metric}
                </Text>
            </TotalActionListRow>
        );
    } else if ('spacer' in item) {
        return <Box sx={{ height: 20, gridColumn: 4 }} />;
    } else {
        return <ActionListSection item={item} />;
    }
}
function ActionListSection({ item }: { item: ActionListItemSection }) {
    const [expanded, { toggle }] = useToggle(item.initExpanded ?? false);
    const handleToggle = useCallback(() => {
        if (item.children.length) {
            toggle();
        }
    }, [item.children.length]);

    const hasChildren = !!item.children.length;
    return (
        <>
            <HoverableActionListRow canHover={hasChildren} onClick={handleToggle}>
                <Center sx={{ opacity: hasChildren ? 1 : 0.5 }}>
                    {!hasChildren ? <Minus /> : expanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
                </Center>
                <Text weight="bolder">{item.section}</Text>
                <Box>
                    {item.onAction ? (
                        <Button size="xs" radius="lg" variant="light" onClick={item.onAction} rightIcon={<ChevronRight size={16} />}>
                            {item.action}
                        </Button>
                    ) : null}
                </Box>
                <Text span align="right" weight="bold">
                    {item.metric}
                </Text>
            </HoverableActionListRow>
            {expanded ? item.children.map((child, idx) => <ActionListItem key={idx} item={child} />) : null}
        </>
    );
}
const ActionListItemCascade = styled.div`
    border-bottom: solid 1px ${(p) => p.theme.colors.gray[2]};
    height: 30px;
    button {
        height: 26px;
    }
`;
const TotalActionListRow = styled(ActionListItemCascade)`
    color: #fff;
    background: ${(p) => p.theme.colors.gray[7]};
    border-radius: ${(p) => p.theme.radius.md}px;
    height: 40px;
    > :first-child {
        grid-column: span 3;
    }
`;
const TitleActionListRow = styled(ActionListItemCascade)`
    height: 36px;
    border-top-left-radius: ${(p) => p.theme.radius.sm}px;
    border-top-right-radius: ${(p) => p.theme.radius.sm}px;
    border-bottom-color: #fff;
    background: ${(p) => p.theme.colors.gray[3]};
    > :first-child {
        grid-column: span 3;
    }
`;
const HoverableActionListRow = styled(ActionListItemCascade)<{ canHover?: boolean }>`
    cursor: ${(p) => (p.canHover ? 'pointer' : 'default')};
    background: ${(p) => p.theme.colors.gray[1]};
    &:hover {
        background: ${(p) => (p.canHover ? p.theme.colors.primary[2] : p.theme.colors.gray[1])};
    }
`;
const ActionListItemLayout = styled.div`
    display: grid;
    grid-template-columns: 30px 1fr min-content max-content;
    gap: 0 8px;
    > div {
        display: grid;
        padding: 0 20px 0 15px;
        > * {
            align-self: center;
        }
        grid-template-columns: subgrid;
        grid-column: span 4;
    }
`;

import { Box, Table } from '@mantine/core';
import { ReactNode, useEffect, useState } from 'react';
import { StandardChartProps } from './Models';

export function ListChart<T extends Record<string, string | number>>(props: ListChartProps<T>) {
    const [rows, setRows] = useState<ReactNode[]>([]);

    useEffect(() => {
        setRows(
            props.data.map((item, i) => {
                return (
                    <tr key={i}>
                        <td style={props?.settings?.keyStyling}>
                            {!props.settings?.keyRenderer ? item[props.settings?.keyProperty!] : props.settings.keyRenderer(item)}
                        </td>
                        <td style={props?.settings?.valueStyling}>
                            {!props.settings?.valueRenderer ? item[props.settings?.valueProperty!] : props.settings.valueRenderer(item)}
                        </td>
                    </tr>
                );
            })
        );
    }, [props.data]);

    return (
        <Box pt={'xs'}>
            <Table>
                <tbody>{rows}</tbody>
            </Table>
        </Box>
    );
}

export interface ListChartSettings<T> {
    valueProperty: string;
    keyProperty: string;
    valueRenderer?: (item: T) => ReactNode;
    keyRenderer?: (item: T) => ReactNode;
    valueStyling?: React.CSSProperties;
    keyStyling?: React.CSSProperties;
}
interface ListChartProps<T> extends StandardChartProps<string | number, T> {
    settings?: ListChartSettings<T>;
}
